import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JSONScalar: any;
  UUID: any;
  _bpchar: any;
  _int4: any;
  _text: any;
  bigint: string;
  bpchar: any;
  date: string;
  float8: any;
  geography: any;
  geometry: any;
  json: any;
  jsonb: any;
  picture_load_status: any;
  smallint: any;
  time: any;
  timestamptz: string;
  uuid: string;
};

export type AnalysisTypeDoesNotExistsError = {
  __typename?: 'AnalysisTypeDoesNotExistsError';
  message: Scalars['String'];
};

export type AppUser = {
  __typename?: 'AppUser';
  email: Scalars['String'];
  id: Scalars['String'];
};

export type ApplicationAlreadyExistsError = {
  __typename?: 'ApplicationAlreadyExistsError';
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CompanyDoesNotExistsError = {
  __typename?: 'CompanyDoesNotExistsError';
  message: Scalars['String'];
};

export type CompleteMultipartUploadResponse = CompanyDoesNotExistsError | CompleteMultipartUploadSuccess | InsufficientPartfileError | InvalidParameterError | NotHavingPermissionError | UnexpectedError | UserIdError;

export type CompleteMultipartUploadSuccess = {
  __typename?: 'CompleteMultipartUploadSuccess';
  message: Scalars['String'];
};

export type FieldPictureIdError = {
  __typename?: 'FieldPictureIdError';
  message: Scalars['String'];
};

export type FieldPictureLoad = {
  __typename?: 'FieldPictureLoad';
  /** Coordinates of the position user took picture */
  capCoor?: Maybe<Scalars['JSONScalar']>;
  /** capture time */
  datetime?: Maybe<Scalars['String']>;
  /** s3 object key */
  imagePath?: Maybe<Scalars['String']>;
  /** s3 object key */
  thumbnailPath?: Maybe<Scalars['String']>;
  /** nb_ufield.id */
  ufieldId?: Maybe<Scalars['UUID']>;
  /** nb_ufield.field_name */
  ufieldName?: Maybe<Scalars['UUID']>;
  /** id of nb_field_picture */
  ufieldPictureId: Scalars['UUID'];
};

export type FieldPictureLoadStatusFailureResponse = CompanyDoesNotExistsError | FieldPictureLoadStatusFailureSuccess | NotHavingPermissionError | UserIdError;

export type FieldPictureLoadStatusFailureSuccess = {
  __typename?: 'FieldPictureLoadStatusFailureSuccess';
  record: Array<FieldPictureLoad>;
};

export type FieldPictureLoadStatusIsAlreadyConfirmedError = {
  __typename?: 'FieldPictureLoadStatusIsAlreadyConfirmedError';
  message: Scalars['String'];
};

export type FieldPictureLoadStatusLoadedResponse = CompanyDoesNotExistsError | FieldPictureLoadStatusLoadedSuccess | NotHavingPermissionError | UserIdError;

export type FieldPictureLoadStatusLoadedSuccess = {
  __typename?: 'FieldPictureLoadStatusLoadedSuccess';
  record: Array<FieldPictureLoad>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

export type GetMasterFieldPolygonsByBboxSuccess = {
  __typename?: 'GetMasterFieldPolygonsByBboxSuccess';
  polygons: Array<PolygonInfo>;
};

export type GetMasterFieldPolygonsResponse = GetMasterFieldPolygonsByBboxSuccess | InternalServerError | InvalidParameterError | UserIdError;

export type GetPictureAnalysisResponse = AnalysisTypeDoesNotExistsError | GetPictureAnalysisSuccess | NotHavingPermissionError | UFieldActiveDoesNotExistsError | UFieldGroupActiveDoesNotExistsError | UserIdError;

export type GetPictureAnalysisStatusResponse = AnalysisTypeDoesNotExistsError | GetPictureAnalysisStatusSuccess | NotHavingPermissionError | UserIdError;

export type GetPictureAnalysisStatusSuccess = {
  __typename?: 'GetPictureAnalysisStatusSuccess';
  message: Scalars['String'];
  stat: Array<PictureAnalysisStatus>;
};

export type GetPictureAnalysisSuccess = {
  __typename?: 'GetPictureAnalysisSuccess';
  analysis: Array<PictureAnalysis>;
  message: Scalars['String'];
};

export type GrowthSensingByTileOutput = {
  __typename?: 'GrowthSensingByTileOutput';
  features: Array<Maybe<Scalars['String']>>;
  type: Scalars['String'];
};

export type InsertAGroupInCompanyResponse = CompanyDoesNotExistsError | InsertGroupNameSuccess | NotHavingPermissionError | UserIdError;

export type InsertAUfieldInCompanyResponse = CompanyDoesNotExistsError | InsertFieldSuccess | MFieldActiveDoesNotExistsError | NotHavingPermissionError | NumFieldsExcessError | UFieldGroupActiveDoesNotExistsError | UserIdError;

export type InsertApplicationsSuccess = {
  __typename?: 'InsertApplicationsSuccess';
  message: Scalars['String'];
};

export type InsertFieldSuccess = {
  __typename?: 'InsertFieldSuccess';
  fieldId: Scalars['UUID'];
  message: Scalars['String'];
};

export type InsertGroupNameSuccess = {
  __typename?: 'InsertGroupNameSuccess';
  groupId: Scalars['UUID'];
  message: Scalars['String'];
};

export type InsufficientPartfileError = {
  __typename?: 'InsufficientPartfileError';
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type InternalError = {
  __typename?: 'InternalError';
  message: Scalars['String'];
};

export type InternalServerError = {
  __typename?: 'InternalServerError';
  message: Scalars['String'];
};

export type InvalidApplicationError = {
  __typename?: 'InvalidApplicationError';
  message: Scalars['String'];
};

export type InvalidParameterError = {
  __typename?: 'InvalidParameterError';
  message: Scalars['String'];
};

export type InviteUserToTheCompanyResponse = ApplicationAlreadyExistsError | CompanyDoesNotExistsError | InsertApplicationsSuccess | InvalidParameterError | NotHavingPermissionError | NumUsersExcessError | UserAccountDoesNotExistsError | UserAlreadyRegisteredError;

export type LaunchIpEngineForWideResponse = CompanyDoesNotExistsError | InternalError | NoNipeEngineInformationError | NoWideOrthoimgsError | NotHavingPermissionError | S3OrthoImageCopyError | Success | UserIdError;

export type MFieldActiveDoesNotExistsError = {
  __typename?: 'MFieldActiveDoesNotExistsError';
  message: Scalars['String'];
};

export type MasterFarmFieldByTileOutput = {
  __typename?: 'MasterFarmFieldByTileOutput';
  features: Array<Maybe<Scalars['String']>>;
  type: Scalars['String'];
};

export type NoNipeEngineInformationError = {
  __typename?: 'NoNipeEngineInformationError';
  message: Scalars['String'];
};

export type NoWideOrthoimgsError = {
  __typename?: 'NoWideOrthoimgsError';
  message: Scalars['String'];
};

export type NotHavingPermissionError = {
  __typename?: 'NotHavingPermissionError';
  message: Scalars['String'];
};

export type NumFieldsExcessError = {
  __typename?: 'NumFieldsExcessError';
  message: Scalars['String'];
};

export type NumUsersExcessError = {
  __typename?: 'NumUsersExcessError';
  message: Scalars['String'];
};

export type PictureAnalysis = {
  __typename?: 'PictureAnalysis';
  /** name of analysis type */
  analysisTypeName: Scalars['String'];
  /** capture time */
  datetime: Scalars['String'];
  /** Unique identifier */
  fieldPictureId: Scalars['UUID'];
  /** s3 object key */
  imagePath: Scalars['String'];
  /** result of analysis */
  result: Scalars['JSONScalar'];
  /** NoRequest：解析依頼していない、Requested：解析依頼中、Success：解析完了、Failure：解析失敗、PermissionError：field_pictureが自分の撮影した写真ではない */
  stat: Scalars['String'];
  /** s3 object key */
  thumbnailPath: Scalars['String'];
  /** Unique identifier */
  ufieldId: Scalars['UUID'];
  /** name of ufield */
  ufieldName: Scalars['String'];
};

export type PictureAnalysisStatus = {
  __typename?: 'PictureAnalysisStatus';
  nbFieldPictureId: Scalars['UUID'];
  stat: Scalars['String'];
};

export type PolygonInfo = {
  __typename?: 'PolygonInfo';
  coordinates: Array<Array<Scalars['Float']>>;
  key: Scalars['UUID'];
};

export type RemoveAMemberFromTheCompanyResponse = CompanyDoesNotExistsError | NotHavingPermissionError | Success | UserIdError;

export type RemoveAuFieldGroupNameSuccess = {
  __typename?: 'RemoveAUFieldGroupNameSuccess';
  message: Scalars['String'];
};

export type RemoveAUfieldGroupResponse = CompanyDoesNotExistsError | NotHavingPermissionError | RemoveAuFieldGroupNameSuccess | UFieldGroupActiveDoesNotExistsError | UFieldGroupHasLinksError | UserIdError;

export type RemoveAUfieldInCompanyResponse = CompanyDoesNotExistsError | NotHavingPermissionError | RemoveFieldSuccess | UFieldActiveDoesNotExistsError | UserIdError;

export type RemoveFieldPictureResponse = FieldPictureIdError | NotHavingPermissionError | RemoveFieldPictureSuccess | UserIdError;

export type RemoveFieldPictureSuccess = {
  __typename?: 'RemoveFieldPictureSuccess';
  message: Scalars['String'];
};

export type RemoveFieldSuccess = {
  __typename?: 'RemoveFieldSuccess';
  fieldId: Scalars['UUID'];
};

export type RemoveUFieldAttributeResponse = RemoveUFieldAttributesSuccess | UFieldAttributeDoesNotExistError | UFieldAttributeLinkUFieldError | UserIdError;

export type RemoveUFieldAttributesSuccess = {
  __typename?: 'RemoveUFieldAttributesSuccess';
  message: Scalars['String'];
};

export type ReplyApplicationToRegisterAMemberWithTheCompanyResponse = CompanyDoesNotExistsError | InvalidApplicationError | Success;

export type RequestAPictureAnalysisResponse = InternalServerError | NotHavingPermissionError | RequestAPictureAnalysisSuccess | UserIdError;

export type RequestAPictureAnalysisSuccess = {
  __typename?: 'RequestAPictureAnalysisSuccess';
  message: Scalars['String'];
};

export type ResumeMultipartUploadResponse = CompanyDoesNotExistsError | InvalidParameterError | NotHavingPermissionError | ResumeMultipartUploadSuccess | UserIdError;

export type ResumeMultipartUploadSuccess = {
  __typename?: 'ResumeMultipartUploadSuccess';
  s3SignedUrls: Array<S3SignedUrl>;
};

export type S3OrthoImageCopyError = {
  __typename?: 'S3OrthoImageCopyError';
  message: Scalars['String'];
};

export type S3SignedUrl = {
  __typename?: 'S3SignedUrl';
  partNumber: Scalars['Int'];
  signedUrl: Scalars['String'];
};

export type S3SignedUrlFieldFileResponse = CompanyDoesNotExistsError | InvalidParameterError | NotHavingPermissionError | S3SignedUrlSuccess | UFieldActiveDoesNotExistsError | UserIdError;

export type S3SignedUrlFieldPictureResponse = CompanyDoesNotExistsError | InvalidParameterError | NotHavingPermissionError | S3SignedUrlSuccess | UserIdError;

export type S3SignedUrlSuccess = {
  __typename?: 'S3SignedUrlSuccess';
  signedUrl: Scalars['String'];
};

export type S3SignedUrlTrackerApk = {
  __typename?: 'S3SignedUrlTrackerApk';
  appName: Scalars['String'];
  buildType?: Maybe<Scalars['String']>;
  codeHash: Scalars['String'];
  releaseDatetime: Scalars['DateTime'];
  signedUrl: Scalars['String'];
  version: Scalars['String'];
};

export type S3SignedUrlTrackerApksResponse = CompanyDoesNotExistsError | NotHavingPermissionError | S3SignedUrlTrackerApksSuccess | UserIdError;

export type S3SignedUrlTrackerApksSuccess = {
  __typename?: 'S3SignedUrlTrackerApksSuccess';
  signedUrls: Array<S3SignedUrlTrackerApk>;
};

export type S3SignedUrlWideOrthoimgResponse = CompanyDoesNotExistsError | InvalidParameterError | NotHavingPermissionError | S3SignedUrlWideOrthoimgSuccess | UserIdError;

export type S3SignedUrlWideOrthoimgSuccess = {
  __typename?: 'S3SignedUrlWideOrthoimgSuccess';
  s3SignedUrls: Array<S3SignedUrl>;
  uploadId: Scalars['String'];
};

export type SampleGetItemOutput = {
  __typename?: 'SampleGetItemOutput';
  item_id: Scalars['Int'];
  q: Scalars['Int'];
};

export type SampleHealthOutput = {
  __typename?: 'SampleHealthOutput';
  status: Scalars['String'];
};

export type SetAttributesForTheUfieldResponse = SetAttributesForTheUfieldSuccess | UFieldActiveDoesNotExistsError | UFieldAttributeDoesNotExistError | UserIdError;

export type SetAttributesForTheUfieldSuccess = {
  __typename?: 'SetAttributesForTheUfieldSuccess';
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type Success = {
  __typename?: 'Success';
  message: Scalars['String'];
};

export type SuspendedPart = {
  __typename?: 'SuspendedPart';
  md5: Scalars['String'];
  number: Scalars['Int'];
};

export type SwitchUserAttributeWithTheCompanyResponse = CompanyDoesNotExistsError | InvalidParameterError | NotHavingPermissionError | Success | UserIdError;

export type TerminateWideOrthoimgLoadResponse = CompanyDoesNotExistsError | InvalidParameterError | NotHavingPermissionError | Success | UserIdError;

export type UFieldActiveDoesNotExistsError = {
  __typename?: 'UFieldActiveDoesNotExistsError';
  message: Scalars['String'];
};

export type UFieldAttributeDoesNotExistError = {
  __typename?: 'UFieldAttributeDoesNotExistError';
  message: Scalars['String'];
};

export type UFieldAttributeLinkUFieldError = {
  __typename?: 'UFieldAttributeLinkUFieldError';
  message: Scalars['String'];
};

export type UFieldGroupActiveDoesNotExistsError = {
  __typename?: 'UFieldGroupActiveDoesNotExistsError';
  message: Scalars['String'];
};

export type UFieldGroupHasLinksError = {
  __typename?: 'UFieldGroupHasLinksError';
  message: Scalars['String'];
};

export type UFieldGroupLinkAlreadyExistsError = {
  __typename?: 'UFieldGroupLinkAlreadyExistsError';
  message: Scalars['String'];
};

export type UFieldGroupLinkDoesNotExistsError = {
  __typename?: 'UFieldGroupLinkDoesNotExistsError';
  message: Scalars['String'];
};

export type UnexpectedError = {
  __typename?: 'UnexpectedError';
  message: Scalars['String'];
};

export type UpdateAPictureToAFieldResponse = CompanyDoesNotExistsError | FieldPictureIdError | FieldPictureLoadStatusIsAlreadyConfirmedError | NotHavingPermissionError | UFieldActiveDoesNotExistsError | UpdateAPictureToAUfieldSuccess | UserIdError;

export type UpdateAPictureToAUfieldSuccess = {
  __typename?: 'UpdateAPictureToAUfieldSuccess';
  message: Scalars['String'];
};

export type UpdateAUfgLinkResponse = CompanyDoesNotExistsError | NotHavingPermissionError | UFieldActiveDoesNotExistsError | UFieldGroupActiveDoesNotExistsError | UFieldGroupLinkAlreadyExistsError | UFieldGroupLinkDoesNotExistsError | UpdateAUfgLinkSuccess | UserIdError;

export type UpdateAUfgLinkSuccess = {
  __typename?: 'UpdateAUfgLinkSuccess';
  message: Scalars['String'];
};

export type UpdateAUfieldFieldNameInCompanyResponse = CompanyDoesNotExistsError | NotHavingPermissionError | UFieldActiveDoesNotExistsError | UpdateFieldNameSuccess | UserIdError;

export type UpdateAUfieldFieldPolygonInCompanyResponse = CompanyDoesNotExistsError | InvalidParameterError | NotHavingPermissionError | UFieldActiveDoesNotExistsError | UpdateFieldPolygonSuccess | UserIdError;

export type UpdateAUfieldGroupNameResponse = CompanyDoesNotExistsError | NotHavingPermissionError | UFieldActiveDoesNotExistsError | UFieldGroupActiveDoesNotExistsError | UFieldGroupLinkAlreadyExistsError | UFieldGroupLinkDoesNotExistsError | UpdateAUfieldGroupNameSuccess | UserIdError;

export type UpdateAUfieldGroupNameSuccess = {
  __typename?: 'UpdateAUfieldGroupNameSuccess';
  message: Scalars['String'];
};

export type UpdateFieldNameSuccess = {
  __typename?: 'UpdateFieldNameSuccess';
  fieldId: Scalars['UUID'];
  fieldName: Scalars['String'];
};

export type UpdateFieldPolygonSuccess = {
  __typename?: 'UpdateFieldPolygonSuccess';
  message: Scalars['String'];
};

export type UserAccountDoesNotExistsError = {
  __typename?: 'UserAccountDoesNotExistsError';
  message: Scalars['String'];
};

export type UserAlreadyRegisteredError = {
  __typename?: 'UserAlreadyRegisteredError';
  message: Scalars['String'];
};

export type UserIdError = {
  __typename?: 'UserIdError';
  message: Scalars['String'];
};

export type WideOrthoimgLoad = {
  __typename?: 'WideOrthoimgLoad';
  filename: Scalars['String'];
  orthoimgType: Scalars['String'];
  procDatetime: Scalars['String'];
  reason: Scalars['String'];
  sensDatetime: Scalars['String'];
  suspendedParts: Array<SuspendedPart>;
  uploadId: Scalars['String'];
};

export type WideOrthoimgLoadSuspended = CompanyDoesNotExistsError | NotHavingPermissionError | UserIdError | WideOrthoimgLoadSuspendedSuccess;

export type WideOrthoimgLoadSuspendedSuccess = {
  __typename?: 'WideOrthoimgLoadSuspendedSuccess';
  wideOrthoimgLoad: Array<WideOrthoimgLoad>;
};

/** Boolean expression to compare columns of type "_bpchar". All fields are combined with logical 'AND'. */
export type _Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_bpchar']>;
  _gt?: InputMaybe<Scalars['_bpchar']>;
  _gte?: InputMaybe<Scalars['_bpchar']>;
  _in?: InputMaybe<Array<Scalars['_bpchar']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_bpchar']>;
  _lte?: InputMaybe<Scalars['_bpchar']>;
  _neq?: InputMaybe<Scalars['_bpchar']>;
  _nin?: InputMaybe<Array<Scalars['_bpchar']>>;
};

/** Boolean expression to compare columns of type "_int4". All fields are combined with logical 'AND'. */
export type _Int4_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_int4']>;
  _gt?: InputMaybe<Scalars['_int4']>;
  _gte?: InputMaybe<Scalars['_int4']>;
  _in?: InputMaybe<Array<Scalars['_int4']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_int4']>;
  _lte?: InputMaybe<Scalars['_int4']>;
  _neq?: InputMaybe<Scalars['_int4']>;
  _nin?: InputMaybe<Array<Scalars['_int4']>>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_text']>;
  _gt?: InputMaybe<Scalars['_text']>;
  _gte?: InputMaybe<Scalars['_text']>;
  _in?: InputMaybe<Array<Scalars['_text']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_text']>;
  _lte?: InputMaybe<Scalars['_text']>;
  _neq?: InputMaybe<Scalars['_text']>;
  _nin?: InputMaybe<Array<Scalars['_text']>>;
};

/** columns and relationships of "app_user" */
export type App_User = {
  __typename?: 'app_user';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  created: Scalars['timestamptz'];
  email: Scalars['String'];
  hasura_user_id: Scalars['String'];
  id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};

/** columns and relationships of "app_user_active" */
export type App_User_Active = {
  __typename?: 'app_user_active';
  /** An object relationship */
  app_user?: Maybe<App_User>;
  app_user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  app_user_property_private?: Maybe<App_User_Property_Private>;
  /** An array relationship */
  applicationUserInvitationToCompaniesByApplicantAppUserActiveId: Array<Application_User_Invitation_To_Company>;
  /** An aggregate relationship */
  applicationUserInvitationToCompaniesByApplicantAppUserActiveId_aggregate: Application_User_Invitation_To_Company_Aggregate;
  /** An array relationship */
  application_user_invitation_to_companies: Array<Application_User_Invitation_To_Company>;
  /** An aggregate relationship */
  application_user_invitation_to_companies_aggregate: Application_User_Invitation_To_Company_Aggregate;
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  nbPerformedWorksByWorkerId: Array<Nb_Performed_Work>;
  /** An aggregate relationship */
  nbPerformedWorksByWorkerId_aggregate: Nb_Performed_Work_Aggregate;
  /** An array relationship */
  nb_actual_crop_yeilds: Array<Nb_Actual_Crop_Yeild>;
  /** An aggregate relationship */
  nb_actual_crop_yeilds_aggregate: Nb_Actual_Crop_Yeild_Aggregate;
  /** An array relationship */
  nb_conditioner_works: Array<Nb_Conditioner_Work>;
  /** An aggregate relationship */
  nb_conditioner_works_aggregate: Nb_Conditioner_Work_Aggregate;
  /** An array relationship */
  nb_cultivation_plans: Array<Nb_Cultivation_Plan>;
  /** An aggregate relationship */
  nb_cultivation_plans_aggregate: Nb_Cultivation_Plan_Aggregate;
  /** An array relationship */
  nb_fertilization_works: Array<Nb_Fertilization_Work>;
  /** An aggregate relationship */
  nb_fertilization_works_aggregate: Nb_Fertilization_Work_Aggregate;
  /** An array relationship */
  nb_field_file_loads: Array<Nb_Field_File_Load>;
  /** An aggregate relationship */
  nb_field_file_loads_aggregate: Nb_Field_File_Load_Aggregate;
  /** An array relationship */
  nb_field_files: Array<Nb_Field_File>;
  /** An aggregate relationship */
  nb_field_files_aggregate: Nb_Field_File_Aggregate;
  /** An array relationship */
  nb_field_memos: Array<Nb_Field_Memo>;
  /** An aggregate relationship */
  nb_field_memos_aggregate: Nb_Field_Memo_Aggregate;
  /** An array relationship */
  nb_field_phtographers: Array<Nb_Field_Photographer>;
  /** An aggregate relationship */
  nb_field_phtographers_aggregate: Nb_Field_Photographer_Aggregate;
  /** An array relationship */
  nb_field_picture_loads: Array<Nb_Field_Picture_Load>;
  /** An aggregate relationship */
  nb_field_picture_loads_aggregate: Nb_Field_Picture_Load_Aggregate;
  /** An array relationship */
  nb_field_properties: Array<Nb_Field_Property>;
  /** An aggregate relationship */
  nb_field_properties_aggregate: Nb_Field_Property_Aggregate;
  /** An array relationship */
  nb_field_water_gate_statuses: Array<Nb_Field_Water_Gate_Status>;
  /** An aggregate relationship */
  nb_field_water_gate_statuses_aggregate: Nb_Field_Water_Gate_Status_Aggregate;
  /** An array relationship */
  nb_field_water_gates: Array<Nb_Field_Water_Gate>;
  /** An aggregate relationship */
  nb_field_water_gates_aggregate: Nb_Field_Water_Gate_Aggregate;
  /** An array relationship */
  nb_file_sizes: Array<Nb_File_Size>;
  /** An aggregate relationship */
  nb_file_sizes_aggregate: Nb_File_Size_Aggregate;
  /** An array relationship */
  nb_finished_ufield_works: Array<Nb_Finished_Ufield_Work>;
  /** An aggregate relationship */
  nb_finished_ufield_works_aggregate: Nb_Finished_Ufield_Work_Aggregate;
  /** An array relationship */
  nb_log_user_positions: Array<Nb_Log_User_Position>;
  /** An aggregate relationship */
  nb_log_user_positions_aggregate: Nb_Log_User_Position_Aggregate;
  /** An array relationship */
  nb_performed_works: Array<Nb_Performed_Work>;
  /** An aggregate relationship */
  nb_performed_works_aggregate: Nb_Performed_Work_Aggregate;
  /** An array relationship */
  nb_pesticide_spray_works: Array<Nb_Pesticide_Spray_Work>;
  /** An aggregate relationship */
  nb_pesticide_spray_works_aggregate: Nb_Pesticide_Spray_Work_Aggregate;
  /** An array relationship */
  nb_soil_chemistries: Array<Nb_Soil_Chemistry>;
  /** An aggregate relationship */
  nb_soil_chemistries_aggregate: Nb_Soil_Chemistry_Aggregate;
  /** An array relationship */
  nb_soil_physics: Array<Nb_Soil_Physics>;
  /** An aggregate relationship */
  nb_soil_physics_aggregate: Nb_Soil_Physics_Aggregate;
  /** An array relationship */
  nb_ufields: Array<Nb_Ufield>;
  /** An aggregate relationship */
  nb_ufields_aggregate: Nb_Ufield_Aggregate;
  /** An array relationship */
  nb_wide_orthoimgs: Array<Nb_Wide_Orthoimg>;
  /** An aggregate relationship */
  nb_wide_orthoimgs_aggregate: Nb_Wide_Orthoimg_Aggregate;
  /** An array relationship */
  nbpm_user_attr_links: Array<Nbpm_User_Attr_Link>;
  /** An aggregate relationship */
  nbpm_user_attr_links_aggregate: Nbpm_User_Attr_Link_Aggregate;
  /** An array relationship */
  nbpm_user_cp_link_links: Array<Nbpm_User_Cp_Link_Link>;
  /** An aggregate relationship */
  nbpm_user_cp_link_links_aggregate: Nbpm_User_Cp_Link_Link_Aggregate;
  /** An array relationship */
  nbs_ipe_run_wides: Array<Nbs_Ipe_Run_Wide>;
  /** An aggregate relationship */
  nbs_ipe_run_wides_aggregate: Nbs_Ipe_Run_Wide_Aggregate;
  /** An array relationship */
  nbs_multipart_uploads: Array<Nbs_Multipart_Upload>;
  /** An aggregate relationship */
  nbs_multipart_uploads_aggregate: Nbs_Multipart_Upload_Aggregate;
  /** An array relationship */
  nbs_wide_orthoimg_loads: Array<Nbs_Wide_Orthoimg_Load>;
  /** An aggregate relationship */
  nbs_wide_orthoimg_loads_aggregate: Nbs_Wide_Orthoimg_Load_Aggregate;
  /** An array relationship */
  nbtrk_contexts: Array<Nbtrk_Context>;
  /** An aggregate relationship */
  nbtrk_contexts_aggregate: Nbtrk_Context_Aggregate;
  nickname: Scalars['String'];
  /** An array relationship */
  nipe_run_commands: Array<Nipe_Run_Command>;
  /** An aggregate relationship */
  nipe_run_commands_aggregate: Nipe_Run_Command_Aggregate;
  updated: Scalars['timestamptz'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveApplicationUserInvitationToCompaniesByApplicantAppUserActiveIdArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveApplicationUserInvitationToCompaniesByApplicantAppUserActiveId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveApplication_User_Invitation_To_CompaniesArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveApplication_User_Invitation_To_Companies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbPerformedWorksByWorkerIdArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbPerformedWorksByWorkerId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Actual_Crop_YeildsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Order_By>>;
  where?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Actual_Crop_Yeilds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Order_By>>;
  where?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Conditioner_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Conditioner_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Cultivation_PlansArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Cultivation_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Fertilization_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Fertilization_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_File_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_File_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_FilesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_MemosArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Memo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Memo_Order_By>>;
  where?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_Memos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Memo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Memo_Order_By>>;
  where?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_PhtographersArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Photographer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Photographer_Order_By>>;
  where?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_Phtographers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Photographer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Photographer_Order_By>>;
  where?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_Picture_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_Picture_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_PropertiesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Property_Order_By>>;
  where?: InputMaybe<Nb_Field_Property_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_Properties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Property_Order_By>>;
  where?: InputMaybe<Nb_Field_Property_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_Water_Gate_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_Water_Gate_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_Water_GatesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Field_Water_Gates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_File_SizesArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_File_Sizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Finished_Ufield_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Finished_Ufield_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Log_User_PositionsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Log_User_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Log_User_Position_Order_By>>;
  where?: InputMaybe<Nb_Log_User_Position_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Log_User_Positions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Log_User_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Log_User_Position_Order_By>>;
  where?: InputMaybe<Nb_Log_User_Position_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Performed_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Performed_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Pesticide_Spray_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Pesticide_Spray_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Soil_ChemistriesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Soil_Chemistries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Soil_PhysicsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Physics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Physics_Order_By>>;
  where?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Soil_Physics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Physics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Physics_Order_By>>;
  where?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_UfieldsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Ufields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Wide_OrthoimgsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNb_Wide_Orthoimgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbpm_User_Attr_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attr_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbpm_User_Attr_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attr_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbpm_User_Cp_Link_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbpm_User_Cp_Link_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbs_Ipe_Run_WidesArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbs_Ipe_Run_Wides_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbs_Multipart_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Multipart_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Multipart_Upload_Order_By>>;
  where?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbs_Multipart_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Multipart_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Multipart_Upload_Order_By>>;
  where?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbs_Wide_Orthoimg_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbs_Wide_Orthoimg_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbtrk_ContextsArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Context_Order_By>>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNbtrk_Contexts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Context_Order_By>>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNipe_Run_CommandsArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Run_Command_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Run_Command_Order_By>>;
  where?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
};


/** columns and relationships of "app_user_active" */
export type App_User_ActiveNipe_Run_Commands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Run_Command_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Run_Command_Order_By>>;
  where?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
};

/** aggregated selection of "app_user_active" */
export type App_User_Active_Aggregate = {
  __typename?: 'app_user_active_aggregate';
  aggregate?: Maybe<App_User_Active_Aggregate_Fields>;
  nodes: Array<App_User_Active>;
};

/** aggregate fields of "app_user_active" */
export type App_User_Active_Aggregate_Fields = {
  __typename?: 'app_user_active_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<App_User_Active_Max_Fields>;
  min?: Maybe<App_User_Active_Min_Fields>;
};


/** aggregate fields of "app_user_active" */
export type App_User_Active_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_User_Active_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "app_user_active". All fields are combined with a logical 'AND'. */
export type App_User_Active_Bool_Exp = {
  _and?: InputMaybe<Array<App_User_Active_Bool_Exp>>;
  _not?: InputMaybe<App_User_Active_Bool_Exp>;
  _or?: InputMaybe<Array<App_User_Active_Bool_Exp>>;
  app_user?: InputMaybe<App_User_Bool_Exp>;
  app_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  app_user_property_private?: InputMaybe<App_User_Property_Private_Bool_Exp>;
  applicationUserInvitationToCompaniesByApplicantAppUserActiveId?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
  applicationUserInvitationToCompaniesByApplicantAppUserActiveId_aggregate?: InputMaybe<Application_User_Invitation_To_Company_Aggregate_Bool_Exp>;
  application_user_invitation_to_companies?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
  application_user_invitation_to_companies_aggregate?: InputMaybe<Application_User_Invitation_To_Company_Aggregate_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nbPerformedWorksByWorkerId?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
  nbPerformedWorksByWorkerId_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Bool_Exp>;
  nb_actual_crop_yeilds?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  nb_actual_crop_yeilds_aggregate?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp>;
  nb_conditioner_works?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
  nb_conditioner_works_aggregate?: InputMaybe<Nb_Conditioner_Work_Aggregate_Bool_Exp>;
  nb_cultivation_plans?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_cultivation_plans_aggregate?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp>;
  nb_fertilization_works?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
  nb_fertilization_works_aggregate?: InputMaybe<Nb_Fertilization_Work_Aggregate_Bool_Exp>;
  nb_field_file_loads?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
  nb_field_file_loads_aggregate?: InputMaybe<Nb_Field_File_Load_Aggregate_Bool_Exp>;
  nb_field_files?: InputMaybe<Nb_Field_File_Bool_Exp>;
  nb_field_files_aggregate?: InputMaybe<Nb_Field_File_Aggregate_Bool_Exp>;
  nb_field_memos?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
  nb_field_memos_aggregate?: InputMaybe<Nb_Field_Memo_Aggregate_Bool_Exp>;
  nb_field_phtographers?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
  nb_field_phtographers_aggregate?: InputMaybe<Nb_Field_Photographer_Aggregate_Bool_Exp>;
  nb_field_picture_loads?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
  nb_field_picture_loads_aggregate?: InputMaybe<Nb_Field_Picture_Load_Aggregate_Bool_Exp>;
  nb_field_properties?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  nb_field_properties_aggregate?: InputMaybe<Nb_Field_Property_Aggregate_Bool_Exp>;
  nb_field_water_gate_statuses?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
  nb_field_water_gate_statuses_aggregate?: InputMaybe<Nb_Field_Water_Gate_Status_Aggregate_Bool_Exp>;
  nb_field_water_gates?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
  nb_field_water_gates_aggregate?: InputMaybe<Nb_Field_Water_Gate_Aggregate_Bool_Exp>;
  nb_file_sizes?: InputMaybe<Nb_File_Size_Bool_Exp>;
  nb_file_sizes_aggregate?: InputMaybe<Nb_File_Size_Aggregate_Bool_Exp>;
  nb_finished_ufield_works?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
  nb_finished_ufield_works_aggregate?: InputMaybe<Nb_Finished_Ufield_Work_Aggregate_Bool_Exp>;
  nb_log_user_positions?: InputMaybe<Nb_Log_User_Position_Bool_Exp>;
  nb_log_user_positions_aggregate?: InputMaybe<Nb_Log_User_Position_Aggregate_Bool_Exp>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Bool_Exp>;
  nb_pesticide_spray_works?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
  nb_pesticide_spray_works_aggregate?: InputMaybe<Nb_Pesticide_Spray_Work_Aggregate_Bool_Exp>;
  nb_soil_chemistries?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
  nb_soil_chemistries_aggregate?: InputMaybe<Nb_Soil_Chemistry_Aggregate_Bool_Exp>;
  nb_soil_physics?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  nb_soil_physics_aggregate?: InputMaybe<Nb_Soil_Physics_Aggregate_Bool_Exp>;
  nb_ufields?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nb_ufields_aggregate?: InputMaybe<Nb_Ufield_Aggregate_Bool_Exp>;
  nb_wide_orthoimgs?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
  nb_wide_orthoimgs_aggregate?: InputMaybe<Nb_Wide_Orthoimg_Aggregate_Bool_Exp>;
  nbpm_user_attr_links?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
  nbpm_user_attr_links_aggregate?: InputMaybe<Nbpm_User_Attr_Link_Aggregate_Bool_Exp>;
  nbpm_user_cp_link_links?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
  nbpm_user_cp_link_links_aggregate?: InputMaybe<Nbpm_User_Cp_Link_Link_Aggregate_Bool_Exp>;
  nbs_ipe_run_wides?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
  nbs_ipe_run_wides_aggregate?: InputMaybe<Nbs_Ipe_Run_Wide_Aggregate_Bool_Exp>;
  nbs_multipart_uploads?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
  nbs_multipart_uploads_aggregate?: InputMaybe<Nbs_Multipart_Upload_Aggregate_Bool_Exp>;
  nbs_wide_orthoimg_loads?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
  nbs_wide_orthoimg_loads_aggregate?: InputMaybe<Nbs_Wide_Orthoimg_Load_Aggregate_Bool_Exp>;
  nbtrk_contexts?: InputMaybe<Nbtrk_Context_Bool_Exp>;
  nbtrk_contexts_aggregate?: InputMaybe<Nbtrk_Context_Aggregate_Bool_Exp>;
  nickname?: InputMaybe<String_Comparison_Exp>;
  nipe_run_commands?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
  nipe_run_commands_aggregate?: InputMaybe<Nipe_Run_Command_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "app_user_active" */
export enum App_User_Active_Constraint {
  /** unique or primary key constraint on columns "app_user_id" */
  AppUserActiveAppUserIdKey = 'app_user_active_app_user_id_key',
  /** unique or primary key constraint on columns "nickname" */
  AppUserActiveNicknameKey = 'app_user_active_nickname_key',
  /** unique or primary key constraint on columns "id" */
  AppUserActivePkey = 'app_user_active_pkey'
}

/** input type for inserting data into table "app_user_active" */
export type App_User_Active_Insert_Input = {
  app_user?: InputMaybe<App_User_Obj_Rel_Insert_Input>;
  app_user_id?: InputMaybe<Scalars['uuid']>;
  app_user_property_private?: InputMaybe<App_User_Property_Private_Obj_Rel_Insert_Input>;
  applicationUserInvitationToCompaniesByApplicantAppUserActiveId?: InputMaybe<Application_User_Invitation_To_Company_Arr_Rel_Insert_Input>;
  application_user_invitation_to_companies?: InputMaybe<Application_User_Invitation_To_Company_Arr_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nbPerformedWorksByWorkerId?: InputMaybe<Nb_Performed_Work_Arr_Rel_Insert_Input>;
  nb_actual_crop_yeilds?: InputMaybe<Nb_Actual_Crop_Yeild_Arr_Rel_Insert_Input>;
  nb_conditioner_works?: InputMaybe<Nb_Conditioner_Work_Arr_Rel_Insert_Input>;
  nb_cultivation_plans?: InputMaybe<Nb_Cultivation_Plan_Arr_Rel_Insert_Input>;
  nb_fertilization_works?: InputMaybe<Nb_Fertilization_Work_Arr_Rel_Insert_Input>;
  nb_field_file_loads?: InputMaybe<Nb_Field_File_Load_Arr_Rel_Insert_Input>;
  nb_field_files?: InputMaybe<Nb_Field_File_Arr_Rel_Insert_Input>;
  nb_field_memos?: InputMaybe<Nb_Field_Memo_Arr_Rel_Insert_Input>;
  nb_field_phtographers?: InputMaybe<Nb_Field_Photographer_Arr_Rel_Insert_Input>;
  nb_field_picture_loads?: InputMaybe<Nb_Field_Picture_Load_Arr_Rel_Insert_Input>;
  nb_field_properties?: InputMaybe<Nb_Field_Property_Arr_Rel_Insert_Input>;
  nb_field_water_gate_statuses?: InputMaybe<Nb_Field_Water_Gate_Status_Arr_Rel_Insert_Input>;
  nb_field_water_gates?: InputMaybe<Nb_Field_Water_Gate_Arr_Rel_Insert_Input>;
  nb_file_sizes?: InputMaybe<Nb_File_Size_Arr_Rel_Insert_Input>;
  nb_finished_ufield_works?: InputMaybe<Nb_Finished_Ufield_Work_Arr_Rel_Insert_Input>;
  nb_log_user_positions?: InputMaybe<Nb_Log_User_Position_Arr_Rel_Insert_Input>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Arr_Rel_Insert_Input>;
  nb_pesticide_spray_works?: InputMaybe<Nb_Pesticide_Spray_Work_Arr_Rel_Insert_Input>;
  nb_soil_chemistries?: InputMaybe<Nb_Soil_Chemistry_Arr_Rel_Insert_Input>;
  nb_soil_physics?: InputMaybe<Nb_Soil_Physics_Arr_Rel_Insert_Input>;
  nb_ufields?: InputMaybe<Nb_Ufield_Arr_Rel_Insert_Input>;
  nb_wide_orthoimgs?: InputMaybe<Nb_Wide_Orthoimg_Arr_Rel_Insert_Input>;
  nbpm_user_attr_links?: InputMaybe<Nbpm_User_Attr_Link_Arr_Rel_Insert_Input>;
  nbpm_user_cp_link_links?: InputMaybe<Nbpm_User_Cp_Link_Link_Arr_Rel_Insert_Input>;
  nbs_ipe_run_wides?: InputMaybe<Nbs_Ipe_Run_Wide_Arr_Rel_Insert_Input>;
  nbs_multipart_uploads?: InputMaybe<Nbs_Multipart_Upload_Arr_Rel_Insert_Input>;
  nbs_wide_orthoimg_loads?: InputMaybe<Nbs_Wide_Orthoimg_Load_Arr_Rel_Insert_Input>;
  nbtrk_contexts?: InputMaybe<Nbtrk_Context_Arr_Rel_Insert_Input>;
  nickname?: InputMaybe<Scalars['String']>;
  nipe_run_commands?: InputMaybe<Nipe_Run_Command_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type App_User_Active_Max_Fields = {
  __typename?: 'app_user_active_max_fields';
  app_user_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type App_User_Active_Min_Fields = {
  __typename?: 'app_user_active_min_fields';
  app_user_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "app_user_active" */
export type App_User_Active_Mutation_Response = {
  __typename?: 'app_user_active_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_User_Active>;
};

/** input type for inserting object relation for remote table "app_user_active" */
export type App_User_Active_Obj_Rel_Insert_Input = {
  data: App_User_Active_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_User_Active_On_Conflict>;
};

/** on_conflict condition type for table "app_user_active" */
export type App_User_Active_On_Conflict = {
  constraint: App_User_Active_Constraint;
  update_columns?: Array<App_User_Active_Update_Column>;
  where?: InputMaybe<App_User_Active_Bool_Exp>;
};

/** Ordering options when selecting data from "app_user_active". */
export type App_User_Active_Order_By = {
  app_user?: InputMaybe<App_User_Order_By>;
  app_user_id?: InputMaybe<Order_By>;
  app_user_property_private?: InputMaybe<App_User_Property_Private_Order_By>;
  applicationUserInvitationToCompaniesByApplicantAppUserActiveId_aggregate?: InputMaybe<Application_User_Invitation_To_Company_Aggregate_Order_By>;
  application_user_invitation_to_companies_aggregate?: InputMaybe<Application_User_Invitation_To_Company_Aggregate_Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nbPerformedWorksByWorkerId_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Order_By>;
  nb_actual_crop_yeilds_aggregate?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Order_By>;
  nb_conditioner_works_aggregate?: InputMaybe<Nb_Conditioner_Work_Aggregate_Order_By>;
  nb_cultivation_plans_aggregate?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Order_By>;
  nb_fertilization_works_aggregate?: InputMaybe<Nb_Fertilization_Work_Aggregate_Order_By>;
  nb_field_file_loads_aggregate?: InputMaybe<Nb_Field_File_Load_Aggregate_Order_By>;
  nb_field_files_aggregate?: InputMaybe<Nb_Field_File_Aggregate_Order_By>;
  nb_field_memos_aggregate?: InputMaybe<Nb_Field_Memo_Aggregate_Order_By>;
  nb_field_phtographers_aggregate?: InputMaybe<Nb_Field_Photographer_Aggregate_Order_By>;
  nb_field_picture_loads_aggregate?: InputMaybe<Nb_Field_Picture_Load_Aggregate_Order_By>;
  nb_field_properties_aggregate?: InputMaybe<Nb_Field_Property_Aggregate_Order_By>;
  nb_field_water_gate_statuses_aggregate?: InputMaybe<Nb_Field_Water_Gate_Status_Aggregate_Order_By>;
  nb_field_water_gates_aggregate?: InputMaybe<Nb_Field_Water_Gate_Aggregate_Order_By>;
  nb_file_sizes_aggregate?: InputMaybe<Nb_File_Size_Aggregate_Order_By>;
  nb_finished_ufield_works_aggregate?: InputMaybe<Nb_Finished_Ufield_Work_Aggregate_Order_By>;
  nb_log_user_positions_aggregate?: InputMaybe<Nb_Log_User_Position_Aggregate_Order_By>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Order_By>;
  nb_pesticide_spray_works_aggregate?: InputMaybe<Nb_Pesticide_Spray_Work_Aggregate_Order_By>;
  nb_soil_chemistries_aggregate?: InputMaybe<Nb_Soil_Chemistry_Aggregate_Order_By>;
  nb_soil_physics_aggregate?: InputMaybe<Nb_Soil_Physics_Aggregate_Order_By>;
  nb_ufields_aggregate?: InputMaybe<Nb_Ufield_Aggregate_Order_By>;
  nb_wide_orthoimgs_aggregate?: InputMaybe<Nb_Wide_Orthoimg_Aggregate_Order_By>;
  nbpm_user_attr_links_aggregate?: InputMaybe<Nbpm_User_Attr_Link_Aggregate_Order_By>;
  nbpm_user_cp_link_links_aggregate?: InputMaybe<Nbpm_User_Cp_Link_Link_Aggregate_Order_By>;
  nbs_ipe_run_wides_aggregate?: InputMaybe<Nbs_Ipe_Run_Wide_Aggregate_Order_By>;
  nbs_multipart_uploads_aggregate?: InputMaybe<Nbs_Multipart_Upload_Aggregate_Order_By>;
  nbs_wide_orthoimg_loads_aggregate?: InputMaybe<Nbs_Wide_Orthoimg_Load_Aggregate_Order_By>;
  nbtrk_contexts_aggregate?: InputMaybe<Nbtrk_Context_Aggregate_Order_By>;
  nickname?: InputMaybe<Order_By>;
  nipe_run_commands_aggregate?: InputMaybe<Nipe_Run_Command_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: app_user_active */
export type App_User_Active_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "app_user_active" */
export enum App_User_Active_Select_Column {
  /** column name */
  AppUserId = 'app_user_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "app_user_active" */
export type App_User_Active_Set_Input = {
  app_user_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "app_user_active" */
export type App_User_Active_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_User_Active_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_User_Active_Stream_Cursor_Value_Input = {
  app_user_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "app_user_active" */
export enum App_User_Active_Update_Column {
  /** column name */
  AppUserId = 'app_user_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type App_User_Active_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_User_Active_Set_Input>;
  where: App_User_Active_Bool_Exp;
};

/** aggregated selection of "app_user" */
export type App_User_Aggregate = {
  __typename?: 'app_user_aggregate';
  aggregate?: Maybe<App_User_Aggregate_Fields>;
  nodes: Array<App_User>;
};

/** aggregate fields of "app_user" */
export type App_User_Aggregate_Fields = {
  __typename?: 'app_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<App_User_Max_Fields>;
  min?: Maybe<App_User_Min_Fields>;
};


/** aggregate fields of "app_user" */
export type App_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "app_user". All fields are combined with a logical 'AND'. */
export type App_User_Bool_Exp = {
  _and?: InputMaybe<Array<App_User_Bool_Exp>>;
  _not?: InputMaybe<App_User_Bool_Exp>;
  _or?: InputMaybe<Array<App_User_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  hasura_user_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "app_user" */
export enum App_User_Constraint {
  /** unique or primary key constraint on columns "hasura_user_id" */
  AppUserHasuraUserIdKey = 'app_user_hasura_user_id_key',
  /** unique or primary key constraint on columns "id" */
  AppUserPkey = 'app_user_pkey'
}

/** input type for inserting data into table "app_user" */
export type App_User_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  hasura_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type App_User_Max_Fields = {
  __typename?: 'app_user_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  hasura_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type App_User_Min_Fields = {
  __typename?: 'app_user_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  hasura_user_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "app_user" */
export type App_User_Mutation_Response = {
  __typename?: 'app_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_User>;
};

/** input type for inserting object relation for remote table "app_user" */
export type App_User_Obj_Rel_Insert_Input = {
  data: App_User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_User_On_Conflict>;
};

/** on_conflict condition type for table "app_user" */
export type App_User_On_Conflict = {
  constraint: App_User_Constraint;
  update_columns?: Array<App_User_Update_Column>;
  where?: InputMaybe<App_User_Bool_Exp>;
};

/** Ordering options when selecting data from "app_user". */
export type App_User_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  hasura_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: app_user */
export type App_User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "app_user_property_private" */
export type App_User_Property_Private = {
  __typename?: 'app_user_property_private';
  /** An object relationship */
  app_user_active: App_User_Active;
  app_user_active_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  phone_number?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "app_user_property_private" */
export type App_User_Property_Private_Aggregate = {
  __typename?: 'app_user_property_private_aggregate';
  aggregate?: Maybe<App_User_Property_Private_Aggregate_Fields>;
  nodes: Array<App_User_Property_Private>;
};

/** aggregate fields of "app_user_property_private" */
export type App_User_Property_Private_Aggregate_Fields = {
  __typename?: 'app_user_property_private_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<App_User_Property_Private_Max_Fields>;
  min?: Maybe<App_User_Property_Private_Min_Fields>;
};


/** aggregate fields of "app_user_property_private" */
export type App_User_Property_Private_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_User_Property_Private_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "app_user_property_private". All fields are combined with a logical 'AND'. */
export type App_User_Property_Private_Bool_Exp = {
  _and?: InputMaybe<Array<App_User_Property_Private_Bool_Exp>>;
  _not?: InputMaybe<App_User_Property_Private_Bool_Exp>;
  _or?: InputMaybe<Array<App_User_Property_Private_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  app_user_active_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "app_user_property_private" */
export enum App_User_Property_Private_Constraint {
  /** unique or primary key constraint on columns "app_user_active_id" */
  AppUserPropertyAppUserActiveIdKey = 'app_user_property_app_user_active_id_key',
  /** unique or primary key constraint on columns "id" */
  AppUserPropertyPkey = 'app_user_property_pkey'
}

/** input type for inserting data into table "app_user_property_private" */
export type App_User_Property_Private_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type App_User_Property_Private_Max_Fields = {
  __typename?: 'app_user_property_private_max_fields';
  app_user_active_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type App_User_Property_Private_Min_Fields = {
  __typename?: 'app_user_property_private_min_fields';
  app_user_active_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "app_user_property_private" */
export type App_User_Property_Private_Mutation_Response = {
  __typename?: 'app_user_property_private_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_User_Property_Private>;
};

/** input type for inserting object relation for remote table "app_user_property_private" */
export type App_User_Property_Private_Obj_Rel_Insert_Input = {
  data: App_User_Property_Private_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_User_Property_Private_On_Conflict>;
};

/** on_conflict condition type for table "app_user_property_private" */
export type App_User_Property_Private_On_Conflict = {
  constraint: App_User_Property_Private_Constraint;
  update_columns?: Array<App_User_Property_Private_Update_Column>;
  where?: InputMaybe<App_User_Property_Private_Bool_Exp>;
};

/** Ordering options when selecting data from "app_user_property_private". */
export type App_User_Property_Private_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  app_user_active_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: app_user_property_private */
export type App_User_Property_Private_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "app_user_property_private" */
export enum App_User_Property_Private_Select_Column {
  /** column name */
  AppUserActiveId = 'app_user_active_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "app_user_property_private" */
export type App_User_Property_Private_Set_Input = {
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "app_user_property_private" */
export type App_User_Property_Private_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_User_Property_Private_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_User_Property_Private_Stream_Cursor_Value_Input = {
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "app_user_property_private" */
export enum App_User_Property_Private_Update_Column {
  /** column name */
  AppUserActiveId = 'app_user_active_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Updated = 'updated'
}

export type App_User_Property_Private_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_User_Property_Private_Set_Input>;
  where: App_User_Property_Private_Bool_Exp;
};

/** select columns of table "app_user" */
export enum App_User_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Email = 'email',
  /** column name */
  HasuraUserId = 'hasura_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "app_user" */
export type App_User_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  hasura_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "app_user" */
export type App_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_User_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  hasura_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "app_user" */
export enum App_User_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Email = 'email',
  /** column name */
  HasuraUserId = 'hasura_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

export type App_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_User_Set_Input>;
  where: App_User_Bool_Exp;
};

/** ユーザを会社に招待する情報を管理するテーブル */
export type Application_User_Invitation_To_Company = {
  __typename?: 'application_user_invitation_to_company';
  /** An object relationship */
  appUserActiveByApplicantAppUserActiveId: App_User_Active;
  /** An object relationship */
  app_user_active: App_User_Active;
  /** 申請時に渡されたメールアドレスに紐付いていたユーザのID */
  app_user_active_id: Scalars['uuid'];
  /** 申請者のapp_user_active_id */
  applicant_app_user_active_id: Scalars['uuid'];
  /** 申請時に渡される会社ID */
  company_id: Scalars['uuid'];
  /** 追加する会社の名前 */
  company_name: Scalars['String'];
  created: Scalars['timestamptz'];
  /** 申請時のものを記録として残す。ユーザの登録メールアドレスには追従させない(外部キーcascadeとはしない)。 */
  email: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An object relationship */
  nb_ufield_group?: Maybe<Nb_Ufield_Group>;
  /** An object relationship */
  nbpm_user_attribute: Nbpm_User_Attribute;
  /** 申請の状態。PENDING、ACCEPTED_now()、DECLINED_now()の３種類。ACCEPTEDとDECLINEDは_nowサフィックスをつけて複数保持可能とする。 */
  status: Scalars['String'];
  /** 招待時に紐付けるユーザ圃場グループ。ユーザ属性によってNULLの場合や必要な場合がある。チェックはビジネスロジックで行う。 */
  ufield_group_id?: Maybe<Scalars['uuid']>;
  /** ユーザ属性情報 */
  user_attribute: Scalars['String'];
};

/** aggregated selection of "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_Aggregate = {
  __typename?: 'application_user_invitation_to_company_aggregate';
  aggregate?: Maybe<Application_User_Invitation_To_Company_Aggregate_Fields>;
  nodes: Array<Application_User_Invitation_To_Company>;
};

export type Application_User_Invitation_To_Company_Aggregate_Bool_Exp = {
  count?: InputMaybe<Application_User_Invitation_To_Company_Aggregate_Bool_Exp_Count>;
};

export type Application_User_Invitation_To_Company_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_Aggregate_Fields = {
  __typename?: 'application_user_invitation_to_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Application_User_Invitation_To_Company_Max_Fields>;
  min?: Maybe<Application_User_Invitation_To_Company_Min_Fields>;
};


/** aggregate fields of "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Application_User_Invitation_To_Company_Max_Order_By>;
  min?: InputMaybe<Application_User_Invitation_To_Company_Min_Order_By>;
};

/** input type for inserting array relation for remote table "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_Arr_Rel_Insert_Input = {
  data: Array<Application_User_Invitation_To_Company_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Application_User_Invitation_To_Company_On_Conflict>;
};

/** Boolean expression to filter rows from the table "application_user_invitation_to_company". All fields are combined with a logical 'AND'. */
export type Application_User_Invitation_To_Company_Bool_Exp = {
  _and?: InputMaybe<Array<Application_User_Invitation_To_Company_Bool_Exp>>;
  _not?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
  _or?: InputMaybe<Array<Application_User_Invitation_To_Company_Bool_Exp>>;
  appUserActiveByApplicantAppUserActiveId?: InputMaybe<App_User_Active_Bool_Exp>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  app_user_active_id?: InputMaybe<Uuid_Comparison_Exp>;
  applicant_app_user_active_id?: InputMaybe<Uuid_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
  nbpm_user_attribute?: InputMaybe<Nbpm_User_Attribute_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  ufield_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_attribute?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "application_user_invitation_to_company" */
export enum Application_User_Invitation_To_Company_Constraint {
  /** unique or primary key constraint on columns "app_user_active_id", "status", "company_id" */
  ApplicationUserInvitationTAppUserActiveIdCompanyIdKey = 'application_user_invitation_t_app_user_active_id_company_id_key',
  /** unique or primary key constraint on columns "id" */
  ApplicationUserInvitationToCompanyPkey = 'application_user_invitation_to_company_pkey'
}

/** input type for inserting data into table "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_Insert_Input = {
  appUserActiveByApplicantAppUserActiveId?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  /** 申請時に渡されたメールアドレスに紐付いていたユーザのID */
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** 申請者のapp_user_active_id */
  applicant_app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** 申請時に渡される会社ID */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** 追加する会社の名前 */
  company_name?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 申請時のものを記録として残す。ユーザの登録メールアドレスには追従させない(外部キーcascadeとはしない)。 */
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Obj_Rel_Insert_Input>;
  nbpm_user_attribute?: InputMaybe<Nbpm_User_Attribute_Obj_Rel_Insert_Input>;
  /** 申請の状態。PENDING、ACCEPTED_now()、DECLINED_now()の３種類。ACCEPTEDとDECLINEDは_nowサフィックスをつけて複数保持可能とする。 */
  status?: InputMaybe<Scalars['String']>;
  /** 招待時に紐付けるユーザ圃場グループ。ユーザ属性によってNULLの場合や必要な場合がある。チェックはビジネスロジックで行う。 */
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  /** ユーザ属性情報 */
  user_attribute?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Application_User_Invitation_To_Company_Max_Fields = {
  __typename?: 'application_user_invitation_to_company_max_fields';
  /** 申請時に渡されたメールアドレスに紐付いていたユーザのID */
  app_user_active_id?: Maybe<Scalars['uuid']>;
  /** 申請者のapp_user_active_id */
  applicant_app_user_active_id?: Maybe<Scalars['uuid']>;
  /** 申請時に渡される会社ID */
  company_id?: Maybe<Scalars['uuid']>;
  /** 追加する会社の名前 */
  company_name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  /** 申請時のものを記録として残す。ユーザの登録メールアドレスには追従させない(外部キーcascadeとはしない)。 */
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** 申請の状態。PENDING、ACCEPTED_now()、DECLINED_now()の３種類。ACCEPTEDとDECLINEDは_nowサフィックスをつけて複数保持可能とする。 */
  status?: Maybe<Scalars['String']>;
  /** 招待時に紐付けるユーザ圃場グループ。ユーザ属性によってNULLの場合や必要な場合がある。チェックはビジネスロジックで行う。 */
  ufield_group_id?: Maybe<Scalars['uuid']>;
  /** ユーザ属性情報 */
  user_attribute?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_Max_Order_By = {
  /** 申請時に渡されたメールアドレスに紐付いていたユーザのID */
  app_user_active_id?: InputMaybe<Order_By>;
  /** 申請者のapp_user_active_id */
  applicant_app_user_active_id?: InputMaybe<Order_By>;
  /** 申請時に渡される会社ID */
  company_id?: InputMaybe<Order_By>;
  /** 追加する会社の名前 */
  company_name?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  /** 申請時のものを記録として残す。ユーザの登録メールアドレスには追従させない(外部キーcascadeとはしない)。 */
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** 申請の状態。PENDING、ACCEPTED_now()、DECLINED_now()の３種類。ACCEPTEDとDECLINEDは_nowサフィックスをつけて複数保持可能とする。 */
  status?: InputMaybe<Order_By>;
  /** 招待時に紐付けるユーザ圃場グループ。ユーザ属性によってNULLの場合や必要な場合がある。チェックはビジネスロジックで行う。 */
  ufield_group_id?: InputMaybe<Order_By>;
  /** ユーザ属性情報 */
  user_attribute?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Application_User_Invitation_To_Company_Min_Fields = {
  __typename?: 'application_user_invitation_to_company_min_fields';
  /** 申請時に渡されたメールアドレスに紐付いていたユーザのID */
  app_user_active_id?: Maybe<Scalars['uuid']>;
  /** 申請者のapp_user_active_id */
  applicant_app_user_active_id?: Maybe<Scalars['uuid']>;
  /** 申請時に渡される会社ID */
  company_id?: Maybe<Scalars['uuid']>;
  /** 追加する会社の名前 */
  company_name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  /** 申請時のものを記録として残す。ユーザの登録メールアドレスには追従させない(外部キーcascadeとはしない)。 */
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** 申請の状態。PENDING、ACCEPTED_now()、DECLINED_now()の３種類。ACCEPTEDとDECLINEDは_nowサフィックスをつけて複数保持可能とする。 */
  status?: Maybe<Scalars['String']>;
  /** 招待時に紐付けるユーザ圃場グループ。ユーザ属性によってNULLの場合や必要な場合がある。チェックはビジネスロジックで行う。 */
  ufield_group_id?: Maybe<Scalars['uuid']>;
  /** ユーザ属性情報 */
  user_attribute?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_Min_Order_By = {
  /** 申請時に渡されたメールアドレスに紐付いていたユーザのID */
  app_user_active_id?: InputMaybe<Order_By>;
  /** 申請者のapp_user_active_id */
  applicant_app_user_active_id?: InputMaybe<Order_By>;
  /** 申請時に渡される会社ID */
  company_id?: InputMaybe<Order_By>;
  /** 追加する会社の名前 */
  company_name?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  /** 申請時のものを記録として残す。ユーザの登録メールアドレスには追従させない(外部キーcascadeとはしない)。 */
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** 申請の状態。PENDING、ACCEPTED_now()、DECLINED_now()の３種類。ACCEPTEDとDECLINEDは_nowサフィックスをつけて複数保持可能とする。 */
  status?: InputMaybe<Order_By>;
  /** 招待時に紐付けるユーザ圃場グループ。ユーザ属性によってNULLの場合や必要な場合がある。チェックはビジネスロジックで行う。 */
  ufield_group_id?: InputMaybe<Order_By>;
  /** ユーザ属性情報 */
  user_attribute?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_Mutation_Response = {
  __typename?: 'application_user_invitation_to_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Application_User_Invitation_To_Company>;
};

/** on_conflict condition type for table "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_On_Conflict = {
  constraint: Application_User_Invitation_To_Company_Constraint;
  update_columns?: Array<Application_User_Invitation_To_Company_Update_Column>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "application_user_invitation_to_company". */
export type Application_User_Invitation_To_Company_Order_By = {
  appUserActiveByApplicantAppUserActiveId?: InputMaybe<App_User_Active_Order_By>;
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  app_user_active_id?: InputMaybe<Order_By>;
  applicant_app_user_active_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Order_By>;
  nbpm_user_attribute?: InputMaybe<Nbpm_User_Attribute_Order_By>;
  status?: InputMaybe<Order_By>;
  ufield_group_id?: InputMaybe<Order_By>;
  user_attribute?: InputMaybe<Order_By>;
};

/** primary key columns input for table: application_user_invitation_to_company */
export type Application_User_Invitation_To_Company_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "application_user_invitation_to_company" */
export enum Application_User_Invitation_To_Company_Select_Column {
  /** column name */
  AppUserActiveId = 'app_user_active_id',
  /** column name */
  ApplicantAppUserActiveId = 'applicant_app_user_active_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  Created = 'created',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UfieldGroupId = 'ufield_group_id',
  /** column name */
  UserAttribute = 'user_attribute'
}

/** input type for updating data in table "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_Set_Input = {
  /** 申請時に渡されたメールアドレスに紐付いていたユーザのID */
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** 申請者のapp_user_active_id */
  applicant_app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** 申請時に渡される会社ID */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** 追加する会社の名前 */
  company_name?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 申請時のものを記録として残す。ユーザの登録メールアドレスには追従させない(外部キーcascadeとはしない)。 */
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** 申請の状態。PENDING、ACCEPTED_now()、DECLINED_now()の３種類。ACCEPTEDとDECLINEDは_nowサフィックスをつけて複数保持可能とする。 */
  status?: InputMaybe<Scalars['String']>;
  /** 招待時に紐付けるユーザ圃場グループ。ユーザ属性によってNULLの場合や必要な場合がある。チェックはビジネスロジックで行う。 */
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  /** ユーザ属性情報 */
  user_attribute?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "application_user_invitation_to_company" */
export type Application_User_Invitation_To_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Application_User_Invitation_To_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Application_User_Invitation_To_Company_Stream_Cursor_Value_Input = {
  /** 申請時に渡されたメールアドレスに紐付いていたユーザのID */
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** 申請者のapp_user_active_id */
  applicant_app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** 申請時に渡される会社ID */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** 追加する会社の名前 */
  company_name?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 申請時のものを記録として残す。ユーザの登録メールアドレスには追従させない(外部キーcascadeとはしない)。 */
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** 申請の状態。PENDING、ACCEPTED_now()、DECLINED_now()の３種類。ACCEPTEDとDECLINEDは_nowサフィックスをつけて複数保持可能とする。 */
  status?: InputMaybe<Scalars['String']>;
  /** 招待時に紐付けるユーザ圃場グループ。ユーザ属性によってNULLの場合や必要な場合がある。チェックはビジネスロジックで行う。 */
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  /** ユーザ属性情報 */
  user_attribute?: InputMaybe<Scalars['String']>;
};

/** update columns of table "application_user_invitation_to_company" */
export enum Application_User_Invitation_To_Company_Update_Column {
  /** column name */
  AppUserActiveId = 'app_user_active_id',
  /** column name */
  ApplicantAppUserActiveId = 'applicant_app_user_active_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  Created = 'created',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UfieldGroupId = 'ufield_group_id',
  /** column name */
  UserAttribute = 'user_attribute'
}

export type Application_User_Invitation_To_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Application_User_Invitation_To_Company_Set_Input>;
  where: Application_User_Invitation_To_Company_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']>;
  _gt?: InputMaybe<Scalars['geography']>;
  _gte?: InputMaybe<Scalars['geography']>;
  _in?: InputMaybe<Array<Scalars['geography']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geography']>;
  _lte?: InputMaybe<Scalars['geography']>;
  _neq?: InputMaybe<Scalars['geography']>;
  _nin?: InputMaybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']>;
  _gt?: InputMaybe<Scalars['geometry']>;
  _gte?: InputMaybe<Scalars['geometry']>;
  _in?: InputMaybe<Array<Scalars['geometry']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geometry']>;
  _lte?: InputMaybe<Scalars['geometry']>;
  _neq?: InputMaybe<Scalars['geometry']>;
  _nin?: InputMaybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export type M2mRequestedIpeCommand = {
  __typename?: 'm2mRequestedIpeCommand';
  command: Scalars['JSON'];
  id: Scalars['String'];
  status: Scalars['String'];
};

export type M2mSuccess = {
  __typename?: 'm2mSuccess';
  message: Scalars['String'];
};

/** 地域メッシュテーブル。総務省が定めた日本国内の場所ごとのコード。https://www.stat.go.jp/data/mesh/m_tuite.html */
export type Master_Nb_Area_Mesh = {
  __typename?: 'master_nb_area_mesh';
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code: Scalars['bigint'];
  /** An array relationship */
  nb_mfields: Array<Master_Nb_Mfield>;
  /** An aggregate relationship */
  nb_mfields_aggregate: Master_Nb_Mfield_Aggregate;
  /** An array relationship */
  nb_weather_data: Array<Master_Nb_Weather_Data>;
  /** An aggregate relationship */
  nb_weather_data_aggregate: Master_Nb_Weather_Data_Aggregate;
};


/** 地域メッシュテーブル。総務省が定めた日本国内の場所ごとのコード。https://www.stat.go.jp/data/mesh/m_tuite.html */
export type Master_Nb_Area_MeshNb_MfieldsArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};


/** 地域メッシュテーブル。総務省が定めた日本国内の場所ごとのコード。https://www.stat.go.jp/data/mesh/m_tuite.html */
export type Master_Nb_Area_MeshNb_Mfields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};


/** 地域メッシュテーブル。総務省が定めた日本国内の場所ごとのコード。https://www.stat.go.jp/data/mesh/m_tuite.html */
export type Master_Nb_Area_MeshNb_Weather_DataArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
};


/** 地域メッシュテーブル。総務省が定めた日本国内の場所ごとのコード。https://www.stat.go.jp/data/mesh/m_tuite.html */
export type Master_Nb_Area_MeshNb_Weather_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
};

/** aggregated selection of "master.nb_area_mesh" */
export type Master_Nb_Area_Mesh_Aggregate = {
  __typename?: 'master_nb_area_mesh_aggregate';
  aggregate?: Maybe<Master_Nb_Area_Mesh_Aggregate_Fields>;
  nodes: Array<Master_Nb_Area_Mesh>;
};

/** aggregate fields of "master.nb_area_mesh" */
export type Master_Nb_Area_Mesh_Aggregate_Fields = {
  __typename?: 'master_nb_area_mesh_aggregate_fields';
  avg?: Maybe<Master_Nb_Area_Mesh_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Master_Nb_Area_Mesh_Max_Fields>;
  min?: Maybe<Master_Nb_Area_Mesh_Min_Fields>;
  stddev?: Maybe<Master_Nb_Area_Mesh_Stddev_Fields>;
  stddev_pop?: Maybe<Master_Nb_Area_Mesh_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Master_Nb_Area_Mesh_Stddev_Samp_Fields>;
  sum?: Maybe<Master_Nb_Area_Mesh_Sum_Fields>;
  var_pop?: Maybe<Master_Nb_Area_Mesh_Var_Pop_Fields>;
  var_samp?: Maybe<Master_Nb_Area_Mesh_Var_Samp_Fields>;
  variance?: Maybe<Master_Nb_Area_Mesh_Variance_Fields>;
};


/** aggregate fields of "master.nb_area_mesh" */
export type Master_Nb_Area_Mesh_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Nb_Area_Mesh_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Master_Nb_Area_Mesh_Avg_Fields = {
  __typename?: 'master_nb_area_mesh_avg_fields';
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "master.nb_area_mesh". All fields are combined with a logical 'AND'. */
export type Master_Nb_Area_Mesh_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Nb_Area_Mesh_Bool_Exp>>;
  _not?: InputMaybe<Master_Nb_Area_Mesh_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Nb_Area_Mesh_Bool_Exp>>;
  code?: InputMaybe<Bigint_Comparison_Exp>;
  nb_mfields?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
  nb_mfields_aggregate?: InputMaybe<Master_Nb_Mfield_Aggregate_Bool_Exp>;
  nb_weather_data?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
  nb_weather_data_aggregate?: InputMaybe<Master_Nb_Weather_Data_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "master.nb_area_mesh" */
export enum Master_Nb_Area_Mesh_Constraint {
  /** unique or primary key constraint on columns "code" */
  NbAreaMeshPkey = 'nb_area_mesh_pkey'
}

/** input type for incrementing numeric columns in table "master.nb_area_mesh" */
export type Master_Nb_Area_Mesh_Inc_Input = {
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "master.nb_area_mesh" */
export type Master_Nb_Area_Mesh_Insert_Input = {
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: InputMaybe<Scalars['bigint']>;
  nb_mfields?: InputMaybe<Master_Nb_Mfield_Arr_Rel_Insert_Input>;
  nb_weather_data?: InputMaybe<Master_Nb_Weather_Data_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Master_Nb_Area_Mesh_Max_Fields = {
  __typename?: 'master_nb_area_mesh_max_fields';
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Master_Nb_Area_Mesh_Min_Fields = {
  __typename?: 'master_nb_area_mesh_min_fields';
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "master.nb_area_mesh" */
export type Master_Nb_Area_Mesh_Mutation_Response = {
  __typename?: 'master_nb_area_mesh_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Nb_Area_Mesh>;
};

/** input type for inserting object relation for remote table "master.nb_area_mesh" */
export type Master_Nb_Area_Mesh_Obj_Rel_Insert_Input = {
  data: Master_Nb_Area_Mesh_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Nb_Area_Mesh_On_Conflict>;
};

/** on_conflict condition type for table "master.nb_area_mesh" */
export type Master_Nb_Area_Mesh_On_Conflict = {
  constraint: Master_Nb_Area_Mesh_Constraint;
  update_columns?: Array<Master_Nb_Area_Mesh_Update_Column>;
  where?: InputMaybe<Master_Nb_Area_Mesh_Bool_Exp>;
};

/** Ordering options when selecting data from "master.nb_area_mesh". */
export type Master_Nb_Area_Mesh_Order_By = {
  code?: InputMaybe<Order_By>;
  nb_mfields_aggregate?: InputMaybe<Master_Nb_Mfield_Aggregate_Order_By>;
  nb_weather_data_aggregate?: InputMaybe<Master_Nb_Weather_Data_Aggregate_Order_By>;
};

/** primary key columns input for table: master.nb_area_mesh */
export type Master_Nb_Area_Mesh_Pk_Columns_Input = {
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code: Scalars['bigint'];
};

/** select columns of table "master.nb_area_mesh" */
export enum Master_Nb_Area_Mesh_Select_Column {
  /** column name */
  Code = 'code'
}

/** input type for updating data in table "master.nb_area_mesh" */
export type Master_Nb_Area_Mesh_Set_Input = {
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Master_Nb_Area_Mesh_Stddev_Fields = {
  __typename?: 'master_nb_area_mesh_stddev_fields';
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Master_Nb_Area_Mesh_Stddev_Pop_Fields = {
  __typename?: 'master_nb_area_mesh_stddev_pop_fields';
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Master_Nb_Area_Mesh_Stddev_Samp_Fields = {
  __typename?: 'master_nb_area_mesh_stddev_samp_fields';
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "master_nb_area_mesh" */
export type Master_Nb_Area_Mesh_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Nb_Area_Mesh_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Nb_Area_Mesh_Stream_Cursor_Value_Input = {
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Master_Nb_Area_Mesh_Sum_Fields = {
  __typename?: 'master_nb_area_mesh_sum_fields';
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: Maybe<Scalars['bigint']>;
};

/** update columns of table "master.nb_area_mesh" */
export enum Master_Nb_Area_Mesh_Update_Column {
  /** column name */
  Code = 'code'
}

export type Master_Nb_Area_Mesh_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Master_Nb_Area_Mesh_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Nb_Area_Mesh_Set_Input>;
  where: Master_Nb_Area_Mesh_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Master_Nb_Area_Mesh_Var_Pop_Fields = {
  __typename?: 'master_nb_area_mesh_var_pop_fields';
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Master_Nb_Area_Mesh_Var_Samp_Fields = {
  __typename?: 'master_nb_area_mesh_var_samp_fields';
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Master_Nb_Area_Mesh_Variance_Fields = {
  __typename?: 'master_nb_area_mesh_variance_fields';
  /** 地域メッシュコードで３次５次(1/4地域メッシュ)など必要なものを格納する */
  code?: Maybe<Scalars['Float']>;
};

/** 統合農地データ。統合農地データ提供APIで取得したデータ。 */
export type Master_Nb_Integrated_Farmland = {
  __typename?: 'master_nb_integrated_farmland';
  city_code: Scalars['Int'];
  edit_year: Scalars['smallint'];
  field_type: Scalars['String'];
  geometry: Scalars['geometry'];
  history: Scalars['jsonb'];
  issue_year: Scalars['smallint'];
  /** 筆ポリゴンのIDでmfield.keyと同じ値 */
  key: Scalars['uuid'];
  last_polygon_uuid?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  nb_mfield: Master_Nb_Mfield;
  number: Scalars['Int'];
  pin_info: Scalars['jsonb'];
  point_lat: Scalars['float8'];
  point_lon: Scalars['float8'];
  prev_last_polygon_uuid?: Maybe<Scalars['uuid']>;
  soil_large_code?: Maybe<Scalars['_bpchar']>;
  soil_middle_code?: Maybe<Scalars['_bpchar']>;
  soil_small_code?: Maybe<Scalars['_bpchar']>;
  soil_small_name?: Maybe<Scalars['String']>;
};


/** 統合農地データ。統合農地データ提供APIで取得したデータ。 */
export type Master_Nb_Integrated_FarmlandHistoryArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** 統合農地データ。統合農地データ提供APIで取得したデータ。 */
export type Master_Nb_Integrated_FarmlandPin_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "master.nb_integrated_farmland" */
export type Master_Nb_Integrated_Farmland_Aggregate = {
  __typename?: 'master_nb_integrated_farmland_aggregate';
  aggregate?: Maybe<Master_Nb_Integrated_Farmland_Aggregate_Fields>;
  nodes: Array<Master_Nb_Integrated_Farmland>;
};

/** aggregate fields of "master.nb_integrated_farmland" */
export type Master_Nb_Integrated_Farmland_Aggregate_Fields = {
  __typename?: 'master_nb_integrated_farmland_aggregate_fields';
  avg?: Maybe<Master_Nb_Integrated_Farmland_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Master_Nb_Integrated_Farmland_Max_Fields>;
  min?: Maybe<Master_Nb_Integrated_Farmland_Min_Fields>;
  stddev?: Maybe<Master_Nb_Integrated_Farmland_Stddev_Fields>;
  stddev_pop?: Maybe<Master_Nb_Integrated_Farmland_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Master_Nb_Integrated_Farmland_Stddev_Samp_Fields>;
  sum?: Maybe<Master_Nb_Integrated_Farmland_Sum_Fields>;
  var_pop?: Maybe<Master_Nb_Integrated_Farmland_Var_Pop_Fields>;
  var_samp?: Maybe<Master_Nb_Integrated_Farmland_Var_Samp_Fields>;
  variance?: Maybe<Master_Nb_Integrated_Farmland_Variance_Fields>;
};


/** aggregate fields of "master.nb_integrated_farmland" */
export type Master_Nb_Integrated_Farmland_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Nb_Integrated_Farmland_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Master_Nb_Integrated_Farmland_Append_Input = {
  history?: InputMaybe<Scalars['jsonb']>;
  pin_info?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Master_Nb_Integrated_Farmland_Avg_Fields = {
  __typename?: 'master_nb_integrated_farmland_avg_fields';
  city_code?: Maybe<Scalars['Float']>;
  edit_year?: Maybe<Scalars['Float']>;
  issue_year?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  point_lat?: Maybe<Scalars['Float']>;
  point_lon?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "master.nb_integrated_farmland". All fields are combined with a logical 'AND'. */
export type Master_Nb_Integrated_Farmland_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Nb_Integrated_Farmland_Bool_Exp>>;
  _not?: InputMaybe<Master_Nb_Integrated_Farmland_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Nb_Integrated_Farmland_Bool_Exp>>;
  city_code?: InputMaybe<Int_Comparison_Exp>;
  edit_year?: InputMaybe<Smallint_Comparison_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
  history?: InputMaybe<Jsonb_Comparison_Exp>;
  issue_year?: InputMaybe<Smallint_Comparison_Exp>;
  key?: InputMaybe<Uuid_Comparison_Exp>;
  last_polygon_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
  number?: InputMaybe<Int_Comparison_Exp>;
  pin_info?: InputMaybe<Jsonb_Comparison_Exp>;
  point_lat?: InputMaybe<Float8_Comparison_Exp>;
  point_lon?: InputMaybe<Float8_Comparison_Exp>;
  prev_last_polygon_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  soil_large_code?: InputMaybe<_Bpchar_Comparison_Exp>;
  soil_middle_code?: InputMaybe<_Bpchar_Comparison_Exp>;
  soil_small_code?: InputMaybe<_Bpchar_Comparison_Exp>;
  soil_small_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "master.nb_integrated_farmland" */
export enum Master_Nb_Integrated_Farmland_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbIntegratedFarmlandPkey = 'nb_integrated_farmland_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Master_Nb_Integrated_Farmland_Delete_At_Path_Input = {
  history?: InputMaybe<Array<Scalars['String']>>;
  pin_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Master_Nb_Integrated_Farmland_Delete_Elem_Input = {
  history?: InputMaybe<Scalars['Int']>;
  pin_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Master_Nb_Integrated_Farmland_Delete_Key_Input = {
  history?: InputMaybe<Scalars['String']>;
  pin_info?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "master.nb_integrated_farmland" */
export type Master_Nb_Integrated_Farmland_Inc_Input = {
  city_code?: InputMaybe<Scalars['Int']>;
  edit_year?: InputMaybe<Scalars['smallint']>;
  issue_year?: InputMaybe<Scalars['smallint']>;
  number?: InputMaybe<Scalars['Int']>;
  point_lat?: InputMaybe<Scalars['float8']>;
  point_lon?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "master.nb_integrated_farmland" */
export type Master_Nb_Integrated_Farmland_Insert_Input = {
  city_code?: InputMaybe<Scalars['Int']>;
  edit_year?: InputMaybe<Scalars['smallint']>;
  field_type?: InputMaybe<Scalars['String']>;
  geometry?: InputMaybe<Scalars['geometry']>;
  history?: InputMaybe<Scalars['jsonb']>;
  issue_year?: InputMaybe<Scalars['smallint']>;
  /** 筆ポリゴンのIDでmfield.keyと同じ値 */
  key?: InputMaybe<Scalars['uuid']>;
  last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Obj_Rel_Insert_Input>;
  number?: InputMaybe<Scalars['Int']>;
  pin_info?: InputMaybe<Scalars['jsonb']>;
  point_lat?: InputMaybe<Scalars['float8']>;
  point_lon?: InputMaybe<Scalars['float8']>;
  prev_last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  soil_large_code?: InputMaybe<Scalars['_bpchar']>;
  soil_middle_code?: InputMaybe<Scalars['_bpchar']>;
  soil_small_code?: InputMaybe<Scalars['_bpchar']>;
  soil_small_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Master_Nb_Integrated_Farmland_Max_Fields = {
  __typename?: 'master_nb_integrated_farmland_max_fields';
  city_code?: Maybe<Scalars['Int']>;
  edit_year?: Maybe<Scalars['smallint']>;
  field_type?: Maybe<Scalars['String']>;
  issue_year?: Maybe<Scalars['smallint']>;
  /** 筆ポリゴンのIDでmfield.keyと同じ値 */
  key?: Maybe<Scalars['uuid']>;
  last_polygon_uuid?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  point_lat?: Maybe<Scalars['float8']>;
  point_lon?: Maybe<Scalars['float8']>;
  prev_last_polygon_uuid?: Maybe<Scalars['uuid']>;
  soil_small_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Master_Nb_Integrated_Farmland_Min_Fields = {
  __typename?: 'master_nb_integrated_farmland_min_fields';
  city_code?: Maybe<Scalars['Int']>;
  edit_year?: Maybe<Scalars['smallint']>;
  field_type?: Maybe<Scalars['String']>;
  issue_year?: Maybe<Scalars['smallint']>;
  /** 筆ポリゴンのIDでmfield.keyと同じ値 */
  key?: Maybe<Scalars['uuid']>;
  last_polygon_uuid?: Maybe<Scalars['uuid']>;
  number?: Maybe<Scalars['Int']>;
  point_lat?: Maybe<Scalars['float8']>;
  point_lon?: Maybe<Scalars['float8']>;
  prev_last_polygon_uuid?: Maybe<Scalars['uuid']>;
  soil_small_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "master.nb_integrated_farmland" */
export type Master_Nb_Integrated_Farmland_Mutation_Response = {
  __typename?: 'master_nb_integrated_farmland_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Nb_Integrated_Farmland>;
};

/** input type for inserting object relation for remote table "master.nb_integrated_farmland" */
export type Master_Nb_Integrated_Farmland_Obj_Rel_Insert_Input = {
  data: Master_Nb_Integrated_Farmland_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Nb_Integrated_Farmland_On_Conflict>;
};

/** on_conflict condition type for table "master.nb_integrated_farmland" */
export type Master_Nb_Integrated_Farmland_On_Conflict = {
  constraint: Master_Nb_Integrated_Farmland_Constraint;
  update_columns?: Array<Master_Nb_Integrated_Farmland_Update_Column>;
  where?: InputMaybe<Master_Nb_Integrated_Farmland_Bool_Exp>;
};

/** Ordering options when selecting data from "master.nb_integrated_farmland". */
export type Master_Nb_Integrated_Farmland_Order_By = {
  city_code?: InputMaybe<Order_By>;
  edit_year?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  history?: InputMaybe<Order_By>;
  issue_year?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  last_polygon_uuid?: InputMaybe<Order_By>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Order_By>;
  number?: InputMaybe<Order_By>;
  pin_info?: InputMaybe<Order_By>;
  point_lat?: InputMaybe<Order_By>;
  point_lon?: InputMaybe<Order_By>;
  prev_last_polygon_uuid?: InputMaybe<Order_By>;
  soil_large_code?: InputMaybe<Order_By>;
  soil_middle_code?: InputMaybe<Order_By>;
  soil_small_code?: InputMaybe<Order_By>;
  soil_small_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: master.nb_integrated_farmland */
export type Master_Nb_Integrated_Farmland_Pk_Columns_Input = {
  /** 筆ポリゴンのIDでmfield.keyと同じ値 */
  key: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Master_Nb_Integrated_Farmland_Prepend_Input = {
  history?: InputMaybe<Scalars['jsonb']>;
  pin_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "master.nb_integrated_farmland" */
export enum Master_Nb_Integrated_Farmland_Select_Column {
  /** column name */
  CityCode = 'city_code',
  /** column name */
  EditYear = 'edit_year',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  Geometry = 'geometry',
  /** column name */
  History = 'history',
  /** column name */
  IssueYear = 'issue_year',
  /** column name */
  Key = 'key',
  /** column name */
  LastPolygonUuid = 'last_polygon_uuid',
  /** column name */
  Number = 'number',
  /** column name */
  PinInfo = 'pin_info',
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLon = 'point_lon',
  /** column name */
  PrevLastPolygonUuid = 'prev_last_polygon_uuid',
  /** column name */
  SoilLargeCode = 'soil_large_code',
  /** column name */
  SoilMiddleCode = 'soil_middle_code',
  /** column name */
  SoilSmallCode = 'soil_small_code',
  /** column name */
  SoilSmallName = 'soil_small_name'
}

/** input type for updating data in table "master.nb_integrated_farmland" */
export type Master_Nb_Integrated_Farmland_Set_Input = {
  city_code?: InputMaybe<Scalars['Int']>;
  edit_year?: InputMaybe<Scalars['smallint']>;
  field_type?: InputMaybe<Scalars['String']>;
  geometry?: InputMaybe<Scalars['geometry']>;
  history?: InputMaybe<Scalars['jsonb']>;
  issue_year?: InputMaybe<Scalars['smallint']>;
  /** 筆ポリゴンのIDでmfield.keyと同じ値 */
  key?: InputMaybe<Scalars['uuid']>;
  last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  number?: InputMaybe<Scalars['Int']>;
  pin_info?: InputMaybe<Scalars['jsonb']>;
  point_lat?: InputMaybe<Scalars['float8']>;
  point_lon?: InputMaybe<Scalars['float8']>;
  prev_last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  soil_large_code?: InputMaybe<Scalars['_bpchar']>;
  soil_middle_code?: InputMaybe<Scalars['_bpchar']>;
  soil_small_code?: InputMaybe<Scalars['_bpchar']>;
  soil_small_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Master_Nb_Integrated_Farmland_Stddev_Fields = {
  __typename?: 'master_nb_integrated_farmland_stddev_fields';
  city_code?: Maybe<Scalars['Float']>;
  edit_year?: Maybe<Scalars['Float']>;
  issue_year?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  point_lat?: Maybe<Scalars['Float']>;
  point_lon?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Master_Nb_Integrated_Farmland_Stddev_Pop_Fields = {
  __typename?: 'master_nb_integrated_farmland_stddev_pop_fields';
  city_code?: Maybe<Scalars['Float']>;
  edit_year?: Maybe<Scalars['Float']>;
  issue_year?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  point_lat?: Maybe<Scalars['Float']>;
  point_lon?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Master_Nb_Integrated_Farmland_Stddev_Samp_Fields = {
  __typename?: 'master_nb_integrated_farmland_stddev_samp_fields';
  city_code?: Maybe<Scalars['Float']>;
  edit_year?: Maybe<Scalars['Float']>;
  issue_year?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  point_lat?: Maybe<Scalars['Float']>;
  point_lon?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "master_nb_integrated_farmland" */
export type Master_Nb_Integrated_Farmland_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Nb_Integrated_Farmland_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Nb_Integrated_Farmland_Stream_Cursor_Value_Input = {
  city_code?: InputMaybe<Scalars['Int']>;
  edit_year?: InputMaybe<Scalars['smallint']>;
  field_type?: InputMaybe<Scalars['String']>;
  geometry?: InputMaybe<Scalars['geometry']>;
  history?: InputMaybe<Scalars['jsonb']>;
  issue_year?: InputMaybe<Scalars['smallint']>;
  /** 筆ポリゴンのIDでmfield.keyと同じ値 */
  key?: InputMaybe<Scalars['uuid']>;
  last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  number?: InputMaybe<Scalars['Int']>;
  pin_info?: InputMaybe<Scalars['jsonb']>;
  point_lat?: InputMaybe<Scalars['float8']>;
  point_lon?: InputMaybe<Scalars['float8']>;
  prev_last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  soil_large_code?: InputMaybe<Scalars['_bpchar']>;
  soil_middle_code?: InputMaybe<Scalars['_bpchar']>;
  soil_small_code?: InputMaybe<Scalars['_bpchar']>;
  soil_small_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Master_Nb_Integrated_Farmland_Sum_Fields = {
  __typename?: 'master_nb_integrated_farmland_sum_fields';
  city_code?: Maybe<Scalars['Int']>;
  edit_year?: Maybe<Scalars['smallint']>;
  issue_year?: Maybe<Scalars['smallint']>;
  number?: Maybe<Scalars['Int']>;
  point_lat?: Maybe<Scalars['float8']>;
  point_lon?: Maybe<Scalars['float8']>;
};

/** update columns of table "master.nb_integrated_farmland" */
export enum Master_Nb_Integrated_Farmland_Update_Column {
  /** column name */
  CityCode = 'city_code',
  /** column name */
  EditYear = 'edit_year',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  Geometry = 'geometry',
  /** column name */
  History = 'history',
  /** column name */
  IssueYear = 'issue_year',
  /** column name */
  Key = 'key',
  /** column name */
  LastPolygonUuid = 'last_polygon_uuid',
  /** column name */
  Number = 'number',
  /** column name */
  PinInfo = 'pin_info',
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLon = 'point_lon',
  /** column name */
  PrevLastPolygonUuid = 'prev_last_polygon_uuid',
  /** column name */
  SoilLargeCode = 'soil_large_code',
  /** column name */
  SoilMiddleCode = 'soil_middle_code',
  /** column name */
  SoilSmallCode = 'soil_small_code',
  /** column name */
  SoilSmallName = 'soil_small_name'
}

export type Master_Nb_Integrated_Farmland_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Master_Nb_Integrated_Farmland_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Master_Nb_Integrated_Farmland_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Master_Nb_Integrated_Farmland_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Master_Nb_Integrated_Farmland_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Master_Nb_Integrated_Farmland_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Master_Nb_Integrated_Farmland_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Nb_Integrated_Farmland_Set_Input>;
  where: Master_Nb_Integrated_Farmland_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Master_Nb_Integrated_Farmland_Var_Pop_Fields = {
  __typename?: 'master_nb_integrated_farmland_var_pop_fields';
  city_code?: Maybe<Scalars['Float']>;
  edit_year?: Maybe<Scalars['Float']>;
  issue_year?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  point_lat?: Maybe<Scalars['Float']>;
  point_lon?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Master_Nb_Integrated_Farmland_Var_Samp_Fields = {
  __typename?: 'master_nb_integrated_farmland_var_samp_fields';
  city_code?: Maybe<Scalars['Float']>;
  edit_year?: Maybe<Scalars['Float']>;
  issue_year?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  point_lat?: Maybe<Scalars['Float']>;
  point_lon?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Master_Nb_Integrated_Farmland_Variance_Fields = {
  __typename?: 'master_nb_integrated_farmland_variance_fields';
  city_code?: Maybe<Scalars['Float']>;
  edit_year?: Maybe<Scalars['Float']>;
  issue_year?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  point_lat?: Maybe<Scalars['Float']>;
  point_lon?: Maybe<Scalars['Float']>;
};

/** 筆ポリゴンのpolygon_uuidを管理しユーザ圃場生成や、データ取得時の起点となるテーブル。データ数が1年で3000万件程度と多いため、ポリゴンデータそのものは保持しない。 */
export type Master_Nb_Mfield = {
  __typename?: 'master_nb_mfield';
  area_mesh_code_3rd: Scalars['bigint'];
  area_mesh_code_5th: Scalars['bigint'];
  key: Scalars['uuid'];
  /** An object relationship */
  nb_area_mesh: Master_Nb_Area_Mesh;
  /** An object relationship */
  nb_integrated_farmland?: Maybe<Master_Nb_Integrated_Farmland>;
  /** An array relationship */
  nb_mfield_links: Array<Nb_Mfield_Link>;
  /** An aggregate relationship */
  nb_mfield_links_aggregate: Nb_Mfield_Link_Aggregate;
  /** An object relationship */
  nb_mfield_polygon_2022_1?: Maybe<Master_Nb_Mfield_Polygon_2022_1>;
  /** An object relationship */
  nb_mfield_polygon_2023_1?: Maybe<Master_Nb_Mfield_Polygon_2023_1>;
  /** An object relationship */
  nb_mfield_polygon_2024_1?: Maybe<Master_Nb_Mfield_Polygon_2024_1>;
  /** An object relationship */
  nb_mfield_version: Master_Nb_Mfield_Version;
  valid_end: Scalars['timestamptz'];
  version_id: Scalars['smallint'];
};


/** 筆ポリゴンのpolygon_uuidを管理しユーザ圃場生成や、データ取得時の起点となるテーブル。データ数が1年で3000万件程度と多いため、ポリゴンデータそのものは保持しない。 */
export type Master_Nb_MfieldNb_Mfield_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Link_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};


/** 筆ポリゴンのpolygon_uuidを管理しユーザ圃場生成や、データ取得時の起点となるテーブル。データ数が1年で3000万件程度と多いため、ポリゴンデータそのものは保持しない。 */
export type Master_Nb_MfieldNb_Mfield_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Link_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};

/** aggregated selection of "master.nb_mfield" */
export type Master_Nb_Mfield_Aggregate = {
  __typename?: 'master_nb_mfield_aggregate';
  aggregate?: Maybe<Master_Nb_Mfield_Aggregate_Fields>;
  nodes: Array<Master_Nb_Mfield>;
};

export type Master_Nb_Mfield_Aggregate_Bool_Exp = {
  count?: InputMaybe<Master_Nb_Mfield_Aggregate_Bool_Exp_Count>;
};

export type Master_Nb_Mfield_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Master_Nb_Mfield_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "master.nb_mfield" */
export type Master_Nb_Mfield_Aggregate_Fields = {
  __typename?: 'master_nb_mfield_aggregate_fields';
  avg?: Maybe<Master_Nb_Mfield_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Master_Nb_Mfield_Max_Fields>;
  min?: Maybe<Master_Nb_Mfield_Min_Fields>;
  stddev?: Maybe<Master_Nb_Mfield_Stddev_Fields>;
  stddev_pop?: Maybe<Master_Nb_Mfield_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Master_Nb_Mfield_Stddev_Samp_Fields>;
  sum?: Maybe<Master_Nb_Mfield_Sum_Fields>;
  var_pop?: Maybe<Master_Nb_Mfield_Var_Pop_Fields>;
  var_samp?: Maybe<Master_Nb_Mfield_Var_Samp_Fields>;
  variance?: Maybe<Master_Nb_Mfield_Variance_Fields>;
};


/** aggregate fields of "master.nb_mfield" */
export type Master_Nb_Mfield_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Nb_Mfield_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "master.nb_mfield" */
export type Master_Nb_Mfield_Aggregate_Order_By = {
  avg?: InputMaybe<Master_Nb_Mfield_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Master_Nb_Mfield_Max_Order_By>;
  min?: InputMaybe<Master_Nb_Mfield_Min_Order_By>;
  stddev?: InputMaybe<Master_Nb_Mfield_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Master_Nb_Mfield_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Master_Nb_Mfield_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Master_Nb_Mfield_Sum_Order_By>;
  var_pop?: InputMaybe<Master_Nb_Mfield_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Master_Nb_Mfield_Var_Samp_Order_By>;
  variance?: InputMaybe<Master_Nb_Mfield_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "master.nb_mfield" */
export type Master_Nb_Mfield_Arr_Rel_Insert_Input = {
  data: Array<Master_Nb_Mfield_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Nb_Mfield_On_Conflict>;
};

/** aggregate avg on columns */
export type Master_Nb_Mfield_Avg_Fields = {
  __typename?: 'master_nb_mfield_avg_fields';
  area_mesh_code_3rd?: Maybe<Scalars['Float']>;
  area_mesh_code_5th?: Maybe<Scalars['Float']>;
  version_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "master.nb_mfield" */
export type Master_Nb_Mfield_Avg_Order_By = {
  area_mesh_code_3rd?: InputMaybe<Order_By>;
  area_mesh_code_5th?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "master.nb_mfield". All fields are combined with a logical 'AND'. */
export type Master_Nb_Mfield_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Nb_Mfield_Bool_Exp>>;
  _not?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Nb_Mfield_Bool_Exp>>;
  area_mesh_code_3rd?: InputMaybe<Bigint_Comparison_Exp>;
  area_mesh_code_5th?: InputMaybe<Bigint_Comparison_Exp>;
  key?: InputMaybe<Uuid_Comparison_Exp>;
  nb_area_mesh?: InputMaybe<Master_Nb_Area_Mesh_Bool_Exp>;
  nb_integrated_farmland?: InputMaybe<Master_Nb_Integrated_Farmland_Bool_Exp>;
  nb_mfield_links?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
  nb_mfield_links_aggregate?: InputMaybe<Nb_Mfield_Link_Aggregate_Bool_Exp>;
  nb_mfield_polygon_2022_1?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Bool_Exp>;
  nb_mfield_polygon_2023_1?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Bool_Exp>;
  nb_mfield_polygon_2024_1?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Bool_Exp>;
  nb_mfield_version?: InputMaybe<Master_Nb_Mfield_Version_Bool_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  version_id?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "master.nb_mfield" */
export enum Master_Nb_Mfield_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbMfieldPkey = 'nb_mfield_pkey'
}

/** input type for incrementing numeric columns in table "master.nb_mfield" */
export type Master_Nb_Mfield_Inc_Input = {
  area_mesh_code_3rd?: InputMaybe<Scalars['bigint']>;
  area_mesh_code_5th?: InputMaybe<Scalars['bigint']>;
  version_id?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "master.nb_mfield" */
export type Master_Nb_Mfield_Insert_Input = {
  area_mesh_code_3rd?: InputMaybe<Scalars['bigint']>;
  area_mesh_code_5th?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['uuid']>;
  nb_area_mesh?: InputMaybe<Master_Nb_Area_Mesh_Obj_Rel_Insert_Input>;
  nb_integrated_farmland?: InputMaybe<Master_Nb_Integrated_Farmland_Obj_Rel_Insert_Input>;
  nb_mfield_links?: InputMaybe<Nb_Mfield_Link_Arr_Rel_Insert_Input>;
  nb_mfield_polygon_2022_1?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Obj_Rel_Insert_Input>;
  nb_mfield_polygon_2023_1?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Obj_Rel_Insert_Input>;
  nb_mfield_polygon_2024_1?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Obj_Rel_Insert_Input>;
  nb_mfield_version?: InputMaybe<Master_Nb_Mfield_Version_Obj_Rel_Insert_Input>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  version_id?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Master_Nb_Mfield_Max_Fields = {
  __typename?: 'master_nb_mfield_max_fields';
  area_mesh_code_3rd?: Maybe<Scalars['bigint']>;
  area_mesh_code_5th?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  version_id?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "master.nb_mfield" */
export type Master_Nb_Mfield_Max_Order_By = {
  area_mesh_code_3rd?: InputMaybe<Order_By>;
  area_mesh_code_5th?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Master_Nb_Mfield_Min_Fields = {
  __typename?: 'master_nb_mfield_min_fields';
  area_mesh_code_3rd?: Maybe<Scalars['bigint']>;
  area_mesh_code_5th?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  version_id?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "master.nb_mfield" */
export type Master_Nb_Mfield_Min_Order_By = {
  area_mesh_code_3rd?: InputMaybe<Order_By>;
  area_mesh_code_5th?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "master.nb_mfield" */
export type Master_Nb_Mfield_Mutation_Response = {
  __typename?: 'master_nb_mfield_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Nb_Mfield>;
};

/** input type for inserting object relation for remote table "master.nb_mfield" */
export type Master_Nb_Mfield_Obj_Rel_Insert_Input = {
  data: Master_Nb_Mfield_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Nb_Mfield_On_Conflict>;
};

/** on_conflict condition type for table "master.nb_mfield" */
export type Master_Nb_Mfield_On_Conflict = {
  constraint: Master_Nb_Mfield_Constraint;
  update_columns?: Array<Master_Nb_Mfield_Update_Column>;
  where?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};

/** Ordering options when selecting data from "master.nb_mfield". */
export type Master_Nb_Mfield_Order_By = {
  area_mesh_code_3rd?: InputMaybe<Order_By>;
  area_mesh_code_5th?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  nb_area_mesh?: InputMaybe<Master_Nb_Area_Mesh_Order_By>;
  nb_integrated_farmland?: InputMaybe<Master_Nb_Integrated_Farmland_Order_By>;
  nb_mfield_links_aggregate?: InputMaybe<Nb_Mfield_Link_Aggregate_Order_By>;
  nb_mfield_polygon_2022_1?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Order_By>;
  nb_mfield_polygon_2023_1?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Order_By>;
  nb_mfield_polygon_2024_1?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Order_By>;
  nb_mfield_version?: InputMaybe<Master_Nb_Mfield_Version_Order_By>;
  valid_end?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: master.nb_mfield */
export type Master_Nb_Mfield_Pk_Columns_Input = {
  key: Scalars['uuid'];
};

/** columns and relationships of "master.nb_mfield_polygon_2022_1" */
export type Master_Nb_Mfield_Polygon_2022_1 = {
  __typename?: 'master_nb_mfield_polygon_2022_1';
  geom: Scalars['geometry'];
  key: Scalars['uuid'];
  local_government_cd: Scalars['bpchar'];
  /** An object relationship */
  nb_mfield: Master_Nb_Mfield;
};

/** aggregated selection of "master.nb_mfield_polygon_2022_1" */
export type Master_Nb_Mfield_Polygon_2022_1_Aggregate = {
  __typename?: 'master_nb_mfield_polygon_2022_1_aggregate';
  aggregate?: Maybe<Master_Nb_Mfield_Polygon_2022_1_Aggregate_Fields>;
  nodes: Array<Master_Nb_Mfield_Polygon_2022_1>;
};

/** aggregate fields of "master.nb_mfield_polygon_2022_1" */
export type Master_Nb_Mfield_Polygon_2022_1_Aggregate_Fields = {
  __typename?: 'master_nb_mfield_polygon_2022_1_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Master_Nb_Mfield_Polygon_2022_1_Max_Fields>;
  min?: Maybe<Master_Nb_Mfield_Polygon_2022_1_Min_Fields>;
};


/** aggregate fields of "master.nb_mfield_polygon_2022_1" */
export type Master_Nb_Mfield_Polygon_2022_1_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2022_1_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "master.nb_mfield_polygon_2022_1". All fields are combined with a logical 'AND'. */
export type Master_Nb_Mfield_Polygon_2022_1_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2022_1_Bool_Exp>>;
  _not?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2022_1_Bool_Exp>>;
  geom?: InputMaybe<Geometry_Comparison_Exp>;
  key?: InputMaybe<Uuid_Comparison_Exp>;
  local_government_cd?: InputMaybe<Bpchar_Comparison_Exp>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};

/** unique or primary key constraints on table "master.nb_mfield_polygon_2022_1" */
export enum Master_Nb_Mfield_Polygon_2022_1_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbMfieldPolygon_2022_1Pkey = 'nb_mfield_polygon_2022_1_pkey'
}

/** input type for inserting data into table "master.nb_mfield_polygon_2022_1" */
export type Master_Nb_Mfield_Polygon_2022_1_Insert_Input = {
  geom?: InputMaybe<Scalars['geometry']>;
  key?: InputMaybe<Scalars['uuid']>;
  local_government_cd?: InputMaybe<Scalars['bpchar']>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Master_Nb_Mfield_Polygon_2022_1_Max_Fields = {
  __typename?: 'master_nb_mfield_polygon_2022_1_max_fields';
  key?: Maybe<Scalars['uuid']>;
  local_government_cd?: Maybe<Scalars['bpchar']>;
};

/** aggregate min on columns */
export type Master_Nb_Mfield_Polygon_2022_1_Min_Fields = {
  __typename?: 'master_nb_mfield_polygon_2022_1_min_fields';
  key?: Maybe<Scalars['uuid']>;
  local_government_cd?: Maybe<Scalars['bpchar']>;
};

/** response of any mutation on the table "master.nb_mfield_polygon_2022_1" */
export type Master_Nb_Mfield_Polygon_2022_1_Mutation_Response = {
  __typename?: 'master_nb_mfield_polygon_2022_1_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Nb_Mfield_Polygon_2022_1>;
};

/** input type for inserting object relation for remote table "master.nb_mfield_polygon_2022_1" */
export type Master_Nb_Mfield_Polygon_2022_1_Obj_Rel_Insert_Input = {
  data: Master_Nb_Mfield_Polygon_2022_1_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_On_Conflict>;
};

/** on_conflict condition type for table "master.nb_mfield_polygon_2022_1" */
export type Master_Nb_Mfield_Polygon_2022_1_On_Conflict = {
  constraint: Master_Nb_Mfield_Polygon_2022_1_Constraint;
  update_columns?: Array<Master_Nb_Mfield_Polygon_2022_1_Update_Column>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Bool_Exp>;
};

/** Ordering options when selecting data from "master.nb_mfield_polygon_2022_1". */
export type Master_Nb_Mfield_Polygon_2022_1_Order_By = {
  geom?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  local_government_cd?: InputMaybe<Order_By>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Order_By>;
};

/** primary key columns input for table: master.nb_mfield_polygon_2022_1 */
export type Master_Nb_Mfield_Polygon_2022_1_Pk_Columns_Input = {
  key: Scalars['uuid'];
};

/** select columns of table "master.nb_mfield_polygon_2022_1" */
export enum Master_Nb_Mfield_Polygon_2022_1_Select_Column {
  /** column name */
  Geom = 'geom',
  /** column name */
  Key = 'key',
  /** column name */
  LocalGovernmentCd = 'local_government_cd'
}

/** input type for updating data in table "master.nb_mfield_polygon_2022_1" */
export type Master_Nb_Mfield_Polygon_2022_1_Set_Input = {
  geom?: InputMaybe<Scalars['geometry']>;
  key?: InputMaybe<Scalars['uuid']>;
  local_government_cd?: InputMaybe<Scalars['bpchar']>;
};

/** Streaming cursor of the table "master_nb_mfield_polygon_2022_1" */
export type Master_Nb_Mfield_Polygon_2022_1_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Nb_Mfield_Polygon_2022_1_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Nb_Mfield_Polygon_2022_1_Stream_Cursor_Value_Input = {
  geom?: InputMaybe<Scalars['geometry']>;
  key?: InputMaybe<Scalars['uuid']>;
  local_government_cd?: InputMaybe<Scalars['bpchar']>;
};

/** update columns of table "master.nb_mfield_polygon_2022_1" */
export enum Master_Nb_Mfield_Polygon_2022_1_Update_Column {
  /** column name */
  Geom = 'geom',
  /** column name */
  Key = 'key',
  /** column name */
  LocalGovernmentCd = 'local_government_cd'
}

export type Master_Nb_Mfield_Polygon_2022_1_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Set_Input>;
  where: Master_Nb_Mfield_Polygon_2022_1_Bool_Exp;
};

/** columns and relationships of "master.nb_mfield_polygon_2023_1" */
export type Master_Nb_Mfield_Polygon_2023_1 = {
  __typename?: 'master_nb_mfield_polygon_2023_1';
  geom: Scalars['geometry'];
  key: Scalars['uuid'];
  local_government_cd: Scalars['bpchar'];
  /** An object relationship */
  nb_mfield: Master_Nb_Mfield;
};

/** aggregated selection of "master.nb_mfield_polygon_2023_1" */
export type Master_Nb_Mfield_Polygon_2023_1_Aggregate = {
  __typename?: 'master_nb_mfield_polygon_2023_1_aggregate';
  aggregate?: Maybe<Master_Nb_Mfield_Polygon_2023_1_Aggregate_Fields>;
  nodes: Array<Master_Nb_Mfield_Polygon_2023_1>;
};

/** aggregate fields of "master.nb_mfield_polygon_2023_1" */
export type Master_Nb_Mfield_Polygon_2023_1_Aggregate_Fields = {
  __typename?: 'master_nb_mfield_polygon_2023_1_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Master_Nb_Mfield_Polygon_2023_1_Max_Fields>;
  min?: Maybe<Master_Nb_Mfield_Polygon_2023_1_Min_Fields>;
};


/** aggregate fields of "master.nb_mfield_polygon_2023_1" */
export type Master_Nb_Mfield_Polygon_2023_1_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2023_1_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "master.nb_mfield_polygon_2023_1". All fields are combined with a logical 'AND'. */
export type Master_Nb_Mfield_Polygon_2023_1_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2023_1_Bool_Exp>>;
  _not?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2023_1_Bool_Exp>>;
  geom?: InputMaybe<Geometry_Comparison_Exp>;
  key?: InputMaybe<Uuid_Comparison_Exp>;
  local_government_cd?: InputMaybe<Bpchar_Comparison_Exp>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};

/** unique or primary key constraints on table "master.nb_mfield_polygon_2023_1" */
export enum Master_Nb_Mfield_Polygon_2023_1_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbMfieldPolygon_2023_1Pkey = 'nb_mfield_polygon_2023_1_pkey'
}

/** input type for inserting data into table "master.nb_mfield_polygon_2023_1" */
export type Master_Nb_Mfield_Polygon_2023_1_Insert_Input = {
  geom?: InputMaybe<Scalars['geometry']>;
  key?: InputMaybe<Scalars['uuid']>;
  local_government_cd?: InputMaybe<Scalars['bpchar']>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Master_Nb_Mfield_Polygon_2023_1_Max_Fields = {
  __typename?: 'master_nb_mfield_polygon_2023_1_max_fields';
  key?: Maybe<Scalars['uuid']>;
  local_government_cd?: Maybe<Scalars['bpchar']>;
};

/** aggregate min on columns */
export type Master_Nb_Mfield_Polygon_2023_1_Min_Fields = {
  __typename?: 'master_nb_mfield_polygon_2023_1_min_fields';
  key?: Maybe<Scalars['uuid']>;
  local_government_cd?: Maybe<Scalars['bpchar']>;
};

/** response of any mutation on the table "master.nb_mfield_polygon_2023_1" */
export type Master_Nb_Mfield_Polygon_2023_1_Mutation_Response = {
  __typename?: 'master_nb_mfield_polygon_2023_1_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Nb_Mfield_Polygon_2023_1>;
};

/** input type for inserting object relation for remote table "master.nb_mfield_polygon_2023_1" */
export type Master_Nb_Mfield_Polygon_2023_1_Obj_Rel_Insert_Input = {
  data: Master_Nb_Mfield_Polygon_2023_1_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_On_Conflict>;
};

/** on_conflict condition type for table "master.nb_mfield_polygon_2023_1" */
export type Master_Nb_Mfield_Polygon_2023_1_On_Conflict = {
  constraint: Master_Nb_Mfield_Polygon_2023_1_Constraint;
  update_columns?: Array<Master_Nb_Mfield_Polygon_2023_1_Update_Column>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Bool_Exp>;
};

/** Ordering options when selecting data from "master.nb_mfield_polygon_2023_1". */
export type Master_Nb_Mfield_Polygon_2023_1_Order_By = {
  geom?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  local_government_cd?: InputMaybe<Order_By>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Order_By>;
};

/** primary key columns input for table: master.nb_mfield_polygon_2023_1 */
export type Master_Nb_Mfield_Polygon_2023_1_Pk_Columns_Input = {
  key: Scalars['uuid'];
};

/** select columns of table "master.nb_mfield_polygon_2023_1" */
export enum Master_Nb_Mfield_Polygon_2023_1_Select_Column {
  /** column name */
  Geom = 'geom',
  /** column name */
  Key = 'key',
  /** column name */
  LocalGovernmentCd = 'local_government_cd'
}

/** input type for updating data in table "master.nb_mfield_polygon_2023_1" */
export type Master_Nb_Mfield_Polygon_2023_1_Set_Input = {
  geom?: InputMaybe<Scalars['geometry']>;
  key?: InputMaybe<Scalars['uuid']>;
  local_government_cd?: InputMaybe<Scalars['bpchar']>;
};

/** Streaming cursor of the table "master_nb_mfield_polygon_2023_1" */
export type Master_Nb_Mfield_Polygon_2023_1_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Nb_Mfield_Polygon_2023_1_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Nb_Mfield_Polygon_2023_1_Stream_Cursor_Value_Input = {
  geom?: InputMaybe<Scalars['geometry']>;
  key?: InputMaybe<Scalars['uuid']>;
  local_government_cd?: InputMaybe<Scalars['bpchar']>;
};

/** update columns of table "master.nb_mfield_polygon_2023_1" */
export enum Master_Nb_Mfield_Polygon_2023_1_Update_Column {
  /** column name */
  Geom = 'geom',
  /** column name */
  Key = 'key',
  /** column name */
  LocalGovernmentCd = 'local_government_cd'
}

export type Master_Nb_Mfield_Polygon_2023_1_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Set_Input>;
  where: Master_Nb_Mfield_Polygon_2023_1_Bool_Exp;
};

/** columns and relationships of "master.nb_mfield_polygon_2024_1" */
export type Master_Nb_Mfield_Polygon_2024_1 = {
  __typename?: 'master_nb_mfield_polygon_2024_1';
  geom: Scalars['geometry'];
  key: Scalars['uuid'];
  local_government_cd: Scalars['bpchar'];
  /** An object relationship */
  nb_mfield: Master_Nb_Mfield;
};

/** aggregated selection of "master.nb_mfield_polygon_2024_1" */
export type Master_Nb_Mfield_Polygon_2024_1_Aggregate = {
  __typename?: 'master_nb_mfield_polygon_2024_1_aggregate';
  aggregate?: Maybe<Master_Nb_Mfield_Polygon_2024_1_Aggregate_Fields>;
  nodes: Array<Master_Nb_Mfield_Polygon_2024_1>;
};

/** aggregate fields of "master.nb_mfield_polygon_2024_1" */
export type Master_Nb_Mfield_Polygon_2024_1_Aggregate_Fields = {
  __typename?: 'master_nb_mfield_polygon_2024_1_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Master_Nb_Mfield_Polygon_2024_1_Max_Fields>;
  min?: Maybe<Master_Nb_Mfield_Polygon_2024_1_Min_Fields>;
};


/** aggregate fields of "master.nb_mfield_polygon_2024_1" */
export type Master_Nb_Mfield_Polygon_2024_1_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2024_1_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "master.nb_mfield_polygon_2024_1". All fields are combined with a logical 'AND'. */
export type Master_Nb_Mfield_Polygon_2024_1_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2024_1_Bool_Exp>>;
  _not?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2024_1_Bool_Exp>>;
  geom?: InputMaybe<Geometry_Comparison_Exp>;
  key?: InputMaybe<Uuid_Comparison_Exp>;
  local_government_cd?: InputMaybe<Bpchar_Comparison_Exp>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};

/** unique or primary key constraints on table "master.nb_mfield_polygon_2024_1" */
export enum Master_Nb_Mfield_Polygon_2024_1_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbMfieldPolygon_2024_1Pkey = 'nb_mfield_polygon_2024_1_pkey'
}

/** input type for inserting data into table "master.nb_mfield_polygon_2024_1" */
export type Master_Nb_Mfield_Polygon_2024_1_Insert_Input = {
  geom?: InputMaybe<Scalars['geometry']>;
  key?: InputMaybe<Scalars['uuid']>;
  local_government_cd?: InputMaybe<Scalars['bpchar']>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Master_Nb_Mfield_Polygon_2024_1_Max_Fields = {
  __typename?: 'master_nb_mfield_polygon_2024_1_max_fields';
  key?: Maybe<Scalars['uuid']>;
  local_government_cd?: Maybe<Scalars['bpchar']>;
};

/** aggregate min on columns */
export type Master_Nb_Mfield_Polygon_2024_1_Min_Fields = {
  __typename?: 'master_nb_mfield_polygon_2024_1_min_fields';
  key?: Maybe<Scalars['uuid']>;
  local_government_cd?: Maybe<Scalars['bpchar']>;
};

/** response of any mutation on the table "master.nb_mfield_polygon_2024_1" */
export type Master_Nb_Mfield_Polygon_2024_1_Mutation_Response = {
  __typename?: 'master_nb_mfield_polygon_2024_1_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Nb_Mfield_Polygon_2024_1>;
};

/** input type for inserting object relation for remote table "master.nb_mfield_polygon_2024_1" */
export type Master_Nb_Mfield_Polygon_2024_1_Obj_Rel_Insert_Input = {
  data: Master_Nb_Mfield_Polygon_2024_1_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_On_Conflict>;
};

/** on_conflict condition type for table "master.nb_mfield_polygon_2024_1" */
export type Master_Nb_Mfield_Polygon_2024_1_On_Conflict = {
  constraint: Master_Nb_Mfield_Polygon_2024_1_Constraint;
  update_columns?: Array<Master_Nb_Mfield_Polygon_2024_1_Update_Column>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Bool_Exp>;
};

/** Ordering options when selecting data from "master.nb_mfield_polygon_2024_1". */
export type Master_Nb_Mfield_Polygon_2024_1_Order_By = {
  geom?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  local_government_cd?: InputMaybe<Order_By>;
  nb_mfield?: InputMaybe<Master_Nb_Mfield_Order_By>;
};

/** primary key columns input for table: master.nb_mfield_polygon_2024_1 */
export type Master_Nb_Mfield_Polygon_2024_1_Pk_Columns_Input = {
  key: Scalars['uuid'];
};

/** select columns of table "master.nb_mfield_polygon_2024_1" */
export enum Master_Nb_Mfield_Polygon_2024_1_Select_Column {
  /** column name */
  Geom = 'geom',
  /** column name */
  Key = 'key',
  /** column name */
  LocalGovernmentCd = 'local_government_cd'
}

/** input type for updating data in table "master.nb_mfield_polygon_2024_1" */
export type Master_Nb_Mfield_Polygon_2024_1_Set_Input = {
  geom?: InputMaybe<Scalars['geometry']>;
  key?: InputMaybe<Scalars['uuid']>;
  local_government_cd?: InputMaybe<Scalars['bpchar']>;
};

/** Streaming cursor of the table "master_nb_mfield_polygon_2024_1" */
export type Master_Nb_Mfield_Polygon_2024_1_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Nb_Mfield_Polygon_2024_1_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Nb_Mfield_Polygon_2024_1_Stream_Cursor_Value_Input = {
  geom?: InputMaybe<Scalars['geometry']>;
  key?: InputMaybe<Scalars['uuid']>;
  local_government_cd?: InputMaybe<Scalars['bpchar']>;
};

/** update columns of table "master.nb_mfield_polygon_2024_1" */
export enum Master_Nb_Mfield_Polygon_2024_1_Update_Column {
  /** column name */
  Geom = 'geom',
  /** column name */
  Key = 'key',
  /** column name */
  LocalGovernmentCd = 'local_government_cd'
}

export type Master_Nb_Mfield_Polygon_2024_1_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Set_Input>;
  where: Master_Nb_Mfield_Polygon_2024_1_Bool_Exp;
};

/** select columns of table "master.nb_mfield" */
export enum Master_Nb_Mfield_Select_Column {
  /** column name */
  AreaMeshCode_3rd = 'area_mesh_code_3rd',
  /** column name */
  AreaMeshCode_5th = 'area_mesh_code_5th',
  /** column name */
  Key = 'key',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  VersionId = 'version_id'
}

/** input type for updating data in table "master.nb_mfield" */
export type Master_Nb_Mfield_Set_Input = {
  area_mesh_code_3rd?: InputMaybe<Scalars['bigint']>;
  area_mesh_code_5th?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  version_id?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Master_Nb_Mfield_Stddev_Fields = {
  __typename?: 'master_nb_mfield_stddev_fields';
  area_mesh_code_3rd?: Maybe<Scalars['Float']>;
  area_mesh_code_5th?: Maybe<Scalars['Float']>;
  version_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "master.nb_mfield" */
export type Master_Nb_Mfield_Stddev_Order_By = {
  area_mesh_code_3rd?: InputMaybe<Order_By>;
  area_mesh_code_5th?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Master_Nb_Mfield_Stddev_Pop_Fields = {
  __typename?: 'master_nb_mfield_stddev_pop_fields';
  area_mesh_code_3rd?: Maybe<Scalars['Float']>;
  area_mesh_code_5th?: Maybe<Scalars['Float']>;
  version_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "master.nb_mfield" */
export type Master_Nb_Mfield_Stddev_Pop_Order_By = {
  area_mesh_code_3rd?: InputMaybe<Order_By>;
  area_mesh_code_5th?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Master_Nb_Mfield_Stddev_Samp_Fields = {
  __typename?: 'master_nb_mfield_stddev_samp_fields';
  area_mesh_code_3rd?: Maybe<Scalars['Float']>;
  area_mesh_code_5th?: Maybe<Scalars['Float']>;
  version_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "master.nb_mfield" */
export type Master_Nb_Mfield_Stddev_Samp_Order_By = {
  area_mesh_code_3rd?: InputMaybe<Order_By>;
  area_mesh_code_5th?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "master_nb_mfield" */
export type Master_Nb_Mfield_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Nb_Mfield_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Nb_Mfield_Stream_Cursor_Value_Input = {
  area_mesh_code_3rd?: InputMaybe<Scalars['bigint']>;
  area_mesh_code_5th?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  version_id?: InputMaybe<Scalars['smallint']>;
};

/** aggregate sum on columns */
export type Master_Nb_Mfield_Sum_Fields = {
  __typename?: 'master_nb_mfield_sum_fields';
  area_mesh_code_3rd?: Maybe<Scalars['bigint']>;
  area_mesh_code_5th?: Maybe<Scalars['bigint']>;
  version_id?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "master.nb_mfield" */
export type Master_Nb_Mfield_Sum_Order_By = {
  area_mesh_code_3rd?: InputMaybe<Order_By>;
  area_mesh_code_5th?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** update columns of table "master.nb_mfield" */
export enum Master_Nb_Mfield_Update_Column {
  /** column name */
  AreaMeshCode_3rd = 'area_mesh_code_3rd',
  /** column name */
  AreaMeshCode_5th = 'area_mesh_code_5th',
  /** column name */
  Key = 'key',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  VersionId = 'version_id'
}

export type Master_Nb_Mfield_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Master_Nb_Mfield_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Nb_Mfield_Set_Input>;
  where: Master_Nb_Mfield_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Master_Nb_Mfield_Var_Pop_Fields = {
  __typename?: 'master_nb_mfield_var_pop_fields';
  area_mesh_code_3rd?: Maybe<Scalars['Float']>;
  area_mesh_code_5th?: Maybe<Scalars['Float']>;
  version_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "master.nb_mfield" */
export type Master_Nb_Mfield_Var_Pop_Order_By = {
  area_mesh_code_3rd?: InputMaybe<Order_By>;
  area_mesh_code_5th?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Master_Nb_Mfield_Var_Samp_Fields = {
  __typename?: 'master_nb_mfield_var_samp_fields';
  area_mesh_code_3rd?: Maybe<Scalars['Float']>;
  area_mesh_code_5th?: Maybe<Scalars['Float']>;
  version_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "master.nb_mfield" */
export type Master_Nb_Mfield_Var_Samp_Order_By = {
  area_mesh_code_3rd?: InputMaybe<Order_By>;
  area_mesh_code_5th?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Master_Nb_Mfield_Variance_Fields = {
  __typename?: 'master_nb_mfield_variance_fields';
  area_mesh_code_3rd?: Maybe<Scalars['Float']>;
  area_mesh_code_5th?: Maybe<Scalars['Float']>;
  version_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "master.nb_mfield" */
export type Master_Nb_Mfield_Variance_Order_By = {
  area_mesh_code_3rd?: InputMaybe<Order_By>;
  area_mesh_code_5th?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** nb_mfieldのレコード生成時のソースデータのバージョン */
export type Master_Nb_Mfield_Version = {
  __typename?: 'master_nb_mfield_version';
  id: Scalars['smallint'];
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year: Scalars['Int'];
  /** An array relationship */
  nb_mfields: Array<Master_Nb_Mfield>;
  /** An aggregate relationship */
  nb_mfields_aggregate: Master_Nb_Mfield_Aggregate;
  /** シンプル化済みポリゴンデータ生成時に決めるファームテクノロジー部製のポリゴンバージョン */
  polygon_version: Scalars['String'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** nb_mfieldのレコード生成時のソースデータのバージョン */
export type Master_Nb_Mfield_VersionNb_MfieldsArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};


/** nb_mfieldのレコード生成時のソースデータのバージョン */
export type Master_Nb_Mfield_VersionNb_Mfields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};

/** aggregated selection of "master.nb_mfield_version" */
export type Master_Nb_Mfield_Version_Aggregate = {
  __typename?: 'master_nb_mfield_version_aggregate';
  aggregate?: Maybe<Master_Nb_Mfield_Version_Aggregate_Fields>;
  nodes: Array<Master_Nb_Mfield_Version>;
};

/** aggregate fields of "master.nb_mfield_version" */
export type Master_Nb_Mfield_Version_Aggregate_Fields = {
  __typename?: 'master_nb_mfield_version_aggregate_fields';
  avg?: Maybe<Master_Nb_Mfield_Version_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Master_Nb_Mfield_Version_Max_Fields>;
  min?: Maybe<Master_Nb_Mfield_Version_Min_Fields>;
  stddev?: Maybe<Master_Nb_Mfield_Version_Stddev_Fields>;
  stddev_pop?: Maybe<Master_Nb_Mfield_Version_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Master_Nb_Mfield_Version_Stddev_Samp_Fields>;
  sum?: Maybe<Master_Nb_Mfield_Version_Sum_Fields>;
  var_pop?: Maybe<Master_Nb_Mfield_Version_Var_Pop_Fields>;
  var_samp?: Maybe<Master_Nb_Mfield_Version_Var_Samp_Fields>;
  variance?: Maybe<Master_Nb_Mfield_Version_Variance_Fields>;
};


/** aggregate fields of "master.nb_mfield_version" */
export type Master_Nb_Mfield_Version_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Nb_Mfield_Version_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Master_Nb_Mfield_Version_Avg_Fields = {
  __typename?: 'master_nb_mfield_version_avg_fields';
  id?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "master.nb_mfield_version". All fields are combined with a logical 'AND'. */
export type Master_Nb_Mfield_Version_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Nb_Mfield_Version_Bool_Exp>>;
  _not?: InputMaybe<Master_Nb_Mfield_Version_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Nb_Mfield_Version_Bool_Exp>>;
  id?: InputMaybe<Smallint_Comparison_Exp>;
  maff_issue_year?: InputMaybe<Int_Comparison_Exp>;
  nb_mfields?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
  nb_mfields_aggregate?: InputMaybe<Master_Nb_Mfield_Aggregate_Bool_Exp>;
  polygon_version?: InputMaybe<String_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "master.nb_mfield_version" */
export enum Master_Nb_Mfield_Version_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbMfieldVersionPkey = 'nb_mfield_version_pkey',
  /** unique or primary key constraint on columns "polygon_version" */
  NbMfieldVersionPolygonVersionKey = 'nb_mfield_version_polygon_version_key'
}

/** input type for incrementing numeric columns in table "master.nb_mfield_version" */
export type Master_Nb_Mfield_Version_Inc_Input = {
  id?: InputMaybe<Scalars['smallint']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "master.nb_mfield_version" */
export type Master_Nb_Mfield_Version_Insert_Input = {
  id?: InputMaybe<Scalars['smallint']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: InputMaybe<Scalars['Int']>;
  nb_mfields?: InputMaybe<Master_Nb_Mfield_Arr_Rel_Insert_Input>;
  /** シンプル化済みポリゴンデータ生成時に決めるファームテクノロジー部製のポリゴンバージョン */
  polygon_version?: InputMaybe<Scalars['String']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Master_Nb_Mfield_Version_Max_Fields = {
  __typename?: 'master_nb_mfield_version_max_fields';
  id?: Maybe<Scalars['smallint']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: Maybe<Scalars['Int']>;
  /** シンプル化済みポリゴンデータ生成時に決めるファームテクノロジー部製のポリゴンバージョン */
  polygon_version?: Maybe<Scalars['String']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Master_Nb_Mfield_Version_Min_Fields = {
  __typename?: 'master_nb_mfield_version_min_fields';
  id?: Maybe<Scalars['smallint']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: Maybe<Scalars['Int']>;
  /** シンプル化済みポリゴンデータ生成時に決めるファームテクノロジー部製のポリゴンバージョン */
  polygon_version?: Maybe<Scalars['String']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "master.nb_mfield_version" */
export type Master_Nb_Mfield_Version_Mutation_Response = {
  __typename?: 'master_nb_mfield_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Nb_Mfield_Version>;
};

/** input type for inserting object relation for remote table "master.nb_mfield_version" */
export type Master_Nb_Mfield_Version_Obj_Rel_Insert_Input = {
  data: Master_Nb_Mfield_Version_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Nb_Mfield_Version_On_Conflict>;
};

/** on_conflict condition type for table "master.nb_mfield_version" */
export type Master_Nb_Mfield_Version_On_Conflict = {
  constraint: Master_Nb_Mfield_Version_Constraint;
  update_columns?: Array<Master_Nb_Mfield_Version_Update_Column>;
  where?: InputMaybe<Master_Nb_Mfield_Version_Bool_Exp>;
};

/** Ordering options when selecting data from "master.nb_mfield_version". */
export type Master_Nb_Mfield_Version_Order_By = {
  id?: InputMaybe<Order_By>;
  maff_issue_year?: InputMaybe<Order_By>;
  nb_mfields_aggregate?: InputMaybe<Master_Nb_Mfield_Aggregate_Order_By>;
  polygon_version?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: master.nb_mfield_version */
export type Master_Nb_Mfield_Version_Pk_Columns_Input = {
  id: Scalars['smallint'];
};

/** select columns of table "master.nb_mfield_version" */
export enum Master_Nb_Mfield_Version_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MaffIssueYear = 'maff_issue_year',
  /** column name */
  PolygonVersion = 'polygon_version',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "master.nb_mfield_version" */
export type Master_Nb_Mfield_Version_Set_Input = {
  id?: InputMaybe<Scalars['smallint']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: InputMaybe<Scalars['Int']>;
  /** シンプル化済みポリゴンデータ生成時に決めるファームテクノロジー部製のポリゴンバージョン */
  polygon_version?: InputMaybe<Scalars['String']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Master_Nb_Mfield_Version_Stddev_Fields = {
  __typename?: 'master_nb_mfield_version_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Master_Nb_Mfield_Version_Stddev_Pop_Fields = {
  __typename?: 'master_nb_mfield_version_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Master_Nb_Mfield_Version_Stddev_Samp_Fields = {
  __typename?: 'master_nb_mfield_version_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "master_nb_mfield_version" */
export type Master_Nb_Mfield_Version_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Nb_Mfield_Version_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Nb_Mfield_Version_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['smallint']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: InputMaybe<Scalars['Int']>;
  /** シンプル化済みポリゴンデータ生成時に決めるファームテクノロジー部製のポリゴンバージョン */
  polygon_version?: InputMaybe<Scalars['String']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Master_Nb_Mfield_Version_Sum_Fields = {
  __typename?: 'master_nb_mfield_version_sum_fields';
  id?: Maybe<Scalars['smallint']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: Maybe<Scalars['Int']>;
};

/** update columns of table "master.nb_mfield_version" */
export enum Master_Nb_Mfield_Version_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MaffIssueYear = 'maff_issue_year',
  /** column name */
  PolygonVersion = 'polygon_version',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Master_Nb_Mfield_Version_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Master_Nb_Mfield_Version_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Nb_Mfield_Version_Set_Input>;
  where: Master_Nb_Mfield_Version_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Master_Nb_Mfield_Version_Var_Pop_Fields = {
  __typename?: 'master_nb_mfield_version_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Master_Nb_Mfield_Version_Var_Samp_Fields = {
  __typename?: 'master_nb_mfield_version_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Master_Nb_Mfield_Version_Variance_Fields = {
  __typename?: 'master_nb_mfield_version_variance_fields';
  id?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンが発行された年。筆ポリゴンデータに格納されているissue_yearと同じ。 */
  maff_issue_year?: Maybe<Scalars['Float']>;
};

/** postgisのバージョン。ユーザ圃場登録時の面積計算等の出自を明確にする。 */
export type Master_Nb_Postgis_Version = {
  __typename?: 'master_nb_postgis_version';
  id: Scalars['uuid'];
  /** postgis_full_version() で得られる文字列。ライブラリを含めた全てのpostgisを構成するバージョン情報。例）POSTGIS="3.2.3 0" [EXTENSION] PGSQL="130" GEOS="3.10.3-CAPI-1.16.1" PROJ="6.2.1" LIBXML="2.9.9" LIBJSON="0.12.99" LIBPROTOBUF="1.3.0" WAGYU="0.5.0 (Internal)" (core procs from "3.1.5 0" need upgrade) */
  postgis_full_version: Scalars['String'];
  /** postgis_lib_version() で得られる文字列。 postgisのコアライブラリのバージョン。　例)3.2.3 */
  postgis_lib_version: Scalars['String'];
  /** postgis_version() で得られる文字列。postgisのメインバージョンと有効化されているライブラリ情報　例) 3.2 USE_GEOS=1 USE_PROJ=1 USE_STATS=1 */
  postgis_version: Scalars['String'];
};

/** aggregated selection of "master.nb_postgis_version" */
export type Master_Nb_Postgis_Version_Aggregate = {
  __typename?: 'master_nb_postgis_version_aggregate';
  aggregate?: Maybe<Master_Nb_Postgis_Version_Aggregate_Fields>;
  nodes: Array<Master_Nb_Postgis_Version>;
};

/** aggregate fields of "master.nb_postgis_version" */
export type Master_Nb_Postgis_Version_Aggregate_Fields = {
  __typename?: 'master_nb_postgis_version_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Master_Nb_Postgis_Version_Max_Fields>;
  min?: Maybe<Master_Nb_Postgis_Version_Min_Fields>;
};


/** aggregate fields of "master.nb_postgis_version" */
export type Master_Nb_Postgis_Version_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Nb_Postgis_Version_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "master.nb_postgis_version". All fields are combined with a logical 'AND'. */
export type Master_Nb_Postgis_Version_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Nb_Postgis_Version_Bool_Exp>>;
  _not?: InputMaybe<Master_Nb_Postgis_Version_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Nb_Postgis_Version_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  postgis_full_version?: InputMaybe<String_Comparison_Exp>;
  postgis_lib_version?: InputMaybe<String_Comparison_Exp>;
  postgis_version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "master.nb_postgis_version" */
export enum Master_Nb_Postgis_Version_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbPostgisVersionPkey = 'nb_postgis_version_pkey',
  /** unique or primary key constraint on columns "postgis_version", "postgis_full_version", "postgis_lib_version" */
  NbPostgisVersionPostgisLibVersionPostgisVersionPostgis = 'nb_postgis_version_postgis_lib_version_postgis_version_postgis_'
}

/** input type for inserting data into table "master.nb_postgis_version" */
export type Master_Nb_Postgis_Version_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  /** postgis_full_version() で得られる文字列。ライブラリを含めた全てのpostgisを構成するバージョン情報。例）POSTGIS="3.2.3 0" [EXTENSION] PGSQL="130" GEOS="3.10.3-CAPI-1.16.1" PROJ="6.2.1" LIBXML="2.9.9" LIBJSON="0.12.99" LIBPROTOBUF="1.3.0" WAGYU="0.5.0 (Internal)" (core procs from "3.1.5 0" need upgrade) */
  postgis_full_version?: InputMaybe<Scalars['String']>;
  /** postgis_lib_version() で得られる文字列。 postgisのコアライブラリのバージョン。　例)3.2.3 */
  postgis_lib_version?: InputMaybe<Scalars['String']>;
  /** postgis_version() で得られる文字列。postgisのメインバージョンと有効化されているライブラリ情報　例) 3.2 USE_GEOS=1 USE_PROJ=1 USE_STATS=1 */
  postgis_version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Master_Nb_Postgis_Version_Max_Fields = {
  __typename?: 'master_nb_postgis_version_max_fields';
  id?: Maybe<Scalars['uuid']>;
  /** postgis_full_version() で得られる文字列。ライブラリを含めた全てのpostgisを構成するバージョン情報。例）POSTGIS="3.2.3 0" [EXTENSION] PGSQL="130" GEOS="3.10.3-CAPI-1.16.1" PROJ="6.2.1" LIBXML="2.9.9" LIBJSON="0.12.99" LIBPROTOBUF="1.3.0" WAGYU="0.5.0 (Internal)" (core procs from "3.1.5 0" need upgrade) */
  postgis_full_version?: Maybe<Scalars['String']>;
  /** postgis_lib_version() で得られる文字列。 postgisのコアライブラリのバージョン。　例)3.2.3 */
  postgis_lib_version?: Maybe<Scalars['String']>;
  /** postgis_version() で得られる文字列。postgisのメインバージョンと有効化されているライブラリ情報　例) 3.2 USE_GEOS=1 USE_PROJ=1 USE_STATS=1 */
  postgis_version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Master_Nb_Postgis_Version_Min_Fields = {
  __typename?: 'master_nb_postgis_version_min_fields';
  id?: Maybe<Scalars['uuid']>;
  /** postgis_full_version() で得られる文字列。ライブラリを含めた全てのpostgisを構成するバージョン情報。例）POSTGIS="3.2.3 0" [EXTENSION] PGSQL="130" GEOS="3.10.3-CAPI-1.16.1" PROJ="6.2.1" LIBXML="2.9.9" LIBJSON="0.12.99" LIBPROTOBUF="1.3.0" WAGYU="0.5.0 (Internal)" (core procs from "3.1.5 0" need upgrade) */
  postgis_full_version?: Maybe<Scalars['String']>;
  /** postgis_lib_version() で得られる文字列。 postgisのコアライブラリのバージョン。　例)3.2.3 */
  postgis_lib_version?: Maybe<Scalars['String']>;
  /** postgis_version() で得られる文字列。postgisのメインバージョンと有効化されているライブラリ情報　例) 3.2 USE_GEOS=1 USE_PROJ=1 USE_STATS=1 */
  postgis_version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "master.nb_postgis_version" */
export type Master_Nb_Postgis_Version_Mutation_Response = {
  __typename?: 'master_nb_postgis_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Nb_Postgis_Version>;
};

/** input type for inserting object relation for remote table "master.nb_postgis_version" */
export type Master_Nb_Postgis_Version_Obj_Rel_Insert_Input = {
  data: Master_Nb_Postgis_Version_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Nb_Postgis_Version_On_Conflict>;
};

/** on_conflict condition type for table "master.nb_postgis_version" */
export type Master_Nb_Postgis_Version_On_Conflict = {
  constraint: Master_Nb_Postgis_Version_Constraint;
  update_columns?: Array<Master_Nb_Postgis_Version_Update_Column>;
  where?: InputMaybe<Master_Nb_Postgis_Version_Bool_Exp>;
};

/** Ordering options when selecting data from "master.nb_postgis_version". */
export type Master_Nb_Postgis_Version_Order_By = {
  id?: InputMaybe<Order_By>;
  postgis_full_version?: InputMaybe<Order_By>;
  postgis_lib_version?: InputMaybe<Order_By>;
  postgis_version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: master.nb_postgis_version */
export type Master_Nb_Postgis_Version_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "master.nb_postgis_version" */
export enum Master_Nb_Postgis_Version_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PostgisFullVersion = 'postgis_full_version',
  /** column name */
  PostgisLibVersion = 'postgis_lib_version',
  /** column name */
  PostgisVersion = 'postgis_version'
}

/** input type for updating data in table "master.nb_postgis_version" */
export type Master_Nb_Postgis_Version_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  /** postgis_full_version() で得られる文字列。ライブラリを含めた全てのpostgisを構成するバージョン情報。例）POSTGIS="3.2.3 0" [EXTENSION] PGSQL="130" GEOS="3.10.3-CAPI-1.16.1" PROJ="6.2.1" LIBXML="2.9.9" LIBJSON="0.12.99" LIBPROTOBUF="1.3.0" WAGYU="0.5.0 (Internal)" (core procs from "3.1.5 0" need upgrade) */
  postgis_full_version?: InputMaybe<Scalars['String']>;
  /** postgis_lib_version() で得られる文字列。 postgisのコアライブラリのバージョン。　例)3.2.3 */
  postgis_lib_version?: InputMaybe<Scalars['String']>;
  /** postgis_version() で得られる文字列。postgisのメインバージョンと有効化されているライブラリ情報　例) 3.2 USE_GEOS=1 USE_PROJ=1 USE_STATS=1 */
  postgis_version?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "master_nb_postgis_version" */
export type Master_Nb_Postgis_Version_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Nb_Postgis_Version_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Nb_Postgis_Version_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  /** postgis_full_version() で得られる文字列。ライブラリを含めた全てのpostgisを構成するバージョン情報。例）POSTGIS="3.2.3 0" [EXTENSION] PGSQL="130" GEOS="3.10.3-CAPI-1.16.1" PROJ="6.2.1" LIBXML="2.9.9" LIBJSON="0.12.99" LIBPROTOBUF="1.3.0" WAGYU="0.5.0 (Internal)" (core procs from "3.1.5 0" need upgrade) */
  postgis_full_version?: InputMaybe<Scalars['String']>;
  /** postgis_lib_version() で得られる文字列。 postgisのコアライブラリのバージョン。　例)3.2.3 */
  postgis_lib_version?: InputMaybe<Scalars['String']>;
  /** postgis_version() で得られる文字列。postgisのメインバージョンと有効化されているライブラリ情報　例) 3.2 USE_GEOS=1 USE_PROJ=1 USE_STATS=1 */
  postgis_version?: InputMaybe<Scalars['String']>;
};

/** update columns of table "master.nb_postgis_version" */
export enum Master_Nb_Postgis_Version_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PostgisFullVersion = 'postgis_full_version',
  /** column name */
  PostgisLibVersion = 'postgis_lib_version',
  /** column name */
  PostgisVersion = 'postgis_version'
}

export type Master_Nb_Postgis_Version_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Nb_Postgis_Version_Set_Input>;
  where: Master_Nb_Postgis_Version_Bool_Exp;
};

/** 天気情報の属性(実測か予測か等) */
export type Master_Nb_Weather_Attr = {
  __typename?: 'master_nb_weather_attr';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  nb_weather_data: Array<Master_Nb_Weather_Data>;
  /** An aggregate relationship */
  nb_weather_data_aggregate: Master_Nb_Weather_Data_Aggregate;
  updated: Scalars['timestamptz'];
};


/** 天気情報の属性(実測か予測か等) */
export type Master_Nb_Weather_AttrNb_Weather_DataArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
};


/** 天気情報の属性(実測か予測か等) */
export type Master_Nb_Weather_AttrNb_Weather_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
};

/** aggregated selection of "master.nb_weather_attr" */
export type Master_Nb_Weather_Attr_Aggregate = {
  __typename?: 'master_nb_weather_attr_aggregate';
  aggregate?: Maybe<Master_Nb_Weather_Attr_Aggregate_Fields>;
  nodes: Array<Master_Nb_Weather_Attr>;
};

/** aggregate fields of "master.nb_weather_attr" */
export type Master_Nb_Weather_Attr_Aggregate_Fields = {
  __typename?: 'master_nb_weather_attr_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Master_Nb_Weather_Attr_Max_Fields>;
  min?: Maybe<Master_Nb_Weather_Attr_Min_Fields>;
};


/** aggregate fields of "master.nb_weather_attr" */
export type Master_Nb_Weather_Attr_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Nb_Weather_Attr_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "master.nb_weather_attr". All fields are combined with a logical 'AND'. */
export type Master_Nb_Weather_Attr_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Nb_Weather_Attr_Bool_Exp>>;
  _not?: InputMaybe<Master_Nb_Weather_Attr_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Nb_Weather_Attr_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_weather_data?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
  nb_weather_data_aggregate?: InputMaybe<Master_Nb_Weather_Data_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "master.nb_weather_attr" */
export enum Master_Nb_Weather_Attr_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbWeatherAttrKeyKey = 'nb_weather_attr_key_key',
  /** unique or primary key constraint on columns "id" */
  NbWeatherAttrPkey = 'nb_weather_attr_pkey'
}

/** input type for inserting data into table "master.nb_weather_attr" */
export type Master_Nb_Weather_Attr_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nb_weather_data?: InputMaybe<Master_Nb_Weather_Data_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Master_Nb_Weather_Attr_Max_Fields = {
  __typename?: 'master_nb_weather_attr_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Master_Nb_Weather_Attr_Min_Fields = {
  __typename?: 'master_nb_weather_attr_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "master.nb_weather_attr" */
export type Master_Nb_Weather_Attr_Mutation_Response = {
  __typename?: 'master_nb_weather_attr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Nb_Weather_Attr>;
};

/** input type for inserting object relation for remote table "master.nb_weather_attr" */
export type Master_Nb_Weather_Attr_Obj_Rel_Insert_Input = {
  data: Master_Nb_Weather_Attr_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Nb_Weather_Attr_On_Conflict>;
};

/** on_conflict condition type for table "master.nb_weather_attr" */
export type Master_Nb_Weather_Attr_On_Conflict = {
  constraint: Master_Nb_Weather_Attr_Constraint;
  update_columns?: Array<Master_Nb_Weather_Attr_Update_Column>;
  where?: InputMaybe<Master_Nb_Weather_Attr_Bool_Exp>;
};

/** Ordering options when selecting data from "master.nb_weather_attr". */
export type Master_Nb_Weather_Attr_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_weather_data_aggregate?: InputMaybe<Master_Nb_Weather_Data_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: master.nb_weather_attr */
export type Master_Nb_Weather_Attr_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "master.nb_weather_attr" */
export enum Master_Nb_Weather_Attr_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "master.nb_weather_attr" */
export type Master_Nb_Weather_Attr_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "master_nb_weather_attr" */
export type Master_Nb_Weather_Attr_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Nb_Weather_Attr_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Nb_Weather_Attr_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "master.nb_weather_attr" */
export enum Master_Nb_Weather_Attr_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

export type Master_Nb_Weather_Attr_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Nb_Weather_Attr_Set_Input>;
  where: Master_Nb_Weather_Attr_Bool_Exp;
};

/** 天気情報 */
export type Master_Nb_Weather_Data = {
  __typename?: 'master_nb_weather_data';
  area_mesh_code: Scalars['bigint'];
  created: Scalars['timestamptz'];
  datetime: Scalars['timestamptz'];
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  id: Scalars['uuid'];
  /** An object relationship */
  nb_area_mesh: Master_Nb_Area_Mesh;
  /** An object relationship */
  nb_weather_attr: Master_Nb_Weather_Attr;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  telop_code?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  updated: Scalars['timestamptz'];
  weather_id: Scalars['uuid'];
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Aggregate = {
  __typename?: 'master_nb_weather_data_aggregate';
  aggregate?: Maybe<Master_Nb_Weather_Data_Aggregate_Fields>;
  nodes: Array<Master_Nb_Weather_Data>;
};

export type Master_Nb_Weather_Data_Aggregate_Bool_Exp = {
  count?: InputMaybe<Master_Nb_Weather_Data_Aggregate_Bool_Exp_Count>;
};

export type Master_Nb_Weather_Data_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Master_Nb_Weather_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Aggregate_Fields = {
  __typename?: 'master_nb_weather_data_aggregate_fields';
  avg?: Maybe<Master_Nb_Weather_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Master_Nb_Weather_Data_Max_Fields>;
  min?: Maybe<Master_Nb_Weather_Data_Min_Fields>;
  stddev?: Maybe<Master_Nb_Weather_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Master_Nb_Weather_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Master_Nb_Weather_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Master_Nb_Weather_Data_Sum_Fields>;
  var_pop?: Maybe<Master_Nb_Weather_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Master_Nb_Weather_Data_Var_Samp_Fields>;
  variance?: Maybe<Master_Nb_Weather_Data_Variance_Fields>;
};


/** aggregate fields of "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Nb_Weather_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Master_Nb_Weather_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Master_Nb_Weather_Data_Max_Order_By>;
  min?: InputMaybe<Master_Nb_Weather_Data_Min_Order_By>;
  stddev?: InputMaybe<Master_Nb_Weather_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Master_Nb_Weather_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Master_Nb_Weather_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Master_Nb_Weather_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Master_Nb_Weather_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Master_Nb_Weather_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Master_Nb_Weather_Data_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Arr_Rel_Insert_Input = {
  data: Array<Master_Nb_Weather_Data_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Nb_Weather_Data_On_Conflict>;
};

/** aggregate avg on columns */
export type Master_Nb_Weather_Data_Avg_Fields = {
  __typename?: 'master_nb_weather_data_avg_fields';
  area_mesh_code?: Maybe<Scalars['Float']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Avg_Order_By = {
  area_mesh_code?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "master.nb_weather_data". All fields are combined with a logical 'AND'. */
export type Master_Nb_Weather_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Nb_Weather_Data_Bool_Exp>>;
  _not?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Nb_Weather_Data_Bool_Exp>>;
  area_mesh_code?: InputMaybe<Bigint_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  day_temperature?: InputMaybe<Float_Comparison_Exp>;
  diff_temperature?: InputMaybe<Float_Comparison_Exp>;
  humidity?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_area_mesh?: InputMaybe<Master_Nb_Area_Mesh_Bool_Exp>;
  nb_weather_attr?: InputMaybe<Master_Nb_Weather_Attr_Bool_Exp>;
  night_temperature?: InputMaybe<Float_Comparison_Exp>;
  rainfall?: InputMaybe<Float_Comparison_Exp>;
  solar_radiation?: InputMaybe<Float_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  telop_code?: InputMaybe<Int_Comparison_Exp>;
  temperature?: InputMaybe<Float_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  weather_id?: InputMaybe<Uuid_Comparison_Exp>;
  wind_velocity?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "master.nb_weather_data" */
export enum Master_Nb_Weather_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbWeatherDataPkey = 'nb_weather_data_pkey'
}

/** input type for incrementing numeric columns in table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Inc_Input = {
  area_mesh_code?: InputMaybe<Scalars['bigint']>;
  day_temperature?: InputMaybe<Scalars['Float']>;
  diff_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  night_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  telop_code?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Insert_Input = {
  area_mesh_code?: InputMaybe<Scalars['bigint']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  day_temperature?: InputMaybe<Scalars['Float']>;
  diff_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_area_mesh?: InputMaybe<Master_Nb_Area_Mesh_Obj_Rel_Insert_Input>;
  nb_weather_attr?: InputMaybe<Master_Nb_Weather_Attr_Obj_Rel_Insert_Input>;
  night_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  telop_code?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  weather_id?: InputMaybe<Scalars['uuid']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Master_Nb_Weather_Data_Max_Fields = {
  __typename?: 'master_nb_weather_data_max_fields';
  area_mesh_code?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  telop_code?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['timestamptz']>;
  weather_id?: Maybe<Scalars['uuid']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Max_Order_By = {
  area_mesh_code?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  weather_id?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Master_Nb_Weather_Data_Min_Fields = {
  __typename?: 'master_nb_weather_data_min_fields';
  area_mesh_code?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  telop_code?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['timestamptz']>;
  weather_id?: Maybe<Scalars['uuid']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Min_Order_By = {
  area_mesh_code?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  weather_id?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Mutation_Response = {
  __typename?: 'master_nb_weather_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Nb_Weather_Data>;
};

/** on_conflict condition type for table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_On_Conflict = {
  constraint: Master_Nb_Weather_Data_Constraint;
  update_columns?: Array<Master_Nb_Weather_Data_Update_Column>;
  where?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "master.nb_weather_data". */
export type Master_Nb_Weather_Data_Order_By = {
  area_mesh_code?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_area_mesh?: InputMaybe<Master_Nb_Area_Mesh_Order_By>;
  nb_weather_attr?: InputMaybe<Master_Nb_Weather_Attr_Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  weather_id?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: master.nb_weather_data */
export type Master_Nb_Weather_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "master.nb_weather_data" */
export enum Master_Nb_Weather_Data_Select_Column {
  /** column name */
  AreaMeshCode = 'area_mesh_code',
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DayTemperature = 'day_temperature',
  /** column name */
  DiffTemperature = 'diff_temperature',
  /** column name */
  Humidity = 'humidity',
  /** column name */
  Id = 'id',
  /** column name */
  NightTemperature = 'night_temperature',
  /** column name */
  Rainfall = 'rainfall',
  /** column name */
  SolarRadiation = 'solar_radiation',
  /** column name */
  Source = 'source',
  /** column name */
  TelopCode = 'telop_code',
  /** column name */
  Temperature = 'temperature',
  /** column name */
  Updated = 'updated',
  /** column name */
  WeatherId = 'weather_id',
  /** column name */
  WindVelocity = 'wind_velocity'
}

/** input type for updating data in table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Set_Input = {
  area_mesh_code?: InputMaybe<Scalars['bigint']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  day_temperature?: InputMaybe<Scalars['Float']>;
  diff_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  night_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  telop_code?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  weather_id?: InputMaybe<Scalars['uuid']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Master_Nb_Weather_Data_Stddev_Fields = {
  __typename?: 'master_nb_weather_data_stddev_fields';
  area_mesh_code?: Maybe<Scalars['Float']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Stddev_Order_By = {
  area_mesh_code?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Master_Nb_Weather_Data_Stddev_Pop_Fields = {
  __typename?: 'master_nb_weather_data_stddev_pop_fields';
  area_mesh_code?: Maybe<Scalars['Float']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Stddev_Pop_Order_By = {
  area_mesh_code?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Master_Nb_Weather_Data_Stddev_Samp_Fields = {
  __typename?: 'master_nb_weather_data_stddev_samp_fields';
  area_mesh_code?: Maybe<Scalars['Float']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Stddev_Samp_Order_By = {
  area_mesh_code?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "master_nb_weather_data" */
export type Master_Nb_Weather_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Nb_Weather_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Nb_Weather_Data_Stream_Cursor_Value_Input = {
  area_mesh_code?: InputMaybe<Scalars['bigint']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  day_temperature?: InputMaybe<Scalars['Float']>;
  diff_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  night_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  telop_code?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  weather_id?: InputMaybe<Scalars['uuid']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Master_Nb_Weather_Data_Sum_Fields = {
  __typename?: 'master_nb_weather_data_sum_fields';
  area_mesh_code?: Maybe<Scalars['bigint']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Sum_Order_By = {
  area_mesh_code?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** update columns of table "master.nb_weather_data" */
export enum Master_Nb_Weather_Data_Update_Column {
  /** column name */
  AreaMeshCode = 'area_mesh_code',
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DayTemperature = 'day_temperature',
  /** column name */
  DiffTemperature = 'diff_temperature',
  /** column name */
  Humidity = 'humidity',
  /** column name */
  Id = 'id',
  /** column name */
  NightTemperature = 'night_temperature',
  /** column name */
  Rainfall = 'rainfall',
  /** column name */
  SolarRadiation = 'solar_radiation',
  /** column name */
  Source = 'source',
  /** column name */
  TelopCode = 'telop_code',
  /** column name */
  Temperature = 'temperature',
  /** column name */
  Updated = 'updated',
  /** column name */
  WeatherId = 'weather_id',
  /** column name */
  WindVelocity = 'wind_velocity'
}

export type Master_Nb_Weather_Data_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Master_Nb_Weather_Data_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Nb_Weather_Data_Set_Input>;
  where: Master_Nb_Weather_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Master_Nb_Weather_Data_Var_Pop_Fields = {
  __typename?: 'master_nb_weather_data_var_pop_fields';
  area_mesh_code?: Maybe<Scalars['Float']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Var_Pop_Order_By = {
  area_mesh_code?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Master_Nb_Weather_Data_Var_Samp_Fields = {
  __typename?: 'master_nb_weather_data_var_samp_fields';
  area_mesh_code?: Maybe<Scalars['Float']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Var_Samp_Order_By = {
  area_mesh_code?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Master_Nb_Weather_Data_Variance_Fields = {
  __typename?: 'master_nb_weather_data_variance_fields';
  area_mesh_code?: Maybe<Scalars['Float']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "master.nb_weather_data" */
export type Master_Nb_Weather_Data_Variance_Order_By = {
  area_mesh_code?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "app_user" */
  delete_app_user?: Maybe<App_User_Mutation_Response>;
  /** delete data from the table: "app_user_active" */
  delete_app_user_active?: Maybe<App_User_Active_Mutation_Response>;
  /** delete single row from the table: "app_user_active" */
  delete_app_user_active_by_pk?: Maybe<App_User_Active>;
  /** delete single row from the table: "app_user" */
  delete_app_user_by_pk?: Maybe<App_User>;
  /** delete data from the table: "app_user_property_private" */
  delete_app_user_property_private?: Maybe<App_User_Property_Private_Mutation_Response>;
  /** delete single row from the table: "app_user_property_private" */
  delete_app_user_property_private_by_pk?: Maybe<App_User_Property_Private>;
  /** delete data from the table: "application_user_invitation_to_company" */
  delete_application_user_invitation_to_company?: Maybe<Application_User_Invitation_To_Company_Mutation_Response>;
  /** delete single row from the table: "application_user_invitation_to_company" */
  delete_application_user_invitation_to_company_by_pk?: Maybe<Application_User_Invitation_To_Company>;
  /** delete data from the table: "master.nb_area_mesh" */
  delete_master_nb_area_mesh?: Maybe<Master_Nb_Area_Mesh_Mutation_Response>;
  /** delete single row from the table: "master.nb_area_mesh" */
  delete_master_nb_area_mesh_by_pk?: Maybe<Master_Nb_Area_Mesh>;
  /** delete data from the table: "master.nb_integrated_farmland" */
  delete_master_nb_integrated_farmland?: Maybe<Master_Nb_Integrated_Farmland_Mutation_Response>;
  /** delete single row from the table: "master.nb_integrated_farmland" */
  delete_master_nb_integrated_farmland_by_pk?: Maybe<Master_Nb_Integrated_Farmland>;
  /** delete data from the table: "master.nb_mfield" */
  delete_master_nb_mfield?: Maybe<Master_Nb_Mfield_Mutation_Response>;
  /** delete single row from the table: "master.nb_mfield" */
  delete_master_nb_mfield_by_pk?: Maybe<Master_Nb_Mfield>;
  /** delete data from the table: "master.nb_mfield_polygon_2022_1" */
  delete_master_nb_mfield_polygon_2022_1?: Maybe<Master_Nb_Mfield_Polygon_2022_1_Mutation_Response>;
  /** delete single row from the table: "master.nb_mfield_polygon_2022_1" */
  delete_master_nb_mfield_polygon_2022_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2022_1>;
  /** delete data from the table: "master.nb_mfield_polygon_2023_1" */
  delete_master_nb_mfield_polygon_2023_1?: Maybe<Master_Nb_Mfield_Polygon_2023_1_Mutation_Response>;
  /** delete single row from the table: "master.nb_mfield_polygon_2023_1" */
  delete_master_nb_mfield_polygon_2023_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2023_1>;
  /** delete data from the table: "master.nb_mfield_polygon_2024_1" */
  delete_master_nb_mfield_polygon_2024_1?: Maybe<Master_Nb_Mfield_Polygon_2024_1_Mutation_Response>;
  /** delete single row from the table: "master.nb_mfield_polygon_2024_1" */
  delete_master_nb_mfield_polygon_2024_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2024_1>;
  /** delete data from the table: "master.nb_mfield_version" */
  delete_master_nb_mfield_version?: Maybe<Master_Nb_Mfield_Version_Mutation_Response>;
  /** delete single row from the table: "master.nb_mfield_version" */
  delete_master_nb_mfield_version_by_pk?: Maybe<Master_Nb_Mfield_Version>;
  /** delete data from the table: "master.nb_postgis_version" */
  delete_master_nb_postgis_version?: Maybe<Master_Nb_Postgis_Version_Mutation_Response>;
  /** delete single row from the table: "master.nb_postgis_version" */
  delete_master_nb_postgis_version_by_pk?: Maybe<Master_Nb_Postgis_Version>;
  /** delete data from the table: "master.nb_weather_attr" */
  delete_master_nb_weather_attr?: Maybe<Master_Nb_Weather_Attr_Mutation_Response>;
  /** delete single row from the table: "master.nb_weather_attr" */
  delete_master_nb_weather_attr_by_pk?: Maybe<Master_Nb_Weather_Attr>;
  /** delete data from the table: "master.nb_weather_data" */
  delete_master_nb_weather_data?: Maybe<Master_Nb_Weather_Data_Mutation_Response>;
  /** delete single row from the table: "master.nb_weather_data" */
  delete_master_nb_weather_data_by_pk?: Maybe<Master_Nb_Weather_Data>;
  /** delete data from the table: "nb_actual_crop_yeild" */
  delete_nb_actual_crop_yeild?: Maybe<Nb_Actual_Crop_Yeild_Mutation_Response>;
  /** delete single row from the table: "nb_actual_crop_yeild" */
  delete_nb_actual_crop_yeild_by_pk?: Maybe<Nb_Actual_Crop_Yeild>;
  /** delete data from the table: "nb_area_mesh" */
  delete_nb_area_mesh?: Maybe<Nb_Area_Mesh_Mutation_Response>;
  /** delete single row from the table: "nb_area_mesh" */
  delete_nb_area_mesh_by_pk?: Maybe<Nb_Area_Mesh>;
  /** delete data from the table: "nb_company" */
  delete_nb_company?: Maybe<Nb_Company_Mutation_Response>;
  /** delete single row from the table: "nb_company" */
  delete_nb_company_by_pk?: Maybe<Nb_Company>;
  /** delete data from the table: "nb_company_info" */
  delete_nb_company_info?: Maybe<Nb_Company_Info_Mutation_Response>;
  /** delete single row from the table: "nb_company_info" */
  delete_nb_company_info_by_pk?: Maybe<Nb_Company_Info>;
  /** delete data from the table: "nb_conditioner_work" */
  delete_nb_conditioner_work?: Maybe<Nb_Conditioner_Work_Mutation_Response>;
  /** delete single row from the table: "nb_conditioner_work" */
  delete_nb_conditioner_work_by_pk?: Maybe<Nb_Conditioner_Work>;
  /** delete data from the table: "nb_cplan_imsensing_link" */
  delete_nb_cplan_imsensing_link?: Maybe<Nb_Cplan_Imsensing_Link_Mutation_Response>;
  /** delete single row from the table: "nb_cplan_imsensing_link" */
  delete_nb_cplan_imsensing_link_by_pk?: Maybe<Nb_Cplan_Imsensing_Link>;
  /** delete data from the table: "nb_crop" */
  delete_nb_crop?: Maybe<Nb_Crop_Mutation_Response>;
  /** delete single row from the table: "nb_crop" */
  delete_nb_crop_by_pk?: Maybe<Nb_Crop>;
  /** delete data from the table: "nb_crop_company_link" */
  delete_nb_crop_company_link?: Maybe<Nb_Crop_Company_Link_Mutation_Response>;
  /** delete single row from the table: "nb_crop_company_link" */
  delete_nb_crop_company_link_by_pk?: Maybe<Nb_Crop_Company_Link>;
  /** delete data from the table: "nb_crop_method_link" */
  delete_nb_crop_method_link?: Maybe<Nb_Crop_Method_Link_Mutation_Response>;
  /** delete single row from the table: "nb_crop_method_link" */
  delete_nb_crop_method_link_by_pk?: Maybe<Nb_Crop_Method_Link>;
  /** delete data from the table: "nb_cultivar" */
  delete_nb_cultivar?: Maybe<Nb_Cultivar_Mutation_Response>;
  /** delete single row from the table: "nb_cultivar" */
  delete_nb_cultivar_by_pk?: Maybe<Nb_Cultivar>;
  /** delete data from the table: "nb_cultivation_method" */
  delete_nb_cultivation_method?: Maybe<Nb_Cultivation_Method_Mutation_Response>;
  /** delete single row from the table: "nb_cultivation_method" */
  delete_nb_cultivation_method_by_pk?: Maybe<Nb_Cultivation_Method>;
  /** delete data from the table: "nb_cultivation_plan" */
  delete_nb_cultivation_plan?: Maybe<Nb_Cultivation_Plan_Mutation_Response>;
  /** delete single row from the table: "nb_cultivation_plan" */
  delete_nb_cultivation_plan_by_pk?: Maybe<Nb_Cultivation_Plan>;
  /** delete data from the table: "nb_demo_task_type" */
  delete_nb_demo_task_type?: Maybe<Nb_Demo_Task_Type_Mutation_Response>;
  /** delete single row from the table: "nb_demo_task_type" */
  delete_nb_demo_task_type_by_pk?: Maybe<Nb_Demo_Task_Type>;
  /** delete data from the table: "nb_demo_user_location" */
  delete_nb_demo_user_location?: Maybe<Nb_Demo_User_Location_Mutation_Response>;
  /** delete single row from the table: "nb_demo_user_location" */
  delete_nb_demo_user_location_by_pk?: Maybe<Nb_Demo_User_Location>;
  /** delete data from the table: "nb_demo_user_name" */
  delete_nb_demo_user_name?: Maybe<Nb_Demo_User_Name_Mutation_Response>;
  /** delete single row from the table: "nb_demo_user_name" */
  delete_nb_demo_user_name_by_pk?: Maybe<Nb_Demo_User_Name>;
  /** delete data from the table: "nb_est_working_load" */
  delete_nb_est_working_load?: Maybe<Nb_Est_Working_Load_Mutation_Response>;
  /** delete single row from the table: "nb_est_working_load" */
  delete_nb_est_working_load_by_pk?: Maybe<Nb_Est_Working_Load>;
  /** delete data from the table: "nb_fertilization_method" */
  delete_nb_fertilization_method?: Maybe<Nb_Fertilization_Method_Mutation_Response>;
  /** delete single row from the table: "nb_fertilization_method" */
  delete_nb_fertilization_method_by_pk?: Maybe<Nb_Fertilization_Method>;
  /** delete data from the table: "nb_fertilization_work" */
  delete_nb_fertilization_work?: Maybe<Nb_Fertilization_Work_Mutation_Response>;
  /** delete single row from the table: "nb_fertilization_work" */
  delete_nb_fertilization_work_by_pk?: Maybe<Nb_Fertilization_Work>;
  /** delete data from the table: "nb_fertilizer" */
  delete_nb_fertilizer?: Maybe<Nb_Fertilizer_Mutation_Response>;
  /** delete single row from the table: "nb_fertilizer" */
  delete_nb_fertilizer_by_pk?: Maybe<Nb_Fertilizer>;
  /** delete data from the table: "nb_fertilizer_company_link" */
  delete_nb_fertilizer_company_link?: Maybe<Nb_Fertilizer_Company_Link_Mutation_Response>;
  /** delete single row from the table: "nb_fertilizer_company_link" */
  delete_nb_fertilizer_company_link_by_pk?: Maybe<Nb_Fertilizer_Company_Link>;
  /** delete data from the table: "nb_fertilizer_picture" */
  delete_nb_fertilizer_picture?: Maybe<Nb_Fertilizer_Picture_Mutation_Response>;
  /** delete single row from the table: "nb_fertilizer_picture" */
  delete_nb_fertilizer_picture_by_pk?: Maybe<Nb_Fertilizer_Picture>;
  /** delete data from the table: "nb_field_file" */
  delete_nb_field_file?: Maybe<Nb_Field_File_Mutation_Response>;
  /** delete single row from the table: "nb_field_file" */
  delete_nb_field_file_by_pk?: Maybe<Nb_Field_File>;
  /** delete data from the table: "nb_field_file_load" */
  delete_nb_field_file_load?: Maybe<Nb_Field_File_Load_Mutation_Response>;
  /** delete single row from the table: "nb_field_file_load" */
  delete_nb_field_file_load_by_pk?: Maybe<Nb_Field_File_Load>;
  /** delete data from the table: "nb_field_file_size" */
  delete_nb_field_file_size?: Maybe<Nb_Field_File_Size_Mutation_Response>;
  /** delete data from the table: "nb_field_memo" */
  delete_nb_field_memo?: Maybe<Nb_Field_Memo_Mutation_Response>;
  /** delete single row from the table: "nb_field_memo" */
  delete_nb_field_memo_by_pk?: Maybe<Nb_Field_Memo>;
  /** delete data from the table: "nb_field_orthoimg" */
  delete_nb_field_orthoimg?: Maybe<Nb_Field_Orthoimg_Mutation_Response>;
  /** delete single row from the table: "nb_field_orthoimg" */
  delete_nb_field_orthoimg_by_pk?: Maybe<Nb_Field_Orthoimg>;
  /** delete data from the table: "nb_field_orthoimg_size" */
  delete_nb_field_orthoimg_size?: Maybe<Nb_Field_Orthoimg_Size_Mutation_Response>;
  /** delete data from the table: "nb_field_photographer" */
  delete_nb_field_photographer?: Maybe<Nb_Field_Photographer_Mutation_Response>;
  /** delete single row from the table: "nb_field_photographer" */
  delete_nb_field_photographer_by_pk?: Maybe<Nb_Field_Photographer>;
  /** delete data from the table: "nb_field_picture" */
  delete_nb_field_picture?: Maybe<Nb_Field_Picture_Mutation_Response>;
  /** delete single row from the table: "nb_field_picture" */
  delete_nb_field_picture_by_pk?: Maybe<Nb_Field_Picture>;
  /** delete data from the table: "nb_field_picture_cplan_link" */
  delete_nb_field_picture_cplan_link?: Maybe<Nb_Field_Picture_Cplan_Link_Mutation_Response>;
  /** delete single row from the table: "nb_field_picture_cplan_link" */
  delete_nb_field_picture_cplan_link_by_pk?: Maybe<Nb_Field_Picture_Cplan_Link>;
  /** delete data from the table: "nb_field_picture_load" */
  delete_nb_field_picture_load?: Maybe<Nb_Field_Picture_Load_Mutation_Response>;
  /** delete single row from the table: "nb_field_picture_load" */
  delete_nb_field_picture_load_by_pk?: Maybe<Nb_Field_Picture_Load>;
  /** delete data from the table: "nb_field_picture_size" */
  delete_nb_field_picture_size?: Maybe<Nb_Field_Picture_Size_Mutation_Response>;
  /** delete data from the table: "nb_field_polygon_attr" */
  delete_nb_field_polygon_attr?: Maybe<Nb_Field_Polygon_Attr_Mutation_Response>;
  /** delete single row from the table: "nb_field_polygon_attr" */
  delete_nb_field_polygon_attr_by_pk?: Maybe<Nb_Field_Polygon_Attr>;
  /** delete data from the table: "nb_field_property" */
  delete_nb_field_property?: Maybe<Nb_Field_Property_Mutation_Response>;
  /** delete single row from the table: "nb_field_property" */
  delete_nb_field_property_by_pk?: Maybe<Nb_Field_Property>;
  /** delete data from the table: "nb_field_sensing" */
  delete_nb_field_sensing?: Maybe<Nb_Field_Sensing_Mutation_Response>;
  /** delete single row from the table: "nb_field_sensing" */
  delete_nb_field_sensing_by_pk?: Maybe<Nb_Field_Sensing>;
  /** delete data from the table: "nb_field_sensing_data" */
  delete_nb_field_sensing_data?: Maybe<Nb_Field_Sensing_Data_Mutation_Response>;
  /** delete single row from the table: "nb_field_sensing_data" */
  delete_nb_field_sensing_data_by_pk?: Maybe<Nb_Field_Sensing_Data>;
  /** delete data from the table: "nb_field_water_gate" */
  delete_nb_field_water_gate?: Maybe<Nb_Field_Water_Gate_Mutation_Response>;
  /** delete single row from the table: "nb_field_water_gate" */
  delete_nb_field_water_gate_by_pk?: Maybe<Nb_Field_Water_Gate>;
  /** delete data from the table: "nb_field_water_gate_status" */
  delete_nb_field_water_gate_status?: Maybe<Nb_Field_Water_Gate_Status_Mutation_Response>;
  /** delete single row from the table: "nb_field_water_gate_status" */
  delete_nb_field_water_gate_status_by_pk?: Maybe<Nb_Field_Water_Gate_Status>;
  /** delete data from the table: "nb_file_size" */
  delete_nb_file_size?: Maybe<Nb_File_Size_Mutation_Response>;
  /** delete single row from the table: "nb_file_size" */
  delete_nb_file_size_by_pk?: Maybe<Nb_File_Size>;
  /** delete data from the table: "nb_file_type" */
  delete_nb_file_type?: Maybe<Nb_File_Type_Mutation_Response>;
  /** delete single row from the table: "nb_file_type" */
  delete_nb_file_type_by_pk?: Maybe<Nb_File_Type>;
  /** delete data from the table: "nb_finished_ufield_work" */
  delete_nb_finished_ufield_work?: Maybe<Nb_Finished_Ufield_Work_Mutation_Response>;
  /** delete single row from the table: "nb_finished_ufield_work" */
  delete_nb_finished_ufield_work_by_pk?: Maybe<Nb_Finished_Ufield_Work>;
  /** delete data from the table: "nb_image_sensing_attr" */
  delete_nb_image_sensing_attr?: Maybe<Nb_Image_Sensing_Attr_Mutation_Response>;
  /** delete single row from the table: "nb_image_sensing_attr" */
  delete_nb_image_sensing_attr_by_pk?: Maybe<Nb_Image_Sensing_Attr>;
  /** delete data from the table: "nb_imsensing_colormap" */
  delete_nb_imsensing_colormap?: Maybe<Nb_Imsensing_Colormap_Mutation_Response>;
  /** delete single row from the table: "nb_imsensing_colormap" */
  delete_nb_imsensing_colormap_by_pk?: Maybe<Nb_Imsensing_Colormap>;
  /** delete data from the table: "nb_log_user_position" */
  delete_nb_log_user_position?: Maybe<Nb_Log_User_Position_Mutation_Response>;
  /** delete single row from the table: "nb_log_user_position" */
  delete_nb_log_user_position_by_pk?: Maybe<Nb_Log_User_Position>;
  /** delete data from the table: "nb_maff_field" */
  delete_nb_maff_field?: Maybe<Nb_Maff_Field_Mutation_Response>;
  /** delete single row from the table: "nb_maff_field" */
  delete_nb_maff_field_by_pk?: Maybe<Nb_Maff_Field>;
  /** delete data from the table: "nb_maff_field_link" */
  delete_nb_maff_field_link?: Maybe<Nb_Maff_Field_Link_Mutation_Response>;
  /** delete single row from the table: "nb_maff_field_link" */
  delete_nb_maff_field_link_by_pk?: Maybe<Nb_Maff_Field_Link>;
  /** delete data from the table: "nb_memo_file_link" */
  delete_nb_memo_file_link?: Maybe<Nb_Memo_File_Link_Mutation_Response>;
  /** delete single row from the table: "nb_memo_file_link" */
  delete_nb_memo_file_link_by_pk?: Maybe<Nb_Memo_File_Link>;
  /** delete data from the table: "nb_memo_picture_link" */
  delete_nb_memo_picture_link?: Maybe<Nb_Memo_Picture_Link_Mutation_Response>;
  /** delete single row from the table: "nb_memo_picture_link" */
  delete_nb_memo_picture_link_by_pk?: Maybe<Nb_Memo_Picture_Link>;
  /** delete data from the table: "nb_memo_ufg_link" */
  delete_nb_memo_ufg_link?: Maybe<Nb_Memo_Ufg_Link_Mutation_Response>;
  /** delete single row from the table: "nb_memo_ufg_link" */
  delete_nb_memo_ufg_link_by_pk?: Maybe<Nb_Memo_Ufg_Link>;
  /** delete data from the table: "nb_memo_ufield_link" */
  delete_nb_memo_ufield_link?: Maybe<Nb_Memo_Ufield_Link_Mutation_Response>;
  /** delete single row from the table: "nb_memo_ufield_link" */
  delete_nb_memo_ufield_link_by_pk?: Maybe<Nb_Memo_Ufield_Link>;
  /** delete data from the table: "nb_mfield" */
  delete_nb_mfield?: Maybe<Nb_Mfield_Mutation_Response>;
  /** delete single row from the table: "nb_mfield" */
  delete_nb_mfield_by_pk?: Maybe<Nb_Mfield>;
  /** delete data from the table: "nb_mfield_link" */
  delete_nb_mfield_link?: Maybe<Nb_Mfield_Link_Mutation_Response>;
  /** delete single row from the table: "nb_mfield_link" */
  delete_nb_mfield_link_by_pk?: Maybe<Nb_Mfield_Link>;
  /** delete data from the table: "nb_performed_work" */
  delete_nb_performed_work?: Maybe<Nb_Performed_Work_Mutation_Response>;
  /** delete single row from the table: "nb_performed_work" */
  delete_nb_performed_work_by_pk?: Maybe<Nb_Performed_Work>;
  /** delete data from the table: "nb_pesticide" */
  delete_nb_pesticide?: Maybe<Nb_Pesticide_Mutation_Response>;
  /** delete single row from the table: "nb_pesticide" */
  delete_nb_pesticide_by_pk?: Maybe<Nb_Pesticide>;
  /** delete data from the table: "nb_pesticide_company_link" */
  delete_nb_pesticide_company_link?: Maybe<Nb_Pesticide_Company_Link_Mutation_Response>;
  /** delete single row from the table: "nb_pesticide_company_link" */
  delete_nb_pesticide_company_link_by_pk?: Maybe<Nb_Pesticide_Company_Link>;
  /** delete data from the table: "nb_pesticide_ingredient" */
  delete_nb_pesticide_ingredient?: Maybe<Nb_Pesticide_Ingredient_Mutation_Response>;
  /** delete single row from the table: "nb_pesticide_ingredient" */
  delete_nb_pesticide_ingredient_by_pk?: Maybe<Nb_Pesticide_Ingredient>;
  /** delete data from the table: "nb_pesticide_spray_work" */
  delete_nb_pesticide_spray_work?: Maybe<Nb_Pesticide_Spray_Work_Mutation_Response>;
  /** delete single row from the table: "nb_pesticide_spray_work" */
  delete_nb_pesticide_spray_work_by_pk?: Maybe<Nb_Pesticide_Spray_Work>;
  /** delete data from the table: "nb_picture_analysis" */
  delete_nb_picture_analysis?: Maybe<Nb_Picture_Analysis_Mutation_Response>;
  /** delete single row from the table: "nb_picture_analysis" */
  delete_nb_picture_analysis_by_pk?: Maybe<Nb_Picture_Analysis>;
  /** delete data from the table: "nb_picture_analysis_type" */
  delete_nb_picture_analysis_type?: Maybe<Nb_Picture_Analysis_Type_Mutation_Response>;
  /** delete single row from the table: "nb_picture_analysis_type" */
  delete_nb_picture_analysis_type_by_pk?: Maybe<Nb_Picture_Analysis_Type>;
  /** delete data from the table: "nb_raster_image_sensing" */
  delete_nb_raster_image_sensing?: Maybe<Nb_Raster_Image_Sensing_Mutation_Response>;
  /** delete single row from the table: "nb_raster_image_sensing" */
  delete_nb_raster_image_sensing_by_pk?: Maybe<Nb_Raster_Image_Sensing>;
  /** delete data from the table: "nb_raster_ims_size" */
  delete_nb_raster_ims_size?: Maybe<Nb_Raster_Ims_Size_Mutation_Response>;
  /** delete data from the table: "nb_soil_chara_map" */
  delete_nb_soil_chara_map?: Maybe<Nb_Soil_Chara_Map_Mutation_Response>;
  /** delete single row from the table: "nb_soil_chara_map" */
  delete_nb_soil_chara_map_by_pk?: Maybe<Nb_Soil_Chara_Map>;
  /** delete data from the table: "nb_soil_chara_map_sellist" */
  delete_nb_soil_chara_map_sellist?: Maybe<Nb_Soil_Chara_Map_Sellist_Mutation_Response>;
  /** delete single row from the table: "nb_soil_chara_map_sellist" */
  delete_nb_soil_chara_map_sellist_by_pk?: Maybe<Nb_Soil_Chara_Map_Sellist>;
  /** delete data from the table: "nb_soil_characteristic" */
  delete_nb_soil_characteristic?: Maybe<Nb_Soil_Characteristic_Mutation_Response>;
  /** delete single row from the table: "nb_soil_characteristic" */
  delete_nb_soil_characteristic_by_pk?: Maybe<Nb_Soil_Characteristic>;
  /** delete data from the table: "nb_soil_chemistry" */
  delete_nb_soil_chemistry?: Maybe<Nb_Soil_Chemistry_Mutation_Response>;
  /** delete single row from the table: "nb_soil_chemistry" */
  delete_nb_soil_chemistry_by_pk?: Maybe<Nb_Soil_Chemistry>;
  /** delete data from the table: "nb_soil_chemistry_proper_range" */
  delete_nb_soil_chemistry_proper_range?: Maybe<Nb_Soil_Chemistry_Proper_Range_Mutation_Response>;
  /** delete single row from the table: "nb_soil_chemistry_proper_range" */
  delete_nb_soil_chemistry_proper_range_by_pk?: Maybe<Nb_Soil_Chemistry_Proper_Range>;
  /** delete data from the table: "nb_soil_chemistry_standards" */
  delete_nb_soil_chemistry_standards?: Maybe<Nb_Soil_Chemistry_Standards_Mutation_Response>;
  /** delete single row from the table: "nb_soil_chemistry_standards" */
  delete_nb_soil_chemistry_standards_by_pk?: Maybe<Nb_Soil_Chemistry_Standards>;
  /** delete data from the table: "nb_soil_chemistry_suitable_range" */
  delete_nb_soil_chemistry_suitable_range?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Mutation_Response>;
  /** delete single row from the table: "nb_soil_chemistry_suitable_range" */
  delete_nb_soil_chemistry_suitable_range_by_pk?: Maybe<Nb_Soil_Chemistry_Suitable_Range>;
  /** delete data from the table: "nb_soil_chemistry_value" */
  delete_nb_soil_chemistry_value?: Maybe<Nb_Soil_Chemistry_Value_Mutation_Response>;
  /** delete single row from the table: "nb_soil_chemistry_value" */
  delete_nb_soil_chemistry_value_by_pk?: Maybe<Nb_Soil_Chemistry_Value>;
  /** delete data from the table: "nb_soil_explanation" */
  delete_nb_soil_explanation?: Maybe<Nb_Soil_Explanation_Mutation_Response>;
  /** delete single row from the table: "nb_soil_explanation" */
  delete_nb_soil_explanation_by_pk?: Maybe<Nb_Soil_Explanation>;
  /** delete data from the table: "nb_soil_physics" */
  delete_nb_soil_physics?: Maybe<Nb_Soil_Physics_Mutation_Response>;
  /** delete single row from the table: "nb_soil_physics" */
  delete_nb_soil_physics_by_pk?: Maybe<Nb_Soil_Physics>;
  /** delete data from the table: "nb_task_type" */
  delete_nb_task_type?: Maybe<Nb_Task_Type_Mutation_Response>;
  /** delete single row from the table: "nb_task_type" */
  delete_nb_task_type_by_pk?: Maybe<Nb_Task_Type>;
  /** delete data from the table: "nb_tasktype_company_link" */
  delete_nb_tasktype_company_link?: Maybe<Nb_Tasktype_Company_Link_Mutation_Response>;
  /** delete single row from the table: "nb_tasktype_company_link" */
  delete_nb_tasktype_company_link_by_pk?: Maybe<Nb_Tasktype_Company_Link>;
  /** delete data from the table: "nb_ufattr_link" */
  delete_nb_ufattr_link?: Maybe<Nb_Ufattr_Link_Mutation_Response>;
  /** delete single row from the table: "nb_ufattr_link" */
  delete_nb_ufattr_link_by_pk?: Maybe<Nb_Ufattr_Link>;
  /** delete data from the table: "nb_ufg_link" */
  delete_nb_ufg_link?: Maybe<Nb_Ufg_Link_Mutation_Response>;
  /** delete single row from the table: "nb_ufg_link" */
  delete_nb_ufg_link_by_pk?: Maybe<Nb_Ufg_Link>;
  /** delete data from the table: "nb_ufg_member_link" */
  delete_nb_ufg_member_link?: Maybe<Nb_Ufg_Member_Link_Mutation_Response>;
  /** delete single row from the table: "nb_ufg_member_link" */
  delete_nb_ufg_member_link_by_pk?: Maybe<Nb_Ufg_Member_Link>;
  /** delete data from the table: "nb_ufield" */
  delete_nb_ufield?: Maybe<Nb_Ufield_Mutation_Response>;
  /** delete data from the table: "nb_ufield_attribute" */
  delete_nb_ufield_attribute?: Maybe<Nb_Ufield_Attribute_Mutation_Response>;
  /** delete single row from the table: "nb_ufield_attribute" */
  delete_nb_ufield_attribute_by_pk?: Maybe<Nb_Ufield_Attribute>;
  /** delete single row from the table: "nb_ufield" */
  delete_nb_ufield_by_pk?: Maybe<Nb_Ufield>;
  /** delete data from the table: "nb_ufield_company_link" */
  delete_nb_ufield_company_link?: Maybe<Nb_Ufield_Company_Link_Mutation_Response>;
  /** delete single row from the table: "nb_ufield_company_link" */
  delete_nb_ufield_company_link_by_pk?: Maybe<Nb_Ufield_Company_Link>;
  /** delete data from the table: "nb_ufield_group" */
  delete_nb_ufield_group?: Maybe<Nb_Ufield_Group_Mutation_Response>;
  /** delete single row from the table: "nb_ufield_group" */
  delete_nb_ufield_group_by_pk?: Maybe<Nb_Ufield_Group>;
  /** delete data from the table: "nb_ufield_history" */
  delete_nb_ufield_history?: Maybe<Nb_Ufield_History_Mutation_Response>;
  /** delete single row from the table: "nb_ufield_history" */
  delete_nb_ufield_history_by_pk?: Maybe<Nb_Ufield_History>;
  /** delete data from the table: "nb_vector_image_sensing" */
  delete_nb_vector_image_sensing?: Maybe<Nb_Vector_Image_Sensing_Mutation_Response>;
  /** delete single row from the table: "nb_vector_image_sensing" */
  delete_nb_vector_image_sensing_by_pk?: Maybe<Nb_Vector_Image_Sensing>;
  /** delete data from the table: "nb_vector_ims_size" */
  delete_nb_vector_ims_size?: Maybe<Nb_Vector_Ims_Size_Mutation_Response>;
  /** delete data from the table: "nb_water_gate_status_cplan_link" */
  delete_nb_water_gate_status_cplan_link?: Maybe<Nb_Water_Gate_Status_Cplan_Link_Mutation_Response>;
  /** delete single row from the table: "nb_water_gate_status_cplan_link" */
  delete_nb_water_gate_status_cplan_link_by_pk?: Maybe<Nb_Water_Gate_Status_Cplan_Link>;
  /** delete data from the table: "nb_weather_attr" */
  delete_nb_weather_attr?: Maybe<Nb_Weather_Attr_Mutation_Response>;
  /** delete single row from the table: "nb_weather_attr" */
  delete_nb_weather_attr_by_pk?: Maybe<Nb_Weather_Attr>;
  /** delete data from the table: "nb_weather_data" */
  delete_nb_weather_data?: Maybe<Nb_Weather_Data_Mutation_Response>;
  /** delete single row from the table: "nb_weather_data" */
  delete_nb_weather_data_by_pk?: Maybe<Nb_Weather_Data>;
  /** delete data from the table: "nb_wide_orthoimg" */
  delete_nb_wide_orthoimg?: Maybe<Nb_Wide_Orthoimg_Mutation_Response>;
  /** delete single row from the table: "nb_wide_orthoimg" */
  delete_nb_wide_orthoimg_by_pk?: Maybe<Nb_Wide_Orthoimg>;
  /** delete data from the table: "nb_wide_orthoimg_size" */
  delete_nb_wide_orthoimg_size?: Maybe<Nb_Wide_Orthoimg_Size_Mutation_Response>;
  /** delete data from the table: "nb_working_machine" */
  delete_nb_working_machine?: Maybe<Nb_Working_Machine_Mutation_Response>;
  /** delete single row from the table: "nb_working_machine" */
  delete_nb_working_machine_by_pk?: Maybe<Nb_Working_Machine>;
  /** delete data from the table: "nbb_picture_analysis_status" */
  delete_nbb_picture_analysis_status?: Maybe<Nbb_Picture_Analysis_Status_Mutation_Response>;
  /** delete single row from the table: "nbb_picture_analysis_status" */
  delete_nbb_picture_analysis_status_by_pk?: Maybe<Nbb_Picture_Analysis_Status>;
  /** delete data from the table: "nbbe_microclimate" */
  delete_nbbe_microclimate?: Maybe<Nbbe_Microclimate_Mutation_Response>;
  /** delete single row from the table: "nbbe_microclimate" */
  delete_nbbe_microclimate_by_pk?: Maybe<Nbbe_Microclimate>;
  /** delete data from the table: "nbbe_orthoimg_type" */
  delete_nbbe_orthoimg_type?: Maybe<Nbbe_Orthoimg_Type_Mutation_Response>;
  /** delete single row from the table: "nbbe_orthoimg_type" */
  delete_nbbe_orthoimg_type_by_pk?: Maybe<Nbbe_Orthoimg_Type>;
  /** delete data from the table: "nbbe_weather_type" */
  delete_nbbe_weather_type?: Maybe<Nbbe_Weather_Type_Mutation_Response>;
  /** delete single row from the table: "nbbe_weather_type" */
  delete_nbbe_weather_type_by_pk?: Maybe<Nbbe_Weather_Type>;
  /** delete data from the table: "nbpm_authorization" */
  delete_nbpm_authorization?: Maybe<Nbpm_Authorization_Mutation_Response>;
  /** delete single row from the table: "nbpm_authorization" */
  delete_nbpm_authorization_by_pk?: Maybe<Nbpm_Authorization>;
  /** delete data from the table: "nbpm_contract_plan" */
  delete_nbpm_contract_plan?: Maybe<Nbpm_Contract_Plan_Mutation_Response>;
  /** delete single row from the table: "nbpm_contract_plan" */
  delete_nbpm_contract_plan_by_pk?: Maybe<Nbpm_Contract_Plan>;
  /** delete data from the table: "nbpm_contract_plan_link" */
  delete_nbpm_contract_plan_link?: Maybe<Nbpm_Contract_Plan_Link_Mutation_Response>;
  /** delete single row from the table: "nbpm_contract_plan_link" */
  delete_nbpm_contract_plan_link_by_pk?: Maybe<Nbpm_Contract_Plan_Link>;
  /** delete data from the table: "nbpm_imsattr_authorization_link" */
  delete_nbpm_imsattr_authorization_link?: Maybe<Nbpm_Imsattr_Authorization_Link_Mutation_Response>;
  /** delete single row from the table: "nbpm_imsattr_authorization_link" */
  delete_nbpm_imsattr_authorization_link_by_pk?: Maybe<Nbpm_Imsattr_Authorization_Link>;
  /** delete data from the table: "nbpm_ipengine_authorization_link" */
  delete_nbpm_ipengine_authorization_link?: Maybe<Nbpm_Ipengine_Authorization_Link_Mutation_Response>;
  /** delete single row from the table: "nbpm_ipengine_authorization_link" */
  delete_nbpm_ipengine_authorization_link_by_pk?: Maybe<Nbpm_Ipengine_Authorization_Link>;
  /** delete data from the table: "nbpm_picanatype_authorization_link" */
  delete_nbpm_picanatype_authorization_link?: Maybe<Nbpm_Picanatype_Authorization_Link_Mutation_Response>;
  /** delete single row from the table: "nbpm_picanatype_authorization_link" */
  delete_nbpm_picanatype_authorization_link_by_pk?: Maybe<Nbpm_Picanatype_Authorization_Link>;
  /** delete data from the table: "nbpm_service_plan" */
  delete_nbpm_service_plan?: Maybe<Nbpm_Service_Plan_Mutation_Response>;
  /** delete single row from the table: "nbpm_service_plan" */
  delete_nbpm_service_plan_by_pk?: Maybe<Nbpm_Service_Plan>;
  /** delete data from the table: "nbpm_service_plan_link" */
  delete_nbpm_service_plan_link?: Maybe<Nbpm_Service_Plan_Link_Mutation_Response>;
  /** delete single row from the table: "nbpm_service_plan_link" */
  delete_nbpm_service_plan_link_by_pk?: Maybe<Nbpm_Service_Plan_Link>;
  /** delete data from the table: "nbpm_user_attr_link" */
  delete_nbpm_user_attr_link?: Maybe<Nbpm_User_Attr_Link_Mutation_Response>;
  /** delete single row from the table: "nbpm_user_attr_link" */
  delete_nbpm_user_attr_link_by_pk?: Maybe<Nbpm_User_Attr_Link>;
  /** delete data from the table: "nbpm_user_attribute" */
  delete_nbpm_user_attribute?: Maybe<Nbpm_User_Attribute_Mutation_Response>;
  /** delete single row from the table: "nbpm_user_attribute" */
  delete_nbpm_user_attribute_by_pk?: Maybe<Nbpm_User_Attribute>;
  /** delete data from the table: "nbpm_user_cp_link_link" */
  delete_nbpm_user_cp_link_link?: Maybe<Nbpm_User_Cp_Link_Link_Mutation_Response>;
  /** delete single row from the table: "nbpm_user_cp_link_link" */
  delete_nbpm_user_cp_link_link_by_pk?: Maybe<Nbpm_User_Cp_Link_Link>;
  /** delete data from the table: "nbs_ipe_run_field" */
  delete_nbs_ipe_run_field?: Maybe<Nbs_Ipe_Run_Field_Mutation_Response>;
  /** delete single row from the table: "nbs_ipe_run_field" */
  delete_nbs_ipe_run_field_by_pk?: Maybe<Nbs_Ipe_Run_Field>;
  /** delete data from the table: "nbs_ipe_run_link_field" */
  delete_nbs_ipe_run_link_field?: Maybe<Nbs_Ipe_Run_Link_Field_Mutation_Response>;
  /** delete single row from the table: "nbs_ipe_run_link_field" */
  delete_nbs_ipe_run_link_field_by_pk?: Maybe<Nbs_Ipe_Run_Link_Field>;
  /** delete data from the table: "nbs_ipe_run_link_wide" */
  delete_nbs_ipe_run_link_wide?: Maybe<Nbs_Ipe_Run_Link_Wide_Mutation_Response>;
  /** delete single row from the table: "nbs_ipe_run_link_wide" */
  delete_nbs_ipe_run_link_wide_by_pk?: Maybe<Nbs_Ipe_Run_Link_Wide>;
  /** delete data from the table: "nbs_ipe_run_wide" */
  delete_nbs_ipe_run_wide?: Maybe<Nbs_Ipe_Run_Wide_Mutation_Response>;
  /** delete single row from the table: "nbs_ipe_run_wide" */
  delete_nbs_ipe_run_wide_by_pk?: Maybe<Nbs_Ipe_Run_Wide>;
  /** delete data from the table: "nbs_multipart_upload" */
  delete_nbs_multipart_upload?: Maybe<Nbs_Multipart_Upload_Mutation_Response>;
  /** delete single row from the table: "nbs_multipart_upload" */
  delete_nbs_multipart_upload_by_pk?: Maybe<Nbs_Multipart_Upload>;
  /** delete data from the table: "nbs_wide_orthoimg_load" */
  delete_nbs_wide_orthoimg_load?: Maybe<Nbs_Wide_Orthoimg_Load_Mutation_Response>;
  /** delete single row from the table: "nbs_wide_orthoimg_load" */
  delete_nbs_wide_orthoimg_load_by_pk?: Maybe<Nbs_Wide_Orthoimg_Load>;
  /** delete data from the table: "nbtrk_context" */
  delete_nbtrk_context?: Maybe<Nbtrk_Context_Mutation_Response>;
  /** delete single row from the table: "nbtrk_context" */
  delete_nbtrk_context_by_pk?: Maybe<Nbtrk_Context>;
  /** delete data from the table: "nbtrk_eph_location" */
  delete_nbtrk_eph_location?: Maybe<Nbtrk_Eph_Location_Mutation_Response>;
  /** delete single row from the table: "nbtrk_eph_location" */
  delete_nbtrk_eph_location_by_pk?: Maybe<Nbtrk_Eph_Location>;
  /** delete data from the table: "nipe_engine_information" */
  delete_nipe_engine_information?: Maybe<Nipe_Engine_Information_Mutation_Response>;
  /** delete single row from the table: "nipe_engine_information" */
  delete_nipe_engine_information_by_pk?: Maybe<Nipe_Engine_Information>;
  /** delete data from the table: "nipe_engine_link_orthoimg_type" */
  delete_nipe_engine_link_orthoimg_type?: Maybe<Nipe_Engine_Link_Orthoimg_Type_Mutation_Response>;
  /** delete single row from the table: "nipe_engine_link_orthoimg_type" */
  delete_nipe_engine_link_orthoimg_type_by_pk?: Maybe<Nipe_Engine_Link_Orthoimg_Type>;
  /** delete data from the table: "nipe_engine_run_status" */
  delete_nipe_engine_run_status?: Maybe<Nipe_Engine_Run_Status_Mutation_Response>;
  /** delete single row from the table: "nipe_engine_run_status" */
  delete_nipe_engine_run_status_by_pk?: Maybe<Nipe_Engine_Run_Status>;
  /** delete data from the table: "nipe_run_command" */
  delete_nipe_run_command?: Maybe<Nipe_Run_Command_Mutation_Response>;
  /** delete single row from the table: "nipe_run_command" */
  delete_nipe_run_command_by_pk?: Maybe<Nipe_Run_Command>;
  fastapiCompleteMultipartUpload: CompleteMultipartUploadResponse;
  fastapiInsertAGroupInCompany: InsertAGroupInCompanyResponse;
  fastapiInsertAUfieldInCompany: InsertAUfieldInCompanyResponse;
  fastapiInsertAUfieldInCompanyV2: InsertAUfieldInCompanyResponse;
  fastapiInviteUserToTheCompany: InviteUserToTheCompanyResponse;
  fastapiLaunchIpEngineForWide: LaunchIpEngineForWideResponse;
  fastapiM2mAcceptIpeCommand: M2mSuccess;
  fastapiM2mDoneIpeCommand: M2mSuccess;
  fastapiM2mErrorIpeCommand: M2mSuccess;
  fastapiRemoveAMemberFromTheCompany: RemoveAMemberFromTheCompanyResponse;
  fastapiRemoveAUfieldGroup: RemoveAUfieldGroupResponse;
  fastapiRemoveAUfieldInCompany: RemoveAUfieldInCompanyResponse;
  fastapiRemoveFieldPicture: RemoveFieldPictureResponse;
  fastapiRemoveUfieldAttribute: RemoveUFieldAttributeResponse;
  fastapiReplyApplicationToRegisterAMemberWithTheCompany: ReplyApplicationToRegisterAMemberWithTheCompanyResponse;
  fastapiRequestAPictureAnalysis: RequestAPictureAnalysisResponse;
  fastapiS3SignedUrlFieldFile: S3SignedUrlFieldFileResponse;
  fastapiS3SignedUrlFieldPicture: S3SignedUrlFieldPictureResponse;
  fastapiS3SignedUrlWideOrthoimg: S3SignedUrlWideOrthoimgResponse;
  fastapiSetAttributesForTheUfield: SetAttributesForTheUfieldResponse;
  fastapiSwitchUserAttributeWithTheCompany: SwitchUserAttributeWithTheCompanyResponse;
  fastapiTerminateWideOrthoimgLoad: TerminateWideOrthoimgLoadResponse;
  fastapiUpdateAPictureToAUfield: UpdateAPictureToAFieldResponse;
  fastapiUpdateAUfgLink: UpdateAUfgLinkResponse;
  fastapiUpdateAUfieldFieldNameInCompany: UpdateAUfieldFieldNameInCompanyResponse;
  fastapiUpdateAUfieldFieldPolygonInCompany: UpdateAUfieldFieldPolygonInCompanyResponse;
  fastapiUpdateAUfieldGroupName: UpdateAUfieldGroupNameResponse;
  /** insert data into the table: "app_user" */
  insert_app_user?: Maybe<App_User_Mutation_Response>;
  /** insert data into the table: "app_user_active" */
  insert_app_user_active?: Maybe<App_User_Active_Mutation_Response>;
  /** insert a single row into the table: "app_user_active" */
  insert_app_user_active_one?: Maybe<App_User_Active>;
  /** insert a single row into the table: "app_user" */
  insert_app_user_one?: Maybe<App_User>;
  /** insert data into the table: "app_user_property_private" */
  insert_app_user_property_private?: Maybe<App_User_Property_Private_Mutation_Response>;
  /** insert a single row into the table: "app_user_property_private" */
  insert_app_user_property_private_one?: Maybe<App_User_Property_Private>;
  /** insert data into the table: "application_user_invitation_to_company" */
  insert_application_user_invitation_to_company?: Maybe<Application_User_Invitation_To_Company_Mutation_Response>;
  /** insert a single row into the table: "application_user_invitation_to_company" */
  insert_application_user_invitation_to_company_one?: Maybe<Application_User_Invitation_To_Company>;
  /** insert data into the table: "master.nb_area_mesh" */
  insert_master_nb_area_mesh?: Maybe<Master_Nb_Area_Mesh_Mutation_Response>;
  /** insert a single row into the table: "master.nb_area_mesh" */
  insert_master_nb_area_mesh_one?: Maybe<Master_Nb_Area_Mesh>;
  /** insert data into the table: "master.nb_integrated_farmland" */
  insert_master_nb_integrated_farmland?: Maybe<Master_Nb_Integrated_Farmland_Mutation_Response>;
  /** insert a single row into the table: "master.nb_integrated_farmland" */
  insert_master_nb_integrated_farmland_one?: Maybe<Master_Nb_Integrated_Farmland>;
  /** insert data into the table: "master.nb_mfield" */
  insert_master_nb_mfield?: Maybe<Master_Nb_Mfield_Mutation_Response>;
  /** insert a single row into the table: "master.nb_mfield" */
  insert_master_nb_mfield_one?: Maybe<Master_Nb_Mfield>;
  /** insert data into the table: "master.nb_mfield_polygon_2022_1" */
  insert_master_nb_mfield_polygon_2022_1?: Maybe<Master_Nb_Mfield_Polygon_2022_1_Mutation_Response>;
  /** insert a single row into the table: "master.nb_mfield_polygon_2022_1" */
  insert_master_nb_mfield_polygon_2022_1_one?: Maybe<Master_Nb_Mfield_Polygon_2022_1>;
  /** insert data into the table: "master.nb_mfield_polygon_2023_1" */
  insert_master_nb_mfield_polygon_2023_1?: Maybe<Master_Nb_Mfield_Polygon_2023_1_Mutation_Response>;
  /** insert a single row into the table: "master.nb_mfield_polygon_2023_1" */
  insert_master_nb_mfield_polygon_2023_1_one?: Maybe<Master_Nb_Mfield_Polygon_2023_1>;
  /** insert data into the table: "master.nb_mfield_polygon_2024_1" */
  insert_master_nb_mfield_polygon_2024_1?: Maybe<Master_Nb_Mfield_Polygon_2024_1_Mutation_Response>;
  /** insert a single row into the table: "master.nb_mfield_polygon_2024_1" */
  insert_master_nb_mfield_polygon_2024_1_one?: Maybe<Master_Nb_Mfield_Polygon_2024_1>;
  /** insert data into the table: "master.nb_mfield_version" */
  insert_master_nb_mfield_version?: Maybe<Master_Nb_Mfield_Version_Mutation_Response>;
  /** insert a single row into the table: "master.nb_mfield_version" */
  insert_master_nb_mfield_version_one?: Maybe<Master_Nb_Mfield_Version>;
  /** insert data into the table: "master.nb_postgis_version" */
  insert_master_nb_postgis_version?: Maybe<Master_Nb_Postgis_Version_Mutation_Response>;
  /** insert a single row into the table: "master.nb_postgis_version" */
  insert_master_nb_postgis_version_one?: Maybe<Master_Nb_Postgis_Version>;
  /** insert data into the table: "master.nb_weather_attr" */
  insert_master_nb_weather_attr?: Maybe<Master_Nb_Weather_Attr_Mutation_Response>;
  /** insert a single row into the table: "master.nb_weather_attr" */
  insert_master_nb_weather_attr_one?: Maybe<Master_Nb_Weather_Attr>;
  /** insert data into the table: "master.nb_weather_data" */
  insert_master_nb_weather_data?: Maybe<Master_Nb_Weather_Data_Mutation_Response>;
  /** insert a single row into the table: "master.nb_weather_data" */
  insert_master_nb_weather_data_one?: Maybe<Master_Nb_Weather_Data>;
  /** insert data into the table: "nb_actual_crop_yeild" */
  insert_nb_actual_crop_yeild?: Maybe<Nb_Actual_Crop_Yeild_Mutation_Response>;
  /** insert a single row into the table: "nb_actual_crop_yeild" */
  insert_nb_actual_crop_yeild_one?: Maybe<Nb_Actual_Crop_Yeild>;
  /** insert data into the table: "nb_area_mesh" */
  insert_nb_area_mesh?: Maybe<Nb_Area_Mesh_Mutation_Response>;
  /** insert a single row into the table: "nb_area_mesh" */
  insert_nb_area_mesh_one?: Maybe<Nb_Area_Mesh>;
  /** insert data into the table: "nb_company" */
  insert_nb_company?: Maybe<Nb_Company_Mutation_Response>;
  /** insert data into the table: "nb_company_info" */
  insert_nb_company_info?: Maybe<Nb_Company_Info_Mutation_Response>;
  /** insert a single row into the table: "nb_company_info" */
  insert_nb_company_info_one?: Maybe<Nb_Company_Info>;
  /** insert a single row into the table: "nb_company" */
  insert_nb_company_one?: Maybe<Nb_Company>;
  /** insert data into the table: "nb_conditioner_work" */
  insert_nb_conditioner_work?: Maybe<Nb_Conditioner_Work_Mutation_Response>;
  /** insert a single row into the table: "nb_conditioner_work" */
  insert_nb_conditioner_work_one?: Maybe<Nb_Conditioner_Work>;
  /** insert data into the table: "nb_cplan_imsensing_link" */
  insert_nb_cplan_imsensing_link?: Maybe<Nb_Cplan_Imsensing_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_cplan_imsensing_link" */
  insert_nb_cplan_imsensing_link_one?: Maybe<Nb_Cplan_Imsensing_Link>;
  /** insert data into the table: "nb_crop" */
  insert_nb_crop?: Maybe<Nb_Crop_Mutation_Response>;
  /** insert data into the table: "nb_crop_company_link" */
  insert_nb_crop_company_link?: Maybe<Nb_Crop_Company_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_crop_company_link" */
  insert_nb_crop_company_link_one?: Maybe<Nb_Crop_Company_Link>;
  /** insert data into the table: "nb_crop_method_link" */
  insert_nb_crop_method_link?: Maybe<Nb_Crop_Method_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_crop_method_link" */
  insert_nb_crop_method_link_one?: Maybe<Nb_Crop_Method_Link>;
  /** insert a single row into the table: "nb_crop" */
  insert_nb_crop_one?: Maybe<Nb_Crop>;
  /** insert data into the table: "nb_cultivar" */
  insert_nb_cultivar?: Maybe<Nb_Cultivar_Mutation_Response>;
  /** insert a single row into the table: "nb_cultivar" */
  insert_nb_cultivar_one?: Maybe<Nb_Cultivar>;
  /** insert data into the table: "nb_cultivation_method" */
  insert_nb_cultivation_method?: Maybe<Nb_Cultivation_Method_Mutation_Response>;
  /** insert a single row into the table: "nb_cultivation_method" */
  insert_nb_cultivation_method_one?: Maybe<Nb_Cultivation_Method>;
  /** insert data into the table: "nb_cultivation_plan" */
  insert_nb_cultivation_plan?: Maybe<Nb_Cultivation_Plan_Mutation_Response>;
  /** insert a single row into the table: "nb_cultivation_plan" */
  insert_nb_cultivation_plan_one?: Maybe<Nb_Cultivation_Plan>;
  /** insert data into the table: "nb_demo_task_type" */
  insert_nb_demo_task_type?: Maybe<Nb_Demo_Task_Type_Mutation_Response>;
  /** insert a single row into the table: "nb_demo_task_type" */
  insert_nb_demo_task_type_one?: Maybe<Nb_Demo_Task_Type>;
  /** insert data into the table: "nb_demo_user_location" */
  insert_nb_demo_user_location?: Maybe<Nb_Demo_User_Location_Mutation_Response>;
  /** insert a single row into the table: "nb_demo_user_location" */
  insert_nb_demo_user_location_one?: Maybe<Nb_Demo_User_Location>;
  /** insert data into the table: "nb_demo_user_name" */
  insert_nb_demo_user_name?: Maybe<Nb_Demo_User_Name_Mutation_Response>;
  /** insert a single row into the table: "nb_demo_user_name" */
  insert_nb_demo_user_name_one?: Maybe<Nb_Demo_User_Name>;
  /** insert data into the table: "nb_est_working_load" */
  insert_nb_est_working_load?: Maybe<Nb_Est_Working_Load_Mutation_Response>;
  /** insert a single row into the table: "nb_est_working_load" */
  insert_nb_est_working_load_one?: Maybe<Nb_Est_Working_Load>;
  /** insert data into the table: "nb_fertilization_method" */
  insert_nb_fertilization_method?: Maybe<Nb_Fertilization_Method_Mutation_Response>;
  /** insert a single row into the table: "nb_fertilization_method" */
  insert_nb_fertilization_method_one?: Maybe<Nb_Fertilization_Method>;
  /** insert data into the table: "nb_fertilization_work" */
  insert_nb_fertilization_work?: Maybe<Nb_Fertilization_Work_Mutation_Response>;
  /** insert a single row into the table: "nb_fertilization_work" */
  insert_nb_fertilization_work_one?: Maybe<Nb_Fertilization_Work>;
  /** insert data into the table: "nb_fertilizer" */
  insert_nb_fertilizer?: Maybe<Nb_Fertilizer_Mutation_Response>;
  /** insert data into the table: "nb_fertilizer_company_link" */
  insert_nb_fertilizer_company_link?: Maybe<Nb_Fertilizer_Company_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_fertilizer_company_link" */
  insert_nb_fertilizer_company_link_one?: Maybe<Nb_Fertilizer_Company_Link>;
  /** insert a single row into the table: "nb_fertilizer" */
  insert_nb_fertilizer_one?: Maybe<Nb_Fertilizer>;
  /** insert data into the table: "nb_fertilizer_picture" */
  insert_nb_fertilizer_picture?: Maybe<Nb_Fertilizer_Picture_Mutation_Response>;
  /** insert a single row into the table: "nb_fertilizer_picture" */
  insert_nb_fertilizer_picture_one?: Maybe<Nb_Fertilizer_Picture>;
  /** insert data into the table: "nb_field_file" */
  insert_nb_field_file?: Maybe<Nb_Field_File_Mutation_Response>;
  /** insert data into the table: "nb_field_file_load" */
  insert_nb_field_file_load?: Maybe<Nb_Field_File_Load_Mutation_Response>;
  /** insert a single row into the table: "nb_field_file_load" */
  insert_nb_field_file_load_one?: Maybe<Nb_Field_File_Load>;
  /** insert a single row into the table: "nb_field_file" */
  insert_nb_field_file_one?: Maybe<Nb_Field_File>;
  /** insert data into the table: "nb_field_file_size" */
  insert_nb_field_file_size?: Maybe<Nb_Field_File_Size_Mutation_Response>;
  /** insert a single row into the table: "nb_field_file_size" */
  insert_nb_field_file_size_one?: Maybe<Nb_Field_File_Size>;
  /** insert data into the table: "nb_field_memo" */
  insert_nb_field_memo?: Maybe<Nb_Field_Memo_Mutation_Response>;
  /** insert a single row into the table: "nb_field_memo" */
  insert_nb_field_memo_one?: Maybe<Nb_Field_Memo>;
  /** insert data into the table: "nb_field_orthoimg" */
  insert_nb_field_orthoimg?: Maybe<Nb_Field_Orthoimg_Mutation_Response>;
  /** insert a single row into the table: "nb_field_orthoimg" */
  insert_nb_field_orthoimg_one?: Maybe<Nb_Field_Orthoimg>;
  /** insert data into the table: "nb_field_orthoimg_size" */
  insert_nb_field_orthoimg_size?: Maybe<Nb_Field_Orthoimg_Size_Mutation_Response>;
  /** insert a single row into the table: "nb_field_orthoimg_size" */
  insert_nb_field_orthoimg_size_one?: Maybe<Nb_Field_Orthoimg_Size>;
  /** insert data into the table: "nb_field_photographer" */
  insert_nb_field_photographer?: Maybe<Nb_Field_Photographer_Mutation_Response>;
  /** insert a single row into the table: "nb_field_photographer" */
  insert_nb_field_photographer_one?: Maybe<Nb_Field_Photographer>;
  /** insert data into the table: "nb_field_picture" */
  insert_nb_field_picture?: Maybe<Nb_Field_Picture_Mutation_Response>;
  /** insert data into the table: "nb_field_picture_cplan_link" */
  insert_nb_field_picture_cplan_link?: Maybe<Nb_Field_Picture_Cplan_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_field_picture_cplan_link" */
  insert_nb_field_picture_cplan_link_one?: Maybe<Nb_Field_Picture_Cplan_Link>;
  /** insert data into the table: "nb_field_picture_load" */
  insert_nb_field_picture_load?: Maybe<Nb_Field_Picture_Load_Mutation_Response>;
  /** insert a single row into the table: "nb_field_picture_load" */
  insert_nb_field_picture_load_one?: Maybe<Nb_Field_Picture_Load>;
  /** insert a single row into the table: "nb_field_picture" */
  insert_nb_field_picture_one?: Maybe<Nb_Field_Picture>;
  /** insert data into the table: "nb_field_picture_size" */
  insert_nb_field_picture_size?: Maybe<Nb_Field_Picture_Size_Mutation_Response>;
  /** insert a single row into the table: "nb_field_picture_size" */
  insert_nb_field_picture_size_one?: Maybe<Nb_Field_Picture_Size>;
  /** insert data into the table: "nb_field_polygon_attr" */
  insert_nb_field_polygon_attr?: Maybe<Nb_Field_Polygon_Attr_Mutation_Response>;
  /** insert a single row into the table: "nb_field_polygon_attr" */
  insert_nb_field_polygon_attr_one?: Maybe<Nb_Field_Polygon_Attr>;
  /** insert data into the table: "nb_field_property" */
  insert_nb_field_property?: Maybe<Nb_Field_Property_Mutation_Response>;
  /** insert a single row into the table: "nb_field_property" */
  insert_nb_field_property_one?: Maybe<Nb_Field_Property>;
  /** insert data into the table: "nb_field_sensing" */
  insert_nb_field_sensing?: Maybe<Nb_Field_Sensing_Mutation_Response>;
  /** insert data into the table: "nb_field_sensing_data" */
  insert_nb_field_sensing_data?: Maybe<Nb_Field_Sensing_Data_Mutation_Response>;
  /** insert a single row into the table: "nb_field_sensing_data" */
  insert_nb_field_sensing_data_one?: Maybe<Nb_Field_Sensing_Data>;
  /** insert a single row into the table: "nb_field_sensing" */
  insert_nb_field_sensing_one?: Maybe<Nb_Field_Sensing>;
  /** insert data into the table: "nb_field_water_gate" */
  insert_nb_field_water_gate?: Maybe<Nb_Field_Water_Gate_Mutation_Response>;
  /** insert a single row into the table: "nb_field_water_gate" */
  insert_nb_field_water_gate_one?: Maybe<Nb_Field_Water_Gate>;
  /** insert data into the table: "nb_field_water_gate_status" */
  insert_nb_field_water_gate_status?: Maybe<Nb_Field_Water_Gate_Status_Mutation_Response>;
  /** insert a single row into the table: "nb_field_water_gate_status" */
  insert_nb_field_water_gate_status_one?: Maybe<Nb_Field_Water_Gate_Status>;
  /** insert data into the table: "nb_file_size" */
  insert_nb_file_size?: Maybe<Nb_File_Size_Mutation_Response>;
  /** insert a single row into the table: "nb_file_size" */
  insert_nb_file_size_one?: Maybe<Nb_File_Size>;
  /** insert data into the table: "nb_file_type" */
  insert_nb_file_type?: Maybe<Nb_File_Type_Mutation_Response>;
  /** insert a single row into the table: "nb_file_type" */
  insert_nb_file_type_one?: Maybe<Nb_File_Type>;
  /** insert data into the table: "nb_finished_ufield_work" */
  insert_nb_finished_ufield_work?: Maybe<Nb_Finished_Ufield_Work_Mutation_Response>;
  /** insert a single row into the table: "nb_finished_ufield_work" */
  insert_nb_finished_ufield_work_one?: Maybe<Nb_Finished_Ufield_Work>;
  /** insert data into the table: "nb_image_sensing_attr" */
  insert_nb_image_sensing_attr?: Maybe<Nb_Image_Sensing_Attr_Mutation_Response>;
  /** insert a single row into the table: "nb_image_sensing_attr" */
  insert_nb_image_sensing_attr_one?: Maybe<Nb_Image_Sensing_Attr>;
  /** insert data into the table: "nb_imsensing_colormap" */
  insert_nb_imsensing_colormap?: Maybe<Nb_Imsensing_Colormap_Mutation_Response>;
  /** insert a single row into the table: "nb_imsensing_colormap" */
  insert_nb_imsensing_colormap_one?: Maybe<Nb_Imsensing_Colormap>;
  /** insert data into the table: "nb_log_user_position" */
  insert_nb_log_user_position?: Maybe<Nb_Log_User_Position_Mutation_Response>;
  /** insert a single row into the table: "nb_log_user_position" */
  insert_nb_log_user_position_one?: Maybe<Nb_Log_User_Position>;
  /** insert data into the table: "nb_maff_field" */
  insert_nb_maff_field?: Maybe<Nb_Maff_Field_Mutation_Response>;
  /** insert data into the table: "nb_maff_field_link" */
  insert_nb_maff_field_link?: Maybe<Nb_Maff_Field_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_maff_field_link" */
  insert_nb_maff_field_link_one?: Maybe<Nb_Maff_Field_Link>;
  /** insert a single row into the table: "nb_maff_field" */
  insert_nb_maff_field_one?: Maybe<Nb_Maff_Field>;
  /** insert data into the table: "nb_memo_file_link" */
  insert_nb_memo_file_link?: Maybe<Nb_Memo_File_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_memo_file_link" */
  insert_nb_memo_file_link_one?: Maybe<Nb_Memo_File_Link>;
  /** insert data into the table: "nb_memo_picture_link" */
  insert_nb_memo_picture_link?: Maybe<Nb_Memo_Picture_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_memo_picture_link" */
  insert_nb_memo_picture_link_one?: Maybe<Nb_Memo_Picture_Link>;
  /** insert data into the table: "nb_memo_ufg_link" */
  insert_nb_memo_ufg_link?: Maybe<Nb_Memo_Ufg_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_memo_ufg_link" */
  insert_nb_memo_ufg_link_one?: Maybe<Nb_Memo_Ufg_Link>;
  /** insert data into the table: "nb_memo_ufield_link" */
  insert_nb_memo_ufield_link?: Maybe<Nb_Memo_Ufield_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_memo_ufield_link" */
  insert_nb_memo_ufield_link_one?: Maybe<Nb_Memo_Ufield_Link>;
  /** insert data into the table: "nb_mfield" */
  insert_nb_mfield?: Maybe<Nb_Mfield_Mutation_Response>;
  /** insert data into the table: "nb_mfield_link" */
  insert_nb_mfield_link?: Maybe<Nb_Mfield_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_mfield_link" */
  insert_nb_mfield_link_one?: Maybe<Nb_Mfield_Link>;
  /** insert a single row into the table: "nb_mfield" */
  insert_nb_mfield_one?: Maybe<Nb_Mfield>;
  /** insert data into the table: "nb_performed_work" */
  insert_nb_performed_work?: Maybe<Nb_Performed_Work_Mutation_Response>;
  /** insert a single row into the table: "nb_performed_work" */
  insert_nb_performed_work_one?: Maybe<Nb_Performed_Work>;
  /** insert data into the table: "nb_pesticide" */
  insert_nb_pesticide?: Maybe<Nb_Pesticide_Mutation_Response>;
  /** insert data into the table: "nb_pesticide_company_link" */
  insert_nb_pesticide_company_link?: Maybe<Nb_Pesticide_Company_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_pesticide_company_link" */
  insert_nb_pesticide_company_link_one?: Maybe<Nb_Pesticide_Company_Link>;
  /** insert data into the table: "nb_pesticide_ingredient" */
  insert_nb_pesticide_ingredient?: Maybe<Nb_Pesticide_Ingredient_Mutation_Response>;
  /** insert a single row into the table: "nb_pesticide_ingredient" */
  insert_nb_pesticide_ingredient_one?: Maybe<Nb_Pesticide_Ingredient>;
  /** insert a single row into the table: "nb_pesticide" */
  insert_nb_pesticide_one?: Maybe<Nb_Pesticide>;
  /** insert data into the table: "nb_pesticide_spray_work" */
  insert_nb_pesticide_spray_work?: Maybe<Nb_Pesticide_Spray_Work_Mutation_Response>;
  /** insert a single row into the table: "nb_pesticide_spray_work" */
  insert_nb_pesticide_spray_work_one?: Maybe<Nb_Pesticide_Spray_Work>;
  /** insert data into the table: "nb_picture_analysis" */
  insert_nb_picture_analysis?: Maybe<Nb_Picture_Analysis_Mutation_Response>;
  /** insert a single row into the table: "nb_picture_analysis" */
  insert_nb_picture_analysis_one?: Maybe<Nb_Picture_Analysis>;
  /** insert data into the table: "nb_picture_analysis_type" */
  insert_nb_picture_analysis_type?: Maybe<Nb_Picture_Analysis_Type_Mutation_Response>;
  /** insert a single row into the table: "nb_picture_analysis_type" */
  insert_nb_picture_analysis_type_one?: Maybe<Nb_Picture_Analysis_Type>;
  /** insert data into the table: "nb_raster_image_sensing" */
  insert_nb_raster_image_sensing?: Maybe<Nb_Raster_Image_Sensing_Mutation_Response>;
  /** insert a single row into the table: "nb_raster_image_sensing" */
  insert_nb_raster_image_sensing_one?: Maybe<Nb_Raster_Image_Sensing>;
  /** insert data into the table: "nb_raster_ims_size" */
  insert_nb_raster_ims_size?: Maybe<Nb_Raster_Ims_Size_Mutation_Response>;
  /** insert a single row into the table: "nb_raster_ims_size" */
  insert_nb_raster_ims_size_one?: Maybe<Nb_Raster_Ims_Size>;
  /** insert data into the table: "nb_soil_chara_map" */
  insert_nb_soil_chara_map?: Maybe<Nb_Soil_Chara_Map_Mutation_Response>;
  /** insert a single row into the table: "nb_soil_chara_map" */
  insert_nb_soil_chara_map_one?: Maybe<Nb_Soil_Chara_Map>;
  /** insert data into the table: "nb_soil_chara_map_sellist" */
  insert_nb_soil_chara_map_sellist?: Maybe<Nb_Soil_Chara_Map_Sellist_Mutation_Response>;
  /** insert a single row into the table: "nb_soil_chara_map_sellist" */
  insert_nb_soil_chara_map_sellist_one?: Maybe<Nb_Soil_Chara_Map_Sellist>;
  /** insert data into the table: "nb_soil_characteristic" */
  insert_nb_soil_characteristic?: Maybe<Nb_Soil_Characteristic_Mutation_Response>;
  /** insert a single row into the table: "nb_soil_characteristic" */
  insert_nb_soil_characteristic_one?: Maybe<Nb_Soil_Characteristic>;
  /** insert data into the table: "nb_soil_chemistry" */
  insert_nb_soil_chemistry?: Maybe<Nb_Soil_Chemistry_Mutation_Response>;
  /** insert a single row into the table: "nb_soil_chemistry" */
  insert_nb_soil_chemistry_one?: Maybe<Nb_Soil_Chemistry>;
  /** insert data into the table: "nb_soil_chemistry_proper_range" */
  insert_nb_soil_chemistry_proper_range?: Maybe<Nb_Soil_Chemistry_Proper_Range_Mutation_Response>;
  /** insert a single row into the table: "nb_soil_chemistry_proper_range" */
  insert_nb_soil_chemistry_proper_range_one?: Maybe<Nb_Soil_Chemistry_Proper_Range>;
  /** insert data into the table: "nb_soil_chemistry_standards" */
  insert_nb_soil_chemistry_standards?: Maybe<Nb_Soil_Chemistry_Standards_Mutation_Response>;
  /** insert a single row into the table: "nb_soil_chemistry_standards" */
  insert_nb_soil_chemistry_standards_one?: Maybe<Nb_Soil_Chemistry_Standards>;
  /** insert data into the table: "nb_soil_chemistry_suitable_range" */
  insert_nb_soil_chemistry_suitable_range?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Mutation_Response>;
  /** insert a single row into the table: "nb_soil_chemistry_suitable_range" */
  insert_nb_soil_chemistry_suitable_range_one?: Maybe<Nb_Soil_Chemistry_Suitable_Range>;
  /** insert data into the table: "nb_soil_chemistry_value" */
  insert_nb_soil_chemistry_value?: Maybe<Nb_Soil_Chemistry_Value_Mutation_Response>;
  /** insert a single row into the table: "nb_soil_chemistry_value" */
  insert_nb_soil_chemistry_value_one?: Maybe<Nb_Soil_Chemistry_Value>;
  /** insert data into the table: "nb_soil_explanation" */
  insert_nb_soil_explanation?: Maybe<Nb_Soil_Explanation_Mutation_Response>;
  /** insert a single row into the table: "nb_soil_explanation" */
  insert_nb_soil_explanation_one?: Maybe<Nb_Soil_Explanation>;
  /** insert data into the table: "nb_soil_physics" */
  insert_nb_soil_physics?: Maybe<Nb_Soil_Physics_Mutation_Response>;
  /** insert a single row into the table: "nb_soil_physics" */
  insert_nb_soil_physics_one?: Maybe<Nb_Soil_Physics>;
  /** insert data into the table: "nb_task_type" */
  insert_nb_task_type?: Maybe<Nb_Task_Type_Mutation_Response>;
  /** insert a single row into the table: "nb_task_type" */
  insert_nb_task_type_one?: Maybe<Nb_Task_Type>;
  /** insert data into the table: "nb_tasktype_company_link" */
  insert_nb_tasktype_company_link?: Maybe<Nb_Tasktype_Company_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_tasktype_company_link" */
  insert_nb_tasktype_company_link_one?: Maybe<Nb_Tasktype_Company_Link>;
  /** insert data into the table: "nb_ufattr_link" */
  insert_nb_ufattr_link?: Maybe<Nb_Ufattr_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_ufattr_link" */
  insert_nb_ufattr_link_one?: Maybe<Nb_Ufattr_Link>;
  /** insert data into the table: "nb_ufg_link" */
  insert_nb_ufg_link?: Maybe<Nb_Ufg_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_ufg_link" */
  insert_nb_ufg_link_one?: Maybe<Nb_Ufg_Link>;
  /** insert data into the table: "nb_ufg_member_link" */
  insert_nb_ufg_member_link?: Maybe<Nb_Ufg_Member_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_ufg_member_link" */
  insert_nb_ufg_member_link_one?: Maybe<Nb_Ufg_Member_Link>;
  /** insert data into the table: "nb_ufield" */
  insert_nb_ufield?: Maybe<Nb_Ufield_Mutation_Response>;
  /** insert data into the table: "nb_ufield_attribute" */
  insert_nb_ufield_attribute?: Maybe<Nb_Ufield_Attribute_Mutation_Response>;
  /** insert a single row into the table: "nb_ufield_attribute" */
  insert_nb_ufield_attribute_one?: Maybe<Nb_Ufield_Attribute>;
  /** insert data into the table: "nb_ufield_company_link" */
  insert_nb_ufield_company_link?: Maybe<Nb_Ufield_Company_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_ufield_company_link" */
  insert_nb_ufield_company_link_one?: Maybe<Nb_Ufield_Company_Link>;
  /** insert data into the table: "nb_ufield_group" */
  insert_nb_ufield_group?: Maybe<Nb_Ufield_Group_Mutation_Response>;
  /** insert a single row into the table: "nb_ufield_group" */
  insert_nb_ufield_group_one?: Maybe<Nb_Ufield_Group>;
  /** insert data into the table: "nb_ufield_history" */
  insert_nb_ufield_history?: Maybe<Nb_Ufield_History_Mutation_Response>;
  /** insert a single row into the table: "nb_ufield_history" */
  insert_nb_ufield_history_one?: Maybe<Nb_Ufield_History>;
  /** insert a single row into the table: "nb_ufield" */
  insert_nb_ufield_one?: Maybe<Nb_Ufield>;
  /** insert data into the table: "nb_vector_image_sensing" */
  insert_nb_vector_image_sensing?: Maybe<Nb_Vector_Image_Sensing_Mutation_Response>;
  /** insert a single row into the table: "nb_vector_image_sensing" */
  insert_nb_vector_image_sensing_one?: Maybe<Nb_Vector_Image_Sensing>;
  /** insert data into the table: "nb_vector_ims_size" */
  insert_nb_vector_ims_size?: Maybe<Nb_Vector_Ims_Size_Mutation_Response>;
  /** insert a single row into the table: "nb_vector_ims_size" */
  insert_nb_vector_ims_size_one?: Maybe<Nb_Vector_Ims_Size>;
  /** insert data into the table: "nb_water_gate_status_cplan_link" */
  insert_nb_water_gate_status_cplan_link?: Maybe<Nb_Water_Gate_Status_Cplan_Link_Mutation_Response>;
  /** insert a single row into the table: "nb_water_gate_status_cplan_link" */
  insert_nb_water_gate_status_cplan_link_one?: Maybe<Nb_Water_Gate_Status_Cplan_Link>;
  /** insert data into the table: "nb_weather_attr" */
  insert_nb_weather_attr?: Maybe<Nb_Weather_Attr_Mutation_Response>;
  /** insert a single row into the table: "nb_weather_attr" */
  insert_nb_weather_attr_one?: Maybe<Nb_Weather_Attr>;
  /** insert data into the table: "nb_weather_data" */
  insert_nb_weather_data?: Maybe<Nb_Weather_Data_Mutation_Response>;
  /** insert a single row into the table: "nb_weather_data" */
  insert_nb_weather_data_one?: Maybe<Nb_Weather_Data>;
  /** insert data into the table: "nb_wide_orthoimg" */
  insert_nb_wide_orthoimg?: Maybe<Nb_Wide_Orthoimg_Mutation_Response>;
  /** insert a single row into the table: "nb_wide_orthoimg" */
  insert_nb_wide_orthoimg_one?: Maybe<Nb_Wide_Orthoimg>;
  /** insert data into the table: "nb_wide_orthoimg_size" */
  insert_nb_wide_orthoimg_size?: Maybe<Nb_Wide_Orthoimg_Size_Mutation_Response>;
  /** insert a single row into the table: "nb_wide_orthoimg_size" */
  insert_nb_wide_orthoimg_size_one?: Maybe<Nb_Wide_Orthoimg_Size>;
  /** insert data into the table: "nb_working_machine" */
  insert_nb_working_machine?: Maybe<Nb_Working_Machine_Mutation_Response>;
  /** insert a single row into the table: "nb_working_machine" */
  insert_nb_working_machine_one?: Maybe<Nb_Working_Machine>;
  /** insert data into the table: "nbb_picture_analysis_status" */
  insert_nbb_picture_analysis_status?: Maybe<Nbb_Picture_Analysis_Status_Mutation_Response>;
  /** insert a single row into the table: "nbb_picture_analysis_status" */
  insert_nbb_picture_analysis_status_one?: Maybe<Nbb_Picture_Analysis_Status>;
  /** insert data into the table: "nbbe_microclimate" */
  insert_nbbe_microclimate?: Maybe<Nbbe_Microclimate_Mutation_Response>;
  /** insert a single row into the table: "nbbe_microclimate" */
  insert_nbbe_microclimate_one?: Maybe<Nbbe_Microclimate>;
  /** insert data into the table: "nbbe_orthoimg_type" */
  insert_nbbe_orthoimg_type?: Maybe<Nbbe_Orthoimg_Type_Mutation_Response>;
  /** insert a single row into the table: "nbbe_orthoimg_type" */
  insert_nbbe_orthoimg_type_one?: Maybe<Nbbe_Orthoimg_Type>;
  /** insert data into the table: "nbbe_weather_type" */
  insert_nbbe_weather_type?: Maybe<Nbbe_Weather_Type_Mutation_Response>;
  /** insert a single row into the table: "nbbe_weather_type" */
  insert_nbbe_weather_type_one?: Maybe<Nbbe_Weather_Type>;
  /** insert data into the table: "nbpm_authorization" */
  insert_nbpm_authorization?: Maybe<Nbpm_Authorization_Mutation_Response>;
  /** insert a single row into the table: "nbpm_authorization" */
  insert_nbpm_authorization_one?: Maybe<Nbpm_Authorization>;
  /** insert data into the table: "nbpm_contract_plan" */
  insert_nbpm_contract_plan?: Maybe<Nbpm_Contract_Plan_Mutation_Response>;
  /** insert data into the table: "nbpm_contract_plan_link" */
  insert_nbpm_contract_plan_link?: Maybe<Nbpm_Contract_Plan_Link_Mutation_Response>;
  /** insert a single row into the table: "nbpm_contract_plan_link" */
  insert_nbpm_contract_plan_link_one?: Maybe<Nbpm_Contract_Plan_Link>;
  /** insert a single row into the table: "nbpm_contract_plan" */
  insert_nbpm_contract_plan_one?: Maybe<Nbpm_Contract_Plan>;
  /** insert data into the table: "nbpm_imsattr_authorization_link" */
  insert_nbpm_imsattr_authorization_link?: Maybe<Nbpm_Imsattr_Authorization_Link_Mutation_Response>;
  /** insert a single row into the table: "nbpm_imsattr_authorization_link" */
  insert_nbpm_imsattr_authorization_link_one?: Maybe<Nbpm_Imsattr_Authorization_Link>;
  /** insert data into the table: "nbpm_ipengine_authorization_link" */
  insert_nbpm_ipengine_authorization_link?: Maybe<Nbpm_Ipengine_Authorization_Link_Mutation_Response>;
  /** insert a single row into the table: "nbpm_ipengine_authorization_link" */
  insert_nbpm_ipengine_authorization_link_one?: Maybe<Nbpm_Ipengine_Authorization_Link>;
  /** insert data into the table: "nbpm_picanatype_authorization_link" */
  insert_nbpm_picanatype_authorization_link?: Maybe<Nbpm_Picanatype_Authorization_Link_Mutation_Response>;
  /** insert a single row into the table: "nbpm_picanatype_authorization_link" */
  insert_nbpm_picanatype_authorization_link_one?: Maybe<Nbpm_Picanatype_Authorization_Link>;
  /** insert data into the table: "nbpm_service_plan" */
  insert_nbpm_service_plan?: Maybe<Nbpm_Service_Plan_Mutation_Response>;
  /** insert data into the table: "nbpm_service_plan_link" */
  insert_nbpm_service_plan_link?: Maybe<Nbpm_Service_Plan_Link_Mutation_Response>;
  /** insert a single row into the table: "nbpm_service_plan_link" */
  insert_nbpm_service_plan_link_one?: Maybe<Nbpm_Service_Plan_Link>;
  /** insert a single row into the table: "nbpm_service_plan" */
  insert_nbpm_service_plan_one?: Maybe<Nbpm_Service_Plan>;
  /** insert data into the table: "nbpm_user_attr_link" */
  insert_nbpm_user_attr_link?: Maybe<Nbpm_User_Attr_Link_Mutation_Response>;
  /** insert a single row into the table: "nbpm_user_attr_link" */
  insert_nbpm_user_attr_link_one?: Maybe<Nbpm_User_Attr_Link>;
  /** insert data into the table: "nbpm_user_attribute" */
  insert_nbpm_user_attribute?: Maybe<Nbpm_User_Attribute_Mutation_Response>;
  /** insert a single row into the table: "nbpm_user_attribute" */
  insert_nbpm_user_attribute_one?: Maybe<Nbpm_User_Attribute>;
  /** insert data into the table: "nbpm_user_cp_link_link" */
  insert_nbpm_user_cp_link_link?: Maybe<Nbpm_User_Cp_Link_Link_Mutation_Response>;
  /** insert a single row into the table: "nbpm_user_cp_link_link" */
  insert_nbpm_user_cp_link_link_one?: Maybe<Nbpm_User_Cp_Link_Link>;
  /** insert data into the table: "nbs_ipe_run_field" */
  insert_nbs_ipe_run_field?: Maybe<Nbs_Ipe_Run_Field_Mutation_Response>;
  /** insert a single row into the table: "nbs_ipe_run_field" */
  insert_nbs_ipe_run_field_one?: Maybe<Nbs_Ipe_Run_Field>;
  /** insert data into the table: "nbs_ipe_run_link_field" */
  insert_nbs_ipe_run_link_field?: Maybe<Nbs_Ipe_Run_Link_Field_Mutation_Response>;
  /** insert a single row into the table: "nbs_ipe_run_link_field" */
  insert_nbs_ipe_run_link_field_one?: Maybe<Nbs_Ipe_Run_Link_Field>;
  /** insert data into the table: "nbs_ipe_run_link_wide" */
  insert_nbs_ipe_run_link_wide?: Maybe<Nbs_Ipe_Run_Link_Wide_Mutation_Response>;
  /** insert a single row into the table: "nbs_ipe_run_link_wide" */
  insert_nbs_ipe_run_link_wide_one?: Maybe<Nbs_Ipe_Run_Link_Wide>;
  /** insert data into the table: "nbs_ipe_run_wide" */
  insert_nbs_ipe_run_wide?: Maybe<Nbs_Ipe_Run_Wide_Mutation_Response>;
  /** insert a single row into the table: "nbs_ipe_run_wide" */
  insert_nbs_ipe_run_wide_one?: Maybe<Nbs_Ipe_Run_Wide>;
  /** insert data into the table: "nbs_multipart_upload" */
  insert_nbs_multipart_upload?: Maybe<Nbs_Multipart_Upload_Mutation_Response>;
  /** insert a single row into the table: "nbs_multipart_upload" */
  insert_nbs_multipart_upload_one?: Maybe<Nbs_Multipart_Upload>;
  /** insert data into the table: "nbs_wide_orthoimg_load" */
  insert_nbs_wide_orthoimg_load?: Maybe<Nbs_Wide_Orthoimg_Load_Mutation_Response>;
  /** insert a single row into the table: "nbs_wide_orthoimg_load" */
  insert_nbs_wide_orthoimg_load_one?: Maybe<Nbs_Wide_Orthoimg_Load>;
  /** insert data into the table: "nbtrk_context" */
  insert_nbtrk_context?: Maybe<Nbtrk_Context_Mutation_Response>;
  /** insert a single row into the table: "nbtrk_context" */
  insert_nbtrk_context_one?: Maybe<Nbtrk_Context>;
  /** insert data into the table: "nbtrk_eph_location" */
  insert_nbtrk_eph_location?: Maybe<Nbtrk_Eph_Location_Mutation_Response>;
  /** insert a single row into the table: "nbtrk_eph_location" */
  insert_nbtrk_eph_location_one?: Maybe<Nbtrk_Eph_Location>;
  /** insert data into the table: "nipe_engine_information" */
  insert_nipe_engine_information?: Maybe<Nipe_Engine_Information_Mutation_Response>;
  /** insert a single row into the table: "nipe_engine_information" */
  insert_nipe_engine_information_one?: Maybe<Nipe_Engine_Information>;
  /** insert data into the table: "nipe_engine_link_orthoimg_type" */
  insert_nipe_engine_link_orthoimg_type?: Maybe<Nipe_Engine_Link_Orthoimg_Type_Mutation_Response>;
  /** insert a single row into the table: "nipe_engine_link_orthoimg_type" */
  insert_nipe_engine_link_orthoimg_type_one?: Maybe<Nipe_Engine_Link_Orthoimg_Type>;
  /** insert data into the table: "nipe_engine_run_status" */
  insert_nipe_engine_run_status?: Maybe<Nipe_Engine_Run_Status_Mutation_Response>;
  /** insert a single row into the table: "nipe_engine_run_status" */
  insert_nipe_engine_run_status_one?: Maybe<Nipe_Engine_Run_Status>;
  /** insert data into the table: "nipe_run_command" */
  insert_nipe_run_command?: Maybe<Nipe_Run_Command_Mutation_Response>;
  /** insert a single row into the table: "nipe_run_command" */
  insert_nipe_run_command_one?: Maybe<Nipe_Run_Command>;
  /** update data of the table: "app_user" */
  update_app_user?: Maybe<App_User_Mutation_Response>;
  /** update data of the table: "app_user_active" */
  update_app_user_active?: Maybe<App_User_Active_Mutation_Response>;
  /** update single row of the table: "app_user_active" */
  update_app_user_active_by_pk?: Maybe<App_User_Active>;
  /** update multiples rows of table: "app_user_active" */
  update_app_user_active_many?: Maybe<Array<Maybe<App_User_Active_Mutation_Response>>>;
  /** update single row of the table: "app_user" */
  update_app_user_by_pk?: Maybe<App_User>;
  /** update multiples rows of table: "app_user" */
  update_app_user_many?: Maybe<Array<Maybe<App_User_Mutation_Response>>>;
  /** update data of the table: "app_user_property_private" */
  update_app_user_property_private?: Maybe<App_User_Property_Private_Mutation_Response>;
  /** update single row of the table: "app_user_property_private" */
  update_app_user_property_private_by_pk?: Maybe<App_User_Property_Private>;
  /** update multiples rows of table: "app_user_property_private" */
  update_app_user_property_private_many?: Maybe<Array<Maybe<App_User_Property_Private_Mutation_Response>>>;
  /** update data of the table: "application_user_invitation_to_company" */
  update_application_user_invitation_to_company?: Maybe<Application_User_Invitation_To_Company_Mutation_Response>;
  /** update single row of the table: "application_user_invitation_to_company" */
  update_application_user_invitation_to_company_by_pk?: Maybe<Application_User_Invitation_To_Company>;
  /** update multiples rows of table: "application_user_invitation_to_company" */
  update_application_user_invitation_to_company_many?: Maybe<Array<Maybe<Application_User_Invitation_To_Company_Mutation_Response>>>;
  /** update data of the table: "master.nb_area_mesh" */
  update_master_nb_area_mesh?: Maybe<Master_Nb_Area_Mesh_Mutation_Response>;
  /** update single row of the table: "master.nb_area_mesh" */
  update_master_nb_area_mesh_by_pk?: Maybe<Master_Nb_Area_Mesh>;
  /** update multiples rows of table: "master.nb_area_mesh" */
  update_master_nb_area_mesh_many?: Maybe<Array<Maybe<Master_Nb_Area_Mesh_Mutation_Response>>>;
  /** update data of the table: "master.nb_integrated_farmland" */
  update_master_nb_integrated_farmland?: Maybe<Master_Nb_Integrated_Farmland_Mutation_Response>;
  /** update single row of the table: "master.nb_integrated_farmland" */
  update_master_nb_integrated_farmland_by_pk?: Maybe<Master_Nb_Integrated_Farmland>;
  /** update multiples rows of table: "master.nb_integrated_farmland" */
  update_master_nb_integrated_farmland_many?: Maybe<Array<Maybe<Master_Nb_Integrated_Farmland_Mutation_Response>>>;
  /** update data of the table: "master.nb_mfield" */
  update_master_nb_mfield?: Maybe<Master_Nb_Mfield_Mutation_Response>;
  /** update single row of the table: "master.nb_mfield" */
  update_master_nb_mfield_by_pk?: Maybe<Master_Nb_Mfield>;
  /** update multiples rows of table: "master.nb_mfield" */
  update_master_nb_mfield_many?: Maybe<Array<Maybe<Master_Nb_Mfield_Mutation_Response>>>;
  /** update data of the table: "master.nb_mfield_polygon_2022_1" */
  update_master_nb_mfield_polygon_2022_1?: Maybe<Master_Nb_Mfield_Polygon_2022_1_Mutation_Response>;
  /** update single row of the table: "master.nb_mfield_polygon_2022_1" */
  update_master_nb_mfield_polygon_2022_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2022_1>;
  /** update multiples rows of table: "master.nb_mfield_polygon_2022_1" */
  update_master_nb_mfield_polygon_2022_1_many?: Maybe<Array<Maybe<Master_Nb_Mfield_Polygon_2022_1_Mutation_Response>>>;
  /** update data of the table: "master.nb_mfield_polygon_2023_1" */
  update_master_nb_mfield_polygon_2023_1?: Maybe<Master_Nb_Mfield_Polygon_2023_1_Mutation_Response>;
  /** update single row of the table: "master.nb_mfield_polygon_2023_1" */
  update_master_nb_mfield_polygon_2023_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2023_1>;
  /** update multiples rows of table: "master.nb_mfield_polygon_2023_1" */
  update_master_nb_mfield_polygon_2023_1_many?: Maybe<Array<Maybe<Master_Nb_Mfield_Polygon_2023_1_Mutation_Response>>>;
  /** update data of the table: "master.nb_mfield_polygon_2024_1" */
  update_master_nb_mfield_polygon_2024_1?: Maybe<Master_Nb_Mfield_Polygon_2024_1_Mutation_Response>;
  /** update single row of the table: "master.nb_mfield_polygon_2024_1" */
  update_master_nb_mfield_polygon_2024_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2024_1>;
  /** update multiples rows of table: "master.nb_mfield_polygon_2024_1" */
  update_master_nb_mfield_polygon_2024_1_many?: Maybe<Array<Maybe<Master_Nb_Mfield_Polygon_2024_1_Mutation_Response>>>;
  /** update data of the table: "master.nb_mfield_version" */
  update_master_nb_mfield_version?: Maybe<Master_Nb_Mfield_Version_Mutation_Response>;
  /** update single row of the table: "master.nb_mfield_version" */
  update_master_nb_mfield_version_by_pk?: Maybe<Master_Nb_Mfield_Version>;
  /** update multiples rows of table: "master.nb_mfield_version" */
  update_master_nb_mfield_version_many?: Maybe<Array<Maybe<Master_Nb_Mfield_Version_Mutation_Response>>>;
  /** update data of the table: "master.nb_postgis_version" */
  update_master_nb_postgis_version?: Maybe<Master_Nb_Postgis_Version_Mutation_Response>;
  /** update single row of the table: "master.nb_postgis_version" */
  update_master_nb_postgis_version_by_pk?: Maybe<Master_Nb_Postgis_Version>;
  /** update multiples rows of table: "master.nb_postgis_version" */
  update_master_nb_postgis_version_many?: Maybe<Array<Maybe<Master_Nb_Postgis_Version_Mutation_Response>>>;
  /** update data of the table: "master.nb_weather_attr" */
  update_master_nb_weather_attr?: Maybe<Master_Nb_Weather_Attr_Mutation_Response>;
  /** update single row of the table: "master.nb_weather_attr" */
  update_master_nb_weather_attr_by_pk?: Maybe<Master_Nb_Weather_Attr>;
  /** update multiples rows of table: "master.nb_weather_attr" */
  update_master_nb_weather_attr_many?: Maybe<Array<Maybe<Master_Nb_Weather_Attr_Mutation_Response>>>;
  /** update data of the table: "master.nb_weather_data" */
  update_master_nb_weather_data?: Maybe<Master_Nb_Weather_Data_Mutation_Response>;
  /** update single row of the table: "master.nb_weather_data" */
  update_master_nb_weather_data_by_pk?: Maybe<Master_Nb_Weather_Data>;
  /** update multiples rows of table: "master.nb_weather_data" */
  update_master_nb_weather_data_many?: Maybe<Array<Maybe<Master_Nb_Weather_Data_Mutation_Response>>>;
  /** update data of the table: "nb_actual_crop_yeild" */
  update_nb_actual_crop_yeild?: Maybe<Nb_Actual_Crop_Yeild_Mutation_Response>;
  /** update single row of the table: "nb_actual_crop_yeild" */
  update_nb_actual_crop_yeild_by_pk?: Maybe<Nb_Actual_Crop_Yeild>;
  /** update multiples rows of table: "nb_actual_crop_yeild" */
  update_nb_actual_crop_yeild_many?: Maybe<Array<Maybe<Nb_Actual_Crop_Yeild_Mutation_Response>>>;
  /** update data of the table: "nb_area_mesh" */
  update_nb_area_mesh?: Maybe<Nb_Area_Mesh_Mutation_Response>;
  /** update single row of the table: "nb_area_mesh" */
  update_nb_area_mesh_by_pk?: Maybe<Nb_Area_Mesh>;
  /** update multiples rows of table: "nb_area_mesh" */
  update_nb_area_mesh_many?: Maybe<Array<Maybe<Nb_Area_Mesh_Mutation_Response>>>;
  /** update data of the table: "nb_company" */
  update_nb_company?: Maybe<Nb_Company_Mutation_Response>;
  /** update single row of the table: "nb_company" */
  update_nb_company_by_pk?: Maybe<Nb_Company>;
  /** update data of the table: "nb_company_info" */
  update_nb_company_info?: Maybe<Nb_Company_Info_Mutation_Response>;
  /** update single row of the table: "nb_company_info" */
  update_nb_company_info_by_pk?: Maybe<Nb_Company_Info>;
  /** update multiples rows of table: "nb_company_info" */
  update_nb_company_info_many?: Maybe<Array<Maybe<Nb_Company_Info_Mutation_Response>>>;
  /** update multiples rows of table: "nb_company" */
  update_nb_company_many?: Maybe<Array<Maybe<Nb_Company_Mutation_Response>>>;
  /** update data of the table: "nb_conditioner_work" */
  update_nb_conditioner_work?: Maybe<Nb_Conditioner_Work_Mutation_Response>;
  /** update single row of the table: "nb_conditioner_work" */
  update_nb_conditioner_work_by_pk?: Maybe<Nb_Conditioner_Work>;
  /** update multiples rows of table: "nb_conditioner_work" */
  update_nb_conditioner_work_many?: Maybe<Array<Maybe<Nb_Conditioner_Work_Mutation_Response>>>;
  /** update data of the table: "nb_cplan_imsensing_link" */
  update_nb_cplan_imsensing_link?: Maybe<Nb_Cplan_Imsensing_Link_Mutation_Response>;
  /** update single row of the table: "nb_cplan_imsensing_link" */
  update_nb_cplan_imsensing_link_by_pk?: Maybe<Nb_Cplan_Imsensing_Link>;
  /** update multiples rows of table: "nb_cplan_imsensing_link" */
  update_nb_cplan_imsensing_link_many?: Maybe<Array<Maybe<Nb_Cplan_Imsensing_Link_Mutation_Response>>>;
  /** update data of the table: "nb_crop" */
  update_nb_crop?: Maybe<Nb_Crop_Mutation_Response>;
  /** update single row of the table: "nb_crop" */
  update_nb_crop_by_pk?: Maybe<Nb_Crop>;
  /** update data of the table: "nb_crop_company_link" */
  update_nb_crop_company_link?: Maybe<Nb_Crop_Company_Link_Mutation_Response>;
  /** update single row of the table: "nb_crop_company_link" */
  update_nb_crop_company_link_by_pk?: Maybe<Nb_Crop_Company_Link>;
  /** update multiples rows of table: "nb_crop_company_link" */
  update_nb_crop_company_link_many?: Maybe<Array<Maybe<Nb_Crop_Company_Link_Mutation_Response>>>;
  /** update multiples rows of table: "nb_crop" */
  update_nb_crop_many?: Maybe<Array<Maybe<Nb_Crop_Mutation_Response>>>;
  /** update data of the table: "nb_crop_method_link" */
  update_nb_crop_method_link?: Maybe<Nb_Crop_Method_Link_Mutation_Response>;
  /** update single row of the table: "nb_crop_method_link" */
  update_nb_crop_method_link_by_pk?: Maybe<Nb_Crop_Method_Link>;
  /** update multiples rows of table: "nb_crop_method_link" */
  update_nb_crop_method_link_many?: Maybe<Array<Maybe<Nb_Crop_Method_Link_Mutation_Response>>>;
  /** update data of the table: "nb_cultivar" */
  update_nb_cultivar?: Maybe<Nb_Cultivar_Mutation_Response>;
  /** update single row of the table: "nb_cultivar" */
  update_nb_cultivar_by_pk?: Maybe<Nb_Cultivar>;
  /** update multiples rows of table: "nb_cultivar" */
  update_nb_cultivar_many?: Maybe<Array<Maybe<Nb_Cultivar_Mutation_Response>>>;
  /** update data of the table: "nb_cultivation_method" */
  update_nb_cultivation_method?: Maybe<Nb_Cultivation_Method_Mutation_Response>;
  /** update single row of the table: "nb_cultivation_method" */
  update_nb_cultivation_method_by_pk?: Maybe<Nb_Cultivation_Method>;
  /** update multiples rows of table: "nb_cultivation_method" */
  update_nb_cultivation_method_many?: Maybe<Array<Maybe<Nb_Cultivation_Method_Mutation_Response>>>;
  /** update data of the table: "nb_cultivation_plan" */
  update_nb_cultivation_plan?: Maybe<Nb_Cultivation_Plan_Mutation_Response>;
  /** update single row of the table: "nb_cultivation_plan" */
  update_nb_cultivation_plan_by_pk?: Maybe<Nb_Cultivation_Plan>;
  /** update multiples rows of table: "nb_cultivation_plan" */
  update_nb_cultivation_plan_many?: Maybe<Array<Maybe<Nb_Cultivation_Plan_Mutation_Response>>>;
  /** update data of the table: "nb_demo_task_type" */
  update_nb_demo_task_type?: Maybe<Nb_Demo_Task_Type_Mutation_Response>;
  /** update single row of the table: "nb_demo_task_type" */
  update_nb_demo_task_type_by_pk?: Maybe<Nb_Demo_Task_Type>;
  /** update multiples rows of table: "nb_demo_task_type" */
  update_nb_demo_task_type_many?: Maybe<Array<Maybe<Nb_Demo_Task_Type_Mutation_Response>>>;
  /** update data of the table: "nb_demo_user_location" */
  update_nb_demo_user_location?: Maybe<Nb_Demo_User_Location_Mutation_Response>;
  /** update single row of the table: "nb_demo_user_location" */
  update_nb_demo_user_location_by_pk?: Maybe<Nb_Demo_User_Location>;
  /** update multiples rows of table: "nb_demo_user_location" */
  update_nb_demo_user_location_many?: Maybe<Array<Maybe<Nb_Demo_User_Location_Mutation_Response>>>;
  /** update data of the table: "nb_demo_user_name" */
  update_nb_demo_user_name?: Maybe<Nb_Demo_User_Name_Mutation_Response>;
  /** update single row of the table: "nb_demo_user_name" */
  update_nb_demo_user_name_by_pk?: Maybe<Nb_Demo_User_Name>;
  /** update multiples rows of table: "nb_demo_user_name" */
  update_nb_demo_user_name_many?: Maybe<Array<Maybe<Nb_Demo_User_Name_Mutation_Response>>>;
  /** update data of the table: "nb_est_working_load" */
  update_nb_est_working_load?: Maybe<Nb_Est_Working_Load_Mutation_Response>;
  /** update single row of the table: "nb_est_working_load" */
  update_nb_est_working_load_by_pk?: Maybe<Nb_Est_Working_Load>;
  /** update multiples rows of table: "nb_est_working_load" */
  update_nb_est_working_load_many?: Maybe<Array<Maybe<Nb_Est_Working_Load_Mutation_Response>>>;
  /** update data of the table: "nb_fertilization_method" */
  update_nb_fertilization_method?: Maybe<Nb_Fertilization_Method_Mutation_Response>;
  /** update single row of the table: "nb_fertilization_method" */
  update_nb_fertilization_method_by_pk?: Maybe<Nb_Fertilization_Method>;
  /** update multiples rows of table: "nb_fertilization_method" */
  update_nb_fertilization_method_many?: Maybe<Array<Maybe<Nb_Fertilization_Method_Mutation_Response>>>;
  /** update data of the table: "nb_fertilization_work" */
  update_nb_fertilization_work?: Maybe<Nb_Fertilization_Work_Mutation_Response>;
  /** update single row of the table: "nb_fertilization_work" */
  update_nb_fertilization_work_by_pk?: Maybe<Nb_Fertilization_Work>;
  /** update multiples rows of table: "nb_fertilization_work" */
  update_nb_fertilization_work_many?: Maybe<Array<Maybe<Nb_Fertilization_Work_Mutation_Response>>>;
  /** update data of the table: "nb_fertilizer" */
  update_nb_fertilizer?: Maybe<Nb_Fertilizer_Mutation_Response>;
  /** update single row of the table: "nb_fertilizer" */
  update_nb_fertilizer_by_pk?: Maybe<Nb_Fertilizer>;
  /** update data of the table: "nb_fertilizer_company_link" */
  update_nb_fertilizer_company_link?: Maybe<Nb_Fertilizer_Company_Link_Mutation_Response>;
  /** update single row of the table: "nb_fertilizer_company_link" */
  update_nb_fertilizer_company_link_by_pk?: Maybe<Nb_Fertilizer_Company_Link>;
  /** update multiples rows of table: "nb_fertilizer_company_link" */
  update_nb_fertilizer_company_link_many?: Maybe<Array<Maybe<Nb_Fertilizer_Company_Link_Mutation_Response>>>;
  /** update multiples rows of table: "nb_fertilizer" */
  update_nb_fertilizer_many?: Maybe<Array<Maybe<Nb_Fertilizer_Mutation_Response>>>;
  /** update data of the table: "nb_fertilizer_picture" */
  update_nb_fertilizer_picture?: Maybe<Nb_Fertilizer_Picture_Mutation_Response>;
  /** update single row of the table: "nb_fertilizer_picture" */
  update_nb_fertilizer_picture_by_pk?: Maybe<Nb_Fertilizer_Picture>;
  /** update multiples rows of table: "nb_fertilizer_picture" */
  update_nb_fertilizer_picture_many?: Maybe<Array<Maybe<Nb_Fertilizer_Picture_Mutation_Response>>>;
  /** update data of the table: "nb_field_file" */
  update_nb_field_file?: Maybe<Nb_Field_File_Mutation_Response>;
  /** update single row of the table: "nb_field_file" */
  update_nb_field_file_by_pk?: Maybe<Nb_Field_File>;
  /** update data of the table: "nb_field_file_load" */
  update_nb_field_file_load?: Maybe<Nb_Field_File_Load_Mutation_Response>;
  /** update single row of the table: "nb_field_file_load" */
  update_nb_field_file_load_by_pk?: Maybe<Nb_Field_File_Load>;
  /** update multiples rows of table: "nb_field_file_load" */
  update_nb_field_file_load_many?: Maybe<Array<Maybe<Nb_Field_File_Load_Mutation_Response>>>;
  /** update multiples rows of table: "nb_field_file" */
  update_nb_field_file_many?: Maybe<Array<Maybe<Nb_Field_File_Mutation_Response>>>;
  /** update data of the table: "nb_field_file_size" */
  update_nb_field_file_size?: Maybe<Nb_Field_File_Size_Mutation_Response>;
  /** update multiples rows of table: "nb_field_file_size" */
  update_nb_field_file_size_many?: Maybe<Array<Maybe<Nb_Field_File_Size_Mutation_Response>>>;
  /** update data of the table: "nb_field_memo" */
  update_nb_field_memo?: Maybe<Nb_Field_Memo_Mutation_Response>;
  /** update single row of the table: "nb_field_memo" */
  update_nb_field_memo_by_pk?: Maybe<Nb_Field_Memo>;
  /** update multiples rows of table: "nb_field_memo" */
  update_nb_field_memo_many?: Maybe<Array<Maybe<Nb_Field_Memo_Mutation_Response>>>;
  /** update data of the table: "nb_field_orthoimg" */
  update_nb_field_orthoimg?: Maybe<Nb_Field_Orthoimg_Mutation_Response>;
  /** update single row of the table: "nb_field_orthoimg" */
  update_nb_field_orthoimg_by_pk?: Maybe<Nb_Field_Orthoimg>;
  /** update multiples rows of table: "nb_field_orthoimg" */
  update_nb_field_orthoimg_many?: Maybe<Array<Maybe<Nb_Field_Orthoimg_Mutation_Response>>>;
  /** update data of the table: "nb_field_orthoimg_size" */
  update_nb_field_orthoimg_size?: Maybe<Nb_Field_Orthoimg_Size_Mutation_Response>;
  /** update multiples rows of table: "nb_field_orthoimg_size" */
  update_nb_field_orthoimg_size_many?: Maybe<Array<Maybe<Nb_Field_Orthoimg_Size_Mutation_Response>>>;
  /** update data of the table: "nb_field_photographer" */
  update_nb_field_photographer?: Maybe<Nb_Field_Photographer_Mutation_Response>;
  /** update single row of the table: "nb_field_photographer" */
  update_nb_field_photographer_by_pk?: Maybe<Nb_Field_Photographer>;
  /** update multiples rows of table: "nb_field_photographer" */
  update_nb_field_photographer_many?: Maybe<Array<Maybe<Nb_Field_Photographer_Mutation_Response>>>;
  /** update data of the table: "nb_field_picture" */
  update_nb_field_picture?: Maybe<Nb_Field_Picture_Mutation_Response>;
  /** update single row of the table: "nb_field_picture" */
  update_nb_field_picture_by_pk?: Maybe<Nb_Field_Picture>;
  /** update data of the table: "nb_field_picture_cplan_link" */
  update_nb_field_picture_cplan_link?: Maybe<Nb_Field_Picture_Cplan_Link_Mutation_Response>;
  /** update single row of the table: "nb_field_picture_cplan_link" */
  update_nb_field_picture_cplan_link_by_pk?: Maybe<Nb_Field_Picture_Cplan_Link>;
  /** update multiples rows of table: "nb_field_picture_cplan_link" */
  update_nb_field_picture_cplan_link_many?: Maybe<Array<Maybe<Nb_Field_Picture_Cplan_Link_Mutation_Response>>>;
  /** update data of the table: "nb_field_picture_load" */
  update_nb_field_picture_load?: Maybe<Nb_Field_Picture_Load_Mutation_Response>;
  /** update single row of the table: "nb_field_picture_load" */
  update_nb_field_picture_load_by_pk?: Maybe<Nb_Field_Picture_Load>;
  /** update multiples rows of table: "nb_field_picture_load" */
  update_nb_field_picture_load_many?: Maybe<Array<Maybe<Nb_Field_Picture_Load_Mutation_Response>>>;
  /** update multiples rows of table: "nb_field_picture" */
  update_nb_field_picture_many?: Maybe<Array<Maybe<Nb_Field_Picture_Mutation_Response>>>;
  /** update data of the table: "nb_field_picture_size" */
  update_nb_field_picture_size?: Maybe<Nb_Field_Picture_Size_Mutation_Response>;
  /** update multiples rows of table: "nb_field_picture_size" */
  update_nb_field_picture_size_many?: Maybe<Array<Maybe<Nb_Field_Picture_Size_Mutation_Response>>>;
  /** update data of the table: "nb_field_polygon_attr" */
  update_nb_field_polygon_attr?: Maybe<Nb_Field_Polygon_Attr_Mutation_Response>;
  /** update single row of the table: "nb_field_polygon_attr" */
  update_nb_field_polygon_attr_by_pk?: Maybe<Nb_Field_Polygon_Attr>;
  /** update multiples rows of table: "nb_field_polygon_attr" */
  update_nb_field_polygon_attr_many?: Maybe<Array<Maybe<Nb_Field_Polygon_Attr_Mutation_Response>>>;
  /** update data of the table: "nb_field_property" */
  update_nb_field_property?: Maybe<Nb_Field_Property_Mutation_Response>;
  /** update single row of the table: "nb_field_property" */
  update_nb_field_property_by_pk?: Maybe<Nb_Field_Property>;
  /** update multiples rows of table: "nb_field_property" */
  update_nb_field_property_many?: Maybe<Array<Maybe<Nb_Field_Property_Mutation_Response>>>;
  /** update data of the table: "nb_field_sensing" */
  update_nb_field_sensing?: Maybe<Nb_Field_Sensing_Mutation_Response>;
  /** update single row of the table: "nb_field_sensing" */
  update_nb_field_sensing_by_pk?: Maybe<Nb_Field_Sensing>;
  /** update data of the table: "nb_field_sensing_data" */
  update_nb_field_sensing_data?: Maybe<Nb_Field_Sensing_Data_Mutation_Response>;
  /** update single row of the table: "nb_field_sensing_data" */
  update_nb_field_sensing_data_by_pk?: Maybe<Nb_Field_Sensing_Data>;
  /** update multiples rows of table: "nb_field_sensing_data" */
  update_nb_field_sensing_data_many?: Maybe<Array<Maybe<Nb_Field_Sensing_Data_Mutation_Response>>>;
  /** update multiples rows of table: "nb_field_sensing" */
  update_nb_field_sensing_many?: Maybe<Array<Maybe<Nb_Field_Sensing_Mutation_Response>>>;
  /** update data of the table: "nb_field_water_gate" */
  update_nb_field_water_gate?: Maybe<Nb_Field_Water_Gate_Mutation_Response>;
  /** update single row of the table: "nb_field_water_gate" */
  update_nb_field_water_gate_by_pk?: Maybe<Nb_Field_Water_Gate>;
  /** update multiples rows of table: "nb_field_water_gate" */
  update_nb_field_water_gate_many?: Maybe<Array<Maybe<Nb_Field_Water_Gate_Mutation_Response>>>;
  /** update data of the table: "nb_field_water_gate_status" */
  update_nb_field_water_gate_status?: Maybe<Nb_Field_Water_Gate_Status_Mutation_Response>;
  /** update single row of the table: "nb_field_water_gate_status" */
  update_nb_field_water_gate_status_by_pk?: Maybe<Nb_Field_Water_Gate_Status>;
  /** update multiples rows of table: "nb_field_water_gate_status" */
  update_nb_field_water_gate_status_many?: Maybe<Array<Maybe<Nb_Field_Water_Gate_Status_Mutation_Response>>>;
  /** update data of the table: "nb_file_size" */
  update_nb_file_size?: Maybe<Nb_File_Size_Mutation_Response>;
  /** update single row of the table: "nb_file_size" */
  update_nb_file_size_by_pk?: Maybe<Nb_File_Size>;
  /** update multiples rows of table: "nb_file_size" */
  update_nb_file_size_many?: Maybe<Array<Maybe<Nb_File_Size_Mutation_Response>>>;
  /** update data of the table: "nb_file_type" */
  update_nb_file_type?: Maybe<Nb_File_Type_Mutation_Response>;
  /** update single row of the table: "nb_file_type" */
  update_nb_file_type_by_pk?: Maybe<Nb_File_Type>;
  /** update multiples rows of table: "nb_file_type" */
  update_nb_file_type_many?: Maybe<Array<Maybe<Nb_File_Type_Mutation_Response>>>;
  /** update data of the table: "nb_finished_ufield_work" */
  update_nb_finished_ufield_work?: Maybe<Nb_Finished_Ufield_Work_Mutation_Response>;
  /** update single row of the table: "nb_finished_ufield_work" */
  update_nb_finished_ufield_work_by_pk?: Maybe<Nb_Finished_Ufield_Work>;
  /** update multiples rows of table: "nb_finished_ufield_work" */
  update_nb_finished_ufield_work_many?: Maybe<Array<Maybe<Nb_Finished_Ufield_Work_Mutation_Response>>>;
  /** update data of the table: "nb_image_sensing_attr" */
  update_nb_image_sensing_attr?: Maybe<Nb_Image_Sensing_Attr_Mutation_Response>;
  /** update single row of the table: "nb_image_sensing_attr" */
  update_nb_image_sensing_attr_by_pk?: Maybe<Nb_Image_Sensing_Attr>;
  /** update multiples rows of table: "nb_image_sensing_attr" */
  update_nb_image_sensing_attr_many?: Maybe<Array<Maybe<Nb_Image_Sensing_Attr_Mutation_Response>>>;
  /** update data of the table: "nb_imsensing_colormap" */
  update_nb_imsensing_colormap?: Maybe<Nb_Imsensing_Colormap_Mutation_Response>;
  /** update single row of the table: "nb_imsensing_colormap" */
  update_nb_imsensing_colormap_by_pk?: Maybe<Nb_Imsensing_Colormap>;
  /** update multiples rows of table: "nb_imsensing_colormap" */
  update_nb_imsensing_colormap_many?: Maybe<Array<Maybe<Nb_Imsensing_Colormap_Mutation_Response>>>;
  /** update data of the table: "nb_log_user_position" */
  update_nb_log_user_position?: Maybe<Nb_Log_User_Position_Mutation_Response>;
  /** update single row of the table: "nb_log_user_position" */
  update_nb_log_user_position_by_pk?: Maybe<Nb_Log_User_Position>;
  /** update multiples rows of table: "nb_log_user_position" */
  update_nb_log_user_position_many?: Maybe<Array<Maybe<Nb_Log_User_Position_Mutation_Response>>>;
  /** update data of the table: "nb_maff_field" */
  update_nb_maff_field?: Maybe<Nb_Maff_Field_Mutation_Response>;
  /** update single row of the table: "nb_maff_field" */
  update_nb_maff_field_by_pk?: Maybe<Nb_Maff_Field>;
  /** update data of the table: "nb_maff_field_link" */
  update_nb_maff_field_link?: Maybe<Nb_Maff_Field_Link_Mutation_Response>;
  /** update single row of the table: "nb_maff_field_link" */
  update_nb_maff_field_link_by_pk?: Maybe<Nb_Maff_Field_Link>;
  /** update multiples rows of table: "nb_maff_field_link" */
  update_nb_maff_field_link_many?: Maybe<Array<Maybe<Nb_Maff_Field_Link_Mutation_Response>>>;
  /** update multiples rows of table: "nb_maff_field" */
  update_nb_maff_field_many?: Maybe<Array<Maybe<Nb_Maff_Field_Mutation_Response>>>;
  /** update data of the table: "nb_memo_file_link" */
  update_nb_memo_file_link?: Maybe<Nb_Memo_File_Link_Mutation_Response>;
  /** update single row of the table: "nb_memo_file_link" */
  update_nb_memo_file_link_by_pk?: Maybe<Nb_Memo_File_Link>;
  /** update multiples rows of table: "nb_memo_file_link" */
  update_nb_memo_file_link_many?: Maybe<Array<Maybe<Nb_Memo_File_Link_Mutation_Response>>>;
  /** update data of the table: "nb_memo_picture_link" */
  update_nb_memo_picture_link?: Maybe<Nb_Memo_Picture_Link_Mutation_Response>;
  /** update single row of the table: "nb_memo_picture_link" */
  update_nb_memo_picture_link_by_pk?: Maybe<Nb_Memo_Picture_Link>;
  /** update multiples rows of table: "nb_memo_picture_link" */
  update_nb_memo_picture_link_many?: Maybe<Array<Maybe<Nb_Memo_Picture_Link_Mutation_Response>>>;
  /** update data of the table: "nb_memo_ufg_link" */
  update_nb_memo_ufg_link?: Maybe<Nb_Memo_Ufg_Link_Mutation_Response>;
  /** update single row of the table: "nb_memo_ufg_link" */
  update_nb_memo_ufg_link_by_pk?: Maybe<Nb_Memo_Ufg_Link>;
  /** update multiples rows of table: "nb_memo_ufg_link" */
  update_nb_memo_ufg_link_many?: Maybe<Array<Maybe<Nb_Memo_Ufg_Link_Mutation_Response>>>;
  /** update data of the table: "nb_memo_ufield_link" */
  update_nb_memo_ufield_link?: Maybe<Nb_Memo_Ufield_Link_Mutation_Response>;
  /** update single row of the table: "nb_memo_ufield_link" */
  update_nb_memo_ufield_link_by_pk?: Maybe<Nb_Memo_Ufield_Link>;
  /** update multiples rows of table: "nb_memo_ufield_link" */
  update_nb_memo_ufield_link_many?: Maybe<Array<Maybe<Nb_Memo_Ufield_Link_Mutation_Response>>>;
  /** update data of the table: "nb_mfield" */
  update_nb_mfield?: Maybe<Nb_Mfield_Mutation_Response>;
  /** update single row of the table: "nb_mfield" */
  update_nb_mfield_by_pk?: Maybe<Nb_Mfield>;
  /** update data of the table: "nb_mfield_link" */
  update_nb_mfield_link?: Maybe<Nb_Mfield_Link_Mutation_Response>;
  /** update single row of the table: "nb_mfield_link" */
  update_nb_mfield_link_by_pk?: Maybe<Nb_Mfield_Link>;
  /** update multiples rows of table: "nb_mfield_link" */
  update_nb_mfield_link_many?: Maybe<Array<Maybe<Nb_Mfield_Link_Mutation_Response>>>;
  /** update multiples rows of table: "nb_mfield" */
  update_nb_mfield_many?: Maybe<Array<Maybe<Nb_Mfield_Mutation_Response>>>;
  /** update data of the table: "nb_performed_work" */
  update_nb_performed_work?: Maybe<Nb_Performed_Work_Mutation_Response>;
  /** update single row of the table: "nb_performed_work" */
  update_nb_performed_work_by_pk?: Maybe<Nb_Performed_Work>;
  /** update multiples rows of table: "nb_performed_work" */
  update_nb_performed_work_many?: Maybe<Array<Maybe<Nb_Performed_Work_Mutation_Response>>>;
  /** update data of the table: "nb_pesticide" */
  update_nb_pesticide?: Maybe<Nb_Pesticide_Mutation_Response>;
  /** update single row of the table: "nb_pesticide" */
  update_nb_pesticide_by_pk?: Maybe<Nb_Pesticide>;
  /** update data of the table: "nb_pesticide_company_link" */
  update_nb_pesticide_company_link?: Maybe<Nb_Pesticide_Company_Link_Mutation_Response>;
  /** update single row of the table: "nb_pesticide_company_link" */
  update_nb_pesticide_company_link_by_pk?: Maybe<Nb_Pesticide_Company_Link>;
  /** update multiples rows of table: "nb_pesticide_company_link" */
  update_nb_pesticide_company_link_many?: Maybe<Array<Maybe<Nb_Pesticide_Company_Link_Mutation_Response>>>;
  /** update data of the table: "nb_pesticide_ingredient" */
  update_nb_pesticide_ingredient?: Maybe<Nb_Pesticide_Ingredient_Mutation_Response>;
  /** update single row of the table: "nb_pesticide_ingredient" */
  update_nb_pesticide_ingredient_by_pk?: Maybe<Nb_Pesticide_Ingredient>;
  /** update multiples rows of table: "nb_pesticide_ingredient" */
  update_nb_pesticide_ingredient_many?: Maybe<Array<Maybe<Nb_Pesticide_Ingredient_Mutation_Response>>>;
  /** update multiples rows of table: "nb_pesticide" */
  update_nb_pesticide_many?: Maybe<Array<Maybe<Nb_Pesticide_Mutation_Response>>>;
  /** update data of the table: "nb_pesticide_spray_work" */
  update_nb_pesticide_spray_work?: Maybe<Nb_Pesticide_Spray_Work_Mutation_Response>;
  /** update single row of the table: "nb_pesticide_spray_work" */
  update_nb_pesticide_spray_work_by_pk?: Maybe<Nb_Pesticide_Spray_Work>;
  /** update multiples rows of table: "nb_pesticide_spray_work" */
  update_nb_pesticide_spray_work_many?: Maybe<Array<Maybe<Nb_Pesticide_Spray_Work_Mutation_Response>>>;
  /** update data of the table: "nb_picture_analysis" */
  update_nb_picture_analysis?: Maybe<Nb_Picture_Analysis_Mutation_Response>;
  /** update single row of the table: "nb_picture_analysis" */
  update_nb_picture_analysis_by_pk?: Maybe<Nb_Picture_Analysis>;
  /** update multiples rows of table: "nb_picture_analysis" */
  update_nb_picture_analysis_many?: Maybe<Array<Maybe<Nb_Picture_Analysis_Mutation_Response>>>;
  /** update data of the table: "nb_picture_analysis_type" */
  update_nb_picture_analysis_type?: Maybe<Nb_Picture_Analysis_Type_Mutation_Response>;
  /** update single row of the table: "nb_picture_analysis_type" */
  update_nb_picture_analysis_type_by_pk?: Maybe<Nb_Picture_Analysis_Type>;
  /** update multiples rows of table: "nb_picture_analysis_type" */
  update_nb_picture_analysis_type_many?: Maybe<Array<Maybe<Nb_Picture_Analysis_Type_Mutation_Response>>>;
  /** update data of the table: "nb_raster_image_sensing" */
  update_nb_raster_image_sensing?: Maybe<Nb_Raster_Image_Sensing_Mutation_Response>;
  /** update single row of the table: "nb_raster_image_sensing" */
  update_nb_raster_image_sensing_by_pk?: Maybe<Nb_Raster_Image_Sensing>;
  /** update multiples rows of table: "nb_raster_image_sensing" */
  update_nb_raster_image_sensing_many?: Maybe<Array<Maybe<Nb_Raster_Image_Sensing_Mutation_Response>>>;
  /** update data of the table: "nb_raster_ims_size" */
  update_nb_raster_ims_size?: Maybe<Nb_Raster_Ims_Size_Mutation_Response>;
  /** update multiples rows of table: "nb_raster_ims_size" */
  update_nb_raster_ims_size_many?: Maybe<Array<Maybe<Nb_Raster_Ims_Size_Mutation_Response>>>;
  /** update data of the table: "nb_soil_chara_map" */
  update_nb_soil_chara_map?: Maybe<Nb_Soil_Chara_Map_Mutation_Response>;
  /** update single row of the table: "nb_soil_chara_map" */
  update_nb_soil_chara_map_by_pk?: Maybe<Nb_Soil_Chara_Map>;
  /** update multiples rows of table: "nb_soil_chara_map" */
  update_nb_soil_chara_map_many?: Maybe<Array<Maybe<Nb_Soil_Chara_Map_Mutation_Response>>>;
  /** update data of the table: "nb_soil_chara_map_sellist" */
  update_nb_soil_chara_map_sellist?: Maybe<Nb_Soil_Chara_Map_Sellist_Mutation_Response>;
  /** update single row of the table: "nb_soil_chara_map_sellist" */
  update_nb_soil_chara_map_sellist_by_pk?: Maybe<Nb_Soil_Chara_Map_Sellist>;
  /** update multiples rows of table: "nb_soil_chara_map_sellist" */
  update_nb_soil_chara_map_sellist_many?: Maybe<Array<Maybe<Nb_Soil_Chara_Map_Sellist_Mutation_Response>>>;
  /** update data of the table: "nb_soil_characteristic" */
  update_nb_soil_characteristic?: Maybe<Nb_Soil_Characteristic_Mutation_Response>;
  /** update single row of the table: "nb_soil_characteristic" */
  update_nb_soil_characteristic_by_pk?: Maybe<Nb_Soil_Characteristic>;
  /** update multiples rows of table: "nb_soil_characteristic" */
  update_nb_soil_characteristic_many?: Maybe<Array<Maybe<Nb_Soil_Characteristic_Mutation_Response>>>;
  /** update data of the table: "nb_soil_chemistry" */
  update_nb_soil_chemistry?: Maybe<Nb_Soil_Chemistry_Mutation_Response>;
  /** update single row of the table: "nb_soil_chemistry" */
  update_nb_soil_chemistry_by_pk?: Maybe<Nb_Soil_Chemistry>;
  /** update multiples rows of table: "nb_soil_chemistry" */
  update_nb_soil_chemistry_many?: Maybe<Array<Maybe<Nb_Soil_Chemistry_Mutation_Response>>>;
  /** update data of the table: "nb_soil_chemistry_proper_range" */
  update_nb_soil_chemistry_proper_range?: Maybe<Nb_Soil_Chemistry_Proper_Range_Mutation_Response>;
  /** update single row of the table: "nb_soil_chemistry_proper_range" */
  update_nb_soil_chemistry_proper_range_by_pk?: Maybe<Nb_Soil_Chemistry_Proper_Range>;
  /** update multiples rows of table: "nb_soil_chemistry_proper_range" */
  update_nb_soil_chemistry_proper_range_many?: Maybe<Array<Maybe<Nb_Soil_Chemistry_Proper_Range_Mutation_Response>>>;
  /** update data of the table: "nb_soil_chemistry_standards" */
  update_nb_soil_chemistry_standards?: Maybe<Nb_Soil_Chemistry_Standards_Mutation_Response>;
  /** update single row of the table: "nb_soil_chemistry_standards" */
  update_nb_soil_chemistry_standards_by_pk?: Maybe<Nb_Soil_Chemistry_Standards>;
  /** update multiples rows of table: "nb_soil_chemistry_standards" */
  update_nb_soil_chemistry_standards_many?: Maybe<Array<Maybe<Nb_Soil_Chemistry_Standards_Mutation_Response>>>;
  /** update data of the table: "nb_soil_chemistry_suitable_range" */
  update_nb_soil_chemistry_suitable_range?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Mutation_Response>;
  /** update single row of the table: "nb_soil_chemistry_suitable_range" */
  update_nb_soil_chemistry_suitable_range_by_pk?: Maybe<Nb_Soil_Chemistry_Suitable_Range>;
  /** update multiples rows of table: "nb_soil_chemistry_suitable_range" */
  update_nb_soil_chemistry_suitable_range_many?: Maybe<Array<Maybe<Nb_Soil_Chemistry_Suitable_Range_Mutation_Response>>>;
  /** update data of the table: "nb_soil_chemistry_value" */
  update_nb_soil_chemistry_value?: Maybe<Nb_Soil_Chemistry_Value_Mutation_Response>;
  /** update single row of the table: "nb_soil_chemistry_value" */
  update_nb_soil_chemistry_value_by_pk?: Maybe<Nb_Soil_Chemistry_Value>;
  /** update multiples rows of table: "nb_soil_chemistry_value" */
  update_nb_soil_chemistry_value_many?: Maybe<Array<Maybe<Nb_Soil_Chemistry_Value_Mutation_Response>>>;
  /** update data of the table: "nb_soil_explanation" */
  update_nb_soil_explanation?: Maybe<Nb_Soil_Explanation_Mutation_Response>;
  /** update single row of the table: "nb_soil_explanation" */
  update_nb_soil_explanation_by_pk?: Maybe<Nb_Soil_Explanation>;
  /** update multiples rows of table: "nb_soil_explanation" */
  update_nb_soil_explanation_many?: Maybe<Array<Maybe<Nb_Soil_Explanation_Mutation_Response>>>;
  /** update data of the table: "nb_soil_physics" */
  update_nb_soil_physics?: Maybe<Nb_Soil_Physics_Mutation_Response>;
  /** update single row of the table: "nb_soil_physics" */
  update_nb_soil_physics_by_pk?: Maybe<Nb_Soil_Physics>;
  /** update multiples rows of table: "nb_soil_physics" */
  update_nb_soil_physics_many?: Maybe<Array<Maybe<Nb_Soil_Physics_Mutation_Response>>>;
  /** update data of the table: "nb_task_type" */
  update_nb_task_type?: Maybe<Nb_Task_Type_Mutation_Response>;
  /** update single row of the table: "nb_task_type" */
  update_nb_task_type_by_pk?: Maybe<Nb_Task_Type>;
  /** update multiples rows of table: "nb_task_type" */
  update_nb_task_type_many?: Maybe<Array<Maybe<Nb_Task_Type_Mutation_Response>>>;
  /** update data of the table: "nb_tasktype_company_link" */
  update_nb_tasktype_company_link?: Maybe<Nb_Tasktype_Company_Link_Mutation_Response>;
  /** update single row of the table: "nb_tasktype_company_link" */
  update_nb_tasktype_company_link_by_pk?: Maybe<Nb_Tasktype_Company_Link>;
  /** update multiples rows of table: "nb_tasktype_company_link" */
  update_nb_tasktype_company_link_many?: Maybe<Array<Maybe<Nb_Tasktype_Company_Link_Mutation_Response>>>;
  /** update data of the table: "nb_ufattr_link" */
  update_nb_ufattr_link?: Maybe<Nb_Ufattr_Link_Mutation_Response>;
  /** update single row of the table: "nb_ufattr_link" */
  update_nb_ufattr_link_by_pk?: Maybe<Nb_Ufattr_Link>;
  /** update multiples rows of table: "nb_ufattr_link" */
  update_nb_ufattr_link_many?: Maybe<Array<Maybe<Nb_Ufattr_Link_Mutation_Response>>>;
  /** update data of the table: "nb_ufg_link" */
  update_nb_ufg_link?: Maybe<Nb_Ufg_Link_Mutation_Response>;
  /** update single row of the table: "nb_ufg_link" */
  update_nb_ufg_link_by_pk?: Maybe<Nb_Ufg_Link>;
  /** update multiples rows of table: "nb_ufg_link" */
  update_nb_ufg_link_many?: Maybe<Array<Maybe<Nb_Ufg_Link_Mutation_Response>>>;
  /** update data of the table: "nb_ufg_member_link" */
  update_nb_ufg_member_link?: Maybe<Nb_Ufg_Member_Link_Mutation_Response>;
  /** update single row of the table: "nb_ufg_member_link" */
  update_nb_ufg_member_link_by_pk?: Maybe<Nb_Ufg_Member_Link>;
  /** update multiples rows of table: "nb_ufg_member_link" */
  update_nb_ufg_member_link_many?: Maybe<Array<Maybe<Nb_Ufg_Member_Link_Mutation_Response>>>;
  /** update data of the table: "nb_ufield" */
  update_nb_ufield?: Maybe<Nb_Ufield_Mutation_Response>;
  /** update data of the table: "nb_ufield_attribute" */
  update_nb_ufield_attribute?: Maybe<Nb_Ufield_Attribute_Mutation_Response>;
  /** update single row of the table: "nb_ufield_attribute" */
  update_nb_ufield_attribute_by_pk?: Maybe<Nb_Ufield_Attribute>;
  /** update multiples rows of table: "nb_ufield_attribute" */
  update_nb_ufield_attribute_many?: Maybe<Array<Maybe<Nb_Ufield_Attribute_Mutation_Response>>>;
  /** update single row of the table: "nb_ufield" */
  update_nb_ufield_by_pk?: Maybe<Nb_Ufield>;
  /** update data of the table: "nb_ufield_company_link" */
  update_nb_ufield_company_link?: Maybe<Nb_Ufield_Company_Link_Mutation_Response>;
  /** update single row of the table: "nb_ufield_company_link" */
  update_nb_ufield_company_link_by_pk?: Maybe<Nb_Ufield_Company_Link>;
  /** update multiples rows of table: "nb_ufield_company_link" */
  update_nb_ufield_company_link_many?: Maybe<Array<Maybe<Nb_Ufield_Company_Link_Mutation_Response>>>;
  /** update data of the table: "nb_ufield_group" */
  update_nb_ufield_group?: Maybe<Nb_Ufield_Group_Mutation_Response>;
  /** update single row of the table: "nb_ufield_group" */
  update_nb_ufield_group_by_pk?: Maybe<Nb_Ufield_Group>;
  /** update multiples rows of table: "nb_ufield_group" */
  update_nb_ufield_group_many?: Maybe<Array<Maybe<Nb_Ufield_Group_Mutation_Response>>>;
  /** update data of the table: "nb_ufield_history" */
  update_nb_ufield_history?: Maybe<Nb_Ufield_History_Mutation_Response>;
  /** update single row of the table: "nb_ufield_history" */
  update_nb_ufield_history_by_pk?: Maybe<Nb_Ufield_History>;
  /** update multiples rows of table: "nb_ufield_history" */
  update_nb_ufield_history_many?: Maybe<Array<Maybe<Nb_Ufield_History_Mutation_Response>>>;
  /** update multiples rows of table: "nb_ufield" */
  update_nb_ufield_many?: Maybe<Array<Maybe<Nb_Ufield_Mutation_Response>>>;
  /** update data of the table: "nb_vector_image_sensing" */
  update_nb_vector_image_sensing?: Maybe<Nb_Vector_Image_Sensing_Mutation_Response>;
  /** update single row of the table: "nb_vector_image_sensing" */
  update_nb_vector_image_sensing_by_pk?: Maybe<Nb_Vector_Image_Sensing>;
  /** update multiples rows of table: "nb_vector_image_sensing" */
  update_nb_vector_image_sensing_many?: Maybe<Array<Maybe<Nb_Vector_Image_Sensing_Mutation_Response>>>;
  /** update data of the table: "nb_vector_ims_size" */
  update_nb_vector_ims_size?: Maybe<Nb_Vector_Ims_Size_Mutation_Response>;
  /** update multiples rows of table: "nb_vector_ims_size" */
  update_nb_vector_ims_size_many?: Maybe<Array<Maybe<Nb_Vector_Ims_Size_Mutation_Response>>>;
  /** update data of the table: "nb_water_gate_status_cplan_link" */
  update_nb_water_gate_status_cplan_link?: Maybe<Nb_Water_Gate_Status_Cplan_Link_Mutation_Response>;
  /** update single row of the table: "nb_water_gate_status_cplan_link" */
  update_nb_water_gate_status_cplan_link_by_pk?: Maybe<Nb_Water_Gate_Status_Cplan_Link>;
  /** update multiples rows of table: "nb_water_gate_status_cplan_link" */
  update_nb_water_gate_status_cplan_link_many?: Maybe<Array<Maybe<Nb_Water_Gate_Status_Cplan_Link_Mutation_Response>>>;
  /** update data of the table: "nb_weather_attr" */
  update_nb_weather_attr?: Maybe<Nb_Weather_Attr_Mutation_Response>;
  /** update single row of the table: "nb_weather_attr" */
  update_nb_weather_attr_by_pk?: Maybe<Nb_Weather_Attr>;
  /** update multiples rows of table: "nb_weather_attr" */
  update_nb_weather_attr_many?: Maybe<Array<Maybe<Nb_Weather_Attr_Mutation_Response>>>;
  /** update data of the table: "nb_weather_data" */
  update_nb_weather_data?: Maybe<Nb_Weather_Data_Mutation_Response>;
  /** update single row of the table: "nb_weather_data" */
  update_nb_weather_data_by_pk?: Maybe<Nb_Weather_Data>;
  /** update multiples rows of table: "nb_weather_data" */
  update_nb_weather_data_many?: Maybe<Array<Maybe<Nb_Weather_Data_Mutation_Response>>>;
  /** update data of the table: "nb_wide_orthoimg" */
  update_nb_wide_orthoimg?: Maybe<Nb_Wide_Orthoimg_Mutation_Response>;
  /** update single row of the table: "nb_wide_orthoimg" */
  update_nb_wide_orthoimg_by_pk?: Maybe<Nb_Wide_Orthoimg>;
  /** update multiples rows of table: "nb_wide_orthoimg" */
  update_nb_wide_orthoimg_many?: Maybe<Array<Maybe<Nb_Wide_Orthoimg_Mutation_Response>>>;
  /** update data of the table: "nb_wide_orthoimg_size" */
  update_nb_wide_orthoimg_size?: Maybe<Nb_Wide_Orthoimg_Size_Mutation_Response>;
  /** update multiples rows of table: "nb_wide_orthoimg_size" */
  update_nb_wide_orthoimg_size_many?: Maybe<Array<Maybe<Nb_Wide_Orthoimg_Size_Mutation_Response>>>;
  /** update data of the table: "nb_working_machine" */
  update_nb_working_machine?: Maybe<Nb_Working_Machine_Mutation_Response>;
  /** update single row of the table: "nb_working_machine" */
  update_nb_working_machine_by_pk?: Maybe<Nb_Working_Machine>;
  /** update multiples rows of table: "nb_working_machine" */
  update_nb_working_machine_many?: Maybe<Array<Maybe<Nb_Working_Machine_Mutation_Response>>>;
  /** update data of the table: "nbb_picture_analysis_status" */
  update_nbb_picture_analysis_status?: Maybe<Nbb_Picture_Analysis_Status_Mutation_Response>;
  /** update single row of the table: "nbb_picture_analysis_status" */
  update_nbb_picture_analysis_status_by_pk?: Maybe<Nbb_Picture_Analysis_Status>;
  /** update multiples rows of table: "nbb_picture_analysis_status" */
  update_nbb_picture_analysis_status_many?: Maybe<Array<Maybe<Nbb_Picture_Analysis_Status_Mutation_Response>>>;
  /** update data of the table: "nbbe_microclimate" */
  update_nbbe_microclimate?: Maybe<Nbbe_Microclimate_Mutation_Response>;
  /** update single row of the table: "nbbe_microclimate" */
  update_nbbe_microclimate_by_pk?: Maybe<Nbbe_Microclimate>;
  /** update multiples rows of table: "nbbe_microclimate" */
  update_nbbe_microclimate_many?: Maybe<Array<Maybe<Nbbe_Microclimate_Mutation_Response>>>;
  /** update data of the table: "nbbe_orthoimg_type" */
  update_nbbe_orthoimg_type?: Maybe<Nbbe_Orthoimg_Type_Mutation_Response>;
  /** update single row of the table: "nbbe_orthoimg_type" */
  update_nbbe_orthoimg_type_by_pk?: Maybe<Nbbe_Orthoimg_Type>;
  /** update multiples rows of table: "nbbe_orthoimg_type" */
  update_nbbe_orthoimg_type_many?: Maybe<Array<Maybe<Nbbe_Orthoimg_Type_Mutation_Response>>>;
  /** update data of the table: "nbbe_weather_type" */
  update_nbbe_weather_type?: Maybe<Nbbe_Weather_Type_Mutation_Response>;
  /** update single row of the table: "nbbe_weather_type" */
  update_nbbe_weather_type_by_pk?: Maybe<Nbbe_Weather_Type>;
  /** update multiples rows of table: "nbbe_weather_type" */
  update_nbbe_weather_type_many?: Maybe<Array<Maybe<Nbbe_Weather_Type_Mutation_Response>>>;
  /** update data of the table: "nbpm_authorization" */
  update_nbpm_authorization?: Maybe<Nbpm_Authorization_Mutation_Response>;
  /** update single row of the table: "nbpm_authorization" */
  update_nbpm_authorization_by_pk?: Maybe<Nbpm_Authorization>;
  /** update multiples rows of table: "nbpm_authorization" */
  update_nbpm_authorization_many?: Maybe<Array<Maybe<Nbpm_Authorization_Mutation_Response>>>;
  /** update data of the table: "nbpm_contract_plan" */
  update_nbpm_contract_plan?: Maybe<Nbpm_Contract_Plan_Mutation_Response>;
  /** update single row of the table: "nbpm_contract_plan" */
  update_nbpm_contract_plan_by_pk?: Maybe<Nbpm_Contract_Plan>;
  /** update data of the table: "nbpm_contract_plan_link" */
  update_nbpm_contract_plan_link?: Maybe<Nbpm_Contract_Plan_Link_Mutation_Response>;
  /** update single row of the table: "nbpm_contract_plan_link" */
  update_nbpm_contract_plan_link_by_pk?: Maybe<Nbpm_Contract_Plan_Link>;
  /** update multiples rows of table: "nbpm_contract_plan_link" */
  update_nbpm_contract_plan_link_many?: Maybe<Array<Maybe<Nbpm_Contract_Plan_Link_Mutation_Response>>>;
  /** update multiples rows of table: "nbpm_contract_plan" */
  update_nbpm_contract_plan_many?: Maybe<Array<Maybe<Nbpm_Contract_Plan_Mutation_Response>>>;
  /** execute VOLATILE function "update_nbpm_contract_plan_used_storage_capacity" which returns "nbpm_contract_plan" */
  update_nbpm_contract_plan_used_storage_capacity: Array<Nbpm_Contract_Plan>;
  /** update data of the table: "nbpm_imsattr_authorization_link" */
  update_nbpm_imsattr_authorization_link?: Maybe<Nbpm_Imsattr_Authorization_Link_Mutation_Response>;
  /** update single row of the table: "nbpm_imsattr_authorization_link" */
  update_nbpm_imsattr_authorization_link_by_pk?: Maybe<Nbpm_Imsattr_Authorization_Link>;
  /** update multiples rows of table: "nbpm_imsattr_authorization_link" */
  update_nbpm_imsattr_authorization_link_many?: Maybe<Array<Maybe<Nbpm_Imsattr_Authorization_Link_Mutation_Response>>>;
  /** update data of the table: "nbpm_ipengine_authorization_link" */
  update_nbpm_ipengine_authorization_link?: Maybe<Nbpm_Ipengine_Authorization_Link_Mutation_Response>;
  /** update single row of the table: "nbpm_ipengine_authorization_link" */
  update_nbpm_ipengine_authorization_link_by_pk?: Maybe<Nbpm_Ipengine_Authorization_Link>;
  /** update multiples rows of table: "nbpm_ipengine_authorization_link" */
  update_nbpm_ipengine_authorization_link_many?: Maybe<Array<Maybe<Nbpm_Ipengine_Authorization_Link_Mutation_Response>>>;
  /** update data of the table: "nbpm_picanatype_authorization_link" */
  update_nbpm_picanatype_authorization_link?: Maybe<Nbpm_Picanatype_Authorization_Link_Mutation_Response>;
  /** update single row of the table: "nbpm_picanatype_authorization_link" */
  update_nbpm_picanatype_authorization_link_by_pk?: Maybe<Nbpm_Picanatype_Authorization_Link>;
  /** update multiples rows of table: "nbpm_picanatype_authorization_link" */
  update_nbpm_picanatype_authorization_link_many?: Maybe<Array<Maybe<Nbpm_Picanatype_Authorization_Link_Mutation_Response>>>;
  /** update data of the table: "nbpm_service_plan" */
  update_nbpm_service_plan?: Maybe<Nbpm_Service_Plan_Mutation_Response>;
  /** update single row of the table: "nbpm_service_plan" */
  update_nbpm_service_plan_by_pk?: Maybe<Nbpm_Service_Plan>;
  /** update data of the table: "nbpm_service_plan_link" */
  update_nbpm_service_plan_link?: Maybe<Nbpm_Service_Plan_Link_Mutation_Response>;
  /** update single row of the table: "nbpm_service_plan_link" */
  update_nbpm_service_plan_link_by_pk?: Maybe<Nbpm_Service_Plan_Link>;
  /** update multiples rows of table: "nbpm_service_plan_link" */
  update_nbpm_service_plan_link_many?: Maybe<Array<Maybe<Nbpm_Service_Plan_Link_Mutation_Response>>>;
  /** update multiples rows of table: "nbpm_service_plan" */
  update_nbpm_service_plan_many?: Maybe<Array<Maybe<Nbpm_Service_Plan_Mutation_Response>>>;
  /** update data of the table: "nbpm_user_attr_link" */
  update_nbpm_user_attr_link?: Maybe<Nbpm_User_Attr_Link_Mutation_Response>;
  /** update single row of the table: "nbpm_user_attr_link" */
  update_nbpm_user_attr_link_by_pk?: Maybe<Nbpm_User_Attr_Link>;
  /** update multiples rows of table: "nbpm_user_attr_link" */
  update_nbpm_user_attr_link_many?: Maybe<Array<Maybe<Nbpm_User_Attr_Link_Mutation_Response>>>;
  /** update data of the table: "nbpm_user_attribute" */
  update_nbpm_user_attribute?: Maybe<Nbpm_User_Attribute_Mutation_Response>;
  /** update single row of the table: "nbpm_user_attribute" */
  update_nbpm_user_attribute_by_pk?: Maybe<Nbpm_User_Attribute>;
  /** update multiples rows of table: "nbpm_user_attribute" */
  update_nbpm_user_attribute_many?: Maybe<Array<Maybe<Nbpm_User_Attribute_Mutation_Response>>>;
  /** update data of the table: "nbpm_user_cp_link_link" */
  update_nbpm_user_cp_link_link?: Maybe<Nbpm_User_Cp_Link_Link_Mutation_Response>;
  /** update single row of the table: "nbpm_user_cp_link_link" */
  update_nbpm_user_cp_link_link_by_pk?: Maybe<Nbpm_User_Cp_Link_Link>;
  /** update multiples rows of table: "nbpm_user_cp_link_link" */
  update_nbpm_user_cp_link_link_many?: Maybe<Array<Maybe<Nbpm_User_Cp_Link_Link_Mutation_Response>>>;
  /** update data of the table: "nbs_ipe_run_field" */
  update_nbs_ipe_run_field?: Maybe<Nbs_Ipe_Run_Field_Mutation_Response>;
  /** update single row of the table: "nbs_ipe_run_field" */
  update_nbs_ipe_run_field_by_pk?: Maybe<Nbs_Ipe_Run_Field>;
  /** update multiples rows of table: "nbs_ipe_run_field" */
  update_nbs_ipe_run_field_many?: Maybe<Array<Maybe<Nbs_Ipe_Run_Field_Mutation_Response>>>;
  /** update data of the table: "nbs_ipe_run_link_field" */
  update_nbs_ipe_run_link_field?: Maybe<Nbs_Ipe_Run_Link_Field_Mutation_Response>;
  /** update single row of the table: "nbs_ipe_run_link_field" */
  update_nbs_ipe_run_link_field_by_pk?: Maybe<Nbs_Ipe_Run_Link_Field>;
  /** update multiples rows of table: "nbs_ipe_run_link_field" */
  update_nbs_ipe_run_link_field_many?: Maybe<Array<Maybe<Nbs_Ipe_Run_Link_Field_Mutation_Response>>>;
  /** update data of the table: "nbs_ipe_run_link_wide" */
  update_nbs_ipe_run_link_wide?: Maybe<Nbs_Ipe_Run_Link_Wide_Mutation_Response>;
  /** update single row of the table: "nbs_ipe_run_link_wide" */
  update_nbs_ipe_run_link_wide_by_pk?: Maybe<Nbs_Ipe_Run_Link_Wide>;
  /** update multiples rows of table: "nbs_ipe_run_link_wide" */
  update_nbs_ipe_run_link_wide_many?: Maybe<Array<Maybe<Nbs_Ipe_Run_Link_Wide_Mutation_Response>>>;
  /** update data of the table: "nbs_ipe_run_wide" */
  update_nbs_ipe_run_wide?: Maybe<Nbs_Ipe_Run_Wide_Mutation_Response>;
  /** update single row of the table: "nbs_ipe_run_wide" */
  update_nbs_ipe_run_wide_by_pk?: Maybe<Nbs_Ipe_Run_Wide>;
  /** update multiples rows of table: "nbs_ipe_run_wide" */
  update_nbs_ipe_run_wide_many?: Maybe<Array<Maybe<Nbs_Ipe_Run_Wide_Mutation_Response>>>;
  /** update data of the table: "nbs_multipart_upload" */
  update_nbs_multipart_upload?: Maybe<Nbs_Multipart_Upload_Mutation_Response>;
  /** update single row of the table: "nbs_multipart_upload" */
  update_nbs_multipart_upload_by_pk?: Maybe<Nbs_Multipart_Upload>;
  /** update multiples rows of table: "nbs_multipart_upload" */
  update_nbs_multipart_upload_many?: Maybe<Array<Maybe<Nbs_Multipart_Upload_Mutation_Response>>>;
  /** update data of the table: "nbs_wide_orthoimg_load" */
  update_nbs_wide_orthoimg_load?: Maybe<Nbs_Wide_Orthoimg_Load_Mutation_Response>;
  /** update single row of the table: "nbs_wide_orthoimg_load" */
  update_nbs_wide_orthoimg_load_by_pk?: Maybe<Nbs_Wide_Orthoimg_Load>;
  /** update multiples rows of table: "nbs_wide_orthoimg_load" */
  update_nbs_wide_orthoimg_load_many?: Maybe<Array<Maybe<Nbs_Wide_Orthoimg_Load_Mutation_Response>>>;
  /** update data of the table: "nbtrk_context" */
  update_nbtrk_context?: Maybe<Nbtrk_Context_Mutation_Response>;
  /** update single row of the table: "nbtrk_context" */
  update_nbtrk_context_by_pk?: Maybe<Nbtrk_Context>;
  /** update multiples rows of table: "nbtrk_context" */
  update_nbtrk_context_many?: Maybe<Array<Maybe<Nbtrk_Context_Mutation_Response>>>;
  /** update data of the table: "nbtrk_eph_location" */
  update_nbtrk_eph_location?: Maybe<Nbtrk_Eph_Location_Mutation_Response>;
  /** update single row of the table: "nbtrk_eph_location" */
  update_nbtrk_eph_location_by_pk?: Maybe<Nbtrk_Eph_Location>;
  /** update multiples rows of table: "nbtrk_eph_location" */
  update_nbtrk_eph_location_many?: Maybe<Array<Maybe<Nbtrk_Eph_Location_Mutation_Response>>>;
  /** update data of the table: "nipe_engine_information" */
  update_nipe_engine_information?: Maybe<Nipe_Engine_Information_Mutation_Response>;
  /** update single row of the table: "nipe_engine_information" */
  update_nipe_engine_information_by_pk?: Maybe<Nipe_Engine_Information>;
  /** update multiples rows of table: "nipe_engine_information" */
  update_nipe_engine_information_many?: Maybe<Array<Maybe<Nipe_Engine_Information_Mutation_Response>>>;
  /** update data of the table: "nipe_engine_link_orthoimg_type" */
  update_nipe_engine_link_orthoimg_type?: Maybe<Nipe_Engine_Link_Orthoimg_Type_Mutation_Response>;
  /** update single row of the table: "nipe_engine_link_orthoimg_type" */
  update_nipe_engine_link_orthoimg_type_by_pk?: Maybe<Nipe_Engine_Link_Orthoimg_Type>;
  /** update multiples rows of table: "nipe_engine_link_orthoimg_type" */
  update_nipe_engine_link_orthoimg_type_many?: Maybe<Array<Maybe<Nipe_Engine_Link_Orthoimg_Type_Mutation_Response>>>;
  /** update data of the table: "nipe_engine_run_status" */
  update_nipe_engine_run_status?: Maybe<Nipe_Engine_Run_Status_Mutation_Response>;
  /** update single row of the table: "nipe_engine_run_status" */
  update_nipe_engine_run_status_by_pk?: Maybe<Nipe_Engine_Run_Status>;
  /** update multiples rows of table: "nipe_engine_run_status" */
  update_nipe_engine_run_status_many?: Maybe<Array<Maybe<Nipe_Engine_Run_Status_Mutation_Response>>>;
  /** update data of the table: "nipe_run_command" */
  update_nipe_run_command?: Maybe<Nipe_Run_Command_Mutation_Response>;
  /** update single row of the table: "nipe_run_command" */
  update_nipe_run_command_by_pk?: Maybe<Nipe_Run_Command>;
  /** update multiples rows of table: "nipe_run_command" */
  update_nipe_run_command_many?: Maybe<Array<Maybe<Nipe_Run_Command_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_App_UserArgs = {
  where: App_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_User_ActiveArgs = {
  where: App_User_Active_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_User_Active_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_App_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_App_User_Property_PrivateArgs = {
  where: App_User_Property_Private_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_User_Property_Private_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Application_User_Invitation_To_CompanyArgs = {
  where: Application_User_Invitation_To_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Application_User_Invitation_To_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Area_MeshArgs = {
  where: Master_Nb_Area_Mesh_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Area_Mesh_By_PkArgs = {
  code: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Integrated_FarmlandArgs = {
  where: Master_Nb_Integrated_Farmland_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Integrated_Farmland_By_PkArgs = {
  key: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_MfieldArgs = {
  where: Master_Nb_Mfield_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Mfield_By_PkArgs = {
  key: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Mfield_Polygon_2022_1Args = {
  where: Master_Nb_Mfield_Polygon_2022_1_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Mfield_Polygon_2022_1_By_PkArgs = {
  key: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Mfield_Polygon_2023_1Args = {
  where: Master_Nb_Mfield_Polygon_2023_1_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Mfield_Polygon_2023_1_By_PkArgs = {
  key: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Mfield_Polygon_2024_1Args = {
  where: Master_Nb_Mfield_Polygon_2024_1_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Mfield_Polygon_2024_1_By_PkArgs = {
  key: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Mfield_VersionArgs = {
  where: Master_Nb_Mfield_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Mfield_Version_By_PkArgs = {
  id: Scalars['smallint'];
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Postgis_VersionArgs = {
  where: Master_Nb_Postgis_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Postgis_Version_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Weather_AttrArgs = {
  where: Master_Nb_Weather_Attr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Weather_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Weather_DataArgs = {
  where: Master_Nb_Weather_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Nb_Weather_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Actual_Crop_YeildArgs = {
  where: Nb_Actual_Crop_Yeild_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Actual_Crop_Yeild_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Area_MeshArgs = {
  where: Nb_Area_Mesh_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Area_Mesh_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_CompanyArgs = {
  where: Nb_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Company_InfoArgs = {
  where: Nb_Company_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Company_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Conditioner_WorkArgs = {
  where: Nb_Conditioner_Work_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Conditioner_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Cplan_Imsensing_LinkArgs = {
  where: Nb_Cplan_Imsensing_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Cplan_Imsensing_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_CropArgs = {
  where: Nb_Crop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Crop_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Crop_Company_LinkArgs = {
  where: Nb_Crop_Company_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Crop_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Crop_Method_LinkArgs = {
  where: Nb_Crop_Method_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Crop_Method_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_CultivarArgs = {
  where: Nb_Cultivar_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Cultivar_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Cultivation_MethodArgs = {
  where: Nb_Cultivation_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Cultivation_Method_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Cultivation_PlanArgs = {
  where: Nb_Cultivation_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Cultivation_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Demo_Task_TypeArgs = {
  where: Nb_Demo_Task_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Demo_Task_Type_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Demo_User_LocationArgs = {
  where: Nb_Demo_User_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Demo_User_Location_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Demo_User_NameArgs = {
  where: Nb_Demo_User_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Demo_User_Name_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Est_Working_LoadArgs = {
  where: Nb_Est_Working_Load_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Est_Working_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Fertilization_MethodArgs = {
  where: Nb_Fertilization_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Fertilization_Method_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Fertilization_WorkArgs = {
  where: Nb_Fertilization_Work_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Fertilization_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_FertilizerArgs = {
  where: Nb_Fertilizer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Fertilizer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Fertilizer_Company_LinkArgs = {
  where: Nb_Fertilizer_Company_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Fertilizer_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Fertilizer_PictureArgs = {
  where: Nb_Fertilizer_Picture_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Fertilizer_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_FileArgs = {
  where: Nb_Field_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_File_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_File_LoadArgs = {
  where: Nb_Field_File_Load_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_File_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_File_SizeArgs = {
  where: Nb_Field_File_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_MemoArgs = {
  where: Nb_Field_Memo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Memo_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_OrthoimgArgs = {
  where: Nb_Field_Orthoimg_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Orthoimg_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Orthoimg_SizeArgs = {
  where: Nb_Field_Orthoimg_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_PhotographerArgs = {
  where: Nb_Field_Photographer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Photographer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_PictureArgs = {
  where: Nb_Field_Picture_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Picture_Cplan_LinkArgs = {
  where: Nb_Field_Picture_Cplan_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Picture_Cplan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Picture_LoadArgs = {
  where: Nb_Field_Picture_Load_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Picture_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Picture_SizeArgs = {
  where: Nb_Field_Picture_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Polygon_AttrArgs = {
  where: Nb_Field_Polygon_Attr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Polygon_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_PropertyArgs = {
  where: Nb_Field_Property_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Property_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_SensingArgs = {
  where: Nb_Field_Sensing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Sensing_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Sensing_DataArgs = {
  where: Nb_Field_Sensing_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Sensing_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Water_GateArgs = {
  where: Nb_Field_Water_Gate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Water_Gate_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Water_Gate_StatusArgs = {
  where: Nb_Field_Water_Gate_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Field_Water_Gate_Status_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_File_SizeArgs = {
  where: Nb_File_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_File_Size_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_File_TypeArgs = {
  where: Nb_File_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_File_Type_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Finished_Ufield_WorkArgs = {
  where: Nb_Finished_Ufield_Work_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Finished_Ufield_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Image_Sensing_AttrArgs = {
  where: Nb_Image_Sensing_Attr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Image_Sensing_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Imsensing_ColormapArgs = {
  where: Nb_Imsensing_Colormap_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Imsensing_Colormap_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Log_User_PositionArgs = {
  where: Nb_Log_User_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Log_User_Position_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Maff_FieldArgs = {
  where: Nb_Maff_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Maff_Field_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Maff_Field_LinkArgs = {
  where: Nb_Maff_Field_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Maff_Field_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Memo_File_LinkArgs = {
  where: Nb_Memo_File_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Memo_File_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Memo_Picture_LinkArgs = {
  where: Nb_Memo_Picture_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Memo_Picture_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Memo_Ufg_LinkArgs = {
  where: Nb_Memo_Ufg_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Memo_Ufg_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Memo_Ufield_LinkArgs = {
  where: Nb_Memo_Ufield_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Memo_Ufield_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_MfieldArgs = {
  where: Nb_Mfield_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Mfield_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Mfield_LinkArgs = {
  where: Nb_Mfield_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Mfield_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Performed_WorkArgs = {
  where: Nb_Performed_Work_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Performed_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_PesticideArgs = {
  where: Nb_Pesticide_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Pesticide_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Pesticide_Company_LinkArgs = {
  where: Nb_Pesticide_Company_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Pesticide_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Pesticide_IngredientArgs = {
  where: Nb_Pesticide_Ingredient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Pesticide_Ingredient_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Pesticide_Spray_WorkArgs = {
  where: Nb_Pesticide_Spray_Work_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Pesticide_Spray_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Picture_AnalysisArgs = {
  where: Nb_Picture_Analysis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Picture_Analysis_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Picture_Analysis_TypeArgs = {
  where: Nb_Picture_Analysis_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Picture_Analysis_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Raster_Image_SensingArgs = {
  where: Nb_Raster_Image_Sensing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Raster_Image_Sensing_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Raster_Ims_SizeArgs = {
  where: Nb_Raster_Ims_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chara_MapArgs = {
  where: Nb_Soil_Chara_Map_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chara_Map_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chara_Map_SellistArgs = {
  where: Nb_Soil_Chara_Map_Sellist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chara_Map_Sellist_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_CharacteristicArgs = {
  where: Nb_Soil_Characteristic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Characteristic_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_ChemistryArgs = {
  where: Nb_Soil_Chemistry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chemistry_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chemistry_Proper_RangeArgs = {
  where: Nb_Soil_Chemistry_Proper_Range_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chemistry_Proper_Range_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chemistry_StandardsArgs = {
  where: Nb_Soil_Chemistry_Standards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chemistry_Standards_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chemistry_Suitable_RangeArgs = {
  where: Nb_Soil_Chemistry_Suitable_Range_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chemistry_Suitable_Range_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chemistry_ValueArgs = {
  where: Nb_Soil_Chemistry_Value_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Chemistry_Value_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_ExplanationArgs = {
  where: Nb_Soil_Explanation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Explanation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_PhysicsArgs = {
  where: Nb_Soil_Physics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Soil_Physics_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Task_TypeArgs = {
  where: Nb_Task_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Task_Type_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Tasktype_Company_LinkArgs = {
  where: Nb_Tasktype_Company_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Tasktype_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufattr_LinkArgs = {
  where: Nb_Ufattr_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufattr_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufg_LinkArgs = {
  where: Nb_Ufg_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufg_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufg_Member_LinkArgs = {
  where: Nb_Ufg_Member_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufg_Member_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_UfieldArgs = {
  where: Nb_Ufield_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufield_AttributeArgs = {
  where: Nb_Ufield_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufield_Attribute_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufield_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufield_Company_LinkArgs = {
  where: Nb_Ufield_Company_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufield_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufield_GroupArgs = {
  where: Nb_Ufield_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufield_Group_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufield_HistoryArgs = {
  where: Nb_Ufield_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Ufield_History_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Vector_Image_SensingArgs = {
  where: Nb_Vector_Image_Sensing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Vector_Image_Sensing_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Vector_Ims_SizeArgs = {
  where: Nb_Vector_Ims_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Water_Gate_Status_Cplan_LinkArgs = {
  where: Nb_Water_Gate_Status_Cplan_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Water_Gate_Status_Cplan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Weather_AttrArgs = {
  where: Nb_Weather_Attr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Weather_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Weather_DataArgs = {
  where: Nb_Weather_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Weather_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Wide_OrthoimgArgs = {
  where: Nb_Wide_Orthoimg_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Wide_Orthoimg_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nb_Wide_Orthoimg_SizeArgs = {
  where: Nb_Wide_Orthoimg_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Working_MachineArgs = {
  where: Nb_Working_Machine_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nb_Working_Machine_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbb_Picture_Analysis_StatusArgs = {
  where: Nbb_Picture_Analysis_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbb_Picture_Analysis_Status_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbbe_MicroclimateArgs = {
  where: Nbbe_Microclimate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbbe_Microclimate_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbbe_Orthoimg_TypeArgs = {
  where: Nbbe_Orthoimg_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbbe_Orthoimg_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Nbbe_Weather_TypeArgs = {
  where: Nbbe_Weather_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbbe_Weather_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_AuthorizationArgs = {
  where: Nbpm_Authorization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Authorization_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Contract_PlanArgs = {
  where: Nbpm_Contract_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Contract_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Contract_Plan_LinkArgs = {
  where: Nbpm_Contract_Plan_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Contract_Plan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Imsattr_Authorization_LinkArgs = {
  where: Nbpm_Imsattr_Authorization_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Imsattr_Authorization_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Ipengine_Authorization_LinkArgs = {
  where: Nbpm_Ipengine_Authorization_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Ipengine_Authorization_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Picanatype_Authorization_LinkArgs = {
  where: Nbpm_Picanatype_Authorization_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Picanatype_Authorization_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Service_PlanArgs = {
  where: Nbpm_Service_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Service_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Service_Plan_LinkArgs = {
  where: Nbpm_Service_Plan_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_Service_Plan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_User_Attr_LinkArgs = {
  where: Nbpm_User_Attr_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_User_Attr_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_User_AttributeArgs = {
  where: Nbpm_User_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_User_Attribute_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_User_Cp_Link_LinkArgs = {
  where: Nbpm_User_Cp_Link_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbpm_User_Cp_Link_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Ipe_Run_FieldArgs = {
  where: Nbs_Ipe_Run_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Ipe_Run_Field_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Ipe_Run_Link_FieldArgs = {
  where: Nbs_Ipe_Run_Link_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Ipe_Run_Link_Field_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Ipe_Run_Link_WideArgs = {
  where: Nbs_Ipe_Run_Link_Wide_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Ipe_Run_Link_Wide_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Ipe_Run_WideArgs = {
  where: Nbs_Ipe_Run_Wide_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Ipe_Run_Wide_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Multipart_UploadArgs = {
  where: Nbs_Multipart_Upload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Multipart_Upload_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Wide_Orthoimg_LoadArgs = {
  where: Nbs_Wide_Orthoimg_Load_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbs_Wide_Orthoimg_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbtrk_ContextArgs = {
  where: Nbtrk_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbtrk_Context_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nbtrk_Eph_LocationArgs = {
  where: Nbtrk_Eph_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nbtrk_Eph_Location_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nipe_Engine_InformationArgs = {
  where: Nipe_Engine_Information_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nipe_Engine_Information_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nipe_Engine_Link_Orthoimg_TypeArgs = {
  where: Nipe_Engine_Link_Orthoimg_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nipe_Engine_Link_Orthoimg_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nipe_Engine_Run_StatusArgs = {
  where: Nipe_Engine_Run_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nipe_Engine_Run_Status_By_PkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Nipe_Run_CommandArgs = {
  where: Nipe_Run_Command_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nipe_Run_Command_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootFastapiCompleteMultipartUploadArgs = {
  companyId: Scalars['UUID'];
  uploadId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootFastapiInsertAGroupInCompanyArgs = {
  groupName: Scalars['String'];
  nbCompanyId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiInsertAUfieldInCompanyArgs = {
  fieldName: Scalars['String'];
  nbCompanyId: Scalars['UUID'];
  nbMfieldKey: Scalars['String'];
  nbUfieldGroupId: Scalars['UUID'];
  polygonField?: InputMaybe<Scalars['JSON']>;
};


/** mutation root */
export type Mutation_RootFastapiInsertAUfieldInCompanyV2Args = {
  fieldName: Scalars['String'];
  nbCompanyId: Scalars['UUID'];
  nbMfieldKey: Scalars['String'];
  nbUfieldGroupId: Scalars['UUID'];
  polygonField?: InputMaybe<Scalars['JSON']>;
};


/** mutation root */
export type Mutation_RootFastapiInviteUserToTheCompanyArgs = {
  email: Scalars['String'];
  nbCompanyId: Scalars['UUID'];
  ufieldGroupId?: InputMaybe<Scalars['UUID']>;
  userAttribute: Scalars['String'];
};


/** mutation root */
export type Mutation_RootFastapiLaunchIpEngineForWideArgs = {
  companyId: Scalars['UUID'];
  engineInformationId: Scalars['UUID'];
  wideOrthoimgIds: Array<Scalars['UUID']>;
};


/** mutation root */
export type Mutation_RootFastapiM2mAcceptIpeCommandArgs = {
  id: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiM2mDoneIpeCommandArgs = {
  id: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiM2mErrorIpeCommandArgs = {
  id: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiRemoveAMemberFromTheCompanyArgs = {
  appUserActiveId: Scalars['UUID'];
  nbCompanyId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiRemoveAUfieldGroupArgs = {
  nbCompanyId: Scalars['UUID'];
  nbUfieldGroupId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiRemoveAUfieldInCompanyArgs = {
  nbCompanyId: Scalars['UUID'];
  nbUfieldId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiRemoveFieldPictureArgs = {
  nbFieldPictureId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiRemoveUfieldAttributeArgs = {
  nbUfieldAttribute: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiReplyApplicationToRegisterAMemberWithTheCompanyArgs = {
  applicationId: Scalars['UUID'];
  isApproved: Scalars['Boolean'];
};


/** mutation root */
export type Mutation_RootFastapiRequestAPictureAnalysisArgs = {
  nbFieldPictureId: Scalars['UUID'];
  nbPictureAnalysisTypeId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiS3SignedUrlFieldFileArgs = {
  companyId: Scalars['UUID'];
  filename: Scalars['String'];
  ufieldId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiS3SignedUrlFieldPictureArgs = {
  companyId: Scalars['UUID'];
  filename: Scalars['String'];
};


/** mutation root */
export type Mutation_RootFastapiS3SignedUrlWideOrthoimgArgs = {
  companyId: Scalars['UUID'];
  filename: Scalars['String'];
  numberOfParts: Scalars['Int'];
  orthoimgType: Scalars['String'];
  partMd5s: Array<Scalars['String']>;
  partSizes: Array<Scalars['Int']>;
  sensDatetime: Scalars['String'];
};


/** mutation root */
export type Mutation_RootFastapiSetAttributesForTheUfieldArgs = {
  nbUfield: Scalars['UUID'];
  nbUfieldAttributes: Array<Scalars['UUID']>;
};


/** mutation root */
export type Mutation_RootFastapiSwitchUserAttributeWithTheCompanyArgs = {
  appUserActiveId: Scalars['UUID'];
  nbCompanyId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiTerminateWideOrthoimgLoadArgs = {
  companyId: Scalars['UUID'];
  uploadId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootFastapiUpdateAPictureToAUfieldArgs = {
  nbCompanyId: Scalars['UUID'];
  nbFieldPictureId: Scalars['UUID'];
  nbUfieldId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiUpdateAUfgLinkArgs = {
  nbCompanyId: Scalars['UUID'];
  nbUfieldId: Scalars['UUID'];
  postNbUfieldGroupId: Scalars['UUID'];
  preNbUfieldGroupId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiUpdateAUfieldFieldNameInCompanyArgs = {
  nbCompanyId: Scalars['UUID'];
  nbUfieldFieldName: Scalars['String'];
  nbUfieldId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiUpdateAUfieldFieldPolygonInCompanyArgs = {
  nbCompanyId: Scalars['UUID'];
  nbUfieldFieldPolygon: Scalars['String'];
  nbUfieldId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootFastapiUpdateAUfieldGroupNameArgs = {
  groupName: Scalars['String'];
  nbCompanyId: Scalars['UUID'];
  nbUfieldGroupId: Scalars['UUID'];
};


/** mutation root */
export type Mutation_RootInsert_App_UserArgs = {
  objects: Array<App_User_Insert_Input>;
  on_conflict?: InputMaybe<App_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_User_ActiveArgs = {
  objects: Array<App_User_Active_Insert_Input>;
  on_conflict?: InputMaybe<App_User_Active_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_User_Active_OneArgs = {
  object: App_User_Active_Insert_Input;
  on_conflict?: InputMaybe<App_User_Active_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_User_OneArgs = {
  object: App_User_Insert_Input;
  on_conflict?: InputMaybe<App_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_User_Property_PrivateArgs = {
  objects: Array<App_User_Property_Private_Insert_Input>;
  on_conflict?: InputMaybe<App_User_Property_Private_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_User_Property_Private_OneArgs = {
  object: App_User_Property_Private_Insert_Input;
  on_conflict?: InputMaybe<App_User_Property_Private_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Application_User_Invitation_To_CompanyArgs = {
  objects: Array<Application_User_Invitation_To_Company_Insert_Input>;
  on_conflict?: InputMaybe<Application_User_Invitation_To_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Application_User_Invitation_To_Company_OneArgs = {
  object: Application_User_Invitation_To_Company_Insert_Input;
  on_conflict?: InputMaybe<Application_User_Invitation_To_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Area_MeshArgs = {
  objects: Array<Master_Nb_Area_Mesh_Insert_Input>;
  on_conflict?: InputMaybe<Master_Nb_Area_Mesh_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Area_Mesh_OneArgs = {
  object: Master_Nb_Area_Mesh_Insert_Input;
  on_conflict?: InputMaybe<Master_Nb_Area_Mesh_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Integrated_FarmlandArgs = {
  objects: Array<Master_Nb_Integrated_Farmland_Insert_Input>;
  on_conflict?: InputMaybe<Master_Nb_Integrated_Farmland_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Integrated_Farmland_OneArgs = {
  object: Master_Nb_Integrated_Farmland_Insert_Input;
  on_conflict?: InputMaybe<Master_Nb_Integrated_Farmland_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_MfieldArgs = {
  objects: Array<Master_Nb_Mfield_Insert_Input>;
  on_conflict?: InputMaybe<Master_Nb_Mfield_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Mfield_OneArgs = {
  object: Master_Nb_Mfield_Insert_Input;
  on_conflict?: InputMaybe<Master_Nb_Mfield_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Mfield_Polygon_2022_1Args = {
  objects: Array<Master_Nb_Mfield_Polygon_2022_1_Insert_Input>;
  on_conflict?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Mfield_Polygon_2022_1_OneArgs = {
  object: Master_Nb_Mfield_Polygon_2022_1_Insert_Input;
  on_conflict?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Mfield_Polygon_2023_1Args = {
  objects: Array<Master_Nb_Mfield_Polygon_2023_1_Insert_Input>;
  on_conflict?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Mfield_Polygon_2023_1_OneArgs = {
  object: Master_Nb_Mfield_Polygon_2023_1_Insert_Input;
  on_conflict?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Mfield_Polygon_2024_1Args = {
  objects: Array<Master_Nb_Mfield_Polygon_2024_1_Insert_Input>;
  on_conflict?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Mfield_Polygon_2024_1_OneArgs = {
  object: Master_Nb_Mfield_Polygon_2024_1_Insert_Input;
  on_conflict?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Mfield_VersionArgs = {
  objects: Array<Master_Nb_Mfield_Version_Insert_Input>;
  on_conflict?: InputMaybe<Master_Nb_Mfield_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Mfield_Version_OneArgs = {
  object: Master_Nb_Mfield_Version_Insert_Input;
  on_conflict?: InputMaybe<Master_Nb_Mfield_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Postgis_VersionArgs = {
  objects: Array<Master_Nb_Postgis_Version_Insert_Input>;
  on_conflict?: InputMaybe<Master_Nb_Postgis_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Postgis_Version_OneArgs = {
  object: Master_Nb_Postgis_Version_Insert_Input;
  on_conflict?: InputMaybe<Master_Nb_Postgis_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Weather_AttrArgs = {
  objects: Array<Master_Nb_Weather_Attr_Insert_Input>;
  on_conflict?: InputMaybe<Master_Nb_Weather_Attr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Weather_Attr_OneArgs = {
  object: Master_Nb_Weather_Attr_Insert_Input;
  on_conflict?: InputMaybe<Master_Nb_Weather_Attr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Weather_DataArgs = {
  objects: Array<Master_Nb_Weather_Data_Insert_Input>;
  on_conflict?: InputMaybe<Master_Nb_Weather_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Nb_Weather_Data_OneArgs = {
  object: Master_Nb_Weather_Data_Insert_Input;
  on_conflict?: InputMaybe<Master_Nb_Weather_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Actual_Crop_YeildArgs = {
  objects: Array<Nb_Actual_Crop_Yeild_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Actual_Crop_Yeild_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Actual_Crop_Yeild_OneArgs = {
  object: Nb_Actual_Crop_Yeild_Insert_Input;
  on_conflict?: InputMaybe<Nb_Actual_Crop_Yeild_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Area_MeshArgs = {
  objects: Array<Nb_Area_Mesh_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Area_Mesh_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Area_Mesh_OneArgs = {
  object: Nb_Area_Mesh_Insert_Input;
  on_conflict?: InputMaybe<Nb_Area_Mesh_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_CompanyArgs = {
  objects: Array<Nb_Company_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Company_InfoArgs = {
  objects: Array<Nb_Company_Info_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Company_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Company_Info_OneArgs = {
  object: Nb_Company_Info_Insert_Input;
  on_conflict?: InputMaybe<Nb_Company_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Company_OneArgs = {
  object: Nb_Company_Insert_Input;
  on_conflict?: InputMaybe<Nb_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Conditioner_WorkArgs = {
  objects: Array<Nb_Conditioner_Work_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Conditioner_Work_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Conditioner_Work_OneArgs = {
  object: Nb_Conditioner_Work_Insert_Input;
  on_conflict?: InputMaybe<Nb_Conditioner_Work_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Cplan_Imsensing_LinkArgs = {
  objects: Array<Nb_Cplan_Imsensing_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Cplan_Imsensing_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Cplan_Imsensing_Link_OneArgs = {
  object: Nb_Cplan_Imsensing_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Cplan_Imsensing_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_CropArgs = {
  objects: Array<Nb_Crop_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Crop_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Crop_Company_LinkArgs = {
  objects: Array<Nb_Crop_Company_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Crop_Company_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Crop_Company_Link_OneArgs = {
  object: Nb_Crop_Company_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Crop_Company_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Crop_Method_LinkArgs = {
  objects: Array<Nb_Crop_Method_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Crop_Method_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Crop_Method_Link_OneArgs = {
  object: Nb_Crop_Method_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Crop_Method_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Crop_OneArgs = {
  object: Nb_Crop_Insert_Input;
  on_conflict?: InputMaybe<Nb_Crop_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_CultivarArgs = {
  objects: Array<Nb_Cultivar_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Cultivar_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Cultivar_OneArgs = {
  object: Nb_Cultivar_Insert_Input;
  on_conflict?: InputMaybe<Nb_Cultivar_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Cultivation_MethodArgs = {
  objects: Array<Nb_Cultivation_Method_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Cultivation_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Cultivation_Method_OneArgs = {
  object: Nb_Cultivation_Method_Insert_Input;
  on_conflict?: InputMaybe<Nb_Cultivation_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Cultivation_PlanArgs = {
  objects: Array<Nb_Cultivation_Plan_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Cultivation_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Cultivation_Plan_OneArgs = {
  object: Nb_Cultivation_Plan_Insert_Input;
  on_conflict?: InputMaybe<Nb_Cultivation_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Demo_Task_TypeArgs = {
  objects: Array<Nb_Demo_Task_Type_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Demo_Task_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Demo_Task_Type_OneArgs = {
  object: Nb_Demo_Task_Type_Insert_Input;
  on_conflict?: InputMaybe<Nb_Demo_Task_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Demo_User_LocationArgs = {
  objects: Array<Nb_Demo_User_Location_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Demo_User_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Demo_User_Location_OneArgs = {
  object: Nb_Demo_User_Location_Insert_Input;
  on_conflict?: InputMaybe<Nb_Demo_User_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Demo_User_NameArgs = {
  objects: Array<Nb_Demo_User_Name_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Demo_User_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Demo_User_Name_OneArgs = {
  object: Nb_Demo_User_Name_Insert_Input;
  on_conflict?: InputMaybe<Nb_Demo_User_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Est_Working_LoadArgs = {
  objects: Array<Nb_Est_Working_Load_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Est_Working_Load_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Est_Working_Load_OneArgs = {
  object: Nb_Est_Working_Load_Insert_Input;
  on_conflict?: InputMaybe<Nb_Est_Working_Load_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Fertilization_MethodArgs = {
  objects: Array<Nb_Fertilization_Method_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Fertilization_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Fertilization_Method_OneArgs = {
  object: Nb_Fertilization_Method_Insert_Input;
  on_conflict?: InputMaybe<Nb_Fertilization_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Fertilization_WorkArgs = {
  objects: Array<Nb_Fertilization_Work_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Fertilization_Work_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Fertilization_Work_OneArgs = {
  object: Nb_Fertilization_Work_Insert_Input;
  on_conflict?: InputMaybe<Nb_Fertilization_Work_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_FertilizerArgs = {
  objects: Array<Nb_Fertilizer_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Fertilizer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Fertilizer_Company_LinkArgs = {
  objects: Array<Nb_Fertilizer_Company_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Fertilizer_Company_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Fertilizer_Company_Link_OneArgs = {
  object: Nb_Fertilizer_Company_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Fertilizer_Company_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Fertilizer_OneArgs = {
  object: Nb_Fertilizer_Insert_Input;
  on_conflict?: InputMaybe<Nb_Fertilizer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Fertilizer_PictureArgs = {
  objects: Array<Nb_Fertilizer_Picture_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Fertilizer_Picture_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Fertilizer_Picture_OneArgs = {
  object: Nb_Fertilizer_Picture_Insert_Input;
  on_conflict?: InputMaybe<Nb_Fertilizer_Picture_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_FileArgs = {
  objects: Array<Nb_Field_File_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_File_LoadArgs = {
  objects: Array<Nb_Field_File_Load_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_File_Load_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_File_Load_OneArgs = {
  object: Nb_Field_File_Load_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_File_Load_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_File_OneArgs = {
  object: Nb_Field_File_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_File_SizeArgs = {
  objects: Array<Nb_Field_File_Size_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_File_Size_OneArgs = {
  object: Nb_Field_File_Size_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_MemoArgs = {
  objects: Array<Nb_Field_Memo_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Memo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Memo_OneArgs = {
  object: Nb_Field_Memo_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Memo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_OrthoimgArgs = {
  objects: Array<Nb_Field_Orthoimg_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Orthoimg_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Orthoimg_OneArgs = {
  object: Nb_Field_Orthoimg_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Orthoimg_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Orthoimg_SizeArgs = {
  objects: Array<Nb_Field_Orthoimg_Size_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Orthoimg_Size_OneArgs = {
  object: Nb_Field_Orthoimg_Size_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_PhotographerArgs = {
  objects: Array<Nb_Field_Photographer_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Photographer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Photographer_OneArgs = {
  object: Nb_Field_Photographer_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Photographer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_PictureArgs = {
  objects: Array<Nb_Field_Picture_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Picture_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Picture_Cplan_LinkArgs = {
  objects: Array<Nb_Field_Picture_Cplan_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Picture_Cplan_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Picture_Cplan_Link_OneArgs = {
  object: Nb_Field_Picture_Cplan_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Picture_Cplan_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Picture_LoadArgs = {
  objects: Array<Nb_Field_Picture_Load_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Picture_Load_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Picture_Load_OneArgs = {
  object: Nb_Field_Picture_Load_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Picture_Load_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Picture_OneArgs = {
  object: Nb_Field_Picture_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Picture_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Picture_SizeArgs = {
  objects: Array<Nb_Field_Picture_Size_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Picture_Size_OneArgs = {
  object: Nb_Field_Picture_Size_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Polygon_AttrArgs = {
  objects: Array<Nb_Field_Polygon_Attr_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Polygon_Attr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Polygon_Attr_OneArgs = {
  object: Nb_Field_Polygon_Attr_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Polygon_Attr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_PropertyArgs = {
  objects: Array<Nb_Field_Property_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Property_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Property_OneArgs = {
  object: Nb_Field_Property_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Property_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_SensingArgs = {
  objects: Array<Nb_Field_Sensing_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Sensing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Sensing_DataArgs = {
  objects: Array<Nb_Field_Sensing_Data_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Sensing_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Sensing_Data_OneArgs = {
  object: Nb_Field_Sensing_Data_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Sensing_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Sensing_OneArgs = {
  object: Nb_Field_Sensing_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Sensing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Water_GateArgs = {
  objects: Array<Nb_Field_Water_Gate_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Water_Gate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Water_Gate_OneArgs = {
  object: Nb_Field_Water_Gate_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Water_Gate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Water_Gate_StatusArgs = {
  objects: Array<Nb_Field_Water_Gate_Status_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Field_Water_Gate_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Field_Water_Gate_Status_OneArgs = {
  object: Nb_Field_Water_Gate_Status_Insert_Input;
  on_conflict?: InputMaybe<Nb_Field_Water_Gate_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_File_SizeArgs = {
  objects: Array<Nb_File_Size_Insert_Input>;
  on_conflict?: InputMaybe<Nb_File_Size_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_File_Size_OneArgs = {
  object: Nb_File_Size_Insert_Input;
  on_conflict?: InputMaybe<Nb_File_Size_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_File_TypeArgs = {
  objects: Array<Nb_File_Type_Insert_Input>;
  on_conflict?: InputMaybe<Nb_File_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_File_Type_OneArgs = {
  object: Nb_File_Type_Insert_Input;
  on_conflict?: InputMaybe<Nb_File_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Finished_Ufield_WorkArgs = {
  objects: Array<Nb_Finished_Ufield_Work_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Finished_Ufield_Work_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Finished_Ufield_Work_OneArgs = {
  object: Nb_Finished_Ufield_Work_Insert_Input;
  on_conflict?: InputMaybe<Nb_Finished_Ufield_Work_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Image_Sensing_AttrArgs = {
  objects: Array<Nb_Image_Sensing_Attr_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Image_Sensing_Attr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Image_Sensing_Attr_OneArgs = {
  object: Nb_Image_Sensing_Attr_Insert_Input;
  on_conflict?: InputMaybe<Nb_Image_Sensing_Attr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Imsensing_ColormapArgs = {
  objects: Array<Nb_Imsensing_Colormap_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Imsensing_Colormap_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Imsensing_Colormap_OneArgs = {
  object: Nb_Imsensing_Colormap_Insert_Input;
  on_conflict?: InputMaybe<Nb_Imsensing_Colormap_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Log_User_PositionArgs = {
  objects: Array<Nb_Log_User_Position_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Log_User_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Log_User_Position_OneArgs = {
  object: Nb_Log_User_Position_Insert_Input;
  on_conflict?: InputMaybe<Nb_Log_User_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Maff_FieldArgs = {
  objects: Array<Nb_Maff_Field_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Maff_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Maff_Field_LinkArgs = {
  objects: Array<Nb_Maff_Field_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Maff_Field_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Maff_Field_Link_OneArgs = {
  object: Nb_Maff_Field_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Maff_Field_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Maff_Field_OneArgs = {
  object: Nb_Maff_Field_Insert_Input;
  on_conflict?: InputMaybe<Nb_Maff_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Memo_File_LinkArgs = {
  objects: Array<Nb_Memo_File_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Memo_File_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Memo_File_Link_OneArgs = {
  object: Nb_Memo_File_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Memo_File_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Memo_Picture_LinkArgs = {
  objects: Array<Nb_Memo_Picture_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Memo_Picture_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Memo_Picture_Link_OneArgs = {
  object: Nb_Memo_Picture_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Memo_Picture_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Memo_Ufg_LinkArgs = {
  objects: Array<Nb_Memo_Ufg_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Memo_Ufg_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Memo_Ufg_Link_OneArgs = {
  object: Nb_Memo_Ufg_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Memo_Ufg_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Memo_Ufield_LinkArgs = {
  objects: Array<Nb_Memo_Ufield_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Memo_Ufield_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Memo_Ufield_Link_OneArgs = {
  object: Nb_Memo_Ufield_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Memo_Ufield_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_MfieldArgs = {
  objects: Array<Nb_Mfield_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Mfield_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Mfield_LinkArgs = {
  objects: Array<Nb_Mfield_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Mfield_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Mfield_Link_OneArgs = {
  object: Nb_Mfield_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Mfield_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Mfield_OneArgs = {
  object: Nb_Mfield_Insert_Input;
  on_conflict?: InputMaybe<Nb_Mfield_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Performed_WorkArgs = {
  objects: Array<Nb_Performed_Work_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Performed_Work_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Performed_Work_OneArgs = {
  object: Nb_Performed_Work_Insert_Input;
  on_conflict?: InputMaybe<Nb_Performed_Work_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_PesticideArgs = {
  objects: Array<Nb_Pesticide_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Pesticide_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Pesticide_Company_LinkArgs = {
  objects: Array<Nb_Pesticide_Company_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Pesticide_Company_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Pesticide_Company_Link_OneArgs = {
  object: Nb_Pesticide_Company_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Pesticide_Company_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Pesticide_IngredientArgs = {
  objects: Array<Nb_Pesticide_Ingredient_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Pesticide_Ingredient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Pesticide_Ingredient_OneArgs = {
  object: Nb_Pesticide_Ingredient_Insert_Input;
  on_conflict?: InputMaybe<Nb_Pesticide_Ingredient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Pesticide_OneArgs = {
  object: Nb_Pesticide_Insert_Input;
  on_conflict?: InputMaybe<Nb_Pesticide_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Pesticide_Spray_WorkArgs = {
  objects: Array<Nb_Pesticide_Spray_Work_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Pesticide_Spray_Work_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Pesticide_Spray_Work_OneArgs = {
  object: Nb_Pesticide_Spray_Work_Insert_Input;
  on_conflict?: InputMaybe<Nb_Pesticide_Spray_Work_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Picture_AnalysisArgs = {
  objects: Array<Nb_Picture_Analysis_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Picture_Analysis_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Picture_Analysis_OneArgs = {
  object: Nb_Picture_Analysis_Insert_Input;
  on_conflict?: InputMaybe<Nb_Picture_Analysis_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Picture_Analysis_TypeArgs = {
  objects: Array<Nb_Picture_Analysis_Type_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Picture_Analysis_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Picture_Analysis_Type_OneArgs = {
  object: Nb_Picture_Analysis_Type_Insert_Input;
  on_conflict?: InputMaybe<Nb_Picture_Analysis_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Raster_Image_SensingArgs = {
  objects: Array<Nb_Raster_Image_Sensing_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Raster_Image_Sensing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Raster_Image_Sensing_OneArgs = {
  object: Nb_Raster_Image_Sensing_Insert_Input;
  on_conflict?: InputMaybe<Nb_Raster_Image_Sensing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Raster_Ims_SizeArgs = {
  objects: Array<Nb_Raster_Ims_Size_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Raster_Ims_Size_OneArgs = {
  object: Nb_Raster_Ims_Size_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chara_MapArgs = {
  objects: Array<Nb_Soil_Chara_Map_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Soil_Chara_Map_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chara_Map_OneArgs = {
  object: Nb_Soil_Chara_Map_Insert_Input;
  on_conflict?: InputMaybe<Nb_Soil_Chara_Map_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chara_Map_SellistArgs = {
  objects: Array<Nb_Soil_Chara_Map_Sellist_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Soil_Chara_Map_Sellist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chara_Map_Sellist_OneArgs = {
  object: Nb_Soil_Chara_Map_Sellist_Insert_Input;
  on_conflict?: InputMaybe<Nb_Soil_Chara_Map_Sellist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_CharacteristicArgs = {
  objects: Array<Nb_Soil_Characteristic_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Soil_Characteristic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Characteristic_OneArgs = {
  object: Nb_Soil_Characteristic_Insert_Input;
  on_conflict?: InputMaybe<Nb_Soil_Characteristic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_ChemistryArgs = {
  objects: Array<Nb_Soil_Chemistry_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chemistry_OneArgs = {
  object: Nb_Soil_Chemistry_Insert_Input;
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chemistry_Proper_RangeArgs = {
  objects: Array<Nb_Soil_Chemistry_Proper_Range_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chemistry_Proper_Range_OneArgs = {
  object: Nb_Soil_Chemistry_Proper_Range_Insert_Input;
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chemistry_StandardsArgs = {
  objects: Array<Nb_Soil_Chemistry_Standards_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Standards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chemistry_Standards_OneArgs = {
  object: Nb_Soil_Chemistry_Standards_Insert_Input;
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Standards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chemistry_Suitable_RangeArgs = {
  objects: Array<Nb_Soil_Chemistry_Suitable_Range_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chemistry_Suitable_Range_OneArgs = {
  object: Nb_Soil_Chemistry_Suitable_Range_Insert_Input;
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chemistry_ValueArgs = {
  objects: Array<Nb_Soil_Chemistry_Value_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Value_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Chemistry_Value_OneArgs = {
  object: Nb_Soil_Chemistry_Value_Insert_Input;
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Value_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_ExplanationArgs = {
  objects: Array<Nb_Soil_Explanation_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Soil_Explanation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Explanation_OneArgs = {
  object: Nb_Soil_Explanation_Insert_Input;
  on_conflict?: InputMaybe<Nb_Soil_Explanation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_PhysicsArgs = {
  objects: Array<Nb_Soil_Physics_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Soil_Physics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Soil_Physics_OneArgs = {
  object: Nb_Soil_Physics_Insert_Input;
  on_conflict?: InputMaybe<Nb_Soil_Physics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Task_TypeArgs = {
  objects: Array<Nb_Task_Type_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Task_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Task_Type_OneArgs = {
  object: Nb_Task_Type_Insert_Input;
  on_conflict?: InputMaybe<Nb_Task_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Tasktype_Company_LinkArgs = {
  objects: Array<Nb_Tasktype_Company_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Tasktype_Company_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Tasktype_Company_Link_OneArgs = {
  object: Nb_Tasktype_Company_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Tasktype_Company_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufattr_LinkArgs = {
  objects: Array<Nb_Ufattr_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Ufattr_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufattr_Link_OneArgs = {
  object: Nb_Ufattr_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Ufattr_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufg_LinkArgs = {
  objects: Array<Nb_Ufg_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Ufg_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufg_Link_OneArgs = {
  object: Nb_Ufg_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Ufg_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufg_Member_LinkArgs = {
  objects: Array<Nb_Ufg_Member_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Ufg_Member_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufg_Member_Link_OneArgs = {
  object: Nb_Ufg_Member_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Ufg_Member_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_UfieldArgs = {
  objects: Array<Nb_Ufield_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Ufield_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufield_AttributeArgs = {
  objects: Array<Nb_Ufield_Attribute_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Ufield_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufield_Attribute_OneArgs = {
  object: Nb_Ufield_Attribute_Insert_Input;
  on_conflict?: InputMaybe<Nb_Ufield_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufield_Company_LinkArgs = {
  objects: Array<Nb_Ufield_Company_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Ufield_Company_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufield_Company_Link_OneArgs = {
  object: Nb_Ufield_Company_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Ufield_Company_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufield_GroupArgs = {
  objects: Array<Nb_Ufield_Group_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Ufield_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufield_Group_OneArgs = {
  object: Nb_Ufield_Group_Insert_Input;
  on_conflict?: InputMaybe<Nb_Ufield_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufield_HistoryArgs = {
  objects: Array<Nb_Ufield_History_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Ufield_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufield_History_OneArgs = {
  object: Nb_Ufield_History_Insert_Input;
  on_conflict?: InputMaybe<Nb_Ufield_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Ufield_OneArgs = {
  object: Nb_Ufield_Insert_Input;
  on_conflict?: InputMaybe<Nb_Ufield_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Vector_Image_SensingArgs = {
  objects: Array<Nb_Vector_Image_Sensing_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Vector_Image_Sensing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Vector_Image_Sensing_OneArgs = {
  object: Nb_Vector_Image_Sensing_Insert_Input;
  on_conflict?: InputMaybe<Nb_Vector_Image_Sensing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Vector_Ims_SizeArgs = {
  objects: Array<Nb_Vector_Ims_Size_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Vector_Ims_Size_OneArgs = {
  object: Nb_Vector_Ims_Size_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Water_Gate_Status_Cplan_LinkArgs = {
  objects: Array<Nb_Water_Gate_Status_Cplan_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Water_Gate_Status_Cplan_Link_OneArgs = {
  object: Nb_Water_Gate_Status_Cplan_Link_Insert_Input;
  on_conflict?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Weather_AttrArgs = {
  objects: Array<Nb_Weather_Attr_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Weather_Attr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Weather_Attr_OneArgs = {
  object: Nb_Weather_Attr_Insert_Input;
  on_conflict?: InputMaybe<Nb_Weather_Attr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Weather_DataArgs = {
  objects: Array<Nb_Weather_Data_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Weather_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Weather_Data_OneArgs = {
  object: Nb_Weather_Data_Insert_Input;
  on_conflict?: InputMaybe<Nb_Weather_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Wide_OrthoimgArgs = {
  objects: Array<Nb_Wide_Orthoimg_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Wide_Orthoimg_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Wide_Orthoimg_OneArgs = {
  object: Nb_Wide_Orthoimg_Insert_Input;
  on_conflict?: InputMaybe<Nb_Wide_Orthoimg_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Wide_Orthoimg_SizeArgs = {
  objects: Array<Nb_Wide_Orthoimg_Size_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Wide_Orthoimg_Size_OneArgs = {
  object: Nb_Wide_Orthoimg_Size_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Working_MachineArgs = {
  objects: Array<Nb_Working_Machine_Insert_Input>;
  on_conflict?: InputMaybe<Nb_Working_Machine_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nb_Working_Machine_OneArgs = {
  object: Nb_Working_Machine_Insert_Input;
  on_conflict?: InputMaybe<Nb_Working_Machine_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbb_Picture_Analysis_StatusArgs = {
  objects: Array<Nbb_Picture_Analysis_Status_Insert_Input>;
  on_conflict?: InputMaybe<Nbb_Picture_Analysis_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbb_Picture_Analysis_Status_OneArgs = {
  object: Nbb_Picture_Analysis_Status_Insert_Input;
  on_conflict?: InputMaybe<Nbb_Picture_Analysis_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbbe_MicroclimateArgs = {
  objects: Array<Nbbe_Microclimate_Insert_Input>;
  on_conflict?: InputMaybe<Nbbe_Microclimate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbbe_Microclimate_OneArgs = {
  object: Nbbe_Microclimate_Insert_Input;
  on_conflict?: InputMaybe<Nbbe_Microclimate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbbe_Orthoimg_TypeArgs = {
  objects: Array<Nbbe_Orthoimg_Type_Insert_Input>;
  on_conflict?: InputMaybe<Nbbe_Orthoimg_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbbe_Orthoimg_Type_OneArgs = {
  object: Nbbe_Orthoimg_Type_Insert_Input;
  on_conflict?: InputMaybe<Nbbe_Orthoimg_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbbe_Weather_TypeArgs = {
  objects: Array<Nbbe_Weather_Type_Insert_Input>;
  on_conflict?: InputMaybe<Nbbe_Weather_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbbe_Weather_Type_OneArgs = {
  object: Nbbe_Weather_Type_Insert_Input;
  on_conflict?: InputMaybe<Nbbe_Weather_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_AuthorizationArgs = {
  objects: Array<Nbpm_Authorization_Insert_Input>;
  on_conflict?: InputMaybe<Nbpm_Authorization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Authorization_OneArgs = {
  object: Nbpm_Authorization_Insert_Input;
  on_conflict?: InputMaybe<Nbpm_Authorization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Contract_PlanArgs = {
  objects: Array<Nbpm_Contract_Plan_Insert_Input>;
  on_conflict?: InputMaybe<Nbpm_Contract_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Contract_Plan_LinkArgs = {
  objects: Array<Nbpm_Contract_Plan_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nbpm_Contract_Plan_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Contract_Plan_Link_OneArgs = {
  object: Nbpm_Contract_Plan_Link_Insert_Input;
  on_conflict?: InputMaybe<Nbpm_Contract_Plan_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Contract_Plan_OneArgs = {
  object: Nbpm_Contract_Plan_Insert_Input;
  on_conflict?: InputMaybe<Nbpm_Contract_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Imsattr_Authorization_LinkArgs = {
  objects: Array<Nbpm_Imsattr_Authorization_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nbpm_Imsattr_Authorization_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Imsattr_Authorization_Link_OneArgs = {
  object: Nbpm_Imsattr_Authorization_Link_Insert_Input;
  on_conflict?: InputMaybe<Nbpm_Imsattr_Authorization_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Ipengine_Authorization_LinkArgs = {
  objects: Array<Nbpm_Ipengine_Authorization_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nbpm_Ipengine_Authorization_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Ipengine_Authorization_Link_OneArgs = {
  object: Nbpm_Ipengine_Authorization_Link_Insert_Input;
  on_conflict?: InputMaybe<Nbpm_Ipengine_Authorization_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Picanatype_Authorization_LinkArgs = {
  objects: Array<Nbpm_Picanatype_Authorization_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nbpm_Picanatype_Authorization_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Picanatype_Authorization_Link_OneArgs = {
  object: Nbpm_Picanatype_Authorization_Link_Insert_Input;
  on_conflict?: InputMaybe<Nbpm_Picanatype_Authorization_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Service_PlanArgs = {
  objects: Array<Nbpm_Service_Plan_Insert_Input>;
  on_conflict?: InputMaybe<Nbpm_Service_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Service_Plan_LinkArgs = {
  objects: Array<Nbpm_Service_Plan_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nbpm_Service_Plan_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Service_Plan_Link_OneArgs = {
  object: Nbpm_Service_Plan_Link_Insert_Input;
  on_conflict?: InputMaybe<Nbpm_Service_Plan_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_Service_Plan_OneArgs = {
  object: Nbpm_Service_Plan_Insert_Input;
  on_conflict?: InputMaybe<Nbpm_Service_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_User_Attr_LinkArgs = {
  objects: Array<Nbpm_User_Attr_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nbpm_User_Attr_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_User_Attr_Link_OneArgs = {
  object: Nbpm_User_Attr_Link_Insert_Input;
  on_conflict?: InputMaybe<Nbpm_User_Attr_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_User_AttributeArgs = {
  objects: Array<Nbpm_User_Attribute_Insert_Input>;
  on_conflict?: InputMaybe<Nbpm_User_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_User_Attribute_OneArgs = {
  object: Nbpm_User_Attribute_Insert_Input;
  on_conflict?: InputMaybe<Nbpm_User_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_User_Cp_Link_LinkArgs = {
  objects: Array<Nbpm_User_Cp_Link_Link_Insert_Input>;
  on_conflict?: InputMaybe<Nbpm_User_Cp_Link_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbpm_User_Cp_Link_Link_OneArgs = {
  object: Nbpm_User_Cp_Link_Link_Insert_Input;
  on_conflict?: InputMaybe<Nbpm_User_Cp_Link_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Ipe_Run_FieldArgs = {
  objects: Array<Nbs_Ipe_Run_Field_Insert_Input>;
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Ipe_Run_Field_OneArgs = {
  object: Nbs_Ipe_Run_Field_Insert_Input;
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Ipe_Run_Link_FieldArgs = {
  objects: Array<Nbs_Ipe_Run_Link_Field_Insert_Input>;
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Link_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Ipe_Run_Link_Field_OneArgs = {
  object: Nbs_Ipe_Run_Link_Field_Insert_Input;
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Link_Field_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Ipe_Run_Link_WideArgs = {
  objects: Array<Nbs_Ipe_Run_Link_Wide_Insert_Input>;
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Link_Wide_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Ipe_Run_Link_Wide_OneArgs = {
  object: Nbs_Ipe_Run_Link_Wide_Insert_Input;
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Link_Wide_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Ipe_Run_WideArgs = {
  objects: Array<Nbs_Ipe_Run_Wide_Insert_Input>;
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Wide_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Ipe_Run_Wide_OneArgs = {
  object: Nbs_Ipe_Run_Wide_Insert_Input;
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Wide_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Multipart_UploadArgs = {
  objects: Array<Nbs_Multipart_Upload_Insert_Input>;
  on_conflict?: InputMaybe<Nbs_Multipart_Upload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Multipart_Upload_OneArgs = {
  object: Nbs_Multipart_Upload_Insert_Input;
  on_conflict?: InputMaybe<Nbs_Multipart_Upload_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Wide_Orthoimg_LoadArgs = {
  objects: Array<Nbs_Wide_Orthoimg_Load_Insert_Input>;
  on_conflict?: InputMaybe<Nbs_Wide_Orthoimg_Load_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbs_Wide_Orthoimg_Load_OneArgs = {
  object: Nbs_Wide_Orthoimg_Load_Insert_Input;
  on_conflict?: InputMaybe<Nbs_Wide_Orthoimg_Load_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbtrk_ContextArgs = {
  objects: Array<Nbtrk_Context_Insert_Input>;
  on_conflict?: InputMaybe<Nbtrk_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbtrk_Context_OneArgs = {
  object: Nbtrk_Context_Insert_Input;
  on_conflict?: InputMaybe<Nbtrk_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbtrk_Eph_LocationArgs = {
  objects: Array<Nbtrk_Eph_Location_Insert_Input>;
  on_conflict?: InputMaybe<Nbtrk_Eph_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nbtrk_Eph_Location_OneArgs = {
  object: Nbtrk_Eph_Location_Insert_Input;
  on_conflict?: InputMaybe<Nbtrk_Eph_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nipe_Engine_InformationArgs = {
  objects: Array<Nipe_Engine_Information_Insert_Input>;
  on_conflict?: InputMaybe<Nipe_Engine_Information_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nipe_Engine_Information_OneArgs = {
  object: Nipe_Engine_Information_Insert_Input;
  on_conflict?: InputMaybe<Nipe_Engine_Information_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nipe_Engine_Link_Orthoimg_TypeArgs = {
  objects: Array<Nipe_Engine_Link_Orthoimg_Type_Insert_Input>;
  on_conflict?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nipe_Engine_Link_Orthoimg_Type_OneArgs = {
  object: Nipe_Engine_Link_Orthoimg_Type_Insert_Input;
  on_conflict?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nipe_Engine_Run_StatusArgs = {
  objects: Array<Nipe_Engine_Run_Status_Insert_Input>;
  on_conflict?: InputMaybe<Nipe_Engine_Run_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nipe_Engine_Run_Status_OneArgs = {
  object: Nipe_Engine_Run_Status_Insert_Input;
  on_conflict?: InputMaybe<Nipe_Engine_Run_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nipe_Run_CommandArgs = {
  objects: Array<Nipe_Run_Command_Insert_Input>;
  on_conflict?: InputMaybe<Nipe_Run_Command_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nipe_Run_Command_OneArgs = {
  object: Nipe_Run_Command_Insert_Input;
  on_conflict?: InputMaybe<Nipe_Run_Command_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_App_UserArgs = {
  _set?: InputMaybe<App_User_Set_Input>;
  where: App_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_User_ActiveArgs = {
  _set?: InputMaybe<App_User_Active_Set_Input>;
  where: App_User_Active_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_User_Active_By_PkArgs = {
  _set?: InputMaybe<App_User_Active_Set_Input>;
  pk_columns: App_User_Active_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_User_Active_ManyArgs = {
  updates: Array<App_User_Active_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_App_User_By_PkArgs = {
  _set?: InputMaybe<App_User_Set_Input>;
  pk_columns: App_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_User_ManyArgs = {
  updates: Array<App_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_App_User_Property_PrivateArgs = {
  _set?: InputMaybe<App_User_Property_Private_Set_Input>;
  where: App_User_Property_Private_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_User_Property_Private_By_PkArgs = {
  _set?: InputMaybe<App_User_Property_Private_Set_Input>;
  pk_columns: App_User_Property_Private_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_User_Property_Private_ManyArgs = {
  updates: Array<App_User_Property_Private_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Application_User_Invitation_To_CompanyArgs = {
  _set?: InputMaybe<Application_User_Invitation_To_Company_Set_Input>;
  where: Application_User_Invitation_To_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Application_User_Invitation_To_Company_By_PkArgs = {
  _set?: InputMaybe<Application_User_Invitation_To_Company_Set_Input>;
  pk_columns: Application_User_Invitation_To_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Application_User_Invitation_To_Company_ManyArgs = {
  updates: Array<Application_User_Invitation_To_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Area_MeshArgs = {
  _inc?: InputMaybe<Master_Nb_Area_Mesh_Inc_Input>;
  _set?: InputMaybe<Master_Nb_Area_Mesh_Set_Input>;
  where: Master_Nb_Area_Mesh_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Area_Mesh_By_PkArgs = {
  _inc?: InputMaybe<Master_Nb_Area_Mesh_Inc_Input>;
  _set?: InputMaybe<Master_Nb_Area_Mesh_Set_Input>;
  pk_columns: Master_Nb_Area_Mesh_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Area_Mesh_ManyArgs = {
  updates: Array<Master_Nb_Area_Mesh_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Integrated_FarmlandArgs = {
  _append?: InputMaybe<Master_Nb_Integrated_Farmland_Append_Input>;
  _delete_at_path?: InputMaybe<Master_Nb_Integrated_Farmland_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Master_Nb_Integrated_Farmland_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Master_Nb_Integrated_Farmland_Delete_Key_Input>;
  _inc?: InputMaybe<Master_Nb_Integrated_Farmland_Inc_Input>;
  _prepend?: InputMaybe<Master_Nb_Integrated_Farmland_Prepend_Input>;
  _set?: InputMaybe<Master_Nb_Integrated_Farmland_Set_Input>;
  where: Master_Nb_Integrated_Farmland_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Integrated_Farmland_By_PkArgs = {
  _append?: InputMaybe<Master_Nb_Integrated_Farmland_Append_Input>;
  _delete_at_path?: InputMaybe<Master_Nb_Integrated_Farmland_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Master_Nb_Integrated_Farmland_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Master_Nb_Integrated_Farmland_Delete_Key_Input>;
  _inc?: InputMaybe<Master_Nb_Integrated_Farmland_Inc_Input>;
  _prepend?: InputMaybe<Master_Nb_Integrated_Farmland_Prepend_Input>;
  _set?: InputMaybe<Master_Nb_Integrated_Farmland_Set_Input>;
  pk_columns: Master_Nb_Integrated_Farmland_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Integrated_Farmland_ManyArgs = {
  updates: Array<Master_Nb_Integrated_Farmland_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_MfieldArgs = {
  _inc?: InputMaybe<Master_Nb_Mfield_Inc_Input>;
  _set?: InputMaybe<Master_Nb_Mfield_Set_Input>;
  where: Master_Nb_Mfield_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_By_PkArgs = {
  _inc?: InputMaybe<Master_Nb_Mfield_Inc_Input>;
  _set?: InputMaybe<Master_Nb_Mfield_Set_Input>;
  pk_columns: Master_Nb_Mfield_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_ManyArgs = {
  updates: Array<Master_Nb_Mfield_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_Polygon_2022_1Args = {
  _set?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Set_Input>;
  where: Master_Nb_Mfield_Polygon_2022_1_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_Polygon_2022_1_By_PkArgs = {
  _set?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Set_Input>;
  pk_columns: Master_Nb_Mfield_Polygon_2022_1_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_Polygon_2022_1_ManyArgs = {
  updates: Array<Master_Nb_Mfield_Polygon_2022_1_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_Polygon_2023_1Args = {
  _set?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Set_Input>;
  where: Master_Nb_Mfield_Polygon_2023_1_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_Polygon_2023_1_By_PkArgs = {
  _set?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Set_Input>;
  pk_columns: Master_Nb_Mfield_Polygon_2023_1_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_Polygon_2023_1_ManyArgs = {
  updates: Array<Master_Nb_Mfield_Polygon_2023_1_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_Polygon_2024_1Args = {
  _set?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Set_Input>;
  where: Master_Nb_Mfield_Polygon_2024_1_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_Polygon_2024_1_By_PkArgs = {
  _set?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Set_Input>;
  pk_columns: Master_Nb_Mfield_Polygon_2024_1_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_Polygon_2024_1_ManyArgs = {
  updates: Array<Master_Nb_Mfield_Polygon_2024_1_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_VersionArgs = {
  _inc?: InputMaybe<Master_Nb_Mfield_Version_Inc_Input>;
  _set?: InputMaybe<Master_Nb_Mfield_Version_Set_Input>;
  where: Master_Nb_Mfield_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_Version_By_PkArgs = {
  _inc?: InputMaybe<Master_Nb_Mfield_Version_Inc_Input>;
  _set?: InputMaybe<Master_Nb_Mfield_Version_Set_Input>;
  pk_columns: Master_Nb_Mfield_Version_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Mfield_Version_ManyArgs = {
  updates: Array<Master_Nb_Mfield_Version_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Postgis_VersionArgs = {
  _set?: InputMaybe<Master_Nb_Postgis_Version_Set_Input>;
  where: Master_Nb_Postgis_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Postgis_Version_By_PkArgs = {
  _set?: InputMaybe<Master_Nb_Postgis_Version_Set_Input>;
  pk_columns: Master_Nb_Postgis_Version_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Postgis_Version_ManyArgs = {
  updates: Array<Master_Nb_Postgis_Version_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Weather_AttrArgs = {
  _set?: InputMaybe<Master_Nb_Weather_Attr_Set_Input>;
  where: Master_Nb_Weather_Attr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Weather_Attr_By_PkArgs = {
  _set?: InputMaybe<Master_Nb_Weather_Attr_Set_Input>;
  pk_columns: Master_Nb_Weather_Attr_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Weather_Attr_ManyArgs = {
  updates: Array<Master_Nb_Weather_Attr_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Weather_DataArgs = {
  _inc?: InputMaybe<Master_Nb_Weather_Data_Inc_Input>;
  _set?: InputMaybe<Master_Nb_Weather_Data_Set_Input>;
  where: Master_Nb_Weather_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Weather_Data_By_PkArgs = {
  _inc?: InputMaybe<Master_Nb_Weather_Data_Inc_Input>;
  _set?: InputMaybe<Master_Nb_Weather_Data_Set_Input>;
  pk_columns: Master_Nb_Weather_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Nb_Weather_Data_ManyArgs = {
  updates: Array<Master_Nb_Weather_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Actual_Crop_YeildArgs = {
  _append?: InputMaybe<Nb_Actual_Crop_Yeild_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Actual_Crop_Yeild_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Actual_Crop_Yeild_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Actual_Crop_Yeild_Delete_Key_Input>;
  _inc?: InputMaybe<Nb_Actual_Crop_Yeild_Inc_Input>;
  _prepend?: InputMaybe<Nb_Actual_Crop_Yeild_Prepend_Input>;
  _set?: InputMaybe<Nb_Actual_Crop_Yeild_Set_Input>;
  where: Nb_Actual_Crop_Yeild_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Actual_Crop_Yeild_By_PkArgs = {
  _append?: InputMaybe<Nb_Actual_Crop_Yeild_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Actual_Crop_Yeild_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Actual_Crop_Yeild_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Actual_Crop_Yeild_Delete_Key_Input>;
  _inc?: InputMaybe<Nb_Actual_Crop_Yeild_Inc_Input>;
  _prepend?: InputMaybe<Nb_Actual_Crop_Yeild_Prepend_Input>;
  _set?: InputMaybe<Nb_Actual_Crop_Yeild_Set_Input>;
  pk_columns: Nb_Actual_Crop_Yeild_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Actual_Crop_Yeild_ManyArgs = {
  updates: Array<Nb_Actual_Crop_Yeild_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Area_MeshArgs = {
  _inc?: InputMaybe<Nb_Area_Mesh_Inc_Input>;
  _set?: InputMaybe<Nb_Area_Mesh_Set_Input>;
  where: Nb_Area_Mesh_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Area_Mesh_By_PkArgs = {
  _inc?: InputMaybe<Nb_Area_Mesh_Inc_Input>;
  _set?: InputMaybe<Nb_Area_Mesh_Set_Input>;
  pk_columns: Nb_Area_Mesh_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Area_Mesh_ManyArgs = {
  updates: Array<Nb_Area_Mesh_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_CompanyArgs = {
  _set?: InputMaybe<Nb_Company_Set_Input>;
  where: Nb_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Company_By_PkArgs = {
  _set?: InputMaybe<Nb_Company_Set_Input>;
  pk_columns: Nb_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Company_InfoArgs = {
  _set?: InputMaybe<Nb_Company_Info_Set_Input>;
  where: Nb_Company_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Company_Info_By_PkArgs = {
  _set?: InputMaybe<Nb_Company_Info_Set_Input>;
  pk_columns: Nb_Company_Info_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Company_Info_ManyArgs = {
  updates: Array<Nb_Company_Info_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Company_ManyArgs = {
  updates: Array<Nb_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Conditioner_WorkArgs = {
  _inc?: InputMaybe<Nb_Conditioner_Work_Inc_Input>;
  _set?: InputMaybe<Nb_Conditioner_Work_Set_Input>;
  where: Nb_Conditioner_Work_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Conditioner_Work_By_PkArgs = {
  _inc?: InputMaybe<Nb_Conditioner_Work_Inc_Input>;
  _set?: InputMaybe<Nb_Conditioner_Work_Set_Input>;
  pk_columns: Nb_Conditioner_Work_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Conditioner_Work_ManyArgs = {
  updates: Array<Nb_Conditioner_Work_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Cplan_Imsensing_LinkArgs = {
  _set?: InputMaybe<Nb_Cplan_Imsensing_Link_Set_Input>;
  where: Nb_Cplan_Imsensing_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Cplan_Imsensing_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Cplan_Imsensing_Link_Set_Input>;
  pk_columns: Nb_Cplan_Imsensing_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Cplan_Imsensing_Link_ManyArgs = {
  updates: Array<Nb_Cplan_Imsensing_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_CropArgs = {
  _set?: InputMaybe<Nb_Crop_Set_Input>;
  where: Nb_Crop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Crop_By_PkArgs = {
  _set?: InputMaybe<Nb_Crop_Set_Input>;
  pk_columns: Nb_Crop_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Crop_Company_LinkArgs = {
  _set?: InputMaybe<Nb_Crop_Company_Link_Set_Input>;
  where: Nb_Crop_Company_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Crop_Company_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Crop_Company_Link_Set_Input>;
  pk_columns: Nb_Crop_Company_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Crop_Company_Link_ManyArgs = {
  updates: Array<Nb_Crop_Company_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Crop_ManyArgs = {
  updates: Array<Nb_Crop_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Crop_Method_LinkArgs = {
  _set?: InputMaybe<Nb_Crop_Method_Link_Set_Input>;
  where: Nb_Crop_Method_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Crop_Method_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Crop_Method_Link_Set_Input>;
  pk_columns: Nb_Crop_Method_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Crop_Method_Link_ManyArgs = {
  updates: Array<Nb_Crop_Method_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_CultivarArgs = {
  _set?: InputMaybe<Nb_Cultivar_Set_Input>;
  where: Nb_Cultivar_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Cultivar_By_PkArgs = {
  _set?: InputMaybe<Nb_Cultivar_Set_Input>;
  pk_columns: Nb_Cultivar_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Cultivar_ManyArgs = {
  updates: Array<Nb_Cultivar_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Cultivation_MethodArgs = {
  _set?: InputMaybe<Nb_Cultivation_Method_Set_Input>;
  where: Nb_Cultivation_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Cultivation_Method_By_PkArgs = {
  _set?: InputMaybe<Nb_Cultivation_Method_Set_Input>;
  pk_columns: Nb_Cultivation_Method_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Cultivation_Method_ManyArgs = {
  updates: Array<Nb_Cultivation_Method_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Cultivation_PlanArgs = {
  _inc?: InputMaybe<Nb_Cultivation_Plan_Inc_Input>;
  _set?: InputMaybe<Nb_Cultivation_Plan_Set_Input>;
  where: Nb_Cultivation_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Cultivation_Plan_By_PkArgs = {
  _inc?: InputMaybe<Nb_Cultivation_Plan_Inc_Input>;
  _set?: InputMaybe<Nb_Cultivation_Plan_Set_Input>;
  pk_columns: Nb_Cultivation_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Cultivation_Plan_ManyArgs = {
  updates: Array<Nb_Cultivation_Plan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Demo_Task_TypeArgs = {
  _set?: InputMaybe<Nb_Demo_Task_Type_Set_Input>;
  where: Nb_Demo_Task_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Demo_Task_Type_By_PkArgs = {
  _set?: InputMaybe<Nb_Demo_Task_Type_Set_Input>;
  pk_columns: Nb_Demo_Task_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Demo_Task_Type_ManyArgs = {
  updates: Array<Nb_Demo_Task_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Demo_User_LocationArgs = {
  _inc?: InputMaybe<Nb_Demo_User_Location_Inc_Input>;
  _set?: InputMaybe<Nb_Demo_User_Location_Set_Input>;
  where: Nb_Demo_User_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Demo_User_Location_By_PkArgs = {
  _inc?: InputMaybe<Nb_Demo_User_Location_Inc_Input>;
  _set?: InputMaybe<Nb_Demo_User_Location_Set_Input>;
  pk_columns: Nb_Demo_User_Location_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Demo_User_Location_ManyArgs = {
  updates: Array<Nb_Demo_User_Location_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Demo_User_NameArgs = {
  _set?: InputMaybe<Nb_Demo_User_Name_Set_Input>;
  where: Nb_Demo_User_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Demo_User_Name_By_PkArgs = {
  _set?: InputMaybe<Nb_Demo_User_Name_Set_Input>;
  pk_columns: Nb_Demo_User_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Demo_User_Name_ManyArgs = {
  updates: Array<Nb_Demo_User_Name_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Est_Working_LoadArgs = {
  _inc?: InputMaybe<Nb_Est_Working_Load_Inc_Input>;
  _set?: InputMaybe<Nb_Est_Working_Load_Set_Input>;
  where: Nb_Est_Working_Load_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Est_Working_Load_By_PkArgs = {
  _inc?: InputMaybe<Nb_Est_Working_Load_Inc_Input>;
  _set?: InputMaybe<Nb_Est_Working_Load_Set_Input>;
  pk_columns: Nb_Est_Working_Load_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Est_Working_Load_ManyArgs = {
  updates: Array<Nb_Est_Working_Load_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilization_MethodArgs = {
  _set?: InputMaybe<Nb_Fertilization_Method_Set_Input>;
  where: Nb_Fertilization_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilization_Method_By_PkArgs = {
  _set?: InputMaybe<Nb_Fertilization_Method_Set_Input>;
  pk_columns: Nb_Fertilization_Method_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilization_Method_ManyArgs = {
  updates: Array<Nb_Fertilization_Method_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilization_WorkArgs = {
  _inc?: InputMaybe<Nb_Fertilization_Work_Inc_Input>;
  _set?: InputMaybe<Nb_Fertilization_Work_Set_Input>;
  where: Nb_Fertilization_Work_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilization_Work_By_PkArgs = {
  _inc?: InputMaybe<Nb_Fertilization_Work_Inc_Input>;
  _set?: InputMaybe<Nb_Fertilization_Work_Set_Input>;
  pk_columns: Nb_Fertilization_Work_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilization_Work_ManyArgs = {
  updates: Array<Nb_Fertilization_Work_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_FertilizerArgs = {
  _set?: InputMaybe<Nb_Fertilizer_Set_Input>;
  where: Nb_Fertilizer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilizer_By_PkArgs = {
  _set?: InputMaybe<Nb_Fertilizer_Set_Input>;
  pk_columns: Nb_Fertilizer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilizer_Company_LinkArgs = {
  _set?: InputMaybe<Nb_Fertilizer_Company_Link_Set_Input>;
  where: Nb_Fertilizer_Company_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilizer_Company_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Fertilizer_Company_Link_Set_Input>;
  pk_columns: Nb_Fertilizer_Company_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilizer_Company_Link_ManyArgs = {
  updates: Array<Nb_Fertilizer_Company_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilizer_ManyArgs = {
  updates: Array<Nb_Fertilizer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilizer_PictureArgs = {
  _inc?: InputMaybe<Nb_Fertilizer_Picture_Inc_Input>;
  _set?: InputMaybe<Nb_Fertilizer_Picture_Set_Input>;
  where: Nb_Fertilizer_Picture_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilizer_Picture_By_PkArgs = {
  _inc?: InputMaybe<Nb_Fertilizer_Picture_Inc_Input>;
  _set?: InputMaybe<Nb_Fertilizer_Picture_Set_Input>;
  pk_columns: Nb_Fertilizer_Picture_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Fertilizer_Picture_ManyArgs = {
  updates: Array<Nb_Fertilizer_Picture_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_FileArgs = {
  _set?: InputMaybe<Nb_Field_File_Set_Input>;
  where: Nb_Field_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_File_By_PkArgs = {
  _set?: InputMaybe<Nb_Field_File_Set_Input>;
  pk_columns: Nb_Field_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_File_LoadArgs = {
  _set?: InputMaybe<Nb_Field_File_Load_Set_Input>;
  where: Nb_Field_File_Load_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_File_Load_By_PkArgs = {
  _set?: InputMaybe<Nb_Field_File_Load_Set_Input>;
  pk_columns: Nb_Field_File_Load_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_File_Load_ManyArgs = {
  updates: Array<Nb_Field_File_Load_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_File_ManyArgs = {
  updates: Array<Nb_Field_File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_File_SizeArgs = {
  _inc?: InputMaybe<Nb_Field_File_Size_Inc_Input>;
  _set?: InputMaybe<Nb_Field_File_Size_Set_Input>;
  where: Nb_Field_File_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_File_Size_ManyArgs = {
  updates: Array<Nb_Field_File_Size_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_MemoArgs = {
  _set?: InputMaybe<Nb_Field_Memo_Set_Input>;
  where: Nb_Field_Memo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Memo_By_PkArgs = {
  _set?: InputMaybe<Nb_Field_Memo_Set_Input>;
  pk_columns: Nb_Field_Memo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Memo_ManyArgs = {
  updates: Array<Nb_Field_Memo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_OrthoimgArgs = {
  _set?: InputMaybe<Nb_Field_Orthoimg_Set_Input>;
  where: Nb_Field_Orthoimg_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Orthoimg_By_PkArgs = {
  _set?: InputMaybe<Nb_Field_Orthoimg_Set_Input>;
  pk_columns: Nb_Field_Orthoimg_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Orthoimg_ManyArgs = {
  updates: Array<Nb_Field_Orthoimg_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Orthoimg_SizeArgs = {
  _inc?: InputMaybe<Nb_Field_Orthoimg_Size_Inc_Input>;
  _set?: InputMaybe<Nb_Field_Orthoimg_Size_Set_Input>;
  where: Nb_Field_Orthoimg_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Orthoimg_Size_ManyArgs = {
  updates: Array<Nb_Field_Orthoimg_Size_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_PhotographerArgs = {
  _set?: InputMaybe<Nb_Field_Photographer_Set_Input>;
  where: Nb_Field_Photographer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Photographer_By_PkArgs = {
  _set?: InputMaybe<Nb_Field_Photographer_Set_Input>;
  pk_columns: Nb_Field_Photographer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Photographer_ManyArgs = {
  updates: Array<Nb_Field_Photographer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_PictureArgs = {
  _append?: InputMaybe<Nb_Field_Picture_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Field_Picture_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Field_Picture_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Field_Picture_Delete_Key_Input>;
  _prepend?: InputMaybe<Nb_Field_Picture_Prepend_Input>;
  _set?: InputMaybe<Nb_Field_Picture_Set_Input>;
  where: Nb_Field_Picture_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Picture_By_PkArgs = {
  _append?: InputMaybe<Nb_Field_Picture_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Field_Picture_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Field_Picture_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Field_Picture_Delete_Key_Input>;
  _prepend?: InputMaybe<Nb_Field_Picture_Prepend_Input>;
  _set?: InputMaybe<Nb_Field_Picture_Set_Input>;
  pk_columns: Nb_Field_Picture_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Picture_Cplan_LinkArgs = {
  _set?: InputMaybe<Nb_Field_Picture_Cplan_Link_Set_Input>;
  where: Nb_Field_Picture_Cplan_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Picture_Cplan_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Field_Picture_Cplan_Link_Set_Input>;
  pk_columns: Nb_Field_Picture_Cplan_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Picture_Cplan_Link_ManyArgs = {
  updates: Array<Nb_Field_Picture_Cplan_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Picture_LoadArgs = {
  _set?: InputMaybe<Nb_Field_Picture_Load_Set_Input>;
  where: Nb_Field_Picture_Load_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Picture_Load_By_PkArgs = {
  _set?: InputMaybe<Nb_Field_Picture_Load_Set_Input>;
  pk_columns: Nb_Field_Picture_Load_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Picture_Load_ManyArgs = {
  updates: Array<Nb_Field_Picture_Load_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Picture_ManyArgs = {
  updates: Array<Nb_Field_Picture_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Picture_SizeArgs = {
  _inc?: InputMaybe<Nb_Field_Picture_Size_Inc_Input>;
  _set?: InputMaybe<Nb_Field_Picture_Size_Set_Input>;
  where: Nb_Field_Picture_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Picture_Size_ManyArgs = {
  updates: Array<Nb_Field_Picture_Size_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Polygon_AttrArgs = {
  _set?: InputMaybe<Nb_Field_Polygon_Attr_Set_Input>;
  where: Nb_Field_Polygon_Attr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Polygon_Attr_By_PkArgs = {
  _set?: InputMaybe<Nb_Field_Polygon_Attr_Set_Input>;
  pk_columns: Nb_Field_Polygon_Attr_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Polygon_Attr_ManyArgs = {
  updates: Array<Nb_Field_Polygon_Attr_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_PropertyArgs = {
  _inc?: InputMaybe<Nb_Field_Property_Inc_Input>;
  _set?: InputMaybe<Nb_Field_Property_Set_Input>;
  where: Nb_Field_Property_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Property_By_PkArgs = {
  _inc?: InputMaybe<Nb_Field_Property_Inc_Input>;
  _set?: InputMaybe<Nb_Field_Property_Set_Input>;
  pk_columns: Nb_Field_Property_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Property_ManyArgs = {
  updates: Array<Nb_Field_Property_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_SensingArgs = {
  _set?: InputMaybe<Nb_Field_Sensing_Set_Input>;
  where: Nb_Field_Sensing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Sensing_By_PkArgs = {
  _set?: InputMaybe<Nb_Field_Sensing_Set_Input>;
  pk_columns: Nb_Field_Sensing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Sensing_DataArgs = {
  _inc?: InputMaybe<Nb_Field_Sensing_Data_Inc_Input>;
  _set?: InputMaybe<Nb_Field_Sensing_Data_Set_Input>;
  where: Nb_Field_Sensing_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Sensing_Data_By_PkArgs = {
  _inc?: InputMaybe<Nb_Field_Sensing_Data_Inc_Input>;
  _set?: InputMaybe<Nb_Field_Sensing_Data_Set_Input>;
  pk_columns: Nb_Field_Sensing_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Sensing_Data_ManyArgs = {
  updates: Array<Nb_Field_Sensing_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Sensing_ManyArgs = {
  updates: Array<Nb_Field_Sensing_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Water_GateArgs = {
  _set?: InputMaybe<Nb_Field_Water_Gate_Set_Input>;
  where: Nb_Field_Water_Gate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Water_Gate_By_PkArgs = {
  _set?: InputMaybe<Nb_Field_Water_Gate_Set_Input>;
  pk_columns: Nb_Field_Water_Gate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Water_Gate_ManyArgs = {
  updates: Array<Nb_Field_Water_Gate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Water_Gate_StatusArgs = {
  _set?: InputMaybe<Nb_Field_Water_Gate_Status_Set_Input>;
  where: Nb_Field_Water_Gate_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Water_Gate_Status_By_PkArgs = {
  _set?: InputMaybe<Nb_Field_Water_Gate_Status_Set_Input>;
  pk_columns: Nb_Field_Water_Gate_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Field_Water_Gate_Status_ManyArgs = {
  updates: Array<Nb_Field_Water_Gate_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_File_SizeArgs = {
  _inc?: InputMaybe<Nb_File_Size_Inc_Input>;
  _set?: InputMaybe<Nb_File_Size_Set_Input>;
  where: Nb_File_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_File_Size_By_PkArgs = {
  _inc?: InputMaybe<Nb_File_Size_Inc_Input>;
  _set?: InputMaybe<Nb_File_Size_Set_Input>;
  pk_columns: Nb_File_Size_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_File_Size_ManyArgs = {
  updates: Array<Nb_File_Size_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_File_TypeArgs = {
  _set?: InputMaybe<Nb_File_Type_Set_Input>;
  where: Nb_File_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_File_Type_By_PkArgs = {
  _set?: InputMaybe<Nb_File_Type_Set_Input>;
  pk_columns: Nb_File_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_File_Type_ManyArgs = {
  updates: Array<Nb_File_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Finished_Ufield_WorkArgs = {
  _inc?: InputMaybe<Nb_Finished_Ufield_Work_Inc_Input>;
  _set?: InputMaybe<Nb_Finished_Ufield_Work_Set_Input>;
  where: Nb_Finished_Ufield_Work_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Finished_Ufield_Work_By_PkArgs = {
  _inc?: InputMaybe<Nb_Finished_Ufield_Work_Inc_Input>;
  _set?: InputMaybe<Nb_Finished_Ufield_Work_Set_Input>;
  pk_columns: Nb_Finished_Ufield_Work_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Finished_Ufield_Work_ManyArgs = {
  updates: Array<Nb_Finished_Ufield_Work_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Image_Sensing_AttrArgs = {
  _inc?: InputMaybe<Nb_Image_Sensing_Attr_Inc_Input>;
  _set?: InputMaybe<Nb_Image_Sensing_Attr_Set_Input>;
  where: Nb_Image_Sensing_Attr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Image_Sensing_Attr_By_PkArgs = {
  _inc?: InputMaybe<Nb_Image_Sensing_Attr_Inc_Input>;
  _set?: InputMaybe<Nb_Image_Sensing_Attr_Set_Input>;
  pk_columns: Nb_Image_Sensing_Attr_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Image_Sensing_Attr_ManyArgs = {
  updates: Array<Nb_Image_Sensing_Attr_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Imsensing_ColormapArgs = {
  _set?: InputMaybe<Nb_Imsensing_Colormap_Set_Input>;
  where: Nb_Imsensing_Colormap_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Imsensing_Colormap_By_PkArgs = {
  _set?: InputMaybe<Nb_Imsensing_Colormap_Set_Input>;
  pk_columns: Nb_Imsensing_Colormap_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Imsensing_Colormap_ManyArgs = {
  updates: Array<Nb_Imsensing_Colormap_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Log_User_PositionArgs = {
  _set?: InputMaybe<Nb_Log_User_Position_Set_Input>;
  where: Nb_Log_User_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Log_User_Position_By_PkArgs = {
  _set?: InputMaybe<Nb_Log_User_Position_Set_Input>;
  pk_columns: Nb_Log_User_Position_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Log_User_Position_ManyArgs = {
  updates: Array<Nb_Log_User_Position_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Maff_FieldArgs = {
  _inc?: InputMaybe<Nb_Maff_Field_Inc_Input>;
  _set?: InputMaybe<Nb_Maff_Field_Set_Input>;
  where: Nb_Maff_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Maff_Field_By_PkArgs = {
  _inc?: InputMaybe<Nb_Maff_Field_Inc_Input>;
  _set?: InputMaybe<Nb_Maff_Field_Set_Input>;
  pk_columns: Nb_Maff_Field_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Maff_Field_LinkArgs = {
  _set?: InputMaybe<Nb_Maff_Field_Link_Set_Input>;
  where: Nb_Maff_Field_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Maff_Field_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Maff_Field_Link_Set_Input>;
  pk_columns: Nb_Maff_Field_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Maff_Field_Link_ManyArgs = {
  updates: Array<Nb_Maff_Field_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Maff_Field_ManyArgs = {
  updates: Array<Nb_Maff_Field_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_File_LinkArgs = {
  _set?: InputMaybe<Nb_Memo_File_Link_Set_Input>;
  where: Nb_Memo_File_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_File_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Memo_File_Link_Set_Input>;
  pk_columns: Nb_Memo_File_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_File_Link_ManyArgs = {
  updates: Array<Nb_Memo_File_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_Picture_LinkArgs = {
  _set?: InputMaybe<Nb_Memo_Picture_Link_Set_Input>;
  where: Nb_Memo_Picture_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_Picture_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Memo_Picture_Link_Set_Input>;
  pk_columns: Nb_Memo_Picture_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_Picture_Link_ManyArgs = {
  updates: Array<Nb_Memo_Picture_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_Ufg_LinkArgs = {
  _set?: InputMaybe<Nb_Memo_Ufg_Link_Set_Input>;
  where: Nb_Memo_Ufg_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_Ufg_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Memo_Ufg_Link_Set_Input>;
  pk_columns: Nb_Memo_Ufg_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_Ufg_Link_ManyArgs = {
  updates: Array<Nb_Memo_Ufg_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_Ufield_LinkArgs = {
  _set?: InputMaybe<Nb_Memo_Ufield_Link_Set_Input>;
  where: Nb_Memo_Ufield_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_Ufield_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Memo_Ufield_Link_Set_Input>;
  pk_columns: Nb_Memo_Ufield_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Memo_Ufield_Link_ManyArgs = {
  updates: Array<Nb_Memo_Ufield_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_MfieldArgs = {
  _append?: InputMaybe<Nb_Mfield_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Mfield_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Mfield_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Mfield_Delete_Key_Input>;
  _inc?: InputMaybe<Nb_Mfield_Inc_Input>;
  _prepend?: InputMaybe<Nb_Mfield_Prepend_Input>;
  _set?: InputMaybe<Nb_Mfield_Set_Input>;
  where: Nb_Mfield_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Mfield_By_PkArgs = {
  _append?: InputMaybe<Nb_Mfield_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Mfield_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Mfield_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Mfield_Delete_Key_Input>;
  _inc?: InputMaybe<Nb_Mfield_Inc_Input>;
  _prepend?: InputMaybe<Nb_Mfield_Prepend_Input>;
  _set?: InputMaybe<Nb_Mfield_Set_Input>;
  pk_columns: Nb_Mfield_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Mfield_LinkArgs = {
  _set?: InputMaybe<Nb_Mfield_Link_Set_Input>;
  where: Nb_Mfield_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Mfield_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Mfield_Link_Set_Input>;
  pk_columns: Nb_Mfield_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Mfield_Link_ManyArgs = {
  updates: Array<Nb_Mfield_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Mfield_ManyArgs = {
  updates: Array<Nb_Mfield_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Performed_WorkArgs = {
  _inc?: InputMaybe<Nb_Performed_Work_Inc_Input>;
  _set?: InputMaybe<Nb_Performed_Work_Set_Input>;
  where: Nb_Performed_Work_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Performed_Work_By_PkArgs = {
  _inc?: InputMaybe<Nb_Performed_Work_Inc_Input>;
  _set?: InputMaybe<Nb_Performed_Work_Set_Input>;
  pk_columns: Nb_Performed_Work_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Performed_Work_ManyArgs = {
  updates: Array<Nb_Performed_Work_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_PesticideArgs = {
  _inc?: InputMaybe<Nb_Pesticide_Inc_Input>;
  _set?: InputMaybe<Nb_Pesticide_Set_Input>;
  where: Nb_Pesticide_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Pesticide_By_PkArgs = {
  _inc?: InputMaybe<Nb_Pesticide_Inc_Input>;
  _set?: InputMaybe<Nb_Pesticide_Set_Input>;
  pk_columns: Nb_Pesticide_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Pesticide_Company_LinkArgs = {
  _set?: InputMaybe<Nb_Pesticide_Company_Link_Set_Input>;
  where: Nb_Pesticide_Company_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Pesticide_Company_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Pesticide_Company_Link_Set_Input>;
  pk_columns: Nb_Pesticide_Company_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Pesticide_Company_Link_ManyArgs = {
  updates: Array<Nb_Pesticide_Company_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Pesticide_IngredientArgs = {
  _inc?: InputMaybe<Nb_Pesticide_Ingredient_Inc_Input>;
  _set?: InputMaybe<Nb_Pesticide_Ingredient_Set_Input>;
  where: Nb_Pesticide_Ingredient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Pesticide_Ingredient_By_PkArgs = {
  _inc?: InputMaybe<Nb_Pesticide_Ingredient_Inc_Input>;
  _set?: InputMaybe<Nb_Pesticide_Ingredient_Set_Input>;
  pk_columns: Nb_Pesticide_Ingredient_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Pesticide_Ingredient_ManyArgs = {
  updates: Array<Nb_Pesticide_Ingredient_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Pesticide_ManyArgs = {
  updates: Array<Nb_Pesticide_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Pesticide_Spray_WorkArgs = {
  _set?: InputMaybe<Nb_Pesticide_Spray_Work_Set_Input>;
  where: Nb_Pesticide_Spray_Work_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Pesticide_Spray_Work_By_PkArgs = {
  _set?: InputMaybe<Nb_Pesticide_Spray_Work_Set_Input>;
  pk_columns: Nb_Pesticide_Spray_Work_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Pesticide_Spray_Work_ManyArgs = {
  updates: Array<Nb_Pesticide_Spray_Work_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Picture_AnalysisArgs = {
  _set?: InputMaybe<Nb_Picture_Analysis_Set_Input>;
  where: Nb_Picture_Analysis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Picture_Analysis_By_PkArgs = {
  _set?: InputMaybe<Nb_Picture_Analysis_Set_Input>;
  pk_columns: Nb_Picture_Analysis_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Picture_Analysis_ManyArgs = {
  updates: Array<Nb_Picture_Analysis_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Picture_Analysis_TypeArgs = {
  _set?: InputMaybe<Nb_Picture_Analysis_Type_Set_Input>;
  where: Nb_Picture_Analysis_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Picture_Analysis_Type_By_PkArgs = {
  _set?: InputMaybe<Nb_Picture_Analysis_Type_Set_Input>;
  pk_columns: Nb_Picture_Analysis_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Picture_Analysis_Type_ManyArgs = {
  updates: Array<Nb_Picture_Analysis_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Raster_Image_SensingArgs = {
  _inc?: InputMaybe<Nb_Raster_Image_Sensing_Inc_Input>;
  _set?: InputMaybe<Nb_Raster_Image_Sensing_Set_Input>;
  where: Nb_Raster_Image_Sensing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Raster_Image_Sensing_By_PkArgs = {
  _inc?: InputMaybe<Nb_Raster_Image_Sensing_Inc_Input>;
  _set?: InputMaybe<Nb_Raster_Image_Sensing_Set_Input>;
  pk_columns: Nb_Raster_Image_Sensing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Raster_Image_Sensing_ManyArgs = {
  updates: Array<Nb_Raster_Image_Sensing_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Raster_Ims_SizeArgs = {
  _inc?: InputMaybe<Nb_Raster_Ims_Size_Inc_Input>;
  _set?: InputMaybe<Nb_Raster_Ims_Size_Set_Input>;
  where: Nb_Raster_Ims_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Raster_Ims_Size_ManyArgs = {
  updates: Array<Nb_Raster_Ims_Size_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chara_MapArgs = {
  _inc?: InputMaybe<Nb_Soil_Chara_Map_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chara_Map_Set_Input>;
  where: Nb_Soil_Chara_Map_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chara_Map_By_PkArgs = {
  _inc?: InputMaybe<Nb_Soil_Chara_Map_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chara_Map_Set_Input>;
  pk_columns: Nb_Soil_Chara_Map_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chara_Map_ManyArgs = {
  updates: Array<Nb_Soil_Chara_Map_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chara_Map_SellistArgs = {
  _inc?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Set_Input>;
  where: Nb_Soil_Chara_Map_Sellist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chara_Map_Sellist_By_PkArgs = {
  _inc?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Set_Input>;
  pk_columns: Nb_Soil_Chara_Map_Sellist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chara_Map_Sellist_ManyArgs = {
  updates: Array<Nb_Soil_Chara_Map_Sellist_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_CharacteristicArgs = {
  _set?: InputMaybe<Nb_Soil_Characteristic_Set_Input>;
  where: Nb_Soil_Characteristic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Characteristic_By_PkArgs = {
  _set?: InputMaybe<Nb_Soil_Characteristic_Set_Input>;
  pk_columns: Nb_Soil_Characteristic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Characteristic_ManyArgs = {
  updates: Array<Nb_Soil_Characteristic_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_ChemistryArgs = {
  _inc?: InputMaybe<Nb_Soil_Chemistry_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chemistry_Set_Input>;
  where: Nb_Soil_Chemistry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_By_PkArgs = {
  _inc?: InputMaybe<Nb_Soil_Chemistry_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chemistry_Set_Input>;
  pk_columns: Nb_Soil_Chemistry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_ManyArgs = {
  updates: Array<Nb_Soil_Chemistry_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_Proper_RangeArgs = {
  _inc?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Set_Input>;
  where: Nb_Soil_Chemistry_Proper_Range_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_Proper_Range_By_PkArgs = {
  _inc?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Set_Input>;
  pk_columns: Nb_Soil_Chemistry_Proper_Range_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_Proper_Range_ManyArgs = {
  updates: Array<Nb_Soil_Chemistry_Proper_Range_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_StandardsArgs = {
  _set?: InputMaybe<Nb_Soil_Chemistry_Standards_Set_Input>;
  where: Nb_Soil_Chemistry_Standards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_Standards_By_PkArgs = {
  _set?: InputMaybe<Nb_Soil_Chemistry_Standards_Set_Input>;
  pk_columns: Nb_Soil_Chemistry_Standards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_Standards_ManyArgs = {
  updates: Array<Nb_Soil_Chemistry_Standards_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_Suitable_RangeArgs = {
  _inc?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Set_Input>;
  where: Nb_Soil_Chemistry_Suitable_Range_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_Suitable_Range_By_PkArgs = {
  _inc?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Set_Input>;
  pk_columns: Nb_Soil_Chemistry_Suitable_Range_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_Suitable_Range_ManyArgs = {
  updates: Array<Nb_Soil_Chemistry_Suitable_Range_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_ValueArgs = {
  _inc?: InputMaybe<Nb_Soil_Chemistry_Value_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chemistry_Value_Set_Input>;
  where: Nb_Soil_Chemistry_Value_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_Value_By_PkArgs = {
  _inc?: InputMaybe<Nb_Soil_Chemistry_Value_Inc_Input>;
  _set?: InputMaybe<Nb_Soil_Chemistry_Value_Set_Input>;
  pk_columns: Nb_Soil_Chemistry_Value_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Chemistry_Value_ManyArgs = {
  updates: Array<Nb_Soil_Chemistry_Value_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_ExplanationArgs = {
  _set?: InputMaybe<Nb_Soil_Explanation_Set_Input>;
  where: Nb_Soil_Explanation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Explanation_By_PkArgs = {
  _set?: InputMaybe<Nb_Soil_Explanation_Set_Input>;
  pk_columns: Nb_Soil_Explanation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Explanation_ManyArgs = {
  updates: Array<Nb_Soil_Explanation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_PhysicsArgs = {
  _append?: InputMaybe<Nb_Soil_Physics_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Soil_Physics_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Soil_Physics_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Soil_Physics_Delete_Key_Input>;
  _inc?: InputMaybe<Nb_Soil_Physics_Inc_Input>;
  _prepend?: InputMaybe<Nb_Soil_Physics_Prepend_Input>;
  _set?: InputMaybe<Nb_Soil_Physics_Set_Input>;
  where: Nb_Soil_Physics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Physics_By_PkArgs = {
  _append?: InputMaybe<Nb_Soil_Physics_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Soil_Physics_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Soil_Physics_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Soil_Physics_Delete_Key_Input>;
  _inc?: InputMaybe<Nb_Soil_Physics_Inc_Input>;
  _prepend?: InputMaybe<Nb_Soil_Physics_Prepend_Input>;
  _set?: InputMaybe<Nb_Soil_Physics_Set_Input>;
  pk_columns: Nb_Soil_Physics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Soil_Physics_ManyArgs = {
  updates: Array<Nb_Soil_Physics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Task_TypeArgs = {
  _set?: InputMaybe<Nb_Task_Type_Set_Input>;
  where: Nb_Task_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Task_Type_By_PkArgs = {
  _set?: InputMaybe<Nb_Task_Type_Set_Input>;
  pk_columns: Nb_Task_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Task_Type_ManyArgs = {
  updates: Array<Nb_Task_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Tasktype_Company_LinkArgs = {
  _set?: InputMaybe<Nb_Tasktype_Company_Link_Set_Input>;
  where: Nb_Tasktype_Company_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Tasktype_Company_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Tasktype_Company_Link_Set_Input>;
  pk_columns: Nb_Tasktype_Company_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Tasktype_Company_Link_ManyArgs = {
  updates: Array<Nb_Tasktype_Company_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufattr_LinkArgs = {
  _set?: InputMaybe<Nb_Ufattr_Link_Set_Input>;
  where: Nb_Ufattr_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufattr_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Ufattr_Link_Set_Input>;
  pk_columns: Nb_Ufattr_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufattr_Link_ManyArgs = {
  updates: Array<Nb_Ufattr_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufg_LinkArgs = {
  _set?: InputMaybe<Nb_Ufg_Link_Set_Input>;
  where: Nb_Ufg_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufg_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Ufg_Link_Set_Input>;
  pk_columns: Nb_Ufg_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufg_Link_ManyArgs = {
  updates: Array<Nb_Ufg_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufg_Member_LinkArgs = {
  _set?: InputMaybe<Nb_Ufg_Member_Link_Set_Input>;
  where: Nb_Ufg_Member_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufg_Member_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Ufg_Member_Link_Set_Input>;
  pk_columns: Nb_Ufg_Member_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufg_Member_Link_ManyArgs = {
  updates: Array<Nb_Ufg_Member_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_UfieldArgs = {
  _inc?: InputMaybe<Nb_Ufield_Inc_Input>;
  _set?: InputMaybe<Nb_Ufield_Set_Input>;
  where: Nb_Ufield_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_AttributeArgs = {
  _set?: InputMaybe<Nb_Ufield_Attribute_Set_Input>;
  where: Nb_Ufield_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_Attribute_By_PkArgs = {
  _set?: InputMaybe<Nb_Ufield_Attribute_Set_Input>;
  pk_columns: Nb_Ufield_Attribute_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_Attribute_ManyArgs = {
  updates: Array<Nb_Ufield_Attribute_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_By_PkArgs = {
  _inc?: InputMaybe<Nb_Ufield_Inc_Input>;
  _set?: InputMaybe<Nb_Ufield_Set_Input>;
  pk_columns: Nb_Ufield_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_Company_LinkArgs = {
  _set?: InputMaybe<Nb_Ufield_Company_Link_Set_Input>;
  where: Nb_Ufield_Company_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_Company_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Ufield_Company_Link_Set_Input>;
  pk_columns: Nb_Ufield_Company_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_Company_Link_ManyArgs = {
  updates: Array<Nb_Ufield_Company_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_GroupArgs = {
  _set?: InputMaybe<Nb_Ufield_Group_Set_Input>;
  where: Nb_Ufield_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_Group_By_PkArgs = {
  _set?: InputMaybe<Nb_Ufield_Group_Set_Input>;
  pk_columns: Nb_Ufield_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_Group_ManyArgs = {
  updates: Array<Nb_Ufield_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_HistoryArgs = {
  _append?: InputMaybe<Nb_Ufield_History_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Ufield_History_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Ufield_History_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Ufield_History_Delete_Key_Input>;
  _prepend?: InputMaybe<Nb_Ufield_History_Prepend_Input>;
  _set?: InputMaybe<Nb_Ufield_History_Set_Input>;
  where: Nb_Ufield_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_History_By_PkArgs = {
  _append?: InputMaybe<Nb_Ufield_History_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Ufield_History_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Ufield_History_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Ufield_History_Delete_Key_Input>;
  _prepend?: InputMaybe<Nb_Ufield_History_Prepend_Input>;
  _set?: InputMaybe<Nb_Ufield_History_Set_Input>;
  pk_columns: Nb_Ufield_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_History_ManyArgs = {
  updates: Array<Nb_Ufield_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Ufield_ManyArgs = {
  updates: Array<Nb_Ufield_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Vector_Image_SensingArgs = {
  _set?: InputMaybe<Nb_Vector_Image_Sensing_Set_Input>;
  where: Nb_Vector_Image_Sensing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Vector_Image_Sensing_By_PkArgs = {
  _set?: InputMaybe<Nb_Vector_Image_Sensing_Set_Input>;
  pk_columns: Nb_Vector_Image_Sensing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Vector_Image_Sensing_ManyArgs = {
  updates: Array<Nb_Vector_Image_Sensing_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Vector_Ims_SizeArgs = {
  _inc?: InputMaybe<Nb_Vector_Ims_Size_Inc_Input>;
  _set?: InputMaybe<Nb_Vector_Ims_Size_Set_Input>;
  where: Nb_Vector_Ims_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Vector_Ims_Size_ManyArgs = {
  updates: Array<Nb_Vector_Ims_Size_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Water_Gate_Status_Cplan_LinkArgs = {
  _set?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Set_Input>;
  where: Nb_Water_Gate_Status_Cplan_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Water_Gate_Status_Cplan_Link_By_PkArgs = {
  _set?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Set_Input>;
  pk_columns: Nb_Water_Gate_Status_Cplan_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Water_Gate_Status_Cplan_Link_ManyArgs = {
  updates: Array<Nb_Water_Gate_Status_Cplan_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Weather_AttrArgs = {
  _set?: InputMaybe<Nb_Weather_Attr_Set_Input>;
  where: Nb_Weather_Attr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Weather_Attr_By_PkArgs = {
  _set?: InputMaybe<Nb_Weather_Attr_Set_Input>;
  pk_columns: Nb_Weather_Attr_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Weather_Attr_ManyArgs = {
  updates: Array<Nb_Weather_Attr_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Weather_DataArgs = {
  _inc?: InputMaybe<Nb_Weather_Data_Inc_Input>;
  _set?: InputMaybe<Nb_Weather_Data_Set_Input>;
  where: Nb_Weather_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Weather_Data_By_PkArgs = {
  _inc?: InputMaybe<Nb_Weather_Data_Inc_Input>;
  _set?: InputMaybe<Nb_Weather_Data_Set_Input>;
  pk_columns: Nb_Weather_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Weather_Data_ManyArgs = {
  updates: Array<Nb_Weather_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Wide_OrthoimgArgs = {
  _set?: InputMaybe<Nb_Wide_Orthoimg_Set_Input>;
  where: Nb_Wide_Orthoimg_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Wide_Orthoimg_By_PkArgs = {
  _set?: InputMaybe<Nb_Wide_Orthoimg_Set_Input>;
  pk_columns: Nb_Wide_Orthoimg_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Wide_Orthoimg_ManyArgs = {
  updates: Array<Nb_Wide_Orthoimg_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Wide_Orthoimg_SizeArgs = {
  _inc?: InputMaybe<Nb_Wide_Orthoimg_Size_Inc_Input>;
  _set?: InputMaybe<Nb_Wide_Orthoimg_Size_Set_Input>;
  where: Nb_Wide_Orthoimg_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Wide_Orthoimg_Size_ManyArgs = {
  updates: Array<Nb_Wide_Orthoimg_Size_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Working_MachineArgs = {
  _append?: InputMaybe<Nb_Working_Machine_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Working_Machine_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Working_Machine_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Working_Machine_Delete_Key_Input>;
  _prepend?: InputMaybe<Nb_Working_Machine_Prepend_Input>;
  _set?: InputMaybe<Nb_Working_Machine_Set_Input>;
  where: Nb_Working_Machine_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Working_Machine_By_PkArgs = {
  _append?: InputMaybe<Nb_Working_Machine_Append_Input>;
  _delete_at_path?: InputMaybe<Nb_Working_Machine_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nb_Working_Machine_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nb_Working_Machine_Delete_Key_Input>;
  _prepend?: InputMaybe<Nb_Working_Machine_Prepend_Input>;
  _set?: InputMaybe<Nb_Working_Machine_Set_Input>;
  pk_columns: Nb_Working_Machine_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nb_Working_Machine_ManyArgs = {
  updates: Array<Nb_Working_Machine_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbb_Picture_Analysis_StatusArgs = {
  _set?: InputMaybe<Nbb_Picture_Analysis_Status_Set_Input>;
  where: Nbb_Picture_Analysis_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbb_Picture_Analysis_Status_By_PkArgs = {
  _set?: InputMaybe<Nbb_Picture_Analysis_Status_Set_Input>;
  pk_columns: Nbb_Picture_Analysis_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbb_Picture_Analysis_Status_ManyArgs = {
  updates: Array<Nbb_Picture_Analysis_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbbe_MicroclimateArgs = {
  _inc?: InputMaybe<Nbbe_Microclimate_Inc_Input>;
  _set?: InputMaybe<Nbbe_Microclimate_Set_Input>;
  where: Nbbe_Microclimate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbbe_Microclimate_By_PkArgs = {
  _inc?: InputMaybe<Nbbe_Microclimate_Inc_Input>;
  _set?: InputMaybe<Nbbe_Microclimate_Set_Input>;
  pk_columns: Nbbe_Microclimate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbbe_Microclimate_ManyArgs = {
  updates: Array<Nbbe_Microclimate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbbe_Orthoimg_TypeArgs = {
  _set?: InputMaybe<Nbbe_Orthoimg_Type_Set_Input>;
  where: Nbbe_Orthoimg_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbbe_Orthoimg_Type_By_PkArgs = {
  _set?: InputMaybe<Nbbe_Orthoimg_Type_Set_Input>;
  pk_columns: Nbbe_Orthoimg_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbbe_Orthoimg_Type_ManyArgs = {
  updates: Array<Nbbe_Orthoimg_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbbe_Weather_TypeArgs = {
  _set?: InputMaybe<Nbbe_Weather_Type_Set_Input>;
  where: Nbbe_Weather_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbbe_Weather_Type_By_PkArgs = {
  _set?: InputMaybe<Nbbe_Weather_Type_Set_Input>;
  pk_columns: Nbbe_Weather_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbbe_Weather_Type_ManyArgs = {
  updates: Array<Nbbe_Weather_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_AuthorizationArgs = {
  _set?: InputMaybe<Nbpm_Authorization_Set_Input>;
  where: Nbpm_Authorization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Authorization_By_PkArgs = {
  _set?: InputMaybe<Nbpm_Authorization_Set_Input>;
  pk_columns: Nbpm_Authorization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Authorization_ManyArgs = {
  updates: Array<Nbpm_Authorization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Contract_PlanArgs = {
  _inc?: InputMaybe<Nbpm_Contract_Plan_Inc_Input>;
  _set?: InputMaybe<Nbpm_Contract_Plan_Set_Input>;
  where: Nbpm_Contract_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Contract_Plan_By_PkArgs = {
  _inc?: InputMaybe<Nbpm_Contract_Plan_Inc_Input>;
  _set?: InputMaybe<Nbpm_Contract_Plan_Set_Input>;
  pk_columns: Nbpm_Contract_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Contract_Plan_LinkArgs = {
  _set?: InputMaybe<Nbpm_Contract_Plan_Link_Set_Input>;
  where: Nbpm_Contract_Plan_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Contract_Plan_Link_By_PkArgs = {
  _set?: InputMaybe<Nbpm_Contract_Plan_Link_Set_Input>;
  pk_columns: Nbpm_Contract_Plan_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Contract_Plan_Link_ManyArgs = {
  updates: Array<Nbpm_Contract_Plan_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Contract_Plan_ManyArgs = {
  updates: Array<Nbpm_Contract_Plan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Contract_Plan_Used_Storage_CapacityArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Imsattr_Authorization_LinkArgs = {
  _set?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Set_Input>;
  where: Nbpm_Imsattr_Authorization_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Imsattr_Authorization_Link_By_PkArgs = {
  _set?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Set_Input>;
  pk_columns: Nbpm_Imsattr_Authorization_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Imsattr_Authorization_Link_ManyArgs = {
  updates: Array<Nbpm_Imsattr_Authorization_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Ipengine_Authorization_LinkArgs = {
  _set?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Set_Input>;
  where: Nbpm_Ipengine_Authorization_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Ipengine_Authorization_Link_By_PkArgs = {
  _set?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Set_Input>;
  pk_columns: Nbpm_Ipengine_Authorization_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Ipengine_Authorization_Link_ManyArgs = {
  updates: Array<Nbpm_Ipengine_Authorization_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Picanatype_Authorization_LinkArgs = {
  _set?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Set_Input>;
  where: Nbpm_Picanatype_Authorization_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Picanatype_Authorization_Link_By_PkArgs = {
  _set?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Set_Input>;
  pk_columns: Nbpm_Picanatype_Authorization_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Picanatype_Authorization_Link_ManyArgs = {
  updates: Array<Nbpm_Picanatype_Authorization_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Service_PlanArgs = {
  _set?: InputMaybe<Nbpm_Service_Plan_Set_Input>;
  where: Nbpm_Service_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Service_Plan_By_PkArgs = {
  _set?: InputMaybe<Nbpm_Service_Plan_Set_Input>;
  pk_columns: Nbpm_Service_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Service_Plan_LinkArgs = {
  _set?: InputMaybe<Nbpm_Service_Plan_Link_Set_Input>;
  where: Nbpm_Service_Plan_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Service_Plan_Link_By_PkArgs = {
  _set?: InputMaybe<Nbpm_Service_Plan_Link_Set_Input>;
  pk_columns: Nbpm_Service_Plan_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Service_Plan_Link_ManyArgs = {
  updates: Array<Nbpm_Service_Plan_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_Service_Plan_ManyArgs = {
  updates: Array<Nbpm_Service_Plan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_User_Attr_LinkArgs = {
  _set?: InputMaybe<Nbpm_User_Attr_Link_Set_Input>;
  where: Nbpm_User_Attr_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_User_Attr_Link_By_PkArgs = {
  _set?: InputMaybe<Nbpm_User_Attr_Link_Set_Input>;
  pk_columns: Nbpm_User_Attr_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_User_Attr_Link_ManyArgs = {
  updates: Array<Nbpm_User_Attr_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_User_AttributeArgs = {
  _set?: InputMaybe<Nbpm_User_Attribute_Set_Input>;
  where: Nbpm_User_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_User_Attribute_By_PkArgs = {
  _set?: InputMaybe<Nbpm_User_Attribute_Set_Input>;
  pk_columns: Nbpm_User_Attribute_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_User_Attribute_ManyArgs = {
  updates: Array<Nbpm_User_Attribute_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_User_Cp_Link_LinkArgs = {
  _set?: InputMaybe<Nbpm_User_Cp_Link_Link_Set_Input>;
  where: Nbpm_User_Cp_Link_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_User_Cp_Link_Link_By_PkArgs = {
  _set?: InputMaybe<Nbpm_User_Cp_Link_Link_Set_Input>;
  pk_columns: Nbpm_User_Cp_Link_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbpm_User_Cp_Link_Link_ManyArgs = {
  updates: Array<Nbpm_User_Cp_Link_Link_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_FieldArgs = {
  _set?: InputMaybe<Nbs_Ipe_Run_Field_Set_Input>;
  where: Nbs_Ipe_Run_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_Field_By_PkArgs = {
  _set?: InputMaybe<Nbs_Ipe_Run_Field_Set_Input>;
  pk_columns: Nbs_Ipe_Run_Field_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_Field_ManyArgs = {
  updates: Array<Nbs_Ipe_Run_Field_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_Link_FieldArgs = {
  _set?: InputMaybe<Nbs_Ipe_Run_Link_Field_Set_Input>;
  where: Nbs_Ipe_Run_Link_Field_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_Link_Field_By_PkArgs = {
  _set?: InputMaybe<Nbs_Ipe_Run_Link_Field_Set_Input>;
  pk_columns: Nbs_Ipe_Run_Link_Field_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_Link_Field_ManyArgs = {
  updates: Array<Nbs_Ipe_Run_Link_Field_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_Link_WideArgs = {
  _set?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Set_Input>;
  where: Nbs_Ipe_Run_Link_Wide_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_Link_Wide_By_PkArgs = {
  _set?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Set_Input>;
  pk_columns: Nbs_Ipe_Run_Link_Wide_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_Link_Wide_ManyArgs = {
  updates: Array<Nbs_Ipe_Run_Link_Wide_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_WideArgs = {
  _set?: InputMaybe<Nbs_Ipe_Run_Wide_Set_Input>;
  where: Nbs_Ipe_Run_Wide_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_Wide_By_PkArgs = {
  _set?: InputMaybe<Nbs_Ipe_Run_Wide_Set_Input>;
  pk_columns: Nbs_Ipe_Run_Wide_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Ipe_Run_Wide_ManyArgs = {
  updates: Array<Nbs_Ipe_Run_Wide_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Multipart_UploadArgs = {
  _inc?: InputMaybe<Nbs_Multipart_Upload_Inc_Input>;
  _set?: InputMaybe<Nbs_Multipart_Upload_Set_Input>;
  where: Nbs_Multipart_Upload_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Multipart_Upload_By_PkArgs = {
  _inc?: InputMaybe<Nbs_Multipart_Upload_Inc_Input>;
  _set?: InputMaybe<Nbs_Multipart_Upload_Set_Input>;
  pk_columns: Nbs_Multipart_Upload_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Multipart_Upload_ManyArgs = {
  updates: Array<Nbs_Multipart_Upload_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Wide_Orthoimg_LoadArgs = {
  _inc?: InputMaybe<Nbs_Wide_Orthoimg_Load_Inc_Input>;
  _set?: InputMaybe<Nbs_Wide_Orthoimg_Load_Set_Input>;
  where: Nbs_Wide_Orthoimg_Load_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Wide_Orthoimg_Load_By_PkArgs = {
  _inc?: InputMaybe<Nbs_Wide_Orthoimg_Load_Inc_Input>;
  _set?: InputMaybe<Nbs_Wide_Orthoimg_Load_Set_Input>;
  pk_columns: Nbs_Wide_Orthoimg_Load_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbs_Wide_Orthoimg_Load_ManyArgs = {
  updates: Array<Nbs_Wide_Orthoimg_Load_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbtrk_ContextArgs = {
  _set?: InputMaybe<Nbtrk_Context_Set_Input>;
  where: Nbtrk_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbtrk_Context_By_PkArgs = {
  _set?: InputMaybe<Nbtrk_Context_Set_Input>;
  pk_columns: Nbtrk_Context_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbtrk_Context_ManyArgs = {
  updates: Array<Nbtrk_Context_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nbtrk_Eph_LocationArgs = {
  _inc?: InputMaybe<Nbtrk_Eph_Location_Inc_Input>;
  _set?: InputMaybe<Nbtrk_Eph_Location_Set_Input>;
  where: Nbtrk_Eph_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nbtrk_Eph_Location_By_PkArgs = {
  _inc?: InputMaybe<Nbtrk_Eph_Location_Inc_Input>;
  _set?: InputMaybe<Nbtrk_Eph_Location_Set_Input>;
  pk_columns: Nbtrk_Eph_Location_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nbtrk_Eph_Location_ManyArgs = {
  updates: Array<Nbtrk_Eph_Location_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Engine_InformationArgs = {
  _set?: InputMaybe<Nipe_Engine_Information_Set_Input>;
  where: Nipe_Engine_Information_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Engine_Information_By_PkArgs = {
  _set?: InputMaybe<Nipe_Engine_Information_Set_Input>;
  pk_columns: Nipe_Engine_Information_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Engine_Information_ManyArgs = {
  updates: Array<Nipe_Engine_Information_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Engine_Link_Orthoimg_TypeArgs = {
  _set?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Set_Input>;
  where: Nipe_Engine_Link_Orthoimg_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Engine_Link_Orthoimg_Type_By_PkArgs = {
  _set?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Set_Input>;
  pk_columns: Nipe_Engine_Link_Orthoimg_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Engine_Link_Orthoimg_Type_ManyArgs = {
  updates: Array<Nipe_Engine_Link_Orthoimg_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Engine_Run_StatusArgs = {
  _set?: InputMaybe<Nipe_Engine_Run_Status_Set_Input>;
  where: Nipe_Engine_Run_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Engine_Run_Status_By_PkArgs = {
  _set?: InputMaybe<Nipe_Engine_Run_Status_Set_Input>;
  pk_columns: Nipe_Engine_Run_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Engine_Run_Status_ManyArgs = {
  updates: Array<Nipe_Engine_Run_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Run_CommandArgs = {
  _set?: InputMaybe<Nipe_Run_Command_Set_Input>;
  where: Nipe_Run_Command_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Run_Command_By_PkArgs = {
  _set?: InputMaybe<Nipe_Run_Command_Set_Input>;
  pk_columns: Nipe_Run_Command_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nipe_Run_Command_ManyArgs = {
  updates: Array<Nipe_Run_Command_Updates>;
};

/** columns and relationships of "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild = {
  __typename?: 'nb_actual_crop_yeild';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  created: Scalars['timestamptz'];
  creator_name: Scalars['String'];
  cultivation_plan_id: Scalars['uuid'];
  gross_weight?: Maybe<Scalars['float8']>;
  id: Scalars['uuid'];
  info_yield?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  nb_cultivation_plan: Nb_Cultivation_Plan;
  protein_rate?: Maybe<Scalars['float8']>;
  updated: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
  water_content?: Maybe<Scalars['float8']>;
  yield_weight?: Maybe<Scalars['float8']>;
};


/** columns and relationships of "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_YeildInfo_YieldArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Aggregate = {
  __typename?: 'nb_actual_crop_yeild_aggregate';
  aggregate?: Maybe<Nb_Actual_Crop_Yeild_Aggregate_Fields>;
  nodes: Array<Nb_Actual_Crop_Yeild>;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Var_Samp>;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Avg = {
  arguments: Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Corr = {
  arguments: Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Max = {
  arguments: Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Min = {
  arguments: Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Sum = {
  arguments: Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Aggregate_Fields = {
  __typename?: 'nb_actual_crop_yeild_aggregate_fields';
  avg?: Maybe<Nb_Actual_Crop_Yeild_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Actual_Crop_Yeild_Max_Fields>;
  min?: Maybe<Nb_Actual_Crop_Yeild_Min_Fields>;
  stddev?: Maybe<Nb_Actual_Crop_Yeild_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Actual_Crop_Yeild_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Actual_Crop_Yeild_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Actual_Crop_Yeild_Sum_Fields>;
  var_pop?: Maybe<Nb_Actual_Crop_Yeild_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Actual_Crop_Yeild_Var_Samp_Fields>;
  variance?: Maybe<Nb_Actual_Crop_Yeild_Variance_Fields>;
};


/** aggregate fields of "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Actual_Crop_Yeild_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Actual_Crop_Yeild_Max_Order_By>;
  min?: InputMaybe<Nb_Actual_Crop_Yeild_Min_Order_By>;
  stddev?: InputMaybe<Nb_Actual_Crop_Yeild_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Actual_Crop_Yeild_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Actual_Crop_Yeild_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Actual_Crop_Yeild_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Actual_Crop_Yeild_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Actual_Crop_Yeild_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Actual_Crop_Yeild_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Nb_Actual_Crop_Yeild_Append_Input = {
  info_yield?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Arr_Rel_Insert_Input = {
  data: Array<Nb_Actual_Crop_Yeild_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Actual_Crop_Yeild_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Actual_Crop_Yeild_Avg_Fields = {
  __typename?: 'nb_actual_crop_yeild_avg_fields';
  gross_weight?: Maybe<Scalars['Float']>;
  protein_rate?: Maybe<Scalars['Float']>;
  water_content?: Maybe<Scalars['Float']>;
  yield_weight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Avg_Order_By = {
  gross_weight?: InputMaybe<Order_By>;
  protein_rate?: InputMaybe<Order_By>;
  water_content?: InputMaybe<Order_By>;
  yield_weight?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_actual_crop_yeild". All fields are combined with a logical 'AND'. */
export type Nb_Actual_Crop_Yeild_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Bool_Exp>>;
  _not?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_name?: InputMaybe<String_Comparison_Exp>;
  cultivation_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  gross_weight?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  info_yield?: InputMaybe<Jsonb_Comparison_Exp>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  protein_rate?: InputMaybe<Float8_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  water_content?: InputMaybe<Float8_Comparison_Exp>;
  yield_weight?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_actual_crop_yeild" */
export enum Nb_Actual_Crop_Yeild_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbActualCropYeildPkey = 'nb_actual_crop_yeild_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Nb_Actual_Crop_Yeild_Delete_At_Path_Input = {
  info_yield?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Nb_Actual_Crop_Yeild_Delete_Elem_Input = {
  info_yield?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Nb_Actual_Crop_Yeild_Delete_Key_Input = {
  info_yield?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Inc_Input = {
  gross_weight?: InputMaybe<Scalars['float8']>;
  protein_rate?: InputMaybe<Scalars['float8']>;
  water_content?: InputMaybe<Scalars['float8']>;
  yield_weight?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  gross_weight?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  info_yield?: InputMaybe<Scalars['jsonb']>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Obj_Rel_Insert_Input>;
  protein_rate?: InputMaybe<Scalars['float8']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  water_content?: InputMaybe<Scalars['float8']>;
  yield_weight?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Nb_Actual_Crop_Yeild_Max_Fields = {
  __typename?: 'nb_actual_crop_yeild_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  gross_weight?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  protein_rate?: Maybe<Scalars['float8']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  water_content?: Maybe<Scalars['float8']>;
  yield_weight?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  gross_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  protein_rate?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  water_content?: InputMaybe<Order_By>;
  yield_weight?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Actual_Crop_Yeild_Min_Fields = {
  __typename?: 'nb_actual_crop_yeild_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  gross_weight?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  protein_rate?: Maybe<Scalars['float8']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  water_content?: Maybe<Scalars['float8']>;
  yield_weight?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  gross_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  protein_rate?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  water_content?: InputMaybe<Order_By>;
  yield_weight?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Mutation_Response = {
  __typename?: 'nb_actual_crop_yeild_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Actual_Crop_Yeild>;
};

/** on_conflict condition type for table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_On_Conflict = {
  constraint: Nb_Actual_Crop_Yeild_Constraint;
  update_columns?: Array<Nb_Actual_Crop_Yeild_Update_Column>;
  where?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_actual_crop_yeild". */
export type Nb_Actual_Crop_Yeild_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  gross_weight?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  info_yield?: InputMaybe<Order_By>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Order_By>;
  protein_rate?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  water_content?: InputMaybe<Order_By>;
  yield_weight?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_actual_crop_yeild */
export type Nb_Actual_Crop_Yeild_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Nb_Actual_Crop_Yeild_Prepend_Input = {
  info_yield?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "nb_actual_crop_yeild" */
export enum Nb_Actual_Crop_Yeild_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  Id = 'id',
  /** column name */
  InfoYield = 'info_yield',
  /** column name */
  ProteinRate = 'protein_rate',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WaterContent = 'water_content',
  /** column name */
  YieldWeight = 'yield_weight'
}

/** select "nb_actual_crop_yeild_aggregate_bool_exp_avg_arguments_columns" columns of table "nb_actual_crop_yeild" */
export enum Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  ProteinRate = 'protein_rate',
  /** column name */
  WaterContent = 'water_content',
  /** column name */
  YieldWeight = 'yield_weight'
}

/** select "nb_actual_crop_yeild_aggregate_bool_exp_corr_arguments_columns" columns of table "nb_actual_crop_yeild" */
export enum Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  ProteinRate = 'protein_rate',
  /** column name */
  WaterContent = 'water_content',
  /** column name */
  YieldWeight = 'yield_weight'
}

/** select "nb_actual_crop_yeild_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "nb_actual_crop_yeild" */
export enum Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  ProteinRate = 'protein_rate',
  /** column name */
  WaterContent = 'water_content',
  /** column name */
  YieldWeight = 'yield_weight'
}

/** select "nb_actual_crop_yeild_aggregate_bool_exp_max_arguments_columns" columns of table "nb_actual_crop_yeild" */
export enum Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  ProteinRate = 'protein_rate',
  /** column name */
  WaterContent = 'water_content',
  /** column name */
  YieldWeight = 'yield_weight'
}

/** select "nb_actual_crop_yeild_aggregate_bool_exp_min_arguments_columns" columns of table "nb_actual_crop_yeild" */
export enum Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  ProteinRate = 'protein_rate',
  /** column name */
  WaterContent = 'water_content',
  /** column name */
  YieldWeight = 'yield_weight'
}

/** select "nb_actual_crop_yeild_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "nb_actual_crop_yeild" */
export enum Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  ProteinRate = 'protein_rate',
  /** column name */
  WaterContent = 'water_content',
  /** column name */
  YieldWeight = 'yield_weight'
}

/** select "nb_actual_crop_yeild_aggregate_bool_exp_sum_arguments_columns" columns of table "nb_actual_crop_yeild" */
export enum Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  ProteinRate = 'protein_rate',
  /** column name */
  WaterContent = 'water_content',
  /** column name */
  YieldWeight = 'yield_weight'
}

/** select "nb_actual_crop_yeild_aggregate_bool_exp_var_samp_arguments_columns" columns of table "nb_actual_crop_yeild" */
export enum Nb_Actual_Crop_Yeild_Select_Column_Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  ProteinRate = 'protein_rate',
  /** column name */
  WaterContent = 'water_content',
  /** column name */
  YieldWeight = 'yield_weight'
}

/** input type for updating data in table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  gross_weight?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  info_yield?: InputMaybe<Scalars['jsonb']>;
  protein_rate?: InputMaybe<Scalars['float8']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  water_content?: InputMaybe<Scalars['float8']>;
  yield_weight?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Nb_Actual_Crop_Yeild_Stddev_Fields = {
  __typename?: 'nb_actual_crop_yeild_stddev_fields';
  gross_weight?: Maybe<Scalars['Float']>;
  protein_rate?: Maybe<Scalars['Float']>;
  water_content?: Maybe<Scalars['Float']>;
  yield_weight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Stddev_Order_By = {
  gross_weight?: InputMaybe<Order_By>;
  protein_rate?: InputMaybe<Order_By>;
  water_content?: InputMaybe<Order_By>;
  yield_weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Actual_Crop_Yeild_Stddev_Pop_Fields = {
  __typename?: 'nb_actual_crop_yeild_stddev_pop_fields';
  gross_weight?: Maybe<Scalars['Float']>;
  protein_rate?: Maybe<Scalars['Float']>;
  water_content?: Maybe<Scalars['Float']>;
  yield_weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Stddev_Pop_Order_By = {
  gross_weight?: InputMaybe<Order_By>;
  protein_rate?: InputMaybe<Order_By>;
  water_content?: InputMaybe<Order_By>;
  yield_weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Actual_Crop_Yeild_Stddev_Samp_Fields = {
  __typename?: 'nb_actual_crop_yeild_stddev_samp_fields';
  gross_weight?: Maybe<Scalars['Float']>;
  protein_rate?: Maybe<Scalars['Float']>;
  water_content?: Maybe<Scalars['Float']>;
  yield_weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Stddev_Samp_Order_By = {
  gross_weight?: InputMaybe<Order_By>;
  protein_rate?: InputMaybe<Order_By>;
  water_content?: InputMaybe<Order_By>;
  yield_weight?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Actual_Crop_Yeild_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Actual_Crop_Yeild_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  gross_weight?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  info_yield?: InputMaybe<Scalars['jsonb']>;
  protein_rate?: InputMaybe<Scalars['float8']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  water_content?: InputMaybe<Scalars['float8']>;
  yield_weight?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Nb_Actual_Crop_Yeild_Sum_Fields = {
  __typename?: 'nb_actual_crop_yeild_sum_fields';
  gross_weight?: Maybe<Scalars['float8']>;
  protein_rate?: Maybe<Scalars['float8']>;
  water_content?: Maybe<Scalars['float8']>;
  yield_weight?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Sum_Order_By = {
  gross_weight?: InputMaybe<Order_By>;
  protein_rate?: InputMaybe<Order_By>;
  water_content?: InputMaybe<Order_By>;
  yield_weight?: InputMaybe<Order_By>;
};

/** update columns of table "nb_actual_crop_yeild" */
export enum Nb_Actual_Crop_Yeild_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  GrossWeight = 'gross_weight',
  /** column name */
  Id = 'id',
  /** column name */
  InfoYield = 'info_yield',
  /** column name */
  ProteinRate = 'protein_rate',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WaterContent = 'water_content',
  /** column name */
  YieldWeight = 'yield_weight'
}

export type Nb_Actual_Crop_Yeild_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Nb_Actual_Crop_Yeild_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Nb_Actual_Crop_Yeild_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Nb_Actual_Crop_Yeild_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Nb_Actual_Crop_Yeild_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Actual_Crop_Yeild_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Nb_Actual_Crop_Yeild_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Actual_Crop_Yeild_Set_Input>;
  where: Nb_Actual_Crop_Yeild_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Actual_Crop_Yeild_Var_Pop_Fields = {
  __typename?: 'nb_actual_crop_yeild_var_pop_fields';
  gross_weight?: Maybe<Scalars['Float']>;
  protein_rate?: Maybe<Scalars['Float']>;
  water_content?: Maybe<Scalars['Float']>;
  yield_weight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Var_Pop_Order_By = {
  gross_weight?: InputMaybe<Order_By>;
  protein_rate?: InputMaybe<Order_By>;
  water_content?: InputMaybe<Order_By>;
  yield_weight?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Actual_Crop_Yeild_Var_Samp_Fields = {
  __typename?: 'nb_actual_crop_yeild_var_samp_fields';
  gross_weight?: Maybe<Scalars['Float']>;
  protein_rate?: Maybe<Scalars['Float']>;
  water_content?: Maybe<Scalars['Float']>;
  yield_weight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Var_Samp_Order_By = {
  gross_weight?: InputMaybe<Order_By>;
  protein_rate?: InputMaybe<Order_By>;
  water_content?: InputMaybe<Order_By>;
  yield_weight?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Actual_Crop_Yeild_Variance_Fields = {
  __typename?: 'nb_actual_crop_yeild_variance_fields';
  gross_weight?: Maybe<Scalars['Float']>;
  protein_rate?: Maybe<Scalars['Float']>;
  water_content?: Maybe<Scalars['Float']>;
  yield_weight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_actual_crop_yeild" */
export type Nb_Actual_Crop_Yeild_Variance_Order_By = {
  gross_weight?: InputMaybe<Order_By>;
  protein_rate?: InputMaybe<Order_By>;
  water_content?: InputMaybe<Order_By>;
  yield_weight?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_area_mesh" */
export type Nb_Area_Mesh = {
  __typename?: 'nb_area_mesh';
  area_mesh_id: Scalars['Int'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  nb_mfields: Array<Nb_Mfield>;
  /** An aggregate relationship */
  nb_mfields_aggregate: Nb_Mfield_Aggregate;
  /** An array relationship */
  nb_weather_data: Array<Nb_Weather_Data>;
  /** An aggregate relationship */
  nb_weather_data_aggregate: Nb_Weather_Data_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_area_mesh" */
export type Nb_Area_MeshNb_MfieldsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Bool_Exp>;
};


/** columns and relationships of "nb_area_mesh" */
export type Nb_Area_MeshNb_Mfields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Bool_Exp>;
};


/** columns and relationships of "nb_area_mesh" */
export type Nb_Area_MeshNb_Weather_DataArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
};


/** columns and relationships of "nb_area_mesh" */
export type Nb_Area_MeshNb_Weather_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
};

/** aggregated selection of "nb_area_mesh" */
export type Nb_Area_Mesh_Aggregate = {
  __typename?: 'nb_area_mesh_aggregate';
  aggregate?: Maybe<Nb_Area_Mesh_Aggregate_Fields>;
  nodes: Array<Nb_Area_Mesh>;
};

/** aggregate fields of "nb_area_mesh" */
export type Nb_Area_Mesh_Aggregate_Fields = {
  __typename?: 'nb_area_mesh_aggregate_fields';
  avg?: Maybe<Nb_Area_Mesh_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Area_Mesh_Max_Fields>;
  min?: Maybe<Nb_Area_Mesh_Min_Fields>;
  stddev?: Maybe<Nb_Area_Mesh_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Area_Mesh_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Area_Mesh_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Area_Mesh_Sum_Fields>;
  var_pop?: Maybe<Nb_Area_Mesh_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Area_Mesh_Var_Samp_Fields>;
  variance?: Maybe<Nb_Area_Mesh_Variance_Fields>;
};


/** aggregate fields of "nb_area_mesh" */
export type Nb_Area_Mesh_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Area_Mesh_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Nb_Area_Mesh_Avg_Fields = {
  __typename?: 'nb_area_mesh_avg_fields';
  area_mesh_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nb_area_mesh". All fields are combined with a logical 'AND'. */
export type Nb_Area_Mesh_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Area_Mesh_Bool_Exp>>;
  _not?: InputMaybe<Nb_Area_Mesh_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Area_Mesh_Bool_Exp>>;
  area_mesh_id?: InputMaybe<Int_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_mfields?: InputMaybe<Nb_Mfield_Bool_Exp>;
  nb_mfields_aggregate?: InputMaybe<Nb_Mfield_Aggregate_Bool_Exp>;
  nb_weather_data?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
  nb_weather_data_aggregate?: InputMaybe<Nb_Weather_Data_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_area_mesh" */
export enum Nb_Area_Mesh_Constraint {
  /** unique or primary key constraint on columns "area_mesh_id" */
  AreaMeshIdPkey = 'area_mesh_id_pkey',
  /** unique or primary key constraint on columns "area_mesh_id" */
  NbAreaMeshAreaMeshIdKey = 'nb_area_mesh_area_mesh_id_key',
  /** unique or primary key constraint on columns "id" */
  NbAreaMeshPkey = 'nb_area_mesh_pkey'
}

/** input type for incrementing numeric columns in table "nb_area_mesh" */
export type Nb_Area_Mesh_Inc_Input = {
  area_mesh_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nb_area_mesh" */
export type Nb_Area_Mesh_Insert_Input = {
  area_mesh_id?: InputMaybe<Scalars['Int']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_mfields?: InputMaybe<Nb_Mfield_Arr_Rel_Insert_Input>;
  nb_weather_data?: InputMaybe<Nb_Weather_Data_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Area_Mesh_Max_Fields = {
  __typename?: 'nb_area_mesh_max_fields';
  area_mesh_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Area_Mesh_Min_Fields = {
  __typename?: 'nb_area_mesh_min_fields';
  area_mesh_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_area_mesh" */
export type Nb_Area_Mesh_Mutation_Response = {
  __typename?: 'nb_area_mesh_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Area_Mesh>;
};

/** input type for inserting object relation for remote table "nb_area_mesh" */
export type Nb_Area_Mesh_Obj_Rel_Insert_Input = {
  data: Nb_Area_Mesh_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Area_Mesh_On_Conflict>;
};

/** on_conflict condition type for table "nb_area_mesh" */
export type Nb_Area_Mesh_On_Conflict = {
  constraint: Nb_Area_Mesh_Constraint;
  update_columns?: Array<Nb_Area_Mesh_Update_Column>;
  where?: InputMaybe<Nb_Area_Mesh_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_area_mesh". */
export type Nb_Area_Mesh_Order_By = {
  area_mesh_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_mfields_aggregate?: InputMaybe<Nb_Mfield_Aggregate_Order_By>;
  nb_weather_data_aggregate?: InputMaybe<Nb_Weather_Data_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_area_mesh */
export type Nb_Area_Mesh_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_area_mesh" */
export enum Nb_Area_Mesh_Select_Column {
  /** column name */
  AreaMeshId = 'area_mesh_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_area_mesh" */
export type Nb_Area_Mesh_Set_Input = {
  area_mesh_id?: InputMaybe<Scalars['Int']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nb_Area_Mesh_Stddev_Fields = {
  __typename?: 'nb_area_mesh_stddev_fields';
  area_mesh_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Nb_Area_Mesh_Stddev_Pop_Fields = {
  __typename?: 'nb_area_mesh_stddev_pop_fields';
  area_mesh_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Nb_Area_Mesh_Stddev_Samp_Fields = {
  __typename?: 'nb_area_mesh_stddev_samp_fields';
  area_mesh_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nb_area_mesh" */
export type Nb_Area_Mesh_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Area_Mesh_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Area_Mesh_Stream_Cursor_Value_Input = {
  area_mesh_id?: InputMaybe<Scalars['Int']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Area_Mesh_Sum_Fields = {
  __typename?: 'nb_area_mesh_sum_fields';
  area_mesh_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "nb_area_mesh" */
export enum Nb_Area_Mesh_Update_Column {
  /** column name */
  AreaMeshId = 'area_mesh_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Area_Mesh_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Area_Mesh_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Area_Mesh_Set_Input>;
  where: Nb_Area_Mesh_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Area_Mesh_Var_Pop_Fields = {
  __typename?: 'nb_area_mesh_var_pop_fields';
  area_mesh_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Nb_Area_Mesh_Var_Samp_Fields = {
  __typename?: 'nb_area_mesh_var_samp_fields';
  area_mesh_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Nb_Area_Mesh_Variance_Fields = {
  __typename?: 'nb_area_mesh_variance_fields';
  area_mesh_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "nb_company" */
export type Nb_Company = {
  __typename?: 'nb_company';
  /** An array relationship */
  application_user_invitation_to_companies: Array<Application_User_Invitation_To_Company>;
  /** An aggregate relationship */
  application_user_invitation_to_companies_aggregate: Application_User_Invitation_To_Company_Aggregate;
  closed: Scalars['Boolean'];
  contract_plan_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  nb_company_infos: Array<Nb_Company_Info>;
  /** An aggregate relationship */
  nb_company_infos_aggregate: Nb_Company_Info_Aggregate;
  /** An array relationship */
  nb_crop_company_links: Array<Nb_Crop_Company_Link>;
  /** An aggregate relationship */
  nb_crop_company_links_aggregate: Nb_Crop_Company_Link_Aggregate;
  /** An array relationship */
  nb_demo_user_names: Array<Nb_Demo_User_Name>;
  /** An aggregate relationship */
  nb_demo_user_names_aggregate: Nb_Demo_User_Name_Aggregate;
  /** An array relationship */
  nb_fertilizer_company_links: Array<Nb_Fertilizer_Company_Link>;
  /** An aggregate relationship */
  nb_fertilizer_company_links_aggregate: Nb_Fertilizer_Company_Link_Aggregate;
  /** An array relationship */
  nb_field_file_loads: Array<Nb_Field_File_Load>;
  /** An aggregate relationship */
  nb_field_file_loads_aggregate: Nb_Field_File_Load_Aggregate;
  /** An array relationship */
  nb_field_picture_loads: Array<Nb_Field_Picture_Load>;
  /** An aggregate relationship */
  nb_field_picture_loads_aggregate: Nb_Field_Picture_Load_Aggregate;
  /** An array relationship */
  nb_file_sizes: Array<Nb_File_Size>;
  /** An aggregate relationship */
  nb_file_sizes_aggregate: Nb_File_Size_Aggregate;
  /** An array relationship */
  nb_performed_works: Array<Nb_Performed_Work>;
  /** An aggregate relationship */
  nb_performed_works_aggregate: Nb_Performed_Work_Aggregate;
  /** An array relationship */
  nb_pesticide_company_links: Array<Nb_Pesticide_Company_Link>;
  /** An aggregate relationship */
  nb_pesticide_company_links_aggregate: Nb_Pesticide_Company_Link_Aggregate;
  /** An array relationship */
  nb_soil_chemistry_standards: Array<Nb_Soil_Chemistry_Standards>;
  /** An aggregate relationship */
  nb_soil_chemistry_standards_aggregate: Nb_Soil_Chemistry_Standards_Aggregate;
  /** An array relationship */
  nb_tasktype_company_links: Array<Nb_Tasktype_Company_Link>;
  /** An aggregate relationship */
  nb_tasktype_company_links_aggregate: Nb_Tasktype_Company_Link_Aggregate;
  /** An array relationship */
  nb_ufield_attributes: Array<Nb_Ufield_Attribute>;
  /** An aggregate relationship */
  nb_ufield_attributes_aggregate: Nb_Ufield_Attribute_Aggregate;
  /** An array relationship */
  nb_ufield_company_links: Array<Nb_Ufield_Company_Link>;
  /** An aggregate relationship */
  nb_ufield_company_links_aggregate: Nb_Ufield_Company_Link_Aggregate;
  /** An array relationship */
  nb_ufield_groups: Array<Nb_Ufield_Group>;
  /** An aggregate relationship */
  nb_ufield_groups_aggregate: Nb_Ufield_Group_Aggregate;
  /** An array relationship */
  nb_wide_orthoimgs: Array<Nb_Wide_Orthoimg>;
  /** An aggregate relationship */
  nb_wide_orthoimgs_aggregate: Nb_Wide_Orthoimg_Aggregate;
  /** An object relationship */
  nbpm_contract_plan: Nbpm_Contract_Plan;
  /** An array relationship */
  nbpm_user_attr_links: Array<Nbpm_User_Attr_Link>;
  /** An aggregate relationship */
  nbpm_user_attr_links_aggregate: Nbpm_User_Attr_Link_Aggregate;
  /** An array relationship */
  nbs_ipe_run_wides: Array<Nbs_Ipe_Run_Wide>;
  /** An aggregate relationship */
  nbs_ipe_run_wides_aggregate: Nbs_Ipe_Run_Wide_Aggregate;
  /** An array relationship */
  nbs_multipart_uploads: Array<Nbs_Multipart_Upload>;
  /** An aggregate relationship */
  nbs_multipart_uploads_aggregate: Nbs_Multipart_Upload_Aggregate;
  /** An array relationship */
  nbs_wide_orthoimg_loads: Array<Nbs_Wide_Orthoimg_Load>;
  /** An aggregate relationship */
  nbs_wide_orthoimg_loads_aggregate: Nbs_Wide_Orthoimg_Load_Aggregate;
  /** An array relationship */
  nbtrk_contexts: Array<Nbtrk_Context>;
  /** An aggregate relationship */
  nbtrk_contexts_aggregate: Nbtrk_Context_Aggregate;
  representative?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyApplication_User_Invitation_To_CompaniesArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyApplication_User_Invitation_To_Companies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Company_InfosArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Info_Order_By>>;
  where?: InputMaybe<Nb_Company_Info_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Company_Infos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Info_Order_By>>;
  where?: InputMaybe<Nb_Company_Info_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Crop_Company_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Crop_Company_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Demo_User_NamesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Name_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Demo_User_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Name_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Fertilizer_Company_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Fertilizer_Company_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Field_File_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Field_File_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Field_Picture_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Field_Picture_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_File_SizesArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_File_Sizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Performed_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Performed_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Pesticide_Company_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Pesticide_Company_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Soil_Chemistry_StandardsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Soil_Chemistry_Standards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Tasktype_Company_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Tasktype_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Tasktype_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Tasktype_Company_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Tasktype_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Tasktype_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Ufield_AttributesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Attribute_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Ufield_Attributes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Attribute_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Ufield_Company_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Ufield_Company_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Ufield_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Group_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Ufield_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Group_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Wide_OrthoimgsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNb_Wide_Orthoimgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNbpm_User_Attr_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attr_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNbpm_User_Attr_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attr_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNbs_Ipe_Run_WidesArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNbs_Ipe_Run_Wides_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNbs_Multipart_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Multipart_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Multipart_Upload_Order_By>>;
  where?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNbs_Multipart_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Multipart_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Multipart_Upload_Order_By>>;
  where?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNbs_Wide_Orthoimg_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNbs_Wide_Orthoimg_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNbtrk_ContextsArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Context_Order_By>>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};


/** columns and relationships of "nb_company" */
export type Nb_CompanyNbtrk_Contexts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Context_Order_By>>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};

/** aggregated selection of "nb_company" */
export type Nb_Company_Aggregate = {
  __typename?: 'nb_company_aggregate';
  aggregate?: Maybe<Nb_Company_Aggregate_Fields>;
  nodes: Array<Nb_Company>;
};

export type Nb_Company_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Nb_Company_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Nb_Company_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Nb_Company_Aggregate_Bool_Exp_Count>;
};

export type Nb_Company_Aggregate_Bool_Exp_Bool_And = {
  arguments: Nb_Company_Select_Column_Nb_Company_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Company_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Nb_Company_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Nb_Company_Select_Column_Nb_Company_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Company_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Nb_Company_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Company_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_company" */
export type Nb_Company_Aggregate_Fields = {
  __typename?: 'nb_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Company_Max_Fields>;
  min?: Maybe<Nb_Company_Min_Fields>;
};


/** aggregate fields of "nb_company" */
export type Nb_Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_company" */
export type Nb_Company_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Company_Max_Order_By>;
  min?: InputMaybe<Nb_Company_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_company" */
export type Nb_Company_Arr_Rel_Insert_Input = {
  data: Array<Nb_Company_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Company_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_company". All fields are combined with a logical 'AND'. */
export type Nb_Company_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Company_Bool_Exp>>;
  _not?: InputMaybe<Nb_Company_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Company_Bool_Exp>>;
  application_user_invitation_to_companies?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
  application_user_invitation_to_companies_aggregate?: InputMaybe<Application_User_Invitation_To_Company_Aggregate_Bool_Exp>;
  closed?: InputMaybe<Boolean_Comparison_Exp>;
  contract_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_company_infos?: InputMaybe<Nb_Company_Info_Bool_Exp>;
  nb_company_infos_aggregate?: InputMaybe<Nb_Company_Info_Aggregate_Bool_Exp>;
  nb_crop_company_links?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
  nb_crop_company_links_aggregate?: InputMaybe<Nb_Crop_Company_Link_Aggregate_Bool_Exp>;
  nb_demo_user_names?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
  nb_demo_user_names_aggregate?: InputMaybe<Nb_Demo_User_Name_Aggregate_Bool_Exp>;
  nb_fertilizer_company_links?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
  nb_fertilizer_company_links_aggregate?: InputMaybe<Nb_Fertilizer_Company_Link_Aggregate_Bool_Exp>;
  nb_field_file_loads?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
  nb_field_file_loads_aggregate?: InputMaybe<Nb_Field_File_Load_Aggregate_Bool_Exp>;
  nb_field_picture_loads?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
  nb_field_picture_loads_aggregate?: InputMaybe<Nb_Field_Picture_Load_Aggregate_Bool_Exp>;
  nb_file_sizes?: InputMaybe<Nb_File_Size_Bool_Exp>;
  nb_file_sizes_aggregate?: InputMaybe<Nb_File_Size_Aggregate_Bool_Exp>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Bool_Exp>;
  nb_pesticide_company_links?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
  nb_pesticide_company_links_aggregate?: InputMaybe<Nb_Pesticide_Company_Link_Aggregate_Bool_Exp>;
  nb_soil_chemistry_standards?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
  nb_soil_chemistry_standards_aggregate?: InputMaybe<Nb_Soil_Chemistry_Standards_Aggregate_Bool_Exp>;
  nb_tasktype_company_links?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
  nb_tasktype_company_links_aggregate?: InputMaybe<Nb_Tasktype_Company_Link_Aggregate_Bool_Exp>;
  nb_ufield_attributes?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
  nb_ufield_attributes_aggregate?: InputMaybe<Nb_Ufield_Attribute_Aggregate_Bool_Exp>;
  nb_ufield_company_links?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
  nb_ufield_company_links_aggregate?: InputMaybe<Nb_Ufield_Company_Link_Aggregate_Bool_Exp>;
  nb_ufield_groups?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
  nb_ufield_groups_aggregate?: InputMaybe<Nb_Ufield_Group_Aggregate_Bool_Exp>;
  nb_wide_orthoimgs?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
  nb_wide_orthoimgs_aggregate?: InputMaybe<Nb_Wide_Orthoimg_Aggregate_Bool_Exp>;
  nbpm_contract_plan?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
  nbpm_user_attr_links?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
  nbpm_user_attr_links_aggregate?: InputMaybe<Nbpm_User_Attr_Link_Aggregate_Bool_Exp>;
  nbs_ipe_run_wides?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
  nbs_ipe_run_wides_aggregate?: InputMaybe<Nbs_Ipe_Run_Wide_Aggregate_Bool_Exp>;
  nbs_multipart_uploads?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
  nbs_multipart_uploads_aggregate?: InputMaybe<Nbs_Multipart_Upload_Aggregate_Bool_Exp>;
  nbs_wide_orthoimg_loads?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
  nbs_wide_orthoimg_loads_aggregate?: InputMaybe<Nbs_Wide_Orthoimg_Load_Aggregate_Bool_Exp>;
  nbtrk_contexts?: InputMaybe<Nbtrk_Context_Bool_Exp>;
  nbtrk_contexts_aggregate?: InputMaybe<Nbtrk_Context_Aggregate_Bool_Exp>;
  representative?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_company" */
export enum Nb_Company_Constraint {
  /** unique or primary key constraint on columns "contract_plan_id" */
  NbCompanyContractPlanIdKey = 'nb_company_contract_plan_id_key',
  /** unique or primary key constraint on columns "key" */
  NbCompanyKeyKey = 'nb_company_key_key',
  /** unique or primary key constraint on columns "id" */
  NbCompanyPkey = 'nb_company_pkey'
}

/** columns and relationships of "nb_company_info" */
export type Nb_Company_Info = {
  __typename?: 'nb_company_info';
  address: Scalars['String'];
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_company: Nb_Company;
  phone_number: Scalars['String'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_company_info" */
export type Nb_Company_Info_Aggregate = {
  __typename?: 'nb_company_info_aggregate';
  aggregate?: Maybe<Nb_Company_Info_Aggregate_Fields>;
  nodes: Array<Nb_Company_Info>;
};

export type Nb_Company_Info_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Company_Info_Aggregate_Bool_Exp_Count>;
};

export type Nb_Company_Info_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Company_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Company_Info_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_company_info" */
export type Nb_Company_Info_Aggregate_Fields = {
  __typename?: 'nb_company_info_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Company_Info_Max_Fields>;
  min?: Maybe<Nb_Company_Info_Min_Fields>;
};


/** aggregate fields of "nb_company_info" */
export type Nb_Company_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Company_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_company_info" */
export type Nb_Company_Info_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Company_Info_Max_Order_By>;
  min?: InputMaybe<Nb_Company_Info_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_company_info" */
export type Nb_Company_Info_Arr_Rel_Insert_Input = {
  data: Array<Nb_Company_Info_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Company_Info_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_company_info". All fields are combined with a logical 'AND'. */
export type Nb_Company_Info_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Company_Info_Bool_Exp>>;
  _not?: InputMaybe<Nb_Company_Info_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Company_Info_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_company_info" */
export enum Nb_Company_Info_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbCompanyInfoPkey = 'nb_company_info_pkey'
}

/** input type for inserting data into table "nb_company_info" */
export type Nb_Company_Info_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Company_Info_Max_Fields = {
  __typename?: 'nb_company_info_max_fields';
  address?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_company_info" */
export type Nb_Company_Info_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Company_Info_Min_Fields = {
  __typename?: 'nb_company_info_min_fields';
  address?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_company_info" */
export type Nb_Company_Info_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_company_info" */
export type Nb_Company_Info_Mutation_Response = {
  __typename?: 'nb_company_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Company_Info>;
};

/** on_conflict condition type for table "nb_company_info" */
export type Nb_Company_Info_On_Conflict = {
  constraint: Nb_Company_Info_Constraint;
  update_columns?: Array<Nb_Company_Info_Update_Column>;
  where?: InputMaybe<Nb_Company_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_company_info". */
export type Nb_Company_Info_Order_By = {
  address?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_company_info */
export type Nb_Company_Info_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_company_info" */
export enum Nb_Company_Info_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_company_info" */
export type Nb_Company_Info_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_company_info" */
export type Nb_Company_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Company_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Company_Info_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_company_info" */
export enum Nb_Company_Info_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Updated = 'updated'
}

export type Nb_Company_Info_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Company_Info_Set_Input>;
  where: Nb_Company_Info_Bool_Exp;
};

/** input type for inserting data into table "nb_company" */
export type Nb_Company_Insert_Input = {
  application_user_invitation_to_companies?: InputMaybe<Application_User_Invitation_To_Company_Arr_Rel_Insert_Input>;
  closed?: InputMaybe<Scalars['Boolean']>;
  contract_plan_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nb_company_infos?: InputMaybe<Nb_Company_Info_Arr_Rel_Insert_Input>;
  nb_crop_company_links?: InputMaybe<Nb_Crop_Company_Link_Arr_Rel_Insert_Input>;
  nb_demo_user_names?: InputMaybe<Nb_Demo_User_Name_Arr_Rel_Insert_Input>;
  nb_fertilizer_company_links?: InputMaybe<Nb_Fertilizer_Company_Link_Arr_Rel_Insert_Input>;
  nb_field_file_loads?: InputMaybe<Nb_Field_File_Load_Arr_Rel_Insert_Input>;
  nb_field_picture_loads?: InputMaybe<Nb_Field_Picture_Load_Arr_Rel_Insert_Input>;
  nb_file_sizes?: InputMaybe<Nb_File_Size_Arr_Rel_Insert_Input>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Arr_Rel_Insert_Input>;
  nb_pesticide_company_links?: InputMaybe<Nb_Pesticide_Company_Link_Arr_Rel_Insert_Input>;
  nb_soil_chemistry_standards?: InputMaybe<Nb_Soil_Chemistry_Standards_Arr_Rel_Insert_Input>;
  nb_tasktype_company_links?: InputMaybe<Nb_Tasktype_Company_Link_Arr_Rel_Insert_Input>;
  nb_ufield_attributes?: InputMaybe<Nb_Ufield_Attribute_Arr_Rel_Insert_Input>;
  nb_ufield_company_links?: InputMaybe<Nb_Ufield_Company_Link_Arr_Rel_Insert_Input>;
  nb_ufield_groups?: InputMaybe<Nb_Ufield_Group_Arr_Rel_Insert_Input>;
  nb_wide_orthoimgs?: InputMaybe<Nb_Wide_Orthoimg_Arr_Rel_Insert_Input>;
  nbpm_contract_plan?: InputMaybe<Nbpm_Contract_Plan_Obj_Rel_Insert_Input>;
  nbpm_user_attr_links?: InputMaybe<Nbpm_User_Attr_Link_Arr_Rel_Insert_Input>;
  nbs_ipe_run_wides?: InputMaybe<Nbs_Ipe_Run_Wide_Arr_Rel_Insert_Input>;
  nbs_multipart_uploads?: InputMaybe<Nbs_Multipart_Upload_Arr_Rel_Insert_Input>;
  nbs_wide_orthoimg_loads?: InputMaybe<Nbs_Wide_Orthoimg_Load_Arr_Rel_Insert_Input>;
  nbtrk_contexts?: InputMaybe<Nbtrk_Context_Arr_Rel_Insert_Input>;
  representative?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Company_Max_Fields = {
  __typename?: 'nb_company_max_fields';
  contract_plan_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  representative?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_company" */
export type Nb_Company_Max_Order_By = {
  contract_plan_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  representative?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Company_Min_Fields = {
  __typename?: 'nb_company_min_fields';
  contract_plan_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  representative?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_company" */
export type Nb_Company_Min_Order_By = {
  contract_plan_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  representative?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_company" */
export type Nb_Company_Mutation_Response = {
  __typename?: 'nb_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Company>;
};

/** input type for inserting object relation for remote table "nb_company" */
export type Nb_Company_Obj_Rel_Insert_Input = {
  data: Nb_Company_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Company_On_Conflict>;
};

/** on_conflict condition type for table "nb_company" */
export type Nb_Company_On_Conflict = {
  constraint: Nb_Company_Constraint;
  update_columns?: Array<Nb_Company_Update_Column>;
  where?: InputMaybe<Nb_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_company". */
export type Nb_Company_Order_By = {
  application_user_invitation_to_companies_aggregate?: InputMaybe<Application_User_Invitation_To_Company_Aggregate_Order_By>;
  closed?: InputMaybe<Order_By>;
  contract_plan_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_company_infos_aggregate?: InputMaybe<Nb_Company_Info_Aggregate_Order_By>;
  nb_crop_company_links_aggregate?: InputMaybe<Nb_Crop_Company_Link_Aggregate_Order_By>;
  nb_demo_user_names_aggregate?: InputMaybe<Nb_Demo_User_Name_Aggregate_Order_By>;
  nb_fertilizer_company_links_aggregate?: InputMaybe<Nb_Fertilizer_Company_Link_Aggregate_Order_By>;
  nb_field_file_loads_aggregate?: InputMaybe<Nb_Field_File_Load_Aggregate_Order_By>;
  nb_field_picture_loads_aggregate?: InputMaybe<Nb_Field_Picture_Load_Aggregate_Order_By>;
  nb_file_sizes_aggregate?: InputMaybe<Nb_File_Size_Aggregate_Order_By>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Order_By>;
  nb_pesticide_company_links_aggregate?: InputMaybe<Nb_Pesticide_Company_Link_Aggregate_Order_By>;
  nb_soil_chemistry_standards_aggregate?: InputMaybe<Nb_Soil_Chemistry_Standards_Aggregate_Order_By>;
  nb_tasktype_company_links_aggregate?: InputMaybe<Nb_Tasktype_Company_Link_Aggregate_Order_By>;
  nb_ufield_attributes_aggregate?: InputMaybe<Nb_Ufield_Attribute_Aggregate_Order_By>;
  nb_ufield_company_links_aggregate?: InputMaybe<Nb_Ufield_Company_Link_Aggregate_Order_By>;
  nb_ufield_groups_aggregate?: InputMaybe<Nb_Ufield_Group_Aggregate_Order_By>;
  nb_wide_orthoimgs_aggregate?: InputMaybe<Nb_Wide_Orthoimg_Aggregate_Order_By>;
  nbpm_contract_plan?: InputMaybe<Nbpm_Contract_Plan_Order_By>;
  nbpm_user_attr_links_aggregate?: InputMaybe<Nbpm_User_Attr_Link_Aggregate_Order_By>;
  nbs_ipe_run_wides_aggregate?: InputMaybe<Nbs_Ipe_Run_Wide_Aggregate_Order_By>;
  nbs_multipart_uploads_aggregate?: InputMaybe<Nbs_Multipart_Upload_Aggregate_Order_By>;
  nbs_wide_orthoimg_loads_aggregate?: InputMaybe<Nbs_Wide_Orthoimg_Load_Aggregate_Order_By>;
  nbtrk_contexts_aggregate?: InputMaybe<Nbtrk_Context_Aggregate_Order_By>;
  representative?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_company */
export type Nb_Company_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_company" */
export enum Nb_Company_Select_Column {
  /** column name */
  Closed = 'closed',
  /** column name */
  ContractPlanId = 'contract_plan_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Representative = 'representative',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** select "nb_company_aggregate_bool_exp_bool_and_arguments_columns" columns of table "nb_company" */
export enum Nb_Company_Select_Column_Nb_Company_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Closed = 'closed'
}

/** select "nb_company_aggregate_bool_exp_bool_or_arguments_columns" columns of table "nb_company" */
export enum Nb_Company_Select_Column_Nb_Company_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Closed = 'closed'
}

/** input type for updating data in table "nb_company" */
export type Nb_Company_Set_Input = {
  closed?: InputMaybe<Scalars['Boolean']>;
  contract_plan_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  representative?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_company" */
export type Nb_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Company_Stream_Cursor_Value_Input = {
  closed?: InputMaybe<Scalars['Boolean']>;
  contract_plan_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  representative?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_company" */
export enum Nb_Company_Update_Column {
  /** column name */
  Closed = 'closed',
  /** column name */
  ContractPlanId = 'contract_plan_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Representative = 'representative',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Company_Set_Input>;
  where: Nb_Company_Bool_Exp;
};

/** columns and relationships of "nb_conditioner_work" */
export type Nb_Conditioner_Work = {
  __typename?: 'nb_conditioner_work';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  conditioner_amount: Scalars['Float'];
  conditioner_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  datetime: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_cultivation_plan?: Maybe<Nb_Cultivation_Plan>;
  /** An object relationship */
  nb_fertilizer: Nb_Fertilizer;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  ufield_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};

/** aggregated selection of "nb_conditioner_work" */
export type Nb_Conditioner_Work_Aggregate = {
  __typename?: 'nb_conditioner_work_aggregate';
  aggregate?: Maybe<Nb_Conditioner_Work_Aggregate_Fields>;
  nodes: Array<Nb_Conditioner_Work>;
};

export type Nb_Conditioner_Work_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Conditioner_Work_Aggregate_Bool_Exp_Count>;
};

export type Nb_Conditioner_Work_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_conditioner_work" */
export type Nb_Conditioner_Work_Aggregate_Fields = {
  __typename?: 'nb_conditioner_work_aggregate_fields';
  avg?: Maybe<Nb_Conditioner_Work_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Conditioner_Work_Max_Fields>;
  min?: Maybe<Nb_Conditioner_Work_Min_Fields>;
  stddev?: Maybe<Nb_Conditioner_Work_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Conditioner_Work_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Conditioner_Work_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Conditioner_Work_Sum_Fields>;
  var_pop?: Maybe<Nb_Conditioner_Work_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Conditioner_Work_Var_Samp_Fields>;
  variance?: Maybe<Nb_Conditioner_Work_Variance_Fields>;
};


/** aggregate fields of "nb_conditioner_work" */
export type Nb_Conditioner_Work_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Conditioner_Work_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Conditioner_Work_Max_Order_By>;
  min?: InputMaybe<Nb_Conditioner_Work_Min_Order_By>;
  stddev?: InputMaybe<Nb_Conditioner_Work_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Conditioner_Work_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Conditioner_Work_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Conditioner_Work_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Conditioner_Work_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Conditioner_Work_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Conditioner_Work_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Arr_Rel_Insert_Input = {
  data: Array<Nb_Conditioner_Work_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Conditioner_Work_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Conditioner_Work_Avg_Fields = {
  __typename?: 'nb_conditioner_work_avg_fields';
  conditioner_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Avg_Order_By = {
  conditioner_amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_conditioner_work". All fields are combined with a logical 'AND'. */
export type Nb_Conditioner_Work_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Conditioner_Work_Bool_Exp>>;
  _not?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Conditioner_Work_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  conditioner_amount?: InputMaybe<Float_Comparison_Exp>;
  conditioner_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  cultivation_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_conditioner_work" */
export enum Nb_Conditioner_Work_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbConditionerWorkPkey = 'nb_conditioner_work_pkey'
}

/** input type for incrementing numeric columns in table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Inc_Input = {
  conditioner_amount?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  conditioner_amount?: InputMaybe<Scalars['Float']>;
  conditioner_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Obj_Rel_Insert_Input>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Obj_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Conditioner_Work_Max_Fields = {
  __typename?: 'nb_conditioner_work_max_fields';
  conditioner_amount?: Maybe<Scalars['Float']>;
  conditioner_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Max_Order_By = {
  conditioner_amount?: InputMaybe<Order_By>;
  conditioner_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Conditioner_Work_Min_Fields = {
  __typename?: 'nb_conditioner_work_min_fields';
  conditioner_amount?: Maybe<Scalars['Float']>;
  conditioner_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Min_Order_By = {
  conditioner_amount?: InputMaybe<Order_By>;
  conditioner_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Mutation_Response = {
  __typename?: 'nb_conditioner_work_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Conditioner_Work>;
};

/** on_conflict condition type for table "nb_conditioner_work" */
export type Nb_Conditioner_Work_On_Conflict = {
  constraint: Nb_Conditioner_Work_Constraint;
  update_columns?: Array<Nb_Conditioner_Work_Update_Column>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_conditioner_work". */
export type Nb_Conditioner_Work_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  conditioner_amount?: InputMaybe<Order_By>;
  conditioner_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Order_By>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_conditioner_work */
export type Nb_Conditioner_Work_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_conditioner_work" */
export enum Nb_Conditioner_Work_Select_Column {
  /** column name */
  ConditionerAmount = 'conditioner_amount',
  /** column name */
  ConditionerId = 'conditioner_id',
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Set_Input = {
  conditioner_amount?: InputMaybe<Scalars['Float']>;
  conditioner_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nb_Conditioner_Work_Stddev_Fields = {
  __typename?: 'nb_conditioner_work_stddev_fields';
  conditioner_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Stddev_Order_By = {
  conditioner_amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Conditioner_Work_Stddev_Pop_Fields = {
  __typename?: 'nb_conditioner_work_stddev_pop_fields';
  conditioner_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Stddev_Pop_Order_By = {
  conditioner_amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Conditioner_Work_Stddev_Samp_Fields = {
  __typename?: 'nb_conditioner_work_stddev_samp_fields';
  conditioner_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Stddev_Samp_Order_By = {
  conditioner_amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Conditioner_Work_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Conditioner_Work_Stream_Cursor_Value_Input = {
  conditioner_amount?: InputMaybe<Scalars['Float']>;
  conditioner_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Conditioner_Work_Sum_Fields = {
  __typename?: 'nb_conditioner_work_sum_fields';
  conditioner_amount?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Sum_Order_By = {
  conditioner_amount?: InputMaybe<Order_By>;
};

/** update columns of table "nb_conditioner_work" */
export enum Nb_Conditioner_Work_Update_Column {
  /** column name */
  ConditionerAmount = 'conditioner_amount',
  /** column name */
  ConditionerId = 'conditioner_id',
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Conditioner_Work_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Conditioner_Work_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Conditioner_Work_Set_Input>;
  where: Nb_Conditioner_Work_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Conditioner_Work_Var_Pop_Fields = {
  __typename?: 'nb_conditioner_work_var_pop_fields';
  conditioner_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Var_Pop_Order_By = {
  conditioner_amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Conditioner_Work_Var_Samp_Fields = {
  __typename?: 'nb_conditioner_work_var_samp_fields';
  conditioner_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Var_Samp_Order_By = {
  conditioner_amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Conditioner_Work_Variance_Fields = {
  __typename?: 'nb_conditioner_work_variance_fields';
  conditioner_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_conditioner_work" */
export type Nb_Conditioner_Work_Variance_Order_By = {
  conditioner_amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link = {
  __typename?: 'nb_cplan_imsensing_link';
  cplan_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  im_sensing_id: Scalars['uuid'];
  /** An object relationship */
  nb_cultivation_plan: Nb_Cultivation_Plan;
  /** An object relationship */
  nb_raster_image_sensing: Nb_Raster_Image_Sensing;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_Aggregate = {
  __typename?: 'nb_cplan_imsensing_link_aggregate';
  aggregate?: Maybe<Nb_Cplan_Imsensing_Link_Aggregate_Fields>;
  nodes: Array<Nb_Cplan_Imsensing_Link>;
};

export type Nb_Cplan_Imsensing_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Cplan_Imsensing_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Cplan_Imsensing_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_Aggregate_Fields = {
  __typename?: 'nb_cplan_imsensing_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Cplan_Imsensing_Link_Max_Fields>;
  min?: Maybe<Nb_Cplan_Imsensing_Link_Min_Fields>;
};


/** aggregate fields of "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Cplan_Imsensing_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Cplan_Imsensing_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Cplan_Imsensing_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Cplan_Imsensing_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_cplan_imsensing_link". All fields are combined with a logical 'AND'. */
export type Nb_Cplan_Imsensing_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Bool_Exp>>;
  cplan_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  im_sensing_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_raster_image_sensing?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_cplan_imsensing_link" */
export enum Nb_Cplan_Imsensing_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbCplanImsensingLinkPkey = 'nb_cplan_imsensing_link_pkey'
}

/** input type for inserting data into table "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_Insert_Input = {
  cplan_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  im_sensing_id?: InputMaybe<Scalars['uuid']>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Obj_Rel_Insert_Input>;
  nb_raster_image_sensing?: InputMaybe<Nb_Raster_Image_Sensing_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Cplan_Imsensing_Link_Max_Fields = {
  __typename?: 'nb_cplan_imsensing_link_max_fields';
  cplan_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  im_sensing_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_Max_Order_By = {
  cplan_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  im_sensing_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Cplan_Imsensing_Link_Min_Fields = {
  __typename?: 'nb_cplan_imsensing_link_min_fields';
  cplan_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  im_sensing_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_Min_Order_By = {
  cplan_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  im_sensing_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_Mutation_Response = {
  __typename?: 'nb_cplan_imsensing_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Cplan_Imsensing_Link>;
};

/** on_conflict condition type for table "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_On_Conflict = {
  constraint: Nb_Cplan_Imsensing_Link_Constraint;
  update_columns?: Array<Nb_Cplan_Imsensing_Link_Update_Column>;
  where?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_cplan_imsensing_link". */
export type Nb_Cplan_Imsensing_Link_Order_By = {
  cplan_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  im_sensing_id?: InputMaybe<Order_By>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Order_By>;
  nb_raster_image_sensing?: InputMaybe<Nb_Raster_Image_Sensing_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_cplan_imsensing_link */
export type Nb_Cplan_Imsensing_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_cplan_imsensing_link" */
export enum Nb_Cplan_Imsensing_Link_Select_Column {
  /** column name */
  CplanId = 'cplan_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  ImSensingId = 'im_sensing_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_Set_Input = {
  cplan_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  im_sensing_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_cplan_imsensing_link" */
export type Nb_Cplan_Imsensing_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Cplan_Imsensing_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Cplan_Imsensing_Link_Stream_Cursor_Value_Input = {
  cplan_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  im_sensing_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_cplan_imsensing_link" */
export enum Nb_Cplan_Imsensing_Link_Update_Column {
  /** column name */
  CplanId = 'cplan_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  ImSensingId = 'im_sensing_id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Cplan_Imsensing_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Cplan_Imsensing_Link_Set_Input>;
  where: Nb_Cplan_Imsensing_Link_Bool_Exp;
};

/** columns and relationships of "nb_crop" */
export type Nb_Crop = {
  __typename?: 'nb_crop';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  nb_crop_company_links: Array<Nb_Crop_Company_Link>;
  /** An aggregate relationship */
  nb_crop_company_links_aggregate: Nb_Crop_Company_Link_Aggregate;
  /** An array relationship */
  nb_crop_method_links: Array<Nb_Crop_Method_Link>;
  /** An aggregate relationship */
  nb_crop_method_links_aggregate: Nb_Crop_Method_Link_Aggregate;
  /** An array relationship */
  nb_cultivar: Array<Nb_Cultivar>;
  /** An aggregate relationship */
  nb_cultivar_aggregate: Nb_Cultivar_Aggregate;
  /** An array relationship */
  nb_cultivation_plans: Array<Nb_Cultivation_Plan>;
  /** An aggregate relationship */
  nb_cultivation_plans_aggregate: Nb_Cultivation_Plan_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_crop" */
export type Nb_CropNb_Crop_Company_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_crop" */
export type Nb_CropNb_Crop_Company_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_crop" */
export type Nb_CropNb_Crop_Method_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Method_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Method_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
};


/** columns and relationships of "nb_crop" */
export type Nb_CropNb_Crop_Method_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Method_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Method_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
};


/** columns and relationships of "nb_crop" */
export type Nb_CropNb_CultivarArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivar_Order_By>>;
  where?: InputMaybe<Nb_Cultivar_Bool_Exp>;
};


/** columns and relationships of "nb_crop" */
export type Nb_CropNb_Cultivar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivar_Order_By>>;
  where?: InputMaybe<Nb_Cultivar_Bool_Exp>;
};


/** columns and relationships of "nb_crop" */
export type Nb_CropNb_Cultivation_PlansArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


/** columns and relationships of "nb_crop" */
export type Nb_CropNb_Cultivation_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};

/** aggregated selection of "nb_crop" */
export type Nb_Crop_Aggregate = {
  __typename?: 'nb_crop_aggregate';
  aggregate?: Maybe<Nb_Crop_Aggregate_Fields>;
  nodes: Array<Nb_Crop>;
};

/** aggregate fields of "nb_crop" */
export type Nb_Crop_Aggregate_Fields = {
  __typename?: 'nb_crop_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Crop_Max_Fields>;
  min?: Maybe<Nb_Crop_Min_Fields>;
};


/** aggregate fields of "nb_crop" */
export type Nb_Crop_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Crop_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_crop". All fields are combined with a logical 'AND'. */
export type Nb_Crop_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Crop_Bool_Exp>>;
  _not?: InputMaybe<Nb_Crop_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Crop_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_crop_company_links?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
  nb_crop_company_links_aggregate?: InputMaybe<Nb_Crop_Company_Link_Aggregate_Bool_Exp>;
  nb_crop_method_links?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
  nb_crop_method_links_aggregate?: InputMaybe<Nb_Crop_Method_Link_Aggregate_Bool_Exp>;
  nb_cultivar?: InputMaybe<Nb_Cultivar_Bool_Exp>;
  nb_cultivar_aggregate?: InputMaybe<Nb_Cultivar_Aggregate_Bool_Exp>;
  nb_cultivation_plans?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_cultivation_plans_aggregate?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "nb_crop_company_link" */
export type Nb_Crop_Company_Link = {
  __typename?: 'nb_crop_company_link';
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  crop_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An object relationship */
  nb_crop: Nb_Crop;
};

/** aggregated selection of "nb_crop_company_link" */
export type Nb_Crop_Company_Link_Aggregate = {
  __typename?: 'nb_crop_company_link_aggregate';
  aggregate?: Maybe<Nb_Crop_Company_Link_Aggregate_Fields>;
  nodes: Array<Nb_Crop_Company_Link>;
};

export type Nb_Crop_Company_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Crop_Company_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Crop_Company_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Crop_Company_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_crop_company_link" */
export type Nb_Crop_Company_Link_Aggregate_Fields = {
  __typename?: 'nb_crop_company_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Crop_Company_Link_Max_Fields>;
  min?: Maybe<Nb_Crop_Company_Link_Min_Fields>;
};


/** aggregate fields of "nb_crop_company_link" */
export type Nb_Crop_Company_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Crop_Company_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_crop_company_link" */
export type Nb_Crop_Company_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Crop_Company_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Crop_Company_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_crop_company_link" */
export type Nb_Crop_Company_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Crop_Company_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Crop_Company_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_crop_company_link". All fields are combined with a logical 'AND'. */
export type Nb_Crop_Company_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Crop_Company_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Crop_Company_Link_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  crop_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_crop?: InputMaybe<Nb_Crop_Bool_Exp>;
};

/** unique or primary key constraints on table "nb_crop_company_link" */
export enum Nb_Crop_Company_Link_Constraint {
  /** unique or primary key constraint on columns "crop_id", "company_id" */
  NbCropCompanyLinkCompanyIdCropIdKey = 'nb_crop_company_link_company_id_crop_id_key',
  /** unique or primary key constraint on columns "id" */
  NbCropCompanyLinkPkey = 'nb_crop_company_link_pkey'
}

/** input type for inserting data into table "nb_crop_company_link" */
export type Nb_Crop_Company_Link_Insert_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_crop?: InputMaybe<Nb_Crop_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Nb_Crop_Company_Link_Max_Fields = {
  __typename?: 'nb_crop_company_link_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  crop_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_crop_company_link" */
export type Nb_Crop_Company_Link_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Crop_Company_Link_Min_Fields = {
  __typename?: 'nb_crop_company_link_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  crop_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_crop_company_link" */
export type Nb_Crop_Company_Link_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_crop_company_link" */
export type Nb_Crop_Company_Link_Mutation_Response = {
  __typename?: 'nb_crop_company_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Crop_Company_Link>;
};

/** on_conflict condition type for table "nb_crop_company_link" */
export type Nb_Crop_Company_Link_On_Conflict = {
  constraint: Nb_Crop_Company_Link_Constraint;
  update_columns?: Array<Nb_Crop_Company_Link_Update_Column>;
  where?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_crop_company_link". */
export type Nb_Crop_Company_Link_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_crop?: InputMaybe<Nb_Crop_Order_By>;
};

/** primary key columns input for table: nb_crop_company_link */
export type Nb_Crop_Company_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_crop_company_link" */
export enum Nb_Crop_Company_Link_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "nb_crop_company_link" */
export type Nb_Crop_Company_Link_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "nb_crop_company_link" */
export type Nb_Crop_Company_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Crop_Company_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Crop_Company_Link_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nb_crop_company_link" */
export enum Nb_Crop_Company_Link_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  Id = 'id'
}

export type Nb_Crop_Company_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Crop_Company_Link_Set_Input>;
  where: Nb_Crop_Company_Link_Bool_Exp;
};

/** unique or primary key constraints on table "nb_crop" */
export enum Nb_Crop_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbCropKeyKey = 'nb_crop_key_key',
  /** unique or primary key constraint on columns "name" */
  NbCropNameKey = 'nb_crop_name_key',
  /** unique or primary key constraint on columns "id" */
  NbCropPkey = 'nb_crop_pkey'
}

/** input type for inserting data into table "nb_crop" */
export type Nb_Crop_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nb_crop_company_links?: InputMaybe<Nb_Crop_Company_Link_Arr_Rel_Insert_Input>;
  nb_crop_method_links?: InputMaybe<Nb_Crop_Method_Link_Arr_Rel_Insert_Input>;
  nb_cultivar?: InputMaybe<Nb_Cultivar_Arr_Rel_Insert_Input>;
  nb_cultivation_plans?: InputMaybe<Nb_Cultivation_Plan_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Crop_Max_Fields = {
  __typename?: 'nb_crop_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "nb_crop_method_link" */
export type Nb_Crop_Method_Link = {
  __typename?: 'nb_crop_method_link';
  created: Scalars['timestamptz'];
  crop_id: Scalars['uuid'];
  id: Scalars['uuid'];
  method_id: Scalars['uuid'];
  /** An object relationship */
  nb_crop: Nb_Crop;
  /** An object relationship */
  nb_cultivation_method: Nb_Cultivation_Method;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_crop_method_link" */
export type Nb_Crop_Method_Link_Aggregate = {
  __typename?: 'nb_crop_method_link_aggregate';
  aggregate?: Maybe<Nb_Crop_Method_Link_Aggregate_Fields>;
  nodes: Array<Nb_Crop_Method_Link>;
};

export type Nb_Crop_Method_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Crop_Method_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Crop_Method_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Crop_Method_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_crop_method_link" */
export type Nb_Crop_Method_Link_Aggregate_Fields = {
  __typename?: 'nb_crop_method_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Crop_Method_Link_Max_Fields>;
  min?: Maybe<Nb_Crop_Method_Link_Min_Fields>;
};


/** aggregate fields of "nb_crop_method_link" */
export type Nb_Crop_Method_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Crop_Method_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_crop_method_link" */
export type Nb_Crop_Method_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Crop_Method_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Crop_Method_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_crop_method_link" */
export type Nb_Crop_Method_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Crop_Method_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Crop_Method_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_crop_method_link". All fields are combined with a logical 'AND'. */
export type Nb_Crop_Method_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Crop_Method_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Crop_Method_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  crop_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  method_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_crop?: InputMaybe<Nb_Crop_Bool_Exp>;
  nb_cultivation_method?: InputMaybe<Nb_Cultivation_Method_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_crop_method_link" */
export enum Nb_Crop_Method_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbCropMethodLinkPkey = 'nb_crop_method_link_pkey'
}

/** input type for inserting data into table "nb_crop_method_link" */
export type Nb_Crop_Method_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  method_id?: InputMaybe<Scalars['uuid']>;
  nb_crop?: InputMaybe<Nb_Crop_Obj_Rel_Insert_Input>;
  nb_cultivation_method?: InputMaybe<Nb_Cultivation_Method_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Crop_Method_Link_Max_Fields = {
  __typename?: 'nb_crop_method_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  crop_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  method_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_crop_method_link" */
export type Nb_Crop_Method_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  method_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Crop_Method_Link_Min_Fields = {
  __typename?: 'nb_crop_method_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  crop_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  method_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_crop_method_link" */
export type Nb_Crop_Method_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  method_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_crop_method_link" */
export type Nb_Crop_Method_Link_Mutation_Response = {
  __typename?: 'nb_crop_method_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Crop_Method_Link>;
};

/** on_conflict condition type for table "nb_crop_method_link" */
export type Nb_Crop_Method_Link_On_Conflict = {
  constraint: Nb_Crop_Method_Link_Constraint;
  update_columns?: Array<Nb_Crop_Method_Link_Update_Column>;
  where?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_crop_method_link". */
export type Nb_Crop_Method_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  method_id?: InputMaybe<Order_By>;
  nb_crop?: InputMaybe<Nb_Crop_Order_By>;
  nb_cultivation_method?: InputMaybe<Nb_Cultivation_Method_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_crop_method_link */
export type Nb_Crop_Method_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_crop_method_link" */
export enum Nb_Crop_Method_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  Id = 'id',
  /** column name */
  MethodId = 'method_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_crop_method_link" */
export type Nb_Crop_Method_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  method_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_crop_method_link" */
export type Nb_Crop_Method_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Crop_Method_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Crop_Method_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  method_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_crop_method_link" */
export enum Nb_Crop_Method_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  Id = 'id',
  /** column name */
  MethodId = 'method_id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Crop_Method_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Crop_Method_Link_Set_Input>;
  where: Nb_Crop_Method_Link_Bool_Exp;
};

/** aggregate min on columns */
export type Nb_Crop_Min_Fields = {
  __typename?: 'nb_crop_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_crop" */
export type Nb_Crop_Mutation_Response = {
  __typename?: 'nb_crop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Crop>;
};

/** input type for inserting object relation for remote table "nb_crop" */
export type Nb_Crop_Obj_Rel_Insert_Input = {
  data: Nb_Crop_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Crop_On_Conflict>;
};

/** on_conflict condition type for table "nb_crop" */
export type Nb_Crop_On_Conflict = {
  constraint: Nb_Crop_Constraint;
  update_columns?: Array<Nb_Crop_Update_Column>;
  where?: InputMaybe<Nb_Crop_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_crop". */
export type Nb_Crop_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_crop_company_links_aggregate?: InputMaybe<Nb_Crop_Company_Link_Aggregate_Order_By>;
  nb_crop_method_links_aggregate?: InputMaybe<Nb_Crop_Method_Link_Aggregate_Order_By>;
  nb_cultivar_aggregate?: InputMaybe<Nb_Cultivar_Aggregate_Order_By>;
  nb_cultivation_plans_aggregate?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_crop */
export type Nb_Crop_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_crop" */
export enum Nb_Crop_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_crop" */
export type Nb_Crop_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_crop" */
export type Nb_Crop_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Crop_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Crop_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_crop" */
export enum Nb_Crop_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

export type Nb_Crop_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Crop_Set_Input>;
  where: Nb_Crop_Bool_Exp;
};

/** columns and relationships of "nb_cultivar" */
export type Nb_Cultivar = {
  __typename?: 'nb_cultivar';
  created: Scalars['timestamptz'];
  crop_id: Scalars['uuid'];
  detail_info?: Maybe<Scalars['json']>;
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An object relationship */
  nb_crop: Nb_Crop;
  /** An array relationship */
  nb_cultivation_plans: Array<Nb_Cultivation_Plan>;
  /** An aggregate relationship */
  nb_cultivation_plans_aggregate: Nb_Cultivation_Plan_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_cultivar" */
export type Nb_CultivarDetail_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "nb_cultivar" */
export type Nb_CultivarNb_Cultivation_PlansArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


/** columns and relationships of "nb_cultivar" */
export type Nb_CultivarNb_Cultivation_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};

/** aggregated selection of "nb_cultivar" */
export type Nb_Cultivar_Aggregate = {
  __typename?: 'nb_cultivar_aggregate';
  aggregate?: Maybe<Nb_Cultivar_Aggregate_Fields>;
  nodes: Array<Nb_Cultivar>;
};

export type Nb_Cultivar_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Cultivar_Aggregate_Bool_Exp_Count>;
};

export type Nb_Cultivar_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Cultivar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Cultivar_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_cultivar" */
export type Nb_Cultivar_Aggregate_Fields = {
  __typename?: 'nb_cultivar_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Cultivar_Max_Fields>;
  min?: Maybe<Nb_Cultivar_Min_Fields>;
};


/** aggregate fields of "nb_cultivar" */
export type Nb_Cultivar_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Cultivar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_cultivar" */
export type Nb_Cultivar_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Cultivar_Max_Order_By>;
  min?: InputMaybe<Nb_Cultivar_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_cultivar" */
export type Nb_Cultivar_Arr_Rel_Insert_Input = {
  data: Array<Nb_Cultivar_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Cultivar_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_cultivar". All fields are combined with a logical 'AND'. */
export type Nb_Cultivar_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Cultivar_Bool_Exp>>;
  _not?: InputMaybe<Nb_Cultivar_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Cultivar_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  crop_id?: InputMaybe<Uuid_Comparison_Exp>;
  detail_info?: InputMaybe<Json_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_crop?: InputMaybe<Nb_Crop_Bool_Exp>;
  nb_cultivation_plans?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_cultivation_plans_aggregate?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_cultivar" */
export enum Nb_Cultivar_Constraint {
  /** unique or primary key constraint on columns "key", "crop_id" */
  NbCultivarCropIdKeyKey = 'nb_cultivar_crop_id_key_key',
  /** unique or primary key constraint on columns "name", "crop_id" */
  NbCultivarCropIdNameKey = 'nb_cultivar_crop_id_name_key',
  /** unique or primary key constraint on columns "id" */
  NbCultivarPkey = 'nb_cultivar_pkey'
}

/** input type for inserting data into table "nb_cultivar" */
export type Nb_Cultivar_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  detail_info?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nb_crop?: InputMaybe<Nb_Crop_Obj_Rel_Insert_Input>;
  nb_cultivation_plans?: InputMaybe<Nb_Cultivation_Plan_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Cultivar_Max_Fields = {
  __typename?: 'nb_cultivar_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  crop_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_cultivar" */
export type Nb_Cultivar_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Cultivar_Min_Fields = {
  __typename?: 'nb_cultivar_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  crop_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_cultivar" */
export type Nb_Cultivar_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_cultivar" */
export type Nb_Cultivar_Mutation_Response = {
  __typename?: 'nb_cultivar_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Cultivar>;
};

/** input type for inserting object relation for remote table "nb_cultivar" */
export type Nb_Cultivar_Obj_Rel_Insert_Input = {
  data: Nb_Cultivar_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Cultivar_On_Conflict>;
};

/** on_conflict condition type for table "nb_cultivar" */
export type Nb_Cultivar_On_Conflict = {
  constraint: Nb_Cultivar_Constraint;
  update_columns?: Array<Nb_Cultivar_Update_Column>;
  where?: InputMaybe<Nb_Cultivar_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_cultivar". */
export type Nb_Cultivar_Order_By = {
  created?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  detail_info?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_crop?: InputMaybe<Nb_Crop_Order_By>;
  nb_cultivation_plans_aggregate?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_cultivar */
export type Nb_Cultivar_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_cultivar" */
export enum Nb_Cultivar_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  DetailInfo = 'detail_info',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_cultivar" */
export type Nb_Cultivar_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  detail_info?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_cultivar" */
export type Nb_Cultivar_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Cultivar_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Cultivar_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  detail_info?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_cultivar" */
export enum Nb_Cultivar_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  DetailInfo = 'detail_info',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

export type Nb_Cultivar_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Cultivar_Set_Input>;
  where: Nb_Cultivar_Bool_Exp;
};

/** columns and relationships of "nb_cultivation_method" */
export type Nb_Cultivation_Method = {
  __typename?: 'nb_cultivation_method';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_transplant: Scalars['Boolean'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  nb_crop_method_links: Array<Nb_Crop_Method_Link>;
  /** An aggregate relationship */
  nb_crop_method_links_aggregate: Nb_Crop_Method_Link_Aggregate;
  /** An array relationship */
  nb_cultivation_plans: Array<Nb_Cultivation_Plan>;
  /** An aggregate relationship */
  nb_cultivation_plans_aggregate: Nb_Cultivation_Plan_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_cultivation_method" */
export type Nb_Cultivation_MethodNb_Crop_Method_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Method_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Method_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_method" */
export type Nb_Cultivation_MethodNb_Crop_Method_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Method_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Method_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_method" */
export type Nb_Cultivation_MethodNb_Cultivation_PlansArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_method" */
export type Nb_Cultivation_MethodNb_Cultivation_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};

/** aggregated selection of "nb_cultivation_method" */
export type Nb_Cultivation_Method_Aggregate = {
  __typename?: 'nb_cultivation_method_aggregate';
  aggregate?: Maybe<Nb_Cultivation_Method_Aggregate_Fields>;
  nodes: Array<Nb_Cultivation_Method>;
};

/** aggregate fields of "nb_cultivation_method" */
export type Nb_Cultivation_Method_Aggregate_Fields = {
  __typename?: 'nb_cultivation_method_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Cultivation_Method_Max_Fields>;
  min?: Maybe<Nb_Cultivation_Method_Min_Fields>;
};


/** aggregate fields of "nb_cultivation_method" */
export type Nb_Cultivation_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Cultivation_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_cultivation_method". All fields are combined with a logical 'AND'. */
export type Nb_Cultivation_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Cultivation_Method_Bool_Exp>>;
  _not?: InputMaybe<Nb_Cultivation_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Cultivation_Method_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_transplant?: InputMaybe<Boolean_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_crop_method_links?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
  nb_crop_method_links_aggregate?: InputMaybe<Nb_Crop_Method_Link_Aggregate_Bool_Exp>;
  nb_cultivation_plans?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_cultivation_plans_aggregate?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_cultivation_method" */
export enum Nb_Cultivation_Method_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbCultivationMethodKeyKey = 'nb_cultivation_method_key_key',
  /** unique or primary key constraint on columns "name" */
  NbCultivationMethodNameKey = 'nb_cultivation_method_name_key',
  /** unique or primary key constraint on columns "id" */
  NbCultivationMethodPkey = 'nb_cultivation_method_pkey'
}

/** input type for inserting data into table "nb_cultivation_method" */
export type Nb_Cultivation_Method_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_transplant?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nb_crop_method_links?: InputMaybe<Nb_Crop_Method_Link_Arr_Rel_Insert_Input>;
  nb_cultivation_plans?: InputMaybe<Nb_Cultivation_Plan_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Cultivation_Method_Max_Fields = {
  __typename?: 'nb_cultivation_method_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Cultivation_Method_Min_Fields = {
  __typename?: 'nb_cultivation_method_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_cultivation_method" */
export type Nb_Cultivation_Method_Mutation_Response = {
  __typename?: 'nb_cultivation_method_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Cultivation_Method>;
};

/** input type for inserting object relation for remote table "nb_cultivation_method" */
export type Nb_Cultivation_Method_Obj_Rel_Insert_Input = {
  data: Nb_Cultivation_Method_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Cultivation_Method_On_Conflict>;
};

/** on_conflict condition type for table "nb_cultivation_method" */
export type Nb_Cultivation_Method_On_Conflict = {
  constraint: Nb_Cultivation_Method_Constraint;
  update_columns?: Array<Nb_Cultivation_Method_Update_Column>;
  where?: InputMaybe<Nb_Cultivation_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_cultivation_method". */
export type Nb_Cultivation_Method_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_transplant?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_crop_method_links_aggregate?: InputMaybe<Nb_Crop_Method_Link_Aggregate_Order_By>;
  nb_cultivation_plans_aggregate?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_cultivation_method */
export type Nb_Cultivation_Method_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_cultivation_method" */
export enum Nb_Cultivation_Method_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IsTransplant = 'is_transplant',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_cultivation_method" */
export type Nb_Cultivation_Method_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_transplant?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_cultivation_method" */
export type Nb_Cultivation_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Cultivation_Method_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Cultivation_Method_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_transplant?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_cultivation_method" */
export enum Nb_Cultivation_Method_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IsTransplant = 'is_transplant',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

export type Nb_Cultivation_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Cultivation_Method_Set_Input>;
  where: Nb_Cultivation_Method_Bool_Exp;
};

/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_Plan = {
  __typename?: 'nb_cultivation_plan';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  created: Scalars['timestamptz'];
  creator_name: Scalars['String'];
  crop_id: Scalars['uuid'];
  cultivar_id: Scalars['uuid'];
  heading_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  method: Scalars['String'];
  method_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** An array relationship */
  nb_actual_crop_yeilds: Array<Nb_Actual_Crop_Yeild>;
  /** An aggregate relationship */
  nb_actual_crop_yeilds_aggregate: Nb_Actual_Crop_Yeild_Aggregate;
  /** An array relationship */
  nb_conditioner_works: Array<Nb_Conditioner_Work>;
  /** An aggregate relationship */
  nb_conditioner_works_aggregate: Nb_Conditioner_Work_Aggregate;
  /** An array relationship */
  nb_cplan_imsensing_links: Array<Nb_Cplan_Imsensing_Link>;
  /** An aggregate relationship */
  nb_cplan_imsensing_links_aggregate: Nb_Cplan_Imsensing_Link_Aggregate;
  /** An object relationship */
  nb_crop: Nb_Crop;
  /** An object relationship */
  nb_cultivar: Nb_Cultivar;
  /** An object relationship */
  nb_cultivation_method?: Maybe<Nb_Cultivation_Method>;
  /** An array relationship */
  nb_fertilization_works: Array<Nb_Fertilization_Work>;
  /** An aggregate relationship */
  nb_fertilization_works_aggregate: Nb_Fertilization_Work_Aggregate;
  /** An array relationship */
  nb_field_picture_cplan_links: Array<Nb_Field_Picture_Cplan_Link>;
  /** An aggregate relationship */
  nb_field_picture_cplan_links_aggregate: Nb_Field_Picture_Cplan_Link_Aggregate;
  /** An array relationship */
  nb_finished_ufield_works: Array<Nb_Finished_Ufield_Work>;
  /** An aggregate relationship */
  nb_finished_ufield_works_aggregate: Nb_Finished_Ufield_Work_Aggregate;
  /** An array relationship */
  nb_performed_works: Array<Nb_Performed_Work>;
  /** An aggregate relationship */
  nb_performed_works_aggregate: Nb_Performed_Work_Aggregate;
  /** An array relationship */
  nb_pesticide_spray_works: Array<Nb_Pesticide_Spray_Work>;
  /** An aggregate relationship */
  nb_pesticide_spray_works_aggregate: Nb_Pesticide_Spray_Work_Aggregate;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  /** An array relationship */
  nb_water_gate_status_cplan_links: Array<Nb_Water_Gate_Status_Cplan_Link>;
  /** An aggregate relationship */
  nb_water_gate_status_cplan_links_aggregate: Nb_Water_Gate_Status_Cplan_Link_Aggregate;
  /** An array relationship */
  nbbe_microclimates: Array<Nbbe_Microclimate>;
  /** An aggregate relationship */
  nbbe_microclimates_aggregate: Nbbe_Microclimate_Aggregate;
  planting_density?: Maybe<Scalars['float8']>;
  reaping_at?: Maybe<Scalars['timestamptz']>;
  seeded_at?: Maybe<Scalars['timestamptz']>;
  transplanted_at?: Maybe<Scalars['timestamptz']>;
  updated: Scalars['timestamptz'];
  user_field_id: Scalars['uuid'];
  user_id?: Maybe<Scalars['uuid']>;
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Actual_Crop_YeildsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Order_By>>;
  where?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Actual_Crop_Yeilds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Order_By>>;
  where?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Conditioner_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Conditioner_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Cplan_Imsensing_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Order_By>>;
  where?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Cplan_Imsensing_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Order_By>>;
  where?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Fertilization_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Fertilization_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Field_Picture_Cplan_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Field_Picture_Cplan_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Finished_Ufield_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Finished_Ufield_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Performed_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Performed_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Pesticide_Spray_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Pesticide_Spray_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Water_Gate_Status_Cplan_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNb_Water_Gate_Status_Cplan_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNbbe_MicroclimatesArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Microclimate_Order_By>>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};


/** columns and relationships of "nb_cultivation_plan" */
export type Nb_Cultivation_PlanNbbe_Microclimates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Microclimate_Order_By>>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};

/** aggregated selection of "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Aggregate = {
  __typename?: 'nb_cultivation_plan_aggregate';
  aggregate?: Maybe<Nb_Cultivation_Plan_Aggregate_Fields>;
  nodes: Array<Nb_Cultivation_Plan>;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp_Var_Samp>;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp_Avg = {
  arguments: Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp_Corr = {
  arguments: Nb_Cultivation_Plan_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Nb_Cultivation_Plan_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp_Max = {
  arguments: Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp_Min = {
  arguments: Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp_Sum = {
  arguments: Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Cultivation_Plan_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Aggregate_Fields = {
  __typename?: 'nb_cultivation_plan_aggregate_fields';
  avg?: Maybe<Nb_Cultivation_Plan_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Cultivation_Plan_Max_Fields>;
  min?: Maybe<Nb_Cultivation_Plan_Min_Fields>;
  stddev?: Maybe<Nb_Cultivation_Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Cultivation_Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Cultivation_Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Cultivation_Plan_Sum_Fields>;
  var_pop?: Maybe<Nb_Cultivation_Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Cultivation_Plan_Var_Samp_Fields>;
  variance?: Maybe<Nb_Cultivation_Plan_Variance_Fields>;
};


/** aggregate fields of "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Cultivation_Plan_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Cultivation_Plan_Max_Order_By>;
  min?: InputMaybe<Nb_Cultivation_Plan_Min_Order_By>;
  stddev?: InputMaybe<Nb_Cultivation_Plan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Cultivation_Plan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Cultivation_Plan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Cultivation_Plan_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Cultivation_Plan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Cultivation_Plan_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Cultivation_Plan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Arr_Rel_Insert_Input = {
  data: Array<Nb_Cultivation_Plan_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Cultivation_Plan_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Cultivation_Plan_Avg_Fields = {
  __typename?: 'nb_cultivation_plan_avg_fields';
  planting_density?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Avg_Order_By = {
  planting_density?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_cultivation_plan". All fields are combined with a logical 'AND'. */
export type Nb_Cultivation_Plan_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Cultivation_Plan_Bool_Exp>>;
  _not?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Cultivation_Plan_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_name?: InputMaybe<String_Comparison_Exp>;
  crop_id?: InputMaybe<Uuid_Comparison_Exp>;
  cultivar_id?: InputMaybe<Uuid_Comparison_Exp>;
  heading_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  method?: InputMaybe<String_Comparison_Exp>;
  method_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_actual_crop_yeilds?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
  nb_actual_crop_yeilds_aggregate?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Bool_Exp>;
  nb_conditioner_works?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
  nb_conditioner_works_aggregate?: InputMaybe<Nb_Conditioner_Work_Aggregate_Bool_Exp>;
  nb_cplan_imsensing_links?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
  nb_cplan_imsensing_links_aggregate?: InputMaybe<Nb_Cplan_Imsensing_Link_Aggregate_Bool_Exp>;
  nb_crop?: InputMaybe<Nb_Crop_Bool_Exp>;
  nb_cultivar?: InputMaybe<Nb_Cultivar_Bool_Exp>;
  nb_cultivation_method?: InputMaybe<Nb_Cultivation_Method_Bool_Exp>;
  nb_fertilization_works?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
  nb_fertilization_works_aggregate?: InputMaybe<Nb_Fertilization_Work_Aggregate_Bool_Exp>;
  nb_field_picture_cplan_links?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
  nb_field_picture_cplan_links_aggregate?: InputMaybe<Nb_Field_Picture_Cplan_Link_Aggregate_Bool_Exp>;
  nb_finished_ufield_works?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
  nb_finished_ufield_works_aggregate?: InputMaybe<Nb_Finished_Ufield_Work_Aggregate_Bool_Exp>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Bool_Exp>;
  nb_pesticide_spray_works?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
  nb_pesticide_spray_works_aggregate?: InputMaybe<Nb_Pesticide_Spray_Work_Aggregate_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nb_water_gate_status_cplan_links?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
  nb_water_gate_status_cplan_links_aggregate?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Aggregate_Bool_Exp>;
  nbbe_microclimates?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
  nbbe_microclimates_aggregate?: InputMaybe<Nbbe_Microclimate_Aggregate_Bool_Exp>;
  planting_density?: InputMaybe<Float8_Comparison_Exp>;
  reaping_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  seeded_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  transplanted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_cultivation_plan" */
export enum Nb_Cultivation_Plan_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbCultivationPlanPkey = 'nb_cultivation_plan_pkey'
}

/** input type for incrementing numeric columns in table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Inc_Input = {
  planting_density?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  cultivar_id?: InputMaybe<Scalars['uuid']>;
  heading_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  method?: InputMaybe<Scalars['String']>;
  method_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nb_actual_crop_yeilds?: InputMaybe<Nb_Actual_Crop_Yeild_Arr_Rel_Insert_Input>;
  nb_conditioner_works?: InputMaybe<Nb_Conditioner_Work_Arr_Rel_Insert_Input>;
  nb_cplan_imsensing_links?: InputMaybe<Nb_Cplan_Imsensing_Link_Arr_Rel_Insert_Input>;
  nb_crop?: InputMaybe<Nb_Crop_Obj_Rel_Insert_Input>;
  nb_cultivar?: InputMaybe<Nb_Cultivar_Obj_Rel_Insert_Input>;
  nb_cultivation_method?: InputMaybe<Nb_Cultivation_Method_Obj_Rel_Insert_Input>;
  nb_fertilization_works?: InputMaybe<Nb_Fertilization_Work_Arr_Rel_Insert_Input>;
  nb_field_picture_cplan_links?: InputMaybe<Nb_Field_Picture_Cplan_Link_Arr_Rel_Insert_Input>;
  nb_finished_ufield_works?: InputMaybe<Nb_Finished_Ufield_Work_Arr_Rel_Insert_Input>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Arr_Rel_Insert_Input>;
  nb_pesticide_spray_works?: InputMaybe<Nb_Pesticide_Spray_Work_Arr_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  nb_water_gate_status_cplan_links?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Arr_Rel_Insert_Input>;
  nbbe_microclimates?: InputMaybe<Nbbe_Microclimate_Arr_Rel_Insert_Input>;
  planting_density?: InputMaybe<Scalars['float8']>;
  reaping_at?: InputMaybe<Scalars['timestamptz']>;
  seeded_at?: InputMaybe<Scalars['timestamptz']>;
  transplanted_at?: InputMaybe<Scalars['timestamptz']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Cultivation_Plan_Max_Fields = {
  __typename?: 'nb_cultivation_plan_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  crop_id?: Maybe<Scalars['uuid']>;
  cultivar_id?: Maybe<Scalars['uuid']>;
  heading_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  method?: Maybe<Scalars['String']>;
  method_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  planting_density?: Maybe<Scalars['float8']>;
  reaping_at?: Maybe<Scalars['timestamptz']>;
  seeded_at?: Maybe<Scalars['timestamptz']>;
  transplanted_at?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  cultivar_id?: InputMaybe<Order_By>;
  heading_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  method_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  planting_density?: InputMaybe<Order_By>;
  reaping_at?: InputMaybe<Order_By>;
  seeded_at?: InputMaybe<Order_By>;
  transplanted_at?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Cultivation_Plan_Min_Fields = {
  __typename?: 'nb_cultivation_plan_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  crop_id?: Maybe<Scalars['uuid']>;
  cultivar_id?: Maybe<Scalars['uuid']>;
  heading_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  method?: Maybe<Scalars['String']>;
  method_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  planting_density?: Maybe<Scalars['float8']>;
  reaping_at?: Maybe<Scalars['timestamptz']>;
  seeded_at?: Maybe<Scalars['timestamptz']>;
  transplanted_at?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  cultivar_id?: InputMaybe<Order_By>;
  heading_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  method_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  planting_density?: InputMaybe<Order_By>;
  reaping_at?: InputMaybe<Order_By>;
  seeded_at?: InputMaybe<Order_By>;
  transplanted_at?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Mutation_Response = {
  __typename?: 'nb_cultivation_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Cultivation_Plan>;
};

/** input type for inserting object relation for remote table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Obj_Rel_Insert_Input = {
  data: Nb_Cultivation_Plan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Cultivation_Plan_On_Conflict>;
};

/** on_conflict condition type for table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_On_Conflict = {
  constraint: Nb_Cultivation_Plan_Constraint;
  update_columns?: Array<Nb_Cultivation_Plan_Update_Column>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_cultivation_plan". */
export type Nb_Cultivation_Plan_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  cultivar_id?: InputMaybe<Order_By>;
  heading_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  method_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_actual_crop_yeilds_aggregate?: InputMaybe<Nb_Actual_Crop_Yeild_Aggregate_Order_By>;
  nb_conditioner_works_aggregate?: InputMaybe<Nb_Conditioner_Work_Aggregate_Order_By>;
  nb_cplan_imsensing_links_aggregate?: InputMaybe<Nb_Cplan_Imsensing_Link_Aggregate_Order_By>;
  nb_crop?: InputMaybe<Nb_Crop_Order_By>;
  nb_cultivar?: InputMaybe<Nb_Cultivar_Order_By>;
  nb_cultivation_method?: InputMaybe<Nb_Cultivation_Method_Order_By>;
  nb_fertilization_works_aggregate?: InputMaybe<Nb_Fertilization_Work_Aggregate_Order_By>;
  nb_field_picture_cplan_links_aggregate?: InputMaybe<Nb_Field_Picture_Cplan_Link_Aggregate_Order_By>;
  nb_finished_ufield_works_aggregate?: InputMaybe<Nb_Finished_Ufield_Work_Aggregate_Order_By>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Order_By>;
  nb_pesticide_spray_works_aggregate?: InputMaybe<Nb_Pesticide_Spray_Work_Aggregate_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nb_water_gate_status_cplan_links_aggregate?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Aggregate_Order_By>;
  nbbe_microclimates_aggregate?: InputMaybe<Nbbe_Microclimate_Aggregate_Order_By>;
  planting_density?: InputMaybe<Order_By>;
  reaping_at?: InputMaybe<Order_By>;
  seeded_at?: InputMaybe<Order_By>;
  transplanted_at?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_cultivation_plan */
export type Nb_Cultivation_Plan_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_cultivation_plan" */
export enum Nb_Cultivation_Plan_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  CultivarId = 'cultivar_id',
  /** column name */
  HeadingAt = 'heading_at',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  MethodId = 'method_id',
  /** column name */
  Name = 'name',
  /** column name */
  PlantingDensity = 'planting_density',
  /** column name */
  ReapingAt = 'reaping_at',
  /** column name */
  SeededAt = 'seeded_at',
  /** column name */
  TransplantedAt = 'transplanted_at',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** select "nb_cultivation_plan_aggregate_bool_exp_avg_arguments_columns" columns of table "nb_cultivation_plan" */
export enum Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  PlantingDensity = 'planting_density'
}

/** select "nb_cultivation_plan_aggregate_bool_exp_corr_arguments_columns" columns of table "nb_cultivation_plan" */
export enum Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  PlantingDensity = 'planting_density'
}

/** select "nb_cultivation_plan_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "nb_cultivation_plan" */
export enum Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  PlantingDensity = 'planting_density'
}

/** select "nb_cultivation_plan_aggregate_bool_exp_max_arguments_columns" columns of table "nb_cultivation_plan" */
export enum Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  PlantingDensity = 'planting_density'
}

/** select "nb_cultivation_plan_aggregate_bool_exp_min_arguments_columns" columns of table "nb_cultivation_plan" */
export enum Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  PlantingDensity = 'planting_density'
}

/** select "nb_cultivation_plan_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "nb_cultivation_plan" */
export enum Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  PlantingDensity = 'planting_density'
}

/** select "nb_cultivation_plan_aggregate_bool_exp_sum_arguments_columns" columns of table "nb_cultivation_plan" */
export enum Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  PlantingDensity = 'planting_density'
}

/** select "nb_cultivation_plan_aggregate_bool_exp_var_samp_arguments_columns" columns of table "nb_cultivation_plan" */
export enum Nb_Cultivation_Plan_Select_Column_Nb_Cultivation_Plan_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  PlantingDensity = 'planting_density'
}

/** input type for updating data in table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  cultivar_id?: InputMaybe<Scalars['uuid']>;
  heading_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  method?: InputMaybe<Scalars['String']>;
  method_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  planting_density?: InputMaybe<Scalars['float8']>;
  reaping_at?: InputMaybe<Scalars['timestamptz']>;
  seeded_at?: InputMaybe<Scalars['timestamptz']>;
  transplanted_at?: InputMaybe<Scalars['timestamptz']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nb_Cultivation_Plan_Stddev_Fields = {
  __typename?: 'nb_cultivation_plan_stddev_fields';
  planting_density?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Stddev_Order_By = {
  planting_density?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Cultivation_Plan_Stddev_Pop_Fields = {
  __typename?: 'nb_cultivation_plan_stddev_pop_fields';
  planting_density?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Stddev_Pop_Order_By = {
  planting_density?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Cultivation_Plan_Stddev_Samp_Fields = {
  __typename?: 'nb_cultivation_plan_stddev_samp_fields';
  planting_density?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Stddev_Samp_Order_By = {
  planting_density?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Cultivation_Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Cultivation_Plan_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  crop_id?: InputMaybe<Scalars['uuid']>;
  cultivar_id?: InputMaybe<Scalars['uuid']>;
  heading_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  method?: InputMaybe<Scalars['String']>;
  method_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  planting_density?: InputMaybe<Scalars['float8']>;
  reaping_at?: InputMaybe<Scalars['timestamptz']>;
  seeded_at?: InputMaybe<Scalars['timestamptz']>;
  transplanted_at?: InputMaybe<Scalars['timestamptz']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Cultivation_Plan_Sum_Fields = {
  __typename?: 'nb_cultivation_plan_sum_fields';
  planting_density?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Sum_Order_By = {
  planting_density?: InputMaybe<Order_By>;
};

/** update columns of table "nb_cultivation_plan" */
export enum Nb_Cultivation_Plan_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  CultivarId = 'cultivar_id',
  /** column name */
  HeadingAt = 'heading_at',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  MethodId = 'method_id',
  /** column name */
  Name = 'name',
  /** column name */
  PlantingDensity = 'planting_density',
  /** column name */
  ReapingAt = 'reaping_at',
  /** column name */
  SeededAt = 'seeded_at',
  /** column name */
  TransplantedAt = 'transplanted_at',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Cultivation_Plan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Cultivation_Plan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Cultivation_Plan_Set_Input>;
  where: Nb_Cultivation_Plan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Cultivation_Plan_Var_Pop_Fields = {
  __typename?: 'nb_cultivation_plan_var_pop_fields';
  planting_density?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Var_Pop_Order_By = {
  planting_density?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Cultivation_Plan_Var_Samp_Fields = {
  __typename?: 'nb_cultivation_plan_var_samp_fields';
  planting_density?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Var_Samp_Order_By = {
  planting_density?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Cultivation_Plan_Variance_Fields = {
  __typename?: 'nb_cultivation_plan_variance_fields';
  planting_density?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_cultivation_plan" */
export type Nb_Cultivation_Plan_Variance_Order_By = {
  planting_density?: InputMaybe<Order_By>;
};

/** 作業タスク種類（デモ用テーブル） */
export type Nb_Demo_Task_Type = {
  __typename?: 'nb_demo_task_type';
  /** An array relationship */
  nb_demo_user_locations: Array<Nb_Demo_User_Location>;
  /** An aggregate relationship */
  nb_demo_user_locations_aggregate: Nb_Demo_User_Location_Aggregate;
  type: Scalars['String'];
};


/** 作業タスク種類（デモ用テーブル） */
export type Nb_Demo_Task_TypeNb_Demo_User_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Location_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
};


/** 作業タスク種類（デモ用テーブル） */
export type Nb_Demo_Task_TypeNb_Demo_User_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Location_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
};

/** aggregated selection of "nb_demo_task_type" */
export type Nb_Demo_Task_Type_Aggregate = {
  __typename?: 'nb_demo_task_type_aggregate';
  aggregate?: Maybe<Nb_Demo_Task_Type_Aggregate_Fields>;
  nodes: Array<Nb_Demo_Task_Type>;
};

/** aggregate fields of "nb_demo_task_type" */
export type Nb_Demo_Task_Type_Aggregate_Fields = {
  __typename?: 'nb_demo_task_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Demo_Task_Type_Max_Fields>;
  min?: Maybe<Nb_Demo_Task_Type_Min_Fields>;
};


/** aggregate fields of "nb_demo_task_type" */
export type Nb_Demo_Task_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Demo_Task_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_demo_task_type". All fields are combined with a logical 'AND'. */
export type Nb_Demo_Task_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Demo_Task_Type_Bool_Exp>>;
  _not?: InputMaybe<Nb_Demo_Task_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Demo_Task_Type_Bool_Exp>>;
  nb_demo_user_locations?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
  nb_demo_user_locations_aggregate?: InputMaybe<Nb_Demo_User_Location_Aggregate_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_demo_task_type" */
export enum Nb_Demo_Task_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  NbDemoTaskTypePkey = 'nb_demo_task_type_pkey'
}

/** input type for inserting data into table "nb_demo_task_type" */
export type Nb_Demo_Task_Type_Insert_Input = {
  nb_demo_user_locations?: InputMaybe<Nb_Demo_User_Location_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nb_Demo_Task_Type_Max_Fields = {
  __typename?: 'nb_demo_task_type_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nb_Demo_Task_Type_Min_Fields = {
  __typename?: 'nb_demo_task_type_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nb_demo_task_type" */
export type Nb_Demo_Task_Type_Mutation_Response = {
  __typename?: 'nb_demo_task_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Demo_Task_Type>;
};

/** input type for inserting object relation for remote table "nb_demo_task_type" */
export type Nb_Demo_Task_Type_Obj_Rel_Insert_Input = {
  data: Nb_Demo_Task_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Demo_Task_Type_On_Conflict>;
};

/** on_conflict condition type for table "nb_demo_task_type" */
export type Nb_Demo_Task_Type_On_Conflict = {
  constraint: Nb_Demo_Task_Type_Constraint;
  update_columns?: Array<Nb_Demo_Task_Type_Update_Column>;
  where?: InputMaybe<Nb_Demo_Task_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_demo_task_type". */
export type Nb_Demo_Task_Type_Order_By = {
  nb_demo_user_locations_aggregate?: InputMaybe<Nb_Demo_User_Location_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_demo_task_type */
export type Nb_Demo_Task_Type_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "nb_demo_task_type" */
export enum Nb_Demo_Task_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "nb_demo_task_type" */
export type Nb_Demo_Task_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nb_demo_task_type" */
export type Nb_Demo_Task_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Demo_Task_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Demo_Task_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nb_demo_task_type" */
export enum Nb_Demo_Task_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type Nb_Demo_Task_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Demo_Task_Type_Set_Input>;
  where: Nb_Demo_Task_Type_Bool_Exp;
};

/** 動態履歴（デモ用） */
export type Nb_Demo_User_Location = {
  __typename?: 'nb_demo_user_location';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  loc_time: Scalars['timestamptz'];
  location: Scalars['geometry'];
  /** An object relationship */
  nb_demo_task_type: Nb_Demo_Task_Type;
  /** An object relationship */
  nb_demo_user_name: Nb_Demo_User_Name;
  task_type: Scalars['String'];
  user_name: Scalars['String'];
  zoom_level: Scalars['Int'];
};

/** aggregated selection of "nb_demo_user_location" */
export type Nb_Demo_User_Location_Aggregate = {
  __typename?: 'nb_demo_user_location_aggregate';
  aggregate?: Maybe<Nb_Demo_User_Location_Aggregate_Fields>;
  nodes: Array<Nb_Demo_User_Location>;
};

export type Nb_Demo_User_Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Demo_User_Location_Aggregate_Bool_Exp_Count>;
};

export type Nb_Demo_User_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Demo_User_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_demo_user_location" */
export type Nb_Demo_User_Location_Aggregate_Fields = {
  __typename?: 'nb_demo_user_location_aggregate_fields';
  avg?: Maybe<Nb_Demo_User_Location_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Demo_User_Location_Max_Fields>;
  min?: Maybe<Nb_Demo_User_Location_Min_Fields>;
  stddev?: Maybe<Nb_Demo_User_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Demo_User_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Demo_User_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Demo_User_Location_Sum_Fields>;
  var_pop?: Maybe<Nb_Demo_User_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Demo_User_Location_Var_Samp_Fields>;
  variance?: Maybe<Nb_Demo_User_Location_Variance_Fields>;
};


/** aggregate fields of "nb_demo_user_location" */
export type Nb_Demo_User_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Demo_User_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Demo_User_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Demo_User_Location_Max_Order_By>;
  min?: InputMaybe<Nb_Demo_User_Location_Min_Order_By>;
  stddev?: InputMaybe<Nb_Demo_User_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Demo_User_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Demo_User_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Demo_User_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Demo_User_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Demo_User_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Demo_User_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Arr_Rel_Insert_Input = {
  data: Array<Nb_Demo_User_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Demo_User_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Demo_User_Location_Avg_Fields = {
  __typename?: 'nb_demo_user_location_avg_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Avg_Order_By = {
  zoom_level?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_demo_user_location". All fields are combined with a logical 'AND'. */
export type Nb_Demo_User_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Demo_User_Location_Bool_Exp>>;
  _not?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Demo_User_Location_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  loc_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  location?: InputMaybe<Geometry_Comparison_Exp>;
  nb_demo_task_type?: InputMaybe<Nb_Demo_Task_Type_Bool_Exp>;
  nb_demo_user_name?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
  task_type?: InputMaybe<String_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
  zoom_level?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_demo_user_location" */
export enum Nb_Demo_User_Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbDemoUserLocationPkey = 'nb_demo_user_location_pkey'
}

/** input type for incrementing numeric columns in table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Inc_Input = {
  zoom_level?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  loc_time?: InputMaybe<Scalars['timestamptz']>;
  location?: InputMaybe<Scalars['geometry']>;
  nb_demo_task_type?: InputMaybe<Nb_Demo_Task_Type_Obj_Rel_Insert_Input>;
  nb_demo_user_name?: InputMaybe<Nb_Demo_User_Name_Obj_Rel_Insert_Input>;
  task_type?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
  zoom_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Nb_Demo_User_Location_Max_Fields = {
  __typename?: 'nb_demo_user_location_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  loc_time?: Maybe<Scalars['timestamptz']>;
  task_type?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
  zoom_level?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loc_time?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
  zoom_level?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Demo_User_Location_Min_Fields = {
  __typename?: 'nb_demo_user_location_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  loc_time?: Maybe<Scalars['timestamptz']>;
  task_type?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
  zoom_level?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loc_time?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
  zoom_level?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Mutation_Response = {
  __typename?: 'nb_demo_user_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Demo_User_Location>;
};

/** on_conflict condition type for table "nb_demo_user_location" */
export type Nb_Demo_User_Location_On_Conflict = {
  constraint: Nb_Demo_User_Location_Constraint;
  update_columns?: Array<Nb_Demo_User_Location_Update_Column>;
  where?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_demo_user_location". */
export type Nb_Demo_User_Location_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loc_time?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  nb_demo_task_type?: InputMaybe<Nb_Demo_Task_Type_Order_By>;
  nb_demo_user_name?: InputMaybe<Nb_Demo_User_Name_Order_By>;
  task_type?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
  zoom_level?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_demo_user_location */
export type Nb_Demo_User_Location_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_demo_user_location" */
export enum Nb_Demo_User_Location_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  LocTime = 'loc_time',
  /** column name */
  Location = 'location',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  UserName = 'user_name',
  /** column name */
  ZoomLevel = 'zoom_level'
}

/** input type for updating data in table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  loc_time?: InputMaybe<Scalars['timestamptz']>;
  location?: InputMaybe<Scalars['geometry']>;
  task_type?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
  zoom_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Nb_Demo_User_Location_Stddev_Fields = {
  __typename?: 'nb_demo_user_location_stddev_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Stddev_Order_By = {
  zoom_level?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Demo_User_Location_Stddev_Pop_Fields = {
  __typename?: 'nb_demo_user_location_stddev_pop_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Stddev_Pop_Order_By = {
  zoom_level?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Demo_User_Location_Stddev_Samp_Fields = {
  __typename?: 'nb_demo_user_location_stddev_samp_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Stddev_Samp_Order_By = {
  zoom_level?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Demo_User_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Demo_User_Location_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  loc_time?: InputMaybe<Scalars['timestamptz']>;
  location?: InputMaybe<Scalars['geometry']>;
  task_type?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
  zoom_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Nb_Demo_User_Location_Sum_Fields = {
  __typename?: 'nb_demo_user_location_sum_fields';
  zoom_level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Sum_Order_By = {
  zoom_level?: InputMaybe<Order_By>;
};

/** update columns of table "nb_demo_user_location" */
export enum Nb_Demo_User_Location_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  LocTime = 'loc_time',
  /** column name */
  Location = 'location',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  UserName = 'user_name',
  /** column name */
  ZoomLevel = 'zoom_level'
}

export type Nb_Demo_User_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Demo_User_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Demo_User_Location_Set_Input>;
  where: Nb_Demo_User_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Demo_User_Location_Var_Pop_Fields = {
  __typename?: 'nb_demo_user_location_var_pop_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Var_Pop_Order_By = {
  zoom_level?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Demo_User_Location_Var_Samp_Fields = {
  __typename?: 'nb_demo_user_location_var_samp_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Var_Samp_Order_By = {
  zoom_level?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Demo_User_Location_Variance_Fields = {
  __typename?: 'nb_demo_user_location_variance_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_demo_user_location" */
export type Nb_Demo_User_Location_Variance_Order_By = {
  zoom_level?: InputMaybe<Order_By>;
};

/** 位置ログ用ユーザ（デモ用） */
export type Nb_Demo_User_Name = {
  __typename?: 'nb_demo_user_name';
  company_id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An array relationship */
  nb_demo_user_locations: Array<Nb_Demo_User_Location>;
  /** An aggregate relationship */
  nb_demo_user_locations_aggregate: Nb_Demo_User_Location_Aggregate;
};


/** 位置ログ用ユーザ（デモ用） */
export type Nb_Demo_User_NameNb_Demo_User_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Location_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
};


/** 位置ログ用ユーザ（デモ用） */
export type Nb_Demo_User_NameNb_Demo_User_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Location_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
};

/** aggregated selection of "nb_demo_user_name" */
export type Nb_Demo_User_Name_Aggregate = {
  __typename?: 'nb_demo_user_name_aggregate';
  aggregate?: Maybe<Nb_Demo_User_Name_Aggregate_Fields>;
  nodes: Array<Nb_Demo_User_Name>;
};

export type Nb_Demo_User_Name_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Demo_User_Name_Aggregate_Bool_Exp_Count>;
};

export type Nb_Demo_User_Name_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Demo_User_Name_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_demo_user_name" */
export type Nb_Demo_User_Name_Aggregate_Fields = {
  __typename?: 'nb_demo_user_name_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Demo_User_Name_Max_Fields>;
  min?: Maybe<Nb_Demo_User_Name_Min_Fields>;
};


/** aggregate fields of "nb_demo_user_name" */
export type Nb_Demo_User_Name_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Demo_User_Name_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_demo_user_name" */
export type Nb_Demo_User_Name_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Demo_User_Name_Max_Order_By>;
  min?: InputMaybe<Nb_Demo_User_Name_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_demo_user_name" */
export type Nb_Demo_User_Name_Arr_Rel_Insert_Input = {
  data: Array<Nb_Demo_User_Name_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Demo_User_Name_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_demo_user_name". All fields are combined with a logical 'AND'. */
export type Nb_Demo_User_Name_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Demo_User_Name_Bool_Exp>>;
  _not?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Demo_User_Name_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_demo_user_locations?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
  nb_demo_user_locations_aggregate?: InputMaybe<Nb_Demo_User_Location_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "nb_demo_user_name" */
export enum Nb_Demo_User_Name_Constraint {
  /** unique or primary key constraint on columns "name" */
  NbDemoUserNamePkey = 'nb_demo_user_name_pkey'
}

/** input type for inserting data into table "nb_demo_user_name" */
export type Nb_Demo_User_Name_Insert_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_demo_user_locations?: InputMaybe<Nb_Demo_User_Location_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Nb_Demo_User_Name_Max_Fields = {
  __typename?: 'nb_demo_user_name_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "nb_demo_user_name" */
export type Nb_Demo_User_Name_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Demo_User_Name_Min_Fields = {
  __typename?: 'nb_demo_user_name_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "nb_demo_user_name" */
export type Nb_Demo_User_Name_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_demo_user_name" */
export type Nb_Demo_User_Name_Mutation_Response = {
  __typename?: 'nb_demo_user_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Demo_User_Name>;
};

/** input type for inserting object relation for remote table "nb_demo_user_name" */
export type Nb_Demo_User_Name_Obj_Rel_Insert_Input = {
  data: Nb_Demo_User_Name_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Demo_User_Name_On_Conflict>;
};

/** on_conflict condition type for table "nb_demo_user_name" */
export type Nb_Demo_User_Name_On_Conflict = {
  constraint: Nb_Demo_User_Name_Constraint;
  update_columns?: Array<Nb_Demo_User_Name_Update_Column>;
  where?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_demo_user_name". */
export type Nb_Demo_User_Name_Order_By = {
  company_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_demo_user_locations_aggregate?: InputMaybe<Nb_Demo_User_Location_Aggregate_Order_By>;
};

/** primary key columns input for table: nb_demo_user_name */
export type Nb_Demo_User_Name_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "nb_demo_user_name" */
export enum Nb_Demo_User_Name_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "nb_demo_user_name" */
export type Nb_Demo_User_Name_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nb_demo_user_name" */
export type Nb_Demo_User_Name_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Demo_User_Name_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Demo_User_Name_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nb_demo_user_name" */
export enum Nb_Demo_User_Name_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Name = 'name'
}

export type Nb_Demo_User_Name_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Demo_User_Name_Set_Input>;
  where: Nb_Demo_User_Name_Bool_Exp;
};

/** columns and relationships of "nb_est_working_load" */
export type Nb_Est_Working_Load = {
  __typename?: 'nb_est_working_load';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_mfield: Nb_Mfield;
  /** An object relationship */
  nb_working_machine: Nb_Working_Machine;
  plan_id: Scalars['uuid'];
  routing?: Maybe<Scalars['geography']>;
  routing_alg: Scalars['String'];
  updated: Scalars['timestamptz'];
  usage_id: Scalars['uuid'];
  working_efficiency: Scalars['float8'];
  working_time: Scalars['float8'];
};

/** aggregated selection of "nb_est_working_load" */
export type Nb_Est_Working_Load_Aggregate = {
  __typename?: 'nb_est_working_load_aggregate';
  aggregate?: Maybe<Nb_Est_Working_Load_Aggregate_Fields>;
  nodes: Array<Nb_Est_Working_Load>;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Nb_Est_Working_Load_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Nb_Est_Working_Load_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Nb_Est_Working_Load_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Nb_Est_Working_Load_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Nb_Est_Working_Load_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Nb_Est_Working_Load_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Nb_Est_Working_Load_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Nb_Est_Working_Load_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Nb_Est_Working_Load_Aggregate_Bool_Exp_Var_Samp>;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp_Avg = {
  arguments: Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp_Corr = {
  arguments: Nb_Est_Working_Load_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Est_Working_Load_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Nb_Est_Working_Load_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp_Max = {
  arguments: Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp_Min = {
  arguments: Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp_Sum = {
  arguments: Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Est_Working_Load_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "nb_est_working_load" */
export type Nb_Est_Working_Load_Aggregate_Fields = {
  __typename?: 'nb_est_working_load_aggregate_fields';
  avg?: Maybe<Nb_Est_Working_Load_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Est_Working_Load_Max_Fields>;
  min?: Maybe<Nb_Est_Working_Load_Min_Fields>;
  stddev?: Maybe<Nb_Est_Working_Load_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Est_Working_Load_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Est_Working_Load_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Est_Working_Load_Sum_Fields>;
  var_pop?: Maybe<Nb_Est_Working_Load_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Est_Working_Load_Var_Samp_Fields>;
  variance?: Maybe<Nb_Est_Working_Load_Variance_Fields>;
};


/** aggregate fields of "nb_est_working_load" */
export type Nb_Est_Working_Load_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Est_Working_Load_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_est_working_load" */
export type Nb_Est_Working_Load_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Est_Working_Load_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Est_Working_Load_Max_Order_By>;
  min?: InputMaybe<Nb_Est_Working_Load_Min_Order_By>;
  stddev?: InputMaybe<Nb_Est_Working_Load_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Est_Working_Load_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Est_Working_Load_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Est_Working_Load_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Est_Working_Load_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Est_Working_Load_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Est_Working_Load_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_est_working_load" */
export type Nb_Est_Working_Load_Arr_Rel_Insert_Input = {
  data: Array<Nb_Est_Working_Load_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Est_Working_Load_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Est_Working_Load_Avg_Fields = {
  __typename?: 'nb_est_working_load_avg_fields';
  working_efficiency?: Maybe<Scalars['Float']>;
  working_time?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_est_working_load" */
export type Nb_Est_Working_Load_Avg_Order_By = {
  working_efficiency?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_est_working_load". All fields are combined with a logical 'AND'. */
export type Nb_Est_Working_Load_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Est_Working_Load_Bool_Exp>>;
  _not?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Est_Working_Load_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_mfield?: InputMaybe<Nb_Mfield_Bool_Exp>;
  nb_working_machine?: InputMaybe<Nb_Working_Machine_Bool_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  routing?: InputMaybe<Geography_Comparison_Exp>;
  routing_alg?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  usage_id?: InputMaybe<Uuid_Comparison_Exp>;
  working_efficiency?: InputMaybe<Float8_Comparison_Exp>;
  working_time?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_est_working_load" */
export enum Nb_Est_Working_Load_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbEstWorkingLoadPkey = 'nb_est_working_load_pkey'
}

/** input type for incrementing numeric columns in table "nb_est_working_load" */
export type Nb_Est_Working_Load_Inc_Input = {
  working_efficiency?: InputMaybe<Scalars['float8']>;
  working_time?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "nb_est_working_load" */
export type Nb_Est_Working_Load_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_mfield?: InputMaybe<Nb_Mfield_Obj_Rel_Insert_Input>;
  nb_working_machine?: InputMaybe<Nb_Working_Machine_Obj_Rel_Insert_Input>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  routing?: InputMaybe<Scalars['geography']>;
  routing_alg?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  usage_id?: InputMaybe<Scalars['uuid']>;
  working_efficiency?: InputMaybe<Scalars['float8']>;
  working_time?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Nb_Est_Working_Load_Max_Fields = {
  __typename?: 'nb_est_working_load_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  routing_alg?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  usage_id?: Maybe<Scalars['uuid']>;
  working_efficiency?: Maybe<Scalars['float8']>;
  working_time?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "nb_est_working_load" */
export type Nb_Est_Working_Load_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  routing_alg?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  usage_id?: InputMaybe<Order_By>;
  working_efficiency?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Est_Working_Load_Min_Fields = {
  __typename?: 'nb_est_working_load_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  routing_alg?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  usage_id?: Maybe<Scalars['uuid']>;
  working_efficiency?: Maybe<Scalars['float8']>;
  working_time?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "nb_est_working_load" */
export type Nb_Est_Working_Load_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  routing_alg?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  usage_id?: InputMaybe<Order_By>;
  working_efficiency?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_est_working_load" */
export type Nb_Est_Working_Load_Mutation_Response = {
  __typename?: 'nb_est_working_load_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Est_Working_Load>;
};

/** on_conflict condition type for table "nb_est_working_load" */
export type Nb_Est_Working_Load_On_Conflict = {
  constraint: Nb_Est_Working_Load_Constraint;
  update_columns?: Array<Nb_Est_Working_Load_Update_Column>;
  where?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_est_working_load". */
export type Nb_Est_Working_Load_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_mfield?: InputMaybe<Nb_Mfield_Order_By>;
  nb_working_machine?: InputMaybe<Nb_Working_Machine_Order_By>;
  plan_id?: InputMaybe<Order_By>;
  routing?: InputMaybe<Order_By>;
  routing_alg?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  usage_id?: InputMaybe<Order_By>;
  working_efficiency?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_est_working_load */
export type Nb_Est_Working_Load_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_est_working_load" */
export enum Nb_Est_Working_Load_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Routing = 'routing',
  /** column name */
  RoutingAlg = 'routing_alg',
  /** column name */
  Updated = 'updated',
  /** column name */
  UsageId = 'usage_id',
  /** column name */
  WorkingEfficiency = 'working_efficiency',
  /** column name */
  WorkingTime = 'working_time'
}

/** select "nb_est_working_load_aggregate_bool_exp_avg_arguments_columns" columns of table "nb_est_working_load" */
export enum Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  WorkingEfficiency = 'working_efficiency',
  /** column name */
  WorkingTime = 'working_time'
}

/** select "nb_est_working_load_aggregate_bool_exp_corr_arguments_columns" columns of table "nb_est_working_load" */
export enum Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  WorkingEfficiency = 'working_efficiency',
  /** column name */
  WorkingTime = 'working_time'
}

/** select "nb_est_working_load_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "nb_est_working_load" */
export enum Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  WorkingEfficiency = 'working_efficiency',
  /** column name */
  WorkingTime = 'working_time'
}

/** select "nb_est_working_load_aggregate_bool_exp_max_arguments_columns" columns of table "nb_est_working_load" */
export enum Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  WorkingEfficiency = 'working_efficiency',
  /** column name */
  WorkingTime = 'working_time'
}

/** select "nb_est_working_load_aggregate_bool_exp_min_arguments_columns" columns of table "nb_est_working_load" */
export enum Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  WorkingEfficiency = 'working_efficiency',
  /** column name */
  WorkingTime = 'working_time'
}

/** select "nb_est_working_load_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "nb_est_working_load" */
export enum Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  WorkingEfficiency = 'working_efficiency',
  /** column name */
  WorkingTime = 'working_time'
}

/** select "nb_est_working_load_aggregate_bool_exp_sum_arguments_columns" columns of table "nb_est_working_load" */
export enum Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  WorkingEfficiency = 'working_efficiency',
  /** column name */
  WorkingTime = 'working_time'
}

/** select "nb_est_working_load_aggregate_bool_exp_var_samp_arguments_columns" columns of table "nb_est_working_load" */
export enum Nb_Est_Working_Load_Select_Column_Nb_Est_Working_Load_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  WorkingEfficiency = 'working_efficiency',
  /** column name */
  WorkingTime = 'working_time'
}

/** input type for updating data in table "nb_est_working_load" */
export type Nb_Est_Working_Load_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  routing?: InputMaybe<Scalars['geography']>;
  routing_alg?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  usage_id?: InputMaybe<Scalars['uuid']>;
  working_efficiency?: InputMaybe<Scalars['float8']>;
  working_time?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Nb_Est_Working_Load_Stddev_Fields = {
  __typename?: 'nb_est_working_load_stddev_fields';
  working_efficiency?: Maybe<Scalars['Float']>;
  working_time?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_est_working_load" */
export type Nb_Est_Working_Load_Stddev_Order_By = {
  working_efficiency?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Est_Working_Load_Stddev_Pop_Fields = {
  __typename?: 'nb_est_working_load_stddev_pop_fields';
  working_efficiency?: Maybe<Scalars['Float']>;
  working_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_est_working_load" */
export type Nb_Est_Working_Load_Stddev_Pop_Order_By = {
  working_efficiency?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Est_Working_Load_Stddev_Samp_Fields = {
  __typename?: 'nb_est_working_load_stddev_samp_fields';
  working_efficiency?: Maybe<Scalars['Float']>;
  working_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_est_working_load" */
export type Nb_Est_Working_Load_Stddev_Samp_Order_By = {
  working_efficiency?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_est_working_load" */
export type Nb_Est_Working_Load_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Est_Working_Load_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Est_Working_Load_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  routing?: InputMaybe<Scalars['geography']>;
  routing_alg?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  usage_id?: InputMaybe<Scalars['uuid']>;
  working_efficiency?: InputMaybe<Scalars['float8']>;
  working_time?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Nb_Est_Working_Load_Sum_Fields = {
  __typename?: 'nb_est_working_load_sum_fields';
  working_efficiency?: Maybe<Scalars['float8']>;
  working_time?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "nb_est_working_load" */
export type Nb_Est_Working_Load_Sum_Order_By = {
  working_efficiency?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** update columns of table "nb_est_working_load" */
export enum Nb_Est_Working_Load_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Routing = 'routing',
  /** column name */
  RoutingAlg = 'routing_alg',
  /** column name */
  Updated = 'updated',
  /** column name */
  UsageId = 'usage_id',
  /** column name */
  WorkingEfficiency = 'working_efficiency',
  /** column name */
  WorkingTime = 'working_time'
}

export type Nb_Est_Working_Load_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Est_Working_Load_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Est_Working_Load_Set_Input>;
  where: Nb_Est_Working_Load_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Est_Working_Load_Var_Pop_Fields = {
  __typename?: 'nb_est_working_load_var_pop_fields';
  working_efficiency?: Maybe<Scalars['Float']>;
  working_time?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_est_working_load" */
export type Nb_Est_Working_Load_Var_Pop_Order_By = {
  working_efficiency?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Est_Working_Load_Var_Samp_Fields = {
  __typename?: 'nb_est_working_load_var_samp_fields';
  working_efficiency?: Maybe<Scalars['Float']>;
  working_time?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_est_working_load" */
export type Nb_Est_Working_Load_Var_Samp_Order_By = {
  working_efficiency?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Est_Working_Load_Variance_Fields = {
  __typename?: 'nb_est_working_load_variance_fields';
  working_efficiency?: Maybe<Scalars['Float']>;
  working_time?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_est_working_load" */
export type Nb_Est_Working_Load_Variance_Order_By = {
  working_efficiency?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_fertilization_method" */
export type Nb_Fertilization_Method = {
  __typename?: 'nb_fertilization_method';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  nb_fertilization_works: Array<Nb_Fertilization_Work>;
  /** An aggregate relationship */
  nb_fertilization_works_aggregate: Nb_Fertilization_Work_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_fertilization_method" */
export type Nb_Fertilization_MethodNb_Fertilization_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


/** columns and relationships of "nb_fertilization_method" */
export type Nb_Fertilization_MethodNb_Fertilization_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};

/** aggregated selection of "nb_fertilization_method" */
export type Nb_Fertilization_Method_Aggregate = {
  __typename?: 'nb_fertilization_method_aggregate';
  aggregate?: Maybe<Nb_Fertilization_Method_Aggregate_Fields>;
  nodes: Array<Nb_Fertilization_Method>;
};

/** aggregate fields of "nb_fertilization_method" */
export type Nb_Fertilization_Method_Aggregate_Fields = {
  __typename?: 'nb_fertilization_method_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Fertilization_Method_Max_Fields>;
  min?: Maybe<Nb_Fertilization_Method_Min_Fields>;
};


/** aggregate fields of "nb_fertilization_method" */
export type Nb_Fertilization_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Fertilization_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_fertilization_method". All fields are combined with a logical 'AND'. */
export type Nb_Fertilization_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Fertilization_Method_Bool_Exp>>;
  _not?: InputMaybe<Nb_Fertilization_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Fertilization_Method_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_fertilization_works?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
  nb_fertilization_works_aggregate?: InputMaybe<Nb_Fertilization_Work_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_fertilization_method" */
export enum Nb_Fertilization_Method_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbFertilizationMethodKeyKey = 'nb_fertilization_method_key_key',
  /** unique or primary key constraint on columns "id" */
  NbFertilizationMethodPkey = 'nb_fertilization_method_pkey'
}

/** input type for inserting data into table "nb_fertilization_method" */
export type Nb_Fertilization_Method_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nb_fertilization_works?: InputMaybe<Nb_Fertilization_Work_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Fertilization_Method_Max_Fields = {
  __typename?: 'nb_fertilization_method_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Fertilization_Method_Min_Fields = {
  __typename?: 'nb_fertilization_method_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_fertilization_method" */
export type Nb_Fertilization_Method_Mutation_Response = {
  __typename?: 'nb_fertilization_method_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Fertilization_Method>;
};

/** input type for inserting object relation for remote table "nb_fertilization_method" */
export type Nb_Fertilization_Method_Obj_Rel_Insert_Input = {
  data: Nb_Fertilization_Method_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Fertilization_Method_On_Conflict>;
};

/** on_conflict condition type for table "nb_fertilization_method" */
export type Nb_Fertilization_Method_On_Conflict = {
  constraint: Nb_Fertilization_Method_Constraint;
  update_columns?: Array<Nb_Fertilization_Method_Update_Column>;
  where?: InputMaybe<Nb_Fertilization_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_fertilization_method". */
export type Nb_Fertilization_Method_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_fertilization_works_aggregate?: InputMaybe<Nb_Fertilization_Work_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_fertilization_method */
export type Nb_Fertilization_Method_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_fertilization_method" */
export enum Nb_Fertilization_Method_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_fertilization_method" */
export type Nb_Fertilization_Method_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_fertilization_method" */
export type Nb_Fertilization_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Fertilization_Method_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Fertilization_Method_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_fertilization_method" */
export enum Nb_Fertilization_Method_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

export type Nb_Fertilization_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Fertilization_Method_Set_Input>;
  where: Nb_Fertilization_Method_Bool_Exp;
};

/** columns and relationships of "nb_fertilization_work" */
export type Nb_Fertilization_Work = {
  __typename?: 'nb_fertilization_work';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  basal_fertilizer: Scalars['Boolean'];
  created: Scalars['timestamptz'];
  cultivation_plan_id: Scalars['uuid'];
  datetime: Scalars['timestamptz'];
  fertilization_method_id?: Maybe<Scalars['uuid']>;
  fertilizer_amount: Scalars['Float'];
  fertilizer_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_cultivation_plan: Nb_Cultivation_Plan;
  /** An object relationship */
  nb_fertilization_method?: Maybe<Nb_Fertilization_Method>;
  /** An object relationship */
  nb_fertilizer: Nb_Fertilizer;
  sequence: Scalars['Int'];
  updated: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};

/** aggregated selection of "nb_fertilization_work" */
export type Nb_Fertilization_Work_Aggregate = {
  __typename?: 'nb_fertilization_work_aggregate';
  aggregate?: Maybe<Nb_Fertilization_Work_Aggregate_Fields>;
  nodes: Array<Nb_Fertilization_Work>;
};

export type Nb_Fertilization_Work_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Nb_Fertilization_Work_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Nb_Fertilization_Work_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Nb_Fertilization_Work_Aggregate_Bool_Exp_Count>;
};

export type Nb_Fertilization_Work_Aggregate_Bool_Exp_Bool_And = {
  arguments: Nb_Fertilization_Work_Select_Column_Nb_Fertilization_Work_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Nb_Fertilization_Work_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Nb_Fertilization_Work_Select_Column_Nb_Fertilization_Work_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Nb_Fertilization_Work_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_fertilization_work" */
export type Nb_Fertilization_Work_Aggregate_Fields = {
  __typename?: 'nb_fertilization_work_aggregate_fields';
  avg?: Maybe<Nb_Fertilization_Work_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Fertilization_Work_Max_Fields>;
  min?: Maybe<Nb_Fertilization_Work_Min_Fields>;
  stddev?: Maybe<Nb_Fertilization_Work_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Fertilization_Work_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Fertilization_Work_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Fertilization_Work_Sum_Fields>;
  var_pop?: Maybe<Nb_Fertilization_Work_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Fertilization_Work_Var_Samp_Fields>;
  variance?: Maybe<Nb_Fertilization_Work_Variance_Fields>;
};


/** aggregate fields of "nb_fertilization_work" */
export type Nb_Fertilization_Work_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Fertilization_Work_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Fertilization_Work_Max_Order_By>;
  min?: InputMaybe<Nb_Fertilization_Work_Min_Order_By>;
  stddev?: InputMaybe<Nb_Fertilization_Work_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Fertilization_Work_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Fertilization_Work_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Fertilization_Work_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Fertilization_Work_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Fertilization_Work_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Fertilization_Work_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Arr_Rel_Insert_Input = {
  data: Array<Nb_Fertilization_Work_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Fertilization_Work_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Fertilization_Work_Avg_Fields = {
  __typename?: 'nb_fertilization_work_avg_fields';
  fertilizer_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Avg_Order_By = {
  fertilizer_amount?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_fertilization_work". All fields are combined with a logical 'AND'. */
export type Nb_Fertilization_Work_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Fertilization_Work_Bool_Exp>>;
  _not?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Fertilization_Work_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  basal_fertilizer?: InputMaybe<Boolean_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  cultivation_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  fertilization_method_id?: InputMaybe<Uuid_Comparison_Exp>;
  fertilizer_amount?: InputMaybe<Float_Comparison_Exp>;
  fertilizer_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_fertilization_method?: InputMaybe<Nb_Fertilization_Method_Bool_Exp>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Bool_Exp>;
  sequence?: InputMaybe<Int_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_fertilization_work" */
export enum Nb_Fertilization_Work_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFertilizationWorkPkey = 'nb_fertilization_work_pkey'
}

/** input type for incrementing numeric columns in table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Inc_Input = {
  fertilizer_amount?: InputMaybe<Scalars['Float']>;
  sequence?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  basal_fertilizer?: InputMaybe<Scalars['Boolean']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  fertilization_method_id?: InputMaybe<Scalars['uuid']>;
  fertilizer_amount?: InputMaybe<Scalars['Float']>;
  fertilizer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Obj_Rel_Insert_Input>;
  nb_fertilization_method?: InputMaybe<Nb_Fertilization_Method_Obj_Rel_Insert_Input>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Obj_Rel_Insert_Input>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Fertilization_Work_Max_Fields = {
  __typename?: 'nb_fertilization_work_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  fertilization_method_id?: Maybe<Scalars['uuid']>;
  fertilizer_amount?: Maybe<Scalars['Float']>;
  fertilizer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  fertilization_method_id?: InputMaybe<Order_By>;
  fertilizer_amount?: InputMaybe<Order_By>;
  fertilizer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Fertilization_Work_Min_Fields = {
  __typename?: 'nb_fertilization_work_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  fertilization_method_id?: Maybe<Scalars['uuid']>;
  fertilizer_amount?: Maybe<Scalars['Float']>;
  fertilizer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sequence?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  fertilization_method_id?: InputMaybe<Order_By>;
  fertilizer_amount?: InputMaybe<Order_By>;
  fertilizer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Mutation_Response = {
  __typename?: 'nb_fertilization_work_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Fertilization_Work>;
};

/** on_conflict condition type for table "nb_fertilization_work" */
export type Nb_Fertilization_Work_On_Conflict = {
  constraint: Nb_Fertilization_Work_Constraint;
  update_columns?: Array<Nb_Fertilization_Work_Update_Column>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_fertilization_work". */
export type Nb_Fertilization_Work_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  basal_fertilizer?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  fertilization_method_id?: InputMaybe<Order_By>;
  fertilizer_amount?: InputMaybe<Order_By>;
  fertilizer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Order_By>;
  nb_fertilization_method?: InputMaybe<Nb_Fertilization_Method_Order_By>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Order_By>;
  sequence?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_fertilization_work */
export type Nb_Fertilization_Work_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_fertilization_work" */
export enum Nb_Fertilization_Work_Select_Column {
  /** column name */
  BasalFertilizer = 'basal_fertilizer',
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FertilizationMethodId = 'fertilization_method_id',
  /** column name */
  FertilizerAmount = 'fertilizer_amount',
  /** column name */
  FertilizerId = 'fertilizer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** select "nb_fertilization_work_aggregate_bool_exp_bool_and_arguments_columns" columns of table "nb_fertilization_work" */
export enum Nb_Fertilization_Work_Select_Column_Nb_Fertilization_Work_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  BasalFertilizer = 'basal_fertilizer'
}

/** select "nb_fertilization_work_aggregate_bool_exp_bool_or_arguments_columns" columns of table "nb_fertilization_work" */
export enum Nb_Fertilization_Work_Select_Column_Nb_Fertilization_Work_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  BasalFertilizer = 'basal_fertilizer'
}

/** input type for updating data in table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Set_Input = {
  basal_fertilizer?: InputMaybe<Scalars['Boolean']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  fertilization_method_id?: InputMaybe<Scalars['uuid']>;
  fertilizer_amount?: InputMaybe<Scalars['Float']>;
  fertilizer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nb_Fertilization_Work_Stddev_Fields = {
  __typename?: 'nb_fertilization_work_stddev_fields';
  fertilizer_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Stddev_Order_By = {
  fertilizer_amount?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Fertilization_Work_Stddev_Pop_Fields = {
  __typename?: 'nb_fertilization_work_stddev_pop_fields';
  fertilizer_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Stddev_Pop_Order_By = {
  fertilizer_amount?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Fertilization_Work_Stddev_Samp_Fields = {
  __typename?: 'nb_fertilization_work_stddev_samp_fields';
  fertilizer_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Stddev_Samp_Order_By = {
  fertilizer_amount?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Fertilization_Work_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Fertilization_Work_Stream_Cursor_Value_Input = {
  basal_fertilizer?: InputMaybe<Scalars['Boolean']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  fertilization_method_id?: InputMaybe<Scalars['uuid']>;
  fertilizer_amount?: InputMaybe<Scalars['Float']>;
  fertilizer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  sequence?: InputMaybe<Scalars['Int']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Fertilization_Work_Sum_Fields = {
  __typename?: 'nb_fertilization_work_sum_fields';
  fertilizer_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Sum_Order_By = {
  fertilizer_amount?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
};

/** update columns of table "nb_fertilization_work" */
export enum Nb_Fertilization_Work_Update_Column {
  /** column name */
  BasalFertilizer = 'basal_fertilizer',
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FertilizationMethodId = 'fertilization_method_id',
  /** column name */
  FertilizerAmount = 'fertilizer_amount',
  /** column name */
  FertilizerId = 'fertilizer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Fertilization_Work_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Fertilization_Work_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Fertilization_Work_Set_Input>;
  where: Nb_Fertilization_Work_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Fertilization_Work_Var_Pop_Fields = {
  __typename?: 'nb_fertilization_work_var_pop_fields';
  fertilizer_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Var_Pop_Order_By = {
  fertilizer_amount?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Fertilization_Work_Var_Samp_Fields = {
  __typename?: 'nb_fertilization_work_var_samp_fields';
  fertilizer_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Var_Samp_Order_By = {
  fertilizer_amount?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Fertilization_Work_Variance_Fields = {
  __typename?: 'nb_fertilization_work_variance_fields';
  fertilizer_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_fertilization_work" */
export type Nb_Fertilization_Work_Variance_Order_By = {
  fertilizer_amount?: InputMaybe<Order_By>;
  sequence?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_fertilizer" */
export type Nb_Fertilizer = {
  __typename?: 'nb_fertilizer';
  content?: Maybe<Scalars['json']>;
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_conditioner: Scalars['Boolean'];
  is_fertilizer: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  nb_conditioner_works: Array<Nb_Conditioner_Work>;
  /** An aggregate relationship */
  nb_conditioner_works_aggregate: Nb_Conditioner_Work_Aggregate;
  /** An array relationship */
  nb_fertilization_works: Array<Nb_Fertilization_Work>;
  /** An aggregate relationship */
  nb_fertilization_works_aggregate: Nb_Fertilization_Work_Aggregate;
  /** An array relationship */
  nb_fertilizer_company_links: Array<Nb_Fertilizer_Company_Link>;
  /** An aggregate relationship */
  nb_fertilizer_company_links_aggregate: Nb_Fertilizer_Company_Link_Aggregate;
  /** An array relationship */
  nb_fertilizer_pictures: Array<Nb_Fertilizer_Picture>;
  /** An aggregate relationship */
  nb_fertilizer_pictures_aggregate: Nb_Fertilizer_Picture_Aggregate;
  picture_path?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  regist_date?: Maybe<Scalars['date']>;
  regist_number?: Maybe<Scalars['String']>;
  thumbnail_path?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nb_fertilizer" */
export type Nb_FertilizerContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "nb_fertilizer" */
export type Nb_FertilizerNb_Conditioner_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


/** columns and relationships of "nb_fertilizer" */
export type Nb_FertilizerNb_Conditioner_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


/** columns and relationships of "nb_fertilizer" */
export type Nb_FertilizerNb_Fertilization_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


/** columns and relationships of "nb_fertilizer" */
export type Nb_FertilizerNb_Fertilization_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


/** columns and relationships of "nb_fertilizer" */
export type Nb_FertilizerNb_Fertilizer_Company_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_fertilizer" */
export type Nb_FertilizerNb_Fertilizer_Company_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_fertilizer" */
export type Nb_FertilizerNb_Fertilizer_PicturesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Picture_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Picture_Bool_Exp>;
};


/** columns and relationships of "nb_fertilizer" */
export type Nb_FertilizerNb_Fertilizer_Pictures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Picture_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Picture_Bool_Exp>;
};

/** aggregated selection of "nb_fertilizer" */
export type Nb_Fertilizer_Aggregate = {
  __typename?: 'nb_fertilizer_aggregate';
  aggregate?: Maybe<Nb_Fertilizer_Aggregate_Fields>;
  nodes: Array<Nb_Fertilizer>;
};

/** aggregate fields of "nb_fertilizer" */
export type Nb_Fertilizer_Aggregate_Fields = {
  __typename?: 'nb_fertilizer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Fertilizer_Max_Fields>;
  min?: Maybe<Nb_Fertilizer_Min_Fields>;
};


/** aggregate fields of "nb_fertilizer" */
export type Nb_Fertilizer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Fertilizer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_fertilizer". All fields are combined with a logical 'AND'. */
export type Nb_Fertilizer_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Fertilizer_Bool_Exp>>;
  _not?: InputMaybe<Nb_Fertilizer_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Fertilizer_Bool_Exp>>;
  content?: InputMaybe<Json_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_conditioner?: InputMaybe<Boolean_Comparison_Exp>;
  is_fertilizer?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_conditioner_works?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
  nb_conditioner_works_aggregate?: InputMaybe<Nb_Conditioner_Work_Aggregate_Bool_Exp>;
  nb_fertilization_works?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
  nb_fertilization_works_aggregate?: InputMaybe<Nb_Fertilization_Work_Aggregate_Bool_Exp>;
  nb_fertilizer_company_links?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
  nb_fertilizer_company_links_aggregate?: InputMaybe<Nb_Fertilizer_Company_Link_Aggregate_Bool_Exp>;
  nb_fertilizer_pictures?: InputMaybe<Nb_Fertilizer_Picture_Bool_Exp>;
  nb_fertilizer_pictures_aggregate?: InputMaybe<Nb_Fertilizer_Picture_Aggregate_Bool_Exp>;
  picture_path?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
  regist_date?: InputMaybe<Date_Comparison_Exp>;
  regist_number?: InputMaybe<String_Comparison_Exp>;
  thumbnail_path?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link = {
  __typename?: 'nb_fertilizer_company_link';
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  fertilizer_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An object relationship */
  nb_fertilizer: Nb_Fertilizer;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_Aggregate = {
  __typename?: 'nb_fertilizer_company_link_aggregate';
  aggregate?: Maybe<Nb_Fertilizer_Company_Link_Aggregate_Fields>;
  nodes: Array<Nb_Fertilizer_Company_Link>;
};

export type Nb_Fertilizer_Company_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Fertilizer_Company_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Fertilizer_Company_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_Aggregate_Fields = {
  __typename?: 'nb_fertilizer_company_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Fertilizer_Company_Link_Max_Fields>;
  min?: Maybe<Nb_Fertilizer_Company_Link_Min_Fields>;
};


/** aggregate fields of "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Fertilizer_Company_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Fertilizer_Company_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Fertilizer_Company_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Fertilizer_Company_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_fertilizer_company_link". All fields are combined with a logical 'AND'. */
export type Nb_Fertilizer_Company_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  fertilizer_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_fertilizer_company_link" */
export enum Nb_Fertilizer_Company_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFertilizerCompanyLinkPkey = 'nb_fertilizer_company_link_pkey'
}

/** input type for inserting data into table "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_Insert_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  fertilizer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Fertilizer_Company_Link_Max_Fields = {
  __typename?: 'nb_fertilizer_company_link_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  fertilizer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  fertilizer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Fertilizer_Company_Link_Min_Fields = {
  __typename?: 'nb_fertilizer_company_link_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  fertilizer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  fertilizer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_Mutation_Response = {
  __typename?: 'nb_fertilizer_company_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Fertilizer_Company_Link>;
};

/** on_conflict condition type for table "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_On_Conflict = {
  constraint: Nb_Fertilizer_Company_Link_Constraint;
  update_columns?: Array<Nb_Fertilizer_Company_Link_Update_Column>;
  where?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_fertilizer_company_link". */
export type Nb_Fertilizer_Company_Link_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  fertilizer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_fertilizer_company_link */
export type Nb_Fertilizer_Company_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_fertilizer_company_link" */
export enum Nb_Fertilizer_Company_Link_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  FertilizerId = 'fertilizer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  fertilizer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_fertilizer_company_link" */
export type Nb_Fertilizer_Company_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Fertilizer_Company_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Fertilizer_Company_Link_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  fertilizer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_fertilizer_company_link" */
export enum Nb_Fertilizer_Company_Link_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  FertilizerId = 'fertilizer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Fertilizer_Company_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Fertilizer_Company_Link_Set_Input>;
  where: Nb_Fertilizer_Company_Link_Bool_Exp;
};

/** unique or primary key constraints on table "nb_fertilizer" */
export enum Nb_Fertilizer_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFertilizerPkey = 'nb_fertilizer_pkey'
}

/** input type for inserting data into table "nb_fertilizer" */
export type Nb_Fertilizer_Insert_Input = {
  content?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_conditioner?: InputMaybe<Scalars['Boolean']>;
  is_fertilizer?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nb_conditioner_works?: InputMaybe<Nb_Conditioner_Work_Arr_Rel_Insert_Input>;
  nb_fertilization_works?: InputMaybe<Nb_Fertilization_Work_Arr_Rel_Insert_Input>;
  nb_fertilizer_company_links?: InputMaybe<Nb_Fertilizer_Company_Link_Arr_Rel_Insert_Input>;
  nb_fertilizer_pictures?: InputMaybe<Nb_Fertilizer_Picture_Arr_Rel_Insert_Input>;
  picture_path?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  regist_date?: InputMaybe<Scalars['date']>;
  regist_number?: InputMaybe<Scalars['String']>;
  thumbnail_path?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Fertilizer_Max_Fields = {
  __typename?: 'nb_fertilizer_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  picture_path?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  regist_date?: Maybe<Scalars['date']>;
  regist_number?: Maybe<Scalars['String']>;
  thumbnail_path?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Fertilizer_Min_Fields = {
  __typename?: 'nb_fertilizer_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  picture_path?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  regist_date?: Maybe<Scalars['date']>;
  regist_number?: Maybe<Scalars['String']>;
  thumbnail_path?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_fertilizer" */
export type Nb_Fertilizer_Mutation_Response = {
  __typename?: 'nb_fertilizer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Fertilizer>;
};

/** input type for inserting object relation for remote table "nb_fertilizer" */
export type Nb_Fertilizer_Obj_Rel_Insert_Input = {
  data: Nb_Fertilizer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Fertilizer_On_Conflict>;
};

/** on_conflict condition type for table "nb_fertilizer" */
export type Nb_Fertilizer_On_Conflict = {
  constraint: Nb_Fertilizer_Constraint;
  update_columns?: Array<Nb_Fertilizer_Update_Column>;
  where?: InputMaybe<Nb_Fertilizer_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_fertilizer". */
export type Nb_Fertilizer_Order_By = {
  content?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_conditioner?: InputMaybe<Order_By>;
  is_fertilizer?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_conditioner_works_aggregate?: InputMaybe<Nb_Conditioner_Work_Aggregate_Order_By>;
  nb_fertilization_works_aggregate?: InputMaybe<Nb_Fertilization_Work_Aggregate_Order_By>;
  nb_fertilizer_company_links_aggregate?: InputMaybe<Nb_Fertilizer_Company_Link_Aggregate_Order_By>;
  nb_fertilizer_pictures_aggregate?: InputMaybe<Nb_Fertilizer_Picture_Aggregate_Order_By>;
  picture_path?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  regist_date?: InputMaybe<Order_By>;
  regist_number?: InputMaybe<Order_By>;
  thumbnail_path?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture = {
  __typename?: 'nb_fertilizer_picture';
  created: Scalars['timestamptz'];
  fertilizer_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_fertilizer: Nb_Fertilizer;
  picture_path: Scalars['String'];
  sort_key: Scalars['Int'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Aggregate = {
  __typename?: 'nb_fertilizer_picture_aggregate';
  aggregate?: Maybe<Nb_Fertilizer_Picture_Aggregate_Fields>;
  nodes: Array<Nb_Fertilizer_Picture>;
};

export type Nb_Fertilizer_Picture_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Fertilizer_Picture_Aggregate_Bool_Exp_Count>;
};

export type Nb_Fertilizer_Picture_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Fertilizer_Picture_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Fertilizer_Picture_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Aggregate_Fields = {
  __typename?: 'nb_fertilizer_picture_aggregate_fields';
  avg?: Maybe<Nb_Fertilizer_Picture_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Fertilizer_Picture_Max_Fields>;
  min?: Maybe<Nb_Fertilizer_Picture_Min_Fields>;
  stddev?: Maybe<Nb_Fertilizer_Picture_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Fertilizer_Picture_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Fertilizer_Picture_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Fertilizer_Picture_Sum_Fields>;
  var_pop?: Maybe<Nb_Fertilizer_Picture_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Fertilizer_Picture_Var_Samp_Fields>;
  variance?: Maybe<Nb_Fertilizer_Picture_Variance_Fields>;
};


/** aggregate fields of "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Fertilizer_Picture_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Fertilizer_Picture_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Fertilizer_Picture_Max_Order_By>;
  min?: InputMaybe<Nb_Fertilizer_Picture_Min_Order_By>;
  stddev?: InputMaybe<Nb_Fertilizer_Picture_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Fertilizer_Picture_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Fertilizer_Picture_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Fertilizer_Picture_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Fertilizer_Picture_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Fertilizer_Picture_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Fertilizer_Picture_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Arr_Rel_Insert_Input = {
  data: Array<Nb_Fertilizer_Picture_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Fertilizer_Picture_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Fertilizer_Picture_Avg_Fields = {
  __typename?: 'nb_fertilizer_picture_avg_fields';
  sort_key?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Avg_Order_By = {
  sort_key?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_fertilizer_picture". All fields are combined with a logical 'AND'. */
export type Nb_Fertilizer_Picture_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Fertilizer_Picture_Bool_Exp>>;
  _not?: InputMaybe<Nb_Fertilizer_Picture_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Fertilizer_Picture_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  fertilizer_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Bool_Exp>;
  picture_path?: InputMaybe<String_Comparison_Exp>;
  sort_key?: InputMaybe<Int_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_fertilizer_picture" */
export enum Nb_Fertilizer_Picture_Constraint {
  /** unique or primary key constraint on columns "fertilizer_id", "sort_key" */
  NbFertilizerPictureFertilizerIdSortKeyKey = 'nb_fertilizer_picture_fertilizer_id_sort_key_key',
  /** unique or primary key constraint on columns "id" */
  NbFertilizerPicturePkey = 'nb_fertilizer_picture_pkey'
}

/** input type for incrementing numeric columns in table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Inc_Input = {
  sort_key?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  fertilizer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Obj_Rel_Insert_Input>;
  picture_path?: InputMaybe<Scalars['String']>;
  sort_key?: InputMaybe<Scalars['Int']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Fertilizer_Picture_Max_Fields = {
  __typename?: 'nb_fertilizer_picture_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  fertilizer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  picture_path?: Maybe<Scalars['String']>;
  sort_key?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  fertilizer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  picture_path?: InputMaybe<Order_By>;
  sort_key?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Fertilizer_Picture_Min_Fields = {
  __typename?: 'nb_fertilizer_picture_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  fertilizer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  picture_path?: Maybe<Scalars['String']>;
  sort_key?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  fertilizer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  picture_path?: InputMaybe<Order_By>;
  sort_key?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Mutation_Response = {
  __typename?: 'nb_fertilizer_picture_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Fertilizer_Picture>;
};

/** on_conflict condition type for table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_On_Conflict = {
  constraint: Nb_Fertilizer_Picture_Constraint;
  update_columns?: Array<Nb_Fertilizer_Picture_Update_Column>;
  where?: InputMaybe<Nb_Fertilizer_Picture_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_fertilizer_picture". */
export type Nb_Fertilizer_Picture_Order_By = {
  created?: InputMaybe<Order_By>;
  fertilizer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_fertilizer?: InputMaybe<Nb_Fertilizer_Order_By>;
  picture_path?: InputMaybe<Order_By>;
  sort_key?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_fertilizer_picture */
export type Nb_Fertilizer_Picture_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_fertilizer_picture" */
export enum Nb_Fertilizer_Picture_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FertilizerId = 'fertilizer_id',
  /** column name */
  Id = 'id',
  /** column name */
  PicturePath = 'picture_path',
  /** column name */
  SortKey = 'sort_key',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  fertilizer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  picture_path?: InputMaybe<Scalars['String']>;
  sort_key?: InputMaybe<Scalars['Int']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nb_Fertilizer_Picture_Stddev_Fields = {
  __typename?: 'nb_fertilizer_picture_stddev_fields';
  sort_key?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Stddev_Order_By = {
  sort_key?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Fertilizer_Picture_Stddev_Pop_Fields = {
  __typename?: 'nb_fertilizer_picture_stddev_pop_fields';
  sort_key?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Stddev_Pop_Order_By = {
  sort_key?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Fertilizer_Picture_Stddev_Samp_Fields = {
  __typename?: 'nb_fertilizer_picture_stddev_samp_fields';
  sort_key?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Stddev_Samp_Order_By = {
  sort_key?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Fertilizer_Picture_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Fertilizer_Picture_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  fertilizer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  picture_path?: InputMaybe<Scalars['String']>;
  sort_key?: InputMaybe<Scalars['Int']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Fertilizer_Picture_Sum_Fields = {
  __typename?: 'nb_fertilizer_picture_sum_fields';
  sort_key?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Sum_Order_By = {
  sort_key?: InputMaybe<Order_By>;
};

/** update columns of table "nb_fertilizer_picture" */
export enum Nb_Fertilizer_Picture_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FertilizerId = 'fertilizer_id',
  /** column name */
  Id = 'id',
  /** column name */
  PicturePath = 'picture_path',
  /** column name */
  SortKey = 'sort_key',
  /** column name */
  Updated = 'updated'
}

export type Nb_Fertilizer_Picture_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Fertilizer_Picture_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Fertilizer_Picture_Set_Input>;
  where: Nb_Fertilizer_Picture_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Fertilizer_Picture_Var_Pop_Fields = {
  __typename?: 'nb_fertilizer_picture_var_pop_fields';
  sort_key?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Var_Pop_Order_By = {
  sort_key?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Fertilizer_Picture_Var_Samp_Fields = {
  __typename?: 'nb_fertilizer_picture_var_samp_fields';
  sort_key?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Var_Samp_Order_By = {
  sort_key?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Fertilizer_Picture_Variance_Fields = {
  __typename?: 'nb_fertilizer_picture_variance_fields';
  sort_key?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_fertilizer_picture" */
export type Nb_Fertilizer_Picture_Variance_Order_By = {
  sort_key?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_fertilizer */
export type Nb_Fertilizer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_fertilizer" */
export enum Nb_Fertilizer_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IsConditioner = 'is_conditioner',
  /** column name */
  IsFertilizer = 'is_fertilizer',
  /** column name */
  Name = 'name',
  /** column name */
  PicturePath = 'picture_path',
  /** column name */
  Provider = 'provider',
  /** column name */
  RegistDate = 'regist_date',
  /** column name */
  RegistNumber = 'regist_number',
  /** column name */
  ThumbnailPath = 'thumbnail_path',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_fertilizer" */
export type Nb_Fertilizer_Set_Input = {
  content?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_conditioner?: InputMaybe<Scalars['Boolean']>;
  is_fertilizer?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  picture_path?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  regist_date?: InputMaybe<Scalars['date']>;
  regist_number?: InputMaybe<Scalars['String']>;
  thumbnail_path?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_fertilizer" */
export type Nb_Fertilizer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Fertilizer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Fertilizer_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_conditioner?: InputMaybe<Scalars['Boolean']>;
  is_fertilizer?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  picture_path?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  regist_date?: InputMaybe<Scalars['date']>;
  regist_number?: InputMaybe<Scalars['String']>;
  thumbnail_path?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_fertilizer" */
export enum Nb_Fertilizer_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IsConditioner = 'is_conditioner',
  /** column name */
  IsFertilizer = 'is_fertilizer',
  /** column name */
  Name = 'name',
  /** column name */
  PicturePath = 'picture_path',
  /** column name */
  Provider = 'provider',
  /** column name */
  RegistDate = 'regist_date',
  /** column name */
  RegistNumber = 'regist_number',
  /** column name */
  ThumbnailPath = 'thumbnail_path',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Fertilizer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Fertilizer_Set_Input>;
  where: Nb_Fertilizer_Bool_Exp;
};

/** 圃場に紐づくファイルのテーブル */
export type Nb_Field_File = {
  __typename?: 'nb_field_file';
  /** An object relationship */
  app_user_active: App_User_Active;
  created: Scalars['timestamptz'];
  file_path: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  nb_field_file_loads: Array<Nb_Field_File_Load>;
  /** An aggregate relationship */
  nb_field_file_loads_aggregate: Nb_Field_File_Load_Aggregate;
  /** An object relationship */
  nb_field_file_size?: Maybe<Nb_Field_File_Size>;
  /** An array relationship */
  nb_field_file_sizes: Array<Nb_Field_File_Size>;
  /** An aggregate relationship */
  nb_field_file_sizes_aggregate: Nb_Field_File_Size_Aggregate;
  /** An array relationship */
  nb_memo_file_links: Array<Nb_Memo_File_Link>;
  /** An aggregate relationship */
  nb_memo_file_links_aggregate: Nb_Memo_File_Link_Aggregate;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  ufield_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
  upload_user_id: Scalars['uuid'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** 圃場に紐づくファイルのテーブル */
export type Nb_Field_FileNb_Field_File_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


/** 圃場に紐づくファイルのテーブル */
export type Nb_Field_FileNb_Field_File_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


/** 圃場に紐づくファイルのテーブル */
export type Nb_Field_FileNb_Field_File_SizesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Size_Bool_Exp>;
};


/** 圃場に紐づくファイルのテーブル */
export type Nb_Field_FileNb_Field_File_Sizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Size_Bool_Exp>;
};


/** 圃場に紐づくファイルのテーブル */
export type Nb_Field_FileNb_Memo_File_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_File_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_File_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
};


/** 圃場に紐づくファイルのテーブル */
export type Nb_Field_FileNb_Memo_File_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_File_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_File_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
};

/** aggregated selection of "nb_field_file" */
export type Nb_Field_File_Aggregate = {
  __typename?: 'nb_field_file_aggregate';
  aggregate?: Maybe<Nb_Field_File_Aggregate_Fields>;
  nodes: Array<Nb_Field_File>;
};

export type Nb_Field_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_File_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_file" */
export type Nb_Field_File_Aggregate_Fields = {
  __typename?: 'nb_field_file_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_File_Max_Fields>;
  min?: Maybe<Nb_Field_File_Min_Fields>;
};


/** aggregate fields of "nb_field_file" */
export type Nb_Field_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_file" */
export type Nb_Field_File_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_File_Max_Order_By>;
  min?: InputMaybe<Nb_Field_File_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_file" */
export type Nb_Field_File_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_File_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_field_file". All fields are combined with a logical 'AND'. */
export type Nb_Field_File_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_File_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_File_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_File_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_field_file_loads?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
  nb_field_file_loads_aggregate?: InputMaybe<Nb_Field_File_Load_Aggregate_Bool_Exp>;
  nb_field_file_size?: InputMaybe<Nb_Field_File_Size_Bool_Exp>;
  nb_field_file_sizes?: InputMaybe<Nb_Field_File_Size_Bool_Exp>;
  nb_field_file_sizes_aggregate?: InputMaybe<Nb_Field_File_Size_Aggregate_Bool_Exp>;
  nb_memo_file_links?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
  nb_memo_file_links_aggregate?: InputMaybe<Nb_Memo_File_Link_Aggregate_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_file" */
export enum Nb_Field_File_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFiledFilePkey = 'nb_filed_file_pkey'
}

/** input type for inserting data into table "nb_field_file" */
export type Nb_Field_File_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  file_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nb_field_file_loads?: InputMaybe<Nb_Field_File_Load_Arr_Rel_Insert_Input>;
  nb_field_file_size?: InputMaybe<Nb_Field_File_Size_Obj_Rel_Insert_Input>;
  nb_field_file_sizes?: InputMaybe<Nb_Field_File_Size_Arr_Rel_Insert_Input>;
  nb_memo_file_links?: InputMaybe<Nb_Memo_File_Link_Arr_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  upload_user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "nb_field_file_load" */
export type Nb_Field_File_Load = {
  __typename?: 'nb_field_file_load';
  /** An object relationship */
  app_user_active: App_User_Active;
  /** 撮影者 */
  app_user_active_id: Scalars['uuid'];
  /** レコードが生成されたタイムスタンプ */
  created: Scalars['timestamptz'];
  /** ファイルコンテンツとなるデータを観測したタイムスタンプ。fileには日時データを持たない場合が多いので、Nullable。 */
  datetime?: Maybe<Scalars['timestamptz']>;
  /** 表示用に使用する画像のS3 key */
  file_path?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: Maybe<Scalars['String']>;
  /** An object relationship */
  nb_company: Nb_Company;
  /** 撮影者が属する会社 */
  nb_company_id: Scalars['uuid'];
  /** An object relationship */
  nb_field_file?: Maybe<Nb_Field_File>;
  nb_field_file_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  /** 紐付けたユーザ圃場 */
  nb_ufield_id: Scalars['uuid'];
  /** ロードの状態 */
  status: Scalars['picture_load_status'];
  /** レコードが更新されたタイムスタンプ */
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_field_file_load" */
export type Nb_Field_File_Load_Aggregate = {
  __typename?: 'nb_field_file_load_aggregate';
  aggregate?: Maybe<Nb_Field_File_Load_Aggregate_Fields>;
  nodes: Array<Nb_Field_File_Load>;
};

export type Nb_Field_File_Load_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_File_Load_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_File_Load_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_file_load" */
export type Nb_Field_File_Load_Aggregate_Fields = {
  __typename?: 'nb_field_file_load_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_File_Load_Max_Fields>;
  min?: Maybe<Nb_Field_File_Load_Min_Fields>;
};


/** aggregate fields of "nb_field_file_load" */
export type Nb_Field_File_Load_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_file_load" */
export type Nb_Field_File_Load_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_File_Load_Max_Order_By>;
  min?: InputMaybe<Nb_Field_File_Load_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_file_load" */
export type Nb_Field_File_Load_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_File_Load_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_File_Load_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_field_file_load". All fields are combined with a logical 'AND'. */
export type Nb_Field_File_Load_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_File_Load_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_File_Load_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  app_user_active_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_company_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_file?: InputMaybe<Nb_Field_File_Bool_Exp>;
  nb_field_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nb_ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Picture_Load_Status_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_file_load" */
export enum Nb_Field_File_Load_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldFileLoadPkey = 'nb_field_file_load_pkey'
}

/** input type for inserting data into table "nb_field_file_load" */
export type Nb_Field_File_Load_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  /** 撮影者 */
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** レコードが生成されたタイムスタンプ */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** ファイルコンテンツとなるデータを観測したタイムスタンプ。fileには日時データを持たない場合が多いので、Nullable。 */
  datetime?: InputMaybe<Scalars['timestamptz']>;
  /** 表示用に使用する画像のS3 key */
  file_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: InputMaybe<Scalars['String']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  /** 撮影者が属する会社 */
  nb_company_id?: InputMaybe<Scalars['uuid']>;
  nb_field_file?: InputMaybe<Nb_Field_File_Obj_Rel_Insert_Input>;
  nb_field_file_id?: InputMaybe<Scalars['uuid']>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  /** 紐付けたユーザ圃場 */
  nb_ufield_id?: InputMaybe<Scalars['uuid']>;
  /** ロードの状態 */
  status?: InputMaybe<Scalars['picture_load_status']>;
  /** レコードが更新されたタイムスタンプ */
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Field_File_Load_Max_Fields = {
  __typename?: 'nb_field_file_load_max_fields';
  /** 撮影者 */
  app_user_active_id?: Maybe<Scalars['uuid']>;
  /** レコードが生成されたタイムスタンプ */
  created?: Maybe<Scalars['timestamptz']>;
  /** ファイルコンテンツとなるデータを観測したタイムスタンプ。fileには日時データを持たない場合が多いので、Nullable。 */
  datetime?: Maybe<Scalars['timestamptz']>;
  /** 表示用に使用する画像のS3 key */
  file_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: Maybe<Scalars['String']>;
  /** 撮影者が属する会社 */
  nb_company_id?: Maybe<Scalars['uuid']>;
  nb_field_file_id?: Maybe<Scalars['uuid']>;
  /** 紐付けたユーザ圃場 */
  nb_ufield_id?: Maybe<Scalars['uuid']>;
  /** ロードの状態 */
  status?: Maybe<Scalars['picture_load_status']>;
  /** レコードが更新されたタイムスタンプ */
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_field_file_load" */
export type Nb_Field_File_Load_Max_Order_By = {
  /** 撮影者 */
  app_user_active_id?: InputMaybe<Order_By>;
  /** レコードが生成されたタイムスタンプ */
  created?: InputMaybe<Order_By>;
  /** ファイルコンテンツとなるデータを観測したタイムスタンプ。fileには日時データを持たない場合が多いので、Nullable。 */
  datetime?: InputMaybe<Order_By>;
  /** 表示用に使用する画像のS3 key */
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: InputMaybe<Order_By>;
  /** 撮影者が属する会社 */
  nb_company_id?: InputMaybe<Order_By>;
  nb_field_file_id?: InputMaybe<Order_By>;
  /** 紐付けたユーザ圃場 */
  nb_ufield_id?: InputMaybe<Order_By>;
  /** ロードの状態 */
  status?: InputMaybe<Order_By>;
  /** レコードが更新されたタイムスタンプ */
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_File_Load_Min_Fields = {
  __typename?: 'nb_field_file_load_min_fields';
  /** 撮影者 */
  app_user_active_id?: Maybe<Scalars['uuid']>;
  /** レコードが生成されたタイムスタンプ */
  created?: Maybe<Scalars['timestamptz']>;
  /** ファイルコンテンツとなるデータを観測したタイムスタンプ。fileには日時データを持たない場合が多いので、Nullable。 */
  datetime?: Maybe<Scalars['timestamptz']>;
  /** 表示用に使用する画像のS3 key */
  file_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: Maybe<Scalars['String']>;
  /** 撮影者が属する会社 */
  nb_company_id?: Maybe<Scalars['uuid']>;
  nb_field_file_id?: Maybe<Scalars['uuid']>;
  /** 紐付けたユーザ圃場 */
  nb_ufield_id?: Maybe<Scalars['uuid']>;
  /** ロードの状態 */
  status?: Maybe<Scalars['picture_load_status']>;
  /** レコードが更新されたタイムスタンプ */
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_field_file_load" */
export type Nb_Field_File_Load_Min_Order_By = {
  /** 撮影者 */
  app_user_active_id?: InputMaybe<Order_By>;
  /** レコードが生成されたタイムスタンプ */
  created?: InputMaybe<Order_By>;
  /** ファイルコンテンツとなるデータを観測したタイムスタンプ。fileには日時データを持たない場合が多いので、Nullable。 */
  datetime?: InputMaybe<Order_By>;
  /** 表示用に使用する画像のS3 key */
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: InputMaybe<Order_By>;
  /** 撮影者が属する会社 */
  nb_company_id?: InputMaybe<Order_By>;
  nb_field_file_id?: InputMaybe<Order_By>;
  /** 紐付けたユーザ圃場 */
  nb_ufield_id?: InputMaybe<Order_By>;
  /** ロードの状態 */
  status?: InputMaybe<Order_By>;
  /** レコードが更新されたタイムスタンプ */
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_file_load" */
export type Nb_Field_File_Load_Mutation_Response = {
  __typename?: 'nb_field_file_load_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_File_Load>;
};

/** on_conflict condition type for table "nb_field_file_load" */
export type Nb_Field_File_Load_On_Conflict = {
  constraint: Nb_Field_File_Load_Constraint;
  update_columns?: Array<Nb_Field_File_Load_Update_Column>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_file_load". */
export type Nb_Field_File_Load_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  app_user_active_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_company_id?: InputMaybe<Order_By>;
  nb_field_file?: InputMaybe<Nb_Field_File_Order_By>;
  nb_field_file_id?: InputMaybe<Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nb_ufield_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_file_load */
export type Nb_Field_File_Load_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_file_load" */
export enum Nb_Field_File_Load_Select_Column {
  /** column name */
  AppUserActiveId = 'app_user_active_id',
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  NbCompanyId = 'nb_company_id',
  /** column name */
  NbFieldFileId = 'nb_field_file_id',
  /** column name */
  NbUfieldId = 'nb_ufield_id',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_field_file_load" */
export type Nb_Field_File_Load_Set_Input = {
  /** 撮影者 */
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** レコードが生成されたタイムスタンプ */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** ファイルコンテンツとなるデータを観測したタイムスタンプ。fileには日時データを持たない場合が多いので、Nullable。 */
  datetime?: InputMaybe<Scalars['timestamptz']>;
  /** 表示用に使用する画像のS3 key */
  file_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: InputMaybe<Scalars['String']>;
  /** 撮影者が属する会社 */
  nb_company_id?: InputMaybe<Scalars['uuid']>;
  nb_field_file_id?: InputMaybe<Scalars['uuid']>;
  /** 紐付けたユーザ圃場 */
  nb_ufield_id?: InputMaybe<Scalars['uuid']>;
  /** ロードの状態 */
  status?: InputMaybe<Scalars['picture_load_status']>;
  /** レコードが更新されたタイムスタンプ */
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_field_file_load" */
export type Nb_Field_File_Load_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_File_Load_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_File_Load_Stream_Cursor_Value_Input = {
  /** 撮影者 */
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** レコードが生成されたタイムスタンプ */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** ファイルコンテンツとなるデータを観測したタイムスタンプ。fileには日時データを持たない場合が多いので、Nullable。 */
  datetime?: InputMaybe<Scalars['timestamptz']>;
  /** 表示用に使用する画像のS3 key */
  file_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: InputMaybe<Scalars['String']>;
  /** 撮影者が属する会社 */
  nb_company_id?: InputMaybe<Scalars['uuid']>;
  nb_field_file_id?: InputMaybe<Scalars['uuid']>;
  /** 紐付けたユーザ圃場 */
  nb_ufield_id?: InputMaybe<Scalars['uuid']>;
  /** ロードの状態 */
  status?: InputMaybe<Scalars['picture_load_status']>;
  /** レコードが更新されたタイムスタンプ */
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_field_file_load" */
export enum Nb_Field_File_Load_Update_Column {
  /** column name */
  AppUserActiveId = 'app_user_active_id',
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  NbCompanyId = 'nb_company_id',
  /** column name */
  NbFieldFileId = 'nb_field_file_id',
  /** column name */
  NbUfieldId = 'nb_ufield_id',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated'
}

export type Nb_Field_File_Load_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_File_Load_Set_Input>;
  where: Nb_Field_File_Load_Bool_Exp;
};

/** aggregate max on columns */
export type Nb_Field_File_Max_Fields = {
  __typename?: 'nb_field_file_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  file_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  upload_user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_field_file" */
export type Nb_Field_File_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upload_user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_File_Min_Fields = {
  __typename?: 'nb_field_file_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  file_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  upload_user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_field_file" */
export type Nb_Field_File_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upload_user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_file" */
export type Nb_Field_File_Mutation_Response = {
  __typename?: 'nb_field_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_File>;
};

/** input type for inserting object relation for remote table "nb_field_file" */
export type Nb_Field_File_Obj_Rel_Insert_Input = {
  data: Nb_Field_File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_File_On_Conflict>;
};

/** on_conflict condition type for table "nb_field_file" */
export type Nb_Field_File_On_Conflict = {
  constraint: Nb_Field_File_Constraint;
  update_columns?: Array<Nb_Field_File_Update_Column>;
  where?: InputMaybe<Nb_Field_File_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_file". */
export type Nb_Field_File_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_field_file_loads_aggregate?: InputMaybe<Nb_Field_File_Load_Aggregate_Order_By>;
  nb_field_file_size?: InputMaybe<Nb_Field_File_Size_Order_By>;
  nb_field_file_sizes_aggregate?: InputMaybe<Nb_Field_File_Size_Aggregate_Order_By>;
  nb_memo_file_links_aggregate?: InputMaybe<Nb_Memo_File_Link_Aggregate_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upload_user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_file */
export type Nb_Field_File_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_file" */
export enum Nb_Field_File_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UploadUserId = 'upload_user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_field_file" */
export type Nb_Field_File_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  file_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  upload_user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** nb_field_fileで管理されるファイルのサイズを保持する */
export type Nb_Field_File_Size = {
  __typename?: 'nb_field_file_size';
  company_key?: Maybe<Scalars['String']>;
  field_file_id: Scalars['uuid'];
  file_size_bytes: Scalars['bigint'];
  file_type: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_field_file: Nb_Field_File;
  total_size_bytes: Scalars['bigint'];
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "nb_field_file_size" */
export type Nb_Field_File_Size_Aggregate = {
  __typename?: 'nb_field_file_size_aggregate';
  aggregate?: Maybe<Nb_Field_File_Size_Aggregate_Fields>;
  nodes: Array<Nb_Field_File_Size>;
};

export type Nb_Field_File_Size_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_File_Size_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_File_Size_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_File_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_File_Size_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_file_size" */
export type Nb_Field_File_Size_Aggregate_Fields = {
  __typename?: 'nb_field_file_size_aggregate_fields';
  avg?: Maybe<Nb_Field_File_Size_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_File_Size_Max_Fields>;
  min?: Maybe<Nb_Field_File_Size_Min_Fields>;
  stddev?: Maybe<Nb_Field_File_Size_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Field_File_Size_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Field_File_Size_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Field_File_Size_Sum_Fields>;
  var_pop?: Maybe<Nb_Field_File_Size_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Field_File_Size_Var_Samp_Fields>;
  variance?: Maybe<Nb_Field_File_Size_Variance_Fields>;
};


/** aggregate fields of "nb_field_file_size" */
export type Nb_Field_File_Size_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_File_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_file_size" */
export type Nb_Field_File_Size_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Field_File_Size_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_File_Size_Max_Order_By>;
  min?: InputMaybe<Nb_Field_File_Size_Min_Order_By>;
  stddev?: InputMaybe<Nb_Field_File_Size_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Field_File_Size_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Field_File_Size_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Field_File_Size_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Field_File_Size_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Field_File_Size_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Field_File_Size_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_file_size" */
export type Nb_Field_File_Size_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_File_Size_Insert_Input>;
};

/** aggregate avg on columns */
export type Nb_Field_File_Size_Avg_Fields = {
  __typename?: 'nb_field_file_size_avg_fields';
  file_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_field_file_size" */
export type Nb_Field_File_Size_Avg_Order_By = {
  file_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_field_file_size". All fields are combined with a logical 'AND'. */
export type Nb_Field_File_Size_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_File_Size_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_File_Size_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_File_Size_Bool_Exp>>;
  company_key?: InputMaybe<String_Comparison_Exp>;
  field_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  file_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_file?: InputMaybe<Nb_Field_File_Bool_Exp>;
  total_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "nb_field_file_size" */
export type Nb_Field_File_Size_Inc_Input = {
  file_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "nb_field_file_size" */
export type Nb_Field_File_Size_Insert_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  field_file_id?: InputMaybe<Scalars['uuid']>;
  file_size_bytes?: InputMaybe<Scalars['bigint']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_file?: InputMaybe<Nb_Field_File_Obj_Rel_Insert_Input>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Field_File_Size_Max_Fields = {
  __typename?: 'nb_field_file_size_max_fields';
  company_key?: Maybe<Scalars['String']>;
  field_file_id?: Maybe<Scalars['uuid']>;
  file_size_bytes?: Maybe<Scalars['bigint']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_field_file_size" */
export type Nb_Field_File_Size_Max_Order_By = {
  company_key?: InputMaybe<Order_By>;
  field_file_id?: InputMaybe<Order_By>;
  file_size_bytes?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_File_Size_Min_Fields = {
  __typename?: 'nb_field_file_size_min_fields';
  company_key?: Maybe<Scalars['String']>;
  field_file_id?: Maybe<Scalars['uuid']>;
  file_size_bytes?: Maybe<Scalars['bigint']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_field_file_size" */
export type Nb_Field_File_Size_Min_Order_By = {
  company_key?: InputMaybe<Order_By>;
  field_file_id?: InputMaybe<Order_By>;
  file_size_bytes?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_file_size" */
export type Nb_Field_File_Size_Mutation_Response = {
  __typename?: 'nb_field_file_size_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_File_Size>;
};

/** input type for inserting object relation for remote table "nb_field_file_size" */
export type Nb_Field_File_Size_Obj_Rel_Insert_Input = {
  data: Nb_Field_File_Size_Insert_Input;
};

/** Ordering options when selecting data from "nb_field_file_size". */
export type Nb_Field_File_Size_Order_By = {
  company_key?: InputMaybe<Order_By>;
  field_file_id?: InputMaybe<Order_By>;
  file_size_bytes?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_file?: InputMaybe<Nb_Field_File_Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "nb_field_file_size" */
export enum Nb_Field_File_Size_Select_Column {
  /** column name */
  CompanyKey = 'company_key',
  /** column name */
  FieldFileId = 'field_file_id',
  /** column name */
  FileSizeBytes = 'file_size_bytes',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  TotalSizeBytes = 'total_size_bytes',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "nb_field_file_size" */
export type Nb_Field_File_Size_Set_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  field_file_id?: InputMaybe<Scalars['uuid']>;
  file_size_bytes?: InputMaybe<Scalars['bigint']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Nb_Field_File_Size_Stddev_Fields = {
  __typename?: 'nb_field_file_size_stddev_fields';
  file_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_field_file_size" */
export type Nb_Field_File_Size_Stddev_Order_By = {
  file_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Field_File_Size_Stddev_Pop_Fields = {
  __typename?: 'nb_field_file_size_stddev_pop_fields';
  file_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_field_file_size" */
export type Nb_Field_File_Size_Stddev_Pop_Order_By = {
  file_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Field_File_Size_Stddev_Samp_Fields = {
  __typename?: 'nb_field_file_size_stddev_samp_fields';
  file_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_field_file_size" */
export type Nb_Field_File_Size_Stddev_Samp_Order_By = {
  file_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_field_file_size" */
export type Nb_Field_File_Size_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_File_Size_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_File_Size_Stream_Cursor_Value_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  field_file_id?: InputMaybe<Scalars['uuid']>;
  file_size_bytes?: InputMaybe<Scalars['bigint']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nb_Field_File_Size_Sum_Fields = {
  __typename?: 'nb_field_file_size_sum_fields';
  file_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "nb_field_file_size" */
export type Nb_Field_File_Size_Sum_Order_By = {
  file_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

export type Nb_Field_File_Size_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Field_File_Size_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_File_Size_Set_Input>;
  where: Nb_Field_File_Size_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Field_File_Size_Var_Pop_Fields = {
  __typename?: 'nb_field_file_size_var_pop_fields';
  file_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_field_file_size" */
export type Nb_Field_File_Size_Var_Pop_Order_By = {
  file_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Field_File_Size_Var_Samp_Fields = {
  __typename?: 'nb_field_file_size_var_samp_fields';
  file_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_field_file_size" */
export type Nb_Field_File_Size_Var_Samp_Order_By = {
  file_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Field_File_Size_Variance_Fields = {
  __typename?: 'nb_field_file_size_variance_fields';
  file_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_field_file_size" */
export type Nb_Field_File_Size_Variance_Order_By = {
  file_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_field_file" */
export type Nb_Field_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_File_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  file_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  upload_user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_field_file" */
export enum Nb_Field_File_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UploadUserId = 'upload_user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Field_File_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_File_Set_Input>;
  where: Nb_Field_File_Bool_Exp;
};

/** columns and relationships of "nb_field_memo" */
export type Nb_Field_Memo = {
  __typename?: 'nb_field_memo';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  created: Scalars['timestamptz'];
  creator_name: Scalars['String'];
  id: Scalars['uuid'];
  memo: Scalars['String'];
  /** An array relationship */
  nb_memo_file_links: Array<Nb_Memo_File_Link>;
  /** An aggregate relationship */
  nb_memo_file_links_aggregate: Nb_Memo_File_Link_Aggregate;
  /** An array relationship */
  nb_memo_picture_links: Array<Nb_Memo_Picture_Link>;
  /** An aggregate relationship */
  nb_memo_picture_links_aggregate: Nb_Memo_Picture_Link_Aggregate;
  /** An array relationship */
  nb_memo_ufg_links: Array<Nb_Memo_Ufg_Link>;
  /** An aggregate relationship */
  nb_memo_ufg_links_aggregate: Nb_Memo_Ufg_Link_Aggregate;
  /** An array relationship */
  nb_memo_ufield_links: Array<Nb_Memo_Ufield_Link>;
  /** An aggregate relationship */
  nb_memo_ufield_links_aggregate: Nb_Memo_Ufield_Link_Aggregate;
  updated: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nb_field_memo" */
export type Nb_Field_MemoNb_Memo_File_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_File_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_File_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_memo" */
export type Nb_Field_MemoNb_Memo_File_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_File_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_File_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_memo" */
export type Nb_Field_MemoNb_Memo_Picture_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Picture_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Picture_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_memo" */
export type Nb_Field_MemoNb_Memo_Picture_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Picture_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Picture_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_memo" */
export type Nb_Field_MemoNb_Memo_Ufg_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_memo" */
export type Nb_Field_MemoNb_Memo_Ufg_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_memo" */
export type Nb_Field_MemoNb_Memo_Ufield_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufield_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_memo" */
export type Nb_Field_MemoNb_Memo_Ufield_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufield_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
};

/** aggregated selection of "nb_field_memo" */
export type Nb_Field_Memo_Aggregate = {
  __typename?: 'nb_field_memo_aggregate';
  aggregate?: Maybe<Nb_Field_Memo_Aggregate_Fields>;
  nodes: Array<Nb_Field_Memo>;
};

export type Nb_Field_Memo_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Memo_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Memo_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Memo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_memo" */
export type Nb_Field_Memo_Aggregate_Fields = {
  __typename?: 'nb_field_memo_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Memo_Max_Fields>;
  min?: Maybe<Nb_Field_Memo_Min_Fields>;
};


/** aggregate fields of "nb_field_memo" */
export type Nb_Field_Memo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Memo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_memo" */
export type Nb_Field_Memo_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Memo_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Memo_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_memo" */
export type Nb_Field_Memo_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Memo_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Memo_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_field_memo". All fields are combined with a logical 'AND'. */
export type Nb_Field_Memo_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Memo_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Memo_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  memo?: InputMaybe<String_Comparison_Exp>;
  nb_memo_file_links?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
  nb_memo_file_links_aggregate?: InputMaybe<Nb_Memo_File_Link_Aggregate_Bool_Exp>;
  nb_memo_picture_links?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
  nb_memo_picture_links_aggregate?: InputMaybe<Nb_Memo_Picture_Link_Aggregate_Bool_Exp>;
  nb_memo_ufg_links?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
  nb_memo_ufg_links_aggregate?: InputMaybe<Nb_Memo_Ufg_Link_Aggregate_Bool_Exp>;
  nb_memo_ufield_links?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
  nb_memo_ufield_links_aggregate?: InputMaybe<Nb_Memo_Ufield_Link_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_memo" */
export enum Nb_Field_Memo_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldMemoPkey = 'nb_field_memo_pkey'
}

/** input type for inserting data into table "nb_field_memo" */
export type Nb_Field_Memo_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  nb_memo_file_links?: InputMaybe<Nb_Memo_File_Link_Arr_Rel_Insert_Input>;
  nb_memo_picture_links?: InputMaybe<Nb_Memo_Picture_Link_Arr_Rel_Insert_Input>;
  nb_memo_ufg_links?: InputMaybe<Nb_Memo_Ufg_Link_Arr_Rel_Insert_Input>;
  nb_memo_ufield_links?: InputMaybe<Nb_Memo_Ufield_Link_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Field_Memo_Max_Fields = {
  __typename?: 'nb_field_memo_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  memo?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_field_memo" */
export type Nb_Field_Memo_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  memo?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Memo_Min_Fields = {
  __typename?: 'nb_field_memo_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  memo?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_field_memo" */
export type Nb_Field_Memo_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  memo?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_memo" */
export type Nb_Field_Memo_Mutation_Response = {
  __typename?: 'nb_field_memo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Memo>;
};

/** input type for inserting object relation for remote table "nb_field_memo" */
export type Nb_Field_Memo_Obj_Rel_Insert_Input = {
  data: Nb_Field_Memo_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Memo_On_Conflict>;
};

/** on_conflict condition type for table "nb_field_memo" */
export type Nb_Field_Memo_On_Conflict = {
  constraint: Nb_Field_Memo_Constraint;
  update_columns?: Array<Nb_Field_Memo_Update_Column>;
  where?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_memo". */
export type Nb_Field_Memo_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  memo?: InputMaybe<Order_By>;
  nb_memo_file_links_aggregate?: InputMaybe<Nb_Memo_File_Link_Aggregate_Order_By>;
  nb_memo_picture_links_aggregate?: InputMaybe<Nb_Memo_Picture_Link_Aggregate_Order_By>;
  nb_memo_ufg_links_aggregate?: InputMaybe<Nb_Memo_Ufg_Link_Aggregate_Order_By>;
  nb_memo_ufield_links_aggregate?: InputMaybe<Nb_Memo_Ufield_Link_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_memo */
export type Nb_Field_Memo_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_memo" */
export enum Nb_Field_Memo_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  Id = 'id',
  /** column name */
  Memo = 'memo',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_field_memo" */
export type Nb_Field_Memo_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_field_memo" */
export type Nb_Field_Memo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Memo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Memo_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_field_memo" */
export enum Nb_Field_Memo_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  Id = 'id',
  /** column name */
  Memo = 'memo',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Field_Memo_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Memo_Set_Input>;
  where: Nb_Field_Memo_Bool_Exp;
};

/** 圃場単位のオルソ画像の管理テーブル */
export type Nb_Field_Orthoimg = {
  __typename?: 'nb_field_orthoimg';
  /** レコードの作成日時 */
  created: Scalars['timestamptz'];
  /** 一意な識別子 */
  id: Scalars['uuid'];
  /** An array relationship */
  nb_field_orthoimg_sizes: Array<Nb_Field_Orthoimg_Size>;
  /** An aggregate relationship */
  nb_field_orthoimg_sizes_aggregate: Nb_Field_Orthoimg_Size_Aggregate;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  /** An object relationship */
  nb_wide_orthoimg: Nb_Wide_Orthoimg;
  /** An object relationship */
  nbbe_orthoimg_type: Nbbe_Orthoimg_Type;
  /** An array relationship */
  nbs_ipe_run_link_fields: Array<Nbs_Ipe_Run_Link_Field>;
  /** An aggregate relationship */
  nbs_ipe_run_link_fields_aggregate: Nbs_Ipe_Run_Link_Field_Aggregate;
  /** オルソ画像の種別 */
  orthoimg_type: Scalars['String'];
  /** オルソ画像のS3 URI */
  s3uri: Scalars['String'];
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime: Scalars['timestamptz'];
  /** 紐づくユーザ圃場のID */
  ufield_id: Scalars['uuid'];
  /** レコードの更新日時 */
  updated: Scalars['timestamptz'];
  /** 分割前の広域オルソ画像のID */
  wide_orthoimg_id: Scalars['uuid'];
};


/** 圃場単位のオルソ画像の管理テーブル */
export type Nb_Field_OrthoimgNb_Field_Orthoimg_SizesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Size_Bool_Exp>;
};


/** 圃場単位のオルソ画像の管理テーブル */
export type Nb_Field_OrthoimgNb_Field_Orthoimg_Sizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Size_Bool_Exp>;
};


/** 圃場単位のオルソ画像の管理テーブル */
export type Nb_Field_OrthoimgNbs_Ipe_Run_Link_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
};


/** 圃場単位のオルソ画像の管理テーブル */
export type Nb_Field_OrthoimgNbs_Ipe_Run_Link_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
};

/** aggregated selection of "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Aggregate = {
  __typename?: 'nb_field_orthoimg_aggregate';
  aggregate?: Maybe<Nb_Field_Orthoimg_Aggregate_Fields>;
  nodes: Array<Nb_Field_Orthoimg>;
};

export type Nb_Field_Orthoimg_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Orthoimg_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Orthoimg_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Aggregate_Fields = {
  __typename?: 'nb_field_orthoimg_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Orthoimg_Max_Fields>;
  min?: Maybe<Nb_Field_Orthoimg_Min_Fields>;
};


/** aggregate fields of "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Orthoimg_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Orthoimg_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Orthoimg_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Orthoimg_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_field_orthoimg". All fields are combined with a logical 'AND'. */
export type Nb_Field_Orthoimg_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Orthoimg_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Orthoimg_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_orthoimg_sizes?: InputMaybe<Nb_Field_Orthoimg_Size_Bool_Exp>;
  nb_field_orthoimg_sizes_aggregate?: InputMaybe<Nb_Field_Orthoimg_Size_Aggregate_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Bool_Exp>;
  nbs_ipe_run_link_fields?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
  nbs_ipe_run_link_fields_aggregate?: InputMaybe<Nbs_Ipe_Run_Link_Field_Aggregate_Bool_Exp>;
  orthoimg_type?: InputMaybe<String_Comparison_Exp>;
  s3uri?: InputMaybe<String_Comparison_Exp>;
  sens_datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  wide_orthoimg_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_orthoimg" */
export enum Nb_Field_Orthoimg_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldOrthoimgPkey = 'nb_field_orthoimg_pkey'
}

/** input type for inserting data into table "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Insert_Input = {
  /** レコードの作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_orthoimg_sizes?: InputMaybe<Nb_Field_Orthoimg_Size_Arr_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Obj_Rel_Insert_Input>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Obj_Rel_Insert_Input>;
  nbs_ipe_run_link_fields?: InputMaybe<Nbs_Ipe_Run_Link_Field_Arr_Rel_Insert_Input>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Scalars['String']>;
  /** オルソ画像のS3 URI */
  s3uri?: InputMaybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** 紐づくユーザ圃場のID */
  ufield_id?: InputMaybe<Scalars['uuid']>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** 分割前の広域オルソ画像のID */
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Field_Orthoimg_Max_Fields = {
  __typename?: 'nb_field_orthoimg_max_fields';
  /** レコードの作成日時 */
  created?: Maybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: Maybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: Maybe<Scalars['String']>;
  /** オルソ画像のS3 URI */
  s3uri?: Maybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: Maybe<Scalars['timestamptz']>;
  /** 紐づくユーザ圃場のID */
  ufield_id?: Maybe<Scalars['uuid']>;
  /** レコードの更新日時 */
  updated?: Maybe<Scalars['timestamptz']>;
  /** 分割前の広域オルソ画像のID */
  wide_orthoimg_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Max_Order_By = {
  /** レコードの作成日時 */
  created?: InputMaybe<Order_By>;
  /** 一意な識別子 */
  id?: InputMaybe<Order_By>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Order_By>;
  /** オルソ画像のS3 URI */
  s3uri?: InputMaybe<Order_By>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Order_By>;
  /** 紐づくユーザ圃場のID */
  ufield_id?: InputMaybe<Order_By>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Order_By>;
  /** 分割前の広域オルソ画像のID */
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Orthoimg_Min_Fields = {
  __typename?: 'nb_field_orthoimg_min_fields';
  /** レコードの作成日時 */
  created?: Maybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: Maybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: Maybe<Scalars['String']>;
  /** オルソ画像のS3 URI */
  s3uri?: Maybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: Maybe<Scalars['timestamptz']>;
  /** 紐づくユーザ圃場のID */
  ufield_id?: Maybe<Scalars['uuid']>;
  /** レコードの更新日時 */
  updated?: Maybe<Scalars['timestamptz']>;
  /** 分割前の広域オルソ画像のID */
  wide_orthoimg_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Min_Order_By = {
  /** レコードの作成日時 */
  created?: InputMaybe<Order_By>;
  /** 一意な識別子 */
  id?: InputMaybe<Order_By>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Order_By>;
  /** オルソ画像のS3 URI */
  s3uri?: InputMaybe<Order_By>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Order_By>;
  /** 紐づくユーザ圃場のID */
  ufield_id?: InputMaybe<Order_By>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Order_By>;
  /** 分割前の広域オルソ画像のID */
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Mutation_Response = {
  __typename?: 'nb_field_orthoimg_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Orthoimg>;
};

/** input type for inserting object relation for remote table "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Obj_Rel_Insert_Input = {
  data: Nb_Field_Orthoimg_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Orthoimg_On_Conflict>;
};

/** on_conflict condition type for table "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_On_Conflict = {
  constraint: Nb_Field_Orthoimg_Constraint;
  update_columns?: Array<Nb_Field_Orthoimg_Update_Column>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_orthoimg". */
export type Nb_Field_Orthoimg_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_orthoimg_sizes_aggregate?: InputMaybe<Nb_Field_Orthoimg_Size_Aggregate_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Order_By>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Order_By>;
  nbs_ipe_run_link_fields_aggregate?: InputMaybe<Nbs_Ipe_Run_Link_Field_Aggregate_Order_By>;
  orthoimg_type?: InputMaybe<Order_By>;
  s3uri?: InputMaybe<Order_By>;
  sens_datetime?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_orthoimg */
export type Nb_Field_Orthoimg_Pk_Columns_Input = {
  /** 一意な識別子 */
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_orthoimg" */
export enum Nb_Field_Orthoimg_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  OrthoimgType = 'orthoimg_type',
  /** column name */
  S3uri = 's3uri',
  /** column name */
  SensDatetime = 'sens_datetime',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  WideOrthoimgId = 'wide_orthoimg_id'
}

/** input type for updating data in table "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Set_Input = {
  /** レコードの作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Scalars['String']>;
  /** オルソ画像のS3 URI */
  s3uri?: InputMaybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** 紐づくユーザ圃場のID */
  ufield_id?: InputMaybe<Scalars['uuid']>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** 分割前の広域オルソ画像のID */
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** nb_field_orthoimgで管理されるファイルのサイズを保持する */
export type Nb_Field_Orthoimg_Size = {
  __typename?: 'nb_field_orthoimg_size';
  company_key?: Maybe<Scalars['String']>;
  field_orthoimg_id: Scalars['uuid'];
  file_type: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_field_orthoimg: Nb_Field_Orthoimg;
  s3obj_size_bytes: Scalars['bigint'];
  total_size_bytes: Scalars['bigint'];
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Aggregate = {
  __typename?: 'nb_field_orthoimg_size_aggregate';
  aggregate?: Maybe<Nb_Field_Orthoimg_Size_Aggregate_Fields>;
  nodes: Array<Nb_Field_Orthoimg_Size>;
};

export type Nb_Field_Orthoimg_Size_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Orthoimg_Size_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Orthoimg_Size_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Orthoimg_Size_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Aggregate_Fields = {
  __typename?: 'nb_field_orthoimg_size_aggregate_fields';
  avg?: Maybe<Nb_Field_Orthoimg_Size_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Orthoimg_Size_Max_Fields>;
  min?: Maybe<Nb_Field_Orthoimg_Size_Min_Fields>;
  stddev?: Maybe<Nb_Field_Orthoimg_Size_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Field_Orthoimg_Size_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Field_Orthoimg_Size_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Field_Orthoimg_Size_Sum_Fields>;
  var_pop?: Maybe<Nb_Field_Orthoimg_Size_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Field_Orthoimg_Size_Var_Samp_Fields>;
  variance?: Maybe<Nb_Field_Orthoimg_Size_Variance_Fields>;
};


/** aggregate fields of "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Field_Orthoimg_Size_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Orthoimg_Size_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Orthoimg_Size_Min_Order_By>;
  stddev?: InputMaybe<Nb_Field_Orthoimg_Size_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Field_Orthoimg_Size_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Field_Orthoimg_Size_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Field_Orthoimg_Size_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Field_Orthoimg_Size_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Field_Orthoimg_Size_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Field_Orthoimg_Size_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Orthoimg_Size_Insert_Input>;
};

/** aggregate avg on columns */
export type Nb_Field_Orthoimg_Size_Avg_Fields = {
  __typename?: 'nb_field_orthoimg_size_avg_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Avg_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_field_orthoimg_size". All fields are combined with a logical 'AND'. */
export type Nb_Field_Orthoimg_Size_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Orthoimg_Size_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Bool_Exp>>;
  company_key?: InputMaybe<String_Comparison_Exp>;
  field_orthoimg_id?: InputMaybe<Uuid_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_orthoimg?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
  s3obj_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  total_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Inc_Input = {
  s3obj_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Insert_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  field_orthoimg_id?: InputMaybe<Scalars['uuid']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_orthoimg?: InputMaybe<Nb_Field_Orthoimg_Obj_Rel_Insert_Input>;
  s3obj_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Field_Orthoimg_Size_Max_Fields = {
  __typename?: 'nb_field_orthoimg_size_max_fields';
  company_key?: Maybe<Scalars['String']>;
  field_orthoimg_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  s3obj_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Max_Order_By = {
  company_key?: InputMaybe<Order_By>;
  field_orthoimg_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Orthoimg_Size_Min_Fields = {
  __typename?: 'nb_field_orthoimg_size_min_fields';
  company_key?: Maybe<Scalars['String']>;
  field_orthoimg_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  s3obj_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Min_Order_By = {
  company_key?: InputMaybe<Order_By>;
  field_orthoimg_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Mutation_Response = {
  __typename?: 'nb_field_orthoimg_size_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Orthoimg_Size>;
};

/** Ordering options when selecting data from "nb_field_orthoimg_size". */
export type Nb_Field_Orthoimg_Size_Order_By = {
  company_key?: InputMaybe<Order_By>;
  field_orthoimg_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_orthoimg?: InputMaybe<Nb_Field_Orthoimg_Order_By>;
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "nb_field_orthoimg_size" */
export enum Nb_Field_Orthoimg_Size_Select_Column {
  /** column name */
  CompanyKey = 'company_key',
  /** column name */
  FieldOrthoimgId = 'field_orthoimg_id',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  S3objSizeBytes = 's3obj_size_bytes',
  /** column name */
  TotalSizeBytes = 'total_size_bytes',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Set_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  field_orthoimg_id?: InputMaybe<Scalars['uuid']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  s3obj_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Nb_Field_Orthoimg_Size_Stddev_Fields = {
  __typename?: 'nb_field_orthoimg_size_stddev_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Stddev_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Field_Orthoimg_Size_Stddev_Pop_Fields = {
  __typename?: 'nb_field_orthoimg_size_stddev_pop_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Stddev_Pop_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Field_Orthoimg_Size_Stddev_Samp_Fields = {
  __typename?: 'nb_field_orthoimg_size_stddev_samp_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Stddev_Samp_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Orthoimg_Size_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Orthoimg_Size_Stream_Cursor_Value_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  field_orthoimg_id?: InputMaybe<Scalars['uuid']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  s3obj_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nb_Field_Orthoimg_Size_Sum_Fields = {
  __typename?: 'nb_field_orthoimg_size_sum_fields';
  s3obj_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Sum_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

export type Nb_Field_Orthoimg_Size_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Field_Orthoimg_Size_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Orthoimg_Size_Set_Input>;
  where: Nb_Field_Orthoimg_Size_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Field_Orthoimg_Size_Var_Pop_Fields = {
  __typename?: 'nb_field_orthoimg_size_var_pop_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Var_Pop_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Field_Orthoimg_Size_Var_Samp_Fields = {
  __typename?: 'nb_field_orthoimg_size_var_samp_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Var_Samp_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Field_Orthoimg_Size_Variance_Fields = {
  __typename?: 'nb_field_orthoimg_size_variance_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_field_orthoimg_size" */
export type Nb_Field_Orthoimg_Size_Variance_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_field_orthoimg" */
export type Nb_Field_Orthoimg_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Orthoimg_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Orthoimg_Stream_Cursor_Value_Input = {
  /** レコードの作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Scalars['String']>;
  /** オルソ画像のS3 URI */
  s3uri?: InputMaybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** 紐づくユーザ圃場のID */
  ufield_id?: InputMaybe<Scalars['uuid']>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** 分割前の広域オルソ画像のID */
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nb_field_orthoimg" */
export enum Nb_Field_Orthoimg_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  OrthoimgType = 'orthoimg_type',
  /** column name */
  S3uri = 's3uri',
  /** column name */
  SensDatetime = 'sens_datetime',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  WideOrthoimgId = 'wide_orthoimg_id'
}

export type Nb_Field_Orthoimg_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Orthoimg_Set_Input>;
  where: Nb_Field_Orthoimg_Bool_Exp;
};

/** columns and relationships of "nb_field_photographer" */
export type Nb_Field_Photographer = {
  __typename?: 'nb_field_photographer';
  /** An object relationship */
  app_user_active: App_User_Active;
  created: Scalars['timestamptz'];
  field_picture_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_field_picture: Nb_Field_Picture;
  photographer_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_field_photographer" */
export type Nb_Field_Photographer_Aggregate = {
  __typename?: 'nb_field_photographer_aggregate';
  aggregate?: Maybe<Nb_Field_Photographer_Aggregate_Fields>;
  nodes: Array<Nb_Field_Photographer>;
};

export type Nb_Field_Photographer_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Photographer_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Photographer_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Photographer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_photographer" */
export type Nb_Field_Photographer_Aggregate_Fields = {
  __typename?: 'nb_field_photographer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Photographer_Max_Fields>;
  min?: Maybe<Nb_Field_Photographer_Min_Fields>;
};


/** aggregate fields of "nb_field_photographer" */
export type Nb_Field_Photographer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Photographer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_photographer" */
export type Nb_Field_Photographer_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Photographer_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Photographer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_photographer" */
export type Nb_Field_Photographer_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Photographer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Photographer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_field_photographer". All fields are combined with a logical 'AND'. */
export type Nb_Field_Photographer_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Photographer_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Photographer_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_picture_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
  photographer_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_photographer" */
export enum Nb_Field_Photographer_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldPhtographerPkey = 'nb_field_phtographer_pkey'
}

/** input type for inserting data into table "nb_field_photographer" */
export type Nb_Field_Photographer_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Obj_Rel_Insert_Input>;
  photographer_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Field_Photographer_Max_Fields = {
  __typename?: 'nb_field_photographer_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_picture_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  photographer_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_field_photographer" */
export type Nb_Field_Photographer_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photographer_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Photographer_Min_Fields = {
  __typename?: 'nb_field_photographer_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_picture_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  photographer_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_field_photographer" */
export type Nb_Field_Photographer_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  photographer_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_photographer" */
export type Nb_Field_Photographer_Mutation_Response = {
  __typename?: 'nb_field_photographer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Photographer>;
};

/** on_conflict condition type for table "nb_field_photographer" */
export type Nb_Field_Photographer_On_Conflict = {
  constraint: Nb_Field_Photographer_Constraint;
  update_columns?: Array<Nb_Field_Photographer_Update_Column>;
  where?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_photographer". */
export type Nb_Field_Photographer_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Order_By>;
  photographer_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_photographer */
export type Nb_Field_Photographer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_photographer" */
export enum Nb_Field_Photographer_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldPictureId = 'field_picture_id',
  /** column name */
  Id = 'id',
  /** column name */
  PhotographerId = 'photographer_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_field_photographer" */
export type Nb_Field_Photographer_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  photographer_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_field_photographer" */
export type Nb_Field_Photographer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Photographer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Photographer_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  photographer_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_field_photographer" */
export enum Nb_Field_Photographer_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldPictureId = 'field_picture_id',
  /** column name */
  Id = 'id',
  /** column name */
  PhotographerId = 'photographer_id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Field_Photographer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Photographer_Set_Input>;
  where: Nb_Field_Photographer_Bool_Exp;
};

/** columns and relationships of "nb_field_picture" */
export type Nb_Field_Picture = {
  __typename?: 'nb_field_picture';
  cap_coor: Scalars['geometry'];
  created: Scalars['timestamptz'];
  datetime: Scalars['timestamptz'];
  detail_info?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  image_path: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  /** An array relationship */
  nb_field_phtographers: Array<Nb_Field_Photographer>;
  /** An aggregate relationship */
  nb_field_phtographers_aggregate: Nb_Field_Photographer_Aggregate;
  /** An array relationship */
  nb_field_picture_cplan_links: Array<Nb_Field_Picture_Cplan_Link>;
  /** An aggregate relationship */
  nb_field_picture_cplan_links_aggregate: Nb_Field_Picture_Cplan_Link_Aggregate;
  /** An array relationship */
  nb_field_picture_loads: Array<Nb_Field_Picture_Load>;
  /** An aggregate relationship */
  nb_field_picture_loads_aggregate: Nb_Field_Picture_Load_Aggregate;
  /** An object relationship */
  nb_field_picture_size?: Maybe<Nb_Field_Picture_Size>;
  /** An array relationship */
  nb_field_picture_sizes: Array<Nb_Field_Picture_Size>;
  /** An aggregate relationship */
  nb_field_picture_sizes_aggregate: Nb_Field_Picture_Size_Aggregate;
  /** An array relationship */
  nb_memo_picture_links: Array<Nb_Memo_Picture_Link>;
  /** An aggregate relationship */
  nb_memo_picture_links_aggregate: Nb_Memo_Picture_Link_Aggregate;
  /** An array relationship */
  nb_picture_analyses: Array<Nb_Picture_Analysis>;
  /** An aggregate relationship */
  nb_picture_analyses_aggregate: Nb_Picture_Analysis_Aggregate;
  /** An object relationship */
  nb_ufield?: Maybe<Nb_Ufield>;
  /** An array relationship */
  nbb_picture_analysis_statuses: Array<Nbb_Picture_Analysis_Status>;
  /** An aggregate relationship */
  nbb_picture_analysis_statuses_aggregate: Nbb_Picture_Analysis_Status_Aggregate;
  raw_path?: Maybe<Scalars['String']>;
  thumbnail_path: Scalars['String'];
  updated: Scalars['timestamptz'];
  user_field_id?: Maybe<Scalars['uuid']>;
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureDetail_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Field_PhtographersArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Photographer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Photographer_Order_By>>;
  where?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Field_Phtographers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Photographer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Photographer_Order_By>>;
  where?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Field_Picture_Cplan_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Field_Picture_Cplan_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Field_Picture_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Field_Picture_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Field_Picture_SizesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Size_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Field_Picture_Sizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Size_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Memo_Picture_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Picture_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Picture_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Memo_Picture_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Picture_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Picture_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Picture_AnalysesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNb_Picture_Analyses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNbb_Picture_Analysis_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Order_By>>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};


/** columns and relationships of "nb_field_picture" */
export type Nb_Field_PictureNbb_Picture_Analysis_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Order_By>>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};

/** aggregated selection of "nb_field_picture" */
export type Nb_Field_Picture_Aggregate = {
  __typename?: 'nb_field_picture_aggregate';
  aggregate?: Maybe<Nb_Field_Picture_Aggregate_Fields>;
  nodes: Array<Nb_Field_Picture>;
};

export type Nb_Field_Picture_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Picture_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Picture_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Picture_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_picture" */
export type Nb_Field_Picture_Aggregate_Fields = {
  __typename?: 'nb_field_picture_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Picture_Max_Fields>;
  min?: Maybe<Nb_Field_Picture_Min_Fields>;
};


/** aggregate fields of "nb_field_picture" */
export type Nb_Field_Picture_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Picture_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_picture" */
export type Nb_Field_Picture_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Picture_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Picture_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Nb_Field_Picture_Append_Input = {
  detail_info?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "nb_field_picture" */
export type Nb_Field_Picture_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Picture_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Picture_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_field_picture". All fields are combined with a logical 'AND'. */
export type Nb_Field_Picture_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Picture_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Picture_Bool_Exp>>;
  cap_coor?: InputMaybe<Geometry_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  detail_info?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image_path?: InputMaybe<String_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  nb_field_phtographers?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
  nb_field_phtographers_aggregate?: InputMaybe<Nb_Field_Photographer_Aggregate_Bool_Exp>;
  nb_field_picture_cplan_links?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
  nb_field_picture_cplan_links_aggregate?: InputMaybe<Nb_Field_Picture_Cplan_Link_Aggregate_Bool_Exp>;
  nb_field_picture_loads?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
  nb_field_picture_loads_aggregate?: InputMaybe<Nb_Field_Picture_Load_Aggregate_Bool_Exp>;
  nb_field_picture_size?: InputMaybe<Nb_Field_Picture_Size_Bool_Exp>;
  nb_field_picture_sizes?: InputMaybe<Nb_Field_Picture_Size_Bool_Exp>;
  nb_field_picture_sizes_aggregate?: InputMaybe<Nb_Field_Picture_Size_Aggregate_Bool_Exp>;
  nb_memo_picture_links?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
  nb_memo_picture_links_aggregate?: InputMaybe<Nb_Memo_Picture_Link_Aggregate_Bool_Exp>;
  nb_picture_analyses?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
  nb_picture_analyses_aggregate?: InputMaybe<Nb_Picture_Analysis_Aggregate_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nbb_picture_analysis_statuses?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
  nbb_picture_analysis_statuses_aggregate?: InputMaybe<Nbb_Picture_Analysis_Status_Aggregate_Bool_Exp>;
  raw_path?: InputMaybe<String_Comparison_Exp>;
  thumbnail_path?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_picture" */
export enum Nb_Field_Picture_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldPicturePkey = 'nb_field_picture_pkey'
}

/** columns and relationships of "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link = {
  __typename?: 'nb_field_picture_cplan_link';
  created: Scalars['timestamptz'];
  cultivation_plan_id: Scalars['uuid'];
  field_capture_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_cultivation_plan: Nb_Cultivation_Plan;
  /** An object relationship */
  nb_field_picture: Nb_Field_Picture;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_Aggregate = {
  __typename?: 'nb_field_picture_cplan_link_aggregate';
  aggregate?: Maybe<Nb_Field_Picture_Cplan_Link_Aggregate_Fields>;
  nodes: Array<Nb_Field_Picture_Cplan_Link>;
};

export type Nb_Field_Picture_Cplan_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Picture_Cplan_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Picture_Cplan_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_Aggregate_Fields = {
  __typename?: 'nb_field_picture_cplan_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Picture_Cplan_Link_Max_Fields>;
  min?: Maybe<Nb_Field_Picture_Cplan_Link_Min_Fields>;
};


/** aggregate fields of "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Picture_Cplan_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Picture_Cplan_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Picture_Cplan_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Picture_Cplan_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_field_picture_cplan_link". All fields are combined with a logical 'AND'. */
export type Nb_Field_Picture_Cplan_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  cultivation_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  field_capture_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_picture_cplan_link" */
export enum Nb_Field_Picture_Cplan_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldPictureCplanLinkPkey = 'nb_field_picture_cplan_link_pkey'
}

/** input type for inserting data into table "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  field_capture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Obj_Rel_Insert_Input>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Field_Picture_Cplan_Link_Max_Fields = {
  __typename?: 'nb_field_picture_cplan_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  field_capture_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  field_capture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Picture_Cplan_Link_Min_Fields = {
  __typename?: 'nb_field_picture_cplan_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  field_capture_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  field_capture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_Mutation_Response = {
  __typename?: 'nb_field_picture_cplan_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Picture_Cplan_Link>;
};

/** on_conflict condition type for table "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_On_Conflict = {
  constraint: Nb_Field_Picture_Cplan_Link_Constraint;
  update_columns?: Array<Nb_Field_Picture_Cplan_Link_Update_Column>;
  where?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_picture_cplan_link". */
export type Nb_Field_Picture_Cplan_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  field_capture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Order_By>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_picture_cplan_link */
export type Nb_Field_Picture_Cplan_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_picture_cplan_link" */
export enum Nb_Field_Picture_Cplan_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  FieldCaptureId = 'field_capture_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  field_capture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_field_picture_cplan_link" */
export type Nb_Field_Picture_Cplan_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Picture_Cplan_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Picture_Cplan_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  field_capture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_field_picture_cplan_link" */
export enum Nb_Field_Picture_Cplan_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  FieldCaptureId = 'field_capture_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Field_Picture_Cplan_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Picture_Cplan_Link_Set_Input>;
  where: Nb_Field_Picture_Cplan_Link_Bool_Exp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Nb_Field_Picture_Delete_At_Path_Input = {
  detail_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Nb_Field_Picture_Delete_Elem_Input = {
  detail_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Nb_Field_Picture_Delete_Key_Input = {
  detail_info?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "nb_field_picture" */
export type Nb_Field_Picture_Insert_Input = {
  cap_coor?: InputMaybe<Scalars['geometry']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  detail_info?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_path?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  nb_field_phtographers?: InputMaybe<Nb_Field_Photographer_Arr_Rel_Insert_Input>;
  nb_field_picture_cplan_links?: InputMaybe<Nb_Field_Picture_Cplan_Link_Arr_Rel_Insert_Input>;
  nb_field_picture_loads?: InputMaybe<Nb_Field_Picture_Load_Arr_Rel_Insert_Input>;
  nb_field_picture_size?: InputMaybe<Nb_Field_Picture_Size_Obj_Rel_Insert_Input>;
  nb_field_picture_sizes?: InputMaybe<Nb_Field_Picture_Size_Arr_Rel_Insert_Input>;
  nb_memo_picture_links?: InputMaybe<Nb_Memo_Picture_Link_Arr_Rel_Insert_Input>;
  nb_picture_analyses?: InputMaybe<Nb_Picture_Analysis_Arr_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  nbb_picture_analysis_statuses?: InputMaybe<Nbb_Picture_Analysis_Status_Arr_Rel_Insert_Input>;
  raw_path?: InputMaybe<Scalars['String']>;
  thumbnail_path?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** 圃場に写真を紐付けるための情報を管理する */
export type Nb_Field_Picture_Load = {
  __typename?: 'nb_field_picture_load';
  /** An object relationship */
  app_user_active: App_User_Active;
  /** app_user_activeテーブルのid */
  app_user_active_id: Scalars['uuid'];
  /** 写真が撮影された時の位置情報 */
  cap_coor?: Maybe<Scalars['geometry']>;
  /** レコードが生成されたタイムスタンプ */
  created: Scalars['timestamptz'];
  /** 写真が撮影されたタイムスタンプが入る。exifの情報。デジタル化された日時ではない。 */
  datetime?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** 表示用に使用する画像のS3 key */
  image_path?: Maybe<Scalars['String']>;
  /** 画像の波長の種別。"RGB"、"Red"、"NIR"等。 */
  kind?: Maybe<Scalars['String']>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: Maybe<Scalars['String']>;
  /** An object relationship */
  nb_company: Nb_Company;
  /** nb_companyテーブルのid */
  nb_company_id: Scalars['uuid'];
  /** An object relationship */
  nb_field_picture?: Maybe<Nb_Field_Picture>;
  nb_field_picture_id?: Maybe<Scalars['uuid']>;
  /** nb_mfieldテーブルのkey */
  nb_mfield_key?: Maybe<Scalars['String']>;
  /** An object relationship */
  nb_ufield?: Maybe<Nb_Ufield>;
  /** nb_ufieldテーブルのid */
  nb_ufield_id?: Maybe<Scalars['uuid']>;
  /** アップロードされたオリジナル画像のS3 key */
  raw_path?: Maybe<Scalars['String']>;
  /** ロードの状態 */
  status: Scalars['picture_load_status'];
  /** サムネイル表示用に使用する画像のS3 key */
  thumbnail_path?: Maybe<Scalars['String']>;
  /** レコードが更新されたタイムスタンプ */
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_field_picture_load" */
export type Nb_Field_Picture_Load_Aggregate = {
  __typename?: 'nb_field_picture_load_aggregate';
  aggregate?: Maybe<Nb_Field_Picture_Load_Aggregate_Fields>;
  nodes: Array<Nb_Field_Picture_Load>;
};

export type Nb_Field_Picture_Load_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Picture_Load_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Picture_Load_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_picture_load" */
export type Nb_Field_Picture_Load_Aggregate_Fields = {
  __typename?: 'nb_field_picture_load_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Picture_Load_Max_Fields>;
  min?: Maybe<Nb_Field_Picture_Load_Min_Fields>;
};


/** aggregate fields of "nb_field_picture_load" */
export type Nb_Field_Picture_Load_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_picture_load" */
export type Nb_Field_Picture_Load_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Picture_Load_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Picture_Load_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_picture_load" */
export type Nb_Field_Picture_Load_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Picture_Load_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Picture_Load_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_field_picture_load". All fields are combined with a logical 'AND'. */
export type Nb_Field_Picture_Load_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Picture_Load_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Picture_Load_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  app_user_active_id?: InputMaybe<Uuid_Comparison_Exp>;
  cap_coor?: InputMaybe<Geometry_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image_path?: InputMaybe<String_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_company_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
  nb_field_picture_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_mfield_key?: InputMaybe<String_Comparison_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nb_ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  raw_path?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Picture_Load_Status_Comparison_Exp>;
  thumbnail_path?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_picture_load" */
export enum Nb_Field_Picture_Load_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldPictureLoadPkey = 'nb_field_picture_load_pkey'
}

/** input type for inserting data into table "nb_field_picture_load" */
export type Nb_Field_Picture_Load_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  /** app_user_activeテーブルのid */
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** 写真が撮影された時の位置情報 */
  cap_coor?: InputMaybe<Scalars['geometry']>;
  /** レコードが生成されたタイムスタンプ */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 写真が撮影されたタイムスタンプが入る。exifの情報。デジタル化された日時ではない。 */
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** 表示用に使用する画像のS3 key */
  image_path?: InputMaybe<Scalars['String']>;
  /** 画像の波長の種別。"RGB"、"Red"、"NIR"等。 */
  kind?: InputMaybe<Scalars['String']>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: InputMaybe<Scalars['String']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  /** nb_companyテーブルのid */
  nb_company_id?: InputMaybe<Scalars['uuid']>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Obj_Rel_Insert_Input>;
  nb_field_picture_id?: InputMaybe<Scalars['uuid']>;
  /** nb_mfieldテーブルのkey */
  nb_mfield_key?: InputMaybe<Scalars['String']>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  /** nb_ufieldテーブルのid */
  nb_ufield_id?: InputMaybe<Scalars['uuid']>;
  /** アップロードされたオリジナル画像のS3 key */
  raw_path?: InputMaybe<Scalars['String']>;
  /** ロードの状態 */
  status?: InputMaybe<Scalars['picture_load_status']>;
  /** サムネイル表示用に使用する画像のS3 key */
  thumbnail_path?: InputMaybe<Scalars['String']>;
  /** レコードが更新されたタイムスタンプ */
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Field_Picture_Load_Max_Fields = {
  __typename?: 'nb_field_picture_load_max_fields';
  /** app_user_activeテーブルのid */
  app_user_active_id?: Maybe<Scalars['uuid']>;
  /** レコードが生成されたタイムスタンプ */
  created?: Maybe<Scalars['timestamptz']>;
  /** 写真が撮影されたタイムスタンプが入る。exifの情報。デジタル化された日時ではない。 */
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** 表示用に使用する画像のS3 key */
  image_path?: Maybe<Scalars['String']>;
  /** 画像の波長の種別。"RGB"、"Red"、"NIR"等。 */
  kind?: Maybe<Scalars['String']>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: Maybe<Scalars['String']>;
  /** nb_companyテーブルのid */
  nb_company_id?: Maybe<Scalars['uuid']>;
  nb_field_picture_id?: Maybe<Scalars['uuid']>;
  /** nb_mfieldテーブルのkey */
  nb_mfield_key?: Maybe<Scalars['String']>;
  /** nb_ufieldテーブルのid */
  nb_ufield_id?: Maybe<Scalars['uuid']>;
  /** アップロードされたオリジナル画像のS3 key */
  raw_path?: Maybe<Scalars['String']>;
  /** ロードの状態 */
  status?: Maybe<Scalars['picture_load_status']>;
  /** サムネイル表示用に使用する画像のS3 key */
  thumbnail_path?: Maybe<Scalars['String']>;
  /** レコードが更新されたタイムスタンプ */
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_field_picture_load" */
export type Nb_Field_Picture_Load_Max_Order_By = {
  /** app_user_activeテーブルのid */
  app_user_active_id?: InputMaybe<Order_By>;
  /** レコードが生成されたタイムスタンプ */
  created?: InputMaybe<Order_By>;
  /** 写真が撮影されたタイムスタンプが入る。exifの情報。デジタル化された日時ではない。 */
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** 表示用に使用する画像のS3 key */
  image_path?: InputMaybe<Order_By>;
  /** 画像の波長の種別。"RGB"、"Red"、"NIR"等。 */
  kind?: InputMaybe<Order_By>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: InputMaybe<Order_By>;
  /** nb_companyテーブルのid */
  nb_company_id?: InputMaybe<Order_By>;
  nb_field_picture_id?: InputMaybe<Order_By>;
  /** nb_mfieldテーブルのkey */
  nb_mfield_key?: InputMaybe<Order_By>;
  /** nb_ufieldテーブルのid */
  nb_ufield_id?: InputMaybe<Order_By>;
  /** アップロードされたオリジナル画像のS3 key */
  raw_path?: InputMaybe<Order_By>;
  /** ロードの状態 */
  status?: InputMaybe<Order_By>;
  /** サムネイル表示用に使用する画像のS3 key */
  thumbnail_path?: InputMaybe<Order_By>;
  /** レコードが更新されたタイムスタンプ */
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Picture_Load_Min_Fields = {
  __typename?: 'nb_field_picture_load_min_fields';
  /** app_user_activeテーブルのid */
  app_user_active_id?: Maybe<Scalars['uuid']>;
  /** レコードが生成されたタイムスタンプ */
  created?: Maybe<Scalars['timestamptz']>;
  /** 写真が撮影されたタイムスタンプが入る。exifの情報。デジタル化された日時ではない。 */
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** 表示用に使用する画像のS3 key */
  image_path?: Maybe<Scalars['String']>;
  /** 画像の波長の種別。"RGB"、"Red"、"NIR"等。 */
  kind?: Maybe<Scalars['String']>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: Maybe<Scalars['String']>;
  /** nb_companyテーブルのid */
  nb_company_id?: Maybe<Scalars['uuid']>;
  nb_field_picture_id?: Maybe<Scalars['uuid']>;
  /** nb_mfieldテーブルのkey */
  nb_mfield_key?: Maybe<Scalars['String']>;
  /** nb_ufieldテーブルのid */
  nb_ufield_id?: Maybe<Scalars['uuid']>;
  /** アップロードされたオリジナル画像のS3 key */
  raw_path?: Maybe<Scalars['String']>;
  /** ロードの状態 */
  status?: Maybe<Scalars['picture_load_status']>;
  /** サムネイル表示用に使用する画像のS3 key */
  thumbnail_path?: Maybe<Scalars['String']>;
  /** レコードが更新されたタイムスタンプ */
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_field_picture_load" */
export type Nb_Field_Picture_Load_Min_Order_By = {
  /** app_user_activeテーブルのid */
  app_user_active_id?: InputMaybe<Order_By>;
  /** レコードが生成されたタイムスタンプ */
  created?: InputMaybe<Order_By>;
  /** 写真が撮影されたタイムスタンプが入る。exifの情報。デジタル化された日時ではない。 */
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** 表示用に使用する画像のS3 key */
  image_path?: InputMaybe<Order_By>;
  /** 画像の波長の種別。"RGB"、"Red"、"NIR"等。 */
  kind?: InputMaybe<Order_By>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: InputMaybe<Order_By>;
  /** nb_companyテーブルのid */
  nb_company_id?: InputMaybe<Order_By>;
  nb_field_picture_id?: InputMaybe<Order_By>;
  /** nb_mfieldテーブルのkey */
  nb_mfield_key?: InputMaybe<Order_By>;
  /** nb_ufieldテーブルのid */
  nb_ufield_id?: InputMaybe<Order_By>;
  /** アップロードされたオリジナル画像のS3 key */
  raw_path?: InputMaybe<Order_By>;
  /** ロードの状態 */
  status?: InputMaybe<Order_By>;
  /** サムネイル表示用に使用する画像のS3 key */
  thumbnail_path?: InputMaybe<Order_By>;
  /** レコードが更新されたタイムスタンプ */
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_picture_load" */
export type Nb_Field_Picture_Load_Mutation_Response = {
  __typename?: 'nb_field_picture_load_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Picture_Load>;
};

/** on_conflict condition type for table "nb_field_picture_load" */
export type Nb_Field_Picture_Load_On_Conflict = {
  constraint: Nb_Field_Picture_Load_Constraint;
  update_columns?: Array<Nb_Field_Picture_Load_Update_Column>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_picture_load". */
export type Nb_Field_Picture_Load_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  app_user_active_id?: InputMaybe<Order_By>;
  cap_coor?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_path?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_company_id?: InputMaybe<Order_By>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Order_By>;
  nb_field_picture_id?: InputMaybe<Order_By>;
  nb_mfield_key?: InputMaybe<Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nb_ufield_id?: InputMaybe<Order_By>;
  raw_path?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_path?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_picture_load */
export type Nb_Field_Picture_Load_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_picture_load" */
export enum Nb_Field_Picture_Load_Select_Column {
  /** column name */
  AppUserActiveId = 'app_user_active_id',
  /** column name */
  CapCoor = 'cap_coor',
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Kind = 'kind',
  /** column name */
  Message = 'message',
  /** column name */
  NbCompanyId = 'nb_company_id',
  /** column name */
  NbFieldPictureId = 'nb_field_picture_id',
  /** column name */
  NbMfieldKey = 'nb_mfield_key',
  /** column name */
  NbUfieldId = 'nb_ufield_id',
  /** column name */
  RawPath = 'raw_path',
  /** column name */
  Status = 'status',
  /** column name */
  ThumbnailPath = 'thumbnail_path',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_field_picture_load" */
export type Nb_Field_Picture_Load_Set_Input = {
  /** app_user_activeテーブルのid */
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** 写真が撮影された時の位置情報 */
  cap_coor?: InputMaybe<Scalars['geometry']>;
  /** レコードが生成されたタイムスタンプ */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 写真が撮影されたタイムスタンプが入る。exifの情報。デジタル化された日時ではない。 */
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** 表示用に使用する画像のS3 key */
  image_path?: InputMaybe<Scalars['String']>;
  /** 画像の波長の種別。"RGB"、"Red"、"NIR"等。 */
  kind?: InputMaybe<Scalars['String']>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: InputMaybe<Scalars['String']>;
  /** nb_companyテーブルのid */
  nb_company_id?: InputMaybe<Scalars['uuid']>;
  nb_field_picture_id?: InputMaybe<Scalars['uuid']>;
  /** nb_mfieldテーブルのkey */
  nb_mfield_key?: InputMaybe<Scalars['String']>;
  /** nb_ufieldテーブルのid */
  nb_ufield_id?: InputMaybe<Scalars['uuid']>;
  /** アップロードされたオリジナル画像のS3 key */
  raw_path?: InputMaybe<Scalars['String']>;
  /** ロードの状態 */
  status?: InputMaybe<Scalars['picture_load_status']>;
  /** サムネイル表示用に使用する画像のS3 key */
  thumbnail_path?: InputMaybe<Scalars['String']>;
  /** レコードが更新されたタイムスタンプ */
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_field_picture_load" */
export type Nb_Field_Picture_Load_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Picture_Load_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Picture_Load_Stream_Cursor_Value_Input = {
  /** app_user_activeテーブルのid */
  app_user_active_id?: InputMaybe<Scalars['uuid']>;
  /** 写真が撮影された時の位置情報 */
  cap_coor?: InputMaybe<Scalars['geometry']>;
  /** レコードが生成されたタイムスタンプ */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 写真が撮影されたタイムスタンプが入る。exifの情報。デジタル化された日時ではない。 */
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** 表示用に使用する画像のS3 key */
  image_path?: InputMaybe<Scalars['String']>;
  /** 画像の波長の種別。"RGB"、"Red"、"NIR"等。 */
  kind?: InputMaybe<Scalars['String']>;
  /** エラーメッセージなど、処理中に起きた内容を保持 */
  message?: InputMaybe<Scalars['String']>;
  /** nb_companyテーブルのid */
  nb_company_id?: InputMaybe<Scalars['uuid']>;
  nb_field_picture_id?: InputMaybe<Scalars['uuid']>;
  /** nb_mfieldテーブルのkey */
  nb_mfield_key?: InputMaybe<Scalars['String']>;
  /** nb_ufieldテーブルのid */
  nb_ufield_id?: InputMaybe<Scalars['uuid']>;
  /** アップロードされたオリジナル画像のS3 key */
  raw_path?: InputMaybe<Scalars['String']>;
  /** ロードの状態 */
  status?: InputMaybe<Scalars['picture_load_status']>;
  /** サムネイル表示用に使用する画像のS3 key */
  thumbnail_path?: InputMaybe<Scalars['String']>;
  /** レコードが更新されたタイムスタンプ */
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_field_picture_load" */
export enum Nb_Field_Picture_Load_Update_Column {
  /** column name */
  AppUserActiveId = 'app_user_active_id',
  /** column name */
  CapCoor = 'cap_coor',
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Kind = 'kind',
  /** column name */
  Message = 'message',
  /** column name */
  NbCompanyId = 'nb_company_id',
  /** column name */
  NbFieldPictureId = 'nb_field_picture_id',
  /** column name */
  NbMfieldKey = 'nb_mfield_key',
  /** column name */
  NbUfieldId = 'nb_ufield_id',
  /** column name */
  RawPath = 'raw_path',
  /** column name */
  Status = 'status',
  /** column name */
  ThumbnailPath = 'thumbnail_path',
  /** column name */
  Updated = 'updated'
}

export type Nb_Field_Picture_Load_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Picture_Load_Set_Input>;
  where: Nb_Field_Picture_Load_Bool_Exp;
};

/** aggregate max on columns */
export type Nb_Field_Picture_Max_Fields = {
  __typename?: 'nb_field_picture_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image_path?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  raw_path?: Maybe<Scalars['String']>;
  thumbnail_path?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_field_picture" */
export type Nb_Field_Picture_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_path?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  raw_path?: InputMaybe<Order_By>;
  thumbnail_path?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Picture_Min_Fields = {
  __typename?: 'nb_field_picture_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image_path?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  raw_path?: Maybe<Scalars['String']>;
  thumbnail_path?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_field_picture" */
export type Nb_Field_Picture_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_path?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  raw_path?: InputMaybe<Order_By>;
  thumbnail_path?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_picture" */
export type Nb_Field_Picture_Mutation_Response = {
  __typename?: 'nb_field_picture_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Picture>;
};

/** input type for inserting object relation for remote table "nb_field_picture" */
export type Nb_Field_Picture_Obj_Rel_Insert_Input = {
  data: Nb_Field_Picture_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Picture_On_Conflict>;
};

/** on_conflict condition type for table "nb_field_picture" */
export type Nb_Field_Picture_On_Conflict = {
  constraint: Nb_Field_Picture_Constraint;
  update_columns?: Array<Nb_Field_Picture_Update_Column>;
  where?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_picture". */
export type Nb_Field_Picture_Order_By = {
  cap_coor?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  detail_info?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_path?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  nb_field_phtographers_aggregate?: InputMaybe<Nb_Field_Photographer_Aggregate_Order_By>;
  nb_field_picture_cplan_links_aggregate?: InputMaybe<Nb_Field_Picture_Cplan_Link_Aggregate_Order_By>;
  nb_field_picture_loads_aggregate?: InputMaybe<Nb_Field_Picture_Load_Aggregate_Order_By>;
  nb_field_picture_size?: InputMaybe<Nb_Field_Picture_Size_Order_By>;
  nb_field_picture_sizes_aggregate?: InputMaybe<Nb_Field_Picture_Size_Aggregate_Order_By>;
  nb_memo_picture_links_aggregate?: InputMaybe<Nb_Memo_Picture_Link_Aggregate_Order_By>;
  nb_picture_analyses_aggregate?: InputMaybe<Nb_Picture_Analysis_Aggregate_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nbb_picture_analysis_statuses_aggregate?: InputMaybe<Nbb_Picture_Analysis_Status_Aggregate_Order_By>;
  raw_path?: InputMaybe<Order_By>;
  thumbnail_path?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_picture */
export type Nb_Field_Picture_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Nb_Field_Picture_Prepend_Input = {
  detail_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "nb_field_picture" */
export enum Nb_Field_Picture_Select_Column {
  /** column name */
  CapCoor = 'cap_coor',
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DetailInfo = 'detail_info',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Key = 'key',
  /** column name */
  Kind = 'kind',
  /** column name */
  RawPath = 'raw_path',
  /** column name */
  ThumbnailPath = 'thumbnail_path',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_field_picture" */
export type Nb_Field_Picture_Set_Input = {
  cap_coor?: InputMaybe<Scalars['geometry']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  detail_info?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_path?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  raw_path?: InputMaybe<Scalars['String']>;
  thumbnail_path?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** nb_field_pictureで管理されるファイルのサイズを保持する */
export type Nb_Field_Picture_Size = {
  __typename?: 'nb_field_picture_size';
  company_key?: Maybe<Scalars['String']>;
  field_picture_id: Scalars['uuid'];
  file_type: Scalars['String'];
  id: Scalars['uuid'];
  image_size_bytes: Scalars['bigint'];
  /** An object relationship */
  nb_field_picture: Nb_Field_Picture;
  raw_size_bytes: Scalars['bigint'];
  thumbnail_size_bytes: Scalars['bigint'];
  total_size_bytes: Scalars['bigint'];
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Aggregate = {
  __typename?: 'nb_field_picture_size_aggregate';
  aggregate?: Maybe<Nb_Field_Picture_Size_Aggregate_Fields>;
  nodes: Array<Nb_Field_Picture_Size>;
};

export type Nb_Field_Picture_Size_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Picture_Size_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Picture_Size_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Picture_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Picture_Size_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Aggregate_Fields = {
  __typename?: 'nb_field_picture_size_aggregate_fields';
  avg?: Maybe<Nb_Field_Picture_Size_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Picture_Size_Max_Fields>;
  min?: Maybe<Nb_Field_Picture_Size_Min_Fields>;
  stddev?: Maybe<Nb_Field_Picture_Size_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Field_Picture_Size_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Field_Picture_Size_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Field_Picture_Size_Sum_Fields>;
  var_pop?: Maybe<Nb_Field_Picture_Size_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Field_Picture_Size_Var_Samp_Fields>;
  variance?: Maybe<Nb_Field_Picture_Size_Variance_Fields>;
};


/** aggregate fields of "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Picture_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Field_Picture_Size_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Picture_Size_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Picture_Size_Min_Order_By>;
  stddev?: InputMaybe<Nb_Field_Picture_Size_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Field_Picture_Size_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Field_Picture_Size_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Field_Picture_Size_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Field_Picture_Size_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Field_Picture_Size_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Field_Picture_Size_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Picture_Size_Insert_Input>;
};

/** aggregate avg on columns */
export type Nb_Field_Picture_Size_Avg_Fields = {
  __typename?: 'nb_field_picture_size_avg_fields';
  image_size_bytes?: Maybe<Scalars['Float']>;
  raw_size_bytes?: Maybe<Scalars['Float']>;
  thumbnail_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Avg_Order_By = {
  image_size_bytes?: InputMaybe<Order_By>;
  raw_size_bytes?: InputMaybe<Order_By>;
  thumbnail_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_field_picture_size". All fields are combined with a logical 'AND'. */
export type Nb_Field_Picture_Size_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Picture_Size_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Picture_Size_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Picture_Size_Bool_Exp>>;
  company_key?: InputMaybe<String_Comparison_Exp>;
  field_picture_id?: InputMaybe<Uuid_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
  raw_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  thumbnail_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  total_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Inc_Input = {
  image_size_bytes?: InputMaybe<Scalars['bigint']>;
  raw_size_bytes?: InputMaybe<Scalars['bigint']>;
  thumbnail_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Insert_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_size_bytes?: InputMaybe<Scalars['bigint']>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Obj_Rel_Insert_Input>;
  raw_size_bytes?: InputMaybe<Scalars['bigint']>;
  thumbnail_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Field_Picture_Size_Max_Fields = {
  __typename?: 'nb_field_picture_size_max_fields';
  company_key?: Maybe<Scalars['String']>;
  field_picture_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_size_bytes?: Maybe<Scalars['bigint']>;
  raw_size_bytes?: Maybe<Scalars['bigint']>;
  thumbnail_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Max_Order_By = {
  company_key?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_size_bytes?: InputMaybe<Order_By>;
  raw_size_bytes?: InputMaybe<Order_By>;
  thumbnail_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Picture_Size_Min_Fields = {
  __typename?: 'nb_field_picture_size_min_fields';
  company_key?: Maybe<Scalars['String']>;
  field_picture_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_size_bytes?: Maybe<Scalars['bigint']>;
  raw_size_bytes?: Maybe<Scalars['bigint']>;
  thumbnail_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Min_Order_By = {
  company_key?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_size_bytes?: InputMaybe<Order_By>;
  raw_size_bytes?: InputMaybe<Order_By>;
  thumbnail_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Mutation_Response = {
  __typename?: 'nb_field_picture_size_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Picture_Size>;
};

/** input type for inserting object relation for remote table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Obj_Rel_Insert_Input = {
  data: Nb_Field_Picture_Size_Insert_Input;
};

/** Ordering options when selecting data from "nb_field_picture_size". */
export type Nb_Field_Picture_Size_Order_By = {
  company_key?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_size_bytes?: InputMaybe<Order_By>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Order_By>;
  raw_size_bytes?: InputMaybe<Order_By>;
  thumbnail_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "nb_field_picture_size" */
export enum Nb_Field_Picture_Size_Select_Column {
  /** column name */
  CompanyKey = 'company_key',
  /** column name */
  FieldPictureId = 'field_picture_id',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  ImageSizeBytes = 'image_size_bytes',
  /** column name */
  RawSizeBytes = 'raw_size_bytes',
  /** column name */
  ThumbnailSizeBytes = 'thumbnail_size_bytes',
  /** column name */
  TotalSizeBytes = 'total_size_bytes',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Set_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_size_bytes?: InputMaybe<Scalars['bigint']>;
  raw_size_bytes?: InputMaybe<Scalars['bigint']>;
  thumbnail_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Nb_Field_Picture_Size_Stddev_Fields = {
  __typename?: 'nb_field_picture_size_stddev_fields';
  image_size_bytes?: Maybe<Scalars['Float']>;
  raw_size_bytes?: Maybe<Scalars['Float']>;
  thumbnail_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Stddev_Order_By = {
  image_size_bytes?: InputMaybe<Order_By>;
  raw_size_bytes?: InputMaybe<Order_By>;
  thumbnail_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Field_Picture_Size_Stddev_Pop_Fields = {
  __typename?: 'nb_field_picture_size_stddev_pop_fields';
  image_size_bytes?: Maybe<Scalars['Float']>;
  raw_size_bytes?: Maybe<Scalars['Float']>;
  thumbnail_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Stddev_Pop_Order_By = {
  image_size_bytes?: InputMaybe<Order_By>;
  raw_size_bytes?: InputMaybe<Order_By>;
  thumbnail_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Field_Picture_Size_Stddev_Samp_Fields = {
  __typename?: 'nb_field_picture_size_stddev_samp_fields';
  image_size_bytes?: Maybe<Scalars['Float']>;
  raw_size_bytes?: Maybe<Scalars['Float']>;
  thumbnail_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Stddev_Samp_Order_By = {
  image_size_bytes?: InputMaybe<Order_By>;
  raw_size_bytes?: InputMaybe<Order_By>;
  thumbnail_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Picture_Size_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Picture_Size_Stream_Cursor_Value_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_size_bytes?: InputMaybe<Scalars['bigint']>;
  raw_size_bytes?: InputMaybe<Scalars['bigint']>;
  thumbnail_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nb_Field_Picture_Size_Sum_Fields = {
  __typename?: 'nb_field_picture_size_sum_fields';
  image_size_bytes?: Maybe<Scalars['bigint']>;
  raw_size_bytes?: Maybe<Scalars['bigint']>;
  thumbnail_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Sum_Order_By = {
  image_size_bytes?: InputMaybe<Order_By>;
  raw_size_bytes?: InputMaybe<Order_By>;
  thumbnail_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

export type Nb_Field_Picture_Size_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Field_Picture_Size_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Picture_Size_Set_Input>;
  where: Nb_Field_Picture_Size_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Field_Picture_Size_Var_Pop_Fields = {
  __typename?: 'nb_field_picture_size_var_pop_fields';
  image_size_bytes?: Maybe<Scalars['Float']>;
  raw_size_bytes?: Maybe<Scalars['Float']>;
  thumbnail_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Var_Pop_Order_By = {
  image_size_bytes?: InputMaybe<Order_By>;
  raw_size_bytes?: InputMaybe<Order_By>;
  thumbnail_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Field_Picture_Size_Var_Samp_Fields = {
  __typename?: 'nb_field_picture_size_var_samp_fields';
  image_size_bytes?: Maybe<Scalars['Float']>;
  raw_size_bytes?: Maybe<Scalars['Float']>;
  thumbnail_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Var_Samp_Order_By = {
  image_size_bytes?: InputMaybe<Order_By>;
  raw_size_bytes?: InputMaybe<Order_By>;
  thumbnail_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Field_Picture_Size_Variance_Fields = {
  __typename?: 'nb_field_picture_size_variance_fields';
  image_size_bytes?: Maybe<Scalars['Float']>;
  raw_size_bytes?: Maybe<Scalars['Float']>;
  thumbnail_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_field_picture_size" */
export type Nb_Field_Picture_Size_Variance_Order_By = {
  image_size_bytes?: InputMaybe<Order_By>;
  raw_size_bytes?: InputMaybe<Order_By>;
  thumbnail_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_field_picture" */
export type Nb_Field_Picture_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Picture_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Picture_Stream_Cursor_Value_Input = {
  cap_coor?: InputMaybe<Scalars['geometry']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  detail_info?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_path?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  raw_path?: InputMaybe<Scalars['String']>;
  thumbnail_path?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_field_picture" */
export enum Nb_Field_Picture_Update_Column {
  /** column name */
  CapCoor = 'cap_coor',
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DetailInfo = 'detail_info',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Key = 'key',
  /** column name */
  Kind = 'kind',
  /** column name */
  RawPath = 'raw_path',
  /** column name */
  ThumbnailPath = 'thumbnail_path',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Field_Picture_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Nb_Field_Picture_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Nb_Field_Picture_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Nb_Field_Picture_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Nb_Field_Picture_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Nb_Field_Picture_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Picture_Set_Input>;
  where: Nb_Field_Picture_Bool_Exp;
};

/** columns and relationships of "nb_field_polygon_attr" */
export type Nb_Field_Polygon_Attr = {
  __typename?: 'nb_field_polygon_attr';
  attribute: Scalars['String'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_field_polygon_attr" */
export type Nb_Field_Polygon_Attr_Aggregate = {
  __typename?: 'nb_field_polygon_attr_aggregate';
  aggregate?: Maybe<Nb_Field_Polygon_Attr_Aggregate_Fields>;
  nodes: Array<Nb_Field_Polygon_Attr>;
};

/** aggregate fields of "nb_field_polygon_attr" */
export type Nb_Field_Polygon_Attr_Aggregate_Fields = {
  __typename?: 'nb_field_polygon_attr_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Polygon_Attr_Max_Fields>;
  min?: Maybe<Nb_Field_Polygon_Attr_Min_Fields>;
};


/** aggregate fields of "nb_field_polygon_attr" */
export type Nb_Field_Polygon_Attr_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Polygon_Attr_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_field_polygon_attr". All fields are combined with a logical 'AND'. */
export type Nb_Field_Polygon_Attr_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Polygon_Attr_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Polygon_Attr_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Polygon_Attr_Bool_Exp>>;
  attribute?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_polygon_attr" */
export enum Nb_Field_Polygon_Attr_Constraint {
  /** unique or primary key constraint on columns "attribute" */
  NbFieldPolygonAttrAttributeKey = 'nb_field_polygon_attr_attribute_key',
  /** unique or primary key constraint on columns "id" */
  NbFieldPolygonAttrPkey = 'nb_field_polygon_attr_pkey'
}

/** input type for inserting data into table "nb_field_polygon_attr" */
export type Nb_Field_Polygon_Attr_Insert_Input = {
  attribute?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Field_Polygon_Attr_Max_Fields = {
  __typename?: 'nb_field_polygon_attr_max_fields';
  attribute?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Field_Polygon_Attr_Min_Fields = {
  __typename?: 'nb_field_polygon_attr_min_fields';
  attribute?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_field_polygon_attr" */
export type Nb_Field_Polygon_Attr_Mutation_Response = {
  __typename?: 'nb_field_polygon_attr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Polygon_Attr>;
};

/** on_conflict condition type for table "nb_field_polygon_attr" */
export type Nb_Field_Polygon_Attr_On_Conflict = {
  constraint: Nb_Field_Polygon_Attr_Constraint;
  update_columns?: Array<Nb_Field_Polygon_Attr_Update_Column>;
  where?: InputMaybe<Nb_Field_Polygon_Attr_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_polygon_attr". */
export type Nb_Field_Polygon_Attr_Order_By = {
  attribute?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_polygon_attr */
export type Nb_Field_Polygon_Attr_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_polygon_attr" */
export enum Nb_Field_Polygon_Attr_Select_Column {
  /** column name */
  Attribute = 'attribute',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_field_polygon_attr" */
export type Nb_Field_Polygon_Attr_Set_Input = {
  attribute?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_field_polygon_attr" */
export type Nb_Field_Polygon_Attr_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Polygon_Attr_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Polygon_Attr_Stream_Cursor_Value_Input = {
  attribute?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_field_polygon_attr" */
export enum Nb_Field_Polygon_Attr_Update_Column {
  /** column name */
  Attribute = 'attribute',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Field_Polygon_Attr_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Polygon_Attr_Set_Input>;
  where: Nb_Field_Polygon_Attr_Bool_Exp;
};

/** columns and relationships of "nb_field_property" */
export type Nb_Field_Property = {
  __typename?: 'nb_field_property';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  created: Scalars['timestamptz'];
  creator_name: Scalars['String'];
  drain_water_time?: Maybe<Scalars['time']>;
  id: Scalars['uuid'];
  irrigate_water_time?: Maybe<Scalars['time']>;
  max_water_level?: Maybe<Scalars['float8']>;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  reduce_water_depth?: Maybe<Scalars['float8']>;
  soil_layer?: Maybe<Scalars['float8']>;
  updated: Scalars['timestamptz'];
  user_field_id: Scalars['uuid'];
  user_id?: Maybe<Scalars['uuid']>;
  water_source?: Maybe<Scalars['String']>;
};

/** aggregated selection of "nb_field_property" */
export type Nb_Field_Property_Aggregate = {
  __typename?: 'nb_field_property_aggregate';
  aggregate?: Maybe<Nb_Field_Property_Aggregate_Fields>;
  nodes: Array<Nb_Field_Property>;
};

export type Nb_Field_Property_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Nb_Field_Property_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Nb_Field_Property_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Nb_Field_Property_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Nb_Field_Property_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Nb_Field_Property_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Nb_Field_Property_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Nb_Field_Property_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Nb_Field_Property_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Nb_Field_Property_Aggregate_Bool_Exp_Var_Samp>;
};

export type Nb_Field_Property_Aggregate_Bool_Exp_Avg = {
  arguments: Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Field_Property_Aggregate_Bool_Exp_Corr = {
  arguments: Nb_Field_Property_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Field_Property_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Nb_Field_Property_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Nb_Field_Property_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Nb_Field_Property_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Field_Property_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Nb_Field_Property_Aggregate_Bool_Exp_Max = {
  arguments: Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Field_Property_Aggregate_Bool_Exp_Min = {
  arguments: Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Field_Property_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Field_Property_Aggregate_Bool_Exp_Sum = {
  arguments: Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Field_Property_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "nb_field_property" */
export type Nb_Field_Property_Aggregate_Fields = {
  __typename?: 'nb_field_property_aggregate_fields';
  avg?: Maybe<Nb_Field_Property_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Property_Max_Fields>;
  min?: Maybe<Nb_Field_Property_Min_Fields>;
  stddev?: Maybe<Nb_Field_Property_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Field_Property_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Field_Property_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Field_Property_Sum_Fields>;
  var_pop?: Maybe<Nb_Field_Property_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Field_Property_Var_Samp_Fields>;
  variance?: Maybe<Nb_Field_Property_Variance_Fields>;
};


/** aggregate fields of "nb_field_property" */
export type Nb_Field_Property_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_property" */
export type Nb_Field_Property_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Field_Property_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Property_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Property_Min_Order_By>;
  stddev?: InputMaybe<Nb_Field_Property_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Field_Property_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Field_Property_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Field_Property_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Field_Property_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Field_Property_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Field_Property_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_property" */
export type Nb_Field_Property_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Property_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Property_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Field_Property_Avg_Fields = {
  __typename?: 'nb_field_property_avg_fields';
  max_water_level?: Maybe<Scalars['Float']>;
  reduce_water_depth?: Maybe<Scalars['Float']>;
  soil_layer?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_field_property" */
export type Nb_Field_Property_Avg_Order_By = {
  max_water_level?: InputMaybe<Order_By>;
  reduce_water_depth?: InputMaybe<Order_By>;
  soil_layer?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_field_property". All fields are combined with a logical 'AND'. */
export type Nb_Field_Property_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Property_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Property_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_name?: InputMaybe<String_Comparison_Exp>;
  drain_water_time?: InputMaybe<Time_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  irrigate_water_time?: InputMaybe<Time_Comparison_Exp>;
  max_water_level?: InputMaybe<Float8_Comparison_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  reduce_water_depth?: InputMaybe<Float8_Comparison_Exp>;
  soil_layer?: InputMaybe<Float8_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  water_source?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_property" */
export enum Nb_Field_Property_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldPropertyPkey = 'nb_field_property_pkey'
}

/** input type for incrementing numeric columns in table "nb_field_property" */
export type Nb_Field_Property_Inc_Input = {
  max_water_level?: InputMaybe<Scalars['float8']>;
  reduce_water_depth?: InputMaybe<Scalars['float8']>;
  soil_layer?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "nb_field_property" */
export type Nb_Field_Property_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  drain_water_time?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['uuid']>;
  irrigate_water_time?: InputMaybe<Scalars['time']>;
  max_water_level?: InputMaybe<Scalars['float8']>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  reduce_water_depth?: InputMaybe<Scalars['float8']>;
  soil_layer?: InputMaybe<Scalars['float8']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  water_source?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nb_Field_Property_Max_Fields = {
  __typename?: 'nb_field_property_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  max_water_level?: Maybe<Scalars['float8']>;
  reduce_water_depth?: Maybe<Scalars['float8']>;
  soil_layer?: Maybe<Scalars['float8']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  water_source?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "nb_field_property" */
export type Nb_Field_Property_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_water_level?: InputMaybe<Order_By>;
  reduce_water_depth?: InputMaybe<Order_By>;
  soil_layer?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  water_source?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Property_Min_Fields = {
  __typename?: 'nb_field_property_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  max_water_level?: Maybe<Scalars['float8']>;
  reduce_water_depth?: Maybe<Scalars['float8']>;
  soil_layer?: Maybe<Scalars['float8']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  water_source?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "nb_field_property" */
export type Nb_Field_Property_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_water_level?: InputMaybe<Order_By>;
  reduce_water_depth?: InputMaybe<Order_By>;
  soil_layer?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  water_source?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_property" */
export type Nb_Field_Property_Mutation_Response = {
  __typename?: 'nb_field_property_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Property>;
};

/** on_conflict condition type for table "nb_field_property" */
export type Nb_Field_Property_On_Conflict = {
  constraint: Nb_Field_Property_Constraint;
  update_columns?: Array<Nb_Field_Property_Update_Column>;
  where?: InputMaybe<Nb_Field_Property_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_property". */
export type Nb_Field_Property_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  drain_water_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  irrigate_water_time?: InputMaybe<Order_By>;
  max_water_level?: InputMaybe<Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  reduce_water_depth?: InputMaybe<Order_By>;
  soil_layer?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  water_source?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_property */
export type Nb_Field_Property_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_property" */
export enum Nb_Field_Property_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  DrainWaterTime = 'drain_water_time',
  /** column name */
  Id = 'id',
  /** column name */
  IrrigateWaterTime = 'irrigate_water_time',
  /** column name */
  MaxWaterLevel = 'max_water_level',
  /** column name */
  ReduceWaterDepth = 'reduce_water_depth',
  /** column name */
  SoilLayer = 'soil_layer',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WaterSource = 'water_source'
}

/** select "nb_field_property_aggregate_bool_exp_avg_arguments_columns" columns of table "nb_field_property" */
export enum Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  MaxWaterLevel = 'max_water_level',
  /** column name */
  ReduceWaterDepth = 'reduce_water_depth',
  /** column name */
  SoilLayer = 'soil_layer'
}

/** select "nb_field_property_aggregate_bool_exp_corr_arguments_columns" columns of table "nb_field_property" */
export enum Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  MaxWaterLevel = 'max_water_level',
  /** column name */
  ReduceWaterDepth = 'reduce_water_depth',
  /** column name */
  SoilLayer = 'soil_layer'
}

/** select "nb_field_property_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "nb_field_property" */
export enum Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  MaxWaterLevel = 'max_water_level',
  /** column name */
  ReduceWaterDepth = 'reduce_water_depth',
  /** column name */
  SoilLayer = 'soil_layer'
}

/** select "nb_field_property_aggregate_bool_exp_max_arguments_columns" columns of table "nb_field_property" */
export enum Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  MaxWaterLevel = 'max_water_level',
  /** column name */
  ReduceWaterDepth = 'reduce_water_depth',
  /** column name */
  SoilLayer = 'soil_layer'
}

/** select "nb_field_property_aggregate_bool_exp_min_arguments_columns" columns of table "nb_field_property" */
export enum Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  MaxWaterLevel = 'max_water_level',
  /** column name */
  ReduceWaterDepth = 'reduce_water_depth',
  /** column name */
  SoilLayer = 'soil_layer'
}

/** select "nb_field_property_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "nb_field_property" */
export enum Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  MaxWaterLevel = 'max_water_level',
  /** column name */
  ReduceWaterDepth = 'reduce_water_depth',
  /** column name */
  SoilLayer = 'soil_layer'
}

/** select "nb_field_property_aggregate_bool_exp_sum_arguments_columns" columns of table "nb_field_property" */
export enum Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  MaxWaterLevel = 'max_water_level',
  /** column name */
  ReduceWaterDepth = 'reduce_water_depth',
  /** column name */
  SoilLayer = 'soil_layer'
}

/** select "nb_field_property_aggregate_bool_exp_var_samp_arguments_columns" columns of table "nb_field_property" */
export enum Nb_Field_Property_Select_Column_Nb_Field_Property_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  MaxWaterLevel = 'max_water_level',
  /** column name */
  ReduceWaterDepth = 'reduce_water_depth',
  /** column name */
  SoilLayer = 'soil_layer'
}

/** input type for updating data in table "nb_field_property" */
export type Nb_Field_Property_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  drain_water_time?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['uuid']>;
  irrigate_water_time?: InputMaybe<Scalars['time']>;
  max_water_level?: InputMaybe<Scalars['float8']>;
  reduce_water_depth?: InputMaybe<Scalars['float8']>;
  soil_layer?: InputMaybe<Scalars['float8']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  water_source?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Nb_Field_Property_Stddev_Fields = {
  __typename?: 'nb_field_property_stddev_fields';
  max_water_level?: Maybe<Scalars['Float']>;
  reduce_water_depth?: Maybe<Scalars['Float']>;
  soil_layer?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_field_property" */
export type Nb_Field_Property_Stddev_Order_By = {
  max_water_level?: InputMaybe<Order_By>;
  reduce_water_depth?: InputMaybe<Order_By>;
  soil_layer?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Field_Property_Stddev_Pop_Fields = {
  __typename?: 'nb_field_property_stddev_pop_fields';
  max_water_level?: Maybe<Scalars['Float']>;
  reduce_water_depth?: Maybe<Scalars['Float']>;
  soil_layer?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_field_property" */
export type Nb_Field_Property_Stddev_Pop_Order_By = {
  max_water_level?: InputMaybe<Order_By>;
  reduce_water_depth?: InputMaybe<Order_By>;
  soil_layer?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Field_Property_Stddev_Samp_Fields = {
  __typename?: 'nb_field_property_stddev_samp_fields';
  max_water_level?: Maybe<Scalars['Float']>;
  reduce_water_depth?: Maybe<Scalars['Float']>;
  soil_layer?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_field_property" */
export type Nb_Field_Property_Stddev_Samp_Order_By = {
  max_water_level?: InputMaybe<Order_By>;
  reduce_water_depth?: InputMaybe<Order_By>;
  soil_layer?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_field_property" */
export type Nb_Field_Property_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Property_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Property_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  drain_water_time?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['uuid']>;
  irrigate_water_time?: InputMaybe<Scalars['time']>;
  max_water_level?: InputMaybe<Scalars['float8']>;
  reduce_water_depth?: InputMaybe<Scalars['float8']>;
  soil_layer?: InputMaybe<Scalars['float8']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  water_source?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Nb_Field_Property_Sum_Fields = {
  __typename?: 'nb_field_property_sum_fields';
  max_water_level?: Maybe<Scalars['float8']>;
  reduce_water_depth?: Maybe<Scalars['float8']>;
  soil_layer?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "nb_field_property" */
export type Nb_Field_Property_Sum_Order_By = {
  max_water_level?: InputMaybe<Order_By>;
  reduce_water_depth?: InputMaybe<Order_By>;
  soil_layer?: InputMaybe<Order_By>;
};

/** update columns of table "nb_field_property" */
export enum Nb_Field_Property_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  DrainWaterTime = 'drain_water_time',
  /** column name */
  Id = 'id',
  /** column name */
  IrrigateWaterTime = 'irrigate_water_time',
  /** column name */
  MaxWaterLevel = 'max_water_level',
  /** column name */
  ReduceWaterDepth = 'reduce_water_depth',
  /** column name */
  SoilLayer = 'soil_layer',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WaterSource = 'water_source'
}

export type Nb_Field_Property_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Field_Property_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Property_Set_Input>;
  where: Nb_Field_Property_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Field_Property_Var_Pop_Fields = {
  __typename?: 'nb_field_property_var_pop_fields';
  max_water_level?: Maybe<Scalars['Float']>;
  reduce_water_depth?: Maybe<Scalars['Float']>;
  soil_layer?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_field_property" */
export type Nb_Field_Property_Var_Pop_Order_By = {
  max_water_level?: InputMaybe<Order_By>;
  reduce_water_depth?: InputMaybe<Order_By>;
  soil_layer?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Field_Property_Var_Samp_Fields = {
  __typename?: 'nb_field_property_var_samp_fields';
  max_water_level?: Maybe<Scalars['Float']>;
  reduce_water_depth?: Maybe<Scalars['Float']>;
  soil_layer?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_field_property" */
export type Nb_Field_Property_Var_Samp_Order_By = {
  max_water_level?: InputMaybe<Order_By>;
  reduce_water_depth?: InputMaybe<Order_By>;
  soil_layer?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Field_Property_Variance_Fields = {
  __typename?: 'nb_field_property_variance_fields';
  max_water_level?: Maybe<Scalars['Float']>;
  reduce_water_depth?: Maybe<Scalars['Float']>;
  soil_layer?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_field_property" */
export type Nb_Field_Property_Variance_Order_By = {
  max_water_level?: InputMaybe<Order_By>;
  reduce_water_depth?: InputMaybe<Order_By>;
  soil_layer?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_field_sensing" */
export type Nb_Field_Sensing = {
  __typename?: 'nb_field_sensing';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  nb_field_sensing_data: Array<Nb_Field_Sensing_Data>;
  /** An aggregate relationship */
  nb_field_sensing_data_aggregate: Nb_Field_Sensing_Data_Aggregate;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  product_name: Scalars['String'];
  set_coordinate?: Maybe<Scalars['geometry']>;
  set_location?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
  user_field_id: Scalars['uuid'];
};


/** columns and relationships of "nb_field_sensing" */
export type Nb_Field_SensingNb_Field_Sensing_DataArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Data_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Data_Bool_Exp>;
};


/** columns and relationships of "nb_field_sensing" */
export type Nb_Field_SensingNb_Field_Sensing_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Data_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Data_Bool_Exp>;
};

/** aggregated selection of "nb_field_sensing" */
export type Nb_Field_Sensing_Aggregate = {
  __typename?: 'nb_field_sensing_aggregate';
  aggregate?: Maybe<Nb_Field_Sensing_Aggregate_Fields>;
  nodes: Array<Nb_Field_Sensing>;
};

export type Nb_Field_Sensing_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Sensing_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Sensing_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Sensing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_sensing" */
export type Nb_Field_Sensing_Aggregate_Fields = {
  __typename?: 'nb_field_sensing_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Sensing_Max_Fields>;
  min?: Maybe<Nb_Field_Sensing_Min_Fields>;
};


/** aggregate fields of "nb_field_sensing" */
export type Nb_Field_Sensing_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Sensing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_sensing" */
export type Nb_Field_Sensing_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Sensing_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Sensing_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_sensing" */
export type Nb_Field_Sensing_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Sensing_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Sensing_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_field_sensing". All fields are combined with a logical 'AND'. */
export type Nb_Field_Sensing_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Sensing_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Sensing_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_sensing_data?: InputMaybe<Nb_Field_Sensing_Data_Bool_Exp>;
  nb_field_sensing_data_aggregate?: InputMaybe<Nb_Field_Sensing_Data_Aggregate_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  product_name?: InputMaybe<String_Comparison_Exp>;
  set_coordinate?: InputMaybe<Geometry_Comparison_Exp>;
  set_location?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_field_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_sensing" */
export enum Nb_Field_Sensing_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldSensingPkey = 'nb_field_sensing_pkey'
}

/** columns and relationships of "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data = {
  __typename?: 'nb_field_sensing_data';
  created: Scalars['timestamptz'];
  datetime: Scalars['timestamptz'];
  day_water_temperature?: Maybe<Scalars['Float']>;
  diff_water_temperature?: Maybe<Scalars['Float']>;
  ground_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  id: Scalars['uuid'];
  /** An object relationship */
  nb_field_sensing: Nb_Field_Sensing;
  night_water_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  sensing_field_id: Scalars['uuid'];
  soid_moisture?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  updated: Scalars['timestamptz'];
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Aggregate = {
  __typename?: 'nb_field_sensing_data_aggregate';
  aggregate?: Maybe<Nb_Field_Sensing_Data_Aggregate_Fields>;
  nodes: Array<Nb_Field_Sensing_Data>;
};

export type Nb_Field_Sensing_Data_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Sensing_Data_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Sensing_Data_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Sensing_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Sensing_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Aggregate_Fields = {
  __typename?: 'nb_field_sensing_data_aggregate_fields';
  avg?: Maybe<Nb_Field_Sensing_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Sensing_Data_Max_Fields>;
  min?: Maybe<Nb_Field_Sensing_Data_Min_Fields>;
  stddev?: Maybe<Nb_Field_Sensing_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Field_Sensing_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Field_Sensing_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Field_Sensing_Data_Sum_Fields>;
  var_pop?: Maybe<Nb_Field_Sensing_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Field_Sensing_Data_Var_Samp_Fields>;
  variance?: Maybe<Nb_Field_Sensing_Data_Variance_Fields>;
};


/** aggregate fields of "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Sensing_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Field_Sensing_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Sensing_Data_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Sensing_Data_Min_Order_By>;
  stddev?: InputMaybe<Nb_Field_Sensing_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Field_Sensing_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Field_Sensing_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Field_Sensing_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Field_Sensing_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Field_Sensing_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Field_Sensing_Data_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Sensing_Data_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Sensing_Data_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Field_Sensing_Data_Avg_Fields = {
  __typename?: 'nb_field_sensing_data_avg_fields';
  day_water_temperature?: Maybe<Scalars['Float']>;
  diff_water_temperature?: Maybe<Scalars['Float']>;
  ground_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_water_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  soid_moisture?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Avg_Order_By = {
  day_water_temperature?: InputMaybe<Order_By>;
  diff_water_temperature?: InputMaybe<Order_By>;
  ground_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_water_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  soid_moisture?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_field_sensing_data". All fields are combined with a logical 'AND'. */
export type Nb_Field_Sensing_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Sensing_Data_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Sensing_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Sensing_Data_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  day_water_temperature?: InputMaybe<Float_Comparison_Exp>;
  diff_water_temperature?: InputMaybe<Float_Comparison_Exp>;
  ground_temperature?: InputMaybe<Float_Comparison_Exp>;
  humidity?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_sensing?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
  night_water_temperature?: InputMaybe<Float_Comparison_Exp>;
  rainfall?: InputMaybe<Float_Comparison_Exp>;
  sensing_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  soid_moisture?: InputMaybe<Float_Comparison_Exp>;
  solar_radiation?: InputMaybe<Float_Comparison_Exp>;
  temperature?: InputMaybe<Float_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  water_level?: InputMaybe<Float_Comparison_Exp>;
  water_temperature?: InputMaybe<Float_Comparison_Exp>;
  wind_velocity?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_sensing_data" */
export enum Nb_Field_Sensing_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldSensingDataPkey = 'nb_field_sensing_data_pkey'
}

/** input type for incrementing numeric columns in table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Inc_Input = {
  day_water_temperature?: InputMaybe<Scalars['Float']>;
  diff_water_temperature?: InputMaybe<Scalars['Float']>;
  ground_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  night_water_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  soid_moisture?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  temperature?: InputMaybe<Scalars['Float']>;
  water_level?: InputMaybe<Scalars['Float']>;
  water_temperature?: InputMaybe<Scalars['Float']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  day_water_temperature?: InputMaybe<Scalars['Float']>;
  diff_water_temperature?: InputMaybe<Scalars['Float']>;
  ground_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_sensing?: InputMaybe<Nb_Field_Sensing_Obj_Rel_Insert_Input>;
  night_water_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  sensing_field_id?: InputMaybe<Scalars['uuid']>;
  soid_moisture?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  temperature?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  water_level?: InputMaybe<Scalars['Float']>;
  water_temperature?: InputMaybe<Scalars['Float']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Nb_Field_Sensing_Data_Max_Fields = {
  __typename?: 'nb_field_sensing_data_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  day_water_temperature?: Maybe<Scalars['Float']>;
  diff_water_temperature?: Maybe<Scalars['Float']>;
  ground_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  night_water_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  sensing_field_id?: Maybe<Scalars['uuid']>;
  soid_moisture?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['timestamptz']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  day_water_temperature?: InputMaybe<Order_By>;
  diff_water_temperature?: InputMaybe<Order_By>;
  ground_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  night_water_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  sensing_field_id?: InputMaybe<Order_By>;
  soid_moisture?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Sensing_Data_Min_Fields = {
  __typename?: 'nb_field_sensing_data_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  day_water_temperature?: Maybe<Scalars['Float']>;
  diff_water_temperature?: Maybe<Scalars['Float']>;
  ground_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  night_water_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  sensing_field_id?: Maybe<Scalars['uuid']>;
  soid_moisture?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['timestamptz']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  day_water_temperature?: InputMaybe<Order_By>;
  diff_water_temperature?: InputMaybe<Order_By>;
  ground_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  night_water_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  sensing_field_id?: InputMaybe<Order_By>;
  soid_moisture?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Mutation_Response = {
  __typename?: 'nb_field_sensing_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Sensing_Data>;
};

/** on_conflict condition type for table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_On_Conflict = {
  constraint: Nb_Field_Sensing_Data_Constraint;
  update_columns?: Array<Nb_Field_Sensing_Data_Update_Column>;
  where?: InputMaybe<Nb_Field_Sensing_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_sensing_data". */
export type Nb_Field_Sensing_Data_Order_By = {
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  day_water_temperature?: InputMaybe<Order_By>;
  diff_water_temperature?: InputMaybe<Order_By>;
  ground_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_sensing?: InputMaybe<Nb_Field_Sensing_Order_By>;
  night_water_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  sensing_field_id?: InputMaybe<Order_By>;
  soid_moisture?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_sensing_data */
export type Nb_Field_Sensing_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_sensing_data" */
export enum Nb_Field_Sensing_Data_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DayWaterTemperature = 'day_water_temperature',
  /** column name */
  DiffWaterTemperature = 'diff_water_temperature',
  /** column name */
  GroundTemperature = 'ground_temperature',
  /** column name */
  Humidity = 'humidity',
  /** column name */
  Id = 'id',
  /** column name */
  NightWaterTemperature = 'night_water_temperature',
  /** column name */
  Rainfall = 'rainfall',
  /** column name */
  SensingFieldId = 'sensing_field_id',
  /** column name */
  SoidMoisture = 'soid_moisture',
  /** column name */
  SolarRadiation = 'solar_radiation',
  /** column name */
  Temperature = 'temperature',
  /** column name */
  Updated = 'updated',
  /** column name */
  WaterLevel = 'water_level',
  /** column name */
  WaterTemperature = 'water_temperature',
  /** column name */
  WindVelocity = 'wind_velocity'
}

/** input type for updating data in table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  day_water_temperature?: InputMaybe<Scalars['Float']>;
  diff_water_temperature?: InputMaybe<Scalars['Float']>;
  ground_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  night_water_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  sensing_field_id?: InputMaybe<Scalars['uuid']>;
  soid_moisture?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  temperature?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  water_level?: InputMaybe<Scalars['Float']>;
  water_temperature?: InputMaybe<Scalars['Float']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Nb_Field_Sensing_Data_Stddev_Fields = {
  __typename?: 'nb_field_sensing_data_stddev_fields';
  day_water_temperature?: Maybe<Scalars['Float']>;
  diff_water_temperature?: Maybe<Scalars['Float']>;
  ground_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_water_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  soid_moisture?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Stddev_Order_By = {
  day_water_temperature?: InputMaybe<Order_By>;
  diff_water_temperature?: InputMaybe<Order_By>;
  ground_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_water_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  soid_moisture?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Field_Sensing_Data_Stddev_Pop_Fields = {
  __typename?: 'nb_field_sensing_data_stddev_pop_fields';
  day_water_temperature?: Maybe<Scalars['Float']>;
  diff_water_temperature?: Maybe<Scalars['Float']>;
  ground_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_water_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  soid_moisture?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Stddev_Pop_Order_By = {
  day_water_temperature?: InputMaybe<Order_By>;
  diff_water_temperature?: InputMaybe<Order_By>;
  ground_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_water_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  soid_moisture?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Field_Sensing_Data_Stddev_Samp_Fields = {
  __typename?: 'nb_field_sensing_data_stddev_samp_fields';
  day_water_temperature?: Maybe<Scalars['Float']>;
  diff_water_temperature?: Maybe<Scalars['Float']>;
  ground_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_water_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  soid_moisture?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Stddev_Samp_Order_By = {
  day_water_temperature?: InputMaybe<Order_By>;
  diff_water_temperature?: InputMaybe<Order_By>;
  ground_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_water_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  soid_moisture?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Sensing_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Sensing_Data_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  day_water_temperature?: InputMaybe<Scalars['Float']>;
  diff_water_temperature?: InputMaybe<Scalars['Float']>;
  ground_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  night_water_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  sensing_field_id?: InputMaybe<Scalars['uuid']>;
  soid_moisture?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  temperature?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  water_level?: InputMaybe<Scalars['Float']>;
  water_temperature?: InputMaybe<Scalars['Float']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Nb_Field_Sensing_Data_Sum_Fields = {
  __typename?: 'nb_field_sensing_data_sum_fields';
  day_water_temperature?: Maybe<Scalars['Float']>;
  diff_water_temperature?: Maybe<Scalars['Float']>;
  ground_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_water_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  soid_moisture?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Sum_Order_By = {
  day_water_temperature?: InputMaybe<Order_By>;
  diff_water_temperature?: InputMaybe<Order_By>;
  ground_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_water_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  soid_moisture?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** update columns of table "nb_field_sensing_data" */
export enum Nb_Field_Sensing_Data_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DayWaterTemperature = 'day_water_temperature',
  /** column name */
  DiffWaterTemperature = 'diff_water_temperature',
  /** column name */
  GroundTemperature = 'ground_temperature',
  /** column name */
  Humidity = 'humidity',
  /** column name */
  Id = 'id',
  /** column name */
  NightWaterTemperature = 'night_water_temperature',
  /** column name */
  Rainfall = 'rainfall',
  /** column name */
  SensingFieldId = 'sensing_field_id',
  /** column name */
  SoidMoisture = 'soid_moisture',
  /** column name */
  SolarRadiation = 'solar_radiation',
  /** column name */
  Temperature = 'temperature',
  /** column name */
  Updated = 'updated',
  /** column name */
  WaterLevel = 'water_level',
  /** column name */
  WaterTemperature = 'water_temperature',
  /** column name */
  WindVelocity = 'wind_velocity'
}

export type Nb_Field_Sensing_Data_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Field_Sensing_Data_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Sensing_Data_Set_Input>;
  where: Nb_Field_Sensing_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Field_Sensing_Data_Var_Pop_Fields = {
  __typename?: 'nb_field_sensing_data_var_pop_fields';
  day_water_temperature?: Maybe<Scalars['Float']>;
  diff_water_temperature?: Maybe<Scalars['Float']>;
  ground_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_water_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  soid_moisture?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Var_Pop_Order_By = {
  day_water_temperature?: InputMaybe<Order_By>;
  diff_water_temperature?: InputMaybe<Order_By>;
  ground_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_water_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  soid_moisture?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Field_Sensing_Data_Var_Samp_Fields = {
  __typename?: 'nb_field_sensing_data_var_samp_fields';
  day_water_temperature?: Maybe<Scalars['Float']>;
  diff_water_temperature?: Maybe<Scalars['Float']>;
  ground_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_water_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  soid_moisture?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Var_Samp_Order_By = {
  day_water_temperature?: InputMaybe<Order_By>;
  diff_water_temperature?: InputMaybe<Order_By>;
  ground_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_water_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  soid_moisture?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Field_Sensing_Data_Variance_Fields = {
  __typename?: 'nb_field_sensing_data_variance_fields';
  day_water_temperature?: Maybe<Scalars['Float']>;
  diff_water_temperature?: Maybe<Scalars['Float']>;
  ground_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_water_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  soid_moisture?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_field_sensing_data" */
export type Nb_Field_Sensing_Data_Variance_Order_By = {
  day_water_temperature?: InputMaybe<Order_By>;
  diff_water_temperature?: InputMaybe<Order_By>;
  ground_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_water_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  soid_moisture?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** input type for inserting data into table "nb_field_sensing" */
export type Nb_Field_Sensing_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_sensing_data?: InputMaybe<Nb_Field_Sensing_Data_Arr_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  product_name?: InputMaybe<Scalars['String']>;
  set_coordinate?: InputMaybe<Scalars['geometry']>;
  set_location?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Field_Sensing_Max_Fields = {
  __typename?: 'nb_field_sensing_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  product_name?: Maybe<Scalars['String']>;
  set_location?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_field_sensing" */
export type Nb_Field_Sensing_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  set_location?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Sensing_Min_Fields = {
  __typename?: 'nb_field_sensing_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  product_name?: Maybe<Scalars['String']>;
  set_location?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_field_sensing" */
export type Nb_Field_Sensing_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  set_location?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_sensing" */
export type Nb_Field_Sensing_Mutation_Response = {
  __typename?: 'nb_field_sensing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Sensing>;
};

/** input type for inserting object relation for remote table "nb_field_sensing" */
export type Nb_Field_Sensing_Obj_Rel_Insert_Input = {
  data: Nb_Field_Sensing_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Sensing_On_Conflict>;
};

/** on_conflict condition type for table "nb_field_sensing" */
export type Nb_Field_Sensing_On_Conflict = {
  constraint: Nb_Field_Sensing_Constraint;
  update_columns?: Array<Nb_Field_Sensing_Update_Column>;
  where?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_sensing". */
export type Nb_Field_Sensing_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_sensing_data_aggregate?: InputMaybe<Nb_Field_Sensing_Data_Aggregate_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  product_name?: InputMaybe<Order_By>;
  set_coordinate?: InputMaybe<Order_By>;
  set_location?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_sensing */
export type Nb_Field_Sensing_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_sensing" */
export enum Nb_Field_Sensing_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  SetCoordinate = 'set_coordinate',
  /** column name */
  SetLocation = 'set_location',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id'
}

/** input type for updating data in table "nb_field_sensing" */
export type Nb_Field_Sensing_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  product_name?: InputMaybe<Scalars['String']>;
  set_coordinate?: InputMaybe<Scalars['geometry']>;
  set_location?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "nb_field_sensing" */
export type Nb_Field_Sensing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Sensing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Sensing_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  product_name?: InputMaybe<Scalars['String']>;
  set_coordinate?: InputMaybe<Scalars['geometry']>;
  set_location?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nb_field_sensing" */
export enum Nb_Field_Sensing_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  SetCoordinate = 'set_coordinate',
  /** column name */
  SetLocation = 'set_location',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id'
}

export type Nb_Field_Sensing_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Sensing_Set_Input>;
  where: Nb_Field_Sensing_Bool_Exp;
};

/** columns and relationships of "nb_field_water_gate" */
export type Nb_Field_Water_Gate = {
  __typename?: 'nb_field_water_gate';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  created: Scalars['timestamptz'];
  creator_name: Scalars['String'];
  id: Scalars['uuid'];
  key?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  /** An array relationship */
  nb_field_water_gate_statuses: Array<Nb_Field_Water_Gate_Status>;
  /** An aggregate relationship */
  nb_field_water_gate_statuses_aggregate: Nb_Field_Water_Gate_Status_Aggregate;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  updated: Scalars['timestamptz'];
  user_field_id: Scalars['uuid'];
  user_id?: Maybe<Scalars['uuid']>;
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
  wg_coor: Scalars['geometry'];
};


/** columns and relationships of "nb_field_water_gate" */
export type Nb_Field_Water_GateNb_Field_Water_Gate_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
};


/** columns and relationships of "nb_field_water_gate" */
export type Nb_Field_Water_GateNb_Field_Water_Gate_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
};

/** aggregated selection of "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Aggregate = {
  __typename?: 'nb_field_water_gate_aggregate';
  aggregate?: Maybe<Nb_Field_Water_Gate_Aggregate_Fields>;
  nodes: Array<Nb_Field_Water_Gate>;
};

export type Nb_Field_Water_Gate_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Water_Gate_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Water_Gate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Water_Gate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Aggregate_Fields = {
  __typename?: 'nb_field_water_gate_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Water_Gate_Max_Fields>;
  min?: Maybe<Nb_Field_Water_Gate_Min_Fields>;
};


/** aggregate fields of "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Water_Gate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Water_Gate_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Water_Gate_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Water_Gate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Water_Gate_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_field_water_gate". All fields are combined with a logical 'AND'. */
export type Nb_Field_Water_Gate_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Water_Gate_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Water_Gate_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  nb_field_water_gate_statuses?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
  nb_field_water_gate_statuses_aggregate?: InputMaybe<Nb_Field_Water_Gate_Status_Aggregate_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  wg_coor?: InputMaybe<Geometry_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_water_gate" */
export enum Nb_Field_Water_Gate_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldWaterGatePkey = 'nb_field_water_gate_pkey'
}

/** input type for inserting data into table "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  nb_field_water_gate_statuses?: InputMaybe<Nb_Field_Water_Gate_Status_Arr_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
  wg_coor?: InputMaybe<Scalars['geometry']>;
};

/** aggregate max on columns */
export type Nb_Field_Water_Gate_Max_Fields = {
  __typename?: 'nb_field_water_gate_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Water_Gate_Min_Fields = {
  __typename?: 'nb_field_water_gate_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Mutation_Response = {
  __typename?: 'nb_field_water_gate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Water_Gate>;
};

/** input type for inserting object relation for remote table "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Obj_Rel_Insert_Input = {
  data: Nb_Field_Water_Gate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Water_Gate_On_Conflict>;
};

/** on_conflict condition type for table "nb_field_water_gate" */
export type Nb_Field_Water_Gate_On_Conflict = {
  constraint: Nb_Field_Water_Gate_Constraint;
  update_columns?: Array<Nb_Field_Water_Gate_Update_Column>;
  where?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_water_gate". */
export type Nb_Field_Water_Gate_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  nb_field_water_gate_statuses_aggregate?: InputMaybe<Nb_Field_Water_Gate_Status_Aggregate_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
  wg_coor?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_water_gate */
export type Nb_Field_Water_Gate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_water_gate" */
export enum Nb_Field_Water_Gate_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Kind = 'kind',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start',
  /** column name */
  WgCoor = 'wg_coor'
}

/** input type for updating data in table "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
  wg_coor?: InputMaybe<Scalars['geometry']>;
};

/** columns and relationships of "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status = {
  __typename?: 'nb_field_water_gate_status';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  created: Scalars['timestamptz'];
  creator_name: Scalars['String'];
  datetime: Scalars['timestamptz'];
  field_wg_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_field_water_gate: Nb_Field_Water_Gate;
  /** An array relationship */
  nb_water_gate_status_cplan_links: Array<Nb_Water_Gate_Status_Cplan_Link>;
  /** An aggregate relationship */
  nb_water_gate_status_cplan_links_aggregate: Nb_Water_Gate_Status_Cplan_Link_Aggregate;
  status: Scalars['String'];
  updated: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_StatusNb_Water_Gate_Status_Cplan_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
};


/** columns and relationships of "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_StatusNb_Water_Gate_Status_Cplan_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
};

/** aggregated selection of "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Aggregate = {
  __typename?: 'nb_field_water_gate_status_aggregate';
  aggregate?: Maybe<Nb_Field_Water_Gate_Status_Aggregate_Fields>;
  nodes: Array<Nb_Field_Water_Gate_Status>;
};

export type Nb_Field_Water_Gate_Status_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Field_Water_Gate_Status_Aggregate_Bool_Exp_Count>;
};

export type Nb_Field_Water_Gate_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Aggregate_Fields = {
  __typename?: 'nb_field_water_gate_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Field_Water_Gate_Status_Max_Fields>;
  min?: Maybe<Nb_Field_Water_Gate_Status_Min_Fields>;
};


/** aggregate fields of "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Field_Water_Gate_Status_Max_Order_By>;
  min?: InputMaybe<Nb_Field_Water_Gate_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Arr_Rel_Insert_Input = {
  data: Array<Nb_Field_Water_Gate_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Water_Gate_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_field_water_gate_status". All fields are combined with a logical 'AND'. */
export type Nb_Field_Water_Gate_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Bool_Exp>>;
  _not?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_name?: InputMaybe<String_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_wg_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_water_gate?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
  nb_water_gate_status_cplan_links?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
  nb_water_gate_status_cplan_links_aggregate?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_field_water_gate_status" */
export enum Nb_Field_Water_Gate_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFieldWaterGateStatusPkey = 'nb_field_water_gate_status_pkey'
}

/** input type for inserting data into table "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  field_wg_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_water_gate?: InputMaybe<Nb_Field_Water_Gate_Obj_Rel_Insert_Input>;
  nb_water_gate_status_cplan_links?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Field_Water_Gate_Status_Max_Fields = {
  __typename?: 'nb_field_water_gate_status_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  field_wg_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  field_wg_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Field_Water_Gate_Status_Min_Fields = {
  __typename?: 'nb_field_water_gate_status_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  field_wg_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  field_wg_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Mutation_Response = {
  __typename?: 'nb_field_water_gate_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Field_Water_Gate_Status>;
};

/** input type for inserting object relation for remote table "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Obj_Rel_Insert_Input = {
  data: Nb_Field_Water_Gate_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Field_Water_Gate_Status_On_Conflict>;
};

/** on_conflict condition type for table "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_On_Conflict = {
  constraint: Nb_Field_Water_Gate_Status_Constraint;
  update_columns?: Array<Nb_Field_Water_Gate_Status_Update_Column>;
  where?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_field_water_gate_status". */
export type Nb_Field_Water_Gate_Status_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  field_wg_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_water_gate?: InputMaybe<Nb_Field_Water_Gate_Order_By>;
  nb_water_gate_status_cplan_links_aggregate?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_field_water_gate_status */
export type Nb_Field_Water_Gate_Status_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_field_water_gate_status" */
export enum Nb_Field_Water_Gate_Status_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FieldWgId = 'field_wg_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  field_wg_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "nb_field_water_gate_status" */
export type Nb_Field_Water_Gate_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Water_Gate_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Water_Gate_Status_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  field_wg_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nb_field_water_gate_status" */
export enum Nb_Field_Water_Gate_Status_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FieldWgId = 'field_wg_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

export type Nb_Field_Water_Gate_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Water_Gate_Status_Set_Input>;
  where: Nb_Field_Water_Gate_Status_Bool_Exp;
};

/** Streaming cursor of the table "nb_field_water_gate" */
export type Nb_Field_Water_Gate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Field_Water_Gate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Field_Water_Gate_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
  wg_coor?: InputMaybe<Scalars['geometry']>;
};

/** update columns of table "nb_field_water_gate" */
export enum Nb_Field_Water_Gate_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Kind = 'kind',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start',
  /** column name */
  WgCoor = 'wg_coor'
}

export type Nb_Field_Water_Gate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Field_Water_Gate_Set_Input>;
  where: Nb_Field_Water_Gate_Bool_Exp;
};

/** nilebankで管理するユーザアセットファイルのサイズベーステーブル */
export type Nb_File_Size = {
  __typename?: 'nb_file_size';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  company_key?: Maybe<Scalars['String']>;
  file_type: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_company?: Maybe<Nb_Company>;
  /** An object relationship */
  nb_file_type: Nb_File_Type;
  total_size_bytes: Scalars['bigint'];
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "nb_file_size" */
export type Nb_File_Size_Aggregate = {
  __typename?: 'nb_file_size_aggregate';
  aggregate?: Maybe<Nb_File_Size_Aggregate_Fields>;
  nodes: Array<Nb_File_Size>;
};

export type Nb_File_Size_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_File_Size_Aggregate_Bool_Exp_Count>;
};

export type Nb_File_Size_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_File_Size_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_file_size" */
export type Nb_File_Size_Aggregate_Fields = {
  __typename?: 'nb_file_size_aggregate_fields';
  avg?: Maybe<Nb_File_Size_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_File_Size_Max_Fields>;
  min?: Maybe<Nb_File_Size_Min_Fields>;
  stddev?: Maybe<Nb_File_Size_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_File_Size_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_File_Size_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_File_Size_Sum_Fields>;
  var_pop?: Maybe<Nb_File_Size_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_File_Size_Var_Samp_Fields>;
  variance?: Maybe<Nb_File_Size_Variance_Fields>;
};


/** aggregate fields of "nb_file_size" */
export type Nb_File_Size_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_file_size" */
export type Nb_File_Size_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_File_Size_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_File_Size_Max_Order_By>;
  min?: InputMaybe<Nb_File_Size_Min_Order_By>;
  stddev?: InputMaybe<Nb_File_Size_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_File_Size_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_File_Size_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_File_Size_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_File_Size_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_File_Size_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_File_Size_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_file_size" */
export type Nb_File_Size_Arr_Rel_Insert_Input = {
  data: Array<Nb_File_Size_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_File_Size_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_File_Size_Avg_Fields = {
  __typename?: 'nb_file_size_avg_fields';
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_file_size" */
export type Nb_File_Size_Avg_Order_By = {
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_file_size". All fields are combined with a logical 'AND'. */
export type Nb_File_Size_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_File_Size_Bool_Exp>>;
  _not?: InputMaybe<Nb_File_Size_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_File_Size_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  company_key?: InputMaybe<String_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_file_type?: InputMaybe<Nb_File_Type_Bool_Exp>;
  total_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** columns and relationships of "nb_file_size_company_user_view" */
export type Nb_File_Size_Company_User_View = {
  __typename?: 'nb_file_size_company_user_view';
  company_id?: Maybe<Scalars['uuid']>;
  contract_plan_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  nbpm_contract_plan?: Maybe<Nbpm_Contract_Plan>;
  total_size_gb?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "nb_file_size_company_user_view" */
export type Nb_File_Size_Company_User_View_Aggregate = {
  __typename?: 'nb_file_size_company_user_view_aggregate';
  aggregate?: Maybe<Nb_File_Size_Company_User_View_Aggregate_Fields>;
  nodes: Array<Nb_File_Size_Company_User_View>;
};

/** aggregate fields of "nb_file_size_company_user_view" */
export type Nb_File_Size_Company_User_View_Aggregate_Fields = {
  __typename?: 'nb_file_size_company_user_view_aggregate_fields';
  avg?: Maybe<Nb_File_Size_Company_User_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_File_Size_Company_User_View_Max_Fields>;
  min?: Maybe<Nb_File_Size_Company_User_View_Min_Fields>;
  stddev?: Maybe<Nb_File_Size_Company_User_View_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_File_Size_Company_User_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_File_Size_Company_User_View_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_File_Size_Company_User_View_Sum_Fields>;
  var_pop?: Maybe<Nb_File_Size_Company_User_View_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_File_Size_Company_User_View_Var_Samp_Fields>;
  variance?: Maybe<Nb_File_Size_Company_User_View_Variance_Fields>;
};


/** aggregate fields of "nb_file_size_company_user_view" */
export type Nb_File_Size_Company_User_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_File_Size_Company_User_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Nb_File_Size_Company_User_View_Avg_Fields = {
  __typename?: 'nb_file_size_company_user_view_avg_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nb_file_size_company_user_view". All fields are combined with a logical 'AND'. */
export type Nb_File_Size_Company_User_View_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_File_Size_Company_User_View_Bool_Exp>>;
  _not?: InputMaybe<Nb_File_Size_Company_User_View_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_File_Size_Company_User_View_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  contract_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  nbpm_contract_plan?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
  total_size_gb?: InputMaybe<Float8_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Nb_File_Size_Company_User_View_Max_Fields = {
  __typename?: 'nb_file_size_company_user_view_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  contract_plan_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['String']>;
  total_size_gb?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Nb_File_Size_Company_User_View_Min_Fields = {
  __typename?: 'nb_file_size_company_user_view_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  contract_plan_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['String']>;
  total_size_gb?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "nb_file_size_company_user_view". */
export type Nb_File_Size_Company_User_View_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contract_plan_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  nbpm_contract_plan?: InputMaybe<Nbpm_Contract_Plan_Order_By>;
  total_size_gb?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "nb_file_size_company_user_view" */
export enum Nb_File_Size_Company_User_View_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractPlanId = 'contract_plan_id',
  /** column name */
  FileType = 'file_type',
  /** column name */
  TotalSizeGb = 'total_size_gb',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Nb_File_Size_Company_User_View_Stddev_Fields = {
  __typename?: 'nb_file_size_company_user_view_stddev_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Nb_File_Size_Company_User_View_Stddev_Pop_Fields = {
  __typename?: 'nb_file_size_company_user_view_stddev_pop_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Nb_File_Size_Company_User_View_Stddev_Samp_Fields = {
  __typename?: 'nb_file_size_company_user_view_stddev_samp_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nb_file_size_company_user_view" */
export type Nb_File_Size_Company_User_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_File_Size_Company_User_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_File_Size_Company_User_View_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  contract_plan_id?: InputMaybe<Scalars['uuid']>;
  file_type?: InputMaybe<Scalars['String']>;
  total_size_gb?: InputMaybe<Scalars['float8']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nb_File_Size_Company_User_View_Sum_Fields = {
  __typename?: 'nb_file_size_company_user_view_sum_fields';
  total_size_gb?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Nb_File_Size_Company_User_View_Var_Pop_Fields = {
  __typename?: 'nb_file_size_company_user_view_var_pop_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Nb_File_Size_Company_User_View_Var_Samp_Fields = {
  __typename?: 'nb_file_size_company_user_view_var_samp_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Nb_File_Size_Company_User_View_Variance_Fields = {
  __typename?: 'nb_file_size_company_user_view_variance_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "nb_file_size_company_view" */
export type Nb_File_Size_Company_View = {
  __typename?: 'nb_file_size_company_view';
  company_id?: Maybe<Scalars['uuid']>;
  contract_plan_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  nbpm_contract_plan?: Maybe<Nbpm_Contract_Plan>;
  total_size_gb?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "nb_file_size_company_view" */
export type Nb_File_Size_Company_View_Aggregate = {
  __typename?: 'nb_file_size_company_view_aggregate';
  aggregate?: Maybe<Nb_File_Size_Company_View_Aggregate_Fields>;
  nodes: Array<Nb_File_Size_Company_View>;
};

/** aggregate fields of "nb_file_size_company_view" */
export type Nb_File_Size_Company_View_Aggregate_Fields = {
  __typename?: 'nb_file_size_company_view_aggregate_fields';
  avg?: Maybe<Nb_File_Size_Company_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_File_Size_Company_View_Max_Fields>;
  min?: Maybe<Nb_File_Size_Company_View_Min_Fields>;
  stddev?: Maybe<Nb_File_Size_Company_View_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_File_Size_Company_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_File_Size_Company_View_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_File_Size_Company_View_Sum_Fields>;
  var_pop?: Maybe<Nb_File_Size_Company_View_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_File_Size_Company_View_Var_Samp_Fields>;
  variance?: Maybe<Nb_File_Size_Company_View_Variance_Fields>;
};


/** aggregate fields of "nb_file_size_company_view" */
export type Nb_File_Size_Company_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_File_Size_Company_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Nb_File_Size_Company_View_Avg_Fields = {
  __typename?: 'nb_file_size_company_view_avg_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nb_file_size_company_view". All fields are combined with a logical 'AND'. */
export type Nb_File_Size_Company_View_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_File_Size_Company_View_Bool_Exp>>;
  _not?: InputMaybe<Nb_File_Size_Company_View_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_File_Size_Company_View_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  contract_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  nbpm_contract_plan?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
  total_size_gb?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Nb_File_Size_Company_View_Max_Fields = {
  __typename?: 'nb_file_size_company_view_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  contract_plan_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['String']>;
  total_size_gb?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Nb_File_Size_Company_View_Min_Fields = {
  __typename?: 'nb_file_size_company_view_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  contract_plan_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['String']>;
  total_size_gb?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "nb_file_size_company_view". */
export type Nb_File_Size_Company_View_Order_By = {
  company_id?: InputMaybe<Order_By>;
  contract_plan_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  nbpm_contract_plan?: InputMaybe<Nbpm_Contract_Plan_Order_By>;
  total_size_gb?: InputMaybe<Order_By>;
};

/** select columns of table "nb_file_size_company_view" */
export enum Nb_File_Size_Company_View_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractPlanId = 'contract_plan_id',
  /** column name */
  FileType = 'file_type',
  /** column name */
  TotalSizeGb = 'total_size_gb'
}

/** aggregate stddev on columns */
export type Nb_File_Size_Company_View_Stddev_Fields = {
  __typename?: 'nb_file_size_company_view_stddev_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Nb_File_Size_Company_View_Stddev_Pop_Fields = {
  __typename?: 'nb_file_size_company_view_stddev_pop_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Nb_File_Size_Company_View_Stddev_Samp_Fields = {
  __typename?: 'nb_file_size_company_view_stddev_samp_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nb_file_size_company_view" */
export type Nb_File_Size_Company_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_File_Size_Company_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_File_Size_Company_View_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  contract_plan_id?: InputMaybe<Scalars['uuid']>;
  file_type?: InputMaybe<Scalars['String']>;
  total_size_gb?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Nb_File_Size_Company_View_Sum_Fields = {
  __typename?: 'nb_file_size_company_view_sum_fields';
  total_size_gb?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Nb_File_Size_Company_View_Var_Pop_Fields = {
  __typename?: 'nb_file_size_company_view_var_pop_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Nb_File_Size_Company_View_Var_Samp_Fields = {
  __typename?: 'nb_file_size_company_view_var_samp_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Nb_File_Size_Company_View_Variance_Fields = {
  __typename?: 'nb_file_size_company_view_variance_fields';
  total_size_gb?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "nb_file_size" */
export enum Nb_File_Size_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFileSizePkey = 'nb_file_size_pkey'
}

/** input type for incrementing numeric columns in table "nb_file_size" */
export type Nb_File_Size_Inc_Input = {
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "nb_file_size" */
export type Nb_File_Size_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_file_type?: InputMaybe<Nb_File_Type_Obj_Rel_Insert_Input>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_File_Size_Max_Fields = {
  __typename?: 'nb_file_size_max_fields';
  company_key?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_file_size" */
export type Nb_File_Size_Max_Order_By = {
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_File_Size_Min_Fields = {
  __typename?: 'nb_file_size_min_fields';
  company_key?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_file_size" */
export type Nb_File_Size_Min_Order_By = {
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_file_size" */
export type Nb_File_Size_Mutation_Response = {
  __typename?: 'nb_file_size_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_File_Size>;
};

/** on_conflict condition type for table "nb_file_size" */
export type Nb_File_Size_On_Conflict = {
  constraint: Nb_File_Size_Constraint;
  update_columns?: Array<Nb_File_Size_Update_Column>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_file_size". */
export type Nb_File_Size_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_file_type?: InputMaybe<Nb_File_Type_Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_file_size */
export type Nb_File_Size_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_file_size" */
export enum Nb_File_Size_Select_Column {
  /** column name */
  CompanyKey = 'company_key',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  TotalSizeBytes = 'total_size_bytes',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "nb_file_size" */
export type Nb_File_Size_Set_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Nb_File_Size_Stddev_Fields = {
  __typename?: 'nb_file_size_stddev_fields';
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_file_size" */
export type Nb_File_Size_Stddev_Order_By = {
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_File_Size_Stddev_Pop_Fields = {
  __typename?: 'nb_file_size_stddev_pop_fields';
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_file_size" */
export type Nb_File_Size_Stddev_Pop_Order_By = {
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_File_Size_Stddev_Samp_Fields = {
  __typename?: 'nb_file_size_stddev_samp_fields';
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_file_size" */
export type Nb_File_Size_Stddev_Samp_Order_By = {
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_file_size" */
export type Nb_File_Size_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_File_Size_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_File_Size_Stream_Cursor_Value_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nb_File_Size_Sum_Fields = {
  __typename?: 'nb_file_size_sum_fields';
  total_size_bytes?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "nb_file_size" */
export type Nb_File_Size_Sum_Order_By = {
  total_size_bytes?: InputMaybe<Order_By>;
};

/** update columns of table "nb_file_size" */
export enum Nb_File_Size_Update_Column {
  /** column name */
  CompanyKey = 'company_key',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  TotalSizeBytes = 'total_size_bytes',
  /** column name */
  UserId = 'user_id'
}

export type Nb_File_Size_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_File_Size_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_File_Size_Set_Input>;
  where: Nb_File_Size_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_File_Size_Var_Pop_Fields = {
  __typename?: 'nb_file_size_var_pop_fields';
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_file_size" */
export type Nb_File_Size_Var_Pop_Order_By = {
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_File_Size_Var_Samp_Fields = {
  __typename?: 'nb_file_size_var_samp_fields';
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_file_size" */
export type Nb_File_Size_Var_Samp_Order_By = {
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_File_Size_Variance_Fields = {
  __typename?: 'nb_file_size_variance_fields';
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_file_size" */
export type Nb_File_Size_Variance_Order_By = {
  total_size_bytes?: InputMaybe<Order_By>;
};

/** サイズ等の管理を行うファイルのタイプ定義 */
export type Nb_File_Type = {
  __typename?: 'nb_file_type';
  /** An array relationship */
  nb_file_sizes: Array<Nb_File_Size>;
  /** An aggregate relationship */
  nb_file_sizes_aggregate: Nb_File_Size_Aggregate;
  type: Scalars['String'];
};


/** サイズ等の管理を行うファイルのタイプ定義 */
export type Nb_File_TypeNb_File_SizesArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};


/** サイズ等の管理を行うファイルのタイプ定義 */
export type Nb_File_TypeNb_File_Sizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};

/** aggregated selection of "nb_file_type" */
export type Nb_File_Type_Aggregate = {
  __typename?: 'nb_file_type_aggregate';
  aggregate?: Maybe<Nb_File_Type_Aggregate_Fields>;
  nodes: Array<Nb_File_Type>;
};

/** aggregate fields of "nb_file_type" */
export type Nb_File_Type_Aggregate_Fields = {
  __typename?: 'nb_file_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_File_Type_Max_Fields>;
  min?: Maybe<Nb_File_Type_Min_Fields>;
};


/** aggregate fields of "nb_file_type" */
export type Nb_File_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_File_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_file_type". All fields are combined with a logical 'AND'. */
export type Nb_File_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_File_Type_Bool_Exp>>;
  _not?: InputMaybe<Nb_File_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_File_Type_Bool_Exp>>;
  nb_file_sizes?: InputMaybe<Nb_File_Size_Bool_Exp>;
  nb_file_sizes_aggregate?: InputMaybe<Nb_File_Size_Aggregate_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_file_type" */
export enum Nb_File_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  NbFileTypePkey = 'nb_file_type_pkey'
}

/** input type for inserting data into table "nb_file_type" */
export type Nb_File_Type_Insert_Input = {
  nb_file_sizes?: InputMaybe<Nb_File_Size_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nb_File_Type_Max_Fields = {
  __typename?: 'nb_file_type_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nb_File_Type_Min_Fields = {
  __typename?: 'nb_file_type_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nb_file_type" */
export type Nb_File_Type_Mutation_Response = {
  __typename?: 'nb_file_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_File_Type>;
};

/** input type for inserting object relation for remote table "nb_file_type" */
export type Nb_File_Type_Obj_Rel_Insert_Input = {
  data: Nb_File_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_File_Type_On_Conflict>;
};

/** on_conflict condition type for table "nb_file_type" */
export type Nb_File_Type_On_Conflict = {
  constraint: Nb_File_Type_Constraint;
  update_columns?: Array<Nb_File_Type_Update_Column>;
  where?: InputMaybe<Nb_File_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_file_type". */
export type Nb_File_Type_Order_By = {
  nb_file_sizes_aggregate?: InputMaybe<Nb_File_Size_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_file_type */
export type Nb_File_Type_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "nb_file_type" */
export enum Nb_File_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "nb_file_type" */
export type Nb_File_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nb_file_type" */
export type Nb_File_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_File_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_File_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nb_file_type" */
export enum Nb_File_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type Nb_File_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_File_Type_Set_Input>;
  where: Nb_File_Type_Bool_Exp;
};

/** ユーザ圃場で完了した作業 */
export type Nb_Finished_Ufield_Work = {
  __typename?: 'nb_finished_ufield_work';
  /** An object relationship */
  app_user_active: App_User_Active;
  created: Scalars['timestamptz'];
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  from_context_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  nb_cultivation_plan?: Maybe<Nb_Cultivation_Plan>;
  /** An object relationship */
  nb_task_type: Nb_Task_Type;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  /** An object relationship */
  nbtrk_context?: Maybe<Nbtrk_Context>;
  start_datetime: Scalars['timestamptz'];
  task_type: Scalars['String'];
  ufield_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
  working_time: Scalars['Int'];
};

/** aggregated selection of "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Aggregate = {
  __typename?: 'nb_finished_ufield_work_aggregate';
  aggregate?: Maybe<Nb_Finished_Ufield_Work_Aggregate_Fields>;
  nodes: Array<Nb_Finished_Ufield_Work>;
};

export type Nb_Finished_Ufield_Work_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Finished_Ufield_Work_Aggregate_Bool_Exp_Count>;
};

export type Nb_Finished_Ufield_Work_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Aggregate_Fields = {
  __typename?: 'nb_finished_ufield_work_aggregate_fields';
  avg?: Maybe<Nb_Finished_Ufield_Work_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Finished_Ufield_Work_Max_Fields>;
  min?: Maybe<Nb_Finished_Ufield_Work_Min_Fields>;
  stddev?: Maybe<Nb_Finished_Ufield_Work_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Finished_Ufield_Work_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Finished_Ufield_Work_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Finished_Ufield_Work_Sum_Fields>;
  var_pop?: Maybe<Nb_Finished_Ufield_Work_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Finished_Ufield_Work_Var_Samp_Fields>;
  variance?: Maybe<Nb_Finished_Ufield_Work_Variance_Fields>;
};


/** aggregate fields of "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Finished_Ufield_Work_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Finished_Ufield_Work_Max_Order_By>;
  min?: InputMaybe<Nb_Finished_Ufield_Work_Min_Order_By>;
  stddev?: InputMaybe<Nb_Finished_Ufield_Work_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Finished_Ufield_Work_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Finished_Ufield_Work_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Finished_Ufield_Work_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Finished_Ufield_Work_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Finished_Ufield_Work_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Finished_Ufield_Work_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Arr_Rel_Insert_Input = {
  data: Array<Nb_Finished_Ufield_Work_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Finished_Ufield_Work_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Finished_Ufield_Work_Avg_Fields = {
  __typename?: 'nb_finished_ufield_work_avg_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Avg_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_finished_ufield_work". All fields are combined with a logical 'AND'. */
export type Nb_Finished_Ufield_Work_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Finished_Ufield_Work_Bool_Exp>>;
  _not?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Finished_Ufield_Work_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  cultivation_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  from_context_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nbtrk_context?: InputMaybe<Nbtrk_Context_Bool_Exp>;
  start_datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  task_type?: InputMaybe<String_Comparison_Exp>;
  ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  working_time?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_finished_ufield_work" */
export enum Nb_Finished_Ufield_Work_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbFinishedUfieldWorkPkey = 'nb_finished_ufield_work_pkey'
}

/** input type for incrementing numeric columns in table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Inc_Input = {
  working_time?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  from_context_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Obj_Rel_Insert_Input>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Obj_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  nbtrk_context?: InputMaybe<Nbtrk_Context_Obj_Rel_Insert_Input>;
  start_datetime?: InputMaybe<Scalars['timestamptz']>;
  task_type?: InputMaybe<Scalars['String']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
  working_time?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Nb_Finished_Ufield_Work_Max_Fields = {
  __typename?: 'nb_finished_ufield_work_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  from_context_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  start_datetime?: Maybe<Scalars['timestamptz']>;
  task_type?: Maybe<Scalars['String']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
  working_time?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  from_context_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Finished_Ufield_Work_Min_Fields = {
  __typename?: 'nb_finished_ufield_work_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  from_context_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  start_datetime?: Maybe<Scalars['timestamptz']>;
  task_type?: Maybe<Scalars['String']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
  working_time?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  from_context_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Mutation_Response = {
  __typename?: 'nb_finished_ufield_work_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Finished_Ufield_Work>;
};

/** on_conflict condition type for table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_On_Conflict = {
  constraint: Nb_Finished_Ufield_Work_Constraint;
  update_columns?: Array<Nb_Finished_Ufield_Work_Update_Column>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_finished_ufield_work". */
export type Nb_Finished_Ufield_Work_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  from_context_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Order_By>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nbtrk_context?: InputMaybe<Nbtrk_Context_Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_finished_ufield_work */
export type Nb_Finished_Ufield_Work_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_finished_ufield_work" */
export enum Nb_Finished_Ufield_Work_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  FromContextId = 'from_context_id',
  /** column name */
  Id = 'id',
  /** column name */
  StartDatetime = 'start_datetime',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start',
  /** column name */
  WorkingTime = 'working_time'
}

/** input type for updating data in table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  from_context_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  start_datetime?: InputMaybe<Scalars['timestamptz']>;
  task_type?: InputMaybe<Scalars['String']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
  working_time?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Nb_Finished_Ufield_Work_Stddev_Fields = {
  __typename?: 'nb_finished_ufield_work_stddev_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Stddev_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Finished_Ufield_Work_Stddev_Pop_Fields = {
  __typename?: 'nb_finished_ufield_work_stddev_pop_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Stddev_Pop_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Finished_Ufield_Work_Stddev_Samp_Fields = {
  __typename?: 'nb_finished_ufield_work_stddev_samp_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Stddev_Samp_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Finished_Ufield_Work_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Finished_Ufield_Work_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  from_context_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  start_datetime?: InputMaybe<Scalars['timestamptz']>;
  task_type?: InputMaybe<Scalars['String']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
  working_time?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Nb_Finished_Ufield_Work_Sum_Fields = {
  __typename?: 'nb_finished_ufield_work_sum_fields';
  working_time?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Sum_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** update columns of table "nb_finished_ufield_work" */
export enum Nb_Finished_Ufield_Work_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  FromContextId = 'from_context_id',
  /** column name */
  Id = 'id',
  /** column name */
  StartDatetime = 'start_datetime',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start',
  /** column name */
  WorkingTime = 'working_time'
}

export type Nb_Finished_Ufield_Work_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Finished_Ufield_Work_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Finished_Ufield_Work_Set_Input>;
  where: Nb_Finished_Ufield_Work_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Finished_Ufield_Work_Var_Pop_Fields = {
  __typename?: 'nb_finished_ufield_work_var_pop_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Var_Pop_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Finished_Ufield_Work_Var_Samp_Fields = {
  __typename?: 'nb_finished_ufield_work_var_samp_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Var_Samp_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Finished_Ufield_Work_Variance_Fields = {
  __typename?: 'nb_finished_ufield_work_variance_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_finished_ufield_work" */
export type Nb_Finished_Ufield_Work_Variance_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr = {
  __typename?: 'nb_image_sensing_attr';
  colormap_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  lower_bound?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  /** An object relationship */
  nb_imsensing_colormap: Nb_Imsensing_Colormap;
  /** An array relationship */
  nb_raster_image_sensings: Array<Nb_Raster_Image_Sensing>;
  /** An aggregate relationship */
  nb_raster_image_sensings_aggregate: Nb_Raster_Image_Sensing_Aggregate;
  /** An array relationship */
  nbpm_imsattr_authorization_links: Array<Nbpm_Imsattr_Authorization_Link>;
  /** An aggregate relationship */
  nbpm_imsattr_authorization_links_aggregate: Nbpm_Imsattr_Authorization_Link_Aggregate;
  /** An array relationship */
  nipe_engine_informations: Array<Nipe_Engine_Information>;
  /** An aggregate relationship */
  nipe_engine_informations_aggregate: Nipe_Engine_Information_Aggregate;
  unit: Scalars['Float'];
  updated: Scalars['timestamptz'];
  upper_bound?: Maybe<Scalars['Float']>;
};


/** columns and relationships of "nb_image_sensing_attr" */
export type Nb_Image_Sensing_AttrNb_Raster_Image_SensingsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};


/** columns and relationships of "nb_image_sensing_attr" */
export type Nb_Image_Sensing_AttrNb_Raster_Image_Sensings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};


/** columns and relationships of "nb_image_sensing_attr" */
export type Nb_Image_Sensing_AttrNbpm_Imsattr_Authorization_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
};


/** columns and relationships of "nb_image_sensing_attr" */
export type Nb_Image_Sensing_AttrNbpm_Imsattr_Authorization_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
};


/** columns and relationships of "nb_image_sensing_attr" */
export type Nb_Image_Sensing_AttrNipe_Engine_InformationsArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Information_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
};


/** columns and relationships of "nb_image_sensing_attr" */
export type Nb_Image_Sensing_AttrNipe_Engine_Informations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Information_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
};

/** aggregated selection of "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Aggregate = {
  __typename?: 'nb_image_sensing_attr_aggregate';
  aggregate?: Maybe<Nb_Image_Sensing_Attr_Aggregate_Fields>;
  nodes: Array<Nb_Image_Sensing_Attr>;
};

export type Nb_Image_Sensing_Attr_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Image_Sensing_Attr_Aggregate_Bool_Exp_Count>;
};

export type Nb_Image_Sensing_Attr_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Image_Sensing_Attr_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Aggregate_Fields = {
  __typename?: 'nb_image_sensing_attr_aggregate_fields';
  avg?: Maybe<Nb_Image_Sensing_Attr_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Image_Sensing_Attr_Max_Fields>;
  min?: Maybe<Nb_Image_Sensing_Attr_Min_Fields>;
  stddev?: Maybe<Nb_Image_Sensing_Attr_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Image_Sensing_Attr_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Image_Sensing_Attr_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Image_Sensing_Attr_Sum_Fields>;
  var_pop?: Maybe<Nb_Image_Sensing_Attr_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Image_Sensing_Attr_Var_Samp_Fields>;
  variance?: Maybe<Nb_Image_Sensing_Attr_Variance_Fields>;
};


/** aggregate fields of "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Image_Sensing_Attr_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Image_Sensing_Attr_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Image_Sensing_Attr_Max_Order_By>;
  min?: InputMaybe<Nb_Image_Sensing_Attr_Min_Order_By>;
  stddev?: InputMaybe<Nb_Image_Sensing_Attr_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Image_Sensing_Attr_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Image_Sensing_Attr_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Image_Sensing_Attr_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Image_Sensing_Attr_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Image_Sensing_Attr_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Image_Sensing_Attr_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Arr_Rel_Insert_Input = {
  data: Array<Nb_Image_Sensing_Attr_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Image_Sensing_Attr_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Image_Sensing_Attr_Avg_Fields = {
  __typename?: 'nb_image_sensing_attr_avg_fields';
  lower_bound?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
  upper_bound?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Avg_Order_By = {
  lower_bound?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  upper_bound?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_image_sensing_attr". All fields are combined with a logical 'AND'. */
export type Nb_Image_Sensing_Attr_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Image_Sensing_Attr_Bool_Exp>>;
  _not?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Image_Sensing_Attr_Bool_Exp>>;
  colormap_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  lower_bound?: InputMaybe<Float_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_imsensing_colormap?: InputMaybe<Nb_Imsensing_Colormap_Bool_Exp>;
  nb_raster_image_sensings?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
  nb_raster_image_sensings_aggregate?: InputMaybe<Nb_Raster_Image_Sensing_Aggregate_Bool_Exp>;
  nbpm_imsattr_authorization_links?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
  nbpm_imsattr_authorization_links_aggregate?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Aggregate_Bool_Exp>;
  nipe_engine_informations?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
  nipe_engine_informations_aggregate?: InputMaybe<Nipe_Engine_Information_Aggregate_Bool_Exp>;
  unit?: InputMaybe<Float_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  upper_bound?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_image_sensing_attr" */
export enum Nb_Image_Sensing_Attr_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbImageSensingAttrKeyKey = 'nb_image_sensing_attr_key_key',
  /** unique or primary key constraint on columns "id" */
  NbImageSensingAttrPkey = 'nb_image_sensing_attr_pkey'
}

/** input type for incrementing numeric columns in table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Inc_Input = {
  lower_bound?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['Float']>;
  upper_bound?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Insert_Input = {
  colormap_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  lower_bound?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  nb_imsensing_colormap?: InputMaybe<Nb_Imsensing_Colormap_Obj_Rel_Insert_Input>;
  nb_raster_image_sensings?: InputMaybe<Nb_Raster_Image_Sensing_Arr_Rel_Insert_Input>;
  nbpm_imsattr_authorization_links?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Arr_Rel_Insert_Input>;
  nipe_engine_informations?: InputMaybe<Nipe_Engine_Information_Arr_Rel_Insert_Input>;
  unit?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  upper_bound?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Nb_Image_Sensing_Attr_Max_Fields = {
  __typename?: 'nb_image_sensing_attr_max_fields';
  colormap_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  lower_bound?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['timestamptz']>;
  upper_bound?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Max_Order_By = {
  colormap_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  lower_bound?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upper_bound?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Image_Sensing_Attr_Min_Fields = {
  __typename?: 'nb_image_sensing_attr_min_fields';
  colormap_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  lower_bound?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['timestamptz']>;
  upper_bound?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Min_Order_By = {
  colormap_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  lower_bound?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upper_bound?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Mutation_Response = {
  __typename?: 'nb_image_sensing_attr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Image_Sensing_Attr>;
};

/** input type for inserting object relation for remote table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Obj_Rel_Insert_Input = {
  data: Nb_Image_Sensing_Attr_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Image_Sensing_Attr_On_Conflict>;
};

/** on_conflict condition type for table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_On_Conflict = {
  constraint: Nb_Image_Sensing_Attr_Constraint;
  update_columns?: Array<Nb_Image_Sensing_Attr_Update_Column>;
  where?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_image_sensing_attr". */
export type Nb_Image_Sensing_Attr_Order_By = {
  colormap_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  lower_bound?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_imsensing_colormap?: InputMaybe<Nb_Imsensing_Colormap_Order_By>;
  nb_raster_image_sensings_aggregate?: InputMaybe<Nb_Raster_Image_Sensing_Aggregate_Order_By>;
  nbpm_imsattr_authorization_links_aggregate?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Aggregate_Order_By>;
  nipe_engine_informations_aggregate?: InputMaybe<Nipe_Engine_Information_Aggregate_Order_By>;
  unit?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upper_bound?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_image_sensing_attr */
export type Nb_Image_Sensing_Attr_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_image_sensing_attr" */
export enum Nb_Image_Sensing_Attr_Select_Column {
  /** column name */
  ColormapId = 'colormap_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LowerBound = 'lower_bound',
  /** column name */
  Name = 'name',
  /** column name */
  Unit = 'unit',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpperBound = 'upper_bound'
}

/** input type for updating data in table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Set_Input = {
  colormap_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  lower_bound?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  upper_bound?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Nb_Image_Sensing_Attr_Stddev_Fields = {
  __typename?: 'nb_image_sensing_attr_stddev_fields';
  lower_bound?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
  upper_bound?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Stddev_Order_By = {
  lower_bound?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  upper_bound?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Image_Sensing_Attr_Stddev_Pop_Fields = {
  __typename?: 'nb_image_sensing_attr_stddev_pop_fields';
  lower_bound?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
  upper_bound?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Stddev_Pop_Order_By = {
  lower_bound?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  upper_bound?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Image_Sensing_Attr_Stddev_Samp_Fields = {
  __typename?: 'nb_image_sensing_attr_stddev_samp_fields';
  lower_bound?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
  upper_bound?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Stddev_Samp_Order_By = {
  lower_bound?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  upper_bound?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Image_Sensing_Attr_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Image_Sensing_Attr_Stream_Cursor_Value_Input = {
  colormap_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  lower_bound?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  upper_bound?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Nb_Image_Sensing_Attr_Sum_Fields = {
  __typename?: 'nb_image_sensing_attr_sum_fields';
  lower_bound?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
  upper_bound?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Sum_Order_By = {
  lower_bound?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  upper_bound?: InputMaybe<Order_By>;
};

/** update columns of table "nb_image_sensing_attr" */
export enum Nb_Image_Sensing_Attr_Update_Column {
  /** column name */
  ColormapId = 'colormap_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LowerBound = 'lower_bound',
  /** column name */
  Name = 'name',
  /** column name */
  Unit = 'unit',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpperBound = 'upper_bound'
}

export type Nb_Image_Sensing_Attr_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Image_Sensing_Attr_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Image_Sensing_Attr_Set_Input>;
  where: Nb_Image_Sensing_Attr_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Image_Sensing_Attr_Var_Pop_Fields = {
  __typename?: 'nb_image_sensing_attr_var_pop_fields';
  lower_bound?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
  upper_bound?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Var_Pop_Order_By = {
  lower_bound?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  upper_bound?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Image_Sensing_Attr_Var_Samp_Fields = {
  __typename?: 'nb_image_sensing_attr_var_samp_fields';
  lower_bound?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
  upper_bound?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Var_Samp_Order_By = {
  lower_bound?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  upper_bound?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Image_Sensing_Attr_Variance_Fields = {
  __typename?: 'nb_image_sensing_attr_variance_fields';
  lower_bound?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['Float']>;
  upper_bound?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_image_sensing_attr" */
export type Nb_Image_Sensing_Attr_Variance_Order_By = {
  lower_bound?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  upper_bound?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_imsensing_colormap" */
export type Nb_Imsensing_Colormap = {
  __typename?: 'nb_imsensing_colormap';
  color_map: Scalars['json'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  /** An array relationship */
  nb_image_sensing_attrs: Array<Nb_Image_Sensing_Attr>;
  /** An aggregate relationship */
  nb_image_sensing_attrs_aggregate: Nb_Image_Sensing_Attr_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_imsensing_colormap" */
export type Nb_Imsensing_ColormapColor_MapArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "nb_imsensing_colormap" */
export type Nb_Imsensing_ColormapNb_Image_Sensing_AttrsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Image_Sensing_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Image_Sensing_Attr_Order_By>>;
  where?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
};


/** columns and relationships of "nb_imsensing_colormap" */
export type Nb_Imsensing_ColormapNb_Image_Sensing_Attrs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Image_Sensing_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Image_Sensing_Attr_Order_By>>;
  where?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
};

/** aggregated selection of "nb_imsensing_colormap" */
export type Nb_Imsensing_Colormap_Aggregate = {
  __typename?: 'nb_imsensing_colormap_aggregate';
  aggregate?: Maybe<Nb_Imsensing_Colormap_Aggregate_Fields>;
  nodes: Array<Nb_Imsensing_Colormap>;
};

/** aggregate fields of "nb_imsensing_colormap" */
export type Nb_Imsensing_Colormap_Aggregate_Fields = {
  __typename?: 'nb_imsensing_colormap_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Imsensing_Colormap_Max_Fields>;
  min?: Maybe<Nb_Imsensing_Colormap_Min_Fields>;
};


/** aggregate fields of "nb_imsensing_colormap" */
export type Nb_Imsensing_Colormap_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Imsensing_Colormap_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_imsensing_colormap". All fields are combined with a logical 'AND'. */
export type Nb_Imsensing_Colormap_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Imsensing_Colormap_Bool_Exp>>;
  _not?: InputMaybe<Nb_Imsensing_Colormap_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Imsensing_Colormap_Bool_Exp>>;
  color_map?: InputMaybe<Json_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  nb_image_sensing_attrs?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
  nb_image_sensing_attrs_aggregate?: InputMaybe<Nb_Image_Sensing_Attr_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_imsensing_colormap" */
export enum Nb_Imsensing_Colormap_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbImsensingColormapNameKey = 'nb_imsensing_colormap_name_key',
  /** unique or primary key constraint on columns "id" */
  NbImsensingColormapPkey = 'nb_imsensing_colormap_pkey'
}

/** input type for inserting data into table "nb_imsensing_colormap" */
export type Nb_Imsensing_Colormap_Insert_Input = {
  color_map?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  nb_image_sensing_attrs?: InputMaybe<Nb_Image_Sensing_Attr_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Imsensing_Colormap_Max_Fields = {
  __typename?: 'nb_imsensing_colormap_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Imsensing_Colormap_Min_Fields = {
  __typename?: 'nb_imsensing_colormap_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_imsensing_colormap" */
export type Nb_Imsensing_Colormap_Mutation_Response = {
  __typename?: 'nb_imsensing_colormap_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Imsensing_Colormap>;
};

/** input type for inserting object relation for remote table "nb_imsensing_colormap" */
export type Nb_Imsensing_Colormap_Obj_Rel_Insert_Input = {
  data: Nb_Imsensing_Colormap_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Imsensing_Colormap_On_Conflict>;
};

/** on_conflict condition type for table "nb_imsensing_colormap" */
export type Nb_Imsensing_Colormap_On_Conflict = {
  constraint: Nb_Imsensing_Colormap_Constraint;
  update_columns?: Array<Nb_Imsensing_Colormap_Update_Column>;
  where?: InputMaybe<Nb_Imsensing_Colormap_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_imsensing_colormap". */
export type Nb_Imsensing_Colormap_Order_By = {
  color_map?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  nb_image_sensing_attrs_aggregate?: InputMaybe<Nb_Image_Sensing_Attr_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_imsensing_colormap */
export type Nb_Imsensing_Colormap_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_imsensing_colormap" */
export enum Nb_Imsensing_Colormap_Select_Column {
  /** column name */
  ColorMap = 'color_map',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_imsensing_colormap" */
export type Nb_Imsensing_Colormap_Set_Input = {
  color_map?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_imsensing_colormap" */
export type Nb_Imsensing_Colormap_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Imsensing_Colormap_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Imsensing_Colormap_Stream_Cursor_Value_Input = {
  color_map?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_imsensing_colormap" */
export enum Nb_Imsensing_Colormap_Update_Column {
  /** column name */
  ColorMap = 'color_map',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Updated = 'updated'
}

export type Nb_Imsensing_Colormap_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Imsensing_Colormap_Set_Input>;
  where: Nb_Imsensing_Colormap_Bool_Exp;
};

/** columns and relationships of "nb_log_user_position" */
export type Nb_Log_User_Position = {
  __typename?: 'nb_log_user_position';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  created: Scalars['timestamptz'];
  creator_name: Scalars['String'];
  datetime: Scalars['timestamptz'];
  id: Scalars['uuid'];
  location: Scalars['geometry'];
  updated: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};

/** aggregated selection of "nb_log_user_position" */
export type Nb_Log_User_Position_Aggregate = {
  __typename?: 'nb_log_user_position_aggregate';
  aggregate?: Maybe<Nb_Log_User_Position_Aggregate_Fields>;
  nodes: Array<Nb_Log_User_Position>;
};

export type Nb_Log_User_Position_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Log_User_Position_Aggregate_Bool_Exp_Count>;
};

export type Nb_Log_User_Position_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Log_User_Position_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Log_User_Position_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_log_user_position" */
export type Nb_Log_User_Position_Aggregate_Fields = {
  __typename?: 'nb_log_user_position_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Log_User_Position_Max_Fields>;
  min?: Maybe<Nb_Log_User_Position_Min_Fields>;
};


/** aggregate fields of "nb_log_user_position" */
export type Nb_Log_User_Position_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Log_User_Position_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_log_user_position" */
export type Nb_Log_User_Position_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Log_User_Position_Max_Order_By>;
  min?: InputMaybe<Nb_Log_User_Position_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_log_user_position" */
export type Nb_Log_User_Position_Arr_Rel_Insert_Input = {
  data: Array<Nb_Log_User_Position_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Log_User_Position_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_log_user_position". All fields are combined with a logical 'AND'. */
export type Nb_Log_User_Position_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Log_User_Position_Bool_Exp>>;
  _not?: InputMaybe<Nb_Log_User_Position_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Log_User_Position_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_name?: InputMaybe<String_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Geometry_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_log_user_position" */
export enum Nb_Log_User_Position_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbLogUserPositionPkey = 'nb_log_user_position_pkey'
}

/** input type for inserting data into table "nb_log_user_position" */
export type Nb_Log_User_Position_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Scalars['geometry']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Log_User_Position_Max_Fields = {
  __typename?: 'nb_log_user_position_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_log_user_position" */
export type Nb_Log_User_Position_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Log_User_Position_Min_Fields = {
  __typename?: 'nb_log_user_position_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_log_user_position" */
export type Nb_Log_User_Position_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_log_user_position" */
export type Nb_Log_User_Position_Mutation_Response = {
  __typename?: 'nb_log_user_position_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Log_User_Position>;
};

/** on_conflict condition type for table "nb_log_user_position" */
export type Nb_Log_User_Position_On_Conflict = {
  constraint: Nb_Log_User_Position_Constraint;
  update_columns?: Array<Nb_Log_User_Position_Update_Column>;
  where?: InputMaybe<Nb_Log_User_Position_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_log_user_position". */
export type Nb_Log_User_Position_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_log_user_position */
export type Nb_Log_User_Position_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_log_user_position" */
export enum Nb_Log_User_Position_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_log_user_position" */
export type Nb_Log_User_Position_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Scalars['geometry']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_log_user_position" */
export type Nb_Log_User_Position_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Log_User_Position_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Log_User_Position_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Scalars['geometry']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_log_user_position" */
export enum Nb_Log_User_Position_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Log_User_Position_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Log_User_Position_Set_Input>;
  where: Nb_Log_User_Position_Bool_Exp;
};

/** columns and relationships of "nb_maff_field" */
export type Nb_Maff_Field = {
  __typename?: 'nb_maff_field';
  address?: Maybe<Scalars['String']>;
  area: Scalars['float8'];
  code: Scalars['String'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  kind: Scalars['String'];
  /** An array relationship */
  nb_maff_field_links: Array<Nb_Maff_Field_Link>;
  /** An aggregate relationship */
  nb_maff_field_links_aggregate: Nb_Maff_Field_Link_Aggregate;
  /** An object relationship */
  nb_soil_characteristic?: Maybe<Nb_Soil_Characteristic>;
  pin_coor?: Maybe<Scalars['geometry']>;
  polygon_field_master: Scalars['geometry'];
  soil_id?: Maybe<Scalars['uuid']>;
  updated: Scalars['timestamptz'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nb_maff_field" */
export type Nb_Maff_FieldNb_Maff_Field_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Link_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
};


/** columns and relationships of "nb_maff_field" */
export type Nb_Maff_FieldNb_Maff_Field_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Link_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
};

/** aggregated selection of "nb_maff_field" */
export type Nb_Maff_Field_Aggregate = {
  __typename?: 'nb_maff_field_aggregate';
  aggregate?: Maybe<Nb_Maff_Field_Aggregate_Fields>;
  nodes: Array<Nb_Maff_Field>;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Nb_Maff_Field_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Nb_Maff_Field_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Nb_Maff_Field_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Nb_Maff_Field_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Nb_Maff_Field_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Nb_Maff_Field_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Nb_Maff_Field_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Nb_Maff_Field_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Nb_Maff_Field_Aggregate_Bool_Exp_Var_Samp>;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp_Avg = {
  arguments: Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp_Corr = {
  arguments: Nb_Maff_Field_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Maff_Field_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Nb_Maff_Field_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp_Max = {
  arguments: Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp_Min = {
  arguments: Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp_Sum = {
  arguments: Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Maff_Field_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "nb_maff_field" */
export type Nb_Maff_Field_Aggregate_Fields = {
  __typename?: 'nb_maff_field_aggregate_fields';
  avg?: Maybe<Nb_Maff_Field_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Maff_Field_Max_Fields>;
  min?: Maybe<Nb_Maff_Field_Min_Fields>;
  stddev?: Maybe<Nb_Maff_Field_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Maff_Field_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Maff_Field_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Maff_Field_Sum_Fields>;
  var_pop?: Maybe<Nb_Maff_Field_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Maff_Field_Var_Samp_Fields>;
  variance?: Maybe<Nb_Maff_Field_Variance_Fields>;
};


/** aggregate fields of "nb_maff_field" */
export type Nb_Maff_Field_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Maff_Field_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_maff_field" */
export type Nb_Maff_Field_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Maff_Field_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Maff_Field_Max_Order_By>;
  min?: InputMaybe<Nb_Maff_Field_Min_Order_By>;
  stddev?: InputMaybe<Nb_Maff_Field_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Maff_Field_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Maff_Field_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Maff_Field_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Maff_Field_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Maff_Field_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Maff_Field_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_maff_field" */
export type Nb_Maff_Field_Arr_Rel_Insert_Input = {
  data: Array<Nb_Maff_Field_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Maff_Field_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Maff_Field_Avg_Fields = {
  __typename?: 'nb_maff_field_avg_fields';
  area?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_maff_field" */
export type Nb_Maff_Field_Avg_Order_By = {
  area?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_maff_field". All fields are combined with a logical 'AND'. */
export type Nb_Maff_Field_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Maff_Field_Bool_Exp>>;
  _not?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Maff_Field_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  area?: InputMaybe<Float8_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  nb_maff_field_links?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
  nb_maff_field_links_aggregate?: InputMaybe<Nb_Maff_Field_Link_Aggregate_Bool_Exp>;
  nb_soil_characteristic?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
  pin_coor?: InputMaybe<Geometry_Comparison_Exp>;
  polygon_field_master?: InputMaybe<Geometry_Comparison_Exp>;
  soil_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_maff_field" */
export enum Nb_Maff_Field_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbMaffFieldPkey = 'nb_maff_field_pkey'
}

/** input type for incrementing numeric columns in table "nb_maff_field" */
export type Nb_Maff_Field_Inc_Input = {
  area?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "nb_maff_field" */
export type Nb_Maff_Field_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<Scalars['float8']>;
  code?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  nb_maff_field_links?: InputMaybe<Nb_Maff_Field_Link_Arr_Rel_Insert_Input>;
  nb_soil_characteristic?: InputMaybe<Nb_Soil_Characteristic_Obj_Rel_Insert_Input>;
  pin_coor?: InputMaybe<Scalars['geometry']>;
  polygon_field_master?: InputMaybe<Scalars['geometry']>;
  soil_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "nb_maff_field_link" */
export type Nb_Maff_Field_Link = {
  __typename?: 'nb_maff_field_link';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  maff_field_id: Scalars['uuid'];
  master_field_id: Scalars['uuid'];
  /** An object relationship */
  nb_maff_field: Nb_Maff_Field;
  /** An object relationship */
  nb_mfield: Nb_Mfield;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_maff_field_link" */
export type Nb_Maff_Field_Link_Aggregate = {
  __typename?: 'nb_maff_field_link_aggregate';
  aggregate?: Maybe<Nb_Maff_Field_Link_Aggregate_Fields>;
  nodes: Array<Nb_Maff_Field_Link>;
};

export type Nb_Maff_Field_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Maff_Field_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Maff_Field_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Maff_Field_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_maff_field_link" */
export type Nb_Maff_Field_Link_Aggregate_Fields = {
  __typename?: 'nb_maff_field_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Maff_Field_Link_Max_Fields>;
  min?: Maybe<Nb_Maff_Field_Link_Min_Fields>;
};


/** aggregate fields of "nb_maff_field_link" */
export type Nb_Maff_Field_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Maff_Field_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_maff_field_link" */
export type Nb_Maff_Field_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Maff_Field_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Maff_Field_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_maff_field_link" */
export type Nb_Maff_Field_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Maff_Field_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Maff_Field_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_maff_field_link". All fields are combined with a logical 'AND'. */
export type Nb_Maff_Field_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Maff_Field_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Maff_Field_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  maff_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  master_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_maff_field?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  nb_mfield?: InputMaybe<Nb_Mfield_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_maff_field_link" */
export enum Nb_Maff_Field_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbMaffFieldLinkPkey = 'nb_maff_field_link_pkey'
}

/** input type for inserting data into table "nb_maff_field_link" */
export type Nb_Maff_Field_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maff_field_id?: InputMaybe<Scalars['uuid']>;
  master_field_id?: InputMaybe<Scalars['uuid']>;
  nb_maff_field?: InputMaybe<Nb_Maff_Field_Obj_Rel_Insert_Input>;
  nb_mfield?: InputMaybe<Nb_Mfield_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Maff_Field_Link_Max_Fields = {
  __typename?: 'nb_maff_field_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maff_field_id?: Maybe<Scalars['uuid']>;
  master_field_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_maff_field_link" */
export type Nb_Maff_Field_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maff_field_id?: InputMaybe<Order_By>;
  master_field_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Maff_Field_Link_Min_Fields = {
  __typename?: 'nb_maff_field_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  maff_field_id?: Maybe<Scalars['uuid']>;
  master_field_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_maff_field_link" */
export type Nb_Maff_Field_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maff_field_id?: InputMaybe<Order_By>;
  master_field_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_maff_field_link" */
export type Nb_Maff_Field_Link_Mutation_Response = {
  __typename?: 'nb_maff_field_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Maff_Field_Link>;
};

/** on_conflict condition type for table "nb_maff_field_link" */
export type Nb_Maff_Field_Link_On_Conflict = {
  constraint: Nb_Maff_Field_Link_Constraint;
  update_columns?: Array<Nb_Maff_Field_Link_Update_Column>;
  where?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_maff_field_link". */
export type Nb_Maff_Field_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maff_field_id?: InputMaybe<Order_By>;
  master_field_id?: InputMaybe<Order_By>;
  nb_maff_field?: InputMaybe<Nb_Maff_Field_Order_By>;
  nb_mfield?: InputMaybe<Nb_Mfield_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_maff_field_link */
export type Nb_Maff_Field_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_maff_field_link" */
export enum Nb_Maff_Field_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  MaffFieldId = 'maff_field_id',
  /** column name */
  MasterFieldId = 'master_field_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_maff_field_link" */
export type Nb_Maff_Field_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maff_field_id?: InputMaybe<Scalars['uuid']>;
  master_field_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_maff_field_link" */
export type Nb_Maff_Field_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Maff_Field_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Maff_Field_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  maff_field_id?: InputMaybe<Scalars['uuid']>;
  master_field_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_maff_field_link" */
export enum Nb_Maff_Field_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  MaffFieldId = 'maff_field_id',
  /** column name */
  MasterFieldId = 'master_field_id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Maff_Field_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Maff_Field_Link_Set_Input>;
  where: Nb_Maff_Field_Link_Bool_Exp;
};

/** aggregate max on columns */
export type Nb_Maff_Field_Max_Fields = {
  __typename?: 'nb_maff_field_max_fields';
  address?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['float8']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  soil_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_maff_field" */
export type Nb_Maff_Field_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  area?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  soil_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Maff_Field_Min_Fields = {
  __typename?: 'nb_maff_field_min_fields';
  address?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['float8']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  soil_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_maff_field" */
export type Nb_Maff_Field_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  area?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  soil_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_maff_field" */
export type Nb_Maff_Field_Mutation_Response = {
  __typename?: 'nb_maff_field_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Maff_Field>;
};

/** input type for inserting object relation for remote table "nb_maff_field" */
export type Nb_Maff_Field_Obj_Rel_Insert_Input = {
  data: Nb_Maff_Field_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Maff_Field_On_Conflict>;
};

/** on_conflict condition type for table "nb_maff_field" */
export type Nb_Maff_Field_On_Conflict = {
  constraint: Nb_Maff_Field_Constraint;
  update_columns?: Array<Nb_Maff_Field_Update_Column>;
  where?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_maff_field". */
export type Nb_Maff_Field_Order_By = {
  address?: InputMaybe<Order_By>;
  area?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  nb_maff_field_links_aggregate?: InputMaybe<Nb_Maff_Field_Link_Aggregate_Order_By>;
  nb_soil_characteristic?: InputMaybe<Nb_Soil_Characteristic_Order_By>;
  pin_coor?: InputMaybe<Order_By>;
  polygon_field_master?: InputMaybe<Order_By>;
  soil_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_maff_field */
export type Nb_Maff_Field_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_maff_field" */
export enum Nb_Maff_Field_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Area = 'area',
  /** column name */
  Code = 'code',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Kind = 'kind',
  /** column name */
  PinCoor = 'pin_coor',
  /** column name */
  PolygonFieldMaster = 'polygon_field_master',
  /** column name */
  SoilId = 'soil_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** select "nb_maff_field_aggregate_bool_exp_avg_arguments_columns" columns of table "nb_maff_field" */
export enum Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Area = 'area'
}

/** select "nb_maff_field_aggregate_bool_exp_corr_arguments_columns" columns of table "nb_maff_field" */
export enum Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Area = 'area'
}

/** select "nb_maff_field_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "nb_maff_field" */
export enum Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Area = 'area'
}

/** select "nb_maff_field_aggregate_bool_exp_max_arguments_columns" columns of table "nb_maff_field" */
export enum Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Area = 'area'
}

/** select "nb_maff_field_aggregate_bool_exp_min_arguments_columns" columns of table "nb_maff_field" */
export enum Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Area = 'area'
}

/** select "nb_maff_field_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "nb_maff_field" */
export enum Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Area = 'area'
}

/** select "nb_maff_field_aggregate_bool_exp_sum_arguments_columns" columns of table "nb_maff_field" */
export enum Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Area = 'area'
}

/** select "nb_maff_field_aggregate_bool_exp_var_samp_arguments_columns" columns of table "nb_maff_field" */
export enum Nb_Maff_Field_Select_Column_Nb_Maff_Field_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Area = 'area'
}

/** input type for updating data in table "nb_maff_field" */
export type Nb_Maff_Field_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<Scalars['float8']>;
  code?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  pin_coor?: InputMaybe<Scalars['geometry']>;
  polygon_field_master?: InputMaybe<Scalars['geometry']>;
  soil_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nb_Maff_Field_Stddev_Fields = {
  __typename?: 'nb_maff_field_stddev_fields';
  area?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_maff_field" */
export type Nb_Maff_Field_Stddev_Order_By = {
  area?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Maff_Field_Stddev_Pop_Fields = {
  __typename?: 'nb_maff_field_stddev_pop_fields';
  area?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_maff_field" */
export type Nb_Maff_Field_Stddev_Pop_Order_By = {
  area?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Maff_Field_Stddev_Samp_Fields = {
  __typename?: 'nb_maff_field_stddev_samp_fields';
  area?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_maff_field" */
export type Nb_Maff_Field_Stddev_Samp_Order_By = {
  area?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_maff_field" */
export type Nb_Maff_Field_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Maff_Field_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Maff_Field_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<Scalars['float8']>;
  code?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  pin_coor?: InputMaybe<Scalars['geometry']>;
  polygon_field_master?: InputMaybe<Scalars['geometry']>;
  soil_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Maff_Field_Sum_Fields = {
  __typename?: 'nb_maff_field_sum_fields';
  area?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "nb_maff_field" */
export type Nb_Maff_Field_Sum_Order_By = {
  area?: InputMaybe<Order_By>;
};

/** update columns of table "nb_maff_field" */
export enum Nb_Maff_Field_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Area = 'area',
  /** column name */
  Code = 'code',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Kind = 'kind',
  /** column name */
  PinCoor = 'pin_coor',
  /** column name */
  PolygonFieldMaster = 'polygon_field_master',
  /** column name */
  SoilId = 'soil_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Maff_Field_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Maff_Field_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Maff_Field_Set_Input>;
  where: Nb_Maff_Field_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Maff_Field_Var_Pop_Fields = {
  __typename?: 'nb_maff_field_var_pop_fields';
  area?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_maff_field" */
export type Nb_Maff_Field_Var_Pop_Order_By = {
  area?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Maff_Field_Var_Samp_Fields = {
  __typename?: 'nb_maff_field_var_samp_fields';
  area?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_maff_field" */
export type Nb_Maff_Field_Var_Samp_Order_By = {
  area?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Maff_Field_Variance_Fields = {
  __typename?: 'nb_maff_field_variance_fields';
  area?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_maff_field" */
export type Nb_Maff_Field_Variance_Order_By = {
  area?: InputMaybe<Order_By>;
};

/** メモと圃場紐付けファイル間にリレーションを作るテーブル */
export type Nb_Memo_File_Link = {
  __typename?: 'nb_memo_file_link';
  created: Scalars['timestamptz'];
  field_file_id: Scalars['uuid'];
  field_memo_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_field_file: Nb_Field_File;
  /** An object relationship */
  nb_field_memo: Nb_Field_Memo;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_memo_file_link" */
export type Nb_Memo_File_Link_Aggregate = {
  __typename?: 'nb_memo_file_link_aggregate';
  aggregate?: Maybe<Nb_Memo_File_Link_Aggregate_Fields>;
  nodes: Array<Nb_Memo_File_Link>;
};

export type Nb_Memo_File_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Memo_File_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Memo_File_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Memo_File_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_memo_file_link" */
export type Nb_Memo_File_Link_Aggregate_Fields = {
  __typename?: 'nb_memo_file_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Memo_File_Link_Max_Fields>;
  min?: Maybe<Nb_Memo_File_Link_Min_Fields>;
};


/** aggregate fields of "nb_memo_file_link" */
export type Nb_Memo_File_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Memo_File_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_memo_file_link" */
export type Nb_Memo_File_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Memo_File_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Memo_File_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_memo_file_link" */
export type Nb_Memo_File_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Memo_File_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Memo_File_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_memo_file_link". All fields are combined with a logical 'AND'. */
export type Nb_Memo_File_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Memo_File_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Memo_File_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  field_memo_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_file?: InputMaybe<Nb_Field_File_Bool_Exp>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_memo_file_link" */
export enum Nb_Memo_File_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbMemoFileLinkPkey = 'nb_memo_file_link_pkey'
}

/** input type for inserting data into table "nb_memo_file_link" */
export type Nb_Memo_File_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_file_id?: InputMaybe<Scalars['uuid']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_file?: InputMaybe<Nb_Field_File_Obj_Rel_Insert_Input>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Memo_File_Link_Max_Fields = {
  __typename?: 'nb_memo_file_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_file_id?: Maybe<Scalars['uuid']>;
  field_memo_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_memo_file_link" */
export type Nb_Memo_File_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  field_file_id?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Memo_File_Link_Min_Fields = {
  __typename?: 'nb_memo_file_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_file_id?: Maybe<Scalars['uuid']>;
  field_memo_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_memo_file_link" */
export type Nb_Memo_File_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  field_file_id?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_memo_file_link" */
export type Nb_Memo_File_Link_Mutation_Response = {
  __typename?: 'nb_memo_file_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Memo_File_Link>;
};

/** on_conflict condition type for table "nb_memo_file_link" */
export type Nb_Memo_File_Link_On_Conflict = {
  constraint: Nb_Memo_File_Link_Constraint;
  update_columns?: Array<Nb_Memo_File_Link_Update_Column>;
  where?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_memo_file_link". */
export type Nb_Memo_File_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  field_file_id?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_file?: InputMaybe<Nb_Field_File_Order_By>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_memo_file_link */
export type Nb_Memo_File_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_memo_file_link" */
export enum Nb_Memo_File_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldFileId = 'field_file_id',
  /** column name */
  FieldMemoId = 'field_memo_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_memo_file_link" */
export type Nb_Memo_File_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_file_id?: InputMaybe<Scalars['uuid']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_memo_file_link" */
export type Nb_Memo_File_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Memo_File_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Memo_File_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_file_id?: InputMaybe<Scalars['uuid']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_memo_file_link" */
export enum Nb_Memo_File_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldFileId = 'field_file_id',
  /** column name */
  FieldMemoId = 'field_memo_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Memo_File_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Memo_File_Link_Set_Input>;
  where: Nb_Memo_File_Link_Bool_Exp;
};

/** columns and relationships of "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link = {
  __typename?: 'nb_memo_picture_link';
  created: Scalars['timestamptz'];
  field_capture_id: Scalars['uuid'];
  field_memo_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_field_memo: Nb_Field_Memo;
  /** An object relationship */
  nb_field_picture: Nb_Field_Picture;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_Aggregate = {
  __typename?: 'nb_memo_picture_link_aggregate';
  aggregate?: Maybe<Nb_Memo_Picture_Link_Aggregate_Fields>;
  nodes: Array<Nb_Memo_Picture_Link>;
};

export type Nb_Memo_Picture_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Memo_Picture_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Memo_Picture_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Memo_Picture_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_Aggregate_Fields = {
  __typename?: 'nb_memo_picture_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Memo_Picture_Link_Max_Fields>;
  min?: Maybe<Nb_Memo_Picture_Link_Min_Fields>;
};


/** aggregate fields of "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Memo_Picture_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Memo_Picture_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Memo_Picture_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Memo_Picture_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Memo_Picture_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_memo_picture_link". All fields are combined with a logical 'AND'. */
export type Nb_Memo_Picture_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Memo_Picture_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Memo_Picture_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_capture_id?: InputMaybe<Uuid_Comparison_Exp>;
  field_memo_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_memo_picture_link" */
export enum Nb_Memo_Picture_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbMemoPictureLinkPkey = 'nb_memo_picture_link_pkey'
}

/** input type for inserting data into table "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_capture_id?: InputMaybe<Scalars['uuid']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Obj_Rel_Insert_Input>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Memo_Picture_Link_Max_Fields = {
  __typename?: 'nb_memo_picture_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_capture_id?: Maybe<Scalars['uuid']>;
  field_memo_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  field_capture_id?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Memo_Picture_Link_Min_Fields = {
  __typename?: 'nb_memo_picture_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_capture_id?: Maybe<Scalars['uuid']>;
  field_memo_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  field_capture_id?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_Mutation_Response = {
  __typename?: 'nb_memo_picture_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Memo_Picture_Link>;
};

/** on_conflict condition type for table "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_On_Conflict = {
  constraint: Nb_Memo_Picture_Link_Constraint;
  update_columns?: Array<Nb_Memo_Picture_Link_Update_Column>;
  where?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_memo_picture_link". */
export type Nb_Memo_Picture_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  field_capture_id?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Order_By>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_memo_picture_link */
export type Nb_Memo_Picture_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_memo_picture_link" */
export enum Nb_Memo_Picture_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldCaptureId = 'field_capture_id',
  /** column name */
  FieldMemoId = 'field_memo_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_capture_id?: InputMaybe<Scalars['uuid']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_memo_picture_link" */
export type Nb_Memo_Picture_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Memo_Picture_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Memo_Picture_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_capture_id?: InputMaybe<Scalars['uuid']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_memo_picture_link" */
export enum Nb_Memo_Picture_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldCaptureId = 'field_capture_id',
  /** column name */
  FieldMemoId = 'field_memo_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Memo_Picture_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Memo_Picture_Link_Set_Input>;
  where: Nb_Memo_Picture_Link_Bool_Exp;
};

/** columns and relationships of "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link = {
  __typename?: 'nb_memo_ufg_link';
  created: Scalars['timestamptz'];
  field_memo_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_field_memo: Nb_Field_Memo;
  /** An object relationship */
  nb_ufield_group: Nb_Ufield_Group;
  ufield_group_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_Aggregate = {
  __typename?: 'nb_memo_ufg_link_aggregate';
  aggregate?: Maybe<Nb_Memo_Ufg_Link_Aggregate_Fields>;
  nodes: Array<Nb_Memo_Ufg_Link>;
};

export type Nb_Memo_Ufg_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Memo_Ufg_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Memo_Ufg_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Memo_Ufg_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_Aggregate_Fields = {
  __typename?: 'nb_memo_ufg_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Memo_Ufg_Link_Max_Fields>;
  min?: Maybe<Nb_Memo_Ufg_Link_Min_Fields>;
};


/** aggregate fields of "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Memo_Ufg_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Memo_Ufg_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Memo_Ufg_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Memo_Ufg_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Memo_Ufg_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_memo_ufg_link". All fields are combined with a logical 'AND'. */
export type Nb_Memo_Ufg_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Memo_Ufg_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Memo_Ufg_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_memo_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
  ufield_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_memo_ufg_link" */
export enum Nb_Memo_Ufg_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbMemoUfgLinkPkey = 'nb_memo_ufg_link_pkey'
}

/** input type for inserting data into table "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Obj_Rel_Insert_Input>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Obj_Rel_Insert_Input>;
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Memo_Ufg_Link_Max_Fields = {
  __typename?: 'nb_memo_ufg_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_memo_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_group_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_group_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Memo_Ufg_Link_Min_Fields = {
  __typename?: 'nb_memo_ufg_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_memo_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_group_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_group_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_Mutation_Response = {
  __typename?: 'nb_memo_ufg_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Memo_Ufg_Link>;
};

/** on_conflict condition type for table "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_On_Conflict = {
  constraint: Nb_Memo_Ufg_Link_Constraint;
  update_columns?: Array<Nb_Memo_Ufg_Link_Update_Column>;
  where?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_memo_ufg_link". */
export type Nb_Memo_Ufg_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Order_By>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Order_By>;
  ufield_group_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_memo_ufg_link */
export type Nb_Memo_Ufg_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_memo_ufg_link" */
export enum Nb_Memo_Ufg_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldMemoId = 'field_memo_id',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldGroupId = 'ufield_group_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_memo_ufg_link" */
export type Nb_Memo_Ufg_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Memo_Ufg_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Memo_Ufg_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_memo_ufg_link" */
export enum Nb_Memo_Ufg_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldMemoId = 'field_memo_id',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldGroupId = 'ufield_group_id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Memo_Ufg_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Memo_Ufg_Link_Set_Input>;
  where: Nb_Memo_Ufg_Link_Bool_Exp;
};

/** columns and relationships of "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link = {
  __typename?: 'nb_memo_ufield_link';
  created: Scalars['timestamptz'];
  field_memo_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_field_memo: Nb_Field_Memo;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  updated: Scalars['timestamptz'];
  user_field_id: Scalars['uuid'];
};

/** aggregated selection of "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_Aggregate = {
  __typename?: 'nb_memo_ufield_link_aggregate';
  aggregate?: Maybe<Nb_Memo_Ufield_Link_Aggregate_Fields>;
  nodes: Array<Nb_Memo_Ufield_Link>;
};

export type Nb_Memo_Ufield_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Memo_Ufield_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Memo_Ufield_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Memo_Ufield_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_Aggregate_Fields = {
  __typename?: 'nb_memo_ufield_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Memo_Ufield_Link_Max_Fields>;
  min?: Maybe<Nb_Memo_Ufield_Link_Min_Fields>;
};


/** aggregate fields of "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Memo_Ufield_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Memo_Ufield_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Memo_Ufield_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Memo_Ufield_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Memo_Ufield_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_memo_ufield_link". All fields are combined with a logical 'AND'. */
export type Nb_Memo_Ufield_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Memo_Ufield_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Memo_Ufield_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_memo_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_field_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_memo_ufield_link" */
export enum Nb_Memo_Ufield_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbMemoUfieldLinkPkey = 'nb_memo_ufield_link_pkey'
}

/** input type for inserting data into table "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Obj_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Memo_Ufield_Link_Max_Fields = {
  __typename?: 'nb_memo_ufield_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_memo_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Memo_Ufield_Link_Min_Fields = {
  __typename?: 'nb_memo_ufield_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_memo_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_Mutation_Response = {
  __typename?: 'nb_memo_ufield_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Memo_Ufield_Link>;
};

/** on_conflict condition type for table "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_On_Conflict = {
  constraint: Nb_Memo_Ufield_Link_Constraint;
  update_columns?: Array<Nb_Memo_Ufield_Link_Update_Column>;
  where?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_memo_ufield_link". */
export type Nb_Memo_Ufield_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  field_memo_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_memo?: InputMaybe<Nb_Field_Memo_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_memo_ufield_link */
export type Nb_Memo_Ufield_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_memo_ufield_link" */
export enum Nb_Memo_Ufield_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldMemoId = 'field_memo_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id'
}

/** input type for updating data in table "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "nb_memo_ufield_link" */
export type Nb_Memo_Ufield_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Memo_Ufield_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Memo_Ufield_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_memo_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nb_memo_ufield_link" */
export enum Nb_Memo_Ufield_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldMemoId = 'field_memo_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id'
}

export type Nb_Memo_Ufield_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Memo_Ufield_Link_Set_Input>;
  where: Nb_Memo_Ufield_Link_Bool_Exp;
};

/** columns and relationships of "nb_mfield" */
export type Nb_Mfield = {
  __typename?: 'nb_mfield';
  /** wagri api から取得した農地PINの登記住所 */
  address?: Maybe<Scalars['String']>;
  /** アドレスを更新した日時 */
  address_updated?: Maybe<Scalars['timestamptz']>;
  area_mesh_id?: Maybe<Scalars['uuid']>;
  /** 軽量化ポリゴンの面積 */
  area_sqm: Scalars['float8'];
  /** 軽量化ポリゴン(polygon_field_master)の重心 */
  centroid?: Maybe<Scalars['geometry']>;
  created: Scalars['timestamptz'];
  /** 圃場ポリゴンが全く同一だったときに保持する履歴情報 */
  history?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** 筆ポリゴンデータに含まれていたIDの値。現在はmaff_polygon_idと同じ値。変更を想定し、冗長だが保持する。 */
  key: Scalars['String'];
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: Maybe<Scalars['smallint']>;
  /** 過去に公開した筆ポリゴンとの関係を示した情報 */
  maff_history?: Maybe<Scalars['jsonb']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: Maybe<Scalars['smallint']>;
  /** 筆ポリゴンデータの耕地の種類。田、畑の地目（衛星画像等の目視判読による判断） 地目コード（100：田、200：畑）。従来仕様に従いcharacter varyingとなっているがsmallintに変更したい。 */
  maff_land_type: Scalars['String'];
  /** ・筆ポリゴンの更新等がなく、形状が前年度公開データ と全く同じポリゴン（履歴では前年同一）の場合、前年 度公開データの「筆ポリゴン ID」。 ・筆ポリゴンの更新等があり、形状が前年と異なるポリ ゴン（履歴では発生、関連）の場合、Null 値。 */
  maff_last_polygon_uuid?: Maybe<Scalars['uuid']>;
  /** maffデータに格納されていた地方公共団体コード */
  maff_local_government_cd: Scalars['String'];
  /** 2021年度の筆ポリゴンデータに紐付いていた情報を社内で2022年度に紐付け直した情報 */
  maff_old_polygon_id?: Maybe<Scalars['String']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: Maybe<Scalars['float8']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: Maybe<Scalars['float8']>;
  /** 筆ポリゴンの圃場ポリゴン */
  maff_polygon?: Maybe<Scalars['geometry']>;
  /** 筆ポリゴンのUUID。 */
  maff_polygon_uuid?: Maybe<Scalars['uuid']>;
  /** ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 全く同じ場合、前年度の筆ポリゴンが保持していた「前 年筆ポリゴン ID」。 ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 異なる場合、Null 値。 */
  maff_prev_last_polygon_uuid?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  nb_area_mesh?: Maybe<Nb_Area_Mesh>;
  /** An array relationship */
  nb_est_working_loads: Array<Nb_Est_Working_Load>;
  /** An aggregate relationship */
  nb_est_working_loads_aggregate: Nb_Est_Working_Load_Aggregate;
  /** An array relationship */
  nb_maff_field_links: Array<Nb_Maff_Field_Link>;
  /** An aggregate relationship */
  nb_maff_field_links_aggregate: Nb_Maff_Field_Link_Aggregate;
  /** An array relationship */
  nb_mfield_links: Array<Nb_Mfield_Link>;
  /** An aggregate relationship */
  nb_mfield_links_aggregate: Nb_Mfield_Link_Aggregate;
  /** 農地ピンの登録場所 */
  pin_coor?: Maybe<Scalars['geometry']>;
  /** 軽量化ポリゴン */
  polygon_field_master: Scalars['geometry'];
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: Maybe<Scalars['bigint']>;
  updated: Scalars['timestamptz'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nb_mfield" */
export type Nb_MfieldMaff_HistoryArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "nb_mfield" */
export type Nb_MfieldNb_Est_Working_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Est_Working_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Est_Working_Load_Order_By>>;
  where?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
};


/** columns and relationships of "nb_mfield" */
export type Nb_MfieldNb_Est_Working_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Est_Working_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Est_Working_Load_Order_By>>;
  where?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
};


/** columns and relationships of "nb_mfield" */
export type Nb_MfieldNb_Maff_Field_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Link_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
};


/** columns and relationships of "nb_mfield" */
export type Nb_MfieldNb_Maff_Field_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Link_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
};


/** columns and relationships of "nb_mfield" */
export type Nb_MfieldNb_Mfield_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Link_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};


/** columns and relationships of "nb_mfield" */
export type Nb_MfieldNb_Mfield_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Link_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};

/** aggregated selection of "nb_mfield" */
export type Nb_Mfield_Aggregate = {
  __typename?: 'nb_mfield_aggregate';
  aggregate?: Maybe<Nb_Mfield_Aggregate_Fields>;
  nodes: Array<Nb_Mfield>;
};

export type Nb_Mfield_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Nb_Mfield_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Nb_Mfield_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Nb_Mfield_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Nb_Mfield_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Nb_Mfield_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Nb_Mfield_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Nb_Mfield_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Nb_Mfield_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Nb_Mfield_Aggregate_Bool_Exp_Var_Samp>;
};

export type Nb_Mfield_Aggregate_Bool_Exp_Avg = {
  arguments: Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Mfield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Mfield_Aggregate_Bool_Exp_Corr = {
  arguments: Nb_Mfield_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Mfield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Mfield_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Nb_Mfield_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Mfield_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Mfield_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Nb_Mfield_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Nb_Mfield_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Mfield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Mfield_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Nb_Mfield_Aggregate_Bool_Exp_Max = {
  arguments: Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Mfield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Mfield_Aggregate_Bool_Exp_Min = {
  arguments: Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Mfield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Mfield_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Mfield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Mfield_Aggregate_Bool_Exp_Sum = {
  arguments: Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Mfield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Mfield_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Mfield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "nb_mfield" */
export type Nb_Mfield_Aggregate_Fields = {
  __typename?: 'nb_mfield_aggregate_fields';
  avg?: Maybe<Nb_Mfield_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Mfield_Max_Fields>;
  min?: Maybe<Nb_Mfield_Min_Fields>;
  stddev?: Maybe<Nb_Mfield_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Mfield_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Mfield_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Mfield_Sum_Fields>;
  var_pop?: Maybe<Nb_Mfield_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Mfield_Var_Samp_Fields>;
  variance?: Maybe<Nb_Mfield_Variance_Fields>;
};


/** aggregate fields of "nb_mfield" */
export type Nb_Mfield_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Mfield_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_mfield" */
export type Nb_Mfield_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Mfield_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Mfield_Max_Order_By>;
  min?: InputMaybe<Nb_Mfield_Min_Order_By>;
  stddev?: InputMaybe<Nb_Mfield_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Mfield_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Mfield_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Mfield_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Mfield_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Mfield_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Mfield_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Nb_Mfield_Append_Input = {
  /** 過去に公開した筆ポリゴンとの関係を示した情報 */
  maff_history?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "nb_mfield" */
export type Nb_Mfield_Arr_Rel_Insert_Input = {
  data: Array<Nb_Mfield_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Mfield_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Mfield_Avg_Fields = {
  __typename?: 'nb_mfield_avg_fields';
  /** 軽量化ポリゴンの面積 */
  area_sqm?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: Maybe<Scalars['Float']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: Maybe<Scalars['Float']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_mfield" */
export type Nb_Mfield_Avg_Order_By = {
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Order_By>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Order_By>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Order_By>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_mfield". All fields are combined with a logical 'AND'. */
export type Nb_Mfield_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Mfield_Bool_Exp>>;
  _not?: InputMaybe<Nb_Mfield_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Mfield_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  address_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  area_mesh_id?: InputMaybe<Uuid_Comparison_Exp>;
  area_sqm?: InputMaybe<Float8_Comparison_Exp>;
  centroid?: InputMaybe<Geometry_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  history?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  maff_edit_year?: InputMaybe<Smallint_Comparison_Exp>;
  maff_history?: InputMaybe<Jsonb_Comparison_Exp>;
  maff_issue_year?: InputMaybe<Smallint_Comparison_Exp>;
  maff_land_type?: InputMaybe<String_Comparison_Exp>;
  maff_last_polygon_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  maff_local_government_cd?: InputMaybe<String_Comparison_Exp>;
  maff_old_polygon_id?: InputMaybe<String_Comparison_Exp>;
  maff_point_lat?: InputMaybe<Float8_Comparison_Exp>;
  maff_point_lng?: InputMaybe<Float8_Comparison_Exp>;
  maff_polygon?: InputMaybe<Geometry_Comparison_Exp>;
  maff_polygon_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  maff_prev_last_polygon_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  nb_area_mesh?: InputMaybe<Nb_Area_Mesh_Bool_Exp>;
  nb_est_working_loads?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  nb_est_working_loads_aggregate?: InputMaybe<Nb_Est_Working_Load_Aggregate_Bool_Exp>;
  nb_maff_field_links?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
  nb_maff_field_links_aggregate?: InputMaybe<Nb_Maff_Field_Link_Aggregate_Bool_Exp>;
  nb_mfield_links?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
  nb_mfield_links_aggregate?: InputMaybe<Nb_Mfield_Link_Aggregate_Bool_Exp>;
  pin_coor?: InputMaybe<Geometry_Comparison_Exp>;
  polygon_field_master?: InputMaybe<Geometry_Comparison_Exp>;
  region_mesh_code?: InputMaybe<Bigint_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_mfield" */
export enum Nb_Mfield_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbMfieldKeyKey = 'nb_mfield_key_key',
  /** unique or primary key constraint on columns "maff_polygon_uuid" */
  NbMfieldMaffPolygonUuidKey = 'nb_mfield_maff_polygon_uuid_key',
  /** unique or primary key constraint on columns "id" */
  NbMfieldPkey = 'nb_mfield_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Nb_Mfield_Delete_At_Path_Input = {
  /** 過去に公開した筆ポリゴンとの関係を示した情報 */
  maff_history?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Nb_Mfield_Delete_Elem_Input = {
  /** 過去に公開した筆ポリゴンとの関係を示した情報 */
  maff_history?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Nb_Mfield_Delete_Key_Input = {
  /** 過去に公開した筆ポリゴンとの関係を示した情報 */
  maff_history?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "nb_mfield" */
export type Nb_Mfield_Inc_Input = {
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Scalars['float8']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Scalars['smallint']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Scalars['smallint']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Scalars['float8']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Scalars['float8']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "nb_mfield" */
export type Nb_Mfield_Insert_Input = {
  /** wagri api から取得した農地PINの登記住所 */
  address?: InputMaybe<Scalars['String']>;
  /** アドレスを更新した日時 */
  address_updated?: InputMaybe<Scalars['timestamptz']>;
  area_mesh_id?: InputMaybe<Scalars['uuid']>;
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Scalars['float8']>;
  /** 軽量化ポリゴン(polygon_field_master)の重心 */
  centroid?: InputMaybe<Scalars['geometry']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 圃場ポリゴンが全く同一だったときに保持する履歴情報 */
  history?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** 筆ポリゴンデータに含まれていたIDの値。現在はmaff_polygon_idと同じ値。変更を想定し、冗長だが保持する。 */
  key?: InputMaybe<Scalars['String']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Scalars['smallint']>;
  /** 過去に公開した筆ポリゴンとの関係を示した情報 */
  maff_history?: InputMaybe<Scalars['jsonb']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Scalars['smallint']>;
  /** 筆ポリゴンデータの耕地の種類。田、畑の地目（衛星画像等の目視判読による判断） 地目コード（100：田、200：畑）。従来仕様に従いcharacter varyingとなっているがsmallintに変更したい。 */
  maff_land_type?: InputMaybe<Scalars['String']>;
  /** ・筆ポリゴンの更新等がなく、形状が前年度公開データ と全く同じポリゴン（履歴では前年同一）の場合、前年 度公開データの「筆ポリゴン ID」。 ・筆ポリゴンの更新等があり、形状が前年と異なるポリ ゴン（履歴では発生、関連）の場合、Null 値。 */
  maff_last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  /** maffデータに格納されていた地方公共団体コード */
  maff_local_government_cd?: InputMaybe<Scalars['String']>;
  /** 2021年度の筆ポリゴンデータに紐付いていた情報を社内で2022年度に紐付け直した情報 */
  maff_old_polygon_id?: InputMaybe<Scalars['String']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Scalars['float8']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Scalars['float8']>;
  /** 筆ポリゴンの圃場ポリゴン */
  maff_polygon?: InputMaybe<Scalars['geometry']>;
  /** 筆ポリゴンのUUID。 */
  maff_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  /** ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 全く同じ場合、前年度の筆ポリゴンが保持していた「前 年筆ポリゴン ID」。 ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 異なる場合、Null 値。 */
  maff_prev_last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  nb_area_mesh?: InputMaybe<Nb_Area_Mesh_Obj_Rel_Insert_Input>;
  nb_est_working_loads?: InputMaybe<Nb_Est_Working_Load_Arr_Rel_Insert_Input>;
  nb_maff_field_links?: InputMaybe<Nb_Maff_Field_Link_Arr_Rel_Insert_Input>;
  nb_mfield_links?: InputMaybe<Nb_Mfield_Link_Arr_Rel_Insert_Input>;
  /** 農地ピンの登録場所 */
  pin_coor?: InputMaybe<Scalars['geometry']>;
  /** 軽量化ポリゴン */
  polygon_field_master?: InputMaybe<Scalars['geometry']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Scalars['bigint']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "nb_mfield_link" */
export type Nb_Mfield_Link = {
  __typename?: 'nb_mfield_link';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  master_field_id?: Maybe<Scalars['uuid']>;
  master_field_key?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  master_nb_mfield?: Maybe<Master_Nb_Mfield>;
  /** An object relationship */
  nb_mfield?: Maybe<Nb_Mfield>;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  updated: Scalars['timestamptz'];
  user_field_id: Scalars['uuid'];
};

/** aggregated selection of "nb_mfield_link" */
export type Nb_Mfield_Link_Aggregate = {
  __typename?: 'nb_mfield_link_aggregate';
  aggregate?: Maybe<Nb_Mfield_Link_Aggregate_Fields>;
  nodes: Array<Nb_Mfield_Link>;
};

export type Nb_Mfield_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Mfield_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Mfield_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_mfield_link" */
export type Nb_Mfield_Link_Aggregate_Fields = {
  __typename?: 'nb_mfield_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Mfield_Link_Max_Fields>;
  min?: Maybe<Nb_Mfield_Link_Min_Fields>;
};


/** aggregate fields of "nb_mfield_link" */
export type Nb_Mfield_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_mfield_link" */
export type Nb_Mfield_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Mfield_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Mfield_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_mfield_link" */
export type Nb_Mfield_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Mfield_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Mfield_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_mfield_link". All fields are combined with a logical 'AND'. */
export type Nb_Mfield_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Mfield_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Mfield_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  master_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  master_field_key?: InputMaybe<Uuid_Comparison_Exp>;
  master_nb_mfield?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
  nb_mfield?: InputMaybe<Nb_Mfield_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_field_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_mfield_link" */
export enum Nb_Mfield_Link_Constraint {
  /** unique or primary key constraint on columns "user_field_id", "master_field_id" */
  NbMfieldLinkMasterFieldIdUserFieldIdKey = 'nb_mfield_link_master_field_id_user_field_id_key',
  /** unique or primary key constraint on columns "id" */
  NbMfieldLinkPkey = 'nb_mfield_link_pkey'
}

/** input type for inserting data into table "nb_mfield_link" */
export type Nb_Mfield_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  master_field_id?: InputMaybe<Scalars['uuid']>;
  master_field_key?: InputMaybe<Scalars['uuid']>;
  master_nb_mfield?: InputMaybe<Master_Nb_Mfield_Obj_Rel_Insert_Input>;
  nb_mfield?: InputMaybe<Nb_Mfield_Obj_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Mfield_Link_Max_Fields = {
  __typename?: 'nb_mfield_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  master_field_id?: Maybe<Scalars['uuid']>;
  master_field_key?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_mfield_link" */
export type Nb_Mfield_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  master_field_id?: InputMaybe<Order_By>;
  master_field_key?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Mfield_Link_Min_Fields = {
  __typename?: 'nb_mfield_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  master_field_id?: Maybe<Scalars['uuid']>;
  master_field_key?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_mfield_link" */
export type Nb_Mfield_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  master_field_id?: InputMaybe<Order_By>;
  master_field_key?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_mfield_link" */
export type Nb_Mfield_Link_Mutation_Response = {
  __typename?: 'nb_mfield_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Mfield_Link>;
};

/** on_conflict condition type for table "nb_mfield_link" */
export type Nb_Mfield_Link_On_Conflict = {
  constraint: Nb_Mfield_Link_Constraint;
  update_columns?: Array<Nb_Mfield_Link_Update_Column>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_mfield_link". */
export type Nb_Mfield_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  master_field_id?: InputMaybe<Order_By>;
  master_field_key?: InputMaybe<Order_By>;
  master_nb_mfield?: InputMaybe<Master_Nb_Mfield_Order_By>;
  nb_mfield?: InputMaybe<Nb_Mfield_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_mfield_link */
export type Nb_Mfield_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_mfield_link" */
export enum Nb_Mfield_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  MasterFieldId = 'master_field_id',
  /** column name */
  MasterFieldKey = 'master_field_key',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id'
}

/** input type for updating data in table "nb_mfield_link" */
export type Nb_Mfield_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  master_field_id?: InputMaybe<Scalars['uuid']>;
  master_field_key?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "nb_mfield_link" */
export type Nb_Mfield_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Mfield_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Mfield_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  master_field_id?: InputMaybe<Scalars['uuid']>;
  master_field_key?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nb_mfield_link" */
export enum Nb_Mfield_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  MasterFieldId = 'master_field_id',
  /** column name */
  MasterFieldKey = 'master_field_key',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id'
}

export type Nb_Mfield_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Mfield_Link_Set_Input>;
  where: Nb_Mfield_Link_Bool_Exp;
};

/** aggregate max on columns */
export type Nb_Mfield_Max_Fields = {
  __typename?: 'nb_mfield_max_fields';
  /** wagri api から取得した農地PINの登記住所 */
  address?: Maybe<Scalars['String']>;
  /** アドレスを更新した日時 */
  address_updated?: Maybe<Scalars['timestamptz']>;
  area_mesh_id?: Maybe<Scalars['uuid']>;
  /** 軽量化ポリゴンの面積 */
  area_sqm?: Maybe<Scalars['float8']>;
  created?: Maybe<Scalars['timestamptz']>;
  /** 圃場ポリゴンが全く同一だったときに保持する履歴情報 */
  history?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  /** 筆ポリゴンデータに含まれていたIDの値。現在はmaff_polygon_idと同じ値。変更を想定し、冗長だが保持する。 */
  key?: Maybe<Scalars['String']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: Maybe<Scalars['smallint']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: Maybe<Scalars['smallint']>;
  /** 筆ポリゴンデータの耕地の種類。田、畑の地目（衛星画像等の目視判読による判断） 地目コード（100：田、200：畑）。従来仕様に従いcharacter varyingとなっているがsmallintに変更したい。 */
  maff_land_type?: Maybe<Scalars['String']>;
  /** ・筆ポリゴンの更新等がなく、形状が前年度公開データ と全く同じポリゴン（履歴では前年同一）の場合、前年 度公開データの「筆ポリゴン ID」。 ・筆ポリゴンの更新等があり、形状が前年と異なるポリ ゴン（履歴では発生、関連）の場合、Null 値。 */
  maff_last_polygon_uuid?: Maybe<Scalars['uuid']>;
  /** maffデータに格納されていた地方公共団体コード */
  maff_local_government_cd?: Maybe<Scalars['String']>;
  /** 2021年度の筆ポリゴンデータに紐付いていた情報を社内で2022年度に紐付け直した情報 */
  maff_old_polygon_id?: Maybe<Scalars['String']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: Maybe<Scalars['float8']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: Maybe<Scalars['float8']>;
  /** 筆ポリゴンのUUID。 */
  maff_polygon_uuid?: Maybe<Scalars['uuid']>;
  /** ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 全く同じ場合、前年度の筆ポリゴンが保持していた「前 年筆ポリゴン ID」。 ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 異なる場合、Null 値。 */
  maff_prev_last_polygon_uuid?: Maybe<Scalars['uuid']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_mfield" */
export type Nb_Mfield_Max_Order_By = {
  /** wagri api から取得した農地PINの登記住所 */
  address?: InputMaybe<Order_By>;
  /** アドレスを更新した日時 */
  address_updated?: InputMaybe<Order_By>;
  area_mesh_id?: InputMaybe<Order_By>;
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  /** 圃場ポリゴンが全く同一だったときに保持する履歴情報 */
  history?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** 筆ポリゴンデータに含まれていたIDの値。現在はmaff_polygon_idと同じ値。変更を想定し、冗長だが保持する。 */
  key?: InputMaybe<Order_By>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Order_By>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Order_By>;
  /** 筆ポリゴンデータの耕地の種類。田、畑の地目（衛星画像等の目視判読による判断） 地目コード（100：田、200：畑）。従来仕様に従いcharacter varyingとなっているがsmallintに変更したい。 */
  maff_land_type?: InputMaybe<Order_By>;
  /** ・筆ポリゴンの更新等がなく、形状が前年度公開データ と全く同じポリゴン（履歴では前年同一）の場合、前年 度公開データの「筆ポリゴン ID」。 ・筆ポリゴンの更新等があり、形状が前年と異なるポリ ゴン（履歴では発生、関連）の場合、Null 値。 */
  maff_last_polygon_uuid?: InputMaybe<Order_By>;
  /** maffデータに格納されていた地方公共団体コード */
  maff_local_government_cd?: InputMaybe<Order_By>;
  /** 2021年度の筆ポリゴンデータに紐付いていた情報を社内で2022年度に紐付け直した情報 */
  maff_old_polygon_id?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Order_By>;
  /** 筆ポリゴンのUUID。 */
  maff_polygon_uuid?: InputMaybe<Order_By>;
  /** ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 全く同じ場合、前年度の筆ポリゴンが保持していた「前 年筆ポリゴン ID」。 ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 異なる場合、Null 値。 */
  maff_prev_last_polygon_uuid?: InputMaybe<Order_By>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Mfield_Min_Fields = {
  __typename?: 'nb_mfield_min_fields';
  /** wagri api から取得した農地PINの登記住所 */
  address?: Maybe<Scalars['String']>;
  /** アドレスを更新した日時 */
  address_updated?: Maybe<Scalars['timestamptz']>;
  area_mesh_id?: Maybe<Scalars['uuid']>;
  /** 軽量化ポリゴンの面積 */
  area_sqm?: Maybe<Scalars['float8']>;
  created?: Maybe<Scalars['timestamptz']>;
  /** 圃場ポリゴンが全く同一だったときに保持する履歴情報 */
  history?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  /** 筆ポリゴンデータに含まれていたIDの値。現在はmaff_polygon_idと同じ値。変更を想定し、冗長だが保持する。 */
  key?: Maybe<Scalars['String']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: Maybe<Scalars['smallint']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: Maybe<Scalars['smallint']>;
  /** 筆ポリゴンデータの耕地の種類。田、畑の地目（衛星画像等の目視判読による判断） 地目コード（100：田、200：畑）。従来仕様に従いcharacter varyingとなっているがsmallintに変更したい。 */
  maff_land_type?: Maybe<Scalars['String']>;
  /** ・筆ポリゴンの更新等がなく、形状が前年度公開データ と全く同じポリゴン（履歴では前年同一）の場合、前年 度公開データの「筆ポリゴン ID」。 ・筆ポリゴンの更新等があり、形状が前年と異なるポリ ゴン（履歴では発生、関連）の場合、Null 値。 */
  maff_last_polygon_uuid?: Maybe<Scalars['uuid']>;
  /** maffデータに格納されていた地方公共団体コード */
  maff_local_government_cd?: Maybe<Scalars['String']>;
  /** 2021年度の筆ポリゴンデータに紐付いていた情報を社内で2022年度に紐付け直した情報 */
  maff_old_polygon_id?: Maybe<Scalars['String']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: Maybe<Scalars['float8']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: Maybe<Scalars['float8']>;
  /** 筆ポリゴンのUUID。 */
  maff_polygon_uuid?: Maybe<Scalars['uuid']>;
  /** ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 全く同じ場合、前年度の筆ポリゴンが保持していた「前 年筆ポリゴン ID」。 ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 異なる場合、Null 値。 */
  maff_prev_last_polygon_uuid?: Maybe<Scalars['uuid']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_mfield" */
export type Nb_Mfield_Min_Order_By = {
  /** wagri api から取得した農地PINの登記住所 */
  address?: InputMaybe<Order_By>;
  /** アドレスを更新した日時 */
  address_updated?: InputMaybe<Order_By>;
  area_mesh_id?: InputMaybe<Order_By>;
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  /** 圃場ポリゴンが全く同一だったときに保持する履歴情報 */
  history?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** 筆ポリゴンデータに含まれていたIDの値。現在はmaff_polygon_idと同じ値。変更を想定し、冗長だが保持する。 */
  key?: InputMaybe<Order_By>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Order_By>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Order_By>;
  /** 筆ポリゴンデータの耕地の種類。田、畑の地目（衛星画像等の目視判読による判断） 地目コード（100：田、200：畑）。従来仕様に従いcharacter varyingとなっているがsmallintに変更したい。 */
  maff_land_type?: InputMaybe<Order_By>;
  /** ・筆ポリゴンの更新等がなく、形状が前年度公開データ と全く同じポリゴン（履歴では前年同一）の場合、前年 度公開データの「筆ポリゴン ID」。 ・筆ポリゴンの更新等があり、形状が前年と異なるポリ ゴン（履歴では発生、関連）の場合、Null 値。 */
  maff_last_polygon_uuid?: InputMaybe<Order_By>;
  /** maffデータに格納されていた地方公共団体コード */
  maff_local_government_cd?: InputMaybe<Order_By>;
  /** 2021年度の筆ポリゴンデータに紐付いていた情報を社内で2022年度に紐付け直した情報 */
  maff_old_polygon_id?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Order_By>;
  /** 筆ポリゴンのUUID。 */
  maff_polygon_uuid?: InputMaybe<Order_By>;
  /** ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 全く同じ場合、前年度の筆ポリゴンが保持していた「前 年筆ポリゴン ID」。 ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 異なる場合、Null 値。 */
  maff_prev_last_polygon_uuid?: InputMaybe<Order_By>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_mfield" */
export type Nb_Mfield_Mutation_Response = {
  __typename?: 'nb_mfield_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Mfield>;
};

/** input type for inserting object relation for remote table "nb_mfield" */
export type Nb_Mfield_Obj_Rel_Insert_Input = {
  data: Nb_Mfield_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Mfield_On_Conflict>;
};

/** on_conflict condition type for table "nb_mfield" */
export type Nb_Mfield_On_Conflict = {
  constraint: Nb_Mfield_Constraint;
  update_columns?: Array<Nb_Mfield_Update_Column>;
  where?: InputMaybe<Nb_Mfield_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_mfield". */
export type Nb_Mfield_Order_By = {
  address?: InputMaybe<Order_By>;
  address_updated?: InputMaybe<Order_By>;
  area_mesh_id?: InputMaybe<Order_By>;
  area_sqm?: InputMaybe<Order_By>;
  centroid?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  history?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  maff_edit_year?: InputMaybe<Order_By>;
  maff_history?: InputMaybe<Order_By>;
  maff_issue_year?: InputMaybe<Order_By>;
  maff_land_type?: InputMaybe<Order_By>;
  maff_last_polygon_uuid?: InputMaybe<Order_By>;
  maff_local_government_cd?: InputMaybe<Order_By>;
  maff_old_polygon_id?: InputMaybe<Order_By>;
  maff_point_lat?: InputMaybe<Order_By>;
  maff_point_lng?: InputMaybe<Order_By>;
  maff_polygon?: InputMaybe<Order_By>;
  maff_polygon_uuid?: InputMaybe<Order_By>;
  maff_prev_last_polygon_uuid?: InputMaybe<Order_By>;
  nb_area_mesh?: InputMaybe<Nb_Area_Mesh_Order_By>;
  nb_est_working_loads_aggregate?: InputMaybe<Nb_Est_Working_Load_Aggregate_Order_By>;
  nb_maff_field_links_aggregate?: InputMaybe<Nb_Maff_Field_Link_Aggregate_Order_By>;
  nb_mfield_links_aggregate?: InputMaybe<Nb_Mfield_Link_Aggregate_Order_By>;
  pin_coor?: InputMaybe<Order_By>;
  polygon_field_master?: InputMaybe<Order_By>;
  region_mesh_code?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_mfield */
export type Nb_Mfield_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Nb_Mfield_Prepend_Input = {
  /** 過去に公開した筆ポリゴンとの関係を示した情報 */
  maff_history?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "nb_mfield" */
export enum Nb_Mfield_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressUpdated = 'address_updated',
  /** column name */
  AreaMeshId = 'area_mesh_id',
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  Centroid = 'centroid',
  /** column name */
  Created = 'created',
  /** column name */
  History = 'history',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  MaffEditYear = 'maff_edit_year',
  /** column name */
  MaffHistory = 'maff_history',
  /** column name */
  MaffIssueYear = 'maff_issue_year',
  /** column name */
  MaffLandType = 'maff_land_type',
  /** column name */
  MaffLastPolygonUuid = 'maff_last_polygon_uuid',
  /** column name */
  MaffLocalGovernmentCd = 'maff_local_government_cd',
  /** column name */
  MaffOldPolygonId = 'maff_old_polygon_id',
  /** column name */
  MaffPointLat = 'maff_point_lat',
  /** column name */
  MaffPointLng = 'maff_point_lng',
  /** column name */
  MaffPolygon = 'maff_polygon',
  /** column name */
  MaffPolygonUuid = 'maff_polygon_uuid',
  /** column name */
  MaffPrevLastPolygonUuid = 'maff_prev_last_polygon_uuid',
  /** column name */
  PinCoor = 'pin_coor',
  /** column name */
  PolygonFieldMaster = 'polygon_field_master',
  /** column name */
  RegionMeshCode = 'region_mesh_code',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** select "nb_mfield_aggregate_bool_exp_avg_arguments_columns" columns of table "nb_mfield" */
export enum Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  MaffPointLat = 'maff_point_lat',
  /** column name */
  MaffPointLng = 'maff_point_lng'
}

/** select "nb_mfield_aggregate_bool_exp_corr_arguments_columns" columns of table "nb_mfield" */
export enum Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  MaffPointLat = 'maff_point_lat',
  /** column name */
  MaffPointLng = 'maff_point_lng'
}

/** select "nb_mfield_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "nb_mfield" */
export enum Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  MaffPointLat = 'maff_point_lat',
  /** column name */
  MaffPointLng = 'maff_point_lng'
}

/** select "nb_mfield_aggregate_bool_exp_max_arguments_columns" columns of table "nb_mfield" */
export enum Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  MaffPointLat = 'maff_point_lat',
  /** column name */
  MaffPointLng = 'maff_point_lng'
}

/** select "nb_mfield_aggregate_bool_exp_min_arguments_columns" columns of table "nb_mfield" */
export enum Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  MaffPointLat = 'maff_point_lat',
  /** column name */
  MaffPointLng = 'maff_point_lng'
}

/** select "nb_mfield_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "nb_mfield" */
export enum Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  MaffPointLat = 'maff_point_lat',
  /** column name */
  MaffPointLng = 'maff_point_lng'
}

/** select "nb_mfield_aggregate_bool_exp_sum_arguments_columns" columns of table "nb_mfield" */
export enum Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  MaffPointLat = 'maff_point_lat',
  /** column name */
  MaffPointLng = 'maff_point_lng'
}

/** select "nb_mfield_aggregate_bool_exp_var_samp_arguments_columns" columns of table "nb_mfield" */
export enum Nb_Mfield_Select_Column_Nb_Mfield_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  MaffPointLat = 'maff_point_lat',
  /** column name */
  MaffPointLng = 'maff_point_lng'
}

/** input type for updating data in table "nb_mfield" */
export type Nb_Mfield_Set_Input = {
  /** wagri api から取得した農地PINの登記住所 */
  address?: InputMaybe<Scalars['String']>;
  /** アドレスを更新した日時 */
  address_updated?: InputMaybe<Scalars['timestamptz']>;
  area_mesh_id?: InputMaybe<Scalars['uuid']>;
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Scalars['float8']>;
  /** 軽量化ポリゴン(polygon_field_master)の重心 */
  centroid?: InputMaybe<Scalars['geometry']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 圃場ポリゴンが全く同一だったときに保持する履歴情報 */
  history?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** 筆ポリゴンデータに含まれていたIDの値。現在はmaff_polygon_idと同じ値。変更を想定し、冗長だが保持する。 */
  key?: InputMaybe<Scalars['String']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Scalars['smallint']>;
  /** 過去に公開した筆ポリゴンとの関係を示した情報 */
  maff_history?: InputMaybe<Scalars['jsonb']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Scalars['smallint']>;
  /** 筆ポリゴンデータの耕地の種類。田、畑の地目（衛星画像等の目視判読による判断） 地目コード（100：田、200：畑）。従来仕様に従いcharacter varyingとなっているがsmallintに変更したい。 */
  maff_land_type?: InputMaybe<Scalars['String']>;
  /** ・筆ポリゴンの更新等がなく、形状が前年度公開データ と全く同じポリゴン（履歴では前年同一）の場合、前年 度公開データの「筆ポリゴン ID」。 ・筆ポリゴンの更新等があり、形状が前年と異なるポリ ゴン（履歴では発生、関連）の場合、Null 値。 */
  maff_last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  /** maffデータに格納されていた地方公共団体コード */
  maff_local_government_cd?: InputMaybe<Scalars['String']>;
  /** 2021年度の筆ポリゴンデータに紐付いていた情報を社内で2022年度に紐付け直した情報 */
  maff_old_polygon_id?: InputMaybe<Scalars['String']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Scalars['float8']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Scalars['float8']>;
  /** 筆ポリゴンの圃場ポリゴン */
  maff_polygon?: InputMaybe<Scalars['geometry']>;
  /** 筆ポリゴンのUUID。 */
  maff_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  /** ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 全く同じ場合、前年度の筆ポリゴンが保持していた「前 年筆ポリゴン ID」。 ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 異なる場合、Null 値。 */
  maff_prev_last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  /** 農地ピンの登録場所 */
  pin_coor?: InputMaybe<Scalars['geometry']>;
  /** 軽量化ポリゴン */
  polygon_field_master?: InputMaybe<Scalars['geometry']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Scalars['bigint']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nb_Mfield_Stddev_Fields = {
  __typename?: 'nb_mfield_stddev_fields';
  /** 軽量化ポリゴンの面積 */
  area_sqm?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: Maybe<Scalars['Float']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: Maybe<Scalars['Float']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_mfield" */
export type Nb_Mfield_Stddev_Order_By = {
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Order_By>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Order_By>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Order_By>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Mfield_Stddev_Pop_Fields = {
  __typename?: 'nb_mfield_stddev_pop_fields';
  /** 軽量化ポリゴンの面積 */
  area_sqm?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: Maybe<Scalars['Float']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: Maybe<Scalars['Float']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_mfield" */
export type Nb_Mfield_Stddev_Pop_Order_By = {
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Order_By>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Order_By>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Order_By>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Mfield_Stddev_Samp_Fields = {
  __typename?: 'nb_mfield_stddev_samp_fields';
  /** 軽量化ポリゴンの面積 */
  area_sqm?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: Maybe<Scalars['Float']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: Maybe<Scalars['Float']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_mfield" */
export type Nb_Mfield_Stddev_Samp_Order_By = {
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Order_By>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Order_By>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Order_By>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_mfield" */
export type Nb_Mfield_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Mfield_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Mfield_Stream_Cursor_Value_Input = {
  /** wagri api から取得した農地PINの登記住所 */
  address?: InputMaybe<Scalars['String']>;
  /** アドレスを更新した日時 */
  address_updated?: InputMaybe<Scalars['timestamptz']>;
  area_mesh_id?: InputMaybe<Scalars['uuid']>;
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Scalars['float8']>;
  /** 軽量化ポリゴン(polygon_field_master)の重心 */
  centroid?: InputMaybe<Scalars['geometry']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 圃場ポリゴンが全く同一だったときに保持する履歴情報 */
  history?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** 筆ポリゴンデータに含まれていたIDの値。現在はmaff_polygon_idと同じ値。変更を想定し、冗長だが保持する。 */
  key?: InputMaybe<Scalars['String']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Scalars['smallint']>;
  /** 過去に公開した筆ポリゴンとの関係を示した情報 */
  maff_history?: InputMaybe<Scalars['jsonb']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Scalars['smallint']>;
  /** 筆ポリゴンデータの耕地の種類。田、畑の地目（衛星画像等の目視判読による判断） 地目コード（100：田、200：畑）。従来仕様に従いcharacter varyingとなっているがsmallintに変更したい。 */
  maff_land_type?: InputMaybe<Scalars['String']>;
  /** ・筆ポリゴンの更新等がなく、形状が前年度公開データ と全く同じポリゴン（履歴では前年同一）の場合、前年 度公開データの「筆ポリゴン ID」。 ・筆ポリゴンの更新等があり、形状が前年と異なるポリ ゴン（履歴では発生、関連）の場合、Null 値。 */
  maff_last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  /** maffデータに格納されていた地方公共団体コード */
  maff_local_government_cd?: InputMaybe<Scalars['String']>;
  /** 2021年度の筆ポリゴンデータに紐付いていた情報を社内で2022年度に紐付け直した情報 */
  maff_old_polygon_id?: InputMaybe<Scalars['String']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Scalars['float8']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Scalars['float8']>;
  /** 筆ポリゴンの圃場ポリゴン */
  maff_polygon?: InputMaybe<Scalars['geometry']>;
  /** 筆ポリゴンのUUID。 */
  maff_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  /** ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 全く同じ場合、前年度の筆ポリゴンが保持していた「前 年筆ポリゴン ID」。 ・前年度の筆ポリゴンと前前年度の筆ポリゴンの形状が 異なる場合、Null 値。 */
  maff_prev_last_polygon_uuid?: InputMaybe<Scalars['uuid']>;
  /** 農地ピンの登録場所 */
  pin_coor?: InputMaybe<Scalars['geometry']>;
  /** 軽量化ポリゴン */
  polygon_field_master?: InputMaybe<Scalars['geometry']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Scalars['bigint']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Mfield_Sum_Fields = {
  __typename?: 'nb_mfield_sum_fields';
  /** 軽量化ポリゴンの面積 */
  area_sqm?: Maybe<Scalars['float8']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: Maybe<Scalars['smallint']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: Maybe<Scalars['smallint']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: Maybe<Scalars['float8']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: Maybe<Scalars['float8']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "nb_mfield" */
export type Nb_Mfield_Sum_Order_By = {
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Order_By>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Order_By>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Order_By>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Order_By>;
};

/** update columns of table "nb_mfield" */
export enum Nb_Mfield_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressUpdated = 'address_updated',
  /** column name */
  AreaMeshId = 'area_mesh_id',
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  Centroid = 'centroid',
  /** column name */
  Created = 'created',
  /** column name */
  History = 'history',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  MaffEditYear = 'maff_edit_year',
  /** column name */
  MaffHistory = 'maff_history',
  /** column name */
  MaffIssueYear = 'maff_issue_year',
  /** column name */
  MaffLandType = 'maff_land_type',
  /** column name */
  MaffLastPolygonUuid = 'maff_last_polygon_uuid',
  /** column name */
  MaffLocalGovernmentCd = 'maff_local_government_cd',
  /** column name */
  MaffOldPolygonId = 'maff_old_polygon_id',
  /** column name */
  MaffPointLat = 'maff_point_lat',
  /** column name */
  MaffPointLng = 'maff_point_lng',
  /** column name */
  MaffPolygon = 'maff_polygon',
  /** column name */
  MaffPolygonUuid = 'maff_polygon_uuid',
  /** column name */
  MaffPrevLastPolygonUuid = 'maff_prev_last_polygon_uuid',
  /** column name */
  PinCoor = 'pin_coor',
  /** column name */
  PolygonFieldMaster = 'polygon_field_master',
  /** column name */
  RegionMeshCode = 'region_mesh_code',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Mfield_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Nb_Mfield_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Nb_Mfield_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Nb_Mfield_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Nb_Mfield_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Mfield_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Nb_Mfield_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Mfield_Set_Input>;
  where: Nb_Mfield_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Mfield_Var_Pop_Fields = {
  __typename?: 'nb_mfield_var_pop_fields';
  /** 軽量化ポリゴンの面積 */
  area_sqm?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: Maybe<Scalars['Float']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: Maybe<Scalars['Float']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_mfield" */
export type Nb_Mfield_Var_Pop_Order_By = {
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Order_By>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Order_By>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Order_By>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Mfield_Var_Samp_Fields = {
  __typename?: 'nb_mfield_var_samp_fields';
  /** 軽量化ポリゴンの面積 */
  area_sqm?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: Maybe<Scalars['Float']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: Maybe<Scalars['Float']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_mfield" */
export type Nb_Mfield_Var_Samp_Order_By = {
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Order_By>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Order_By>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Order_By>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Mfield_Variance_Fields = {
  __typename?: 'nb_mfield_variance_fields';
  /** 軽量化ポリゴンの面積 */
  area_sqm?: Maybe<Scalars['Float']>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: Maybe<Scalars['Float']>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: Maybe<Scalars['Float']>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: Maybe<Scalars['Float']>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_mfield" */
export type Nb_Mfield_Variance_Order_By = {
  /** 軽量化ポリゴンの面積 */
  area_sqm?: InputMaybe<Order_By>;
  /** 筆ポリゴンを新規作成・更新した年度 （例：2021 年度に新規作成・更新作業を実施したもの は、「2021」） */
  maff_edit_year?: InputMaybe<Order_By>;
  /** 筆ポリゴン公開サイトに公開した年度 （例：2022 年度に公開したものは、「2022」） */
  maff_issue_year?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の緯度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lat?: InputMaybe<Order_By>;
  /** PostgreSQL の拡張モジュール PostGIS の ST_Centroid 関数で計算した重心点の経度座標。 座標値は 10 進法で小数点以下９桁まで。 */
  maff_point_lng?: InputMaybe<Order_By>;
  /** 地域メッシュコード。現在5次元コードを格納。 */
  region_mesh_code?: InputMaybe<Order_By>;
};

/** 作業実績記録テーブル */
export type Nb_Performed_Work = {
  __typename?: 'nb_performed_work';
  /** An object relationship */
  appUserActiveByWorkerId: App_User_Active;
  /** An object relationship */
  app_user_active: App_User_Active;
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  from_context_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An object relationship */
  nb_cultivation_plan?: Maybe<Nb_Cultivation_Plan>;
  /** An object relationship */
  nb_task_type: Nb_Task_Type;
  /** An object relationship */
  nb_ufield?: Maybe<Nb_Ufield>;
  /** An object relationship */
  nbtrk_context?: Maybe<Nbtrk_Context>;
  recorder_id: Scalars['uuid'];
  start_datetime: Scalars['timestamptz'];
  task_type: Scalars['String'];
  ufield_id?: Maybe<Scalars['uuid']>;
  updated: Scalars['timestamptz'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
  worker_id: Scalars['uuid'];
  working_time?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "nb_performed_work" */
export type Nb_Performed_Work_Aggregate = {
  __typename?: 'nb_performed_work_aggregate';
  aggregate?: Maybe<Nb_Performed_Work_Aggregate_Fields>;
  nodes: Array<Nb_Performed_Work>;
};

export type Nb_Performed_Work_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Performed_Work_Aggregate_Bool_Exp_Count>;
};

export type Nb_Performed_Work_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_performed_work" */
export type Nb_Performed_Work_Aggregate_Fields = {
  __typename?: 'nb_performed_work_aggregate_fields';
  avg?: Maybe<Nb_Performed_Work_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Performed_Work_Max_Fields>;
  min?: Maybe<Nb_Performed_Work_Min_Fields>;
  stddev?: Maybe<Nb_Performed_Work_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Performed_Work_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Performed_Work_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Performed_Work_Sum_Fields>;
  var_pop?: Maybe<Nb_Performed_Work_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Performed_Work_Var_Samp_Fields>;
  variance?: Maybe<Nb_Performed_Work_Variance_Fields>;
};


/** aggregate fields of "nb_performed_work" */
export type Nb_Performed_Work_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_performed_work" */
export type Nb_Performed_Work_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Performed_Work_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Performed_Work_Max_Order_By>;
  min?: InputMaybe<Nb_Performed_Work_Min_Order_By>;
  stddev?: InputMaybe<Nb_Performed_Work_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Performed_Work_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Performed_Work_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Performed_Work_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Performed_Work_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Performed_Work_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Performed_Work_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_performed_work" */
export type Nb_Performed_Work_Arr_Rel_Insert_Input = {
  data: Array<Nb_Performed_Work_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Performed_Work_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Performed_Work_Avg_Fields = {
  __typename?: 'nb_performed_work_avg_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_performed_work" */
export type Nb_Performed_Work_Avg_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_performed_work". All fields are combined with a logical 'AND'. */
export type Nb_Performed_Work_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Performed_Work_Bool_Exp>>;
  _not?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Performed_Work_Bool_Exp>>;
  appUserActiveByWorkerId?: InputMaybe<App_User_Active_Bool_Exp>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  cultivation_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  from_context_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nbtrk_context?: InputMaybe<Nbtrk_Context_Bool_Exp>;
  recorder_id?: InputMaybe<Uuid_Comparison_Exp>;
  start_datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  task_type?: InputMaybe<String_Comparison_Exp>;
  ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  worker_id?: InputMaybe<Uuid_Comparison_Exp>;
  working_time?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_performed_work" */
export enum Nb_Performed_Work_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbPerformedWorkPkey = 'nb_performed_work_pkey'
}

/** input type for incrementing numeric columns in table "nb_performed_work" */
export type Nb_Performed_Work_Inc_Input = {
  working_time?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nb_performed_work" */
export type Nb_Performed_Work_Insert_Input = {
  appUserActiveByWorkerId?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  from_context_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Obj_Rel_Insert_Input>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Obj_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  nbtrk_context?: InputMaybe<Nbtrk_Context_Obj_Rel_Insert_Input>;
  recorder_id?: InputMaybe<Scalars['uuid']>;
  start_datetime?: InputMaybe<Scalars['timestamptz']>;
  task_type?: InputMaybe<Scalars['String']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
  working_time?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Nb_Performed_Work_Max_Fields = {
  __typename?: 'nb_performed_work_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  from_context_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  recorder_id?: Maybe<Scalars['uuid']>;
  start_datetime?: Maybe<Scalars['timestamptz']>;
  task_type?: Maybe<Scalars['String']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
  worker_id?: Maybe<Scalars['uuid']>;
  working_time?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "nb_performed_work" */
export type Nb_Performed_Work_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  from_context_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recorder_id?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Performed_Work_Min_Fields = {
  __typename?: 'nb_performed_work_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  from_context_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  recorder_id?: Maybe<Scalars['uuid']>;
  start_datetime?: Maybe<Scalars['timestamptz']>;
  task_type?: Maybe<Scalars['String']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
  worker_id?: Maybe<Scalars['uuid']>;
  working_time?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "nb_performed_work" */
export type Nb_Performed_Work_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  from_context_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recorder_id?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_performed_work" */
export type Nb_Performed_Work_Mutation_Response = {
  __typename?: 'nb_performed_work_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Performed_Work>;
};

/** on_conflict condition type for table "nb_performed_work" */
export type Nb_Performed_Work_On_Conflict = {
  constraint: Nb_Performed_Work_Constraint;
  update_columns?: Array<Nb_Performed_Work_Update_Column>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_performed_work". */
export type Nb_Performed_Work_Order_By = {
  appUserActiveByWorkerId?: InputMaybe<App_User_Active_Order_By>;
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  from_context_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Order_By>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nbtrk_context?: InputMaybe<Nbtrk_Context_Order_By>;
  recorder_id?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
  working_time?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_performed_work */
export type Nb_Performed_Work_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_performed_work" */
export enum Nb_Performed_Work_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  FromContextId = 'from_context_id',
  /** column name */
  Id = 'id',
  /** column name */
  RecorderId = 'recorder_id',
  /** column name */
  StartDatetime = 'start_datetime',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start',
  /** column name */
  WorkerId = 'worker_id',
  /** column name */
  WorkingTime = 'working_time'
}

/** input type for updating data in table "nb_performed_work" */
export type Nb_Performed_Work_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  from_context_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  recorder_id?: InputMaybe<Scalars['uuid']>;
  start_datetime?: InputMaybe<Scalars['timestamptz']>;
  task_type?: InputMaybe<Scalars['String']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
  working_time?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Nb_Performed_Work_Stddev_Fields = {
  __typename?: 'nb_performed_work_stddev_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_performed_work" */
export type Nb_Performed_Work_Stddev_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Performed_Work_Stddev_Pop_Fields = {
  __typename?: 'nb_performed_work_stddev_pop_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_performed_work" */
export type Nb_Performed_Work_Stddev_Pop_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Performed_Work_Stddev_Samp_Fields = {
  __typename?: 'nb_performed_work_stddev_samp_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_performed_work" */
export type Nb_Performed_Work_Stddev_Samp_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_performed_work" */
export type Nb_Performed_Work_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Performed_Work_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Performed_Work_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  from_context_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  recorder_id?: InputMaybe<Scalars['uuid']>;
  start_datetime?: InputMaybe<Scalars['timestamptz']>;
  task_type?: InputMaybe<Scalars['String']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
  working_time?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Nb_Performed_Work_Sum_Fields = {
  __typename?: 'nb_performed_work_sum_fields';
  working_time?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "nb_performed_work" */
export type Nb_Performed_Work_Sum_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** update columns of table "nb_performed_work" */
export enum Nb_Performed_Work_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  FromContextId = 'from_context_id',
  /** column name */
  Id = 'id',
  /** column name */
  RecorderId = 'recorder_id',
  /** column name */
  StartDatetime = 'start_datetime',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start',
  /** column name */
  WorkerId = 'worker_id',
  /** column name */
  WorkingTime = 'working_time'
}

export type Nb_Performed_Work_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Performed_Work_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Performed_Work_Set_Input>;
  where: Nb_Performed_Work_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Performed_Work_Var_Pop_Fields = {
  __typename?: 'nb_performed_work_var_pop_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_performed_work" */
export type Nb_Performed_Work_Var_Pop_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Performed_Work_Var_Samp_Fields = {
  __typename?: 'nb_performed_work_var_samp_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_performed_work" */
export type Nb_Performed_Work_Var_Samp_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Performed_Work_Variance_Fields = {
  __typename?: 'nb_performed_work_variance_fields';
  working_time?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_performed_work" */
export type Nb_Performed_Work_Variance_Order_By = {
  working_time?: InputMaybe<Order_By>;
};

/** 農薬テーブル */
export type Nb_Pesticide = {
  __typename?: 'nb_pesticide';
  created: Scalars['timestamptz'];
  formulation_name: Scalars['String'];
  id: Scalars['uuid'];
  kind: Scalars['String'];
  n_mixtures: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  nb_pesticide_company_links: Array<Nb_Pesticide_Company_Link>;
  /** An aggregate relationship */
  nb_pesticide_company_links_aggregate: Nb_Pesticide_Company_Link_Aggregate;
  /** An array relationship */
  nb_pesticide_ingredients: Array<Nb_Pesticide_Ingredient>;
  /** An aggregate relationship */
  nb_pesticide_ingredients_aggregate: Nb_Pesticide_Ingredient_Aggregate;
  purpose: Scalars['String'];
  register_number: Scalars['String'];
  registered_company: Scalars['String'];
  updated: Scalars['timestamptz'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** 農薬テーブル */
export type Nb_PesticideNb_Pesticide_Company_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
};


/** 農薬テーブル */
export type Nb_PesticideNb_Pesticide_Company_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
};


/** 農薬テーブル */
export type Nb_PesticideNb_Pesticide_IngredientsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Ingredient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Ingredient_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Ingredient_Bool_Exp>;
};


/** 農薬テーブル */
export type Nb_PesticideNb_Pesticide_Ingredients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Ingredient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Ingredient_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Ingredient_Bool_Exp>;
};

/** aggregated selection of "nb_pesticide" */
export type Nb_Pesticide_Aggregate = {
  __typename?: 'nb_pesticide_aggregate';
  aggregate?: Maybe<Nb_Pesticide_Aggregate_Fields>;
  nodes: Array<Nb_Pesticide>;
};

/** aggregate fields of "nb_pesticide" */
export type Nb_Pesticide_Aggregate_Fields = {
  __typename?: 'nb_pesticide_aggregate_fields';
  avg?: Maybe<Nb_Pesticide_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Pesticide_Max_Fields>;
  min?: Maybe<Nb_Pesticide_Min_Fields>;
  stddev?: Maybe<Nb_Pesticide_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Pesticide_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Pesticide_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Pesticide_Sum_Fields>;
  var_pop?: Maybe<Nb_Pesticide_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Pesticide_Var_Samp_Fields>;
  variance?: Maybe<Nb_Pesticide_Variance_Fields>;
};


/** aggregate fields of "nb_pesticide" */
export type Nb_Pesticide_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Pesticide_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Nb_Pesticide_Avg_Fields = {
  __typename?: 'nb_pesticide_avg_fields';
  n_mixtures?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nb_pesticide". All fields are combined with a logical 'AND'. */
export type Nb_Pesticide_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Pesticide_Bool_Exp>>;
  _not?: InputMaybe<Nb_Pesticide_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Pesticide_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  formulation_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  n_mixtures?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_pesticide_company_links?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
  nb_pesticide_company_links_aggregate?: InputMaybe<Nb_Pesticide_Company_Link_Aggregate_Bool_Exp>;
  nb_pesticide_ingredients?: InputMaybe<Nb_Pesticide_Ingredient_Bool_Exp>;
  nb_pesticide_ingredients_aggregate?: InputMaybe<Nb_Pesticide_Ingredient_Aggregate_Bool_Exp>;
  purpose?: InputMaybe<String_Comparison_Exp>;
  register_number?: InputMaybe<String_Comparison_Exp>;
  registered_company?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link = {
  __typename?: 'nb_pesticide_company_link';
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An object relationship */
  nb_pesticide: Nb_Pesticide;
  pesticide_id: Scalars['uuid'];
};

/** aggregated selection of "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_Aggregate = {
  __typename?: 'nb_pesticide_company_link_aggregate';
  aggregate?: Maybe<Nb_Pesticide_Company_Link_Aggregate_Fields>;
  nodes: Array<Nb_Pesticide_Company_Link>;
};

export type Nb_Pesticide_Company_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Pesticide_Company_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Pesticide_Company_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Pesticide_Company_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_Aggregate_Fields = {
  __typename?: 'nb_pesticide_company_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Pesticide_Company_Link_Max_Fields>;
  min?: Maybe<Nb_Pesticide_Company_Link_Min_Fields>;
};


/** aggregate fields of "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Pesticide_Company_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Pesticide_Company_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Pesticide_Company_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Pesticide_Company_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Pesticide_Company_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_pesticide_company_link". All fields are combined with a logical 'AND'. */
export type Nb_Pesticide_Company_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Pesticide_Company_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Pesticide_Company_Link_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_pesticide?: InputMaybe<Nb_Pesticide_Bool_Exp>;
  pesticide_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_pesticide_company_link" */
export enum Nb_Pesticide_Company_Link_Constraint {
  /** unique or primary key constraint on columns "pesticide_id", "company_id" */
  NbPesticideCompanyLinkCompanyIdPesticideIdKey = 'nb_pesticide_company_link_company_id_pesticide_id_key',
  /** unique or primary key constraint on columns "id" */
  NbPesticideCompanyLinkPkey = 'nb_pesticide_company_link_pkey'
}

/** input type for inserting data into table "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_Insert_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_pesticide?: InputMaybe<Nb_Pesticide_Obj_Rel_Insert_Input>;
  pesticide_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Pesticide_Company_Link_Max_Fields = {
  __typename?: 'nb_pesticide_company_link_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pesticide_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pesticide_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Pesticide_Company_Link_Min_Fields = {
  __typename?: 'nb_pesticide_company_link_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pesticide_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pesticide_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_Mutation_Response = {
  __typename?: 'nb_pesticide_company_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Pesticide_Company_Link>;
};

/** on_conflict condition type for table "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_On_Conflict = {
  constraint: Nb_Pesticide_Company_Link_Constraint;
  update_columns?: Array<Nb_Pesticide_Company_Link_Update_Column>;
  where?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_pesticide_company_link". */
export type Nb_Pesticide_Company_Link_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_pesticide?: InputMaybe<Nb_Pesticide_Order_By>;
  pesticide_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_pesticide_company_link */
export type Nb_Pesticide_Company_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_pesticide_company_link" */
export enum Nb_Pesticide_Company_Link_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PesticideId = 'pesticide_id'
}

/** input type for updating data in table "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  pesticide_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "nb_pesticide_company_link" */
export type Nb_Pesticide_Company_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Pesticide_Company_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Pesticide_Company_Link_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  pesticide_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nb_pesticide_company_link" */
export enum Nb_Pesticide_Company_Link_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PesticideId = 'pesticide_id'
}

export type Nb_Pesticide_Company_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Pesticide_Company_Link_Set_Input>;
  where: Nb_Pesticide_Company_Link_Bool_Exp;
};

/** unique or primary key constraints on table "nb_pesticide" */
export enum Nb_Pesticide_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbPesticidePkey = 'nb_pesticide_pkey',
  /** unique or primary key constraint on columns "register_number" */
  NbPesticideRegisterNumberKey = 'nb_pesticide_register_number_key'
}

/** input type for incrementing numeric columns in table "nb_pesticide" */
export type Nb_Pesticide_Inc_Input = {
  n_mixtures?: InputMaybe<Scalars['Int']>;
};

/** 農薬成分テーブル */
export type Nb_Pesticide_Ingredient = {
  __typename?: 'nb_pesticide_ingredient';
  active_ingredient: Scalars['String'];
  concentration: Scalars['Float'];
  created: Scalars['timestamptz'];
  detail_active_ingredient: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_pesticide: Nb_Pesticide;
  pesticide_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Aggregate = {
  __typename?: 'nb_pesticide_ingredient_aggregate';
  aggregate?: Maybe<Nb_Pesticide_Ingredient_Aggregate_Fields>;
  nodes: Array<Nb_Pesticide_Ingredient>;
};

export type Nb_Pesticide_Ingredient_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Pesticide_Ingredient_Aggregate_Bool_Exp_Count>;
};

export type Nb_Pesticide_Ingredient_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Pesticide_Ingredient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Pesticide_Ingredient_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Aggregate_Fields = {
  __typename?: 'nb_pesticide_ingredient_aggregate_fields';
  avg?: Maybe<Nb_Pesticide_Ingredient_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Pesticide_Ingredient_Max_Fields>;
  min?: Maybe<Nb_Pesticide_Ingredient_Min_Fields>;
  stddev?: Maybe<Nb_Pesticide_Ingredient_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Pesticide_Ingredient_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Pesticide_Ingredient_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Pesticide_Ingredient_Sum_Fields>;
  var_pop?: Maybe<Nb_Pesticide_Ingredient_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Pesticide_Ingredient_Var_Samp_Fields>;
  variance?: Maybe<Nb_Pesticide_Ingredient_Variance_Fields>;
};


/** aggregate fields of "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Pesticide_Ingredient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Pesticide_Ingredient_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Pesticide_Ingredient_Max_Order_By>;
  min?: InputMaybe<Nb_Pesticide_Ingredient_Min_Order_By>;
  stddev?: InputMaybe<Nb_Pesticide_Ingredient_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Pesticide_Ingredient_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Pesticide_Ingredient_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Pesticide_Ingredient_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Pesticide_Ingredient_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Pesticide_Ingredient_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Pesticide_Ingredient_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Arr_Rel_Insert_Input = {
  data: Array<Nb_Pesticide_Ingredient_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Pesticide_Ingredient_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Pesticide_Ingredient_Avg_Fields = {
  __typename?: 'nb_pesticide_ingredient_avg_fields';
  concentration?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Avg_Order_By = {
  concentration?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_pesticide_ingredient". All fields are combined with a logical 'AND'. */
export type Nb_Pesticide_Ingredient_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Pesticide_Ingredient_Bool_Exp>>;
  _not?: InputMaybe<Nb_Pesticide_Ingredient_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Pesticide_Ingredient_Bool_Exp>>;
  active_ingredient?: InputMaybe<String_Comparison_Exp>;
  concentration?: InputMaybe<Float_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  detail_active_ingredient?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_pesticide?: InputMaybe<Nb_Pesticide_Bool_Exp>;
  pesticide_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_pesticide_ingredient" */
export enum Nb_Pesticide_Ingredient_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbPesticideIngredientPkey = 'nb_pesticide_ingredient_pkey'
}

/** input type for incrementing numeric columns in table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Inc_Input = {
  concentration?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Insert_Input = {
  active_ingredient?: InputMaybe<Scalars['String']>;
  concentration?: InputMaybe<Scalars['Float']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_active_ingredient?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_pesticide?: InputMaybe<Nb_Pesticide_Obj_Rel_Insert_Input>;
  pesticide_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Pesticide_Ingredient_Max_Fields = {
  __typename?: 'nb_pesticide_ingredient_max_fields';
  active_ingredient?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['timestamptz']>;
  detail_active_ingredient?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  pesticide_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Max_Order_By = {
  active_ingredient?: InputMaybe<Order_By>;
  concentration?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  detail_active_ingredient?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pesticide_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Pesticide_Ingredient_Min_Fields = {
  __typename?: 'nb_pesticide_ingredient_min_fields';
  active_ingredient?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['timestamptz']>;
  detail_active_ingredient?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  pesticide_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Min_Order_By = {
  active_ingredient?: InputMaybe<Order_By>;
  concentration?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  detail_active_ingredient?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pesticide_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Mutation_Response = {
  __typename?: 'nb_pesticide_ingredient_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Pesticide_Ingredient>;
};

/** on_conflict condition type for table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_On_Conflict = {
  constraint: Nb_Pesticide_Ingredient_Constraint;
  update_columns?: Array<Nb_Pesticide_Ingredient_Update_Column>;
  where?: InputMaybe<Nb_Pesticide_Ingredient_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_pesticide_ingredient". */
export type Nb_Pesticide_Ingredient_Order_By = {
  active_ingredient?: InputMaybe<Order_By>;
  concentration?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  detail_active_ingredient?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_pesticide?: InputMaybe<Nb_Pesticide_Order_By>;
  pesticide_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_pesticide_ingredient */
export type Nb_Pesticide_Ingredient_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_pesticide_ingredient" */
export enum Nb_Pesticide_Ingredient_Select_Column {
  /** column name */
  ActiveIngredient = 'active_ingredient',
  /** column name */
  Concentration = 'concentration',
  /** column name */
  Created = 'created',
  /** column name */
  DetailActiveIngredient = 'detail_active_ingredient',
  /** column name */
  Id = 'id',
  /** column name */
  PesticideId = 'pesticide_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Set_Input = {
  active_ingredient?: InputMaybe<Scalars['String']>;
  concentration?: InputMaybe<Scalars['Float']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_active_ingredient?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  pesticide_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nb_Pesticide_Ingredient_Stddev_Fields = {
  __typename?: 'nb_pesticide_ingredient_stddev_fields';
  concentration?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Stddev_Order_By = {
  concentration?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Pesticide_Ingredient_Stddev_Pop_Fields = {
  __typename?: 'nb_pesticide_ingredient_stddev_pop_fields';
  concentration?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Stddev_Pop_Order_By = {
  concentration?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Pesticide_Ingredient_Stddev_Samp_Fields = {
  __typename?: 'nb_pesticide_ingredient_stddev_samp_fields';
  concentration?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Stddev_Samp_Order_By = {
  concentration?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Pesticide_Ingredient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Pesticide_Ingredient_Stream_Cursor_Value_Input = {
  active_ingredient?: InputMaybe<Scalars['String']>;
  concentration?: InputMaybe<Scalars['Float']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_active_ingredient?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  pesticide_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Pesticide_Ingredient_Sum_Fields = {
  __typename?: 'nb_pesticide_ingredient_sum_fields';
  concentration?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Sum_Order_By = {
  concentration?: InputMaybe<Order_By>;
};

/** update columns of table "nb_pesticide_ingredient" */
export enum Nb_Pesticide_Ingredient_Update_Column {
  /** column name */
  ActiveIngredient = 'active_ingredient',
  /** column name */
  Concentration = 'concentration',
  /** column name */
  Created = 'created',
  /** column name */
  DetailActiveIngredient = 'detail_active_ingredient',
  /** column name */
  Id = 'id',
  /** column name */
  PesticideId = 'pesticide_id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Pesticide_Ingredient_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Pesticide_Ingredient_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Pesticide_Ingredient_Set_Input>;
  where: Nb_Pesticide_Ingredient_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Pesticide_Ingredient_Var_Pop_Fields = {
  __typename?: 'nb_pesticide_ingredient_var_pop_fields';
  concentration?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Var_Pop_Order_By = {
  concentration?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Pesticide_Ingredient_Var_Samp_Fields = {
  __typename?: 'nb_pesticide_ingredient_var_samp_fields';
  concentration?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Var_Samp_Order_By = {
  concentration?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Pesticide_Ingredient_Variance_Fields = {
  __typename?: 'nb_pesticide_ingredient_variance_fields';
  concentration?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_pesticide_ingredient" */
export type Nb_Pesticide_Ingredient_Variance_Order_By = {
  concentration?: InputMaybe<Order_By>;
};

/** input type for inserting data into table "nb_pesticide" */
export type Nb_Pesticide_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  formulation_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  n_mixtures?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nb_pesticide_company_links?: InputMaybe<Nb_Pesticide_Company_Link_Arr_Rel_Insert_Input>;
  nb_pesticide_ingredients?: InputMaybe<Nb_Pesticide_Ingredient_Arr_Rel_Insert_Input>;
  purpose?: InputMaybe<Scalars['String']>;
  register_number?: InputMaybe<Scalars['String']>;
  registered_company?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Pesticide_Max_Fields = {
  __typename?: 'nb_pesticide_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  formulation_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kind?: Maybe<Scalars['String']>;
  n_mixtures?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  register_number?: Maybe<Scalars['String']>;
  registered_company?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Pesticide_Min_Fields = {
  __typename?: 'nb_pesticide_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  formulation_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kind?: Maybe<Scalars['String']>;
  n_mixtures?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  register_number?: Maybe<Scalars['String']>;
  registered_company?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_pesticide" */
export type Nb_Pesticide_Mutation_Response = {
  __typename?: 'nb_pesticide_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Pesticide>;
};

/** input type for inserting object relation for remote table "nb_pesticide" */
export type Nb_Pesticide_Obj_Rel_Insert_Input = {
  data: Nb_Pesticide_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Pesticide_On_Conflict>;
};

/** on_conflict condition type for table "nb_pesticide" */
export type Nb_Pesticide_On_Conflict = {
  constraint: Nb_Pesticide_Constraint;
  update_columns?: Array<Nb_Pesticide_Update_Column>;
  where?: InputMaybe<Nb_Pesticide_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_pesticide". */
export type Nb_Pesticide_Order_By = {
  created?: InputMaybe<Order_By>;
  formulation_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  n_mixtures?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_pesticide_company_links_aggregate?: InputMaybe<Nb_Pesticide_Company_Link_Aggregate_Order_By>;
  nb_pesticide_ingredients_aggregate?: InputMaybe<Nb_Pesticide_Ingredient_Aggregate_Order_By>;
  purpose?: InputMaybe<Order_By>;
  register_number?: InputMaybe<Order_By>;
  registered_company?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_pesticide */
export type Nb_Pesticide_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_pesticide" */
export enum Nb_Pesticide_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FormulationName = 'formulation_name',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  NMixtures = 'n_mixtures',
  /** column name */
  Name = 'name',
  /** column name */
  Purpose = 'purpose',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  RegisteredCompany = 'registered_company',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_pesticide" */
export type Nb_Pesticide_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  formulation_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  n_mixtures?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<Scalars['String']>;
  register_number?: InputMaybe<Scalars['String']>;
  registered_company?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work = {
  __typename?: 'nb_pesticide_spray_work';
  /** An object relationship */
  app_user_active: App_User_Active;
  comment: Scalars['String'];
  created: Scalars['timestamptz'];
  cultivation_plan_id: Scalars['uuid'];
  datetime: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_cultivation_plan: Nb_Cultivation_Plan;
  updated: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};

/** aggregated selection of "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_Aggregate = {
  __typename?: 'nb_pesticide_spray_work_aggregate';
  aggregate?: Maybe<Nb_Pesticide_Spray_Work_Aggregate_Fields>;
  nodes: Array<Nb_Pesticide_Spray_Work>;
};

export type Nb_Pesticide_Spray_Work_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Pesticide_Spray_Work_Aggregate_Bool_Exp_Count>;
};

export type Nb_Pesticide_Spray_Work_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_Aggregate_Fields = {
  __typename?: 'nb_pesticide_spray_work_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Pesticide_Spray_Work_Max_Fields>;
  min?: Maybe<Nb_Pesticide_Spray_Work_Min_Fields>;
};


/** aggregate fields of "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Pesticide_Spray_Work_Max_Order_By>;
  min?: InputMaybe<Nb_Pesticide_Spray_Work_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_Arr_Rel_Insert_Input = {
  data: Array<Nb_Pesticide_Spray_Work_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Pesticide_Spray_Work_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_pesticide_spray_work". All fields are combined with a logical 'AND'. */
export type Nb_Pesticide_Spray_Work_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Bool_Exp>>;
  _not?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  cultivation_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_pesticide_spray_work" */
export enum Nb_Pesticide_Spray_Work_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbPesticideSprayWorkPkey = 'nb_pesticide_spray_work_pkey'
}

/** input type for inserting data into table "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Pesticide_Spray_Work_Max_Fields = {
  __typename?: 'nb_pesticide_spray_work_max_fields';
  comment?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Pesticide_Spray_Work_Min_Fields = {
  __typename?: 'nb_pesticide_spray_work_min_fields';
  comment?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_Mutation_Response = {
  __typename?: 'nb_pesticide_spray_work_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Pesticide_Spray_Work>;
};

/** on_conflict condition type for table "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_On_Conflict = {
  constraint: Nb_Pesticide_Spray_Work_Constraint;
  update_columns?: Array<Nb_Pesticide_Spray_Work_Update_Column>;
  where?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_pesticide_spray_work". */
export type Nb_Pesticide_Spray_Work_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_pesticide_spray_work */
export type Nb_Pesticide_Spray_Work_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_pesticide_spray_work" */
export enum Nb_Pesticide_Spray_Work_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_pesticide_spray_work" */
export type Nb_Pesticide_Spray_Work_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Pesticide_Spray_Work_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Pesticide_Spray_Work_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_pesticide_spray_work" */
export enum Nb_Pesticide_Spray_Work_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Pesticide_Spray_Work_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Pesticide_Spray_Work_Set_Input>;
  where: Nb_Pesticide_Spray_Work_Bool_Exp;
};

/** aggregate stddev on columns */
export type Nb_Pesticide_Stddev_Fields = {
  __typename?: 'nb_pesticide_stddev_fields';
  n_mixtures?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Nb_Pesticide_Stddev_Pop_Fields = {
  __typename?: 'nb_pesticide_stddev_pop_fields';
  n_mixtures?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Nb_Pesticide_Stddev_Samp_Fields = {
  __typename?: 'nb_pesticide_stddev_samp_fields';
  n_mixtures?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nb_pesticide" */
export type Nb_Pesticide_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Pesticide_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Pesticide_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  formulation_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  n_mixtures?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<Scalars['String']>;
  register_number?: InputMaybe<Scalars['String']>;
  registered_company?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Pesticide_Sum_Fields = {
  __typename?: 'nb_pesticide_sum_fields';
  n_mixtures?: Maybe<Scalars['Int']>;
};

/** update columns of table "nb_pesticide" */
export enum Nb_Pesticide_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FormulationName = 'formulation_name',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  NMixtures = 'n_mixtures',
  /** column name */
  Name = 'name',
  /** column name */
  Purpose = 'purpose',
  /** column name */
  RegisterNumber = 'register_number',
  /** column name */
  RegisteredCompany = 'registered_company',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Pesticide_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Pesticide_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Pesticide_Set_Input>;
  where: Nb_Pesticide_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Pesticide_Var_Pop_Fields = {
  __typename?: 'nb_pesticide_var_pop_fields';
  n_mixtures?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Nb_Pesticide_Var_Samp_Fields = {
  __typename?: 'nb_pesticide_var_samp_fields';
  n_mixtures?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Nb_Pesticide_Variance_Fields = {
  __typename?: 'nb_pesticide_variance_fields';
  n_mixtures?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "nb_picture_analysis" */
export type Nb_Picture_Analysis = {
  __typename?: 'nb_picture_analysis';
  analysis_type_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  field_picture_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_field_picture: Nb_Field_Picture;
  /** An object relationship */
  nb_picture_analysis_type: Nb_Picture_Analysis_Type;
  /** An array relationship */
  nbb_picture_analysis_statuses: Array<Nbb_Picture_Analysis_Status>;
  /** An aggregate relationship */
  nbb_picture_analysis_statuses_aggregate: Nbb_Picture_Analysis_Status_Aggregate;
  result: Scalars['json'];
  result_image_path?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nb_picture_analysis" */
export type Nb_Picture_AnalysisNbb_Picture_Analysis_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Order_By>>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};


/** columns and relationships of "nb_picture_analysis" */
export type Nb_Picture_AnalysisNbb_Picture_Analysis_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Order_By>>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};


/** columns and relationships of "nb_picture_analysis" */
export type Nb_Picture_AnalysisResultArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "nb_picture_analysis" */
export type Nb_Picture_Analysis_Aggregate = {
  __typename?: 'nb_picture_analysis_aggregate';
  aggregate?: Maybe<Nb_Picture_Analysis_Aggregate_Fields>;
  nodes: Array<Nb_Picture_Analysis>;
};

export type Nb_Picture_Analysis_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Picture_Analysis_Aggregate_Bool_Exp_Count>;
};

export type Nb_Picture_Analysis_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Picture_Analysis_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_picture_analysis" */
export type Nb_Picture_Analysis_Aggregate_Fields = {
  __typename?: 'nb_picture_analysis_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Picture_Analysis_Max_Fields>;
  min?: Maybe<Nb_Picture_Analysis_Min_Fields>;
};


/** aggregate fields of "nb_picture_analysis" */
export type Nb_Picture_Analysis_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Picture_Analysis_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_picture_analysis" */
export type Nb_Picture_Analysis_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Picture_Analysis_Max_Order_By>;
  min?: InputMaybe<Nb_Picture_Analysis_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_picture_analysis" */
export type Nb_Picture_Analysis_Arr_Rel_Insert_Input = {
  data: Array<Nb_Picture_Analysis_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Picture_Analysis_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_picture_analysis". All fields are combined with a logical 'AND'. */
export type Nb_Picture_Analysis_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Picture_Analysis_Bool_Exp>>;
  _not?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Picture_Analysis_Bool_Exp>>;
  analysis_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_picture_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
  nb_picture_analysis_type?: InputMaybe<Nb_Picture_Analysis_Type_Bool_Exp>;
  nbb_picture_analysis_statuses?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
  nbb_picture_analysis_statuses_aggregate?: InputMaybe<Nbb_Picture_Analysis_Status_Aggregate_Bool_Exp>;
  result?: InputMaybe<Json_Comparison_Exp>;
  result_image_path?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_picture_analysis" */
export enum Nb_Picture_Analysis_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbPictureAnalysisPkey = 'nb_picture_analysis_pkey'
}

/** input type for inserting data into table "nb_picture_analysis" */
export type Nb_Picture_Analysis_Insert_Input = {
  analysis_type_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Obj_Rel_Insert_Input>;
  nb_picture_analysis_type?: InputMaybe<Nb_Picture_Analysis_Type_Obj_Rel_Insert_Input>;
  nbb_picture_analysis_statuses?: InputMaybe<Nbb_Picture_Analysis_Status_Arr_Rel_Insert_Input>;
  result?: InputMaybe<Scalars['json']>;
  result_image_path?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Picture_Analysis_Max_Fields = {
  __typename?: 'nb_picture_analysis_max_fields';
  analysis_type_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  field_picture_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  result_image_path?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_picture_analysis" */
export type Nb_Picture_Analysis_Max_Order_By = {
  analysis_type_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result_image_path?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Picture_Analysis_Min_Fields = {
  __typename?: 'nb_picture_analysis_min_fields';
  analysis_type_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  field_picture_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  result_image_path?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_picture_analysis" */
export type Nb_Picture_Analysis_Min_Order_By = {
  analysis_type_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result_image_path?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_picture_analysis" */
export type Nb_Picture_Analysis_Mutation_Response = {
  __typename?: 'nb_picture_analysis_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Picture_Analysis>;
};

/** input type for inserting object relation for remote table "nb_picture_analysis" */
export type Nb_Picture_Analysis_Obj_Rel_Insert_Input = {
  data: Nb_Picture_Analysis_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Picture_Analysis_On_Conflict>;
};

/** on_conflict condition type for table "nb_picture_analysis" */
export type Nb_Picture_Analysis_On_Conflict = {
  constraint: Nb_Picture_Analysis_Constraint;
  update_columns?: Array<Nb_Picture_Analysis_Update_Column>;
  where?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_picture_analysis". */
export type Nb_Picture_Analysis_Order_By = {
  analysis_type_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Order_By>;
  nb_picture_analysis_type?: InputMaybe<Nb_Picture_Analysis_Type_Order_By>;
  nbb_picture_analysis_statuses_aggregate?: InputMaybe<Nbb_Picture_Analysis_Status_Aggregate_Order_By>;
  result?: InputMaybe<Order_By>;
  result_image_path?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_picture_analysis */
export type Nb_Picture_Analysis_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_picture_analysis" */
export enum Nb_Picture_Analysis_Select_Column {
  /** column name */
  AnalysisTypeId = 'analysis_type_id',
  /** column name */
  Created = 'created',
  /** column name */
  FieldPictureId = 'field_picture_id',
  /** column name */
  Id = 'id',
  /** column name */
  Result = 'result',
  /** column name */
  ResultImagePath = 'result_image_path',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_picture_analysis" */
export type Nb_Picture_Analysis_Set_Input = {
  analysis_type_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  result?: InputMaybe<Scalars['json']>;
  result_image_path?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_picture_analysis" */
export type Nb_Picture_Analysis_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Picture_Analysis_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Picture_Analysis_Stream_Cursor_Value_Input = {
  analysis_type_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  result?: InputMaybe<Scalars['json']>;
  result_image_path?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_Type = {
  __typename?: 'nb_picture_analysis_type';
  classes_name: Scalars['json'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  nb_picture_analyses: Array<Nb_Picture_Analysis>;
  /** An aggregate relationship */
  nb_picture_analyses_aggregate: Nb_Picture_Analysis_Aggregate;
  /** An array relationship */
  nbb_picture_analysis_statuses: Array<Nbb_Picture_Analysis_Status>;
  /** An aggregate relationship */
  nbb_picture_analysis_statuses_aggregate: Nbb_Picture_Analysis_Status_Aggregate;
  /** An array relationship */
  nbpm_picanatype_authorization_links: Array<Nbpm_Picanatype_Authorization_Link>;
  /** An aggregate relationship */
  nbpm_picanatype_authorization_links_aggregate: Nbpm_Picanatype_Authorization_Link_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_TypeClasses_NameArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_TypeNb_Picture_AnalysesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
};


/** columns and relationships of "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_TypeNb_Picture_Analyses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
};


/** columns and relationships of "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_TypeNbb_Picture_Analysis_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Order_By>>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};


/** columns and relationships of "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_TypeNbb_Picture_Analysis_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Order_By>>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};


/** columns and relationships of "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_TypeNbpm_Picanatype_Authorization_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
};


/** columns and relationships of "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_TypeNbpm_Picanatype_Authorization_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
};

/** aggregated selection of "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_Type_Aggregate = {
  __typename?: 'nb_picture_analysis_type_aggregate';
  aggregate?: Maybe<Nb_Picture_Analysis_Type_Aggregate_Fields>;
  nodes: Array<Nb_Picture_Analysis_Type>;
};

/** aggregate fields of "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_Type_Aggregate_Fields = {
  __typename?: 'nb_picture_analysis_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Picture_Analysis_Type_Max_Fields>;
  min?: Maybe<Nb_Picture_Analysis_Type_Min_Fields>;
};


/** aggregate fields of "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Picture_Analysis_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_picture_analysis_type". All fields are combined with a logical 'AND'. */
export type Nb_Picture_Analysis_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Picture_Analysis_Type_Bool_Exp>>;
  _not?: InputMaybe<Nb_Picture_Analysis_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Picture_Analysis_Type_Bool_Exp>>;
  classes_name?: InputMaybe<Json_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_picture_analyses?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
  nb_picture_analyses_aggregate?: InputMaybe<Nb_Picture_Analysis_Aggregate_Bool_Exp>;
  nbb_picture_analysis_statuses?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
  nbb_picture_analysis_statuses_aggregate?: InputMaybe<Nbb_Picture_Analysis_Status_Aggregate_Bool_Exp>;
  nbpm_picanatype_authorization_links?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
  nbpm_picanatype_authorization_links_aggregate?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_picture_analysis_type" */
export enum Nb_Picture_Analysis_Type_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbPictureAnalysisTypeKeyKey = 'nb_picture_analysis_type_key_key',
  /** unique or primary key constraint on columns "id" */
  NbPictureAnalysisTypePkey = 'nb_picture_analysis_type_pkey'
}

/** input type for inserting data into table "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_Type_Insert_Input = {
  classes_name?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nb_picture_analyses?: InputMaybe<Nb_Picture_Analysis_Arr_Rel_Insert_Input>;
  nbb_picture_analysis_statuses?: InputMaybe<Nbb_Picture_Analysis_Status_Arr_Rel_Insert_Input>;
  nbpm_picanatype_authorization_links?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Picture_Analysis_Type_Max_Fields = {
  __typename?: 'nb_picture_analysis_type_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Picture_Analysis_Type_Min_Fields = {
  __typename?: 'nb_picture_analysis_type_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_Type_Mutation_Response = {
  __typename?: 'nb_picture_analysis_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Picture_Analysis_Type>;
};

/** input type for inserting object relation for remote table "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_Type_Obj_Rel_Insert_Input = {
  data: Nb_Picture_Analysis_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Picture_Analysis_Type_On_Conflict>;
};

/** on_conflict condition type for table "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_Type_On_Conflict = {
  constraint: Nb_Picture_Analysis_Type_Constraint;
  update_columns?: Array<Nb_Picture_Analysis_Type_Update_Column>;
  where?: InputMaybe<Nb_Picture_Analysis_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_picture_analysis_type". */
export type Nb_Picture_Analysis_Type_Order_By = {
  classes_name?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_picture_analyses_aggregate?: InputMaybe<Nb_Picture_Analysis_Aggregate_Order_By>;
  nbb_picture_analysis_statuses_aggregate?: InputMaybe<Nbb_Picture_Analysis_Status_Aggregate_Order_By>;
  nbpm_picanatype_authorization_links_aggregate?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_picture_analysis_type */
export type Nb_Picture_Analysis_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_picture_analysis_type" */
export enum Nb_Picture_Analysis_Type_Select_Column {
  /** column name */
  ClassesName = 'classes_name',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_Type_Set_Input = {
  classes_name?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_picture_analysis_type" */
export type Nb_Picture_Analysis_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Picture_Analysis_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Picture_Analysis_Type_Stream_Cursor_Value_Input = {
  classes_name?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_picture_analysis_type" */
export enum Nb_Picture_Analysis_Type_Update_Column {
  /** column name */
  ClassesName = 'classes_name',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

export type Nb_Picture_Analysis_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Picture_Analysis_Type_Set_Input>;
  where: Nb_Picture_Analysis_Type_Bool_Exp;
};

/** update columns of table "nb_picture_analysis" */
export enum Nb_Picture_Analysis_Update_Column {
  /** column name */
  AnalysisTypeId = 'analysis_type_id',
  /** column name */
  Created = 'created',
  /** column name */
  FieldPictureId = 'field_picture_id',
  /** column name */
  Id = 'id',
  /** column name */
  Result = 'result',
  /** column name */
  ResultImagePath = 'result_image_path',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Picture_Analysis_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Picture_Analysis_Set_Input>;
  where: Nb_Picture_Analysis_Bool_Exp;
};

/** columns and relationships of "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing = {
  __typename?: 'nb_raster_image_sensing';
  created: Scalars['timestamptz'];
  datetime: Scalars['timestamptz'];
  frame_corners?: Maybe<Scalars['geometry']>;
  id: Scalars['uuid'];
  mean?: Maybe<Scalars['Float']>;
  /** An array relationship */
  nb_cplan_imsensing_links: Array<Nb_Cplan_Imsensing_Link>;
  /** An aggregate relationship */
  nb_cplan_imsensing_links_aggregate: Nb_Cplan_Imsensing_Link_Aggregate;
  /** An object relationship */
  nb_image_sensing_attr: Nb_Image_Sensing_Attr;
  /** An array relationship */
  nb_raster_ims_sizes: Array<Nb_Raster_Ims_Size>;
  /** An aggregate relationship */
  nb_raster_ims_sizes_aggregate: Nb_Raster_Ims_Size_Aggregate;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  /** An object relationship */
  nb_vector_image_sensing?: Maybe<Nb_Vector_Image_Sensing>;
  sensing_data_path: Scalars['String'];
  sensing_type_id: Scalars['uuid'];
  ufield_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
  vector_ims_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "nb_raster_image_sensing" */
export type Nb_Raster_Image_SensingNb_Cplan_Imsensing_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Order_By>>;
  where?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
};


/** columns and relationships of "nb_raster_image_sensing" */
export type Nb_Raster_Image_SensingNb_Cplan_Imsensing_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Order_By>>;
  where?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
};


/** columns and relationships of "nb_raster_image_sensing" */
export type Nb_Raster_Image_SensingNb_Raster_Ims_SizesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Raster_Ims_Size_Bool_Exp>;
};


/** columns and relationships of "nb_raster_image_sensing" */
export type Nb_Raster_Image_SensingNb_Raster_Ims_Sizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Raster_Ims_Size_Bool_Exp>;
};

/** aggregated selection of "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Aggregate = {
  __typename?: 'nb_raster_image_sensing_aggregate';
  aggregate?: Maybe<Nb_Raster_Image_Sensing_Aggregate_Fields>;
  nodes: Array<Nb_Raster_Image_Sensing>;
};

export type Nb_Raster_Image_Sensing_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Raster_Image_Sensing_Aggregate_Bool_Exp_Count>;
};

export type Nb_Raster_Image_Sensing_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Aggregate_Fields = {
  __typename?: 'nb_raster_image_sensing_aggregate_fields';
  avg?: Maybe<Nb_Raster_Image_Sensing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Raster_Image_Sensing_Max_Fields>;
  min?: Maybe<Nb_Raster_Image_Sensing_Min_Fields>;
  stddev?: Maybe<Nb_Raster_Image_Sensing_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Raster_Image_Sensing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Raster_Image_Sensing_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Raster_Image_Sensing_Sum_Fields>;
  var_pop?: Maybe<Nb_Raster_Image_Sensing_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Raster_Image_Sensing_Var_Samp_Fields>;
  variance?: Maybe<Nb_Raster_Image_Sensing_Variance_Fields>;
};


/** aggregate fields of "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Raster_Image_Sensing_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Raster_Image_Sensing_Max_Order_By>;
  min?: InputMaybe<Nb_Raster_Image_Sensing_Min_Order_By>;
  stddev?: InputMaybe<Nb_Raster_Image_Sensing_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Raster_Image_Sensing_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Raster_Image_Sensing_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Raster_Image_Sensing_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Raster_Image_Sensing_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Raster_Image_Sensing_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Raster_Image_Sensing_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Arr_Rel_Insert_Input = {
  data: Array<Nb_Raster_Image_Sensing_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Raster_Image_Sensing_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Raster_Image_Sensing_Avg_Fields = {
  __typename?: 'nb_raster_image_sensing_avg_fields';
  mean?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Avg_Order_By = {
  mean?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_raster_image_sensing". All fields are combined with a logical 'AND'. */
export type Nb_Raster_Image_Sensing_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Raster_Image_Sensing_Bool_Exp>>;
  _not?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Raster_Image_Sensing_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  frame_corners?: InputMaybe<Geometry_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mean?: InputMaybe<Float_Comparison_Exp>;
  nb_cplan_imsensing_links?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
  nb_cplan_imsensing_links_aggregate?: InputMaybe<Nb_Cplan_Imsensing_Link_Aggregate_Bool_Exp>;
  nb_image_sensing_attr?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
  nb_raster_ims_sizes?: InputMaybe<Nb_Raster_Ims_Size_Bool_Exp>;
  nb_raster_ims_sizes_aggregate?: InputMaybe<Nb_Raster_Ims_Size_Aggregate_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nb_vector_image_sensing?: InputMaybe<Nb_Vector_Image_Sensing_Bool_Exp>;
  sensing_data_path?: InputMaybe<String_Comparison_Exp>;
  sensing_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  vector_ims_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_raster_image_sensing" */
export enum Nb_Raster_Image_Sensing_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbRasterImageSensingPkey = 'nb_raster_image_sensing_pkey'
}

/** input type for incrementing numeric columns in table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Inc_Input = {
  mean?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  frame_corners?: InputMaybe<Scalars['geometry']>;
  id?: InputMaybe<Scalars['uuid']>;
  mean?: InputMaybe<Scalars['Float']>;
  nb_cplan_imsensing_links?: InputMaybe<Nb_Cplan_Imsensing_Link_Arr_Rel_Insert_Input>;
  nb_image_sensing_attr?: InputMaybe<Nb_Image_Sensing_Attr_Obj_Rel_Insert_Input>;
  nb_raster_ims_sizes?: InputMaybe<Nb_Raster_Ims_Size_Arr_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  nb_vector_image_sensing?: InputMaybe<Nb_Vector_Image_Sensing_Obj_Rel_Insert_Input>;
  sensing_data_path?: InputMaybe<Scalars['String']>;
  sensing_type_id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  vector_ims_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Raster_Image_Sensing_Max_Fields = {
  __typename?: 'nb_raster_image_sensing_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mean?: Maybe<Scalars['Float']>;
  sensing_data_path?: Maybe<Scalars['String']>;
  sensing_type_id?: Maybe<Scalars['uuid']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  vector_ims_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mean?: InputMaybe<Order_By>;
  sensing_data_path?: InputMaybe<Order_By>;
  sensing_type_id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  vector_ims_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Raster_Image_Sensing_Min_Fields = {
  __typename?: 'nb_raster_image_sensing_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mean?: Maybe<Scalars['Float']>;
  sensing_data_path?: Maybe<Scalars['String']>;
  sensing_type_id?: Maybe<Scalars['uuid']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  vector_ims_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mean?: InputMaybe<Order_By>;
  sensing_data_path?: InputMaybe<Order_By>;
  sensing_type_id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  vector_ims_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Mutation_Response = {
  __typename?: 'nb_raster_image_sensing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Raster_Image_Sensing>;
};

/** input type for inserting object relation for remote table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Obj_Rel_Insert_Input = {
  data: Nb_Raster_Image_Sensing_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Raster_Image_Sensing_On_Conflict>;
};

/** on_conflict condition type for table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_On_Conflict = {
  constraint: Nb_Raster_Image_Sensing_Constraint;
  update_columns?: Array<Nb_Raster_Image_Sensing_Update_Column>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_raster_image_sensing". */
export type Nb_Raster_Image_Sensing_Order_By = {
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  frame_corners?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mean?: InputMaybe<Order_By>;
  nb_cplan_imsensing_links_aggregate?: InputMaybe<Nb_Cplan_Imsensing_Link_Aggregate_Order_By>;
  nb_image_sensing_attr?: InputMaybe<Nb_Image_Sensing_Attr_Order_By>;
  nb_raster_ims_sizes_aggregate?: InputMaybe<Nb_Raster_Ims_Size_Aggregate_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nb_vector_image_sensing?: InputMaybe<Nb_Vector_Image_Sensing_Order_By>;
  sensing_data_path?: InputMaybe<Order_By>;
  sensing_type_id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  vector_ims_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_raster_image_sensing */
export type Nb_Raster_Image_Sensing_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_raster_image_sensing" */
export enum Nb_Raster_Image_Sensing_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FrameCorners = 'frame_corners',
  /** column name */
  Id = 'id',
  /** column name */
  Mean = 'mean',
  /** column name */
  SensingDataPath = 'sensing_data_path',
  /** column name */
  SensingTypeId = 'sensing_type_id',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  VectorImsId = 'vector_ims_id'
}

/** input type for updating data in table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  frame_corners?: InputMaybe<Scalars['geometry']>;
  id?: InputMaybe<Scalars['uuid']>;
  mean?: InputMaybe<Scalars['Float']>;
  sensing_data_path?: InputMaybe<Scalars['String']>;
  sensing_type_id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  vector_ims_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Nb_Raster_Image_Sensing_Stddev_Fields = {
  __typename?: 'nb_raster_image_sensing_stddev_fields';
  mean?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Stddev_Order_By = {
  mean?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Raster_Image_Sensing_Stddev_Pop_Fields = {
  __typename?: 'nb_raster_image_sensing_stddev_pop_fields';
  mean?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Stddev_Pop_Order_By = {
  mean?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Raster_Image_Sensing_Stddev_Samp_Fields = {
  __typename?: 'nb_raster_image_sensing_stddev_samp_fields';
  mean?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Stddev_Samp_Order_By = {
  mean?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Raster_Image_Sensing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Raster_Image_Sensing_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  frame_corners?: InputMaybe<Scalars['geometry']>;
  id?: InputMaybe<Scalars['uuid']>;
  mean?: InputMaybe<Scalars['Float']>;
  sensing_data_path?: InputMaybe<Scalars['String']>;
  sensing_type_id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  vector_ims_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nb_Raster_Image_Sensing_Sum_Fields = {
  __typename?: 'nb_raster_image_sensing_sum_fields';
  mean?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Sum_Order_By = {
  mean?: InputMaybe<Order_By>;
};

/** update columns of table "nb_raster_image_sensing" */
export enum Nb_Raster_Image_Sensing_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FrameCorners = 'frame_corners',
  /** column name */
  Id = 'id',
  /** column name */
  Mean = 'mean',
  /** column name */
  SensingDataPath = 'sensing_data_path',
  /** column name */
  SensingTypeId = 'sensing_type_id',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  VectorImsId = 'vector_ims_id'
}

export type Nb_Raster_Image_Sensing_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Raster_Image_Sensing_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Raster_Image_Sensing_Set_Input>;
  where: Nb_Raster_Image_Sensing_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Raster_Image_Sensing_Var_Pop_Fields = {
  __typename?: 'nb_raster_image_sensing_var_pop_fields';
  mean?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Var_Pop_Order_By = {
  mean?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Raster_Image_Sensing_Var_Samp_Fields = {
  __typename?: 'nb_raster_image_sensing_var_samp_fields';
  mean?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Var_Samp_Order_By = {
  mean?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Raster_Image_Sensing_Variance_Fields = {
  __typename?: 'nb_raster_image_sensing_variance_fields';
  mean?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_raster_image_sensing" */
export type Nb_Raster_Image_Sensing_Variance_Order_By = {
  mean?: InputMaybe<Order_By>;
};

/** nb_raster_image_sensingで管理されるファイルのサイズを保持する */
export type Nb_Raster_Ims_Size = {
  __typename?: 'nb_raster_ims_size';
  company_key?: Maybe<Scalars['String']>;
  file_type: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_raster_image_sensing: Nb_Raster_Image_Sensing;
  raster_ims_id: Scalars['uuid'];
  sensing_data_size_bytes: Scalars['bigint'];
  total_size_bytes: Scalars['bigint'];
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Aggregate = {
  __typename?: 'nb_raster_ims_size_aggregate';
  aggregate?: Maybe<Nb_Raster_Ims_Size_Aggregate_Fields>;
  nodes: Array<Nb_Raster_Ims_Size>;
};

export type Nb_Raster_Ims_Size_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Raster_Ims_Size_Aggregate_Bool_Exp_Count>;
};

export type Nb_Raster_Ims_Size_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Raster_Ims_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Raster_Ims_Size_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Aggregate_Fields = {
  __typename?: 'nb_raster_ims_size_aggregate_fields';
  avg?: Maybe<Nb_Raster_Ims_Size_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Raster_Ims_Size_Max_Fields>;
  min?: Maybe<Nb_Raster_Ims_Size_Min_Fields>;
  stddev?: Maybe<Nb_Raster_Ims_Size_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Raster_Ims_Size_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Raster_Ims_Size_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Raster_Ims_Size_Sum_Fields>;
  var_pop?: Maybe<Nb_Raster_Ims_Size_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Raster_Ims_Size_Var_Samp_Fields>;
  variance?: Maybe<Nb_Raster_Ims_Size_Variance_Fields>;
};


/** aggregate fields of "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Raster_Ims_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Raster_Ims_Size_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Raster_Ims_Size_Max_Order_By>;
  min?: InputMaybe<Nb_Raster_Ims_Size_Min_Order_By>;
  stddev?: InputMaybe<Nb_Raster_Ims_Size_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Raster_Ims_Size_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Raster_Ims_Size_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Raster_Ims_Size_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Raster_Ims_Size_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Raster_Ims_Size_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Raster_Ims_Size_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Arr_Rel_Insert_Input = {
  data: Array<Nb_Raster_Ims_Size_Insert_Input>;
};

/** aggregate avg on columns */
export type Nb_Raster_Ims_Size_Avg_Fields = {
  __typename?: 'nb_raster_ims_size_avg_fields';
  sensing_data_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Avg_Order_By = {
  sensing_data_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_raster_ims_size". All fields are combined with a logical 'AND'. */
export type Nb_Raster_Ims_Size_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Raster_Ims_Size_Bool_Exp>>;
  _not?: InputMaybe<Nb_Raster_Ims_Size_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Raster_Ims_Size_Bool_Exp>>;
  company_key?: InputMaybe<String_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_raster_image_sensing?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
  raster_ims_id?: InputMaybe<Uuid_Comparison_Exp>;
  sensing_data_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  total_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Inc_Input = {
  sensing_data_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Insert_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_raster_image_sensing?: InputMaybe<Nb_Raster_Image_Sensing_Obj_Rel_Insert_Input>;
  raster_ims_id?: InputMaybe<Scalars['uuid']>;
  sensing_data_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Raster_Ims_Size_Max_Fields = {
  __typename?: 'nb_raster_ims_size_max_fields';
  company_key?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  raster_ims_id?: Maybe<Scalars['uuid']>;
  sensing_data_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Max_Order_By = {
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  raster_ims_id?: InputMaybe<Order_By>;
  sensing_data_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Raster_Ims_Size_Min_Fields = {
  __typename?: 'nb_raster_ims_size_min_fields';
  company_key?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  raster_ims_id?: Maybe<Scalars['uuid']>;
  sensing_data_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Min_Order_By = {
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  raster_ims_id?: InputMaybe<Order_By>;
  sensing_data_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Mutation_Response = {
  __typename?: 'nb_raster_ims_size_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Raster_Ims_Size>;
};

/** Ordering options when selecting data from "nb_raster_ims_size". */
export type Nb_Raster_Ims_Size_Order_By = {
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_raster_image_sensing?: InputMaybe<Nb_Raster_Image_Sensing_Order_By>;
  raster_ims_id?: InputMaybe<Order_By>;
  sensing_data_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "nb_raster_ims_size" */
export enum Nb_Raster_Ims_Size_Select_Column {
  /** column name */
  CompanyKey = 'company_key',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  RasterImsId = 'raster_ims_id',
  /** column name */
  SensingDataSizeBytes = 'sensing_data_size_bytes',
  /** column name */
  TotalSizeBytes = 'total_size_bytes',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Set_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  raster_ims_id?: InputMaybe<Scalars['uuid']>;
  sensing_data_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Nb_Raster_Ims_Size_Stddev_Fields = {
  __typename?: 'nb_raster_ims_size_stddev_fields';
  sensing_data_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Stddev_Order_By = {
  sensing_data_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Raster_Ims_Size_Stddev_Pop_Fields = {
  __typename?: 'nb_raster_ims_size_stddev_pop_fields';
  sensing_data_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Stddev_Pop_Order_By = {
  sensing_data_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Raster_Ims_Size_Stddev_Samp_Fields = {
  __typename?: 'nb_raster_ims_size_stddev_samp_fields';
  sensing_data_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Stddev_Samp_Order_By = {
  sensing_data_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Raster_Ims_Size_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Raster_Ims_Size_Stream_Cursor_Value_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  raster_ims_id?: InputMaybe<Scalars['uuid']>;
  sensing_data_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nb_Raster_Ims_Size_Sum_Fields = {
  __typename?: 'nb_raster_ims_size_sum_fields';
  sensing_data_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Sum_Order_By = {
  sensing_data_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

export type Nb_Raster_Ims_Size_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Raster_Ims_Size_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Raster_Ims_Size_Set_Input>;
  where: Nb_Raster_Ims_Size_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Raster_Ims_Size_Var_Pop_Fields = {
  __typename?: 'nb_raster_ims_size_var_pop_fields';
  sensing_data_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Var_Pop_Order_By = {
  sensing_data_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Raster_Ims_Size_Var_Samp_Fields = {
  __typename?: 'nb_raster_ims_size_var_samp_fields';
  sensing_data_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Var_Samp_Order_By = {
  sensing_data_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Raster_Ims_Size_Variance_Fields = {
  __typename?: 'nb_raster_ims_size_variance_fields';
  sensing_data_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_raster_ims_size" */
export type Nb_Raster_Ims_Size_Variance_Order_By = {
  sensing_data_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map = {
  __typename?: 'nb_soil_chara_map';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_soil_characteristic: Nb_Soil_Characteristic;
  pin_coor: Scalars['geometry'];
  polygon: Scalars['geometry'];
  scale: Scalars['Float'];
  sel_key: Scalars['String'];
  soil_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Aggregate = {
  __typename?: 'nb_soil_chara_map_aggregate';
  aggregate?: Maybe<Nb_Soil_Chara_Map_Aggregate_Fields>;
  nodes: Array<Nb_Soil_Chara_Map>;
};

export type Nb_Soil_Chara_Map_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Soil_Chara_Map_Aggregate_Bool_Exp_Count>;
};

export type Nb_Soil_Chara_Map_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Soil_Chara_Map_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Chara_Map_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Aggregate_Fields = {
  __typename?: 'nb_soil_chara_map_aggregate_fields';
  avg?: Maybe<Nb_Soil_Chara_Map_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Soil_Chara_Map_Max_Fields>;
  min?: Maybe<Nb_Soil_Chara_Map_Min_Fields>;
  stddev?: Maybe<Nb_Soil_Chara_Map_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Soil_Chara_Map_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Soil_Chara_Map_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Soil_Chara_Map_Sum_Fields>;
  var_pop?: Maybe<Nb_Soil_Chara_Map_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Soil_Chara_Map_Var_Samp_Fields>;
  variance?: Maybe<Nb_Soil_Chara_Map_Variance_Fields>;
};


/** aggregate fields of "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Soil_Chara_Map_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Soil_Chara_Map_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Soil_Chara_Map_Max_Order_By>;
  min?: InputMaybe<Nb_Soil_Chara_Map_Min_Order_By>;
  stddev?: InputMaybe<Nb_Soil_Chara_Map_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Soil_Chara_Map_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Soil_Chara_Map_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Soil_Chara_Map_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Soil_Chara_Map_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Soil_Chara_Map_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Soil_Chara_Map_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Arr_Rel_Insert_Input = {
  data: Array<Nb_Soil_Chara_Map_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Chara_Map_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Soil_Chara_Map_Avg_Fields = {
  __typename?: 'nb_soil_chara_map_avg_fields';
  scale?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Avg_Order_By = {
  scale?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_soil_chara_map". All fields are combined with a logical 'AND'. */
export type Nb_Soil_Chara_Map_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Soil_Chara_Map_Bool_Exp>>;
  _not?: InputMaybe<Nb_Soil_Chara_Map_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Soil_Chara_Map_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_soil_characteristic?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
  pin_coor?: InputMaybe<Geometry_Comparison_Exp>;
  polygon?: InputMaybe<Geometry_Comparison_Exp>;
  scale?: InputMaybe<Float_Comparison_Exp>;
  sel_key?: InputMaybe<String_Comparison_Exp>;
  soil_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_soil_chara_map" */
export enum Nb_Soil_Chara_Map_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbSoilCharaMapPkey = 'nb_soil_chara_map_pkey'
}

/** input type for incrementing numeric columns in table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Inc_Input = {
  scale?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_soil_characteristic?: InputMaybe<Nb_Soil_Characteristic_Obj_Rel_Insert_Input>;
  pin_coor?: InputMaybe<Scalars['geometry']>;
  polygon?: InputMaybe<Scalars['geometry']>;
  scale?: InputMaybe<Scalars['Float']>;
  sel_key?: InputMaybe<Scalars['String']>;
  soil_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Soil_Chara_Map_Max_Fields = {
  __typename?: 'nb_soil_chara_map_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  scale?: Maybe<Scalars['Float']>;
  sel_key?: Maybe<Scalars['String']>;
  soil_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  scale?: InputMaybe<Order_By>;
  sel_key?: InputMaybe<Order_By>;
  soil_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Soil_Chara_Map_Min_Fields = {
  __typename?: 'nb_soil_chara_map_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  scale?: Maybe<Scalars['Float']>;
  sel_key?: Maybe<Scalars['String']>;
  soil_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  scale?: InputMaybe<Order_By>;
  sel_key?: InputMaybe<Order_By>;
  soil_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Mutation_Response = {
  __typename?: 'nb_soil_chara_map_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Soil_Chara_Map>;
};

/** on_conflict condition type for table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_On_Conflict = {
  constraint: Nb_Soil_Chara_Map_Constraint;
  update_columns?: Array<Nb_Soil_Chara_Map_Update_Column>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_soil_chara_map". */
export type Nb_Soil_Chara_Map_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_soil_characteristic?: InputMaybe<Nb_Soil_Characteristic_Order_By>;
  pin_coor?: InputMaybe<Order_By>;
  polygon?: InputMaybe<Order_By>;
  scale?: InputMaybe<Order_By>;
  sel_key?: InputMaybe<Order_By>;
  soil_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_soil_chara_map */
export type Nb_Soil_Chara_Map_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_soil_chara_map" */
export enum Nb_Soil_Chara_Map_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PinCoor = 'pin_coor',
  /** column name */
  Polygon = 'polygon',
  /** column name */
  Scale = 'scale',
  /** column name */
  SelKey = 'sel_key',
  /** column name */
  SoilId = 'soil_id',
  /** column name */
  Updated = 'updated'
}

/** columns and relationships of "nb_soil_chara_map_sellist" */
export type Nb_Soil_Chara_Map_Sellist = {
  __typename?: 'nb_soil_chara_map_sellist';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  sel_key: Scalars['String'];
  updated: Scalars['timestamptz'];
  zoom_level: Scalars['Int'];
};

/** aggregated selection of "nb_soil_chara_map_sellist" */
export type Nb_Soil_Chara_Map_Sellist_Aggregate = {
  __typename?: 'nb_soil_chara_map_sellist_aggregate';
  aggregate?: Maybe<Nb_Soil_Chara_Map_Sellist_Aggregate_Fields>;
  nodes: Array<Nb_Soil_Chara_Map_Sellist>;
};

/** aggregate fields of "nb_soil_chara_map_sellist" */
export type Nb_Soil_Chara_Map_Sellist_Aggregate_Fields = {
  __typename?: 'nb_soil_chara_map_sellist_aggregate_fields';
  avg?: Maybe<Nb_Soil_Chara_Map_Sellist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Soil_Chara_Map_Sellist_Max_Fields>;
  min?: Maybe<Nb_Soil_Chara_Map_Sellist_Min_Fields>;
  stddev?: Maybe<Nb_Soil_Chara_Map_Sellist_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Soil_Chara_Map_Sellist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Soil_Chara_Map_Sellist_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Soil_Chara_Map_Sellist_Sum_Fields>;
  var_pop?: Maybe<Nb_Soil_Chara_Map_Sellist_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Soil_Chara_Map_Sellist_Var_Samp_Fields>;
  variance?: Maybe<Nb_Soil_Chara_Map_Sellist_Variance_Fields>;
};


/** aggregate fields of "nb_soil_chara_map_sellist" */
export type Nb_Soil_Chara_Map_Sellist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Soil_Chara_Map_Sellist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Nb_Soil_Chara_Map_Sellist_Avg_Fields = {
  __typename?: 'nb_soil_chara_map_sellist_avg_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nb_soil_chara_map_sellist". All fields are combined with a logical 'AND'. */
export type Nb_Soil_Chara_Map_Sellist_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Soil_Chara_Map_Sellist_Bool_Exp>>;
  _not?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Soil_Chara_Map_Sellist_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sel_key?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoom_level?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_soil_chara_map_sellist" */
export enum Nb_Soil_Chara_Map_Sellist_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbSoilCharaMapSellistPkey = 'nb_soil_chara_map_sellist_pkey'
}

/** input type for incrementing numeric columns in table "nb_soil_chara_map_sellist" */
export type Nb_Soil_Chara_Map_Sellist_Inc_Input = {
  zoom_level?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nb_soil_chara_map_sellist" */
export type Nb_Soil_Chara_Map_Sellist_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sel_key?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  zoom_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Nb_Soil_Chara_Map_Sellist_Max_Fields = {
  __typename?: 'nb_soil_chara_map_sellist_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sel_key?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  zoom_level?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Nb_Soil_Chara_Map_Sellist_Min_Fields = {
  __typename?: 'nb_soil_chara_map_sellist_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sel_key?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  zoom_level?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "nb_soil_chara_map_sellist" */
export type Nb_Soil_Chara_Map_Sellist_Mutation_Response = {
  __typename?: 'nb_soil_chara_map_sellist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Soil_Chara_Map_Sellist>;
};

/** on_conflict condition type for table "nb_soil_chara_map_sellist" */
export type Nb_Soil_Chara_Map_Sellist_On_Conflict = {
  constraint: Nb_Soil_Chara_Map_Sellist_Constraint;
  update_columns?: Array<Nb_Soil_Chara_Map_Sellist_Update_Column>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_soil_chara_map_sellist". */
export type Nb_Soil_Chara_Map_Sellist_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sel_key?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  zoom_level?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_soil_chara_map_sellist */
export type Nb_Soil_Chara_Map_Sellist_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_soil_chara_map_sellist" */
export enum Nb_Soil_Chara_Map_Sellist_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  SelKey = 'sel_key',
  /** column name */
  Updated = 'updated',
  /** column name */
  ZoomLevel = 'zoom_level'
}

/** input type for updating data in table "nb_soil_chara_map_sellist" */
export type Nb_Soil_Chara_Map_Sellist_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sel_key?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  zoom_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Nb_Soil_Chara_Map_Sellist_Stddev_Fields = {
  __typename?: 'nb_soil_chara_map_sellist_stddev_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Nb_Soil_Chara_Map_Sellist_Stddev_Pop_Fields = {
  __typename?: 'nb_soil_chara_map_sellist_stddev_pop_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Nb_Soil_Chara_Map_Sellist_Stddev_Samp_Fields = {
  __typename?: 'nb_soil_chara_map_sellist_stddev_samp_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nb_soil_chara_map_sellist" */
export type Nb_Soil_Chara_Map_Sellist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Soil_Chara_Map_Sellist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Soil_Chara_Map_Sellist_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sel_key?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  zoom_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Nb_Soil_Chara_Map_Sellist_Sum_Fields = {
  __typename?: 'nb_soil_chara_map_sellist_sum_fields';
  zoom_level?: Maybe<Scalars['Int']>;
};

/** update columns of table "nb_soil_chara_map_sellist" */
export enum Nb_Soil_Chara_Map_Sellist_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  SelKey = 'sel_key',
  /** column name */
  Updated = 'updated',
  /** column name */
  ZoomLevel = 'zoom_level'
}

export type Nb_Soil_Chara_Map_Sellist_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Set_Input>;
  where: Nb_Soil_Chara_Map_Sellist_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Soil_Chara_Map_Sellist_Var_Pop_Fields = {
  __typename?: 'nb_soil_chara_map_sellist_var_pop_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Nb_Soil_Chara_Map_Sellist_Var_Samp_Fields = {
  __typename?: 'nb_soil_chara_map_sellist_var_samp_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Nb_Soil_Chara_Map_Sellist_Variance_Fields = {
  __typename?: 'nb_soil_chara_map_sellist_variance_fields';
  zoom_level?: Maybe<Scalars['Float']>;
};

/** input type for updating data in table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  pin_coor?: InputMaybe<Scalars['geometry']>;
  polygon?: InputMaybe<Scalars['geometry']>;
  scale?: InputMaybe<Scalars['Float']>;
  sel_key?: InputMaybe<Scalars['String']>;
  soil_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nb_Soil_Chara_Map_Stddev_Fields = {
  __typename?: 'nb_soil_chara_map_stddev_fields';
  scale?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Stddev_Order_By = {
  scale?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Soil_Chara_Map_Stddev_Pop_Fields = {
  __typename?: 'nb_soil_chara_map_stddev_pop_fields';
  scale?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Stddev_Pop_Order_By = {
  scale?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Soil_Chara_Map_Stddev_Samp_Fields = {
  __typename?: 'nb_soil_chara_map_stddev_samp_fields';
  scale?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Stddev_Samp_Order_By = {
  scale?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Soil_Chara_Map_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Soil_Chara_Map_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  pin_coor?: InputMaybe<Scalars['geometry']>;
  polygon?: InputMaybe<Scalars['geometry']>;
  scale?: InputMaybe<Scalars['Float']>;
  sel_key?: InputMaybe<Scalars['String']>;
  soil_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Soil_Chara_Map_Sum_Fields = {
  __typename?: 'nb_soil_chara_map_sum_fields';
  scale?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Sum_Order_By = {
  scale?: InputMaybe<Order_By>;
};

/** update columns of table "nb_soil_chara_map" */
export enum Nb_Soil_Chara_Map_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PinCoor = 'pin_coor',
  /** column name */
  Polygon = 'polygon',
  /** column name */
  Scale = 'scale',
  /** column name */
  SelKey = 'sel_key',
  /** column name */
  SoilId = 'soil_id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Soil_Chara_Map_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Soil_Chara_Map_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Soil_Chara_Map_Set_Input>;
  where: Nb_Soil_Chara_Map_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Soil_Chara_Map_Var_Pop_Fields = {
  __typename?: 'nb_soil_chara_map_var_pop_fields';
  scale?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Var_Pop_Order_By = {
  scale?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Soil_Chara_Map_Var_Samp_Fields = {
  __typename?: 'nb_soil_chara_map_var_samp_fields';
  scale?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Var_Samp_Order_By = {
  scale?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Soil_Chara_Map_Variance_Fields = {
  __typename?: 'nb_soil_chara_map_variance_fields';
  scale?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_soil_chara_map" */
export type Nb_Soil_Chara_Map_Variance_Order_By = {
  scale?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_soil_characteristic" */
export type Nb_Soil_Characteristic = {
  __typename?: 'nb_soil_characteristic';
  color_code: Scalars['String'];
  created: Scalars['timestamptz'];
  detail_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  large_code?: Maybe<Scalars['String']>;
  middle_code?: Maybe<Scalars['String']>;
  /** An array relationship */
  nb_maff_fields: Array<Nb_Maff_Field>;
  /** An aggregate relationship */
  nb_maff_fields_aggregate: Nb_Maff_Field_Aggregate;
  /** An array relationship */
  nb_soil_chara_maps: Array<Nb_Soil_Chara_Map>;
  /** An aggregate relationship */
  nb_soil_chara_maps_aggregate: Nb_Soil_Chara_Map_Aggregate;
  /** An object relationship */
  nb_soil_explanation?: Maybe<Nb_Soil_Explanation>;
  small_code?: Maybe<Scalars['String']>;
  small_name?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_soil_characteristic" */
export type Nb_Soil_CharacteristicNb_Maff_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
};


/** columns and relationships of "nb_soil_characteristic" */
export type Nb_Soil_CharacteristicNb_Maff_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
};


/** columns and relationships of "nb_soil_characteristic" */
export type Nb_Soil_CharacteristicNb_Soil_Chara_MapsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chara_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chara_Map_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Bool_Exp>;
};


/** columns and relationships of "nb_soil_characteristic" */
export type Nb_Soil_CharacteristicNb_Soil_Chara_Maps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chara_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chara_Map_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Bool_Exp>;
};

/** aggregated selection of "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Aggregate = {
  __typename?: 'nb_soil_characteristic_aggregate';
  aggregate?: Maybe<Nb_Soil_Characteristic_Aggregate_Fields>;
  nodes: Array<Nb_Soil_Characteristic>;
};

export type Nb_Soil_Characteristic_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Soil_Characteristic_Aggregate_Bool_Exp_Count>;
};

export type Nb_Soil_Characteristic_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Soil_Characteristic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Aggregate_Fields = {
  __typename?: 'nb_soil_characteristic_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Soil_Characteristic_Max_Fields>;
  min?: Maybe<Nb_Soil_Characteristic_Min_Fields>;
};


/** aggregate fields of "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Soil_Characteristic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Soil_Characteristic_Max_Order_By>;
  min?: InputMaybe<Nb_Soil_Characteristic_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Arr_Rel_Insert_Input = {
  data: Array<Nb_Soil_Characteristic_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Characteristic_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_soil_characteristic". All fields are combined with a logical 'AND'. */
export type Nb_Soil_Characteristic_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Soil_Characteristic_Bool_Exp>>;
  _not?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Soil_Characteristic_Bool_Exp>>;
  color_code?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  detail_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  large_code?: InputMaybe<String_Comparison_Exp>;
  middle_code?: InputMaybe<String_Comparison_Exp>;
  nb_maff_fields?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
  nb_maff_fields_aggregate?: InputMaybe<Nb_Maff_Field_Aggregate_Bool_Exp>;
  nb_soil_chara_maps?: InputMaybe<Nb_Soil_Chara_Map_Bool_Exp>;
  nb_soil_chara_maps_aggregate?: InputMaybe<Nb_Soil_Chara_Map_Aggregate_Bool_Exp>;
  nb_soil_explanation?: InputMaybe<Nb_Soil_Explanation_Bool_Exp>;
  small_code?: InputMaybe<String_Comparison_Exp>;
  small_name?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_soil_characteristic" */
export enum Nb_Soil_Characteristic_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbSoilCharacteristicPkey = 'nb_soil_characteristic_pkey',
  /** unique or primary key constraint on columns "small_code" */
  NbSoilCharacteristicSmallCodeKey = 'nb_soil_characteristic_small_code_key'
}

/** input type for inserting data into table "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Insert_Input = {
  color_code?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  large_code?: InputMaybe<Scalars['String']>;
  middle_code?: InputMaybe<Scalars['String']>;
  nb_maff_fields?: InputMaybe<Nb_Maff_Field_Arr_Rel_Insert_Input>;
  nb_soil_chara_maps?: InputMaybe<Nb_Soil_Chara_Map_Arr_Rel_Insert_Input>;
  nb_soil_explanation?: InputMaybe<Nb_Soil_Explanation_Obj_Rel_Insert_Input>;
  small_code?: InputMaybe<Scalars['String']>;
  small_name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Soil_Characteristic_Max_Fields = {
  __typename?: 'nb_soil_characteristic_max_fields';
  color_code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  detail_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  large_code?: Maybe<Scalars['String']>;
  middle_code?: Maybe<Scalars['String']>;
  small_code?: Maybe<Scalars['String']>;
  small_name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Max_Order_By = {
  color_code?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  detail_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  large_code?: InputMaybe<Order_By>;
  middle_code?: InputMaybe<Order_By>;
  small_code?: InputMaybe<Order_By>;
  small_name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Soil_Characteristic_Min_Fields = {
  __typename?: 'nb_soil_characteristic_min_fields';
  color_code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  detail_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  large_code?: Maybe<Scalars['String']>;
  middle_code?: Maybe<Scalars['String']>;
  small_code?: Maybe<Scalars['String']>;
  small_name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Min_Order_By = {
  color_code?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  detail_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  large_code?: InputMaybe<Order_By>;
  middle_code?: InputMaybe<Order_By>;
  small_code?: InputMaybe<Order_By>;
  small_name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Mutation_Response = {
  __typename?: 'nb_soil_characteristic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Soil_Characteristic>;
};

/** input type for inserting object relation for remote table "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Obj_Rel_Insert_Input = {
  data: Nb_Soil_Characteristic_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Characteristic_On_Conflict>;
};

/** on_conflict condition type for table "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_On_Conflict = {
  constraint: Nb_Soil_Characteristic_Constraint;
  update_columns?: Array<Nb_Soil_Characteristic_Update_Column>;
  where?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_soil_characteristic". */
export type Nb_Soil_Characteristic_Order_By = {
  color_code?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  detail_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  large_code?: InputMaybe<Order_By>;
  middle_code?: InputMaybe<Order_By>;
  nb_maff_fields_aggregate?: InputMaybe<Nb_Maff_Field_Aggregate_Order_By>;
  nb_soil_chara_maps_aggregate?: InputMaybe<Nb_Soil_Chara_Map_Aggregate_Order_By>;
  nb_soil_explanation?: InputMaybe<Nb_Soil_Explanation_Order_By>;
  small_code?: InputMaybe<Order_By>;
  small_name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_soil_characteristic */
export type Nb_Soil_Characteristic_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_soil_characteristic" */
export enum Nb_Soil_Characteristic_Select_Column {
  /** column name */
  ColorCode = 'color_code',
  /** column name */
  Created = 'created',
  /** column name */
  DetailId = 'detail_id',
  /** column name */
  Id = 'id',
  /** column name */
  LargeCode = 'large_code',
  /** column name */
  MiddleCode = 'middle_code',
  /** column name */
  SmallCode = 'small_code',
  /** column name */
  SmallName = 'small_name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Set_Input = {
  color_code?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  large_code?: InputMaybe<Scalars['String']>;
  middle_code?: InputMaybe<Scalars['String']>;
  small_code?: InputMaybe<Scalars['String']>;
  small_name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_soil_characteristic" */
export type Nb_Soil_Characteristic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Soil_Characteristic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Soil_Characteristic_Stream_Cursor_Value_Input = {
  color_code?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  large_code?: InputMaybe<Scalars['String']>;
  middle_code?: InputMaybe<Scalars['String']>;
  small_code?: InputMaybe<Scalars['String']>;
  small_name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_soil_characteristic" */
export enum Nb_Soil_Characteristic_Update_Column {
  /** column name */
  ColorCode = 'color_code',
  /** column name */
  Created = 'created',
  /** column name */
  DetailId = 'detail_id',
  /** column name */
  Id = 'id',
  /** column name */
  LargeCode = 'large_code',
  /** column name */
  MiddleCode = 'middle_code',
  /** column name */
  SmallCode = 'small_code',
  /** column name */
  SmallName = 'small_name',
  /** column name */
  Updated = 'updated'
}

export type Nb_Soil_Characteristic_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Soil_Characteristic_Set_Input>;
  where: Nb_Soil_Characteristic_Bool_Exp;
};

/** columns and relationships of "nb_soil_chemistry" */
export type Nb_Soil_Chemistry = {
  __typename?: 'nb_soil_chemistry';
  CEC?: Maybe<Scalars['Float']>;
  CaO?: Maybe<Scalars['Float']>;
  EC?: Maybe<Scalars['Float']>;
  ammonia_nitrogen?: Maybe<Scalars['Float']>;
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  available_nitrogen?: Maybe<Scalars['Float']>;
  boric_acid?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  created: Scalars['timestamptz'];
  creation_date?: Maybe<Scalars['date']>;
  creator_name: Scalars['String'];
  degree_of_base_saturation?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: Maybe<Scalars['Float']>;
  humus?: Maybe<Scalars['Float']>;
  id: Scalars['uuid'];
  kalium?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio?: Maybe<Scalars['Float']>;
  magnesia?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio?: Maybe<Scalars['Float']>;
  manganese?: Maybe<Scalars['Float']>;
  /** An object relationship */
  nb_soil_chemistry_standard?: Maybe<Nb_Soil_Chemistry_Standards>;
  /** An object relationship */
  nb_soil_chemistry_suitable_range?: Maybe<Nb_Soil_Chemistry_Suitable_Range>;
  /** An array relationship */
  nb_soil_chemistry_values: Array<Nb_Soil_Chemistry_Value>;
  /** An aggregate relationship */
  nb_soil_chemistry_values_aggregate: Nb_Soil_Chemistry_Value_Aggregate;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  nitrate_nitrogen?: Maybe<Scalars['Float']>;
  pH?: Maybe<Scalars['Float']>;
  pH_KCl?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff?: Maybe<Scalars['Float']>;
  phosphoric_acid?: Maybe<Scalars['Float']>;
  reception_date?: Maybe<Scalars['date']>;
  sampling_date: Scalars['timestamptz'];
  silicic_acid?: Maybe<Scalars['Float']>;
  soil_chemistry_standard_id?: Maybe<Scalars['uuid']>;
  soil_diagnosis?: Maybe<Scalars['String']>;
  suitable_range_id?: Maybe<Scalars['uuid']>;
  updated: Scalars['timestamptz'];
  user_field_id: Scalars['uuid'];
  user_id?: Maybe<Scalars['uuid']>;
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nb_soil_chemistry" */
export type Nb_Soil_ChemistryNb_Soil_Chemistry_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
};


/** columns and relationships of "nb_soil_chemistry" */
export type Nb_Soil_ChemistryNb_Soil_Chemistry_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
};

/** aggregated selection of "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Aggregate = {
  __typename?: 'nb_soil_chemistry_aggregate';
  aggregate?: Maybe<Nb_Soil_Chemistry_Aggregate_Fields>;
  nodes: Array<Nb_Soil_Chemistry>;
};

export type Nb_Soil_Chemistry_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Soil_Chemistry_Aggregate_Bool_Exp_Count>;
};

export type Nb_Soil_Chemistry_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Aggregate_Fields = {
  __typename?: 'nb_soil_chemistry_aggregate_fields';
  avg?: Maybe<Nb_Soil_Chemistry_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Soil_Chemistry_Max_Fields>;
  min?: Maybe<Nb_Soil_Chemistry_Min_Fields>;
  stddev?: Maybe<Nb_Soil_Chemistry_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Soil_Chemistry_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Soil_Chemistry_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Soil_Chemistry_Sum_Fields>;
  var_pop?: Maybe<Nb_Soil_Chemistry_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Soil_Chemistry_Var_Samp_Fields>;
  variance?: Maybe<Nb_Soil_Chemistry_Variance_Fields>;
};


/** aggregate fields of "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Soil_Chemistry_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Soil_Chemistry_Max_Order_By>;
  min?: InputMaybe<Nb_Soil_Chemistry_Min_Order_By>;
  stddev?: InputMaybe<Nb_Soil_Chemistry_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Soil_Chemistry_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Soil_Chemistry_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Soil_Chemistry_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Soil_Chemistry_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Soil_Chemistry_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Soil_Chemistry_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Arr_Rel_Insert_Input = {
  data: Array<Nb_Soil_Chemistry_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Soil_Chemistry_Avg_Fields = {
  __typename?: 'nb_soil_chemistry_avg_fields';
  CEC?: Maybe<Scalars['Float']>;
  CaO?: Maybe<Scalars['Float']>;
  EC?: Maybe<Scalars['Float']>;
  ammonia_nitrogen?: Maybe<Scalars['Float']>;
  available_nitrogen?: Maybe<Scalars['Float']>;
  boric_acid?: Maybe<Scalars['Float']>;
  degree_of_base_saturation?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: Maybe<Scalars['Float']>;
  humus?: Maybe<Scalars['Float']>;
  kalium?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio?: Maybe<Scalars['Float']>;
  magnesia?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio?: Maybe<Scalars['Float']>;
  manganese?: Maybe<Scalars['Float']>;
  nitrate_nitrogen?: Maybe<Scalars['Float']>;
  pH?: Maybe<Scalars['Float']>;
  pH_KCl?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff?: Maybe<Scalars['Float']>;
  phosphoric_acid?: Maybe<Scalars['Float']>;
  silicic_acid?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Avg_Order_By = {
  CEC?: InputMaybe<Order_By>;
  CaO?: InputMaybe<Order_By>;
  EC?: InputMaybe<Order_By>;
  ammonia_nitrogen?: InputMaybe<Order_By>;
  available_nitrogen?: InputMaybe<Order_By>;
  boric_acid?: InputMaybe<Order_By>;
  degree_of_base_saturation?: InputMaybe<Order_By>;
  degree_of_kalium_saturation?: InputMaybe<Order_By>;
  degree_of_lime_saturation?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation?: InputMaybe<Order_By>;
  humus?: InputMaybe<Order_By>;
  kalium?: InputMaybe<Order_By>;
  lime_magnesium_ratio?: InputMaybe<Order_By>;
  magnesia?: InputMaybe<Order_By>;
  magnesium_potassium_ratio?: InputMaybe<Order_By>;
  manganese?: InputMaybe<Order_By>;
  nitrate_nitrogen?: InputMaybe<Order_By>;
  pH?: InputMaybe<Order_By>;
  pH_KCl?: InputMaybe<Order_By>;
  phosphate_absorption_coeff?: InputMaybe<Order_By>;
  phosphoric_acid?: InputMaybe<Order_By>;
  silicic_acid?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_soil_chemistry". All fields are combined with a logical 'AND'. */
export type Nb_Soil_Chemistry_Bool_Exp = {
  CEC?: InputMaybe<Float_Comparison_Exp>;
  CaO?: InputMaybe<Float_Comparison_Exp>;
  EC?: InputMaybe<Float_Comparison_Exp>;
  _and?: InputMaybe<Array<Nb_Soil_Chemistry_Bool_Exp>>;
  _not?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Soil_Chemistry_Bool_Exp>>;
  ammonia_nitrogen?: InputMaybe<Float_Comparison_Exp>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  available_nitrogen?: InputMaybe<Float_Comparison_Exp>;
  boric_acid?: InputMaybe<Float_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  creation_date?: InputMaybe<Date_Comparison_Exp>;
  creator_name?: InputMaybe<String_Comparison_Exp>;
  degree_of_base_saturation?: InputMaybe<Float_Comparison_Exp>;
  degree_of_kalium_saturation?: InputMaybe<Float_Comparison_Exp>;
  degree_of_lime_saturation?: InputMaybe<Float_Comparison_Exp>;
  degree_of_magnesia_saturation?: InputMaybe<Float_Comparison_Exp>;
  humus?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kalium?: InputMaybe<Float_Comparison_Exp>;
  lime_magnesium_ratio?: InputMaybe<Float_Comparison_Exp>;
  magnesia?: InputMaybe<Float_Comparison_Exp>;
  magnesium_potassium_ratio?: InputMaybe<Float_Comparison_Exp>;
  manganese?: InputMaybe<Float_Comparison_Exp>;
  nb_soil_chemistry_standard?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
  nb_soil_chemistry_suitable_range?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Bool_Exp>;
  nb_soil_chemistry_values?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
  nb_soil_chemistry_values_aggregate?: InputMaybe<Nb_Soil_Chemistry_Value_Aggregate_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nitrate_nitrogen?: InputMaybe<Float_Comparison_Exp>;
  pH?: InputMaybe<Float_Comparison_Exp>;
  pH_KCl?: InputMaybe<Float_Comparison_Exp>;
  phosphate_absorption_coeff?: InputMaybe<Float_Comparison_Exp>;
  phosphoric_acid?: InputMaybe<Float_Comparison_Exp>;
  reception_date?: InputMaybe<Date_Comparison_Exp>;
  sampling_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  silicic_acid?: InputMaybe<Float_Comparison_Exp>;
  soil_chemistry_standard_id?: InputMaybe<Uuid_Comparison_Exp>;
  soil_diagnosis?: InputMaybe<String_Comparison_Exp>;
  suitable_range_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_soil_chemistry" */
export enum Nb_Soil_Chemistry_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbSoilChemistryPkey = 'nb_soil_chemistry_pkey'
}

/** input type for incrementing numeric columns in table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Inc_Input = {
  CEC?: InputMaybe<Scalars['Float']>;
  CaO?: InputMaybe<Scalars['Float']>;
  EC?: InputMaybe<Scalars['Float']>;
  ammonia_nitrogen?: InputMaybe<Scalars['Float']>;
  available_nitrogen?: InputMaybe<Scalars['Float']>;
  boric_acid?: InputMaybe<Scalars['Float']>;
  degree_of_base_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: InputMaybe<Scalars['Float']>;
  humus?: InputMaybe<Scalars['Float']>;
  kalium?: InputMaybe<Scalars['Float']>;
  lime_magnesium_ratio?: InputMaybe<Scalars['Float']>;
  magnesia?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio?: InputMaybe<Scalars['Float']>;
  manganese?: InputMaybe<Scalars['Float']>;
  nitrate_nitrogen?: InputMaybe<Scalars['Float']>;
  pH?: InputMaybe<Scalars['Float']>;
  pH_KCl?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff?: InputMaybe<Scalars['Float']>;
  phosphoric_acid?: InputMaybe<Scalars['Float']>;
  silicic_acid?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Insert_Input = {
  CEC?: InputMaybe<Scalars['Float']>;
  CaO?: InputMaybe<Scalars['Float']>;
  EC?: InputMaybe<Scalars['Float']>;
  ammonia_nitrogen?: InputMaybe<Scalars['Float']>;
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  available_nitrogen?: InputMaybe<Scalars['Float']>;
  boric_acid?: InputMaybe<Scalars['Float']>;
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creation_date?: InputMaybe<Scalars['date']>;
  creator_name?: InputMaybe<Scalars['String']>;
  degree_of_base_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: InputMaybe<Scalars['Float']>;
  humus?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  kalium?: InputMaybe<Scalars['Float']>;
  lime_magnesium_ratio?: InputMaybe<Scalars['Float']>;
  magnesia?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio?: InputMaybe<Scalars['Float']>;
  manganese?: InputMaybe<Scalars['Float']>;
  nb_soil_chemistry_standard?: InputMaybe<Nb_Soil_Chemistry_Standards_Obj_Rel_Insert_Input>;
  nb_soil_chemistry_suitable_range?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Obj_Rel_Insert_Input>;
  nb_soil_chemistry_values?: InputMaybe<Nb_Soil_Chemistry_Value_Arr_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  nitrate_nitrogen?: InputMaybe<Scalars['Float']>;
  pH?: InputMaybe<Scalars['Float']>;
  pH_KCl?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff?: InputMaybe<Scalars['Float']>;
  phosphoric_acid?: InputMaybe<Scalars['Float']>;
  reception_date?: InputMaybe<Scalars['date']>;
  sampling_date?: InputMaybe<Scalars['timestamptz']>;
  silicic_acid?: InputMaybe<Scalars['Float']>;
  soil_chemistry_standard_id?: InputMaybe<Scalars['uuid']>;
  soil_diagnosis?: InputMaybe<Scalars['String']>;
  suitable_range_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Soil_Chemistry_Max_Fields = {
  __typename?: 'nb_soil_chemistry_max_fields';
  CEC?: Maybe<Scalars['Float']>;
  CaO?: Maybe<Scalars['Float']>;
  EC?: Maybe<Scalars['Float']>;
  ammonia_nitrogen?: Maybe<Scalars['Float']>;
  available_nitrogen?: Maybe<Scalars['Float']>;
  boric_acid?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  creation_date?: Maybe<Scalars['date']>;
  creator_name?: Maybe<Scalars['String']>;
  degree_of_base_saturation?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: Maybe<Scalars['Float']>;
  humus?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  kalium?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio?: Maybe<Scalars['Float']>;
  magnesia?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio?: Maybe<Scalars['Float']>;
  manganese?: Maybe<Scalars['Float']>;
  nitrate_nitrogen?: Maybe<Scalars['Float']>;
  pH?: Maybe<Scalars['Float']>;
  pH_KCl?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff?: Maybe<Scalars['Float']>;
  phosphoric_acid?: Maybe<Scalars['Float']>;
  reception_date?: Maybe<Scalars['date']>;
  sampling_date?: Maybe<Scalars['timestamptz']>;
  silicic_acid?: Maybe<Scalars['Float']>;
  soil_chemistry_standard_id?: Maybe<Scalars['uuid']>;
  soil_diagnosis?: Maybe<Scalars['String']>;
  suitable_range_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Max_Order_By = {
  CEC?: InputMaybe<Order_By>;
  CaO?: InputMaybe<Order_By>;
  EC?: InputMaybe<Order_By>;
  ammonia_nitrogen?: InputMaybe<Order_By>;
  available_nitrogen?: InputMaybe<Order_By>;
  boric_acid?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  creation_date?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  degree_of_base_saturation?: InputMaybe<Order_By>;
  degree_of_kalium_saturation?: InputMaybe<Order_By>;
  degree_of_lime_saturation?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation?: InputMaybe<Order_By>;
  humus?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kalium?: InputMaybe<Order_By>;
  lime_magnesium_ratio?: InputMaybe<Order_By>;
  magnesia?: InputMaybe<Order_By>;
  magnesium_potassium_ratio?: InputMaybe<Order_By>;
  manganese?: InputMaybe<Order_By>;
  nitrate_nitrogen?: InputMaybe<Order_By>;
  pH?: InputMaybe<Order_By>;
  pH_KCl?: InputMaybe<Order_By>;
  phosphate_absorption_coeff?: InputMaybe<Order_By>;
  phosphoric_acid?: InputMaybe<Order_By>;
  reception_date?: InputMaybe<Order_By>;
  sampling_date?: InputMaybe<Order_By>;
  silicic_acid?: InputMaybe<Order_By>;
  soil_chemistry_standard_id?: InputMaybe<Order_By>;
  soil_diagnosis?: InputMaybe<Order_By>;
  suitable_range_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Soil_Chemistry_Min_Fields = {
  __typename?: 'nb_soil_chemistry_min_fields';
  CEC?: Maybe<Scalars['Float']>;
  CaO?: Maybe<Scalars['Float']>;
  EC?: Maybe<Scalars['Float']>;
  ammonia_nitrogen?: Maybe<Scalars['Float']>;
  available_nitrogen?: Maybe<Scalars['Float']>;
  boric_acid?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  creation_date?: Maybe<Scalars['date']>;
  creator_name?: Maybe<Scalars['String']>;
  degree_of_base_saturation?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: Maybe<Scalars['Float']>;
  humus?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  kalium?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio?: Maybe<Scalars['Float']>;
  magnesia?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio?: Maybe<Scalars['Float']>;
  manganese?: Maybe<Scalars['Float']>;
  nitrate_nitrogen?: Maybe<Scalars['Float']>;
  pH?: Maybe<Scalars['Float']>;
  pH_KCl?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff?: Maybe<Scalars['Float']>;
  phosphoric_acid?: Maybe<Scalars['Float']>;
  reception_date?: Maybe<Scalars['date']>;
  sampling_date?: Maybe<Scalars['timestamptz']>;
  silicic_acid?: Maybe<Scalars['Float']>;
  soil_chemistry_standard_id?: Maybe<Scalars['uuid']>;
  soil_diagnosis?: Maybe<Scalars['String']>;
  suitable_range_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Min_Order_By = {
  CEC?: InputMaybe<Order_By>;
  CaO?: InputMaybe<Order_By>;
  EC?: InputMaybe<Order_By>;
  ammonia_nitrogen?: InputMaybe<Order_By>;
  available_nitrogen?: InputMaybe<Order_By>;
  boric_acid?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  creation_date?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  degree_of_base_saturation?: InputMaybe<Order_By>;
  degree_of_kalium_saturation?: InputMaybe<Order_By>;
  degree_of_lime_saturation?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation?: InputMaybe<Order_By>;
  humus?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kalium?: InputMaybe<Order_By>;
  lime_magnesium_ratio?: InputMaybe<Order_By>;
  magnesia?: InputMaybe<Order_By>;
  magnesium_potassium_ratio?: InputMaybe<Order_By>;
  manganese?: InputMaybe<Order_By>;
  nitrate_nitrogen?: InputMaybe<Order_By>;
  pH?: InputMaybe<Order_By>;
  pH_KCl?: InputMaybe<Order_By>;
  phosphate_absorption_coeff?: InputMaybe<Order_By>;
  phosphoric_acid?: InputMaybe<Order_By>;
  reception_date?: InputMaybe<Order_By>;
  sampling_date?: InputMaybe<Order_By>;
  silicic_acid?: InputMaybe<Order_By>;
  soil_chemistry_standard_id?: InputMaybe<Order_By>;
  soil_diagnosis?: InputMaybe<Order_By>;
  suitable_range_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Mutation_Response = {
  __typename?: 'nb_soil_chemistry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Soil_Chemistry>;
};

/** input type for inserting object relation for remote table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Obj_Rel_Insert_Input = {
  data: Nb_Soil_Chemistry_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_On_Conflict>;
};

/** on_conflict condition type for table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_On_Conflict = {
  constraint: Nb_Soil_Chemistry_Constraint;
  update_columns?: Array<Nb_Soil_Chemistry_Update_Column>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_soil_chemistry". */
export type Nb_Soil_Chemistry_Order_By = {
  CEC?: InputMaybe<Order_By>;
  CaO?: InputMaybe<Order_By>;
  EC?: InputMaybe<Order_By>;
  ammonia_nitrogen?: InputMaybe<Order_By>;
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  available_nitrogen?: InputMaybe<Order_By>;
  boric_acid?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  creation_date?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  degree_of_base_saturation?: InputMaybe<Order_By>;
  degree_of_kalium_saturation?: InputMaybe<Order_By>;
  degree_of_lime_saturation?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation?: InputMaybe<Order_By>;
  humus?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kalium?: InputMaybe<Order_By>;
  lime_magnesium_ratio?: InputMaybe<Order_By>;
  magnesia?: InputMaybe<Order_By>;
  magnesium_potassium_ratio?: InputMaybe<Order_By>;
  manganese?: InputMaybe<Order_By>;
  nb_soil_chemistry_standard?: InputMaybe<Nb_Soil_Chemistry_Standards_Order_By>;
  nb_soil_chemistry_suitable_range?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Order_By>;
  nb_soil_chemistry_values_aggregate?: InputMaybe<Nb_Soil_Chemistry_Value_Aggregate_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nitrate_nitrogen?: InputMaybe<Order_By>;
  pH?: InputMaybe<Order_By>;
  pH_KCl?: InputMaybe<Order_By>;
  phosphate_absorption_coeff?: InputMaybe<Order_By>;
  phosphoric_acid?: InputMaybe<Order_By>;
  reception_date?: InputMaybe<Order_By>;
  sampling_date?: InputMaybe<Order_By>;
  silicic_acid?: InputMaybe<Order_By>;
  soil_chemistry_standard_id?: InputMaybe<Order_By>;
  soil_diagnosis?: InputMaybe<Order_By>;
  suitable_range_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_soil_chemistry */
export type Nb_Soil_Chemistry_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** 土壌化学成分適正範囲 */
export type Nb_Soil_Chemistry_Proper_Range = {
  __typename?: 'nb_soil_chemistry_proper_range';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  lower: Scalars['Float'];
  name: Scalars['String'];
  /** An object relationship */
  nb_soil_chemistry_standard: Nb_Soil_Chemistry_Standards;
  /** An array relationship */
  nb_soil_chemistry_values: Array<Nb_Soil_Chemistry_Value>;
  /** An aggregate relationship */
  nb_soil_chemistry_values_aggregate: Nb_Soil_Chemistry_Value_Aggregate;
  order: Scalars['Int'];
  soil_chemistry_standard_id: Scalars['uuid'];
  unit?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
  upper: Scalars['Float'];
};


/** 土壌化学成分適正範囲 */
export type Nb_Soil_Chemistry_Proper_RangeNb_Soil_Chemistry_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
};


/** 土壌化学成分適正範囲 */
export type Nb_Soil_Chemistry_Proper_RangeNb_Soil_Chemistry_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
};

/** aggregated selection of "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Aggregate = {
  __typename?: 'nb_soil_chemistry_proper_range_aggregate';
  aggregate?: Maybe<Nb_Soil_Chemistry_Proper_Range_Aggregate_Fields>;
  nodes: Array<Nb_Soil_Chemistry_Proper_Range>;
};

export type Nb_Soil_Chemistry_Proper_Range_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Aggregate_Bool_Exp_Count>;
};

export type Nb_Soil_Chemistry_Proper_Range_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Aggregate_Fields = {
  __typename?: 'nb_soil_chemistry_proper_range_aggregate_fields';
  avg?: Maybe<Nb_Soil_Chemistry_Proper_Range_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Soil_Chemistry_Proper_Range_Max_Fields>;
  min?: Maybe<Nb_Soil_Chemistry_Proper_Range_Min_Fields>;
  stddev?: Maybe<Nb_Soil_Chemistry_Proper_Range_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Soil_Chemistry_Proper_Range_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Soil_Chemistry_Proper_Range_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Soil_Chemistry_Proper_Range_Sum_Fields>;
  var_pop?: Maybe<Nb_Soil_Chemistry_Proper_Range_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Soil_Chemistry_Proper_Range_Var_Samp_Fields>;
  variance?: Maybe<Nb_Soil_Chemistry_Proper_Range_Variance_Fields>;
};


/** aggregate fields of "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Max_Order_By>;
  min?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Min_Order_By>;
  stddev?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Arr_Rel_Insert_Input = {
  data: Array<Nb_Soil_Chemistry_Proper_Range_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Soil_Chemistry_Proper_Range_Avg_Fields = {
  __typename?: 'nb_soil_chemistry_proper_range_avg_fields';
  lower?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Avg_Order_By = {
  lower?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  upper?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_soil_chemistry_proper_range". All fields are combined with a logical 'AND'. */
export type Nb_Soil_Chemistry_Proper_Range_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>>;
  _not?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  lower?: InputMaybe<Float_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_soil_chemistry_standard?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
  nb_soil_chemistry_values?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
  nb_soil_chemistry_values_aggregate?: InputMaybe<Nb_Soil_Chemistry_Value_Aggregate_Bool_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  soil_chemistry_standard_id?: InputMaybe<Uuid_Comparison_Exp>;
  unit?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  upper?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_soil_chemistry_proper_range" */
export enum Nb_Soil_Chemistry_Proper_Range_Constraint {
  /** unique or primary key constraint on columns "key", "soil_chemistry_standard_id" */
  NbSoilChemistryProperRangKeySoilChemistryStandardIKey = 'nb_soil_chemistry_proper_rang_key_soil_chemistry_standard_i_key',
  /** unique or primary key constraint on columns "name", "soil_chemistry_standard_id" */
  NbSoilChemistryProperRangNameSoilChemistryStandardKey = 'nb_soil_chemistry_proper_rang_name_soil_chemistry_standard__key',
  /** unique or primary key constraint on columns "soil_chemistry_standard_id", "order" */
  NbSoilChemistryProperRangOrderSoilChemistryStandardKey = 'nb_soil_chemistry_proper_rang_order_soil_chemistry_standard_key',
  /** unique or primary key constraint on columns "id" */
  NbSoilChemistryProperRangePkey = 'nb_soil_chemistry_proper_range_pkey'
}

/** input type for incrementing numeric columns in table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Inc_Input = {
  lower?: InputMaybe<Scalars['Float']>;
  order?: InputMaybe<Scalars['Int']>;
  upper?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  lower?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  nb_soil_chemistry_standard?: InputMaybe<Nb_Soil_Chemistry_Standards_Obj_Rel_Insert_Input>;
  nb_soil_chemistry_values?: InputMaybe<Nb_Soil_Chemistry_Value_Arr_Rel_Insert_Input>;
  order?: InputMaybe<Scalars['Int']>;
  soil_chemistry_standard_id?: InputMaybe<Scalars['uuid']>;
  unit?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  upper?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Nb_Soil_Chemistry_Proper_Range_Max_Fields = {
  __typename?: 'nb_soil_chemistry_proper_range_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  lower?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  soil_chemistry_standard_id?: Maybe<Scalars['uuid']>;
  unit?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  upper?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  lower?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  soil_chemistry_standard_id?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upper?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Soil_Chemistry_Proper_Range_Min_Fields = {
  __typename?: 'nb_soil_chemistry_proper_range_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  lower?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  soil_chemistry_standard_id?: Maybe<Scalars['uuid']>;
  unit?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  upper?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  lower?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  soil_chemistry_standard_id?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upper?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Mutation_Response = {
  __typename?: 'nb_soil_chemistry_proper_range_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Soil_Chemistry_Proper_Range>;
};

/** input type for inserting object relation for remote table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Obj_Rel_Insert_Input = {
  data: Nb_Soil_Chemistry_Proper_Range_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_On_Conflict>;
};

/** on_conflict condition type for table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_On_Conflict = {
  constraint: Nb_Soil_Chemistry_Proper_Range_Constraint;
  update_columns?: Array<Nb_Soil_Chemistry_Proper_Range_Update_Column>;
  where?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_soil_chemistry_proper_range". */
export type Nb_Soil_Chemistry_Proper_Range_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  lower?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_soil_chemistry_standard?: InputMaybe<Nb_Soil_Chemistry_Standards_Order_By>;
  nb_soil_chemistry_values_aggregate?: InputMaybe<Nb_Soil_Chemistry_Value_Aggregate_Order_By>;
  order?: InputMaybe<Order_By>;
  soil_chemistry_standard_id?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upper?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_soil_chemistry_proper_range */
export type Nb_Soil_Chemistry_Proper_Range_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_soil_chemistry_proper_range" */
export enum Nb_Soil_Chemistry_Proper_Range_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Lower = 'lower',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  SoilChemistryStandardId = 'soil_chemistry_standard_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  Updated = 'updated',
  /** column name */
  Upper = 'upper'
}

/** input type for updating data in table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  lower?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  soil_chemistry_standard_id?: InputMaybe<Scalars['uuid']>;
  unit?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  upper?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Nb_Soil_Chemistry_Proper_Range_Stddev_Fields = {
  __typename?: 'nb_soil_chemistry_proper_range_stddev_fields';
  lower?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Stddev_Order_By = {
  lower?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  upper?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Soil_Chemistry_Proper_Range_Stddev_Pop_Fields = {
  __typename?: 'nb_soil_chemistry_proper_range_stddev_pop_fields';
  lower?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Stddev_Pop_Order_By = {
  lower?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  upper?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Soil_Chemistry_Proper_Range_Stddev_Samp_Fields = {
  __typename?: 'nb_soil_chemistry_proper_range_stddev_samp_fields';
  lower?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Stddev_Samp_Order_By = {
  lower?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  upper?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Soil_Chemistry_Proper_Range_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Soil_Chemistry_Proper_Range_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  lower?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  soil_chemistry_standard_id?: InputMaybe<Scalars['uuid']>;
  unit?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  upper?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Nb_Soil_Chemistry_Proper_Range_Sum_Fields = {
  __typename?: 'nb_soil_chemistry_proper_range_sum_fields';
  lower?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Int']>;
  upper?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Sum_Order_By = {
  lower?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  upper?: InputMaybe<Order_By>;
};

/** update columns of table "nb_soil_chemistry_proper_range" */
export enum Nb_Soil_Chemistry_Proper_Range_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Lower = 'lower',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  SoilChemistryStandardId = 'soil_chemistry_standard_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  Updated = 'updated',
  /** column name */
  Upper = 'upper'
}

export type Nb_Soil_Chemistry_Proper_Range_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Set_Input>;
  where: Nb_Soil_Chemistry_Proper_Range_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Soil_Chemistry_Proper_Range_Var_Pop_Fields = {
  __typename?: 'nb_soil_chemistry_proper_range_var_pop_fields';
  lower?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Var_Pop_Order_By = {
  lower?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  upper?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Soil_Chemistry_Proper_Range_Var_Samp_Fields = {
  __typename?: 'nb_soil_chemistry_proper_range_var_samp_fields';
  lower?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Var_Samp_Order_By = {
  lower?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  upper?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Soil_Chemistry_Proper_Range_Variance_Fields = {
  __typename?: 'nb_soil_chemistry_proper_range_variance_fields';
  lower?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_soil_chemistry_proper_range" */
export type Nb_Soil_Chemistry_Proper_Range_Variance_Order_By = {
  lower?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  upper?: InputMaybe<Order_By>;
};

/** select columns of table "nb_soil_chemistry" */
export enum Nb_Soil_Chemistry_Select_Column {
  /** column name */
  Cec = 'CEC',
  /** column name */
  CaO = 'CaO',
  /** column name */
  Ec = 'EC',
  /** column name */
  AmmoniaNitrogen = 'ammonia_nitrogen',
  /** column name */
  AvailableNitrogen = 'available_nitrogen',
  /** column name */
  BoricAcid = 'boric_acid',
  /** column name */
  Comment = 'comment',
  /** column name */
  Created = 'created',
  /** column name */
  CreationDate = 'creation_date',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  DegreeOfBaseSaturation = 'degree_of_base_saturation',
  /** column name */
  DegreeOfKaliumSaturation = 'degree_of_kalium_saturation',
  /** column name */
  DegreeOfLimeSaturation = 'degree_of_lime_saturation',
  /** column name */
  DegreeOfMagnesiaSaturation = 'degree_of_magnesia_saturation',
  /** column name */
  Humus = 'humus',
  /** column name */
  Id = 'id',
  /** column name */
  Kalium = 'kalium',
  /** column name */
  LimeMagnesiumRatio = 'lime_magnesium_ratio',
  /** column name */
  Magnesia = 'magnesia',
  /** column name */
  MagnesiumPotassiumRatio = 'magnesium_potassium_ratio',
  /** column name */
  Manganese = 'manganese',
  /** column name */
  NitrateNitrogen = 'nitrate_nitrogen',
  /** column name */
  PH = 'pH',
  /** column name */
  PHKCl = 'pH_KCl',
  /** column name */
  PhosphateAbsorptionCoeff = 'phosphate_absorption_coeff',
  /** column name */
  PhosphoricAcid = 'phosphoric_acid',
  /** column name */
  ReceptionDate = 'reception_date',
  /** column name */
  SamplingDate = 'sampling_date',
  /** column name */
  SilicicAcid = 'silicic_acid',
  /** column name */
  SoilChemistryStandardId = 'soil_chemistry_standard_id',
  /** column name */
  SoilDiagnosis = 'soil_diagnosis',
  /** column name */
  SuitableRangeId = 'suitable_range_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Set_Input = {
  CEC?: InputMaybe<Scalars['Float']>;
  CaO?: InputMaybe<Scalars['Float']>;
  EC?: InputMaybe<Scalars['Float']>;
  ammonia_nitrogen?: InputMaybe<Scalars['Float']>;
  available_nitrogen?: InputMaybe<Scalars['Float']>;
  boric_acid?: InputMaybe<Scalars['Float']>;
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creation_date?: InputMaybe<Scalars['date']>;
  creator_name?: InputMaybe<Scalars['String']>;
  degree_of_base_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: InputMaybe<Scalars['Float']>;
  humus?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  kalium?: InputMaybe<Scalars['Float']>;
  lime_magnesium_ratio?: InputMaybe<Scalars['Float']>;
  magnesia?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio?: InputMaybe<Scalars['Float']>;
  manganese?: InputMaybe<Scalars['Float']>;
  nitrate_nitrogen?: InputMaybe<Scalars['Float']>;
  pH?: InputMaybe<Scalars['Float']>;
  pH_KCl?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff?: InputMaybe<Scalars['Float']>;
  phosphoric_acid?: InputMaybe<Scalars['Float']>;
  reception_date?: InputMaybe<Scalars['date']>;
  sampling_date?: InputMaybe<Scalars['timestamptz']>;
  silicic_acid?: InputMaybe<Scalars['Float']>;
  soil_chemistry_standard_id?: InputMaybe<Scalars['uuid']>;
  soil_diagnosis?: InputMaybe<Scalars['String']>;
  suitable_range_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** 土壌化学成分基準 */
export type Nb_Soil_Chemistry_Standards = {
  __typename?: 'nb_soil_chemistry_standards';
  company_id?: Maybe<Scalars['uuid']>;
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An object relationship */
  nb_company?: Maybe<Nb_Company>;
  /** An array relationship */
  nb_soil_chemistries: Array<Nb_Soil_Chemistry>;
  /** An aggregate relationship */
  nb_soil_chemistries_aggregate: Nb_Soil_Chemistry_Aggregate;
  /** An array relationship */
  nb_soil_chemistry_proper_ranges: Array<Nb_Soil_Chemistry_Proper_Range>;
  /** An aggregate relationship */
  nb_soil_chemistry_proper_ranges_aggregate: Nb_Soil_Chemistry_Proper_Range_Aggregate;
  updated: Scalars['timestamptz'];
};


/** 土壌化学成分基準 */
export type Nb_Soil_Chemistry_StandardsNb_Soil_ChemistriesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


/** 土壌化学成分基準 */
export type Nb_Soil_Chemistry_StandardsNb_Soil_Chemistries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


/** 土壌化学成分基準 */
export type Nb_Soil_Chemistry_StandardsNb_Soil_Chemistry_Proper_RangesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
};


/** 土壌化学成分基準 */
export type Nb_Soil_Chemistry_StandardsNb_Soil_Chemistry_Proper_Ranges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
};

/** aggregated selection of "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Aggregate = {
  __typename?: 'nb_soil_chemistry_standards_aggregate';
  aggregate?: Maybe<Nb_Soil_Chemistry_Standards_Aggregate_Fields>;
  nodes: Array<Nb_Soil_Chemistry_Standards>;
};

export type Nb_Soil_Chemistry_Standards_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Soil_Chemistry_Standards_Aggregate_Bool_Exp_Count>;
};

export type Nb_Soil_Chemistry_Standards_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Aggregate_Fields = {
  __typename?: 'nb_soil_chemistry_standards_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Soil_Chemistry_Standards_Max_Fields>;
  min?: Maybe<Nb_Soil_Chemistry_Standards_Min_Fields>;
};


/** aggregate fields of "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Soil_Chemistry_Standards_Max_Order_By>;
  min?: InputMaybe<Nb_Soil_Chemistry_Standards_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Arr_Rel_Insert_Input = {
  data: Array<Nb_Soil_Chemistry_Standards_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Standards_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_soil_chemistry_standards". All fields are combined with a logical 'AND'. */
export type Nb_Soil_Chemistry_Standards_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Bool_Exp>>;
  _not?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_soil_chemistries?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
  nb_soil_chemistries_aggregate?: InputMaybe<Nb_Soil_Chemistry_Aggregate_Bool_Exp>;
  nb_soil_chemistry_proper_ranges?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
  nb_soil_chemistry_proper_ranges_aggregate?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_soil_chemistry_standards" */
export enum Nb_Soil_Chemistry_Standards_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbSoilChemistryStandardsKeyKey = 'nb_soil_chemistry_standards_key_key',
  /** unique or primary key constraint on columns "name" */
  NbSoilChemistryStandardsNameKey = 'nb_soil_chemistry_standards_name_key',
  /** unique or primary key constraint on columns "id" */
  NbSoilChemistryStandardsPkey = 'nb_soil_chemistry_standards_pkey'
}

/** input type for inserting data into table "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Insert_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_soil_chemistries?: InputMaybe<Nb_Soil_Chemistry_Arr_Rel_Insert_Input>;
  nb_soil_chemistry_proper_ranges?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Soil_Chemistry_Standards_Max_Fields = {
  __typename?: 'nb_soil_chemistry_standards_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Soil_Chemistry_Standards_Min_Fields = {
  __typename?: 'nb_soil_chemistry_standards_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Mutation_Response = {
  __typename?: 'nb_soil_chemistry_standards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Soil_Chemistry_Standards>;
};

/** input type for inserting object relation for remote table "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Obj_Rel_Insert_Input = {
  data: Nb_Soil_Chemistry_Standards_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Standards_On_Conflict>;
};

/** on_conflict condition type for table "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_On_Conflict = {
  constraint: Nb_Soil_Chemistry_Standards_Constraint;
  update_columns?: Array<Nb_Soil_Chemistry_Standards_Update_Column>;
  where?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_soil_chemistry_standards". */
export type Nb_Soil_Chemistry_Standards_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_soil_chemistries_aggregate?: InputMaybe<Nb_Soil_Chemistry_Aggregate_Order_By>;
  nb_soil_chemistry_proper_ranges_aggregate?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_soil_chemistry_standards */
export type Nb_Soil_Chemistry_Standards_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_soil_chemistry_standards" */
export enum Nb_Soil_Chemistry_Standards_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_soil_chemistry_standards" */
export type Nb_Soil_Chemistry_Standards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Soil_Chemistry_Standards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Soil_Chemistry_Standards_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_soil_chemistry_standards" */
export enum Nb_Soil_Chemistry_Standards_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

export type Nb_Soil_Chemistry_Standards_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Soil_Chemistry_Standards_Set_Input>;
  where: Nb_Soil_Chemistry_Standards_Bool_Exp;
};

/** aggregate stddev on columns */
export type Nb_Soil_Chemistry_Stddev_Fields = {
  __typename?: 'nb_soil_chemistry_stddev_fields';
  CEC?: Maybe<Scalars['Float']>;
  CaO?: Maybe<Scalars['Float']>;
  EC?: Maybe<Scalars['Float']>;
  ammonia_nitrogen?: Maybe<Scalars['Float']>;
  available_nitrogen?: Maybe<Scalars['Float']>;
  boric_acid?: Maybe<Scalars['Float']>;
  degree_of_base_saturation?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: Maybe<Scalars['Float']>;
  humus?: Maybe<Scalars['Float']>;
  kalium?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio?: Maybe<Scalars['Float']>;
  magnesia?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio?: Maybe<Scalars['Float']>;
  manganese?: Maybe<Scalars['Float']>;
  nitrate_nitrogen?: Maybe<Scalars['Float']>;
  pH?: Maybe<Scalars['Float']>;
  pH_KCl?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff?: Maybe<Scalars['Float']>;
  phosphoric_acid?: Maybe<Scalars['Float']>;
  silicic_acid?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Stddev_Order_By = {
  CEC?: InputMaybe<Order_By>;
  CaO?: InputMaybe<Order_By>;
  EC?: InputMaybe<Order_By>;
  ammonia_nitrogen?: InputMaybe<Order_By>;
  available_nitrogen?: InputMaybe<Order_By>;
  boric_acid?: InputMaybe<Order_By>;
  degree_of_base_saturation?: InputMaybe<Order_By>;
  degree_of_kalium_saturation?: InputMaybe<Order_By>;
  degree_of_lime_saturation?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation?: InputMaybe<Order_By>;
  humus?: InputMaybe<Order_By>;
  kalium?: InputMaybe<Order_By>;
  lime_magnesium_ratio?: InputMaybe<Order_By>;
  magnesia?: InputMaybe<Order_By>;
  magnesium_potassium_ratio?: InputMaybe<Order_By>;
  manganese?: InputMaybe<Order_By>;
  nitrate_nitrogen?: InputMaybe<Order_By>;
  pH?: InputMaybe<Order_By>;
  pH_KCl?: InputMaybe<Order_By>;
  phosphate_absorption_coeff?: InputMaybe<Order_By>;
  phosphoric_acid?: InputMaybe<Order_By>;
  silicic_acid?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Soil_Chemistry_Stddev_Pop_Fields = {
  __typename?: 'nb_soil_chemistry_stddev_pop_fields';
  CEC?: Maybe<Scalars['Float']>;
  CaO?: Maybe<Scalars['Float']>;
  EC?: Maybe<Scalars['Float']>;
  ammonia_nitrogen?: Maybe<Scalars['Float']>;
  available_nitrogen?: Maybe<Scalars['Float']>;
  boric_acid?: Maybe<Scalars['Float']>;
  degree_of_base_saturation?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: Maybe<Scalars['Float']>;
  humus?: Maybe<Scalars['Float']>;
  kalium?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio?: Maybe<Scalars['Float']>;
  magnesia?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio?: Maybe<Scalars['Float']>;
  manganese?: Maybe<Scalars['Float']>;
  nitrate_nitrogen?: Maybe<Scalars['Float']>;
  pH?: Maybe<Scalars['Float']>;
  pH_KCl?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff?: Maybe<Scalars['Float']>;
  phosphoric_acid?: Maybe<Scalars['Float']>;
  silicic_acid?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Stddev_Pop_Order_By = {
  CEC?: InputMaybe<Order_By>;
  CaO?: InputMaybe<Order_By>;
  EC?: InputMaybe<Order_By>;
  ammonia_nitrogen?: InputMaybe<Order_By>;
  available_nitrogen?: InputMaybe<Order_By>;
  boric_acid?: InputMaybe<Order_By>;
  degree_of_base_saturation?: InputMaybe<Order_By>;
  degree_of_kalium_saturation?: InputMaybe<Order_By>;
  degree_of_lime_saturation?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation?: InputMaybe<Order_By>;
  humus?: InputMaybe<Order_By>;
  kalium?: InputMaybe<Order_By>;
  lime_magnesium_ratio?: InputMaybe<Order_By>;
  magnesia?: InputMaybe<Order_By>;
  magnesium_potassium_ratio?: InputMaybe<Order_By>;
  manganese?: InputMaybe<Order_By>;
  nitrate_nitrogen?: InputMaybe<Order_By>;
  pH?: InputMaybe<Order_By>;
  pH_KCl?: InputMaybe<Order_By>;
  phosphate_absorption_coeff?: InputMaybe<Order_By>;
  phosphoric_acid?: InputMaybe<Order_By>;
  silicic_acid?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Soil_Chemistry_Stddev_Samp_Fields = {
  __typename?: 'nb_soil_chemistry_stddev_samp_fields';
  CEC?: Maybe<Scalars['Float']>;
  CaO?: Maybe<Scalars['Float']>;
  EC?: Maybe<Scalars['Float']>;
  ammonia_nitrogen?: Maybe<Scalars['Float']>;
  available_nitrogen?: Maybe<Scalars['Float']>;
  boric_acid?: Maybe<Scalars['Float']>;
  degree_of_base_saturation?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: Maybe<Scalars['Float']>;
  humus?: Maybe<Scalars['Float']>;
  kalium?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio?: Maybe<Scalars['Float']>;
  magnesia?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio?: Maybe<Scalars['Float']>;
  manganese?: Maybe<Scalars['Float']>;
  nitrate_nitrogen?: Maybe<Scalars['Float']>;
  pH?: Maybe<Scalars['Float']>;
  pH_KCl?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff?: Maybe<Scalars['Float']>;
  phosphoric_acid?: Maybe<Scalars['Float']>;
  silicic_acid?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Stddev_Samp_Order_By = {
  CEC?: InputMaybe<Order_By>;
  CaO?: InputMaybe<Order_By>;
  EC?: InputMaybe<Order_By>;
  ammonia_nitrogen?: InputMaybe<Order_By>;
  available_nitrogen?: InputMaybe<Order_By>;
  boric_acid?: InputMaybe<Order_By>;
  degree_of_base_saturation?: InputMaybe<Order_By>;
  degree_of_kalium_saturation?: InputMaybe<Order_By>;
  degree_of_lime_saturation?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation?: InputMaybe<Order_By>;
  humus?: InputMaybe<Order_By>;
  kalium?: InputMaybe<Order_By>;
  lime_magnesium_ratio?: InputMaybe<Order_By>;
  magnesia?: InputMaybe<Order_By>;
  magnesium_potassium_ratio?: InputMaybe<Order_By>;
  manganese?: InputMaybe<Order_By>;
  nitrate_nitrogen?: InputMaybe<Order_By>;
  pH?: InputMaybe<Order_By>;
  pH_KCl?: InputMaybe<Order_By>;
  phosphate_absorption_coeff?: InputMaybe<Order_By>;
  phosphoric_acid?: InputMaybe<Order_By>;
  silicic_acid?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Soil_Chemistry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Soil_Chemistry_Stream_Cursor_Value_Input = {
  CEC?: InputMaybe<Scalars['Float']>;
  CaO?: InputMaybe<Scalars['Float']>;
  EC?: InputMaybe<Scalars['Float']>;
  ammonia_nitrogen?: InputMaybe<Scalars['Float']>;
  available_nitrogen?: InputMaybe<Scalars['Float']>;
  boric_acid?: InputMaybe<Scalars['Float']>;
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creation_date?: InputMaybe<Scalars['date']>;
  creator_name?: InputMaybe<Scalars['String']>;
  degree_of_base_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: InputMaybe<Scalars['Float']>;
  humus?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  kalium?: InputMaybe<Scalars['Float']>;
  lime_magnesium_ratio?: InputMaybe<Scalars['Float']>;
  magnesia?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio?: InputMaybe<Scalars['Float']>;
  manganese?: InputMaybe<Scalars['Float']>;
  nitrate_nitrogen?: InputMaybe<Scalars['Float']>;
  pH?: InputMaybe<Scalars['Float']>;
  pH_KCl?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff?: InputMaybe<Scalars['Float']>;
  phosphoric_acid?: InputMaybe<Scalars['Float']>;
  reception_date?: InputMaybe<Scalars['date']>;
  sampling_date?: InputMaybe<Scalars['timestamptz']>;
  silicic_acid?: InputMaybe<Scalars['Float']>;
  soil_chemistry_standard_id?: InputMaybe<Scalars['uuid']>;
  soil_diagnosis?: InputMaybe<Scalars['String']>;
  suitable_range_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_Range = {
  __typename?: 'nb_soil_chemistry_suitable_range';
  CEC_rmax?: Maybe<Scalars['Float']>;
  CEC_rmin?: Maybe<Scalars['Float']>;
  CaO_rmax?: Maybe<Scalars['Float']>;
  CaO_rmin?: Maybe<Scalars['Float']>;
  EC_rmax?: Maybe<Scalars['Float']>;
  EC_rmin?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmax?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmin?: Maybe<Scalars['Float']>;
  boric_acid_rmax?: Maybe<Scalars['Float']>;
  boric_acid_rmin?: Maybe<Scalars['Float']>;
  created: Scalars['timestamptz'];
  degree_of_base_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: Maybe<Scalars['Float']>;
  humus_rmax?: Maybe<Scalars['Float']>;
  humus_rmin?: Maybe<Scalars['Float']>;
  id: Scalars['uuid'];
  kalium_rmax?: Maybe<Scalars['Float']>;
  kalium_rmin?: Maybe<Scalars['Float']>;
  key: Scalars['String'];
  lime_magnesium_ratio_rmax?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: Maybe<Scalars['Float']>;
  magnesia_rmax?: Maybe<Scalars['Float']>;
  magnesia_rmin?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: Maybe<Scalars['Float']>;
  manganese_rmax?: Maybe<Scalars['Float']>;
  manganese_rmin?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  /** An array relationship */
  nb_soil_chemistries: Array<Nb_Soil_Chemistry>;
  /** An aggregate relationship */
  nb_soil_chemistries_aggregate: Nb_Soil_Chemistry_Aggregate;
  nitrate_nitro_rmax?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmin?: Maybe<Scalars['Float']>;
  pH_KCl_rmax?: Maybe<Scalars['Float']>;
  pH_KCl_rmin?: Maybe<Scalars['Float']>;
  pH_rmax?: Maybe<Scalars['Float']>;
  pH_rmin?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmax?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmin?: Maybe<Scalars['Float']>;
  silicic_acid_rmax?: Maybe<Scalars['Float']>;
  silicic_acid_rmin?: Maybe<Scalars['Float']>;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_RangeNb_Soil_ChemistriesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


/** columns and relationships of "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_RangeNb_Soil_Chemistries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};

/** aggregated selection of "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_Range_Aggregate = {
  __typename?: 'nb_soil_chemistry_suitable_range_aggregate';
  aggregate?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Aggregate_Fields>;
  nodes: Array<Nb_Soil_Chemistry_Suitable_Range>;
};

/** aggregate fields of "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_Range_Aggregate_Fields = {
  __typename?: 'nb_soil_chemistry_suitable_range_aggregate_fields';
  avg?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Max_Fields>;
  min?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Min_Fields>;
  stddev?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Sum_Fields>;
  var_pop?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Var_Samp_Fields>;
  variance?: Maybe<Nb_Soil_Chemistry_Suitable_Range_Variance_Fields>;
};


/** aggregate fields of "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_Range_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Soil_Chemistry_Suitable_Range_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Nb_Soil_Chemistry_Suitable_Range_Avg_Fields = {
  __typename?: 'nb_soil_chemistry_suitable_range_avg_fields';
  CEC_rmax?: Maybe<Scalars['Float']>;
  CEC_rmin?: Maybe<Scalars['Float']>;
  CaO_rmax?: Maybe<Scalars['Float']>;
  CaO_rmin?: Maybe<Scalars['Float']>;
  EC_rmax?: Maybe<Scalars['Float']>;
  EC_rmin?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmax?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmin?: Maybe<Scalars['Float']>;
  boric_acid_rmax?: Maybe<Scalars['Float']>;
  boric_acid_rmin?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: Maybe<Scalars['Float']>;
  humus_rmax?: Maybe<Scalars['Float']>;
  humus_rmin?: Maybe<Scalars['Float']>;
  kalium_rmax?: Maybe<Scalars['Float']>;
  kalium_rmin?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmax?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: Maybe<Scalars['Float']>;
  magnesia_rmax?: Maybe<Scalars['Float']>;
  magnesia_rmin?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: Maybe<Scalars['Float']>;
  manganese_rmax?: Maybe<Scalars['Float']>;
  manganese_rmin?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmax?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmin?: Maybe<Scalars['Float']>;
  pH_KCl_rmax?: Maybe<Scalars['Float']>;
  pH_KCl_rmin?: Maybe<Scalars['Float']>;
  pH_rmax?: Maybe<Scalars['Float']>;
  pH_rmin?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmax?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmin?: Maybe<Scalars['Float']>;
  silicic_acid_rmax?: Maybe<Scalars['Float']>;
  silicic_acid_rmin?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "nb_soil_chemistry_suitable_range". All fields are combined with a logical 'AND'. */
export type Nb_Soil_Chemistry_Suitable_Range_Bool_Exp = {
  CEC_rmax?: InputMaybe<Float_Comparison_Exp>;
  CEC_rmin?: InputMaybe<Float_Comparison_Exp>;
  CaO_rmax?: InputMaybe<Float_Comparison_Exp>;
  CaO_rmin?: InputMaybe<Float_Comparison_Exp>;
  EC_rmax?: InputMaybe<Float_Comparison_Exp>;
  EC_rmin?: InputMaybe<Float_Comparison_Exp>;
  _and?: InputMaybe<Array<Nb_Soil_Chemistry_Suitable_Range_Bool_Exp>>;
  _not?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Soil_Chemistry_Suitable_Range_Bool_Exp>>;
  ammonia_nitro_rmax?: InputMaybe<Float_Comparison_Exp>;
  ammonia_nitro_rmin?: InputMaybe<Float_Comparison_Exp>;
  boric_acid_rmax?: InputMaybe<Float_Comparison_Exp>;
  boric_acid_rmin?: InputMaybe<Float_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  degree_of_base_saturation_rmax?: InputMaybe<Float_Comparison_Exp>;
  degree_of_base_saturation_rmin?: InputMaybe<Float_Comparison_Exp>;
  degree_of_kalium_saturation_rmax?: InputMaybe<Float_Comparison_Exp>;
  degree_of_kalium_saturation_rmin?: InputMaybe<Float_Comparison_Exp>;
  degree_of_lime_saturation_rmax?: InputMaybe<Float_Comparison_Exp>;
  degree_of_lime_saturation_rmin?: InputMaybe<Float_Comparison_Exp>;
  degree_of_magnesia_saturation_rmax?: InputMaybe<Float_Comparison_Exp>;
  degree_of_magnesia_saturation_rmin?: InputMaybe<Float_Comparison_Exp>;
  humus_rmax?: InputMaybe<Float_Comparison_Exp>;
  humus_rmin?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kalium_rmax?: InputMaybe<Float_Comparison_Exp>;
  kalium_rmin?: InputMaybe<Float_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  lime_magnesium_ratio_rmax?: InputMaybe<Float_Comparison_Exp>;
  lime_magnesium_ratio_rmin?: InputMaybe<Float_Comparison_Exp>;
  magnesia_rmax?: InputMaybe<Float_Comparison_Exp>;
  magnesia_rmin?: InputMaybe<Float_Comparison_Exp>;
  magnesium_potassium_ratio_rmax?: InputMaybe<Float_Comparison_Exp>;
  magnesium_potassium_ratio_rmin?: InputMaybe<Float_Comparison_Exp>;
  manganese_rmax?: InputMaybe<Float_Comparison_Exp>;
  manganese_rmin?: InputMaybe<Float_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_soil_chemistries?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
  nb_soil_chemistries_aggregate?: InputMaybe<Nb_Soil_Chemistry_Aggregate_Bool_Exp>;
  nitrate_nitro_rmax?: InputMaybe<Float_Comparison_Exp>;
  nitrate_nitro_rmin?: InputMaybe<Float_Comparison_Exp>;
  pH_KCl_rmax?: InputMaybe<Float_Comparison_Exp>;
  pH_KCl_rmin?: InputMaybe<Float_Comparison_Exp>;
  pH_rmax?: InputMaybe<Float_Comparison_Exp>;
  pH_rmin?: InputMaybe<Float_Comparison_Exp>;
  phosphate_absorption_coeff_rmax?: InputMaybe<Float_Comparison_Exp>;
  phosphate_absorption_coeff_rmin?: InputMaybe<Float_Comparison_Exp>;
  phosphoric_acid_rmax?: InputMaybe<Float_Comparison_Exp>;
  phosphoric_acid_rmin?: InputMaybe<Float_Comparison_Exp>;
  silicic_acid_rmax?: InputMaybe<Float_Comparison_Exp>;
  silicic_acid_rmin?: InputMaybe<Float_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_soil_chemistry_suitable_range" */
export enum Nb_Soil_Chemistry_Suitable_Range_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbSoilChemistrySuitableRangeKeyKey = 'nb_soil_chemistry_suitable_range_key_key',
  /** unique or primary key constraint on columns "id" */
  NbSoilChemistrySuitableRangePkey = 'nb_soil_chemistry_suitable_range_pkey'
}

/** input type for incrementing numeric columns in table "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_Range_Inc_Input = {
  CEC_rmax?: InputMaybe<Scalars['Float']>;
  CEC_rmin?: InputMaybe<Scalars['Float']>;
  CaO_rmax?: InputMaybe<Scalars['Float']>;
  CaO_rmin?: InputMaybe<Scalars['Float']>;
  EC_rmax?: InputMaybe<Scalars['Float']>;
  EC_rmin?: InputMaybe<Scalars['Float']>;
  ammonia_nitro_rmax?: InputMaybe<Scalars['Float']>;
  ammonia_nitro_rmin?: InputMaybe<Scalars['Float']>;
  boric_acid_rmax?: InputMaybe<Scalars['Float']>;
  boric_acid_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_base_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: InputMaybe<Scalars['Float']>;
  humus_rmax?: InputMaybe<Scalars['Float']>;
  humus_rmin?: InputMaybe<Scalars['Float']>;
  kalium_rmax?: InputMaybe<Scalars['Float']>;
  kalium_rmin?: InputMaybe<Scalars['Float']>;
  lime_magnesium_ratio_rmax?: InputMaybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: InputMaybe<Scalars['Float']>;
  magnesia_rmax?: InputMaybe<Scalars['Float']>;
  magnesia_rmin?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: InputMaybe<Scalars['Float']>;
  manganese_rmax?: InputMaybe<Scalars['Float']>;
  manganese_rmin?: InputMaybe<Scalars['Float']>;
  nitrate_nitro_rmax?: InputMaybe<Scalars['Float']>;
  nitrate_nitro_rmin?: InputMaybe<Scalars['Float']>;
  pH_KCl_rmax?: InputMaybe<Scalars['Float']>;
  pH_KCl_rmin?: InputMaybe<Scalars['Float']>;
  pH_rmax?: InputMaybe<Scalars['Float']>;
  pH_rmin?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: InputMaybe<Scalars['Float']>;
  phosphoric_acid_rmax?: InputMaybe<Scalars['Float']>;
  phosphoric_acid_rmin?: InputMaybe<Scalars['Float']>;
  silicic_acid_rmax?: InputMaybe<Scalars['Float']>;
  silicic_acid_rmin?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_Range_Insert_Input = {
  CEC_rmax?: InputMaybe<Scalars['Float']>;
  CEC_rmin?: InputMaybe<Scalars['Float']>;
  CaO_rmax?: InputMaybe<Scalars['Float']>;
  CaO_rmin?: InputMaybe<Scalars['Float']>;
  EC_rmax?: InputMaybe<Scalars['Float']>;
  EC_rmin?: InputMaybe<Scalars['Float']>;
  ammonia_nitro_rmax?: InputMaybe<Scalars['Float']>;
  ammonia_nitro_rmin?: InputMaybe<Scalars['Float']>;
  boric_acid_rmax?: InputMaybe<Scalars['Float']>;
  boric_acid_rmin?: InputMaybe<Scalars['Float']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  degree_of_base_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: InputMaybe<Scalars['Float']>;
  humus_rmax?: InputMaybe<Scalars['Float']>;
  humus_rmin?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  kalium_rmax?: InputMaybe<Scalars['Float']>;
  kalium_rmin?: InputMaybe<Scalars['Float']>;
  key?: InputMaybe<Scalars['String']>;
  lime_magnesium_ratio_rmax?: InputMaybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: InputMaybe<Scalars['Float']>;
  magnesia_rmax?: InputMaybe<Scalars['Float']>;
  magnesia_rmin?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: InputMaybe<Scalars['Float']>;
  manganese_rmax?: InputMaybe<Scalars['Float']>;
  manganese_rmin?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  nb_soil_chemistries?: InputMaybe<Nb_Soil_Chemistry_Arr_Rel_Insert_Input>;
  nitrate_nitro_rmax?: InputMaybe<Scalars['Float']>;
  nitrate_nitro_rmin?: InputMaybe<Scalars['Float']>;
  pH_KCl_rmax?: InputMaybe<Scalars['Float']>;
  pH_KCl_rmin?: InputMaybe<Scalars['Float']>;
  pH_rmax?: InputMaybe<Scalars['Float']>;
  pH_rmin?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: InputMaybe<Scalars['Float']>;
  phosphoric_acid_rmax?: InputMaybe<Scalars['Float']>;
  phosphoric_acid_rmin?: InputMaybe<Scalars['Float']>;
  silicic_acid_rmax?: InputMaybe<Scalars['Float']>;
  silicic_acid_rmin?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Soil_Chemistry_Suitable_Range_Max_Fields = {
  __typename?: 'nb_soil_chemistry_suitable_range_max_fields';
  CEC_rmax?: Maybe<Scalars['Float']>;
  CEC_rmin?: Maybe<Scalars['Float']>;
  CaO_rmax?: Maybe<Scalars['Float']>;
  CaO_rmin?: Maybe<Scalars['Float']>;
  EC_rmax?: Maybe<Scalars['Float']>;
  EC_rmin?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmax?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmin?: Maybe<Scalars['Float']>;
  boric_acid_rmax?: Maybe<Scalars['Float']>;
  boric_acid_rmin?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['timestamptz']>;
  degree_of_base_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: Maybe<Scalars['Float']>;
  humus_rmax?: Maybe<Scalars['Float']>;
  humus_rmin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  kalium_rmax?: Maybe<Scalars['Float']>;
  kalium_rmin?: Maybe<Scalars['Float']>;
  key?: Maybe<Scalars['String']>;
  lime_magnesium_ratio_rmax?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: Maybe<Scalars['Float']>;
  magnesia_rmax?: Maybe<Scalars['Float']>;
  magnesia_rmin?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: Maybe<Scalars['Float']>;
  manganese_rmax?: Maybe<Scalars['Float']>;
  manganese_rmin?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  nitrate_nitro_rmax?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmin?: Maybe<Scalars['Float']>;
  pH_KCl_rmax?: Maybe<Scalars['Float']>;
  pH_KCl_rmin?: Maybe<Scalars['Float']>;
  pH_rmax?: Maybe<Scalars['Float']>;
  pH_rmin?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmax?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmin?: Maybe<Scalars['Float']>;
  silicic_acid_rmax?: Maybe<Scalars['Float']>;
  silicic_acid_rmin?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Soil_Chemistry_Suitable_Range_Min_Fields = {
  __typename?: 'nb_soil_chemistry_suitable_range_min_fields';
  CEC_rmax?: Maybe<Scalars['Float']>;
  CEC_rmin?: Maybe<Scalars['Float']>;
  CaO_rmax?: Maybe<Scalars['Float']>;
  CaO_rmin?: Maybe<Scalars['Float']>;
  EC_rmax?: Maybe<Scalars['Float']>;
  EC_rmin?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmax?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmin?: Maybe<Scalars['Float']>;
  boric_acid_rmax?: Maybe<Scalars['Float']>;
  boric_acid_rmin?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['timestamptz']>;
  degree_of_base_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: Maybe<Scalars['Float']>;
  humus_rmax?: Maybe<Scalars['Float']>;
  humus_rmin?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  kalium_rmax?: Maybe<Scalars['Float']>;
  kalium_rmin?: Maybe<Scalars['Float']>;
  key?: Maybe<Scalars['String']>;
  lime_magnesium_ratio_rmax?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: Maybe<Scalars['Float']>;
  magnesia_rmax?: Maybe<Scalars['Float']>;
  magnesia_rmin?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: Maybe<Scalars['Float']>;
  manganese_rmax?: Maybe<Scalars['Float']>;
  manganese_rmin?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  nitrate_nitro_rmax?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmin?: Maybe<Scalars['Float']>;
  pH_KCl_rmax?: Maybe<Scalars['Float']>;
  pH_KCl_rmin?: Maybe<Scalars['Float']>;
  pH_rmax?: Maybe<Scalars['Float']>;
  pH_rmin?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmax?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmin?: Maybe<Scalars['Float']>;
  silicic_acid_rmax?: Maybe<Scalars['Float']>;
  silicic_acid_rmin?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_Range_Mutation_Response = {
  __typename?: 'nb_soil_chemistry_suitable_range_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Soil_Chemistry_Suitable_Range>;
};

/** input type for inserting object relation for remote table "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_Range_Obj_Rel_Insert_Input = {
  data: Nb_Soil_Chemistry_Suitable_Range_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_On_Conflict>;
};

/** on_conflict condition type for table "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_Range_On_Conflict = {
  constraint: Nb_Soil_Chemistry_Suitable_Range_Constraint;
  update_columns?: Array<Nb_Soil_Chemistry_Suitable_Range_Update_Column>;
  where?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_soil_chemistry_suitable_range". */
export type Nb_Soil_Chemistry_Suitable_Range_Order_By = {
  CEC_rmax?: InputMaybe<Order_By>;
  CEC_rmin?: InputMaybe<Order_By>;
  CaO_rmax?: InputMaybe<Order_By>;
  CaO_rmin?: InputMaybe<Order_By>;
  EC_rmax?: InputMaybe<Order_By>;
  EC_rmin?: InputMaybe<Order_By>;
  ammonia_nitro_rmax?: InputMaybe<Order_By>;
  ammonia_nitro_rmin?: InputMaybe<Order_By>;
  boric_acid_rmax?: InputMaybe<Order_By>;
  boric_acid_rmin?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  degree_of_base_saturation_rmax?: InputMaybe<Order_By>;
  degree_of_base_saturation_rmin?: InputMaybe<Order_By>;
  degree_of_kalium_saturation_rmax?: InputMaybe<Order_By>;
  degree_of_kalium_saturation_rmin?: InputMaybe<Order_By>;
  degree_of_lime_saturation_rmax?: InputMaybe<Order_By>;
  degree_of_lime_saturation_rmin?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation_rmax?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation_rmin?: InputMaybe<Order_By>;
  humus_rmax?: InputMaybe<Order_By>;
  humus_rmin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kalium_rmax?: InputMaybe<Order_By>;
  kalium_rmin?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  lime_magnesium_ratio_rmax?: InputMaybe<Order_By>;
  lime_magnesium_ratio_rmin?: InputMaybe<Order_By>;
  magnesia_rmax?: InputMaybe<Order_By>;
  magnesia_rmin?: InputMaybe<Order_By>;
  magnesium_potassium_ratio_rmax?: InputMaybe<Order_By>;
  magnesium_potassium_ratio_rmin?: InputMaybe<Order_By>;
  manganese_rmax?: InputMaybe<Order_By>;
  manganese_rmin?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_soil_chemistries_aggregate?: InputMaybe<Nb_Soil_Chemistry_Aggregate_Order_By>;
  nitrate_nitro_rmax?: InputMaybe<Order_By>;
  nitrate_nitro_rmin?: InputMaybe<Order_By>;
  pH_KCl_rmax?: InputMaybe<Order_By>;
  pH_KCl_rmin?: InputMaybe<Order_By>;
  pH_rmax?: InputMaybe<Order_By>;
  pH_rmin?: InputMaybe<Order_By>;
  phosphate_absorption_coeff_rmax?: InputMaybe<Order_By>;
  phosphate_absorption_coeff_rmin?: InputMaybe<Order_By>;
  phosphoric_acid_rmax?: InputMaybe<Order_By>;
  phosphoric_acid_rmin?: InputMaybe<Order_By>;
  silicic_acid_rmax?: InputMaybe<Order_By>;
  silicic_acid_rmin?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_soil_chemistry_suitable_range */
export type Nb_Soil_Chemistry_Suitable_Range_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_soil_chemistry_suitable_range" */
export enum Nb_Soil_Chemistry_Suitable_Range_Select_Column {
  /** column name */
  CecRmax = 'CEC_rmax',
  /** column name */
  CecRmin = 'CEC_rmin',
  /** column name */
  CaORmax = 'CaO_rmax',
  /** column name */
  CaORmin = 'CaO_rmin',
  /** column name */
  EcRmax = 'EC_rmax',
  /** column name */
  EcRmin = 'EC_rmin',
  /** column name */
  AmmoniaNitroRmax = 'ammonia_nitro_rmax',
  /** column name */
  AmmoniaNitroRmin = 'ammonia_nitro_rmin',
  /** column name */
  BoricAcidRmax = 'boric_acid_rmax',
  /** column name */
  BoricAcidRmin = 'boric_acid_rmin',
  /** column name */
  Created = 'created',
  /** column name */
  DegreeOfBaseSaturationRmax = 'degree_of_base_saturation_rmax',
  /** column name */
  DegreeOfBaseSaturationRmin = 'degree_of_base_saturation_rmin',
  /** column name */
  DegreeOfKaliumSaturationRmax = 'degree_of_kalium_saturation_rmax',
  /** column name */
  DegreeOfKaliumSaturationRmin = 'degree_of_kalium_saturation_rmin',
  /** column name */
  DegreeOfLimeSaturationRmax = 'degree_of_lime_saturation_rmax',
  /** column name */
  DegreeOfLimeSaturationRmin = 'degree_of_lime_saturation_rmin',
  /** column name */
  DegreeOfMagnesiaSaturationRmax = 'degree_of_magnesia_saturation_rmax',
  /** column name */
  DegreeOfMagnesiaSaturationRmin = 'degree_of_magnesia_saturation_rmin',
  /** column name */
  HumusRmax = 'humus_rmax',
  /** column name */
  HumusRmin = 'humus_rmin',
  /** column name */
  Id = 'id',
  /** column name */
  KaliumRmax = 'kalium_rmax',
  /** column name */
  KaliumRmin = 'kalium_rmin',
  /** column name */
  Key = 'key',
  /** column name */
  LimeMagnesiumRatioRmax = 'lime_magnesium_ratio_rmax',
  /** column name */
  LimeMagnesiumRatioRmin = 'lime_magnesium_ratio_rmin',
  /** column name */
  MagnesiaRmax = 'magnesia_rmax',
  /** column name */
  MagnesiaRmin = 'magnesia_rmin',
  /** column name */
  MagnesiumPotassiumRatioRmax = 'magnesium_potassium_ratio_rmax',
  /** column name */
  MagnesiumPotassiumRatioRmin = 'magnesium_potassium_ratio_rmin',
  /** column name */
  ManganeseRmax = 'manganese_rmax',
  /** column name */
  ManganeseRmin = 'manganese_rmin',
  /** column name */
  Name = 'name',
  /** column name */
  NitrateNitroRmax = 'nitrate_nitro_rmax',
  /** column name */
  NitrateNitroRmin = 'nitrate_nitro_rmin',
  /** column name */
  PHKClRmax = 'pH_KCl_rmax',
  /** column name */
  PHKClRmin = 'pH_KCl_rmin',
  /** column name */
  PHRmax = 'pH_rmax',
  /** column name */
  PHRmin = 'pH_rmin',
  /** column name */
  PhosphateAbsorptionCoeffRmax = 'phosphate_absorption_coeff_rmax',
  /** column name */
  PhosphateAbsorptionCoeffRmin = 'phosphate_absorption_coeff_rmin',
  /** column name */
  PhosphoricAcidRmax = 'phosphoric_acid_rmax',
  /** column name */
  PhosphoricAcidRmin = 'phosphoric_acid_rmin',
  /** column name */
  SilicicAcidRmax = 'silicic_acid_rmax',
  /** column name */
  SilicicAcidRmin = 'silicic_acid_rmin',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_Range_Set_Input = {
  CEC_rmax?: InputMaybe<Scalars['Float']>;
  CEC_rmin?: InputMaybe<Scalars['Float']>;
  CaO_rmax?: InputMaybe<Scalars['Float']>;
  CaO_rmin?: InputMaybe<Scalars['Float']>;
  EC_rmax?: InputMaybe<Scalars['Float']>;
  EC_rmin?: InputMaybe<Scalars['Float']>;
  ammonia_nitro_rmax?: InputMaybe<Scalars['Float']>;
  ammonia_nitro_rmin?: InputMaybe<Scalars['Float']>;
  boric_acid_rmax?: InputMaybe<Scalars['Float']>;
  boric_acid_rmin?: InputMaybe<Scalars['Float']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  degree_of_base_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: InputMaybe<Scalars['Float']>;
  humus_rmax?: InputMaybe<Scalars['Float']>;
  humus_rmin?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  kalium_rmax?: InputMaybe<Scalars['Float']>;
  kalium_rmin?: InputMaybe<Scalars['Float']>;
  key?: InputMaybe<Scalars['String']>;
  lime_magnesium_ratio_rmax?: InputMaybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: InputMaybe<Scalars['Float']>;
  magnesia_rmax?: InputMaybe<Scalars['Float']>;
  magnesia_rmin?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: InputMaybe<Scalars['Float']>;
  manganese_rmax?: InputMaybe<Scalars['Float']>;
  manganese_rmin?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  nitrate_nitro_rmax?: InputMaybe<Scalars['Float']>;
  nitrate_nitro_rmin?: InputMaybe<Scalars['Float']>;
  pH_KCl_rmax?: InputMaybe<Scalars['Float']>;
  pH_KCl_rmin?: InputMaybe<Scalars['Float']>;
  pH_rmax?: InputMaybe<Scalars['Float']>;
  pH_rmin?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: InputMaybe<Scalars['Float']>;
  phosphoric_acid_rmax?: InputMaybe<Scalars['Float']>;
  phosphoric_acid_rmin?: InputMaybe<Scalars['Float']>;
  silicic_acid_rmax?: InputMaybe<Scalars['Float']>;
  silicic_acid_rmin?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nb_Soil_Chemistry_Suitable_Range_Stddev_Fields = {
  __typename?: 'nb_soil_chemistry_suitable_range_stddev_fields';
  CEC_rmax?: Maybe<Scalars['Float']>;
  CEC_rmin?: Maybe<Scalars['Float']>;
  CaO_rmax?: Maybe<Scalars['Float']>;
  CaO_rmin?: Maybe<Scalars['Float']>;
  EC_rmax?: Maybe<Scalars['Float']>;
  EC_rmin?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmax?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmin?: Maybe<Scalars['Float']>;
  boric_acid_rmax?: Maybe<Scalars['Float']>;
  boric_acid_rmin?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: Maybe<Scalars['Float']>;
  humus_rmax?: Maybe<Scalars['Float']>;
  humus_rmin?: Maybe<Scalars['Float']>;
  kalium_rmax?: Maybe<Scalars['Float']>;
  kalium_rmin?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmax?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: Maybe<Scalars['Float']>;
  magnesia_rmax?: Maybe<Scalars['Float']>;
  magnesia_rmin?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: Maybe<Scalars['Float']>;
  manganese_rmax?: Maybe<Scalars['Float']>;
  manganese_rmin?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmax?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmin?: Maybe<Scalars['Float']>;
  pH_KCl_rmax?: Maybe<Scalars['Float']>;
  pH_KCl_rmin?: Maybe<Scalars['Float']>;
  pH_rmax?: Maybe<Scalars['Float']>;
  pH_rmin?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmax?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmin?: Maybe<Scalars['Float']>;
  silicic_acid_rmax?: Maybe<Scalars['Float']>;
  silicic_acid_rmin?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Nb_Soil_Chemistry_Suitable_Range_Stddev_Pop_Fields = {
  __typename?: 'nb_soil_chemistry_suitable_range_stddev_pop_fields';
  CEC_rmax?: Maybe<Scalars['Float']>;
  CEC_rmin?: Maybe<Scalars['Float']>;
  CaO_rmax?: Maybe<Scalars['Float']>;
  CaO_rmin?: Maybe<Scalars['Float']>;
  EC_rmax?: Maybe<Scalars['Float']>;
  EC_rmin?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmax?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmin?: Maybe<Scalars['Float']>;
  boric_acid_rmax?: Maybe<Scalars['Float']>;
  boric_acid_rmin?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: Maybe<Scalars['Float']>;
  humus_rmax?: Maybe<Scalars['Float']>;
  humus_rmin?: Maybe<Scalars['Float']>;
  kalium_rmax?: Maybe<Scalars['Float']>;
  kalium_rmin?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmax?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: Maybe<Scalars['Float']>;
  magnesia_rmax?: Maybe<Scalars['Float']>;
  magnesia_rmin?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: Maybe<Scalars['Float']>;
  manganese_rmax?: Maybe<Scalars['Float']>;
  manganese_rmin?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmax?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmin?: Maybe<Scalars['Float']>;
  pH_KCl_rmax?: Maybe<Scalars['Float']>;
  pH_KCl_rmin?: Maybe<Scalars['Float']>;
  pH_rmax?: Maybe<Scalars['Float']>;
  pH_rmin?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmax?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmin?: Maybe<Scalars['Float']>;
  silicic_acid_rmax?: Maybe<Scalars['Float']>;
  silicic_acid_rmin?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Nb_Soil_Chemistry_Suitable_Range_Stddev_Samp_Fields = {
  __typename?: 'nb_soil_chemistry_suitable_range_stddev_samp_fields';
  CEC_rmax?: Maybe<Scalars['Float']>;
  CEC_rmin?: Maybe<Scalars['Float']>;
  CaO_rmax?: Maybe<Scalars['Float']>;
  CaO_rmin?: Maybe<Scalars['Float']>;
  EC_rmax?: Maybe<Scalars['Float']>;
  EC_rmin?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmax?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmin?: Maybe<Scalars['Float']>;
  boric_acid_rmax?: Maybe<Scalars['Float']>;
  boric_acid_rmin?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: Maybe<Scalars['Float']>;
  humus_rmax?: Maybe<Scalars['Float']>;
  humus_rmin?: Maybe<Scalars['Float']>;
  kalium_rmax?: Maybe<Scalars['Float']>;
  kalium_rmin?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmax?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: Maybe<Scalars['Float']>;
  magnesia_rmax?: Maybe<Scalars['Float']>;
  magnesia_rmin?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: Maybe<Scalars['Float']>;
  manganese_rmax?: Maybe<Scalars['Float']>;
  manganese_rmin?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmax?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmin?: Maybe<Scalars['Float']>;
  pH_KCl_rmax?: Maybe<Scalars['Float']>;
  pH_KCl_rmin?: Maybe<Scalars['Float']>;
  pH_rmax?: Maybe<Scalars['Float']>;
  pH_rmin?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmax?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmin?: Maybe<Scalars['Float']>;
  silicic_acid_rmax?: Maybe<Scalars['Float']>;
  silicic_acid_rmin?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "nb_soil_chemistry_suitable_range" */
export type Nb_Soil_Chemistry_Suitable_Range_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Soil_Chemistry_Suitable_Range_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Soil_Chemistry_Suitable_Range_Stream_Cursor_Value_Input = {
  CEC_rmax?: InputMaybe<Scalars['Float']>;
  CEC_rmin?: InputMaybe<Scalars['Float']>;
  CaO_rmax?: InputMaybe<Scalars['Float']>;
  CaO_rmin?: InputMaybe<Scalars['Float']>;
  EC_rmax?: InputMaybe<Scalars['Float']>;
  EC_rmin?: InputMaybe<Scalars['Float']>;
  ammonia_nitro_rmax?: InputMaybe<Scalars['Float']>;
  ammonia_nitro_rmin?: InputMaybe<Scalars['Float']>;
  boric_acid_rmax?: InputMaybe<Scalars['Float']>;
  boric_acid_rmin?: InputMaybe<Scalars['Float']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  degree_of_base_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: InputMaybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: InputMaybe<Scalars['Float']>;
  humus_rmax?: InputMaybe<Scalars['Float']>;
  humus_rmin?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  kalium_rmax?: InputMaybe<Scalars['Float']>;
  kalium_rmin?: InputMaybe<Scalars['Float']>;
  key?: InputMaybe<Scalars['String']>;
  lime_magnesium_ratio_rmax?: InputMaybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: InputMaybe<Scalars['Float']>;
  magnesia_rmax?: InputMaybe<Scalars['Float']>;
  magnesia_rmin?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: InputMaybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: InputMaybe<Scalars['Float']>;
  manganese_rmax?: InputMaybe<Scalars['Float']>;
  manganese_rmin?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  nitrate_nitro_rmax?: InputMaybe<Scalars['Float']>;
  nitrate_nitro_rmin?: InputMaybe<Scalars['Float']>;
  pH_KCl_rmax?: InputMaybe<Scalars['Float']>;
  pH_KCl_rmin?: InputMaybe<Scalars['Float']>;
  pH_rmax?: InputMaybe<Scalars['Float']>;
  pH_rmin?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: InputMaybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: InputMaybe<Scalars['Float']>;
  phosphoric_acid_rmax?: InputMaybe<Scalars['Float']>;
  phosphoric_acid_rmin?: InputMaybe<Scalars['Float']>;
  silicic_acid_rmax?: InputMaybe<Scalars['Float']>;
  silicic_acid_rmin?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Soil_Chemistry_Suitable_Range_Sum_Fields = {
  __typename?: 'nb_soil_chemistry_suitable_range_sum_fields';
  CEC_rmax?: Maybe<Scalars['Float']>;
  CEC_rmin?: Maybe<Scalars['Float']>;
  CaO_rmax?: Maybe<Scalars['Float']>;
  CaO_rmin?: Maybe<Scalars['Float']>;
  EC_rmax?: Maybe<Scalars['Float']>;
  EC_rmin?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmax?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmin?: Maybe<Scalars['Float']>;
  boric_acid_rmax?: Maybe<Scalars['Float']>;
  boric_acid_rmin?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: Maybe<Scalars['Float']>;
  humus_rmax?: Maybe<Scalars['Float']>;
  humus_rmin?: Maybe<Scalars['Float']>;
  kalium_rmax?: Maybe<Scalars['Float']>;
  kalium_rmin?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmax?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: Maybe<Scalars['Float']>;
  magnesia_rmax?: Maybe<Scalars['Float']>;
  magnesia_rmin?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: Maybe<Scalars['Float']>;
  manganese_rmax?: Maybe<Scalars['Float']>;
  manganese_rmin?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmax?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmin?: Maybe<Scalars['Float']>;
  pH_KCl_rmax?: Maybe<Scalars['Float']>;
  pH_KCl_rmin?: Maybe<Scalars['Float']>;
  pH_rmax?: Maybe<Scalars['Float']>;
  pH_rmin?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmax?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmin?: Maybe<Scalars['Float']>;
  silicic_acid_rmax?: Maybe<Scalars['Float']>;
  silicic_acid_rmin?: Maybe<Scalars['Float']>;
};

/** update columns of table "nb_soil_chemistry_suitable_range" */
export enum Nb_Soil_Chemistry_Suitable_Range_Update_Column {
  /** column name */
  CecRmax = 'CEC_rmax',
  /** column name */
  CecRmin = 'CEC_rmin',
  /** column name */
  CaORmax = 'CaO_rmax',
  /** column name */
  CaORmin = 'CaO_rmin',
  /** column name */
  EcRmax = 'EC_rmax',
  /** column name */
  EcRmin = 'EC_rmin',
  /** column name */
  AmmoniaNitroRmax = 'ammonia_nitro_rmax',
  /** column name */
  AmmoniaNitroRmin = 'ammonia_nitro_rmin',
  /** column name */
  BoricAcidRmax = 'boric_acid_rmax',
  /** column name */
  BoricAcidRmin = 'boric_acid_rmin',
  /** column name */
  Created = 'created',
  /** column name */
  DegreeOfBaseSaturationRmax = 'degree_of_base_saturation_rmax',
  /** column name */
  DegreeOfBaseSaturationRmin = 'degree_of_base_saturation_rmin',
  /** column name */
  DegreeOfKaliumSaturationRmax = 'degree_of_kalium_saturation_rmax',
  /** column name */
  DegreeOfKaliumSaturationRmin = 'degree_of_kalium_saturation_rmin',
  /** column name */
  DegreeOfLimeSaturationRmax = 'degree_of_lime_saturation_rmax',
  /** column name */
  DegreeOfLimeSaturationRmin = 'degree_of_lime_saturation_rmin',
  /** column name */
  DegreeOfMagnesiaSaturationRmax = 'degree_of_magnesia_saturation_rmax',
  /** column name */
  DegreeOfMagnesiaSaturationRmin = 'degree_of_magnesia_saturation_rmin',
  /** column name */
  HumusRmax = 'humus_rmax',
  /** column name */
  HumusRmin = 'humus_rmin',
  /** column name */
  Id = 'id',
  /** column name */
  KaliumRmax = 'kalium_rmax',
  /** column name */
  KaliumRmin = 'kalium_rmin',
  /** column name */
  Key = 'key',
  /** column name */
  LimeMagnesiumRatioRmax = 'lime_magnesium_ratio_rmax',
  /** column name */
  LimeMagnesiumRatioRmin = 'lime_magnesium_ratio_rmin',
  /** column name */
  MagnesiaRmax = 'magnesia_rmax',
  /** column name */
  MagnesiaRmin = 'magnesia_rmin',
  /** column name */
  MagnesiumPotassiumRatioRmax = 'magnesium_potassium_ratio_rmax',
  /** column name */
  MagnesiumPotassiumRatioRmin = 'magnesium_potassium_ratio_rmin',
  /** column name */
  ManganeseRmax = 'manganese_rmax',
  /** column name */
  ManganeseRmin = 'manganese_rmin',
  /** column name */
  Name = 'name',
  /** column name */
  NitrateNitroRmax = 'nitrate_nitro_rmax',
  /** column name */
  NitrateNitroRmin = 'nitrate_nitro_rmin',
  /** column name */
  PHKClRmax = 'pH_KCl_rmax',
  /** column name */
  PHKClRmin = 'pH_KCl_rmin',
  /** column name */
  PHRmax = 'pH_rmax',
  /** column name */
  PHRmin = 'pH_rmin',
  /** column name */
  PhosphateAbsorptionCoeffRmax = 'phosphate_absorption_coeff_rmax',
  /** column name */
  PhosphateAbsorptionCoeffRmin = 'phosphate_absorption_coeff_rmin',
  /** column name */
  PhosphoricAcidRmax = 'phosphoric_acid_rmax',
  /** column name */
  PhosphoricAcidRmin = 'phosphoric_acid_rmin',
  /** column name */
  SilicicAcidRmax = 'silicic_acid_rmax',
  /** column name */
  SilicicAcidRmin = 'silicic_acid_rmin',
  /** column name */
  Updated = 'updated'
}

export type Nb_Soil_Chemistry_Suitable_Range_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Set_Input>;
  where: Nb_Soil_Chemistry_Suitable_Range_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Soil_Chemistry_Suitable_Range_Var_Pop_Fields = {
  __typename?: 'nb_soil_chemistry_suitable_range_var_pop_fields';
  CEC_rmax?: Maybe<Scalars['Float']>;
  CEC_rmin?: Maybe<Scalars['Float']>;
  CaO_rmax?: Maybe<Scalars['Float']>;
  CaO_rmin?: Maybe<Scalars['Float']>;
  EC_rmax?: Maybe<Scalars['Float']>;
  EC_rmin?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmax?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmin?: Maybe<Scalars['Float']>;
  boric_acid_rmax?: Maybe<Scalars['Float']>;
  boric_acid_rmin?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: Maybe<Scalars['Float']>;
  humus_rmax?: Maybe<Scalars['Float']>;
  humus_rmin?: Maybe<Scalars['Float']>;
  kalium_rmax?: Maybe<Scalars['Float']>;
  kalium_rmin?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmax?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: Maybe<Scalars['Float']>;
  magnesia_rmax?: Maybe<Scalars['Float']>;
  magnesia_rmin?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: Maybe<Scalars['Float']>;
  manganese_rmax?: Maybe<Scalars['Float']>;
  manganese_rmin?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmax?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmin?: Maybe<Scalars['Float']>;
  pH_KCl_rmax?: Maybe<Scalars['Float']>;
  pH_KCl_rmin?: Maybe<Scalars['Float']>;
  pH_rmax?: Maybe<Scalars['Float']>;
  pH_rmin?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmax?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmin?: Maybe<Scalars['Float']>;
  silicic_acid_rmax?: Maybe<Scalars['Float']>;
  silicic_acid_rmin?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Nb_Soil_Chemistry_Suitable_Range_Var_Samp_Fields = {
  __typename?: 'nb_soil_chemistry_suitable_range_var_samp_fields';
  CEC_rmax?: Maybe<Scalars['Float']>;
  CEC_rmin?: Maybe<Scalars['Float']>;
  CaO_rmax?: Maybe<Scalars['Float']>;
  CaO_rmin?: Maybe<Scalars['Float']>;
  EC_rmax?: Maybe<Scalars['Float']>;
  EC_rmin?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmax?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmin?: Maybe<Scalars['Float']>;
  boric_acid_rmax?: Maybe<Scalars['Float']>;
  boric_acid_rmin?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: Maybe<Scalars['Float']>;
  humus_rmax?: Maybe<Scalars['Float']>;
  humus_rmin?: Maybe<Scalars['Float']>;
  kalium_rmax?: Maybe<Scalars['Float']>;
  kalium_rmin?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmax?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: Maybe<Scalars['Float']>;
  magnesia_rmax?: Maybe<Scalars['Float']>;
  magnesia_rmin?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: Maybe<Scalars['Float']>;
  manganese_rmax?: Maybe<Scalars['Float']>;
  manganese_rmin?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmax?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmin?: Maybe<Scalars['Float']>;
  pH_KCl_rmax?: Maybe<Scalars['Float']>;
  pH_KCl_rmin?: Maybe<Scalars['Float']>;
  pH_rmax?: Maybe<Scalars['Float']>;
  pH_rmin?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmax?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmin?: Maybe<Scalars['Float']>;
  silicic_acid_rmax?: Maybe<Scalars['Float']>;
  silicic_acid_rmin?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Nb_Soil_Chemistry_Suitable_Range_Variance_Fields = {
  __typename?: 'nb_soil_chemistry_suitable_range_variance_fields';
  CEC_rmax?: Maybe<Scalars['Float']>;
  CEC_rmin?: Maybe<Scalars['Float']>;
  CaO_rmax?: Maybe<Scalars['Float']>;
  CaO_rmin?: Maybe<Scalars['Float']>;
  EC_rmax?: Maybe<Scalars['Float']>;
  EC_rmin?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmax?: Maybe<Scalars['Float']>;
  ammonia_nitro_rmin?: Maybe<Scalars['Float']>;
  boric_acid_rmax?: Maybe<Scalars['Float']>;
  boric_acid_rmin?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_base_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation_rmin?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmax?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation_rmin?: Maybe<Scalars['Float']>;
  humus_rmax?: Maybe<Scalars['Float']>;
  humus_rmin?: Maybe<Scalars['Float']>;
  kalium_rmax?: Maybe<Scalars['Float']>;
  kalium_rmin?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmax?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio_rmin?: Maybe<Scalars['Float']>;
  magnesia_rmax?: Maybe<Scalars['Float']>;
  magnesia_rmin?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmax?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio_rmin?: Maybe<Scalars['Float']>;
  manganese_rmax?: Maybe<Scalars['Float']>;
  manganese_rmin?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmax?: Maybe<Scalars['Float']>;
  nitrate_nitro_rmin?: Maybe<Scalars['Float']>;
  pH_KCl_rmax?: Maybe<Scalars['Float']>;
  pH_KCl_rmin?: Maybe<Scalars['Float']>;
  pH_rmax?: Maybe<Scalars['Float']>;
  pH_rmin?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmax?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff_rmin?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmax?: Maybe<Scalars['Float']>;
  phosphoric_acid_rmin?: Maybe<Scalars['Float']>;
  silicic_acid_rmax?: Maybe<Scalars['Float']>;
  silicic_acid_rmin?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Nb_Soil_Chemistry_Sum_Fields = {
  __typename?: 'nb_soil_chemistry_sum_fields';
  CEC?: Maybe<Scalars['Float']>;
  CaO?: Maybe<Scalars['Float']>;
  EC?: Maybe<Scalars['Float']>;
  ammonia_nitrogen?: Maybe<Scalars['Float']>;
  available_nitrogen?: Maybe<Scalars['Float']>;
  boric_acid?: Maybe<Scalars['Float']>;
  degree_of_base_saturation?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: Maybe<Scalars['Float']>;
  humus?: Maybe<Scalars['Float']>;
  kalium?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio?: Maybe<Scalars['Float']>;
  magnesia?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio?: Maybe<Scalars['Float']>;
  manganese?: Maybe<Scalars['Float']>;
  nitrate_nitrogen?: Maybe<Scalars['Float']>;
  pH?: Maybe<Scalars['Float']>;
  pH_KCl?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff?: Maybe<Scalars['Float']>;
  phosphoric_acid?: Maybe<Scalars['Float']>;
  silicic_acid?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Sum_Order_By = {
  CEC?: InputMaybe<Order_By>;
  CaO?: InputMaybe<Order_By>;
  EC?: InputMaybe<Order_By>;
  ammonia_nitrogen?: InputMaybe<Order_By>;
  available_nitrogen?: InputMaybe<Order_By>;
  boric_acid?: InputMaybe<Order_By>;
  degree_of_base_saturation?: InputMaybe<Order_By>;
  degree_of_kalium_saturation?: InputMaybe<Order_By>;
  degree_of_lime_saturation?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation?: InputMaybe<Order_By>;
  humus?: InputMaybe<Order_By>;
  kalium?: InputMaybe<Order_By>;
  lime_magnesium_ratio?: InputMaybe<Order_By>;
  magnesia?: InputMaybe<Order_By>;
  magnesium_potassium_ratio?: InputMaybe<Order_By>;
  manganese?: InputMaybe<Order_By>;
  nitrate_nitrogen?: InputMaybe<Order_By>;
  pH?: InputMaybe<Order_By>;
  pH_KCl?: InputMaybe<Order_By>;
  phosphate_absorption_coeff?: InputMaybe<Order_By>;
  phosphoric_acid?: InputMaybe<Order_By>;
  silicic_acid?: InputMaybe<Order_By>;
};

/** update columns of table "nb_soil_chemistry" */
export enum Nb_Soil_Chemistry_Update_Column {
  /** column name */
  Cec = 'CEC',
  /** column name */
  CaO = 'CaO',
  /** column name */
  Ec = 'EC',
  /** column name */
  AmmoniaNitrogen = 'ammonia_nitrogen',
  /** column name */
  AvailableNitrogen = 'available_nitrogen',
  /** column name */
  BoricAcid = 'boric_acid',
  /** column name */
  Comment = 'comment',
  /** column name */
  Created = 'created',
  /** column name */
  CreationDate = 'creation_date',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  DegreeOfBaseSaturation = 'degree_of_base_saturation',
  /** column name */
  DegreeOfKaliumSaturation = 'degree_of_kalium_saturation',
  /** column name */
  DegreeOfLimeSaturation = 'degree_of_lime_saturation',
  /** column name */
  DegreeOfMagnesiaSaturation = 'degree_of_magnesia_saturation',
  /** column name */
  Humus = 'humus',
  /** column name */
  Id = 'id',
  /** column name */
  Kalium = 'kalium',
  /** column name */
  LimeMagnesiumRatio = 'lime_magnesium_ratio',
  /** column name */
  Magnesia = 'magnesia',
  /** column name */
  MagnesiumPotassiumRatio = 'magnesium_potassium_ratio',
  /** column name */
  Manganese = 'manganese',
  /** column name */
  NitrateNitrogen = 'nitrate_nitrogen',
  /** column name */
  PH = 'pH',
  /** column name */
  PHKCl = 'pH_KCl',
  /** column name */
  PhosphateAbsorptionCoeff = 'phosphate_absorption_coeff',
  /** column name */
  PhosphoricAcid = 'phosphoric_acid',
  /** column name */
  ReceptionDate = 'reception_date',
  /** column name */
  SamplingDate = 'sampling_date',
  /** column name */
  SilicicAcid = 'silicic_acid',
  /** column name */
  SoilChemistryStandardId = 'soil_chemistry_standard_id',
  /** column name */
  SoilDiagnosis = 'soil_diagnosis',
  /** column name */
  SuitableRangeId = 'suitable_range_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Soil_Chemistry_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Soil_Chemistry_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Soil_Chemistry_Set_Input>;
  where: Nb_Soil_Chemistry_Bool_Exp;
};

/** 土壌化学成分分析値 */
export type Nb_Soil_Chemistry_Value = {
  __typename?: 'nb_soil_chemistry_value';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_soil_chemistry: Nb_Soil_Chemistry;
  /** An object relationship */
  nb_soil_chemistry_proper_range: Nb_Soil_Chemistry_Proper_Range;
  soil_chemistry_id: Scalars['uuid'];
  soil_chemistry_proper_range_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
  value?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Aggregate = {
  __typename?: 'nb_soil_chemistry_value_aggregate';
  aggregate?: Maybe<Nb_Soil_Chemistry_Value_Aggregate_Fields>;
  nodes: Array<Nb_Soil_Chemistry_Value>;
};

export type Nb_Soil_Chemistry_Value_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Soil_Chemistry_Value_Aggregate_Bool_Exp_Count>;
};

export type Nb_Soil_Chemistry_Value_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Aggregate_Fields = {
  __typename?: 'nb_soil_chemistry_value_aggregate_fields';
  avg?: Maybe<Nb_Soil_Chemistry_Value_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Soil_Chemistry_Value_Max_Fields>;
  min?: Maybe<Nb_Soil_Chemistry_Value_Min_Fields>;
  stddev?: Maybe<Nb_Soil_Chemistry_Value_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Soil_Chemistry_Value_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Soil_Chemistry_Value_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Soil_Chemistry_Value_Sum_Fields>;
  var_pop?: Maybe<Nb_Soil_Chemistry_Value_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Soil_Chemistry_Value_Var_Samp_Fields>;
  variance?: Maybe<Nb_Soil_Chemistry_Value_Variance_Fields>;
};


/** aggregate fields of "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Soil_Chemistry_Value_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Soil_Chemistry_Value_Max_Order_By>;
  min?: InputMaybe<Nb_Soil_Chemistry_Value_Min_Order_By>;
  stddev?: InputMaybe<Nb_Soil_Chemistry_Value_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Soil_Chemistry_Value_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Soil_Chemistry_Value_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Soil_Chemistry_Value_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Soil_Chemistry_Value_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Soil_Chemistry_Value_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Soil_Chemistry_Value_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Arr_Rel_Insert_Input = {
  data: Array<Nb_Soil_Chemistry_Value_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Chemistry_Value_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Soil_Chemistry_Value_Avg_Fields = {
  __typename?: 'nb_soil_chemistry_value_avg_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Avg_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_soil_chemistry_value". All fields are combined with a logical 'AND'. */
export type Nb_Soil_Chemistry_Value_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Bool_Exp>>;
  _not?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_soil_chemistry?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
  nb_soil_chemistry_proper_range?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
  soil_chemistry_id?: InputMaybe<Uuid_Comparison_Exp>;
  soil_chemistry_proper_range_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_soil_chemistry_value" */
export enum Nb_Soil_Chemistry_Value_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbSoilChemistryValuePkey = 'nb_soil_chemistry_value_pkey',
  /** unique or primary key constraint on columns "soil_chemistry_proper_range_id", "soil_chemistry_id" */
  NbSoilChemistryValueSoilChemistryIdSoilChemistryPrKey = 'nb_soil_chemistry_value_soil_chemistry_id_soil_chemistry_pr_key'
}

/** input type for incrementing numeric columns in table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Inc_Input = {
  value?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_soil_chemistry?: InputMaybe<Nb_Soil_Chemistry_Obj_Rel_Insert_Input>;
  nb_soil_chemistry_proper_range?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Obj_Rel_Insert_Input>;
  soil_chemistry_id?: InputMaybe<Scalars['uuid']>;
  soil_chemistry_proper_range_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Nb_Soil_Chemistry_Value_Max_Fields = {
  __typename?: 'nb_soil_chemistry_value_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  soil_chemistry_id?: Maybe<Scalars['uuid']>;
  soil_chemistry_proper_range_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  soil_chemistry_id?: InputMaybe<Order_By>;
  soil_chemistry_proper_range_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Soil_Chemistry_Value_Min_Fields = {
  __typename?: 'nb_soil_chemistry_value_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  soil_chemistry_id?: Maybe<Scalars['uuid']>;
  soil_chemistry_proper_range_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  soil_chemistry_id?: InputMaybe<Order_By>;
  soil_chemistry_proper_range_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Mutation_Response = {
  __typename?: 'nb_soil_chemistry_value_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Soil_Chemistry_Value>;
};

/** on_conflict condition type for table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_On_Conflict = {
  constraint: Nb_Soil_Chemistry_Value_Constraint;
  update_columns?: Array<Nb_Soil_Chemistry_Value_Update_Column>;
  where?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_soil_chemistry_value". */
export type Nb_Soil_Chemistry_Value_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_soil_chemistry?: InputMaybe<Nb_Soil_Chemistry_Order_By>;
  nb_soil_chemistry_proper_range?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Order_By>;
  soil_chemistry_id?: InputMaybe<Order_By>;
  soil_chemistry_proper_range_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_soil_chemistry_value */
export type Nb_Soil_Chemistry_Value_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_soil_chemistry_value" */
export enum Nb_Soil_Chemistry_Value_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  SoilChemistryId = 'soil_chemistry_id',
  /** column name */
  SoilChemistryProperRangeId = 'soil_chemistry_proper_range_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  soil_chemistry_id?: InputMaybe<Scalars['uuid']>;
  soil_chemistry_proper_range_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Nb_Soil_Chemistry_Value_Stddev_Fields = {
  __typename?: 'nb_soil_chemistry_value_stddev_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Stddev_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Soil_Chemistry_Value_Stddev_Pop_Fields = {
  __typename?: 'nb_soil_chemistry_value_stddev_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Stddev_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Soil_Chemistry_Value_Stddev_Samp_Fields = {
  __typename?: 'nb_soil_chemistry_value_stddev_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Stddev_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Soil_Chemistry_Value_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Soil_Chemistry_Value_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  soil_chemistry_id?: InputMaybe<Scalars['uuid']>;
  soil_chemistry_proper_range_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Nb_Soil_Chemistry_Value_Sum_Fields = {
  __typename?: 'nb_soil_chemistry_value_sum_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Sum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** update columns of table "nb_soil_chemistry_value" */
export enum Nb_Soil_Chemistry_Value_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  SoilChemistryId = 'soil_chemistry_id',
  /** column name */
  SoilChemistryProperRangeId = 'soil_chemistry_proper_range_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  Value = 'value'
}

export type Nb_Soil_Chemistry_Value_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Soil_Chemistry_Value_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Soil_Chemistry_Value_Set_Input>;
  where: Nb_Soil_Chemistry_Value_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Soil_Chemistry_Value_Var_Pop_Fields = {
  __typename?: 'nb_soil_chemistry_value_var_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Var_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Soil_Chemistry_Value_Var_Samp_Fields = {
  __typename?: 'nb_soil_chemistry_value_var_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Var_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Soil_Chemistry_Value_Variance_Fields = {
  __typename?: 'nb_soil_chemistry_value_variance_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_soil_chemistry_value" */
export type Nb_Soil_Chemistry_Value_Variance_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Nb_Soil_Chemistry_Var_Pop_Fields = {
  __typename?: 'nb_soil_chemistry_var_pop_fields';
  CEC?: Maybe<Scalars['Float']>;
  CaO?: Maybe<Scalars['Float']>;
  EC?: Maybe<Scalars['Float']>;
  ammonia_nitrogen?: Maybe<Scalars['Float']>;
  available_nitrogen?: Maybe<Scalars['Float']>;
  boric_acid?: Maybe<Scalars['Float']>;
  degree_of_base_saturation?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: Maybe<Scalars['Float']>;
  humus?: Maybe<Scalars['Float']>;
  kalium?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio?: Maybe<Scalars['Float']>;
  magnesia?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio?: Maybe<Scalars['Float']>;
  manganese?: Maybe<Scalars['Float']>;
  nitrate_nitrogen?: Maybe<Scalars['Float']>;
  pH?: Maybe<Scalars['Float']>;
  pH_KCl?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff?: Maybe<Scalars['Float']>;
  phosphoric_acid?: Maybe<Scalars['Float']>;
  silicic_acid?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Var_Pop_Order_By = {
  CEC?: InputMaybe<Order_By>;
  CaO?: InputMaybe<Order_By>;
  EC?: InputMaybe<Order_By>;
  ammonia_nitrogen?: InputMaybe<Order_By>;
  available_nitrogen?: InputMaybe<Order_By>;
  boric_acid?: InputMaybe<Order_By>;
  degree_of_base_saturation?: InputMaybe<Order_By>;
  degree_of_kalium_saturation?: InputMaybe<Order_By>;
  degree_of_lime_saturation?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation?: InputMaybe<Order_By>;
  humus?: InputMaybe<Order_By>;
  kalium?: InputMaybe<Order_By>;
  lime_magnesium_ratio?: InputMaybe<Order_By>;
  magnesia?: InputMaybe<Order_By>;
  magnesium_potassium_ratio?: InputMaybe<Order_By>;
  manganese?: InputMaybe<Order_By>;
  nitrate_nitrogen?: InputMaybe<Order_By>;
  pH?: InputMaybe<Order_By>;
  pH_KCl?: InputMaybe<Order_By>;
  phosphate_absorption_coeff?: InputMaybe<Order_By>;
  phosphoric_acid?: InputMaybe<Order_By>;
  silicic_acid?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Soil_Chemistry_Var_Samp_Fields = {
  __typename?: 'nb_soil_chemistry_var_samp_fields';
  CEC?: Maybe<Scalars['Float']>;
  CaO?: Maybe<Scalars['Float']>;
  EC?: Maybe<Scalars['Float']>;
  ammonia_nitrogen?: Maybe<Scalars['Float']>;
  available_nitrogen?: Maybe<Scalars['Float']>;
  boric_acid?: Maybe<Scalars['Float']>;
  degree_of_base_saturation?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: Maybe<Scalars['Float']>;
  humus?: Maybe<Scalars['Float']>;
  kalium?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio?: Maybe<Scalars['Float']>;
  magnesia?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio?: Maybe<Scalars['Float']>;
  manganese?: Maybe<Scalars['Float']>;
  nitrate_nitrogen?: Maybe<Scalars['Float']>;
  pH?: Maybe<Scalars['Float']>;
  pH_KCl?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff?: Maybe<Scalars['Float']>;
  phosphoric_acid?: Maybe<Scalars['Float']>;
  silicic_acid?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Var_Samp_Order_By = {
  CEC?: InputMaybe<Order_By>;
  CaO?: InputMaybe<Order_By>;
  EC?: InputMaybe<Order_By>;
  ammonia_nitrogen?: InputMaybe<Order_By>;
  available_nitrogen?: InputMaybe<Order_By>;
  boric_acid?: InputMaybe<Order_By>;
  degree_of_base_saturation?: InputMaybe<Order_By>;
  degree_of_kalium_saturation?: InputMaybe<Order_By>;
  degree_of_lime_saturation?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation?: InputMaybe<Order_By>;
  humus?: InputMaybe<Order_By>;
  kalium?: InputMaybe<Order_By>;
  lime_magnesium_ratio?: InputMaybe<Order_By>;
  magnesia?: InputMaybe<Order_By>;
  magnesium_potassium_ratio?: InputMaybe<Order_By>;
  manganese?: InputMaybe<Order_By>;
  nitrate_nitrogen?: InputMaybe<Order_By>;
  pH?: InputMaybe<Order_By>;
  pH_KCl?: InputMaybe<Order_By>;
  phosphate_absorption_coeff?: InputMaybe<Order_By>;
  phosphoric_acid?: InputMaybe<Order_By>;
  silicic_acid?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Soil_Chemistry_Variance_Fields = {
  __typename?: 'nb_soil_chemistry_variance_fields';
  CEC?: Maybe<Scalars['Float']>;
  CaO?: Maybe<Scalars['Float']>;
  EC?: Maybe<Scalars['Float']>;
  ammonia_nitrogen?: Maybe<Scalars['Float']>;
  available_nitrogen?: Maybe<Scalars['Float']>;
  boric_acid?: Maybe<Scalars['Float']>;
  degree_of_base_saturation?: Maybe<Scalars['Float']>;
  degree_of_kalium_saturation?: Maybe<Scalars['Float']>;
  degree_of_lime_saturation?: Maybe<Scalars['Float']>;
  degree_of_magnesia_saturation?: Maybe<Scalars['Float']>;
  humus?: Maybe<Scalars['Float']>;
  kalium?: Maybe<Scalars['Float']>;
  lime_magnesium_ratio?: Maybe<Scalars['Float']>;
  magnesia?: Maybe<Scalars['Float']>;
  magnesium_potassium_ratio?: Maybe<Scalars['Float']>;
  manganese?: Maybe<Scalars['Float']>;
  nitrate_nitrogen?: Maybe<Scalars['Float']>;
  pH?: Maybe<Scalars['Float']>;
  pH_KCl?: Maybe<Scalars['Float']>;
  phosphate_absorption_coeff?: Maybe<Scalars['Float']>;
  phosphoric_acid?: Maybe<Scalars['Float']>;
  silicic_acid?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_soil_chemistry" */
export type Nb_Soil_Chemistry_Variance_Order_By = {
  CEC?: InputMaybe<Order_By>;
  CaO?: InputMaybe<Order_By>;
  EC?: InputMaybe<Order_By>;
  ammonia_nitrogen?: InputMaybe<Order_By>;
  available_nitrogen?: InputMaybe<Order_By>;
  boric_acid?: InputMaybe<Order_By>;
  degree_of_base_saturation?: InputMaybe<Order_By>;
  degree_of_kalium_saturation?: InputMaybe<Order_By>;
  degree_of_lime_saturation?: InputMaybe<Order_By>;
  degree_of_magnesia_saturation?: InputMaybe<Order_By>;
  humus?: InputMaybe<Order_By>;
  kalium?: InputMaybe<Order_By>;
  lime_magnesium_ratio?: InputMaybe<Order_By>;
  magnesia?: InputMaybe<Order_By>;
  magnesium_potassium_ratio?: InputMaybe<Order_By>;
  manganese?: InputMaybe<Order_By>;
  nitrate_nitrogen?: InputMaybe<Order_By>;
  pH?: InputMaybe<Order_By>;
  pH_KCl?: InputMaybe<Order_By>;
  phosphate_absorption_coeff?: InputMaybe<Order_By>;
  phosphoric_acid?: InputMaybe<Order_By>;
  silicic_acid?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_soil_explanation" */
export type Nb_Soil_Explanation = {
  __typename?: 'nb_soil_explanation';
  created: Scalars['timestamptz'];
  explanation?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  nb_soil_characteristics: Array<Nb_Soil_Characteristic>;
  /** An aggregate relationship */
  nb_soil_characteristics_aggregate: Nb_Soil_Characteristic_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_soil_explanation" */
export type Nb_Soil_ExplanationNb_Soil_CharacteristicsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Characteristic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Characteristic_Order_By>>;
  where?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
};


/** columns and relationships of "nb_soil_explanation" */
export type Nb_Soil_ExplanationNb_Soil_Characteristics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Characteristic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Characteristic_Order_By>>;
  where?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
};

/** aggregated selection of "nb_soil_explanation" */
export type Nb_Soil_Explanation_Aggregate = {
  __typename?: 'nb_soil_explanation_aggregate';
  aggregate?: Maybe<Nb_Soil_Explanation_Aggregate_Fields>;
  nodes: Array<Nb_Soil_Explanation>;
};

/** aggregate fields of "nb_soil_explanation" */
export type Nb_Soil_Explanation_Aggregate_Fields = {
  __typename?: 'nb_soil_explanation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Soil_Explanation_Max_Fields>;
  min?: Maybe<Nb_Soil_Explanation_Min_Fields>;
};


/** aggregate fields of "nb_soil_explanation" */
export type Nb_Soil_Explanation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Soil_Explanation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_soil_explanation". All fields are combined with a logical 'AND'. */
export type Nb_Soil_Explanation_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Soil_Explanation_Bool_Exp>>;
  _not?: InputMaybe<Nb_Soil_Explanation_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Soil_Explanation_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  explanation?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_soil_characteristics?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
  nb_soil_characteristics_aggregate?: InputMaybe<Nb_Soil_Characteristic_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_soil_explanation" */
export enum Nb_Soil_Explanation_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbSoilExplanationPkey = 'nb_soil_explanation_pkey'
}

/** input type for inserting data into table "nb_soil_explanation" */
export type Nb_Soil_Explanation_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_soil_characteristics?: InputMaybe<Nb_Soil_Characteristic_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Soil_Explanation_Max_Fields = {
  __typename?: 'nb_soil_explanation_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Soil_Explanation_Min_Fields = {
  __typename?: 'nb_soil_explanation_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_soil_explanation" */
export type Nb_Soil_Explanation_Mutation_Response = {
  __typename?: 'nb_soil_explanation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Soil_Explanation>;
};

/** input type for inserting object relation for remote table "nb_soil_explanation" */
export type Nb_Soil_Explanation_Obj_Rel_Insert_Input = {
  data: Nb_Soil_Explanation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Explanation_On_Conflict>;
};

/** on_conflict condition type for table "nb_soil_explanation" */
export type Nb_Soil_Explanation_On_Conflict = {
  constraint: Nb_Soil_Explanation_Constraint;
  update_columns?: Array<Nb_Soil_Explanation_Update_Column>;
  where?: InputMaybe<Nb_Soil_Explanation_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_soil_explanation". */
export type Nb_Soil_Explanation_Order_By = {
  created?: InputMaybe<Order_By>;
  explanation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_soil_characteristics_aggregate?: InputMaybe<Nb_Soil_Characteristic_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_soil_explanation */
export type Nb_Soil_Explanation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_soil_explanation" */
export enum Nb_Soil_Explanation_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Explanation = 'explanation',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_soil_explanation" */
export type Nb_Soil_Explanation_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_soil_explanation" */
export type Nb_Soil_Explanation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Soil_Explanation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Soil_Explanation_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_soil_explanation" */
export enum Nb_Soil_Explanation_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Explanation = 'explanation',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Soil_Explanation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Soil_Explanation_Set_Input>;
  where: Nb_Soil_Explanation_Bool_Exp;
};

/** columns and relationships of "nb_soil_physics" */
export type Nb_Soil_Physics = {
  __typename?: 'nb_soil_physics';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  check_date: Scalars['date'];
  created: Scalars['timestamptz'];
  creator_name: Scalars['String'];
  hardness?: Maybe<Scalars['float8']>;
  id: Scalars['uuid'];
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  resultss?: Maybe<Scalars['jsonb']>;
  updated: Scalars['timestamptz'];
  user_field_id: Scalars['uuid'];
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "nb_soil_physics" */
export type Nb_Soil_PhysicsResultssArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "nb_soil_physics" */
export type Nb_Soil_Physics_Aggregate = {
  __typename?: 'nb_soil_physics_aggregate';
  aggregate?: Maybe<Nb_Soil_Physics_Aggregate_Fields>;
  nodes: Array<Nb_Soil_Physics>;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Nb_Soil_Physics_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Nb_Soil_Physics_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Nb_Soil_Physics_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Nb_Soil_Physics_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Nb_Soil_Physics_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Nb_Soil_Physics_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Nb_Soil_Physics_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Nb_Soil_Physics_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Nb_Soil_Physics_Aggregate_Bool_Exp_Var_Samp>;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp_Avg = {
  arguments: Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp_Corr = {
  arguments: Nb_Soil_Physics_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Soil_Physics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Nb_Soil_Physics_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp_Max = {
  arguments: Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp_Min = {
  arguments: Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp_Sum = {
  arguments: Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Soil_Physics_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "nb_soil_physics" */
export type Nb_Soil_Physics_Aggregate_Fields = {
  __typename?: 'nb_soil_physics_aggregate_fields';
  avg?: Maybe<Nb_Soil_Physics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Soil_Physics_Max_Fields>;
  min?: Maybe<Nb_Soil_Physics_Min_Fields>;
  stddev?: Maybe<Nb_Soil_Physics_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Soil_Physics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Soil_Physics_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Soil_Physics_Sum_Fields>;
  var_pop?: Maybe<Nb_Soil_Physics_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Soil_Physics_Var_Samp_Fields>;
  variance?: Maybe<Nb_Soil_Physics_Variance_Fields>;
};


/** aggregate fields of "nb_soil_physics" */
export type Nb_Soil_Physics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Soil_Physics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_soil_physics" */
export type Nb_Soil_Physics_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Soil_Physics_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Soil_Physics_Max_Order_By>;
  min?: InputMaybe<Nb_Soil_Physics_Min_Order_By>;
  stddev?: InputMaybe<Nb_Soil_Physics_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Soil_Physics_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Soil_Physics_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Soil_Physics_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Soil_Physics_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Soil_Physics_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Soil_Physics_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Nb_Soil_Physics_Append_Input = {
  resultss?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "nb_soil_physics" */
export type Nb_Soil_Physics_Arr_Rel_Insert_Input = {
  data: Array<Nb_Soil_Physics_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Soil_Physics_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Soil_Physics_Avg_Fields = {
  __typename?: 'nb_soil_physics_avg_fields';
  hardness?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_soil_physics" */
export type Nb_Soil_Physics_Avg_Order_By = {
  hardness?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_soil_physics". All fields are combined with a logical 'AND'. */
export type Nb_Soil_Physics_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Soil_Physics_Bool_Exp>>;
  _not?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Soil_Physics_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  check_date?: InputMaybe<Date_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_name?: InputMaybe<String_Comparison_Exp>;
  hardness?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  resultss?: InputMaybe<Jsonb_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_soil_physics" */
export enum Nb_Soil_Physics_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbSoilPhysicsPkey = 'nb_soil_physics_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Nb_Soil_Physics_Delete_At_Path_Input = {
  resultss?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Nb_Soil_Physics_Delete_Elem_Input = {
  resultss?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Nb_Soil_Physics_Delete_Key_Input = {
  resultss?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "nb_soil_physics" */
export type Nb_Soil_Physics_Inc_Input = {
  hardness?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "nb_soil_physics" */
export type Nb_Soil_Physics_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  check_date?: InputMaybe<Scalars['date']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  hardness?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  resultss?: InputMaybe<Scalars['jsonb']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Soil_Physics_Max_Fields = {
  __typename?: 'nb_soil_physics_max_fields';
  check_date?: Maybe<Scalars['date']>;
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  hardness?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_soil_physics" */
export type Nb_Soil_Physics_Max_Order_By = {
  check_date?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  hardness?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Soil_Physics_Min_Fields = {
  __typename?: 'nb_soil_physics_min_fields';
  check_date?: Maybe<Scalars['date']>;
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  hardness?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_field_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_soil_physics" */
export type Nb_Soil_Physics_Min_Order_By = {
  check_date?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  hardness?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_soil_physics" */
export type Nb_Soil_Physics_Mutation_Response = {
  __typename?: 'nb_soil_physics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Soil_Physics>;
};

/** on_conflict condition type for table "nb_soil_physics" */
export type Nb_Soil_Physics_On_Conflict = {
  constraint: Nb_Soil_Physics_Constraint;
  update_columns?: Array<Nb_Soil_Physics_Update_Column>;
  where?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_soil_physics". */
export type Nb_Soil_Physics_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  check_date?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  hardness?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  resultss?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_field_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_soil_physics */
export type Nb_Soil_Physics_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Nb_Soil_Physics_Prepend_Input = {
  resultss?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "nb_soil_physics" */
export enum Nb_Soil_Physics_Select_Column {
  /** column name */
  CheckDate = 'check_date',
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  Hardness = 'hardness',
  /** column name */
  Id = 'id',
  /** column name */
  Resultss = 'resultss',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  UserId = 'user_id'
}

/** select "nb_soil_physics_aggregate_bool_exp_avg_arguments_columns" columns of table "nb_soil_physics" */
export enum Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Hardness = 'hardness'
}

/** select "nb_soil_physics_aggregate_bool_exp_corr_arguments_columns" columns of table "nb_soil_physics" */
export enum Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Hardness = 'hardness'
}

/** select "nb_soil_physics_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "nb_soil_physics" */
export enum Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Hardness = 'hardness'
}

/** select "nb_soil_physics_aggregate_bool_exp_max_arguments_columns" columns of table "nb_soil_physics" */
export enum Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Hardness = 'hardness'
}

/** select "nb_soil_physics_aggregate_bool_exp_min_arguments_columns" columns of table "nb_soil_physics" */
export enum Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Hardness = 'hardness'
}

/** select "nb_soil_physics_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "nb_soil_physics" */
export enum Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Hardness = 'hardness'
}

/** select "nb_soil_physics_aggregate_bool_exp_sum_arguments_columns" columns of table "nb_soil_physics" */
export enum Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Hardness = 'hardness'
}

/** select "nb_soil_physics_aggregate_bool_exp_var_samp_arguments_columns" columns of table "nb_soil_physics" */
export enum Nb_Soil_Physics_Select_Column_Nb_Soil_Physics_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Hardness = 'hardness'
}

/** input type for updating data in table "nb_soil_physics" */
export type Nb_Soil_Physics_Set_Input = {
  check_date?: InputMaybe<Scalars['date']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  hardness?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  resultss?: InputMaybe<Scalars['jsonb']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Nb_Soil_Physics_Stddev_Fields = {
  __typename?: 'nb_soil_physics_stddev_fields';
  hardness?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_soil_physics" */
export type Nb_Soil_Physics_Stddev_Order_By = {
  hardness?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Soil_Physics_Stddev_Pop_Fields = {
  __typename?: 'nb_soil_physics_stddev_pop_fields';
  hardness?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_soil_physics" */
export type Nb_Soil_Physics_Stddev_Pop_Order_By = {
  hardness?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Soil_Physics_Stddev_Samp_Fields = {
  __typename?: 'nb_soil_physics_stddev_samp_fields';
  hardness?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_soil_physics" */
export type Nb_Soil_Physics_Stddev_Samp_Order_By = {
  hardness?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_soil_physics" */
export type Nb_Soil_Physics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Soil_Physics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Soil_Physics_Stream_Cursor_Value_Input = {
  check_date?: InputMaybe<Scalars['date']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  hardness?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  resultss?: InputMaybe<Scalars['jsonb']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_field_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nb_Soil_Physics_Sum_Fields = {
  __typename?: 'nb_soil_physics_sum_fields';
  hardness?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "nb_soil_physics" */
export type Nb_Soil_Physics_Sum_Order_By = {
  hardness?: InputMaybe<Order_By>;
};

/** update columns of table "nb_soil_physics" */
export enum Nb_Soil_Physics_Update_Column {
  /** column name */
  CheckDate = 'check_date',
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  Hardness = 'hardness',
  /** column name */
  Id = 'id',
  /** column name */
  Resultss = 'resultss',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserFieldId = 'user_field_id',
  /** column name */
  UserId = 'user_id'
}

export type Nb_Soil_Physics_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Nb_Soil_Physics_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Nb_Soil_Physics_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Nb_Soil_Physics_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Nb_Soil_Physics_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Soil_Physics_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Nb_Soil_Physics_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Soil_Physics_Set_Input>;
  where: Nb_Soil_Physics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Soil_Physics_Var_Pop_Fields = {
  __typename?: 'nb_soil_physics_var_pop_fields';
  hardness?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_soil_physics" */
export type Nb_Soil_Physics_Var_Pop_Order_By = {
  hardness?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Soil_Physics_Var_Samp_Fields = {
  __typename?: 'nb_soil_physics_var_samp_fields';
  hardness?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_soil_physics" */
export type Nb_Soil_Physics_Var_Samp_Order_By = {
  hardness?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Soil_Physics_Variance_Fields = {
  __typename?: 'nb_soil_physics_variance_fields';
  hardness?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_soil_physics" */
export type Nb_Soil_Physics_Variance_Order_By = {
  hardness?: InputMaybe<Order_By>;
};

/** 農作業のタスクを管理するテーブル */
export type Nb_Task_Type = {
  __typename?: 'nb_task_type';
  /** An array relationship */
  nb_finished_ufield_works: Array<Nb_Finished_Ufield_Work>;
  /** An aggregate relationship */
  nb_finished_ufield_works_aggregate: Nb_Finished_Ufield_Work_Aggregate;
  /** An array relationship */
  nb_performed_works: Array<Nb_Performed_Work>;
  /** An aggregate relationship */
  nb_performed_works_aggregate: Nb_Performed_Work_Aggregate;
  /** An array relationship */
  nb_tasktype_company_links: Array<Nb_Tasktype_Company_Link>;
  /** An aggregate relationship */
  nb_tasktype_company_links_aggregate: Nb_Tasktype_Company_Link_Aggregate;
  /** An array relationship */
  nbtrk_contexts: Array<Nbtrk_Context>;
  /** An aggregate relationship */
  nbtrk_contexts_aggregate: Nbtrk_Context_Aggregate;
  type: Scalars['String'];
};


/** 農作業のタスクを管理するテーブル */
export type Nb_Task_TypeNb_Finished_Ufield_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


/** 農作業のタスクを管理するテーブル */
export type Nb_Task_TypeNb_Finished_Ufield_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


/** 農作業のタスクを管理するテーブル */
export type Nb_Task_TypeNb_Performed_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** 農作業のタスクを管理するテーブル */
export type Nb_Task_TypeNb_Performed_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** 農作業のタスクを管理するテーブル */
export type Nb_Task_TypeNb_Tasktype_Company_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Tasktype_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Tasktype_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
};


/** 農作業のタスクを管理するテーブル */
export type Nb_Task_TypeNb_Tasktype_Company_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Tasktype_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Tasktype_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
};


/** 農作業のタスクを管理するテーブル */
export type Nb_Task_TypeNbtrk_ContextsArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Context_Order_By>>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};


/** 農作業のタスクを管理するテーブル */
export type Nb_Task_TypeNbtrk_Contexts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Context_Order_By>>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};

/** aggregated selection of "nb_task_type" */
export type Nb_Task_Type_Aggregate = {
  __typename?: 'nb_task_type_aggregate';
  aggregate?: Maybe<Nb_Task_Type_Aggregate_Fields>;
  nodes: Array<Nb_Task_Type>;
};

/** aggregate fields of "nb_task_type" */
export type Nb_Task_Type_Aggregate_Fields = {
  __typename?: 'nb_task_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Task_Type_Max_Fields>;
  min?: Maybe<Nb_Task_Type_Min_Fields>;
};


/** aggregate fields of "nb_task_type" */
export type Nb_Task_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Task_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_task_type". All fields are combined with a logical 'AND'. */
export type Nb_Task_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Task_Type_Bool_Exp>>;
  _not?: InputMaybe<Nb_Task_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Task_Type_Bool_Exp>>;
  nb_finished_ufield_works?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
  nb_finished_ufield_works_aggregate?: InputMaybe<Nb_Finished_Ufield_Work_Aggregate_Bool_Exp>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Bool_Exp>;
  nb_tasktype_company_links?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
  nb_tasktype_company_links_aggregate?: InputMaybe<Nb_Tasktype_Company_Link_Aggregate_Bool_Exp>;
  nbtrk_contexts?: InputMaybe<Nbtrk_Context_Bool_Exp>;
  nbtrk_contexts_aggregate?: InputMaybe<Nbtrk_Context_Aggregate_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_task_type" */
export enum Nb_Task_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  NbTaskTypePkey = 'nb_task_type_pkey'
}

/** input type for inserting data into table "nb_task_type" */
export type Nb_Task_Type_Insert_Input = {
  nb_finished_ufield_works?: InputMaybe<Nb_Finished_Ufield_Work_Arr_Rel_Insert_Input>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Arr_Rel_Insert_Input>;
  nb_tasktype_company_links?: InputMaybe<Nb_Tasktype_Company_Link_Arr_Rel_Insert_Input>;
  nbtrk_contexts?: InputMaybe<Nbtrk_Context_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nb_Task_Type_Max_Fields = {
  __typename?: 'nb_task_type_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nb_Task_Type_Min_Fields = {
  __typename?: 'nb_task_type_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nb_task_type" */
export type Nb_Task_Type_Mutation_Response = {
  __typename?: 'nb_task_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Task_Type>;
};

/** input type for inserting object relation for remote table "nb_task_type" */
export type Nb_Task_Type_Obj_Rel_Insert_Input = {
  data: Nb_Task_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Task_Type_On_Conflict>;
};

/** on_conflict condition type for table "nb_task_type" */
export type Nb_Task_Type_On_Conflict = {
  constraint: Nb_Task_Type_Constraint;
  update_columns?: Array<Nb_Task_Type_Update_Column>;
  where?: InputMaybe<Nb_Task_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_task_type". */
export type Nb_Task_Type_Order_By = {
  nb_finished_ufield_works_aggregate?: InputMaybe<Nb_Finished_Ufield_Work_Aggregate_Order_By>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Order_By>;
  nb_tasktype_company_links_aggregate?: InputMaybe<Nb_Tasktype_Company_Link_Aggregate_Order_By>;
  nbtrk_contexts_aggregate?: InputMaybe<Nbtrk_Context_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_task_type */
export type Nb_Task_Type_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "nb_task_type" */
export enum Nb_Task_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "nb_task_type" */
export type Nb_Task_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nb_task_type" */
export type Nb_Task_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Task_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Task_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nb_task_type" */
export enum Nb_Task_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type Nb_Task_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Task_Type_Set_Input>;
  where: Nb_Task_Type_Bool_Exp;
};

/** columns and relationships of "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link = {
  __typename?: 'nb_tasktype_company_link';
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An object relationship */
  nb_task_type: Nb_Task_Type;
  task_type: Scalars['String'];
};

/** aggregated selection of "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_Aggregate = {
  __typename?: 'nb_tasktype_company_link_aggregate';
  aggregate?: Maybe<Nb_Tasktype_Company_Link_Aggregate_Fields>;
  nodes: Array<Nb_Tasktype_Company_Link>;
};

export type Nb_Tasktype_Company_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Tasktype_Company_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Tasktype_Company_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Tasktype_Company_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_Aggregate_Fields = {
  __typename?: 'nb_tasktype_company_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Tasktype_Company_Link_Max_Fields>;
  min?: Maybe<Nb_Tasktype_Company_Link_Min_Fields>;
};


/** aggregate fields of "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Tasktype_Company_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Tasktype_Company_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Tasktype_Company_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Tasktype_Company_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Tasktype_Company_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_tasktype_company_link". All fields are combined with a logical 'AND'. */
export type Nb_Tasktype_Company_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Tasktype_Company_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Tasktype_Company_Link_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Bool_Exp>;
  task_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_tasktype_company_link" */
export enum Nb_Tasktype_Company_Link_Constraint {
  /** unique or primary key constraint on columns "task_type", "company_id" */
  NbTasktypeCompanyLinkCompanyIdTaskTypeKey = 'nb_tasktype_company_link_company_id_task_type_key',
  /** unique or primary key constraint on columns "id" */
  NbTasktypeCompanyLinkPkey = 'nb_tasktype_company_link_pkey'
}

/** input type for inserting data into table "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_Insert_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Obj_Rel_Insert_Input>;
  task_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nb_Tasktype_Company_Link_Max_Fields = {
  __typename?: 'nb_tasktype_company_link_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  task_type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Tasktype_Company_Link_Min_Fields = {
  __typename?: 'nb_tasktype_company_link_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  task_type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_Mutation_Response = {
  __typename?: 'nb_tasktype_company_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Tasktype_Company_Link>;
};

/** on_conflict condition type for table "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_On_Conflict = {
  constraint: Nb_Tasktype_Company_Link_Constraint;
  update_columns?: Array<Nb_Tasktype_Company_Link_Update_Column>;
  where?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_tasktype_company_link". */
export type Nb_Tasktype_Company_Link_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Order_By>;
  task_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_tasktype_company_link */
export type Nb_Tasktype_Company_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_tasktype_company_link" */
export enum Nb_Tasktype_Company_Link_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  TaskType = 'task_type'
}

/** input type for updating data in table "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  task_type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nb_tasktype_company_link" */
export type Nb_Tasktype_Company_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Tasktype_Company_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Tasktype_Company_Link_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  task_type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nb_tasktype_company_link" */
export enum Nb_Tasktype_Company_Link_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  TaskType = 'task_type'
}

export type Nb_Tasktype_Company_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Tasktype_Company_Link_Set_Input>;
  where: Nb_Tasktype_Company_Link_Bool_Exp;
};

/** columns and relationships of "nb_ufattr_link" */
export type Nb_Ufattr_Link = {
  __typename?: 'nb_ufattr_link';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  /** An object relationship */
  nb_ufield_attribute: Nb_Ufield_Attribute;
  ufield_attribute_id: Scalars['uuid'];
  ufield_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_ufattr_link" */
export type Nb_Ufattr_Link_Aggregate = {
  __typename?: 'nb_ufattr_link_aggregate';
  aggregate?: Maybe<Nb_Ufattr_Link_Aggregate_Fields>;
  nodes: Array<Nb_Ufattr_Link>;
};

export type Nb_Ufattr_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Ufattr_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Ufattr_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Ufattr_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_ufattr_link" */
export type Nb_Ufattr_Link_Aggregate_Fields = {
  __typename?: 'nb_ufattr_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Ufattr_Link_Max_Fields>;
  min?: Maybe<Nb_Ufattr_Link_Min_Fields>;
};


/** aggregate fields of "nb_ufattr_link" */
export type Nb_Ufattr_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Ufattr_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_ufattr_link" */
export type Nb_Ufattr_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Ufattr_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Ufattr_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_ufattr_link" */
export type Nb_Ufattr_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Ufattr_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Ufattr_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_ufattr_link". All fields are combined with a logical 'AND'. */
export type Nb_Ufattr_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Ufattr_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Ufattr_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nb_ufield_attribute?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
  ufield_attribute_id?: InputMaybe<Uuid_Comparison_Exp>;
  ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_ufattr_link" */
export enum Nb_Ufattr_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbUfattrLinkPkey = 'nb_ufattr_link_pkey'
}

/** input type for inserting data into table "nb_ufattr_link" */
export type Nb_Ufattr_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  nb_ufield_attribute?: InputMaybe<Nb_Ufield_Attribute_Obj_Rel_Insert_Input>;
  ufield_attribute_id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Ufattr_Link_Max_Fields = {
  __typename?: 'nb_ufattr_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_attribute_id?: Maybe<Scalars['uuid']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_ufattr_link" */
export type Nb_Ufattr_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_attribute_id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Ufattr_Link_Min_Fields = {
  __typename?: 'nb_ufattr_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_attribute_id?: Maybe<Scalars['uuid']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_ufattr_link" */
export type Nb_Ufattr_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_attribute_id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_ufattr_link" */
export type Nb_Ufattr_Link_Mutation_Response = {
  __typename?: 'nb_ufattr_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Ufattr_Link>;
};

/** on_conflict condition type for table "nb_ufattr_link" */
export type Nb_Ufattr_Link_On_Conflict = {
  constraint: Nb_Ufattr_Link_Constraint;
  update_columns?: Array<Nb_Ufattr_Link_Update_Column>;
  where?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_ufattr_link". */
export type Nb_Ufattr_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nb_ufield_attribute?: InputMaybe<Nb_Ufield_Attribute_Order_By>;
  ufield_attribute_id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_ufattr_link */
export type Nb_Ufattr_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_ufattr_link" */
export enum Nb_Ufattr_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldAttributeId = 'ufield_attribute_id',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_ufattr_link" */
export type Nb_Ufattr_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_attribute_id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_ufattr_link" */
export type Nb_Ufattr_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Ufattr_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Ufattr_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_attribute_id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_ufattr_link" */
export enum Nb_Ufattr_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldAttributeId = 'ufield_attribute_id',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Ufattr_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Ufattr_Link_Set_Input>;
  where: Nb_Ufattr_Link_Bool_Exp;
};

/** columns and relationships of "nb_ufg_link" */
export type Nb_Ufg_Link = {
  __typename?: 'nb_ufg_link';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  /** An object relationship */
  nb_ufield_group: Nb_Ufield_Group;
  ufield_group_id: Scalars['uuid'];
  ufield_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_ufg_link" */
export type Nb_Ufg_Link_Aggregate = {
  __typename?: 'nb_ufg_link_aggregate';
  aggregate?: Maybe<Nb_Ufg_Link_Aggregate_Fields>;
  nodes: Array<Nb_Ufg_Link>;
};

export type Nb_Ufg_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Ufg_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Ufg_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Ufg_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_ufg_link" */
export type Nb_Ufg_Link_Aggregate_Fields = {
  __typename?: 'nb_ufg_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Ufg_Link_Max_Fields>;
  min?: Maybe<Nb_Ufg_Link_Min_Fields>;
};


/** aggregate fields of "nb_ufg_link" */
export type Nb_Ufg_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Ufg_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_ufg_link" */
export type Nb_Ufg_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Ufg_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Ufg_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_ufg_link" */
export type Nb_Ufg_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Ufg_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Ufg_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_ufg_link". All fields are combined with a logical 'AND'. */
export type Nb_Ufg_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Ufg_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Ufg_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
  ufield_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_ufg_link" */
export enum Nb_Ufg_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbUfgLinkPkey = 'nb_ufg_link_pkey',
  /** unique or primary key constraint on columns "ufield_id", "ufield_group_id" */
  NbUfgLinkUfieldIdUfieldGroupIdKey = 'nb_ufg_link_ufield_id_ufield_group_id_key'
}

/** input type for inserting data into table "nb_ufg_link" */
export type Nb_Ufg_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Obj_Rel_Insert_Input>;
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Ufg_Link_Max_Fields = {
  __typename?: 'nb_ufg_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_group_id?: Maybe<Scalars['uuid']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_ufg_link" */
export type Nb_Ufg_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_group_id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Ufg_Link_Min_Fields = {
  __typename?: 'nb_ufg_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_group_id?: Maybe<Scalars['uuid']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_ufg_link" */
export type Nb_Ufg_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_group_id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_ufg_link" */
export type Nb_Ufg_Link_Mutation_Response = {
  __typename?: 'nb_ufg_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Ufg_Link>;
};

/** on_conflict condition type for table "nb_ufg_link" */
export type Nb_Ufg_Link_On_Conflict = {
  constraint: Nb_Ufg_Link_Constraint;
  update_columns?: Array<Nb_Ufg_Link_Update_Column>;
  where?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_ufg_link". */
export type Nb_Ufg_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Order_By>;
  ufield_group_id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_ufg_link */
export type Nb_Ufg_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_ufg_link" */
export enum Nb_Ufg_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldGroupId = 'ufield_group_id',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_ufg_link" */
export type Nb_Ufg_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_ufg_link" */
export type Nb_Ufg_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Ufg_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Ufg_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_ufg_link" */
export enum Nb_Ufg_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldGroupId = 'ufield_group_id',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Ufg_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Ufg_Link_Set_Input>;
  where: Nb_Ufg_Link_Bool_Exp;
};

/** columns and relationships of "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link = {
  __typename?: 'nb_ufg_member_link';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_ufield_group: Nb_Ufield_Group;
  /** An object relationship */
  nbpm_user_attr_link: Nbpm_User_Attr_Link;
  ufield_group_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
  user_attr_link_id: Scalars['uuid'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};

/** aggregated selection of "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_Aggregate = {
  __typename?: 'nb_ufg_member_link_aggregate';
  aggregate?: Maybe<Nb_Ufg_Member_Link_Aggregate_Fields>;
  nodes: Array<Nb_Ufg_Member_Link>;
};

export type Nb_Ufg_Member_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Ufg_Member_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Ufg_Member_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Ufg_Member_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_Aggregate_Fields = {
  __typename?: 'nb_ufg_member_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Ufg_Member_Link_Max_Fields>;
  min?: Maybe<Nb_Ufg_Member_Link_Min_Fields>;
};


/** aggregate fields of "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Ufg_Member_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Ufg_Member_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Ufg_Member_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Ufg_Member_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Ufg_Member_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_ufg_member_link". All fields are combined with a logical 'AND'. */
export type Nb_Ufg_Member_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Ufg_Member_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Ufg_Member_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
  nbpm_user_attr_link?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
  ufield_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_attr_link_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_ufg_member_link" */
export enum Nb_Ufg_Member_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbUfgMemberLinkPkey = 'nb_ufg_member_link_pkey',
  /** unique or primary key constraint on columns "user_attr_link_id", "ufield_group_id" */
  NbUfgMemberLinkUserAttrLinkIdUfieldGroupIdKey = 'nb_ufg_member_link_user_attr_link_id_ufield_group_id_key'
}

/** input type for inserting data into table "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Obj_Rel_Insert_Input>;
  nbpm_user_attr_link?: InputMaybe<Nbpm_User_Attr_Link_Obj_Rel_Insert_Input>;
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_attr_link_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Ufg_Member_Link_Max_Fields = {
  __typename?: 'nb_ufg_member_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_group_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_attr_link_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_group_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_attr_link_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Ufg_Member_Link_Min_Fields = {
  __typename?: 'nb_ufg_member_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_group_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_attr_link_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_group_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_attr_link_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_Mutation_Response = {
  __typename?: 'nb_ufg_member_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Ufg_Member_Link>;
};

/** on_conflict condition type for table "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_On_Conflict = {
  constraint: Nb_Ufg_Member_Link_Constraint;
  update_columns?: Array<Nb_Ufg_Member_Link_Update_Column>;
  where?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_ufg_member_link". */
export type Nb_Ufg_Member_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_ufield_group?: InputMaybe<Nb_Ufield_Group_Order_By>;
  nbpm_user_attr_link?: InputMaybe<Nbpm_User_Attr_Link_Order_By>;
  ufield_group_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_attr_link_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_ufg_member_link */
export type Nb_Ufg_Member_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_ufg_member_link" */
export enum Nb_Ufg_Member_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldGroupId = 'ufield_group_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserAttrLinkId = 'user_attr_link_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_attr_link_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_ufg_member_link" */
export type Nb_Ufg_Member_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Ufg_Member_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Ufg_Member_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_group_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_attr_link_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_ufg_member_link" */
export enum Nb_Ufg_Member_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldGroupId = 'ufield_group_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserAttrLinkId = 'user_attr_link_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Ufg_Member_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Ufg_Member_Link_Set_Input>;
  where: Nb_Ufg_Member_Link_Bool_Exp;
};

/** columns and relationships of "nb_ufield" */
export type Nb_Ufield = {
  __typename?: 'nb_ufield';
  /** An object relationship */
  app_user_active?: Maybe<App_User_Active>;
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: Maybe<Scalars['float8']>;
  centroid?: Maybe<Scalars['geometry']>;
  created: Scalars['timestamptz'];
  creator_name: Scalars['String'];
  field_name: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  nb_conditioner_works: Array<Nb_Conditioner_Work>;
  /** An aggregate relationship */
  nb_conditioner_works_aggregate: Nb_Conditioner_Work_Aggregate;
  /** An array relationship */
  nb_cultivation_plans: Array<Nb_Cultivation_Plan>;
  /** An aggregate relationship */
  nb_cultivation_plans_aggregate: Nb_Cultivation_Plan_Aggregate;
  /** An array relationship */
  nb_field_file_loads: Array<Nb_Field_File_Load>;
  /** An aggregate relationship */
  nb_field_file_loads_aggregate: Nb_Field_File_Load_Aggregate;
  /** An array relationship */
  nb_field_files: Array<Nb_Field_File>;
  /** An aggregate relationship */
  nb_field_files_aggregate: Nb_Field_File_Aggregate;
  /** An array relationship */
  nb_field_orthoimgs: Array<Nb_Field_Orthoimg>;
  /** An aggregate relationship */
  nb_field_orthoimgs_aggregate: Nb_Field_Orthoimg_Aggregate;
  /** An array relationship */
  nb_field_picture_loads: Array<Nb_Field_Picture_Load>;
  /** An aggregate relationship */
  nb_field_picture_loads_aggregate: Nb_Field_Picture_Load_Aggregate;
  /** An array relationship */
  nb_field_pictures: Array<Nb_Field_Picture>;
  /** An aggregate relationship */
  nb_field_pictures_aggregate: Nb_Field_Picture_Aggregate;
  /** An array relationship */
  nb_field_properties: Array<Nb_Field_Property>;
  /** An aggregate relationship */
  nb_field_properties_aggregate: Nb_Field_Property_Aggregate;
  /** An array relationship */
  nb_field_sensings: Array<Nb_Field_Sensing>;
  /** An aggregate relationship */
  nb_field_sensings_aggregate: Nb_Field_Sensing_Aggregate;
  /** An array relationship */
  nb_field_water_gates: Array<Nb_Field_Water_Gate>;
  /** An aggregate relationship */
  nb_field_water_gates_aggregate: Nb_Field_Water_Gate_Aggregate;
  /** An array relationship */
  nb_finished_ufield_works: Array<Nb_Finished_Ufield_Work>;
  /** An aggregate relationship */
  nb_finished_ufield_works_aggregate: Nb_Finished_Ufield_Work_Aggregate;
  /** An array relationship */
  nb_memo_ufield_links: Array<Nb_Memo_Ufield_Link>;
  /** An aggregate relationship */
  nb_memo_ufield_links_aggregate: Nb_Memo_Ufield_Link_Aggregate;
  /** An array relationship */
  nb_mfield_links: Array<Nb_Mfield_Link>;
  /** An aggregate relationship */
  nb_mfield_links_aggregate: Nb_Mfield_Link_Aggregate;
  /** An array relationship */
  nb_performed_works: Array<Nb_Performed_Work>;
  /** An aggregate relationship */
  nb_performed_works_aggregate: Nb_Performed_Work_Aggregate;
  /** An object relationship */
  nb_postgis_version?: Maybe<Master_Nb_Postgis_Version>;
  /** An array relationship */
  nb_raster_image_sensings: Array<Nb_Raster_Image_Sensing>;
  /** An aggregate relationship */
  nb_raster_image_sensings_aggregate: Nb_Raster_Image_Sensing_Aggregate;
  /** An array relationship */
  nb_soil_chemistries: Array<Nb_Soil_Chemistry>;
  /** An aggregate relationship */
  nb_soil_chemistries_aggregate: Nb_Soil_Chemistry_Aggregate;
  /** An array relationship */
  nb_soil_physics: Array<Nb_Soil_Physics>;
  /** An aggregate relationship */
  nb_soil_physics_aggregate: Nb_Soil_Physics_Aggregate;
  /** An array relationship */
  nb_ufattr_links: Array<Nb_Ufattr_Link>;
  /** An aggregate relationship */
  nb_ufattr_links_aggregate: Nb_Ufattr_Link_Aggregate;
  /** An array relationship */
  nb_ufg_links: Array<Nb_Ufg_Link>;
  /** An aggregate relationship */
  nb_ufg_links_aggregate: Nb_Ufg_Link_Aggregate;
  /** An array relationship */
  nb_ufield_company_links: Array<Nb_Ufield_Company_Link>;
  /** An aggregate relationship */
  nb_ufield_company_links_aggregate: Nb_Ufield_Company_Link_Aggregate;
  /** An object relationship */
  nb_ufield_history?: Maybe<Nb_Ufield_History>;
  /** An array relationship */
  nbbe_microclimates: Array<Nbbe_Microclimate>;
  /** An aggregate relationship */
  nbbe_microclimates_aggregate: Nbbe_Microclimate_Aggregate;
  polygon_field: Scalars['geometry'];
  /** area_sqmなどpostgisを使うデータを生成したときのpostgisバージョンを保持 */
  postgis_version_id?: Maybe<Scalars['uuid']>;
  updated: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Conditioner_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Conditioner_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Cultivation_PlansArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Cultivation_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_File_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_File_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_FilesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_OrthoimgsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_Orthoimgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_Picture_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_Picture_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_PicturesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_Pictures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_PropertiesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Property_Order_By>>;
  where?: InputMaybe<Nb_Field_Property_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_Properties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Property_Order_By>>;
  where?: InputMaybe<Nb_Field_Property_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_SensingsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_Sensings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_Water_GatesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Field_Water_Gates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Finished_Ufield_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Finished_Ufield_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Memo_Ufield_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufield_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Memo_Ufield_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufield_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Mfield_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Link_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Mfield_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Link_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Performed_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Performed_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Raster_Image_SensingsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Raster_Image_Sensings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Soil_ChemistriesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Soil_Chemistries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Soil_PhysicsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Physics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Physics_Order_By>>;
  where?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Soil_Physics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Physics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Physics_Order_By>>;
  where?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Ufattr_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufattr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufattr_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Ufattr_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufattr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufattr_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Ufg_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Ufg_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Ufield_Company_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNb_Ufield_Company_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNbbe_MicroclimatesArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Microclimate_Order_By>>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};


/** columns and relationships of "nb_ufield" */
export type Nb_UfieldNbbe_Microclimates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Microclimate_Order_By>>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};

/** aggregated selection of "nb_ufield" */
export type Nb_Ufield_Aggregate = {
  __typename?: 'nb_ufield_aggregate';
  aggregate?: Maybe<Nb_Ufield_Aggregate_Fields>;
  nodes: Array<Nb_Ufield>;
};

export type Nb_Ufield_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Nb_Ufield_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Nb_Ufield_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Nb_Ufield_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Nb_Ufield_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Nb_Ufield_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Nb_Ufield_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Nb_Ufield_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Nb_Ufield_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Nb_Ufield_Aggregate_Bool_Exp_Var_Samp>;
};

export type Nb_Ufield_Aggregate_Bool_Exp_Avg = {
  arguments: Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Ufield_Aggregate_Bool_Exp_Corr = {
  arguments: Nb_Ufield_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Ufield_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Nb_Ufield_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Ufield_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Nb_Ufield_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Nb_Ufield_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Ufield_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Nb_Ufield_Aggregate_Bool_Exp_Max = {
  arguments: Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Ufield_Aggregate_Bool_Exp_Min = {
  arguments: Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Ufield_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Ufield_Aggregate_Bool_Exp_Sum = {
  arguments: Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nb_Ufield_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "nb_ufield" */
export type Nb_Ufield_Aggregate_Fields = {
  __typename?: 'nb_ufield_aggregate_fields';
  avg?: Maybe<Nb_Ufield_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Ufield_Max_Fields>;
  min?: Maybe<Nb_Ufield_Min_Fields>;
  stddev?: Maybe<Nb_Ufield_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Ufield_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Ufield_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Ufield_Sum_Fields>;
  var_pop?: Maybe<Nb_Ufield_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Ufield_Var_Samp_Fields>;
  variance?: Maybe<Nb_Ufield_Variance_Fields>;
};


/** aggregate fields of "nb_ufield" */
export type Nb_Ufield_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Ufield_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_ufield" */
export type Nb_Ufield_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Ufield_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Ufield_Max_Order_By>;
  min?: InputMaybe<Nb_Ufield_Min_Order_By>;
  stddev?: InputMaybe<Nb_Ufield_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Ufield_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Ufield_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Ufield_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Ufield_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Ufield_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Ufield_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_ufield" */
export type Nb_Ufield_Arr_Rel_Insert_Input = {
  data: Array<Nb_Ufield_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Ufield_On_Conflict>;
};

/** columns and relationships of "nb_ufield_attribute" */
export type Nb_Ufield_Attribute = {
  __typename?: 'nb_ufield_attribute';
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An array relationship */
  nb_ufattr_links: Array<Nb_Ufattr_Link>;
  /** An aggregate relationship */
  nb_ufattr_links_aggregate: Nb_Ufattr_Link_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_ufield_attribute" */
export type Nb_Ufield_AttributeNb_Ufattr_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufattr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufattr_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield_attribute" */
export type Nb_Ufield_AttributeNb_Ufattr_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufattr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufattr_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
};

/** aggregated selection of "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Aggregate = {
  __typename?: 'nb_ufield_attribute_aggregate';
  aggregate?: Maybe<Nb_Ufield_Attribute_Aggregate_Fields>;
  nodes: Array<Nb_Ufield_Attribute>;
};

export type Nb_Ufield_Attribute_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Ufield_Attribute_Aggregate_Bool_Exp_Count>;
};

export type Nb_Ufield_Attribute_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Ufield_Attribute_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Aggregate_Fields = {
  __typename?: 'nb_ufield_attribute_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Ufield_Attribute_Max_Fields>;
  min?: Maybe<Nb_Ufield_Attribute_Min_Fields>;
};


/** aggregate fields of "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Ufield_Attribute_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Ufield_Attribute_Max_Order_By>;
  min?: InputMaybe<Nb_Ufield_Attribute_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Arr_Rel_Insert_Input = {
  data: Array<Nb_Ufield_Attribute_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Ufield_Attribute_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_ufield_attribute". All fields are combined with a logical 'AND'. */
export type Nb_Ufield_Attribute_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Ufield_Attribute_Bool_Exp>>;
  _not?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Ufield_Attribute_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_ufattr_links?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
  nb_ufattr_links_aggregate?: InputMaybe<Nb_Ufattr_Link_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_ufield_attribute" */
export enum Nb_Ufield_Attribute_Constraint {
  /** unique or primary key constraint on columns "name", "company_id" */
  NbUfieldAttributeNameCompanyIdKey = 'nb_ufield_attribute_name_company_id_key',
  /** unique or primary key constraint on columns "id" */
  NbUfieldAttributePkey = 'nb_ufield_attribute_pkey'
}

/** input type for inserting data into table "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Insert_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_ufattr_links?: InputMaybe<Nb_Ufattr_Link_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Ufield_Attribute_Max_Fields = {
  __typename?: 'nb_ufield_attribute_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Ufield_Attribute_Min_Fields = {
  __typename?: 'nb_ufield_attribute_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Mutation_Response = {
  __typename?: 'nb_ufield_attribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Ufield_Attribute>;
};

/** input type for inserting object relation for remote table "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Obj_Rel_Insert_Input = {
  data: Nb_Ufield_Attribute_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Ufield_Attribute_On_Conflict>;
};

/** on_conflict condition type for table "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_On_Conflict = {
  constraint: Nb_Ufield_Attribute_Constraint;
  update_columns?: Array<Nb_Ufield_Attribute_Update_Column>;
  where?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_ufield_attribute". */
export type Nb_Ufield_Attribute_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_ufattr_links_aggregate?: InputMaybe<Nb_Ufattr_Link_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_ufield_attribute */
export type Nb_Ufield_Attribute_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_ufield_attribute" */
export enum Nb_Ufield_Attribute_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_ufield_attribute" */
export type Nb_Ufield_Attribute_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Ufield_Attribute_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Ufield_Attribute_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_ufield_attribute" */
export enum Nb_Ufield_Attribute_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

export type Nb_Ufield_Attribute_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Ufield_Attribute_Set_Input>;
  where: Nb_Ufield_Attribute_Bool_Exp;
};

/** aggregate avg on columns */
export type Nb_Ufield_Avg_Fields = {
  __typename?: 'nb_ufield_avg_fields';
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_ufield" */
export type Nb_Ufield_Avg_Order_By = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_ufield". All fields are combined with a logical 'AND'. */
export type Nb_Ufield_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Ufield_Bool_Exp>>;
  _not?: InputMaybe<Nb_Ufield_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Ufield_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  area_sqm?: InputMaybe<Float8_Comparison_Exp>;
  centroid?: InputMaybe<Geometry_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_name?: InputMaybe<String_Comparison_Exp>;
  field_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_conditioner_works?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
  nb_conditioner_works_aggregate?: InputMaybe<Nb_Conditioner_Work_Aggregate_Bool_Exp>;
  nb_cultivation_plans?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_cultivation_plans_aggregate?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Bool_Exp>;
  nb_field_file_loads?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
  nb_field_file_loads_aggregate?: InputMaybe<Nb_Field_File_Load_Aggregate_Bool_Exp>;
  nb_field_files?: InputMaybe<Nb_Field_File_Bool_Exp>;
  nb_field_files_aggregate?: InputMaybe<Nb_Field_File_Aggregate_Bool_Exp>;
  nb_field_orthoimgs?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
  nb_field_orthoimgs_aggregate?: InputMaybe<Nb_Field_Orthoimg_Aggregate_Bool_Exp>;
  nb_field_picture_loads?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
  nb_field_picture_loads_aggregate?: InputMaybe<Nb_Field_Picture_Load_Aggregate_Bool_Exp>;
  nb_field_pictures?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
  nb_field_pictures_aggregate?: InputMaybe<Nb_Field_Picture_Aggregate_Bool_Exp>;
  nb_field_properties?: InputMaybe<Nb_Field_Property_Bool_Exp>;
  nb_field_properties_aggregate?: InputMaybe<Nb_Field_Property_Aggregate_Bool_Exp>;
  nb_field_sensings?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
  nb_field_sensings_aggregate?: InputMaybe<Nb_Field_Sensing_Aggregate_Bool_Exp>;
  nb_field_water_gates?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
  nb_field_water_gates_aggregate?: InputMaybe<Nb_Field_Water_Gate_Aggregate_Bool_Exp>;
  nb_finished_ufield_works?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
  nb_finished_ufield_works_aggregate?: InputMaybe<Nb_Finished_Ufield_Work_Aggregate_Bool_Exp>;
  nb_memo_ufield_links?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
  nb_memo_ufield_links_aggregate?: InputMaybe<Nb_Memo_Ufield_Link_Aggregate_Bool_Exp>;
  nb_mfield_links?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
  nb_mfield_links_aggregate?: InputMaybe<Nb_Mfield_Link_Aggregate_Bool_Exp>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Bool_Exp>;
  nb_postgis_version?: InputMaybe<Master_Nb_Postgis_Version_Bool_Exp>;
  nb_raster_image_sensings?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
  nb_raster_image_sensings_aggregate?: InputMaybe<Nb_Raster_Image_Sensing_Aggregate_Bool_Exp>;
  nb_soil_chemistries?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
  nb_soil_chemistries_aggregate?: InputMaybe<Nb_Soil_Chemistry_Aggregate_Bool_Exp>;
  nb_soil_physics?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
  nb_soil_physics_aggregate?: InputMaybe<Nb_Soil_Physics_Aggregate_Bool_Exp>;
  nb_ufattr_links?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
  nb_ufattr_links_aggregate?: InputMaybe<Nb_Ufattr_Link_Aggregate_Bool_Exp>;
  nb_ufg_links?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
  nb_ufg_links_aggregate?: InputMaybe<Nb_Ufg_Link_Aggregate_Bool_Exp>;
  nb_ufield_company_links?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
  nb_ufield_company_links_aggregate?: InputMaybe<Nb_Ufield_Company_Link_Aggregate_Bool_Exp>;
  nb_ufield_history?: InputMaybe<Nb_Ufield_History_Bool_Exp>;
  nbbe_microclimates?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
  nbbe_microclimates_aggregate?: InputMaybe<Nbbe_Microclimate_Aggregate_Bool_Exp>;
  polygon_field?: InputMaybe<Geometry_Comparison_Exp>;
  postgis_version_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link = {
  __typename?: 'nb_ufield_company_link';
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  ufield_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_Aggregate = {
  __typename?: 'nb_ufield_company_link_aggregate';
  aggregate?: Maybe<Nb_Ufield_Company_Link_Aggregate_Fields>;
  nodes: Array<Nb_Ufield_Company_Link>;
};

export type Nb_Ufield_Company_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Ufield_Company_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Ufield_Company_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Ufield_Company_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_Aggregate_Fields = {
  __typename?: 'nb_ufield_company_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Ufield_Company_Link_Max_Fields>;
  min?: Maybe<Nb_Ufield_Company_Link_Min_Fields>;
};


/** aggregate fields of "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Ufield_Company_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Ufield_Company_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Ufield_Company_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Ufield_Company_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Ufield_Company_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_ufield_company_link". All fields are combined with a logical 'AND'. */
export type Nb_Ufield_Company_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Ufield_Company_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Ufield_Company_Link_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_ufield_company_link" */
export enum Nb_Ufield_Company_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbUfieldCompanyLinkPkey = 'nb_ufield_company_link_pkey'
}

/** input type for inserting data into table "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_Insert_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Ufield_Company_Link_Max_Fields = {
  __typename?: 'nb_ufield_company_link_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Ufield_Company_Link_Min_Fields = {
  __typename?: 'nb_ufield_company_link_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_Mutation_Response = {
  __typename?: 'nb_ufield_company_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Ufield_Company_Link>;
};

/** on_conflict condition type for table "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_On_Conflict = {
  constraint: Nb_Ufield_Company_Link_Constraint;
  update_columns?: Array<Nb_Ufield_Company_Link_Update_Column>;
  where?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_ufield_company_link". */
export type Nb_Ufield_Company_Link_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_ufield_company_link */
export type Nb_Ufield_Company_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_ufield_company_link" */
export enum Nb_Ufield_Company_Link_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_ufield_company_link" */
export type Nb_Ufield_Company_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Ufield_Company_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Ufield_Company_Link_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_ufield_company_link" */
export enum Nb_Ufield_Company_Link_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Ufield_Company_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Ufield_Company_Link_Set_Input>;
  where: Nb_Ufield_Company_Link_Bool_Exp;
};

/** unique or primary key constraints on table "nb_ufield" */
export enum Nb_Ufield_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbUfieldPkey = 'nb_ufield_pkey'
}

/** columns and relationships of "nb_ufield_group" */
export type Nb_Ufield_Group = {
  __typename?: 'nb_ufield_group';
  /** An array relationship */
  application_user_invitation_to_companies: Array<Application_User_Invitation_To_Company>;
  /** An aggregate relationship */
  application_user_invitation_to_companies_aggregate: Application_User_Invitation_To_Company_Aggregate;
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An array relationship */
  nb_memo_ufg_links: Array<Nb_Memo_Ufg_Link>;
  /** An aggregate relationship */
  nb_memo_ufg_links_aggregate: Nb_Memo_Ufg_Link_Aggregate;
  /** An array relationship */
  nb_ufg_links: Array<Nb_Ufg_Link>;
  /** An aggregate relationship */
  nb_ufg_links_aggregate: Nb_Ufg_Link_Aggregate;
  /** An array relationship */
  nb_ufg_member_links: Array<Nb_Ufg_Member_Link>;
  /** An aggregate relationship */
  nb_ufg_member_links_aggregate: Nb_Ufg_Member_Link_Aggregate;
  updated: Scalars['timestamptz'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nb_ufield_group" */
export type Nb_Ufield_GroupApplication_User_Invitation_To_CompaniesArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


/** columns and relationships of "nb_ufield_group" */
export type Nb_Ufield_GroupApplication_User_Invitation_To_Companies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


/** columns and relationships of "nb_ufield_group" */
export type Nb_Ufield_GroupNb_Memo_Ufg_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield_group" */
export type Nb_Ufield_GroupNb_Memo_Ufg_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield_group" */
export type Nb_Ufield_GroupNb_Ufg_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield_group" */
export type Nb_Ufield_GroupNb_Ufg_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield_group" */
export type Nb_Ufield_GroupNb_Ufg_Member_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Member_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Member_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
};


/** columns and relationships of "nb_ufield_group" */
export type Nb_Ufield_GroupNb_Ufg_Member_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Member_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Member_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
};

/** aggregated selection of "nb_ufield_group" */
export type Nb_Ufield_Group_Aggregate = {
  __typename?: 'nb_ufield_group_aggregate';
  aggregate?: Maybe<Nb_Ufield_Group_Aggregate_Fields>;
  nodes: Array<Nb_Ufield_Group>;
};

export type Nb_Ufield_Group_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Ufield_Group_Aggregate_Bool_Exp_Count>;
};

export type Nb_Ufield_Group_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Ufield_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_ufield_group" */
export type Nb_Ufield_Group_Aggregate_Fields = {
  __typename?: 'nb_ufield_group_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Ufield_Group_Max_Fields>;
  min?: Maybe<Nb_Ufield_Group_Min_Fields>;
};


/** aggregate fields of "nb_ufield_group" */
export type Nb_Ufield_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Ufield_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_ufield_group" */
export type Nb_Ufield_Group_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Ufield_Group_Max_Order_By>;
  min?: InputMaybe<Nb_Ufield_Group_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_ufield_group" */
export type Nb_Ufield_Group_Arr_Rel_Insert_Input = {
  data: Array<Nb_Ufield_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Ufield_Group_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_ufield_group". All fields are combined with a logical 'AND'. */
export type Nb_Ufield_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Ufield_Group_Bool_Exp>>;
  _not?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Ufield_Group_Bool_Exp>>;
  application_user_invitation_to_companies?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
  application_user_invitation_to_companies_aggregate?: InputMaybe<Application_User_Invitation_To_Company_Aggregate_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_memo_ufg_links?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
  nb_memo_ufg_links_aggregate?: InputMaybe<Nb_Memo_Ufg_Link_Aggregate_Bool_Exp>;
  nb_ufg_links?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
  nb_ufg_links_aggregate?: InputMaybe<Nb_Ufg_Link_Aggregate_Bool_Exp>;
  nb_ufg_member_links?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
  nb_ufg_member_links_aggregate?: InputMaybe<Nb_Ufg_Member_Link_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_ufield_group" */
export enum Nb_Ufield_Group_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbUfieldGroupPkey = 'nb_ufield_group_pkey'
}

/** input type for inserting data into table "nb_ufield_group" */
export type Nb_Ufield_Group_Insert_Input = {
  application_user_invitation_to_companies?: InputMaybe<Application_User_Invitation_To_Company_Arr_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_memo_ufg_links?: InputMaybe<Nb_Memo_Ufg_Link_Arr_Rel_Insert_Input>;
  nb_ufg_links?: InputMaybe<Nb_Ufg_Link_Arr_Rel_Insert_Input>;
  nb_ufg_member_links?: InputMaybe<Nb_Ufg_Member_Link_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Ufield_Group_Max_Fields = {
  __typename?: 'nb_ufield_group_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_ufield_group" */
export type Nb_Ufield_Group_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Ufield_Group_Min_Fields = {
  __typename?: 'nb_ufield_group_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_ufield_group" */
export type Nb_Ufield_Group_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_ufield_group" */
export type Nb_Ufield_Group_Mutation_Response = {
  __typename?: 'nb_ufield_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Ufield_Group>;
};

/** input type for inserting object relation for remote table "nb_ufield_group" */
export type Nb_Ufield_Group_Obj_Rel_Insert_Input = {
  data: Nb_Ufield_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Ufield_Group_On_Conflict>;
};

/** on_conflict condition type for table "nb_ufield_group" */
export type Nb_Ufield_Group_On_Conflict = {
  constraint: Nb_Ufield_Group_Constraint;
  update_columns?: Array<Nb_Ufield_Group_Update_Column>;
  where?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_ufield_group". */
export type Nb_Ufield_Group_Order_By = {
  application_user_invitation_to_companies_aggregate?: InputMaybe<Application_User_Invitation_To_Company_Aggregate_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_memo_ufg_links_aggregate?: InputMaybe<Nb_Memo_Ufg_Link_Aggregate_Order_By>;
  nb_ufg_links_aggregate?: InputMaybe<Nb_Ufg_Link_Aggregate_Order_By>;
  nb_ufg_member_links_aggregate?: InputMaybe<Nb_Ufg_Member_Link_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_ufield_group */
export type Nb_Ufield_Group_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_ufield_group" */
export enum Nb_Ufield_Group_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nb_ufield_group" */
export type Nb_Ufield_Group_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_ufield_group" */
export type Nb_Ufield_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Ufield_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Ufield_Group_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_ufield_group" */
export enum Nb_Ufield_Group_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Ufield_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Ufield_Group_Set_Input>;
  where: Nb_Ufield_Group_Bool_Exp;
};

/** ユーザ圃場とマスタ圃場のひも付きを管理するテーブル */
export type Nb_Ufield_History = {
  __typename?: 'nb_ufield_history';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /**
   *
   * データサンプル
   * [
   *     {
   *         "polygon_uuid": "12345678-1234-1234-1234-123456789012",
   *         "mfield_id": "12345678-1234-1234-1234-123456789012",
   *         "mfield_version_id":"12345678-1234-1234-1234-123456789012",
   *         "postgis_version_id": "12345678-1234-1234-1234-123456789012",
   *         "updated": "2022-01-01T12:34:56+0900",
   *         "updated_app_user_active_id":"12345678-1234-1234-1234-123456789012"
   *     }
   *     ,...
   * ]
   * polygon_uuid : 筆ポリゴンで付与されているUUID(nb_mfield.keyと同じ)
   * mfield_id : マスタ圃場のUUID。マスタ圃場更新時に参照先が削除される場合がある）
   * mfield_version_id : 上記マスタ圃場作成時のバージョン情報 (マスタ圃場更新時もバージョン情報は消えない)
   * post_gis_version_id : ユーザ圃場更新時のpostgisバージョン
   * updated : 更新を実施した日時(timeaware)
   * updated_app_user_active_id : マニュアル操作したユーザのuuid、システム自動更新時は ”system_automatic_update”とする。 更新実施者を知ることで、更新の妥当性に懸念が出た時の調査材料とする。
   *
   */
  mfield_history: Scalars['jsonb'];
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  /** nb_ufield.id 1対1の関係となる */
  ufield_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};


/** ユーザ圃場とマスタ圃場のひも付きを管理するテーブル */
export type Nb_Ufield_HistoryMfield_HistoryArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "nb_ufield_history" */
export type Nb_Ufield_History_Aggregate = {
  __typename?: 'nb_ufield_history_aggregate';
  aggregate?: Maybe<Nb_Ufield_History_Aggregate_Fields>;
  nodes: Array<Nb_Ufield_History>;
};

/** aggregate fields of "nb_ufield_history" */
export type Nb_Ufield_History_Aggregate_Fields = {
  __typename?: 'nb_ufield_history_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Ufield_History_Max_Fields>;
  min?: Maybe<Nb_Ufield_History_Min_Fields>;
};


/** aggregate fields of "nb_ufield_history" */
export type Nb_Ufield_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Ufield_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Nb_Ufield_History_Append_Input = {
  /**
   *
   * データサンプル
   * [
   *     {
   *         "polygon_uuid": "12345678-1234-1234-1234-123456789012",
   *         "mfield_id": "12345678-1234-1234-1234-123456789012",
   *         "mfield_version_id":"12345678-1234-1234-1234-123456789012",
   *         "postgis_version_id": "12345678-1234-1234-1234-123456789012",
   *         "updated": "2022-01-01T12:34:56+0900",
   *         "updated_app_user_active_id":"12345678-1234-1234-1234-123456789012"
   *     }
   *     ,...
   * ]
   * polygon_uuid : 筆ポリゴンで付与されているUUID(nb_mfield.keyと同じ)
   * mfield_id : マスタ圃場のUUID。マスタ圃場更新時に参照先が削除される場合がある）
   * mfield_version_id : 上記マスタ圃場作成時のバージョン情報 (マスタ圃場更新時もバージョン情報は消えない)
   * post_gis_version_id : ユーザ圃場更新時のpostgisバージョン
   * updated : 更新を実施した日時(timeaware)
   * updated_app_user_active_id : マニュアル操作したユーザのuuid、システム自動更新時は ”system_automatic_update”とする。 更新実施者を知ることで、更新の妥当性に懸念が出た時の調査材料とする。
   *
   */
  mfield_history?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "nb_ufield_history". All fields are combined with a logical 'AND'. */
export type Nb_Ufield_History_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Ufield_History_Bool_Exp>>;
  _not?: InputMaybe<Nb_Ufield_History_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Ufield_History_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mfield_history?: InputMaybe<Jsonb_Comparison_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_ufield_history" */
export enum Nb_Ufield_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbUfieldHistoryPkey = 'nb_ufield_history_pkey',
  /** unique or primary key constraint on columns "ufield_id" */
  NbUfieldHistoryUfieldIdKey = 'nb_ufield_history_ufield_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Nb_Ufield_History_Delete_At_Path_Input = {
  /**
   *
   * データサンプル
   * [
   *     {
   *         "polygon_uuid": "12345678-1234-1234-1234-123456789012",
   *         "mfield_id": "12345678-1234-1234-1234-123456789012",
   *         "mfield_version_id":"12345678-1234-1234-1234-123456789012",
   *         "postgis_version_id": "12345678-1234-1234-1234-123456789012",
   *         "updated": "2022-01-01T12:34:56+0900",
   *         "updated_app_user_active_id":"12345678-1234-1234-1234-123456789012"
   *     }
   *     ,...
   * ]
   * polygon_uuid : 筆ポリゴンで付与されているUUID(nb_mfield.keyと同じ)
   * mfield_id : マスタ圃場のUUID。マスタ圃場更新時に参照先が削除される場合がある）
   * mfield_version_id : 上記マスタ圃場作成時のバージョン情報 (マスタ圃場更新時もバージョン情報は消えない)
   * post_gis_version_id : ユーザ圃場更新時のpostgisバージョン
   * updated : 更新を実施した日時(timeaware)
   * updated_app_user_active_id : マニュアル操作したユーザのuuid、システム自動更新時は ”system_automatic_update”とする。 更新実施者を知ることで、更新の妥当性に懸念が出た時の調査材料とする。
   *
   */
  mfield_history?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Nb_Ufield_History_Delete_Elem_Input = {
  /**
   *
   * データサンプル
   * [
   *     {
   *         "polygon_uuid": "12345678-1234-1234-1234-123456789012",
   *         "mfield_id": "12345678-1234-1234-1234-123456789012",
   *         "mfield_version_id":"12345678-1234-1234-1234-123456789012",
   *         "postgis_version_id": "12345678-1234-1234-1234-123456789012",
   *         "updated": "2022-01-01T12:34:56+0900",
   *         "updated_app_user_active_id":"12345678-1234-1234-1234-123456789012"
   *     }
   *     ,...
   * ]
   * polygon_uuid : 筆ポリゴンで付与されているUUID(nb_mfield.keyと同じ)
   * mfield_id : マスタ圃場のUUID。マスタ圃場更新時に参照先が削除される場合がある）
   * mfield_version_id : 上記マスタ圃場作成時のバージョン情報 (マスタ圃場更新時もバージョン情報は消えない)
   * post_gis_version_id : ユーザ圃場更新時のpostgisバージョン
   * updated : 更新を実施した日時(timeaware)
   * updated_app_user_active_id : マニュアル操作したユーザのuuid、システム自動更新時は ”system_automatic_update”とする。 更新実施者を知ることで、更新の妥当性に懸念が出た時の調査材料とする。
   *
   */
  mfield_history?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Nb_Ufield_History_Delete_Key_Input = {
  /**
   *
   * データサンプル
   * [
   *     {
   *         "polygon_uuid": "12345678-1234-1234-1234-123456789012",
   *         "mfield_id": "12345678-1234-1234-1234-123456789012",
   *         "mfield_version_id":"12345678-1234-1234-1234-123456789012",
   *         "postgis_version_id": "12345678-1234-1234-1234-123456789012",
   *         "updated": "2022-01-01T12:34:56+0900",
   *         "updated_app_user_active_id":"12345678-1234-1234-1234-123456789012"
   *     }
   *     ,...
   * ]
   * polygon_uuid : 筆ポリゴンで付与されているUUID(nb_mfield.keyと同じ)
   * mfield_id : マスタ圃場のUUID。マスタ圃場更新時に参照先が削除される場合がある）
   * mfield_version_id : 上記マスタ圃場作成時のバージョン情報 (マスタ圃場更新時もバージョン情報は消えない)
   * post_gis_version_id : ユーザ圃場更新時のpostgisバージョン
   * updated : 更新を実施した日時(timeaware)
   * updated_app_user_active_id : マニュアル操作したユーザのuuid、システム自動更新時は ”system_automatic_update”とする。 更新実施者を知ることで、更新の妥当性に懸念が出た時の調査材料とする。
   *
   */
  mfield_history?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "nb_ufield_history" */
export type Nb_Ufield_History_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  /**
   *
   * データサンプル
   * [
   *     {
   *         "polygon_uuid": "12345678-1234-1234-1234-123456789012",
   *         "mfield_id": "12345678-1234-1234-1234-123456789012",
   *         "mfield_version_id":"12345678-1234-1234-1234-123456789012",
   *         "postgis_version_id": "12345678-1234-1234-1234-123456789012",
   *         "updated": "2022-01-01T12:34:56+0900",
   *         "updated_app_user_active_id":"12345678-1234-1234-1234-123456789012"
   *     }
   *     ,...
   * ]
   * polygon_uuid : 筆ポリゴンで付与されているUUID(nb_mfield.keyと同じ)
   * mfield_id : マスタ圃場のUUID。マスタ圃場更新時に参照先が削除される場合がある）
   * mfield_version_id : 上記マスタ圃場作成時のバージョン情報 (マスタ圃場更新時もバージョン情報は消えない)
   * post_gis_version_id : ユーザ圃場更新時のpostgisバージョン
   * updated : 更新を実施した日時(timeaware)
   * updated_app_user_active_id : マニュアル操作したユーザのuuid、システム自動更新時は ”system_automatic_update”とする。 更新実施者を知ることで、更新の妥当性に懸念が出た時の調査材料とする。
   *
   */
  mfield_history?: InputMaybe<Scalars['jsonb']>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  /** nb_ufield.id 1対1の関係となる */
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Ufield_History_Max_Fields = {
  __typename?: 'nb_ufield_history_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** nb_ufield.id 1対1の関係となる */
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Ufield_History_Min_Fields = {
  __typename?: 'nb_ufield_history_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** nb_ufield.id 1対1の関係となる */
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_ufield_history" */
export type Nb_Ufield_History_Mutation_Response = {
  __typename?: 'nb_ufield_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Ufield_History>;
};

/** input type for inserting object relation for remote table "nb_ufield_history" */
export type Nb_Ufield_History_Obj_Rel_Insert_Input = {
  data: Nb_Ufield_History_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Ufield_History_On_Conflict>;
};

/** on_conflict condition type for table "nb_ufield_history" */
export type Nb_Ufield_History_On_Conflict = {
  constraint: Nb_Ufield_History_Constraint;
  update_columns?: Array<Nb_Ufield_History_Update_Column>;
  where?: InputMaybe<Nb_Ufield_History_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_ufield_history". */
export type Nb_Ufield_History_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mfield_history?: InputMaybe<Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_ufield_history */
export type Nb_Ufield_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Nb_Ufield_History_Prepend_Input = {
  /**
   *
   * データサンプル
   * [
   *     {
   *         "polygon_uuid": "12345678-1234-1234-1234-123456789012",
   *         "mfield_id": "12345678-1234-1234-1234-123456789012",
   *         "mfield_version_id":"12345678-1234-1234-1234-123456789012",
   *         "postgis_version_id": "12345678-1234-1234-1234-123456789012",
   *         "updated": "2022-01-01T12:34:56+0900",
   *         "updated_app_user_active_id":"12345678-1234-1234-1234-123456789012"
   *     }
   *     ,...
   * ]
   * polygon_uuid : 筆ポリゴンで付与されているUUID(nb_mfield.keyと同じ)
   * mfield_id : マスタ圃場のUUID。マスタ圃場更新時に参照先が削除される場合がある）
   * mfield_version_id : 上記マスタ圃場作成時のバージョン情報 (マスタ圃場更新時もバージョン情報は消えない)
   * post_gis_version_id : ユーザ圃場更新時のpostgisバージョン
   * updated : 更新を実施した日時(timeaware)
   * updated_app_user_active_id : マニュアル操作したユーザのuuid、システム自動更新時は ”system_automatic_update”とする。 更新実施者を知ることで、更新の妥当性に懸念が出た時の調査材料とする。
   *
   */
  mfield_history?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "nb_ufield_history" */
export enum Nb_Ufield_History_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  MfieldHistory = 'mfield_history',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_ufield_history" */
export type Nb_Ufield_History_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  /**
   *
   * データサンプル
   * [
   *     {
   *         "polygon_uuid": "12345678-1234-1234-1234-123456789012",
   *         "mfield_id": "12345678-1234-1234-1234-123456789012",
   *         "mfield_version_id":"12345678-1234-1234-1234-123456789012",
   *         "postgis_version_id": "12345678-1234-1234-1234-123456789012",
   *         "updated": "2022-01-01T12:34:56+0900",
   *         "updated_app_user_active_id":"12345678-1234-1234-1234-123456789012"
   *     }
   *     ,...
   * ]
   * polygon_uuid : 筆ポリゴンで付与されているUUID(nb_mfield.keyと同じ)
   * mfield_id : マスタ圃場のUUID。マスタ圃場更新時に参照先が削除される場合がある）
   * mfield_version_id : 上記マスタ圃場作成時のバージョン情報 (マスタ圃場更新時もバージョン情報は消えない)
   * post_gis_version_id : ユーザ圃場更新時のpostgisバージョン
   * updated : 更新を実施した日時(timeaware)
   * updated_app_user_active_id : マニュアル操作したユーザのuuid、システム自動更新時は ”system_automatic_update”とする。 更新実施者を知ることで、更新の妥当性に懸念が出た時の調査材料とする。
   *
   */
  mfield_history?: InputMaybe<Scalars['jsonb']>;
  /** nb_ufield.id 1対1の関係となる */
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_ufield_history" */
export type Nb_Ufield_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Ufield_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Ufield_History_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  /**
   *
   * データサンプル
   * [
   *     {
   *         "polygon_uuid": "12345678-1234-1234-1234-123456789012",
   *         "mfield_id": "12345678-1234-1234-1234-123456789012",
   *         "mfield_version_id":"12345678-1234-1234-1234-123456789012",
   *         "postgis_version_id": "12345678-1234-1234-1234-123456789012",
   *         "updated": "2022-01-01T12:34:56+0900",
   *         "updated_app_user_active_id":"12345678-1234-1234-1234-123456789012"
   *     }
   *     ,...
   * ]
   * polygon_uuid : 筆ポリゴンで付与されているUUID(nb_mfield.keyと同じ)
   * mfield_id : マスタ圃場のUUID。マスタ圃場更新時に参照先が削除される場合がある）
   * mfield_version_id : 上記マスタ圃場作成時のバージョン情報 (マスタ圃場更新時もバージョン情報は消えない)
   * post_gis_version_id : ユーザ圃場更新時のpostgisバージョン
   * updated : 更新を実施した日時(timeaware)
   * updated_app_user_active_id : マニュアル操作したユーザのuuid、システム自動更新時は ”system_automatic_update”とする。 更新実施者を知ることで、更新の妥当性に懸念が出た時の調査材料とする。
   *
   */
  mfield_history?: InputMaybe<Scalars['jsonb']>;
  /** nb_ufield.id 1対1の関係となる */
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_ufield_history" */
export enum Nb_Ufield_History_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  MfieldHistory = 'mfield_history',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated'
}

export type Nb_Ufield_History_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Nb_Ufield_History_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Nb_Ufield_History_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Nb_Ufield_History_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Nb_Ufield_History_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Nb_Ufield_History_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Ufield_History_Set_Input>;
  where: Nb_Ufield_History_Bool_Exp;
};

/** input type for incrementing numeric columns in table "nb_ufield" */
export type Nb_Ufield_Inc_Input = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "nb_ufield" */
export type Nb_Ufield_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Scalars['float8']>;
  centroid?: InputMaybe<Scalars['geometry']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  field_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_conditioner_works?: InputMaybe<Nb_Conditioner_Work_Arr_Rel_Insert_Input>;
  nb_cultivation_plans?: InputMaybe<Nb_Cultivation_Plan_Arr_Rel_Insert_Input>;
  nb_field_file_loads?: InputMaybe<Nb_Field_File_Load_Arr_Rel_Insert_Input>;
  nb_field_files?: InputMaybe<Nb_Field_File_Arr_Rel_Insert_Input>;
  nb_field_orthoimgs?: InputMaybe<Nb_Field_Orthoimg_Arr_Rel_Insert_Input>;
  nb_field_picture_loads?: InputMaybe<Nb_Field_Picture_Load_Arr_Rel_Insert_Input>;
  nb_field_pictures?: InputMaybe<Nb_Field_Picture_Arr_Rel_Insert_Input>;
  nb_field_properties?: InputMaybe<Nb_Field_Property_Arr_Rel_Insert_Input>;
  nb_field_sensings?: InputMaybe<Nb_Field_Sensing_Arr_Rel_Insert_Input>;
  nb_field_water_gates?: InputMaybe<Nb_Field_Water_Gate_Arr_Rel_Insert_Input>;
  nb_finished_ufield_works?: InputMaybe<Nb_Finished_Ufield_Work_Arr_Rel_Insert_Input>;
  nb_memo_ufield_links?: InputMaybe<Nb_Memo_Ufield_Link_Arr_Rel_Insert_Input>;
  nb_mfield_links?: InputMaybe<Nb_Mfield_Link_Arr_Rel_Insert_Input>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Arr_Rel_Insert_Input>;
  nb_postgis_version?: InputMaybe<Master_Nb_Postgis_Version_Obj_Rel_Insert_Input>;
  nb_raster_image_sensings?: InputMaybe<Nb_Raster_Image_Sensing_Arr_Rel_Insert_Input>;
  nb_soil_chemistries?: InputMaybe<Nb_Soil_Chemistry_Arr_Rel_Insert_Input>;
  nb_soil_physics?: InputMaybe<Nb_Soil_Physics_Arr_Rel_Insert_Input>;
  nb_ufattr_links?: InputMaybe<Nb_Ufattr_Link_Arr_Rel_Insert_Input>;
  nb_ufg_links?: InputMaybe<Nb_Ufg_Link_Arr_Rel_Insert_Input>;
  nb_ufield_company_links?: InputMaybe<Nb_Ufield_Company_Link_Arr_Rel_Insert_Input>;
  nb_ufield_history?: InputMaybe<Nb_Ufield_History_Obj_Rel_Insert_Input>;
  nbbe_microclimates?: InputMaybe<Nbbe_Microclimate_Arr_Rel_Insert_Input>;
  polygon_field?: InputMaybe<Scalars['geometry']>;
  /** area_sqmなどpostgisを使うデータを生成したときのpostgisバージョンを保持 */
  postgis_version_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Ufield_Max_Fields = {
  __typename?: 'nb_ufield_max_fields';
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: Maybe<Scalars['float8']>;
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  field_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** area_sqmなどpostgisを使うデータを生成したときのpostgisバージョンを保持 */
  postgis_version_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nb_ufield" */
export type Nb_Ufield_Max_Order_By = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** area_sqmなどpostgisを使うデータを生成したときのpostgisバージョンを保持 */
  postgis_version_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Ufield_Min_Fields = {
  __typename?: 'nb_ufield_min_fields';
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: Maybe<Scalars['float8']>;
  created?: Maybe<Scalars['timestamptz']>;
  creator_name?: Maybe<Scalars['String']>;
  field_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** area_sqmなどpostgisを使うデータを生成したときのpostgisバージョンを保持 */
  postgis_version_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nb_ufield" */
export type Nb_Ufield_Min_Order_By = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** area_sqmなどpostgisを使うデータを生成したときのpostgisバージョンを保持 */
  postgis_version_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_ufield" */
export type Nb_Ufield_Mutation_Response = {
  __typename?: 'nb_ufield_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Ufield>;
};

/** input type for inserting object relation for remote table "nb_ufield" */
export type Nb_Ufield_Obj_Rel_Insert_Input = {
  data: Nb_Ufield_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Ufield_On_Conflict>;
};

/** on_conflict condition type for table "nb_ufield" */
export type Nb_Ufield_On_Conflict = {
  constraint: Nb_Ufield_Constraint;
  update_columns?: Array<Nb_Ufield_Update_Column>;
  where?: InputMaybe<Nb_Ufield_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_ufield". */
export type Nb_Ufield_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  area_sqm?: InputMaybe<Order_By>;
  centroid?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  creator_name?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_conditioner_works_aggregate?: InputMaybe<Nb_Conditioner_Work_Aggregate_Order_By>;
  nb_cultivation_plans_aggregate?: InputMaybe<Nb_Cultivation_Plan_Aggregate_Order_By>;
  nb_field_file_loads_aggregate?: InputMaybe<Nb_Field_File_Load_Aggregate_Order_By>;
  nb_field_files_aggregate?: InputMaybe<Nb_Field_File_Aggregate_Order_By>;
  nb_field_orthoimgs_aggregate?: InputMaybe<Nb_Field_Orthoimg_Aggregate_Order_By>;
  nb_field_picture_loads_aggregate?: InputMaybe<Nb_Field_Picture_Load_Aggregate_Order_By>;
  nb_field_pictures_aggregate?: InputMaybe<Nb_Field_Picture_Aggregate_Order_By>;
  nb_field_properties_aggregate?: InputMaybe<Nb_Field_Property_Aggregate_Order_By>;
  nb_field_sensings_aggregate?: InputMaybe<Nb_Field_Sensing_Aggregate_Order_By>;
  nb_field_water_gates_aggregate?: InputMaybe<Nb_Field_Water_Gate_Aggregate_Order_By>;
  nb_finished_ufield_works_aggregate?: InputMaybe<Nb_Finished_Ufield_Work_Aggregate_Order_By>;
  nb_memo_ufield_links_aggregate?: InputMaybe<Nb_Memo_Ufield_Link_Aggregate_Order_By>;
  nb_mfield_links_aggregate?: InputMaybe<Nb_Mfield_Link_Aggregate_Order_By>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Order_By>;
  nb_postgis_version?: InputMaybe<Master_Nb_Postgis_Version_Order_By>;
  nb_raster_image_sensings_aggregate?: InputMaybe<Nb_Raster_Image_Sensing_Aggregate_Order_By>;
  nb_soil_chemistries_aggregate?: InputMaybe<Nb_Soil_Chemistry_Aggregate_Order_By>;
  nb_soil_physics_aggregate?: InputMaybe<Nb_Soil_Physics_Aggregate_Order_By>;
  nb_ufattr_links_aggregate?: InputMaybe<Nb_Ufattr_Link_Aggregate_Order_By>;
  nb_ufg_links_aggregate?: InputMaybe<Nb_Ufg_Link_Aggregate_Order_By>;
  nb_ufield_company_links_aggregate?: InputMaybe<Nb_Ufield_Company_Link_Aggregate_Order_By>;
  nb_ufield_history?: InputMaybe<Nb_Ufield_History_Order_By>;
  nbbe_microclimates_aggregate?: InputMaybe<Nbbe_Microclimate_Aggregate_Order_By>;
  polygon_field?: InputMaybe<Order_By>;
  postgis_version_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_ufield */
export type Nb_Ufield_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_ufield" */
export enum Nb_Ufield_Select_Column {
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  Centroid = 'centroid',
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  FieldName = 'field_name',
  /** column name */
  Id = 'id',
  /** column name */
  PolygonField = 'polygon_field',
  /** column name */
  PostgisVersionId = 'postgis_version_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** select "nb_ufield_aggregate_bool_exp_avg_arguments_columns" columns of table "nb_ufield" */
export enum Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm'
}

/** select "nb_ufield_aggregate_bool_exp_corr_arguments_columns" columns of table "nb_ufield" */
export enum Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm'
}

/** select "nb_ufield_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "nb_ufield" */
export enum Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm'
}

/** select "nb_ufield_aggregate_bool_exp_max_arguments_columns" columns of table "nb_ufield" */
export enum Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm'
}

/** select "nb_ufield_aggregate_bool_exp_min_arguments_columns" columns of table "nb_ufield" */
export enum Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm'
}

/** select "nb_ufield_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "nb_ufield" */
export enum Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm'
}

/** select "nb_ufield_aggregate_bool_exp_sum_arguments_columns" columns of table "nb_ufield" */
export enum Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm'
}

/** select "nb_ufield_aggregate_bool_exp_var_samp_arguments_columns" columns of table "nb_ufield" */
export enum Nb_Ufield_Select_Column_Nb_Ufield_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  AreaSqm = 'area_sqm'
}

/** input type for updating data in table "nb_ufield" */
export type Nb_Ufield_Set_Input = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Scalars['float8']>;
  centroid?: InputMaybe<Scalars['geometry']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  field_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  polygon_field?: InputMaybe<Scalars['geometry']>;
  /** area_sqmなどpostgisを使うデータを生成したときのpostgisバージョンを保持 */
  postgis_version_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Nb_Ufield_Stddev_Fields = {
  __typename?: 'nb_ufield_stddev_fields';
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_ufield" */
export type Nb_Ufield_Stddev_Order_By = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Ufield_Stddev_Pop_Fields = {
  __typename?: 'nb_ufield_stddev_pop_fields';
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_ufield" */
export type Nb_Ufield_Stddev_Pop_Order_By = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Ufield_Stddev_Samp_Fields = {
  __typename?: 'nb_ufield_stddev_samp_fields';
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_ufield" */
export type Nb_Ufield_Stddev_Samp_Order_By = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_ufield" */
export type Nb_Ufield_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Ufield_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Ufield_Stream_Cursor_Value_Input = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Scalars['float8']>;
  centroid?: InputMaybe<Scalars['geometry']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  creator_name?: InputMaybe<Scalars['String']>;
  field_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  polygon_field?: InputMaybe<Scalars['geometry']>;
  /** area_sqmなどpostgisを使うデータを生成したときのpostgisバージョンを保持 */
  postgis_version_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Nb_Ufield_Sum_Fields = {
  __typename?: 'nb_ufield_sum_fields';
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "nb_ufield" */
export type Nb_Ufield_Sum_Order_By = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Order_By>;
};

/** update columns of table "nb_ufield" */
export enum Nb_Ufield_Update_Column {
  /** column name */
  AreaSqm = 'area_sqm',
  /** column name */
  Centroid = 'centroid',
  /** column name */
  Created = 'created',
  /** column name */
  CreatorName = 'creator_name',
  /** column name */
  FieldName = 'field_name',
  /** column name */
  Id = 'id',
  /** column name */
  PolygonField = 'polygon_field',
  /** column name */
  PostgisVersionId = 'postgis_version_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nb_Ufield_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Ufield_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Ufield_Set_Input>;
  where: Nb_Ufield_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Ufield_Var_Pop_Fields = {
  __typename?: 'nb_ufield_var_pop_fields';
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_ufield" */
export type Nb_Ufield_Var_Pop_Order_By = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Ufield_Var_Samp_Fields = {
  __typename?: 'nb_ufield_var_samp_fields';
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_ufield" */
export type Nb_Ufield_Var_Samp_Order_By = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Ufield_Variance_Fields = {
  __typename?: 'nb_ufield_variance_fields';
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_ufield" */
export type Nb_Ufield_Variance_Order_By = {
  /** polygon_field から st_area で 算出した面積平方メートル */
  area_sqm?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_vector_image_sensing" */
export type Nb_Vector_Image_Sensing = {
  __typename?: 'nb_vector_image_sensing';
  coarse_grain: Scalars['String'];
  created: Scalars['timestamptz'];
  data_type?: Maybe<Scalars['String']>;
  fine_grain: Scalars['String'];
  id: Scalars['uuid'];
  mean: Scalars['String'];
  middle_grain: Scalars['String'];
  /** An array relationship */
  nb_raster_image_sensings: Array<Nb_Raster_Image_Sensing>;
  /** An aggregate relationship */
  nb_raster_image_sensings_aggregate: Nb_Raster_Image_Sensing_Aggregate;
  /** An array relationship */
  nb_vector_ims_sizes: Array<Nb_Vector_Ims_Size>;
  /** An aggregate relationship */
  nb_vector_ims_sizes_aggregate: Nb_Vector_Ims_Size_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_vector_image_sensing" */
export type Nb_Vector_Image_SensingNb_Raster_Image_SensingsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};


/** columns and relationships of "nb_vector_image_sensing" */
export type Nb_Vector_Image_SensingNb_Raster_Image_Sensings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};


/** columns and relationships of "nb_vector_image_sensing" */
export type Nb_Vector_Image_SensingNb_Vector_Ims_SizesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Vector_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Vector_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Vector_Ims_Size_Bool_Exp>;
};


/** columns and relationships of "nb_vector_image_sensing" */
export type Nb_Vector_Image_SensingNb_Vector_Ims_Sizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Vector_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Vector_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Vector_Ims_Size_Bool_Exp>;
};

/** aggregated selection of "nb_vector_image_sensing" */
export type Nb_Vector_Image_Sensing_Aggregate = {
  __typename?: 'nb_vector_image_sensing_aggregate';
  aggregate?: Maybe<Nb_Vector_Image_Sensing_Aggregate_Fields>;
  nodes: Array<Nb_Vector_Image_Sensing>;
};

/** aggregate fields of "nb_vector_image_sensing" */
export type Nb_Vector_Image_Sensing_Aggregate_Fields = {
  __typename?: 'nb_vector_image_sensing_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Vector_Image_Sensing_Max_Fields>;
  min?: Maybe<Nb_Vector_Image_Sensing_Min_Fields>;
};


/** aggregate fields of "nb_vector_image_sensing" */
export type Nb_Vector_Image_Sensing_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Vector_Image_Sensing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_vector_image_sensing". All fields are combined with a logical 'AND'. */
export type Nb_Vector_Image_Sensing_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Vector_Image_Sensing_Bool_Exp>>;
  _not?: InputMaybe<Nb_Vector_Image_Sensing_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Vector_Image_Sensing_Bool_Exp>>;
  coarse_grain?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  data_type?: InputMaybe<String_Comparison_Exp>;
  fine_grain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mean?: InputMaybe<String_Comparison_Exp>;
  middle_grain?: InputMaybe<String_Comparison_Exp>;
  nb_raster_image_sensings?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
  nb_raster_image_sensings_aggregate?: InputMaybe<Nb_Raster_Image_Sensing_Aggregate_Bool_Exp>;
  nb_vector_ims_sizes?: InputMaybe<Nb_Vector_Ims_Size_Bool_Exp>;
  nb_vector_ims_sizes_aggregate?: InputMaybe<Nb_Vector_Ims_Size_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_vector_image_sensing" */
export enum Nb_Vector_Image_Sensing_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbVectorImageSensingPkey = 'nb_vector_image_sensing_pkey'
}

/** input type for inserting data into table "nb_vector_image_sensing" */
export type Nb_Vector_Image_Sensing_Insert_Input = {
  coarse_grain?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  data_type?: InputMaybe<Scalars['String']>;
  fine_grain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mean?: InputMaybe<Scalars['String']>;
  middle_grain?: InputMaybe<Scalars['String']>;
  nb_raster_image_sensings?: InputMaybe<Nb_Raster_Image_Sensing_Arr_Rel_Insert_Input>;
  nb_vector_ims_sizes?: InputMaybe<Nb_Vector_Ims_Size_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Vector_Image_Sensing_Max_Fields = {
  __typename?: 'nb_vector_image_sensing_max_fields';
  coarse_grain?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  data_type?: Maybe<Scalars['String']>;
  fine_grain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mean?: Maybe<Scalars['String']>;
  middle_grain?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Vector_Image_Sensing_Min_Fields = {
  __typename?: 'nb_vector_image_sensing_min_fields';
  coarse_grain?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  data_type?: Maybe<Scalars['String']>;
  fine_grain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mean?: Maybe<Scalars['String']>;
  middle_grain?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_vector_image_sensing" */
export type Nb_Vector_Image_Sensing_Mutation_Response = {
  __typename?: 'nb_vector_image_sensing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Vector_Image_Sensing>;
};

/** input type for inserting object relation for remote table "nb_vector_image_sensing" */
export type Nb_Vector_Image_Sensing_Obj_Rel_Insert_Input = {
  data: Nb_Vector_Image_Sensing_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Vector_Image_Sensing_On_Conflict>;
};

/** on_conflict condition type for table "nb_vector_image_sensing" */
export type Nb_Vector_Image_Sensing_On_Conflict = {
  constraint: Nb_Vector_Image_Sensing_Constraint;
  update_columns?: Array<Nb_Vector_Image_Sensing_Update_Column>;
  where?: InputMaybe<Nb_Vector_Image_Sensing_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_vector_image_sensing". */
export type Nb_Vector_Image_Sensing_Order_By = {
  coarse_grain?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  data_type?: InputMaybe<Order_By>;
  fine_grain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mean?: InputMaybe<Order_By>;
  middle_grain?: InputMaybe<Order_By>;
  nb_raster_image_sensings_aggregate?: InputMaybe<Nb_Raster_Image_Sensing_Aggregate_Order_By>;
  nb_vector_ims_sizes_aggregate?: InputMaybe<Nb_Vector_Ims_Size_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_vector_image_sensing */
export type Nb_Vector_Image_Sensing_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_vector_image_sensing" */
export enum Nb_Vector_Image_Sensing_Select_Column {
  /** column name */
  CoarseGrain = 'coarse_grain',
  /** column name */
  Created = 'created',
  /** column name */
  DataType = 'data_type',
  /** column name */
  FineGrain = 'fine_grain',
  /** column name */
  Id = 'id',
  /** column name */
  Mean = 'mean',
  /** column name */
  MiddleGrain = 'middle_grain',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_vector_image_sensing" */
export type Nb_Vector_Image_Sensing_Set_Input = {
  coarse_grain?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  data_type?: InputMaybe<Scalars['String']>;
  fine_grain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mean?: InputMaybe<Scalars['String']>;
  middle_grain?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_vector_image_sensing" */
export type Nb_Vector_Image_Sensing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Vector_Image_Sensing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Vector_Image_Sensing_Stream_Cursor_Value_Input = {
  coarse_grain?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  data_type?: InputMaybe<Scalars['String']>;
  fine_grain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mean?: InputMaybe<Scalars['String']>;
  middle_grain?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_vector_image_sensing" */
export enum Nb_Vector_Image_Sensing_Update_Column {
  /** column name */
  CoarseGrain = 'coarse_grain',
  /** column name */
  Created = 'created',
  /** column name */
  DataType = 'data_type',
  /** column name */
  FineGrain = 'fine_grain',
  /** column name */
  Id = 'id',
  /** column name */
  Mean = 'mean',
  /** column name */
  MiddleGrain = 'middle_grain',
  /** column name */
  Updated = 'updated'
}

export type Nb_Vector_Image_Sensing_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Vector_Image_Sensing_Set_Input>;
  where: Nb_Vector_Image_Sensing_Bool_Exp;
};

/** nb_vector_image_sensingで管理されるファイルのサイズを保持する */
export type Nb_Vector_Ims_Size = {
  __typename?: 'nb_vector_ims_size';
  coarse_grain_size_bytes: Scalars['bigint'];
  company_key?: Maybe<Scalars['String']>;
  file_type: Scalars['String'];
  fine_grain_size_bytes: Scalars['bigint'];
  id: Scalars['uuid'];
  mean_size_bytes: Scalars['bigint'];
  middle_grain_size_bytes: Scalars['bigint'];
  /** An object relationship */
  nb_vector_image_sensing: Nb_Vector_Image_Sensing;
  total_size_bytes: Scalars['bigint'];
  user_id?: Maybe<Scalars['uuid']>;
  vector_ims_id: Scalars['uuid'];
};

/** aggregated selection of "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Aggregate = {
  __typename?: 'nb_vector_ims_size_aggregate';
  aggregate?: Maybe<Nb_Vector_Ims_Size_Aggregate_Fields>;
  nodes: Array<Nb_Vector_Ims_Size>;
};

export type Nb_Vector_Ims_Size_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Vector_Ims_Size_Aggregate_Bool_Exp_Count>;
};

export type Nb_Vector_Ims_Size_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Vector_Ims_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Vector_Ims_Size_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Aggregate_Fields = {
  __typename?: 'nb_vector_ims_size_aggregate_fields';
  avg?: Maybe<Nb_Vector_Ims_Size_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Vector_Ims_Size_Max_Fields>;
  min?: Maybe<Nb_Vector_Ims_Size_Min_Fields>;
  stddev?: Maybe<Nb_Vector_Ims_Size_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Vector_Ims_Size_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Vector_Ims_Size_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Vector_Ims_Size_Sum_Fields>;
  var_pop?: Maybe<Nb_Vector_Ims_Size_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Vector_Ims_Size_Var_Samp_Fields>;
  variance?: Maybe<Nb_Vector_Ims_Size_Variance_Fields>;
};


/** aggregate fields of "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Vector_Ims_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Vector_Ims_Size_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Vector_Ims_Size_Max_Order_By>;
  min?: InputMaybe<Nb_Vector_Ims_Size_Min_Order_By>;
  stddev?: InputMaybe<Nb_Vector_Ims_Size_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Vector_Ims_Size_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Vector_Ims_Size_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Vector_Ims_Size_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Vector_Ims_Size_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Vector_Ims_Size_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Vector_Ims_Size_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Arr_Rel_Insert_Input = {
  data: Array<Nb_Vector_Ims_Size_Insert_Input>;
};

/** aggregate avg on columns */
export type Nb_Vector_Ims_Size_Avg_Fields = {
  __typename?: 'nb_vector_ims_size_avg_fields';
  coarse_grain_size_bytes?: Maybe<Scalars['Float']>;
  fine_grain_size_bytes?: Maybe<Scalars['Float']>;
  mean_size_bytes?: Maybe<Scalars['Float']>;
  middle_grain_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Avg_Order_By = {
  coarse_grain_size_bytes?: InputMaybe<Order_By>;
  fine_grain_size_bytes?: InputMaybe<Order_By>;
  mean_size_bytes?: InputMaybe<Order_By>;
  middle_grain_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_vector_ims_size". All fields are combined with a logical 'AND'. */
export type Nb_Vector_Ims_Size_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Vector_Ims_Size_Bool_Exp>>;
  _not?: InputMaybe<Nb_Vector_Ims_Size_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Vector_Ims_Size_Bool_Exp>>;
  coarse_grain_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  company_key?: InputMaybe<String_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  fine_grain_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mean_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  middle_grain_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  nb_vector_image_sensing?: InputMaybe<Nb_Vector_Image_Sensing_Bool_Exp>;
  total_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vector_ims_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Inc_Input = {
  coarse_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  fine_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  mean_size_bytes?: InputMaybe<Scalars['bigint']>;
  middle_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Insert_Input = {
  coarse_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  fine_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  mean_size_bytes?: InputMaybe<Scalars['bigint']>;
  middle_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  nb_vector_image_sensing?: InputMaybe<Nb_Vector_Image_Sensing_Obj_Rel_Insert_Input>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vector_ims_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Vector_Ims_Size_Max_Fields = {
  __typename?: 'nb_vector_ims_size_max_fields';
  coarse_grain_size_bytes?: Maybe<Scalars['bigint']>;
  company_key?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  fine_grain_size_bytes?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  mean_size_bytes?: Maybe<Scalars['bigint']>;
  middle_grain_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  vector_ims_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Max_Order_By = {
  coarse_grain_size_bytes?: InputMaybe<Order_By>;
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  fine_grain_size_bytes?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mean_size_bytes?: InputMaybe<Order_By>;
  middle_grain_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vector_ims_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Vector_Ims_Size_Min_Fields = {
  __typename?: 'nb_vector_ims_size_min_fields';
  coarse_grain_size_bytes?: Maybe<Scalars['bigint']>;
  company_key?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  fine_grain_size_bytes?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  mean_size_bytes?: Maybe<Scalars['bigint']>;
  middle_grain_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  vector_ims_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Min_Order_By = {
  coarse_grain_size_bytes?: InputMaybe<Order_By>;
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  fine_grain_size_bytes?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mean_size_bytes?: InputMaybe<Order_By>;
  middle_grain_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vector_ims_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Mutation_Response = {
  __typename?: 'nb_vector_ims_size_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Vector_Ims_Size>;
};

/** Ordering options when selecting data from "nb_vector_ims_size". */
export type Nb_Vector_Ims_Size_Order_By = {
  coarse_grain_size_bytes?: InputMaybe<Order_By>;
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  fine_grain_size_bytes?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mean_size_bytes?: InputMaybe<Order_By>;
  middle_grain_size_bytes?: InputMaybe<Order_By>;
  nb_vector_image_sensing?: InputMaybe<Nb_Vector_Image_Sensing_Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vector_ims_id?: InputMaybe<Order_By>;
};

/** select columns of table "nb_vector_ims_size" */
export enum Nb_Vector_Ims_Size_Select_Column {
  /** column name */
  CoarseGrainSizeBytes = 'coarse_grain_size_bytes',
  /** column name */
  CompanyKey = 'company_key',
  /** column name */
  FileType = 'file_type',
  /** column name */
  FineGrainSizeBytes = 'fine_grain_size_bytes',
  /** column name */
  Id = 'id',
  /** column name */
  MeanSizeBytes = 'mean_size_bytes',
  /** column name */
  MiddleGrainSizeBytes = 'middle_grain_size_bytes',
  /** column name */
  TotalSizeBytes = 'total_size_bytes',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VectorImsId = 'vector_ims_id'
}

/** input type for updating data in table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Set_Input = {
  coarse_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  fine_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  mean_size_bytes?: InputMaybe<Scalars['bigint']>;
  middle_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vector_ims_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Nb_Vector_Ims_Size_Stddev_Fields = {
  __typename?: 'nb_vector_ims_size_stddev_fields';
  coarse_grain_size_bytes?: Maybe<Scalars['Float']>;
  fine_grain_size_bytes?: Maybe<Scalars['Float']>;
  mean_size_bytes?: Maybe<Scalars['Float']>;
  middle_grain_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Stddev_Order_By = {
  coarse_grain_size_bytes?: InputMaybe<Order_By>;
  fine_grain_size_bytes?: InputMaybe<Order_By>;
  mean_size_bytes?: InputMaybe<Order_By>;
  middle_grain_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Vector_Ims_Size_Stddev_Pop_Fields = {
  __typename?: 'nb_vector_ims_size_stddev_pop_fields';
  coarse_grain_size_bytes?: Maybe<Scalars['Float']>;
  fine_grain_size_bytes?: Maybe<Scalars['Float']>;
  mean_size_bytes?: Maybe<Scalars['Float']>;
  middle_grain_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Stddev_Pop_Order_By = {
  coarse_grain_size_bytes?: InputMaybe<Order_By>;
  fine_grain_size_bytes?: InputMaybe<Order_By>;
  mean_size_bytes?: InputMaybe<Order_By>;
  middle_grain_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Vector_Ims_Size_Stddev_Samp_Fields = {
  __typename?: 'nb_vector_ims_size_stddev_samp_fields';
  coarse_grain_size_bytes?: Maybe<Scalars['Float']>;
  fine_grain_size_bytes?: Maybe<Scalars['Float']>;
  mean_size_bytes?: Maybe<Scalars['Float']>;
  middle_grain_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Stddev_Samp_Order_By = {
  coarse_grain_size_bytes?: InputMaybe<Order_By>;
  fine_grain_size_bytes?: InputMaybe<Order_By>;
  mean_size_bytes?: InputMaybe<Order_By>;
  middle_grain_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Vector_Ims_Size_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Vector_Ims_Size_Stream_Cursor_Value_Input = {
  coarse_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  fine_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  mean_size_bytes?: InputMaybe<Scalars['bigint']>;
  middle_grain_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vector_ims_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nb_Vector_Ims_Size_Sum_Fields = {
  __typename?: 'nb_vector_ims_size_sum_fields';
  coarse_grain_size_bytes?: Maybe<Scalars['bigint']>;
  fine_grain_size_bytes?: Maybe<Scalars['bigint']>;
  mean_size_bytes?: Maybe<Scalars['bigint']>;
  middle_grain_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Sum_Order_By = {
  coarse_grain_size_bytes?: InputMaybe<Order_By>;
  fine_grain_size_bytes?: InputMaybe<Order_By>;
  mean_size_bytes?: InputMaybe<Order_By>;
  middle_grain_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

export type Nb_Vector_Ims_Size_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Vector_Ims_Size_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Vector_Ims_Size_Set_Input>;
  where: Nb_Vector_Ims_Size_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Vector_Ims_Size_Var_Pop_Fields = {
  __typename?: 'nb_vector_ims_size_var_pop_fields';
  coarse_grain_size_bytes?: Maybe<Scalars['Float']>;
  fine_grain_size_bytes?: Maybe<Scalars['Float']>;
  mean_size_bytes?: Maybe<Scalars['Float']>;
  middle_grain_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Var_Pop_Order_By = {
  coarse_grain_size_bytes?: InputMaybe<Order_By>;
  fine_grain_size_bytes?: InputMaybe<Order_By>;
  mean_size_bytes?: InputMaybe<Order_By>;
  middle_grain_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Vector_Ims_Size_Var_Samp_Fields = {
  __typename?: 'nb_vector_ims_size_var_samp_fields';
  coarse_grain_size_bytes?: Maybe<Scalars['Float']>;
  fine_grain_size_bytes?: Maybe<Scalars['Float']>;
  mean_size_bytes?: Maybe<Scalars['Float']>;
  middle_grain_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Var_Samp_Order_By = {
  coarse_grain_size_bytes?: InputMaybe<Order_By>;
  fine_grain_size_bytes?: InputMaybe<Order_By>;
  mean_size_bytes?: InputMaybe<Order_By>;
  middle_grain_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Vector_Ims_Size_Variance_Fields = {
  __typename?: 'nb_vector_ims_size_variance_fields';
  coarse_grain_size_bytes?: Maybe<Scalars['Float']>;
  fine_grain_size_bytes?: Maybe<Scalars['Float']>;
  mean_size_bytes?: Maybe<Scalars['Float']>;
  middle_grain_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_vector_ims_size" */
export type Nb_Vector_Ims_Size_Variance_Order_By = {
  coarse_grain_size_bytes?: InputMaybe<Order_By>;
  fine_grain_size_bytes?: InputMaybe<Order_By>;
  mean_size_bytes?: InputMaybe<Order_By>;
  middle_grain_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** columns and relationships of "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link = {
  __typename?: 'nb_water_gate_status_cplan_link';
  created: Scalars['timestamptz'];
  cultivation_plan_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_cultivation_plan: Nb_Cultivation_Plan;
  /** An object relationship */
  nb_field_water_gate_status: Nb_Field_Water_Gate_Status;
  updated: Scalars['timestamptz'];
  wgate_status_id: Scalars['uuid'];
};

/** aggregated selection of "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_Aggregate = {
  __typename?: 'nb_water_gate_status_cplan_link_aggregate';
  aggregate?: Maybe<Nb_Water_Gate_Status_Cplan_Link_Aggregate_Fields>;
  nodes: Array<Nb_Water_Gate_Status_Cplan_Link>;
};

export type Nb_Water_Gate_Status_Cplan_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Aggregate_Bool_Exp_Count>;
};

export type Nb_Water_Gate_Status_Cplan_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_Aggregate_Fields = {
  __typename?: 'nb_water_gate_status_cplan_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Water_Gate_Status_Cplan_Link_Max_Fields>;
  min?: Maybe<Nb_Water_Gate_Status_Cplan_Link_Min_Fields>;
};


/** aggregate fields of "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Max_Order_By>;
  min?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_Arr_Rel_Insert_Input = {
  data: Array<Nb_Water_Gate_Status_Cplan_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_water_gate_status_cplan_link". All fields are combined with a logical 'AND'. */
export type Nb_Water_Gate_Status_Cplan_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>>;
  _not?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  cultivation_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_field_water_gate_status?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  wgate_status_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_water_gate_status_cplan_link" */
export enum Nb_Water_Gate_Status_Cplan_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbWaterGateStatusCplanLinkPkey = 'nb_water_gate_status_cplan_link_pkey'
}

/** input type for inserting data into table "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Obj_Rel_Insert_Input>;
  nb_field_water_gate_status?: InputMaybe<Nb_Field_Water_Gate_Status_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  wgate_status_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Water_Gate_Status_Cplan_Link_Max_Fields = {
  __typename?: 'nb_water_gate_status_cplan_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  wgate_status_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  wgate_status_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Water_Gate_Status_Cplan_Link_Min_Fields = {
  __typename?: 'nb_water_gate_status_cplan_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  wgate_status_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  wgate_status_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_Mutation_Response = {
  __typename?: 'nb_water_gate_status_cplan_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Water_Gate_Status_Cplan_Link>;
};

/** on_conflict condition type for table "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_On_Conflict = {
  constraint: Nb_Water_Gate_Status_Cplan_Link_Constraint;
  update_columns?: Array<Nb_Water_Gate_Status_Cplan_Link_Update_Column>;
  where?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_water_gate_status_cplan_link". */
export type Nb_Water_Gate_Status_Cplan_Link_Order_By = {
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Order_By>;
  nb_field_water_gate_status?: InputMaybe<Nb_Field_Water_Gate_Status_Order_By>;
  updated?: InputMaybe<Order_By>;
  wgate_status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_water_gate_status_cplan_link */
export type Nb_Water_Gate_Status_Cplan_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_water_gate_status_cplan_link" */
export enum Nb_Water_Gate_Status_Cplan_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated',
  /** column name */
  WgateStatusId = 'wgate_status_id'
}

/** input type for updating data in table "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  wgate_status_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "nb_water_gate_status_cplan_link" */
export type Nb_Water_Gate_Status_Cplan_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Water_Gate_Status_Cplan_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Water_Gate_Status_Cplan_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  wgate_status_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nb_water_gate_status_cplan_link" */
export enum Nb_Water_Gate_Status_Cplan_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated',
  /** column name */
  WgateStatusId = 'wgate_status_id'
}

export type Nb_Water_Gate_Status_Cplan_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Set_Input>;
  where: Nb_Water_Gate_Status_Cplan_Link_Bool_Exp;
};

/** columns and relationships of "nb_weather_attr" */
export type Nb_Weather_Attr = {
  __typename?: 'nb_weather_attr';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  nb_weather_data: Array<Nb_Weather_Data>;
  /** An aggregate relationship */
  nb_weather_data_aggregate: Nb_Weather_Data_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_weather_attr" */
export type Nb_Weather_AttrNb_Weather_DataArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
};


/** columns and relationships of "nb_weather_attr" */
export type Nb_Weather_AttrNb_Weather_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
};

/** aggregated selection of "nb_weather_attr" */
export type Nb_Weather_Attr_Aggregate = {
  __typename?: 'nb_weather_attr_aggregate';
  aggregate?: Maybe<Nb_Weather_Attr_Aggregate_Fields>;
  nodes: Array<Nb_Weather_Attr>;
};

/** aggregate fields of "nb_weather_attr" */
export type Nb_Weather_Attr_Aggregate_Fields = {
  __typename?: 'nb_weather_attr_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Weather_Attr_Max_Fields>;
  min?: Maybe<Nb_Weather_Attr_Min_Fields>;
};


/** aggregate fields of "nb_weather_attr" */
export type Nb_Weather_Attr_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Weather_Attr_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nb_weather_attr". All fields are combined with a logical 'AND'. */
export type Nb_Weather_Attr_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Weather_Attr_Bool_Exp>>;
  _not?: InputMaybe<Nb_Weather_Attr_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Weather_Attr_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_weather_data?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
  nb_weather_data_aggregate?: InputMaybe<Nb_Weather_Data_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_weather_attr" */
export enum Nb_Weather_Attr_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbWeatherAttrKeyKey = 'nb_weather_attr_key_key',
  /** unique or primary key constraint on columns "id" */
  NbWeatherAttrPkey = 'nb_weather_attr_pkey'
}

/** input type for inserting data into table "nb_weather_attr" */
export type Nb_Weather_Attr_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nb_weather_data?: InputMaybe<Nb_Weather_Data_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Weather_Attr_Max_Fields = {
  __typename?: 'nb_weather_attr_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Weather_Attr_Min_Fields = {
  __typename?: 'nb_weather_attr_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_weather_attr" */
export type Nb_Weather_Attr_Mutation_Response = {
  __typename?: 'nb_weather_attr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Weather_Attr>;
};

/** input type for inserting object relation for remote table "nb_weather_attr" */
export type Nb_Weather_Attr_Obj_Rel_Insert_Input = {
  data: Nb_Weather_Attr_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Weather_Attr_On_Conflict>;
};

/** on_conflict condition type for table "nb_weather_attr" */
export type Nb_Weather_Attr_On_Conflict = {
  constraint: Nb_Weather_Attr_Constraint;
  update_columns?: Array<Nb_Weather_Attr_Update_Column>;
  where?: InputMaybe<Nb_Weather_Attr_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_weather_attr". */
export type Nb_Weather_Attr_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_weather_data_aggregate?: InputMaybe<Nb_Weather_Data_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_weather_attr */
export type Nb_Weather_Attr_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_weather_attr" */
export enum Nb_Weather_Attr_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_weather_attr" */
export type Nb_Weather_Attr_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_weather_attr" */
export type Nb_Weather_Attr_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Weather_Attr_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Weather_Attr_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_weather_attr" */
export enum Nb_Weather_Attr_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

export type Nb_Weather_Attr_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Weather_Attr_Set_Input>;
  where: Nb_Weather_Attr_Bool_Exp;
};

/** columns and relationships of "nb_weather_data" */
export type Nb_Weather_Data = {
  __typename?: 'nb_weather_data';
  area_mesh_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  datetime: Scalars['timestamptz'];
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  id: Scalars['uuid'];
  /** An object relationship */
  nb_area_mesh: Nb_Area_Mesh;
  /** An object relationship */
  nb_weather_attr: Nb_Weather_Attr;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  telop_code?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  updated: Scalars['timestamptz'];
  weather_id: Scalars['uuid'];
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "nb_weather_data" */
export type Nb_Weather_Data_Aggregate = {
  __typename?: 'nb_weather_data_aggregate';
  aggregate?: Maybe<Nb_Weather_Data_Aggregate_Fields>;
  nodes: Array<Nb_Weather_Data>;
};

export type Nb_Weather_Data_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Weather_Data_Aggregate_Bool_Exp_Count>;
};

export type Nb_Weather_Data_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Weather_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_weather_data" */
export type Nb_Weather_Data_Aggregate_Fields = {
  __typename?: 'nb_weather_data_aggregate_fields';
  avg?: Maybe<Nb_Weather_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Weather_Data_Max_Fields>;
  min?: Maybe<Nb_Weather_Data_Min_Fields>;
  stddev?: Maybe<Nb_Weather_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Weather_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Weather_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Weather_Data_Sum_Fields>;
  var_pop?: Maybe<Nb_Weather_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Weather_Data_Var_Samp_Fields>;
  variance?: Maybe<Nb_Weather_Data_Variance_Fields>;
};


/** aggregate fields of "nb_weather_data" */
export type Nb_Weather_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Weather_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_weather_data" */
export type Nb_Weather_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Weather_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Weather_Data_Max_Order_By>;
  min?: InputMaybe<Nb_Weather_Data_Min_Order_By>;
  stddev?: InputMaybe<Nb_Weather_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Weather_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Weather_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Weather_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Weather_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Weather_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Weather_Data_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_weather_data" */
export type Nb_Weather_Data_Arr_Rel_Insert_Input = {
  data: Array<Nb_Weather_Data_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Weather_Data_On_Conflict>;
};

/** aggregate avg on columns */
export type Nb_Weather_Data_Avg_Fields = {
  __typename?: 'nb_weather_data_avg_fields';
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_weather_data" */
export type Nb_Weather_Data_Avg_Order_By = {
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_weather_data". All fields are combined with a logical 'AND'. */
export type Nb_Weather_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Weather_Data_Bool_Exp>>;
  _not?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Weather_Data_Bool_Exp>>;
  area_mesh_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  day_temperature?: InputMaybe<Float_Comparison_Exp>;
  diff_temperature?: InputMaybe<Float_Comparison_Exp>;
  humidity?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_area_mesh?: InputMaybe<Nb_Area_Mesh_Bool_Exp>;
  nb_weather_attr?: InputMaybe<Nb_Weather_Attr_Bool_Exp>;
  night_temperature?: InputMaybe<Float_Comparison_Exp>;
  rainfall?: InputMaybe<Float_Comparison_Exp>;
  solar_radiation?: InputMaybe<Float_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  telop_code?: InputMaybe<Int_Comparison_Exp>;
  temperature?: InputMaybe<Float_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  weather_id?: InputMaybe<Uuid_Comparison_Exp>;
  wind_velocity?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_weather_data" */
export enum Nb_Weather_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbWeatherDataPkey = 'nb_weather_data_pkey'
}

/** input type for incrementing numeric columns in table "nb_weather_data" */
export type Nb_Weather_Data_Inc_Input = {
  day_temperature?: InputMaybe<Scalars['Float']>;
  diff_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  night_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  telop_code?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nb_weather_data" */
export type Nb_Weather_Data_Insert_Input = {
  area_mesh_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  day_temperature?: InputMaybe<Scalars['Float']>;
  diff_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_area_mesh?: InputMaybe<Nb_Area_Mesh_Obj_Rel_Insert_Input>;
  nb_weather_attr?: InputMaybe<Nb_Weather_Attr_Obj_Rel_Insert_Input>;
  night_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  telop_code?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  weather_id?: InputMaybe<Scalars['uuid']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Nb_Weather_Data_Max_Fields = {
  __typename?: 'nb_weather_data_max_fields';
  area_mesh_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  telop_code?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['timestamptz']>;
  weather_id?: Maybe<Scalars['uuid']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "nb_weather_data" */
export type Nb_Weather_Data_Max_Order_By = {
  area_mesh_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  weather_id?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Weather_Data_Min_Fields = {
  __typename?: 'nb_weather_data_min_fields';
  area_mesh_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  telop_code?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['timestamptz']>;
  weather_id?: Maybe<Scalars['uuid']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "nb_weather_data" */
export type Nb_Weather_Data_Min_Order_By = {
  area_mesh_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  weather_id?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_weather_data" */
export type Nb_Weather_Data_Mutation_Response = {
  __typename?: 'nb_weather_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Weather_Data>;
};

/** on_conflict condition type for table "nb_weather_data" */
export type Nb_Weather_Data_On_Conflict = {
  constraint: Nb_Weather_Data_Constraint;
  update_columns?: Array<Nb_Weather_Data_Update_Column>;
  where?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_weather_data". */
export type Nb_Weather_Data_Order_By = {
  area_mesh_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_area_mesh?: InputMaybe<Nb_Area_Mesh_Order_By>;
  nb_weather_attr?: InputMaybe<Nb_Weather_Attr_Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  weather_id?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_weather_data */
export type Nb_Weather_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nb_weather_data" */
export enum Nb_Weather_Data_Select_Column {
  /** column name */
  AreaMeshId = 'area_mesh_id',
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DayTemperature = 'day_temperature',
  /** column name */
  DiffTemperature = 'diff_temperature',
  /** column name */
  Humidity = 'humidity',
  /** column name */
  Id = 'id',
  /** column name */
  NightTemperature = 'night_temperature',
  /** column name */
  Rainfall = 'rainfall',
  /** column name */
  SolarRadiation = 'solar_radiation',
  /** column name */
  Source = 'source',
  /** column name */
  TelopCode = 'telop_code',
  /** column name */
  Temperature = 'temperature',
  /** column name */
  Updated = 'updated',
  /** column name */
  WeatherId = 'weather_id',
  /** column name */
  WindVelocity = 'wind_velocity'
}

/** input type for updating data in table "nb_weather_data" */
export type Nb_Weather_Data_Set_Input = {
  area_mesh_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  day_temperature?: InputMaybe<Scalars['Float']>;
  diff_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  night_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  telop_code?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  weather_id?: InputMaybe<Scalars['uuid']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Nb_Weather_Data_Stddev_Fields = {
  __typename?: 'nb_weather_data_stddev_fields';
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_weather_data" */
export type Nb_Weather_Data_Stddev_Order_By = {
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Weather_Data_Stddev_Pop_Fields = {
  __typename?: 'nb_weather_data_stddev_pop_fields';
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_weather_data" */
export type Nb_Weather_Data_Stddev_Pop_Order_By = {
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Weather_Data_Stddev_Samp_Fields = {
  __typename?: 'nb_weather_data_stddev_samp_fields';
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_weather_data" */
export type Nb_Weather_Data_Stddev_Samp_Order_By = {
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_weather_data" */
export type Nb_Weather_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Weather_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Weather_Data_Stream_Cursor_Value_Input = {
  area_mesh_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  day_temperature?: InputMaybe<Scalars['Float']>;
  diff_temperature?: InputMaybe<Scalars['Float']>;
  humidity?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  night_temperature?: InputMaybe<Scalars['Float']>;
  rainfall?: InputMaybe<Scalars['Float']>;
  solar_radiation?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  telop_code?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  weather_id?: InputMaybe<Scalars['uuid']>;
  wind_velocity?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Nb_Weather_Data_Sum_Fields = {
  __typename?: 'nb_weather_data_sum_fields';
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "nb_weather_data" */
export type Nb_Weather_Data_Sum_Order_By = {
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** update columns of table "nb_weather_data" */
export enum Nb_Weather_Data_Update_Column {
  /** column name */
  AreaMeshId = 'area_mesh_id',
  /** column name */
  Created = 'created',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DayTemperature = 'day_temperature',
  /** column name */
  DiffTemperature = 'diff_temperature',
  /** column name */
  Humidity = 'humidity',
  /** column name */
  Id = 'id',
  /** column name */
  NightTemperature = 'night_temperature',
  /** column name */
  Rainfall = 'rainfall',
  /** column name */
  SolarRadiation = 'solar_radiation',
  /** column name */
  Source = 'source',
  /** column name */
  TelopCode = 'telop_code',
  /** column name */
  Temperature = 'temperature',
  /** column name */
  Updated = 'updated',
  /** column name */
  WeatherId = 'weather_id',
  /** column name */
  WindVelocity = 'wind_velocity'
}

export type Nb_Weather_Data_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Weather_Data_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Weather_Data_Set_Input>;
  where: Nb_Weather_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Weather_Data_Var_Pop_Fields = {
  __typename?: 'nb_weather_data_var_pop_fields';
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_weather_data" */
export type Nb_Weather_Data_Var_Pop_Order_By = {
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Weather_Data_Var_Samp_Fields = {
  __typename?: 'nb_weather_data_var_samp_fields';
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_weather_data" */
export type Nb_Weather_Data_Var_Samp_Order_By = {
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Weather_Data_Variance_Fields = {
  __typename?: 'nb_weather_data_variance_fields';
  day_temperature?: Maybe<Scalars['Float']>;
  diff_temperature?: Maybe<Scalars['Float']>;
  humidity?: Maybe<Scalars['Float']>;
  night_temperature?: Maybe<Scalars['Float']>;
  rainfall?: Maybe<Scalars['Float']>;
  solar_radiation?: Maybe<Scalars['Float']>;
  telop_code?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  wind_velocity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_weather_data" */
export type Nb_Weather_Data_Variance_Order_By = {
  day_temperature?: InputMaybe<Order_By>;
  diff_temperature?: InputMaybe<Order_By>;
  humidity?: InputMaybe<Order_By>;
  night_temperature?: InputMaybe<Order_By>;
  rainfall?: InputMaybe<Order_By>;
  solar_radiation?: InputMaybe<Order_By>;
  telop_code?: InputMaybe<Order_By>;
  temperature?: InputMaybe<Order_By>;
  wind_velocity?: InputMaybe<Order_By>;
};

/** 広域オルソ画像の管理テーブル */
export type Nb_Wide_Orthoimg = {
  __typename?: 'nb_wide_orthoimg';
  /** An object relationship */
  app_user_active: App_User_Active;
  /** データを保有する会社のID */
  company_id: Scalars['uuid'];
  /** レコードの作成日時 */
  created: Scalars['timestamptz'];
  /** 一意な識別子 */
  id: Scalars['uuid'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An array relationship */
  nb_field_orthoimgs: Array<Nb_Field_Orthoimg>;
  /** An aggregate relationship */
  nb_field_orthoimgs_aggregate: Nb_Field_Orthoimg_Aggregate;
  /** An array relationship */
  nb_wide_orthoimg_sizes: Array<Nb_Wide_Orthoimg_Size>;
  /** An aggregate relationship */
  nb_wide_orthoimg_sizes_aggregate: Nb_Wide_Orthoimg_Size_Aggregate;
  /** An object relationship */
  nbbe_orthoimg_type: Nbbe_Orthoimg_Type;
  /** An array relationship */
  nbs_ipe_run_link_wides: Array<Nbs_Ipe_Run_Link_Wide>;
  /** An aggregate relationship */
  nbs_ipe_run_link_wides_aggregate: Nbs_Ipe_Run_Link_Wide_Aggregate;
  /** An array relationship */
  nbs_wide_orthoimg_loads: Array<Nbs_Wide_Orthoimg_Load>;
  /** An aggregate relationship */
  nbs_wide_orthoimg_loads_aggregate: Nbs_Wide_Orthoimg_Load_Aggregate;
  /** オルソ画像の種別 */
  orthoimg_type: Scalars['String'];
  /** オルソ画像のS3 URI */
  s3uri: Scalars['String'];
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime: Scalars['timestamptz'];
  /** レコードの更新日時 */
  updated: Scalars['timestamptz'];
  /** アップロードを実施したユーザのID */
  upload_user_id: Scalars['uuid'];
};


/** 広域オルソ画像の管理テーブル */
export type Nb_Wide_OrthoimgNb_Field_OrthoimgsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};


/** 広域オルソ画像の管理テーブル */
export type Nb_Wide_OrthoimgNb_Field_Orthoimgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};


/** 広域オルソ画像の管理テーブル */
export type Nb_Wide_OrthoimgNb_Wide_Orthoimg_SizesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Size_Bool_Exp>;
};


/** 広域オルソ画像の管理テーブル */
export type Nb_Wide_OrthoimgNb_Wide_Orthoimg_Sizes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Size_Bool_Exp>;
};


/** 広域オルソ画像の管理テーブル */
export type Nb_Wide_OrthoimgNbs_Ipe_Run_Link_WidesArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
};


/** 広域オルソ画像の管理テーブル */
export type Nb_Wide_OrthoimgNbs_Ipe_Run_Link_Wides_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
};


/** 広域オルソ画像の管理テーブル */
export type Nb_Wide_OrthoimgNbs_Wide_Orthoimg_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


/** 広域オルソ画像の管理テーブル */
export type Nb_Wide_OrthoimgNbs_Wide_Orthoimg_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};

/** aggregated selection of "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Aggregate = {
  __typename?: 'nb_wide_orthoimg_aggregate';
  aggregate?: Maybe<Nb_Wide_Orthoimg_Aggregate_Fields>;
  nodes: Array<Nb_Wide_Orthoimg>;
};

export type Nb_Wide_Orthoimg_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Wide_Orthoimg_Aggregate_Bool_Exp_Count>;
};

export type Nb_Wide_Orthoimg_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Aggregate_Fields = {
  __typename?: 'nb_wide_orthoimg_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Wide_Orthoimg_Max_Fields>;
  min?: Maybe<Nb_Wide_Orthoimg_Min_Fields>;
};


/** aggregate fields of "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Wide_Orthoimg_Max_Order_By>;
  min?: InputMaybe<Nb_Wide_Orthoimg_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Arr_Rel_Insert_Input = {
  data: Array<Nb_Wide_Orthoimg_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Wide_Orthoimg_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nb_wide_orthoimg". All fields are combined with a logical 'AND'. */
export type Nb_Wide_Orthoimg_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Wide_Orthoimg_Bool_Exp>>;
  _not?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Wide_Orthoimg_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_field_orthoimgs?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
  nb_field_orthoimgs_aggregate?: InputMaybe<Nb_Field_Orthoimg_Aggregate_Bool_Exp>;
  nb_wide_orthoimg_sizes?: InputMaybe<Nb_Wide_Orthoimg_Size_Bool_Exp>;
  nb_wide_orthoimg_sizes_aggregate?: InputMaybe<Nb_Wide_Orthoimg_Size_Aggregate_Bool_Exp>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Bool_Exp>;
  nbs_ipe_run_link_wides?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
  nbs_ipe_run_link_wides_aggregate?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Aggregate_Bool_Exp>;
  nbs_wide_orthoimg_loads?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
  nbs_wide_orthoimg_loads_aggregate?: InputMaybe<Nbs_Wide_Orthoimg_Load_Aggregate_Bool_Exp>;
  orthoimg_type?: InputMaybe<String_Comparison_Exp>;
  s3uri?: InputMaybe<String_Comparison_Exp>;
  sens_datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload_user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_wide_orthoimg" */
export enum Nb_Wide_Orthoimg_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbWideOrthoimgPkey = 'nb_wide_orthoimg_pkey'
}

/** input type for inserting data into table "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  /** データを保有する会社のID */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** レコードの作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_field_orthoimgs?: InputMaybe<Nb_Field_Orthoimg_Arr_Rel_Insert_Input>;
  nb_wide_orthoimg_sizes?: InputMaybe<Nb_Wide_Orthoimg_Size_Arr_Rel_Insert_Input>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Obj_Rel_Insert_Input>;
  nbs_ipe_run_link_wides?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Arr_Rel_Insert_Input>;
  nbs_wide_orthoimg_loads?: InputMaybe<Nbs_Wide_Orthoimg_Load_Arr_Rel_Insert_Input>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Scalars['String']>;
  /** オルソ画像のS3 URI */
  s3uri?: InputMaybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** アップロードを実施したユーザのID */
  upload_user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Wide_Orthoimg_Max_Fields = {
  __typename?: 'nb_wide_orthoimg_max_fields';
  /** データを保有する会社のID */
  company_id?: Maybe<Scalars['uuid']>;
  /** レコードの作成日時 */
  created?: Maybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: Maybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: Maybe<Scalars['String']>;
  /** オルソ画像のS3 URI */
  s3uri?: Maybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: Maybe<Scalars['timestamptz']>;
  /** レコードの更新日時 */
  updated?: Maybe<Scalars['timestamptz']>;
  /** アップロードを実施したユーザのID */
  upload_user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Max_Order_By = {
  /** データを保有する会社のID */
  company_id?: InputMaybe<Order_By>;
  /** レコードの作成日時 */
  created?: InputMaybe<Order_By>;
  /** 一意な識別子 */
  id?: InputMaybe<Order_By>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Order_By>;
  /** オルソ画像のS3 URI */
  s3uri?: InputMaybe<Order_By>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Order_By>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Order_By>;
  /** アップロードを実施したユーザのID */
  upload_user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Wide_Orthoimg_Min_Fields = {
  __typename?: 'nb_wide_orthoimg_min_fields';
  /** データを保有する会社のID */
  company_id?: Maybe<Scalars['uuid']>;
  /** レコードの作成日時 */
  created?: Maybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: Maybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: Maybe<Scalars['String']>;
  /** オルソ画像のS3 URI */
  s3uri?: Maybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: Maybe<Scalars['timestamptz']>;
  /** レコードの更新日時 */
  updated?: Maybe<Scalars['timestamptz']>;
  /** アップロードを実施したユーザのID */
  upload_user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Min_Order_By = {
  /** データを保有する会社のID */
  company_id?: InputMaybe<Order_By>;
  /** レコードの作成日時 */
  created?: InputMaybe<Order_By>;
  /** 一意な識別子 */
  id?: InputMaybe<Order_By>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Order_By>;
  /** オルソ画像のS3 URI */
  s3uri?: InputMaybe<Order_By>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Order_By>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Order_By>;
  /** アップロードを実施したユーザのID */
  upload_user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Mutation_Response = {
  __typename?: 'nb_wide_orthoimg_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Wide_Orthoimg>;
};

/** input type for inserting object relation for remote table "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Obj_Rel_Insert_Input = {
  data: Nb_Wide_Orthoimg_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Wide_Orthoimg_On_Conflict>;
};

/** on_conflict condition type for table "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_On_Conflict = {
  constraint: Nb_Wide_Orthoimg_Constraint;
  update_columns?: Array<Nb_Wide_Orthoimg_Update_Column>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_wide_orthoimg". */
export type Nb_Wide_Orthoimg_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_field_orthoimgs_aggregate?: InputMaybe<Nb_Field_Orthoimg_Aggregate_Order_By>;
  nb_wide_orthoimg_sizes_aggregate?: InputMaybe<Nb_Wide_Orthoimg_Size_Aggregate_Order_By>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Order_By>;
  nbs_ipe_run_link_wides_aggregate?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Aggregate_Order_By>;
  nbs_wide_orthoimg_loads_aggregate?: InputMaybe<Nbs_Wide_Orthoimg_Load_Aggregate_Order_By>;
  orthoimg_type?: InputMaybe<Order_By>;
  s3uri?: InputMaybe<Order_By>;
  sens_datetime?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upload_user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_wide_orthoimg */
export type Nb_Wide_Orthoimg_Pk_Columns_Input = {
  /** 一意な識別子 */
  id: Scalars['uuid'];
};

/** select columns of table "nb_wide_orthoimg" */
export enum Nb_Wide_Orthoimg_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  OrthoimgType = 'orthoimg_type',
  /** column name */
  S3uri = 's3uri',
  /** column name */
  SensDatetime = 'sens_datetime',
  /** column name */
  Updated = 'updated',
  /** column name */
  UploadUserId = 'upload_user_id'
}

/** input type for updating data in table "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Set_Input = {
  /** データを保有する会社のID */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** レコードの作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Scalars['String']>;
  /** オルソ画像のS3 URI */
  s3uri?: InputMaybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** アップロードを実施したユーザのID */
  upload_user_id?: InputMaybe<Scalars['uuid']>;
};

/** nb_wide_orthoimgで管理されるファイルのサイズを保持する */
export type Nb_Wide_Orthoimg_Size = {
  __typename?: 'nb_wide_orthoimg_size';
  company_key?: Maybe<Scalars['String']>;
  file_type: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_wide_orthoimg: Nb_Wide_Orthoimg;
  s3obj_size_bytes: Scalars['bigint'];
  total_size_bytes: Scalars['bigint'];
  user_id?: Maybe<Scalars['uuid']>;
  wide_orthoimg_id: Scalars['uuid'];
};

/** aggregated selection of "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Aggregate = {
  __typename?: 'nb_wide_orthoimg_size_aggregate';
  aggregate?: Maybe<Nb_Wide_Orthoimg_Size_Aggregate_Fields>;
  nodes: Array<Nb_Wide_Orthoimg_Size>;
};

export type Nb_Wide_Orthoimg_Size_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nb_Wide_Orthoimg_Size_Aggregate_Bool_Exp_Count>;
};

export type Nb_Wide_Orthoimg_Size_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nb_Wide_Orthoimg_Size_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Aggregate_Fields = {
  __typename?: 'nb_wide_orthoimg_size_aggregate_fields';
  avg?: Maybe<Nb_Wide_Orthoimg_Size_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nb_Wide_Orthoimg_Size_Max_Fields>;
  min?: Maybe<Nb_Wide_Orthoimg_Size_Min_Fields>;
  stddev?: Maybe<Nb_Wide_Orthoimg_Size_Stddev_Fields>;
  stddev_pop?: Maybe<Nb_Wide_Orthoimg_Size_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nb_Wide_Orthoimg_Size_Stddev_Samp_Fields>;
  sum?: Maybe<Nb_Wide_Orthoimg_Size_Sum_Fields>;
  var_pop?: Maybe<Nb_Wide_Orthoimg_Size_Var_Pop_Fields>;
  var_samp?: Maybe<Nb_Wide_Orthoimg_Size_Var_Samp_Fields>;
  variance?: Maybe<Nb_Wide_Orthoimg_Size_Variance_Fields>;
};


/** aggregate fields of "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Aggregate_Order_By = {
  avg?: InputMaybe<Nb_Wide_Orthoimg_Size_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nb_Wide_Orthoimg_Size_Max_Order_By>;
  min?: InputMaybe<Nb_Wide_Orthoimg_Size_Min_Order_By>;
  stddev?: InputMaybe<Nb_Wide_Orthoimg_Size_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nb_Wide_Orthoimg_Size_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nb_Wide_Orthoimg_Size_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nb_Wide_Orthoimg_Size_Sum_Order_By>;
  var_pop?: InputMaybe<Nb_Wide_Orthoimg_Size_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nb_Wide_Orthoimg_Size_Var_Samp_Order_By>;
  variance?: InputMaybe<Nb_Wide_Orthoimg_Size_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Arr_Rel_Insert_Input = {
  data: Array<Nb_Wide_Orthoimg_Size_Insert_Input>;
};

/** aggregate avg on columns */
export type Nb_Wide_Orthoimg_Size_Avg_Fields = {
  __typename?: 'nb_wide_orthoimg_size_avg_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Avg_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nb_wide_orthoimg_size". All fields are combined with a logical 'AND'. */
export type Nb_Wide_Orthoimg_Size_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Bool_Exp>>;
  _not?: InputMaybe<Nb_Wide_Orthoimg_Size_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Bool_Exp>>;
  company_key?: InputMaybe<String_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
  s3obj_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  total_size_bytes?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  wide_orthoimg_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Inc_Input = {
  s3obj_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Insert_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Obj_Rel_Insert_Input>;
  s3obj_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nb_Wide_Orthoimg_Size_Max_Fields = {
  __typename?: 'nb_wide_orthoimg_size_max_fields';
  company_key?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  s3obj_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  wide_orthoimg_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Max_Order_By = {
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nb_Wide_Orthoimg_Size_Min_Fields = {
  __typename?: 'nb_wide_orthoimg_size_min_fields';
  company_key?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  s3obj_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  wide_orthoimg_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Min_Order_By = {
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Mutation_Response = {
  __typename?: 'nb_wide_orthoimg_size_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Wide_Orthoimg_Size>;
};

/** Ordering options when selecting data from "nb_wide_orthoimg_size". */
export type Nb_Wide_Orthoimg_Size_Order_By = {
  company_key?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Order_By>;
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** select columns of table "nb_wide_orthoimg_size" */
export enum Nb_Wide_Orthoimg_Size_Select_Column {
  /** column name */
  CompanyKey = 'company_key',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  S3objSizeBytes = 's3obj_size_bytes',
  /** column name */
  TotalSizeBytes = 'total_size_bytes',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WideOrthoimgId = 'wide_orthoimg_id'
}

/** input type for updating data in table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Set_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  s3obj_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Nb_Wide_Orthoimg_Size_Stddev_Fields = {
  __typename?: 'nb_wide_orthoimg_size_stddev_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Stddev_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nb_Wide_Orthoimg_Size_Stddev_Pop_Fields = {
  __typename?: 'nb_wide_orthoimg_size_stddev_pop_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Stddev_Pop_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nb_Wide_Orthoimg_Size_Stddev_Samp_Fields = {
  __typename?: 'nb_wide_orthoimg_size_stddev_samp_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Stddev_Samp_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Wide_Orthoimg_Size_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Wide_Orthoimg_Size_Stream_Cursor_Value_Input = {
  company_key?: InputMaybe<Scalars['String']>;
  file_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  s3obj_size_bytes?: InputMaybe<Scalars['bigint']>;
  total_size_bytes?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nb_Wide_Orthoimg_Size_Sum_Fields = {
  __typename?: 'nb_wide_orthoimg_size_sum_fields';
  s3obj_size_bytes?: Maybe<Scalars['bigint']>;
  total_size_bytes?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Sum_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

export type Nb_Wide_Orthoimg_Size_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nb_Wide_Orthoimg_Size_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Wide_Orthoimg_Size_Set_Input>;
  where: Nb_Wide_Orthoimg_Size_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nb_Wide_Orthoimg_Size_Var_Pop_Fields = {
  __typename?: 'nb_wide_orthoimg_size_var_pop_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Var_Pop_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nb_Wide_Orthoimg_Size_Var_Samp_Fields = {
  __typename?: 'nb_wide_orthoimg_size_var_samp_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Var_Samp_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nb_Wide_Orthoimg_Size_Variance_Fields = {
  __typename?: 'nb_wide_orthoimg_size_variance_fields';
  s3obj_size_bytes?: Maybe<Scalars['Float']>;
  total_size_bytes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nb_wide_orthoimg_size" */
export type Nb_Wide_Orthoimg_Size_Variance_Order_By = {
  s3obj_size_bytes?: InputMaybe<Order_By>;
  total_size_bytes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nb_wide_orthoimg" */
export type Nb_Wide_Orthoimg_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Wide_Orthoimg_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Wide_Orthoimg_Stream_Cursor_Value_Input = {
  /** データを保有する会社のID */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** レコードの作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Scalars['String']>;
  /** オルソ画像のS3 URI */
  s3uri?: InputMaybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** アップロードを実施したユーザのID */
  upload_user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nb_wide_orthoimg" */
export enum Nb_Wide_Orthoimg_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  OrthoimgType = 'orthoimg_type',
  /** column name */
  S3uri = 's3uri',
  /** column name */
  SensDatetime = 'sens_datetime',
  /** column name */
  Updated = 'updated',
  /** column name */
  UploadUserId = 'upload_user_id'
}

export type Nb_Wide_Orthoimg_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Wide_Orthoimg_Set_Input>;
  where: Nb_Wide_Orthoimg_Bool_Exp;
};

/** columns and relationships of "nb_working_machine" */
export type Nb_Working_Machine = {
  __typename?: 'nb_working_machine';
  created: Scalars['timestamptz'];
  detail_info?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  key: Scalars['String'];
  /** An array relationship */
  nb_est_working_loads: Array<Nb_Est_Working_Load>;
  /** An aggregate relationship */
  nb_est_working_loads_aggregate: Nb_Est_Working_Load_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nb_working_machine" */
export type Nb_Working_MachineDetail_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "nb_working_machine" */
export type Nb_Working_MachineNb_Est_Working_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Est_Working_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Est_Working_Load_Order_By>>;
  where?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
};


/** columns and relationships of "nb_working_machine" */
export type Nb_Working_MachineNb_Est_Working_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Est_Working_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Est_Working_Load_Order_By>>;
  where?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
};

/** aggregated selection of "nb_working_machine" */
export type Nb_Working_Machine_Aggregate = {
  __typename?: 'nb_working_machine_aggregate';
  aggregate?: Maybe<Nb_Working_Machine_Aggregate_Fields>;
  nodes: Array<Nb_Working_Machine>;
};

/** aggregate fields of "nb_working_machine" */
export type Nb_Working_Machine_Aggregate_Fields = {
  __typename?: 'nb_working_machine_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nb_Working_Machine_Max_Fields>;
  min?: Maybe<Nb_Working_Machine_Min_Fields>;
};


/** aggregate fields of "nb_working_machine" */
export type Nb_Working_Machine_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nb_Working_Machine_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Nb_Working_Machine_Append_Input = {
  detail_info?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "nb_working_machine". All fields are combined with a logical 'AND'. */
export type Nb_Working_Machine_Bool_Exp = {
  _and?: InputMaybe<Array<Nb_Working_Machine_Bool_Exp>>;
  _not?: InputMaybe<Nb_Working_Machine_Bool_Exp>;
  _or?: InputMaybe<Array<Nb_Working_Machine_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  detail_info?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  nb_est_working_loads?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
  nb_est_working_loads_aggregate?: InputMaybe<Nb_Est_Working_Load_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nb_working_machine" */
export enum Nb_Working_Machine_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbWorkingMachineKeyKey = 'nb_working_machine_key_key',
  /** unique or primary key constraint on columns "id" */
  NbWorkingMachinePkey = 'nb_working_machine_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Nb_Working_Machine_Delete_At_Path_Input = {
  detail_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Nb_Working_Machine_Delete_Elem_Input = {
  detail_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Nb_Working_Machine_Delete_Key_Input = {
  detail_info?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "nb_working_machine" */
export type Nb_Working_Machine_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_info?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  nb_est_working_loads?: InputMaybe<Nb_Est_Working_Load_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nb_Working_Machine_Max_Fields = {
  __typename?: 'nb_working_machine_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nb_Working_Machine_Min_Fields = {
  __typename?: 'nb_working_machine_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nb_working_machine" */
export type Nb_Working_Machine_Mutation_Response = {
  __typename?: 'nb_working_machine_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nb_Working_Machine>;
};

/** input type for inserting object relation for remote table "nb_working_machine" */
export type Nb_Working_Machine_Obj_Rel_Insert_Input = {
  data: Nb_Working_Machine_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nb_Working_Machine_On_Conflict>;
};

/** on_conflict condition type for table "nb_working_machine" */
export type Nb_Working_Machine_On_Conflict = {
  constraint: Nb_Working_Machine_Constraint;
  update_columns?: Array<Nb_Working_Machine_Update_Column>;
  where?: InputMaybe<Nb_Working_Machine_Bool_Exp>;
};

/** Ordering options when selecting data from "nb_working_machine". */
export type Nb_Working_Machine_Order_By = {
  created?: InputMaybe<Order_By>;
  detail_info?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  nb_est_working_loads_aggregate?: InputMaybe<Nb_Est_Working_Load_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nb_working_machine */
export type Nb_Working_Machine_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Nb_Working_Machine_Prepend_Input = {
  detail_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "nb_working_machine" */
export enum Nb_Working_Machine_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  DetailInfo = 'detail_info',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nb_working_machine" */
export type Nb_Working_Machine_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_info?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nb_working_machine" */
export type Nb_Working_Machine_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nb_Working_Machine_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nb_Working_Machine_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_info?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nb_working_machine" */
export enum Nb_Working_Machine_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  DetailInfo = 'detail_info',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Updated = 'updated'
}

export type Nb_Working_Machine_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Nb_Working_Machine_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Nb_Working_Machine_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Nb_Working_Machine_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Nb_Working_Machine_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Nb_Working_Machine_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nb_Working_Machine_Set_Input>;
  where: Nb_Working_Machine_Bool_Exp;
};

/** nb_picture_analysisのレコードの状態を管理 */
export type Nbb_Picture_Analysis_Status = {
  __typename?: 'nbb_picture_analysis_status';
  created: Scalars['timestamptz'];
  field_picture_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_field_picture: Nb_Field_Picture;
  /** An object relationship */
  nb_picture_analysis?: Maybe<Nb_Picture_Analysis>;
  /** An object relationship */
  nb_picture_analysis_type: Nb_Picture_Analysis_Type;
  picture_analysis_id?: Maybe<Scalars['uuid']>;
  picture_analysis_type_id: Scalars['uuid'];
  request_id?: Maybe<Scalars['uuid']>;
  status: Scalars['String'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_Aggregate = {
  __typename?: 'nbb_picture_analysis_status_aggregate';
  aggregate?: Maybe<Nbb_Picture_Analysis_Status_Aggregate_Fields>;
  nodes: Array<Nbb_Picture_Analysis_Status>;
};

export type Nbb_Picture_Analysis_Status_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbb_Picture_Analysis_Status_Aggregate_Bool_Exp_Count>;
};

export type Nbb_Picture_Analysis_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_Aggregate_Fields = {
  __typename?: 'nbb_picture_analysis_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbb_Picture_Analysis_Status_Max_Fields>;
  min?: Maybe<Nbb_Picture_Analysis_Status_Min_Fields>;
};


/** aggregate fields of "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbb_Picture_Analysis_Status_Max_Order_By>;
  min?: InputMaybe<Nbb_Picture_Analysis_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_Arr_Rel_Insert_Input = {
  data: Array<Nbb_Picture_Analysis_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbb_Picture_Analysis_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbb_picture_analysis_status". All fields are combined with a logical 'AND'. */
export type Nbb_Picture_Analysis_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Bool_Exp>>;
  _not?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_picture_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
  nb_picture_analysis?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
  nb_picture_analysis_type?: InputMaybe<Nb_Picture_Analysis_Type_Bool_Exp>;
  picture_analysis_id?: InputMaybe<Uuid_Comparison_Exp>;
  picture_analysis_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  request_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbb_picture_analysis_status" */
export enum Nbb_Picture_Analysis_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbbPictureAnalysisStatusPkey = 'nbb_picture_analysis_status_pkey'
}

/** input type for inserting data into table "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Obj_Rel_Insert_Input>;
  nb_picture_analysis?: InputMaybe<Nb_Picture_Analysis_Obj_Rel_Insert_Input>;
  nb_picture_analysis_type?: InputMaybe<Nb_Picture_Analysis_Type_Obj_Rel_Insert_Input>;
  picture_analysis_id?: InputMaybe<Scalars['uuid']>;
  picture_analysis_type_id?: InputMaybe<Scalars['uuid']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nbb_Picture_Analysis_Status_Max_Fields = {
  __typename?: 'nbb_picture_analysis_status_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_picture_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  picture_analysis_id?: Maybe<Scalars['uuid']>;
  picture_analysis_type_id?: Maybe<Scalars['uuid']>;
  request_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  picture_analysis_id?: InputMaybe<Order_By>;
  picture_analysis_type_id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbb_Picture_Analysis_Status_Min_Fields = {
  __typename?: 'nbb_picture_analysis_status_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_picture_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  picture_analysis_id?: Maybe<Scalars['uuid']>;
  picture_analysis_type_id?: Maybe<Scalars['uuid']>;
  request_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  picture_analysis_id?: InputMaybe<Order_By>;
  picture_analysis_type_id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_Mutation_Response = {
  __typename?: 'nbb_picture_analysis_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbb_Picture_Analysis_Status>;
};

/** on_conflict condition type for table "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_On_Conflict = {
  constraint: Nbb_Picture_Analysis_Status_Constraint;
  update_columns?: Array<Nbb_Picture_Analysis_Status_Update_Column>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "nbb_picture_analysis_status". */
export type Nbb_Picture_Analysis_Status_Order_By = {
  created?: InputMaybe<Order_By>;
  field_picture_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_field_picture?: InputMaybe<Nb_Field_Picture_Order_By>;
  nb_picture_analysis?: InputMaybe<Nb_Picture_Analysis_Order_By>;
  nb_picture_analysis_type?: InputMaybe<Nb_Picture_Analysis_Type_Order_By>;
  picture_analysis_id?: InputMaybe<Order_By>;
  picture_analysis_type_id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbb_picture_analysis_status */
export type Nbb_Picture_Analysis_Status_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbb_picture_analysis_status" */
export enum Nbb_Picture_Analysis_Status_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldPictureId = 'field_picture_id',
  /** column name */
  Id = 'id',
  /** column name */
  PictureAnalysisId = 'picture_analysis_id',
  /** column name */
  PictureAnalysisTypeId = 'picture_analysis_type_id',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  picture_analysis_id?: InputMaybe<Scalars['uuid']>;
  picture_analysis_type_id?: InputMaybe<Scalars['uuid']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nbb_picture_analysis_status" */
export type Nbb_Picture_Analysis_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbb_Picture_Analysis_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbb_Picture_Analysis_Status_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_picture_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  picture_analysis_id?: InputMaybe<Scalars['uuid']>;
  picture_analysis_type_id?: InputMaybe<Scalars['uuid']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nbb_picture_analysis_status" */
export enum Nbb_Picture_Analysis_Status_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldPictureId = 'field_picture_id',
  /** column name */
  Id = 'id',
  /** column name */
  PictureAnalysisId = 'picture_analysis_id',
  /** column name */
  PictureAnalysisTypeId = 'picture_analysis_type_id',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated'
}

export type Nbb_Picture_Analysis_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbb_Picture_Analysis_Status_Set_Input>;
  where: Nbb_Picture_Analysis_Status_Bool_Exp;
};

/** 微気象推定 */
export type Nbbe_Microclimate = {
  __typename?: 'nbbe_microclimate';
  chemical_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  created: Scalars['timestamptz'];
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  datetime: Scalars['timestamptz'];
  detail_data?: Maybe<Scalars['json']>;
  id: Scalars['uuid'];
  mc_version: Scalars['String'];
  /** An object relationship */
  nb_cultivation_plan?: Maybe<Nb_Cultivation_Plan>;
  /** An object relationship */
  nb_ufield: Nb_Ufield;
  /** An object relationship */
  nbbe_weather_type: Nbbe_Weather_Type;
  organic_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  soil_mineralization_amount?: Maybe<Scalars['Float']>;
  soil_moisture?: Maybe<Scalars['Float']>;
  soil_temperature?: Maybe<Scalars['Float']>;
  ufield_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  weather_type: Scalars['String'];
};


/** 微気象推定 */
export type Nbbe_MicroclimateDetail_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "nbbe_microclimate" */
export type Nbbe_Microclimate_Aggregate = {
  __typename?: 'nbbe_microclimate_aggregate';
  aggregate?: Maybe<Nbbe_Microclimate_Aggregate_Fields>;
  nodes: Array<Nbbe_Microclimate>;
};

export type Nbbe_Microclimate_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbbe_Microclimate_Aggregate_Bool_Exp_Count>;
};

export type Nbbe_Microclimate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbbe_microclimate" */
export type Nbbe_Microclimate_Aggregate_Fields = {
  __typename?: 'nbbe_microclimate_aggregate_fields';
  avg?: Maybe<Nbbe_Microclimate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nbbe_Microclimate_Max_Fields>;
  min?: Maybe<Nbbe_Microclimate_Min_Fields>;
  stddev?: Maybe<Nbbe_Microclimate_Stddev_Fields>;
  stddev_pop?: Maybe<Nbbe_Microclimate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nbbe_Microclimate_Stddev_Samp_Fields>;
  sum?: Maybe<Nbbe_Microclimate_Sum_Fields>;
  var_pop?: Maybe<Nbbe_Microclimate_Var_Pop_Fields>;
  var_samp?: Maybe<Nbbe_Microclimate_Var_Samp_Fields>;
  variance?: Maybe<Nbbe_Microclimate_Variance_Fields>;
};


/** aggregate fields of "nbbe_microclimate" */
export type Nbbe_Microclimate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbbe_microclimate" */
export type Nbbe_Microclimate_Aggregate_Order_By = {
  avg?: InputMaybe<Nbbe_Microclimate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbbe_Microclimate_Max_Order_By>;
  min?: InputMaybe<Nbbe_Microclimate_Min_Order_By>;
  stddev?: InputMaybe<Nbbe_Microclimate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nbbe_Microclimate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nbbe_Microclimate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nbbe_Microclimate_Sum_Order_By>;
  var_pop?: InputMaybe<Nbbe_Microclimate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nbbe_Microclimate_Var_Samp_Order_By>;
  variance?: InputMaybe<Nbbe_Microclimate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nbbe_microclimate" */
export type Nbbe_Microclimate_Arr_Rel_Insert_Input = {
  data: Array<Nbbe_Microclimate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbbe_Microclimate_On_Conflict>;
};

/** aggregate avg on columns */
export type Nbbe_Microclimate_Avg_Fields = {
  __typename?: 'nbbe_microclimate_avg_fields';
  chemical_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  organic_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  soil_mineralization_amount?: Maybe<Scalars['Float']>;
  soil_moisture?: Maybe<Scalars['Float']>;
  soil_temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nbbe_microclimate" */
export type Nbbe_Microclimate_Avg_Order_By = {
  chemical_fertilizer_efficiency?: InputMaybe<Order_By>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Order_By>;
  organic_fertilizer_efficiency?: InputMaybe<Order_By>;
  soil_mineralization_amount?: InputMaybe<Order_By>;
  soil_moisture?: InputMaybe<Order_By>;
  soil_temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nbbe_microclimate". All fields are combined with a logical 'AND'. */
export type Nbbe_Microclimate_Bool_Exp = {
  _and?: InputMaybe<Array<Nbbe_Microclimate_Bool_Exp>>;
  _not?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
  _or?: InputMaybe<Array<Nbbe_Microclimate_Bool_Exp>>;
  chemical_fertilizer_efficiency?: InputMaybe<Float_Comparison_Exp>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Float_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  cultivation_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  detail_data?: InputMaybe<Json_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mc_version?: InputMaybe<String_Comparison_Exp>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
  nb_ufield?: InputMaybe<Nb_Ufield_Bool_Exp>;
  nbbe_weather_type?: InputMaybe<Nbbe_Weather_Type_Bool_Exp>;
  organic_fertilizer_efficiency?: InputMaybe<Float_Comparison_Exp>;
  soil_mineralization_amount?: InputMaybe<Float_Comparison_Exp>;
  soil_moisture?: InputMaybe<Float_Comparison_Exp>;
  soil_temperature?: InputMaybe<Float_Comparison_Exp>;
  ufield_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  water_level?: InputMaybe<Float_Comparison_Exp>;
  water_temperature?: InputMaybe<Float_Comparison_Exp>;
  weather_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbbe_microclimate" */
export enum Nbbe_Microclimate_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbbeMicroclimatePkey = 'nbbe_microclimate_pkey'
}

/** input type for incrementing numeric columns in table "nbbe_microclimate" */
export type Nbbe_Microclimate_Inc_Input = {
  chemical_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  organic_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  soil_mineralization_amount?: InputMaybe<Scalars['Float']>;
  soil_moisture?: InputMaybe<Scalars['Float']>;
  soil_temperature?: InputMaybe<Scalars['Float']>;
  water_level?: InputMaybe<Scalars['Float']>;
  water_temperature?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "nbbe_microclimate" */
export type Nbbe_Microclimate_Insert_Input = {
  chemical_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  detail_data?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['uuid']>;
  mc_version?: InputMaybe<Scalars['String']>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Obj_Rel_Insert_Input>;
  nb_ufield?: InputMaybe<Nb_Ufield_Obj_Rel_Insert_Input>;
  nbbe_weather_type?: InputMaybe<Nbbe_Weather_Type_Obj_Rel_Insert_Input>;
  organic_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  soil_mineralization_amount?: InputMaybe<Scalars['Float']>;
  soil_moisture?: InputMaybe<Scalars['Float']>;
  soil_temperature?: InputMaybe<Scalars['Float']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  water_level?: InputMaybe<Scalars['Float']>;
  water_temperature?: InputMaybe<Scalars['Float']>;
  weather_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nbbe_Microclimate_Max_Fields = {
  __typename?: 'nbbe_microclimate_max_fields';
  chemical_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mc_version?: Maybe<Scalars['String']>;
  organic_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  soil_mineralization_amount?: Maybe<Scalars['Float']>;
  soil_moisture?: Maybe<Scalars['Float']>;
  soil_temperature?: Maybe<Scalars['Float']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  weather_type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "nbbe_microclimate" */
export type Nbbe_Microclimate_Max_Order_By = {
  chemical_fertilizer_efficiency?: InputMaybe<Order_By>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mc_version?: InputMaybe<Order_By>;
  organic_fertilizer_efficiency?: InputMaybe<Order_By>;
  soil_mineralization_amount?: InputMaybe<Order_By>;
  soil_moisture?: InputMaybe<Order_By>;
  soil_temperature?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  weather_type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbbe_Microclimate_Min_Fields = {
  __typename?: 'nbbe_microclimate_min_fields';
  chemical_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['timestamptz']>;
  cultivation_plan_id?: Maybe<Scalars['uuid']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mc_version?: Maybe<Scalars['String']>;
  organic_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  soil_mineralization_amount?: Maybe<Scalars['Float']>;
  soil_moisture?: Maybe<Scalars['Float']>;
  soil_temperature?: Maybe<Scalars['Float']>;
  ufield_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
  weather_type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "nbbe_microclimate" */
export type Nbbe_Microclimate_Min_Order_By = {
  chemical_fertilizer_efficiency?: InputMaybe<Order_By>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mc_version?: InputMaybe<Order_By>;
  organic_fertilizer_efficiency?: InputMaybe<Order_By>;
  soil_mineralization_amount?: InputMaybe<Order_By>;
  soil_moisture?: InputMaybe<Order_By>;
  soil_temperature?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  weather_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbbe_microclimate" */
export type Nbbe_Microclimate_Mutation_Response = {
  __typename?: 'nbbe_microclimate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbbe_Microclimate>;
};

/** on_conflict condition type for table "nbbe_microclimate" */
export type Nbbe_Microclimate_On_Conflict = {
  constraint: Nbbe_Microclimate_Constraint;
  update_columns?: Array<Nbbe_Microclimate_Update_Column>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};

/** Ordering options when selecting data from "nbbe_microclimate". */
export type Nbbe_Microclimate_Order_By = {
  chemical_fertilizer_efficiency?: InputMaybe<Order_By>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  cultivation_plan_id?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  detail_data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mc_version?: InputMaybe<Order_By>;
  nb_cultivation_plan?: InputMaybe<Nb_Cultivation_Plan_Order_By>;
  nb_ufield?: InputMaybe<Nb_Ufield_Order_By>;
  nbbe_weather_type?: InputMaybe<Nbbe_Weather_Type_Order_By>;
  organic_fertilizer_efficiency?: InputMaybe<Order_By>;
  soil_mineralization_amount?: InputMaybe<Order_By>;
  soil_moisture?: InputMaybe<Order_By>;
  soil_temperature?: InputMaybe<Order_By>;
  ufield_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
  weather_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbbe_microclimate */
export type Nbbe_Microclimate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbbe_microclimate" */
export enum Nbbe_Microclimate_Select_Column {
  /** column name */
  ChemicalFertilizerEfficiency = 'chemical_fertilizer_efficiency',
  /** column name */
  CoatedUreaFertilizerEfficiency = 'coated_urea_fertilizer_efficiency',
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DetailData = 'detail_data',
  /** column name */
  Id = 'id',
  /** column name */
  McVersion = 'mc_version',
  /** column name */
  OrganicFertilizerEfficiency = 'organic_fertilizer_efficiency',
  /** column name */
  SoilMineralizationAmount = 'soil_mineralization_amount',
  /** column name */
  SoilMoisture = 'soil_moisture',
  /** column name */
  SoilTemperature = 'soil_temperature',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  WaterLevel = 'water_level',
  /** column name */
  WaterTemperature = 'water_temperature',
  /** column name */
  WeatherType = 'weather_type'
}

/** input type for updating data in table "nbbe_microclimate" */
export type Nbbe_Microclimate_Set_Input = {
  chemical_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  detail_data?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['uuid']>;
  mc_version?: InputMaybe<Scalars['String']>;
  organic_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  soil_mineralization_amount?: InputMaybe<Scalars['Float']>;
  soil_moisture?: InputMaybe<Scalars['Float']>;
  soil_temperature?: InputMaybe<Scalars['Float']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  water_level?: InputMaybe<Scalars['Float']>;
  water_temperature?: InputMaybe<Scalars['Float']>;
  weather_type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Nbbe_Microclimate_Stddev_Fields = {
  __typename?: 'nbbe_microclimate_stddev_fields';
  chemical_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  organic_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  soil_mineralization_amount?: Maybe<Scalars['Float']>;
  soil_moisture?: Maybe<Scalars['Float']>;
  soil_temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nbbe_microclimate" */
export type Nbbe_Microclimate_Stddev_Order_By = {
  chemical_fertilizer_efficiency?: InputMaybe<Order_By>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Order_By>;
  organic_fertilizer_efficiency?: InputMaybe<Order_By>;
  soil_mineralization_amount?: InputMaybe<Order_By>;
  soil_moisture?: InputMaybe<Order_By>;
  soil_temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nbbe_Microclimate_Stddev_Pop_Fields = {
  __typename?: 'nbbe_microclimate_stddev_pop_fields';
  chemical_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  organic_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  soil_mineralization_amount?: Maybe<Scalars['Float']>;
  soil_moisture?: Maybe<Scalars['Float']>;
  soil_temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nbbe_microclimate" */
export type Nbbe_Microclimate_Stddev_Pop_Order_By = {
  chemical_fertilizer_efficiency?: InputMaybe<Order_By>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Order_By>;
  organic_fertilizer_efficiency?: InputMaybe<Order_By>;
  soil_mineralization_amount?: InputMaybe<Order_By>;
  soil_moisture?: InputMaybe<Order_By>;
  soil_temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nbbe_Microclimate_Stddev_Samp_Fields = {
  __typename?: 'nbbe_microclimate_stddev_samp_fields';
  chemical_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  organic_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  soil_mineralization_amount?: Maybe<Scalars['Float']>;
  soil_moisture?: Maybe<Scalars['Float']>;
  soil_temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nbbe_microclimate" */
export type Nbbe_Microclimate_Stddev_Samp_Order_By = {
  chemical_fertilizer_efficiency?: InputMaybe<Order_By>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Order_By>;
  organic_fertilizer_efficiency?: InputMaybe<Order_By>;
  soil_mineralization_amount?: InputMaybe<Order_By>;
  soil_moisture?: InputMaybe<Order_By>;
  soil_temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nbbe_microclimate" */
export type Nbbe_Microclimate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbbe_Microclimate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbbe_Microclimate_Stream_Cursor_Value_Input = {
  chemical_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  cultivation_plan_id?: InputMaybe<Scalars['uuid']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  detail_data?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['uuid']>;
  mc_version?: InputMaybe<Scalars['String']>;
  organic_fertilizer_efficiency?: InputMaybe<Scalars['Float']>;
  soil_mineralization_amount?: InputMaybe<Scalars['Float']>;
  soil_moisture?: InputMaybe<Scalars['Float']>;
  soil_temperature?: InputMaybe<Scalars['Float']>;
  ufield_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  water_level?: InputMaybe<Scalars['Float']>;
  water_temperature?: InputMaybe<Scalars['Float']>;
  weather_type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Nbbe_Microclimate_Sum_Fields = {
  __typename?: 'nbbe_microclimate_sum_fields';
  chemical_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  organic_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  soil_mineralization_amount?: Maybe<Scalars['Float']>;
  soil_moisture?: Maybe<Scalars['Float']>;
  soil_temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "nbbe_microclimate" */
export type Nbbe_Microclimate_Sum_Order_By = {
  chemical_fertilizer_efficiency?: InputMaybe<Order_By>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Order_By>;
  organic_fertilizer_efficiency?: InputMaybe<Order_By>;
  soil_mineralization_amount?: InputMaybe<Order_By>;
  soil_moisture?: InputMaybe<Order_By>;
  soil_temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
};

/** update columns of table "nbbe_microclimate" */
export enum Nbbe_Microclimate_Update_Column {
  /** column name */
  ChemicalFertilizerEfficiency = 'chemical_fertilizer_efficiency',
  /** column name */
  CoatedUreaFertilizerEfficiency = 'coated_urea_fertilizer_efficiency',
  /** column name */
  Created = 'created',
  /** column name */
  CultivationPlanId = 'cultivation_plan_id',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DetailData = 'detail_data',
  /** column name */
  Id = 'id',
  /** column name */
  McVersion = 'mc_version',
  /** column name */
  OrganicFertilizerEfficiency = 'organic_fertilizer_efficiency',
  /** column name */
  SoilMineralizationAmount = 'soil_mineralization_amount',
  /** column name */
  SoilMoisture = 'soil_moisture',
  /** column name */
  SoilTemperature = 'soil_temperature',
  /** column name */
  UfieldId = 'ufield_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  WaterLevel = 'water_level',
  /** column name */
  WaterTemperature = 'water_temperature',
  /** column name */
  WeatherType = 'weather_type'
}

export type Nbbe_Microclimate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nbbe_Microclimate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbbe_Microclimate_Set_Input>;
  where: Nbbe_Microclimate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nbbe_Microclimate_Var_Pop_Fields = {
  __typename?: 'nbbe_microclimate_var_pop_fields';
  chemical_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  organic_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  soil_mineralization_amount?: Maybe<Scalars['Float']>;
  soil_moisture?: Maybe<Scalars['Float']>;
  soil_temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nbbe_microclimate" */
export type Nbbe_Microclimate_Var_Pop_Order_By = {
  chemical_fertilizer_efficiency?: InputMaybe<Order_By>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Order_By>;
  organic_fertilizer_efficiency?: InputMaybe<Order_By>;
  soil_mineralization_amount?: InputMaybe<Order_By>;
  soil_moisture?: InputMaybe<Order_By>;
  soil_temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nbbe_Microclimate_Var_Samp_Fields = {
  __typename?: 'nbbe_microclimate_var_samp_fields';
  chemical_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  organic_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  soil_mineralization_amount?: Maybe<Scalars['Float']>;
  soil_moisture?: Maybe<Scalars['Float']>;
  soil_temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nbbe_microclimate" */
export type Nbbe_Microclimate_Var_Samp_Order_By = {
  chemical_fertilizer_efficiency?: InputMaybe<Order_By>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Order_By>;
  organic_fertilizer_efficiency?: InputMaybe<Order_By>;
  soil_mineralization_amount?: InputMaybe<Order_By>;
  soil_moisture?: InputMaybe<Order_By>;
  soil_temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nbbe_Microclimate_Variance_Fields = {
  __typename?: 'nbbe_microclimate_variance_fields';
  chemical_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  coated_urea_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  organic_fertilizer_efficiency?: Maybe<Scalars['Float']>;
  soil_mineralization_amount?: Maybe<Scalars['Float']>;
  soil_moisture?: Maybe<Scalars['Float']>;
  soil_temperature?: Maybe<Scalars['Float']>;
  water_level?: Maybe<Scalars['Float']>;
  water_temperature?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nbbe_microclimate" */
export type Nbbe_Microclimate_Variance_Order_By = {
  chemical_fertilizer_efficiency?: InputMaybe<Order_By>;
  coated_urea_fertilizer_efficiency?: InputMaybe<Order_By>;
  organic_fertilizer_efficiency?: InputMaybe<Order_By>;
  soil_mineralization_amount?: InputMaybe<Order_By>;
  soil_moisture?: InputMaybe<Order_By>;
  soil_temperature?: InputMaybe<Order_By>;
  water_level?: InputMaybe<Order_By>;
  water_temperature?: InputMaybe<Order_By>;
};

/** オルソ画像のタイプ定義 */
export type Nbbe_Orthoimg_Type = {
  __typename?: 'nbbe_orthoimg_type';
  name: Scalars['String'];
  /** An array relationship */
  nb_field_orthoimgs: Array<Nb_Field_Orthoimg>;
  /** An aggregate relationship */
  nb_field_orthoimgs_aggregate: Nb_Field_Orthoimg_Aggregate;
  /** An array relationship */
  nb_wide_orthoimgs: Array<Nb_Wide_Orthoimg>;
  /** An aggregate relationship */
  nb_wide_orthoimgs_aggregate: Nb_Wide_Orthoimg_Aggregate;
  /** An array relationship */
  nbs_wide_orthoimg_loads: Array<Nbs_Wide_Orthoimg_Load>;
  /** An aggregate relationship */
  nbs_wide_orthoimg_loads_aggregate: Nbs_Wide_Orthoimg_Load_Aggregate;
  /** An array relationship */
  nipe_engine_link_orthoimg_types: Array<Nipe_Engine_Link_Orthoimg_Type>;
  /** An aggregate relationship */
  nipe_engine_link_orthoimg_types_aggregate: Nipe_Engine_Link_Orthoimg_Type_Aggregate;
  value: Scalars['String'];
};


/** オルソ画像のタイプ定義 */
export type Nbbe_Orthoimg_TypeNb_Field_OrthoimgsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};


/** オルソ画像のタイプ定義 */
export type Nbbe_Orthoimg_TypeNb_Field_Orthoimgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};


/** オルソ画像のタイプ定義 */
export type Nbbe_Orthoimg_TypeNb_Wide_OrthoimgsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};


/** オルソ画像のタイプ定義 */
export type Nbbe_Orthoimg_TypeNb_Wide_Orthoimgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};


/** オルソ画像のタイプ定義 */
export type Nbbe_Orthoimg_TypeNbs_Wide_Orthoimg_LoadsArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


/** オルソ画像のタイプ定義 */
export type Nbbe_Orthoimg_TypeNbs_Wide_Orthoimg_Loads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


/** オルソ画像のタイプ定義 */
export type Nbbe_Orthoimg_TypeNipe_Engine_Link_Orthoimg_TypesArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
};


/** オルソ画像のタイプ定義 */
export type Nbbe_Orthoimg_TypeNipe_Engine_Link_Orthoimg_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
};

/** aggregated selection of "nbbe_orthoimg_type" */
export type Nbbe_Orthoimg_Type_Aggregate = {
  __typename?: 'nbbe_orthoimg_type_aggregate';
  aggregate?: Maybe<Nbbe_Orthoimg_Type_Aggregate_Fields>;
  nodes: Array<Nbbe_Orthoimg_Type>;
};

/** aggregate fields of "nbbe_orthoimg_type" */
export type Nbbe_Orthoimg_Type_Aggregate_Fields = {
  __typename?: 'nbbe_orthoimg_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbbe_Orthoimg_Type_Max_Fields>;
  min?: Maybe<Nbbe_Orthoimg_Type_Min_Fields>;
};


/** aggregate fields of "nbbe_orthoimg_type" */
export type Nbbe_Orthoimg_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbbe_Orthoimg_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nbbe_orthoimg_type". All fields are combined with a logical 'AND'. */
export type Nbbe_Orthoimg_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Nbbe_Orthoimg_Type_Bool_Exp>>;
  _not?: InputMaybe<Nbbe_Orthoimg_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Nbbe_Orthoimg_Type_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_field_orthoimgs?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
  nb_field_orthoimgs_aggregate?: InputMaybe<Nb_Field_Orthoimg_Aggregate_Bool_Exp>;
  nb_wide_orthoimgs?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
  nb_wide_orthoimgs_aggregate?: InputMaybe<Nb_Wide_Orthoimg_Aggregate_Bool_Exp>;
  nbs_wide_orthoimg_loads?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
  nbs_wide_orthoimg_loads_aggregate?: InputMaybe<Nbs_Wide_Orthoimg_Load_Aggregate_Bool_Exp>;
  nipe_engine_link_orthoimg_types?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
  nipe_engine_link_orthoimg_types_aggregate?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbbe_orthoimg_type" */
export enum Nbbe_Orthoimg_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  NbbeOrthoimgTypePkey = 'nbbe_orthoimg_type_pkey'
}

/** input type for inserting data into table "nbbe_orthoimg_type" */
export type Nbbe_Orthoimg_Type_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  nb_field_orthoimgs?: InputMaybe<Nb_Field_Orthoimg_Arr_Rel_Insert_Input>;
  nb_wide_orthoimgs?: InputMaybe<Nb_Wide_Orthoimg_Arr_Rel_Insert_Input>;
  nbs_wide_orthoimg_loads?: InputMaybe<Nbs_Wide_Orthoimg_Load_Arr_Rel_Insert_Input>;
  nipe_engine_link_orthoimg_types?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nbbe_Orthoimg_Type_Max_Fields = {
  __typename?: 'nbbe_orthoimg_type_max_fields';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nbbe_Orthoimg_Type_Min_Fields = {
  __typename?: 'nbbe_orthoimg_type_min_fields';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nbbe_orthoimg_type" */
export type Nbbe_Orthoimg_Type_Mutation_Response = {
  __typename?: 'nbbe_orthoimg_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbbe_Orthoimg_Type>;
};

/** input type for inserting object relation for remote table "nbbe_orthoimg_type" */
export type Nbbe_Orthoimg_Type_Obj_Rel_Insert_Input = {
  data: Nbbe_Orthoimg_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbbe_Orthoimg_Type_On_Conflict>;
};

/** on_conflict condition type for table "nbbe_orthoimg_type" */
export type Nbbe_Orthoimg_Type_On_Conflict = {
  constraint: Nbbe_Orthoimg_Type_Constraint;
  update_columns?: Array<Nbbe_Orthoimg_Type_Update_Column>;
  where?: InputMaybe<Nbbe_Orthoimg_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "nbbe_orthoimg_type". */
export type Nbbe_Orthoimg_Type_Order_By = {
  name?: InputMaybe<Order_By>;
  nb_field_orthoimgs_aggregate?: InputMaybe<Nb_Field_Orthoimg_Aggregate_Order_By>;
  nb_wide_orthoimgs_aggregate?: InputMaybe<Nb_Wide_Orthoimg_Aggregate_Order_By>;
  nbs_wide_orthoimg_loads_aggregate?: InputMaybe<Nbs_Wide_Orthoimg_Load_Aggregate_Order_By>;
  nipe_engine_link_orthoimg_types_aggregate?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbbe_orthoimg_type */
export type Nbbe_Orthoimg_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "nbbe_orthoimg_type" */
export enum Nbbe_Orthoimg_Type_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "nbbe_orthoimg_type" */
export type Nbbe_Orthoimg_Type_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nbbe_orthoimg_type" */
export type Nbbe_Orthoimg_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbbe_Orthoimg_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbbe_Orthoimg_Type_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nbbe_orthoimg_type" */
export enum Nbbe_Orthoimg_Type_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

export type Nbbe_Orthoimg_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbbe_Orthoimg_Type_Set_Input>;
  where: Nbbe_Orthoimg_Type_Bool_Exp;
};

/** columns and relationships of "nbbe_weather_type" */
export type Nbbe_Weather_Type = {
  __typename?: 'nbbe_weather_type';
  name: Scalars['String'];
  /** An array relationship */
  nbbe_microclimates: Array<Nbbe_Microclimate>;
  /** An aggregate relationship */
  nbbe_microclimates_aggregate: Nbbe_Microclimate_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "nbbe_weather_type" */
export type Nbbe_Weather_TypeNbbe_MicroclimatesArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Microclimate_Order_By>>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};


/** columns and relationships of "nbbe_weather_type" */
export type Nbbe_Weather_TypeNbbe_Microclimates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Microclimate_Order_By>>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};

/** aggregated selection of "nbbe_weather_type" */
export type Nbbe_Weather_Type_Aggregate = {
  __typename?: 'nbbe_weather_type_aggregate';
  aggregate?: Maybe<Nbbe_Weather_Type_Aggregate_Fields>;
  nodes: Array<Nbbe_Weather_Type>;
};

/** aggregate fields of "nbbe_weather_type" */
export type Nbbe_Weather_Type_Aggregate_Fields = {
  __typename?: 'nbbe_weather_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbbe_Weather_Type_Max_Fields>;
  min?: Maybe<Nbbe_Weather_Type_Min_Fields>;
};


/** aggregate fields of "nbbe_weather_type" */
export type Nbbe_Weather_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbbe_Weather_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nbbe_weather_type". All fields are combined with a logical 'AND'. */
export type Nbbe_Weather_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Nbbe_Weather_Type_Bool_Exp>>;
  _not?: InputMaybe<Nbbe_Weather_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Nbbe_Weather_Type_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  nbbe_microclimates?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
  nbbe_microclimates_aggregate?: InputMaybe<Nbbe_Microclimate_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbbe_weather_type" */
export enum Nbbe_Weather_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  NbbeWeatherTypePkey = 'nbbe_weather_type_pkey'
}

/** input type for inserting data into table "nbbe_weather_type" */
export type Nbbe_Weather_Type_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  nbbe_microclimates?: InputMaybe<Nbbe_Microclimate_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nbbe_Weather_Type_Max_Fields = {
  __typename?: 'nbbe_weather_type_max_fields';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nbbe_Weather_Type_Min_Fields = {
  __typename?: 'nbbe_weather_type_min_fields';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nbbe_weather_type" */
export type Nbbe_Weather_Type_Mutation_Response = {
  __typename?: 'nbbe_weather_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbbe_Weather_Type>;
};

/** input type for inserting object relation for remote table "nbbe_weather_type" */
export type Nbbe_Weather_Type_Obj_Rel_Insert_Input = {
  data: Nbbe_Weather_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbbe_Weather_Type_On_Conflict>;
};

/** on_conflict condition type for table "nbbe_weather_type" */
export type Nbbe_Weather_Type_On_Conflict = {
  constraint: Nbbe_Weather_Type_Constraint;
  update_columns?: Array<Nbbe_Weather_Type_Update_Column>;
  where?: InputMaybe<Nbbe_Weather_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "nbbe_weather_type". */
export type Nbbe_Weather_Type_Order_By = {
  name?: InputMaybe<Order_By>;
  nbbe_microclimates_aggregate?: InputMaybe<Nbbe_Microclimate_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbbe_weather_type */
export type Nbbe_Weather_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "nbbe_weather_type" */
export enum Nbbe_Weather_Type_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "nbbe_weather_type" */
export type Nbbe_Weather_Type_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nbbe_weather_type" */
export type Nbbe_Weather_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbbe_Weather_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbbe_Weather_Type_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nbbe_weather_type" */
export enum Nbbe_Weather_Type_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

export type Nbbe_Weather_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbbe_Weather_Type_Set_Input>;
  where: Nbbe_Weather_Type_Bool_Exp;
};

/** columns and relationships of "nbpm_authorization" */
export type Nbpm_Authorization = {
  __typename?: 'nbpm_authorization';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  nbpm_contract_plan_links: Array<Nbpm_Contract_Plan_Link>;
  /** An aggregate relationship */
  nbpm_contract_plan_links_aggregate: Nbpm_Contract_Plan_Link_Aggregate;
  /** An array relationship */
  nbpm_imsattr_authorization_links: Array<Nbpm_Imsattr_Authorization_Link>;
  /** An aggregate relationship */
  nbpm_imsattr_authorization_links_aggregate: Nbpm_Imsattr_Authorization_Link_Aggregate;
  /** An array relationship */
  nbpm_ipengine_authorization_links: Array<Nbpm_Ipengine_Authorization_Link>;
  /** An aggregate relationship */
  nbpm_ipengine_authorization_links_aggregate: Nbpm_Ipengine_Authorization_Link_Aggregate;
  /** An array relationship */
  nbpm_picanatype_authorization_links: Array<Nbpm_Picanatype_Authorization_Link>;
  /** An aggregate relationship */
  nbpm_picanatype_authorization_links_aggregate: Nbpm_Picanatype_Authorization_Link_Aggregate;
  /** An array relationship */
  nbpm_service_plan_links: Array<Nbpm_Service_Plan_Link>;
  /** An aggregate relationship */
  nbpm_service_plan_links_aggregate: Nbpm_Service_Plan_Link_Aggregate;
  updated: Scalars['timestamptz'];
  writable: Scalars['Boolean'];
};


/** columns and relationships of "nbpm_authorization" */
export type Nbpm_AuthorizationNbpm_Contract_Plan_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_authorization" */
export type Nbpm_AuthorizationNbpm_Contract_Plan_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_authorization" */
export type Nbpm_AuthorizationNbpm_Imsattr_Authorization_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_authorization" */
export type Nbpm_AuthorizationNbpm_Imsattr_Authorization_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_authorization" */
export type Nbpm_AuthorizationNbpm_Ipengine_Authorization_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_authorization" */
export type Nbpm_AuthorizationNbpm_Ipengine_Authorization_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_authorization" */
export type Nbpm_AuthorizationNbpm_Picanatype_Authorization_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_authorization" */
export type Nbpm_AuthorizationNbpm_Picanatype_Authorization_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_authorization" */
export type Nbpm_AuthorizationNbpm_Service_Plan_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_authorization" */
export type Nbpm_AuthorizationNbpm_Service_Plan_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};

/** aggregated selection of "nbpm_authorization" */
export type Nbpm_Authorization_Aggregate = {
  __typename?: 'nbpm_authorization_aggregate';
  aggregate?: Maybe<Nbpm_Authorization_Aggregate_Fields>;
  nodes: Array<Nbpm_Authorization>;
};

/** aggregate fields of "nbpm_authorization" */
export type Nbpm_Authorization_Aggregate_Fields = {
  __typename?: 'nbpm_authorization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbpm_Authorization_Max_Fields>;
  min?: Maybe<Nbpm_Authorization_Min_Fields>;
};


/** aggregate fields of "nbpm_authorization" */
export type Nbpm_Authorization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbpm_Authorization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nbpm_authorization". All fields are combined with a logical 'AND'. */
export type Nbpm_Authorization_Bool_Exp = {
  _and?: InputMaybe<Array<Nbpm_Authorization_Bool_Exp>>;
  _not?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
  _or?: InputMaybe<Array<Nbpm_Authorization_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nbpm_contract_plan_links?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
  nbpm_contract_plan_links_aggregate?: InputMaybe<Nbpm_Contract_Plan_Link_Aggregate_Bool_Exp>;
  nbpm_imsattr_authorization_links?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
  nbpm_imsattr_authorization_links_aggregate?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Aggregate_Bool_Exp>;
  nbpm_ipengine_authorization_links?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
  nbpm_ipengine_authorization_links_aggregate?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Aggregate_Bool_Exp>;
  nbpm_picanatype_authorization_links?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
  nbpm_picanatype_authorization_links_aggregate?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Aggregate_Bool_Exp>;
  nbpm_service_plan_links?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
  nbpm_service_plan_links_aggregate?: InputMaybe<Nbpm_Service_Plan_Link_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  writable?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbpm_authorization" */
export enum Nbpm_Authorization_Constraint {
  /** unique or primary key constraint on columns "key", "writable" */
  NbpmAuthorizationKeyWritableKey = 'nbpm_authorization_key_writable_key',
  /** unique or primary key constraint on columns "id" */
  NbpmAuthorizationPkey = 'nbpm_authorization_pkey'
}

/** input type for inserting data into table "nbpm_authorization" */
export type Nbpm_Authorization_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nbpm_contract_plan_links?: InputMaybe<Nbpm_Contract_Plan_Link_Arr_Rel_Insert_Input>;
  nbpm_imsattr_authorization_links?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Arr_Rel_Insert_Input>;
  nbpm_ipengine_authorization_links?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Arr_Rel_Insert_Input>;
  nbpm_picanatype_authorization_links?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Arr_Rel_Insert_Input>;
  nbpm_service_plan_links?: InputMaybe<Nbpm_Service_Plan_Link_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  writable?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Nbpm_Authorization_Max_Fields = {
  __typename?: 'nbpm_authorization_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nbpm_Authorization_Min_Fields = {
  __typename?: 'nbpm_authorization_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nbpm_authorization" */
export type Nbpm_Authorization_Mutation_Response = {
  __typename?: 'nbpm_authorization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbpm_Authorization>;
};

/** input type for inserting object relation for remote table "nbpm_authorization" */
export type Nbpm_Authorization_Obj_Rel_Insert_Input = {
  data: Nbpm_Authorization_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_Authorization_On_Conflict>;
};

/** on_conflict condition type for table "nbpm_authorization" */
export type Nbpm_Authorization_On_Conflict = {
  constraint: Nbpm_Authorization_Constraint;
  update_columns?: Array<Nbpm_Authorization_Update_Column>;
  where?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
};

/** Ordering options when selecting data from "nbpm_authorization". */
export type Nbpm_Authorization_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nbpm_contract_plan_links_aggregate?: InputMaybe<Nbpm_Contract_Plan_Link_Aggregate_Order_By>;
  nbpm_imsattr_authorization_links_aggregate?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Aggregate_Order_By>;
  nbpm_ipengine_authorization_links_aggregate?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Aggregate_Order_By>;
  nbpm_picanatype_authorization_links_aggregate?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Aggregate_Order_By>;
  nbpm_service_plan_links_aggregate?: InputMaybe<Nbpm_Service_Plan_Link_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
  writable?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbpm_authorization */
export type Nbpm_Authorization_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbpm_authorization" */
export enum Nbpm_Authorization_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated',
  /** column name */
  Writable = 'writable'
}

/** input type for updating data in table "nbpm_authorization" */
export type Nbpm_Authorization_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  writable?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "nbpm_authorization" */
export type Nbpm_Authorization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbpm_Authorization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbpm_Authorization_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  writable?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "nbpm_authorization" */
export enum Nbpm_Authorization_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated',
  /** column name */
  Writable = 'writable'
}

export type Nbpm_Authorization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbpm_Authorization_Set_Input>;
  where: Nbpm_Authorization_Bool_Exp;
};

/** columns and relationships of "nbpm_contract_plan" */
export type Nbpm_Contract_Plan = {
  __typename?: 'nbpm_contract_plan';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  limit_num_fields: Scalars['Int'];
  limit_num_users: Scalars['Int'];
  max_storage_capacity: Scalars['bigint'];
  /** An array relationship */
  nb_companies: Array<Nb_Company>;
  /** An aggregate relationship */
  nb_companies_aggregate: Nb_Company_Aggregate;
  /** An object relationship */
  nb_company?: Maybe<Nb_Company>;
  /** An array relationship */
  nbpm_contract_plan_links: Array<Nbpm_Contract_Plan_Link>;
  /** An aggregate relationship */
  nbpm_contract_plan_links_aggregate: Nbpm_Contract_Plan_Link_Aggregate;
  /** An object relationship */
  nbpm_service_plan: Nbpm_Service_Plan;
  num_fields: Scalars['Int'];
  num_users: Scalars['Int'];
  service_plan_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
  used_storage_capacity: Scalars['bigint'];
};


/** columns and relationships of "nbpm_contract_plan" */
export type Nbpm_Contract_PlanNb_CompaniesArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Order_By>>;
  where?: InputMaybe<Nb_Company_Bool_Exp>;
};


/** columns and relationships of "nbpm_contract_plan" */
export type Nbpm_Contract_PlanNb_Companies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Order_By>>;
  where?: InputMaybe<Nb_Company_Bool_Exp>;
};


/** columns and relationships of "nbpm_contract_plan" */
export type Nbpm_Contract_PlanNbpm_Contract_Plan_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_contract_plan" */
export type Nbpm_Contract_PlanNbpm_Contract_Plan_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
};

/** aggregated selection of "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Aggregate = {
  __typename?: 'nbpm_contract_plan_aggregate';
  aggregate?: Maybe<Nbpm_Contract_Plan_Aggregate_Fields>;
  nodes: Array<Nbpm_Contract_Plan>;
};

export type Nbpm_Contract_Plan_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbpm_Contract_Plan_Aggregate_Bool_Exp_Count>;
};

export type Nbpm_Contract_Plan_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbpm_Contract_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Aggregate_Fields = {
  __typename?: 'nbpm_contract_plan_aggregate_fields';
  avg?: Maybe<Nbpm_Contract_Plan_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nbpm_Contract_Plan_Max_Fields>;
  min?: Maybe<Nbpm_Contract_Plan_Min_Fields>;
  stddev?: Maybe<Nbpm_Contract_Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Nbpm_Contract_Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nbpm_Contract_Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Nbpm_Contract_Plan_Sum_Fields>;
  var_pop?: Maybe<Nbpm_Contract_Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Nbpm_Contract_Plan_Var_Samp_Fields>;
  variance?: Maybe<Nbpm_Contract_Plan_Variance_Fields>;
};


/** aggregate fields of "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbpm_Contract_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Aggregate_Order_By = {
  avg?: InputMaybe<Nbpm_Contract_Plan_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbpm_Contract_Plan_Max_Order_By>;
  min?: InputMaybe<Nbpm_Contract_Plan_Min_Order_By>;
  stddev?: InputMaybe<Nbpm_Contract_Plan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nbpm_Contract_Plan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nbpm_Contract_Plan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nbpm_Contract_Plan_Sum_Order_By>;
  var_pop?: InputMaybe<Nbpm_Contract_Plan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nbpm_Contract_Plan_Var_Samp_Order_By>;
  variance?: InputMaybe<Nbpm_Contract_Plan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Arr_Rel_Insert_Input = {
  data: Array<Nbpm_Contract_Plan_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_Contract_Plan_On_Conflict>;
};

/** aggregate avg on columns */
export type Nbpm_Contract_Plan_Avg_Fields = {
  __typename?: 'nbpm_contract_plan_avg_fields';
  limit_num_fields?: Maybe<Scalars['Float']>;
  limit_num_users?: Maybe<Scalars['Float']>;
  max_storage_capacity?: Maybe<Scalars['Float']>;
  num_fields?: Maybe<Scalars['Float']>;
  num_users?: Maybe<Scalars['Float']>;
  used_storage_capacity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Avg_Order_By = {
  limit_num_fields?: InputMaybe<Order_By>;
  limit_num_users?: InputMaybe<Order_By>;
  max_storage_capacity?: InputMaybe<Order_By>;
  num_fields?: InputMaybe<Order_By>;
  num_users?: InputMaybe<Order_By>;
  used_storage_capacity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nbpm_contract_plan". All fields are combined with a logical 'AND'. */
export type Nbpm_Contract_Plan_Bool_Exp = {
  _and?: InputMaybe<Array<Nbpm_Contract_Plan_Bool_Exp>>;
  _not?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
  _or?: InputMaybe<Array<Nbpm_Contract_Plan_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  limit_num_fields?: InputMaybe<Int_Comparison_Exp>;
  limit_num_users?: InputMaybe<Int_Comparison_Exp>;
  max_storage_capacity?: InputMaybe<Bigint_Comparison_Exp>;
  nb_companies?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_companies_aggregate?: InputMaybe<Nb_Company_Aggregate_Bool_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nbpm_contract_plan_links?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
  nbpm_contract_plan_links_aggregate?: InputMaybe<Nbpm_Contract_Plan_Link_Aggregate_Bool_Exp>;
  nbpm_service_plan?: InputMaybe<Nbpm_Service_Plan_Bool_Exp>;
  num_fields?: InputMaybe<Int_Comparison_Exp>;
  num_users?: InputMaybe<Int_Comparison_Exp>;
  service_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  used_storage_capacity?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbpm_contract_plan" */
export enum Nbpm_Contract_Plan_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbpmServicePlanPkey = 'nbpm_service_plan_pkey'
}

/** input type for incrementing numeric columns in table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Inc_Input = {
  limit_num_fields?: InputMaybe<Scalars['Int']>;
  limit_num_users?: InputMaybe<Scalars['Int']>;
  max_storage_capacity?: InputMaybe<Scalars['bigint']>;
  num_fields?: InputMaybe<Scalars['Int']>;
  num_users?: InputMaybe<Scalars['Int']>;
  used_storage_capacity?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  limit_num_fields?: InputMaybe<Scalars['Int']>;
  limit_num_users?: InputMaybe<Scalars['Int']>;
  max_storage_capacity?: InputMaybe<Scalars['bigint']>;
  nb_companies?: InputMaybe<Nb_Company_Arr_Rel_Insert_Input>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nbpm_contract_plan_links?: InputMaybe<Nbpm_Contract_Plan_Link_Arr_Rel_Insert_Input>;
  nbpm_service_plan?: InputMaybe<Nbpm_Service_Plan_Obj_Rel_Insert_Input>;
  num_fields?: InputMaybe<Scalars['Int']>;
  num_users?: InputMaybe<Scalars['Int']>;
  service_plan_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  used_storage_capacity?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link = {
  __typename?: 'nbpm_contract_plan_link';
  authorization_id?: Maybe<Scalars['uuid']>;
  contract_plan_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nbpm_authorization?: Maybe<Nbpm_Authorization>;
  /** An object relationship */
  nbpm_contract_plan: Nbpm_Contract_Plan;
  /** An array relationship */
  nbpm_user_cp_link_links: Array<Nbpm_User_Cp_Link_Link>;
  /** An aggregate relationship */
  nbpm_user_cp_link_links_aggregate: Nbpm_User_Cp_Link_Link_Aggregate;
  permission_id?: Maybe<Scalars['uuid']>;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_LinkNbpm_User_Cp_Link_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_LinkNbpm_User_Cp_Link_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
};

/** aggregated selection of "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Aggregate = {
  __typename?: 'nbpm_contract_plan_link_aggregate';
  aggregate?: Maybe<Nbpm_Contract_Plan_Link_Aggregate_Fields>;
  nodes: Array<Nbpm_Contract_Plan_Link>;
};

export type Nbpm_Contract_Plan_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbpm_Contract_Plan_Link_Aggregate_Bool_Exp_Count>;
};

export type Nbpm_Contract_Plan_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Aggregate_Fields = {
  __typename?: 'nbpm_contract_plan_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbpm_Contract_Plan_Link_Max_Fields>;
  min?: Maybe<Nbpm_Contract_Plan_Link_Min_Fields>;
};


/** aggregate fields of "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbpm_Contract_Plan_Link_Max_Order_By>;
  min?: InputMaybe<Nbpm_Contract_Plan_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Arr_Rel_Insert_Input = {
  data: Array<Nbpm_Contract_Plan_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_Contract_Plan_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbpm_contract_plan_link". All fields are combined with a logical 'AND'. */
export type Nbpm_Contract_Plan_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Bool_Exp>>;
  _not?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Bool_Exp>>;
  authorization_id?: InputMaybe<Uuid_Comparison_Exp>;
  contract_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
  nbpm_contract_plan?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
  nbpm_user_cp_link_links?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
  nbpm_user_cp_link_links_aggregate?: InputMaybe<Nbpm_User_Cp_Link_Link_Aggregate_Bool_Exp>;
  permission_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbpm_contract_plan_link" */
export enum Nbpm_Contract_Plan_Link_Constraint {
  /** unique or primary key constraint on columns "authorization_id", "contract_plan_id" */
  NbpmContractPlanLinkContractPlanIdAuthorizationIdKey = 'nbpm_contract_plan_link_contract_plan_id_authorization_id_key',
  /** unique or primary key constraint on columns "id" */
  NbpmContractePlanLinkPkey = 'nbpm_contracte_plan_link_pkey'
}

/** input type for inserting data into table "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Insert_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  contract_plan_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Obj_Rel_Insert_Input>;
  nbpm_contract_plan?: InputMaybe<Nbpm_Contract_Plan_Obj_Rel_Insert_Input>;
  nbpm_user_cp_link_links?: InputMaybe<Nbpm_User_Cp_Link_Link_Arr_Rel_Insert_Input>;
  permission_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nbpm_Contract_Plan_Link_Max_Fields = {
  __typename?: 'nbpm_contract_plan_link_max_fields';
  authorization_id?: Maybe<Scalars['uuid']>;
  contract_plan_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  permission_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Max_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  contract_plan_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbpm_Contract_Plan_Link_Min_Fields = {
  __typename?: 'nbpm_contract_plan_link_min_fields';
  authorization_id?: Maybe<Scalars['uuid']>;
  contract_plan_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  permission_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Min_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  contract_plan_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Mutation_Response = {
  __typename?: 'nbpm_contract_plan_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbpm_Contract_Plan_Link>;
};

/** input type for inserting object relation for remote table "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Obj_Rel_Insert_Input = {
  data: Nbpm_Contract_Plan_Link_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_Contract_Plan_Link_On_Conflict>;
};

/** on_conflict condition type for table "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_On_Conflict = {
  constraint: Nbpm_Contract_Plan_Link_Constraint;
  update_columns?: Array<Nbpm_Contract_Plan_Link_Update_Column>;
  where?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nbpm_contract_plan_link". */
export type Nbpm_Contract_Plan_Link_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  contract_plan_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Order_By>;
  nbpm_contract_plan?: InputMaybe<Nbpm_Contract_Plan_Order_By>;
  nbpm_user_cp_link_links_aggregate?: InputMaybe<Nbpm_User_Cp_Link_Link_Aggregate_Order_By>;
  permission_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbpm_contract_plan_link */
export type Nbpm_Contract_Plan_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbpm_contract_plan_link" */
export enum Nbpm_Contract_Plan_Link_Select_Column {
  /** column name */
  AuthorizationId = 'authorization_id',
  /** column name */
  ContractPlanId = 'contract_plan_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Set_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  contract_plan_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nbpm_contract_plan_link" */
export type Nbpm_Contract_Plan_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbpm_Contract_Plan_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbpm_Contract_Plan_Link_Stream_Cursor_Value_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  contract_plan_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nbpm_contract_plan_link" */
export enum Nbpm_Contract_Plan_Link_Update_Column {
  /** column name */
  AuthorizationId = 'authorization_id',
  /** column name */
  ContractPlanId = 'contract_plan_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  Updated = 'updated'
}

export type Nbpm_Contract_Plan_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbpm_Contract_Plan_Link_Set_Input>;
  where: Nbpm_Contract_Plan_Link_Bool_Exp;
};

/** aggregate max on columns */
export type Nbpm_Contract_Plan_Max_Fields = {
  __typename?: 'nbpm_contract_plan_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  limit_num_fields?: Maybe<Scalars['Int']>;
  limit_num_users?: Maybe<Scalars['Int']>;
  max_storage_capacity?: Maybe<Scalars['bigint']>;
  num_fields?: Maybe<Scalars['Int']>;
  num_users?: Maybe<Scalars['Int']>;
  service_plan_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  used_storage_capacity?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_num_fields?: InputMaybe<Order_By>;
  limit_num_users?: InputMaybe<Order_By>;
  max_storage_capacity?: InputMaybe<Order_By>;
  num_fields?: InputMaybe<Order_By>;
  num_users?: InputMaybe<Order_By>;
  service_plan_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  used_storage_capacity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbpm_Contract_Plan_Min_Fields = {
  __typename?: 'nbpm_contract_plan_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  limit_num_fields?: Maybe<Scalars['Int']>;
  limit_num_users?: Maybe<Scalars['Int']>;
  max_storage_capacity?: Maybe<Scalars['bigint']>;
  num_fields?: Maybe<Scalars['Int']>;
  num_users?: Maybe<Scalars['Int']>;
  service_plan_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  used_storage_capacity?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_num_fields?: InputMaybe<Order_By>;
  limit_num_users?: InputMaybe<Order_By>;
  max_storage_capacity?: InputMaybe<Order_By>;
  num_fields?: InputMaybe<Order_By>;
  num_users?: InputMaybe<Order_By>;
  service_plan_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  used_storage_capacity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Mutation_Response = {
  __typename?: 'nbpm_contract_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbpm_Contract_Plan>;
};

/** input type for inserting object relation for remote table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Obj_Rel_Insert_Input = {
  data: Nbpm_Contract_Plan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_Contract_Plan_On_Conflict>;
};

/** on_conflict condition type for table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_On_Conflict = {
  constraint: Nbpm_Contract_Plan_Constraint;
  update_columns?: Array<Nbpm_Contract_Plan_Update_Column>;
  where?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "nbpm_contract_plan". */
export type Nbpm_Contract_Plan_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_num_fields?: InputMaybe<Order_By>;
  limit_num_users?: InputMaybe<Order_By>;
  max_storage_capacity?: InputMaybe<Order_By>;
  nb_companies_aggregate?: InputMaybe<Nb_Company_Aggregate_Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nbpm_contract_plan_links_aggregate?: InputMaybe<Nbpm_Contract_Plan_Link_Aggregate_Order_By>;
  nbpm_service_plan?: InputMaybe<Nbpm_Service_Plan_Order_By>;
  num_fields?: InputMaybe<Order_By>;
  num_users?: InputMaybe<Order_By>;
  service_plan_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  used_storage_capacity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbpm_contract_plan */
export type Nbpm_Contract_Plan_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbpm_contract_plan" */
export enum Nbpm_Contract_Plan_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  LimitNumFields = 'limit_num_fields',
  /** column name */
  LimitNumUsers = 'limit_num_users',
  /** column name */
  MaxStorageCapacity = 'max_storage_capacity',
  /** column name */
  NumFields = 'num_fields',
  /** column name */
  NumUsers = 'num_users',
  /** column name */
  ServicePlanId = 'service_plan_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UsedStorageCapacity = 'used_storage_capacity'
}

/** input type for updating data in table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  limit_num_fields?: InputMaybe<Scalars['Int']>;
  limit_num_users?: InputMaybe<Scalars['Int']>;
  max_storage_capacity?: InputMaybe<Scalars['bigint']>;
  num_fields?: InputMaybe<Scalars['Int']>;
  num_users?: InputMaybe<Scalars['Int']>;
  service_plan_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  used_storage_capacity?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Nbpm_Contract_Plan_Stddev_Fields = {
  __typename?: 'nbpm_contract_plan_stddev_fields';
  limit_num_fields?: Maybe<Scalars['Float']>;
  limit_num_users?: Maybe<Scalars['Float']>;
  max_storage_capacity?: Maybe<Scalars['Float']>;
  num_fields?: Maybe<Scalars['Float']>;
  num_users?: Maybe<Scalars['Float']>;
  used_storage_capacity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Stddev_Order_By = {
  limit_num_fields?: InputMaybe<Order_By>;
  limit_num_users?: InputMaybe<Order_By>;
  max_storage_capacity?: InputMaybe<Order_By>;
  num_fields?: InputMaybe<Order_By>;
  num_users?: InputMaybe<Order_By>;
  used_storage_capacity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nbpm_Contract_Plan_Stddev_Pop_Fields = {
  __typename?: 'nbpm_contract_plan_stddev_pop_fields';
  limit_num_fields?: Maybe<Scalars['Float']>;
  limit_num_users?: Maybe<Scalars['Float']>;
  max_storage_capacity?: Maybe<Scalars['Float']>;
  num_fields?: Maybe<Scalars['Float']>;
  num_users?: Maybe<Scalars['Float']>;
  used_storage_capacity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Stddev_Pop_Order_By = {
  limit_num_fields?: InputMaybe<Order_By>;
  limit_num_users?: InputMaybe<Order_By>;
  max_storage_capacity?: InputMaybe<Order_By>;
  num_fields?: InputMaybe<Order_By>;
  num_users?: InputMaybe<Order_By>;
  used_storage_capacity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nbpm_Contract_Plan_Stddev_Samp_Fields = {
  __typename?: 'nbpm_contract_plan_stddev_samp_fields';
  limit_num_fields?: Maybe<Scalars['Float']>;
  limit_num_users?: Maybe<Scalars['Float']>;
  max_storage_capacity?: Maybe<Scalars['Float']>;
  num_fields?: Maybe<Scalars['Float']>;
  num_users?: Maybe<Scalars['Float']>;
  used_storage_capacity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Stddev_Samp_Order_By = {
  limit_num_fields?: InputMaybe<Order_By>;
  limit_num_users?: InputMaybe<Order_By>;
  max_storage_capacity?: InputMaybe<Order_By>;
  num_fields?: InputMaybe<Order_By>;
  num_users?: InputMaybe<Order_By>;
  used_storage_capacity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbpm_Contract_Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbpm_Contract_Plan_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  limit_num_fields?: InputMaybe<Scalars['Int']>;
  limit_num_users?: InputMaybe<Scalars['Int']>;
  max_storage_capacity?: InputMaybe<Scalars['bigint']>;
  num_fields?: InputMaybe<Scalars['Int']>;
  num_users?: InputMaybe<Scalars['Int']>;
  service_plan_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  used_storage_capacity?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Nbpm_Contract_Plan_Sum_Fields = {
  __typename?: 'nbpm_contract_plan_sum_fields';
  limit_num_fields?: Maybe<Scalars['Int']>;
  limit_num_users?: Maybe<Scalars['Int']>;
  max_storage_capacity?: Maybe<Scalars['bigint']>;
  num_fields?: Maybe<Scalars['Int']>;
  num_users?: Maybe<Scalars['Int']>;
  used_storage_capacity?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Sum_Order_By = {
  limit_num_fields?: InputMaybe<Order_By>;
  limit_num_users?: InputMaybe<Order_By>;
  max_storage_capacity?: InputMaybe<Order_By>;
  num_fields?: InputMaybe<Order_By>;
  num_users?: InputMaybe<Order_By>;
  used_storage_capacity?: InputMaybe<Order_By>;
};

/** update columns of table "nbpm_contract_plan" */
export enum Nbpm_Contract_Plan_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  LimitNumFields = 'limit_num_fields',
  /** column name */
  LimitNumUsers = 'limit_num_users',
  /** column name */
  MaxStorageCapacity = 'max_storage_capacity',
  /** column name */
  NumFields = 'num_fields',
  /** column name */
  NumUsers = 'num_users',
  /** column name */
  ServicePlanId = 'service_plan_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UsedStorageCapacity = 'used_storage_capacity'
}

export type Nbpm_Contract_Plan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nbpm_Contract_Plan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbpm_Contract_Plan_Set_Input>;
  where: Nbpm_Contract_Plan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nbpm_Contract_Plan_Var_Pop_Fields = {
  __typename?: 'nbpm_contract_plan_var_pop_fields';
  limit_num_fields?: Maybe<Scalars['Float']>;
  limit_num_users?: Maybe<Scalars['Float']>;
  max_storage_capacity?: Maybe<Scalars['Float']>;
  num_fields?: Maybe<Scalars['Float']>;
  num_users?: Maybe<Scalars['Float']>;
  used_storage_capacity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Var_Pop_Order_By = {
  limit_num_fields?: InputMaybe<Order_By>;
  limit_num_users?: InputMaybe<Order_By>;
  max_storage_capacity?: InputMaybe<Order_By>;
  num_fields?: InputMaybe<Order_By>;
  num_users?: InputMaybe<Order_By>;
  used_storage_capacity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nbpm_Contract_Plan_Var_Samp_Fields = {
  __typename?: 'nbpm_contract_plan_var_samp_fields';
  limit_num_fields?: Maybe<Scalars['Float']>;
  limit_num_users?: Maybe<Scalars['Float']>;
  max_storage_capacity?: Maybe<Scalars['Float']>;
  num_fields?: Maybe<Scalars['Float']>;
  num_users?: Maybe<Scalars['Float']>;
  used_storage_capacity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Var_Samp_Order_By = {
  limit_num_fields?: InputMaybe<Order_By>;
  limit_num_users?: InputMaybe<Order_By>;
  max_storage_capacity?: InputMaybe<Order_By>;
  num_fields?: InputMaybe<Order_By>;
  num_users?: InputMaybe<Order_By>;
  used_storage_capacity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nbpm_Contract_Plan_Variance_Fields = {
  __typename?: 'nbpm_contract_plan_variance_fields';
  limit_num_fields?: Maybe<Scalars['Float']>;
  limit_num_users?: Maybe<Scalars['Float']>;
  max_storage_capacity?: Maybe<Scalars['Float']>;
  num_fields?: Maybe<Scalars['Float']>;
  num_users?: Maybe<Scalars['Float']>;
  used_storage_capacity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nbpm_contract_plan" */
export type Nbpm_Contract_Plan_Variance_Order_By = {
  limit_num_fields?: InputMaybe<Order_By>;
  limit_num_users?: InputMaybe<Order_By>;
  max_storage_capacity?: InputMaybe<Order_By>;
  num_fields?: InputMaybe<Order_By>;
  num_users?: InputMaybe<Order_By>;
  used_storage_capacity?: InputMaybe<Order_By>;
};

/** columns and relationships of "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link = {
  __typename?: 'nbpm_imsattr_authorization_link';
  authorization_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  ims_attr_id: Scalars['uuid'];
  /** An object relationship */
  nb_image_sensing_attr: Nb_Image_Sensing_Attr;
  /** An object relationship */
  nbpm_authorization: Nbpm_Authorization;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_Aggregate = {
  __typename?: 'nbpm_imsattr_authorization_link_aggregate';
  aggregate?: Maybe<Nbpm_Imsattr_Authorization_Link_Aggregate_Fields>;
  nodes: Array<Nbpm_Imsattr_Authorization_Link>;
};

export type Nbpm_Imsattr_Authorization_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Aggregate_Bool_Exp_Count>;
};

export type Nbpm_Imsattr_Authorization_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_Aggregate_Fields = {
  __typename?: 'nbpm_imsattr_authorization_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbpm_Imsattr_Authorization_Link_Max_Fields>;
  min?: Maybe<Nbpm_Imsattr_Authorization_Link_Min_Fields>;
};


/** aggregate fields of "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Max_Order_By>;
  min?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_Arr_Rel_Insert_Input = {
  data: Array<Nbpm_Imsattr_Authorization_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_Imsattr_Authorization_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbpm_imsattr_authorization_link". All fields are combined with a logical 'AND'. */
export type Nbpm_Imsattr_Authorization_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Bool_Exp>>;
  _not?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Bool_Exp>>;
  authorization_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ims_attr_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_image_sensing_attr?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbpm_imsattr_authorization_link" */
export enum Nbpm_Imsattr_Authorization_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbpmImsattrAuthorizationLinkPkey = 'nbpm_imsattr_authorization_link_pkey'
}

/** input type for inserting data into table "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_Insert_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ims_attr_id?: InputMaybe<Scalars['uuid']>;
  nb_image_sensing_attr?: InputMaybe<Nb_Image_Sensing_Attr_Obj_Rel_Insert_Input>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nbpm_Imsattr_Authorization_Link_Max_Fields = {
  __typename?: 'nbpm_imsattr_authorization_link_max_fields';
  authorization_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ims_attr_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_Max_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ims_attr_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbpm_Imsattr_Authorization_Link_Min_Fields = {
  __typename?: 'nbpm_imsattr_authorization_link_min_fields';
  authorization_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ims_attr_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_Min_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ims_attr_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_Mutation_Response = {
  __typename?: 'nbpm_imsattr_authorization_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbpm_Imsattr_Authorization_Link>;
};

/** on_conflict condition type for table "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_On_Conflict = {
  constraint: Nbpm_Imsattr_Authorization_Link_Constraint;
  update_columns?: Array<Nbpm_Imsattr_Authorization_Link_Update_Column>;
  where?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nbpm_imsattr_authorization_link". */
export type Nbpm_Imsattr_Authorization_Link_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ims_attr_id?: InputMaybe<Order_By>;
  nb_image_sensing_attr?: InputMaybe<Nb_Image_Sensing_Attr_Order_By>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbpm_imsattr_authorization_link */
export type Nbpm_Imsattr_Authorization_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbpm_imsattr_authorization_link" */
export enum Nbpm_Imsattr_Authorization_Link_Select_Column {
  /** column name */
  AuthorizationId = 'authorization_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  ImsAttrId = 'ims_attr_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_Set_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ims_attr_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nbpm_imsattr_authorization_link" */
export type Nbpm_Imsattr_Authorization_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbpm_Imsattr_Authorization_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbpm_Imsattr_Authorization_Link_Stream_Cursor_Value_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ims_attr_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nbpm_imsattr_authorization_link" */
export enum Nbpm_Imsattr_Authorization_Link_Update_Column {
  /** column name */
  AuthorizationId = 'authorization_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  ImsAttrId = 'ims_attr_id',
  /** column name */
  Updated = 'updated'
}

export type Nbpm_Imsattr_Authorization_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Set_Input>;
  where: Nbpm_Imsattr_Authorization_Link_Bool_Exp;
};

/** 画像処理エンジンと認可をつなぐテーブル */
export type Nbpm_Ipengine_Authorization_Link = {
  __typename?: 'nbpm_ipengine_authorization_link';
  authorization_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  engine_information_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nbpm_authorization: Nbpm_Authorization;
  /** An object relationship */
  nipe_engine_information: Nipe_Engine_Information;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_Aggregate = {
  __typename?: 'nbpm_ipengine_authorization_link_aggregate';
  aggregate?: Maybe<Nbpm_Ipengine_Authorization_Link_Aggregate_Fields>;
  nodes: Array<Nbpm_Ipengine_Authorization_Link>;
};

export type Nbpm_Ipengine_Authorization_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Aggregate_Bool_Exp_Count>;
};

export type Nbpm_Ipengine_Authorization_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_Aggregate_Fields = {
  __typename?: 'nbpm_ipengine_authorization_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbpm_Ipengine_Authorization_Link_Max_Fields>;
  min?: Maybe<Nbpm_Ipengine_Authorization_Link_Min_Fields>;
};


/** aggregate fields of "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Max_Order_By>;
  min?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_Arr_Rel_Insert_Input = {
  data: Array<Nbpm_Ipengine_Authorization_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_Ipengine_Authorization_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbpm_ipengine_authorization_link". All fields are combined with a logical 'AND'. */
export type Nbpm_Ipengine_Authorization_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Bool_Exp>>;
  _not?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Bool_Exp>>;
  authorization_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  engine_information_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
  nipe_engine_information?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbpm_ipengine_authorization_link" */
export enum Nbpm_Ipengine_Authorization_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbpmIpengineAuthorizationLinkPkey = 'nbpm_ipengine_authorization_link_pkey'
}

/** input type for inserting data into table "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_Insert_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  engine_information_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Obj_Rel_Insert_Input>;
  nipe_engine_information?: InputMaybe<Nipe_Engine_Information_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nbpm_Ipengine_Authorization_Link_Max_Fields = {
  __typename?: 'nbpm_ipengine_authorization_link_max_fields';
  authorization_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  engine_information_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_Max_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  engine_information_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbpm_Ipengine_Authorization_Link_Min_Fields = {
  __typename?: 'nbpm_ipengine_authorization_link_min_fields';
  authorization_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  engine_information_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_Min_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  engine_information_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_Mutation_Response = {
  __typename?: 'nbpm_ipengine_authorization_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbpm_Ipengine_Authorization_Link>;
};

/** on_conflict condition type for table "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_On_Conflict = {
  constraint: Nbpm_Ipengine_Authorization_Link_Constraint;
  update_columns?: Array<Nbpm_Ipengine_Authorization_Link_Update_Column>;
  where?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nbpm_ipengine_authorization_link". */
export type Nbpm_Ipengine_Authorization_Link_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  engine_information_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Order_By>;
  nipe_engine_information?: InputMaybe<Nipe_Engine_Information_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbpm_ipengine_authorization_link */
export type Nbpm_Ipengine_Authorization_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbpm_ipengine_authorization_link" */
export enum Nbpm_Ipengine_Authorization_Link_Select_Column {
  /** column name */
  AuthorizationId = 'authorization_id',
  /** column name */
  Created = 'created',
  /** column name */
  EngineInformationId = 'engine_information_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_Set_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  engine_information_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nbpm_ipengine_authorization_link" */
export type Nbpm_Ipengine_Authorization_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbpm_Ipengine_Authorization_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbpm_Ipengine_Authorization_Link_Stream_Cursor_Value_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  engine_information_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nbpm_ipengine_authorization_link" */
export enum Nbpm_Ipengine_Authorization_Link_Update_Column {
  /** column name */
  AuthorizationId = 'authorization_id',
  /** column name */
  Created = 'created',
  /** column name */
  EngineInformationId = 'engine_information_id',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

export type Nbpm_Ipengine_Authorization_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Set_Input>;
  where: Nbpm_Ipengine_Authorization_Link_Bool_Exp;
};

/** columns and relationships of "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link = {
  __typename?: 'nbpm_picanatype_authorization_link';
  authorization_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_picture_analysis_type: Nb_Picture_Analysis_Type;
  /** An object relationship */
  nbpm_authorization: Nbpm_Authorization;
  pic_ana_type_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_Aggregate = {
  __typename?: 'nbpm_picanatype_authorization_link_aggregate';
  aggregate?: Maybe<Nbpm_Picanatype_Authorization_Link_Aggregate_Fields>;
  nodes: Array<Nbpm_Picanatype_Authorization_Link>;
};

export type Nbpm_Picanatype_Authorization_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Aggregate_Bool_Exp_Count>;
};

export type Nbpm_Picanatype_Authorization_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_Aggregate_Fields = {
  __typename?: 'nbpm_picanatype_authorization_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbpm_Picanatype_Authorization_Link_Max_Fields>;
  min?: Maybe<Nbpm_Picanatype_Authorization_Link_Min_Fields>;
};


/** aggregate fields of "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Max_Order_By>;
  min?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_Arr_Rel_Insert_Input = {
  data: Array<Nbpm_Picanatype_Authorization_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_Picanatype_Authorization_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbpm_picanatype_authorization_link". All fields are combined with a logical 'AND'. */
export type Nbpm_Picanatype_Authorization_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Bool_Exp>>;
  _not?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Bool_Exp>>;
  authorization_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_picture_analysis_type?: InputMaybe<Nb_Picture_Analysis_Type_Bool_Exp>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
  pic_ana_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbpm_picanatype_authorization_link" */
export enum Nbpm_Picanatype_Authorization_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbpmPicanatypeAuthorizationLinkPkey = 'nbpm_picanatype_authorization_link_pkey'
}

/** input type for inserting data into table "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_Insert_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_picture_analysis_type?: InputMaybe<Nb_Picture_Analysis_Type_Obj_Rel_Insert_Input>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Obj_Rel_Insert_Input>;
  pic_ana_type_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nbpm_Picanatype_Authorization_Link_Max_Fields = {
  __typename?: 'nbpm_picanatype_authorization_link_max_fields';
  authorization_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pic_ana_type_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_Max_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pic_ana_type_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbpm_Picanatype_Authorization_Link_Min_Fields = {
  __typename?: 'nbpm_picanatype_authorization_link_min_fields';
  authorization_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pic_ana_type_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_Min_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pic_ana_type_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_Mutation_Response = {
  __typename?: 'nbpm_picanatype_authorization_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbpm_Picanatype_Authorization_Link>;
};

/** on_conflict condition type for table "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_On_Conflict = {
  constraint: Nbpm_Picanatype_Authorization_Link_Constraint;
  update_columns?: Array<Nbpm_Picanatype_Authorization_Link_Update_Column>;
  where?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nbpm_picanatype_authorization_link". */
export type Nbpm_Picanatype_Authorization_Link_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_picture_analysis_type?: InputMaybe<Nb_Picture_Analysis_Type_Order_By>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Order_By>;
  pic_ana_type_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbpm_picanatype_authorization_link */
export type Nbpm_Picanatype_Authorization_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbpm_picanatype_authorization_link" */
export enum Nbpm_Picanatype_Authorization_Link_Select_Column {
  /** column name */
  AuthorizationId = 'authorization_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PicAnaTypeId = 'pic_ana_type_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_Set_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  pic_ana_type_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nbpm_picanatype_authorization_link" */
export type Nbpm_Picanatype_Authorization_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbpm_Picanatype_Authorization_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbpm_Picanatype_Authorization_Link_Stream_Cursor_Value_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  pic_ana_type_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nbpm_picanatype_authorization_link" */
export enum Nbpm_Picanatype_Authorization_Link_Update_Column {
  /** column name */
  AuthorizationId = 'authorization_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PicAnaTypeId = 'pic_ana_type_id',
  /** column name */
  Updated = 'updated'
}

export type Nbpm_Picanatype_Authorization_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Set_Input>;
  where: Nbpm_Picanatype_Authorization_Link_Bool_Exp;
};

/** columns and relationships of "nbpm_service_plan" */
export type Nbpm_Service_Plan = {
  __typename?: 'nbpm_service_plan';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  nbpm_contract_plans: Array<Nbpm_Contract_Plan>;
  /** An aggregate relationship */
  nbpm_contract_plans_aggregate: Nbpm_Contract_Plan_Aggregate;
  /** An array relationship */
  nbpm_service_plan_links: Array<Nbpm_Service_Plan_Link>;
  /** An aggregate relationship */
  nbpm_service_plan_links_aggregate: Nbpm_Service_Plan_Link_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nbpm_service_plan" */
export type Nbpm_Service_PlanNbpm_Contract_PlansArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
};


/** columns and relationships of "nbpm_service_plan" */
export type Nbpm_Service_PlanNbpm_Contract_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
};


/** columns and relationships of "nbpm_service_plan" */
export type Nbpm_Service_PlanNbpm_Service_Plan_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_service_plan" */
export type Nbpm_Service_PlanNbpm_Service_Plan_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};

/** aggregated selection of "nbpm_service_plan" */
export type Nbpm_Service_Plan_Aggregate = {
  __typename?: 'nbpm_service_plan_aggregate';
  aggregate?: Maybe<Nbpm_Service_Plan_Aggregate_Fields>;
  nodes: Array<Nbpm_Service_Plan>;
};

/** aggregate fields of "nbpm_service_plan" */
export type Nbpm_Service_Plan_Aggregate_Fields = {
  __typename?: 'nbpm_service_plan_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbpm_Service_Plan_Max_Fields>;
  min?: Maybe<Nbpm_Service_Plan_Min_Fields>;
};


/** aggregate fields of "nbpm_service_plan" */
export type Nbpm_Service_Plan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbpm_Service_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nbpm_service_plan". All fields are combined with a logical 'AND'. */
export type Nbpm_Service_Plan_Bool_Exp = {
  _and?: InputMaybe<Array<Nbpm_Service_Plan_Bool_Exp>>;
  _not?: InputMaybe<Nbpm_Service_Plan_Bool_Exp>;
  _or?: InputMaybe<Array<Nbpm_Service_Plan_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nbpm_contract_plans?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
  nbpm_contract_plans_aggregate?: InputMaybe<Nbpm_Contract_Plan_Aggregate_Bool_Exp>;
  nbpm_service_plan_links?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
  nbpm_service_plan_links_aggregate?: InputMaybe<Nbpm_Service_Plan_Link_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbpm_service_plan" */
export enum Nbpm_Service_Plan_Constraint {
  /** unique or primary key constraint on columns "key" */
  NbpmServicePlanKeyKey = 'nbpm_service_plan_key_key',
  /** unique or primary key constraint on columns "id" */
  NbpmServicePlanPkey1 = 'nbpm_service_plan_pkey1'
}

/** input type for inserting data into table "nbpm_service_plan" */
export type Nbpm_Service_Plan_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nbpm_contract_plans?: InputMaybe<Nbpm_Contract_Plan_Arr_Rel_Insert_Input>;
  nbpm_service_plan_links?: InputMaybe<Nbpm_Service_Plan_Link_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link = {
  __typename?: 'nbpm_service_plan_link';
  authorization_id?: Maybe<Scalars['uuid']>;
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nbpm_authorization?: Maybe<Nbpm_Authorization>;
  /** An object relationship */
  nbpm_service_plan: Nbpm_Service_Plan;
  /** An object relationship */
  nbpm_user_attribute?: Maybe<Nbpm_User_Attribute>;
  permission_id?: Maybe<Scalars['uuid']>;
  service_plan_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
  user_attribute_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_Aggregate = {
  __typename?: 'nbpm_service_plan_link_aggregate';
  aggregate?: Maybe<Nbpm_Service_Plan_Link_Aggregate_Fields>;
  nodes: Array<Nbpm_Service_Plan_Link>;
};

export type Nbpm_Service_Plan_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbpm_Service_Plan_Link_Aggregate_Bool_Exp_Count>;
};

export type Nbpm_Service_Plan_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_Aggregate_Fields = {
  __typename?: 'nbpm_service_plan_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbpm_Service_Plan_Link_Max_Fields>;
  min?: Maybe<Nbpm_Service_Plan_Link_Min_Fields>;
};


/** aggregate fields of "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbpm_Service_Plan_Link_Max_Order_By>;
  min?: InputMaybe<Nbpm_Service_Plan_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_Arr_Rel_Insert_Input = {
  data: Array<Nbpm_Service_Plan_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_Service_Plan_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbpm_service_plan_link". All fields are combined with a logical 'AND'. */
export type Nbpm_Service_Plan_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nbpm_Service_Plan_Link_Bool_Exp>>;
  _not?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nbpm_Service_Plan_Link_Bool_Exp>>;
  authorization_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
  nbpm_service_plan?: InputMaybe<Nbpm_Service_Plan_Bool_Exp>;
  nbpm_user_attribute?: InputMaybe<Nbpm_User_Attribute_Bool_Exp>;
  permission_id?: InputMaybe<Uuid_Comparison_Exp>;
  service_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_attribute_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbpm_service_plan_link" */
export enum Nbpm_Service_Plan_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbpmServicePlanLinkPkey = 'nbpm_service_plan_link_pkey',
  /** unique or primary key constraint on columns "authorization_id", "service_plan_id", "user_attribute_id" */
  NbpmServicePlanLinkServicePlanIdAuthorizationIdUserAt = 'nbpm_service_plan_link_service_plan_id_authorization_id_user_at'
}

/** input type for inserting data into table "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_Insert_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Obj_Rel_Insert_Input>;
  nbpm_service_plan?: InputMaybe<Nbpm_Service_Plan_Obj_Rel_Insert_Input>;
  nbpm_user_attribute?: InputMaybe<Nbpm_User_Attribute_Obj_Rel_Insert_Input>;
  permission_id?: InputMaybe<Scalars['uuid']>;
  service_plan_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_attribute_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nbpm_Service_Plan_Link_Max_Fields = {
  __typename?: 'nbpm_service_plan_link_max_fields';
  authorization_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  permission_id?: Maybe<Scalars['uuid']>;
  service_plan_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_attribute_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_Max_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  service_plan_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_attribute_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbpm_Service_Plan_Link_Min_Fields = {
  __typename?: 'nbpm_service_plan_link_min_fields';
  authorization_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  permission_id?: Maybe<Scalars['uuid']>;
  service_plan_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_attribute_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_Min_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  service_plan_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_attribute_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_Mutation_Response = {
  __typename?: 'nbpm_service_plan_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbpm_Service_Plan_Link>;
};

/** on_conflict condition type for table "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_On_Conflict = {
  constraint: Nbpm_Service_Plan_Link_Constraint;
  update_columns?: Array<Nbpm_Service_Plan_Link_Update_Column>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nbpm_service_plan_link". */
export type Nbpm_Service_Plan_Link_Order_By = {
  authorization_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nbpm_authorization?: InputMaybe<Nbpm_Authorization_Order_By>;
  nbpm_service_plan?: InputMaybe<Nbpm_Service_Plan_Order_By>;
  nbpm_user_attribute?: InputMaybe<Nbpm_User_Attribute_Order_By>;
  permission_id?: InputMaybe<Order_By>;
  service_plan_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_attribute_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbpm_service_plan_link */
export type Nbpm_Service_Plan_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbpm_service_plan_link" */
export enum Nbpm_Service_Plan_Link_Select_Column {
  /** column name */
  AuthorizationId = 'authorization_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  ServicePlanId = 'service_plan_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserAttributeId = 'user_attribute_id'
}

/** input type for updating data in table "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_Set_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission_id?: InputMaybe<Scalars['uuid']>;
  service_plan_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_attribute_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "nbpm_service_plan_link" */
export type Nbpm_Service_Plan_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbpm_Service_Plan_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbpm_Service_Plan_Link_Stream_Cursor_Value_Input = {
  authorization_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission_id?: InputMaybe<Scalars['uuid']>;
  service_plan_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_attribute_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nbpm_service_plan_link" */
export enum Nbpm_Service_Plan_Link_Update_Column {
  /** column name */
  AuthorizationId = 'authorization_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  ServicePlanId = 'service_plan_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserAttributeId = 'user_attribute_id'
}

export type Nbpm_Service_Plan_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbpm_Service_Plan_Link_Set_Input>;
  where: Nbpm_Service_Plan_Link_Bool_Exp;
};

/** aggregate max on columns */
export type Nbpm_Service_Plan_Max_Fields = {
  __typename?: 'nbpm_service_plan_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nbpm_Service_Plan_Min_Fields = {
  __typename?: 'nbpm_service_plan_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nbpm_service_plan" */
export type Nbpm_Service_Plan_Mutation_Response = {
  __typename?: 'nbpm_service_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbpm_Service_Plan>;
};

/** input type for inserting object relation for remote table "nbpm_service_plan" */
export type Nbpm_Service_Plan_Obj_Rel_Insert_Input = {
  data: Nbpm_Service_Plan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_Service_Plan_On_Conflict>;
};

/** on_conflict condition type for table "nbpm_service_plan" */
export type Nbpm_Service_Plan_On_Conflict = {
  constraint: Nbpm_Service_Plan_Constraint;
  update_columns?: Array<Nbpm_Service_Plan_Update_Column>;
  where?: InputMaybe<Nbpm_Service_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "nbpm_service_plan". */
export type Nbpm_Service_Plan_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nbpm_contract_plans_aggregate?: InputMaybe<Nbpm_Contract_Plan_Aggregate_Order_By>;
  nbpm_service_plan_links_aggregate?: InputMaybe<Nbpm_Service_Plan_Link_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbpm_service_plan */
export type Nbpm_Service_Plan_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbpm_service_plan" */
export enum Nbpm_Service_Plan_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nbpm_service_plan" */
export type Nbpm_Service_Plan_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nbpm_service_plan" */
export type Nbpm_Service_Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbpm_Service_Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbpm_Service_Plan_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nbpm_service_plan" */
export enum Nbpm_Service_Plan_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

export type Nbpm_Service_Plan_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbpm_Service_Plan_Set_Input>;
  where: Nbpm_Service_Plan_Bool_Exp;
};

/** columns and relationships of "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link = {
  __typename?: 'nbpm_user_attr_link';
  /** An object relationship */
  app_user_active: App_User_Active;
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An array relationship */
  nb_ufg_member_links: Array<Nb_Ufg_Member_Link>;
  /** An aggregate relationship */
  nb_ufg_member_links_aggregate: Nb_Ufg_Member_Link_Aggregate;
  /** An object relationship */
  nbpm_user_attribute: Nbpm_User_Attribute;
  updated: Scalars['timestamptz'];
  user_attribute_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  valid_end: Scalars['timestamptz'];
  valid_start: Scalars['timestamptz'];
};


/** columns and relationships of "nbpm_user_attr_link" */
export type Nbpm_User_Attr_LinkNb_Ufg_Member_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Member_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Member_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_user_attr_link" */
export type Nbpm_User_Attr_LinkNb_Ufg_Member_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Member_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Member_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
};

/** aggregated selection of "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Aggregate = {
  __typename?: 'nbpm_user_attr_link_aggregate';
  aggregate?: Maybe<Nbpm_User_Attr_Link_Aggregate_Fields>;
  nodes: Array<Nbpm_User_Attr_Link>;
};

export type Nbpm_User_Attr_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbpm_User_Attr_Link_Aggregate_Bool_Exp_Count>;
};

export type Nbpm_User_Attr_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Aggregate_Fields = {
  __typename?: 'nbpm_user_attr_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbpm_User_Attr_Link_Max_Fields>;
  min?: Maybe<Nbpm_User_Attr_Link_Min_Fields>;
};


/** aggregate fields of "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbpm_User_Attr_Link_Max_Order_By>;
  min?: InputMaybe<Nbpm_User_Attr_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Arr_Rel_Insert_Input = {
  data: Array<Nbpm_User_Attr_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_User_Attr_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbpm_user_attr_link". All fields are combined with a logical 'AND'. */
export type Nbpm_User_Attr_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nbpm_User_Attr_Link_Bool_Exp>>;
  _not?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nbpm_User_Attr_Link_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_ufg_member_links?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
  nb_ufg_member_links_aggregate?: InputMaybe<Nb_Ufg_Member_Link_Aggregate_Bool_Exp>;
  nbpm_user_attribute?: InputMaybe<Nbpm_User_Attribute_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_attribute_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbpm_user_attr_link" */
export enum Nbpm_User_Attr_Link_Constraint {
  /** unique or primary key constraint on columns "user_id", "company_id" */
  NbpmUserAttrLinkCompanyIdUserIdKey = 'nbpm_user_attr_link_company_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  NbpmUserAttrLinkPkey = 'nbpm_user_attr_link_pkey'
}

/** input type for inserting data into table "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_ufg_member_links?: InputMaybe<Nb_Ufg_Member_Link_Arr_Rel_Insert_Input>;
  nbpm_user_attribute?: InputMaybe<Nbpm_User_Attribute_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_attribute_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nbpm_User_Attr_Link_Max_Fields = {
  __typename?: 'nbpm_user_attr_link_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_attribute_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_attribute_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbpm_User_Attr_Link_Min_Fields = {
  __typename?: 'nbpm_user_attr_link_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_attribute_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_end?: Maybe<Scalars['timestamptz']>;
  valid_start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_attribute_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Mutation_Response = {
  __typename?: 'nbpm_user_attr_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbpm_User_Attr_Link>;
};

/** input type for inserting object relation for remote table "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Obj_Rel_Insert_Input = {
  data: Nbpm_User_Attr_Link_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_User_Attr_Link_On_Conflict>;
};

/** on_conflict condition type for table "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_On_Conflict = {
  constraint: Nbpm_User_Attr_Link_Constraint;
  update_columns?: Array<Nbpm_User_Attr_Link_Update_Column>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nbpm_user_attr_link". */
export type Nbpm_User_Attr_Link_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_ufg_member_links_aggregate?: InputMaybe<Nb_Ufg_Member_Link_Aggregate_Order_By>;
  nbpm_user_attribute?: InputMaybe<Nbpm_User_Attribute_Order_By>;
  updated?: InputMaybe<Order_By>;
  user_attribute_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_end?: InputMaybe<Order_By>;
  valid_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbpm_user_attr_link */
export type Nbpm_User_Attr_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbpm_user_attr_link" */
export enum Nbpm_User_Attr_Link_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserAttributeId = 'user_attribute_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

/** input type for updating data in table "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_attribute_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nbpm_user_attr_link" */
export type Nbpm_User_Attr_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbpm_User_Attr_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbpm_User_Attr_Link_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_attribute_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  valid_end?: InputMaybe<Scalars['timestamptz']>;
  valid_start?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nbpm_user_attr_link" */
export enum Nbpm_User_Attr_Link_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserAttributeId = 'user_attribute_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidEnd = 'valid_end',
  /** column name */
  ValidStart = 'valid_start'
}

export type Nbpm_User_Attr_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbpm_User_Attr_Link_Set_Input>;
  where: Nbpm_User_Attr_Link_Bool_Exp;
};

/** columns and relationships of "nbpm_user_attribute" */
export type Nbpm_User_Attribute = {
  __typename?: 'nbpm_user_attribute';
  /** An array relationship */
  application_user_invitation_to_companies: Array<Application_User_Invitation_To_Company>;
  /** An aggregate relationship */
  application_user_invitation_to_companies_aggregate: Application_User_Invitation_To_Company_Aggregate;
  attribute: Scalars['String'];
  attribute_name: Scalars['String'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  nbpm_service_plan_links: Array<Nbpm_Service_Plan_Link>;
  /** An aggregate relationship */
  nbpm_service_plan_links_aggregate: Nbpm_Service_Plan_Link_Aggregate;
  /** An array relationship */
  nbpm_user_attr_links: Array<Nbpm_User_Attr_Link>;
  /** An aggregate relationship */
  nbpm_user_attr_links_aggregate: Nbpm_User_Attr_Link_Aggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "nbpm_user_attribute" */
export type Nbpm_User_AttributeApplication_User_Invitation_To_CompaniesArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


/** columns and relationships of "nbpm_user_attribute" */
export type Nbpm_User_AttributeApplication_User_Invitation_To_Companies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


/** columns and relationships of "nbpm_user_attribute" */
export type Nbpm_User_AttributeNbpm_Service_Plan_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_user_attribute" */
export type Nbpm_User_AttributeNbpm_Service_Plan_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_user_attribute" */
export type Nbpm_User_AttributeNbpm_User_Attr_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attr_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};


/** columns and relationships of "nbpm_user_attribute" */
export type Nbpm_User_AttributeNbpm_User_Attr_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attr_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};

/** aggregated selection of "nbpm_user_attribute" */
export type Nbpm_User_Attribute_Aggregate = {
  __typename?: 'nbpm_user_attribute_aggregate';
  aggregate?: Maybe<Nbpm_User_Attribute_Aggregate_Fields>;
  nodes: Array<Nbpm_User_Attribute>;
};

/** aggregate fields of "nbpm_user_attribute" */
export type Nbpm_User_Attribute_Aggregate_Fields = {
  __typename?: 'nbpm_user_attribute_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbpm_User_Attribute_Max_Fields>;
  min?: Maybe<Nbpm_User_Attribute_Min_Fields>;
};


/** aggregate fields of "nbpm_user_attribute" */
export type Nbpm_User_Attribute_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbpm_User_Attribute_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nbpm_user_attribute". All fields are combined with a logical 'AND'. */
export type Nbpm_User_Attribute_Bool_Exp = {
  _and?: InputMaybe<Array<Nbpm_User_Attribute_Bool_Exp>>;
  _not?: InputMaybe<Nbpm_User_Attribute_Bool_Exp>;
  _or?: InputMaybe<Array<Nbpm_User_Attribute_Bool_Exp>>;
  application_user_invitation_to_companies?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
  application_user_invitation_to_companies_aggregate?: InputMaybe<Application_User_Invitation_To_Company_Aggregate_Bool_Exp>;
  attribute?: InputMaybe<String_Comparison_Exp>;
  attribute_name?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nbpm_service_plan_links?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
  nbpm_service_plan_links_aggregate?: InputMaybe<Nbpm_Service_Plan_Link_Aggregate_Bool_Exp>;
  nbpm_user_attr_links?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
  nbpm_user_attr_links_aggregate?: InputMaybe<Nbpm_User_Attr_Link_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbpm_user_attribute" */
export enum Nbpm_User_Attribute_Constraint {
  /** unique or primary key constraint on columns "attribute" */
  NbpmUserAttributeAttributeKey = 'nbpm_user_attribute_attribute_key',
  /** unique or primary key constraint on columns "id" */
  NbpmUserAttributePkey = 'nbpm_user_attribute_pkey'
}

/** input type for inserting data into table "nbpm_user_attribute" */
export type Nbpm_User_Attribute_Insert_Input = {
  application_user_invitation_to_companies?: InputMaybe<Application_User_Invitation_To_Company_Arr_Rel_Insert_Input>;
  attribute?: InputMaybe<Scalars['String']>;
  attribute_name?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nbpm_service_plan_links?: InputMaybe<Nbpm_Service_Plan_Link_Arr_Rel_Insert_Input>;
  nbpm_user_attr_links?: InputMaybe<Nbpm_User_Attr_Link_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nbpm_User_Attribute_Max_Fields = {
  __typename?: 'nbpm_user_attribute_max_fields';
  attribute?: Maybe<Scalars['String']>;
  attribute_name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Nbpm_User_Attribute_Min_Fields = {
  __typename?: 'nbpm_user_attribute_min_fields';
  attribute?: Maybe<Scalars['String']>;
  attribute_name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "nbpm_user_attribute" */
export type Nbpm_User_Attribute_Mutation_Response = {
  __typename?: 'nbpm_user_attribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbpm_User_Attribute>;
};

/** input type for inserting object relation for remote table "nbpm_user_attribute" */
export type Nbpm_User_Attribute_Obj_Rel_Insert_Input = {
  data: Nbpm_User_Attribute_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_User_Attribute_On_Conflict>;
};

/** on_conflict condition type for table "nbpm_user_attribute" */
export type Nbpm_User_Attribute_On_Conflict = {
  constraint: Nbpm_User_Attribute_Constraint;
  update_columns?: Array<Nbpm_User_Attribute_Update_Column>;
  where?: InputMaybe<Nbpm_User_Attribute_Bool_Exp>;
};

/** Ordering options when selecting data from "nbpm_user_attribute". */
export type Nbpm_User_Attribute_Order_By = {
  application_user_invitation_to_companies_aggregate?: InputMaybe<Application_User_Invitation_To_Company_Aggregate_Order_By>;
  attribute?: InputMaybe<Order_By>;
  attribute_name?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nbpm_service_plan_links_aggregate?: InputMaybe<Nbpm_Service_Plan_Link_Aggregate_Order_By>;
  nbpm_user_attr_links_aggregate?: InputMaybe<Nbpm_User_Attr_Link_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbpm_user_attribute */
export type Nbpm_User_Attribute_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbpm_user_attribute" */
export enum Nbpm_User_Attribute_Select_Column {
  /** column name */
  Attribute = 'attribute',
  /** column name */
  AttributeName = 'attribute_name',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nbpm_user_attribute" */
export type Nbpm_User_Attribute_Set_Input = {
  attribute?: InputMaybe<Scalars['String']>;
  attribute_name?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nbpm_user_attribute" */
export type Nbpm_User_Attribute_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbpm_User_Attribute_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbpm_User_Attribute_Stream_Cursor_Value_Input = {
  attribute?: InputMaybe<Scalars['String']>;
  attribute_name?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nbpm_user_attribute" */
export enum Nbpm_User_Attribute_Update_Column {
  /** column name */
  Attribute = 'attribute',
  /** column name */
  AttributeName = 'attribute_name',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

export type Nbpm_User_Attribute_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbpm_User_Attribute_Set_Input>;
  where: Nbpm_User_Attribute_Bool_Exp;
};

/** columns and relationships of "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link = {
  __typename?: 'nbpm_user_cp_link_link';
  /** An object relationship */
  app_user_active: App_User_Active;
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  nbpm_contract_plan_link: Nbpm_Contract_Plan_Link;
  sp_permission_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_Aggregate = {
  __typename?: 'nbpm_user_cp_link_link_aggregate';
  aggregate?: Maybe<Nbpm_User_Cp_Link_Link_Aggregate_Fields>;
  nodes: Array<Nbpm_User_Cp_Link_Link>;
};

export type Nbpm_User_Cp_Link_Link_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbpm_User_Cp_Link_Link_Aggregate_Bool_Exp_Count>;
};

export type Nbpm_User_Cp_Link_Link_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_Aggregate_Fields = {
  __typename?: 'nbpm_user_cp_link_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbpm_User_Cp_Link_Link_Max_Fields>;
  min?: Maybe<Nbpm_User_Cp_Link_Link_Min_Fields>;
};


/** aggregate fields of "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbpm_User_Cp_Link_Link_Max_Order_By>;
  min?: InputMaybe<Nbpm_User_Cp_Link_Link_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_Arr_Rel_Insert_Input = {
  data: Array<Nbpm_User_Cp_Link_Link_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbpm_User_Cp_Link_Link_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbpm_user_cp_link_link". All fields are combined with a logical 'AND'. */
export type Nbpm_User_Cp_Link_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Bool_Exp>>;
  _not?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nbpm_contract_plan_link?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
  sp_permission_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbpm_user_cp_link_link" */
export enum Nbpm_User_Cp_Link_Link_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbpmPesrmissionLinkPkey = 'nbpm_pesrmission_link_pkey',
  /** unique or primary key constraint on columns "user_id", "sp_permission_id" */
  NbpmUserCpLinkLinkUserIdSpPermissionIdKey = 'nbpm_user_cp_link_link_user_id_sp_permission_id_key'
}

/** input type for inserting data into table "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nbpm_contract_plan_link?: InputMaybe<Nbpm_Contract_Plan_Link_Obj_Rel_Insert_Input>;
  sp_permission_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nbpm_User_Cp_Link_Link_Max_Fields = {
  __typename?: 'nbpm_user_cp_link_link_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sp_permission_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sp_permission_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbpm_User_Cp_Link_Link_Min_Fields = {
  __typename?: 'nbpm_user_cp_link_link_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sp_permission_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sp_permission_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_Mutation_Response = {
  __typename?: 'nbpm_user_cp_link_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbpm_User_Cp_Link_Link>;
};

/** on_conflict condition type for table "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_On_Conflict = {
  constraint: Nbpm_User_Cp_Link_Link_Constraint;
  update_columns?: Array<Nbpm_User_Cp_Link_Link_Update_Column>;
  where?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "nbpm_user_cp_link_link". */
export type Nbpm_User_Cp_Link_Link_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nbpm_contract_plan_link?: InputMaybe<Nbpm_Contract_Plan_Link_Order_By>;
  sp_permission_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbpm_user_cp_link_link */
export type Nbpm_User_Cp_Link_Link_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbpm_user_cp_link_link" */
export enum Nbpm_User_Cp_Link_Link_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  SpPermissionId = 'sp_permission_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sp_permission_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "nbpm_user_cp_link_link" */
export type Nbpm_User_Cp_Link_Link_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbpm_User_Cp_Link_Link_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbpm_User_Cp_Link_Link_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sp_permission_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nbpm_user_cp_link_link" */
export enum Nbpm_User_Cp_Link_Link_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  SpPermissionId = 'sp_permission_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

export type Nbpm_User_Cp_Link_Link_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbpm_User_Cp_Link_Link_Set_Input>;
  where: Nbpm_User_Cp_Link_Link_Bool_Exp;
};

/** 圃場画像に対する画像処理エンジン実行依頼テーブル */
export type Nbs_Ipe_Run_Field = {
  __typename?: 'nbs_ipe_run_field';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  ipe_run_command_id: Scalars['uuid'];
  ipe_run_wide_id: Scalars['uuid'];
  /** An array relationship */
  nbs_ipe_run_link_fields: Array<Nbs_Ipe_Run_Link_Field>;
  /** An aggregate relationship */
  nbs_ipe_run_link_fields_aggregate: Nbs_Ipe_Run_Link_Field_Aggregate;
  /** An object relationship */
  nbs_ipe_run_wide: Nbs_Ipe_Run_Wide;
  /** An object relationship */
  nipe_run_command: Nipe_Run_Command;
  updated: Scalars['timestamptz'];
  working_path: Scalars['String'];
};


/** 圃場画像に対する画像処理エンジン実行依頼テーブル */
export type Nbs_Ipe_Run_FieldNbs_Ipe_Run_Link_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
};


/** 圃場画像に対する画像処理エンジン実行依頼テーブル */
export type Nbs_Ipe_Run_FieldNbs_Ipe_Run_Link_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
};

/** aggregated selection of "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Aggregate = {
  __typename?: 'nbs_ipe_run_field_aggregate';
  aggregate?: Maybe<Nbs_Ipe_Run_Field_Aggregate_Fields>;
  nodes: Array<Nbs_Ipe_Run_Field>;
};

export type Nbs_Ipe_Run_Field_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbs_Ipe_Run_Field_Aggregate_Bool_Exp_Count>;
};

export type Nbs_Ipe_Run_Field_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbs_Ipe_Run_Field_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Aggregate_Fields = {
  __typename?: 'nbs_ipe_run_field_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbs_Ipe_Run_Field_Max_Fields>;
  min?: Maybe<Nbs_Ipe_Run_Field_Min_Fields>;
};


/** aggregate fields of "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbs_Ipe_Run_Field_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbs_Ipe_Run_Field_Max_Order_By>;
  min?: InputMaybe<Nbs_Ipe_Run_Field_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Arr_Rel_Insert_Input = {
  data: Array<Nbs_Ipe_Run_Field_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Field_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbs_ipe_run_field". All fields are combined with a logical 'AND'. */
export type Nbs_Ipe_Run_Field_Bool_Exp = {
  _and?: InputMaybe<Array<Nbs_Ipe_Run_Field_Bool_Exp>>;
  _not?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
  _or?: InputMaybe<Array<Nbs_Ipe_Run_Field_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ipe_run_command_id?: InputMaybe<Uuid_Comparison_Exp>;
  ipe_run_wide_id?: InputMaybe<Uuid_Comparison_Exp>;
  nbs_ipe_run_link_fields?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
  nbs_ipe_run_link_fields_aggregate?: InputMaybe<Nbs_Ipe_Run_Link_Field_Aggregate_Bool_Exp>;
  nbs_ipe_run_wide?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
  nipe_run_command?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  working_path?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbs_ipe_run_field" */
export enum Nbs_Ipe_Run_Field_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbsIpeRunFieldPkey = 'nbs_ipe_run_field_pkey'
}

/** input type for inserting data into table "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_run_command_id?: InputMaybe<Scalars['uuid']>;
  ipe_run_wide_id?: InputMaybe<Scalars['uuid']>;
  nbs_ipe_run_link_fields?: InputMaybe<Nbs_Ipe_Run_Link_Field_Arr_Rel_Insert_Input>;
  nbs_ipe_run_wide?: InputMaybe<Nbs_Ipe_Run_Wide_Obj_Rel_Insert_Input>;
  nipe_run_command?: InputMaybe<Nipe_Run_Command_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  working_path?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nbs_Ipe_Run_Field_Max_Fields = {
  __typename?: 'nbs_ipe_run_field_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ipe_run_command_id?: Maybe<Scalars['uuid']>;
  ipe_run_wide_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  working_path?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_run_command_id?: InputMaybe<Order_By>;
  ipe_run_wide_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  working_path?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbs_Ipe_Run_Field_Min_Fields = {
  __typename?: 'nbs_ipe_run_field_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ipe_run_command_id?: Maybe<Scalars['uuid']>;
  ipe_run_wide_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  working_path?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_run_command_id?: InputMaybe<Order_By>;
  ipe_run_wide_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  working_path?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Mutation_Response = {
  __typename?: 'nbs_ipe_run_field_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbs_Ipe_Run_Field>;
};

/** input type for inserting object relation for remote table "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Obj_Rel_Insert_Input = {
  data: Nbs_Ipe_Run_Field_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Field_On_Conflict>;
};

/** on_conflict condition type for table "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_On_Conflict = {
  constraint: Nbs_Ipe_Run_Field_Constraint;
  update_columns?: Array<Nbs_Ipe_Run_Field_Update_Column>;
  where?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
};

/** Ordering options when selecting data from "nbs_ipe_run_field". */
export type Nbs_Ipe_Run_Field_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_run_command_id?: InputMaybe<Order_By>;
  ipe_run_wide_id?: InputMaybe<Order_By>;
  nbs_ipe_run_link_fields_aggregate?: InputMaybe<Nbs_Ipe_Run_Link_Field_Aggregate_Order_By>;
  nbs_ipe_run_wide?: InputMaybe<Nbs_Ipe_Run_Wide_Order_By>;
  nipe_run_command?: InputMaybe<Nipe_Run_Command_Order_By>;
  updated?: InputMaybe<Order_By>;
  working_path?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbs_ipe_run_field */
export type Nbs_Ipe_Run_Field_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbs_ipe_run_field" */
export enum Nbs_Ipe_Run_Field_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IpeRunCommandId = 'ipe_run_command_id',
  /** column name */
  IpeRunWideId = 'ipe_run_wide_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  WorkingPath = 'working_path'
}

/** input type for updating data in table "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_run_command_id?: InputMaybe<Scalars['uuid']>;
  ipe_run_wide_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  working_path?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nbs_ipe_run_field" */
export type Nbs_Ipe_Run_Field_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbs_Ipe_Run_Field_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbs_Ipe_Run_Field_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_run_command_id?: InputMaybe<Scalars['uuid']>;
  ipe_run_wide_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  working_path?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nbs_ipe_run_field" */
export enum Nbs_Ipe_Run_Field_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IpeRunCommandId = 'ipe_run_command_id',
  /** column name */
  IpeRunWideId = 'ipe_run_wide_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  WorkingPath = 'working_path'
}

export type Nbs_Ipe_Run_Field_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbs_Ipe_Run_Field_Set_Input>;
  where: Nbs_Ipe_Run_Field_Bool_Exp;
};

/** 画像処理エンジン実行の入力圃場画像データへのリンクテーブル */
export type Nbs_Ipe_Run_Link_Field = {
  __typename?: 'nbs_ipe_run_link_field';
  created: Scalars['timestamptz'];
  field_orthoimg_id: Scalars['uuid'];
  id: Scalars['uuid'];
  ipe_run_field_id: Scalars['uuid'];
  /** An object relationship */
  nb_field_orthoimg: Nb_Field_Orthoimg;
  /** An object relationship */
  nbs_ipe_run_field: Nbs_Ipe_Run_Field;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_Aggregate = {
  __typename?: 'nbs_ipe_run_link_field_aggregate';
  aggregate?: Maybe<Nbs_Ipe_Run_Link_Field_Aggregate_Fields>;
  nodes: Array<Nbs_Ipe_Run_Link_Field>;
};

export type Nbs_Ipe_Run_Link_Field_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbs_Ipe_Run_Link_Field_Aggregate_Bool_Exp_Count>;
};

export type Nbs_Ipe_Run_Link_Field_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_Aggregate_Fields = {
  __typename?: 'nbs_ipe_run_link_field_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbs_Ipe_Run_Link_Field_Max_Fields>;
  min?: Maybe<Nbs_Ipe_Run_Link_Field_Min_Fields>;
};


/** aggregate fields of "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbs_Ipe_Run_Link_Field_Max_Order_By>;
  min?: InputMaybe<Nbs_Ipe_Run_Link_Field_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_Arr_Rel_Insert_Input = {
  data: Array<Nbs_Ipe_Run_Link_Field_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Link_Field_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbs_ipe_run_link_field". All fields are combined with a logical 'AND'. */
export type Nbs_Ipe_Run_Link_Field_Bool_Exp = {
  _and?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Bool_Exp>>;
  _not?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
  _or?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_orthoimg_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ipe_run_field_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_field_orthoimg?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
  nbs_ipe_run_field?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbs_ipe_run_link_field" */
export enum Nbs_Ipe_Run_Link_Field_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbsIpeRunLinkFieldPkey = 'nbs_ipe_run_link_field_pkey'
}

/** input type for inserting data into table "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_orthoimg_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_run_field_id?: InputMaybe<Scalars['uuid']>;
  nb_field_orthoimg?: InputMaybe<Nb_Field_Orthoimg_Obj_Rel_Insert_Input>;
  nbs_ipe_run_field?: InputMaybe<Nbs_Ipe_Run_Field_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nbs_Ipe_Run_Link_Field_Max_Fields = {
  __typename?: 'nbs_ipe_run_link_field_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_orthoimg_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  ipe_run_field_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  field_orthoimg_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_run_field_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbs_Ipe_Run_Link_Field_Min_Fields = {
  __typename?: 'nbs_ipe_run_link_field_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  field_orthoimg_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  ipe_run_field_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  field_orthoimg_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_run_field_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_Mutation_Response = {
  __typename?: 'nbs_ipe_run_link_field_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbs_Ipe_Run_Link_Field>;
};

/** on_conflict condition type for table "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_On_Conflict = {
  constraint: Nbs_Ipe_Run_Link_Field_Constraint;
  update_columns?: Array<Nbs_Ipe_Run_Link_Field_Update_Column>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
};

/** Ordering options when selecting data from "nbs_ipe_run_link_field". */
export type Nbs_Ipe_Run_Link_Field_Order_By = {
  created?: InputMaybe<Order_By>;
  field_orthoimg_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_run_field_id?: InputMaybe<Order_By>;
  nb_field_orthoimg?: InputMaybe<Nb_Field_Orthoimg_Order_By>;
  nbs_ipe_run_field?: InputMaybe<Nbs_Ipe_Run_Field_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbs_ipe_run_link_field */
export type Nbs_Ipe_Run_Link_Field_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbs_ipe_run_link_field" */
export enum Nbs_Ipe_Run_Link_Field_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldOrthoimgId = 'field_orthoimg_id',
  /** column name */
  Id = 'id',
  /** column name */
  IpeRunFieldId = 'ipe_run_field_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_orthoimg_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_run_field_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nbs_ipe_run_link_field" */
export type Nbs_Ipe_Run_Link_Field_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbs_Ipe_Run_Link_Field_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbs_Ipe_Run_Link_Field_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  field_orthoimg_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_run_field_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nbs_ipe_run_link_field" */
export enum Nbs_Ipe_Run_Link_Field_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FieldOrthoimgId = 'field_orthoimg_id',
  /** column name */
  Id = 'id',
  /** column name */
  IpeRunFieldId = 'ipe_run_field_id',
  /** column name */
  Updated = 'updated'
}

export type Nbs_Ipe_Run_Link_Field_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbs_Ipe_Run_Link_Field_Set_Input>;
  where: Nbs_Ipe_Run_Link_Field_Bool_Exp;
};

/** 広域画像に対する画像処理エンジン実行で定義した入力画像データへのリンクテーブル */
export type Nbs_Ipe_Run_Link_Wide = {
  __typename?: 'nbs_ipe_run_link_wide';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  ipe_run_wide_id: Scalars['uuid'];
  /** An object relationship */
  nb_wide_orthoimg: Nb_Wide_Orthoimg;
  /** An object relationship */
  nbs_ipe_run_wide: Nbs_Ipe_Run_Wide;
  updated: Scalars['timestamptz'];
  wide_orthoimg_id: Scalars['uuid'];
};

/** aggregated selection of "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_Aggregate = {
  __typename?: 'nbs_ipe_run_link_wide_aggregate';
  aggregate?: Maybe<Nbs_Ipe_Run_Link_Wide_Aggregate_Fields>;
  nodes: Array<Nbs_Ipe_Run_Link_Wide>;
};

export type Nbs_Ipe_Run_Link_Wide_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Aggregate_Bool_Exp_Count>;
};

export type Nbs_Ipe_Run_Link_Wide_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_Aggregate_Fields = {
  __typename?: 'nbs_ipe_run_link_wide_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbs_Ipe_Run_Link_Wide_Max_Fields>;
  min?: Maybe<Nbs_Ipe_Run_Link_Wide_Min_Fields>;
};


/** aggregate fields of "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Max_Order_By>;
  min?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_Arr_Rel_Insert_Input = {
  data: Array<Nbs_Ipe_Run_Link_Wide_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Link_Wide_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbs_ipe_run_link_wide". All fields are combined with a logical 'AND'. */
export type Nbs_Ipe_Run_Link_Wide_Bool_Exp = {
  _and?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Bool_Exp>>;
  _not?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
  _or?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ipe_run_wide_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
  nbs_ipe_run_wide?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  wide_orthoimg_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbs_ipe_run_link_wide" */
export enum Nbs_Ipe_Run_Link_Wide_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbsIpeRunLinkWidePkey = 'nbs_ipe_run_link_wide_pkey'
}

/** input type for inserting data into table "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_run_wide_id?: InputMaybe<Scalars['uuid']>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Obj_Rel_Insert_Input>;
  nbs_ipe_run_wide?: InputMaybe<Nbs_Ipe_Run_Wide_Obj_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nbs_Ipe_Run_Link_Wide_Max_Fields = {
  __typename?: 'nbs_ipe_run_link_wide_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ipe_run_wide_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  wide_orthoimg_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_run_wide_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbs_Ipe_Run_Link_Wide_Min_Fields = {
  __typename?: 'nbs_ipe_run_link_wide_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ipe_run_wide_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  wide_orthoimg_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_run_wide_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_Mutation_Response = {
  __typename?: 'nbs_ipe_run_link_wide_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbs_Ipe_Run_Link_Wide>;
};

/** on_conflict condition type for table "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_On_Conflict = {
  constraint: Nbs_Ipe_Run_Link_Wide_Constraint;
  update_columns?: Array<Nbs_Ipe_Run_Link_Wide_Update_Column>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
};

/** Ordering options when selecting data from "nbs_ipe_run_link_wide". */
export type Nbs_Ipe_Run_Link_Wide_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_run_wide_id?: InputMaybe<Order_By>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Order_By>;
  nbs_ipe_run_wide?: InputMaybe<Nbs_Ipe_Run_Wide_Order_By>;
  updated?: InputMaybe<Order_By>;
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbs_ipe_run_link_wide */
export type Nbs_Ipe_Run_Link_Wide_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbs_ipe_run_link_wide" */
export enum Nbs_Ipe_Run_Link_Wide_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IpeRunWideId = 'ipe_run_wide_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  WideOrthoimgId = 'wide_orthoimg_id'
}

/** input type for updating data in table "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_run_wide_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "nbs_ipe_run_link_wide" */
export type Nbs_Ipe_Run_Link_Wide_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbs_Ipe_Run_Link_Wide_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbs_Ipe_Run_Link_Wide_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_run_wide_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nbs_ipe_run_link_wide" */
export enum Nbs_Ipe_Run_Link_Wide_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IpeRunWideId = 'ipe_run_wide_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  WideOrthoimgId = 'wide_orthoimg_id'
}

export type Nbs_Ipe_Run_Link_Wide_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Set_Input>;
  where: Nbs_Ipe_Run_Link_Wide_Bool_Exp;
};

/** 広域画像に対する画像処理エンジン実行依頼テーブル */
export type Nbs_Ipe_Run_Wide = {
  __typename?: 'nbs_ipe_run_wide';
  /** An object relationship */
  app_user_active: App_User_Active;
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  ipe_engine_id: Scalars['uuid'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An array relationship */
  nbs_ipe_run_fields: Array<Nbs_Ipe_Run_Field>;
  /** An aggregate relationship */
  nbs_ipe_run_fields_aggregate: Nbs_Ipe_Run_Field_Aggregate;
  /** An array relationship */
  nbs_ipe_run_link_wides: Array<Nbs_Ipe_Run_Link_Wide>;
  /** An aggregate relationship */
  nbs_ipe_run_link_wides_aggregate: Nbs_Ipe_Run_Link_Wide_Aggregate;
  /** An object relationship */
  nipe_engine_information: Nipe_Engine_Information;
  requested_user_id: Scalars['uuid'];
  updated: Scalars['timestamptz'];
};


/** 広域画像に対する画像処理エンジン実行依頼テーブル */
export type Nbs_Ipe_Run_WideNbs_Ipe_Run_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
};


/** 広域画像に対する画像処理エンジン実行依頼テーブル */
export type Nbs_Ipe_Run_WideNbs_Ipe_Run_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
};


/** 広域画像に対する画像処理エンジン実行依頼テーブル */
export type Nbs_Ipe_Run_WideNbs_Ipe_Run_Link_WidesArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
};


/** 広域画像に対する画像処理エンジン実行依頼テーブル */
export type Nbs_Ipe_Run_WideNbs_Ipe_Run_Link_Wides_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
};

/** aggregated selection of "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Aggregate = {
  __typename?: 'nbs_ipe_run_wide_aggregate';
  aggregate?: Maybe<Nbs_Ipe_Run_Wide_Aggregate_Fields>;
  nodes: Array<Nbs_Ipe_Run_Wide>;
};

export type Nbs_Ipe_Run_Wide_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbs_Ipe_Run_Wide_Aggregate_Bool_Exp_Count>;
};

export type Nbs_Ipe_Run_Wide_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Aggregate_Fields = {
  __typename?: 'nbs_ipe_run_wide_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbs_Ipe_Run_Wide_Max_Fields>;
  min?: Maybe<Nbs_Ipe_Run_Wide_Min_Fields>;
};


/** aggregate fields of "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbs_Ipe_Run_Wide_Max_Order_By>;
  min?: InputMaybe<Nbs_Ipe_Run_Wide_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Arr_Rel_Insert_Input = {
  data: Array<Nbs_Ipe_Run_Wide_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Wide_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbs_ipe_run_wide". All fields are combined with a logical 'AND'. */
export type Nbs_Ipe_Run_Wide_Bool_Exp = {
  _and?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Bool_Exp>>;
  _not?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
  _or?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ipe_engine_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nbs_ipe_run_fields?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
  nbs_ipe_run_fields_aggregate?: InputMaybe<Nbs_Ipe_Run_Field_Aggregate_Bool_Exp>;
  nbs_ipe_run_link_wides?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
  nbs_ipe_run_link_wides_aggregate?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Aggregate_Bool_Exp>;
  nipe_engine_information?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
  requested_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbs_ipe_run_wide" */
export enum Nbs_Ipe_Run_Wide_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbIpeRunWidePkey = 'nb_ipe_run_wide_pkey'
}

/** input type for inserting data into table "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_engine_id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nbs_ipe_run_fields?: InputMaybe<Nbs_Ipe_Run_Field_Arr_Rel_Insert_Input>;
  nbs_ipe_run_link_wides?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Arr_Rel_Insert_Input>;
  nipe_engine_information?: InputMaybe<Nipe_Engine_Information_Obj_Rel_Insert_Input>;
  requested_user_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nbs_Ipe_Run_Wide_Max_Fields = {
  __typename?: 'nbs_ipe_run_wide_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ipe_engine_id?: Maybe<Scalars['uuid']>;
  requested_user_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_engine_id?: InputMaybe<Order_By>;
  requested_user_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbs_Ipe_Run_Wide_Min_Fields = {
  __typename?: 'nbs_ipe_run_wide_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ipe_engine_id?: Maybe<Scalars['uuid']>;
  requested_user_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_engine_id?: InputMaybe<Order_By>;
  requested_user_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Mutation_Response = {
  __typename?: 'nbs_ipe_run_wide_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbs_Ipe_Run_Wide>;
};

/** input type for inserting object relation for remote table "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Obj_Rel_Insert_Input = {
  data: Nbs_Ipe_Run_Wide_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbs_Ipe_Run_Wide_On_Conflict>;
};

/** on_conflict condition type for table "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_On_Conflict = {
  constraint: Nbs_Ipe_Run_Wide_Constraint;
  update_columns?: Array<Nbs_Ipe_Run_Wide_Update_Column>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};

/** Ordering options when selecting data from "nbs_ipe_run_wide". */
export type Nbs_Ipe_Run_Wide_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ipe_engine_id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nbs_ipe_run_fields_aggregate?: InputMaybe<Nbs_Ipe_Run_Field_Aggregate_Order_By>;
  nbs_ipe_run_link_wides_aggregate?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Aggregate_Order_By>;
  nipe_engine_information?: InputMaybe<Nipe_Engine_Information_Order_By>;
  requested_user_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbs_ipe_run_wide */
export type Nbs_Ipe_Run_Wide_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbs_ipe_run_wide" */
export enum Nbs_Ipe_Run_Wide_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IpeEngineId = 'ipe_engine_id',
  /** column name */
  RequestedUserId = 'requested_user_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Set_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_engine_id?: InputMaybe<Scalars['uuid']>;
  requested_user_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nbs_ipe_run_wide" */
export type Nbs_Ipe_Run_Wide_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbs_Ipe_Run_Wide_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbs_Ipe_Run_Wide_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ipe_engine_id?: InputMaybe<Scalars['uuid']>;
  requested_user_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nbs_ipe_run_wide" */
export enum Nbs_Ipe_Run_Wide_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IpeEngineId = 'ipe_engine_id',
  /** column name */
  RequestedUserId = 'requested_user_id',
  /** column name */
  Updated = 'updated'
}

export type Nbs_Ipe_Run_Wide_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbs_Ipe_Run_Wide_Set_Input>;
  where: Nbs_Ipe_Run_Wide_Bool_Exp;
};

/** マルチパートアップロードを管理 */
export type Nbs_Multipart_Upload = {
  __typename?: 'nbs_multipart_upload';
  /** An object relationship */
  app_user_active: App_User_Active;
  /** ファイルアップロード要求した会社 */
  company_id: Scalars['uuid'];
  /** 作成日時 */
  created: Scalars['timestamptz'];
  /** 一意な識別子 */
  id: Scalars['uuid'];
  /** 終了処理が完了したか否か */
  is_completed: Scalars['Boolean'];
  /** エラー発生時などのメッセージ */
  message: Scalars['String'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An object relationship */
  nbs_wide_orthoimg_load?: Maybe<Nbs_Wide_Orthoimg_Load>;
  /** パート数 */
  number_of_parts: Scalars['Int'];
  /** パートファイルごとのmd5でファイルの先頭のパートから順にmd5が格納される。アップロードの再開時に使用し、署名付きURLの払い出し時に設定する必要がある。 */
  part_md5s: Scalars['_text'];
  /** パートファイルごとのファイルサイズでファイルの先頭のパートから順にmd5が格納される。アップロードの再開時に使用し、署名付きURLの払い出し時に設定する必要がある。 */
  part_sizes: Scalars['_int4'];
  /** バケット名 */
  s3bucket: Scalars['String'];
  /** s3オブジェクトのキー */
  s3key: Scalars['String'];
  /** 更新日時 */
  updated: Scalars['timestamptz'];
  /** s3 create_multipart_upload で払い出されるID */
  upload_id: Scalars['String'];
  /** ファイルアップロード要求をしたユーザ */
  upload_user_id: Scalars['uuid'];
};

/** aggregated selection of "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Aggregate = {
  __typename?: 'nbs_multipart_upload_aggregate';
  aggregate?: Maybe<Nbs_Multipart_Upload_Aggregate_Fields>;
  nodes: Array<Nbs_Multipart_Upload>;
};

export type Nbs_Multipart_Upload_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Nbs_Multipart_Upload_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Nbs_Multipart_Upload_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Nbs_Multipart_Upload_Aggregate_Bool_Exp_Count>;
};

export type Nbs_Multipart_Upload_Aggregate_Bool_Exp_Bool_And = {
  arguments: Nbs_Multipart_Upload_Select_Column_Nbs_Multipart_Upload_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Nbs_Multipart_Upload_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Nbs_Multipart_Upload_Select_Column_Nbs_Multipart_Upload_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Nbs_Multipart_Upload_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbs_Multipart_Upload_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Aggregate_Fields = {
  __typename?: 'nbs_multipart_upload_aggregate_fields';
  avg?: Maybe<Nbs_Multipart_Upload_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nbs_Multipart_Upload_Max_Fields>;
  min?: Maybe<Nbs_Multipart_Upload_Min_Fields>;
  stddev?: Maybe<Nbs_Multipart_Upload_Stddev_Fields>;
  stddev_pop?: Maybe<Nbs_Multipart_Upload_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nbs_Multipart_Upload_Stddev_Samp_Fields>;
  sum?: Maybe<Nbs_Multipart_Upload_Sum_Fields>;
  var_pop?: Maybe<Nbs_Multipart_Upload_Var_Pop_Fields>;
  var_samp?: Maybe<Nbs_Multipart_Upload_Var_Samp_Fields>;
  variance?: Maybe<Nbs_Multipart_Upload_Variance_Fields>;
};


/** aggregate fields of "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbs_Multipart_Upload_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Aggregate_Order_By = {
  avg?: InputMaybe<Nbs_Multipart_Upload_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbs_Multipart_Upload_Max_Order_By>;
  min?: InputMaybe<Nbs_Multipart_Upload_Min_Order_By>;
  stddev?: InputMaybe<Nbs_Multipart_Upload_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nbs_Multipart_Upload_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nbs_Multipart_Upload_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nbs_Multipart_Upload_Sum_Order_By>;
  var_pop?: InputMaybe<Nbs_Multipart_Upload_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nbs_Multipart_Upload_Var_Samp_Order_By>;
  variance?: InputMaybe<Nbs_Multipart_Upload_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Arr_Rel_Insert_Input = {
  data: Array<Nbs_Multipart_Upload_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbs_Multipart_Upload_On_Conflict>;
};

/** aggregate avg on columns */
export type Nbs_Multipart_Upload_Avg_Fields = {
  __typename?: 'nbs_multipart_upload_avg_fields';
  /** パート数 */
  number_of_parts?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Avg_Order_By = {
  /** パート数 */
  number_of_parts?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nbs_multipart_upload". All fields are combined with a logical 'AND'. */
export type Nbs_Multipart_Upload_Bool_Exp = {
  _and?: InputMaybe<Array<Nbs_Multipart_Upload_Bool_Exp>>;
  _not?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
  _or?: InputMaybe<Array<Nbs_Multipart_Upload_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_completed?: InputMaybe<Boolean_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nbs_wide_orthoimg_load?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
  number_of_parts?: InputMaybe<Int_Comparison_Exp>;
  part_md5s?: InputMaybe<_Text_Comparison_Exp>;
  part_sizes?: InputMaybe<_Int4_Comparison_Exp>;
  s3bucket?: InputMaybe<String_Comparison_Exp>;
  s3key?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload_id?: InputMaybe<String_Comparison_Exp>;
  upload_user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbs_multipart_upload" */
export enum Nbs_Multipart_Upload_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbsMultipartUploadPkey = 'nbs_multipart_upload_pkey',
  /** unique or primary key constraint on columns "upload_id" */
  NbsMultipartUploadUploadIdKey = 'nbs_multipart_upload_upload_id_key'
}

/** input type for incrementing numeric columns in table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Inc_Input = {
  /** パート数 */
  number_of_parts?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  /** ファイルアップロード要求した会社 */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** 作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  /** 終了処理が完了したか否か */
  is_completed?: InputMaybe<Scalars['Boolean']>;
  /** エラー発生時などのメッセージ */
  message?: InputMaybe<Scalars['String']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nbs_wide_orthoimg_load?: InputMaybe<Nbs_Wide_Orthoimg_Load_Obj_Rel_Insert_Input>;
  /** パート数 */
  number_of_parts?: InputMaybe<Scalars['Int']>;
  /** パートファイルごとのmd5でファイルの先頭のパートから順にmd5が格納される。アップロードの再開時に使用し、署名付きURLの払い出し時に設定する必要がある。 */
  part_md5s?: InputMaybe<Scalars['_text']>;
  /** パートファイルごとのファイルサイズでファイルの先頭のパートから順にmd5が格納される。アップロードの再開時に使用し、署名付きURLの払い出し時に設定する必要がある。 */
  part_sizes?: InputMaybe<Scalars['_int4']>;
  /** バケット名 */
  s3bucket?: InputMaybe<Scalars['String']>;
  /** s3オブジェクトのキー */
  s3key?: InputMaybe<Scalars['String']>;
  /** 更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** s3 create_multipart_upload で払い出されるID */
  upload_id?: InputMaybe<Scalars['String']>;
  /** ファイルアップロード要求をしたユーザ */
  upload_user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nbs_Multipart_Upload_Max_Fields = {
  __typename?: 'nbs_multipart_upload_max_fields';
  /** ファイルアップロード要求した会社 */
  company_id?: Maybe<Scalars['uuid']>;
  /** 作成日時 */
  created?: Maybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: Maybe<Scalars['uuid']>;
  /** エラー発生時などのメッセージ */
  message?: Maybe<Scalars['String']>;
  /** パート数 */
  number_of_parts?: Maybe<Scalars['Int']>;
  /** バケット名 */
  s3bucket?: Maybe<Scalars['String']>;
  /** s3オブジェクトのキー */
  s3key?: Maybe<Scalars['String']>;
  /** 更新日時 */
  updated?: Maybe<Scalars['timestamptz']>;
  /** s3 create_multipart_upload で払い出されるID */
  upload_id?: Maybe<Scalars['String']>;
  /** ファイルアップロード要求をしたユーザ */
  upload_user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Max_Order_By = {
  /** ファイルアップロード要求した会社 */
  company_id?: InputMaybe<Order_By>;
  /** 作成日時 */
  created?: InputMaybe<Order_By>;
  /** 一意な識別子 */
  id?: InputMaybe<Order_By>;
  /** エラー発生時などのメッセージ */
  message?: InputMaybe<Order_By>;
  /** パート数 */
  number_of_parts?: InputMaybe<Order_By>;
  /** バケット名 */
  s3bucket?: InputMaybe<Order_By>;
  /** s3オブジェクトのキー */
  s3key?: InputMaybe<Order_By>;
  /** 更新日時 */
  updated?: InputMaybe<Order_By>;
  /** s3 create_multipart_upload で払い出されるID */
  upload_id?: InputMaybe<Order_By>;
  /** ファイルアップロード要求をしたユーザ */
  upload_user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbs_Multipart_Upload_Min_Fields = {
  __typename?: 'nbs_multipart_upload_min_fields';
  /** ファイルアップロード要求した会社 */
  company_id?: Maybe<Scalars['uuid']>;
  /** 作成日時 */
  created?: Maybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: Maybe<Scalars['uuid']>;
  /** エラー発生時などのメッセージ */
  message?: Maybe<Scalars['String']>;
  /** パート数 */
  number_of_parts?: Maybe<Scalars['Int']>;
  /** バケット名 */
  s3bucket?: Maybe<Scalars['String']>;
  /** s3オブジェクトのキー */
  s3key?: Maybe<Scalars['String']>;
  /** 更新日時 */
  updated?: Maybe<Scalars['timestamptz']>;
  /** s3 create_multipart_upload で払い出されるID */
  upload_id?: Maybe<Scalars['String']>;
  /** ファイルアップロード要求をしたユーザ */
  upload_user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Min_Order_By = {
  /** ファイルアップロード要求した会社 */
  company_id?: InputMaybe<Order_By>;
  /** 作成日時 */
  created?: InputMaybe<Order_By>;
  /** 一意な識別子 */
  id?: InputMaybe<Order_By>;
  /** エラー発生時などのメッセージ */
  message?: InputMaybe<Order_By>;
  /** パート数 */
  number_of_parts?: InputMaybe<Order_By>;
  /** バケット名 */
  s3bucket?: InputMaybe<Order_By>;
  /** s3オブジェクトのキー */
  s3key?: InputMaybe<Order_By>;
  /** 更新日時 */
  updated?: InputMaybe<Order_By>;
  /** s3 create_multipart_upload で払い出されるID */
  upload_id?: InputMaybe<Order_By>;
  /** ファイルアップロード要求をしたユーザ */
  upload_user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Mutation_Response = {
  __typename?: 'nbs_multipart_upload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbs_Multipart_Upload>;
};

/** input type for inserting object relation for remote table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Obj_Rel_Insert_Input = {
  data: Nbs_Multipart_Upload_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbs_Multipart_Upload_On_Conflict>;
};

/** on_conflict condition type for table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_On_Conflict = {
  constraint: Nbs_Multipart_Upload_Constraint;
  update_columns?: Array<Nbs_Multipart_Upload_Update_Column>;
  where?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
};

/** Ordering options when selecting data from "nbs_multipart_upload". */
export type Nbs_Multipart_Upload_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_completed?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nbs_wide_orthoimg_load?: InputMaybe<Nbs_Wide_Orthoimg_Load_Order_By>;
  number_of_parts?: InputMaybe<Order_By>;
  part_md5s?: InputMaybe<Order_By>;
  part_sizes?: InputMaybe<Order_By>;
  s3bucket?: InputMaybe<Order_By>;
  s3key?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
  upload_user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbs_multipart_upload */
export type Nbs_Multipart_Upload_Pk_Columns_Input = {
  /** 一意な識別子 */
  id: Scalars['uuid'];
};

/** select columns of table "nbs_multipart_upload" */
export enum Nbs_Multipart_Upload_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  Message = 'message',
  /** column name */
  NumberOfParts = 'number_of_parts',
  /** column name */
  PartMd5s = 'part_md5s',
  /** column name */
  PartSizes = 'part_sizes',
  /** column name */
  S3bucket = 's3bucket',
  /** column name */
  S3key = 's3key',
  /** column name */
  Updated = 'updated',
  /** column name */
  UploadId = 'upload_id',
  /** column name */
  UploadUserId = 'upload_user_id'
}

/** select "nbs_multipart_upload_aggregate_bool_exp_bool_and_arguments_columns" columns of table "nbs_multipart_upload" */
export enum Nbs_Multipart_Upload_Select_Column_Nbs_Multipart_Upload_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** select "nbs_multipart_upload_aggregate_bool_exp_bool_or_arguments_columns" columns of table "nbs_multipart_upload" */
export enum Nbs_Multipart_Upload_Select_Column_Nbs_Multipart_Upload_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** input type for updating data in table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Set_Input = {
  /** ファイルアップロード要求した会社 */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** 作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  /** 終了処理が完了したか否か */
  is_completed?: InputMaybe<Scalars['Boolean']>;
  /** エラー発生時などのメッセージ */
  message?: InputMaybe<Scalars['String']>;
  /** パート数 */
  number_of_parts?: InputMaybe<Scalars['Int']>;
  /** パートファイルごとのmd5でファイルの先頭のパートから順にmd5が格納される。アップロードの再開時に使用し、署名付きURLの払い出し時に設定する必要がある。 */
  part_md5s?: InputMaybe<Scalars['_text']>;
  /** パートファイルごとのファイルサイズでファイルの先頭のパートから順にmd5が格納される。アップロードの再開時に使用し、署名付きURLの払い出し時に設定する必要がある。 */
  part_sizes?: InputMaybe<Scalars['_int4']>;
  /** バケット名 */
  s3bucket?: InputMaybe<Scalars['String']>;
  /** s3オブジェクトのキー */
  s3key?: InputMaybe<Scalars['String']>;
  /** 更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** s3 create_multipart_upload で払い出されるID */
  upload_id?: InputMaybe<Scalars['String']>;
  /** ファイルアップロード要求をしたユーザ */
  upload_user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Nbs_Multipart_Upload_Stddev_Fields = {
  __typename?: 'nbs_multipart_upload_stddev_fields';
  /** パート数 */
  number_of_parts?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Stddev_Order_By = {
  /** パート数 */
  number_of_parts?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nbs_Multipart_Upload_Stddev_Pop_Fields = {
  __typename?: 'nbs_multipart_upload_stddev_pop_fields';
  /** パート数 */
  number_of_parts?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Stddev_Pop_Order_By = {
  /** パート数 */
  number_of_parts?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nbs_Multipart_Upload_Stddev_Samp_Fields = {
  __typename?: 'nbs_multipart_upload_stddev_samp_fields';
  /** パート数 */
  number_of_parts?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Stddev_Samp_Order_By = {
  /** パート数 */
  number_of_parts?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbs_Multipart_Upload_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbs_Multipart_Upload_Stream_Cursor_Value_Input = {
  /** ファイルアップロード要求した会社 */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** 作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  /** 終了処理が完了したか否か */
  is_completed?: InputMaybe<Scalars['Boolean']>;
  /** エラー発生時などのメッセージ */
  message?: InputMaybe<Scalars['String']>;
  /** パート数 */
  number_of_parts?: InputMaybe<Scalars['Int']>;
  /** パートファイルごとのmd5でファイルの先頭のパートから順にmd5が格納される。アップロードの再開時に使用し、署名付きURLの払い出し時に設定する必要がある。 */
  part_md5s?: InputMaybe<Scalars['_text']>;
  /** パートファイルごとのファイルサイズでファイルの先頭のパートから順にmd5が格納される。アップロードの再開時に使用し、署名付きURLの払い出し時に設定する必要がある。 */
  part_sizes?: InputMaybe<Scalars['_int4']>;
  /** バケット名 */
  s3bucket?: InputMaybe<Scalars['String']>;
  /** s3オブジェクトのキー */
  s3key?: InputMaybe<Scalars['String']>;
  /** 更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** s3 create_multipart_upload で払い出されるID */
  upload_id?: InputMaybe<Scalars['String']>;
  /** ファイルアップロード要求をしたユーザ */
  upload_user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nbs_Multipart_Upload_Sum_Fields = {
  __typename?: 'nbs_multipart_upload_sum_fields';
  /** パート数 */
  number_of_parts?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Sum_Order_By = {
  /** パート数 */
  number_of_parts?: InputMaybe<Order_By>;
};

/** update columns of table "nbs_multipart_upload" */
export enum Nbs_Multipart_Upload_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  Message = 'message',
  /** column name */
  NumberOfParts = 'number_of_parts',
  /** column name */
  PartMd5s = 'part_md5s',
  /** column name */
  PartSizes = 'part_sizes',
  /** column name */
  S3bucket = 's3bucket',
  /** column name */
  S3key = 's3key',
  /** column name */
  Updated = 'updated',
  /** column name */
  UploadId = 'upload_id',
  /** column name */
  UploadUserId = 'upload_user_id'
}

export type Nbs_Multipart_Upload_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nbs_Multipart_Upload_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbs_Multipart_Upload_Set_Input>;
  where: Nbs_Multipart_Upload_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nbs_Multipart_Upload_Var_Pop_Fields = {
  __typename?: 'nbs_multipart_upload_var_pop_fields';
  /** パート数 */
  number_of_parts?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Var_Pop_Order_By = {
  /** パート数 */
  number_of_parts?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nbs_Multipart_Upload_Var_Samp_Fields = {
  __typename?: 'nbs_multipart_upload_var_samp_fields';
  /** パート数 */
  number_of_parts?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Var_Samp_Order_By = {
  /** パート数 */
  number_of_parts?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nbs_Multipart_Upload_Variance_Fields = {
  __typename?: 'nbs_multipart_upload_variance_fields';
  /** パート数 */
  number_of_parts?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nbs_multipart_upload" */
export type Nbs_Multipart_Upload_Variance_Order_By = {
  /** パート数 */
  number_of_parts?: InputMaybe<Order_By>;
};

/** 画像センシング用の広域オルソ化済み画像のロード管理テーブル */
export type Nbs_Wide_Orthoimg_Load = {
  __typename?: 'nbs_wide_orthoimg_load';
  /** An object relationship */
  app_user_active: App_User_Active;
  /** nb_companyのid、データアップロードが実行された会社 */
  company_id: Scalars['uuid'];
  /** レコードの作成日時 */
  created: Scalars['timestamptz'];
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: Maybe<Scalars['Int']>;
  /** 一意な識別子 */
  id: Scalars['uuid'];
  /** nbs_multipart_upload.id */
  multipart_upload_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  nb_company: Nb_Company;
  /** An object relationship */
  nb_wide_orthoimg?: Maybe<Nb_Wide_Orthoimg>;
  /** An object relationship */
  nbbe_orthoimg_type: Nbbe_Orthoimg_Type;
  /** An object relationship */
  nbs_multipart_upload?: Maybe<Nbs_Multipart_Upload>;
  /** オルソ画像の種別 */
  orthoimg_type: Scalars['String'];
  /** 分割処理実行時のタイムスタンプ */
  proc_datetime: Scalars['timestamptz'];
  /** 画像ファイルのS3 URI */
  s3uri?: Maybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime: Scalars['timestamptz'];
  /** データファイルのロード状態 */
  status: Scalars['String'];
  /** レコードの更新日時 */
  updated: Scalars['timestamptz'];
  /** app_user_activeテーブルのid、データアップロード実行ユーザ */
  upload_user_id: Scalars['uuid'];
  /** wide_orthoimgのid */
  wide_orthoimg_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Aggregate = {
  __typename?: 'nbs_wide_orthoimg_load_aggregate';
  aggregate?: Maybe<Nbs_Wide_Orthoimg_Load_Aggregate_Fields>;
  nodes: Array<Nbs_Wide_Orthoimg_Load>;
};

export type Nbs_Wide_Orthoimg_Load_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbs_Wide_Orthoimg_Load_Aggregate_Bool_Exp_Count>;
};

export type Nbs_Wide_Orthoimg_Load_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Aggregate_Fields = {
  __typename?: 'nbs_wide_orthoimg_load_aggregate_fields';
  avg?: Maybe<Nbs_Wide_Orthoimg_Load_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nbs_Wide_Orthoimg_Load_Max_Fields>;
  min?: Maybe<Nbs_Wide_Orthoimg_Load_Min_Fields>;
  stddev?: Maybe<Nbs_Wide_Orthoimg_Load_Stddev_Fields>;
  stddev_pop?: Maybe<Nbs_Wide_Orthoimg_Load_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nbs_Wide_Orthoimg_Load_Stddev_Samp_Fields>;
  sum?: Maybe<Nbs_Wide_Orthoimg_Load_Sum_Fields>;
  var_pop?: Maybe<Nbs_Wide_Orthoimg_Load_Var_Pop_Fields>;
  var_samp?: Maybe<Nbs_Wide_Orthoimg_Load_Var_Samp_Fields>;
  variance?: Maybe<Nbs_Wide_Orthoimg_Load_Variance_Fields>;
};


/** aggregate fields of "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Aggregate_Order_By = {
  avg?: InputMaybe<Nbs_Wide_Orthoimg_Load_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbs_Wide_Orthoimg_Load_Max_Order_By>;
  min?: InputMaybe<Nbs_Wide_Orthoimg_Load_Min_Order_By>;
  stddev?: InputMaybe<Nbs_Wide_Orthoimg_Load_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nbs_Wide_Orthoimg_Load_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nbs_Wide_Orthoimg_Load_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nbs_Wide_Orthoimg_Load_Sum_Order_By>;
  var_pop?: InputMaybe<Nbs_Wide_Orthoimg_Load_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nbs_Wide_Orthoimg_Load_Var_Samp_Order_By>;
  variance?: InputMaybe<Nbs_Wide_Orthoimg_Load_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Arr_Rel_Insert_Input = {
  data: Array<Nbs_Wide_Orthoimg_Load_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbs_Wide_Orthoimg_Load_On_Conflict>;
};

/** aggregate avg on columns */
export type Nbs_Wide_Orthoimg_Load_Avg_Fields = {
  __typename?: 'nbs_wide_orthoimg_load_avg_fields';
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Avg_Order_By = {
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nbs_wide_orthoimg_load". All fields are combined with a logical 'AND'. */
export type Nbs_Wide_Orthoimg_Load_Bool_Exp = {
  _and?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Bool_Exp>>;
  _not?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
  _or?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  divided_num?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  multipart_upload_id?: InputMaybe<Uuid_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Bool_Exp>;
  nbs_multipart_upload?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
  orthoimg_type?: InputMaybe<String_Comparison_Exp>;
  proc_datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  s3uri?: InputMaybe<String_Comparison_Exp>;
  sens_datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  wide_orthoimg_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbs_wide_orthoimg_load" */
export enum Nbs_Wide_Orthoimg_Load_Constraint {
  /** unique or primary key constraint on columns "multipart_upload_id" */
  NbsWideOrthoimgLoadMultipartUploadIdKey = 'nbs_wide_orthoimg_load_multipart_upload_id_key',
  /** unique or primary key constraint on columns "id" */
  NbsWideOrthoimgLoadPkey = 'nbs_wide_orthoimg_load_pkey',
  /** unique or primary key constraint on columns "s3uri" */
  NbsWideOrthoimgLoadS3uriKey = 'nbs_wide_orthoimg_load_s3uri_key'
}

/** input type for incrementing numeric columns in table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Inc_Input = {
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  /** nb_companyのid、データアップロードが実行された会社 */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** レコードの作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Scalars['Int']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  /** nbs_multipart_upload.id */
  multipart_upload_id?: InputMaybe<Scalars['uuid']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Obj_Rel_Insert_Input>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Obj_Rel_Insert_Input>;
  nbs_multipart_upload?: InputMaybe<Nbs_Multipart_Upload_Obj_Rel_Insert_Input>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Scalars['String']>;
  /** 分割処理実行時のタイムスタンプ */
  proc_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** 画像ファイルのS3 URI */
  s3uri?: InputMaybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** データファイルのロード状態 */
  status?: InputMaybe<Scalars['String']>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** app_user_activeテーブルのid、データアップロード実行ユーザ */
  upload_user_id?: InputMaybe<Scalars['uuid']>;
  /** wide_orthoimgのid */
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nbs_Wide_Orthoimg_Load_Max_Fields = {
  __typename?: 'nbs_wide_orthoimg_load_max_fields';
  /** nb_companyのid、データアップロードが実行された会社 */
  company_id?: Maybe<Scalars['uuid']>;
  /** レコードの作成日時 */
  created?: Maybe<Scalars['timestamptz']>;
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: Maybe<Scalars['Int']>;
  /** 一意な識別子 */
  id?: Maybe<Scalars['uuid']>;
  /** nbs_multipart_upload.id */
  multipart_upload_id?: Maybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: Maybe<Scalars['String']>;
  /** 分割処理実行時のタイムスタンプ */
  proc_datetime?: Maybe<Scalars['timestamptz']>;
  /** 画像ファイルのS3 URI */
  s3uri?: Maybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: Maybe<Scalars['timestamptz']>;
  /** データファイルのロード状態 */
  status?: Maybe<Scalars['String']>;
  /** レコードの更新日時 */
  updated?: Maybe<Scalars['timestamptz']>;
  /** app_user_activeテーブルのid、データアップロード実行ユーザ */
  upload_user_id?: Maybe<Scalars['uuid']>;
  /** wide_orthoimgのid */
  wide_orthoimg_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Max_Order_By = {
  /** nb_companyのid、データアップロードが実行された会社 */
  company_id?: InputMaybe<Order_By>;
  /** レコードの作成日時 */
  created?: InputMaybe<Order_By>;
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Order_By>;
  /** 一意な識別子 */
  id?: InputMaybe<Order_By>;
  /** nbs_multipart_upload.id */
  multipart_upload_id?: InputMaybe<Order_By>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Order_By>;
  /** 分割処理実行時のタイムスタンプ */
  proc_datetime?: InputMaybe<Order_By>;
  /** 画像ファイルのS3 URI */
  s3uri?: InputMaybe<Order_By>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Order_By>;
  /** データファイルのロード状態 */
  status?: InputMaybe<Order_By>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Order_By>;
  /** app_user_activeテーブルのid、データアップロード実行ユーザ */
  upload_user_id?: InputMaybe<Order_By>;
  /** wide_orthoimgのid */
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbs_Wide_Orthoimg_Load_Min_Fields = {
  __typename?: 'nbs_wide_orthoimg_load_min_fields';
  /** nb_companyのid、データアップロードが実行された会社 */
  company_id?: Maybe<Scalars['uuid']>;
  /** レコードの作成日時 */
  created?: Maybe<Scalars['timestamptz']>;
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: Maybe<Scalars['Int']>;
  /** 一意な識別子 */
  id?: Maybe<Scalars['uuid']>;
  /** nbs_multipart_upload.id */
  multipart_upload_id?: Maybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: Maybe<Scalars['String']>;
  /** 分割処理実行時のタイムスタンプ */
  proc_datetime?: Maybe<Scalars['timestamptz']>;
  /** 画像ファイルのS3 URI */
  s3uri?: Maybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: Maybe<Scalars['timestamptz']>;
  /** データファイルのロード状態 */
  status?: Maybe<Scalars['String']>;
  /** レコードの更新日時 */
  updated?: Maybe<Scalars['timestamptz']>;
  /** app_user_activeテーブルのid、データアップロード実行ユーザ */
  upload_user_id?: Maybe<Scalars['uuid']>;
  /** wide_orthoimgのid */
  wide_orthoimg_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Min_Order_By = {
  /** nb_companyのid、データアップロードが実行された会社 */
  company_id?: InputMaybe<Order_By>;
  /** レコードの作成日時 */
  created?: InputMaybe<Order_By>;
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Order_By>;
  /** 一意な識別子 */
  id?: InputMaybe<Order_By>;
  /** nbs_multipart_upload.id */
  multipart_upload_id?: InputMaybe<Order_By>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Order_By>;
  /** 分割処理実行時のタイムスタンプ */
  proc_datetime?: InputMaybe<Order_By>;
  /** 画像ファイルのS3 URI */
  s3uri?: InputMaybe<Order_By>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Order_By>;
  /** データファイルのロード状態 */
  status?: InputMaybe<Order_By>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Order_By>;
  /** app_user_activeテーブルのid、データアップロード実行ユーザ */
  upload_user_id?: InputMaybe<Order_By>;
  /** wide_orthoimgのid */
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Mutation_Response = {
  __typename?: 'nbs_wide_orthoimg_load_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbs_Wide_Orthoimg_Load>;
};

/** input type for inserting object relation for remote table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Obj_Rel_Insert_Input = {
  data: Nbs_Wide_Orthoimg_Load_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbs_Wide_Orthoimg_Load_On_Conflict>;
};

/** on_conflict condition type for table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_On_Conflict = {
  constraint: Nbs_Wide_Orthoimg_Load_Constraint;
  update_columns?: Array<Nbs_Wide_Orthoimg_Load_Update_Column>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};

/** Ordering options when selecting data from "nbs_wide_orthoimg_load". */
export type Nbs_Wide_Orthoimg_Load_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  divided_num?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  multipart_upload_id?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_wide_orthoimg?: InputMaybe<Nb_Wide_Orthoimg_Order_By>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Order_By>;
  nbs_multipart_upload?: InputMaybe<Nbs_Multipart_Upload_Order_By>;
  orthoimg_type?: InputMaybe<Order_By>;
  proc_datetime?: InputMaybe<Order_By>;
  s3uri?: InputMaybe<Order_By>;
  sens_datetime?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  upload_user_id?: InputMaybe<Order_By>;
  wide_orthoimg_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbs_wide_orthoimg_load */
export type Nbs_Wide_Orthoimg_Load_Pk_Columns_Input = {
  /** 一意な識別子 */
  id: Scalars['uuid'];
};

/** select columns of table "nbs_wide_orthoimg_load" */
export enum Nbs_Wide_Orthoimg_Load_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  DividedNum = 'divided_num',
  /** column name */
  Id = 'id',
  /** column name */
  MultipartUploadId = 'multipart_upload_id',
  /** column name */
  OrthoimgType = 'orthoimg_type',
  /** column name */
  ProcDatetime = 'proc_datetime',
  /** column name */
  S3uri = 's3uri',
  /** column name */
  SensDatetime = 'sens_datetime',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated',
  /** column name */
  UploadUserId = 'upload_user_id',
  /** column name */
  WideOrthoimgId = 'wide_orthoimg_id'
}

/** input type for updating data in table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Set_Input = {
  /** nb_companyのid、データアップロードが実行された会社 */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** レコードの作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Scalars['Int']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  /** nbs_multipart_upload.id */
  multipart_upload_id?: InputMaybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Scalars['String']>;
  /** 分割処理実行時のタイムスタンプ */
  proc_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** 画像ファイルのS3 URI */
  s3uri?: InputMaybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** データファイルのロード状態 */
  status?: InputMaybe<Scalars['String']>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** app_user_activeテーブルのid、データアップロード実行ユーザ */
  upload_user_id?: InputMaybe<Scalars['uuid']>;
  /** wide_orthoimgのid */
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Nbs_Wide_Orthoimg_Load_Stddev_Fields = {
  __typename?: 'nbs_wide_orthoimg_load_stddev_fields';
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Stddev_Order_By = {
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nbs_Wide_Orthoimg_Load_Stddev_Pop_Fields = {
  __typename?: 'nbs_wide_orthoimg_load_stddev_pop_fields';
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Stddev_Pop_Order_By = {
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nbs_Wide_Orthoimg_Load_Stddev_Samp_Fields = {
  __typename?: 'nbs_wide_orthoimg_load_stddev_samp_fields';
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Stddev_Samp_Order_By = {
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbs_Wide_Orthoimg_Load_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbs_Wide_Orthoimg_Load_Stream_Cursor_Value_Input = {
  /** nb_companyのid、データアップロードが実行された会社 */
  company_id?: InputMaybe<Scalars['uuid']>;
  /** レコードの作成日時 */
  created?: InputMaybe<Scalars['timestamptz']>;
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Scalars['Int']>;
  /** 一意な識別子 */
  id?: InputMaybe<Scalars['uuid']>;
  /** nbs_multipart_upload.id */
  multipart_upload_id?: InputMaybe<Scalars['uuid']>;
  /** オルソ画像の種別 */
  orthoimg_type?: InputMaybe<Scalars['String']>;
  /** 分割処理実行時のタイムスタンプ */
  proc_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** 画像ファイルのS3 URI */
  s3uri?: InputMaybe<Scalars['String']>;
  /** オルソ画像を撮影した(センシングした)日時 */
  sens_datetime?: InputMaybe<Scalars['timestamptz']>;
  /** データファイルのロード状態 */
  status?: InputMaybe<Scalars['String']>;
  /** レコードの更新日時 */
  updated?: InputMaybe<Scalars['timestamptz']>;
  /** app_user_activeテーブルのid、データアップロード実行ユーザ */
  upload_user_id?: InputMaybe<Scalars['uuid']>;
  /** wide_orthoimgのid */
  wide_orthoimg_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Nbs_Wide_Orthoimg_Load_Sum_Fields = {
  __typename?: 'nbs_wide_orthoimg_load_sum_fields';
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Sum_Order_By = {
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Order_By>;
};

/** update columns of table "nbs_wide_orthoimg_load" */
export enum Nbs_Wide_Orthoimg_Load_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  DividedNum = 'divided_num',
  /** column name */
  Id = 'id',
  /** column name */
  MultipartUploadId = 'multipart_upload_id',
  /** column name */
  OrthoimgType = 'orthoimg_type',
  /** column name */
  ProcDatetime = 'proc_datetime',
  /** column name */
  S3uri = 's3uri',
  /** column name */
  SensDatetime = 'sens_datetime',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated',
  /** column name */
  UploadUserId = 'upload_user_id',
  /** column name */
  WideOrthoimgId = 'wide_orthoimg_id'
}

export type Nbs_Wide_Orthoimg_Load_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nbs_Wide_Orthoimg_Load_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbs_Wide_Orthoimg_Load_Set_Input>;
  where: Nbs_Wide_Orthoimg_Load_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nbs_Wide_Orthoimg_Load_Var_Pop_Fields = {
  __typename?: 'nbs_wide_orthoimg_load_var_pop_fields';
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Var_Pop_Order_By = {
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nbs_Wide_Orthoimg_Load_Var_Samp_Fields = {
  __typename?: 'nbs_wide_orthoimg_load_var_samp_fields';
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Var_Samp_Order_By = {
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nbs_Wide_Orthoimg_Load_Variance_Fields = {
  __typename?: 'nbs_wide_orthoimg_load_variance_fields';
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nbs_wide_orthoimg_load" */
export type Nbs_Wide_Orthoimg_Load_Variance_Order_By = {
  /** 分割によって生成された圃場単位のファイル数 */
  divided_num?: InputMaybe<Order_By>;
};

/** Trackerアプリで収集したユーザ位置情報のコンテキスト情報 */
export type Nbtrk_Context = {
  __typename?: 'nbtrk_context';
  /** An object relationship */
  app_user_active: App_User_Active;
  app_ver: Scalars['String'];
  code_hash?: Maybe<Scalars['String']>;
  company_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  memo: Scalars['String'];
  /** An object relationship */
  nb_company: Nb_Company;
  /** An array relationship */
  nb_finished_ufield_works: Array<Nb_Finished_Ufield_Work>;
  /** An aggregate relationship */
  nb_finished_ufield_works_aggregate: Nb_Finished_Ufield_Work_Aggregate;
  /** An array relationship */
  nb_performed_works: Array<Nb_Performed_Work>;
  /** An aggregate relationship */
  nb_performed_works_aggregate: Nb_Performed_Work_Aggregate;
  /** An object relationship */
  nb_task_type: Nb_Task_Type;
  /** An array relationship */
  nbtrk_eph_locations: Array<Nbtrk_Eph_Location>;
  /** An aggregate relationship */
  nbtrk_eph_locations_aggregate: Nbtrk_Eph_Location_Aggregate;
  task_type: Scalars['String'];
  user_id: Scalars['uuid'];
};


/** Trackerアプリで収集したユーザ位置情報のコンテキスト情報 */
export type Nbtrk_ContextNb_Finished_Ufield_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


/** Trackerアプリで収集したユーザ位置情報のコンテキスト情報 */
export type Nbtrk_ContextNb_Finished_Ufield_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


/** Trackerアプリで収集したユーザ位置情報のコンテキスト情報 */
export type Nbtrk_ContextNb_Performed_WorksArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** Trackerアプリで収集したユーザ位置情報のコンテキスト情報 */
export type Nbtrk_ContextNb_Performed_Works_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


/** Trackerアプリで収集したユーザ位置情報のコンテキスト情報 */
export type Nbtrk_ContextNbtrk_Eph_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Eph_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Eph_Location_Order_By>>;
  where?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
};


/** Trackerアプリで収集したユーザ位置情報のコンテキスト情報 */
export type Nbtrk_ContextNbtrk_Eph_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Eph_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Eph_Location_Order_By>>;
  where?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
};

/** aggregated selection of "nbtrk_context" */
export type Nbtrk_Context_Aggregate = {
  __typename?: 'nbtrk_context_aggregate';
  aggregate?: Maybe<Nbtrk_Context_Aggregate_Fields>;
  nodes: Array<Nbtrk_Context>;
};

export type Nbtrk_Context_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nbtrk_Context_Aggregate_Bool_Exp_Count>;
};

export type Nbtrk_Context_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbtrk_Context_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nbtrk_context" */
export type Nbtrk_Context_Aggregate_Fields = {
  __typename?: 'nbtrk_context_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nbtrk_Context_Max_Fields>;
  min?: Maybe<Nbtrk_Context_Min_Fields>;
};


/** aggregate fields of "nbtrk_context" */
export type Nbtrk_Context_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbtrk_context" */
export type Nbtrk_Context_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbtrk_Context_Max_Order_By>;
  min?: InputMaybe<Nbtrk_Context_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nbtrk_context" */
export type Nbtrk_Context_Arr_Rel_Insert_Input = {
  data: Array<Nbtrk_Context_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbtrk_Context_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nbtrk_context". All fields are combined with a logical 'AND'. */
export type Nbtrk_Context_Bool_Exp = {
  _and?: InputMaybe<Array<Nbtrk_Context_Bool_Exp>>;
  _not?: InputMaybe<Nbtrk_Context_Bool_Exp>;
  _or?: InputMaybe<Array<Nbtrk_Context_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  app_ver?: InputMaybe<String_Comparison_Exp>;
  code_hash?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  memo?: InputMaybe<String_Comparison_Exp>;
  nb_company?: InputMaybe<Nb_Company_Bool_Exp>;
  nb_finished_ufield_works?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
  nb_finished_ufield_works_aggregate?: InputMaybe<Nb_Finished_Ufield_Work_Aggregate_Bool_Exp>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Bool_Exp>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Bool_Exp>;
  nbtrk_eph_locations?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
  nbtrk_eph_locations_aggregate?: InputMaybe<Nbtrk_Eph_Location_Aggregate_Bool_Exp>;
  task_type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbtrk_context" */
export enum Nbtrk_Context_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbtrkContextPkey = 'nbtrk_context_pkey'
}

/** input type for inserting data into table "nbtrk_context" */
export type Nbtrk_Context_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  app_ver?: InputMaybe<Scalars['String']>;
  code_hash?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  nb_company?: InputMaybe<Nb_Company_Obj_Rel_Insert_Input>;
  nb_finished_ufield_works?: InputMaybe<Nb_Finished_Ufield_Work_Arr_Rel_Insert_Input>;
  nb_performed_works?: InputMaybe<Nb_Performed_Work_Arr_Rel_Insert_Input>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Obj_Rel_Insert_Input>;
  nbtrk_eph_locations?: InputMaybe<Nbtrk_Eph_Location_Arr_Rel_Insert_Input>;
  task_type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Nbtrk_Context_Max_Fields = {
  __typename?: 'nbtrk_context_max_fields';
  app_ver?: Maybe<Scalars['String']>;
  code_hash?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memo?: Maybe<Scalars['String']>;
  task_type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "nbtrk_context" */
export type Nbtrk_Context_Max_Order_By = {
  app_ver?: InputMaybe<Order_By>;
  code_hash?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  memo?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbtrk_Context_Min_Fields = {
  __typename?: 'nbtrk_context_min_fields';
  app_ver?: Maybe<Scalars['String']>;
  code_hash?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  memo?: Maybe<Scalars['String']>;
  task_type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "nbtrk_context" */
export type Nbtrk_Context_Min_Order_By = {
  app_ver?: InputMaybe<Order_By>;
  code_hash?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  memo?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbtrk_context" */
export type Nbtrk_Context_Mutation_Response = {
  __typename?: 'nbtrk_context_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbtrk_Context>;
};

/** input type for inserting object relation for remote table "nbtrk_context" */
export type Nbtrk_Context_Obj_Rel_Insert_Input = {
  data: Nbtrk_Context_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbtrk_Context_On_Conflict>;
};

/** on_conflict condition type for table "nbtrk_context" */
export type Nbtrk_Context_On_Conflict = {
  constraint: Nbtrk_Context_Constraint;
  update_columns?: Array<Nbtrk_Context_Update_Column>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};

/** Ordering options when selecting data from "nbtrk_context". */
export type Nbtrk_Context_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  app_ver?: InputMaybe<Order_By>;
  code_hash?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  memo?: InputMaybe<Order_By>;
  nb_company?: InputMaybe<Nb_Company_Order_By>;
  nb_finished_ufield_works_aggregate?: InputMaybe<Nb_Finished_Ufield_Work_Aggregate_Order_By>;
  nb_performed_works_aggregate?: InputMaybe<Nb_Performed_Work_Aggregate_Order_By>;
  nb_task_type?: InputMaybe<Nb_Task_Type_Order_By>;
  nbtrk_eph_locations_aggregate?: InputMaybe<Nbtrk_Eph_Location_Aggregate_Order_By>;
  task_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbtrk_context */
export type Nbtrk_Context_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbtrk_context" */
export enum Nbtrk_Context_Select_Column {
  /** column name */
  AppVer = 'app_ver',
  /** column name */
  CodeHash = 'code_hash',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Memo = 'memo',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "nbtrk_context" */
export type Nbtrk_Context_Set_Input = {
  app_ver?: InputMaybe<Scalars['String']>;
  code_hash?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  task_type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "nbtrk_context" */
export type Nbtrk_Context_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbtrk_Context_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbtrk_Context_Stream_Cursor_Value_Input = {
  app_ver?: InputMaybe<Scalars['String']>;
  code_hash?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  task_type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "nbtrk_context" */
export enum Nbtrk_Context_Update_Column {
  /** column name */
  AppVer = 'app_ver',
  /** column name */
  CodeHash = 'code_hash',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Memo = 'memo',
  /** column name */
  TaskType = 'task_type',
  /** column name */
  UserId = 'user_id'
}

export type Nbtrk_Context_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbtrk_Context_Set_Input>;
  where: Nbtrk_Context_Bool_Exp;
};

/** ユーザの一時情報を格納する。TTLを設定し定期的に削除する想定。一時的であるためテーブル名にeph_をつけている。 */
export type Nbtrk_Eph_Location = {
  __typename?: 'nbtrk_eph_location';
  context_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  loc_time: Scalars['timestamptz'];
  /** An object relationship */
  nbtrk_context: Nbtrk_Context;
  point_lat: Scalars['float8'];
  point_lng: Scalars['float8'];
};

/** aggregated selection of "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Aggregate = {
  __typename?: 'nbtrk_eph_location_aggregate';
  aggregate?: Maybe<Nbtrk_Eph_Location_Aggregate_Fields>;
  nodes: Array<Nbtrk_Eph_Location>;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Nbtrk_Eph_Location_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Nbtrk_Eph_Location_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Nbtrk_Eph_Location_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Nbtrk_Eph_Location_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Nbtrk_Eph_Location_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Nbtrk_Eph_Location_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Nbtrk_Eph_Location_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Nbtrk_Eph_Location_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Nbtrk_Eph_Location_Aggregate_Bool_Exp_Var_Samp>;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp_Avg = {
  arguments: Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp_Corr = {
  arguments: Nbtrk_Eph_Location_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nbtrk_Eph_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Nbtrk_Eph_Location_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp_Max = {
  arguments: Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp_Min = {
  arguments: Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp_Sum = {
  arguments: Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Nbtrk_Eph_Location_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Aggregate_Fields = {
  __typename?: 'nbtrk_eph_location_aggregate_fields';
  avg?: Maybe<Nbtrk_Eph_Location_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Nbtrk_Eph_Location_Max_Fields>;
  min?: Maybe<Nbtrk_Eph_Location_Min_Fields>;
  stddev?: Maybe<Nbtrk_Eph_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Nbtrk_Eph_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nbtrk_Eph_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Nbtrk_Eph_Location_Sum_Fields>;
  var_pop?: Maybe<Nbtrk_Eph_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Nbtrk_Eph_Location_Var_Samp_Fields>;
  variance?: Maybe<Nbtrk_Eph_Location_Variance_Fields>;
};


/** aggregate fields of "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nbtrk_Eph_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Nbtrk_Eph_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nbtrk_Eph_Location_Max_Order_By>;
  min?: InputMaybe<Nbtrk_Eph_Location_Min_Order_By>;
  stddev?: InputMaybe<Nbtrk_Eph_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nbtrk_Eph_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nbtrk_Eph_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nbtrk_Eph_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Nbtrk_Eph_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nbtrk_Eph_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Nbtrk_Eph_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Arr_Rel_Insert_Input = {
  data: Array<Nbtrk_Eph_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nbtrk_Eph_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Nbtrk_Eph_Location_Avg_Fields = {
  __typename?: 'nbtrk_eph_location_avg_fields';
  point_lat?: Maybe<Scalars['Float']>;
  point_lng?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Avg_Order_By = {
  point_lat?: InputMaybe<Order_By>;
  point_lng?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nbtrk_eph_location". All fields are combined with a logical 'AND'. */
export type Nbtrk_Eph_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Nbtrk_Eph_Location_Bool_Exp>>;
  _not?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Nbtrk_Eph_Location_Bool_Exp>>;
  context_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  loc_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  nbtrk_context?: InputMaybe<Nbtrk_Context_Bool_Exp>;
  point_lat?: InputMaybe<Float8_Comparison_Exp>;
  point_lng?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "nbtrk_eph_location" */
export enum Nbtrk_Eph_Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  NbtrkEphLocationPkey = 'nbtrk_eph_location_pkey'
}

/** input type for incrementing numeric columns in table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Inc_Input = {
  point_lat?: InputMaybe<Scalars['float8']>;
  point_lng?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Insert_Input = {
  context_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  loc_time?: InputMaybe<Scalars['timestamptz']>;
  nbtrk_context?: InputMaybe<Nbtrk_Context_Obj_Rel_Insert_Input>;
  point_lat?: InputMaybe<Scalars['float8']>;
  point_lng?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Nbtrk_Eph_Location_Max_Fields = {
  __typename?: 'nbtrk_eph_location_max_fields';
  context_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  loc_time?: Maybe<Scalars['timestamptz']>;
  point_lat?: Maybe<Scalars['float8']>;
  point_lng?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Max_Order_By = {
  context_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loc_time?: InputMaybe<Order_By>;
  point_lat?: InputMaybe<Order_By>;
  point_lng?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nbtrk_Eph_Location_Min_Fields = {
  __typename?: 'nbtrk_eph_location_min_fields';
  context_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  loc_time?: Maybe<Scalars['timestamptz']>;
  point_lat?: Maybe<Scalars['float8']>;
  point_lng?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Min_Order_By = {
  context_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loc_time?: InputMaybe<Order_By>;
  point_lat?: InputMaybe<Order_By>;
  point_lng?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Mutation_Response = {
  __typename?: 'nbtrk_eph_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nbtrk_Eph_Location>;
};

/** on_conflict condition type for table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_On_Conflict = {
  constraint: Nbtrk_Eph_Location_Constraint;
  update_columns?: Array<Nbtrk_Eph_Location_Update_Column>;
  where?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "nbtrk_eph_location". */
export type Nbtrk_Eph_Location_Order_By = {
  context_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loc_time?: InputMaybe<Order_By>;
  nbtrk_context?: InputMaybe<Nbtrk_Context_Order_By>;
  point_lat?: InputMaybe<Order_By>;
  point_lng?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nbtrk_eph_location */
export type Nbtrk_Eph_Location_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nbtrk_eph_location" */
export enum Nbtrk_Eph_Location_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  LocTime = 'loc_time',
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLng = 'point_lng'
}

/** select "nbtrk_eph_location_aggregate_bool_exp_avg_arguments_columns" columns of table "nbtrk_eph_location" */
export enum Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLng = 'point_lng'
}

/** select "nbtrk_eph_location_aggregate_bool_exp_corr_arguments_columns" columns of table "nbtrk_eph_location" */
export enum Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLng = 'point_lng'
}

/** select "nbtrk_eph_location_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "nbtrk_eph_location" */
export enum Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLng = 'point_lng'
}

/** select "nbtrk_eph_location_aggregate_bool_exp_max_arguments_columns" columns of table "nbtrk_eph_location" */
export enum Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLng = 'point_lng'
}

/** select "nbtrk_eph_location_aggregate_bool_exp_min_arguments_columns" columns of table "nbtrk_eph_location" */
export enum Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLng = 'point_lng'
}

/** select "nbtrk_eph_location_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "nbtrk_eph_location" */
export enum Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLng = 'point_lng'
}

/** select "nbtrk_eph_location_aggregate_bool_exp_sum_arguments_columns" columns of table "nbtrk_eph_location" */
export enum Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLng = 'point_lng'
}

/** select "nbtrk_eph_location_aggregate_bool_exp_var_samp_arguments_columns" columns of table "nbtrk_eph_location" */
export enum Nbtrk_Eph_Location_Select_Column_Nbtrk_Eph_Location_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLng = 'point_lng'
}

/** input type for updating data in table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Set_Input = {
  context_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  loc_time?: InputMaybe<Scalars['timestamptz']>;
  point_lat?: InputMaybe<Scalars['float8']>;
  point_lng?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Nbtrk_Eph_Location_Stddev_Fields = {
  __typename?: 'nbtrk_eph_location_stddev_fields';
  point_lat?: Maybe<Scalars['Float']>;
  point_lng?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Stddev_Order_By = {
  point_lat?: InputMaybe<Order_By>;
  point_lng?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nbtrk_Eph_Location_Stddev_Pop_Fields = {
  __typename?: 'nbtrk_eph_location_stddev_pop_fields';
  point_lat?: Maybe<Scalars['Float']>;
  point_lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Stddev_Pop_Order_By = {
  point_lat?: InputMaybe<Order_By>;
  point_lng?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nbtrk_Eph_Location_Stddev_Samp_Fields = {
  __typename?: 'nbtrk_eph_location_stddev_samp_fields';
  point_lat?: Maybe<Scalars['Float']>;
  point_lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Stddev_Samp_Order_By = {
  point_lat?: InputMaybe<Order_By>;
  point_lng?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nbtrk_Eph_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nbtrk_Eph_Location_Stream_Cursor_Value_Input = {
  context_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  loc_time?: InputMaybe<Scalars['timestamptz']>;
  point_lat?: InputMaybe<Scalars['float8']>;
  point_lng?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Nbtrk_Eph_Location_Sum_Fields = {
  __typename?: 'nbtrk_eph_location_sum_fields';
  point_lat?: Maybe<Scalars['float8']>;
  point_lng?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Sum_Order_By = {
  point_lat?: InputMaybe<Order_By>;
  point_lng?: InputMaybe<Order_By>;
};

/** update columns of table "nbtrk_eph_location" */
export enum Nbtrk_Eph_Location_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  LocTime = 'loc_time',
  /** column name */
  PointLat = 'point_lat',
  /** column name */
  PointLng = 'point_lng'
}

export type Nbtrk_Eph_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nbtrk_Eph_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nbtrk_Eph_Location_Set_Input>;
  where: Nbtrk_Eph_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nbtrk_Eph_Location_Var_Pop_Fields = {
  __typename?: 'nbtrk_eph_location_var_pop_fields';
  point_lat?: Maybe<Scalars['Float']>;
  point_lng?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Var_Pop_Order_By = {
  point_lat?: InputMaybe<Order_By>;
  point_lng?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nbtrk_Eph_Location_Var_Samp_Fields = {
  __typename?: 'nbtrk_eph_location_var_samp_fields';
  point_lat?: Maybe<Scalars['Float']>;
  point_lng?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Var_Samp_Order_By = {
  point_lat?: InputMaybe<Order_By>;
  point_lng?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nbtrk_Eph_Location_Variance_Fields = {
  __typename?: 'nbtrk_eph_location_variance_fields';
  point_lat?: Maybe<Scalars['Float']>;
  point_lng?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "nbtrk_eph_location" */
export type Nbtrk_Eph_Location_Variance_Order_By = {
  point_lat?: InputMaybe<Order_By>;
  point_lng?: InputMaybe<Order_By>;
};

/** 画像処理エンジン定義テーブル */
export type Nipe_Engine_Information = {
  __typename?: 'nipe_engine_information';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  image_sensing_attr_id: Scalars['uuid'];
  information: Scalars['json'];
  name: Scalars['String'];
  /** An object relationship */
  nb_image_sensing_attr: Nb_Image_Sensing_Attr;
  /** An array relationship */
  nbpm_ipengine_authorization_links: Array<Nbpm_Ipengine_Authorization_Link>;
  /** An aggregate relationship */
  nbpm_ipengine_authorization_links_aggregate: Nbpm_Ipengine_Authorization_Link_Aggregate;
  /** An array relationship */
  nbs_ipe_run_wides: Array<Nbs_Ipe_Run_Wide>;
  /** An aggregate relationship */
  nbs_ipe_run_wides_aggregate: Nbs_Ipe_Run_Wide_Aggregate;
  /** An array relationship */
  nipe_engine_link_orthoimg_types: Array<Nipe_Engine_Link_Orthoimg_Type>;
  /** An aggregate relationship */
  nipe_engine_link_orthoimg_types_aggregate: Nipe_Engine_Link_Orthoimg_Type_Aggregate;
  updated: Scalars['timestamptz'];
};


/** 画像処理エンジン定義テーブル */
export type Nipe_Engine_InformationInformationArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** 画像処理エンジン定義テーブル */
export type Nipe_Engine_InformationNbpm_Ipengine_Authorization_LinksArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
};


/** 画像処理エンジン定義テーブル */
export type Nipe_Engine_InformationNbpm_Ipengine_Authorization_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
};


/** 画像処理エンジン定義テーブル */
export type Nipe_Engine_InformationNbs_Ipe_Run_WidesArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};


/** 画像処理エンジン定義テーブル */
export type Nipe_Engine_InformationNbs_Ipe_Run_Wides_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};


/** 画像処理エンジン定義テーブル */
export type Nipe_Engine_InformationNipe_Engine_Link_Orthoimg_TypesArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
};


/** 画像処理エンジン定義テーブル */
export type Nipe_Engine_InformationNipe_Engine_Link_Orthoimg_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
};

/** aggregated selection of "nipe_engine_information" */
export type Nipe_Engine_Information_Aggregate = {
  __typename?: 'nipe_engine_information_aggregate';
  aggregate?: Maybe<Nipe_Engine_Information_Aggregate_Fields>;
  nodes: Array<Nipe_Engine_Information>;
};

export type Nipe_Engine_Information_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nipe_Engine_Information_Aggregate_Bool_Exp_Count>;
};

export type Nipe_Engine_Information_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nipe_Engine_Information_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nipe_engine_information" */
export type Nipe_Engine_Information_Aggregate_Fields = {
  __typename?: 'nipe_engine_information_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nipe_Engine_Information_Max_Fields>;
  min?: Maybe<Nipe_Engine_Information_Min_Fields>;
};


/** aggregate fields of "nipe_engine_information" */
export type Nipe_Engine_Information_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nipe_Engine_Information_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nipe_engine_information" */
export type Nipe_Engine_Information_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nipe_Engine_Information_Max_Order_By>;
  min?: InputMaybe<Nipe_Engine_Information_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nipe_engine_information" */
export type Nipe_Engine_Information_Arr_Rel_Insert_Input = {
  data: Array<Nipe_Engine_Information_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nipe_Engine_Information_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nipe_engine_information". All fields are combined with a logical 'AND'. */
export type Nipe_Engine_Information_Bool_Exp = {
  _and?: InputMaybe<Array<Nipe_Engine_Information_Bool_Exp>>;
  _not?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
  _or?: InputMaybe<Array<Nipe_Engine_Information_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image_sensing_attr_id?: InputMaybe<Uuid_Comparison_Exp>;
  information?: InputMaybe<Json_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_image_sensing_attr?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
  nbpm_ipengine_authorization_links?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
  nbpm_ipengine_authorization_links_aggregate?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Aggregate_Bool_Exp>;
  nbs_ipe_run_wides?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
  nbs_ipe_run_wides_aggregate?: InputMaybe<Nbs_Ipe_Run_Wide_Aggregate_Bool_Exp>;
  nipe_engine_link_orthoimg_types?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
  nipe_engine_link_orthoimg_types_aggregate?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Aggregate_Bool_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nipe_engine_information" */
export enum Nipe_Engine_Information_Constraint {
  /** unique or primary key constraint on columns "name" */
  NipeEngineInformationNameKey = 'nipe_engine_information_name_key',
  /** unique or primary key constraint on columns "id" */
  NipeEngineInformationPkey = 'nipe_engine_information_pkey'
}

/** input type for inserting data into table "nipe_engine_information" */
export type Nipe_Engine_Information_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_sensing_attr_id?: InputMaybe<Scalars['uuid']>;
  information?: InputMaybe<Scalars['json']>;
  name?: InputMaybe<Scalars['String']>;
  nb_image_sensing_attr?: InputMaybe<Nb_Image_Sensing_Attr_Obj_Rel_Insert_Input>;
  nbpm_ipengine_authorization_links?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Arr_Rel_Insert_Input>;
  nbs_ipe_run_wides?: InputMaybe<Nbs_Ipe_Run_Wide_Arr_Rel_Insert_Input>;
  nipe_engine_link_orthoimg_types?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Arr_Rel_Insert_Input>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nipe_Engine_Information_Max_Fields = {
  __typename?: 'nipe_engine_information_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image_sensing_attr_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nipe_engine_information" */
export type Nipe_Engine_Information_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_sensing_attr_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nipe_Engine_Information_Min_Fields = {
  __typename?: 'nipe_engine_information_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  image_sensing_attr_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nipe_engine_information" */
export type Nipe_Engine_Information_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_sensing_attr_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nipe_engine_information" */
export type Nipe_Engine_Information_Mutation_Response = {
  __typename?: 'nipe_engine_information_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nipe_Engine_Information>;
};

/** input type for inserting object relation for remote table "nipe_engine_information" */
export type Nipe_Engine_Information_Obj_Rel_Insert_Input = {
  data: Nipe_Engine_Information_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nipe_Engine_Information_On_Conflict>;
};

/** on_conflict condition type for table "nipe_engine_information" */
export type Nipe_Engine_Information_On_Conflict = {
  constraint: Nipe_Engine_Information_Constraint;
  update_columns?: Array<Nipe_Engine_Information_Update_Column>;
  where?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
};

/** Ordering options when selecting data from "nipe_engine_information". */
export type Nipe_Engine_Information_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_sensing_attr_id?: InputMaybe<Order_By>;
  information?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_image_sensing_attr?: InputMaybe<Nb_Image_Sensing_Attr_Order_By>;
  nbpm_ipengine_authorization_links_aggregate?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Aggregate_Order_By>;
  nbs_ipe_run_wides_aggregate?: InputMaybe<Nbs_Ipe_Run_Wide_Aggregate_Order_By>;
  nipe_engine_link_orthoimg_types_aggregate?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Aggregate_Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nipe_engine_information */
export type Nipe_Engine_Information_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nipe_engine_information" */
export enum Nipe_Engine_Information_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  ImageSensingAttrId = 'image_sensing_attr_id',
  /** column name */
  Information = 'information',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nipe_engine_information" */
export type Nipe_Engine_Information_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_sensing_attr_id?: InputMaybe<Scalars['uuid']>;
  information?: InputMaybe<Scalars['json']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nipe_engine_information" */
export type Nipe_Engine_Information_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nipe_Engine_Information_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nipe_Engine_Information_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_sensing_attr_id?: InputMaybe<Scalars['uuid']>;
  information?: InputMaybe<Scalars['json']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nipe_engine_information" */
export enum Nipe_Engine_Information_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  ImageSensingAttrId = 'image_sensing_attr_id',
  /** column name */
  Information = 'information',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

export type Nipe_Engine_Information_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nipe_Engine_Information_Set_Input>;
  where: Nipe_Engine_Information_Bool_Exp;
};

/** columns and relationships of "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type = {
  __typename?: 'nipe_engine_link_orthoimg_type';
  created: Scalars['timestamptz'];
  engine_information_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  nbbe_orthoimg_type: Nbbe_Orthoimg_Type;
  /** An object relationship */
  nipe_engine_information: Nipe_Engine_Information;
  orthoimg_type: Scalars['String'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_Aggregate = {
  __typename?: 'nipe_engine_link_orthoimg_type_aggregate';
  aggregate?: Maybe<Nipe_Engine_Link_Orthoimg_Type_Aggregate_Fields>;
  nodes: Array<Nipe_Engine_Link_Orthoimg_Type>;
};

export type Nipe_Engine_Link_Orthoimg_Type_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Aggregate_Bool_Exp_Count>;
};

export type Nipe_Engine_Link_Orthoimg_Type_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_Aggregate_Fields = {
  __typename?: 'nipe_engine_link_orthoimg_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nipe_Engine_Link_Orthoimg_Type_Max_Fields>;
  min?: Maybe<Nipe_Engine_Link_Orthoimg_Type_Min_Fields>;
};


/** aggregate fields of "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Max_Order_By>;
  min?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_Arr_Rel_Insert_Input = {
  data: Array<Nipe_Engine_Link_Orthoimg_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nipe_engine_link_orthoimg_type". All fields are combined with a logical 'AND'. */
export type Nipe_Engine_Link_Orthoimg_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>>;
  _not?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  engine_information_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Bool_Exp>;
  nipe_engine_information?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
  orthoimg_type?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nipe_engine_link_orthoimg_type" */
export enum Nipe_Engine_Link_Orthoimg_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  NipeEngineLinkOrthoimgTypePkey = 'nipe_engine_link_orthoimg_type_pkey'
}

/** input type for inserting data into table "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  engine_information_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Obj_Rel_Insert_Input>;
  nipe_engine_information?: InputMaybe<Nipe_Engine_Information_Obj_Rel_Insert_Input>;
  orthoimg_type?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nipe_Engine_Link_Orthoimg_Type_Max_Fields = {
  __typename?: 'nipe_engine_link_orthoimg_type_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  engine_information_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  orthoimg_type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  engine_information_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orthoimg_type?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nipe_Engine_Link_Orthoimg_Type_Min_Fields = {
  __typename?: 'nipe_engine_link_orthoimg_type_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  engine_information_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  orthoimg_type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  engine_information_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orthoimg_type?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_Mutation_Response = {
  __typename?: 'nipe_engine_link_orthoimg_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nipe_Engine_Link_Orthoimg_Type>;
};

/** on_conflict condition type for table "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_On_Conflict = {
  constraint: Nipe_Engine_Link_Orthoimg_Type_Constraint;
  update_columns?: Array<Nipe_Engine_Link_Orthoimg_Type_Update_Column>;
  where?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "nipe_engine_link_orthoimg_type". */
export type Nipe_Engine_Link_Orthoimg_Type_Order_By = {
  created?: InputMaybe<Order_By>;
  engine_information_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nbbe_orthoimg_type?: InputMaybe<Nbbe_Orthoimg_Type_Order_By>;
  nipe_engine_information?: InputMaybe<Nipe_Engine_Information_Order_By>;
  orthoimg_type?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nipe_engine_link_orthoimg_type */
export type Nipe_Engine_Link_Orthoimg_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nipe_engine_link_orthoimg_type" */
export enum Nipe_Engine_Link_Orthoimg_Type_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  EngineInformationId = 'engine_information_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrthoimgType = 'orthoimg_type',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  engine_information_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  orthoimg_type?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nipe_engine_link_orthoimg_type" */
export type Nipe_Engine_Link_Orthoimg_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nipe_Engine_Link_Orthoimg_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nipe_Engine_Link_Orthoimg_Type_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  engine_information_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  orthoimg_type?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nipe_engine_link_orthoimg_type" */
export enum Nipe_Engine_Link_Orthoimg_Type_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  EngineInformationId = 'engine_information_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrthoimgType = 'orthoimg_type',
  /** column name */
  Updated = 'updated'
}

export type Nipe_Engine_Link_Orthoimg_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Set_Input>;
  where: Nipe_Engine_Link_Orthoimg_Type_Bool_Exp;
};

/** 画像処理エンジンの実行ステータステーブル */
export type Nipe_Engine_Run_Status = {
  __typename?: 'nipe_engine_run_status';
  /** An array relationship */
  nipe_run_commands: Array<Nipe_Run_Command>;
  /** An aggregate relationship */
  nipe_run_commands_aggregate: Nipe_Run_Command_Aggregate;
  status: Scalars['String'];
};


/** 画像処理エンジンの実行ステータステーブル */
export type Nipe_Engine_Run_StatusNipe_Run_CommandsArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Run_Command_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Run_Command_Order_By>>;
  where?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
};


/** 画像処理エンジンの実行ステータステーブル */
export type Nipe_Engine_Run_StatusNipe_Run_Commands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Run_Command_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Run_Command_Order_By>>;
  where?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
};

/** aggregated selection of "nipe_engine_run_status" */
export type Nipe_Engine_Run_Status_Aggregate = {
  __typename?: 'nipe_engine_run_status_aggregate';
  aggregate?: Maybe<Nipe_Engine_Run_Status_Aggregate_Fields>;
  nodes: Array<Nipe_Engine_Run_Status>;
};

/** aggregate fields of "nipe_engine_run_status" */
export type Nipe_Engine_Run_Status_Aggregate_Fields = {
  __typename?: 'nipe_engine_run_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nipe_Engine_Run_Status_Max_Fields>;
  min?: Maybe<Nipe_Engine_Run_Status_Min_Fields>;
};


/** aggregate fields of "nipe_engine_run_status" */
export type Nipe_Engine_Run_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nipe_Engine_Run_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nipe_engine_run_status". All fields are combined with a logical 'AND'. */
export type Nipe_Engine_Run_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Nipe_Engine_Run_Status_Bool_Exp>>;
  _not?: InputMaybe<Nipe_Engine_Run_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Nipe_Engine_Run_Status_Bool_Exp>>;
  nipe_run_commands?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
  nipe_run_commands_aggregate?: InputMaybe<Nipe_Run_Command_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nipe_engine_run_status" */
export enum Nipe_Engine_Run_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  NipeEngineRunStatusPkey = 'nipe_engine_run_status_pkey'
}

/** input type for inserting data into table "nipe_engine_run_status" */
export type Nipe_Engine_Run_Status_Insert_Input = {
  nipe_run_commands?: InputMaybe<Nipe_Run_Command_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nipe_Engine_Run_Status_Max_Fields = {
  __typename?: 'nipe_engine_run_status_max_fields';
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nipe_Engine_Run_Status_Min_Fields = {
  __typename?: 'nipe_engine_run_status_min_fields';
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nipe_engine_run_status" */
export type Nipe_Engine_Run_Status_Mutation_Response = {
  __typename?: 'nipe_engine_run_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nipe_Engine_Run_Status>;
};

/** input type for inserting object relation for remote table "nipe_engine_run_status" */
export type Nipe_Engine_Run_Status_Obj_Rel_Insert_Input = {
  data: Nipe_Engine_Run_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nipe_Engine_Run_Status_On_Conflict>;
};

/** on_conflict condition type for table "nipe_engine_run_status" */
export type Nipe_Engine_Run_Status_On_Conflict = {
  constraint: Nipe_Engine_Run_Status_Constraint;
  update_columns?: Array<Nipe_Engine_Run_Status_Update_Column>;
  where?: InputMaybe<Nipe_Engine_Run_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "nipe_engine_run_status". */
export type Nipe_Engine_Run_Status_Order_By = {
  nipe_run_commands_aggregate?: InputMaybe<Nipe_Run_Command_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nipe_engine_run_status */
export type Nipe_Engine_Run_Status_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "nipe_engine_run_status" */
export enum Nipe_Engine_Run_Status_Select_Column {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "nipe_engine_run_status" */
export type Nipe_Engine_Run_Status_Set_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nipe_engine_run_status" */
export type Nipe_Engine_Run_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nipe_Engine_Run_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nipe_Engine_Run_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nipe_engine_run_status" */
export enum Nipe_Engine_Run_Status_Update_Column {
  /** column name */
  Status = 'status'
}

export type Nipe_Engine_Run_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nipe_Engine_Run_Status_Set_Input>;
  where: Nipe_Engine_Run_Status_Bool_Exp;
};

/** 画像処理エンジンの実行テーブル */
export type Nipe_Run_Command = {
  __typename?: 'nipe_run_command';
  /** An object relationship */
  app_user_active: App_User_Active;
  command: Scalars['json'];
  created: Scalars['timestamptz'];
  detail_status?: Maybe<Scalars['json']>;
  id: Scalars['uuid'];
  /** An array relationship */
  nbs_ipe_run_fields: Array<Nbs_Ipe_Run_Field>;
  /** An aggregate relationship */
  nbs_ipe_run_fields_aggregate: Nbs_Ipe_Run_Field_Aggregate;
  /** An object relationship */
  nipe_engine_run_status: Nipe_Engine_Run_Status;
  requested_user_id: Scalars['uuid'];
  status: Scalars['String'];
  updated: Scalars['timestamptz'];
};


/** 画像処理エンジンの実行テーブル */
export type Nipe_Run_CommandCommandArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** 画像処理エンジンの実行テーブル */
export type Nipe_Run_CommandDetail_StatusArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** 画像処理エンジンの実行テーブル */
export type Nipe_Run_CommandNbs_Ipe_Run_FieldsArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
};


/** 画像処理エンジンの実行テーブル */
export type Nipe_Run_CommandNbs_Ipe_Run_Fields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
};

/** aggregated selection of "nipe_run_command" */
export type Nipe_Run_Command_Aggregate = {
  __typename?: 'nipe_run_command_aggregate';
  aggregate?: Maybe<Nipe_Run_Command_Aggregate_Fields>;
  nodes: Array<Nipe_Run_Command>;
};

export type Nipe_Run_Command_Aggregate_Bool_Exp = {
  count?: InputMaybe<Nipe_Run_Command_Aggregate_Bool_Exp_Count>;
};

export type Nipe_Run_Command_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nipe_Run_Command_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nipe_run_command" */
export type Nipe_Run_Command_Aggregate_Fields = {
  __typename?: 'nipe_run_command_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nipe_Run_Command_Max_Fields>;
  min?: Maybe<Nipe_Run_Command_Min_Fields>;
};


/** aggregate fields of "nipe_run_command" */
export type Nipe_Run_Command_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nipe_Run_Command_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nipe_run_command" */
export type Nipe_Run_Command_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nipe_Run_Command_Max_Order_By>;
  min?: InputMaybe<Nipe_Run_Command_Min_Order_By>;
};

/** input type for inserting array relation for remote table "nipe_run_command" */
export type Nipe_Run_Command_Arr_Rel_Insert_Input = {
  data: Array<Nipe_Run_Command_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nipe_Run_Command_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nipe_run_command". All fields are combined with a logical 'AND'. */
export type Nipe_Run_Command_Bool_Exp = {
  _and?: InputMaybe<Array<Nipe_Run_Command_Bool_Exp>>;
  _not?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
  _or?: InputMaybe<Array<Nipe_Run_Command_Bool_Exp>>;
  app_user_active?: InputMaybe<App_User_Active_Bool_Exp>;
  command?: InputMaybe<Json_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  detail_status?: InputMaybe<Json_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nbs_ipe_run_fields?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
  nbs_ipe_run_fields_aggregate?: InputMaybe<Nbs_Ipe_Run_Field_Aggregate_Bool_Exp>;
  nipe_engine_run_status?: InputMaybe<Nipe_Engine_Run_Status_Bool_Exp>;
  requested_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "nipe_run_command" */
export enum Nipe_Run_Command_Constraint {
  /** unique or primary key constraint on columns "id" */
  NipeRunCommandPkey = 'nipe_run_command_pkey'
}

/** input type for inserting data into table "nipe_run_command" */
export type Nipe_Run_Command_Insert_Input = {
  app_user_active?: InputMaybe<App_User_Active_Obj_Rel_Insert_Input>;
  command?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_status?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['uuid']>;
  nbs_ipe_run_fields?: InputMaybe<Nbs_Ipe_Run_Field_Arr_Rel_Insert_Input>;
  nipe_engine_run_status?: InputMaybe<Nipe_Engine_Run_Status_Obj_Rel_Insert_Input>;
  requested_user_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Nipe_Run_Command_Max_Fields = {
  __typename?: 'nipe_run_command_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  requested_user_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "nipe_run_command" */
export type Nipe_Run_Command_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requested_user_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nipe_Run_Command_Min_Fields = {
  __typename?: 'nipe_run_command_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  requested_user_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "nipe_run_command" */
export type Nipe_Run_Command_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requested_user_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nipe_run_command" */
export type Nipe_Run_Command_Mutation_Response = {
  __typename?: 'nipe_run_command_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nipe_Run_Command>;
};

/** input type for inserting object relation for remote table "nipe_run_command" */
export type Nipe_Run_Command_Obj_Rel_Insert_Input = {
  data: Nipe_Run_Command_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nipe_Run_Command_On_Conflict>;
};

/** on_conflict condition type for table "nipe_run_command" */
export type Nipe_Run_Command_On_Conflict = {
  constraint: Nipe_Run_Command_Constraint;
  update_columns?: Array<Nipe_Run_Command_Update_Column>;
  where?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
};

/** Ordering options when selecting data from "nipe_run_command". */
export type Nipe_Run_Command_Order_By = {
  app_user_active?: InputMaybe<App_User_Active_Order_By>;
  command?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  detail_status?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nbs_ipe_run_fields_aggregate?: InputMaybe<Nbs_Ipe_Run_Field_Aggregate_Order_By>;
  nipe_engine_run_status?: InputMaybe<Nipe_Engine_Run_Status_Order_By>;
  requested_user_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nipe_run_command */
export type Nipe_Run_Command_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nipe_run_command" */
export enum Nipe_Run_Command_Select_Column {
  /** column name */
  Command = 'command',
  /** column name */
  Created = 'created',
  /** column name */
  DetailStatus = 'detail_status',
  /** column name */
  Id = 'id',
  /** column name */
  RequestedUserId = 'requested_user_id',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "nipe_run_command" */
export type Nipe_Run_Command_Set_Input = {
  command?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_status?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['uuid']>;
  requested_user_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "nipe_run_command" */
export type Nipe_Run_Command_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nipe_Run_Command_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nipe_Run_Command_Stream_Cursor_Value_Input = {
  command?: InputMaybe<Scalars['json']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  detail_status?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['uuid']>;
  requested_user_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "nipe_run_command" */
export enum Nipe_Run_Command_Update_Column {
  /** column name */
  Command = 'command',
  /** column name */
  Created = 'created',
  /** column name */
  DetailStatus = 'detail_status',
  /** column name */
  Id = 'id',
  /** column name */
  RequestedUserId = 'requested_user_id',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated'
}

export type Nipe_Run_Command_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nipe_Run_Command_Set_Input>;
  where: Nipe_Run_Command_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Boolean expression to compare columns of type "picture_load_status". All fields are combined with logical 'AND'. */
export type Picture_Load_Status_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['picture_load_status']>;
  _gt?: InputMaybe<Scalars['picture_load_status']>;
  _gte?: InputMaybe<Scalars['picture_load_status']>;
  _in?: InputMaybe<Array<Scalars['picture_load_status']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['picture_load_status']>;
  _lte?: InputMaybe<Scalars['picture_load_status']>;
  _neq?: InputMaybe<Scalars['picture_load_status']>;
  _nin?: InputMaybe<Array<Scalars['picture_load_status']>>;
};

export type Query_Root = {
  __typename?: 'query_root';
  GrowthSensingByTile?: Maybe<GrowthSensingByTileOutput>;
  MasterFarmFieldByTile?: Maybe<MasterFarmFieldByTileOutput>;
  /** fetch data from the table: "app_user" */
  app_user: Array<App_User>;
  /** fetch data from the table: "app_user_active" */
  app_user_active: Array<App_User_Active>;
  /** fetch aggregated fields from the table: "app_user_active" */
  app_user_active_aggregate: App_User_Active_Aggregate;
  /** fetch data from the table: "app_user_active" using primary key columns */
  app_user_active_by_pk?: Maybe<App_User_Active>;
  /** fetch aggregated fields from the table: "app_user" */
  app_user_aggregate: App_User_Aggregate;
  /** fetch data from the table: "app_user" using primary key columns */
  app_user_by_pk?: Maybe<App_User>;
  /** fetch data from the table: "app_user_property_private" */
  app_user_property_private: Array<App_User_Property_Private>;
  /** fetch aggregated fields from the table: "app_user_property_private" */
  app_user_property_private_aggregate: App_User_Property_Private_Aggregate;
  /** fetch data from the table: "app_user_property_private" using primary key columns */
  app_user_property_private_by_pk?: Maybe<App_User_Property_Private>;
  /** fetch data from the table: "application_user_invitation_to_company" */
  application_user_invitation_to_company: Array<Application_User_Invitation_To_Company>;
  /** fetch aggregated fields from the table: "application_user_invitation_to_company" */
  application_user_invitation_to_company_aggregate: Application_User_Invitation_To_Company_Aggregate;
  /** fetch data from the table: "application_user_invitation_to_company" using primary key columns */
  application_user_invitation_to_company_by_pk?: Maybe<Application_User_Invitation_To_Company>;
  fastapiFieldPictureLoadStatusFailure: FieldPictureLoadStatusFailureResponse;
  fastapiFieldPictureLoadStatusLoaded: FieldPictureLoadStatusLoadedResponse;
  fastapiGetMasterFieldPolygonsByBbox: GetMasterFieldPolygonsResponse;
  fastapiGetPictureAnalysis: GetPictureAnalysisResponse;
  fastapiGetPictureAnalysisStatus: GetPictureAnalysisStatusResponse;
  fastapiHello: Scalars['String'];
  fastapiM2mGetRequestedIpeCommand: Array<M2mRequestedIpeCommand>;
  fastapiMyEmail?: Maybe<AppUser>;
  fastapiResumeMultipartUpload: ResumeMultipartUploadResponse;
  fastapiS3SignedUrlTrackerApks: S3SignedUrlTrackerApksResponse;
  fastapiWideOrthoimgLoadSuspended: WideOrthoimgLoadSuspended;
  /** fetch data from the table: "master.nb_area_mesh" */
  master_nb_area_mesh: Array<Master_Nb_Area_Mesh>;
  /** fetch aggregated fields from the table: "master.nb_area_mesh" */
  master_nb_area_mesh_aggregate: Master_Nb_Area_Mesh_Aggregate;
  /** fetch data from the table: "master.nb_area_mesh" using primary key columns */
  master_nb_area_mesh_by_pk?: Maybe<Master_Nb_Area_Mesh>;
  /** fetch data from the table: "master.nb_integrated_farmland" */
  master_nb_integrated_farmland: Array<Master_Nb_Integrated_Farmland>;
  /** fetch aggregated fields from the table: "master.nb_integrated_farmland" */
  master_nb_integrated_farmland_aggregate: Master_Nb_Integrated_Farmland_Aggregate;
  /** fetch data from the table: "master.nb_integrated_farmland" using primary key columns */
  master_nb_integrated_farmland_by_pk?: Maybe<Master_Nb_Integrated_Farmland>;
  /** fetch data from the table: "master.nb_mfield" */
  master_nb_mfield: Array<Master_Nb_Mfield>;
  /** fetch aggregated fields from the table: "master.nb_mfield" */
  master_nb_mfield_aggregate: Master_Nb_Mfield_Aggregate;
  /** fetch data from the table: "master.nb_mfield" using primary key columns */
  master_nb_mfield_by_pk?: Maybe<Master_Nb_Mfield>;
  /** fetch data from the table: "master.nb_mfield_polygon_2022_1" */
  master_nb_mfield_polygon_2022_1: Array<Master_Nb_Mfield_Polygon_2022_1>;
  /** fetch aggregated fields from the table: "master.nb_mfield_polygon_2022_1" */
  master_nb_mfield_polygon_2022_1_aggregate: Master_Nb_Mfield_Polygon_2022_1_Aggregate;
  /** fetch data from the table: "master.nb_mfield_polygon_2022_1" using primary key columns */
  master_nb_mfield_polygon_2022_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2022_1>;
  /** fetch data from the table: "master.nb_mfield_polygon_2023_1" */
  master_nb_mfield_polygon_2023_1: Array<Master_Nb_Mfield_Polygon_2023_1>;
  /** fetch aggregated fields from the table: "master.nb_mfield_polygon_2023_1" */
  master_nb_mfield_polygon_2023_1_aggregate: Master_Nb_Mfield_Polygon_2023_1_Aggregate;
  /** fetch data from the table: "master.nb_mfield_polygon_2023_1" using primary key columns */
  master_nb_mfield_polygon_2023_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2023_1>;
  /** fetch data from the table: "master.nb_mfield_polygon_2024_1" */
  master_nb_mfield_polygon_2024_1: Array<Master_Nb_Mfield_Polygon_2024_1>;
  /** fetch aggregated fields from the table: "master.nb_mfield_polygon_2024_1" */
  master_nb_mfield_polygon_2024_1_aggregate: Master_Nb_Mfield_Polygon_2024_1_Aggregate;
  /** fetch data from the table: "master.nb_mfield_polygon_2024_1" using primary key columns */
  master_nb_mfield_polygon_2024_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2024_1>;
  /** fetch data from the table: "master.nb_mfield_version" */
  master_nb_mfield_version: Array<Master_Nb_Mfield_Version>;
  /** fetch aggregated fields from the table: "master.nb_mfield_version" */
  master_nb_mfield_version_aggregate: Master_Nb_Mfield_Version_Aggregate;
  /** fetch data from the table: "master.nb_mfield_version" using primary key columns */
  master_nb_mfield_version_by_pk?: Maybe<Master_Nb_Mfield_Version>;
  /** fetch data from the table: "master.nb_postgis_version" */
  master_nb_postgis_version: Array<Master_Nb_Postgis_Version>;
  /** fetch aggregated fields from the table: "master.nb_postgis_version" */
  master_nb_postgis_version_aggregate: Master_Nb_Postgis_Version_Aggregate;
  /** fetch data from the table: "master.nb_postgis_version" using primary key columns */
  master_nb_postgis_version_by_pk?: Maybe<Master_Nb_Postgis_Version>;
  /** fetch data from the table: "master.nb_weather_attr" */
  master_nb_weather_attr: Array<Master_Nb_Weather_Attr>;
  /** fetch aggregated fields from the table: "master.nb_weather_attr" */
  master_nb_weather_attr_aggregate: Master_Nb_Weather_Attr_Aggregate;
  /** fetch data from the table: "master.nb_weather_attr" using primary key columns */
  master_nb_weather_attr_by_pk?: Maybe<Master_Nb_Weather_Attr>;
  /** fetch data from the table: "master.nb_weather_data" */
  master_nb_weather_data: Array<Master_Nb_Weather_Data>;
  /** fetch aggregated fields from the table: "master.nb_weather_data" */
  master_nb_weather_data_aggregate: Master_Nb_Weather_Data_Aggregate;
  /** fetch data from the table: "master.nb_weather_data" using primary key columns */
  master_nb_weather_data_by_pk?: Maybe<Master_Nb_Weather_Data>;
  /** fetch data from the table: "nb_actual_crop_yeild" */
  nb_actual_crop_yeild: Array<Nb_Actual_Crop_Yeild>;
  /** fetch aggregated fields from the table: "nb_actual_crop_yeild" */
  nb_actual_crop_yeild_aggregate: Nb_Actual_Crop_Yeild_Aggregate;
  /** fetch data from the table: "nb_actual_crop_yeild" using primary key columns */
  nb_actual_crop_yeild_by_pk?: Maybe<Nb_Actual_Crop_Yeild>;
  /** fetch data from the table: "nb_area_mesh" */
  nb_area_mesh: Array<Nb_Area_Mesh>;
  /** fetch aggregated fields from the table: "nb_area_mesh" */
  nb_area_mesh_aggregate: Nb_Area_Mesh_Aggregate;
  /** fetch data from the table: "nb_area_mesh" using primary key columns */
  nb_area_mesh_by_pk?: Maybe<Nb_Area_Mesh>;
  /** fetch data from the table: "nb_company" */
  nb_company: Array<Nb_Company>;
  /** fetch aggregated fields from the table: "nb_company" */
  nb_company_aggregate: Nb_Company_Aggregate;
  /** fetch data from the table: "nb_company" using primary key columns */
  nb_company_by_pk?: Maybe<Nb_Company>;
  /** fetch data from the table: "nb_company_info" */
  nb_company_info: Array<Nb_Company_Info>;
  /** fetch aggregated fields from the table: "nb_company_info" */
  nb_company_info_aggregate: Nb_Company_Info_Aggregate;
  /** fetch data from the table: "nb_company_info" using primary key columns */
  nb_company_info_by_pk?: Maybe<Nb_Company_Info>;
  /** fetch data from the table: "nb_conditioner_work" */
  nb_conditioner_work: Array<Nb_Conditioner_Work>;
  /** fetch aggregated fields from the table: "nb_conditioner_work" */
  nb_conditioner_work_aggregate: Nb_Conditioner_Work_Aggregate;
  /** fetch data from the table: "nb_conditioner_work" using primary key columns */
  nb_conditioner_work_by_pk?: Maybe<Nb_Conditioner_Work>;
  /** fetch data from the table: "nb_cplan_imsensing_link" */
  nb_cplan_imsensing_link: Array<Nb_Cplan_Imsensing_Link>;
  /** fetch aggregated fields from the table: "nb_cplan_imsensing_link" */
  nb_cplan_imsensing_link_aggregate: Nb_Cplan_Imsensing_Link_Aggregate;
  /** fetch data from the table: "nb_cplan_imsensing_link" using primary key columns */
  nb_cplan_imsensing_link_by_pk?: Maybe<Nb_Cplan_Imsensing_Link>;
  /** fetch data from the table: "nb_crop" */
  nb_crop: Array<Nb_Crop>;
  /** fetch aggregated fields from the table: "nb_crop" */
  nb_crop_aggregate: Nb_Crop_Aggregate;
  /** fetch data from the table: "nb_crop" using primary key columns */
  nb_crop_by_pk?: Maybe<Nb_Crop>;
  /** fetch data from the table: "nb_crop_company_link" */
  nb_crop_company_link: Array<Nb_Crop_Company_Link>;
  /** fetch aggregated fields from the table: "nb_crop_company_link" */
  nb_crop_company_link_aggregate: Nb_Crop_Company_Link_Aggregate;
  /** fetch data from the table: "nb_crop_company_link" using primary key columns */
  nb_crop_company_link_by_pk?: Maybe<Nb_Crop_Company_Link>;
  /** fetch data from the table: "nb_crop_method_link" */
  nb_crop_method_link: Array<Nb_Crop_Method_Link>;
  /** fetch aggregated fields from the table: "nb_crop_method_link" */
  nb_crop_method_link_aggregate: Nb_Crop_Method_Link_Aggregate;
  /** fetch data from the table: "nb_crop_method_link" using primary key columns */
  nb_crop_method_link_by_pk?: Maybe<Nb_Crop_Method_Link>;
  /** An array relationship */
  nb_cultivar: Array<Nb_Cultivar>;
  /** An aggregate relationship */
  nb_cultivar_aggregate: Nb_Cultivar_Aggregate;
  /** fetch data from the table: "nb_cultivar" using primary key columns */
  nb_cultivar_by_pk?: Maybe<Nb_Cultivar>;
  /** fetch data from the table: "nb_cultivation_method" */
  nb_cultivation_method: Array<Nb_Cultivation_Method>;
  /** fetch aggregated fields from the table: "nb_cultivation_method" */
  nb_cultivation_method_aggregate: Nb_Cultivation_Method_Aggregate;
  /** fetch data from the table: "nb_cultivation_method" using primary key columns */
  nb_cultivation_method_by_pk?: Maybe<Nb_Cultivation_Method>;
  /** fetch data from the table: "nb_cultivation_plan" */
  nb_cultivation_plan: Array<Nb_Cultivation_Plan>;
  /** fetch aggregated fields from the table: "nb_cultivation_plan" */
  nb_cultivation_plan_aggregate: Nb_Cultivation_Plan_Aggregate;
  /** fetch data from the table: "nb_cultivation_plan" using primary key columns */
  nb_cultivation_plan_by_pk?: Maybe<Nb_Cultivation_Plan>;
  /** fetch data from the table: "nb_demo_task_type" */
  nb_demo_task_type: Array<Nb_Demo_Task_Type>;
  /** fetch aggregated fields from the table: "nb_demo_task_type" */
  nb_demo_task_type_aggregate: Nb_Demo_Task_Type_Aggregate;
  /** fetch data from the table: "nb_demo_task_type" using primary key columns */
  nb_demo_task_type_by_pk?: Maybe<Nb_Demo_Task_Type>;
  /** fetch data from the table: "nb_demo_user_location" */
  nb_demo_user_location: Array<Nb_Demo_User_Location>;
  /** fetch aggregated fields from the table: "nb_demo_user_location" */
  nb_demo_user_location_aggregate: Nb_Demo_User_Location_Aggregate;
  /** fetch data from the table: "nb_demo_user_location" using primary key columns */
  nb_demo_user_location_by_pk?: Maybe<Nb_Demo_User_Location>;
  /** fetch data from the table: "nb_demo_user_name" */
  nb_demo_user_name: Array<Nb_Demo_User_Name>;
  /** fetch aggregated fields from the table: "nb_demo_user_name" */
  nb_demo_user_name_aggregate: Nb_Demo_User_Name_Aggregate;
  /** fetch data from the table: "nb_demo_user_name" using primary key columns */
  nb_demo_user_name_by_pk?: Maybe<Nb_Demo_User_Name>;
  /** fetch data from the table: "nb_est_working_load" */
  nb_est_working_load: Array<Nb_Est_Working_Load>;
  /** fetch aggregated fields from the table: "nb_est_working_load" */
  nb_est_working_load_aggregate: Nb_Est_Working_Load_Aggregate;
  /** fetch data from the table: "nb_est_working_load" using primary key columns */
  nb_est_working_load_by_pk?: Maybe<Nb_Est_Working_Load>;
  /** fetch data from the table: "nb_fertilization_method" */
  nb_fertilization_method: Array<Nb_Fertilization_Method>;
  /** fetch aggregated fields from the table: "nb_fertilization_method" */
  nb_fertilization_method_aggregate: Nb_Fertilization_Method_Aggregate;
  /** fetch data from the table: "nb_fertilization_method" using primary key columns */
  nb_fertilization_method_by_pk?: Maybe<Nb_Fertilization_Method>;
  /** fetch data from the table: "nb_fertilization_work" */
  nb_fertilization_work: Array<Nb_Fertilization_Work>;
  /** fetch aggregated fields from the table: "nb_fertilization_work" */
  nb_fertilization_work_aggregate: Nb_Fertilization_Work_Aggregate;
  /** fetch data from the table: "nb_fertilization_work" using primary key columns */
  nb_fertilization_work_by_pk?: Maybe<Nb_Fertilization_Work>;
  /** fetch data from the table: "nb_fertilizer" */
  nb_fertilizer: Array<Nb_Fertilizer>;
  /** fetch aggregated fields from the table: "nb_fertilizer" */
  nb_fertilizer_aggregate: Nb_Fertilizer_Aggregate;
  /** fetch data from the table: "nb_fertilizer" using primary key columns */
  nb_fertilizer_by_pk?: Maybe<Nb_Fertilizer>;
  /** fetch data from the table: "nb_fertilizer_company_link" */
  nb_fertilizer_company_link: Array<Nb_Fertilizer_Company_Link>;
  /** fetch aggregated fields from the table: "nb_fertilizer_company_link" */
  nb_fertilizer_company_link_aggregate: Nb_Fertilizer_Company_Link_Aggregate;
  /** fetch data from the table: "nb_fertilizer_company_link" using primary key columns */
  nb_fertilizer_company_link_by_pk?: Maybe<Nb_Fertilizer_Company_Link>;
  /** fetch data from the table: "nb_fertilizer_picture" */
  nb_fertilizer_picture: Array<Nb_Fertilizer_Picture>;
  /** fetch aggregated fields from the table: "nb_fertilizer_picture" */
  nb_fertilizer_picture_aggregate: Nb_Fertilizer_Picture_Aggregate;
  /** fetch data from the table: "nb_fertilizer_picture" using primary key columns */
  nb_fertilizer_picture_by_pk?: Maybe<Nb_Fertilizer_Picture>;
  /** fetch data from the table: "nb_field_file" */
  nb_field_file: Array<Nb_Field_File>;
  /** fetch aggregated fields from the table: "nb_field_file" */
  nb_field_file_aggregate: Nb_Field_File_Aggregate;
  /** fetch data from the table: "nb_field_file" using primary key columns */
  nb_field_file_by_pk?: Maybe<Nb_Field_File>;
  /** fetch data from the table: "nb_field_file_load" */
  nb_field_file_load: Array<Nb_Field_File_Load>;
  /** fetch aggregated fields from the table: "nb_field_file_load" */
  nb_field_file_load_aggregate: Nb_Field_File_Load_Aggregate;
  /** fetch data from the table: "nb_field_file_load" using primary key columns */
  nb_field_file_load_by_pk?: Maybe<Nb_Field_File_Load>;
  /** fetch data from the table: "nb_field_file_size" */
  nb_field_file_size: Array<Nb_Field_File_Size>;
  /** fetch aggregated fields from the table: "nb_field_file_size" */
  nb_field_file_size_aggregate: Nb_Field_File_Size_Aggregate;
  /** fetch data from the table: "nb_field_memo" */
  nb_field_memo: Array<Nb_Field_Memo>;
  /** fetch aggregated fields from the table: "nb_field_memo" */
  nb_field_memo_aggregate: Nb_Field_Memo_Aggregate;
  /** fetch data from the table: "nb_field_memo" using primary key columns */
  nb_field_memo_by_pk?: Maybe<Nb_Field_Memo>;
  /** fetch data from the table: "nb_field_orthoimg" */
  nb_field_orthoimg: Array<Nb_Field_Orthoimg>;
  /** fetch aggregated fields from the table: "nb_field_orthoimg" */
  nb_field_orthoimg_aggregate: Nb_Field_Orthoimg_Aggregate;
  /** fetch data from the table: "nb_field_orthoimg" using primary key columns */
  nb_field_orthoimg_by_pk?: Maybe<Nb_Field_Orthoimg>;
  /** fetch data from the table: "nb_field_orthoimg_size" */
  nb_field_orthoimg_size: Array<Nb_Field_Orthoimg_Size>;
  /** fetch aggregated fields from the table: "nb_field_orthoimg_size" */
  nb_field_orthoimg_size_aggregate: Nb_Field_Orthoimg_Size_Aggregate;
  /** fetch data from the table: "nb_field_photographer" */
  nb_field_photographer: Array<Nb_Field_Photographer>;
  /** fetch aggregated fields from the table: "nb_field_photographer" */
  nb_field_photographer_aggregate: Nb_Field_Photographer_Aggregate;
  /** fetch data from the table: "nb_field_photographer" using primary key columns */
  nb_field_photographer_by_pk?: Maybe<Nb_Field_Photographer>;
  /** fetch data from the table: "nb_field_picture" */
  nb_field_picture: Array<Nb_Field_Picture>;
  /** fetch aggregated fields from the table: "nb_field_picture" */
  nb_field_picture_aggregate: Nb_Field_Picture_Aggregate;
  /** fetch data from the table: "nb_field_picture" using primary key columns */
  nb_field_picture_by_pk?: Maybe<Nb_Field_Picture>;
  /** fetch data from the table: "nb_field_picture_cplan_link" */
  nb_field_picture_cplan_link: Array<Nb_Field_Picture_Cplan_Link>;
  /** fetch aggregated fields from the table: "nb_field_picture_cplan_link" */
  nb_field_picture_cplan_link_aggregate: Nb_Field_Picture_Cplan_Link_Aggregate;
  /** fetch data from the table: "nb_field_picture_cplan_link" using primary key columns */
  nb_field_picture_cplan_link_by_pk?: Maybe<Nb_Field_Picture_Cplan_Link>;
  /** fetch data from the table: "nb_field_picture_load" */
  nb_field_picture_load: Array<Nb_Field_Picture_Load>;
  /** fetch aggregated fields from the table: "nb_field_picture_load" */
  nb_field_picture_load_aggregate: Nb_Field_Picture_Load_Aggregate;
  /** fetch data from the table: "nb_field_picture_load" using primary key columns */
  nb_field_picture_load_by_pk?: Maybe<Nb_Field_Picture_Load>;
  /** fetch data from the table: "nb_field_picture_size" */
  nb_field_picture_size: Array<Nb_Field_Picture_Size>;
  /** fetch aggregated fields from the table: "nb_field_picture_size" */
  nb_field_picture_size_aggregate: Nb_Field_Picture_Size_Aggregate;
  /** fetch data from the table: "nb_field_polygon_attr" */
  nb_field_polygon_attr: Array<Nb_Field_Polygon_Attr>;
  /** fetch aggregated fields from the table: "nb_field_polygon_attr" */
  nb_field_polygon_attr_aggregate: Nb_Field_Polygon_Attr_Aggregate;
  /** fetch data from the table: "nb_field_polygon_attr" using primary key columns */
  nb_field_polygon_attr_by_pk?: Maybe<Nb_Field_Polygon_Attr>;
  /** fetch data from the table: "nb_field_property" */
  nb_field_property: Array<Nb_Field_Property>;
  /** fetch aggregated fields from the table: "nb_field_property" */
  nb_field_property_aggregate: Nb_Field_Property_Aggregate;
  /** fetch data from the table: "nb_field_property" using primary key columns */
  nb_field_property_by_pk?: Maybe<Nb_Field_Property>;
  /** fetch data from the table: "nb_field_sensing" */
  nb_field_sensing: Array<Nb_Field_Sensing>;
  /** fetch aggregated fields from the table: "nb_field_sensing" */
  nb_field_sensing_aggregate: Nb_Field_Sensing_Aggregate;
  /** fetch data from the table: "nb_field_sensing" using primary key columns */
  nb_field_sensing_by_pk?: Maybe<Nb_Field_Sensing>;
  /** An array relationship */
  nb_field_sensing_data: Array<Nb_Field_Sensing_Data>;
  /** An aggregate relationship */
  nb_field_sensing_data_aggregate: Nb_Field_Sensing_Data_Aggregate;
  /** fetch data from the table: "nb_field_sensing_data" using primary key columns */
  nb_field_sensing_data_by_pk?: Maybe<Nb_Field_Sensing_Data>;
  /** fetch data from the table: "nb_field_water_gate" */
  nb_field_water_gate: Array<Nb_Field_Water_Gate>;
  /** fetch aggregated fields from the table: "nb_field_water_gate" */
  nb_field_water_gate_aggregate: Nb_Field_Water_Gate_Aggregate;
  /** fetch data from the table: "nb_field_water_gate" using primary key columns */
  nb_field_water_gate_by_pk?: Maybe<Nb_Field_Water_Gate>;
  /** fetch data from the table: "nb_field_water_gate_status" */
  nb_field_water_gate_status: Array<Nb_Field_Water_Gate_Status>;
  /** fetch aggregated fields from the table: "nb_field_water_gate_status" */
  nb_field_water_gate_status_aggregate: Nb_Field_Water_Gate_Status_Aggregate;
  /** fetch data from the table: "nb_field_water_gate_status" using primary key columns */
  nb_field_water_gate_status_by_pk?: Maybe<Nb_Field_Water_Gate_Status>;
  /** fetch data from the table: "nb_file_size" */
  nb_file_size: Array<Nb_File_Size>;
  /** fetch aggregated fields from the table: "nb_file_size" */
  nb_file_size_aggregate: Nb_File_Size_Aggregate;
  /** fetch data from the table: "nb_file_size" using primary key columns */
  nb_file_size_by_pk?: Maybe<Nb_File_Size>;
  /** fetch data from the table: "nb_file_size_company_user_view" */
  nb_file_size_company_user_view: Array<Nb_File_Size_Company_User_View>;
  /** fetch aggregated fields from the table: "nb_file_size_company_user_view" */
  nb_file_size_company_user_view_aggregate: Nb_File_Size_Company_User_View_Aggregate;
  /** fetch data from the table: "nb_file_size_company_view" */
  nb_file_size_company_view: Array<Nb_File_Size_Company_View>;
  /** fetch aggregated fields from the table: "nb_file_size_company_view" */
  nb_file_size_company_view_aggregate: Nb_File_Size_Company_View_Aggregate;
  /** fetch data from the table: "nb_file_type" */
  nb_file_type: Array<Nb_File_Type>;
  /** fetch aggregated fields from the table: "nb_file_type" */
  nb_file_type_aggregate: Nb_File_Type_Aggregate;
  /** fetch data from the table: "nb_file_type" using primary key columns */
  nb_file_type_by_pk?: Maybe<Nb_File_Type>;
  /** fetch data from the table: "nb_finished_ufield_work" */
  nb_finished_ufield_work: Array<Nb_Finished_Ufield_Work>;
  /** fetch aggregated fields from the table: "nb_finished_ufield_work" */
  nb_finished_ufield_work_aggregate: Nb_Finished_Ufield_Work_Aggregate;
  /** fetch data from the table: "nb_finished_ufield_work" using primary key columns */
  nb_finished_ufield_work_by_pk?: Maybe<Nb_Finished_Ufield_Work>;
  /** fetch data from the table: "nb_image_sensing_attr" */
  nb_image_sensing_attr: Array<Nb_Image_Sensing_Attr>;
  /** fetch aggregated fields from the table: "nb_image_sensing_attr" */
  nb_image_sensing_attr_aggregate: Nb_Image_Sensing_Attr_Aggregate;
  /** fetch data from the table: "nb_image_sensing_attr" using primary key columns */
  nb_image_sensing_attr_by_pk?: Maybe<Nb_Image_Sensing_Attr>;
  /** fetch data from the table: "nb_imsensing_colormap" */
  nb_imsensing_colormap: Array<Nb_Imsensing_Colormap>;
  /** fetch aggregated fields from the table: "nb_imsensing_colormap" */
  nb_imsensing_colormap_aggregate: Nb_Imsensing_Colormap_Aggregate;
  /** fetch data from the table: "nb_imsensing_colormap" using primary key columns */
  nb_imsensing_colormap_by_pk?: Maybe<Nb_Imsensing_Colormap>;
  /** fetch data from the table: "nb_log_user_position" */
  nb_log_user_position: Array<Nb_Log_User_Position>;
  /** fetch aggregated fields from the table: "nb_log_user_position" */
  nb_log_user_position_aggregate: Nb_Log_User_Position_Aggregate;
  /** fetch data from the table: "nb_log_user_position" using primary key columns */
  nb_log_user_position_by_pk?: Maybe<Nb_Log_User_Position>;
  /** fetch data from the table: "nb_maff_field" */
  nb_maff_field: Array<Nb_Maff_Field>;
  /** fetch aggregated fields from the table: "nb_maff_field" */
  nb_maff_field_aggregate: Nb_Maff_Field_Aggregate;
  /** fetch data from the table: "nb_maff_field" using primary key columns */
  nb_maff_field_by_pk?: Maybe<Nb_Maff_Field>;
  /** fetch data from the table: "nb_maff_field_link" */
  nb_maff_field_link: Array<Nb_Maff_Field_Link>;
  /** fetch aggregated fields from the table: "nb_maff_field_link" */
  nb_maff_field_link_aggregate: Nb_Maff_Field_Link_Aggregate;
  /** fetch data from the table: "nb_maff_field_link" using primary key columns */
  nb_maff_field_link_by_pk?: Maybe<Nb_Maff_Field_Link>;
  /** fetch data from the table: "nb_memo_file_link" */
  nb_memo_file_link: Array<Nb_Memo_File_Link>;
  /** fetch aggregated fields from the table: "nb_memo_file_link" */
  nb_memo_file_link_aggregate: Nb_Memo_File_Link_Aggregate;
  /** fetch data from the table: "nb_memo_file_link" using primary key columns */
  nb_memo_file_link_by_pk?: Maybe<Nb_Memo_File_Link>;
  /** fetch data from the table: "nb_memo_picture_link" */
  nb_memo_picture_link: Array<Nb_Memo_Picture_Link>;
  /** fetch aggregated fields from the table: "nb_memo_picture_link" */
  nb_memo_picture_link_aggregate: Nb_Memo_Picture_Link_Aggregate;
  /** fetch data from the table: "nb_memo_picture_link" using primary key columns */
  nb_memo_picture_link_by_pk?: Maybe<Nb_Memo_Picture_Link>;
  /** fetch data from the table: "nb_memo_ufg_link" */
  nb_memo_ufg_link: Array<Nb_Memo_Ufg_Link>;
  /** fetch aggregated fields from the table: "nb_memo_ufg_link" */
  nb_memo_ufg_link_aggregate: Nb_Memo_Ufg_Link_Aggregate;
  /** fetch data from the table: "nb_memo_ufg_link" using primary key columns */
  nb_memo_ufg_link_by_pk?: Maybe<Nb_Memo_Ufg_Link>;
  /** fetch data from the table: "nb_memo_ufield_link" */
  nb_memo_ufield_link: Array<Nb_Memo_Ufield_Link>;
  /** fetch aggregated fields from the table: "nb_memo_ufield_link" */
  nb_memo_ufield_link_aggregate: Nb_Memo_Ufield_Link_Aggregate;
  /** fetch data from the table: "nb_memo_ufield_link" using primary key columns */
  nb_memo_ufield_link_by_pk?: Maybe<Nb_Memo_Ufield_Link>;
  /** fetch data from the table: "nb_mfield" */
  nb_mfield: Array<Nb_Mfield>;
  /** fetch aggregated fields from the table: "nb_mfield" */
  nb_mfield_aggregate: Nb_Mfield_Aggregate;
  /** fetch data from the table: "nb_mfield" using primary key columns */
  nb_mfield_by_pk?: Maybe<Nb_Mfield>;
  /** fetch data from the table: "nb_mfield_link" */
  nb_mfield_link: Array<Nb_Mfield_Link>;
  /** fetch aggregated fields from the table: "nb_mfield_link" */
  nb_mfield_link_aggregate: Nb_Mfield_Link_Aggregate;
  /** fetch data from the table: "nb_mfield_link" using primary key columns */
  nb_mfield_link_by_pk?: Maybe<Nb_Mfield_Link>;
  /** fetch data from the table: "nb_performed_work" */
  nb_performed_work: Array<Nb_Performed_Work>;
  /** fetch aggregated fields from the table: "nb_performed_work" */
  nb_performed_work_aggregate: Nb_Performed_Work_Aggregate;
  /** fetch data from the table: "nb_performed_work" using primary key columns */
  nb_performed_work_by_pk?: Maybe<Nb_Performed_Work>;
  /** fetch data from the table: "nb_pesticide" */
  nb_pesticide: Array<Nb_Pesticide>;
  /** fetch aggregated fields from the table: "nb_pesticide" */
  nb_pesticide_aggregate: Nb_Pesticide_Aggregate;
  /** fetch data from the table: "nb_pesticide" using primary key columns */
  nb_pesticide_by_pk?: Maybe<Nb_Pesticide>;
  /** fetch data from the table: "nb_pesticide_company_link" */
  nb_pesticide_company_link: Array<Nb_Pesticide_Company_Link>;
  /** fetch aggregated fields from the table: "nb_pesticide_company_link" */
  nb_pesticide_company_link_aggregate: Nb_Pesticide_Company_Link_Aggregate;
  /** fetch data from the table: "nb_pesticide_company_link" using primary key columns */
  nb_pesticide_company_link_by_pk?: Maybe<Nb_Pesticide_Company_Link>;
  /** fetch data from the table: "nb_pesticide_ingredient" */
  nb_pesticide_ingredient: Array<Nb_Pesticide_Ingredient>;
  /** fetch aggregated fields from the table: "nb_pesticide_ingredient" */
  nb_pesticide_ingredient_aggregate: Nb_Pesticide_Ingredient_Aggregate;
  /** fetch data from the table: "nb_pesticide_ingredient" using primary key columns */
  nb_pesticide_ingredient_by_pk?: Maybe<Nb_Pesticide_Ingredient>;
  /** fetch data from the table: "nb_pesticide_spray_work" */
  nb_pesticide_spray_work: Array<Nb_Pesticide_Spray_Work>;
  /** fetch aggregated fields from the table: "nb_pesticide_spray_work" */
  nb_pesticide_spray_work_aggregate: Nb_Pesticide_Spray_Work_Aggregate;
  /** fetch data from the table: "nb_pesticide_spray_work" using primary key columns */
  nb_pesticide_spray_work_by_pk?: Maybe<Nb_Pesticide_Spray_Work>;
  /** fetch data from the table: "nb_picture_analysis" */
  nb_picture_analysis: Array<Nb_Picture_Analysis>;
  /** fetch aggregated fields from the table: "nb_picture_analysis" */
  nb_picture_analysis_aggregate: Nb_Picture_Analysis_Aggregate;
  /** fetch data from the table: "nb_picture_analysis" using primary key columns */
  nb_picture_analysis_by_pk?: Maybe<Nb_Picture_Analysis>;
  /** fetch data from the table: "nb_picture_analysis_type" */
  nb_picture_analysis_type: Array<Nb_Picture_Analysis_Type>;
  /** fetch aggregated fields from the table: "nb_picture_analysis_type" */
  nb_picture_analysis_type_aggregate: Nb_Picture_Analysis_Type_Aggregate;
  /** fetch data from the table: "nb_picture_analysis_type" using primary key columns */
  nb_picture_analysis_type_by_pk?: Maybe<Nb_Picture_Analysis_Type>;
  /** fetch data from the table: "nb_raster_image_sensing" */
  nb_raster_image_sensing: Array<Nb_Raster_Image_Sensing>;
  /** fetch aggregated fields from the table: "nb_raster_image_sensing" */
  nb_raster_image_sensing_aggregate: Nb_Raster_Image_Sensing_Aggregate;
  /** fetch data from the table: "nb_raster_image_sensing" using primary key columns */
  nb_raster_image_sensing_by_pk?: Maybe<Nb_Raster_Image_Sensing>;
  /** fetch data from the table: "nb_raster_ims_size" */
  nb_raster_ims_size: Array<Nb_Raster_Ims_Size>;
  /** fetch aggregated fields from the table: "nb_raster_ims_size" */
  nb_raster_ims_size_aggregate: Nb_Raster_Ims_Size_Aggregate;
  /** fetch data from the table: "nb_soil_chara_map" */
  nb_soil_chara_map: Array<Nb_Soil_Chara_Map>;
  /** fetch aggregated fields from the table: "nb_soil_chara_map" */
  nb_soil_chara_map_aggregate: Nb_Soil_Chara_Map_Aggregate;
  /** fetch data from the table: "nb_soil_chara_map" using primary key columns */
  nb_soil_chara_map_by_pk?: Maybe<Nb_Soil_Chara_Map>;
  /** fetch data from the table: "nb_soil_chara_map_sellist" */
  nb_soil_chara_map_sellist: Array<Nb_Soil_Chara_Map_Sellist>;
  /** fetch aggregated fields from the table: "nb_soil_chara_map_sellist" */
  nb_soil_chara_map_sellist_aggregate: Nb_Soil_Chara_Map_Sellist_Aggregate;
  /** fetch data from the table: "nb_soil_chara_map_sellist" using primary key columns */
  nb_soil_chara_map_sellist_by_pk?: Maybe<Nb_Soil_Chara_Map_Sellist>;
  /** fetch data from the table: "nb_soil_characteristic" */
  nb_soil_characteristic: Array<Nb_Soil_Characteristic>;
  /** fetch aggregated fields from the table: "nb_soil_characteristic" */
  nb_soil_characteristic_aggregate: Nb_Soil_Characteristic_Aggregate;
  /** fetch data from the table: "nb_soil_characteristic" using primary key columns */
  nb_soil_characteristic_by_pk?: Maybe<Nb_Soil_Characteristic>;
  /** fetch data from the table: "nb_soil_chemistry" */
  nb_soil_chemistry: Array<Nb_Soil_Chemistry>;
  /** fetch aggregated fields from the table: "nb_soil_chemistry" */
  nb_soil_chemistry_aggregate: Nb_Soil_Chemistry_Aggregate;
  /** fetch data from the table: "nb_soil_chemistry" using primary key columns */
  nb_soil_chemistry_by_pk?: Maybe<Nb_Soil_Chemistry>;
  /** fetch data from the table: "nb_soil_chemistry_proper_range" */
  nb_soil_chemistry_proper_range: Array<Nb_Soil_Chemistry_Proper_Range>;
  /** fetch aggregated fields from the table: "nb_soil_chemistry_proper_range" */
  nb_soil_chemistry_proper_range_aggregate: Nb_Soil_Chemistry_Proper_Range_Aggregate;
  /** fetch data from the table: "nb_soil_chemistry_proper_range" using primary key columns */
  nb_soil_chemistry_proper_range_by_pk?: Maybe<Nb_Soil_Chemistry_Proper_Range>;
  /** An array relationship */
  nb_soil_chemistry_standards: Array<Nb_Soil_Chemistry_Standards>;
  /** An aggregate relationship */
  nb_soil_chemistry_standards_aggregate: Nb_Soil_Chemistry_Standards_Aggregate;
  /** fetch data from the table: "nb_soil_chemistry_standards" using primary key columns */
  nb_soil_chemistry_standards_by_pk?: Maybe<Nb_Soil_Chemistry_Standards>;
  /** fetch data from the table: "nb_soil_chemistry_suitable_range" */
  nb_soil_chemistry_suitable_range: Array<Nb_Soil_Chemistry_Suitable_Range>;
  /** fetch aggregated fields from the table: "nb_soil_chemistry_suitable_range" */
  nb_soil_chemistry_suitable_range_aggregate: Nb_Soil_Chemistry_Suitable_Range_Aggregate;
  /** fetch data from the table: "nb_soil_chemistry_suitable_range" using primary key columns */
  nb_soil_chemistry_suitable_range_by_pk?: Maybe<Nb_Soil_Chemistry_Suitable_Range>;
  /** fetch data from the table: "nb_soil_chemistry_value" */
  nb_soil_chemistry_value: Array<Nb_Soil_Chemistry_Value>;
  /** fetch aggregated fields from the table: "nb_soil_chemistry_value" */
  nb_soil_chemistry_value_aggregate: Nb_Soil_Chemistry_Value_Aggregate;
  /** fetch data from the table: "nb_soil_chemistry_value" using primary key columns */
  nb_soil_chemistry_value_by_pk?: Maybe<Nb_Soil_Chemistry_Value>;
  /** fetch data from the table: "nb_soil_explanation" */
  nb_soil_explanation: Array<Nb_Soil_Explanation>;
  /** fetch aggregated fields from the table: "nb_soil_explanation" */
  nb_soil_explanation_aggregate: Nb_Soil_Explanation_Aggregate;
  /** fetch data from the table: "nb_soil_explanation" using primary key columns */
  nb_soil_explanation_by_pk?: Maybe<Nb_Soil_Explanation>;
  /** An array relationship */
  nb_soil_physics: Array<Nb_Soil_Physics>;
  /** An aggregate relationship */
  nb_soil_physics_aggregate: Nb_Soil_Physics_Aggregate;
  /** fetch data from the table: "nb_soil_physics" using primary key columns */
  nb_soil_physics_by_pk?: Maybe<Nb_Soil_Physics>;
  /** fetch data from the table: "nb_task_type" */
  nb_task_type: Array<Nb_Task_Type>;
  /** fetch aggregated fields from the table: "nb_task_type" */
  nb_task_type_aggregate: Nb_Task_Type_Aggregate;
  /** fetch data from the table: "nb_task_type" using primary key columns */
  nb_task_type_by_pk?: Maybe<Nb_Task_Type>;
  /** fetch data from the table: "nb_tasktype_company_link" */
  nb_tasktype_company_link: Array<Nb_Tasktype_Company_Link>;
  /** fetch aggregated fields from the table: "nb_tasktype_company_link" */
  nb_tasktype_company_link_aggregate: Nb_Tasktype_Company_Link_Aggregate;
  /** fetch data from the table: "nb_tasktype_company_link" using primary key columns */
  nb_tasktype_company_link_by_pk?: Maybe<Nb_Tasktype_Company_Link>;
  /** fetch data from the table: "nb_ufattr_link" */
  nb_ufattr_link: Array<Nb_Ufattr_Link>;
  /** fetch aggregated fields from the table: "nb_ufattr_link" */
  nb_ufattr_link_aggregate: Nb_Ufattr_Link_Aggregate;
  /** fetch data from the table: "nb_ufattr_link" using primary key columns */
  nb_ufattr_link_by_pk?: Maybe<Nb_Ufattr_Link>;
  /** fetch data from the table: "nb_ufg_link" */
  nb_ufg_link: Array<Nb_Ufg_Link>;
  /** fetch aggregated fields from the table: "nb_ufg_link" */
  nb_ufg_link_aggregate: Nb_Ufg_Link_Aggregate;
  /** fetch data from the table: "nb_ufg_link" using primary key columns */
  nb_ufg_link_by_pk?: Maybe<Nb_Ufg_Link>;
  /** fetch data from the table: "nb_ufg_member_link" */
  nb_ufg_member_link: Array<Nb_Ufg_Member_Link>;
  /** fetch aggregated fields from the table: "nb_ufg_member_link" */
  nb_ufg_member_link_aggregate: Nb_Ufg_Member_Link_Aggregate;
  /** fetch data from the table: "nb_ufg_member_link" using primary key columns */
  nb_ufg_member_link_by_pk?: Maybe<Nb_Ufg_Member_Link>;
  /** fetch data from the table: "nb_ufield" */
  nb_ufield: Array<Nb_Ufield>;
  /** fetch aggregated fields from the table: "nb_ufield" */
  nb_ufield_aggregate: Nb_Ufield_Aggregate;
  /** fetch data from the table: "nb_ufield_attribute" */
  nb_ufield_attribute: Array<Nb_Ufield_Attribute>;
  /** fetch aggregated fields from the table: "nb_ufield_attribute" */
  nb_ufield_attribute_aggregate: Nb_Ufield_Attribute_Aggregate;
  /** fetch data from the table: "nb_ufield_attribute" using primary key columns */
  nb_ufield_attribute_by_pk?: Maybe<Nb_Ufield_Attribute>;
  /** fetch data from the table: "nb_ufield" using primary key columns */
  nb_ufield_by_pk?: Maybe<Nb_Ufield>;
  /** fetch data from the table: "nb_ufield_company_link" */
  nb_ufield_company_link: Array<Nb_Ufield_Company_Link>;
  /** fetch aggregated fields from the table: "nb_ufield_company_link" */
  nb_ufield_company_link_aggregate: Nb_Ufield_Company_Link_Aggregate;
  /** fetch data from the table: "nb_ufield_company_link" using primary key columns */
  nb_ufield_company_link_by_pk?: Maybe<Nb_Ufield_Company_Link>;
  /** fetch data from the table: "nb_ufield_group" */
  nb_ufield_group: Array<Nb_Ufield_Group>;
  /** fetch aggregated fields from the table: "nb_ufield_group" */
  nb_ufield_group_aggregate: Nb_Ufield_Group_Aggregate;
  /** fetch data from the table: "nb_ufield_group" using primary key columns */
  nb_ufield_group_by_pk?: Maybe<Nb_Ufield_Group>;
  /** fetch data from the table: "nb_ufield_history" */
  nb_ufield_history: Array<Nb_Ufield_History>;
  /** fetch aggregated fields from the table: "nb_ufield_history" */
  nb_ufield_history_aggregate: Nb_Ufield_History_Aggregate;
  /** fetch data from the table: "nb_ufield_history" using primary key columns */
  nb_ufield_history_by_pk?: Maybe<Nb_Ufield_History>;
  /** fetch data from the table: "nb_vector_image_sensing" */
  nb_vector_image_sensing: Array<Nb_Vector_Image_Sensing>;
  /** fetch aggregated fields from the table: "nb_vector_image_sensing" */
  nb_vector_image_sensing_aggregate: Nb_Vector_Image_Sensing_Aggregate;
  /** fetch data from the table: "nb_vector_image_sensing" using primary key columns */
  nb_vector_image_sensing_by_pk?: Maybe<Nb_Vector_Image_Sensing>;
  /** fetch data from the table: "nb_vector_ims_size" */
  nb_vector_ims_size: Array<Nb_Vector_Ims_Size>;
  /** fetch aggregated fields from the table: "nb_vector_ims_size" */
  nb_vector_ims_size_aggregate: Nb_Vector_Ims_Size_Aggregate;
  /** fetch data from the table: "nb_water_gate_status_cplan_link" */
  nb_water_gate_status_cplan_link: Array<Nb_Water_Gate_Status_Cplan_Link>;
  /** fetch aggregated fields from the table: "nb_water_gate_status_cplan_link" */
  nb_water_gate_status_cplan_link_aggregate: Nb_Water_Gate_Status_Cplan_Link_Aggregate;
  /** fetch data from the table: "nb_water_gate_status_cplan_link" using primary key columns */
  nb_water_gate_status_cplan_link_by_pk?: Maybe<Nb_Water_Gate_Status_Cplan_Link>;
  /** fetch data from the table: "nb_weather_attr" */
  nb_weather_attr: Array<Nb_Weather_Attr>;
  /** fetch aggregated fields from the table: "nb_weather_attr" */
  nb_weather_attr_aggregate: Nb_Weather_Attr_Aggregate;
  /** fetch data from the table: "nb_weather_attr" using primary key columns */
  nb_weather_attr_by_pk?: Maybe<Nb_Weather_Attr>;
  /** An array relationship */
  nb_weather_data: Array<Nb_Weather_Data>;
  /** An aggregate relationship */
  nb_weather_data_aggregate: Nb_Weather_Data_Aggregate;
  /** fetch data from the table: "nb_weather_data" using primary key columns */
  nb_weather_data_by_pk?: Maybe<Nb_Weather_Data>;
  /** fetch data from the table: "nb_wide_orthoimg" */
  nb_wide_orthoimg: Array<Nb_Wide_Orthoimg>;
  /** fetch aggregated fields from the table: "nb_wide_orthoimg" */
  nb_wide_orthoimg_aggregate: Nb_Wide_Orthoimg_Aggregate;
  /** fetch data from the table: "nb_wide_orthoimg" using primary key columns */
  nb_wide_orthoimg_by_pk?: Maybe<Nb_Wide_Orthoimg>;
  /** fetch data from the table: "nb_wide_orthoimg_size" */
  nb_wide_orthoimg_size: Array<Nb_Wide_Orthoimg_Size>;
  /** fetch aggregated fields from the table: "nb_wide_orthoimg_size" */
  nb_wide_orthoimg_size_aggregate: Nb_Wide_Orthoimg_Size_Aggregate;
  /** fetch data from the table: "nb_working_machine" */
  nb_working_machine: Array<Nb_Working_Machine>;
  /** fetch aggregated fields from the table: "nb_working_machine" */
  nb_working_machine_aggregate: Nb_Working_Machine_Aggregate;
  /** fetch data from the table: "nb_working_machine" using primary key columns */
  nb_working_machine_by_pk?: Maybe<Nb_Working_Machine>;
  /** fetch data from the table: "nbb_picture_analysis_status" */
  nbb_picture_analysis_status: Array<Nbb_Picture_Analysis_Status>;
  /** fetch aggregated fields from the table: "nbb_picture_analysis_status" */
  nbb_picture_analysis_status_aggregate: Nbb_Picture_Analysis_Status_Aggregate;
  /** fetch data from the table: "nbb_picture_analysis_status" using primary key columns */
  nbb_picture_analysis_status_by_pk?: Maybe<Nbb_Picture_Analysis_Status>;
  /** fetch data from the table: "nbbe_microclimate" */
  nbbe_microclimate: Array<Nbbe_Microclimate>;
  /** fetch aggregated fields from the table: "nbbe_microclimate" */
  nbbe_microclimate_aggregate: Nbbe_Microclimate_Aggregate;
  /** fetch data from the table: "nbbe_microclimate" using primary key columns */
  nbbe_microclimate_by_pk?: Maybe<Nbbe_Microclimate>;
  /** fetch data from the table: "nbbe_orthoimg_type" */
  nbbe_orthoimg_type: Array<Nbbe_Orthoimg_Type>;
  /** fetch aggregated fields from the table: "nbbe_orthoimg_type" */
  nbbe_orthoimg_type_aggregate: Nbbe_Orthoimg_Type_Aggregate;
  /** fetch data from the table: "nbbe_orthoimg_type" using primary key columns */
  nbbe_orthoimg_type_by_pk?: Maybe<Nbbe_Orthoimg_Type>;
  /** fetch data from the table: "nbbe_weather_type" */
  nbbe_weather_type: Array<Nbbe_Weather_Type>;
  /** fetch aggregated fields from the table: "nbbe_weather_type" */
  nbbe_weather_type_aggregate: Nbbe_Weather_Type_Aggregate;
  /** fetch data from the table: "nbbe_weather_type" using primary key columns */
  nbbe_weather_type_by_pk?: Maybe<Nbbe_Weather_Type>;
  /** fetch data from the table: "nbpm_authorization" */
  nbpm_authorization: Array<Nbpm_Authorization>;
  /** fetch aggregated fields from the table: "nbpm_authorization" */
  nbpm_authorization_aggregate: Nbpm_Authorization_Aggregate;
  /** fetch data from the table: "nbpm_authorization" using primary key columns */
  nbpm_authorization_by_pk?: Maybe<Nbpm_Authorization>;
  /** fetch data from the table: "nbpm_contract_plan" */
  nbpm_contract_plan: Array<Nbpm_Contract_Plan>;
  /** fetch aggregated fields from the table: "nbpm_contract_plan" */
  nbpm_contract_plan_aggregate: Nbpm_Contract_Plan_Aggregate;
  /** fetch data from the table: "nbpm_contract_plan" using primary key columns */
  nbpm_contract_plan_by_pk?: Maybe<Nbpm_Contract_Plan>;
  /** fetch data from the table: "nbpm_contract_plan_link" */
  nbpm_contract_plan_link: Array<Nbpm_Contract_Plan_Link>;
  /** fetch aggregated fields from the table: "nbpm_contract_plan_link" */
  nbpm_contract_plan_link_aggregate: Nbpm_Contract_Plan_Link_Aggregate;
  /** fetch data from the table: "nbpm_contract_plan_link" using primary key columns */
  nbpm_contract_plan_link_by_pk?: Maybe<Nbpm_Contract_Plan_Link>;
  /** fetch data from the table: "nbpm_imsattr_authorization_link" */
  nbpm_imsattr_authorization_link: Array<Nbpm_Imsattr_Authorization_Link>;
  /** fetch aggregated fields from the table: "nbpm_imsattr_authorization_link" */
  nbpm_imsattr_authorization_link_aggregate: Nbpm_Imsattr_Authorization_Link_Aggregate;
  /** fetch data from the table: "nbpm_imsattr_authorization_link" using primary key columns */
  nbpm_imsattr_authorization_link_by_pk?: Maybe<Nbpm_Imsattr_Authorization_Link>;
  /** fetch data from the table: "nbpm_ipengine_authorization_link" */
  nbpm_ipengine_authorization_link: Array<Nbpm_Ipengine_Authorization_Link>;
  /** fetch aggregated fields from the table: "nbpm_ipengine_authorization_link" */
  nbpm_ipengine_authorization_link_aggregate: Nbpm_Ipengine_Authorization_Link_Aggregate;
  /** fetch data from the table: "nbpm_ipengine_authorization_link" using primary key columns */
  nbpm_ipengine_authorization_link_by_pk?: Maybe<Nbpm_Ipengine_Authorization_Link>;
  /** fetch data from the table: "nbpm_picanatype_authorization_link" */
  nbpm_picanatype_authorization_link: Array<Nbpm_Picanatype_Authorization_Link>;
  /** fetch aggregated fields from the table: "nbpm_picanatype_authorization_link" */
  nbpm_picanatype_authorization_link_aggregate: Nbpm_Picanatype_Authorization_Link_Aggregate;
  /** fetch data from the table: "nbpm_picanatype_authorization_link" using primary key columns */
  nbpm_picanatype_authorization_link_by_pk?: Maybe<Nbpm_Picanatype_Authorization_Link>;
  /** fetch data from the table: "nbpm_service_plan" */
  nbpm_service_plan: Array<Nbpm_Service_Plan>;
  /** fetch aggregated fields from the table: "nbpm_service_plan" */
  nbpm_service_plan_aggregate: Nbpm_Service_Plan_Aggregate;
  /** fetch data from the table: "nbpm_service_plan" using primary key columns */
  nbpm_service_plan_by_pk?: Maybe<Nbpm_Service_Plan>;
  /** fetch data from the table: "nbpm_service_plan_link" */
  nbpm_service_plan_link: Array<Nbpm_Service_Plan_Link>;
  /** fetch aggregated fields from the table: "nbpm_service_plan_link" */
  nbpm_service_plan_link_aggregate: Nbpm_Service_Plan_Link_Aggregate;
  /** fetch data from the table: "nbpm_service_plan_link" using primary key columns */
  nbpm_service_plan_link_by_pk?: Maybe<Nbpm_Service_Plan_Link>;
  /** fetch data from the table: "nbpm_user_attr_link" */
  nbpm_user_attr_link: Array<Nbpm_User_Attr_Link>;
  /** fetch aggregated fields from the table: "nbpm_user_attr_link" */
  nbpm_user_attr_link_aggregate: Nbpm_User_Attr_Link_Aggregate;
  /** fetch data from the table: "nbpm_user_attr_link" using primary key columns */
  nbpm_user_attr_link_by_pk?: Maybe<Nbpm_User_Attr_Link>;
  /** fetch data from the table: "nbpm_user_attribute" */
  nbpm_user_attribute: Array<Nbpm_User_Attribute>;
  /** fetch aggregated fields from the table: "nbpm_user_attribute" */
  nbpm_user_attribute_aggregate: Nbpm_User_Attribute_Aggregate;
  /** fetch data from the table: "nbpm_user_attribute" using primary key columns */
  nbpm_user_attribute_by_pk?: Maybe<Nbpm_User_Attribute>;
  /** fetch data from the table: "nbpm_user_cp_link_link" */
  nbpm_user_cp_link_link: Array<Nbpm_User_Cp_Link_Link>;
  /** fetch aggregated fields from the table: "nbpm_user_cp_link_link" */
  nbpm_user_cp_link_link_aggregate: Nbpm_User_Cp_Link_Link_Aggregate;
  /** fetch data from the table: "nbpm_user_cp_link_link" using primary key columns */
  nbpm_user_cp_link_link_by_pk?: Maybe<Nbpm_User_Cp_Link_Link>;
  /** fetch data from the table: "nbs_ipe_run_field" */
  nbs_ipe_run_field: Array<Nbs_Ipe_Run_Field>;
  /** fetch aggregated fields from the table: "nbs_ipe_run_field" */
  nbs_ipe_run_field_aggregate: Nbs_Ipe_Run_Field_Aggregate;
  /** fetch data from the table: "nbs_ipe_run_field" using primary key columns */
  nbs_ipe_run_field_by_pk?: Maybe<Nbs_Ipe_Run_Field>;
  /** fetch data from the table: "nbs_ipe_run_link_field" */
  nbs_ipe_run_link_field: Array<Nbs_Ipe_Run_Link_Field>;
  /** fetch aggregated fields from the table: "nbs_ipe_run_link_field" */
  nbs_ipe_run_link_field_aggregate: Nbs_Ipe_Run_Link_Field_Aggregate;
  /** fetch data from the table: "nbs_ipe_run_link_field" using primary key columns */
  nbs_ipe_run_link_field_by_pk?: Maybe<Nbs_Ipe_Run_Link_Field>;
  /** fetch data from the table: "nbs_ipe_run_link_wide" */
  nbs_ipe_run_link_wide: Array<Nbs_Ipe_Run_Link_Wide>;
  /** fetch aggregated fields from the table: "nbs_ipe_run_link_wide" */
  nbs_ipe_run_link_wide_aggregate: Nbs_Ipe_Run_Link_Wide_Aggregate;
  /** fetch data from the table: "nbs_ipe_run_link_wide" using primary key columns */
  nbs_ipe_run_link_wide_by_pk?: Maybe<Nbs_Ipe_Run_Link_Wide>;
  /** fetch data from the table: "nbs_ipe_run_wide" */
  nbs_ipe_run_wide: Array<Nbs_Ipe_Run_Wide>;
  /** fetch aggregated fields from the table: "nbs_ipe_run_wide" */
  nbs_ipe_run_wide_aggregate: Nbs_Ipe_Run_Wide_Aggregate;
  /** fetch data from the table: "nbs_ipe_run_wide" using primary key columns */
  nbs_ipe_run_wide_by_pk?: Maybe<Nbs_Ipe_Run_Wide>;
  /** fetch data from the table: "nbs_multipart_upload" */
  nbs_multipart_upload: Array<Nbs_Multipart_Upload>;
  /** fetch aggregated fields from the table: "nbs_multipart_upload" */
  nbs_multipart_upload_aggregate: Nbs_Multipart_Upload_Aggregate;
  /** fetch data from the table: "nbs_multipart_upload" using primary key columns */
  nbs_multipart_upload_by_pk?: Maybe<Nbs_Multipart_Upload>;
  /** fetch data from the table: "nbs_wide_orthoimg_load" */
  nbs_wide_orthoimg_load: Array<Nbs_Wide_Orthoimg_Load>;
  /** fetch aggregated fields from the table: "nbs_wide_orthoimg_load" */
  nbs_wide_orthoimg_load_aggregate: Nbs_Wide_Orthoimg_Load_Aggregate;
  /** fetch data from the table: "nbs_wide_orthoimg_load" using primary key columns */
  nbs_wide_orthoimg_load_by_pk?: Maybe<Nbs_Wide_Orthoimg_Load>;
  /** fetch data from the table: "nbtrk_context" */
  nbtrk_context: Array<Nbtrk_Context>;
  /** fetch aggregated fields from the table: "nbtrk_context" */
  nbtrk_context_aggregate: Nbtrk_Context_Aggregate;
  /** fetch data from the table: "nbtrk_context" using primary key columns */
  nbtrk_context_by_pk?: Maybe<Nbtrk_Context>;
  /** fetch data from the table: "nbtrk_eph_location" */
  nbtrk_eph_location: Array<Nbtrk_Eph_Location>;
  /** fetch aggregated fields from the table: "nbtrk_eph_location" */
  nbtrk_eph_location_aggregate: Nbtrk_Eph_Location_Aggregate;
  /** fetch data from the table: "nbtrk_eph_location" using primary key columns */
  nbtrk_eph_location_by_pk?: Maybe<Nbtrk_Eph_Location>;
  /** fetch data from the table: "nipe_engine_information" */
  nipe_engine_information: Array<Nipe_Engine_Information>;
  /** fetch aggregated fields from the table: "nipe_engine_information" */
  nipe_engine_information_aggregate: Nipe_Engine_Information_Aggregate;
  /** fetch data from the table: "nipe_engine_information" using primary key columns */
  nipe_engine_information_by_pk?: Maybe<Nipe_Engine_Information>;
  /** fetch data from the table: "nipe_engine_link_orthoimg_type" */
  nipe_engine_link_orthoimg_type: Array<Nipe_Engine_Link_Orthoimg_Type>;
  /** fetch aggregated fields from the table: "nipe_engine_link_orthoimg_type" */
  nipe_engine_link_orthoimg_type_aggregate: Nipe_Engine_Link_Orthoimg_Type_Aggregate;
  /** fetch data from the table: "nipe_engine_link_orthoimg_type" using primary key columns */
  nipe_engine_link_orthoimg_type_by_pk?: Maybe<Nipe_Engine_Link_Orthoimg_Type>;
  /** fetch data from the table: "nipe_engine_run_status" */
  nipe_engine_run_status: Array<Nipe_Engine_Run_Status>;
  /** fetch aggregated fields from the table: "nipe_engine_run_status" */
  nipe_engine_run_status_aggregate: Nipe_Engine_Run_Status_Aggregate;
  /** fetch data from the table: "nipe_engine_run_status" using primary key columns */
  nipe_engine_run_status_by_pk?: Maybe<Nipe_Engine_Run_Status>;
  /** fetch data from the table: "nipe_run_command" */
  nipe_run_command: Array<Nipe_Run_Command>;
  /** fetch aggregated fields from the table: "nipe_run_command" */
  nipe_run_command_aggregate: Nipe_Run_Command_Aggregate;
  /** fetch data from the table: "nipe_run_command" using primary key columns */
  nipe_run_command_by_pk?: Maybe<Nipe_Run_Command>;
  restSampleGetItem?: Maybe<SampleGetItemOutput>;
  restSampleHealth?: Maybe<SampleHealthOutput>;
  s3_private_list?: Maybe<Array<S3_Private_List_Output>>;
  s3_private_signed_url: S3_Private_Signed_Url_Output;
};


export type Query_RootGrowthSensingByTileArgs = {
  growthTypeId: Scalars['String'];
  tileX: Scalars['Int'];
  tileY: Scalars['Int'];
  userFieldGroupId: Scalars['String'];
  zoomLevel: Scalars['Int'];
};


export type Query_RootMasterFarmFieldByTileArgs = {
  tileX: Scalars['Int'];
  tileY: Scalars['Int'];
  zoomLevel: Scalars['Int'];
};


export type Query_RootApp_UserArgs = {
  distinct_on?: InputMaybe<Array<App_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Order_By>>;
  where?: InputMaybe<App_User_Bool_Exp>;
};


export type Query_RootApp_User_ActiveArgs = {
  distinct_on?: InputMaybe<Array<App_User_Active_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Active_Order_By>>;
  where?: InputMaybe<App_User_Active_Bool_Exp>;
};


export type Query_RootApp_User_Active_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_User_Active_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Active_Order_By>>;
  where?: InputMaybe<App_User_Active_Bool_Exp>;
};


export type Query_RootApp_User_Active_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootApp_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Order_By>>;
  where?: InputMaybe<App_User_Bool_Exp>;
};


export type Query_RootApp_User_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootApp_User_Property_PrivateArgs = {
  distinct_on?: InputMaybe<Array<App_User_Property_Private_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Property_Private_Order_By>>;
  where?: InputMaybe<App_User_Property_Private_Bool_Exp>;
};


export type Query_RootApp_User_Property_Private_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_User_Property_Private_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Property_Private_Order_By>>;
  where?: InputMaybe<App_User_Property_Private_Bool_Exp>;
};


export type Query_RootApp_User_Property_Private_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootApplication_User_Invitation_To_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


export type Query_RootApplication_User_Invitation_To_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


export type Query_RootApplication_User_Invitation_To_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFastapiFieldPictureLoadStatusFailureArgs = {
  nbCompanyId: Scalars['UUID'];
};


export type Query_RootFastapiFieldPictureLoadStatusLoadedArgs = {
  nbCompanyId: Scalars['UUID'];
};


export type Query_RootFastapiGetMasterFieldPolygonsByBboxArgs = {
  bbox: Array<Scalars['Float']>;
};


export type Query_RootFastapiGetPictureAnalysisArgs = {
  fetchType: Scalars['String'];
  id: Scalars['UUID'];
  nbPictureAnalysisTypeId: Scalars['UUID'];
};


export type Query_RootFastapiGetPictureAnalysisStatusArgs = {
  nbFieldPictureId: Array<Scalars['UUID']>;
  nbPictureAnalysisTypeId: Scalars['UUID'];
};


export type Query_RootFastapiHelloArgs = {
  name: Scalars['String'];
};


export type Query_RootFastapiResumeMultipartUploadArgs = {
  companyId: Scalars['UUID'];
  uploadId: Scalars['String'];
};


export type Query_RootFastapiS3SignedUrlTrackerApksArgs = {
  companyId: Scalars['UUID'];
};


export type Query_RootFastapiWideOrthoimgLoadSuspendedArgs = {
  companyId: Scalars['UUID'];
};


export type Query_RootMaster_Nb_Area_MeshArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Area_Mesh_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Area_Mesh_Order_By>>;
  where?: InputMaybe<Master_Nb_Area_Mesh_Bool_Exp>;
};


export type Query_RootMaster_Nb_Area_Mesh_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Area_Mesh_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Area_Mesh_Order_By>>;
  where?: InputMaybe<Master_Nb_Area_Mesh_Bool_Exp>;
};


export type Query_RootMaster_Nb_Area_Mesh_By_PkArgs = {
  code: Scalars['bigint'];
};


export type Query_RootMaster_Nb_Integrated_FarmlandArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Integrated_Farmland_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Integrated_Farmland_Order_By>>;
  where?: InputMaybe<Master_Nb_Integrated_Farmland_Bool_Exp>;
};


export type Query_RootMaster_Nb_Integrated_Farmland_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Integrated_Farmland_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Integrated_Farmland_Order_By>>;
  where?: InputMaybe<Master_Nb_Integrated_Farmland_Bool_Exp>;
};


export type Query_RootMaster_Nb_Integrated_Farmland_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Query_RootMaster_Nb_MfieldArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};


export type Query_RootMaster_Nb_Mfield_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};


export type Query_RootMaster_Nb_Mfield_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Query_RootMaster_Nb_Mfield_Polygon_2022_1Args = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2022_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2022_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Bool_Exp>;
};


export type Query_RootMaster_Nb_Mfield_Polygon_2022_1_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2022_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2022_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Bool_Exp>;
};


export type Query_RootMaster_Nb_Mfield_Polygon_2022_1_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Query_RootMaster_Nb_Mfield_Polygon_2023_1Args = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2023_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2023_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Bool_Exp>;
};


export type Query_RootMaster_Nb_Mfield_Polygon_2023_1_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2023_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2023_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Bool_Exp>;
};


export type Query_RootMaster_Nb_Mfield_Polygon_2023_1_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Query_RootMaster_Nb_Mfield_Polygon_2024_1Args = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2024_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2024_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Bool_Exp>;
};


export type Query_RootMaster_Nb_Mfield_Polygon_2024_1_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2024_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2024_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Bool_Exp>;
};


export type Query_RootMaster_Nb_Mfield_Polygon_2024_1_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Query_RootMaster_Nb_Mfield_VersionArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Version_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Version_Bool_Exp>;
};


export type Query_RootMaster_Nb_Mfield_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Version_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Version_Bool_Exp>;
};


export type Query_RootMaster_Nb_Mfield_Version_By_PkArgs = {
  id: Scalars['smallint'];
};


export type Query_RootMaster_Nb_Postgis_VersionArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Postgis_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Postgis_Version_Order_By>>;
  where?: InputMaybe<Master_Nb_Postgis_Version_Bool_Exp>;
};


export type Query_RootMaster_Nb_Postgis_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Postgis_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Postgis_Version_Order_By>>;
  where?: InputMaybe<Master_Nb_Postgis_Version_Bool_Exp>;
};


export type Query_RootMaster_Nb_Postgis_Version_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMaster_Nb_Weather_AttrArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Attr_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Attr_Bool_Exp>;
};


export type Query_RootMaster_Nb_Weather_Attr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Attr_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Attr_Bool_Exp>;
};


export type Query_RootMaster_Nb_Weather_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMaster_Nb_Weather_DataArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
};


export type Query_RootMaster_Nb_Weather_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
};


export type Query_RootMaster_Nb_Weather_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Actual_Crop_YeildArgs = {
  distinct_on?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Order_By>>;
  where?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
};


export type Query_RootNb_Actual_Crop_Yeild_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Order_By>>;
  where?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
};


export type Query_RootNb_Actual_Crop_Yeild_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Area_MeshArgs = {
  distinct_on?: InputMaybe<Array<Nb_Area_Mesh_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Area_Mesh_Order_By>>;
  where?: InputMaybe<Nb_Area_Mesh_Bool_Exp>;
};


export type Query_RootNb_Area_Mesh_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Area_Mesh_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Area_Mesh_Order_By>>;
  where?: InputMaybe<Nb_Area_Mesh_Bool_Exp>;
};


export type Query_RootNb_Area_Mesh_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Order_By>>;
  where?: InputMaybe<Nb_Company_Bool_Exp>;
};


export type Query_RootNb_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Order_By>>;
  where?: InputMaybe<Nb_Company_Bool_Exp>;
};


export type Query_RootNb_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Company_InfoArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Info_Order_By>>;
  where?: InputMaybe<Nb_Company_Info_Bool_Exp>;
};


export type Query_RootNb_Company_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Info_Order_By>>;
  where?: InputMaybe<Nb_Company_Info_Bool_Exp>;
};


export type Query_RootNb_Company_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Conditioner_WorkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


export type Query_RootNb_Conditioner_Work_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


export type Query_RootNb_Conditioner_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Cplan_Imsensing_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Order_By>>;
  where?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
};


export type Query_RootNb_Cplan_Imsensing_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Order_By>>;
  where?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
};


export type Query_RootNb_Cplan_Imsensing_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_CropArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Order_By>>;
  where?: InputMaybe<Nb_Crop_Bool_Exp>;
};


export type Query_RootNb_Crop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Order_By>>;
  where?: InputMaybe<Nb_Crop_Bool_Exp>;
};


export type Query_RootNb_Crop_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Crop_Company_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
};


export type Query_RootNb_Crop_Company_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
};


export type Query_RootNb_Crop_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Crop_Method_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Method_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Method_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
};


export type Query_RootNb_Crop_Method_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Method_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Method_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
};


export type Query_RootNb_Crop_Method_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_CultivarArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivar_Order_By>>;
  where?: InputMaybe<Nb_Cultivar_Bool_Exp>;
};


export type Query_RootNb_Cultivar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivar_Order_By>>;
  where?: InputMaybe<Nb_Cultivar_Bool_Exp>;
};


export type Query_RootNb_Cultivar_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Cultivation_MethodArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Method_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Method_Bool_Exp>;
};


export type Query_RootNb_Cultivation_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Method_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Method_Bool_Exp>;
};


export type Query_RootNb_Cultivation_Method_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Cultivation_PlanArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


export type Query_RootNb_Cultivation_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


export type Query_RootNb_Cultivation_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Demo_Task_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_Task_Type_Order_By>>;
  where?: InputMaybe<Nb_Demo_Task_Type_Bool_Exp>;
};


export type Query_RootNb_Demo_Task_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_Task_Type_Order_By>>;
  where?: InputMaybe<Nb_Demo_Task_Type_Bool_Exp>;
};


export type Query_RootNb_Demo_Task_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootNb_Demo_User_LocationArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Location_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
};


export type Query_RootNb_Demo_User_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Location_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
};


export type Query_RootNb_Demo_User_Location_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Demo_User_NameArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Name_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
};


export type Query_RootNb_Demo_User_Name_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Name_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
};


export type Query_RootNb_Demo_User_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootNb_Est_Working_LoadArgs = {
  distinct_on?: InputMaybe<Array<Nb_Est_Working_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Est_Working_Load_Order_By>>;
  where?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
};


export type Query_RootNb_Est_Working_Load_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Est_Working_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Est_Working_Load_Order_By>>;
  where?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
};


export type Query_RootNb_Est_Working_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Fertilization_MethodArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Method_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Method_Bool_Exp>;
};


export type Query_RootNb_Fertilization_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Method_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Method_Bool_Exp>;
};


export type Query_RootNb_Fertilization_Method_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Fertilization_WorkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


export type Query_RootNb_Fertilization_Work_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


export type Query_RootNb_Fertilization_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_FertilizerArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Bool_Exp>;
};


export type Query_RootNb_Fertilizer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Bool_Exp>;
};


export type Query_RootNb_Fertilizer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Fertilizer_Company_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
};


export type Query_RootNb_Fertilizer_Company_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
};


export type Query_RootNb_Fertilizer_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Fertilizer_PictureArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Picture_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Picture_Bool_Exp>;
};


export type Query_RootNb_Fertilizer_Picture_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Picture_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Picture_Bool_Exp>;
};


export type Query_RootNb_Fertilizer_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_FileArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Bool_Exp>;
};


export type Query_RootNb_Field_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Bool_Exp>;
};


export type Query_RootNb_Field_File_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_File_LoadArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


export type Query_RootNb_Field_File_Load_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


export type Query_RootNb_Field_File_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_File_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Size_Bool_Exp>;
};


export type Query_RootNb_Field_File_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Size_Bool_Exp>;
};


export type Query_RootNb_Field_MemoArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Memo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Memo_Order_By>>;
  where?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
};


export type Query_RootNb_Field_Memo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Memo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Memo_Order_By>>;
  where?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
};


export type Query_RootNb_Field_Memo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_OrthoimgArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};


export type Query_RootNb_Field_Orthoimg_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};


export type Query_RootNb_Field_Orthoimg_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_Orthoimg_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Size_Bool_Exp>;
};


export type Query_RootNb_Field_Orthoimg_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Size_Bool_Exp>;
};


export type Query_RootNb_Field_PhotographerArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Photographer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Photographer_Order_By>>;
  where?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
};


export type Query_RootNb_Field_Photographer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Photographer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Photographer_Order_By>>;
  where?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
};


export type Query_RootNb_Field_Photographer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_PictureArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
};


export type Query_RootNb_Field_Picture_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
};


export type Query_RootNb_Field_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_Picture_Cplan_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
};


export type Query_RootNb_Field_Picture_Cplan_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
};


export type Query_RootNb_Field_Picture_Cplan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_Picture_LoadArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


export type Query_RootNb_Field_Picture_Load_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


export type Query_RootNb_Field_Picture_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_Picture_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Size_Bool_Exp>;
};


export type Query_RootNb_Field_Picture_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Size_Bool_Exp>;
};


export type Query_RootNb_Field_Polygon_AttrArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Polygon_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Polygon_Attr_Order_By>>;
  where?: InputMaybe<Nb_Field_Polygon_Attr_Bool_Exp>;
};


export type Query_RootNb_Field_Polygon_Attr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Polygon_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Polygon_Attr_Order_By>>;
  where?: InputMaybe<Nb_Field_Polygon_Attr_Bool_Exp>;
};


export type Query_RootNb_Field_Polygon_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_PropertyArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Property_Order_By>>;
  where?: InputMaybe<Nb_Field_Property_Bool_Exp>;
};


export type Query_RootNb_Field_Property_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Property_Order_By>>;
  where?: InputMaybe<Nb_Field_Property_Bool_Exp>;
};


export type Query_RootNb_Field_Property_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_SensingArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
};


export type Query_RootNb_Field_Sensing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
};


export type Query_RootNb_Field_Sensing_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_Sensing_DataArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Data_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Data_Bool_Exp>;
};


export type Query_RootNb_Field_Sensing_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Data_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Data_Bool_Exp>;
};


export type Query_RootNb_Field_Sensing_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_Water_GateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
};


export type Query_RootNb_Field_Water_Gate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
};


export type Query_RootNb_Field_Water_Gate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Field_Water_Gate_StatusArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
};


export type Query_RootNb_Field_Water_Gate_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
};


export type Query_RootNb_Field_Water_Gate_Status_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_File_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};


export type Query_RootNb_File_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};


export type Query_RootNb_File_Size_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_File_Size_Company_User_ViewArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Company_User_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Company_User_View_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Company_User_View_Bool_Exp>;
};


export type Query_RootNb_File_Size_Company_User_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Company_User_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Company_User_View_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Company_User_View_Bool_Exp>;
};


export type Query_RootNb_File_Size_Company_ViewArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Company_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Company_View_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Company_View_Bool_Exp>;
};


export type Query_RootNb_File_Size_Company_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Company_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Company_View_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Company_View_Bool_Exp>;
};


export type Query_RootNb_File_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Type_Order_By>>;
  where?: InputMaybe<Nb_File_Type_Bool_Exp>;
};


export type Query_RootNb_File_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Type_Order_By>>;
  where?: InputMaybe<Nb_File_Type_Bool_Exp>;
};


export type Query_RootNb_File_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootNb_Finished_Ufield_WorkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


export type Query_RootNb_Finished_Ufield_Work_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


export type Query_RootNb_Finished_Ufield_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Image_Sensing_AttrArgs = {
  distinct_on?: InputMaybe<Array<Nb_Image_Sensing_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Image_Sensing_Attr_Order_By>>;
  where?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
};


export type Query_RootNb_Image_Sensing_Attr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Image_Sensing_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Image_Sensing_Attr_Order_By>>;
  where?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
};


export type Query_RootNb_Image_Sensing_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Imsensing_ColormapArgs = {
  distinct_on?: InputMaybe<Array<Nb_Imsensing_Colormap_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Imsensing_Colormap_Order_By>>;
  where?: InputMaybe<Nb_Imsensing_Colormap_Bool_Exp>;
};


export type Query_RootNb_Imsensing_Colormap_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Imsensing_Colormap_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Imsensing_Colormap_Order_By>>;
  where?: InputMaybe<Nb_Imsensing_Colormap_Bool_Exp>;
};


export type Query_RootNb_Imsensing_Colormap_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Log_User_PositionArgs = {
  distinct_on?: InputMaybe<Array<Nb_Log_User_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Log_User_Position_Order_By>>;
  where?: InputMaybe<Nb_Log_User_Position_Bool_Exp>;
};


export type Query_RootNb_Log_User_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Log_User_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Log_User_Position_Order_By>>;
  where?: InputMaybe<Nb_Log_User_Position_Bool_Exp>;
};


export type Query_RootNb_Log_User_Position_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Maff_FieldArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
};


export type Query_RootNb_Maff_Field_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
};


export type Query_RootNb_Maff_Field_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Maff_Field_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Link_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
};


export type Query_RootNb_Maff_Field_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Link_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
};


export type Query_RootNb_Maff_Field_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Memo_File_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_File_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_File_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
};


export type Query_RootNb_Memo_File_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_File_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_File_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
};


export type Query_RootNb_Memo_File_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Memo_Picture_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Picture_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Picture_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
};


export type Query_RootNb_Memo_Picture_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Picture_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Picture_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
};


export type Query_RootNb_Memo_Picture_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Memo_Ufg_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
};


export type Query_RootNb_Memo_Ufg_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
};


export type Query_RootNb_Memo_Ufg_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Memo_Ufield_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufield_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
};


export type Query_RootNb_Memo_Ufield_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufield_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
};


export type Query_RootNb_Memo_Ufield_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_MfieldArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Bool_Exp>;
};


export type Query_RootNb_Mfield_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Bool_Exp>;
};


export type Query_RootNb_Mfield_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Mfield_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Link_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};


export type Query_RootNb_Mfield_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Link_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};


export type Query_RootNb_Mfield_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Performed_WorkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


export type Query_RootNb_Performed_Work_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


export type Query_RootNb_Performed_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_PesticideArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Bool_Exp>;
};


export type Query_RootNb_Pesticide_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Bool_Exp>;
};


export type Query_RootNb_Pesticide_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Pesticide_Company_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
};


export type Query_RootNb_Pesticide_Company_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
};


export type Query_RootNb_Pesticide_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Pesticide_IngredientArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Ingredient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Ingredient_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Ingredient_Bool_Exp>;
};


export type Query_RootNb_Pesticide_Ingredient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Ingredient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Ingredient_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Ingredient_Bool_Exp>;
};


export type Query_RootNb_Pesticide_Ingredient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Pesticide_Spray_WorkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
};


export type Query_RootNb_Pesticide_Spray_Work_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
};


export type Query_RootNb_Pesticide_Spray_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Picture_AnalysisArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
};


export type Query_RootNb_Picture_Analysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
};


export type Query_RootNb_Picture_Analysis_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Picture_Analysis_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Type_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Type_Bool_Exp>;
};


export type Query_RootNb_Picture_Analysis_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Type_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Type_Bool_Exp>;
};


export type Query_RootNb_Picture_Analysis_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Raster_Image_SensingArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};


export type Query_RootNb_Raster_Image_Sensing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};


export type Query_RootNb_Raster_Image_Sensing_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Raster_Ims_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Raster_Ims_Size_Bool_Exp>;
};


export type Query_RootNb_Raster_Ims_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Raster_Ims_Size_Bool_Exp>;
};


export type Query_RootNb_Soil_Chara_MapArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chara_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chara_Map_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Bool_Exp>;
};


export type Query_RootNb_Soil_Chara_Map_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chara_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chara_Map_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Bool_Exp>;
};


export type Query_RootNb_Soil_Chara_Map_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Soil_Chara_Map_SellistArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chara_Map_Sellist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chara_Map_Sellist_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Bool_Exp>;
};


export type Query_RootNb_Soil_Chara_Map_Sellist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chara_Map_Sellist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chara_Map_Sellist_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Bool_Exp>;
};


export type Query_RootNb_Soil_Chara_Map_Sellist_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Soil_CharacteristicArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Characteristic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Characteristic_Order_By>>;
  where?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
};


export type Query_RootNb_Soil_Characteristic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Characteristic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Characteristic_Order_By>>;
  where?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
};


export type Query_RootNb_Soil_Characteristic_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Soil_ChemistryArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


export type Query_RootNb_Soil_Chemistry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


export type Query_RootNb_Soil_Chemistry_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Soil_Chemistry_Proper_RangeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
};


export type Query_RootNb_Soil_Chemistry_Proper_Range_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
};


export type Query_RootNb_Soil_Chemistry_Proper_Range_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Soil_Chemistry_StandardsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
};


export type Query_RootNb_Soil_Chemistry_Standards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
};


export type Query_RootNb_Soil_Chemistry_Standards_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Soil_Chemistry_Suitable_RangeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Suitable_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Suitable_Range_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Bool_Exp>;
};


export type Query_RootNb_Soil_Chemistry_Suitable_Range_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Suitable_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Suitable_Range_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Bool_Exp>;
};


export type Query_RootNb_Soil_Chemistry_Suitable_Range_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Soil_Chemistry_ValueArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
};


export type Query_RootNb_Soil_Chemistry_Value_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
};


export type Query_RootNb_Soil_Chemistry_Value_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Soil_ExplanationArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Explanation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Explanation_Order_By>>;
  where?: InputMaybe<Nb_Soil_Explanation_Bool_Exp>;
};


export type Query_RootNb_Soil_Explanation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Explanation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Explanation_Order_By>>;
  where?: InputMaybe<Nb_Soil_Explanation_Bool_Exp>;
};


export type Query_RootNb_Soil_Explanation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Soil_PhysicsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Physics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Physics_Order_By>>;
  where?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
};


export type Query_RootNb_Soil_Physics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Physics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Physics_Order_By>>;
  where?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
};


export type Query_RootNb_Soil_Physics_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Task_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Task_Type_Order_By>>;
  where?: InputMaybe<Nb_Task_Type_Bool_Exp>;
};


export type Query_RootNb_Task_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Task_Type_Order_By>>;
  where?: InputMaybe<Nb_Task_Type_Bool_Exp>;
};


export type Query_RootNb_Task_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootNb_Tasktype_Company_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Tasktype_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Tasktype_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
};


export type Query_RootNb_Tasktype_Company_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Tasktype_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Tasktype_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
};


export type Query_RootNb_Tasktype_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Ufattr_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufattr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufattr_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
};


export type Query_RootNb_Ufattr_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufattr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufattr_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
};


export type Query_RootNb_Ufattr_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Ufg_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
};


export type Query_RootNb_Ufg_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
};


export type Query_RootNb_Ufg_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Ufg_Member_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Member_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Member_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
};


export type Query_RootNb_Ufg_Member_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Member_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Member_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
};


export type Query_RootNb_Ufg_Member_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_UfieldArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Bool_Exp>;
};


export type Query_RootNb_Ufield_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Bool_Exp>;
};


export type Query_RootNb_Ufield_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Attribute_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
};


export type Query_RootNb_Ufield_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Attribute_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
};


export type Query_RootNb_Ufield_Attribute_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Ufield_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Ufield_Company_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
};


export type Query_RootNb_Ufield_Company_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
};


export type Query_RootNb_Ufield_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Ufield_GroupArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Group_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
};


export type Query_RootNb_Ufield_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Group_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
};


export type Query_RootNb_Ufield_Group_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Ufield_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_History_Order_By>>;
  where?: InputMaybe<Nb_Ufield_History_Bool_Exp>;
};


export type Query_RootNb_Ufield_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_History_Order_By>>;
  where?: InputMaybe<Nb_Ufield_History_Bool_Exp>;
};


export type Query_RootNb_Ufield_History_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Vector_Image_SensingArgs = {
  distinct_on?: InputMaybe<Array<Nb_Vector_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Vector_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Vector_Image_Sensing_Bool_Exp>;
};


export type Query_RootNb_Vector_Image_Sensing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Vector_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Vector_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Vector_Image_Sensing_Bool_Exp>;
};


export type Query_RootNb_Vector_Image_Sensing_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Vector_Ims_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Vector_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Vector_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Vector_Ims_Size_Bool_Exp>;
};


export type Query_RootNb_Vector_Ims_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Vector_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Vector_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Vector_Ims_Size_Bool_Exp>;
};


export type Query_RootNb_Water_Gate_Status_Cplan_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
};


export type Query_RootNb_Water_Gate_Status_Cplan_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
};


export type Query_RootNb_Water_Gate_Status_Cplan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Weather_AttrArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Attr_Order_By>>;
  where?: InputMaybe<Nb_Weather_Attr_Bool_Exp>;
};


export type Query_RootNb_Weather_Attr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Attr_Order_By>>;
  where?: InputMaybe<Nb_Weather_Attr_Bool_Exp>;
};


export type Query_RootNb_Weather_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Weather_DataArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
};


export type Query_RootNb_Weather_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
};


export type Query_RootNb_Weather_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Wide_OrthoimgArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};


export type Query_RootNb_Wide_Orthoimg_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};


export type Query_RootNb_Wide_Orthoimg_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNb_Wide_Orthoimg_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Size_Bool_Exp>;
};


export type Query_RootNb_Wide_Orthoimg_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Size_Bool_Exp>;
};


export type Query_RootNb_Working_MachineArgs = {
  distinct_on?: InputMaybe<Array<Nb_Working_Machine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Working_Machine_Order_By>>;
  where?: InputMaybe<Nb_Working_Machine_Bool_Exp>;
};


export type Query_RootNb_Working_Machine_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Working_Machine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Working_Machine_Order_By>>;
  where?: InputMaybe<Nb_Working_Machine_Bool_Exp>;
};


export type Query_RootNb_Working_Machine_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbb_Picture_Analysis_StatusArgs = {
  distinct_on?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Order_By>>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};


export type Query_RootNbb_Picture_Analysis_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Order_By>>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};


export type Query_RootNbb_Picture_Analysis_Status_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbbe_MicroclimateArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Microclimate_Order_By>>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};


export type Query_RootNbbe_Microclimate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Microclimate_Order_By>>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};


export type Query_RootNbbe_Microclimate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbbe_Orthoimg_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nbbe_Orthoimg_Type_Bool_Exp>;
};


export type Query_RootNbbe_Orthoimg_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nbbe_Orthoimg_Type_Bool_Exp>;
};


export type Query_RootNbbe_Orthoimg_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootNbbe_Weather_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Weather_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Weather_Type_Order_By>>;
  where?: InputMaybe<Nbbe_Weather_Type_Bool_Exp>;
};


export type Query_RootNbbe_Weather_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Weather_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Weather_Type_Order_By>>;
  where?: InputMaybe<Nbbe_Weather_Type_Bool_Exp>;
};


export type Query_RootNbbe_Weather_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootNbpm_AuthorizationArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Authorization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Authorization_Order_By>>;
  where?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
};


export type Query_RootNbpm_Authorization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Authorization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Authorization_Order_By>>;
  where?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
};


export type Query_RootNbpm_Authorization_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbpm_Contract_PlanArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
};


export type Query_RootNbpm_Contract_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
};


export type Query_RootNbpm_Contract_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbpm_Contract_Plan_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
};


export type Query_RootNbpm_Contract_Plan_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
};


export type Query_RootNbpm_Contract_Plan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbpm_Imsattr_Authorization_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
};


export type Query_RootNbpm_Imsattr_Authorization_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
};


export type Query_RootNbpm_Imsattr_Authorization_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbpm_Ipengine_Authorization_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
};


export type Query_RootNbpm_Ipengine_Authorization_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
};


export type Query_RootNbpm_Ipengine_Authorization_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbpm_Picanatype_Authorization_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
};


export type Query_RootNbpm_Picanatype_Authorization_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
};


export type Query_RootNbpm_Picanatype_Authorization_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbpm_Service_PlanArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Bool_Exp>;
};


export type Query_RootNbpm_Service_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Bool_Exp>;
};


export type Query_RootNbpm_Service_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbpm_Service_Plan_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};


export type Query_RootNbpm_Service_Plan_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};


export type Query_RootNbpm_Service_Plan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbpm_User_Attr_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attr_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};


export type Query_RootNbpm_User_Attr_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attr_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};


export type Query_RootNbpm_User_Attr_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbpm_User_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attribute_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attribute_Bool_Exp>;
};


export type Query_RootNbpm_User_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attribute_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attribute_Bool_Exp>;
};


export type Query_RootNbpm_User_Attribute_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbpm_User_Cp_Link_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
};


export type Query_RootNbpm_User_Cp_Link_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
};


export type Query_RootNbpm_User_Cp_Link_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbs_Ipe_Run_FieldArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
};


export type Query_RootNbs_Ipe_Run_Field_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
};


export type Query_RootNbs_Ipe_Run_Field_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbs_Ipe_Run_Link_FieldArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
};


export type Query_RootNbs_Ipe_Run_Link_Field_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
};


export type Query_RootNbs_Ipe_Run_Link_Field_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbs_Ipe_Run_Link_WideArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
};


export type Query_RootNbs_Ipe_Run_Link_Wide_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
};


export type Query_RootNbs_Ipe_Run_Link_Wide_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbs_Ipe_Run_WideArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};


export type Query_RootNbs_Ipe_Run_Wide_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};


export type Query_RootNbs_Ipe_Run_Wide_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbs_Multipart_UploadArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Multipart_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Multipart_Upload_Order_By>>;
  where?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
};


export type Query_RootNbs_Multipart_Upload_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Multipart_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Multipart_Upload_Order_By>>;
  where?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
};


export type Query_RootNbs_Multipart_Upload_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbs_Wide_Orthoimg_LoadArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


export type Query_RootNbs_Wide_Orthoimg_Load_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


export type Query_RootNbs_Wide_Orthoimg_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbtrk_ContextArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Context_Order_By>>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};


export type Query_RootNbtrk_Context_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Context_Order_By>>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};


export type Query_RootNbtrk_Context_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNbtrk_Eph_LocationArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Eph_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Eph_Location_Order_By>>;
  where?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
};


export type Query_RootNbtrk_Eph_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Eph_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Eph_Location_Order_By>>;
  where?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
};


export type Query_RootNbtrk_Eph_Location_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNipe_Engine_InformationArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Information_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
};


export type Query_RootNipe_Engine_Information_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Information_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
};


export type Query_RootNipe_Engine_Information_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNipe_Engine_Link_Orthoimg_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
};


export type Query_RootNipe_Engine_Link_Orthoimg_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
};


export type Query_RootNipe_Engine_Link_Orthoimg_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNipe_Engine_Run_StatusArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Run_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Run_Status_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Run_Status_Bool_Exp>;
};


export type Query_RootNipe_Engine_Run_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Run_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Run_Status_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Run_Status_Bool_Exp>;
};


export type Query_RootNipe_Engine_Run_Status_By_PkArgs = {
  status: Scalars['String'];
};


export type Query_RootNipe_Run_CommandArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Run_Command_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Run_Command_Order_By>>;
  where?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
};


export type Query_RootNipe_Run_Command_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Run_Command_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Run_Command_Order_By>>;
  where?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
};


export type Query_RootNipe_Run_Command_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRestSampleGetItemArgs = {
  item_id: Scalars['Int'];
  quantity: Scalars['Int'];
};


export type Query_RootS3_Private_ListArgs = {
  input: S3_Private_List_Input;
};


export type Query_RootS3_Private_Signed_UrlArgs = {
  input: S3_Private_Signed_Url_Input;
};

export type S3_Private_List_Input = {
  prefix?: InputMaybe<Scalars['String']>;
};

export type S3_Private_List_Output = {
  __typename?: 's3_private_list_output';
  key: Scalars['String'];
  last_modified: Scalars['DateTime'];
  size: Scalars['Int'];
};

export type S3_Private_Signed_Url_Input = {
  command: Scalars['String'];
  expires_in?: InputMaybe<Scalars['Int']>;
  path: Scalars['String'];
};

export type S3_Private_Signed_Url_Output = {
  __typename?: 's3_private_signed_url_output';
  signed_url: Scalars['String'];
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: InputMaybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "app_user" */
  app_user: Array<App_User>;
  /** fetch data from the table: "app_user_active" */
  app_user_active: Array<App_User_Active>;
  /** fetch aggregated fields from the table: "app_user_active" */
  app_user_active_aggregate: App_User_Active_Aggregate;
  /** fetch data from the table: "app_user_active" using primary key columns */
  app_user_active_by_pk?: Maybe<App_User_Active>;
  /** fetch data from the table in a streaming manner: "app_user_active" */
  app_user_active_stream: Array<App_User_Active>;
  /** fetch aggregated fields from the table: "app_user" */
  app_user_aggregate: App_User_Aggregate;
  /** fetch data from the table: "app_user" using primary key columns */
  app_user_by_pk?: Maybe<App_User>;
  /** fetch data from the table: "app_user_property_private" */
  app_user_property_private: Array<App_User_Property_Private>;
  /** fetch aggregated fields from the table: "app_user_property_private" */
  app_user_property_private_aggregate: App_User_Property_Private_Aggregate;
  /** fetch data from the table: "app_user_property_private" using primary key columns */
  app_user_property_private_by_pk?: Maybe<App_User_Property_Private>;
  /** fetch data from the table in a streaming manner: "app_user_property_private" */
  app_user_property_private_stream: Array<App_User_Property_Private>;
  /** fetch data from the table in a streaming manner: "app_user" */
  app_user_stream: Array<App_User>;
  /** fetch data from the table: "application_user_invitation_to_company" */
  application_user_invitation_to_company: Array<Application_User_Invitation_To_Company>;
  /** fetch aggregated fields from the table: "application_user_invitation_to_company" */
  application_user_invitation_to_company_aggregate: Application_User_Invitation_To_Company_Aggregate;
  /** fetch data from the table: "application_user_invitation_to_company" using primary key columns */
  application_user_invitation_to_company_by_pk?: Maybe<Application_User_Invitation_To_Company>;
  /** fetch data from the table in a streaming manner: "application_user_invitation_to_company" */
  application_user_invitation_to_company_stream: Array<Application_User_Invitation_To_Company>;
  /** fetch data from the table: "master.nb_area_mesh" */
  master_nb_area_mesh: Array<Master_Nb_Area_Mesh>;
  /** fetch aggregated fields from the table: "master.nb_area_mesh" */
  master_nb_area_mesh_aggregate: Master_Nb_Area_Mesh_Aggregate;
  /** fetch data from the table: "master.nb_area_mesh" using primary key columns */
  master_nb_area_mesh_by_pk?: Maybe<Master_Nb_Area_Mesh>;
  /** fetch data from the table in a streaming manner: "master.nb_area_mesh" */
  master_nb_area_mesh_stream: Array<Master_Nb_Area_Mesh>;
  /** fetch data from the table: "master.nb_integrated_farmland" */
  master_nb_integrated_farmland: Array<Master_Nb_Integrated_Farmland>;
  /** fetch aggregated fields from the table: "master.nb_integrated_farmland" */
  master_nb_integrated_farmland_aggregate: Master_Nb_Integrated_Farmland_Aggregate;
  /** fetch data from the table: "master.nb_integrated_farmland" using primary key columns */
  master_nb_integrated_farmland_by_pk?: Maybe<Master_Nb_Integrated_Farmland>;
  /** fetch data from the table in a streaming manner: "master.nb_integrated_farmland" */
  master_nb_integrated_farmland_stream: Array<Master_Nb_Integrated_Farmland>;
  /** fetch data from the table: "master.nb_mfield" */
  master_nb_mfield: Array<Master_Nb_Mfield>;
  /** fetch aggregated fields from the table: "master.nb_mfield" */
  master_nb_mfield_aggregate: Master_Nb_Mfield_Aggregate;
  /** fetch data from the table: "master.nb_mfield" using primary key columns */
  master_nb_mfield_by_pk?: Maybe<Master_Nb_Mfield>;
  /** fetch data from the table: "master.nb_mfield_polygon_2022_1" */
  master_nb_mfield_polygon_2022_1: Array<Master_Nb_Mfield_Polygon_2022_1>;
  /** fetch aggregated fields from the table: "master.nb_mfield_polygon_2022_1" */
  master_nb_mfield_polygon_2022_1_aggregate: Master_Nb_Mfield_Polygon_2022_1_Aggregate;
  /** fetch data from the table: "master.nb_mfield_polygon_2022_1" using primary key columns */
  master_nb_mfield_polygon_2022_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2022_1>;
  /** fetch data from the table in a streaming manner: "master.nb_mfield_polygon_2022_1" */
  master_nb_mfield_polygon_2022_1_stream: Array<Master_Nb_Mfield_Polygon_2022_1>;
  /** fetch data from the table: "master.nb_mfield_polygon_2023_1" */
  master_nb_mfield_polygon_2023_1: Array<Master_Nb_Mfield_Polygon_2023_1>;
  /** fetch aggregated fields from the table: "master.nb_mfield_polygon_2023_1" */
  master_nb_mfield_polygon_2023_1_aggregate: Master_Nb_Mfield_Polygon_2023_1_Aggregate;
  /** fetch data from the table: "master.nb_mfield_polygon_2023_1" using primary key columns */
  master_nb_mfield_polygon_2023_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2023_1>;
  /** fetch data from the table in a streaming manner: "master.nb_mfield_polygon_2023_1" */
  master_nb_mfield_polygon_2023_1_stream: Array<Master_Nb_Mfield_Polygon_2023_1>;
  /** fetch data from the table: "master.nb_mfield_polygon_2024_1" */
  master_nb_mfield_polygon_2024_1: Array<Master_Nb_Mfield_Polygon_2024_1>;
  /** fetch aggregated fields from the table: "master.nb_mfield_polygon_2024_1" */
  master_nb_mfield_polygon_2024_1_aggregate: Master_Nb_Mfield_Polygon_2024_1_Aggregate;
  /** fetch data from the table: "master.nb_mfield_polygon_2024_1" using primary key columns */
  master_nb_mfield_polygon_2024_1_by_pk?: Maybe<Master_Nb_Mfield_Polygon_2024_1>;
  /** fetch data from the table in a streaming manner: "master.nb_mfield_polygon_2024_1" */
  master_nb_mfield_polygon_2024_1_stream: Array<Master_Nb_Mfield_Polygon_2024_1>;
  /** fetch data from the table in a streaming manner: "master.nb_mfield" */
  master_nb_mfield_stream: Array<Master_Nb_Mfield>;
  /** fetch data from the table: "master.nb_mfield_version" */
  master_nb_mfield_version: Array<Master_Nb_Mfield_Version>;
  /** fetch aggregated fields from the table: "master.nb_mfield_version" */
  master_nb_mfield_version_aggregate: Master_Nb_Mfield_Version_Aggregate;
  /** fetch data from the table: "master.nb_mfield_version" using primary key columns */
  master_nb_mfield_version_by_pk?: Maybe<Master_Nb_Mfield_Version>;
  /** fetch data from the table in a streaming manner: "master.nb_mfield_version" */
  master_nb_mfield_version_stream: Array<Master_Nb_Mfield_Version>;
  /** fetch data from the table: "master.nb_postgis_version" */
  master_nb_postgis_version: Array<Master_Nb_Postgis_Version>;
  /** fetch aggregated fields from the table: "master.nb_postgis_version" */
  master_nb_postgis_version_aggregate: Master_Nb_Postgis_Version_Aggregate;
  /** fetch data from the table: "master.nb_postgis_version" using primary key columns */
  master_nb_postgis_version_by_pk?: Maybe<Master_Nb_Postgis_Version>;
  /** fetch data from the table in a streaming manner: "master.nb_postgis_version" */
  master_nb_postgis_version_stream: Array<Master_Nb_Postgis_Version>;
  /** fetch data from the table: "master.nb_weather_attr" */
  master_nb_weather_attr: Array<Master_Nb_Weather_Attr>;
  /** fetch aggregated fields from the table: "master.nb_weather_attr" */
  master_nb_weather_attr_aggregate: Master_Nb_Weather_Attr_Aggregate;
  /** fetch data from the table: "master.nb_weather_attr" using primary key columns */
  master_nb_weather_attr_by_pk?: Maybe<Master_Nb_Weather_Attr>;
  /** fetch data from the table in a streaming manner: "master.nb_weather_attr" */
  master_nb_weather_attr_stream: Array<Master_Nb_Weather_Attr>;
  /** fetch data from the table: "master.nb_weather_data" */
  master_nb_weather_data: Array<Master_Nb_Weather_Data>;
  /** fetch aggregated fields from the table: "master.nb_weather_data" */
  master_nb_weather_data_aggregate: Master_Nb_Weather_Data_Aggregate;
  /** fetch data from the table: "master.nb_weather_data" using primary key columns */
  master_nb_weather_data_by_pk?: Maybe<Master_Nb_Weather_Data>;
  /** fetch data from the table in a streaming manner: "master.nb_weather_data" */
  master_nb_weather_data_stream: Array<Master_Nb_Weather_Data>;
  /** fetch data from the table: "nb_actual_crop_yeild" */
  nb_actual_crop_yeild: Array<Nb_Actual_Crop_Yeild>;
  /** fetch aggregated fields from the table: "nb_actual_crop_yeild" */
  nb_actual_crop_yeild_aggregate: Nb_Actual_Crop_Yeild_Aggregate;
  /** fetch data from the table: "nb_actual_crop_yeild" using primary key columns */
  nb_actual_crop_yeild_by_pk?: Maybe<Nb_Actual_Crop_Yeild>;
  /** fetch data from the table in a streaming manner: "nb_actual_crop_yeild" */
  nb_actual_crop_yeild_stream: Array<Nb_Actual_Crop_Yeild>;
  /** fetch data from the table: "nb_area_mesh" */
  nb_area_mesh: Array<Nb_Area_Mesh>;
  /** fetch aggregated fields from the table: "nb_area_mesh" */
  nb_area_mesh_aggregate: Nb_Area_Mesh_Aggregate;
  /** fetch data from the table: "nb_area_mesh" using primary key columns */
  nb_area_mesh_by_pk?: Maybe<Nb_Area_Mesh>;
  /** fetch data from the table in a streaming manner: "nb_area_mesh" */
  nb_area_mesh_stream: Array<Nb_Area_Mesh>;
  /** fetch data from the table: "nb_company" */
  nb_company: Array<Nb_Company>;
  /** fetch aggregated fields from the table: "nb_company" */
  nb_company_aggregate: Nb_Company_Aggregate;
  /** fetch data from the table: "nb_company" using primary key columns */
  nb_company_by_pk?: Maybe<Nb_Company>;
  /** fetch data from the table: "nb_company_info" */
  nb_company_info: Array<Nb_Company_Info>;
  /** fetch aggregated fields from the table: "nb_company_info" */
  nb_company_info_aggregate: Nb_Company_Info_Aggregate;
  /** fetch data from the table: "nb_company_info" using primary key columns */
  nb_company_info_by_pk?: Maybe<Nb_Company_Info>;
  /** fetch data from the table in a streaming manner: "nb_company_info" */
  nb_company_info_stream: Array<Nb_Company_Info>;
  /** fetch data from the table in a streaming manner: "nb_company" */
  nb_company_stream: Array<Nb_Company>;
  /** fetch data from the table: "nb_conditioner_work" */
  nb_conditioner_work: Array<Nb_Conditioner_Work>;
  /** fetch aggregated fields from the table: "nb_conditioner_work" */
  nb_conditioner_work_aggregate: Nb_Conditioner_Work_Aggregate;
  /** fetch data from the table: "nb_conditioner_work" using primary key columns */
  nb_conditioner_work_by_pk?: Maybe<Nb_Conditioner_Work>;
  /** fetch data from the table in a streaming manner: "nb_conditioner_work" */
  nb_conditioner_work_stream: Array<Nb_Conditioner_Work>;
  /** fetch data from the table: "nb_cplan_imsensing_link" */
  nb_cplan_imsensing_link: Array<Nb_Cplan_Imsensing_Link>;
  /** fetch aggregated fields from the table: "nb_cplan_imsensing_link" */
  nb_cplan_imsensing_link_aggregate: Nb_Cplan_Imsensing_Link_Aggregate;
  /** fetch data from the table: "nb_cplan_imsensing_link" using primary key columns */
  nb_cplan_imsensing_link_by_pk?: Maybe<Nb_Cplan_Imsensing_Link>;
  /** fetch data from the table in a streaming manner: "nb_cplan_imsensing_link" */
  nb_cplan_imsensing_link_stream: Array<Nb_Cplan_Imsensing_Link>;
  /** fetch data from the table: "nb_crop" */
  nb_crop: Array<Nb_Crop>;
  /** fetch aggregated fields from the table: "nb_crop" */
  nb_crop_aggregate: Nb_Crop_Aggregate;
  /** fetch data from the table: "nb_crop" using primary key columns */
  nb_crop_by_pk?: Maybe<Nb_Crop>;
  /** fetch data from the table: "nb_crop_company_link" */
  nb_crop_company_link: Array<Nb_Crop_Company_Link>;
  /** fetch aggregated fields from the table: "nb_crop_company_link" */
  nb_crop_company_link_aggregate: Nb_Crop_Company_Link_Aggregate;
  /** fetch data from the table: "nb_crop_company_link" using primary key columns */
  nb_crop_company_link_by_pk?: Maybe<Nb_Crop_Company_Link>;
  /** fetch data from the table in a streaming manner: "nb_crop_company_link" */
  nb_crop_company_link_stream: Array<Nb_Crop_Company_Link>;
  /** fetch data from the table: "nb_crop_method_link" */
  nb_crop_method_link: Array<Nb_Crop_Method_Link>;
  /** fetch aggregated fields from the table: "nb_crop_method_link" */
  nb_crop_method_link_aggregate: Nb_Crop_Method_Link_Aggregate;
  /** fetch data from the table: "nb_crop_method_link" using primary key columns */
  nb_crop_method_link_by_pk?: Maybe<Nb_Crop_Method_Link>;
  /** fetch data from the table in a streaming manner: "nb_crop_method_link" */
  nb_crop_method_link_stream: Array<Nb_Crop_Method_Link>;
  /** fetch data from the table in a streaming manner: "nb_crop" */
  nb_crop_stream: Array<Nb_Crop>;
  /** An array relationship */
  nb_cultivar: Array<Nb_Cultivar>;
  /** An aggregate relationship */
  nb_cultivar_aggregate: Nb_Cultivar_Aggregate;
  /** fetch data from the table: "nb_cultivar" using primary key columns */
  nb_cultivar_by_pk?: Maybe<Nb_Cultivar>;
  /** fetch data from the table in a streaming manner: "nb_cultivar" */
  nb_cultivar_stream: Array<Nb_Cultivar>;
  /** fetch data from the table: "nb_cultivation_method" */
  nb_cultivation_method: Array<Nb_Cultivation_Method>;
  /** fetch aggregated fields from the table: "nb_cultivation_method" */
  nb_cultivation_method_aggregate: Nb_Cultivation_Method_Aggregate;
  /** fetch data from the table: "nb_cultivation_method" using primary key columns */
  nb_cultivation_method_by_pk?: Maybe<Nb_Cultivation_Method>;
  /** fetch data from the table in a streaming manner: "nb_cultivation_method" */
  nb_cultivation_method_stream: Array<Nb_Cultivation_Method>;
  /** fetch data from the table: "nb_cultivation_plan" */
  nb_cultivation_plan: Array<Nb_Cultivation_Plan>;
  /** fetch aggregated fields from the table: "nb_cultivation_plan" */
  nb_cultivation_plan_aggregate: Nb_Cultivation_Plan_Aggregate;
  /** fetch data from the table: "nb_cultivation_plan" using primary key columns */
  nb_cultivation_plan_by_pk?: Maybe<Nb_Cultivation_Plan>;
  /** fetch data from the table in a streaming manner: "nb_cultivation_plan" */
  nb_cultivation_plan_stream: Array<Nb_Cultivation_Plan>;
  /** fetch data from the table: "nb_demo_task_type" */
  nb_demo_task_type: Array<Nb_Demo_Task_Type>;
  /** fetch aggregated fields from the table: "nb_demo_task_type" */
  nb_demo_task_type_aggregate: Nb_Demo_Task_Type_Aggregate;
  /** fetch data from the table: "nb_demo_task_type" using primary key columns */
  nb_demo_task_type_by_pk?: Maybe<Nb_Demo_Task_Type>;
  /** fetch data from the table in a streaming manner: "nb_demo_task_type" */
  nb_demo_task_type_stream: Array<Nb_Demo_Task_Type>;
  /** fetch data from the table: "nb_demo_user_location" */
  nb_demo_user_location: Array<Nb_Demo_User_Location>;
  /** fetch aggregated fields from the table: "nb_demo_user_location" */
  nb_demo_user_location_aggregate: Nb_Demo_User_Location_Aggregate;
  /** fetch data from the table: "nb_demo_user_location" using primary key columns */
  nb_demo_user_location_by_pk?: Maybe<Nb_Demo_User_Location>;
  /** fetch data from the table in a streaming manner: "nb_demo_user_location" */
  nb_demo_user_location_stream: Array<Nb_Demo_User_Location>;
  /** fetch data from the table: "nb_demo_user_name" */
  nb_demo_user_name: Array<Nb_Demo_User_Name>;
  /** fetch aggregated fields from the table: "nb_demo_user_name" */
  nb_demo_user_name_aggregate: Nb_Demo_User_Name_Aggregate;
  /** fetch data from the table: "nb_demo_user_name" using primary key columns */
  nb_demo_user_name_by_pk?: Maybe<Nb_Demo_User_Name>;
  /** fetch data from the table in a streaming manner: "nb_demo_user_name" */
  nb_demo_user_name_stream: Array<Nb_Demo_User_Name>;
  /** fetch data from the table: "nb_est_working_load" */
  nb_est_working_load: Array<Nb_Est_Working_Load>;
  /** fetch aggregated fields from the table: "nb_est_working_load" */
  nb_est_working_load_aggregate: Nb_Est_Working_Load_Aggregate;
  /** fetch data from the table: "nb_est_working_load" using primary key columns */
  nb_est_working_load_by_pk?: Maybe<Nb_Est_Working_Load>;
  /** fetch data from the table in a streaming manner: "nb_est_working_load" */
  nb_est_working_load_stream: Array<Nb_Est_Working_Load>;
  /** fetch data from the table: "nb_fertilization_method" */
  nb_fertilization_method: Array<Nb_Fertilization_Method>;
  /** fetch aggregated fields from the table: "nb_fertilization_method" */
  nb_fertilization_method_aggregate: Nb_Fertilization_Method_Aggregate;
  /** fetch data from the table: "nb_fertilization_method" using primary key columns */
  nb_fertilization_method_by_pk?: Maybe<Nb_Fertilization_Method>;
  /** fetch data from the table in a streaming manner: "nb_fertilization_method" */
  nb_fertilization_method_stream: Array<Nb_Fertilization_Method>;
  /** fetch data from the table: "nb_fertilization_work" */
  nb_fertilization_work: Array<Nb_Fertilization_Work>;
  /** fetch aggregated fields from the table: "nb_fertilization_work" */
  nb_fertilization_work_aggregate: Nb_Fertilization_Work_Aggregate;
  /** fetch data from the table: "nb_fertilization_work" using primary key columns */
  nb_fertilization_work_by_pk?: Maybe<Nb_Fertilization_Work>;
  /** fetch data from the table in a streaming manner: "nb_fertilization_work" */
  nb_fertilization_work_stream: Array<Nb_Fertilization_Work>;
  /** fetch data from the table: "nb_fertilizer" */
  nb_fertilizer: Array<Nb_Fertilizer>;
  /** fetch aggregated fields from the table: "nb_fertilizer" */
  nb_fertilizer_aggregate: Nb_Fertilizer_Aggregate;
  /** fetch data from the table: "nb_fertilizer" using primary key columns */
  nb_fertilizer_by_pk?: Maybe<Nb_Fertilizer>;
  /** fetch data from the table: "nb_fertilizer_company_link" */
  nb_fertilizer_company_link: Array<Nb_Fertilizer_Company_Link>;
  /** fetch aggregated fields from the table: "nb_fertilizer_company_link" */
  nb_fertilizer_company_link_aggregate: Nb_Fertilizer_Company_Link_Aggregate;
  /** fetch data from the table: "nb_fertilizer_company_link" using primary key columns */
  nb_fertilizer_company_link_by_pk?: Maybe<Nb_Fertilizer_Company_Link>;
  /** fetch data from the table in a streaming manner: "nb_fertilizer_company_link" */
  nb_fertilizer_company_link_stream: Array<Nb_Fertilizer_Company_Link>;
  /** fetch data from the table: "nb_fertilizer_picture" */
  nb_fertilizer_picture: Array<Nb_Fertilizer_Picture>;
  /** fetch aggregated fields from the table: "nb_fertilizer_picture" */
  nb_fertilizer_picture_aggregate: Nb_Fertilizer_Picture_Aggregate;
  /** fetch data from the table: "nb_fertilizer_picture" using primary key columns */
  nb_fertilizer_picture_by_pk?: Maybe<Nb_Fertilizer_Picture>;
  /** fetch data from the table in a streaming manner: "nb_fertilizer_picture" */
  nb_fertilizer_picture_stream: Array<Nb_Fertilizer_Picture>;
  /** fetch data from the table in a streaming manner: "nb_fertilizer" */
  nb_fertilizer_stream: Array<Nb_Fertilizer>;
  /** fetch data from the table: "nb_field_file" */
  nb_field_file: Array<Nb_Field_File>;
  /** fetch aggregated fields from the table: "nb_field_file" */
  nb_field_file_aggregate: Nb_Field_File_Aggregate;
  /** fetch data from the table: "nb_field_file" using primary key columns */
  nb_field_file_by_pk?: Maybe<Nb_Field_File>;
  /** fetch data from the table: "nb_field_file_load" */
  nb_field_file_load: Array<Nb_Field_File_Load>;
  /** fetch aggregated fields from the table: "nb_field_file_load" */
  nb_field_file_load_aggregate: Nb_Field_File_Load_Aggregate;
  /** fetch data from the table: "nb_field_file_load" using primary key columns */
  nb_field_file_load_by_pk?: Maybe<Nb_Field_File_Load>;
  /** fetch data from the table in a streaming manner: "nb_field_file_load" */
  nb_field_file_load_stream: Array<Nb_Field_File_Load>;
  /** fetch data from the table: "nb_field_file_size" */
  nb_field_file_size: Array<Nb_Field_File_Size>;
  /** fetch aggregated fields from the table: "nb_field_file_size" */
  nb_field_file_size_aggregate: Nb_Field_File_Size_Aggregate;
  /** fetch data from the table in a streaming manner: "nb_field_file_size" */
  nb_field_file_size_stream: Array<Nb_Field_File_Size>;
  /** fetch data from the table in a streaming manner: "nb_field_file" */
  nb_field_file_stream: Array<Nb_Field_File>;
  /** fetch data from the table: "nb_field_memo" */
  nb_field_memo: Array<Nb_Field_Memo>;
  /** fetch aggregated fields from the table: "nb_field_memo" */
  nb_field_memo_aggregate: Nb_Field_Memo_Aggregate;
  /** fetch data from the table: "nb_field_memo" using primary key columns */
  nb_field_memo_by_pk?: Maybe<Nb_Field_Memo>;
  /** fetch data from the table in a streaming manner: "nb_field_memo" */
  nb_field_memo_stream: Array<Nb_Field_Memo>;
  /** fetch data from the table: "nb_field_orthoimg" */
  nb_field_orthoimg: Array<Nb_Field_Orthoimg>;
  /** fetch aggregated fields from the table: "nb_field_orthoimg" */
  nb_field_orthoimg_aggregate: Nb_Field_Orthoimg_Aggregate;
  /** fetch data from the table: "nb_field_orthoimg" using primary key columns */
  nb_field_orthoimg_by_pk?: Maybe<Nb_Field_Orthoimg>;
  /** fetch data from the table: "nb_field_orthoimg_size" */
  nb_field_orthoimg_size: Array<Nb_Field_Orthoimg_Size>;
  /** fetch aggregated fields from the table: "nb_field_orthoimg_size" */
  nb_field_orthoimg_size_aggregate: Nb_Field_Orthoimg_Size_Aggregate;
  /** fetch data from the table in a streaming manner: "nb_field_orthoimg_size" */
  nb_field_orthoimg_size_stream: Array<Nb_Field_Orthoimg_Size>;
  /** fetch data from the table in a streaming manner: "nb_field_orthoimg" */
  nb_field_orthoimg_stream: Array<Nb_Field_Orthoimg>;
  /** fetch data from the table: "nb_field_photographer" */
  nb_field_photographer: Array<Nb_Field_Photographer>;
  /** fetch aggregated fields from the table: "nb_field_photographer" */
  nb_field_photographer_aggregate: Nb_Field_Photographer_Aggregate;
  /** fetch data from the table: "nb_field_photographer" using primary key columns */
  nb_field_photographer_by_pk?: Maybe<Nb_Field_Photographer>;
  /** fetch data from the table in a streaming manner: "nb_field_photographer" */
  nb_field_photographer_stream: Array<Nb_Field_Photographer>;
  /** fetch data from the table: "nb_field_picture" */
  nb_field_picture: Array<Nb_Field_Picture>;
  /** fetch aggregated fields from the table: "nb_field_picture" */
  nb_field_picture_aggregate: Nb_Field_Picture_Aggregate;
  /** fetch data from the table: "nb_field_picture" using primary key columns */
  nb_field_picture_by_pk?: Maybe<Nb_Field_Picture>;
  /** fetch data from the table: "nb_field_picture_cplan_link" */
  nb_field_picture_cplan_link: Array<Nb_Field_Picture_Cplan_Link>;
  /** fetch aggregated fields from the table: "nb_field_picture_cplan_link" */
  nb_field_picture_cplan_link_aggregate: Nb_Field_Picture_Cplan_Link_Aggregate;
  /** fetch data from the table: "nb_field_picture_cplan_link" using primary key columns */
  nb_field_picture_cplan_link_by_pk?: Maybe<Nb_Field_Picture_Cplan_Link>;
  /** fetch data from the table in a streaming manner: "nb_field_picture_cplan_link" */
  nb_field_picture_cplan_link_stream: Array<Nb_Field_Picture_Cplan_Link>;
  /** fetch data from the table: "nb_field_picture_load" */
  nb_field_picture_load: Array<Nb_Field_Picture_Load>;
  /** fetch aggregated fields from the table: "nb_field_picture_load" */
  nb_field_picture_load_aggregate: Nb_Field_Picture_Load_Aggregate;
  /** fetch data from the table: "nb_field_picture_load" using primary key columns */
  nb_field_picture_load_by_pk?: Maybe<Nb_Field_Picture_Load>;
  /** fetch data from the table in a streaming manner: "nb_field_picture_load" */
  nb_field_picture_load_stream: Array<Nb_Field_Picture_Load>;
  /** fetch data from the table: "nb_field_picture_size" */
  nb_field_picture_size: Array<Nb_Field_Picture_Size>;
  /** fetch aggregated fields from the table: "nb_field_picture_size" */
  nb_field_picture_size_aggregate: Nb_Field_Picture_Size_Aggregate;
  /** fetch data from the table in a streaming manner: "nb_field_picture_size" */
  nb_field_picture_size_stream: Array<Nb_Field_Picture_Size>;
  /** fetch data from the table in a streaming manner: "nb_field_picture" */
  nb_field_picture_stream: Array<Nb_Field_Picture>;
  /** fetch data from the table: "nb_field_polygon_attr" */
  nb_field_polygon_attr: Array<Nb_Field_Polygon_Attr>;
  /** fetch aggregated fields from the table: "nb_field_polygon_attr" */
  nb_field_polygon_attr_aggregate: Nb_Field_Polygon_Attr_Aggregate;
  /** fetch data from the table: "nb_field_polygon_attr" using primary key columns */
  nb_field_polygon_attr_by_pk?: Maybe<Nb_Field_Polygon_Attr>;
  /** fetch data from the table in a streaming manner: "nb_field_polygon_attr" */
  nb_field_polygon_attr_stream: Array<Nb_Field_Polygon_Attr>;
  /** fetch data from the table: "nb_field_property" */
  nb_field_property: Array<Nb_Field_Property>;
  /** fetch aggregated fields from the table: "nb_field_property" */
  nb_field_property_aggregate: Nb_Field_Property_Aggregate;
  /** fetch data from the table: "nb_field_property" using primary key columns */
  nb_field_property_by_pk?: Maybe<Nb_Field_Property>;
  /** fetch data from the table in a streaming manner: "nb_field_property" */
  nb_field_property_stream: Array<Nb_Field_Property>;
  /** fetch data from the table: "nb_field_sensing" */
  nb_field_sensing: Array<Nb_Field_Sensing>;
  /** fetch aggregated fields from the table: "nb_field_sensing" */
  nb_field_sensing_aggregate: Nb_Field_Sensing_Aggregate;
  /** fetch data from the table: "nb_field_sensing" using primary key columns */
  nb_field_sensing_by_pk?: Maybe<Nb_Field_Sensing>;
  /** An array relationship */
  nb_field_sensing_data: Array<Nb_Field_Sensing_Data>;
  /** An aggregate relationship */
  nb_field_sensing_data_aggregate: Nb_Field_Sensing_Data_Aggregate;
  /** fetch data from the table: "nb_field_sensing_data" using primary key columns */
  nb_field_sensing_data_by_pk?: Maybe<Nb_Field_Sensing_Data>;
  /** fetch data from the table in a streaming manner: "nb_field_sensing_data" */
  nb_field_sensing_data_stream: Array<Nb_Field_Sensing_Data>;
  /** fetch data from the table in a streaming manner: "nb_field_sensing" */
  nb_field_sensing_stream: Array<Nb_Field_Sensing>;
  /** fetch data from the table: "nb_field_water_gate" */
  nb_field_water_gate: Array<Nb_Field_Water_Gate>;
  /** fetch aggregated fields from the table: "nb_field_water_gate" */
  nb_field_water_gate_aggregate: Nb_Field_Water_Gate_Aggregate;
  /** fetch data from the table: "nb_field_water_gate" using primary key columns */
  nb_field_water_gate_by_pk?: Maybe<Nb_Field_Water_Gate>;
  /** fetch data from the table: "nb_field_water_gate_status" */
  nb_field_water_gate_status: Array<Nb_Field_Water_Gate_Status>;
  /** fetch aggregated fields from the table: "nb_field_water_gate_status" */
  nb_field_water_gate_status_aggregate: Nb_Field_Water_Gate_Status_Aggregate;
  /** fetch data from the table: "nb_field_water_gate_status" using primary key columns */
  nb_field_water_gate_status_by_pk?: Maybe<Nb_Field_Water_Gate_Status>;
  /** fetch data from the table in a streaming manner: "nb_field_water_gate_status" */
  nb_field_water_gate_status_stream: Array<Nb_Field_Water_Gate_Status>;
  /** fetch data from the table in a streaming manner: "nb_field_water_gate" */
  nb_field_water_gate_stream: Array<Nb_Field_Water_Gate>;
  /** fetch data from the table: "nb_file_size" */
  nb_file_size: Array<Nb_File_Size>;
  /** fetch aggregated fields from the table: "nb_file_size" */
  nb_file_size_aggregate: Nb_File_Size_Aggregate;
  /** fetch data from the table: "nb_file_size" using primary key columns */
  nb_file_size_by_pk?: Maybe<Nb_File_Size>;
  /** fetch data from the table: "nb_file_size_company_user_view" */
  nb_file_size_company_user_view: Array<Nb_File_Size_Company_User_View>;
  /** fetch aggregated fields from the table: "nb_file_size_company_user_view" */
  nb_file_size_company_user_view_aggregate: Nb_File_Size_Company_User_View_Aggregate;
  /** fetch data from the table in a streaming manner: "nb_file_size_company_user_view" */
  nb_file_size_company_user_view_stream: Array<Nb_File_Size_Company_User_View>;
  /** fetch data from the table: "nb_file_size_company_view" */
  nb_file_size_company_view: Array<Nb_File_Size_Company_View>;
  /** fetch aggregated fields from the table: "nb_file_size_company_view" */
  nb_file_size_company_view_aggregate: Nb_File_Size_Company_View_Aggregate;
  /** fetch data from the table in a streaming manner: "nb_file_size_company_view" */
  nb_file_size_company_view_stream: Array<Nb_File_Size_Company_View>;
  /** fetch data from the table in a streaming manner: "nb_file_size" */
  nb_file_size_stream: Array<Nb_File_Size>;
  /** fetch data from the table: "nb_file_type" */
  nb_file_type: Array<Nb_File_Type>;
  /** fetch aggregated fields from the table: "nb_file_type" */
  nb_file_type_aggregate: Nb_File_Type_Aggregate;
  /** fetch data from the table: "nb_file_type" using primary key columns */
  nb_file_type_by_pk?: Maybe<Nb_File_Type>;
  /** fetch data from the table in a streaming manner: "nb_file_type" */
  nb_file_type_stream: Array<Nb_File_Type>;
  /** fetch data from the table: "nb_finished_ufield_work" */
  nb_finished_ufield_work: Array<Nb_Finished_Ufield_Work>;
  /** fetch aggregated fields from the table: "nb_finished_ufield_work" */
  nb_finished_ufield_work_aggregate: Nb_Finished_Ufield_Work_Aggregate;
  /** fetch data from the table: "nb_finished_ufield_work" using primary key columns */
  nb_finished_ufield_work_by_pk?: Maybe<Nb_Finished_Ufield_Work>;
  /** fetch data from the table in a streaming manner: "nb_finished_ufield_work" */
  nb_finished_ufield_work_stream: Array<Nb_Finished_Ufield_Work>;
  /** fetch data from the table: "nb_image_sensing_attr" */
  nb_image_sensing_attr: Array<Nb_Image_Sensing_Attr>;
  /** fetch aggregated fields from the table: "nb_image_sensing_attr" */
  nb_image_sensing_attr_aggregate: Nb_Image_Sensing_Attr_Aggregate;
  /** fetch data from the table: "nb_image_sensing_attr" using primary key columns */
  nb_image_sensing_attr_by_pk?: Maybe<Nb_Image_Sensing_Attr>;
  /** fetch data from the table in a streaming manner: "nb_image_sensing_attr" */
  nb_image_sensing_attr_stream: Array<Nb_Image_Sensing_Attr>;
  /** fetch data from the table: "nb_imsensing_colormap" */
  nb_imsensing_colormap: Array<Nb_Imsensing_Colormap>;
  /** fetch aggregated fields from the table: "nb_imsensing_colormap" */
  nb_imsensing_colormap_aggregate: Nb_Imsensing_Colormap_Aggregate;
  /** fetch data from the table: "nb_imsensing_colormap" using primary key columns */
  nb_imsensing_colormap_by_pk?: Maybe<Nb_Imsensing_Colormap>;
  /** fetch data from the table in a streaming manner: "nb_imsensing_colormap" */
  nb_imsensing_colormap_stream: Array<Nb_Imsensing_Colormap>;
  /** fetch data from the table: "nb_log_user_position" */
  nb_log_user_position: Array<Nb_Log_User_Position>;
  /** fetch aggregated fields from the table: "nb_log_user_position" */
  nb_log_user_position_aggregate: Nb_Log_User_Position_Aggregate;
  /** fetch data from the table: "nb_log_user_position" using primary key columns */
  nb_log_user_position_by_pk?: Maybe<Nb_Log_User_Position>;
  /** fetch data from the table in a streaming manner: "nb_log_user_position" */
  nb_log_user_position_stream: Array<Nb_Log_User_Position>;
  /** fetch data from the table: "nb_maff_field" */
  nb_maff_field: Array<Nb_Maff_Field>;
  /** fetch aggregated fields from the table: "nb_maff_field" */
  nb_maff_field_aggregate: Nb_Maff_Field_Aggregate;
  /** fetch data from the table: "nb_maff_field" using primary key columns */
  nb_maff_field_by_pk?: Maybe<Nb_Maff_Field>;
  /** fetch data from the table: "nb_maff_field_link" */
  nb_maff_field_link: Array<Nb_Maff_Field_Link>;
  /** fetch aggregated fields from the table: "nb_maff_field_link" */
  nb_maff_field_link_aggregate: Nb_Maff_Field_Link_Aggregate;
  /** fetch data from the table: "nb_maff_field_link" using primary key columns */
  nb_maff_field_link_by_pk?: Maybe<Nb_Maff_Field_Link>;
  /** fetch data from the table in a streaming manner: "nb_maff_field_link" */
  nb_maff_field_link_stream: Array<Nb_Maff_Field_Link>;
  /** fetch data from the table in a streaming manner: "nb_maff_field" */
  nb_maff_field_stream: Array<Nb_Maff_Field>;
  /** fetch data from the table: "nb_memo_file_link" */
  nb_memo_file_link: Array<Nb_Memo_File_Link>;
  /** fetch aggregated fields from the table: "nb_memo_file_link" */
  nb_memo_file_link_aggregate: Nb_Memo_File_Link_Aggregate;
  /** fetch data from the table: "nb_memo_file_link" using primary key columns */
  nb_memo_file_link_by_pk?: Maybe<Nb_Memo_File_Link>;
  /** fetch data from the table in a streaming manner: "nb_memo_file_link" */
  nb_memo_file_link_stream: Array<Nb_Memo_File_Link>;
  /** fetch data from the table: "nb_memo_picture_link" */
  nb_memo_picture_link: Array<Nb_Memo_Picture_Link>;
  /** fetch aggregated fields from the table: "nb_memo_picture_link" */
  nb_memo_picture_link_aggregate: Nb_Memo_Picture_Link_Aggregate;
  /** fetch data from the table: "nb_memo_picture_link" using primary key columns */
  nb_memo_picture_link_by_pk?: Maybe<Nb_Memo_Picture_Link>;
  /** fetch data from the table in a streaming manner: "nb_memo_picture_link" */
  nb_memo_picture_link_stream: Array<Nb_Memo_Picture_Link>;
  /** fetch data from the table: "nb_memo_ufg_link" */
  nb_memo_ufg_link: Array<Nb_Memo_Ufg_Link>;
  /** fetch aggregated fields from the table: "nb_memo_ufg_link" */
  nb_memo_ufg_link_aggregate: Nb_Memo_Ufg_Link_Aggregate;
  /** fetch data from the table: "nb_memo_ufg_link" using primary key columns */
  nb_memo_ufg_link_by_pk?: Maybe<Nb_Memo_Ufg_Link>;
  /** fetch data from the table in a streaming manner: "nb_memo_ufg_link" */
  nb_memo_ufg_link_stream: Array<Nb_Memo_Ufg_Link>;
  /** fetch data from the table: "nb_memo_ufield_link" */
  nb_memo_ufield_link: Array<Nb_Memo_Ufield_Link>;
  /** fetch aggregated fields from the table: "nb_memo_ufield_link" */
  nb_memo_ufield_link_aggregate: Nb_Memo_Ufield_Link_Aggregate;
  /** fetch data from the table: "nb_memo_ufield_link" using primary key columns */
  nb_memo_ufield_link_by_pk?: Maybe<Nb_Memo_Ufield_Link>;
  /** fetch data from the table in a streaming manner: "nb_memo_ufield_link" */
  nb_memo_ufield_link_stream: Array<Nb_Memo_Ufield_Link>;
  /** fetch data from the table: "nb_mfield" */
  nb_mfield: Array<Nb_Mfield>;
  /** fetch aggregated fields from the table: "nb_mfield" */
  nb_mfield_aggregate: Nb_Mfield_Aggregate;
  /** fetch data from the table: "nb_mfield" using primary key columns */
  nb_mfield_by_pk?: Maybe<Nb_Mfield>;
  /** fetch data from the table: "nb_mfield_link" */
  nb_mfield_link: Array<Nb_Mfield_Link>;
  /** fetch aggregated fields from the table: "nb_mfield_link" */
  nb_mfield_link_aggregate: Nb_Mfield_Link_Aggregate;
  /** fetch data from the table: "nb_mfield_link" using primary key columns */
  nb_mfield_link_by_pk?: Maybe<Nb_Mfield_Link>;
  /** fetch data from the table in a streaming manner: "nb_mfield_link" */
  nb_mfield_link_stream: Array<Nb_Mfield_Link>;
  /** fetch data from the table in a streaming manner: "nb_mfield" */
  nb_mfield_stream: Array<Nb_Mfield>;
  /** fetch data from the table: "nb_performed_work" */
  nb_performed_work: Array<Nb_Performed_Work>;
  /** fetch aggregated fields from the table: "nb_performed_work" */
  nb_performed_work_aggregate: Nb_Performed_Work_Aggregate;
  /** fetch data from the table: "nb_performed_work" using primary key columns */
  nb_performed_work_by_pk?: Maybe<Nb_Performed_Work>;
  /** fetch data from the table in a streaming manner: "nb_performed_work" */
  nb_performed_work_stream: Array<Nb_Performed_Work>;
  /** fetch data from the table: "nb_pesticide" */
  nb_pesticide: Array<Nb_Pesticide>;
  /** fetch aggregated fields from the table: "nb_pesticide" */
  nb_pesticide_aggregate: Nb_Pesticide_Aggregate;
  /** fetch data from the table: "nb_pesticide" using primary key columns */
  nb_pesticide_by_pk?: Maybe<Nb_Pesticide>;
  /** fetch data from the table: "nb_pesticide_company_link" */
  nb_pesticide_company_link: Array<Nb_Pesticide_Company_Link>;
  /** fetch aggregated fields from the table: "nb_pesticide_company_link" */
  nb_pesticide_company_link_aggregate: Nb_Pesticide_Company_Link_Aggregate;
  /** fetch data from the table: "nb_pesticide_company_link" using primary key columns */
  nb_pesticide_company_link_by_pk?: Maybe<Nb_Pesticide_Company_Link>;
  /** fetch data from the table in a streaming manner: "nb_pesticide_company_link" */
  nb_pesticide_company_link_stream: Array<Nb_Pesticide_Company_Link>;
  /** fetch data from the table: "nb_pesticide_ingredient" */
  nb_pesticide_ingredient: Array<Nb_Pesticide_Ingredient>;
  /** fetch aggregated fields from the table: "nb_pesticide_ingredient" */
  nb_pesticide_ingredient_aggregate: Nb_Pesticide_Ingredient_Aggregate;
  /** fetch data from the table: "nb_pesticide_ingredient" using primary key columns */
  nb_pesticide_ingredient_by_pk?: Maybe<Nb_Pesticide_Ingredient>;
  /** fetch data from the table in a streaming manner: "nb_pesticide_ingredient" */
  nb_pesticide_ingredient_stream: Array<Nb_Pesticide_Ingredient>;
  /** fetch data from the table: "nb_pesticide_spray_work" */
  nb_pesticide_spray_work: Array<Nb_Pesticide_Spray_Work>;
  /** fetch aggregated fields from the table: "nb_pesticide_spray_work" */
  nb_pesticide_spray_work_aggregate: Nb_Pesticide_Spray_Work_Aggregate;
  /** fetch data from the table: "nb_pesticide_spray_work" using primary key columns */
  nb_pesticide_spray_work_by_pk?: Maybe<Nb_Pesticide_Spray_Work>;
  /** fetch data from the table in a streaming manner: "nb_pesticide_spray_work" */
  nb_pesticide_spray_work_stream: Array<Nb_Pesticide_Spray_Work>;
  /** fetch data from the table in a streaming manner: "nb_pesticide" */
  nb_pesticide_stream: Array<Nb_Pesticide>;
  /** fetch data from the table: "nb_picture_analysis" */
  nb_picture_analysis: Array<Nb_Picture_Analysis>;
  /** fetch aggregated fields from the table: "nb_picture_analysis" */
  nb_picture_analysis_aggregate: Nb_Picture_Analysis_Aggregate;
  /** fetch data from the table: "nb_picture_analysis" using primary key columns */
  nb_picture_analysis_by_pk?: Maybe<Nb_Picture_Analysis>;
  /** fetch data from the table in a streaming manner: "nb_picture_analysis" */
  nb_picture_analysis_stream: Array<Nb_Picture_Analysis>;
  /** fetch data from the table: "nb_picture_analysis_type" */
  nb_picture_analysis_type: Array<Nb_Picture_Analysis_Type>;
  /** fetch aggregated fields from the table: "nb_picture_analysis_type" */
  nb_picture_analysis_type_aggregate: Nb_Picture_Analysis_Type_Aggregate;
  /** fetch data from the table: "nb_picture_analysis_type" using primary key columns */
  nb_picture_analysis_type_by_pk?: Maybe<Nb_Picture_Analysis_Type>;
  /** fetch data from the table in a streaming manner: "nb_picture_analysis_type" */
  nb_picture_analysis_type_stream: Array<Nb_Picture_Analysis_Type>;
  /** fetch data from the table: "nb_raster_image_sensing" */
  nb_raster_image_sensing: Array<Nb_Raster_Image_Sensing>;
  /** fetch aggregated fields from the table: "nb_raster_image_sensing" */
  nb_raster_image_sensing_aggregate: Nb_Raster_Image_Sensing_Aggregate;
  /** fetch data from the table: "nb_raster_image_sensing" using primary key columns */
  nb_raster_image_sensing_by_pk?: Maybe<Nb_Raster_Image_Sensing>;
  /** fetch data from the table in a streaming manner: "nb_raster_image_sensing" */
  nb_raster_image_sensing_stream: Array<Nb_Raster_Image_Sensing>;
  /** fetch data from the table: "nb_raster_ims_size" */
  nb_raster_ims_size: Array<Nb_Raster_Ims_Size>;
  /** fetch aggregated fields from the table: "nb_raster_ims_size" */
  nb_raster_ims_size_aggregate: Nb_Raster_Ims_Size_Aggregate;
  /** fetch data from the table in a streaming manner: "nb_raster_ims_size" */
  nb_raster_ims_size_stream: Array<Nb_Raster_Ims_Size>;
  /** fetch data from the table: "nb_soil_chara_map" */
  nb_soil_chara_map: Array<Nb_Soil_Chara_Map>;
  /** fetch aggregated fields from the table: "nb_soil_chara_map" */
  nb_soil_chara_map_aggregate: Nb_Soil_Chara_Map_Aggregate;
  /** fetch data from the table: "nb_soil_chara_map" using primary key columns */
  nb_soil_chara_map_by_pk?: Maybe<Nb_Soil_Chara_Map>;
  /** fetch data from the table: "nb_soil_chara_map_sellist" */
  nb_soil_chara_map_sellist: Array<Nb_Soil_Chara_Map_Sellist>;
  /** fetch aggregated fields from the table: "nb_soil_chara_map_sellist" */
  nb_soil_chara_map_sellist_aggregate: Nb_Soil_Chara_Map_Sellist_Aggregate;
  /** fetch data from the table: "nb_soil_chara_map_sellist" using primary key columns */
  nb_soil_chara_map_sellist_by_pk?: Maybe<Nb_Soil_Chara_Map_Sellist>;
  /** fetch data from the table in a streaming manner: "nb_soil_chara_map_sellist" */
  nb_soil_chara_map_sellist_stream: Array<Nb_Soil_Chara_Map_Sellist>;
  /** fetch data from the table in a streaming manner: "nb_soil_chara_map" */
  nb_soil_chara_map_stream: Array<Nb_Soil_Chara_Map>;
  /** fetch data from the table: "nb_soil_characteristic" */
  nb_soil_characteristic: Array<Nb_Soil_Characteristic>;
  /** fetch aggregated fields from the table: "nb_soil_characteristic" */
  nb_soil_characteristic_aggregate: Nb_Soil_Characteristic_Aggregate;
  /** fetch data from the table: "nb_soil_characteristic" using primary key columns */
  nb_soil_characteristic_by_pk?: Maybe<Nb_Soil_Characteristic>;
  /** fetch data from the table in a streaming manner: "nb_soil_characteristic" */
  nb_soil_characteristic_stream: Array<Nb_Soil_Characteristic>;
  /** fetch data from the table: "nb_soil_chemistry" */
  nb_soil_chemistry: Array<Nb_Soil_Chemistry>;
  /** fetch aggregated fields from the table: "nb_soil_chemistry" */
  nb_soil_chemistry_aggregate: Nb_Soil_Chemistry_Aggregate;
  /** fetch data from the table: "nb_soil_chemistry" using primary key columns */
  nb_soil_chemistry_by_pk?: Maybe<Nb_Soil_Chemistry>;
  /** fetch data from the table: "nb_soil_chemistry_proper_range" */
  nb_soil_chemistry_proper_range: Array<Nb_Soil_Chemistry_Proper_Range>;
  /** fetch aggregated fields from the table: "nb_soil_chemistry_proper_range" */
  nb_soil_chemistry_proper_range_aggregate: Nb_Soil_Chemistry_Proper_Range_Aggregate;
  /** fetch data from the table: "nb_soil_chemistry_proper_range" using primary key columns */
  nb_soil_chemistry_proper_range_by_pk?: Maybe<Nb_Soil_Chemistry_Proper_Range>;
  /** fetch data from the table in a streaming manner: "nb_soil_chemistry_proper_range" */
  nb_soil_chemistry_proper_range_stream: Array<Nb_Soil_Chemistry_Proper_Range>;
  /** An array relationship */
  nb_soil_chemistry_standards: Array<Nb_Soil_Chemistry_Standards>;
  /** An aggregate relationship */
  nb_soil_chemistry_standards_aggregate: Nb_Soil_Chemistry_Standards_Aggregate;
  /** fetch data from the table: "nb_soil_chemistry_standards" using primary key columns */
  nb_soil_chemistry_standards_by_pk?: Maybe<Nb_Soil_Chemistry_Standards>;
  /** fetch data from the table in a streaming manner: "nb_soil_chemistry_standards" */
  nb_soil_chemistry_standards_stream: Array<Nb_Soil_Chemistry_Standards>;
  /** fetch data from the table in a streaming manner: "nb_soil_chemistry" */
  nb_soil_chemistry_stream: Array<Nb_Soil_Chemistry>;
  /** fetch data from the table: "nb_soil_chemistry_suitable_range" */
  nb_soil_chemistry_suitable_range: Array<Nb_Soil_Chemistry_Suitable_Range>;
  /** fetch aggregated fields from the table: "nb_soil_chemistry_suitable_range" */
  nb_soil_chemistry_suitable_range_aggregate: Nb_Soil_Chemistry_Suitable_Range_Aggregate;
  /** fetch data from the table: "nb_soil_chemistry_suitable_range" using primary key columns */
  nb_soil_chemistry_suitable_range_by_pk?: Maybe<Nb_Soil_Chemistry_Suitable_Range>;
  /** fetch data from the table in a streaming manner: "nb_soil_chemistry_suitable_range" */
  nb_soil_chemistry_suitable_range_stream: Array<Nb_Soil_Chemistry_Suitable_Range>;
  /** fetch data from the table: "nb_soil_chemistry_value" */
  nb_soil_chemistry_value: Array<Nb_Soil_Chemistry_Value>;
  /** fetch aggregated fields from the table: "nb_soil_chemistry_value" */
  nb_soil_chemistry_value_aggregate: Nb_Soil_Chemistry_Value_Aggregate;
  /** fetch data from the table: "nb_soil_chemistry_value" using primary key columns */
  nb_soil_chemistry_value_by_pk?: Maybe<Nb_Soil_Chemistry_Value>;
  /** fetch data from the table in a streaming manner: "nb_soil_chemistry_value" */
  nb_soil_chemistry_value_stream: Array<Nb_Soil_Chemistry_Value>;
  /** fetch data from the table: "nb_soil_explanation" */
  nb_soil_explanation: Array<Nb_Soil_Explanation>;
  /** fetch aggregated fields from the table: "nb_soil_explanation" */
  nb_soil_explanation_aggregate: Nb_Soil_Explanation_Aggregate;
  /** fetch data from the table: "nb_soil_explanation" using primary key columns */
  nb_soil_explanation_by_pk?: Maybe<Nb_Soil_Explanation>;
  /** fetch data from the table in a streaming manner: "nb_soil_explanation" */
  nb_soil_explanation_stream: Array<Nb_Soil_Explanation>;
  /** An array relationship */
  nb_soil_physics: Array<Nb_Soil_Physics>;
  /** An aggregate relationship */
  nb_soil_physics_aggregate: Nb_Soil_Physics_Aggregate;
  /** fetch data from the table: "nb_soil_physics" using primary key columns */
  nb_soil_physics_by_pk?: Maybe<Nb_Soil_Physics>;
  /** fetch data from the table in a streaming manner: "nb_soil_physics" */
  nb_soil_physics_stream: Array<Nb_Soil_Physics>;
  /** fetch data from the table: "nb_task_type" */
  nb_task_type: Array<Nb_Task_Type>;
  /** fetch aggregated fields from the table: "nb_task_type" */
  nb_task_type_aggregate: Nb_Task_Type_Aggregate;
  /** fetch data from the table: "nb_task_type" using primary key columns */
  nb_task_type_by_pk?: Maybe<Nb_Task_Type>;
  /** fetch data from the table in a streaming manner: "nb_task_type" */
  nb_task_type_stream: Array<Nb_Task_Type>;
  /** fetch data from the table: "nb_tasktype_company_link" */
  nb_tasktype_company_link: Array<Nb_Tasktype_Company_Link>;
  /** fetch aggregated fields from the table: "nb_tasktype_company_link" */
  nb_tasktype_company_link_aggregate: Nb_Tasktype_Company_Link_Aggregate;
  /** fetch data from the table: "nb_tasktype_company_link" using primary key columns */
  nb_tasktype_company_link_by_pk?: Maybe<Nb_Tasktype_Company_Link>;
  /** fetch data from the table in a streaming manner: "nb_tasktype_company_link" */
  nb_tasktype_company_link_stream: Array<Nb_Tasktype_Company_Link>;
  /** fetch data from the table: "nb_ufattr_link" */
  nb_ufattr_link: Array<Nb_Ufattr_Link>;
  /** fetch aggregated fields from the table: "nb_ufattr_link" */
  nb_ufattr_link_aggregate: Nb_Ufattr_Link_Aggregate;
  /** fetch data from the table: "nb_ufattr_link" using primary key columns */
  nb_ufattr_link_by_pk?: Maybe<Nb_Ufattr_Link>;
  /** fetch data from the table in a streaming manner: "nb_ufattr_link" */
  nb_ufattr_link_stream: Array<Nb_Ufattr_Link>;
  /** fetch data from the table: "nb_ufg_link" */
  nb_ufg_link: Array<Nb_Ufg_Link>;
  /** fetch aggregated fields from the table: "nb_ufg_link" */
  nb_ufg_link_aggregate: Nb_Ufg_Link_Aggregate;
  /** fetch data from the table: "nb_ufg_link" using primary key columns */
  nb_ufg_link_by_pk?: Maybe<Nb_Ufg_Link>;
  /** fetch data from the table in a streaming manner: "nb_ufg_link" */
  nb_ufg_link_stream: Array<Nb_Ufg_Link>;
  /** fetch data from the table: "nb_ufg_member_link" */
  nb_ufg_member_link: Array<Nb_Ufg_Member_Link>;
  /** fetch aggregated fields from the table: "nb_ufg_member_link" */
  nb_ufg_member_link_aggregate: Nb_Ufg_Member_Link_Aggregate;
  /** fetch data from the table: "nb_ufg_member_link" using primary key columns */
  nb_ufg_member_link_by_pk?: Maybe<Nb_Ufg_Member_Link>;
  /** fetch data from the table in a streaming manner: "nb_ufg_member_link" */
  nb_ufg_member_link_stream: Array<Nb_Ufg_Member_Link>;
  /** fetch data from the table: "nb_ufield" */
  nb_ufield: Array<Nb_Ufield>;
  /** fetch aggregated fields from the table: "nb_ufield" */
  nb_ufield_aggregate: Nb_Ufield_Aggregate;
  /** fetch data from the table: "nb_ufield_attribute" */
  nb_ufield_attribute: Array<Nb_Ufield_Attribute>;
  /** fetch aggregated fields from the table: "nb_ufield_attribute" */
  nb_ufield_attribute_aggregate: Nb_Ufield_Attribute_Aggregate;
  /** fetch data from the table: "nb_ufield_attribute" using primary key columns */
  nb_ufield_attribute_by_pk?: Maybe<Nb_Ufield_Attribute>;
  /** fetch data from the table in a streaming manner: "nb_ufield_attribute" */
  nb_ufield_attribute_stream: Array<Nb_Ufield_Attribute>;
  /** fetch data from the table: "nb_ufield" using primary key columns */
  nb_ufield_by_pk?: Maybe<Nb_Ufield>;
  /** fetch data from the table: "nb_ufield_company_link" */
  nb_ufield_company_link: Array<Nb_Ufield_Company_Link>;
  /** fetch aggregated fields from the table: "nb_ufield_company_link" */
  nb_ufield_company_link_aggregate: Nb_Ufield_Company_Link_Aggregate;
  /** fetch data from the table: "nb_ufield_company_link" using primary key columns */
  nb_ufield_company_link_by_pk?: Maybe<Nb_Ufield_Company_Link>;
  /** fetch data from the table in a streaming manner: "nb_ufield_company_link" */
  nb_ufield_company_link_stream: Array<Nb_Ufield_Company_Link>;
  /** fetch data from the table: "nb_ufield_group" */
  nb_ufield_group: Array<Nb_Ufield_Group>;
  /** fetch aggregated fields from the table: "nb_ufield_group" */
  nb_ufield_group_aggregate: Nb_Ufield_Group_Aggregate;
  /** fetch data from the table: "nb_ufield_group" using primary key columns */
  nb_ufield_group_by_pk?: Maybe<Nb_Ufield_Group>;
  /** fetch data from the table in a streaming manner: "nb_ufield_group" */
  nb_ufield_group_stream: Array<Nb_Ufield_Group>;
  /** fetch data from the table: "nb_ufield_history" */
  nb_ufield_history: Array<Nb_Ufield_History>;
  /** fetch aggregated fields from the table: "nb_ufield_history" */
  nb_ufield_history_aggregate: Nb_Ufield_History_Aggregate;
  /** fetch data from the table: "nb_ufield_history" using primary key columns */
  nb_ufield_history_by_pk?: Maybe<Nb_Ufield_History>;
  /** fetch data from the table in a streaming manner: "nb_ufield_history" */
  nb_ufield_history_stream: Array<Nb_Ufield_History>;
  /** fetch data from the table in a streaming manner: "nb_ufield" */
  nb_ufield_stream: Array<Nb_Ufield>;
  /** fetch data from the table: "nb_vector_image_sensing" */
  nb_vector_image_sensing: Array<Nb_Vector_Image_Sensing>;
  /** fetch aggregated fields from the table: "nb_vector_image_sensing" */
  nb_vector_image_sensing_aggregate: Nb_Vector_Image_Sensing_Aggregate;
  /** fetch data from the table: "nb_vector_image_sensing" using primary key columns */
  nb_vector_image_sensing_by_pk?: Maybe<Nb_Vector_Image_Sensing>;
  /** fetch data from the table in a streaming manner: "nb_vector_image_sensing" */
  nb_vector_image_sensing_stream: Array<Nb_Vector_Image_Sensing>;
  /** fetch data from the table: "nb_vector_ims_size" */
  nb_vector_ims_size: Array<Nb_Vector_Ims_Size>;
  /** fetch aggregated fields from the table: "nb_vector_ims_size" */
  nb_vector_ims_size_aggregate: Nb_Vector_Ims_Size_Aggregate;
  /** fetch data from the table in a streaming manner: "nb_vector_ims_size" */
  nb_vector_ims_size_stream: Array<Nb_Vector_Ims_Size>;
  /** fetch data from the table: "nb_water_gate_status_cplan_link" */
  nb_water_gate_status_cplan_link: Array<Nb_Water_Gate_Status_Cplan_Link>;
  /** fetch aggregated fields from the table: "nb_water_gate_status_cplan_link" */
  nb_water_gate_status_cplan_link_aggregate: Nb_Water_Gate_Status_Cplan_Link_Aggregate;
  /** fetch data from the table: "nb_water_gate_status_cplan_link" using primary key columns */
  nb_water_gate_status_cplan_link_by_pk?: Maybe<Nb_Water_Gate_Status_Cplan_Link>;
  /** fetch data from the table in a streaming manner: "nb_water_gate_status_cplan_link" */
  nb_water_gate_status_cplan_link_stream: Array<Nb_Water_Gate_Status_Cplan_Link>;
  /** fetch data from the table: "nb_weather_attr" */
  nb_weather_attr: Array<Nb_Weather_Attr>;
  /** fetch aggregated fields from the table: "nb_weather_attr" */
  nb_weather_attr_aggregate: Nb_Weather_Attr_Aggregate;
  /** fetch data from the table: "nb_weather_attr" using primary key columns */
  nb_weather_attr_by_pk?: Maybe<Nb_Weather_Attr>;
  /** fetch data from the table in a streaming manner: "nb_weather_attr" */
  nb_weather_attr_stream: Array<Nb_Weather_Attr>;
  /** An array relationship */
  nb_weather_data: Array<Nb_Weather_Data>;
  /** An aggregate relationship */
  nb_weather_data_aggregate: Nb_Weather_Data_Aggregate;
  /** fetch data from the table: "nb_weather_data" using primary key columns */
  nb_weather_data_by_pk?: Maybe<Nb_Weather_Data>;
  /** fetch data from the table in a streaming manner: "nb_weather_data" */
  nb_weather_data_stream: Array<Nb_Weather_Data>;
  /** fetch data from the table: "nb_wide_orthoimg" */
  nb_wide_orthoimg: Array<Nb_Wide_Orthoimg>;
  /** fetch aggregated fields from the table: "nb_wide_orthoimg" */
  nb_wide_orthoimg_aggregate: Nb_Wide_Orthoimg_Aggregate;
  /** fetch data from the table: "nb_wide_orthoimg" using primary key columns */
  nb_wide_orthoimg_by_pk?: Maybe<Nb_Wide_Orthoimg>;
  /** fetch data from the table: "nb_wide_orthoimg_size" */
  nb_wide_orthoimg_size: Array<Nb_Wide_Orthoimg_Size>;
  /** fetch aggregated fields from the table: "nb_wide_orthoimg_size" */
  nb_wide_orthoimg_size_aggregate: Nb_Wide_Orthoimg_Size_Aggregate;
  /** fetch data from the table in a streaming manner: "nb_wide_orthoimg_size" */
  nb_wide_orthoimg_size_stream: Array<Nb_Wide_Orthoimg_Size>;
  /** fetch data from the table in a streaming manner: "nb_wide_orthoimg" */
  nb_wide_orthoimg_stream: Array<Nb_Wide_Orthoimg>;
  /** fetch data from the table: "nb_working_machine" */
  nb_working_machine: Array<Nb_Working_Machine>;
  /** fetch aggregated fields from the table: "nb_working_machine" */
  nb_working_machine_aggregate: Nb_Working_Machine_Aggregate;
  /** fetch data from the table: "nb_working_machine" using primary key columns */
  nb_working_machine_by_pk?: Maybe<Nb_Working_Machine>;
  /** fetch data from the table in a streaming manner: "nb_working_machine" */
  nb_working_machine_stream: Array<Nb_Working_Machine>;
  /** fetch data from the table: "nbb_picture_analysis_status" */
  nbb_picture_analysis_status: Array<Nbb_Picture_Analysis_Status>;
  /** fetch aggregated fields from the table: "nbb_picture_analysis_status" */
  nbb_picture_analysis_status_aggregate: Nbb_Picture_Analysis_Status_Aggregate;
  /** fetch data from the table: "nbb_picture_analysis_status" using primary key columns */
  nbb_picture_analysis_status_by_pk?: Maybe<Nbb_Picture_Analysis_Status>;
  /** fetch data from the table in a streaming manner: "nbb_picture_analysis_status" */
  nbb_picture_analysis_status_stream: Array<Nbb_Picture_Analysis_Status>;
  /** fetch data from the table: "nbbe_microclimate" */
  nbbe_microclimate: Array<Nbbe_Microclimate>;
  /** fetch aggregated fields from the table: "nbbe_microclimate" */
  nbbe_microclimate_aggregate: Nbbe_Microclimate_Aggregate;
  /** fetch data from the table: "nbbe_microclimate" using primary key columns */
  nbbe_microclimate_by_pk?: Maybe<Nbbe_Microclimate>;
  /** fetch data from the table in a streaming manner: "nbbe_microclimate" */
  nbbe_microclimate_stream: Array<Nbbe_Microclimate>;
  /** fetch data from the table: "nbbe_orthoimg_type" */
  nbbe_orthoimg_type: Array<Nbbe_Orthoimg_Type>;
  /** fetch aggregated fields from the table: "nbbe_orthoimg_type" */
  nbbe_orthoimg_type_aggregate: Nbbe_Orthoimg_Type_Aggregate;
  /** fetch data from the table: "nbbe_orthoimg_type" using primary key columns */
  nbbe_orthoimg_type_by_pk?: Maybe<Nbbe_Orthoimg_Type>;
  /** fetch data from the table in a streaming manner: "nbbe_orthoimg_type" */
  nbbe_orthoimg_type_stream: Array<Nbbe_Orthoimg_Type>;
  /** fetch data from the table: "nbbe_weather_type" */
  nbbe_weather_type: Array<Nbbe_Weather_Type>;
  /** fetch aggregated fields from the table: "nbbe_weather_type" */
  nbbe_weather_type_aggregate: Nbbe_Weather_Type_Aggregate;
  /** fetch data from the table: "nbbe_weather_type" using primary key columns */
  nbbe_weather_type_by_pk?: Maybe<Nbbe_Weather_Type>;
  /** fetch data from the table in a streaming manner: "nbbe_weather_type" */
  nbbe_weather_type_stream: Array<Nbbe_Weather_Type>;
  /** fetch data from the table: "nbpm_authorization" */
  nbpm_authorization: Array<Nbpm_Authorization>;
  /** fetch aggregated fields from the table: "nbpm_authorization" */
  nbpm_authorization_aggregate: Nbpm_Authorization_Aggregate;
  /** fetch data from the table: "nbpm_authorization" using primary key columns */
  nbpm_authorization_by_pk?: Maybe<Nbpm_Authorization>;
  /** fetch data from the table in a streaming manner: "nbpm_authorization" */
  nbpm_authorization_stream: Array<Nbpm_Authorization>;
  /** fetch data from the table: "nbpm_contract_plan" */
  nbpm_contract_plan: Array<Nbpm_Contract_Plan>;
  /** fetch aggregated fields from the table: "nbpm_contract_plan" */
  nbpm_contract_plan_aggregate: Nbpm_Contract_Plan_Aggregate;
  /** fetch data from the table: "nbpm_contract_plan" using primary key columns */
  nbpm_contract_plan_by_pk?: Maybe<Nbpm_Contract_Plan>;
  /** fetch data from the table: "nbpm_contract_plan_link" */
  nbpm_contract_plan_link: Array<Nbpm_Contract_Plan_Link>;
  /** fetch aggregated fields from the table: "nbpm_contract_plan_link" */
  nbpm_contract_plan_link_aggregate: Nbpm_Contract_Plan_Link_Aggregate;
  /** fetch data from the table: "nbpm_contract_plan_link" using primary key columns */
  nbpm_contract_plan_link_by_pk?: Maybe<Nbpm_Contract_Plan_Link>;
  /** fetch data from the table in a streaming manner: "nbpm_contract_plan_link" */
  nbpm_contract_plan_link_stream: Array<Nbpm_Contract_Plan_Link>;
  /** fetch data from the table in a streaming manner: "nbpm_contract_plan" */
  nbpm_contract_plan_stream: Array<Nbpm_Contract_Plan>;
  /** fetch data from the table: "nbpm_imsattr_authorization_link" */
  nbpm_imsattr_authorization_link: Array<Nbpm_Imsattr_Authorization_Link>;
  /** fetch aggregated fields from the table: "nbpm_imsattr_authorization_link" */
  nbpm_imsattr_authorization_link_aggregate: Nbpm_Imsattr_Authorization_Link_Aggregate;
  /** fetch data from the table: "nbpm_imsattr_authorization_link" using primary key columns */
  nbpm_imsattr_authorization_link_by_pk?: Maybe<Nbpm_Imsattr_Authorization_Link>;
  /** fetch data from the table in a streaming manner: "nbpm_imsattr_authorization_link" */
  nbpm_imsattr_authorization_link_stream: Array<Nbpm_Imsattr_Authorization_Link>;
  /** fetch data from the table: "nbpm_ipengine_authorization_link" */
  nbpm_ipengine_authorization_link: Array<Nbpm_Ipengine_Authorization_Link>;
  /** fetch aggregated fields from the table: "nbpm_ipengine_authorization_link" */
  nbpm_ipengine_authorization_link_aggregate: Nbpm_Ipengine_Authorization_Link_Aggregate;
  /** fetch data from the table: "nbpm_ipengine_authorization_link" using primary key columns */
  nbpm_ipengine_authorization_link_by_pk?: Maybe<Nbpm_Ipengine_Authorization_Link>;
  /** fetch data from the table in a streaming manner: "nbpm_ipengine_authorization_link" */
  nbpm_ipengine_authorization_link_stream: Array<Nbpm_Ipengine_Authorization_Link>;
  /** fetch data from the table: "nbpm_picanatype_authorization_link" */
  nbpm_picanatype_authorization_link: Array<Nbpm_Picanatype_Authorization_Link>;
  /** fetch aggregated fields from the table: "nbpm_picanatype_authorization_link" */
  nbpm_picanatype_authorization_link_aggregate: Nbpm_Picanatype_Authorization_Link_Aggregate;
  /** fetch data from the table: "nbpm_picanatype_authorization_link" using primary key columns */
  nbpm_picanatype_authorization_link_by_pk?: Maybe<Nbpm_Picanatype_Authorization_Link>;
  /** fetch data from the table in a streaming manner: "nbpm_picanatype_authorization_link" */
  nbpm_picanatype_authorization_link_stream: Array<Nbpm_Picanatype_Authorization_Link>;
  /** fetch data from the table: "nbpm_service_plan" */
  nbpm_service_plan: Array<Nbpm_Service_Plan>;
  /** fetch aggregated fields from the table: "nbpm_service_plan" */
  nbpm_service_plan_aggregate: Nbpm_Service_Plan_Aggregate;
  /** fetch data from the table: "nbpm_service_plan" using primary key columns */
  nbpm_service_plan_by_pk?: Maybe<Nbpm_Service_Plan>;
  /** fetch data from the table: "nbpm_service_plan_link" */
  nbpm_service_plan_link: Array<Nbpm_Service_Plan_Link>;
  /** fetch aggregated fields from the table: "nbpm_service_plan_link" */
  nbpm_service_plan_link_aggregate: Nbpm_Service_Plan_Link_Aggregate;
  /** fetch data from the table: "nbpm_service_plan_link" using primary key columns */
  nbpm_service_plan_link_by_pk?: Maybe<Nbpm_Service_Plan_Link>;
  /** fetch data from the table in a streaming manner: "nbpm_service_plan_link" */
  nbpm_service_plan_link_stream: Array<Nbpm_Service_Plan_Link>;
  /** fetch data from the table in a streaming manner: "nbpm_service_plan" */
  nbpm_service_plan_stream: Array<Nbpm_Service_Plan>;
  /** fetch data from the table: "nbpm_user_attr_link" */
  nbpm_user_attr_link: Array<Nbpm_User_Attr_Link>;
  /** fetch aggregated fields from the table: "nbpm_user_attr_link" */
  nbpm_user_attr_link_aggregate: Nbpm_User_Attr_Link_Aggregate;
  /** fetch data from the table: "nbpm_user_attr_link" using primary key columns */
  nbpm_user_attr_link_by_pk?: Maybe<Nbpm_User_Attr_Link>;
  /** fetch data from the table in a streaming manner: "nbpm_user_attr_link" */
  nbpm_user_attr_link_stream: Array<Nbpm_User_Attr_Link>;
  /** fetch data from the table: "nbpm_user_attribute" */
  nbpm_user_attribute: Array<Nbpm_User_Attribute>;
  /** fetch aggregated fields from the table: "nbpm_user_attribute" */
  nbpm_user_attribute_aggregate: Nbpm_User_Attribute_Aggregate;
  /** fetch data from the table: "nbpm_user_attribute" using primary key columns */
  nbpm_user_attribute_by_pk?: Maybe<Nbpm_User_Attribute>;
  /** fetch data from the table in a streaming manner: "nbpm_user_attribute" */
  nbpm_user_attribute_stream: Array<Nbpm_User_Attribute>;
  /** fetch data from the table: "nbpm_user_cp_link_link" */
  nbpm_user_cp_link_link: Array<Nbpm_User_Cp_Link_Link>;
  /** fetch aggregated fields from the table: "nbpm_user_cp_link_link" */
  nbpm_user_cp_link_link_aggregate: Nbpm_User_Cp_Link_Link_Aggregate;
  /** fetch data from the table: "nbpm_user_cp_link_link" using primary key columns */
  nbpm_user_cp_link_link_by_pk?: Maybe<Nbpm_User_Cp_Link_Link>;
  /** fetch data from the table in a streaming manner: "nbpm_user_cp_link_link" */
  nbpm_user_cp_link_link_stream: Array<Nbpm_User_Cp_Link_Link>;
  /** fetch data from the table: "nbs_ipe_run_field" */
  nbs_ipe_run_field: Array<Nbs_Ipe_Run_Field>;
  /** fetch aggregated fields from the table: "nbs_ipe_run_field" */
  nbs_ipe_run_field_aggregate: Nbs_Ipe_Run_Field_Aggregate;
  /** fetch data from the table: "nbs_ipe_run_field" using primary key columns */
  nbs_ipe_run_field_by_pk?: Maybe<Nbs_Ipe_Run_Field>;
  /** fetch data from the table in a streaming manner: "nbs_ipe_run_field" */
  nbs_ipe_run_field_stream: Array<Nbs_Ipe_Run_Field>;
  /** fetch data from the table: "nbs_ipe_run_link_field" */
  nbs_ipe_run_link_field: Array<Nbs_Ipe_Run_Link_Field>;
  /** fetch aggregated fields from the table: "nbs_ipe_run_link_field" */
  nbs_ipe_run_link_field_aggregate: Nbs_Ipe_Run_Link_Field_Aggregate;
  /** fetch data from the table: "nbs_ipe_run_link_field" using primary key columns */
  nbs_ipe_run_link_field_by_pk?: Maybe<Nbs_Ipe_Run_Link_Field>;
  /** fetch data from the table in a streaming manner: "nbs_ipe_run_link_field" */
  nbs_ipe_run_link_field_stream: Array<Nbs_Ipe_Run_Link_Field>;
  /** fetch data from the table: "nbs_ipe_run_link_wide" */
  nbs_ipe_run_link_wide: Array<Nbs_Ipe_Run_Link_Wide>;
  /** fetch aggregated fields from the table: "nbs_ipe_run_link_wide" */
  nbs_ipe_run_link_wide_aggregate: Nbs_Ipe_Run_Link_Wide_Aggregate;
  /** fetch data from the table: "nbs_ipe_run_link_wide" using primary key columns */
  nbs_ipe_run_link_wide_by_pk?: Maybe<Nbs_Ipe_Run_Link_Wide>;
  /** fetch data from the table in a streaming manner: "nbs_ipe_run_link_wide" */
  nbs_ipe_run_link_wide_stream: Array<Nbs_Ipe_Run_Link_Wide>;
  /** fetch data from the table: "nbs_ipe_run_wide" */
  nbs_ipe_run_wide: Array<Nbs_Ipe_Run_Wide>;
  /** fetch aggregated fields from the table: "nbs_ipe_run_wide" */
  nbs_ipe_run_wide_aggregate: Nbs_Ipe_Run_Wide_Aggregate;
  /** fetch data from the table: "nbs_ipe_run_wide" using primary key columns */
  nbs_ipe_run_wide_by_pk?: Maybe<Nbs_Ipe_Run_Wide>;
  /** fetch data from the table in a streaming manner: "nbs_ipe_run_wide" */
  nbs_ipe_run_wide_stream: Array<Nbs_Ipe_Run_Wide>;
  /** fetch data from the table: "nbs_multipart_upload" */
  nbs_multipart_upload: Array<Nbs_Multipart_Upload>;
  /** fetch aggregated fields from the table: "nbs_multipart_upload" */
  nbs_multipart_upload_aggregate: Nbs_Multipart_Upload_Aggregate;
  /** fetch data from the table: "nbs_multipart_upload" using primary key columns */
  nbs_multipart_upload_by_pk?: Maybe<Nbs_Multipart_Upload>;
  /** fetch data from the table in a streaming manner: "nbs_multipart_upload" */
  nbs_multipart_upload_stream: Array<Nbs_Multipart_Upload>;
  /** fetch data from the table: "nbs_wide_orthoimg_load" */
  nbs_wide_orthoimg_load: Array<Nbs_Wide_Orthoimg_Load>;
  /** fetch aggregated fields from the table: "nbs_wide_orthoimg_load" */
  nbs_wide_orthoimg_load_aggregate: Nbs_Wide_Orthoimg_Load_Aggregate;
  /** fetch data from the table: "nbs_wide_orthoimg_load" using primary key columns */
  nbs_wide_orthoimg_load_by_pk?: Maybe<Nbs_Wide_Orthoimg_Load>;
  /** fetch data from the table in a streaming manner: "nbs_wide_orthoimg_load" */
  nbs_wide_orthoimg_load_stream: Array<Nbs_Wide_Orthoimg_Load>;
  /** fetch data from the table: "nbtrk_context" */
  nbtrk_context: Array<Nbtrk_Context>;
  /** fetch aggregated fields from the table: "nbtrk_context" */
  nbtrk_context_aggregate: Nbtrk_Context_Aggregate;
  /** fetch data from the table: "nbtrk_context" using primary key columns */
  nbtrk_context_by_pk?: Maybe<Nbtrk_Context>;
  /** fetch data from the table in a streaming manner: "nbtrk_context" */
  nbtrk_context_stream: Array<Nbtrk_Context>;
  /** fetch data from the table: "nbtrk_eph_location" */
  nbtrk_eph_location: Array<Nbtrk_Eph_Location>;
  /** fetch aggregated fields from the table: "nbtrk_eph_location" */
  nbtrk_eph_location_aggregate: Nbtrk_Eph_Location_Aggregate;
  /** fetch data from the table: "nbtrk_eph_location" using primary key columns */
  nbtrk_eph_location_by_pk?: Maybe<Nbtrk_Eph_Location>;
  /** fetch data from the table in a streaming manner: "nbtrk_eph_location" */
  nbtrk_eph_location_stream: Array<Nbtrk_Eph_Location>;
  /** fetch data from the table: "nipe_engine_information" */
  nipe_engine_information: Array<Nipe_Engine_Information>;
  /** fetch aggregated fields from the table: "nipe_engine_information" */
  nipe_engine_information_aggregate: Nipe_Engine_Information_Aggregate;
  /** fetch data from the table: "nipe_engine_information" using primary key columns */
  nipe_engine_information_by_pk?: Maybe<Nipe_Engine_Information>;
  /** fetch data from the table in a streaming manner: "nipe_engine_information" */
  nipe_engine_information_stream: Array<Nipe_Engine_Information>;
  /** fetch data from the table: "nipe_engine_link_orthoimg_type" */
  nipe_engine_link_orthoimg_type: Array<Nipe_Engine_Link_Orthoimg_Type>;
  /** fetch aggregated fields from the table: "nipe_engine_link_orthoimg_type" */
  nipe_engine_link_orthoimg_type_aggregate: Nipe_Engine_Link_Orthoimg_Type_Aggregate;
  /** fetch data from the table: "nipe_engine_link_orthoimg_type" using primary key columns */
  nipe_engine_link_orthoimg_type_by_pk?: Maybe<Nipe_Engine_Link_Orthoimg_Type>;
  /** fetch data from the table in a streaming manner: "nipe_engine_link_orthoimg_type" */
  nipe_engine_link_orthoimg_type_stream: Array<Nipe_Engine_Link_Orthoimg_Type>;
  /** fetch data from the table: "nipe_engine_run_status" */
  nipe_engine_run_status: Array<Nipe_Engine_Run_Status>;
  /** fetch aggregated fields from the table: "nipe_engine_run_status" */
  nipe_engine_run_status_aggregate: Nipe_Engine_Run_Status_Aggregate;
  /** fetch data from the table: "nipe_engine_run_status" using primary key columns */
  nipe_engine_run_status_by_pk?: Maybe<Nipe_Engine_Run_Status>;
  /** fetch data from the table in a streaming manner: "nipe_engine_run_status" */
  nipe_engine_run_status_stream: Array<Nipe_Engine_Run_Status>;
  /** fetch data from the table: "nipe_run_command" */
  nipe_run_command: Array<Nipe_Run_Command>;
  /** fetch aggregated fields from the table: "nipe_run_command" */
  nipe_run_command_aggregate: Nipe_Run_Command_Aggregate;
  /** fetch data from the table: "nipe_run_command" using primary key columns */
  nipe_run_command_by_pk?: Maybe<Nipe_Run_Command>;
  /** fetch data from the table in a streaming manner: "nipe_run_command" */
  nipe_run_command_stream: Array<Nipe_Run_Command>;
};


export type Subscription_RootApp_UserArgs = {
  distinct_on?: InputMaybe<Array<App_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Order_By>>;
  where?: InputMaybe<App_User_Bool_Exp>;
};


export type Subscription_RootApp_User_ActiveArgs = {
  distinct_on?: InputMaybe<Array<App_User_Active_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Active_Order_By>>;
  where?: InputMaybe<App_User_Active_Bool_Exp>;
};


export type Subscription_RootApp_User_Active_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_User_Active_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Active_Order_By>>;
  where?: InputMaybe<App_User_Active_Bool_Exp>;
};


export type Subscription_RootApp_User_Active_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootApp_User_Active_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<App_User_Active_Stream_Cursor_Input>>;
  where?: InputMaybe<App_User_Active_Bool_Exp>;
};


export type Subscription_RootApp_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Order_By>>;
  where?: InputMaybe<App_User_Bool_Exp>;
};


export type Subscription_RootApp_User_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootApp_User_Property_PrivateArgs = {
  distinct_on?: InputMaybe<Array<App_User_Property_Private_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Property_Private_Order_By>>;
  where?: InputMaybe<App_User_Property_Private_Bool_Exp>;
};


export type Subscription_RootApp_User_Property_Private_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_User_Property_Private_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_User_Property_Private_Order_By>>;
  where?: InputMaybe<App_User_Property_Private_Bool_Exp>;
};


export type Subscription_RootApp_User_Property_Private_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootApp_User_Property_Private_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<App_User_Property_Private_Stream_Cursor_Input>>;
  where?: InputMaybe<App_User_Property_Private_Bool_Exp>;
};


export type Subscription_RootApp_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<App_User_Stream_Cursor_Input>>;
  where?: InputMaybe<App_User_Bool_Exp>;
};


export type Subscription_RootApplication_User_Invitation_To_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


export type Subscription_RootApplication_User_Invitation_To_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Application_User_Invitation_To_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Application_User_Invitation_To_Company_Order_By>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


export type Subscription_RootApplication_User_Invitation_To_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootApplication_User_Invitation_To_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Application_User_Invitation_To_Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Application_User_Invitation_To_Company_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Area_MeshArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Area_Mesh_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Area_Mesh_Order_By>>;
  where?: InputMaybe<Master_Nb_Area_Mesh_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Area_Mesh_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Area_Mesh_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Area_Mesh_Order_By>>;
  where?: InputMaybe<Master_Nb_Area_Mesh_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Area_Mesh_By_PkArgs = {
  code: Scalars['bigint'];
};


export type Subscription_RootMaster_Nb_Area_Mesh_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Master_Nb_Area_Mesh_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Nb_Area_Mesh_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Integrated_FarmlandArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Integrated_Farmland_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Integrated_Farmland_Order_By>>;
  where?: InputMaybe<Master_Nb_Integrated_Farmland_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Integrated_Farmland_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Integrated_Farmland_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Integrated_Farmland_Order_By>>;
  where?: InputMaybe<Master_Nb_Integrated_Farmland_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Integrated_Farmland_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Subscription_RootMaster_Nb_Integrated_Farmland_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Master_Nb_Integrated_Farmland_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Nb_Integrated_Farmland_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_MfieldArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2022_1Args = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2022_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2022_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2022_1_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2022_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2022_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2022_1_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2022_1_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2022_1_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2023_1Args = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2023_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2023_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2023_1_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2023_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2023_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2023_1_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2023_1_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2023_1_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2024_1Args = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2024_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2024_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2024_1_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2024_1_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Polygon_2024_1_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2024_1_By_PkArgs = {
  key: Scalars['uuid'];
};


export type Subscription_RootMaster_Nb_Mfield_Polygon_2024_1_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Nb_Mfield_Polygon_2024_1_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Master_Nb_Mfield_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Nb_Mfield_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_VersionArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Version_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Version_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Mfield_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Mfield_Version_Order_By>>;
  where?: InputMaybe<Master_Nb_Mfield_Version_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Mfield_Version_By_PkArgs = {
  id: Scalars['smallint'];
};


export type Subscription_RootMaster_Nb_Mfield_Version_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Master_Nb_Mfield_Version_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Nb_Mfield_Version_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Postgis_VersionArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Postgis_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Postgis_Version_Order_By>>;
  where?: InputMaybe<Master_Nb_Postgis_Version_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Postgis_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Postgis_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Postgis_Version_Order_By>>;
  where?: InputMaybe<Master_Nb_Postgis_Version_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Postgis_Version_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMaster_Nb_Postgis_Version_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Master_Nb_Postgis_Version_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Nb_Postgis_Version_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Weather_AttrArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Attr_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Attr_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Weather_Attr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Attr_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Attr_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Weather_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMaster_Nb_Weather_Attr_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Master_Nb_Weather_Attr_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Nb_Weather_Attr_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Weather_DataArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Weather_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Master_Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
};


export type Subscription_RootMaster_Nb_Weather_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMaster_Nb_Weather_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Master_Nb_Weather_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Nb_Weather_Data_Bool_Exp>;
};


export type Subscription_RootNb_Actual_Crop_YeildArgs = {
  distinct_on?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Order_By>>;
  where?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
};


export type Subscription_RootNb_Actual_Crop_Yeild_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Actual_Crop_Yeild_Order_By>>;
  where?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
};


export type Subscription_RootNb_Actual_Crop_Yeild_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Actual_Crop_Yeild_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Actual_Crop_Yeild_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Actual_Crop_Yeild_Bool_Exp>;
};


export type Subscription_RootNb_Area_MeshArgs = {
  distinct_on?: InputMaybe<Array<Nb_Area_Mesh_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Area_Mesh_Order_By>>;
  where?: InputMaybe<Nb_Area_Mesh_Bool_Exp>;
};


export type Subscription_RootNb_Area_Mesh_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Area_Mesh_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Area_Mesh_Order_By>>;
  where?: InputMaybe<Nb_Area_Mesh_Bool_Exp>;
};


export type Subscription_RootNb_Area_Mesh_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Area_Mesh_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Area_Mesh_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Area_Mesh_Bool_Exp>;
};


export type Subscription_RootNb_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Order_By>>;
  where?: InputMaybe<Nb_Company_Bool_Exp>;
};


export type Subscription_RootNb_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Order_By>>;
  where?: InputMaybe<Nb_Company_Bool_Exp>;
};


export type Subscription_RootNb_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Company_InfoArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Info_Order_By>>;
  where?: InputMaybe<Nb_Company_Info_Bool_Exp>;
};


export type Subscription_RootNb_Company_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Company_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Company_Info_Order_By>>;
  where?: InputMaybe<Nb_Company_Info_Bool_Exp>;
};


export type Subscription_RootNb_Company_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Company_Info_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Company_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Company_Info_Bool_Exp>;
};


export type Subscription_RootNb_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Company_Bool_Exp>;
};


export type Subscription_RootNb_Conditioner_WorkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


export type Subscription_RootNb_Conditioner_Work_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Conditioner_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Conditioner_Work_Order_By>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


export type Subscription_RootNb_Conditioner_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Conditioner_Work_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Conditioner_Work_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Conditioner_Work_Bool_Exp>;
};


export type Subscription_RootNb_Cplan_Imsensing_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Order_By>>;
  where?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
};


export type Subscription_RootNb_Cplan_Imsensing_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cplan_Imsensing_Link_Order_By>>;
  where?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
};


export type Subscription_RootNb_Cplan_Imsensing_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Cplan_Imsensing_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Cplan_Imsensing_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Cplan_Imsensing_Link_Bool_Exp>;
};


export type Subscription_RootNb_CropArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Order_By>>;
  where?: InputMaybe<Nb_Crop_Bool_Exp>;
};


export type Subscription_RootNb_Crop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Order_By>>;
  where?: InputMaybe<Nb_Crop_Bool_Exp>;
};


export type Subscription_RootNb_Crop_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Crop_Company_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Crop_Company_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Crop_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Crop_Company_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Crop_Company_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Crop_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Crop_Method_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Method_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Method_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
};


export type Subscription_RootNb_Crop_Method_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Crop_Method_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Crop_Method_Link_Order_By>>;
  where?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
};


export type Subscription_RootNb_Crop_Method_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Crop_Method_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Crop_Method_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Crop_Method_Link_Bool_Exp>;
};


export type Subscription_RootNb_Crop_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Crop_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Crop_Bool_Exp>;
};


export type Subscription_RootNb_CultivarArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivar_Order_By>>;
  where?: InputMaybe<Nb_Cultivar_Bool_Exp>;
};


export type Subscription_RootNb_Cultivar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivar_Order_By>>;
  where?: InputMaybe<Nb_Cultivar_Bool_Exp>;
};


export type Subscription_RootNb_Cultivar_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Cultivar_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Cultivar_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Cultivar_Bool_Exp>;
};


export type Subscription_RootNb_Cultivation_MethodArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Method_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Method_Bool_Exp>;
};


export type Subscription_RootNb_Cultivation_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Method_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Method_Bool_Exp>;
};


export type Subscription_RootNb_Cultivation_Method_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Cultivation_Method_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Cultivation_Method_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Cultivation_Method_Bool_Exp>;
};


export type Subscription_RootNb_Cultivation_PlanArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


export type Subscription_RootNb_Cultivation_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Cultivation_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Cultivation_Plan_Order_By>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


export type Subscription_RootNb_Cultivation_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Cultivation_Plan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Cultivation_Plan_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Cultivation_Plan_Bool_Exp>;
};


export type Subscription_RootNb_Demo_Task_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_Task_Type_Order_By>>;
  where?: InputMaybe<Nb_Demo_Task_Type_Bool_Exp>;
};


export type Subscription_RootNb_Demo_Task_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_Task_Type_Order_By>>;
  where?: InputMaybe<Nb_Demo_Task_Type_Bool_Exp>;
};


export type Subscription_RootNb_Demo_Task_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootNb_Demo_Task_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Demo_Task_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Demo_Task_Type_Bool_Exp>;
};


export type Subscription_RootNb_Demo_User_LocationArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Location_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
};


export type Subscription_RootNb_Demo_User_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Location_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
};


export type Subscription_RootNb_Demo_User_Location_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Demo_User_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Demo_User_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Demo_User_Location_Bool_Exp>;
};


export type Subscription_RootNb_Demo_User_NameArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Name_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
};


export type Subscription_RootNb_Demo_User_Name_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Demo_User_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Demo_User_Name_Order_By>>;
  where?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
};


export type Subscription_RootNb_Demo_User_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootNb_Demo_User_Name_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Demo_User_Name_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Demo_User_Name_Bool_Exp>;
};


export type Subscription_RootNb_Est_Working_LoadArgs = {
  distinct_on?: InputMaybe<Array<Nb_Est_Working_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Est_Working_Load_Order_By>>;
  where?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
};


export type Subscription_RootNb_Est_Working_Load_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Est_Working_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Est_Working_Load_Order_By>>;
  where?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
};


export type Subscription_RootNb_Est_Working_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Est_Working_Load_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Est_Working_Load_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Est_Working_Load_Bool_Exp>;
};


export type Subscription_RootNb_Fertilization_MethodArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Method_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Method_Bool_Exp>;
};


export type Subscription_RootNb_Fertilization_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Method_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Method_Bool_Exp>;
};


export type Subscription_RootNb_Fertilization_Method_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Fertilization_Method_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Fertilization_Method_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Fertilization_Method_Bool_Exp>;
};


export type Subscription_RootNb_Fertilization_WorkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


export type Subscription_RootNb_Fertilization_Work_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilization_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilization_Work_Order_By>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


export type Subscription_RootNb_Fertilization_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Fertilization_Work_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Fertilization_Work_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Fertilization_Work_Bool_Exp>;
};


export type Subscription_RootNb_FertilizerArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Bool_Exp>;
};


export type Subscription_RootNb_Fertilizer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Bool_Exp>;
};


export type Subscription_RootNb_Fertilizer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Fertilizer_Company_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Fertilizer_Company_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Fertilizer_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Fertilizer_Company_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Fertilizer_Company_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Fertilizer_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Fertilizer_PictureArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Picture_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Picture_Bool_Exp>;
};


export type Subscription_RootNb_Fertilizer_Picture_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Fertilizer_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Fertilizer_Picture_Order_By>>;
  where?: InputMaybe<Nb_Fertilizer_Picture_Bool_Exp>;
};


export type Subscription_RootNb_Fertilizer_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Fertilizer_Picture_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Fertilizer_Picture_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Fertilizer_Picture_Bool_Exp>;
};


export type Subscription_RootNb_Fertilizer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Fertilizer_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Fertilizer_Bool_Exp>;
};


export type Subscription_RootNb_Field_FileArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Bool_Exp>;
};


export type Subscription_RootNb_Field_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Bool_Exp>;
};


export type Subscription_RootNb_Field_File_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_File_LoadArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


export type Subscription_RootNb_Field_File_Load_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


export type Subscription_RootNb_Field_File_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_File_Load_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_File_Load_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_File_Load_Bool_Exp>;
};


export type Subscription_RootNb_Field_File_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Size_Bool_Exp>;
};


export type Subscription_RootNb_Field_File_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_File_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_File_Size_Bool_Exp>;
};


export type Subscription_RootNb_Field_File_Size_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_File_Size_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_File_Size_Bool_Exp>;
};


export type Subscription_RootNb_Field_File_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_File_Bool_Exp>;
};


export type Subscription_RootNb_Field_MemoArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Memo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Memo_Order_By>>;
  where?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
};


export type Subscription_RootNb_Field_Memo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Memo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Memo_Order_By>>;
  where?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
};


export type Subscription_RootNb_Field_Memo_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Memo_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Memo_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Memo_Bool_Exp>;
};


export type Subscription_RootNb_Field_OrthoimgArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};


export type Subscription_RootNb_Field_Orthoimg_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};


export type Subscription_RootNb_Field_Orthoimg_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Orthoimg_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Size_Bool_Exp>;
};


export type Subscription_RootNb_Field_Orthoimg_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Size_Bool_Exp>;
};


export type Subscription_RootNb_Field_Orthoimg_Size_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Orthoimg_Size_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Size_Bool_Exp>;
};


export type Subscription_RootNb_Field_Orthoimg_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Orthoimg_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Orthoimg_Bool_Exp>;
};


export type Subscription_RootNb_Field_PhotographerArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Photographer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Photographer_Order_By>>;
  where?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
};


export type Subscription_RootNb_Field_Photographer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Photographer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Photographer_Order_By>>;
  where?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
};


export type Subscription_RootNb_Field_Photographer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Photographer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Photographer_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Photographer_Bool_Exp>;
};


export type Subscription_RootNb_Field_PictureArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
};


export type Subscription_RootNb_Field_Picture_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
};


export type Subscription_RootNb_Field_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Picture_Cplan_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
};


export type Subscription_RootNb_Field_Picture_Cplan_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
};


export type Subscription_RootNb_Field_Picture_Cplan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Picture_Cplan_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Picture_Cplan_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Picture_Cplan_Link_Bool_Exp>;
};


export type Subscription_RootNb_Field_Picture_LoadArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


export type Subscription_RootNb_Field_Picture_Load_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Load_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


export type Subscription_RootNb_Field_Picture_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Picture_Load_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Picture_Load_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Picture_Load_Bool_Exp>;
};


export type Subscription_RootNb_Field_Picture_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Size_Bool_Exp>;
};


export type Subscription_RootNb_Field_Picture_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Picture_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Picture_Size_Order_By>>;
  where?: InputMaybe<Nb_Field_Picture_Size_Bool_Exp>;
};


export type Subscription_RootNb_Field_Picture_Size_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Picture_Size_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Picture_Size_Bool_Exp>;
};


export type Subscription_RootNb_Field_Picture_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Picture_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Picture_Bool_Exp>;
};


export type Subscription_RootNb_Field_Polygon_AttrArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Polygon_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Polygon_Attr_Order_By>>;
  where?: InputMaybe<Nb_Field_Polygon_Attr_Bool_Exp>;
};


export type Subscription_RootNb_Field_Polygon_Attr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Polygon_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Polygon_Attr_Order_By>>;
  where?: InputMaybe<Nb_Field_Polygon_Attr_Bool_Exp>;
};


export type Subscription_RootNb_Field_Polygon_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Polygon_Attr_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Polygon_Attr_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Polygon_Attr_Bool_Exp>;
};


export type Subscription_RootNb_Field_PropertyArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Property_Order_By>>;
  where?: InputMaybe<Nb_Field_Property_Bool_Exp>;
};


export type Subscription_RootNb_Field_Property_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Property_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Property_Order_By>>;
  where?: InputMaybe<Nb_Field_Property_Bool_Exp>;
};


export type Subscription_RootNb_Field_Property_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Property_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Property_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Property_Bool_Exp>;
};


export type Subscription_RootNb_Field_SensingArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
};


export type Subscription_RootNb_Field_Sensing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
};


export type Subscription_RootNb_Field_Sensing_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Sensing_DataArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Data_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Data_Bool_Exp>;
};


export type Subscription_RootNb_Field_Sensing_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Sensing_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Sensing_Data_Order_By>>;
  where?: InputMaybe<Nb_Field_Sensing_Data_Bool_Exp>;
};


export type Subscription_RootNb_Field_Sensing_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Sensing_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Sensing_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Sensing_Data_Bool_Exp>;
};


export type Subscription_RootNb_Field_Sensing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Sensing_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Sensing_Bool_Exp>;
};


export type Subscription_RootNb_Field_Water_GateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
};


export type Subscription_RootNb_Field_Water_Gate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
};


export type Subscription_RootNb_Field_Water_Gate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Water_Gate_StatusArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
};


export type Subscription_RootNb_Field_Water_Gate_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Order_By>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
};


export type Subscription_RootNb_Field_Water_Gate_Status_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Field_Water_Gate_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Water_Gate_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Status_Bool_Exp>;
};


export type Subscription_RootNb_Field_Water_Gate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Field_Water_Gate_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Field_Water_Gate_Bool_Exp>;
};


export type Subscription_RootNb_File_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};


export type Subscription_RootNb_File_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};


export type Subscription_RootNb_File_Size_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_File_Size_Company_User_ViewArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Company_User_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Company_User_View_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Company_User_View_Bool_Exp>;
};


export type Subscription_RootNb_File_Size_Company_User_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Company_User_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Company_User_View_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Company_User_View_Bool_Exp>;
};


export type Subscription_RootNb_File_Size_Company_User_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_File_Size_Company_User_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_File_Size_Company_User_View_Bool_Exp>;
};


export type Subscription_RootNb_File_Size_Company_ViewArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Company_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Company_View_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Company_View_Bool_Exp>;
};


export type Subscription_RootNb_File_Size_Company_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Size_Company_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Size_Company_View_Order_By>>;
  where?: InputMaybe<Nb_File_Size_Company_View_Bool_Exp>;
};


export type Subscription_RootNb_File_Size_Company_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_File_Size_Company_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_File_Size_Company_View_Bool_Exp>;
};


export type Subscription_RootNb_File_Size_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_File_Size_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_File_Size_Bool_Exp>;
};


export type Subscription_RootNb_File_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Type_Order_By>>;
  where?: InputMaybe<Nb_File_Type_Bool_Exp>;
};


export type Subscription_RootNb_File_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_File_Type_Order_By>>;
  where?: InputMaybe<Nb_File_Type_Bool_Exp>;
};


export type Subscription_RootNb_File_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootNb_File_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_File_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_File_Type_Bool_Exp>;
};


export type Subscription_RootNb_Finished_Ufield_WorkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


export type Subscription_RootNb_Finished_Ufield_Work_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Finished_Ufield_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Finished_Ufield_Work_Order_By>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


export type Subscription_RootNb_Finished_Ufield_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Finished_Ufield_Work_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Finished_Ufield_Work_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Finished_Ufield_Work_Bool_Exp>;
};


export type Subscription_RootNb_Image_Sensing_AttrArgs = {
  distinct_on?: InputMaybe<Array<Nb_Image_Sensing_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Image_Sensing_Attr_Order_By>>;
  where?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
};


export type Subscription_RootNb_Image_Sensing_Attr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Image_Sensing_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Image_Sensing_Attr_Order_By>>;
  where?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
};


export type Subscription_RootNb_Image_Sensing_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Image_Sensing_Attr_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Image_Sensing_Attr_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Image_Sensing_Attr_Bool_Exp>;
};


export type Subscription_RootNb_Imsensing_ColormapArgs = {
  distinct_on?: InputMaybe<Array<Nb_Imsensing_Colormap_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Imsensing_Colormap_Order_By>>;
  where?: InputMaybe<Nb_Imsensing_Colormap_Bool_Exp>;
};


export type Subscription_RootNb_Imsensing_Colormap_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Imsensing_Colormap_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Imsensing_Colormap_Order_By>>;
  where?: InputMaybe<Nb_Imsensing_Colormap_Bool_Exp>;
};


export type Subscription_RootNb_Imsensing_Colormap_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Imsensing_Colormap_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Imsensing_Colormap_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Imsensing_Colormap_Bool_Exp>;
};


export type Subscription_RootNb_Log_User_PositionArgs = {
  distinct_on?: InputMaybe<Array<Nb_Log_User_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Log_User_Position_Order_By>>;
  where?: InputMaybe<Nb_Log_User_Position_Bool_Exp>;
};


export type Subscription_RootNb_Log_User_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Log_User_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Log_User_Position_Order_By>>;
  where?: InputMaybe<Nb_Log_User_Position_Bool_Exp>;
};


export type Subscription_RootNb_Log_User_Position_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Log_User_Position_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Log_User_Position_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Log_User_Position_Bool_Exp>;
};


export type Subscription_RootNb_Maff_FieldArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
};


export type Subscription_RootNb_Maff_Field_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
};


export type Subscription_RootNb_Maff_Field_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Maff_Field_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Link_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
};


export type Subscription_RootNb_Maff_Field_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Maff_Field_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Maff_Field_Link_Order_By>>;
  where?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
};


export type Subscription_RootNb_Maff_Field_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Maff_Field_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Maff_Field_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Maff_Field_Link_Bool_Exp>;
};


export type Subscription_RootNb_Maff_Field_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Maff_Field_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Maff_Field_Bool_Exp>;
};


export type Subscription_RootNb_Memo_File_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_File_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_File_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
};


export type Subscription_RootNb_Memo_File_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_File_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_File_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
};


export type Subscription_RootNb_Memo_File_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Memo_File_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Memo_File_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Memo_File_Link_Bool_Exp>;
};


export type Subscription_RootNb_Memo_Picture_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Picture_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Picture_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
};


export type Subscription_RootNb_Memo_Picture_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Picture_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Picture_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
};


export type Subscription_RootNb_Memo_Picture_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Memo_Picture_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Memo_Picture_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Memo_Picture_Link_Bool_Exp>;
};


export type Subscription_RootNb_Memo_Ufg_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
};


export type Subscription_RootNb_Memo_Ufg_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
};


export type Subscription_RootNb_Memo_Ufg_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Memo_Ufg_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Memo_Ufg_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Memo_Ufg_Link_Bool_Exp>;
};


export type Subscription_RootNb_Memo_Ufield_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufield_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
};


export type Subscription_RootNb_Memo_Ufield_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Memo_Ufield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Memo_Ufield_Link_Order_By>>;
  where?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
};


export type Subscription_RootNb_Memo_Ufield_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Memo_Ufield_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Memo_Ufield_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Memo_Ufield_Link_Bool_Exp>;
};


export type Subscription_RootNb_MfieldArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Bool_Exp>;
};


export type Subscription_RootNb_Mfield_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Bool_Exp>;
};


export type Subscription_RootNb_Mfield_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Mfield_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Link_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};


export type Subscription_RootNb_Mfield_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Mfield_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Mfield_Link_Order_By>>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};


export type Subscription_RootNb_Mfield_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Mfield_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Mfield_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Mfield_Link_Bool_Exp>;
};


export type Subscription_RootNb_Mfield_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Mfield_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Mfield_Bool_Exp>;
};


export type Subscription_RootNb_Performed_WorkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


export type Subscription_RootNb_Performed_Work_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Performed_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Performed_Work_Order_By>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


export type Subscription_RootNb_Performed_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Performed_Work_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Performed_Work_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Performed_Work_Bool_Exp>;
};


export type Subscription_RootNb_PesticideArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Bool_Exp>;
};


export type Subscription_RootNb_Pesticide_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Bool_Exp>;
};


export type Subscription_RootNb_Pesticide_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Pesticide_Company_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Pesticide_Company_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Pesticide_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Pesticide_Company_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Pesticide_Company_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Pesticide_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Pesticide_IngredientArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Ingredient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Ingredient_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Ingredient_Bool_Exp>;
};


export type Subscription_RootNb_Pesticide_Ingredient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Ingredient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Ingredient_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Ingredient_Bool_Exp>;
};


export type Subscription_RootNb_Pesticide_Ingredient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Pesticide_Ingredient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Pesticide_Ingredient_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Pesticide_Ingredient_Bool_Exp>;
};


export type Subscription_RootNb_Pesticide_Spray_WorkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
};


export type Subscription_RootNb_Pesticide_Spray_Work_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Pesticide_Spray_Work_Order_By>>;
  where?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
};


export type Subscription_RootNb_Pesticide_Spray_Work_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Pesticide_Spray_Work_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Pesticide_Spray_Work_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Pesticide_Spray_Work_Bool_Exp>;
};


export type Subscription_RootNb_Pesticide_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Pesticide_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Pesticide_Bool_Exp>;
};


export type Subscription_RootNb_Picture_AnalysisArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
};


export type Subscription_RootNb_Picture_Analysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
};


export type Subscription_RootNb_Picture_Analysis_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Picture_Analysis_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Picture_Analysis_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Picture_Analysis_Bool_Exp>;
};


export type Subscription_RootNb_Picture_Analysis_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Type_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Type_Bool_Exp>;
};


export type Subscription_RootNb_Picture_Analysis_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Picture_Analysis_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Picture_Analysis_Type_Order_By>>;
  where?: InputMaybe<Nb_Picture_Analysis_Type_Bool_Exp>;
};


export type Subscription_RootNb_Picture_Analysis_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Picture_Analysis_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Picture_Analysis_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Picture_Analysis_Type_Bool_Exp>;
};


export type Subscription_RootNb_Raster_Image_SensingArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};


export type Subscription_RootNb_Raster_Image_Sensing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};


export type Subscription_RootNb_Raster_Image_Sensing_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Raster_Image_Sensing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Raster_Image_Sensing_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Raster_Image_Sensing_Bool_Exp>;
};


export type Subscription_RootNb_Raster_Ims_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Raster_Ims_Size_Bool_Exp>;
};


export type Subscription_RootNb_Raster_Ims_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Raster_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Raster_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Raster_Ims_Size_Bool_Exp>;
};


export type Subscription_RootNb_Raster_Ims_Size_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Raster_Ims_Size_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Raster_Ims_Size_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chara_MapArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chara_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chara_Map_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chara_Map_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chara_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chara_Map_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chara_Map_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Soil_Chara_Map_SellistArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chara_Map_Sellist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chara_Map_Sellist_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chara_Map_Sellist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chara_Map_Sellist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chara_Map_Sellist_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chara_Map_Sellist_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Soil_Chara_Map_Sellist_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Soil_Chara_Map_Sellist_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Sellist_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chara_Map_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Soil_Chara_Map_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Soil_Chara_Map_Bool_Exp>;
};


export type Subscription_RootNb_Soil_CharacteristicArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Characteristic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Characteristic_Order_By>>;
  where?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Characteristic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Characteristic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Characteristic_Order_By>>;
  where?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Characteristic_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Soil_Characteristic_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Soil_Characteristic_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Soil_Characteristic_Bool_Exp>;
};


export type Subscription_RootNb_Soil_ChemistryArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Soil_Chemistry_Proper_RangeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_Proper_Range_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Proper_Range_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_Proper_Range_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Soil_Chemistry_Proper_Range_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Soil_Chemistry_Proper_Range_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Proper_Range_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_StandardsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_Standards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Standards_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_Standards_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Soil_Chemistry_Standards_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Soil_Chemistry_Standards_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Standards_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Soil_Chemistry_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_Suitable_RangeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Suitable_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Suitable_Range_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_Suitable_Range_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Suitable_Range_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Suitable_Range_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_Suitable_Range_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Soil_Chemistry_Suitable_Range_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Suitable_Range_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_ValueArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_Value_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Chemistry_Value_Order_By>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Chemistry_Value_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Soil_Chemistry_Value_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Soil_Chemistry_Value_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Soil_Chemistry_Value_Bool_Exp>;
};


export type Subscription_RootNb_Soil_ExplanationArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Explanation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Explanation_Order_By>>;
  where?: InputMaybe<Nb_Soil_Explanation_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Explanation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Explanation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Explanation_Order_By>>;
  where?: InputMaybe<Nb_Soil_Explanation_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Explanation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Soil_Explanation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Soil_Explanation_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Soil_Explanation_Bool_Exp>;
};


export type Subscription_RootNb_Soil_PhysicsArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Physics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Physics_Order_By>>;
  where?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Physics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Soil_Physics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Soil_Physics_Order_By>>;
  where?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
};


export type Subscription_RootNb_Soil_Physics_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Soil_Physics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Soil_Physics_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Soil_Physics_Bool_Exp>;
};


export type Subscription_RootNb_Task_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Task_Type_Order_By>>;
  where?: InputMaybe<Nb_Task_Type_Bool_Exp>;
};


export type Subscription_RootNb_Task_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Task_Type_Order_By>>;
  where?: InputMaybe<Nb_Task_Type_Bool_Exp>;
};


export type Subscription_RootNb_Task_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootNb_Task_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Task_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Task_Type_Bool_Exp>;
};


export type Subscription_RootNb_Tasktype_Company_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Tasktype_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Tasktype_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Tasktype_Company_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Tasktype_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Tasktype_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Tasktype_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Tasktype_Company_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Tasktype_Company_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Tasktype_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufattr_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufattr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufattr_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufattr_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufattr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufattr_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufattr_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Ufattr_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Ufattr_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Ufattr_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufg_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufg_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufg_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Ufg_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Ufg_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Ufg_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufg_Member_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Member_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Member_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufg_Member_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufg_Member_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufg_Member_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufg_Member_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Ufg_Member_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Ufg_Member_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Ufg_Member_Link_Bool_Exp>;
};


export type Subscription_RootNb_UfieldArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Attribute_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Attribute_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_Attribute_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Ufield_Attribute_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Ufield_Attribute_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Ufield_Attribute_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Ufield_Company_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_Company_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Company_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Company_Link_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_Company_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Ufield_Company_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Ufield_Company_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Ufield_Company_Link_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_GroupArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Group_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_Group_Order_By>>;
  where?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_Group_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Ufield_Group_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Ufield_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Ufield_Group_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_History_Order_By>>;
  where?: InputMaybe<Nb_Ufield_History_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Ufield_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Ufield_History_Order_By>>;
  where?: InputMaybe<Nb_Ufield_History_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_History_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Ufield_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Ufield_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Ufield_History_Bool_Exp>;
};


export type Subscription_RootNb_Ufield_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Ufield_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Ufield_Bool_Exp>;
};


export type Subscription_RootNb_Vector_Image_SensingArgs = {
  distinct_on?: InputMaybe<Array<Nb_Vector_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Vector_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Vector_Image_Sensing_Bool_Exp>;
};


export type Subscription_RootNb_Vector_Image_Sensing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Vector_Image_Sensing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Vector_Image_Sensing_Order_By>>;
  where?: InputMaybe<Nb_Vector_Image_Sensing_Bool_Exp>;
};


export type Subscription_RootNb_Vector_Image_Sensing_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Vector_Image_Sensing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Vector_Image_Sensing_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Vector_Image_Sensing_Bool_Exp>;
};


export type Subscription_RootNb_Vector_Ims_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Vector_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Vector_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Vector_Ims_Size_Bool_Exp>;
};


export type Subscription_RootNb_Vector_Ims_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Vector_Ims_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Vector_Ims_Size_Order_By>>;
  where?: InputMaybe<Nb_Vector_Ims_Size_Bool_Exp>;
};


export type Subscription_RootNb_Vector_Ims_Size_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Vector_Ims_Size_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Vector_Ims_Size_Bool_Exp>;
};


export type Subscription_RootNb_Water_Gate_Status_Cplan_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
};


export type Subscription_RootNb_Water_Gate_Status_Cplan_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Water_Gate_Status_Cplan_Link_Order_By>>;
  where?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
};


export type Subscription_RootNb_Water_Gate_Status_Cplan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Water_Gate_Status_Cplan_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Water_Gate_Status_Cplan_Link_Bool_Exp>;
};


export type Subscription_RootNb_Weather_AttrArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Attr_Order_By>>;
  where?: InputMaybe<Nb_Weather_Attr_Bool_Exp>;
};


export type Subscription_RootNb_Weather_Attr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Attr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Attr_Order_By>>;
  where?: InputMaybe<Nb_Weather_Attr_Bool_Exp>;
};


export type Subscription_RootNb_Weather_Attr_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Weather_Attr_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Weather_Attr_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Weather_Attr_Bool_Exp>;
};


export type Subscription_RootNb_Weather_DataArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
};


export type Subscription_RootNb_Weather_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Weather_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Weather_Data_Order_By>>;
  where?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
};


export type Subscription_RootNb_Weather_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Weather_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Weather_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Weather_Data_Bool_Exp>;
};


export type Subscription_RootNb_Wide_OrthoimgArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};


export type Subscription_RootNb_Wide_Orthoimg_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};


export type Subscription_RootNb_Wide_Orthoimg_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Wide_Orthoimg_SizeArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Size_Bool_Exp>;
};


export type Subscription_RootNb_Wide_Orthoimg_Size_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Wide_Orthoimg_Size_Order_By>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Size_Bool_Exp>;
};


export type Subscription_RootNb_Wide_Orthoimg_Size_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Wide_Orthoimg_Size_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Size_Bool_Exp>;
};


export type Subscription_RootNb_Wide_Orthoimg_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Wide_Orthoimg_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Wide_Orthoimg_Bool_Exp>;
};


export type Subscription_RootNb_Working_MachineArgs = {
  distinct_on?: InputMaybe<Array<Nb_Working_Machine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Working_Machine_Order_By>>;
  where?: InputMaybe<Nb_Working_Machine_Bool_Exp>;
};


export type Subscription_RootNb_Working_Machine_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nb_Working_Machine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nb_Working_Machine_Order_By>>;
  where?: InputMaybe<Nb_Working_Machine_Bool_Exp>;
};


export type Subscription_RootNb_Working_Machine_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNb_Working_Machine_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nb_Working_Machine_Stream_Cursor_Input>>;
  where?: InputMaybe<Nb_Working_Machine_Bool_Exp>;
};


export type Subscription_RootNbb_Picture_Analysis_StatusArgs = {
  distinct_on?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Order_By>>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};


export type Subscription_RootNbb_Picture_Analysis_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbb_Picture_Analysis_Status_Order_By>>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};


export type Subscription_RootNbb_Picture_Analysis_Status_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbb_Picture_Analysis_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbb_Picture_Analysis_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbb_Picture_Analysis_Status_Bool_Exp>;
};


export type Subscription_RootNbbe_MicroclimateArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Microclimate_Order_By>>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};


export type Subscription_RootNbbe_Microclimate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Microclimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Microclimate_Order_By>>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};


export type Subscription_RootNbbe_Microclimate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbbe_Microclimate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbbe_Microclimate_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbbe_Microclimate_Bool_Exp>;
};


export type Subscription_RootNbbe_Orthoimg_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nbbe_Orthoimg_Type_Bool_Exp>;
};


export type Subscription_RootNbbe_Orthoimg_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nbbe_Orthoimg_Type_Bool_Exp>;
};


export type Subscription_RootNbbe_Orthoimg_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootNbbe_Orthoimg_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbbe_Orthoimg_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbbe_Orthoimg_Type_Bool_Exp>;
};


export type Subscription_RootNbbe_Weather_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Weather_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Weather_Type_Order_By>>;
  where?: InputMaybe<Nbbe_Weather_Type_Bool_Exp>;
};


export type Subscription_RootNbbe_Weather_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbbe_Weather_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbbe_Weather_Type_Order_By>>;
  where?: InputMaybe<Nbbe_Weather_Type_Bool_Exp>;
};


export type Subscription_RootNbbe_Weather_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootNbbe_Weather_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbbe_Weather_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbbe_Weather_Type_Bool_Exp>;
};


export type Subscription_RootNbpm_AuthorizationArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Authorization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Authorization_Order_By>>;
  where?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
};


export type Subscription_RootNbpm_Authorization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Authorization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Authorization_Order_By>>;
  where?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
};


export type Subscription_RootNbpm_Authorization_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbpm_Authorization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbpm_Authorization_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbpm_Authorization_Bool_Exp>;
};


export type Subscription_RootNbpm_Contract_PlanArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
};


export type Subscription_RootNbpm_Contract_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
};


export type Subscription_RootNbpm_Contract_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbpm_Contract_Plan_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Contract_Plan_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Contract_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Contract_Plan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbpm_Contract_Plan_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbpm_Contract_Plan_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Contract_Plan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbpm_Contract_Plan_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbpm_Contract_Plan_Bool_Exp>;
};


export type Subscription_RootNbpm_Imsattr_Authorization_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Imsattr_Authorization_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Imsattr_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Imsattr_Authorization_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbpm_Imsattr_Authorization_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbpm_Imsattr_Authorization_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbpm_Imsattr_Authorization_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Ipengine_Authorization_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Ipengine_Authorization_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Ipengine_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Ipengine_Authorization_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbpm_Ipengine_Authorization_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbpm_Ipengine_Authorization_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbpm_Ipengine_Authorization_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Picanatype_Authorization_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Picanatype_Authorization_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Picanatype_Authorization_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Picanatype_Authorization_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbpm_Picanatype_Authorization_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbpm_Picanatype_Authorization_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbpm_Picanatype_Authorization_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Service_PlanArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Bool_Exp>;
};


export type Subscription_RootNbpm_Service_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Bool_Exp>;
};


export type Subscription_RootNbpm_Service_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbpm_Service_Plan_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Service_Plan_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_Service_Plan_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_Service_Plan_Link_Order_By>>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Service_Plan_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbpm_Service_Plan_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbpm_Service_Plan_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbpm_Service_Plan_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_Service_Plan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbpm_Service_Plan_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbpm_Service_Plan_Bool_Exp>;
};


export type Subscription_RootNbpm_User_Attr_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attr_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_User_Attr_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attr_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attr_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_User_Attr_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbpm_User_Attr_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbpm_User_Attr_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbpm_User_Attr_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_User_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attribute_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attribute_Bool_Exp>;
};


export type Subscription_RootNbpm_User_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Attribute_Order_By>>;
  where?: InputMaybe<Nbpm_User_Attribute_Bool_Exp>;
};


export type Subscription_RootNbpm_User_Attribute_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbpm_User_Attribute_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbpm_User_Attribute_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbpm_User_Attribute_Bool_Exp>;
};


export type Subscription_RootNbpm_User_Cp_Link_LinkArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_User_Cp_Link_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbpm_User_Cp_Link_Link_Order_By>>;
  where?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
};


export type Subscription_RootNbpm_User_Cp_Link_Link_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbpm_User_Cp_Link_Link_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbpm_User_Cp_Link_Link_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbpm_User_Cp_Link_Link_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_FieldArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_Field_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_Field_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbs_Ipe_Run_Field_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbs_Ipe_Run_Field_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbs_Ipe_Run_Field_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_Link_FieldArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_Link_Field_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Field_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_Link_Field_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbs_Ipe_Run_Link_Field_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbs_Ipe_Run_Link_Field_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Field_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_Link_WideArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_Link_Wide_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Link_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_Link_Wide_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbs_Ipe_Run_Link_Wide_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbs_Ipe_Run_Link_Wide_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbs_Ipe_Run_Link_Wide_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_WideArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_Wide_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Ipe_Run_Wide_Order_By>>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};


export type Subscription_RootNbs_Ipe_Run_Wide_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbs_Ipe_Run_Wide_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbs_Ipe_Run_Wide_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbs_Ipe_Run_Wide_Bool_Exp>;
};


export type Subscription_RootNbs_Multipart_UploadArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Multipart_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Multipart_Upload_Order_By>>;
  where?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
};


export type Subscription_RootNbs_Multipart_Upload_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Multipart_Upload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Multipart_Upload_Order_By>>;
  where?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
};


export type Subscription_RootNbs_Multipart_Upload_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbs_Multipart_Upload_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbs_Multipart_Upload_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbs_Multipart_Upload_Bool_Exp>;
};


export type Subscription_RootNbs_Wide_Orthoimg_LoadArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


export type Subscription_RootNbs_Wide_Orthoimg_Load_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbs_Wide_Orthoimg_Load_Order_By>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


export type Subscription_RootNbs_Wide_Orthoimg_Load_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbs_Wide_Orthoimg_Load_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbs_Wide_Orthoimg_Load_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbs_Wide_Orthoimg_Load_Bool_Exp>;
};


export type Subscription_RootNbtrk_ContextArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Context_Order_By>>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};


export type Subscription_RootNbtrk_Context_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Context_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Context_Order_By>>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};


export type Subscription_RootNbtrk_Context_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbtrk_Context_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbtrk_Context_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbtrk_Context_Bool_Exp>;
};


export type Subscription_RootNbtrk_Eph_LocationArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Eph_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Eph_Location_Order_By>>;
  where?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
};


export type Subscription_RootNbtrk_Eph_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nbtrk_Eph_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nbtrk_Eph_Location_Order_By>>;
  where?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
};


export type Subscription_RootNbtrk_Eph_Location_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNbtrk_Eph_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nbtrk_Eph_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Nbtrk_Eph_Location_Bool_Exp>;
};


export type Subscription_RootNipe_Engine_InformationArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Information_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
};


export type Subscription_RootNipe_Engine_Information_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Information_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Information_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
};


export type Subscription_RootNipe_Engine_Information_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNipe_Engine_Information_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nipe_Engine_Information_Stream_Cursor_Input>>;
  where?: InputMaybe<Nipe_Engine_Information_Bool_Exp>;
};


export type Subscription_RootNipe_Engine_Link_Orthoimg_TypeArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
};


export type Subscription_RootNipe_Engine_Link_Orthoimg_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Link_Orthoimg_Type_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
};


export type Subscription_RootNipe_Engine_Link_Orthoimg_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNipe_Engine_Link_Orthoimg_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Nipe_Engine_Link_Orthoimg_Type_Bool_Exp>;
};


export type Subscription_RootNipe_Engine_Run_StatusArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Run_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Run_Status_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Run_Status_Bool_Exp>;
};


export type Subscription_RootNipe_Engine_Run_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Engine_Run_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Engine_Run_Status_Order_By>>;
  where?: InputMaybe<Nipe_Engine_Run_Status_Bool_Exp>;
};


export type Subscription_RootNipe_Engine_Run_Status_By_PkArgs = {
  status: Scalars['String'];
};


export type Subscription_RootNipe_Engine_Run_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nipe_Engine_Run_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Nipe_Engine_Run_Status_Bool_Exp>;
};


export type Subscription_RootNipe_Run_CommandArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Run_Command_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Run_Command_Order_By>>;
  where?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
};


export type Subscription_RootNipe_Run_Command_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nipe_Run_Command_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nipe_Run_Command_Order_By>>;
  where?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
};


export type Subscription_RootNipe_Run_Command_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNipe_Run_Command_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Nipe_Run_Command_Stream_Cursor_Input>>;
  where?: InputMaybe<Nipe_Run_Command_Bool_Exp>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type GetCompanyListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyListQuery = { __typename?: 'query_root', nb_company: Array<{ __typename?: 'nb_company', id: string, name: string }> };

export type GetUserAttributeAndAuthorizationsQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
  app_user_active_id?: Scalars['uuid'];
}>;


export type GetUserAttributeAndAuthorizationsQuery = { __typename?: 'query_root', nb_company: Array<{ __typename?: 'nb_company', nbpm_user_attr_links: Array<{ __typename?: 'nbpm_user_attr_link', nbpm_user_attribute: { __typename?: 'nbpm_user_attribute', attribute: string } }>, nbpm_contract_plan: { __typename?: 'nbpm_contract_plan', nbpm_contract_plan_links: Array<{ __typename?: 'nbpm_contract_plan_link', nbpm_authorization?: { __typename?: 'nbpm_authorization', key: string, name: string, writable: boolean } | null }> } }> };

export type GetUserFieldGroupListQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
}>;


export type GetUserFieldGroupListQuery = { __typename?: 'query_root', nb_ufield_group: Array<{ __typename?: 'nb_ufield_group', id: string, created: string, name: string, nb_ufg_links: Array<{ __typename?: 'nb_ufg_link', nb_ufield: { __typename?: 'nb_ufield', id: string } }> }> };

export type GetUserFieldListQueryVariables = Exact<{
  user_field_group_id?: Scalars['uuid'];
}>;


export type GetUserFieldListQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, field_name: string, polygon_field: any }> };

export type GetUserFieldByUserFieldGroupIdQueryVariables = Exact<{
  user_field_group_id?: InputMaybe<Scalars['uuid']>;
}>;


export type GetUserFieldByUserFieldGroupIdQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, field_name: string, polygon_field: any, nb_field_pictures: Array<{ __typename?: 'nb_field_picture', id: string, thumbnail_path: string, image_path: string, cap_coor: any, datetime: string }> }> };

export type GetUserFieldPicturesQueryVariables = Exact<{
  user_field_id?: Scalars['uuid'];
}>;


export type GetUserFieldPicturesQuery = { __typename?: 'query_root', nb_field_picture: Array<{ __typename?: 'nb_field_picture', id: string, thumbnail_path: string, datetime: string, nb_memo_picture_links: Array<{ __typename?: 'nb_memo_picture_link', nb_field_memo: { __typename?: 'nb_field_memo', id: string } }> }> };

export type GetUserFieldPicturesByFieldPictureIdQueryVariables = Exact<{
  field_picture_id?: InputMaybe<Scalars['uuid']>;
}>;


export type GetUserFieldPicturesByFieldPictureIdQuery = { __typename?: 'query_root', nb_field_picture: Array<{ __typename?: 'nb_field_picture', id: string, datetime: string, image_path: string, nb_field_phtographers: Array<{ __typename?: 'nb_field_photographer', app_user_active: { __typename?: 'app_user_active', name: string } }>, nb_picture_analyses: Array<{ __typename?: 'nb_picture_analysis', result: any, nb_picture_analysis_type: { __typename?: 'nb_picture_analysis_type', name: string } }> }> };

export type GetUserFieldLayerDataQueryVariables = Exact<{
  user_field_group_id?: Scalars['uuid'];
}>;


export type GetUserFieldLayerDataQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, field_name: string, polygon_field: any, nb_field_pictures: Array<{ __typename?: 'nb_field_picture', id: string, thumbnail_path: string, cap_coor: any }> }> };

export type GetCultivationDateListQueryVariables = Exact<{
  user_field_id?: InputMaybe<Scalars['uuid']>;
}>;


export type GetCultivationDateListQuery = { __typename?: 'query_root', nb_cultivation_plan: Array<{ __typename?: 'nb_cultivation_plan', id: string, name: string, seeded_at?: string | null, transplanted_at?: string | null, reaping_at?: string | null, nb_fertilization_works: Array<{ __typename?: 'nb_fertilization_work', id: string, datetime: string }> }> };

export type GetFarmingFieldChartQueryVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  term_start?: InputMaybe<Scalars['timestamptz']>;
  term_end?: InputMaybe<Scalars['timestamptz']>;
}>;


export type GetFarmingFieldChartQuery = { __typename?: 'query_root', nb_mfield_link: Array<{ __typename?: 'nb_mfield_link', master_nb_mfield?: { __typename?: 'master_nb_mfield', nb_area_mesh: { __typename?: 'master_nb_area_mesh', nb_weather_data: Array<{ __typename?: 'master_nb_weather_data', datetime: string, temperature?: number | null, day_temperature?: number | null, night_temperature?: number | null, diff_temperature?: number | null }> } } | null }>, nb_ufield: Array<{ __typename?: 'nb_ufield', nb_field_sensings: Array<{ __typename?: 'nb_field_sensing', nb_field_sensing_data: Array<{ __typename?: 'nb_field_sensing_data', datetime: string, water_level?: number | null, water_temperature?: number | null, ground_temperature?: number | null, day_water_temperature?: number | null, night_water_temperature?: number | null, diff_water_temperature?: number | null }> }>, nbbe_microclimates: Array<{ __typename?: 'nbbe_microclimate', datetime: string, organic_fertilizer_efficiency?: number | null, chemical_fertilizer_efficiency?: number | null, coated_urea_fertilizer_efficiency?: number | null }> }> };

export type GetTemperatureDataQueryVariables = Exact<{
  user_field_group_id?: Scalars['uuid'];
  datetime_from?: Scalars['timestamptz'];
  datetime_to?: Scalars['timestamptz'];
}>;


export type GetTemperatureDataQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, field_name: string, polygon_field: any, nb_mfield_links: Array<{ __typename?: 'nb_mfield_link', master_nb_mfield?: { __typename?: 'master_nb_mfield', nb_area_mesh: { __typename?: 'master_nb_area_mesh', nb_weather_data: Array<{ __typename?: 'master_nb_weather_data', temperature?: number | null, day_temperature?: number | null, night_temperature?: number | null, diff_temperature?: number | null }> } } | null }> }> };

export type InsertFieldWaterGateMutationVariables = Exact<{
  objects?: InputMaybe<Array<Nb_Field_Water_Gate_Insert_Input> | Nb_Field_Water_Gate_Insert_Input>;
}>;


export type InsertFieldWaterGateMutation = { __typename?: 'mutation_root', insert_nb_field_water_gate?: { __typename?: 'nb_field_water_gate_mutation_response', returning: Array<{ __typename?: 'nb_field_water_gate', id: string }> } | null };

export type InsertFieldWaterGateStatusMutationVariables = Exact<{
  objects?: InputMaybe<Array<Nb_Field_Water_Gate_Status_Insert_Input> | Nb_Field_Water_Gate_Status_Insert_Input>;
}>;


export type InsertFieldWaterGateStatusMutation = { __typename?: 'mutation_root', insert_nb_field_water_gate_status?: { __typename?: 'nb_field_water_gate_status_mutation_response', returning: Array<{ __typename?: 'nb_field_water_gate_status', id: string }> } | null };

export type DeleteFieldWaterGateMutationVariables = Exact<{
  field_water_gate_id?: InputMaybe<Scalars['uuid']>;
}>;


export type DeleteFieldWaterGateMutation = { __typename?: 'mutation_root', update_nb_field_water_gate?: { __typename?: 'nb_field_water_gate_mutation_response', returning: Array<{ __typename?: 'nb_field_water_gate', id: string }> } | null };

export type RevWaterGateSubscriptionVariables = Exact<{
  user_field_group_id?: Scalars['uuid'];
}>;


export type RevWaterGateSubscription = { __typename?: 'subscription_root', nb_field_water_gate: Array<{ __typename?: 'nb_field_water_gate', id: string, key?: string | null, kind: string, creator_name: string, wg_coor: any, nb_field_water_gate_statuses: Array<{ __typename?: 'nb_field_water_gate_status', id: string, status: string, datetime: string, creator_name: string, app_user_active?: { __typename?: 'app_user_active', id: string } | null }>, nb_ufield: { __typename?: 'nb_ufield', id: string, field_name: string, area_sqm?: any | null, nb_cultivation_plans: Array<{ __typename?: 'nb_cultivation_plan', transplanted_at?: string | null, nb_cultivar: { __typename?: 'nb_cultivar', name: string } }> } }> };

export type GetWaterGateQueryVariables = Exact<{
  user_field_group_id?: Scalars['uuid'];
}>;


export type GetWaterGateQuery = { __typename?: 'query_root', nb_field_water_gate: Array<{ __typename?: 'nb_field_water_gate', id: string, key?: string | null, kind: string, creator_name: string, wg_coor: any, nb_field_water_gate_statuses: Array<{ __typename?: 'nb_field_water_gate_status', id: string, status: string, datetime: string, creator_name: string }>, nb_ufield: { __typename?: 'nb_ufield', id: string } }> };

export type InsertSoilReportMutationVariables = Exact<{
  objects?: Array<Nb_Soil_Chemistry_Insert_Input> | Nb_Soil_Chemistry_Insert_Input;
}>;


export type InsertSoilReportMutation = { __typename?: 'mutation_root', insert_nb_soil_chemistry?: { __typename?: 'nb_soil_chemistry_mutation_response', returning: Array<{ __typename?: 'nb_soil_chemistry', id: string }> } | null };

export type DeleteSoilReportMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteSoilReportMutation = { __typename?: 'mutation_root', update_nb_soil_chemistry?: { __typename?: 'nb_soil_chemistry_mutation_response', returning: Array<{ __typename?: 'nb_soil_chemistry', id: string }> } | null };

export type UpdateSoilChemistryMutationVariables = Exact<{
  id: Scalars['uuid'];
  object?: InputMaybe<Nb_Soil_Chemistry_Set_Input>;
}>;


export type UpdateSoilChemistryMutation = { __typename?: 'mutation_root', update_nb_soil_chemistry?: { __typename?: 'nb_soil_chemistry_mutation_response', returning: Array<{ __typename?: 'nb_soil_chemistry', id: string }> } | null };

export type UpdateSoilChemistryValueMutationVariables = Exact<{
  id: Scalars['uuid'];
  object?: InputMaybe<Nb_Soil_Chemistry_Value_Set_Input>;
}>;


export type UpdateSoilChemistryValueMutation = { __typename?: 'mutation_root', update_nb_soil_chemistry_value?: { __typename?: 'nb_soil_chemistry_value_mutation_response', returning: Array<{ __typename?: 'nb_soil_chemistry_value', id: string }> } | null };

export type GetLatestCultivationPlanListQueryVariables = Exact<{
  user_field_group_id?: Scalars['uuid'];
}>;


export type GetLatestCultivationPlanListQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, field_name: string, area_sqm?: any | null, plans_seeded_at: Array<{ __typename?: 'nb_cultivation_plan', seeded_at?: string | null, transplanted_at?: string | null, heading_at?: string | null, nb_crop: { __typename?: 'nb_crop', name: string }, nb_cultivar: { __typename?: 'nb_cultivar', name: string } }>, plans_transplanted_at: Array<{ __typename?: 'nb_cultivation_plan', seeded_at?: string | null, transplanted_at?: string | null, heading_at?: string | null, nb_crop: { __typename?: 'nb_crop', name: string }, nb_cultivar: { __typename?: 'nb_cultivar', name: string } }> }> };

export type GetUserFieldsWithMasterFieldQueryVariables = Exact<{
  master_field_key?: Scalars['uuid'];
  user_field_group_id?: Scalars['uuid'];
}>;


export type GetUserFieldsWithMasterFieldQuery = { __typename?: 'query_root', master_nb_mfield: Array<{ __typename?: 'master_nb_mfield', nb_mfield_links: Array<{ __typename?: 'nb_mfield_link', nb_ufield: { __typename?: 'nb_ufield', id: string, field_name: string } }> }> };

export type GetCropListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCropListQuery = { __typename?: 'query_root', nb_crop: Array<{ __typename?: 'nb_crop', id: string, name: string, key: string }> };

export type GetCultivarListQueryVariables = Exact<{
  crop_id?: InputMaybe<Scalars['uuid']>;
}>;


export type GetCultivarListQuery = { __typename?: 'query_root', nb_cultivar: Array<{ __typename?: 'nb_cultivar', id: string, name: string, key: string }> };

export type GetCultivationMethodListQueryVariables = Exact<{
  crop_id?: InputMaybe<Scalars['uuid']>;
}>;


export type GetCultivationMethodListQuery = { __typename?: 'query_root', nb_crop: Array<{ __typename?: 'nb_crop', id: string, nb_crop_method_links: Array<{ __typename?: 'nb_crop_method_link', nb_cultivation_method: { __typename?: 'nb_cultivation_method', id: string, name: string, is_transplant: boolean } }> }> };

export type GetFertilizerAndConditionerListQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
}>;


export type GetFertilizerAndConditionerListQuery = { __typename?: 'query_root', nb_company: Array<{ __typename?: 'nb_company', nb_fertilizer_company_links: Array<{ __typename?: 'nb_fertilizer_company_link', nb_fertilizer: { __typename?: 'nb_fertilizer', id: string, name: string, picture_path?: string | null, thumbnail_path?: string | null, is_fertilizer: boolean, is_conditioner: boolean, content?: any | null, nb_fertilizer_pictures: Array<{ __typename?: 'nb_fertilizer_picture', id: string, picture_path: string }> } }> }> };

export type GetFertilizationMethodListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFertilizationMethodListQuery = { __typename?: 'query_root', nb_fertilization_method: Array<{ __typename?: 'nb_fertilization_method', id: string, name: string }> };

export type GetUserFieldCultivationPlanListQueryVariables = Exact<{
  user_field_id?: InputMaybe<Scalars['uuid']>;
}>;


export type GetUserFieldCultivationPlanListQuery = { __typename?: 'query_root', nb_cultivation_plan: Array<{ __typename?: 'nb_cultivation_plan', id: string, updated: string, name: string, method_id?: string | null, seeded_at?: string | null, transplanted_at?: string | null, reaping_at?: string | null, nb_crop: { __typename?: 'nb_crop', id: string, name: string }, nb_cultivar: { __typename?: 'nb_cultivar', id: string, name: string }, nb_cultivation_method?: { __typename?: 'nb_cultivation_method', id: string, name: string, is_transplant: boolean } | null, nb_actual_crop_yeilds: Array<{ __typename?: 'nb_actual_crop_yeild', id: string, yield_weight?: any | null }>, nb_fertilization_works: Array<{ __typename?: 'nb_fertilization_work', id: string, datetime: string, fertilizer_amount: number, fertilizer_id: string, basal_fertilizer: boolean, fertilization_method_id?: string | null, nb_fertilizer: { __typename?: 'nb_fertilizer', id: string, name: string, picture_path?: string | null, thumbnail_path?: string | null, content?: any | null, nb_fertilizer_pictures: Array<{ __typename?: 'nb_fertilizer_picture', id: string, picture_path: string }> }, nb_fertilization_method?: { __typename?: 'nb_fertilization_method', id: string, name: string } | null }>, nb_pesticide_spray_works: Array<{ __typename?: 'nb_pesticide_spray_work', id: string, datetime: string, comment: string }> }> };

export type GetCultivationPlanQueryVariables = Exact<{
  cultivation_plan_id?: Scalars['uuid'];
}>;


export type GetCultivationPlanQuery = { __typename?: 'query_root', nb_cultivation_plan: Array<{ __typename?: 'nb_cultivation_plan', id: string, updated: string, name: string, method: string, method_id?: string | null, seeded_at?: string | null, transplanted_at?: string | null, reaping_at?: string | null, user_id?: string | null, creator_name: string, nb_crop: { __typename?: 'nb_crop', id: string, name: string }, nb_cultivar: { __typename?: 'nb_cultivar', id: string, name: string }, nb_actual_crop_yeilds: Array<{ __typename?: 'nb_actual_crop_yeild', id: string, yield_weight?: any | null, user_id?: string | null, creator_name: string }>, nb_fertilization_works: Array<{ __typename?: 'nb_fertilization_work', id: string, datetime: string, fertilizer_amount: number, fertilizer_id: string, user_id?: string | null, sequence: number, basal_fertilizer: boolean, fertilization_method_id?: string | null, nb_fertilizer: { __typename?: 'nb_fertilizer', id: string, name: string, picture_path?: string | null, thumbnail_path?: string | null, nb_fertilizer_pictures: Array<{ __typename?: 'nb_fertilizer_picture', id: string, picture_path: string }> }, nb_fertilization_method?: { __typename?: 'nb_fertilization_method', id: string, name: string } | null }>, nb_pesticide_spray_works: Array<{ __typename?: 'nb_pesticide_spray_work', id: string, datetime: string, comment: string }>, nb_ufield: { __typename?: 'nb_ufield', id: string, field_name: string } }> };

export type InsertUserFieldCultivationPlanMutationVariables = Exact<{
  objects?: Array<Nb_Cultivation_Plan_Insert_Input> | Nb_Cultivation_Plan_Insert_Input;
}>;


export type InsertUserFieldCultivationPlanMutation = { __typename?: 'mutation_root', insert_nb_cultivation_plan?: { __typename?: 'nb_cultivation_plan_mutation_response', returning: Array<{ __typename?: 'nb_cultivation_plan', id: string }> } | null };

export type UpdateUserFieldCultivationPlanMutationVariables = Exact<{
  cultivation_plan_id?: Scalars['uuid'];
  object?: InputMaybe<Nb_Cultivation_Plan_Set_Input>;
}>;


export type UpdateUserFieldCultivationPlanMutation = { __typename?: 'mutation_root', update_nb_cultivation_plan_by_pk?: { __typename?: 'nb_cultivation_plan', id: string } | null };

export type DeleteUserFieldCultivationPlanMutationVariables = Exact<{
  cultivation_plan_id?: Scalars['uuid'];
}>;


export type DeleteUserFieldCultivationPlanMutation = { __typename?: 'mutation_root', update_nb_cultivation_plan_by_pk?: { __typename?: 'nb_cultivation_plan', id: string } | null, update_nb_fertilization_work?: { __typename?: 'nb_fertilization_work_mutation_response', returning: Array<{ __typename?: 'nb_fertilization_work', id: string }> } | null };

export type UpdateCropYieldWeightMutationVariables = Exact<{
  cultivation_plan_id?: Scalars['uuid'];
  yield_weight?: InputMaybe<Scalars['float8']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  creator_name?: InputMaybe<Scalars['String']>;
}>;


export type UpdateCropYieldWeightMutation = { __typename?: 'mutation_root', update_nb_actual_crop_yeild?: { __typename?: 'nb_actual_crop_yeild_mutation_response', returning: Array<{ __typename?: 'nb_actual_crop_yeild', id: string }> } | null };

export type InsertFertilizationWorkMutationVariables = Exact<{
  objects?: Array<Nb_Fertilization_Work_Insert_Input> | Nb_Fertilization_Work_Insert_Input;
}>;


export type InsertFertilizationWorkMutation = { __typename?: 'mutation_root', insert_nb_fertilization_work?: { __typename?: 'nb_fertilization_work_mutation_response', returning: Array<{ __typename?: 'nb_fertilization_work', id: string }> } | null };

export type UpdateFertilizationWorkMutationVariables = Exact<{
  fertilization_work_id?: Scalars['uuid'];
  datetime: Scalars['timestamptz'];
  fertilizer_amount: Scalars['Float'];
  fertilizer_id: Scalars['uuid'];
  basal_fertilizer: Scalars['Boolean'];
  fertilization_method_id: Scalars['uuid'];
  user_id?: InputMaybe<Scalars['uuid']>;
}>;


export type UpdateFertilizationWorkMutation = { __typename?: 'mutation_root', update_nb_fertilization_work?: { __typename?: 'nb_fertilization_work_mutation_response', returning: Array<{ __typename?: 'nb_fertilization_work', id: string }> } | null };

export type DeleteFertilizationWorkMutationVariables = Exact<{
  fertilization_work_id?: Scalars['uuid'];
}>;


export type DeleteFertilizationWorkMutation = { __typename?: 'mutation_root', update_nb_fertilization_work_by_pk?: { __typename?: 'nb_fertilization_work', id: string } | null };

export type GetConditionerWorksQueryVariables = Exact<{
  user_field_id?: Scalars['uuid'];
}>;


export type GetConditionerWorksQuery = { __typename?: 'query_root', nb_conditioner_work: Array<{ __typename?: 'nb_conditioner_work', id: string, conditioner_id: string, datetime: string, conditioner_amount: number, nb_fertilizer: { __typename?: 'nb_fertilizer', id: string, name: string } }> };

export type InsertConditionerWorkMutationVariables = Exact<{
  objects?: Array<Nb_Conditioner_Work_Insert_Input> | Nb_Conditioner_Work_Insert_Input;
}>;


export type InsertConditionerWorkMutation = { __typename?: 'mutation_root', insert_nb_conditioner_work?: { __typename?: 'nb_conditioner_work_mutation_response', returning: Array<{ __typename?: 'nb_conditioner_work', id: string }> } | null };

export type UpdateConditionerWorkMutationVariables = Exact<{
  conditioner_work_id?: Scalars['uuid'];
  conditioner_id?: Scalars['uuid'];
  datetime: Scalars['timestamptz'];
  conditioner_amount: Scalars['Float'];
  user_id?: InputMaybe<Scalars['uuid']>;
}>;


export type UpdateConditionerWorkMutation = { __typename?: 'mutation_root', update_nb_conditioner_work?: { __typename?: 'nb_conditioner_work_mutation_response', returning: Array<{ __typename?: 'nb_conditioner_work', id: string }> } | null };

export type DeleteConditionerWorkMutationVariables = Exact<{
  conditioner_work_id?: Scalars['uuid'];
}>;


export type DeleteConditionerWorkMutation = { __typename?: 'mutation_root', update_nb_conditioner_work_by_pk?: { __typename?: 'nb_conditioner_work', id: string } | null };

export type InsertPesticideSprayWorkMutationVariables = Exact<{
  objects?: Array<Nb_Pesticide_Spray_Work_Insert_Input> | Nb_Pesticide_Spray_Work_Insert_Input;
}>;


export type InsertPesticideSprayWorkMutation = { __typename?: 'mutation_root', insert_nb_pesticide_spray_work?: { __typename?: 'nb_pesticide_spray_work_mutation_response', returning: Array<{ __typename?: 'nb_pesticide_spray_work', id: string }> } | null };

export type UpdatePesticideSprayWorkMutationVariables = Exact<{
  pesticide_spray_work_id?: Scalars['uuid'];
  object?: Nb_Pesticide_Spray_Work_Set_Input;
}>;


export type UpdatePesticideSprayWorkMutation = { __typename?: 'mutation_root', update_nb_pesticide_spray_work?: { __typename?: 'nb_pesticide_spray_work_mutation_response', returning: Array<{ __typename?: 'nb_pesticide_spray_work', id: string }> } | null };

export type DeletePesticideSprayWorkMutationVariables = Exact<{
  pesticide_spray_work_id?: Scalars['uuid'];
}>;


export type DeletePesticideSprayWorkMutation = { __typename?: 'mutation_root', update_nb_pesticide_spray_work?: { __typename?: 'nb_pesticide_spray_work_mutation_response', returning: Array<{ __typename?: 'nb_pesticide_spray_work', id: string }> } | null };

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfileQuery = { __typename?: 'query_root', app_user: Array<{ __typename?: 'app_user', id: string, app_user_active?: { __typename?: 'app_user_active', id: string, name: string, nickname: string, app_user_property_private?: { __typename?: 'app_user_property_private', id: string, phone_number?: string | null } | null } | null }> };

export type InsertActiveUserInfoMutationVariables = Exact<{
  objects?: Array<App_User_Active_Insert_Input> | App_User_Active_Insert_Input;
}>;


export type InsertActiveUserInfoMutation = { __typename?: 'mutation_root', insert_app_user_active?: { __typename?: 'app_user_active_mutation_response', returning: Array<{ __typename?: 'app_user_active', id: string }> } | null };

export type UpdateActiveUserInfoMutationVariables = Exact<{
  id?: Scalars['uuid'];
  object?: InputMaybe<App_User_Active_Set_Input>;
}>;


export type UpdateActiveUserInfoMutation = { __typename?: 'mutation_root', update_app_user_active_by_pk?: { __typename?: 'app_user_active', id: string } | null };

export type UpdateActiveUserPropertyPrivateMutationVariables = Exact<{
  id?: Scalars['uuid'];
  object?: InputMaybe<App_User_Property_Private_Set_Input>;
}>;


export type UpdateActiveUserPropertyPrivateMutation = { __typename?: 'mutation_root', update_app_user_property_private_by_pk?: { __typename?: 'app_user_property_private', id: string } | null };

export type GetSameNickNameQueryVariables = Exact<{
  nickName?: InputMaybe<Scalars['String']>;
}>;


export type GetSameNickNameQuery = { __typename?: 'query_root', app_user_active: Array<{ __typename?: 'app_user_active', id: string, nickname: string }> };

export type GetSensingTypeListQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
}>;


export type GetSensingTypeListQuery = { __typename?: 'query_root', nb_image_sensing_attr: Array<{ __typename?: 'nb_image_sensing_attr', key: string, name: string, unit: number, lower_bound?: number | null, upper_bound?: number | null, nb_imsensing_colormap: { __typename?: 'nb_imsensing_colormap', color_map: any } }> };

export type GetSensingLayerDataByUserFieldGroupQueryVariables = Exact<{
  user_field_group_id?: Scalars['uuid'];
  geometry: Scalars['geometry'];
  sensing_type_key?: Scalars['String'];
  sampling_datetime_from?: Scalars['timestamptz'];
  sampling_datetime_to?: Scalars['timestamptz'];
}>;


export type GetSensingLayerDataByUserFieldGroupQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, field_name: string, polygon_field: any, nb_raster_image_sensings: Array<{ __typename?: 'nb_raster_image_sensing', nb_vector_image_sensing?: { __typename?: 'nb_vector_image_sensing', mean: string, coarse_grain: string, middle_grain: string, fine_grain: string } | null }> }> };

export type GetSensingRegisteredDateListQueryVariables = Exact<{
  user_field_group_id?: Scalars['uuid'];
  sensing_type_key?: Scalars['String'];
}>;


export type GetSensingRegisteredDateListQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, nb_raster_image_sensings: Array<{ __typename?: 'nb_raster_image_sensing', datetime: string }> }> };

export type FieldMemoFragment = { __typename?: 'nb_field_memo', id: string, memo: string, created: string, updated: string, user_id?: string | null, app_user_active?: { __typename?: 'app_user_active', name: string } | null };

export type GetFieldPictureMemosQueryVariables = Exact<{
  field_picture_id?: Scalars['uuid'];
}>;


export type GetFieldPictureMemosQuery = { __typename?: 'query_root', nb_field_memo: Array<{ __typename?: 'nb_field_memo', id: string, memo: string, created: string, updated: string, user_id?: string | null, app_user_active?: { __typename?: 'app_user_active', name: string } | null }> };

export type GetFieldFileMemosQueryVariables = Exact<{
  field_file_id?: Scalars['uuid'];
}>;


export type GetFieldFileMemosQuery = { __typename?: 'query_root', nb_field_memo: Array<{ __typename?: 'nb_field_memo', id: string, memo: string, created: string, updated: string, user_id?: string | null, app_user_active?: { __typename?: 'app_user_active', name: string } | null }> };

export type GetUserFieldGroupMemosQueryVariables = Exact<{
  user_field_group_id?: Scalars['uuid'];
}>;


export type GetUserFieldGroupMemosQuery = { __typename?: 'query_root', nb_field_memo: Array<{ __typename?: 'nb_field_memo', id: string, memo: string, created: string, updated: string, user_id?: string | null, app_user_active?: { __typename?: 'app_user_active', name: string } | null }> };

export type InsertFieldMemoMutationVariables = Exact<{
  objects?: InputMaybe<Array<Nb_Field_Memo_Insert_Input> | Nb_Field_Memo_Insert_Input>;
}>;


export type InsertFieldMemoMutation = { __typename?: 'mutation_root', insert_nb_field_memo?: { __typename?: 'nb_field_memo_mutation_response', returning: Array<{ __typename?: 'nb_field_memo', id: string }> } | null };

export type UpdateFieldMemoMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  memo: Scalars['String'];
}>;


export type UpdateFieldMemoMutation = { __typename?: 'mutation_root', update_nb_field_memo?: { __typename?: 'nb_field_memo_mutation_response', returning: Array<{ __typename?: 'nb_field_memo', id: string }> } | null };

export type DeleteFieldMemoMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type DeleteFieldMemoMutation = { __typename?: 'mutation_root', update_nb_field_memo?: { __typename?: 'nb_field_memo_mutation_response', returning: Array<{ __typename?: 'nb_field_memo', id: string }> } | null };

export type GetUserFieldPolygonFieldQueryVariables = Exact<{
  user_field_id: Scalars['uuid'];
}>;


export type GetUserFieldPolygonFieldQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, polygon_field: any }> };

export type GetUserAttributeListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserAttributeListQuery = { __typename?: 'query_root', nbpm_user_attribute: Array<{ __typename?: 'nbpm_user_attribute', id: string, attribute: string, attribute_name: string }> };

export type GetMemberListQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
}>;


export type GetMemberListQuery = { __typename?: 'query_root', nb_company: Array<{ __typename?: 'nb_company', nbpm_user_attr_links: Array<{ __typename?: 'nbpm_user_attr_link', app_user_active: { __typename?: 'app_user_active', id: string, name: string, nickname: string }, nbpm_user_attribute: { __typename?: 'nbpm_user_attribute', id: string, attribute: string, attribute_name: string }, nb_ufg_member_links: Array<{ __typename?: 'nb_ufg_member_link', nb_ufield_group: { __typename?: 'nb_ufield_group', id: string, name: string } }> }>, application_user_invitation_to_companies: Array<{ __typename?: 'application_user_invitation_to_company', id: string, status: string, app_user_active: { __typename?: 'app_user_active', id: string, name: string, nickname: string }, nb_ufield_group?: { __typename?: 'nb_ufield_group', id: string, name: string } | null, nbpm_user_attribute: { __typename?: 'nbpm_user_attribute', id: string, attribute: string, attribute_name: string } }> }> };

export type GetUserInvitationsQueryVariables = Exact<{
  app_user_active_id?: Scalars['uuid'];
}>;


export type GetUserInvitationsQuery = { __typename?: 'query_root', application_user_invitation_to_company: Array<{ __typename?: 'application_user_invitation_to_company', id: string, company_name: string }> };

export type GetUserFieldNameQueryVariables = Exact<{
  user_field_id?: Scalars['uuid'];
}>;


export type GetUserFieldNameQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, field_name: string }> };

export type GetSoilCharcteristicQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSoilCharcteristicQuery = { __typename?: 'query_root', nb_soil_characteristic: Array<{ __typename?: 'nb_soil_characteristic', id: string, small_code?: string | null, small_name?: string | null, color_code: string }> };

export type GetUserFieldPolygonFieldByUserFieldIdQueryVariables = Exact<{
  user_field_id: Scalars['uuid'];
}>;


export type GetUserFieldPolygonFieldByUserFieldIdQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', polygon_field: any }> };

export type GetSoilChemistryStandardListQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
  user_field_id?: Scalars['uuid'];
}>;


export type GetSoilChemistryStandardListQuery = { __typename?: 'query_root', nb_soil_chemistry_standards: Array<{ __typename?: 'nb_soil_chemistry_standards', id: string, name: string, nb_soil_chemistry_proper_ranges: Array<{ __typename?: 'nb_soil_chemistry_proper_range', id: string, name: string, upper: number, lower: number, unit?: string | null }>, nb_soil_chemistries: Array<{ __typename?: 'nb_soil_chemistry', id: string }> }> };

export type GetUserFieldsHasSoilReportQueryVariables = Exact<{
  user_field_group_id?: Scalars['uuid'];
}>;


export type GetUserFieldsHasSoilReportQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, polygon_field: any, nb_soil_chemistries: Array<{ __typename?: 'nb_soil_chemistry', sampling_date: string }> }> };

export type GetSoilReportListQueryVariables = Exact<{
  user_field_id?: Scalars['uuid'];
  soil_chemistry_standard_id?: Scalars['uuid'];
}>;


export type GetSoilReportListQuery = { __typename?: 'query_root', nb_soil_chemistry: Array<{ __typename?: 'nb_soil_chemistry', id: string, sampling_date: string, comment?: string | null, nb_soil_chemistry_values: Array<{ __typename?: 'nb_soil_chemistry_value', id: string, value?: number | null, nb_soil_chemistry_proper_range: { __typename?: 'nb_soil_chemistry_proper_range', name: string, upper: number, lower: number, unit?: string | null } }> }> };

export type GetUserFieldAttributeListQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
}>;


export type GetUserFieldAttributeListQuery = { __typename?: 'query_root', nb_ufield_attribute: Array<{ __typename?: 'nb_ufield_attribute', id: string, name: string, created: string, nb_ufattr_links: Array<{ __typename?: 'nb_ufattr_link', nb_ufield: { __typename?: 'nb_ufield', id: string, nb_ufg_links: Array<{ __typename?: 'nb_ufg_link', nb_ufield_group: { __typename?: 'nb_ufield_group', id: string } }> } }> }> };

export type InsertUserFieldAttributeMutationVariables = Exact<{
  objects?: Array<Nb_Ufield_Attribute_Insert_Input> | Nb_Ufield_Attribute_Insert_Input;
}>;


export type InsertUserFieldAttributeMutation = { __typename?: 'mutation_root', insert_nb_ufield_attribute?: { __typename?: 'nb_ufield_attribute_mutation_response', returning: Array<{ __typename?: 'nb_ufield_attribute', id: string }> } | null };

export type UpdateUserFieldAttributeMutationVariables = Exact<{
  user_field_attribute_id?: Scalars['uuid'];
  object?: Nb_Ufield_Attribute_Set_Input;
}>;


export type UpdateUserFieldAttributeMutation = { __typename?: 'mutation_root', update_nb_ufield_attribute?: { __typename?: 'nb_ufield_attribute_mutation_response', returning: Array<{ __typename?: 'nb_ufield_attribute', id: string }> } | null };

export type GetOrthoimgTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrthoimgTypesQuery = { __typename?: 'query_root', nbbe_orthoimg_type: Array<{ __typename?: 'nbbe_orthoimg_type', value: string, name: string }> };

export type GetUploadedWideOrthoimgListQueryVariables = Exact<{
  upload_user_id?: Scalars['uuid'];
  company_id?: Scalars['uuid'];
}>;


export type GetUploadedWideOrthoimgListQuery = { __typename?: 'query_root', nbs_wide_orthoimg_load: Array<{ __typename?: 'nbs_wide_orthoimg_load', sens_datetime: string, proc_datetime: string, s3uri?: string | null, status: string, nb_wide_orthoimg?: { __typename?: 'nb_wide_orthoimg', nb_field_orthoimgs: Array<{ __typename?: 'nb_field_orthoimg', nb_ufield: { __typename?: 'nb_ufield', id: string } }> } | null }> };

export type GetWideOrthoimgUserFieldsQueryVariables = Exact<{
  user_field_ids?: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type GetWideOrthoimgUserFieldsQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, field_name: string, polygon_field: any, nb_ufg_links: Array<{ __typename?: 'nb_ufg_link', nb_ufield_group: { __typename?: 'nb_ufield_group', id: string, name: string } }> }> };

export type GetAnalysisContentsQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
  upload_user_id?: Scalars['uuid'];
}>;


export type GetAnalysisContentsQuery = { __typename?: 'query_root', nipe_engine_information: Array<{ __typename?: 'nipe_engine_information', id: string, name: string, nipe_engine_link_orthoimg_types: Array<{ __typename?: 'nipe_engine_link_orthoimg_type', id: string, orthoimg_type: string, nbbe_orthoimg_type: { __typename?: 'nbbe_orthoimg_type', name: string } }> }>, nb_wide_orthoimg: Array<{ __typename?: 'nb_wide_orthoimg', id: string, orthoimg_type: string, s3uri: string, nbs_wide_orthoimg_loads: Array<{ __typename?: 'nbs_wide_orthoimg_load', proc_datetime: string, sens_datetime: string }> }> };

export type GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQueryVariables = Exact<{
  requested_user_id?: Scalars['uuid'];
  company_id?: Scalars['uuid'];
}>;


export type GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery = { __typename?: 'query_root', nbs_ipe_run_wide: Array<{ __typename?: 'nbs_ipe_run_wide', created: string, nipe_engine_information: { __typename?: 'nipe_engine_information', name: string }, requested: Array<{ __typename?: 'nbs_ipe_run_field', nipe_run_command: { __typename?: 'nipe_run_command', status: string } }>, running: Array<{ __typename?: 'nbs_ipe_run_field', nipe_run_command: { __typename?: 'nipe_run_command', status: string } }>, success: Array<{ __typename?: 'nbs_ipe_run_field', nipe_run_command: { __typename?: 'nipe_run_command', status: string } }>, error: Array<{ __typename?: 'nbs_ipe_run_field', nipe_run_command: { __typename?: 'nipe_run_command', status: string } }> }> };

export type GetNbFieldFileListByUserFieldIdQueryVariables = Exact<{
  user_field_id?: Scalars['uuid'];
}>;


export type GetNbFieldFileListByUserFieldIdQuery = { __typename?: 'query_root', nb_field_file: Array<{ __typename?: 'nb_field_file', id: string, name: string, created: string }> };

export type GetNbFieldFileListByUserFieldIdAndUploadUserIdQueryVariables = Exact<{
  user_field_id?: Scalars['uuid'];
  upload_user_id?: Scalars['uuid'];
}>;


export type GetNbFieldFileListByUserFieldIdAndUploadUserIdQuery = { __typename?: 'query_root', nb_field_file: Array<{ __typename?: 'nb_field_file', id: string, name: string, created: string }> };

export type GetFieldFileQueryVariables = Exact<{
  field_file_id?: Scalars['uuid'];
}>;


export type GetFieldFileQuery = { __typename?: 'query_root', nb_field_file: Array<{ __typename?: 'nb_field_file', id: string, name: string, file_path: string }> };

export type UpdateNbFieldFileByIdMutationVariables = Exact<{
  id?: Scalars['uuid'];
  input?: Nb_Field_File_Set_Input;
}>;


export type UpdateNbFieldFileByIdMutation = { __typename?: 'mutation_root', update_nb_field_file?: { __typename?: 'nb_field_file_mutation_response', returning: Array<{ __typename?: 'nb_field_file', id: string }> } | null };

export type DeleteNbFieldFileByIdMutationVariables = Exact<{
  id?: Scalars['uuid'];
}>;


export type DeleteNbFieldFileByIdMutation = { __typename?: 'mutation_root', update_nb_field_file?: { __typename?: 'nb_field_file_mutation_response', returning: Array<{ __typename?: 'nb_field_file', id: string }> } | null };

export type GetTaskTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTaskTypesQuery = { __typename?: 'query_root', nb_task_type: Array<{ __typename?: 'nb_task_type', type: string }> };

export type GetUsersInCompanyQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
}>;


export type GetUsersInCompanyQuery = { __typename?: 'query_root', nb_company: Array<{ __typename?: 'nb_company', nbpm_user_attr_links: Array<{ __typename?: 'nbpm_user_attr_link', app_user_active: { __typename?: 'app_user_active', id: string, name: string, nickname: string } }> }> };

export type GetUserLocationContextsQueryVariables = Exact<{
  date_from?: Scalars['timestamptz'];
  date_to?: Scalars['timestamptz'];
  task_types?: Array<Scalars['String']> | Scalars['String'];
  user_ids?: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type GetUserLocationContextsQuery = { __typename?: 'query_root', nbtrk_context: Array<{ __typename?: 'nbtrk_context', id: string }> };

export type GetUserLocationHistoryQueryVariables = Exact<{
  context_id?: Scalars['uuid'];
}>;


export type GetUserLocationHistoryQuery = { __typename?: 'query_root', nbtrk_context: Array<{ __typename?: 'nbtrk_context', created: string, task_type: string, nbtrk_eph_locations: Array<{ __typename?: 'nbtrk_eph_location', loc_time: string, point_lat: any, point_lng: any }>, app_user_active: { __typename?: 'app_user_active', name: string }, nb_performed_works: Array<{ __typename?: 'nb_performed_work', id: string, start_datetime: string, working_time?: number | null, nb_ufield?: { __typename?: 'nb_ufield', id: string, field_name: string, polygon_field: any } | null }> }> };

export type GetUserFieldListForUserLocationHistoryQueryVariables = Exact<{
  geometry_object: Scalars['geometry'];
}>;


export type GetUserFieldListForUserLocationHistoryQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, field_name: string, polygon_field: any }> };

export type GetMasterFieldsByGeometryQueryVariables = Exact<{
  geometry: Scalars['geometry'];
}>;


export type GetMasterFieldsByGeometryQuery = { __typename?: 'query_root', master_nb_mfield: Array<{ __typename?: 'master_nb_mfield', nb_mfield_polygon?: { __typename?: 'master_nb_mfield_polygon_2024_1', key: string, geom: any } | null }> };

export type FastapiS3SignedUrlFieldPictureMutationVariables = Exact<{
  company_id?: Scalars['UUID'];
  filename?: Scalars['String'];
}>;


export type FastapiS3SignedUrlFieldPictureMutation = { __typename?: 'mutation_root', fastapiS3SignedUrlFieldPicture: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InvalidParameterError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'S3SignedUrlSuccess', signedUrl: string } | { __typename: 'UserIdError', message: string } };

export type FastapiUpdateAUfieldFieldNameInCompanyMutationVariables = Exact<{
  user_field_id: Scalars['UUID'];
  user_field_name: Scalars['String'];
  company_id: Scalars['UUID'];
}>;


export type FastapiUpdateAUfieldFieldNameInCompanyMutation = { __typename?: 'mutation_root', fastapiUpdateAUfieldFieldNameInCompany: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'UFieldActiveDoesNotExistsError', message: string } | { __typename: 'UpdateFieldNameSuccess', fieldName: string, fieldId: any } | { __typename: 'UserIdError', message: string } };

export type FastapiRemoveAUfieldInCompanyMutationVariables = Exact<{
  user_field_id: Scalars['UUID'];
  company_id: Scalars['UUID'];
}>;


export type FastapiRemoveAUfieldInCompanyMutation = { __typename?: 'mutation_root', fastapiRemoveAUfieldInCompany: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'RemoveFieldSuccess', fieldId: any } | { __typename: 'UFieldActiveDoesNotExistsError', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiUpdateAUfgLinkMutationVariables = Exact<{
  pre_user_field_group_id: Scalars['UUID'];
  post_user_field_group_id: Scalars['UUID'];
  company_id: Scalars['UUID'];
  user_field_id: Scalars['UUID'];
}>;


export type FastapiUpdateAUfgLinkMutation = { __typename?: 'mutation_root', fastapiUpdateAUfgLink: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'UFieldActiveDoesNotExistsError', message: string } | { __typename: 'UFieldGroupActiveDoesNotExistsError', message: string } | { __typename: 'UFieldGroupLinkAlreadyExistsError', message: string } | { __typename: 'UFieldGroupLinkDoesNotExistsError', message: string } | { __typename: 'UpdateAUfgLinkSuccess', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiInsertAGroupInCompanyMutationVariables = Exact<{
  user_field_group_name: Scalars['String'];
  company_id: Scalars['UUID'];
}>;


export type FastapiInsertAGroupInCompanyMutation = { __typename?: 'mutation_root', fastapiInsertAGroupInCompany: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InsertGroupNameSuccess', message: string, groupId: any } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiUpdateAUfieldGroupNameMutationVariables = Exact<{
  user_field_group_id: Scalars['UUID'];
  company_id: Scalars['UUID'];
  user_field_group_name: Scalars['String'];
}>;


export type FastapiUpdateAUfieldGroupNameMutation = { __typename?: 'mutation_root', fastapiUpdateAUfieldGroupName: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'UFieldActiveDoesNotExistsError', message: string } | { __typename: 'UFieldGroupActiveDoesNotExistsError', message: string } | { __typename: 'UFieldGroupLinkAlreadyExistsError', message: string } | { __typename: 'UFieldGroupLinkDoesNotExistsError', message: string } | { __typename: 'UpdateAUfieldGroupNameSuccess', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiRemoveAUfieldGroupMutationVariables = Exact<{
  user_field_group_id: Scalars['UUID'];
  company_id: Scalars['UUID'];
}>;


export type FastapiRemoveAUfieldGroupMutation = { __typename?: 'mutation_root', fastapiRemoveAUfieldGroup: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'RemoveAUFieldGroupNameSuccess', message: string } | { __typename: 'UFieldGroupActiveDoesNotExistsError', message: string } | { __typename: 'UFieldGroupHasLinksError', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiInviteUserToTheCompanyMutationVariables = Exact<{
  company_id?: Scalars['UUID'];
  email?: Scalars['String'];
  user_attribute?: Scalars['String'];
  group_id?: InputMaybe<Scalars['UUID']>;
}>;


export type FastapiInviteUserToTheCompanyMutation = { __typename?: 'mutation_root', fastapiInviteUserToTheCompany: { __typename: 'ApplicationAlreadyExistsError', message: string } | { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InsertApplicationsSuccess', message: string } | { __typename: 'InvalidParameterError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'NumUsersExcessError', message: string } | { __typename: 'UserAccountDoesNotExistsError', message: string } | { __typename: 'UserAlreadyRegisteredError', message: string } };

export type FastapiSwitchUserAttributeWithTheCompanyMutationVariables = Exact<{
  company_id?: Scalars['UUID'];
  app_user_active_id?: Scalars['UUID'];
}>;


export type FastapiSwitchUserAttributeWithTheCompanyMutation = { __typename?: 'mutation_root', fastapiSwitchUserAttributeWithTheCompany: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InvalidParameterError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'Success', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiRemoveAMemberFromTheCompanyMutationVariables = Exact<{
  company_id?: Scalars['UUID'];
  app_user_active_id?: Scalars['UUID'];
}>;


export type FastapiRemoveAMemberFromTheCompanyMutation = { __typename?: 'mutation_root', fastapiRemoveAMemberFromTheCompany: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'Success', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutationVariables = Exact<{
  user_invitation_id?: Scalars['UUID'];
  is_approved?: Scalars['Boolean'];
}>;


export type FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation = { __typename?: 'mutation_root', fastapiReplyApplicationToRegisterAMemberWithTheCompany: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InvalidApplicationError', message: string } | { __typename: 'Success', message: string } };

export type FastapiFieldPictureLoadStatusFailureQueryVariables = Exact<{
  company_id: Scalars['UUID'];
}>;


export type FastapiFieldPictureLoadStatusFailureQuery = { __typename?: 'query_root', fastapiFieldPictureLoadStatusFailure: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'FieldPictureLoadStatusFailureSuccess', record: Array<{ __typename?: 'FieldPictureLoad', ufieldPictureId: any, capCoor?: any | null, datetime?: string | null, imagePath?: string | null, thumbnailPath?: string | null, ufieldId?: any | null, ufieldName?: any | null }> } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiUpdateAPictureToAUfieldMutationVariables = Exact<{
  company_id: Scalars['UUID'];
  user_field_id: Scalars['UUID'];
  field_picture_id: Scalars['UUID'];
}>;


export type FastapiUpdateAPictureToAUfieldMutation = { __typename?: 'mutation_root', fastapiUpdateAPictureToAUfield: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'FieldPictureIdError', message: string } | { __typename: 'FieldPictureLoadStatusIsAlreadyConfirmedError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'UFieldActiveDoesNotExistsError', message: string } | { __typename: 'UpdateAPictureToAUfieldSuccess', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiFieldPictureLoadStatusLoadedQueryVariables = Exact<{
  company_id: Scalars['UUID'];
}>;


export type FastapiFieldPictureLoadStatusLoadedQuery = { __typename?: 'query_root', fastapiFieldPictureLoadStatusLoaded: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'FieldPictureLoadStatusLoadedSuccess', record: Array<{ __typename?: 'FieldPictureLoad', ufieldPictureId: any, capCoor?: any | null, datetime?: string | null, imagePath?: string | null, thumbnailPath?: string | null, ufieldId?: any | null, ufieldName?: any | null }> } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiRemoveFieldPictureMutationVariables = Exact<{
  field_picture_id: Scalars['UUID'];
}>;


export type FastapiRemoveFieldPictureMutation = { __typename?: 'mutation_root', fastapiRemoveFieldPicture: { __typename: 'FieldPictureIdError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'RemoveFieldPictureSuccess', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiGetPictureAnalysisQueryVariables = Exact<{
  fetch_type: Scalars['String'];
  id: Scalars['UUID'];
  picture_analysis_type_Id: Scalars['UUID'];
}>;


export type FastapiGetPictureAnalysisQuery = { __typename?: 'query_root', fastapiGetPictureAnalysis: { __typename: 'AnalysisTypeDoesNotExistsError' } | { __typename: 'GetPictureAnalysisSuccess', message: string, analysis: Array<{ __typename?: 'PictureAnalysis', fieldPictureId: any, imagePath: string, thumbnailPath: string, datetime: string, ufieldId: any, ufieldName: string, analysisTypeName: string, stat: string, result: any }> } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'UFieldActiveDoesNotExistsError', message: string } | { __typename: 'UFieldGroupActiveDoesNotExistsError', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiRequestAPictureAnalysisMutationVariables = Exact<{
  picture_id?: Scalars['UUID'];
  picture_analysis_type_id?: Scalars['UUID'];
}>;


export type FastapiRequestAPictureAnalysisMutation = { __typename?: 'mutation_root', fastapiRequestAPictureAnalysis: { __typename: 'InternalServerError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'RequestAPictureAnalysisSuccess', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiUpdateAUfieldFieldPolygonInCompanyMutationVariables = Exact<{
  company_id: Scalars['UUID'];
  user_field_polygon_string: Scalars['String'];
  user_field_id: Scalars['UUID'];
}>;


export type FastapiUpdateAUfieldFieldPolygonInCompanyMutation = { __typename?: 'mutation_root', fastapiUpdateAUfieldFieldPolygonInCompany: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InvalidParameterError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'UFieldActiveDoesNotExistsError', message: string } | { __typename: 'UpdateFieldPolygonSuccess', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiSetAttributesForTheUfieldMutationVariables = Exact<{
  user_field_id?: Scalars['UUID'];
  user_field_attribute_ids?: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type FastapiSetAttributesForTheUfieldMutation = { __typename?: 'mutation_root', fastapiSetAttributesForTheUfield: { __typename: 'SetAttributesForTheUfieldSuccess', message: string } | { __typename: 'UFieldActiveDoesNotExistsError', message: string } | { __typename: 'UFieldAttributeDoesNotExistError', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiS3SignedUrlWideOrthoimgMutationVariables = Exact<{
  company_id?: Scalars['UUID'];
  filename?: Scalars['String'];
  orthoimg_type?: Scalars['String'];
  number_of_parts?: Scalars['Int'];
  part_md5s?: Array<Scalars['String']> | Scalars['String'];
  part_sizes?: Array<Scalars['Int']> | Scalars['Int'];
  sens_datetime?: Scalars['String'];
}>;


export type FastapiS3SignedUrlWideOrthoimgMutation = { __typename?: 'mutation_root', fastapiS3SignedUrlWideOrthoimg: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InvalidParameterError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'S3SignedUrlWideOrthoimgSuccess', uploadId: string, s3SignedUrls: Array<{ __typename?: 'S3SignedUrl', partNumber: number, signedUrl: string }> } | { __typename: 'UserIdError', message: string } };

export type FastapiRemoveUfieldAttributeMutationVariables = Exact<{
  user_field_attribute_id?: Scalars['UUID'];
}>;


export type FastapiRemoveUfieldAttributeMutation = { __typename?: 'mutation_root', fastapiRemoveUfieldAttribute: { __typename: 'RemoveUFieldAttributesSuccess', message: string } | { __typename: 'UFieldAttributeDoesNotExistError', message: string } | { __typename: 'UFieldAttributeLinkUFieldError', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiCompleteMultipartUploadMutationVariables = Exact<{
  company_id?: Scalars['UUID'];
  upload_id?: Scalars['String'];
}>;


export type FastapiCompleteMultipartUploadMutation = { __typename?: 'mutation_root', fastapiCompleteMultipartUpload: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'CompleteMultipartUploadSuccess', message: string } | { __typename: 'InsufficientPartfileError', message: string } | { __typename: 'InvalidParameterError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'UnexpectedError', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiWideOrthoimgLoadSuspendedQueryVariables = Exact<{
  company_id?: Scalars['UUID'];
}>;


export type FastapiWideOrthoimgLoadSuspendedQuery = { __typename?: 'query_root', fastapiWideOrthoimgLoadSuspended: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'UserIdError', message: string } | { __typename: 'WideOrthoimgLoadSuspendedSuccess', wideOrthoimgLoad: Array<{ __typename?: 'WideOrthoimgLoad', filename: string, orthoimgType: string, procDatetime: string, reason: string, sensDatetime: string, uploadId: string, suspendedParts: Array<{ __typename?: 'SuspendedPart', number: number, md5: string }> }> } };

export type FastapiResumeMultipartUploadQueryVariables = Exact<{
  company_id?: Scalars['UUID'];
  upload_id?: Scalars['String'];
}>;


export type FastapiResumeMultipartUploadQuery = { __typename?: 'query_root', fastapiResumeMultipartUpload: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InvalidParameterError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'ResumeMultipartUploadSuccess', s3SignedUrls: Array<{ __typename?: 'S3SignedUrl', partNumber: number, signedUrl: string }> } | { __typename: 'UserIdError', message: string } };

export type FastapiTerminateWideOrthoimgLoadMutationVariables = Exact<{
  company_id?: Scalars['UUID'];
  upload_id?: Scalars['String'];
}>;


export type FastapiTerminateWideOrthoimgLoadMutation = { __typename?: 'mutation_root', fastapiTerminateWideOrthoimgLoad: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InvalidParameterError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'Success', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiLaunchIpEngineForWideMutationVariables = Exact<{
  company_id?: Scalars['UUID'];
  engine_information_id?: Scalars['UUID'];
  wide_orthoimg_ids?: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type FastapiLaunchIpEngineForWideMutation = { __typename?: 'mutation_root', fastapiLaunchIpEngineForWide: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InternalError', message: string } | { __typename: 'NoNipeEngineInformationError', message: string } | { __typename: 'NoWideOrthoimgsError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'S3OrthoImageCopyError', message: string } | { __typename: 'Success', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiS3SignedUrlFieldFileMutationVariables = Exact<{
  company_id?: Scalars['UUID'];
  user_field_id?: Scalars['UUID'];
  filename?: Scalars['String'];
}>;


export type FastapiS3SignedUrlFieldFileMutation = { __typename?: 'mutation_root', fastapiS3SignedUrlFieldFile: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InvalidParameterError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'S3SignedUrlSuccess', signedUrl: string } | { __typename: 'UFieldActiveDoesNotExistsError', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiInsertAUfieldInCompanyV2MutationVariables = Exact<{
  user_field_name?: Scalars['String'];
  company_id?: Scalars['UUID'];
  master_field_key?: Scalars['String'];
  user_field_group_id?: Scalars['UUID'];
}>;


export type FastapiInsertAUfieldInCompanyV2Mutation = { __typename?: 'mutation_root', fastapiInsertAUfieldInCompanyV2: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'InsertFieldSuccess', fieldId: any, message: string } | { __typename: 'MFieldActiveDoesNotExistsError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'NumFieldsExcessError', message: string } | { __typename: 'UFieldGroupActiveDoesNotExistsError', message: string } | { __typename: 'UserIdError', message: string } };

export type FastapiS3SignedUrlTrackerApksQueryVariables = Exact<{
  company_id?: Scalars['UUID'];
}>;


export type FastapiS3SignedUrlTrackerApksQuery = { __typename?: 'query_root', fastapiS3SignedUrlTrackerApks: { __typename: 'CompanyDoesNotExistsError', message: string } | { __typename: 'NotHavingPermissionError', message: string } | { __typename: 'S3SignedUrlTrackerApksSuccess', signedUrls: Array<{ __typename?: 'S3SignedUrlTrackerApk', signedUrl: string, appName: string, version: string, codeHash: string, releaseDatetime: any, buildType?: string | null }> } | { __typename: 'UserIdError', message: string } };

export type PerformedWorkFragment = { __typename?: 'nb_performed_work', id: string, task_type: string, start_datetime: string, working_time?: number | null, nb_ufield?: { __typename?: 'nb_ufield', id: string, field_name: string } | null, appUserActiveByWorkerId: { __typename?: 'app_user_active', id: string, name: string }, app_user_active: { __typename?: 'app_user_active', id: string, name: string }, nb_cultivation_plan?: { __typename?: 'nb_cultivation_plan', id: string, name: string } | null };

export type GetMobileCompaniesQueryVariables = Exact<{
  app_user_active_id?: Scalars['uuid'];
}>;


export type GetMobileCompaniesQuery = { __typename?: 'query_root', nb_company: Array<{ __typename?: 'nb_company', id: string, name: string, nbpm_user_attr_links: Array<{ __typename?: 'nbpm_user_attr_link', nbpm_user_attribute: { __typename?: 'nbpm_user_attribute', attribute: string } }>, nbpm_contract_plan: { __typename?: 'nbpm_contract_plan', nbpm_contract_plan_links: Array<{ __typename?: 'nbpm_contract_plan_link', nbpm_authorization?: { __typename?: 'nbpm_authorization', key: string, name: string, writable: boolean } | null }> } }> };

export type GetMobileUserFieldsQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
}>;


export type GetMobileUserFieldsQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, field_name: string, polygon_field: any, nb_ufattr_links: Array<{ __typename?: 'nb_ufattr_link', nb_ufield_attribute: { __typename?: 'nb_ufield_attribute', id: string, name: string } }>, nb_ufg_links: Array<{ __typename?: 'nb_ufg_link', nb_ufield_group: { __typename?: 'nb_ufield_group', id: string, name: string } }> }> };

export type GetMobileUserFieldAttributesQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
}>;


export type GetMobileUserFieldAttributesQuery = { __typename?: 'query_root', nb_ufield_attribute: Array<{ __typename?: 'nb_ufield_attribute', id: string, name: string, created: string }> };

export type GetMobileUserFieldGroupsQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
}>;


export type GetMobileUserFieldGroupsQuery = { __typename?: 'query_root', nb_ufield_group: Array<{ __typename?: 'nb_ufield_group', id: string, name: string, created: string }> };

export type GetMobileTaskTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMobileTaskTypesQuery = { __typename?: 'query_root', nb_task_type: Array<{ __typename?: 'nb_task_type', type: string }> };

export type GetTackerLatestUserLocationsSubscriptionVariables = Exact<{
  company_id?: Scalars['uuid'];
  datetime?: Scalars['timestamptz'];
}>;


export type GetTackerLatestUserLocationsSubscription = { __typename?: 'subscription_root', nbtrk_context: Array<{ __typename?: 'nbtrk_context', id: string, created: string, task_type: string, user_id: string, app_user_active: { __typename?: 'app_user_active', name: string }, nbtrk_eph_locations: Array<{ __typename?: 'nbtrk_eph_location', loc_time: string, point_lat: any, point_lng: any }> }> };

export type GetMobileUserLocationContextsQueryVariables = Exact<{
  user_ids?: Array<Scalars['uuid']> | Scalars['uuid'];
  date_from?: Scalars['timestamptz'];
  date_to?: Scalars['timestamptz'];
  task_types?: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetMobileUserLocationContextsQuery = { __typename?: 'query_root', nbtrk_context: Array<{ __typename?: 'nbtrk_context', id: string }> };

export type GetMobileUserLocationHistoryQueryVariables = Exact<{
  context_id?: Scalars['uuid'];
}>;


export type GetMobileUserLocationHistoryQuery = { __typename?: 'query_root', nbtrk_context: Array<{ __typename?: 'nbtrk_context', id: string, created: string, task_type: string, user_id: string, app_user_active: { __typename?: 'app_user_active', name: string }, nbtrk_eph_locations: Array<{ __typename?: 'nbtrk_eph_location', loc_time: string, point_lat: any, point_lng: any }>, nb_performed_works: Array<{ __typename?: 'nb_performed_work', id: string, start_datetime: string, working_time?: number | null, nb_ufield?: { __typename?: 'nb_ufield', id: string, field_name: string, polygon_field: any } | null }> }> };

export type GetMobileUserFieldsInGeometryQueryVariables = Exact<{
  geometry?: Scalars['geometry'];
}>;


export type GetMobileUserFieldsInGeometryQuery = { __typename?: 'query_root', nb_ufield: Array<{ __typename?: 'nb_ufield', id: string, field_name: string, polygon_field: any }> };

export type DeleteMobilePerformedWorksMutationVariables = Exact<{
  ids?: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type DeleteMobilePerformedWorksMutation = { __typename?: 'mutation_root', update_nb_performed_work?: { __typename?: 'nb_performed_work_mutation_response', returning: Array<{ __typename?: 'nb_performed_work', id: string }> } | null };

export type InsertMobilePerformedWorksMutationVariables = Exact<{
  input?: Array<Nb_Performed_Work_Insert_Input> | Nb_Performed_Work_Insert_Input;
}>;


export type InsertMobilePerformedWorksMutation = { __typename?: 'mutation_root', insert_nb_performed_work?: { __typename?: 'nb_performed_work_mutation_response', returning: Array<{ __typename?: 'nb_performed_work', id: string }> } | null };

export type InsertPerformedWorksMutationVariables = Exact<{
  input?: Array<Nb_Performed_Work_Insert_Input> | Nb_Performed_Work_Insert_Input;
}>;


export type InsertPerformedWorksMutation = { __typename?: 'mutation_root', insert_nb_performed_work?: { __typename?: 'nb_performed_work_mutation_response', returning: Array<{ __typename?: 'nb_performed_work', id: string }> } | null };

export type UpdatePerformedWorkMutationVariables = Exact<{
  performed_work_id?: Scalars['uuid'];
  input?: Nb_Performed_Work_Set_Input;
}>;


export type UpdatePerformedWorkMutation = { __typename?: 'mutation_root', update_nb_performed_work?: { __typename?: 'nb_performed_work_mutation_response', returning: Array<{ __typename?: 'nb_performed_work', id: string }> } | null };

export type DeletePerformedWorksMutationVariables = Exact<{
  performed_work_ids?: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type DeletePerformedWorksMutation = { __typename?: 'mutation_root', update_nb_performed_work?: { __typename?: 'nb_performed_work_mutation_response', returning: Array<{ __typename?: 'nb_performed_work', id: string }> } | null };

export type GetPerformedWorksQueryVariables = Exact<{
  company_id?: Scalars['uuid'];
  user_field_group_id?: Scalars['uuid'];
}>;


export type GetPerformedWorksQuery = { __typename?: 'query_root', nb_performed_work: Array<{ __typename?: 'nb_performed_work', id: string, task_type: string, start_datetime: string, working_time?: number | null, nb_ufield?: { __typename?: 'nb_ufield', id: string, field_name: string } | null, appUserActiveByWorkerId: { __typename?: 'app_user_active', id: string, name: string }, app_user_active: { __typename?: 'app_user_active', id: string, name: string }, nb_cultivation_plan?: { __typename?: 'nb_cultivation_plan', id: string, name: string } | null }> };

export type Upsert_App_UserMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
}>;


export type Upsert_App_UserMutation = { __typename?: 'mutation_root', insert_app_user?: { __typename?: 'app_user_mutation_response', returning: Array<{ __typename?: 'app_user', id: string }> } | null };

export const FieldMemoFragmentDoc = gql`
    fragment FieldMemo on nb_field_memo {
  id
  memo
  created
  updated
  user_id
  app_user_active {
    name
  }
}
    `;
export const PerformedWorkFragmentDoc = gql`
    fragment PerformedWork on nb_performed_work {
  id
  task_type
  nb_ufield {
    id
    field_name
  }
  start_datetime
  appUserActiveByWorkerId {
    id
    name
  }
  app_user_active {
    id
    name
  }
  working_time
  nb_cultivation_plan {
    id
    name
  }
}
    `;
export const GetCompanyListDocument = gql`
    query GetCompanyList {
  nb_company(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now"}}
    order_by: {name: asc}
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetCompanyListQuery__
 *
 * To run a query within a React component, call `useGetCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyListQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, options);
      }
export function useGetCompanyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, options);
        }
export type GetCompanyListQueryHookResult = ReturnType<typeof useGetCompanyListQuery>;
export type GetCompanyListLazyQueryHookResult = ReturnType<typeof useGetCompanyListLazyQuery>;
export type GetCompanyListQueryResult = Apollo.QueryResult<GetCompanyListQuery, GetCompanyListQueryVariables>;
export const GetUserAttributeAndAuthorizationsDocument = gql`
    query GetUserAttributeAndAuthorizations($company_id: uuid! = "00000000-0000-0000-0000-000000000000", $app_user_active_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_company(where: {id: {_eq: $company_id}}) {
    nbpm_user_attr_links(where: {app_user_active: {id: {_eq: $app_user_active_id}}}) {
      nbpm_user_attribute {
        attribute
      }
    }
    nbpm_contract_plan {
      nbpm_contract_plan_links(
        where: {nbpm_user_cp_link_links: {app_user_active: {id: {_eq: $app_user_active_id}}}}
      ) {
        nbpm_authorization {
          key
          name
          writable
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserAttributeAndAuthorizationsQuery__
 *
 * To run a query within a React component, call `useGetUserAttributeAndAuthorizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAttributeAndAuthorizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAttributeAndAuthorizationsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      app_user_active_id: // value for 'app_user_active_id'
 *   },
 * });
 */
export function useGetUserAttributeAndAuthorizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAttributeAndAuthorizationsQuery, GetUserAttributeAndAuthorizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAttributeAndAuthorizationsQuery, GetUserAttributeAndAuthorizationsQueryVariables>(GetUserAttributeAndAuthorizationsDocument, options);
      }
export function useGetUserAttributeAndAuthorizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAttributeAndAuthorizationsQuery, GetUserAttributeAndAuthorizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAttributeAndAuthorizationsQuery, GetUserAttributeAndAuthorizationsQueryVariables>(GetUserAttributeAndAuthorizationsDocument, options);
        }
export type GetUserAttributeAndAuthorizationsQueryHookResult = ReturnType<typeof useGetUserAttributeAndAuthorizationsQuery>;
export type GetUserAttributeAndAuthorizationsLazyQueryHookResult = ReturnType<typeof useGetUserAttributeAndAuthorizationsLazyQuery>;
export type GetUserAttributeAndAuthorizationsQueryResult = Apollo.QueryResult<GetUserAttributeAndAuthorizationsQuery, GetUserAttributeAndAuthorizationsQueryVariables>;
export const GetUserFieldGroupListDocument = gql`
    query GetUserFieldGroupList($company_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_ufield_group(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, company_id: {_eq: $company_id}}
    order_by: {name: asc}
  ) {
    id
    created
    name
    nb_ufg_links(
      where: {nb_ufield: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}}}
    ) {
      nb_ufield {
        id
      }
    }
  }
}
    `;

/**
 * __useGetUserFieldGroupListQuery__
 *
 * To run a query within a React component, call `useGetUserFieldGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldGroupListQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetUserFieldGroupListQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldGroupListQuery, GetUserFieldGroupListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldGroupListQuery, GetUserFieldGroupListQueryVariables>(GetUserFieldGroupListDocument, options);
      }
export function useGetUserFieldGroupListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldGroupListQuery, GetUserFieldGroupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldGroupListQuery, GetUserFieldGroupListQueryVariables>(GetUserFieldGroupListDocument, options);
        }
export type GetUserFieldGroupListQueryHookResult = ReturnType<typeof useGetUserFieldGroupListQuery>;
export type GetUserFieldGroupListLazyQueryHookResult = ReturnType<typeof useGetUserFieldGroupListLazyQuery>;
export type GetUserFieldGroupListQueryResult = Apollo.QueryResult<GetUserFieldGroupListQuery, GetUserFieldGroupListQueryVariables>;
export const GetUserFieldListDocument = gql`
    query GetUserFieldList($user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_ufield(
    where: {valid_end: {_gte: "now()"}, nb_ufg_links: {nb_ufield_group: {valid_end: {_gte: "now()"}, id: {_eq: $user_field_group_id}}}}
    order_by: {field_name: asc}
  ) {
    id
    field_name
    polygon_field
  }
}
    `;

/**
 * __useGetUserFieldListQuery__
 *
 * To run a query within a React component, call `useGetUserFieldListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldListQuery({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *   },
 * });
 */
export function useGetUserFieldListQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldListQuery, GetUserFieldListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldListQuery, GetUserFieldListQueryVariables>(GetUserFieldListDocument, options);
      }
export function useGetUserFieldListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldListQuery, GetUserFieldListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldListQuery, GetUserFieldListQueryVariables>(GetUserFieldListDocument, options);
        }
export type GetUserFieldListQueryHookResult = ReturnType<typeof useGetUserFieldListQuery>;
export type GetUserFieldListLazyQueryHookResult = ReturnType<typeof useGetUserFieldListLazyQuery>;
export type GetUserFieldListQueryResult = Apollo.QueryResult<GetUserFieldListQuery, GetUserFieldListQueryVariables>;
export const GetUserFieldByUserFieldGroupIdDocument = gql`
    query GetUserFieldByUserFieldGroupId($user_field_group_id: uuid = "00000000-0000-0000-0000-000000000000") {
  nb_ufield(
    order_by: {field_name: asc}
    where: {nb_ufg_links: {nb_ufield_group: {id: {_eq: $user_field_group_id}}}, valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
  ) {
    id
    field_name
    polygon_field
    nb_field_pictures(
      where: {valid_end: {_gte: "now()"}}
      order_by: {datetime: desc}
    ) {
      id
      thumbnail_path
      image_path
      cap_coor
      datetime
    }
  }
}
    `;

/**
 * __useGetUserFieldByUserFieldGroupIdQuery__
 *
 * To run a query within a React component, call `useGetUserFieldByUserFieldGroupIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldByUserFieldGroupIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldByUserFieldGroupIdQuery({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *   },
 * });
 */
export function useGetUserFieldByUserFieldGroupIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldByUserFieldGroupIdQuery, GetUserFieldByUserFieldGroupIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldByUserFieldGroupIdQuery, GetUserFieldByUserFieldGroupIdQueryVariables>(GetUserFieldByUserFieldGroupIdDocument, options);
      }
export function useGetUserFieldByUserFieldGroupIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldByUserFieldGroupIdQuery, GetUserFieldByUserFieldGroupIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldByUserFieldGroupIdQuery, GetUserFieldByUserFieldGroupIdQueryVariables>(GetUserFieldByUserFieldGroupIdDocument, options);
        }
export type GetUserFieldByUserFieldGroupIdQueryHookResult = ReturnType<typeof useGetUserFieldByUserFieldGroupIdQuery>;
export type GetUserFieldByUserFieldGroupIdLazyQueryHookResult = ReturnType<typeof useGetUserFieldByUserFieldGroupIdLazyQuery>;
export type GetUserFieldByUserFieldGroupIdQueryResult = Apollo.QueryResult<GetUserFieldByUserFieldGroupIdQuery, GetUserFieldByUserFieldGroupIdQueryVariables>;
export const GetUserFieldPicturesDocument = gql`
    query GetUserFieldPictures($user_field_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_field_picture(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, nb_ufield: {id: {_eq: $user_field_id}}}
    order_by: {datetime: desc}
  ) {
    id
    thumbnail_path
    datetime
    nb_memo_picture_links(
      where: {nb_field_memo: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}}}
    ) {
      nb_field_memo {
        id
      }
    }
  }
}
    `;

/**
 * __useGetUserFieldPicturesQuery__
 *
 * To run a query within a React component, call `useGetUserFieldPicturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldPicturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldPicturesQuery({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *   },
 * });
 */
export function useGetUserFieldPicturesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldPicturesQuery, GetUserFieldPicturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldPicturesQuery, GetUserFieldPicturesQueryVariables>(GetUserFieldPicturesDocument, options);
      }
export function useGetUserFieldPicturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldPicturesQuery, GetUserFieldPicturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldPicturesQuery, GetUserFieldPicturesQueryVariables>(GetUserFieldPicturesDocument, options);
        }
export type GetUserFieldPicturesQueryHookResult = ReturnType<typeof useGetUserFieldPicturesQuery>;
export type GetUserFieldPicturesLazyQueryHookResult = ReturnType<typeof useGetUserFieldPicturesLazyQuery>;
export type GetUserFieldPicturesQueryResult = Apollo.QueryResult<GetUserFieldPicturesQuery, GetUserFieldPicturesQueryVariables>;
export const GetUserFieldPicturesByFieldPictureIdDocument = gql`
    query GetUserFieldPicturesByFieldPictureId($field_picture_id: uuid = "00000000-0000-0000-0000-000000000000") {
  nb_field_picture(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, nb_ufield: {nb_field_pictures: {id: {_eq: $field_picture_id}}}}
    order_by: {datetime: desc}
  ) {
    id
    datetime
    image_path
    nb_field_phtographers {
      app_user_active {
        name
      }
    }
    nb_picture_analyses(order_by: {created: desc}) {
      result
      nb_picture_analysis_type {
        name
      }
    }
  }
}
    `;

/**
 * __useGetUserFieldPicturesByFieldPictureIdQuery__
 *
 * To run a query within a React component, call `useGetUserFieldPicturesByFieldPictureIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldPicturesByFieldPictureIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldPicturesByFieldPictureIdQuery({
 *   variables: {
 *      field_picture_id: // value for 'field_picture_id'
 *   },
 * });
 */
export function useGetUserFieldPicturesByFieldPictureIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldPicturesByFieldPictureIdQuery, GetUserFieldPicturesByFieldPictureIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldPicturesByFieldPictureIdQuery, GetUserFieldPicturesByFieldPictureIdQueryVariables>(GetUserFieldPicturesByFieldPictureIdDocument, options);
      }
export function useGetUserFieldPicturesByFieldPictureIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldPicturesByFieldPictureIdQuery, GetUserFieldPicturesByFieldPictureIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldPicturesByFieldPictureIdQuery, GetUserFieldPicturesByFieldPictureIdQueryVariables>(GetUserFieldPicturesByFieldPictureIdDocument, options);
        }
export type GetUserFieldPicturesByFieldPictureIdQueryHookResult = ReturnType<typeof useGetUserFieldPicturesByFieldPictureIdQuery>;
export type GetUserFieldPicturesByFieldPictureIdLazyQueryHookResult = ReturnType<typeof useGetUserFieldPicturesByFieldPictureIdLazyQuery>;
export type GetUserFieldPicturesByFieldPictureIdQueryResult = Apollo.QueryResult<GetUserFieldPicturesByFieldPictureIdQuery, GetUserFieldPicturesByFieldPictureIdQueryVariables>;
export const GetUserFieldLayerDataDocument = gql`
    query GetUserFieldLayerData($user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_ufield(
    where: {valid_end: {_gte: "now()"}, nb_ufg_links: {nb_ufield_group: {valid_end: {_gte: "now()"}, id: {_eq: $user_field_group_id}}}}
    order_by: {updated: asc}
  ) {
    id
    field_name
    polygon_field
    nb_field_pictures(
      where: {valid_end: {_gte: "now()"}}
      order_by: {datetime: desc}
    ) {
      id
      thumbnail_path
      cap_coor
    }
  }
}
    `;

/**
 * __useGetUserFieldLayerDataQuery__
 *
 * To run a query within a React component, call `useGetUserFieldLayerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldLayerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldLayerDataQuery({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *   },
 * });
 */
export function useGetUserFieldLayerDataQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldLayerDataQuery, GetUserFieldLayerDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldLayerDataQuery, GetUserFieldLayerDataQueryVariables>(GetUserFieldLayerDataDocument, options);
      }
export function useGetUserFieldLayerDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldLayerDataQuery, GetUserFieldLayerDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldLayerDataQuery, GetUserFieldLayerDataQueryVariables>(GetUserFieldLayerDataDocument, options);
        }
export type GetUserFieldLayerDataQueryHookResult = ReturnType<typeof useGetUserFieldLayerDataQuery>;
export type GetUserFieldLayerDataLazyQueryHookResult = ReturnType<typeof useGetUserFieldLayerDataLazyQuery>;
export type GetUserFieldLayerDataQueryResult = Apollo.QueryResult<GetUserFieldLayerDataQuery, GetUserFieldLayerDataQueryVariables>;
export const GetCultivationDateListDocument = gql`
    query GetCultivationDateList($user_field_id: uuid = "00000000-0000-0000-0000-000000000000") {
  nb_cultivation_plan(
    order_by: {updated: desc}
    where: {user_field_id: {_eq: $user_field_id}, valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
  ) {
    id
    name
    seeded_at
    transplanted_at
    reaping_at
    nb_fertilization_works(
      where: {valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
      order_by: {datetime: asc}
      limit: 1
    ) {
      id
      datetime
    }
  }
}
    `;

/**
 * __useGetCultivationDateListQuery__
 *
 * To run a query within a React component, call `useGetCultivationDateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCultivationDateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCultivationDateListQuery({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *   },
 * });
 */
export function useGetCultivationDateListQuery(baseOptions?: Apollo.QueryHookOptions<GetCultivationDateListQuery, GetCultivationDateListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCultivationDateListQuery, GetCultivationDateListQueryVariables>(GetCultivationDateListDocument, options);
      }
export function useGetCultivationDateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCultivationDateListQuery, GetCultivationDateListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCultivationDateListQuery, GetCultivationDateListQueryVariables>(GetCultivationDateListDocument, options);
        }
export type GetCultivationDateListQueryHookResult = ReturnType<typeof useGetCultivationDateListQuery>;
export type GetCultivationDateListLazyQueryHookResult = ReturnType<typeof useGetCultivationDateListLazyQuery>;
export type GetCultivationDateListQueryResult = Apollo.QueryResult<GetCultivationDateListQuery, GetCultivationDateListQueryVariables>;
export const GetFarmingFieldChartDocument = gql`
    query GetFarmingFieldChart($id: uuid = "00000000-0000-0000-0000-000000000000", $term_start: timestamptz = "now()", $term_end: timestamptz = "now()") {
  nb_mfield_link(where: {nb_ufield: {id: {_eq: $id}}}) {
    master_nb_mfield {
      nb_area_mesh {
        nb_weather_data(
          order_by: {datetime: asc}
          where: {datetime: {_gte: $term_start, _lte: $term_end}}
        ) {
          datetime
          temperature
          day_temperature
          night_temperature
          diff_temperature
        }
      }
    }
  }
  nb_ufield(where: {id: {_eq: $id}}) {
    nb_field_sensings {
      nb_field_sensing_data(
        order_by: {datetime: asc}
        where: {datetime: {_gte: $term_start, _lte: $term_end}}
      ) {
        datetime
        water_level
        water_temperature
        ground_temperature
        day_water_temperature
        night_water_temperature
        diff_water_temperature
      }
    }
  }
  nb_ufield(where: {id: {_eq: $id}}) {
    nbbe_microclimates(
      where: {datetime: {_gte: $term_start, _lte: $term_end}}
      order_by: {datetime: asc}
    ) {
      datetime
      organic_fertilizer_efficiency
      chemical_fertilizer_efficiency
      coated_urea_fertilizer_efficiency
    }
  }
}
    `;

/**
 * __useGetFarmingFieldChartQuery__
 *
 * To run a query within a React component, call `useGetFarmingFieldChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFarmingFieldChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFarmingFieldChartQuery({
 *   variables: {
 *      id: // value for 'id'
 *      term_start: // value for 'term_start'
 *      term_end: // value for 'term_end'
 *   },
 * });
 */
export function useGetFarmingFieldChartQuery(baseOptions?: Apollo.QueryHookOptions<GetFarmingFieldChartQuery, GetFarmingFieldChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFarmingFieldChartQuery, GetFarmingFieldChartQueryVariables>(GetFarmingFieldChartDocument, options);
      }
export function useGetFarmingFieldChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFarmingFieldChartQuery, GetFarmingFieldChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFarmingFieldChartQuery, GetFarmingFieldChartQueryVariables>(GetFarmingFieldChartDocument, options);
        }
export type GetFarmingFieldChartQueryHookResult = ReturnType<typeof useGetFarmingFieldChartQuery>;
export type GetFarmingFieldChartLazyQueryHookResult = ReturnType<typeof useGetFarmingFieldChartLazyQuery>;
export type GetFarmingFieldChartQueryResult = Apollo.QueryResult<GetFarmingFieldChartQuery, GetFarmingFieldChartQueryVariables>;
export const GetTemperatureDataDocument = gql`
    query GetTemperatureData($user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000", $datetime_from: timestamptz! = "now()", $datetime_to: timestamptz! = "now()") {
  nb_ufield(
    where: {nb_ufg_links: {nb_ufield_group: {id: {_eq: $user_field_group_id}, valid_end: {_gte: "now()"}}}, valid_end: {_gte: "now()"}}
    order_by: {field_name: asc}
  ) {
    id
    field_name
    polygon_field
    nb_mfield_links {
      master_nb_mfield {
        nb_area_mesh {
          nb_weather_data(
            order_by: {datetime: desc}
            where: {datetime: {_gte: $datetime_from, _lt: $datetime_to}}
          ) {
            temperature
            day_temperature
            night_temperature
            diff_temperature
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetTemperatureDataQuery__
 *
 * To run a query within a React component, call `useGetTemperatureDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemperatureDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemperatureDataQuery({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *      datetime_from: // value for 'datetime_from'
 *      datetime_to: // value for 'datetime_to'
 *   },
 * });
 */
export function useGetTemperatureDataQuery(baseOptions?: Apollo.QueryHookOptions<GetTemperatureDataQuery, GetTemperatureDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemperatureDataQuery, GetTemperatureDataQueryVariables>(GetTemperatureDataDocument, options);
      }
export function useGetTemperatureDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemperatureDataQuery, GetTemperatureDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemperatureDataQuery, GetTemperatureDataQueryVariables>(GetTemperatureDataDocument, options);
        }
export type GetTemperatureDataQueryHookResult = ReturnType<typeof useGetTemperatureDataQuery>;
export type GetTemperatureDataLazyQueryHookResult = ReturnType<typeof useGetTemperatureDataLazyQuery>;
export type GetTemperatureDataQueryResult = Apollo.QueryResult<GetTemperatureDataQuery, GetTemperatureDataQueryVariables>;
export const InsertFieldWaterGateDocument = gql`
    mutation InsertFieldWaterGate($objects: [nb_field_water_gate_insert_input!] = {}) {
  insert_nb_field_water_gate(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertFieldWaterGateMutationFn = Apollo.MutationFunction<InsertFieldWaterGateMutation, InsertFieldWaterGateMutationVariables>;

/**
 * __useInsertFieldWaterGateMutation__
 *
 * To run a mutation, you first call `useInsertFieldWaterGateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFieldWaterGateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFieldWaterGateMutation, { data, loading, error }] = useInsertFieldWaterGateMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertFieldWaterGateMutation(baseOptions?: Apollo.MutationHookOptions<InsertFieldWaterGateMutation, InsertFieldWaterGateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFieldWaterGateMutation, InsertFieldWaterGateMutationVariables>(InsertFieldWaterGateDocument, options);
      }
export type InsertFieldWaterGateMutationHookResult = ReturnType<typeof useInsertFieldWaterGateMutation>;
export type InsertFieldWaterGateMutationResult = Apollo.MutationResult<InsertFieldWaterGateMutation>;
export type InsertFieldWaterGateMutationOptions = Apollo.BaseMutationOptions<InsertFieldWaterGateMutation, InsertFieldWaterGateMutationVariables>;
export const InsertFieldWaterGateStatusDocument = gql`
    mutation InsertFieldWaterGateStatus($objects: [nb_field_water_gate_status_insert_input!] = {}) {
  insert_nb_field_water_gate_status(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertFieldWaterGateStatusMutationFn = Apollo.MutationFunction<InsertFieldWaterGateStatusMutation, InsertFieldWaterGateStatusMutationVariables>;

/**
 * __useInsertFieldWaterGateStatusMutation__
 *
 * To run a mutation, you first call `useInsertFieldWaterGateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFieldWaterGateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFieldWaterGateStatusMutation, { data, loading, error }] = useInsertFieldWaterGateStatusMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertFieldWaterGateStatusMutation(baseOptions?: Apollo.MutationHookOptions<InsertFieldWaterGateStatusMutation, InsertFieldWaterGateStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFieldWaterGateStatusMutation, InsertFieldWaterGateStatusMutationVariables>(InsertFieldWaterGateStatusDocument, options);
      }
export type InsertFieldWaterGateStatusMutationHookResult = ReturnType<typeof useInsertFieldWaterGateStatusMutation>;
export type InsertFieldWaterGateStatusMutationResult = Apollo.MutationResult<InsertFieldWaterGateStatusMutation>;
export type InsertFieldWaterGateStatusMutationOptions = Apollo.BaseMutationOptions<InsertFieldWaterGateStatusMutation, InsertFieldWaterGateStatusMutationVariables>;
export const DeleteFieldWaterGateDocument = gql`
    mutation DeleteFieldWaterGate($field_water_gate_id: uuid = "00000000-0000-0000-0000-000000000000") {
  update_nb_field_water_gate(
    where: {id: {_eq: $field_water_gate_id}}
    _set: {valid_end: "now()"}
  ) {
    returning {
      id
    }
  }
}
    `;
export type DeleteFieldWaterGateMutationFn = Apollo.MutationFunction<DeleteFieldWaterGateMutation, DeleteFieldWaterGateMutationVariables>;

/**
 * __useDeleteFieldWaterGateMutation__
 *
 * To run a mutation, you first call `useDeleteFieldWaterGateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFieldWaterGateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFieldWaterGateMutation, { data, loading, error }] = useDeleteFieldWaterGateMutation({
 *   variables: {
 *      field_water_gate_id: // value for 'field_water_gate_id'
 *   },
 * });
 */
export function useDeleteFieldWaterGateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFieldWaterGateMutation, DeleteFieldWaterGateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFieldWaterGateMutation, DeleteFieldWaterGateMutationVariables>(DeleteFieldWaterGateDocument, options);
      }
export type DeleteFieldWaterGateMutationHookResult = ReturnType<typeof useDeleteFieldWaterGateMutation>;
export type DeleteFieldWaterGateMutationResult = Apollo.MutationResult<DeleteFieldWaterGateMutation>;
export type DeleteFieldWaterGateMutationOptions = Apollo.BaseMutationOptions<DeleteFieldWaterGateMutation, DeleteFieldWaterGateMutationVariables>;
export const RevWaterGateDocument = gql`
    subscription RevWaterGate($user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_field_water_gate(
    where: {valid_end: {_gte: "now()"}, nb_ufield: {nb_ufg_links: {nb_ufield_group: {valid_end: {_gte: "now()"}, id: {_eq: $user_field_group_id}}}}}
  ) {
    id
    key
    kind
    creator_name
    wg_coor
    nb_field_water_gate_statuses(order_by: {created: desc}) {
      id
      status
      datetime
      creator_name
      app_user_active {
        id
      }
    }
    nb_ufield {
      id
      field_name
      area_sqm
      nb_cultivation_plans(
        where: {transplanted_at: {_is_null: false, _gte: "20210401"}}
        order_by: {transplanted_at: desc}
        limit: 1
      ) {
        nb_cultivar {
          name
        }
        transplanted_at
      }
    }
  }
}
    `;

/**
 * __useRevWaterGateSubscription__
 *
 * To run a query within a React component, call `useRevWaterGateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRevWaterGateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevWaterGateSubscription({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *   },
 * });
 */
export function useRevWaterGateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RevWaterGateSubscription, RevWaterGateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RevWaterGateSubscription, RevWaterGateSubscriptionVariables>(RevWaterGateDocument, options);
      }
export type RevWaterGateSubscriptionHookResult = ReturnType<typeof useRevWaterGateSubscription>;
export type RevWaterGateSubscriptionResult = Apollo.SubscriptionResult<RevWaterGateSubscription>;
export const GetWaterGateDocument = gql`
    query GetWaterGate($user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_field_water_gate(
    where: {valid_end: {_gte: "now()"}, nb_ufield: {nb_ufg_links: {nb_ufield_group: {valid_end: {_gte: "now()"}, id: {_eq: $user_field_group_id}}}}}
  ) {
    id
    key
    kind
    creator_name
    wg_coor
    nb_field_water_gate_statuses(order_by: {created: desc}) {
      id
      status
      datetime
      creator_name
    }
    nb_ufield {
      id
    }
  }
}
    `;

/**
 * __useGetWaterGateQuery__
 *
 * To run a query within a React component, call `useGetWaterGateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaterGateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaterGateQuery({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *   },
 * });
 */
export function useGetWaterGateQuery(baseOptions?: Apollo.QueryHookOptions<GetWaterGateQuery, GetWaterGateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWaterGateQuery, GetWaterGateQueryVariables>(GetWaterGateDocument, options);
      }
export function useGetWaterGateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWaterGateQuery, GetWaterGateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWaterGateQuery, GetWaterGateQueryVariables>(GetWaterGateDocument, options);
        }
export type GetWaterGateQueryHookResult = ReturnType<typeof useGetWaterGateQuery>;
export type GetWaterGateLazyQueryHookResult = ReturnType<typeof useGetWaterGateLazyQuery>;
export type GetWaterGateQueryResult = Apollo.QueryResult<GetWaterGateQuery, GetWaterGateQueryVariables>;
export const InsertSoilReportDocument = gql`
    mutation InsertSoilReport($objects: [nb_soil_chemistry_insert_input!]! = {}) {
  insert_nb_soil_chemistry(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertSoilReportMutationFn = Apollo.MutationFunction<InsertSoilReportMutation, InsertSoilReportMutationVariables>;

/**
 * __useInsertSoilReportMutation__
 *
 * To run a mutation, you first call `useInsertSoilReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSoilReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSoilReportMutation, { data, loading, error }] = useInsertSoilReportMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertSoilReportMutation(baseOptions?: Apollo.MutationHookOptions<InsertSoilReportMutation, InsertSoilReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSoilReportMutation, InsertSoilReportMutationVariables>(InsertSoilReportDocument, options);
      }
export type InsertSoilReportMutationHookResult = ReturnType<typeof useInsertSoilReportMutation>;
export type InsertSoilReportMutationResult = Apollo.MutationResult<InsertSoilReportMutation>;
export type InsertSoilReportMutationOptions = Apollo.BaseMutationOptions<InsertSoilReportMutation, InsertSoilReportMutationVariables>;
export const DeleteSoilReportDocument = gql`
    mutation DeleteSoilReport($id: uuid!) {
  update_nb_soil_chemistry(where: {id: {_eq: $id}}, _set: {valid_end: "now()"}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteSoilReportMutationFn = Apollo.MutationFunction<DeleteSoilReportMutation, DeleteSoilReportMutationVariables>;

/**
 * __useDeleteSoilReportMutation__
 *
 * To run a mutation, you first call `useDeleteSoilReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSoilReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSoilReportMutation, { data, loading, error }] = useDeleteSoilReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSoilReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSoilReportMutation, DeleteSoilReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSoilReportMutation, DeleteSoilReportMutationVariables>(DeleteSoilReportDocument, options);
      }
export type DeleteSoilReportMutationHookResult = ReturnType<typeof useDeleteSoilReportMutation>;
export type DeleteSoilReportMutationResult = Apollo.MutationResult<DeleteSoilReportMutation>;
export type DeleteSoilReportMutationOptions = Apollo.BaseMutationOptions<DeleteSoilReportMutation, DeleteSoilReportMutationVariables>;
export const UpdateSoilChemistryDocument = gql`
    mutation UpdateSoilChemistry($id: uuid!, $object: nb_soil_chemistry_set_input = {}) {
  update_nb_soil_chemistry(where: {id: {_eq: $id}}, _set: $object) {
    returning {
      id
    }
  }
}
    `;
export type UpdateSoilChemistryMutationFn = Apollo.MutationFunction<UpdateSoilChemistryMutation, UpdateSoilChemistryMutationVariables>;

/**
 * __useUpdateSoilChemistryMutation__
 *
 * To run a mutation, you first call `useUpdateSoilChemistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSoilChemistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSoilChemistryMutation, { data, loading, error }] = useUpdateSoilChemistryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateSoilChemistryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSoilChemistryMutation, UpdateSoilChemistryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSoilChemistryMutation, UpdateSoilChemistryMutationVariables>(UpdateSoilChemistryDocument, options);
      }
export type UpdateSoilChemistryMutationHookResult = ReturnType<typeof useUpdateSoilChemistryMutation>;
export type UpdateSoilChemistryMutationResult = Apollo.MutationResult<UpdateSoilChemistryMutation>;
export type UpdateSoilChemistryMutationOptions = Apollo.BaseMutationOptions<UpdateSoilChemistryMutation, UpdateSoilChemistryMutationVariables>;
export const UpdateSoilChemistryValueDocument = gql`
    mutation UpdateSoilChemistryValue($id: uuid!, $object: nb_soil_chemistry_value_set_input = {}) {
  update_nb_soil_chemistry_value(where: {id: {_eq: $id}}, _set: $object) {
    returning {
      id
    }
  }
}
    `;
export type UpdateSoilChemistryValueMutationFn = Apollo.MutationFunction<UpdateSoilChemistryValueMutation, UpdateSoilChemistryValueMutationVariables>;

/**
 * __useUpdateSoilChemistryValueMutation__
 *
 * To run a mutation, you first call `useUpdateSoilChemistryValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSoilChemistryValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSoilChemistryValueMutation, { data, loading, error }] = useUpdateSoilChemistryValueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateSoilChemistryValueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSoilChemistryValueMutation, UpdateSoilChemistryValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSoilChemistryValueMutation, UpdateSoilChemistryValueMutationVariables>(UpdateSoilChemistryValueDocument, options);
      }
export type UpdateSoilChemistryValueMutationHookResult = ReturnType<typeof useUpdateSoilChemistryValueMutation>;
export type UpdateSoilChemistryValueMutationResult = Apollo.MutationResult<UpdateSoilChemistryValueMutation>;
export type UpdateSoilChemistryValueMutationOptions = Apollo.BaseMutationOptions<UpdateSoilChemistryValueMutation, UpdateSoilChemistryValueMutationVariables>;
export const GetLatestCultivationPlanListDocument = gql`
    query GetLatestCultivationPlanList($user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_ufield(
    where: {valid_end: {_gte: "now()"}, nb_ufg_links: {nb_ufield_group: {valid_end: {_gte: "now()"}, id: {_eq: $user_field_group_id}}}}
  ) {
    id
    field_name
    area_sqm
    plans_seeded_at: nb_cultivation_plans(
      where: {_or: [{seeded_at: {_is_null: false}}, {transplanted_at: {_is_null: false}}], valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
      order_by: {seeded_at: desc_nulls_last}
      limit: 1
    ) {
      nb_crop {
        name
      }
      nb_cultivar {
        name
      }
      seeded_at
      transplanted_at
      heading_at
    }
    plans_transplanted_at: nb_cultivation_plans(
      where: {_or: [{seeded_at: {_is_null: false}}, {transplanted_at: {_is_null: false}}], valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
      order_by: {transplanted_at: desc_nulls_last}
      limit: 1
    ) {
      nb_crop {
        name
      }
      nb_cultivar {
        name
      }
      seeded_at
      transplanted_at
      heading_at
    }
  }
}
    `;

/**
 * __useGetLatestCultivationPlanListQuery__
 *
 * To run a query within a React component, call `useGetLatestCultivationPlanListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestCultivationPlanListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestCultivationPlanListQuery({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *   },
 * });
 */
export function useGetLatestCultivationPlanListQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestCultivationPlanListQuery, GetLatestCultivationPlanListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestCultivationPlanListQuery, GetLatestCultivationPlanListQueryVariables>(GetLatestCultivationPlanListDocument, options);
      }
export function useGetLatestCultivationPlanListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestCultivationPlanListQuery, GetLatestCultivationPlanListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestCultivationPlanListQuery, GetLatestCultivationPlanListQueryVariables>(GetLatestCultivationPlanListDocument, options);
        }
export type GetLatestCultivationPlanListQueryHookResult = ReturnType<typeof useGetLatestCultivationPlanListQuery>;
export type GetLatestCultivationPlanListLazyQueryHookResult = ReturnType<typeof useGetLatestCultivationPlanListLazyQuery>;
export type GetLatestCultivationPlanListQueryResult = Apollo.QueryResult<GetLatestCultivationPlanListQuery, GetLatestCultivationPlanListQueryVariables>;
export const GetUserFieldsWithMasterFieldDocument = gql`
    query GetUserFieldsWithMasterField($master_field_key: uuid! = "00000000-0000-0000-0000-000000000000", $user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  master_nb_mfield(
    where: {key: {_eq: $master_field_key}, valid_end: {_gte: "now()"}}
  ) {
    nb_mfield_links(
      where: {nb_ufield: {nb_ufg_links: {nb_ufield_group: {id: {_eq: $user_field_group_id}}}, valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}}}
      order_by: {nb_ufield: {field_name: asc}}
    ) {
      nb_ufield {
        id
        field_name
      }
    }
  }
}
    `;

/**
 * __useGetUserFieldsWithMasterFieldQuery__
 *
 * To run a query within a React component, call `useGetUserFieldsWithMasterFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldsWithMasterFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldsWithMasterFieldQuery({
 *   variables: {
 *      master_field_key: // value for 'master_field_key'
 *      user_field_group_id: // value for 'user_field_group_id'
 *   },
 * });
 */
export function useGetUserFieldsWithMasterFieldQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldsWithMasterFieldQuery, GetUserFieldsWithMasterFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldsWithMasterFieldQuery, GetUserFieldsWithMasterFieldQueryVariables>(GetUserFieldsWithMasterFieldDocument, options);
      }
export function useGetUserFieldsWithMasterFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldsWithMasterFieldQuery, GetUserFieldsWithMasterFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldsWithMasterFieldQuery, GetUserFieldsWithMasterFieldQueryVariables>(GetUserFieldsWithMasterFieldDocument, options);
        }
export type GetUserFieldsWithMasterFieldQueryHookResult = ReturnType<typeof useGetUserFieldsWithMasterFieldQuery>;
export type GetUserFieldsWithMasterFieldLazyQueryHookResult = ReturnType<typeof useGetUserFieldsWithMasterFieldLazyQuery>;
export type GetUserFieldsWithMasterFieldQueryResult = Apollo.QueryResult<GetUserFieldsWithMasterFieldQuery, GetUserFieldsWithMasterFieldQueryVariables>;
export const GetCropListDocument = gql`
    query GetCropList {
  nb_crop(order_by: {name: asc}) {
    id
    name
    key
  }
}
    `;

/**
 * __useGetCropListQuery__
 *
 * To run a query within a React component, call `useGetCropListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCropListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCropListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCropListQuery(baseOptions?: Apollo.QueryHookOptions<GetCropListQuery, GetCropListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCropListQuery, GetCropListQueryVariables>(GetCropListDocument, options);
      }
export function useGetCropListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCropListQuery, GetCropListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCropListQuery, GetCropListQueryVariables>(GetCropListDocument, options);
        }
export type GetCropListQueryHookResult = ReturnType<typeof useGetCropListQuery>;
export type GetCropListLazyQueryHookResult = ReturnType<typeof useGetCropListLazyQuery>;
export type GetCropListQueryResult = Apollo.QueryResult<GetCropListQuery, GetCropListQueryVariables>;
export const GetCultivarListDocument = gql`
    query GetCultivarList($crop_id: uuid = "00000000-0000-0000-0000-000000000000") {
  nb_cultivar(where: {crop_id: {_eq: $crop_id}}, order_by: {name: asc}) {
    id
    name
    key
  }
}
    `;

/**
 * __useGetCultivarListQuery__
 *
 * To run a query within a React component, call `useGetCultivarListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCultivarListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCultivarListQuery({
 *   variables: {
 *      crop_id: // value for 'crop_id'
 *   },
 * });
 */
export function useGetCultivarListQuery(baseOptions?: Apollo.QueryHookOptions<GetCultivarListQuery, GetCultivarListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCultivarListQuery, GetCultivarListQueryVariables>(GetCultivarListDocument, options);
      }
export function useGetCultivarListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCultivarListQuery, GetCultivarListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCultivarListQuery, GetCultivarListQueryVariables>(GetCultivarListDocument, options);
        }
export type GetCultivarListQueryHookResult = ReturnType<typeof useGetCultivarListQuery>;
export type GetCultivarListLazyQueryHookResult = ReturnType<typeof useGetCultivarListLazyQuery>;
export type GetCultivarListQueryResult = Apollo.QueryResult<GetCultivarListQuery, GetCultivarListQueryVariables>;
export const GetCultivationMethodListDocument = gql`
    query GetCultivationMethodList($crop_id: uuid = "00000000-0000-0000-0000-000000000000") {
  nb_crop(where: {id: {_eq: $crop_id}}) {
    id
    nb_crop_method_links(order_by: {nb_cultivation_method: {key: asc}}) {
      nb_cultivation_method {
        id
        name
        is_transplant
      }
    }
  }
}
    `;

/**
 * __useGetCultivationMethodListQuery__
 *
 * To run a query within a React component, call `useGetCultivationMethodListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCultivationMethodListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCultivationMethodListQuery({
 *   variables: {
 *      crop_id: // value for 'crop_id'
 *   },
 * });
 */
export function useGetCultivationMethodListQuery(baseOptions?: Apollo.QueryHookOptions<GetCultivationMethodListQuery, GetCultivationMethodListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCultivationMethodListQuery, GetCultivationMethodListQueryVariables>(GetCultivationMethodListDocument, options);
      }
export function useGetCultivationMethodListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCultivationMethodListQuery, GetCultivationMethodListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCultivationMethodListQuery, GetCultivationMethodListQueryVariables>(GetCultivationMethodListDocument, options);
        }
export type GetCultivationMethodListQueryHookResult = ReturnType<typeof useGetCultivationMethodListQuery>;
export type GetCultivationMethodListLazyQueryHookResult = ReturnType<typeof useGetCultivationMethodListLazyQuery>;
export type GetCultivationMethodListQueryResult = Apollo.QueryResult<GetCultivationMethodListQuery, GetCultivationMethodListQueryVariables>;
export const GetFertilizerAndConditionerListDocument = gql`
    query GetFertilizerAndConditionerList($company_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_company(where: {id: {_eq: $company_id}}) {
    nb_fertilizer_company_links(order_by: {nb_fertilizer: {name: asc}}) {
      nb_fertilizer {
        id
        name
        picture_path
        thumbnail_path
        nb_fertilizer_pictures(order_by: {sort_key: asc}) {
          id
          picture_path
        }
        is_fertilizer
        is_conditioner
        content
      }
    }
  }
}
    `;

/**
 * __useGetFertilizerAndConditionerListQuery__
 *
 * To run a query within a React component, call `useGetFertilizerAndConditionerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFertilizerAndConditionerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFertilizerAndConditionerListQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetFertilizerAndConditionerListQuery(baseOptions?: Apollo.QueryHookOptions<GetFertilizerAndConditionerListQuery, GetFertilizerAndConditionerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFertilizerAndConditionerListQuery, GetFertilizerAndConditionerListQueryVariables>(GetFertilizerAndConditionerListDocument, options);
      }
export function useGetFertilizerAndConditionerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFertilizerAndConditionerListQuery, GetFertilizerAndConditionerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFertilizerAndConditionerListQuery, GetFertilizerAndConditionerListQueryVariables>(GetFertilizerAndConditionerListDocument, options);
        }
export type GetFertilizerAndConditionerListQueryHookResult = ReturnType<typeof useGetFertilizerAndConditionerListQuery>;
export type GetFertilizerAndConditionerListLazyQueryHookResult = ReturnType<typeof useGetFertilizerAndConditionerListLazyQuery>;
export type GetFertilizerAndConditionerListQueryResult = Apollo.QueryResult<GetFertilizerAndConditionerListQuery, GetFertilizerAndConditionerListQueryVariables>;
export const GetFertilizationMethodListDocument = gql`
    query GetFertilizationMethodList {
  nb_fertilization_method {
    id
    name
  }
}
    `;

/**
 * __useGetFertilizationMethodListQuery__
 *
 * To run a query within a React component, call `useGetFertilizationMethodListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFertilizationMethodListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFertilizationMethodListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFertilizationMethodListQuery(baseOptions?: Apollo.QueryHookOptions<GetFertilizationMethodListQuery, GetFertilizationMethodListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFertilizationMethodListQuery, GetFertilizationMethodListQueryVariables>(GetFertilizationMethodListDocument, options);
      }
export function useGetFertilizationMethodListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFertilizationMethodListQuery, GetFertilizationMethodListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFertilizationMethodListQuery, GetFertilizationMethodListQueryVariables>(GetFertilizationMethodListDocument, options);
        }
export type GetFertilizationMethodListQueryHookResult = ReturnType<typeof useGetFertilizationMethodListQuery>;
export type GetFertilizationMethodListLazyQueryHookResult = ReturnType<typeof useGetFertilizationMethodListLazyQuery>;
export type GetFertilizationMethodListQueryResult = Apollo.QueryResult<GetFertilizationMethodListQuery, GetFertilizationMethodListQueryVariables>;
export const GetUserFieldCultivationPlanListDocument = gql`
    query GetUserFieldCultivationPlanList($user_field_id: uuid = "00000000-0000-0000-0000-000000000000") {
  nb_cultivation_plan(
    order_by: {updated: desc}
    where: {user_field_id: {_eq: $user_field_id}, valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
  ) {
    id
    updated
    name
    method_id
    seeded_at
    transplanted_at
    reaping_at
    nb_crop {
      id
      name
    }
    nb_cultivar {
      id
      name
    }
    nb_cultivation_method {
      id
      name
      is_transplant
    }
    nb_actual_crop_yeilds {
      id
      yield_weight
    }
    nb_fertilization_works(
      where: {valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
      order_by: {basal_fertilizer: desc, datetime: asc}
    ) {
      id
      datetime
      fertilizer_amount
      fertilizer_id
      basal_fertilizer
      fertilization_method_id
      nb_fertilizer {
        id
        name
        picture_path
        thumbnail_path
        nb_fertilizer_pictures(order_by: {sort_key: asc}) {
          id
          picture_path
        }
        content
      }
      nb_fertilization_method {
        id
        name
      }
    }
    nb_pesticide_spray_works(
      where: {valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
      order_by: {datetime: asc}
    ) {
      id
      datetime
      comment
    }
  }
}
    `;

/**
 * __useGetUserFieldCultivationPlanListQuery__
 *
 * To run a query within a React component, call `useGetUserFieldCultivationPlanListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldCultivationPlanListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldCultivationPlanListQuery({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *   },
 * });
 */
export function useGetUserFieldCultivationPlanListQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldCultivationPlanListQuery, GetUserFieldCultivationPlanListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldCultivationPlanListQuery, GetUserFieldCultivationPlanListQueryVariables>(GetUserFieldCultivationPlanListDocument, options);
      }
export function useGetUserFieldCultivationPlanListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldCultivationPlanListQuery, GetUserFieldCultivationPlanListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldCultivationPlanListQuery, GetUserFieldCultivationPlanListQueryVariables>(GetUserFieldCultivationPlanListDocument, options);
        }
export type GetUserFieldCultivationPlanListQueryHookResult = ReturnType<typeof useGetUserFieldCultivationPlanListQuery>;
export type GetUserFieldCultivationPlanListLazyQueryHookResult = ReturnType<typeof useGetUserFieldCultivationPlanListLazyQuery>;
export type GetUserFieldCultivationPlanListQueryResult = Apollo.QueryResult<GetUserFieldCultivationPlanListQuery, GetUserFieldCultivationPlanListQueryVariables>;
export const GetCultivationPlanDocument = gql`
    query GetCultivationPlan($cultivation_plan_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_cultivation_plan(
    where: {id: {_eq: $cultivation_plan_id}, valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
  ) {
    id
    updated
    name
    method
    method_id
    seeded_at
    transplanted_at
    reaping_at
    user_id
    creator_name
    nb_crop {
      id
      name
    }
    nb_cultivar {
      id
      name
    }
    nb_actual_crop_yeilds {
      id
      yield_weight
      user_id
      creator_name
    }
    nb_fertilization_works(
      where: {valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
      order_by: {basal_fertilizer: desc, datetime: asc}
    ) {
      id
      datetime
      fertilizer_amount
      fertilizer_id
      user_id
      sequence
      basal_fertilizer
      fertilization_method_id
      nb_fertilizer {
        id
        name
        picture_path
        thumbnail_path
        nb_fertilizer_pictures(order_by: {sort_key: asc}) {
          id
          picture_path
        }
      }
      nb_fertilization_method {
        id
        name
      }
    }
    nb_pesticide_spray_works(
      where: {valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
      order_by: {datetime: asc}
    ) {
      id
      datetime
      comment
    }
    nb_ufield {
      id
      field_name
    }
  }
}
    `;

/**
 * __useGetCultivationPlanQuery__
 *
 * To run a query within a React component, call `useGetCultivationPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCultivationPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCultivationPlanQuery({
 *   variables: {
 *      cultivation_plan_id: // value for 'cultivation_plan_id'
 *   },
 * });
 */
export function useGetCultivationPlanQuery(baseOptions?: Apollo.QueryHookOptions<GetCultivationPlanQuery, GetCultivationPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCultivationPlanQuery, GetCultivationPlanQueryVariables>(GetCultivationPlanDocument, options);
      }
export function useGetCultivationPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCultivationPlanQuery, GetCultivationPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCultivationPlanQuery, GetCultivationPlanQueryVariables>(GetCultivationPlanDocument, options);
        }
export type GetCultivationPlanQueryHookResult = ReturnType<typeof useGetCultivationPlanQuery>;
export type GetCultivationPlanLazyQueryHookResult = ReturnType<typeof useGetCultivationPlanLazyQuery>;
export type GetCultivationPlanQueryResult = Apollo.QueryResult<GetCultivationPlanQuery, GetCultivationPlanQueryVariables>;
export const InsertUserFieldCultivationPlanDocument = gql`
    mutation InsertUserFieldCultivationPlan($objects: [nb_cultivation_plan_insert_input!]! = {}) {
  insert_nb_cultivation_plan(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertUserFieldCultivationPlanMutationFn = Apollo.MutationFunction<InsertUserFieldCultivationPlanMutation, InsertUserFieldCultivationPlanMutationVariables>;

/**
 * __useInsertUserFieldCultivationPlanMutation__
 *
 * To run a mutation, you first call `useInsertUserFieldCultivationPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserFieldCultivationPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserFieldCultivationPlanMutation, { data, loading, error }] = useInsertUserFieldCultivationPlanMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertUserFieldCultivationPlanMutation(baseOptions?: Apollo.MutationHookOptions<InsertUserFieldCultivationPlanMutation, InsertUserFieldCultivationPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUserFieldCultivationPlanMutation, InsertUserFieldCultivationPlanMutationVariables>(InsertUserFieldCultivationPlanDocument, options);
      }
export type InsertUserFieldCultivationPlanMutationHookResult = ReturnType<typeof useInsertUserFieldCultivationPlanMutation>;
export type InsertUserFieldCultivationPlanMutationResult = Apollo.MutationResult<InsertUserFieldCultivationPlanMutation>;
export type InsertUserFieldCultivationPlanMutationOptions = Apollo.BaseMutationOptions<InsertUserFieldCultivationPlanMutation, InsertUserFieldCultivationPlanMutationVariables>;
export const UpdateUserFieldCultivationPlanDocument = gql`
    mutation UpdateUserFieldCultivationPlan($cultivation_plan_id: uuid! = "00000000-0000-0000-0000-000000000000", $object: nb_cultivation_plan_set_input = {}) {
  update_nb_cultivation_plan_by_pk(
    pk_columns: {id: $cultivation_plan_id}
    _set: $object
  ) {
    id
  }
}
    `;
export type UpdateUserFieldCultivationPlanMutationFn = Apollo.MutationFunction<UpdateUserFieldCultivationPlanMutation, UpdateUserFieldCultivationPlanMutationVariables>;

/**
 * __useUpdateUserFieldCultivationPlanMutation__
 *
 * To run a mutation, you first call `useUpdateUserFieldCultivationPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFieldCultivationPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFieldCultivationPlanMutation, { data, loading, error }] = useUpdateUserFieldCultivationPlanMutation({
 *   variables: {
 *      cultivation_plan_id: // value for 'cultivation_plan_id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateUserFieldCultivationPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserFieldCultivationPlanMutation, UpdateUserFieldCultivationPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserFieldCultivationPlanMutation, UpdateUserFieldCultivationPlanMutationVariables>(UpdateUserFieldCultivationPlanDocument, options);
      }
export type UpdateUserFieldCultivationPlanMutationHookResult = ReturnType<typeof useUpdateUserFieldCultivationPlanMutation>;
export type UpdateUserFieldCultivationPlanMutationResult = Apollo.MutationResult<UpdateUserFieldCultivationPlanMutation>;
export type UpdateUserFieldCultivationPlanMutationOptions = Apollo.BaseMutationOptions<UpdateUserFieldCultivationPlanMutation, UpdateUserFieldCultivationPlanMutationVariables>;
export const DeleteUserFieldCultivationPlanDocument = gql`
    mutation DeleteUserFieldCultivationPlan($cultivation_plan_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  update_nb_cultivation_plan_by_pk(
    pk_columns: {id: $cultivation_plan_id}
    _set: {valid_end: "now()"}
  ) {
    id
  }
  update_nb_fertilization_work(
    where: {cultivation_plan_id: {_eq: $cultivation_plan_id}}
    _set: {valid_end: "now()"}
  ) {
    returning {
      id
    }
  }
}
    `;
export type DeleteUserFieldCultivationPlanMutationFn = Apollo.MutationFunction<DeleteUserFieldCultivationPlanMutation, DeleteUserFieldCultivationPlanMutationVariables>;

/**
 * __useDeleteUserFieldCultivationPlanMutation__
 *
 * To run a mutation, you first call `useDeleteUserFieldCultivationPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFieldCultivationPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFieldCultivationPlanMutation, { data, loading, error }] = useDeleteUserFieldCultivationPlanMutation({
 *   variables: {
 *      cultivation_plan_id: // value for 'cultivation_plan_id'
 *   },
 * });
 */
export function useDeleteUserFieldCultivationPlanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserFieldCultivationPlanMutation, DeleteUserFieldCultivationPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserFieldCultivationPlanMutation, DeleteUserFieldCultivationPlanMutationVariables>(DeleteUserFieldCultivationPlanDocument, options);
      }
export type DeleteUserFieldCultivationPlanMutationHookResult = ReturnType<typeof useDeleteUserFieldCultivationPlanMutation>;
export type DeleteUserFieldCultivationPlanMutationResult = Apollo.MutationResult<DeleteUserFieldCultivationPlanMutation>;
export type DeleteUserFieldCultivationPlanMutationOptions = Apollo.BaseMutationOptions<DeleteUserFieldCultivationPlanMutation, DeleteUserFieldCultivationPlanMutationVariables>;
export const UpdateCropYieldWeightDocument = gql`
    mutation UpdateCropYieldWeight($cultivation_plan_id: uuid! = "00000000-0000-0000-0000-000000000000", $yield_weight: float8 = null, $user_id: uuid = null, $creator_name: String = "") {
  update_nb_actual_crop_yeild(
    where: {cultivation_plan_id: {_eq: $cultivation_plan_id}}
    _set: {yield_weight: $yield_weight, user_id: $user_id, creator_name: $creator_name}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateCropYieldWeightMutationFn = Apollo.MutationFunction<UpdateCropYieldWeightMutation, UpdateCropYieldWeightMutationVariables>;

/**
 * __useUpdateCropYieldWeightMutation__
 *
 * To run a mutation, you first call `useUpdateCropYieldWeightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCropYieldWeightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCropYieldWeightMutation, { data, loading, error }] = useUpdateCropYieldWeightMutation({
 *   variables: {
 *      cultivation_plan_id: // value for 'cultivation_plan_id'
 *      yield_weight: // value for 'yield_weight'
 *      user_id: // value for 'user_id'
 *      creator_name: // value for 'creator_name'
 *   },
 * });
 */
export function useUpdateCropYieldWeightMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCropYieldWeightMutation, UpdateCropYieldWeightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCropYieldWeightMutation, UpdateCropYieldWeightMutationVariables>(UpdateCropYieldWeightDocument, options);
      }
export type UpdateCropYieldWeightMutationHookResult = ReturnType<typeof useUpdateCropYieldWeightMutation>;
export type UpdateCropYieldWeightMutationResult = Apollo.MutationResult<UpdateCropYieldWeightMutation>;
export type UpdateCropYieldWeightMutationOptions = Apollo.BaseMutationOptions<UpdateCropYieldWeightMutation, UpdateCropYieldWeightMutationVariables>;
export const InsertFertilizationWorkDocument = gql`
    mutation InsertFertilizationWork($objects: [nb_fertilization_work_insert_input!]! = {}) {
  insert_nb_fertilization_work(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertFertilizationWorkMutationFn = Apollo.MutationFunction<InsertFertilizationWorkMutation, InsertFertilizationWorkMutationVariables>;

/**
 * __useInsertFertilizationWorkMutation__
 *
 * To run a mutation, you first call `useInsertFertilizationWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFertilizationWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFertilizationWorkMutation, { data, loading, error }] = useInsertFertilizationWorkMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertFertilizationWorkMutation(baseOptions?: Apollo.MutationHookOptions<InsertFertilizationWorkMutation, InsertFertilizationWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFertilizationWorkMutation, InsertFertilizationWorkMutationVariables>(InsertFertilizationWorkDocument, options);
      }
export type InsertFertilizationWorkMutationHookResult = ReturnType<typeof useInsertFertilizationWorkMutation>;
export type InsertFertilizationWorkMutationResult = Apollo.MutationResult<InsertFertilizationWorkMutation>;
export type InsertFertilizationWorkMutationOptions = Apollo.BaseMutationOptions<InsertFertilizationWorkMutation, InsertFertilizationWorkMutationVariables>;
export const UpdateFertilizationWorkDocument = gql`
    mutation UpdateFertilizationWork($fertilization_work_id: uuid! = "00000000-0000-0000-0000-000000000000", $datetime: timestamptz!, $fertilizer_amount: Float!, $fertilizer_id: uuid!, $basal_fertilizer: Boolean!, $fertilization_method_id: uuid!, $user_id: uuid = null) {
  update_nb_fertilization_work(
    where: {id: {_eq: $fertilization_work_id}}
    _set: {datetime: $datetime, fertilizer_amount: $fertilizer_amount, fertilizer_id: $fertilizer_id, basal_fertilizer: $basal_fertilizer, fertilization_method_id: $fertilization_method_id, user_id: $user_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateFertilizationWorkMutationFn = Apollo.MutationFunction<UpdateFertilizationWorkMutation, UpdateFertilizationWorkMutationVariables>;

/**
 * __useUpdateFertilizationWorkMutation__
 *
 * To run a mutation, you first call `useUpdateFertilizationWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFertilizationWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFertilizationWorkMutation, { data, loading, error }] = useUpdateFertilizationWorkMutation({
 *   variables: {
 *      fertilization_work_id: // value for 'fertilization_work_id'
 *      datetime: // value for 'datetime'
 *      fertilizer_amount: // value for 'fertilizer_amount'
 *      fertilizer_id: // value for 'fertilizer_id'
 *      basal_fertilizer: // value for 'basal_fertilizer'
 *      fertilization_method_id: // value for 'fertilization_method_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUpdateFertilizationWorkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFertilizationWorkMutation, UpdateFertilizationWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFertilizationWorkMutation, UpdateFertilizationWorkMutationVariables>(UpdateFertilizationWorkDocument, options);
      }
export type UpdateFertilizationWorkMutationHookResult = ReturnType<typeof useUpdateFertilizationWorkMutation>;
export type UpdateFertilizationWorkMutationResult = Apollo.MutationResult<UpdateFertilizationWorkMutation>;
export type UpdateFertilizationWorkMutationOptions = Apollo.BaseMutationOptions<UpdateFertilizationWorkMutation, UpdateFertilizationWorkMutationVariables>;
export const DeleteFertilizationWorkDocument = gql`
    mutation DeleteFertilizationWork($fertilization_work_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  update_nb_fertilization_work_by_pk(
    pk_columns: {id: $fertilization_work_id}
    _set: {valid_end: "now()"}
  ) {
    id
  }
}
    `;
export type DeleteFertilizationWorkMutationFn = Apollo.MutationFunction<DeleteFertilizationWorkMutation, DeleteFertilizationWorkMutationVariables>;

/**
 * __useDeleteFertilizationWorkMutation__
 *
 * To run a mutation, you first call `useDeleteFertilizationWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFertilizationWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFertilizationWorkMutation, { data, loading, error }] = useDeleteFertilizationWorkMutation({
 *   variables: {
 *      fertilization_work_id: // value for 'fertilization_work_id'
 *   },
 * });
 */
export function useDeleteFertilizationWorkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFertilizationWorkMutation, DeleteFertilizationWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFertilizationWorkMutation, DeleteFertilizationWorkMutationVariables>(DeleteFertilizationWorkDocument, options);
      }
export type DeleteFertilizationWorkMutationHookResult = ReturnType<typeof useDeleteFertilizationWorkMutation>;
export type DeleteFertilizationWorkMutationResult = Apollo.MutationResult<DeleteFertilizationWorkMutation>;
export type DeleteFertilizationWorkMutationOptions = Apollo.BaseMutationOptions<DeleteFertilizationWorkMutation, DeleteFertilizationWorkMutationVariables>;
export const GetConditionerWorksDocument = gql`
    query GetConditionerWorks($user_field_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_conditioner_work(
    where: {valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}, ufield_id: {_eq: $user_field_id}}
    order_by: {datetime: desc}
  ) {
    id
    conditioner_id
    datetime
    conditioner_amount
    nb_fertilizer {
      id
      name
    }
  }
}
    `;

/**
 * __useGetConditionerWorksQuery__
 *
 * To run a query within a React component, call `useGetConditionerWorksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConditionerWorksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConditionerWorksQuery({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *   },
 * });
 */
export function useGetConditionerWorksQuery(baseOptions?: Apollo.QueryHookOptions<GetConditionerWorksQuery, GetConditionerWorksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConditionerWorksQuery, GetConditionerWorksQueryVariables>(GetConditionerWorksDocument, options);
      }
export function useGetConditionerWorksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConditionerWorksQuery, GetConditionerWorksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConditionerWorksQuery, GetConditionerWorksQueryVariables>(GetConditionerWorksDocument, options);
        }
export type GetConditionerWorksQueryHookResult = ReturnType<typeof useGetConditionerWorksQuery>;
export type GetConditionerWorksLazyQueryHookResult = ReturnType<typeof useGetConditionerWorksLazyQuery>;
export type GetConditionerWorksQueryResult = Apollo.QueryResult<GetConditionerWorksQuery, GetConditionerWorksQueryVariables>;
export const InsertConditionerWorkDocument = gql`
    mutation InsertConditionerWork($objects: [nb_conditioner_work_insert_input!]! = {}) {
  insert_nb_conditioner_work(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertConditionerWorkMutationFn = Apollo.MutationFunction<InsertConditionerWorkMutation, InsertConditionerWorkMutationVariables>;

/**
 * __useInsertConditionerWorkMutation__
 *
 * To run a mutation, you first call `useInsertConditionerWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConditionerWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConditionerWorkMutation, { data, loading, error }] = useInsertConditionerWorkMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertConditionerWorkMutation(baseOptions?: Apollo.MutationHookOptions<InsertConditionerWorkMutation, InsertConditionerWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertConditionerWorkMutation, InsertConditionerWorkMutationVariables>(InsertConditionerWorkDocument, options);
      }
export type InsertConditionerWorkMutationHookResult = ReturnType<typeof useInsertConditionerWorkMutation>;
export type InsertConditionerWorkMutationResult = Apollo.MutationResult<InsertConditionerWorkMutation>;
export type InsertConditionerWorkMutationOptions = Apollo.BaseMutationOptions<InsertConditionerWorkMutation, InsertConditionerWorkMutationVariables>;
export const UpdateConditionerWorkDocument = gql`
    mutation UpdateConditionerWork($conditioner_work_id: uuid! = "00000000-0000-0000-0000-000000000000", $conditioner_id: uuid! = "00000000-0000-0000-0000-000000000000", $datetime: timestamptz!, $conditioner_amount: Float!, $user_id: uuid = null) {
  update_nb_conditioner_work(
    where: {id: {_eq: $conditioner_work_id}}
    _set: {conditioner_id: $conditioner_id, datetime: $datetime, conditioner_amount: $conditioner_amount, user_id: $user_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateConditionerWorkMutationFn = Apollo.MutationFunction<UpdateConditionerWorkMutation, UpdateConditionerWorkMutationVariables>;

/**
 * __useUpdateConditionerWorkMutation__
 *
 * To run a mutation, you first call `useUpdateConditionerWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConditionerWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConditionerWorkMutation, { data, loading, error }] = useUpdateConditionerWorkMutation({
 *   variables: {
 *      conditioner_work_id: // value for 'conditioner_work_id'
 *      conditioner_id: // value for 'conditioner_id'
 *      datetime: // value for 'datetime'
 *      conditioner_amount: // value for 'conditioner_amount'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUpdateConditionerWorkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConditionerWorkMutation, UpdateConditionerWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConditionerWorkMutation, UpdateConditionerWorkMutationVariables>(UpdateConditionerWorkDocument, options);
      }
export type UpdateConditionerWorkMutationHookResult = ReturnType<typeof useUpdateConditionerWorkMutation>;
export type UpdateConditionerWorkMutationResult = Apollo.MutationResult<UpdateConditionerWorkMutation>;
export type UpdateConditionerWorkMutationOptions = Apollo.BaseMutationOptions<UpdateConditionerWorkMutation, UpdateConditionerWorkMutationVariables>;
export const DeleteConditionerWorkDocument = gql`
    mutation DeleteConditionerWork($conditioner_work_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  update_nb_conditioner_work_by_pk(
    pk_columns: {id: $conditioner_work_id}
    _set: {valid_end: "now()"}
  ) {
    id
  }
}
    `;
export type DeleteConditionerWorkMutationFn = Apollo.MutationFunction<DeleteConditionerWorkMutation, DeleteConditionerWorkMutationVariables>;

/**
 * __useDeleteConditionerWorkMutation__
 *
 * To run a mutation, you first call `useDeleteConditionerWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConditionerWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConditionerWorkMutation, { data, loading, error }] = useDeleteConditionerWorkMutation({
 *   variables: {
 *      conditioner_work_id: // value for 'conditioner_work_id'
 *   },
 * });
 */
export function useDeleteConditionerWorkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConditionerWorkMutation, DeleteConditionerWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConditionerWorkMutation, DeleteConditionerWorkMutationVariables>(DeleteConditionerWorkDocument, options);
      }
export type DeleteConditionerWorkMutationHookResult = ReturnType<typeof useDeleteConditionerWorkMutation>;
export type DeleteConditionerWorkMutationResult = Apollo.MutationResult<DeleteConditionerWorkMutation>;
export type DeleteConditionerWorkMutationOptions = Apollo.BaseMutationOptions<DeleteConditionerWorkMutation, DeleteConditionerWorkMutationVariables>;
export const InsertPesticideSprayWorkDocument = gql`
    mutation InsertPesticideSprayWork($objects: [nb_pesticide_spray_work_insert_input!]! = {}) {
  insert_nb_pesticide_spray_work(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertPesticideSprayWorkMutationFn = Apollo.MutationFunction<InsertPesticideSprayWorkMutation, InsertPesticideSprayWorkMutationVariables>;

/**
 * __useInsertPesticideSprayWorkMutation__
 *
 * To run a mutation, you first call `useInsertPesticideSprayWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPesticideSprayWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPesticideSprayWorkMutation, { data, loading, error }] = useInsertPesticideSprayWorkMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertPesticideSprayWorkMutation(baseOptions?: Apollo.MutationHookOptions<InsertPesticideSprayWorkMutation, InsertPesticideSprayWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPesticideSprayWorkMutation, InsertPesticideSprayWorkMutationVariables>(InsertPesticideSprayWorkDocument, options);
      }
export type InsertPesticideSprayWorkMutationHookResult = ReturnType<typeof useInsertPesticideSprayWorkMutation>;
export type InsertPesticideSprayWorkMutationResult = Apollo.MutationResult<InsertPesticideSprayWorkMutation>;
export type InsertPesticideSprayWorkMutationOptions = Apollo.BaseMutationOptions<InsertPesticideSprayWorkMutation, InsertPesticideSprayWorkMutationVariables>;
export const UpdatePesticideSprayWorkDocument = gql`
    mutation UpdatePesticideSprayWork($pesticide_spray_work_id: uuid! = "00000000-0000-0000-0000-000000000000", $object: nb_pesticide_spray_work_set_input! = {}) {
  update_nb_pesticide_spray_work(
    where: {id: {_eq: $pesticide_spray_work_id}}
    _set: $object
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdatePesticideSprayWorkMutationFn = Apollo.MutationFunction<UpdatePesticideSprayWorkMutation, UpdatePesticideSprayWorkMutationVariables>;

/**
 * __useUpdatePesticideSprayWorkMutation__
 *
 * To run a mutation, you first call `useUpdatePesticideSprayWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePesticideSprayWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePesticideSprayWorkMutation, { data, loading, error }] = useUpdatePesticideSprayWorkMutation({
 *   variables: {
 *      pesticide_spray_work_id: // value for 'pesticide_spray_work_id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdatePesticideSprayWorkMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePesticideSprayWorkMutation, UpdatePesticideSprayWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePesticideSprayWorkMutation, UpdatePesticideSprayWorkMutationVariables>(UpdatePesticideSprayWorkDocument, options);
      }
export type UpdatePesticideSprayWorkMutationHookResult = ReturnType<typeof useUpdatePesticideSprayWorkMutation>;
export type UpdatePesticideSprayWorkMutationResult = Apollo.MutationResult<UpdatePesticideSprayWorkMutation>;
export type UpdatePesticideSprayWorkMutationOptions = Apollo.BaseMutationOptions<UpdatePesticideSprayWorkMutation, UpdatePesticideSprayWorkMutationVariables>;
export const DeletePesticideSprayWorkDocument = gql`
    mutation DeletePesticideSprayWork($pesticide_spray_work_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  update_nb_pesticide_spray_work(
    where: {id: {_eq: $pesticide_spray_work_id}}
    _set: {valid_end: "now()"}
  ) {
    returning {
      id
    }
  }
}
    `;
export type DeletePesticideSprayWorkMutationFn = Apollo.MutationFunction<DeletePesticideSprayWorkMutation, DeletePesticideSprayWorkMutationVariables>;

/**
 * __useDeletePesticideSprayWorkMutation__
 *
 * To run a mutation, you first call `useDeletePesticideSprayWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePesticideSprayWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePesticideSprayWorkMutation, { data, loading, error }] = useDeletePesticideSprayWorkMutation({
 *   variables: {
 *      pesticide_spray_work_id: // value for 'pesticide_spray_work_id'
 *   },
 * });
 */
export function useDeletePesticideSprayWorkMutation(baseOptions?: Apollo.MutationHookOptions<DeletePesticideSprayWorkMutation, DeletePesticideSprayWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePesticideSprayWorkMutation, DeletePesticideSprayWorkMutationVariables>(DeletePesticideSprayWorkDocument, options);
      }
export type DeletePesticideSprayWorkMutationHookResult = ReturnType<typeof useDeletePesticideSprayWorkMutation>;
export type DeletePesticideSprayWorkMutationResult = Apollo.MutationResult<DeletePesticideSprayWorkMutation>;
export type DeletePesticideSprayWorkMutationOptions = Apollo.BaseMutationOptions<DeletePesticideSprayWorkMutation, DeletePesticideSprayWorkMutationVariables>;
export const GetUserProfileDocument = gql`
    query GetUserProfile {
  app_user {
    id
    app_user_active {
      id
      name
      nickname
      app_user_property_private {
        id
        phone_number
      }
    }
  }
}
    `;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const InsertActiveUserInfoDocument = gql`
    mutation InsertActiveUserInfo($objects: [app_user_active_insert_input!]! = {}) {
  insert_app_user_active(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertActiveUserInfoMutationFn = Apollo.MutationFunction<InsertActiveUserInfoMutation, InsertActiveUserInfoMutationVariables>;

/**
 * __useInsertActiveUserInfoMutation__
 *
 * To run a mutation, you first call `useInsertActiveUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertActiveUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertActiveUserInfoMutation, { data, loading, error }] = useInsertActiveUserInfoMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertActiveUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<InsertActiveUserInfoMutation, InsertActiveUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertActiveUserInfoMutation, InsertActiveUserInfoMutationVariables>(InsertActiveUserInfoDocument, options);
      }
export type InsertActiveUserInfoMutationHookResult = ReturnType<typeof useInsertActiveUserInfoMutation>;
export type InsertActiveUserInfoMutationResult = Apollo.MutationResult<InsertActiveUserInfoMutation>;
export type InsertActiveUserInfoMutationOptions = Apollo.BaseMutationOptions<InsertActiveUserInfoMutation, InsertActiveUserInfoMutationVariables>;
export const UpdateActiveUserInfoDocument = gql`
    mutation UpdateActiveUserInfo($id: uuid! = "00000000-0000-0000-0000-000000000000", $object: app_user_active_set_input = {}) {
  update_app_user_active_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
    `;
export type UpdateActiveUserInfoMutationFn = Apollo.MutationFunction<UpdateActiveUserInfoMutation, UpdateActiveUserInfoMutationVariables>;

/**
 * __useUpdateActiveUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateActiveUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveUserInfoMutation, { data, loading, error }] = useUpdateActiveUserInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateActiveUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActiveUserInfoMutation, UpdateActiveUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActiveUserInfoMutation, UpdateActiveUserInfoMutationVariables>(UpdateActiveUserInfoDocument, options);
      }
export type UpdateActiveUserInfoMutationHookResult = ReturnType<typeof useUpdateActiveUserInfoMutation>;
export type UpdateActiveUserInfoMutationResult = Apollo.MutationResult<UpdateActiveUserInfoMutation>;
export type UpdateActiveUserInfoMutationOptions = Apollo.BaseMutationOptions<UpdateActiveUserInfoMutation, UpdateActiveUserInfoMutationVariables>;
export const UpdateActiveUserPropertyPrivateDocument = gql`
    mutation UpdateActiveUserPropertyPrivate($id: uuid! = "00000000-0000-0000-0000-000000000000", $object: app_user_property_private_set_input = {}) {
  update_app_user_property_private_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
    `;
export type UpdateActiveUserPropertyPrivateMutationFn = Apollo.MutationFunction<UpdateActiveUserPropertyPrivateMutation, UpdateActiveUserPropertyPrivateMutationVariables>;

/**
 * __useUpdateActiveUserPropertyPrivateMutation__
 *
 * To run a mutation, you first call `useUpdateActiveUserPropertyPrivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveUserPropertyPrivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveUserPropertyPrivateMutation, { data, loading, error }] = useUpdateActiveUserPropertyPrivateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateActiveUserPropertyPrivateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActiveUserPropertyPrivateMutation, UpdateActiveUserPropertyPrivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActiveUserPropertyPrivateMutation, UpdateActiveUserPropertyPrivateMutationVariables>(UpdateActiveUserPropertyPrivateDocument, options);
      }
export type UpdateActiveUserPropertyPrivateMutationHookResult = ReturnType<typeof useUpdateActiveUserPropertyPrivateMutation>;
export type UpdateActiveUserPropertyPrivateMutationResult = Apollo.MutationResult<UpdateActiveUserPropertyPrivateMutation>;
export type UpdateActiveUserPropertyPrivateMutationOptions = Apollo.BaseMutationOptions<UpdateActiveUserPropertyPrivateMutation, UpdateActiveUserPropertyPrivateMutationVariables>;
export const GetSameNickNameDocument = gql`
    query GetSameNickName($nickName: String = "") {
  app_user_active(where: {nickname: {_eq: $nickName}}) {
    id
    nickname
  }
}
    `;

/**
 * __useGetSameNickNameQuery__
 *
 * To run a query within a React component, call `useGetSameNickNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSameNickNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSameNickNameQuery({
 *   variables: {
 *      nickName: // value for 'nickName'
 *   },
 * });
 */
export function useGetSameNickNameQuery(baseOptions?: Apollo.QueryHookOptions<GetSameNickNameQuery, GetSameNickNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSameNickNameQuery, GetSameNickNameQueryVariables>(GetSameNickNameDocument, options);
      }
export function useGetSameNickNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSameNickNameQuery, GetSameNickNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSameNickNameQuery, GetSameNickNameQueryVariables>(GetSameNickNameDocument, options);
        }
export type GetSameNickNameQueryHookResult = ReturnType<typeof useGetSameNickNameQuery>;
export type GetSameNickNameLazyQueryHookResult = ReturnType<typeof useGetSameNickNameLazyQuery>;
export type GetSameNickNameQueryResult = Apollo.QueryResult<GetSameNickNameQuery, GetSameNickNameQueryVariables>;
export const GetSensingTypeListDocument = gql`
    query GetSensingTypeList($company_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_image_sensing_attr(
    where: {nbpm_imsattr_authorization_links: {nbpm_authorization: {nbpm_contract_plan_links: {nbpm_contract_plan: {nb_company: {id: {_eq: $company_id}}}}}}}
    order_by: {key: asc}
  ) {
    key
    name
    unit
    lower_bound
    upper_bound
    nb_imsensing_colormap {
      color_map
    }
  }
}
    `;

/**
 * __useGetSensingTypeListQuery__
 *
 * To run a query within a React component, call `useGetSensingTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensingTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensingTypeListQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetSensingTypeListQuery(baseOptions?: Apollo.QueryHookOptions<GetSensingTypeListQuery, GetSensingTypeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSensingTypeListQuery, GetSensingTypeListQueryVariables>(GetSensingTypeListDocument, options);
      }
export function useGetSensingTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSensingTypeListQuery, GetSensingTypeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSensingTypeListQuery, GetSensingTypeListQueryVariables>(GetSensingTypeListDocument, options);
        }
export type GetSensingTypeListQueryHookResult = ReturnType<typeof useGetSensingTypeListQuery>;
export type GetSensingTypeListLazyQueryHookResult = ReturnType<typeof useGetSensingTypeListLazyQuery>;
export type GetSensingTypeListQueryResult = Apollo.QueryResult<GetSensingTypeListQuery, GetSensingTypeListQueryVariables>;
export const GetSensingLayerDataByUserFieldGroupDocument = gql`
    query GetSensingLayerDataByUserFieldGroup($user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000", $geometry: geometry!, $sensing_type_key: String! = "", $sampling_datetime_from: timestamptz! = "now()", $sampling_datetime_to: timestamptz! = "now()") {
  nb_ufield(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, nb_ufg_links: {nb_ufield_group: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, id: {_eq: $user_field_group_id}}}, polygon_field: {_st_intersects: $geometry}}
  ) {
    id
    field_name
    polygon_field
    nb_raster_image_sensings(
      where: {datetime: {_gte: $sampling_datetime_from, _lt: $sampling_datetime_to}, nb_image_sensing_attr: {key: {_eq: $sensing_type_key}}}
    ) {
      nb_vector_image_sensing {
        mean
        coarse_grain
        middle_grain
        fine_grain
      }
    }
  }
}
    `;

/**
 * __useGetSensingLayerDataByUserFieldGroupQuery__
 *
 * To run a query within a React component, call `useGetSensingLayerDataByUserFieldGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensingLayerDataByUserFieldGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensingLayerDataByUserFieldGroupQuery({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *      geometry: // value for 'geometry'
 *      sensing_type_key: // value for 'sensing_type_key'
 *      sampling_datetime_from: // value for 'sampling_datetime_from'
 *      sampling_datetime_to: // value for 'sampling_datetime_to'
 *   },
 * });
 */
export function useGetSensingLayerDataByUserFieldGroupQuery(baseOptions: Apollo.QueryHookOptions<GetSensingLayerDataByUserFieldGroupQuery, GetSensingLayerDataByUserFieldGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSensingLayerDataByUserFieldGroupQuery, GetSensingLayerDataByUserFieldGroupQueryVariables>(GetSensingLayerDataByUserFieldGroupDocument, options);
      }
export function useGetSensingLayerDataByUserFieldGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSensingLayerDataByUserFieldGroupQuery, GetSensingLayerDataByUserFieldGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSensingLayerDataByUserFieldGroupQuery, GetSensingLayerDataByUserFieldGroupQueryVariables>(GetSensingLayerDataByUserFieldGroupDocument, options);
        }
export type GetSensingLayerDataByUserFieldGroupQueryHookResult = ReturnType<typeof useGetSensingLayerDataByUserFieldGroupQuery>;
export type GetSensingLayerDataByUserFieldGroupLazyQueryHookResult = ReturnType<typeof useGetSensingLayerDataByUserFieldGroupLazyQuery>;
export type GetSensingLayerDataByUserFieldGroupQueryResult = Apollo.QueryResult<GetSensingLayerDataByUserFieldGroupQuery, GetSensingLayerDataByUserFieldGroupQueryVariables>;
export const GetSensingRegisteredDateListDocument = gql`
    query GetSensingRegisteredDateList($user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000", $sensing_type_key: String! = "") {
  nb_ufield(
    where: {valid_end: {_gte: "now()"}, nb_ufg_links: {nb_ufield_group: {valid_end: {_gte: "now()"}, id: {_eq: $user_field_group_id}}}}
  ) {
    id
    nb_raster_image_sensings(
      where: {nb_image_sensing_attr: {key: {_eq: $sensing_type_key}}}
      order_by: {datetime: asc}
    ) {
      datetime
    }
  }
}
    `;

/**
 * __useGetSensingRegisteredDateListQuery__
 *
 * To run a query within a React component, call `useGetSensingRegisteredDateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensingRegisteredDateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensingRegisteredDateListQuery({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *      sensing_type_key: // value for 'sensing_type_key'
 *   },
 * });
 */
export function useGetSensingRegisteredDateListQuery(baseOptions?: Apollo.QueryHookOptions<GetSensingRegisteredDateListQuery, GetSensingRegisteredDateListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSensingRegisteredDateListQuery, GetSensingRegisteredDateListQueryVariables>(GetSensingRegisteredDateListDocument, options);
      }
export function useGetSensingRegisteredDateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSensingRegisteredDateListQuery, GetSensingRegisteredDateListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSensingRegisteredDateListQuery, GetSensingRegisteredDateListQueryVariables>(GetSensingRegisteredDateListDocument, options);
        }
export type GetSensingRegisteredDateListQueryHookResult = ReturnType<typeof useGetSensingRegisteredDateListQuery>;
export type GetSensingRegisteredDateListLazyQueryHookResult = ReturnType<typeof useGetSensingRegisteredDateListLazyQuery>;
export type GetSensingRegisteredDateListQueryResult = Apollo.QueryResult<GetSensingRegisteredDateListQuery, GetSensingRegisteredDateListQueryVariables>;
export const GetFieldPictureMemosDocument = gql`
    query GetFieldPictureMemos($field_picture_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_field_memo(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, nb_memo_picture_links: {nb_field_picture: {id: {_eq: $field_picture_id}}}}
    order_by: {updated: desc}
  ) {
    ...FieldMemo
  }
}
    ${FieldMemoFragmentDoc}`;

/**
 * __useGetFieldPictureMemosQuery__
 *
 * To run a query within a React component, call `useGetFieldPictureMemosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldPictureMemosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFieldPictureMemosQuery({
 *   variables: {
 *      field_picture_id: // value for 'field_picture_id'
 *   },
 * });
 */
export function useGetFieldPictureMemosQuery(baseOptions?: Apollo.QueryHookOptions<GetFieldPictureMemosQuery, GetFieldPictureMemosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFieldPictureMemosQuery, GetFieldPictureMemosQueryVariables>(GetFieldPictureMemosDocument, options);
      }
export function useGetFieldPictureMemosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFieldPictureMemosQuery, GetFieldPictureMemosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFieldPictureMemosQuery, GetFieldPictureMemosQueryVariables>(GetFieldPictureMemosDocument, options);
        }
export type GetFieldPictureMemosQueryHookResult = ReturnType<typeof useGetFieldPictureMemosQuery>;
export type GetFieldPictureMemosLazyQueryHookResult = ReturnType<typeof useGetFieldPictureMemosLazyQuery>;
export type GetFieldPictureMemosQueryResult = Apollo.QueryResult<GetFieldPictureMemosQuery, GetFieldPictureMemosQueryVariables>;
export const GetFieldFileMemosDocument = gql`
    query GetFieldFileMemos($field_file_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_field_memo(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, nb_memo_file_links: {nb_field_file: {id: {_eq: $field_file_id}}}}
    order_by: {updated: desc}
  ) {
    ...FieldMemo
  }
}
    ${FieldMemoFragmentDoc}`;

/**
 * __useGetFieldFileMemosQuery__
 *
 * To run a query within a React component, call `useGetFieldFileMemosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldFileMemosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFieldFileMemosQuery({
 *   variables: {
 *      field_file_id: // value for 'field_file_id'
 *   },
 * });
 */
export function useGetFieldFileMemosQuery(baseOptions?: Apollo.QueryHookOptions<GetFieldFileMemosQuery, GetFieldFileMemosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFieldFileMemosQuery, GetFieldFileMemosQueryVariables>(GetFieldFileMemosDocument, options);
      }
export function useGetFieldFileMemosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFieldFileMemosQuery, GetFieldFileMemosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFieldFileMemosQuery, GetFieldFileMemosQueryVariables>(GetFieldFileMemosDocument, options);
        }
export type GetFieldFileMemosQueryHookResult = ReturnType<typeof useGetFieldFileMemosQuery>;
export type GetFieldFileMemosLazyQueryHookResult = ReturnType<typeof useGetFieldFileMemosLazyQuery>;
export type GetFieldFileMemosQueryResult = Apollo.QueryResult<GetFieldFileMemosQuery, GetFieldFileMemosQueryVariables>;
export const GetUserFieldGroupMemosDocument = gql`
    query GetUserFieldGroupMemos($user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_field_memo(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, nb_memo_ufg_links: {nb_ufield_group: {id: {_eq: $user_field_group_id}}}}
    order_by: {updated: desc}
  ) {
    ...FieldMemo
  }
}
    ${FieldMemoFragmentDoc}`;

/**
 * __useGetUserFieldGroupMemosQuery__
 *
 * To run a query within a React component, call `useGetUserFieldGroupMemosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldGroupMemosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldGroupMemosQuery({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *   },
 * });
 */
export function useGetUserFieldGroupMemosQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldGroupMemosQuery, GetUserFieldGroupMemosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldGroupMemosQuery, GetUserFieldGroupMemosQueryVariables>(GetUserFieldGroupMemosDocument, options);
      }
export function useGetUserFieldGroupMemosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldGroupMemosQuery, GetUserFieldGroupMemosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldGroupMemosQuery, GetUserFieldGroupMemosQueryVariables>(GetUserFieldGroupMemosDocument, options);
        }
export type GetUserFieldGroupMemosQueryHookResult = ReturnType<typeof useGetUserFieldGroupMemosQuery>;
export type GetUserFieldGroupMemosLazyQueryHookResult = ReturnType<typeof useGetUserFieldGroupMemosLazyQuery>;
export type GetUserFieldGroupMemosQueryResult = Apollo.QueryResult<GetUserFieldGroupMemosQuery, GetUserFieldGroupMemosQueryVariables>;
export const InsertFieldMemoDocument = gql`
    mutation InsertFieldMemo($objects: [nb_field_memo_insert_input!] = {}) {
  insert_nb_field_memo(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertFieldMemoMutationFn = Apollo.MutationFunction<InsertFieldMemoMutation, InsertFieldMemoMutationVariables>;

/**
 * __useInsertFieldMemoMutation__
 *
 * To run a mutation, you first call `useInsertFieldMemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFieldMemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFieldMemoMutation, { data, loading, error }] = useInsertFieldMemoMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertFieldMemoMutation(baseOptions?: Apollo.MutationHookOptions<InsertFieldMemoMutation, InsertFieldMemoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFieldMemoMutation, InsertFieldMemoMutationVariables>(InsertFieldMemoDocument, options);
      }
export type InsertFieldMemoMutationHookResult = ReturnType<typeof useInsertFieldMemoMutation>;
export type InsertFieldMemoMutationResult = Apollo.MutationResult<InsertFieldMemoMutation>;
export type InsertFieldMemoMutationOptions = Apollo.BaseMutationOptions<InsertFieldMemoMutation, InsertFieldMemoMutationVariables>;
export const UpdateFieldMemoDocument = gql`
    mutation UpdateFieldMemo($id: uuid = "00000000-0000-0000-0000-000000000000", $memo: String!) {
  update_nb_field_memo(where: {id: {_eq: $id}}, _set: {memo: $memo}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateFieldMemoMutationFn = Apollo.MutationFunction<UpdateFieldMemoMutation, UpdateFieldMemoMutationVariables>;

/**
 * __useUpdateFieldMemoMutation__
 *
 * To run a mutation, you first call `useUpdateFieldMemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldMemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldMemoMutation, { data, loading, error }] = useUpdateFieldMemoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      memo: // value for 'memo'
 *   },
 * });
 */
export function useUpdateFieldMemoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFieldMemoMutation, UpdateFieldMemoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFieldMemoMutation, UpdateFieldMemoMutationVariables>(UpdateFieldMemoDocument, options);
      }
export type UpdateFieldMemoMutationHookResult = ReturnType<typeof useUpdateFieldMemoMutation>;
export type UpdateFieldMemoMutationResult = Apollo.MutationResult<UpdateFieldMemoMutation>;
export type UpdateFieldMemoMutationOptions = Apollo.BaseMutationOptions<UpdateFieldMemoMutation, UpdateFieldMemoMutationVariables>;
export const DeleteFieldMemoDocument = gql`
    mutation DeleteFieldMemo($id: uuid = "00000000-0000-0000-0000-000000000000") {
  update_nb_field_memo(where: {id: {_eq: $id}}, _set: {valid_end: "now()"}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteFieldMemoMutationFn = Apollo.MutationFunction<DeleteFieldMemoMutation, DeleteFieldMemoMutationVariables>;

/**
 * __useDeleteFieldMemoMutation__
 *
 * To run a mutation, you first call `useDeleteFieldMemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFieldMemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFieldMemoMutation, { data, loading, error }] = useDeleteFieldMemoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFieldMemoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFieldMemoMutation, DeleteFieldMemoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFieldMemoMutation, DeleteFieldMemoMutationVariables>(DeleteFieldMemoDocument, options);
      }
export type DeleteFieldMemoMutationHookResult = ReturnType<typeof useDeleteFieldMemoMutation>;
export type DeleteFieldMemoMutationResult = Apollo.MutationResult<DeleteFieldMemoMutation>;
export type DeleteFieldMemoMutationOptions = Apollo.BaseMutationOptions<DeleteFieldMemoMutation, DeleteFieldMemoMutationVariables>;
export const GetUserFieldPolygonFieldDocument = gql`
    query GetUserFieldPolygonField($user_field_id: uuid!) {
  nb_ufield(where: {id: {_eq: $user_field_id}}) {
    id
    polygon_field
  }
}
    `;

/**
 * __useGetUserFieldPolygonFieldQuery__
 *
 * To run a query within a React component, call `useGetUserFieldPolygonFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldPolygonFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldPolygonFieldQuery({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *   },
 * });
 */
export function useGetUserFieldPolygonFieldQuery(baseOptions: Apollo.QueryHookOptions<GetUserFieldPolygonFieldQuery, GetUserFieldPolygonFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldPolygonFieldQuery, GetUserFieldPolygonFieldQueryVariables>(GetUserFieldPolygonFieldDocument, options);
      }
export function useGetUserFieldPolygonFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldPolygonFieldQuery, GetUserFieldPolygonFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldPolygonFieldQuery, GetUserFieldPolygonFieldQueryVariables>(GetUserFieldPolygonFieldDocument, options);
        }
export type GetUserFieldPolygonFieldQueryHookResult = ReturnType<typeof useGetUserFieldPolygonFieldQuery>;
export type GetUserFieldPolygonFieldLazyQueryHookResult = ReturnType<typeof useGetUserFieldPolygonFieldLazyQuery>;
export type GetUserFieldPolygonFieldQueryResult = Apollo.QueryResult<GetUserFieldPolygonFieldQuery, GetUserFieldPolygonFieldQueryVariables>;
export const GetUserAttributeListDocument = gql`
    query GetUserAttributeList {
  nbpm_user_attribute {
    id
    attribute
    attribute_name
  }
}
    `;

/**
 * __useGetUserAttributeListQuery__
 *
 * To run a query within a React component, call `useGetUserAttributeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAttributeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAttributeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAttributeListQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAttributeListQuery, GetUserAttributeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAttributeListQuery, GetUserAttributeListQueryVariables>(GetUserAttributeListDocument, options);
      }
export function useGetUserAttributeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAttributeListQuery, GetUserAttributeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAttributeListQuery, GetUserAttributeListQueryVariables>(GetUserAttributeListDocument, options);
        }
export type GetUserAttributeListQueryHookResult = ReturnType<typeof useGetUserAttributeListQuery>;
export type GetUserAttributeListLazyQueryHookResult = ReturnType<typeof useGetUserAttributeListLazyQuery>;
export type GetUserAttributeListQueryResult = Apollo.QueryResult<GetUserAttributeListQuery, GetUserAttributeListQueryVariables>;
export const GetMemberListDocument = gql`
    query GetMemberList($company_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_company(where: {id: {_eq: $company_id}}) {
    nbpm_user_attr_links(
      where: {valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}
    ) {
      app_user_active {
        id
        name
        nickname
      }
      nbpm_user_attribute {
        id
        attribute
        attribute_name
      }
      nb_ufg_member_links(
        where: {nb_ufield_group: {valid_end: {_gt: "now()"}, valid_start: {_lt: "now()"}}}
      ) {
        nb_ufield_group {
          id
          name
        }
      }
    }
    application_user_invitation_to_companies(where: {status: {_eq: "PENDING"}}) {
      id
      app_user_active {
        id
        name
        nickname
      }
      nb_ufield_group {
        id
        name
      }
      nbpm_user_attribute {
        id
        attribute
        attribute_name
      }
      status
    }
  }
}
    `;

/**
 * __useGetMemberListQuery__
 *
 * To run a query within a React component, call `useGetMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberListQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetMemberListQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberListQuery, GetMemberListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberListQuery, GetMemberListQueryVariables>(GetMemberListDocument, options);
      }
export function useGetMemberListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberListQuery, GetMemberListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberListQuery, GetMemberListQueryVariables>(GetMemberListDocument, options);
        }
export type GetMemberListQueryHookResult = ReturnType<typeof useGetMemberListQuery>;
export type GetMemberListLazyQueryHookResult = ReturnType<typeof useGetMemberListLazyQuery>;
export type GetMemberListQueryResult = Apollo.QueryResult<GetMemberListQuery, GetMemberListQueryVariables>;
export const GetUserInvitationsDocument = gql`
    query GetUserInvitations($app_user_active_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  application_user_invitation_to_company(
    where: {app_user_active_id: {_eq: $app_user_active_id}, status: {_eq: "PENDING"}}
  ) {
    id
    company_name
  }
}
    `;

/**
 * __useGetUserInvitationsQuery__
 *
 * To run a query within a React component, call `useGetUserInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInvitationsQuery({
 *   variables: {
 *      app_user_active_id: // value for 'app_user_active_id'
 *   },
 * });
 */
export function useGetUserInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserInvitationsQuery, GetUserInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInvitationsQuery, GetUserInvitationsQueryVariables>(GetUserInvitationsDocument, options);
      }
export function useGetUserInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInvitationsQuery, GetUserInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInvitationsQuery, GetUserInvitationsQueryVariables>(GetUserInvitationsDocument, options);
        }
export type GetUserInvitationsQueryHookResult = ReturnType<typeof useGetUserInvitationsQuery>;
export type GetUserInvitationsLazyQueryHookResult = ReturnType<typeof useGetUserInvitationsLazyQuery>;
export type GetUserInvitationsQueryResult = Apollo.QueryResult<GetUserInvitationsQuery, GetUserInvitationsQueryVariables>;
export const GetUserFieldNameDocument = gql`
    query GetUserFieldName($user_field_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_ufield(where: {id: {_eq: $user_field_id}}) {
    id
    field_name
  }
}
    `;

/**
 * __useGetUserFieldNameQuery__
 *
 * To run a query within a React component, call `useGetUserFieldNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldNameQuery({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *   },
 * });
 */
export function useGetUserFieldNameQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldNameQuery, GetUserFieldNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldNameQuery, GetUserFieldNameQueryVariables>(GetUserFieldNameDocument, options);
      }
export function useGetUserFieldNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldNameQuery, GetUserFieldNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldNameQuery, GetUserFieldNameQueryVariables>(GetUserFieldNameDocument, options);
        }
export type GetUserFieldNameQueryHookResult = ReturnType<typeof useGetUserFieldNameQuery>;
export type GetUserFieldNameLazyQueryHookResult = ReturnType<typeof useGetUserFieldNameLazyQuery>;
export type GetUserFieldNameQueryResult = Apollo.QueryResult<GetUserFieldNameQuery, GetUserFieldNameQueryVariables>;
export const GetSoilCharcteristicDocument = gql`
    query GetSoilCharcteristic {
  nb_soil_characteristic {
    id
    small_code
    small_name
    color_code
  }
}
    `;

/**
 * __useGetSoilCharcteristicQuery__
 *
 * To run a query within a React component, call `useGetSoilCharcteristicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoilCharcteristicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoilCharcteristicQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSoilCharcteristicQuery(baseOptions?: Apollo.QueryHookOptions<GetSoilCharcteristicQuery, GetSoilCharcteristicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSoilCharcteristicQuery, GetSoilCharcteristicQueryVariables>(GetSoilCharcteristicDocument, options);
      }
export function useGetSoilCharcteristicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSoilCharcteristicQuery, GetSoilCharcteristicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSoilCharcteristicQuery, GetSoilCharcteristicQueryVariables>(GetSoilCharcteristicDocument, options);
        }
export type GetSoilCharcteristicQueryHookResult = ReturnType<typeof useGetSoilCharcteristicQuery>;
export type GetSoilCharcteristicLazyQueryHookResult = ReturnType<typeof useGetSoilCharcteristicLazyQuery>;
export type GetSoilCharcteristicQueryResult = Apollo.QueryResult<GetSoilCharcteristicQuery, GetSoilCharcteristicQueryVariables>;
export const GetUserFieldPolygonFieldByUserFieldIdDocument = gql`
    query GetUserFieldPolygonFieldByUserFieldId($user_field_id: uuid!) {
  nb_ufield(where: {id: {_eq: $user_field_id}}) {
    polygon_field
  }
}
    `;

/**
 * __useGetUserFieldPolygonFieldByUserFieldIdQuery__
 *
 * To run a query within a React component, call `useGetUserFieldPolygonFieldByUserFieldIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldPolygonFieldByUserFieldIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldPolygonFieldByUserFieldIdQuery({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *   },
 * });
 */
export function useGetUserFieldPolygonFieldByUserFieldIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserFieldPolygonFieldByUserFieldIdQuery, GetUserFieldPolygonFieldByUserFieldIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldPolygonFieldByUserFieldIdQuery, GetUserFieldPolygonFieldByUserFieldIdQueryVariables>(GetUserFieldPolygonFieldByUserFieldIdDocument, options);
      }
export function useGetUserFieldPolygonFieldByUserFieldIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldPolygonFieldByUserFieldIdQuery, GetUserFieldPolygonFieldByUserFieldIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldPolygonFieldByUserFieldIdQuery, GetUserFieldPolygonFieldByUserFieldIdQueryVariables>(GetUserFieldPolygonFieldByUserFieldIdDocument, options);
        }
export type GetUserFieldPolygonFieldByUserFieldIdQueryHookResult = ReturnType<typeof useGetUserFieldPolygonFieldByUserFieldIdQuery>;
export type GetUserFieldPolygonFieldByUserFieldIdLazyQueryHookResult = ReturnType<typeof useGetUserFieldPolygonFieldByUserFieldIdLazyQuery>;
export type GetUserFieldPolygonFieldByUserFieldIdQueryResult = Apollo.QueryResult<GetUserFieldPolygonFieldByUserFieldIdQuery, GetUserFieldPolygonFieldByUserFieldIdQueryVariables>;
export const GetSoilChemistryStandardListDocument = gql`
    query GetSoilChemistryStandardList($company_id: uuid! = "00000000-0000-0000-0000-000000000000", $user_field_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_soil_chemistry_standards(
    where: {_or: [{company_id: {_eq: $company_id}}, {company_id: {_is_null: true}}]}
    order_by: {company_id: asc_nulls_last}
  ) {
    id
    name
    nb_soil_chemistry_proper_ranges(order_by: {order: asc}) {
      id
      name
      upper
      lower
      unit
    }
    nb_soil_chemistries(
      where: {valid_end: {_gte: "now()"}, user_field_id: {_eq: $user_field_id}}
    ) {
      id
    }
  }
}
    `;

/**
 * __useGetSoilChemistryStandardListQuery__
 *
 * To run a query within a React component, call `useGetSoilChemistryStandardListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoilChemistryStandardListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoilChemistryStandardListQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      user_field_id: // value for 'user_field_id'
 *   },
 * });
 */
export function useGetSoilChemistryStandardListQuery(baseOptions?: Apollo.QueryHookOptions<GetSoilChemistryStandardListQuery, GetSoilChemistryStandardListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSoilChemistryStandardListQuery, GetSoilChemistryStandardListQueryVariables>(GetSoilChemistryStandardListDocument, options);
      }
export function useGetSoilChemistryStandardListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSoilChemistryStandardListQuery, GetSoilChemistryStandardListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSoilChemistryStandardListQuery, GetSoilChemistryStandardListQueryVariables>(GetSoilChemistryStandardListDocument, options);
        }
export type GetSoilChemistryStandardListQueryHookResult = ReturnType<typeof useGetSoilChemistryStandardListQuery>;
export type GetSoilChemistryStandardListLazyQueryHookResult = ReturnType<typeof useGetSoilChemistryStandardListLazyQuery>;
export type GetSoilChemistryStandardListQueryResult = Apollo.QueryResult<GetSoilChemistryStandardListQuery, GetSoilChemistryStandardListQueryVariables>;
export const GetUserFieldsHasSoilReportDocument = gql`
    query GetUserFieldsHasSoilReport($user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_ufield(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, nb_ufg_links: {nb_ufield_group: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, id: {_eq: $user_field_group_id}}}, nb_soil_chemistries: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}}}
  ) {
    id
    polygon_field
    nb_soil_chemistries(order_by: {sampling_date: desc}) {
      sampling_date
    }
  }
}
    `;

/**
 * __useGetUserFieldsHasSoilReportQuery__
 *
 * To run a query within a React component, call `useGetUserFieldsHasSoilReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldsHasSoilReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldsHasSoilReportQuery({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *   },
 * });
 */
export function useGetUserFieldsHasSoilReportQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldsHasSoilReportQuery, GetUserFieldsHasSoilReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldsHasSoilReportQuery, GetUserFieldsHasSoilReportQueryVariables>(GetUserFieldsHasSoilReportDocument, options);
      }
export function useGetUserFieldsHasSoilReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldsHasSoilReportQuery, GetUserFieldsHasSoilReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldsHasSoilReportQuery, GetUserFieldsHasSoilReportQueryVariables>(GetUserFieldsHasSoilReportDocument, options);
        }
export type GetUserFieldsHasSoilReportQueryHookResult = ReturnType<typeof useGetUserFieldsHasSoilReportQuery>;
export type GetUserFieldsHasSoilReportLazyQueryHookResult = ReturnType<typeof useGetUserFieldsHasSoilReportLazyQuery>;
export type GetUserFieldsHasSoilReportQueryResult = Apollo.QueryResult<GetUserFieldsHasSoilReportQuery, GetUserFieldsHasSoilReportQueryVariables>;
export const GetSoilReportListDocument = gql`
    query GetSoilReportList($user_field_id: uuid! = "00000000-0000-0000-0000-000000000000", $soil_chemistry_standard_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_soil_chemistry(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, user_field_id: {_eq: $user_field_id}, soil_chemistry_standard_id: {_eq: $soil_chemistry_standard_id}}
    order_by: {sampling_date: desc, created: desc}
    limit: 10
  ) {
    id
    sampling_date
    comment
    nb_soil_chemistry_values(
      order_by: {nb_soil_chemistry_proper_range: {order: asc}}
    ) {
      id
      value
      nb_soil_chemistry_proper_range {
        name
        upper
        lower
        unit
      }
    }
  }
}
    `;

/**
 * __useGetSoilReportListQuery__
 *
 * To run a query within a React component, call `useGetSoilReportListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoilReportListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoilReportListQuery({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *      soil_chemistry_standard_id: // value for 'soil_chemistry_standard_id'
 *   },
 * });
 */
export function useGetSoilReportListQuery(baseOptions?: Apollo.QueryHookOptions<GetSoilReportListQuery, GetSoilReportListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSoilReportListQuery, GetSoilReportListQueryVariables>(GetSoilReportListDocument, options);
      }
export function useGetSoilReportListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSoilReportListQuery, GetSoilReportListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSoilReportListQuery, GetSoilReportListQueryVariables>(GetSoilReportListDocument, options);
        }
export type GetSoilReportListQueryHookResult = ReturnType<typeof useGetSoilReportListQuery>;
export type GetSoilReportListLazyQueryHookResult = ReturnType<typeof useGetSoilReportListLazyQuery>;
export type GetSoilReportListQueryResult = Apollo.QueryResult<GetSoilReportListQuery, GetSoilReportListQueryVariables>;
export const GetUserFieldAttributeListDocument = gql`
    query GetUserFieldAttributeList($company_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_ufield_attribute(where: {company_id: {_eq: $company_id}}) {
    id
    name
    created
    nb_ufattr_links(
      where: {nb_ufield: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}}}
    ) {
      nb_ufield {
        id
        nb_ufg_links {
          nb_ufield_group {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserFieldAttributeListQuery__
 *
 * To run a query within a React component, call `useGetUserFieldAttributeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldAttributeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldAttributeListQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetUserFieldAttributeListQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFieldAttributeListQuery, GetUserFieldAttributeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldAttributeListQuery, GetUserFieldAttributeListQueryVariables>(GetUserFieldAttributeListDocument, options);
      }
export function useGetUserFieldAttributeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldAttributeListQuery, GetUserFieldAttributeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldAttributeListQuery, GetUserFieldAttributeListQueryVariables>(GetUserFieldAttributeListDocument, options);
        }
export type GetUserFieldAttributeListQueryHookResult = ReturnType<typeof useGetUserFieldAttributeListQuery>;
export type GetUserFieldAttributeListLazyQueryHookResult = ReturnType<typeof useGetUserFieldAttributeListLazyQuery>;
export type GetUserFieldAttributeListQueryResult = Apollo.QueryResult<GetUserFieldAttributeListQuery, GetUserFieldAttributeListQueryVariables>;
export const InsertUserFieldAttributeDocument = gql`
    mutation InsertUserFieldAttribute($objects: [nb_ufield_attribute_insert_input!]! = {}) {
  insert_nb_ufield_attribute(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertUserFieldAttributeMutationFn = Apollo.MutationFunction<InsertUserFieldAttributeMutation, InsertUserFieldAttributeMutationVariables>;

/**
 * __useInsertUserFieldAttributeMutation__
 *
 * To run a mutation, you first call `useInsertUserFieldAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserFieldAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserFieldAttributeMutation, { data, loading, error }] = useInsertUserFieldAttributeMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertUserFieldAttributeMutation(baseOptions?: Apollo.MutationHookOptions<InsertUserFieldAttributeMutation, InsertUserFieldAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUserFieldAttributeMutation, InsertUserFieldAttributeMutationVariables>(InsertUserFieldAttributeDocument, options);
      }
export type InsertUserFieldAttributeMutationHookResult = ReturnType<typeof useInsertUserFieldAttributeMutation>;
export type InsertUserFieldAttributeMutationResult = Apollo.MutationResult<InsertUserFieldAttributeMutation>;
export type InsertUserFieldAttributeMutationOptions = Apollo.BaseMutationOptions<InsertUserFieldAttributeMutation, InsertUserFieldAttributeMutationVariables>;
export const UpdateUserFieldAttributeDocument = gql`
    mutation UpdateUserFieldAttribute($user_field_attribute_id: uuid! = "00000000-0000-0000-0000-000000000000", $object: nb_ufield_attribute_set_input! = {}) {
  update_nb_ufield_attribute(
    where: {id: {_eq: $user_field_attribute_id}}
    _set: $object
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateUserFieldAttributeMutationFn = Apollo.MutationFunction<UpdateUserFieldAttributeMutation, UpdateUserFieldAttributeMutationVariables>;

/**
 * __useUpdateUserFieldAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateUserFieldAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFieldAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFieldAttributeMutation, { data, loading, error }] = useUpdateUserFieldAttributeMutation({
 *   variables: {
 *      user_field_attribute_id: // value for 'user_field_attribute_id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateUserFieldAttributeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserFieldAttributeMutation, UpdateUserFieldAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserFieldAttributeMutation, UpdateUserFieldAttributeMutationVariables>(UpdateUserFieldAttributeDocument, options);
      }
export type UpdateUserFieldAttributeMutationHookResult = ReturnType<typeof useUpdateUserFieldAttributeMutation>;
export type UpdateUserFieldAttributeMutationResult = Apollo.MutationResult<UpdateUserFieldAttributeMutation>;
export type UpdateUserFieldAttributeMutationOptions = Apollo.BaseMutationOptions<UpdateUserFieldAttributeMutation, UpdateUserFieldAttributeMutationVariables>;
export const GetOrthoimgTypesDocument = gql`
    query GetOrthoimgTypes {
  nbbe_orthoimg_type {
    value
    name
  }
}
    `;

/**
 * __useGetOrthoimgTypesQuery__
 *
 * To run a query within a React component, call `useGetOrthoimgTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrthoimgTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrthoimgTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrthoimgTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetOrthoimgTypesQuery, GetOrthoimgTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrthoimgTypesQuery, GetOrthoimgTypesQueryVariables>(GetOrthoimgTypesDocument, options);
      }
export function useGetOrthoimgTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrthoimgTypesQuery, GetOrthoimgTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrthoimgTypesQuery, GetOrthoimgTypesQueryVariables>(GetOrthoimgTypesDocument, options);
        }
export type GetOrthoimgTypesQueryHookResult = ReturnType<typeof useGetOrthoimgTypesQuery>;
export type GetOrthoimgTypesLazyQueryHookResult = ReturnType<typeof useGetOrthoimgTypesLazyQuery>;
export type GetOrthoimgTypesQueryResult = Apollo.QueryResult<GetOrthoimgTypesQuery, GetOrthoimgTypesQueryVariables>;
export const GetUploadedWideOrthoimgListDocument = gql`
    query GetUploadedWideOrthoimgList($upload_user_id: uuid! = "00000000-0000-0000-0000-000000000000", $company_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nbs_wide_orthoimg_load(
    where: {status: {_in: ["PROCESSING", "LOADED", "FAILURE"]}, upload_user_id: {_eq: $upload_user_id}, nb_company: {id: {_eq: $company_id}}}
    order_by: {sens_datetime: desc}
  ) {
    sens_datetime
    proc_datetime
    s3uri
    status
    nb_wide_orthoimg {
      nb_field_orthoimgs {
        nb_ufield {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetUploadedWideOrthoimgListQuery__
 *
 * To run a query within a React component, call `useGetUploadedWideOrthoimgListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadedWideOrthoimgListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadedWideOrthoimgListQuery({
 *   variables: {
 *      upload_user_id: // value for 'upload_user_id'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetUploadedWideOrthoimgListQuery(baseOptions?: Apollo.QueryHookOptions<GetUploadedWideOrthoimgListQuery, GetUploadedWideOrthoimgListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadedWideOrthoimgListQuery, GetUploadedWideOrthoimgListQueryVariables>(GetUploadedWideOrthoimgListDocument, options);
      }
export function useGetUploadedWideOrthoimgListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadedWideOrthoimgListQuery, GetUploadedWideOrthoimgListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadedWideOrthoimgListQuery, GetUploadedWideOrthoimgListQueryVariables>(GetUploadedWideOrthoimgListDocument, options);
        }
export type GetUploadedWideOrthoimgListQueryHookResult = ReturnType<typeof useGetUploadedWideOrthoimgListQuery>;
export type GetUploadedWideOrthoimgListLazyQueryHookResult = ReturnType<typeof useGetUploadedWideOrthoimgListLazyQuery>;
export type GetUploadedWideOrthoimgListQueryResult = Apollo.QueryResult<GetUploadedWideOrthoimgListQuery, GetUploadedWideOrthoimgListQueryVariables>;
export const GetWideOrthoimgUserFieldsDocument = gql`
    query GetWideOrthoimgUserFields($user_field_ids: [uuid!]! = ["00000000-0000-0000-0000-000000000000"]) {
  nb_ufield(where: {id: {_in: $user_field_ids}}) {
    id
    field_name
    polygon_field
    nb_ufg_links {
      nb_ufield_group {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetWideOrthoimgUserFieldsQuery__
 *
 * To run a query within a React component, call `useGetWideOrthoimgUserFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWideOrthoimgUserFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWideOrthoimgUserFieldsQuery({
 *   variables: {
 *      user_field_ids: // value for 'user_field_ids'
 *   },
 * });
 */
export function useGetWideOrthoimgUserFieldsQuery(baseOptions?: Apollo.QueryHookOptions<GetWideOrthoimgUserFieldsQuery, GetWideOrthoimgUserFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWideOrthoimgUserFieldsQuery, GetWideOrthoimgUserFieldsQueryVariables>(GetWideOrthoimgUserFieldsDocument, options);
      }
export function useGetWideOrthoimgUserFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWideOrthoimgUserFieldsQuery, GetWideOrthoimgUserFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWideOrthoimgUserFieldsQuery, GetWideOrthoimgUserFieldsQueryVariables>(GetWideOrthoimgUserFieldsDocument, options);
        }
export type GetWideOrthoimgUserFieldsQueryHookResult = ReturnType<typeof useGetWideOrthoimgUserFieldsQuery>;
export type GetWideOrthoimgUserFieldsLazyQueryHookResult = ReturnType<typeof useGetWideOrthoimgUserFieldsLazyQuery>;
export type GetWideOrthoimgUserFieldsQueryResult = Apollo.QueryResult<GetWideOrthoimgUserFieldsQuery, GetWideOrthoimgUserFieldsQueryVariables>;
export const GetAnalysisContentsDocument = gql`
    query GetAnalysisContents($company_id: uuid! = "00000000-0000-0000-0000-000000000000", $upload_user_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nipe_engine_information(
    where: {nbpm_ipengine_authorization_links: {nbpm_authorization: {nbpm_contract_plan_links: {nbpm_contract_plan: {nb_company: {id: {_eq: $company_id}}}}}}}
  ) {
    id
    name
    nipe_engine_link_orthoimg_types {
      id
      orthoimg_type
      nbbe_orthoimg_type {
        name
      }
    }
  }
  nb_wide_orthoimg(
    where: {company_id: {_eq: $company_id}, upload_user_id: {_eq: $upload_user_id}, nbs_wide_orthoimg_loads: {status: {_eq: "LOADED"}}}
    order_by: {orthoimg_type: asc}
  ) {
    id
    orthoimg_type
    s3uri
    nbs_wide_orthoimg_loads {
      proc_datetime
      sens_datetime
    }
  }
}
    `;

/**
 * __useGetAnalysisContentsQuery__
 *
 * To run a query within a React component, call `useGetAnalysisContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalysisContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalysisContentsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      upload_user_id: // value for 'upload_user_id'
 *   },
 * });
 */
export function useGetAnalysisContentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAnalysisContentsQuery, GetAnalysisContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalysisContentsQuery, GetAnalysisContentsQueryVariables>(GetAnalysisContentsDocument, options);
      }
export function useGetAnalysisContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalysisContentsQuery, GetAnalysisContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalysisContentsQuery, GetAnalysisContentsQueryVariables>(GetAnalysisContentsDocument, options);
        }
export type GetAnalysisContentsQueryHookResult = ReturnType<typeof useGetAnalysisContentsQuery>;
export type GetAnalysisContentsLazyQueryHookResult = ReturnType<typeof useGetAnalysisContentsLazyQuery>;
export type GetAnalysisContentsQueryResult = Apollo.QueryResult<GetAnalysisContentsQuery, GetAnalysisContentsQueryVariables>;
export const GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdDocument = gql`
    query GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyId($requested_user_id: uuid! = "00000000-0000-0000-0000-000000000000", $company_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nbs_ipe_run_wide(
    where: {requested_user_id: {_eq: $requested_user_id}, company_id: {_eq: $company_id}}
  ) {
    created
    nipe_engine_information {
      name
    }
    requested: nbs_ipe_run_fields(
      where: {nipe_run_command: {status: {_eq: "REQUESTED"}}}
    ) {
      nipe_run_command {
        status
      }
    }
    running: nbs_ipe_run_fields(
      where: {nipe_run_command: {status: {_eq: "RUNNING"}}}
    ) {
      nipe_run_command {
        status
      }
    }
    success: nbs_ipe_run_fields(
      where: {nipe_run_command: {status: {_eq: "SUCCESS"}}}
    ) {
      nipe_run_command {
        status
      }
    }
    error: nbs_ipe_run_fields(where: {nipe_run_command: {status: {_eq: "ERROR"}}}) {
      nipe_run_command {
        status
      }
    }
  }
}
    `;

/**
 * __useGetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery({
 *   variables: {
 *      requested_user_id: // value for 'requested_user_id'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery(baseOptions?: Apollo.QueryHookOptions<GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery, GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery, GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQueryVariables>(GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdDocument, options);
      }
export function useGetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery, GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery, GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQueryVariables>(GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdDocument, options);
        }
export type GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQueryHookResult = ReturnType<typeof useGetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery>;
export type GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdLazyQueryHookResult = ReturnType<typeof useGetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdLazyQuery>;
export type GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQueryResult = Apollo.QueryResult<GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQuery, GetWideOrthoimgAnalysisListByRequestedUserIdAndCompanyIdQueryVariables>;
export const GetNbFieldFileListByUserFieldIdDocument = gql`
    query GetNbFieldFileListByUserFieldId($user_field_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_field_file(
    where: {ufield_id: {_eq: $user_field_id}, valid_end: {_gte: "now()"}}
    order_by: {created: desc}
  ) {
    id
    name
    created
  }
}
    `;

/**
 * __useGetNbFieldFileListByUserFieldIdQuery__
 *
 * To run a query within a React component, call `useGetNbFieldFileListByUserFieldIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNbFieldFileListByUserFieldIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNbFieldFileListByUserFieldIdQuery({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *   },
 * });
 */
export function useGetNbFieldFileListByUserFieldIdQuery(baseOptions?: Apollo.QueryHookOptions<GetNbFieldFileListByUserFieldIdQuery, GetNbFieldFileListByUserFieldIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNbFieldFileListByUserFieldIdQuery, GetNbFieldFileListByUserFieldIdQueryVariables>(GetNbFieldFileListByUserFieldIdDocument, options);
      }
export function useGetNbFieldFileListByUserFieldIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNbFieldFileListByUserFieldIdQuery, GetNbFieldFileListByUserFieldIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNbFieldFileListByUserFieldIdQuery, GetNbFieldFileListByUserFieldIdQueryVariables>(GetNbFieldFileListByUserFieldIdDocument, options);
        }
export type GetNbFieldFileListByUserFieldIdQueryHookResult = ReturnType<typeof useGetNbFieldFileListByUserFieldIdQuery>;
export type GetNbFieldFileListByUserFieldIdLazyQueryHookResult = ReturnType<typeof useGetNbFieldFileListByUserFieldIdLazyQuery>;
export type GetNbFieldFileListByUserFieldIdQueryResult = Apollo.QueryResult<GetNbFieldFileListByUserFieldIdQuery, GetNbFieldFileListByUserFieldIdQueryVariables>;
export const GetNbFieldFileListByUserFieldIdAndUploadUserIdDocument = gql`
    query GetNbFieldFileListByUserFieldIdAndUploadUserId($user_field_id: uuid! = "00000000-0000-0000-0000-000000000000", $upload_user_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_field_file(
    where: {ufield_id: {_eq: $user_field_id}, upload_user_id: {_eq: $upload_user_id}, valid_end: {_gte: "now()"}}
    order_by: {created: desc}
  ) {
    id
    name
    created
  }
}
    `;

/**
 * __useGetNbFieldFileListByUserFieldIdAndUploadUserIdQuery__
 *
 * To run a query within a React component, call `useGetNbFieldFileListByUserFieldIdAndUploadUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNbFieldFileListByUserFieldIdAndUploadUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNbFieldFileListByUserFieldIdAndUploadUserIdQuery({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *      upload_user_id: // value for 'upload_user_id'
 *   },
 * });
 */
export function useGetNbFieldFileListByUserFieldIdAndUploadUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetNbFieldFileListByUserFieldIdAndUploadUserIdQuery, GetNbFieldFileListByUserFieldIdAndUploadUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNbFieldFileListByUserFieldIdAndUploadUserIdQuery, GetNbFieldFileListByUserFieldIdAndUploadUserIdQueryVariables>(GetNbFieldFileListByUserFieldIdAndUploadUserIdDocument, options);
      }
export function useGetNbFieldFileListByUserFieldIdAndUploadUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNbFieldFileListByUserFieldIdAndUploadUserIdQuery, GetNbFieldFileListByUserFieldIdAndUploadUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNbFieldFileListByUserFieldIdAndUploadUserIdQuery, GetNbFieldFileListByUserFieldIdAndUploadUserIdQueryVariables>(GetNbFieldFileListByUserFieldIdAndUploadUserIdDocument, options);
        }
export type GetNbFieldFileListByUserFieldIdAndUploadUserIdQueryHookResult = ReturnType<typeof useGetNbFieldFileListByUserFieldIdAndUploadUserIdQuery>;
export type GetNbFieldFileListByUserFieldIdAndUploadUserIdLazyQueryHookResult = ReturnType<typeof useGetNbFieldFileListByUserFieldIdAndUploadUserIdLazyQuery>;
export type GetNbFieldFileListByUserFieldIdAndUploadUserIdQueryResult = Apollo.QueryResult<GetNbFieldFileListByUserFieldIdAndUploadUserIdQuery, GetNbFieldFileListByUserFieldIdAndUploadUserIdQueryVariables>;
export const GetFieldFileDocument = gql`
    query GetFieldFile($field_file_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_field_file(where: {id: {_eq: $field_file_id}}) {
    id
    name
    file_path
  }
}
    `;

/**
 * __useGetFieldFileQuery__
 *
 * To run a query within a React component, call `useGetFieldFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFieldFileQuery({
 *   variables: {
 *      field_file_id: // value for 'field_file_id'
 *   },
 * });
 */
export function useGetFieldFileQuery(baseOptions?: Apollo.QueryHookOptions<GetFieldFileQuery, GetFieldFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFieldFileQuery, GetFieldFileQueryVariables>(GetFieldFileDocument, options);
      }
export function useGetFieldFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFieldFileQuery, GetFieldFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFieldFileQuery, GetFieldFileQueryVariables>(GetFieldFileDocument, options);
        }
export type GetFieldFileQueryHookResult = ReturnType<typeof useGetFieldFileQuery>;
export type GetFieldFileLazyQueryHookResult = ReturnType<typeof useGetFieldFileLazyQuery>;
export type GetFieldFileQueryResult = Apollo.QueryResult<GetFieldFileQuery, GetFieldFileQueryVariables>;
export const UpdateNbFieldFileByIdDocument = gql`
    mutation UpdateNbFieldFileById($id: uuid! = "00000000-0000-0000-0000-000000000000", $input: nb_field_file_set_input! = {}) {
  update_nb_field_file(where: {id: {_eq: $id}}, _set: $input) {
    returning {
      id
    }
  }
}
    `;
export type UpdateNbFieldFileByIdMutationFn = Apollo.MutationFunction<UpdateNbFieldFileByIdMutation, UpdateNbFieldFileByIdMutationVariables>;

/**
 * __useUpdateNbFieldFileByIdMutation__
 *
 * To run a mutation, you first call `useUpdateNbFieldFileByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNbFieldFileByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNbFieldFileByIdMutation, { data, loading, error }] = useUpdateNbFieldFileByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNbFieldFileByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNbFieldFileByIdMutation, UpdateNbFieldFileByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNbFieldFileByIdMutation, UpdateNbFieldFileByIdMutationVariables>(UpdateNbFieldFileByIdDocument, options);
      }
export type UpdateNbFieldFileByIdMutationHookResult = ReturnType<typeof useUpdateNbFieldFileByIdMutation>;
export type UpdateNbFieldFileByIdMutationResult = Apollo.MutationResult<UpdateNbFieldFileByIdMutation>;
export type UpdateNbFieldFileByIdMutationOptions = Apollo.BaseMutationOptions<UpdateNbFieldFileByIdMutation, UpdateNbFieldFileByIdMutationVariables>;
export const DeleteNbFieldFileByIdDocument = gql`
    mutation DeleteNbFieldFileById($id: uuid! = "00000000-0000-0000-0000-000000000000") {
  update_nb_field_file(where: {id: {_eq: $id}}, _set: {valid_end: "now()"}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteNbFieldFileByIdMutationFn = Apollo.MutationFunction<DeleteNbFieldFileByIdMutation, DeleteNbFieldFileByIdMutationVariables>;

/**
 * __useDeleteNbFieldFileByIdMutation__
 *
 * To run a mutation, you first call `useDeleteNbFieldFileByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNbFieldFileByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNbFieldFileByIdMutation, { data, loading, error }] = useDeleteNbFieldFileByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNbFieldFileByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNbFieldFileByIdMutation, DeleteNbFieldFileByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNbFieldFileByIdMutation, DeleteNbFieldFileByIdMutationVariables>(DeleteNbFieldFileByIdDocument, options);
      }
export type DeleteNbFieldFileByIdMutationHookResult = ReturnType<typeof useDeleteNbFieldFileByIdMutation>;
export type DeleteNbFieldFileByIdMutationResult = Apollo.MutationResult<DeleteNbFieldFileByIdMutation>;
export type DeleteNbFieldFileByIdMutationOptions = Apollo.BaseMutationOptions<DeleteNbFieldFileByIdMutation, DeleteNbFieldFileByIdMutationVariables>;
export const GetTaskTypesDocument = gql`
    query GetTaskTypes {
  nb_task_type {
    type
  }
}
    `;

/**
 * __useGetTaskTypesQuery__
 *
 * To run a query within a React component, call `useGetTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaskTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskTypesQuery, GetTaskTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskTypesQuery, GetTaskTypesQueryVariables>(GetTaskTypesDocument, options);
      }
export function useGetTaskTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskTypesQuery, GetTaskTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskTypesQuery, GetTaskTypesQueryVariables>(GetTaskTypesDocument, options);
        }
export type GetTaskTypesQueryHookResult = ReturnType<typeof useGetTaskTypesQuery>;
export type GetTaskTypesLazyQueryHookResult = ReturnType<typeof useGetTaskTypesLazyQuery>;
export type GetTaskTypesQueryResult = Apollo.QueryResult<GetTaskTypesQuery, GetTaskTypesQueryVariables>;
export const GetUsersInCompanyDocument = gql`
    query GetUsersInCompany($company_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_company(where: {id: {_eq: $company_id}}) {
    nbpm_user_attr_links(
      where: {valid_end: {_gte: "now()"}, valid_start: {_lt: "now()"}}
    ) {
      app_user_active {
        id
        name
        nickname
      }
    }
  }
}
    `;

/**
 * __useGetUsersInCompanyQuery__
 *
 * To run a query within a React component, call `useGetUsersInCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersInCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersInCompanyQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetUsersInCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersInCompanyQuery, GetUsersInCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersInCompanyQuery, GetUsersInCompanyQueryVariables>(GetUsersInCompanyDocument, options);
      }
export function useGetUsersInCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersInCompanyQuery, GetUsersInCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersInCompanyQuery, GetUsersInCompanyQueryVariables>(GetUsersInCompanyDocument, options);
        }
export type GetUsersInCompanyQueryHookResult = ReturnType<typeof useGetUsersInCompanyQuery>;
export type GetUsersInCompanyLazyQueryHookResult = ReturnType<typeof useGetUsersInCompanyLazyQuery>;
export type GetUsersInCompanyQueryResult = Apollo.QueryResult<GetUsersInCompanyQuery, GetUsersInCompanyQueryVariables>;
export const GetUserLocationContextsDocument = gql`
    query GetUserLocationContexts($date_from: timestamptz! = "now()", $date_to: timestamptz! = "now()", $task_types: [String!]! = [""], $user_ids: [uuid!]! = ["00000000-0000-0000-0000-000000000000"]) {
  nbtrk_context(
    where: {created: {_gte: $date_from, _lt: $date_to}, task_type: {_in: $task_types}, user_id: {_in: $user_ids}}
  ) {
    id
  }
}
    `;

/**
 * __useGetUserLocationContextsQuery__
 *
 * To run a query within a React component, call `useGetUserLocationContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLocationContextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLocationContextsQuery({
 *   variables: {
 *      date_from: // value for 'date_from'
 *      date_to: // value for 'date_to'
 *      task_types: // value for 'task_types'
 *      user_ids: // value for 'user_ids'
 *   },
 * });
 */
export function useGetUserLocationContextsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserLocationContextsQuery, GetUserLocationContextsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserLocationContextsQuery, GetUserLocationContextsQueryVariables>(GetUserLocationContextsDocument, options);
      }
export function useGetUserLocationContextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserLocationContextsQuery, GetUserLocationContextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserLocationContextsQuery, GetUserLocationContextsQueryVariables>(GetUserLocationContextsDocument, options);
        }
export type GetUserLocationContextsQueryHookResult = ReturnType<typeof useGetUserLocationContextsQuery>;
export type GetUserLocationContextsLazyQueryHookResult = ReturnType<typeof useGetUserLocationContextsLazyQuery>;
export type GetUserLocationContextsQueryResult = Apollo.QueryResult<GetUserLocationContextsQuery, GetUserLocationContextsQueryVariables>;
export const GetUserLocationHistoryDocument = gql`
    query GetUserLocationHistory($context_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nbtrk_context(where: {id: {_eq: $context_id}}) {
    created
    task_type
    nbtrk_eph_locations(order_by: {loc_time: asc}) {
      loc_time
      point_lat
      point_lng
    }
    app_user_active {
      name
    }
    nb_performed_works(
      where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}}
      order_by: [{start_datetime: asc}]
    ) {
      id
      start_datetime
      working_time
      nb_ufield {
        id
        field_name
        polygon_field
      }
    }
  }
}
    `;

/**
 * __useGetUserLocationHistoryQuery__
 *
 * To run a query within a React component, call `useGetUserLocationHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLocationHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLocationHistoryQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetUserLocationHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetUserLocationHistoryQuery, GetUserLocationHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserLocationHistoryQuery, GetUserLocationHistoryQueryVariables>(GetUserLocationHistoryDocument, options);
      }
export function useGetUserLocationHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserLocationHistoryQuery, GetUserLocationHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserLocationHistoryQuery, GetUserLocationHistoryQueryVariables>(GetUserLocationHistoryDocument, options);
        }
export type GetUserLocationHistoryQueryHookResult = ReturnType<typeof useGetUserLocationHistoryQuery>;
export type GetUserLocationHistoryLazyQueryHookResult = ReturnType<typeof useGetUserLocationHistoryLazyQuery>;
export type GetUserLocationHistoryQueryResult = Apollo.QueryResult<GetUserLocationHistoryQuery, GetUserLocationHistoryQueryVariables>;
export const GetUserFieldListForUserLocationHistoryDocument = gql`
    query GetUserFieldListForUserLocationHistory($geometry_object: geometry!) {
  nb_ufield(
    where: {polygon_field: {_st_intersects: $geometry_object}}
    order_by: {field_name: asc}
  ) {
    id
    field_name
    polygon_field
  }
}
    `;

/**
 * __useGetUserFieldListForUserLocationHistoryQuery__
 *
 * To run a query within a React component, call `useGetUserFieldListForUserLocationHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldListForUserLocationHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldListForUserLocationHistoryQuery({
 *   variables: {
 *      geometry_object: // value for 'geometry_object'
 *   },
 * });
 */
export function useGetUserFieldListForUserLocationHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetUserFieldListForUserLocationHistoryQuery, GetUserFieldListForUserLocationHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFieldListForUserLocationHistoryQuery, GetUserFieldListForUserLocationHistoryQueryVariables>(GetUserFieldListForUserLocationHistoryDocument, options);
      }
export function useGetUserFieldListForUserLocationHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldListForUserLocationHistoryQuery, GetUserFieldListForUserLocationHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFieldListForUserLocationHistoryQuery, GetUserFieldListForUserLocationHistoryQueryVariables>(GetUserFieldListForUserLocationHistoryDocument, options);
        }
export type GetUserFieldListForUserLocationHistoryQueryHookResult = ReturnType<typeof useGetUserFieldListForUserLocationHistoryQuery>;
export type GetUserFieldListForUserLocationHistoryLazyQueryHookResult = ReturnType<typeof useGetUserFieldListForUserLocationHistoryLazyQuery>;
export type GetUserFieldListForUserLocationHistoryQueryResult = Apollo.QueryResult<GetUserFieldListForUserLocationHistoryQuery, GetUserFieldListForUserLocationHistoryQueryVariables>;
export const GetMasterFieldsByGeometryDocument = gql`
    query GetMasterFieldsByGeometry($geometry: geometry!) {
  master_nb_mfield(
    where: {valid_end: {_gte: "now()"}, nb_mfield_polygon_2024_1: {geom: {_st_intersects: $geometry}}}
  ) {
    nb_mfield_polygon: nb_mfield_polygon_2024_1 {
      key
      geom
    }
  }
}
    `;

/**
 * __useGetMasterFieldsByGeometryQuery__
 *
 * To run a query within a React component, call `useGetMasterFieldsByGeometryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterFieldsByGeometryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterFieldsByGeometryQuery({
 *   variables: {
 *      geometry: // value for 'geometry'
 *   },
 * });
 */
export function useGetMasterFieldsByGeometryQuery(baseOptions: Apollo.QueryHookOptions<GetMasterFieldsByGeometryQuery, GetMasterFieldsByGeometryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMasterFieldsByGeometryQuery, GetMasterFieldsByGeometryQueryVariables>(GetMasterFieldsByGeometryDocument, options);
      }
export function useGetMasterFieldsByGeometryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMasterFieldsByGeometryQuery, GetMasterFieldsByGeometryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMasterFieldsByGeometryQuery, GetMasterFieldsByGeometryQueryVariables>(GetMasterFieldsByGeometryDocument, options);
        }
export type GetMasterFieldsByGeometryQueryHookResult = ReturnType<typeof useGetMasterFieldsByGeometryQuery>;
export type GetMasterFieldsByGeometryLazyQueryHookResult = ReturnType<typeof useGetMasterFieldsByGeometryLazyQuery>;
export type GetMasterFieldsByGeometryQueryResult = Apollo.QueryResult<GetMasterFieldsByGeometryQuery, GetMasterFieldsByGeometryQueryVariables>;
export const FastapiS3SignedUrlFieldPictureDocument = gql`
    mutation FastapiS3SignedUrlFieldPicture($company_id: UUID! = "00000000-0000-0000-0000-000000000000", $filename: String! = "") {
  fastapiS3SignedUrlFieldPicture(companyId: $company_id, filename: $filename) {
    ... on S3SignedUrlSuccess {
      __typename
      signedUrl
    }
    ... on CompanyDoesNotExistsError {
      __typename
      message
    }
    ... on InvalidParameterError {
      __typename
      message
    }
    ... on NotHavingPermissionError {
      __typename
      message
    }
    ... on UserIdError {
      __typename
      message
    }
  }
}
    `;
export type FastapiS3SignedUrlFieldPictureMutationFn = Apollo.MutationFunction<FastapiS3SignedUrlFieldPictureMutation, FastapiS3SignedUrlFieldPictureMutationVariables>;

/**
 * __useFastapiS3SignedUrlFieldPictureMutation__
 *
 * To run a mutation, you first call `useFastapiS3SignedUrlFieldPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiS3SignedUrlFieldPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiS3SignedUrlFieldPictureMutation, { data, loading, error }] = useFastapiS3SignedUrlFieldPictureMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useFastapiS3SignedUrlFieldPictureMutation(baseOptions?: Apollo.MutationHookOptions<FastapiS3SignedUrlFieldPictureMutation, FastapiS3SignedUrlFieldPictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiS3SignedUrlFieldPictureMutation, FastapiS3SignedUrlFieldPictureMutationVariables>(FastapiS3SignedUrlFieldPictureDocument, options);
      }
export type FastapiS3SignedUrlFieldPictureMutationHookResult = ReturnType<typeof useFastapiS3SignedUrlFieldPictureMutation>;
export type FastapiS3SignedUrlFieldPictureMutationResult = Apollo.MutationResult<FastapiS3SignedUrlFieldPictureMutation>;
export type FastapiS3SignedUrlFieldPictureMutationOptions = Apollo.BaseMutationOptions<FastapiS3SignedUrlFieldPictureMutation, FastapiS3SignedUrlFieldPictureMutationVariables>;
export const FastapiUpdateAUfieldFieldNameInCompanyDocument = gql`
    mutation FastapiUpdateAUfieldFieldNameInCompany($user_field_id: UUID!, $user_field_name: String!, $company_id: UUID!) {
  fastapiUpdateAUfieldFieldNameInCompany(
    nbUfieldId: $user_field_id
    nbUfieldFieldName: $user_field_name
    nbCompanyId: $company_id
  ) {
    __typename
    ... on UpdateFieldNameSuccess {
      fieldName
      fieldId
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on UFieldActiveDoesNotExistsError {
      message
    }
    ... on UserIdError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
  }
}
    `;
export type FastapiUpdateAUfieldFieldNameInCompanyMutationFn = Apollo.MutationFunction<FastapiUpdateAUfieldFieldNameInCompanyMutation, FastapiUpdateAUfieldFieldNameInCompanyMutationVariables>;

/**
 * __useFastapiUpdateAUfieldFieldNameInCompanyMutation__
 *
 * To run a mutation, you first call `useFastapiUpdateAUfieldFieldNameInCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiUpdateAUfieldFieldNameInCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiUpdateAUfieldFieldNameInCompanyMutation, { data, loading, error }] = useFastapiUpdateAUfieldFieldNameInCompanyMutation({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *      user_field_name: // value for 'user_field_name'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useFastapiUpdateAUfieldFieldNameInCompanyMutation(baseOptions?: Apollo.MutationHookOptions<FastapiUpdateAUfieldFieldNameInCompanyMutation, FastapiUpdateAUfieldFieldNameInCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiUpdateAUfieldFieldNameInCompanyMutation, FastapiUpdateAUfieldFieldNameInCompanyMutationVariables>(FastapiUpdateAUfieldFieldNameInCompanyDocument, options);
      }
export type FastapiUpdateAUfieldFieldNameInCompanyMutationHookResult = ReturnType<typeof useFastapiUpdateAUfieldFieldNameInCompanyMutation>;
export type FastapiUpdateAUfieldFieldNameInCompanyMutationResult = Apollo.MutationResult<FastapiUpdateAUfieldFieldNameInCompanyMutation>;
export type FastapiUpdateAUfieldFieldNameInCompanyMutationOptions = Apollo.BaseMutationOptions<FastapiUpdateAUfieldFieldNameInCompanyMutation, FastapiUpdateAUfieldFieldNameInCompanyMutationVariables>;
export const FastapiRemoveAUfieldInCompanyDocument = gql`
    mutation FastapiRemoveAUfieldInCompany($user_field_id: UUID!, $company_id: UUID!) {
  fastapiRemoveAUfieldInCompany(
    nbUfieldId: $user_field_id
    nbCompanyId: $company_id
  ) {
    __typename
    ... on RemoveFieldSuccess {
      fieldId
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UFieldActiveDoesNotExistsError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiRemoveAUfieldInCompanyMutationFn = Apollo.MutationFunction<FastapiRemoveAUfieldInCompanyMutation, FastapiRemoveAUfieldInCompanyMutationVariables>;

/**
 * __useFastapiRemoveAUfieldInCompanyMutation__
 *
 * To run a mutation, you first call `useFastapiRemoveAUfieldInCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiRemoveAUfieldInCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiRemoveAUfieldInCompanyMutation, { data, loading, error }] = useFastapiRemoveAUfieldInCompanyMutation({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useFastapiRemoveAUfieldInCompanyMutation(baseOptions?: Apollo.MutationHookOptions<FastapiRemoveAUfieldInCompanyMutation, FastapiRemoveAUfieldInCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiRemoveAUfieldInCompanyMutation, FastapiRemoveAUfieldInCompanyMutationVariables>(FastapiRemoveAUfieldInCompanyDocument, options);
      }
export type FastapiRemoveAUfieldInCompanyMutationHookResult = ReturnType<typeof useFastapiRemoveAUfieldInCompanyMutation>;
export type FastapiRemoveAUfieldInCompanyMutationResult = Apollo.MutationResult<FastapiRemoveAUfieldInCompanyMutation>;
export type FastapiRemoveAUfieldInCompanyMutationOptions = Apollo.BaseMutationOptions<FastapiRemoveAUfieldInCompanyMutation, FastapiRemoveAUfieldInCompanyMutationVariables>;
export const FastapiUpdateAUfgLinkDocument = gql`
    mutation FastapiUpdateAUfgLink($pre_user_field_group_id: UUID!, $post_user_field_group_id: UUID!, $company_id: UUID!, $user_field_id: UUID!) {
  fastapiUpdateAUfgLink(
    preNbUfieldGroupId: $pre_user_field_group_id
    postNbUfieldGroupId: $post_user_field_group_id
    nbCompanyId: $company_id
    nbUfieldId: $user_field_id
  ) {
    __typename
    ... on UpdateAUfgLinkSuccess {
      message
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UFieldActiveDoesNotExistsError {
      message
    }
    ... on UFieldGroupActiveDoesNotExistsError {
      message
    }
    ... on UFieldGroupLinkAlreadyExistsError {
      message
    }
    ... on UFieldGroupLinkDoesNotExistsError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiUpdateAUfgLinkMutationFn = Apollo.MutationFunction<FastapiUpdateAUfgLinkMutation, FastapiUpdateAUfgLinkMutationVariables>;

/**
 * __useFastapiUpdateAUfgLinkMutation__
 *
 * To run a mutation, you first call `useFastapiUpdateAUfgLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiUpdateAUfgLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiUpdateAUfgLinkMutation, { data, loading, error }] = useFastapiUpdateAUfgLinkMutation({
 *   variables: {
 *      pre_user_field_group_id: // value for 'pre_user_field_group_id'
 *      post_user_field_group_id: // value for 'post_user_field_group_id'
 *      company_id: // value for 'company_id'
 *      user_field_id: // value for 'user_field_id'
 *   },
 * });
 */
export function useFastapiUpdateAUfgLinkMutation(baseOptions?: Apollo.MutationHookOptions<FastapiUpdateAUfgLinkMutation, FastapiUpdateAUfgLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiUpdateAUfgLinkMutation, FastapiUpdateAUfgLinkMutationVariables>(FastapiUpdateAUfgLinkDocument, options);
      }
export type FastapiUpdateAUfgLinkMutationHookResult = ReturnType<typeof useFastapiUpdateAUfgLinkMutation>;
export type FastapiUpdateAUfgLinkMutationResult = Apollo.MutationResult<FastapiUpdateAUfgLinkMutation>;
export type FastapiUpdateAUfgLinkMutationOptions = Apollo.BaseMutationOptions<FastapiUpdateAUfgLinkMutation, FastapiUpdateAUfgLinkMutationVariables>;
export const FastapiInsertAGroupInCompanyDocument = gql`
    mutation FastapiInsertAGroupInCompany($user_field_group_name: String!, $company_id: UUID!) {
  fastapiInsertAGroupInCompany(
    groupName: $user_field_group_name
    nbCompanyId: $company_id
  ) {
    __typename
    ... on InsertGroupNameSuccess {
      message
      groupId
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiInsertAGroupInCompanyMutationFn = Apollo.MutationFunction<FastapiInsertAGroupInCompanyMutation, FastapiInsertAGroupInCompanyMutationVariables>;

/**
 * __useFastapiInsertAGroupInCompanyMutation__
 *
 * To run a mutation, you first call `useFastapiInsertAGroupInCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiInsertAGroupInCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiInsertAGroupInCompanyMutation, { data, loading, error }] = useFastapiInsertAGroupInCompanyMutation({
 *   variables: {
 *      user_field_group_name: // value for 'user_field_group_name'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useFastapiInsertAGroupInCompanyMutation(baseOptions?: Apollo.MutationHookOptions<FastapiInsertAGroupInCompanyMutation, FastapiInsertAGroupInCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiInsertAGroupInCompanyMutation, FastapiInsertAGroupInCompanyMutationVariables>(FastapiInsertAGroupInCompanyDocument, options);
      }
export type FastapiInsertAGroupInCompanyMutationHookResult = ReturnType<typeof useFastapiInsertAGroupInCompanyMutation>;
export type FastapiInsertAGroupInCompanyMutationResult = Apollo.MutationResult<FastapiInsertAGroupInCompanyMutation>;
export type FastapiInsertAGroupInCompanyMutationOptions = Apollo.BaseMutationOptions<FastapiInsertAGroupInCompanyMutation, FastapiInsertAGroupInCompanyMutationVariables>;
export const FastapiUpdateAUfieldGroupNameDocument = gql`
    mutation FastapiUpdateAUfieldGroupName($user_field_group_id: UUID!, $company_id: UUID!, $user_field_group_name: String!) {
  fastapiUpdateAUfieldGroupName(
    nbUfieldGroupId: $user_field_group_id
    nbCompanyId: $company_id
    groupName: $user_field_group_name
  ) {
    __typename
    ... on UpdateAUfieldGroupNameSuccess {
      message
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UFieldActiveDoesNotExistsError {
      message
    }
    ... on UFieldGroupActiveDoesNotExistsError {
      message
    }
    ... on UFieldGroupLinkAlreadyExistsError {
      message
    }
    ... on UFieldGroupLinkDoesNotExistsError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiUpdateAUfieldGroupNameMutationFn = Apollo.MutationFunction<FastapiUpdateAUfieldGroupNameMutation, FastapiUpdateAUfieldGroupNameMutationVariables>;

/**
 * __useFastapiUpdateAUfieldGroupNameMutation__
 *
 * To run a mutation, you first call `useFastapiUpdateAUfieldGroupNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiUpdateAUfieldGroupNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiUpdateAUfieldGroupNameMutation, { data, loading, error }] = useFastapiUpdateAUfieldGroupNameMutation({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *      company_id: // value for 'company_id'
 *      user_field_group_name: // value for 'user_field_group_name'
 *   },
 * });
 */
export function useFastapiUpdateAUfieldGroupNameMutation(baseOptions?: Apollo.MutationHookOptions<FastapiUpdateAUfieldGroupNameMutation, FastapiUpdateAUfieldGroupNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiUpdateAUfieldGroupNameMutation, FastapiUpdateAUfieldGroupNameMutationVariables>(FastapiUpdateAUfieldGroupNameDocument, options);
      }
export type FastapiUpdateAUfieldGroupNameMutationHookResult = ReturnType<typeof useFastapiUpdateAUfieldGroupNameMutation>;
export type FastapiUpdateAUfieldGroupNameMutationResult = Apollo.MutationResult<FastapiUpdateAUfieldGroupNameMutation>;
export type FastapiUpdateAUfieldGroupNameMutationOptions = Apollo.BaseMutationOptions<FastapiUpdateAUfieldGroupNameMutation, FastapiUpdateAUfieldGroupNameMutationVariables>;
export const FastapiRemoveAUfieldGroupDocument = gql`
    mutation FastapiRemoveAUfieldGroup($user_field_group_id: UUID!, $company_id: UUID!) {
  fastapiRemoveAUfieldGroup(
    nbUfieldGroupId: $user_field_group_id
    nbCompanyId: $company_id
  ) {
    __typename
    ... on RemoveAUFieldGroupNameSuccess {
      message
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UFieldGroupActiveDoesNotExistsError {
      message
    }
    ... on UFieldGroupHasLinksError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiRemoveAUfieldGroupMutationFn = Apollo.MutationFunction<FastapiRemoveAUfieldGroupMutation, FastapiRemoveAUfieldGroupMutationVariables>;

/**
 * __useFastapiRemoveAUfieldGroupMutation__
 *
 * To run a mutation, you first call `useFastapiRemoveAUfieldGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiRemoveAUfieldGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiRemoveAUfieldGroupMutation, { data, loading, error }] = useFastapiRemoveAUfieldGroupMutation({
 *   variables: {
 *      user_field_group_id: // value for 'user_field_group_id'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useFastapiRemoveAUfieldGroupMutation(baseOptions?: Apollo.MutationHookOptions<FastapiRemoveAUfieldGroupMutation, FastapiRemoveAUfieldGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiRemoveAUfieldGroupMutation, FastapiRemoveAUfieldGroupMutationVariables>(FastapiRemoveAUfieldGroupDocument, options);
      }
export type FastapiRemoveAUfieldGroupMutationHookResult = ReturnType<typeof useFastapiRemoveAUfieldGroupMutation>;
export type FastapiRemoveAUfieldGroupMutationResult = Apollo.MutationResult<FastapiRemoveAUfieldGroupMutation>;
export type FastapiRemoveAUfieldGroupMutationOptions = Apollo.BaseMutationOptions<FastapiRemoveAUfieldGroupMutation, FastapiRemoveAUfieldGroupMutationVariables>;
export const FastapiInviteUserToTheCompanyDocument = gql`
    mutation FastapiInviteUserToTheCompany($company_id: UUID! = "00000000-0000-0000-0000-000000000000", $email: String! = "", $user_attribute: String! = "", $group_id: UUID) {
  fastapiInviteUserToTheCompany(
    nbCompanyId: $company_id
    email: $email
    userAttribute: $user_attribute
    ufieldGroupId: $group_id
  ) {
    __typename
    ... on ApplicationAlreadyExistsError {
      message
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on InsertApplicationsSuccess {
      message
    }
    ... on InvalidParameterError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UserAccountDoesNotExistsError {
      message
    }
    ... on UserAlreadyRegisteredError {
      message
    }
    ... on NumUsersExcessError {
      message
    }
  }
}
    `;
export type FastapiInviteUserToTheCompanyMutationFn = Apollo.MutationFunction<FastapiInviteUserToTheCompanyMutation, FastapiInviteUserToTheCompanyMutationVariables>;

/**
 * __useFastapiInviteUserToTheCompanyMutation__
 *
 * To run a mutation, you first call `useFastapiInviteUserToTheCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiInviteUserToTheCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiInviteUserToTheCompanyMutation, { data, loading, error }] = useFastapiInviteUserToTheCompanyMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      email: // value for 'email'
 *      user_attribute: // value for 'user_attribute'
 *      group_id: // value for 'group_id'
 *   },
 * });
 */
export function useFastapiInviteUserToTheCompanyMutation(baseOptions?: Apollo.MutationHookOptions<FastapiInviteUserToTheCompanyMutation, FastapiInviteUserToTheCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiInviteUserToTheCompanyMutation, FastapiInviteUserToTheCompanyMutationVariables>(FastapiInviteUserToTheCompanyDocument, options);
      }
export type FastapiInviteUserToTheCompanyMutationHookResult = ReturnType<typeof useFastapiInviteUserToTheCompanyMutation>;
export type FastapiInviteUserToTheCompanyMutationResult = Apollo.MutationResult<FastapiInviteUserToTheCompanyMutation>;
export type FastapiInviteUserToTheCompanyMutationOptions = Apollo.BaseMutationOptions<FastapiInviteUserToTheCompanyMutation, FastapiInviteUserToTheCompanyMutationVariables>;
export const FastapiSwitchUserAttributeWithTheCompanyDocument = gql`
    mutation FastapiSwitchUserAttributeWithTheCompany($company_id: UUID! = "00000000-0000-0000-0000-000000000000", $app_user_active_id: UUID! = "00000000-0000-0000-0000-000000000000") {
  fastapiSwitchUserAttributeWithTheCompany(
    nbCompanyId: $company_id
    appUserActiveId: $app_user_active_id
  ) {
    __typename
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on InvalidParameterError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on Success {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiSwitchUserAttributeWithTheCompanyMutationFn = Apollo.MutationFunction<FastapiSwitchUserAttributeWithTheCompanyMutation, FastapiSwitchUserAttributeWithTheCompanyMutationVariables>;

/**
 * __useFastapiSwitchUserAttributeWithTheCompanyMutation__
 *
 * To run a mutation, you first call `useFastapiSwitchUserAttributeWithTheCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiSwitchUserAttributeWithTheCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiSwitchUserAttributeWithTheCompanyMutation, { data, loading, error }] = useFastapiSwitchUserAttributeWithTheCompanyMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      app_user_active_id: // value for 'app_user_active_id'
 *   },
 * });
 */
export function useFastapiSwitchUserAttributeWithTheCompanyMutation(baseOptions?: Apollo.MutationHookOptions<FastapiSwitchUserAttributeWithTheCompanyMutation, FastapiSwitchUserAttributeWithTheCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiSwitchUserAttributeWithTheCompanyMutation, FastapiSwitchUserAttributeWithTheCompanyMutationVariables>(FastapiSwitchUserAttributeWithTheCompanyDocument, options);
      }
export type FastapiSwitchUserAttributeWithTheCompanyMutationHookResult = ReturnType<typeof useFastapiSwitchUserAttributeWithTheCompanyMutation>;
export type FastapiSwitchUserAttributeWithTheCompanyMutationResult = Apollo.MutationResult<FastapiSwitchUserAttributeWithTheCompanyMutation>;
export type FastapiSwitchUserAttributeWithTheCompanyMutationOptions = Apollo.BaseMutationOptions<FastapiSwitchUserAttributeWithTheCompanyMutation, FastapiSwitchUserAttributeWithTheCompanyMutationVariables>;
export const FastapiRemoveAMemberFromTheCompanyDocument = gql`
    mutation FastapiRemoveAMemberFromTheCompany($company_id: UUID! = "00000000-0000-0000-0000-000000000000", $app_user_active_id: UUID! = "00000000-0000-0000-0000-000000000000") {
  fastapiRemoveAMemberFromTheCompany(
    nbCompanyId: $company_id
    appUserActiveId: $app_user_active_id
  ) {
    __typename
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on Success {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiRemoveAMemberFromTheCompanyMutationFn = Apollo.MutationFunction<FastapiRemoveAMemberFromTheCompanyMutation, FastapiRemoveAMemberFromTheCompanyMutationVariables>;

/**
 * __useFastapiRemoveAMemberFromTheCompanyMutation__
 *
 * To run a mutation, you first call `useFastapiRemoveAMemberFromTheCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiRemoveAMemberFromTheCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiRemoveAMemberFromTheCompanyMutation, { data, loading, error }] = useFastapiRemoveAMemberFromTheCompanyMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      app_user_active_id: // value for 'app_user_active_id'
 *   },
 * });
 */
export function useFastapiRemoveAMemberFromTheCompanyMutation(baseOptions?: Apollo.MutationHookOptions<FastapiRemoveAMemberFromTheCompanyMutation, FastapiRemoveAMemberFromTheCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiRemoveAMemberFromTheCompanyMutation, FastapiRemoveAMemberFromTheCompanyMutationVariables>(FastapiRemoveAMemberFromTheCompanyDocument, options);
      }
export type FastapiRemoveAMemberFromTheCompanyMutationHookResult = ReturnType<typeof useFastapiRemoveAMemberFromTheCompanyMutation>;
export type FastapiRemoveAMemberFromTheCompanyMutationResult = Apollo.MutationResult<FastapiRemoveAMemberFromTheCompanyMutation>;
export type FastapiRemoveAMemberFromTheCompanyMutationOptions = Apollo.BaseMutationOptions<FastapiRemoveAMemberFromTheCompanyMutation, FastapiRemoveAMemberFromTheCompanyMutationVariables>;
export const FastapiReplyApplicationToRegisterAMemberWithTheCompanyDocument = gql`
    mutation FastapiReplyApplicationToRegisterAMemberWithTheCompany($user_invitation_id: UUID! = "00000000-0000-0000-0000-000000000000", $is_approved: Boolean! = false) {
  fastapiReplyApplicationToRegisterAMemberWithTheCompany(
    applicationId: $user_invitation_id
    isApproved: $is_approved
  ) {
    __typename
    ... on CompanyDoesNotExistsError {
      __typename
      message
    }
    ... on InvalidApplicationError {
      __typename
      message
    }
    ... on Success {
      __typename
      message
    }
  }
}
    `;
export type FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutationFn = Apollo.MutationFunction<FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation, FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutationVariables>;

/**
 * __useFastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation__
 *
 * To run a mutation, you first call `useFastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation, { data, loading, error }] = useFastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation({
 *   variables: {
 *      user_invitation_id: // value for 'user_invitation_id'
 *      is_approved: // value for 'is_approved'
 *   },
 * });
 */
export function useFastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation(baseOptions?: Apollo.MutationHookOptions<FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation, FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation, FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutationVariables>(FastapiReplyApplicationToRegisterAMemberWithTheCompanyDocument, options);
      }
export type FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutationHookResult = ReturnType<typeof useFastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation>;
export type FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutationResult = Apollo.MutationResult<FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation>;
export type FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutationOptions = Apollo.BaseMutationOptions<FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutation, FastapiReplyApplicationToRegisterAMemberWithTheCompanyMutationVariables>;
export const FastapiFieldPictureLoadStatusFailureDocument = gql`
    query FastapiFieldPictureLoadStatusFailure($company_id: UUID!) {
  fastapiFieldPictureLoadStatusFailure(nbCompanyId: $company_id) {
    __typename
    ... on FieldPictureLoadStatusFailureSuccess {
      record {
        ufieldPictureId
        capCoor
        datetime
        imagePath
        thumbnailPath
        ufieldId
        ufieldName
      }
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;

/**
 * __useFastapiFieldPictureLoadStatusFailureQuery__
 *
 * To run a query within a React component, call `useFastapiFieldPictureLoadStatusFailureQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastapiFieldPictureLoadStatusFailureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastapiFieldPictureLoadStatusFailureQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useFastapiFieldPictureLoadStatusFailureQuery(baseOptions: Apollo.QueryHookOptions<FastapiFieldPictureLoadStatusFailureQuery, FastapiFieldPictureLoadStatusFailureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FastapiFieldPictureLoadStatusFailureQuery, FastapiFieldPictureLoadStatusFailureQueryVariables>(FastapiFieldPictureLoadStatusFailureDocument, options);
      }
export function useFastapiFieldPictureLoadStatusFailureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FastapiFieldPictureLoadStatusFailureQuery, FastapiFieldPictureLoadStatusFailureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FastapiFieldPictureLoadStatusFailureQuery, FastapiFieldPictureLoadStatusFailureQueryVariables>(FastapiFieldPictureLoadStatusFailureDocument, options);
        }
export type FastapiFieldPictureLoadStatusFailureQueryHookResult = ReturnType<typeof useFastapiFieldPictureLoadStatusFailureQuery>;
export type FastapiFieldPictureLoadStatusFailureLazyQueryHookResult = ReturnType<typeof useFastapiFieldPictureLoadStatusFailureLazyQuery>;
export type FastapiFieldPictureLoadStatusFailureQueryResult = Apollo.QueryResult<FastapiFieldPictureLoadStatusFailureQuery, FastapiFieldPictureLoadStatusFailureQueryVariables>;
export const FastapiUpdateAPictureToAUfieldDocument = gql`
    mutation FastapiUpdateAPictureToAUfield($company_id: UUID!, $user_field_id: UUID!, $field_picture_id: UUID!) {
  fastapiUpdateAPictureToAUfield(
    nbCompanyId: $company_id
    nbUfieldId: $user_field_id
    nbFieldPictureId: $field_picture_id
  ) {
    __typename
    ... on UpdateAPictureToAUfieldSuccess {
      message
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on FieldPictureIdError {
      message
    }
    ... on FieldPictureLoadStatusIsAlreadyConfirmedError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UFieldActiveDoesNotExistsError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiUpdateAPictureToAUfieldMutationFn = Apollo.MutationFunction<FastapiUpdateAPictureToAUfieldMutation, FastapiUpdateAPictureToAUfieldMutationVariables>;

/**
 * __useFastapiUpdateAPictureToAUfieldMutation__
 *
 * To run a mutation, you first call `useFastapiUpdateAPictureToAUfieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiUpdateAPictureToAUfieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiUpdateAPictureToAUfieldMutation, { data, loading, error }] = useFastapiUpdateAPictureToAUfieldMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      user_field_id: // value for 'user_field_id'
 *      field_picture_id: // value for 'field_picture_id'
 *   },
 * });
 */
export function useFastapiUpdateAPictureToAUfieldMutation(baseOptions?: Apollo.MutationHookOptions<FastapiUpdateAPictureToAUfieldMutation, FastapiUpdateAPictureToAUfieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiUpdateAPictureToAUfieldMutation, FastapiUpdateAPictureToAUfieldMutationVariables>(FastapiUpdateAPictureToAUfieldDocument, options);
      }
export type FastapiUpdateAPictureToAUfieldMutationHookResult = ReturnType<typeof useFastapiUpdateAPictureToAUfieldMutation>;
export type FastapiUpdateAPictureToAUfieldMutationResult = Apollo.MutationResult<FastapiUpdateAPictureToAUfieldMutation>;
export type FastapiUpdateAPictureToAUfieldMutationOptions = Apollo.BaseMutationOptions<FastapiUpdateAPictureToAUfieldMutation, FastapiUpdateAPictureToAUfieldMutationVariables>;
export const FastapiFieldPictureLoadStatusLoadedDocument = gql`
    query FastapiFieldPictureLoadStatusLoaded($company_id: UUID!) {
  fastapiFieldPictureLoadStatusLoaded(nbCompanyId: $company_id) {
    __typename
    ... on FieldPictureLoadStatusLoadedSuccess {
      record {
        ufieldPictureId
        capCoor
        datetime
        imagePath
        thumbnailPath
        ufieldId
        ufieldName
      }
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;

/**
 * __useFastapiFieldPictureLoadStatusLoadedQuery__
 *
 * To run a query within a React component, call `useFastapiFieldPictureLoadStatusLoadedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastapiFieldPictureLoadStatusLoadedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastapiFieldPictureLoadStatusLoadedQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useFastapiFieldPictureLoadStatusLoadedQuery(baseOptions: Apollo.QueryHookOptions<FastapiFieldPictureLoadStatusLoadedQuery, FastapiFieldPictureLoadStatusLoadedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FastapiFieldPictureLoadStatusLoadedQuery, FastapiFieldPictureLoadStatusLoadedQueryVariables>(FastapiFieldPictureLoadStatusLoadedDocument, options);
      }
export function useFastapiFieldPictureLoadStatusLoadedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FastapiFieldPictureLoadStatusLoadedQuery, FastapiFieldPictureLoadStatusLoadedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FastapiFieldPictureLoadStatusLoadedQuery, FastapiFieldPictureLoadStatusLoadedQueryVariables>(FastapiFieldPictureLoadStatusLoadedDocument, options);
        }
export type FastapiFieldPictureLoadStatusLoadedQueryHookResult = ReturnType<typeof useFastapiFieldPictureLoadStatusLoadedQuery>;
export type FastapiFieldPictureLoadStatusLoadedLazyQueryHookResult = ReturnType<typeof useFastapiFieldPictureLoadStatusLoadedLazyQuery>;
export type FastapiFieldPictureLoadStatusLoadedQueryResult = Apollo.QueryResult<FastapiFieldPictureLoadStatusLoadedQuery, FastapiFieldPictureLoadStatusLoadedQueryVariables>;
export const FastapiRemoveFieldPictureDocument = gql`
    mutation FastapiRemoveFieldPicture($field_picture_id: UUID!) {
  fastapiRemoveFieldPicture(nbFieldPictureId: $field_picture_id) {
    __typename
    ... on RemoveFieldPictureSuccess {
      message
    }
    ... on FieldPictureIdError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiRemoveFieldPictureMutationFn = Apollo.MutationFunction<FastapiRemoveFieldPictureMutation, FastapiRemoveFieldPictureMutationVariables>;

/**
 * __useFastapiRemoveFieldPictureMutation__
 *
 * To run a mutation, you first call `useFastapiRemoveFieldPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiRemoveFieldPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiRemoveFieldPictureMutation, { data, loading, error }] = useFastapiRemoveFieldPictureMutation({
 *   variables: {
 *      field_picture_id: // value for 'field_picture_id'
 *   },
 * });
 */
export function useFastapiRemoveFieldPictureMutation(baseOptions?: Apollo.MutationHookOptions<FastapiRemoveFieldPictureMutation, FastapiRemoveFieldPictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiRemoveFieldPictureMutation, FastapiRemoveFieldPictureMutationVariables>(FastapiRemoveFieldPictureDocument, options);
      }
export type FastapiRemoveFieldPictureMutationHookResult = ReturnType<typeof useFastapiRemoveFieldPictureMutation>;
export type FastapiRemoveFieldPictureMutationResult = Apollo.MutationResult<FastapiRemoveFieldPictureMutation>;
export type FastapiRemoveFieldPictureMutationOptions = Apollo.BaseMutationOptions<FastapiRemoveFieldPictureMutation, FastapiRemoveFieldPictureMutationVariables>;
export const FastapiGetPictureAnalysisDocument = gql`
    query FastapiGetPictureAnalysis($fetch_type: String!, $id: UUID!, $picture_analysis_type_Id: UUID!) {
  fastapiGetPictureAnalysis(
    fetchType: $fetch_type
    id: $id
    nbPictureAnalysisTypeId: $picture_analysis_type_Id
  ) {
    __typename
    ... on GetPictureAnalysisSuccess {
      analysis {
        fieldPictureId
        imagePath
        thumbnailPath
        datetime
        ufieldId
        ufieldName
        analysisTypeName
        stat
        result
      }
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UFieldActiveDoesNotExistsError {
      message
    }
    ... on UFieldGroupActiveDoesNotExistsError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;

/**
 * __useFastapiGetPictureAnalysisQuery__
 *
 * To run a query within a React component, call `useFastapiGetPictureAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastapiGetPictureAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastapiGetPictureAnalysisQuery({
 *   variables: {
 *      fetch_type: // value for 'fetch_type'
 *      id: // value for 'id'
 *      picture_analysis_type_Id: // value for 'picture_analysis_type_Id'
 *   },
 * });
 */
export function useFastapiGetPictureAnalysisQuery(baseOptions: Apollo.QueryHookOptions<FastapiGetPictureAnalysisQuery, FastapiGetPictureAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FastapiGetPictureAnalysisQuery, FastapiGetPictureAnalysisQueryVariables>(FastapiGetPictureAnalysisDocument, options);
      }
export function useFastapiGetPictureAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FastapiGetPictureAnalysisQuery, FastapiGetPictureAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FastapiGetPictureAnalysisQuery, FastapiGetPictureAnalysisQueryVariables>(FastapiGetPictureAnalysisDocument, options);
        }
export type FastapiGetPictureAnalysisQueryHookResult = ReturnType<typeof useFastapiGetPictureAnalysisQuery>;
export type FastapiGetPictureAnalysisLazyQueryHookResult = ReturnType<typeof useFastapiGetPictureAnalysisLazyQuery>;
export type FastapiGetPictureAnalysisQueryResult = Apollo.QueryResult<FastapiGetPictureAnalysisQuery, FastapiGetPictureAnalysisQueryVariables>;
export const FastapiRequestAPictureAnalysisDocument = gql`
    mutation FastapiRequestAPictureAnalysis($picture_id: UUID! = "00000000-0000-0000-0000-000000000000", $picture_analysis_type_id: UUID! = "00000000-0000-0000-0000-000000000000") {
  fastapiRequestAPictureAnalysis(
    nbFieldPictureId: $picture_id
    nbPictureAnalysisTypeId: $picture_analysis_type_id
  ) {
    __typename
    ... on RequestAPictureAnalysisSuccess {
      message
    }
    ... on InternalServerError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiRequestAPictureAnalysisMutationFn = Apollo.MutationFunction<FastapiRequestAPictureAnalysisMutation, FastapiRequestAPictureAnalysisMutationVariables>;

/**
 * __useFastapiRequestAPictureAnalysisMutation__
 *
 * To run a mutation, you first call `useFastapiRequestAPictureAnalysisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiRequestAPictureAnalysisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiRequestAPictureAnalysisMutation, { data, loading, error }] = useFastapiRequestAPictureAnalysisMutation({
 *   variables: {
 *      picture_id: // value for 'picture_id'
 *      picture_analysis_type_id: // value for 'picture_analysis_type_id'
 *   },
 * });
 */
export function useFastapiRequestAPictureAnalysisMutation(baseOptions?: Apollo.MutationHookOptions<FastapiRequestAPictureAnalysisMutation, FastapiRequestAPictureAnalysisMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiRequestAPictureAnalysisMutation, FastapiRequestAPictureAnalysisMutationVariables>(FastapiRequestAPictureAnalysisDocument, options);
      }
export type FastapiRequestAPictureAnalysisMutationHookResult = ReturnType<typeof useFastapiRequestAPictureAnalysisMutation>;
export type FastapiRequestAPictureAnalysisMutationResult = Apollo.MutationResult<FastapiRequestAPictureAnalysisMutation>;
export type FastapiRequestAPictureAnalysisMutationOptions = Apollo.BaseMutationOptions<FastapiRequestAPictureAnalysisMutation, FastapiRequestAPictureAnalysisMutationVariables>;
export const FastapiUpdateAUfieldFieldPolygonInCompanyDocument = gql`
    mutation FastapiUpdateAUfieldFieldPolygonInCompany($company_id: UUID!, $user_field_polygon_string: String!, $user_field_id: UUID!) {
  fastapiUpdateAUfieldFieldPolygonInCompany(
    nbCompanyId: $company_id
    nbUfieldFieldPolygon: $user_field_polygon_string
    nbUfieldId: $user_field_id
  ) {
    __typename
    ... on UpdateFieldPolygonSuccess {
      message
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on InvalidParameterError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UFieldActiveDoesNotExistsError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiUpdateAUfieldFieldPolygonInCompanyMutationFn = Apollo.MutationFunction<FastapiUpdateAUfieldFieldPolygonInCompanyMutation, FastapiUpdateAUfieldFieldPolygonInCompanyMutationVariables>;

/**
 * __useFastapiUpdateAUfieldFieldPolygonInCompanyMutation__
 *
 * To run a mutation, you first call `useFastapiUpdateAUfieldFieldPolygonInCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiUpdateAUfieldFieldPolygonInCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiUpdateAUfieldFieldPolygonInCompanyMutation, { data, loading, error }] = useFastapiUpdateAUfieldFieldPolygonInCompanyMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      user_field_polygon_string: // value for 'user_field_polygon_string'
 *      user_field_id: // value for 'user_field_id'
 *   },
 * });
 */
export function useFastapiUpdateAUfieldFieldPolygonInCompanyMutation(baseOptions?: Apollo.MutationHookOptions<FastapiUpdateAUfieldFieldPolygonInCompanyMutation, FastapiUpdateAUfieldFieldPolygonInCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiUpdateAUfieldFieldPolygonInCompanyMutation, FastapiUpdateAUfieldFieldPolygonInCompanyMutationVariables>(FastapiUpdateAUfieldFieldPolygonInCompanyDocument, options);
      }
export type FastapiUpdateAUfieldFieldPolygonInCompanyMutationHookResult = ReturnType<typeof useFastapiUpdateAUfieldFieldPolygonInCompanyMutation>;
export type FastapiUpdateAUfieldFieldPolygonInCompanyMutationResult = Apollo.MutationResult<FastapiUpdateAUfieldFieldPolygonInCompanyMutation>;
export type FastapiUpdateAUfieldFieldPolygonInCompanyMutationOptions = Apollo.BaseMutationOptions<FastapiUpdateAUfieldFieldPolygonInCompanyMutation, FastapiUpdateAUfieldFieldPolygonInCompanyMutationVariables>;
export const FastapiSetAttributesForTheUfieldDocument = gql`
    mutation FastapiSetAttributesForTheUfield($user_field_id: UUID! = "00000000-0000-0000-0000-000000000000", $user_field_attribute_ids: [UUID!]! = ["00000000-0000-0000-0000-000000000000"]) {
  fastapiSetAttributesForTheUfield(
    nbUfield: $user_field_id
    nbUfieldAttributes: $user_field_attribute_ids
  ) {
    __typename
    ... on SetAttributesForTheUfieldSuccess {
      message
    }
    ... on UFieldActiveDoesNotExistsError {
      message
    }
    ... on UFieldAttributeDoesNotExistError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiSetAttributesForTheUfieldMutationFn = Apollo.MutationFunction<FastapiSetAttributesForTheUfieldMutation, FastapiSetAttributesForTheUfieldMutationVariables>;

/**
 * __useFastapiSetAttributesForTheUfieldMutation__
 *
 * To run a mutation, you first call `useFastapiSetAttributesForTheUfieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiSetAttributesForTheUfieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiSetAttributesForTheUfieldMutation, { data, loading, error }] = useFastapiSetAttributesForTheUfieldMutation({
 *   variables: {
 *      user_field_id: // value for 'user_field_id'
 *      user_field_attribute_ids: // value for 'user_field_attribute_ids'
 *   },
 * });
 */
export function useFastapiSetAttributesForTheUfieldMutation(baseOptions?: Apollo.MutationHookOptions<FastapiSetAttributesForTheUfieldMutation, FastapiSetAttributesForTheUfieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiSetAttributesForTheUfieldMutation, FastapiSetAttributesForTheUfieldMutationVariables>(FastapiSetAttributesForTheUfieldDocument, options);
      }
export type FastapiSetAttributesForTheUfieldMutationHookResult = ReturnType<typeof useFastapiSetAttributesForTheUfieldMutation>;
export type FastapiSetAttributesForTheUfieldMutationResult = Apollo.MutationResult<FastapiSetAttributesForTheUfieldMutation>;
export type FastapiSetAttributesForTheUfieldMutationOptions = Apollo.BaseMutationOptions<FastapiSetAttributesForTheUfieldMutation, FastapiSetAttributesForTheUfieldMutationVariables>;
export const FastapiS3SignedUrlWideOrthoimgDocument = gql`
    mutation FastapiS3SignedUrlWideOrthoimg($company_id: UUID! = "00000000-0000-0000-0000-000000000000", $filename: String! = "", $orthoimg_type: String! = "", $number_of_parts: Int! = 0, $part_md5s: [String!]! = [""], $part_sizes: [Int!]! = [0], $sens_datetime: String! = "") {
  fastapiS3SignedUrlWideOrthoimg(
    companyId: $company_id
    filename: $filename
    orthoimgType: $orthoimg_type
    numberOfParts: $number_of_parts
    partMd5s: $part_md5s
    partSizes: $part_sizes
    sensDatetime: $sens_datetime
  ) {
    __typename
    ... on S3SignedUrlWideOrthoimgSuccess {
      s3SignedUrls {
        partNumber
        signedUrl
      }
      uploadId
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on InvalidParameterError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiS3SignedUrlWideOrthoimgMutationFn = Apollo.MutationFunction<FastapiS3SignedUrlWideOrthoimgMutation, FastapiS3SignedUrlWideOrthoimgMutationVariables>;

/**
 * __useFastapiS3SignedUrlWideOrthoimgMutation__
 *
 * To run a mutation, you first call `useFastapiS3SignedUrlWideOrthoimgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiS3SignedUrlWideOrthoimgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiS3SignedUrlWideOrthoimgMutation, { data, loading, error }] = useFastapiS3SignedUrlWideOrthoimgMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      filename: // value for 'filename'
 *      orthoimg_type: // value for 'orthoimg_type'
 *      number_of_parts: // value for 'number_of_parts'
 *      part_md5s: // value for 'part_md5s'
 *      part_sizes: // value for 'part_sizes'
 *      sens_datetime: // value for 'sens_datetime'
 *   },
 * });
 */
export function useFastapiS3SignedUrlWideOrthoimgMutation(baseOptions?: Apollo.MutationHookOptions<FastapiS3SignedUrlWideOrthoimgMutation, FastapiS3SignedUrlWideOrthoimgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiS3SignedUrlWideOrthoimgMutation, FastapiS3SignedUrlWideOrthoimgMutationVariables>(FastapiS3SignedUrlWideOrthoimgDocument, options);
      }
export type FastapiS3SignedUrlWideOrthoimgMutationHookResult = ReturnType<typeof useFastapiS3SignedUrlWideOrthoimgMutation>;
export type FastapiS3SignedUrlWideOrthoimgMutationResult = Apollo.MutationResult<FastapiS3SignedUrlWideOrthoimgMutation>;
export type FastapiS3SignedUrlWideOrthoimgMutationOptions = Apollo.BaseMutationOptions<FastapiS3SignedUrlWideOrthoimgMutation, FastapiS3SignedUrlWideOrthoimgMutationVariables>;
export const FastapiRemoveUfieldAttributeDocument = gql`
    mutation FastapiRemoveUfieldAttribute($user_field_attribute_id: UUID! = "00000000-0000-0000-0000-000000000000") {
  fastapiRemoveUfieldAttribute(nbUfieldAttribute: $user_field_attribute_id) {
    __typename
    ... on RemoveUFieldAttributesSuccess {
      message
    }
    ... on UFieldAttributeDoesNotExistError {
      message
    }
    ... on UFieldAttributeLinkUFieldError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiRemoveUfieldAttributeMutationFn = Apollo.MutationFunction<FastapiRemoveUfieldAttributeMutation, FastapiRemoveUfieldAttributeMutationVariables>;

/**
 * __useFastapiRemoveUfieldAttributeMutation__
 *
 * To run a mutation, you first call `useFastapiRemoveUfieldAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiRemoveUfieldAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiRemoveUfieldAttributeMutation, { data, loading, error }] = useFastapiRemoveUfieldAttributeMutation({
 *   variables: {
 *      user_field_attribute_id: // value for 'user_field_attribute_id'
 *   },
 * });
 */
export function useFastapiRemoveUfieldAttributeMutation(baseOptions?: Apollo.MutationHookOptions<FastapiRemoveUfieldAttributeMutation, FastapiRemoveUfieldAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiRemoveUfieldAttributeMutation, FastapiRemoveUfieldAttributeMutationVariables>(FastapiRemoveUfieldAttributeDocument, options);
      }
export type FastapiRemoveUfieldAttributeMutationHookResult = ReturnType<typeof useFastapiRemoveUfieldAttributeMutation>;
export type FastapiRemoveUfieldAttributeMutationResult = Apollo.MutationResult<FastapiRemoveUfieldAttributeMutation>;
export type FastapiRemoveUfieldAttributeMutationOptions = Apollo.BaseMutationOptions<FastapiRemoveUfieldAttributeMutation, FastapiRemoveUfieldAttributeMutationVariables>;
export const FastapiCompleteMultipartUploadDocument = gql`
    mutation FastapiCompleteMultipartUpload($company_id: UUID! = "00000000-0000-0000-0000-000000000000", $upload_id: String! = "") {
  fastapiCompleteMultipartUpload(companyId: $company_id, uploadId: $upload_id) {
    __typename
    ... on CompleteMultipartUploadSuccess {
      message
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on InsufficientPartfileError {
      message
    }
    ... on InvalidParameterError {
      message
    }
    ... on UnexpectedError {
      message
    }
    ... on UserIdError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
  }
}
    `;
export type FastapiCompleteMultipartUploadMutationFn = Apollo.MutationFunction<FastapiCompleteMultipartUploadMutation, FastapiCompleteMultipartUploadMutationVariables>;

/**
 * __useFastapiCompleteMultipartUploadMutation__
 *
 * To run a mutation, you first call `useFastapiCompleteMultipartUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiCompleteMultipartUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiCompleteMultipartUploadMutation, { data, loading, error }] = useFastapiCompleteMultipartUploadMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      upload_id: // value for 'upload_id'
 *   },
 * });
 */
export function useFastapiCompleteMultipartUploadMutation(baseOptions?: Apollo.MutationHookOptions<FastapiCompleteMultipartUploadMutation, FastapiCompleteMultipartUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiCompleteMultipartUploadMutation, FastapiCompleteMultipartUploadMutationVariables>(FastapiCompleteMultipartUploadDocument, options);
      }
export type FastapiCompleteMultipartUploadMutationHookResult = ReturnType<typeof useFastapiCompleteMultipartUploadMutation>;
export type FastapiCompleteMultipartUploadMutationResult = Apollo.MutationResult<FastapiCompleteMultipartUploadMutation>;
export type FastapiCompleteMultipartUploadMutationOptions = Apollo.BaseMutationOptions<FastapiCompleteMultipartUploadMutation, FastapiCompleteMultipartUploadMutationVariables>;
export const FastapiWideOrthoimgLoadSuspendedDocument = gql`
    query FastapiWideOrthoimgLoadSuspended($company_id: UUID! = "00000000-0000-0000-0000-000000000000") {
  fastapiWideOrthoimgLoadSuspended(companyId: $company_id) {
    __typename
    ... on WideOrthoimgLoadSuspendedSuccess {
      wideOrthoimgLoad {
        filename
        orthoimgType
        suspendedParts {
          number
          md5
        }
        procDatetime
        reason
        sensDatetime
        uploadId
      }
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;

/**
 * __useFastapiWideOrthoimgLoadSuspendedQuery__
 *
 * To run a query within a React component, call `useFastapiWideOrthoimgLoadSuspendedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastapiWideOrthoimgLoadSuspendedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastapiWideOrthoimgLoadSuspendedQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useFastapiWideOrthoimgLoadSuspendedQuery(baseOptions?: Apollo.QueryHookOptions<FastapiWideOrthoimgLoadSuspendedQuery, FastapiWideOrthoimgLoadSuspendedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FastapiWideOrthoimgLoadSuspendedQuery, FastapiWideOrthoimgLoadSuspendedQueryVariables>(FastapiWideOrthoimgLoadSuspendedDocument, options);
      }
export function useFastapiWideOrthoimgLoadSuspendedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FastapiWideOrthoimgLoadSuspendedQuery, FastapiWideOrthoimgLoadSuspendedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FastapiWideOrthoimgLoadSuspendedQuery, FastapiWideOrthoimgLoadSuspendedQueryVariables>(FastapiWideOrthoimgLoadSuspendedDocument, options);
        }
export type FastapiWideOrthoimgLoadSuspendedQueryHookResult = ReturnType<typeof useFastapiWideOrthoimgLoadSuspendedQuery>;
export type FastapiWideOrthoimgLoadSuspendedLazyQueryHookResult = ReturnType<typeof useFastapiWideOrthoimgLoadSuspendedLazyQuery>;
export type FastapiWideOrthoimgLoadSuspendedQueryResult = Apollo.QueryResult<FastapiWideOrthoimgLoadSuspendedQuery, FastapiWideOrthoimgLoadSuspendedQueryVariables>;
export const FastapiResumeMultipartUploadDocument = gql`
    query FastapiResumeMultipartUpload($company_id: UUID! = "00000000-0000-0000-0000-000000000000", $upload_id: String! = "") {
  fastapiResumeMultipartUpload(companyId: $company_id, uploadId: $upload_id) {
    __typename
    ... on ResumeMultipartUploadSuccess {
      s3SignedUrls {
        partNumber
        signedUrl
      }
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on InvalidParameterError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;

/**
 * __useFastapiResumeMultipartUploadQuery__
 *
 * To run a query within a React component, call `useFastapiResumeMultipartUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastapiResumeMultipartUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastapiResumeMultipartUploadQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      upload_id: // value for 'upload_id'
 *   },
 * });
 */
export function useFastapiResumeMultipartUploadQuery(baseOptions?: Apollo.QueryHookOptions<FastapiResumeMultipartUploadQuery, FastapiResumeMultipartUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FastapiResumeMultipartUploadQuery, FastapiResumeMultipartUploadQueryVariables>(FastapiResumeMultipartUploadDocument, options);
      }
export function useFastapiResumeMultipartUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FastapiResumeMultipartUploadQuery, FastapiResumeMultipartUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FastapiResumeMultipartUploadQuery, FastapiResumeMultipartUploadQueryVariables>(FastapiResumeMultipartUploadDocument, options);
        }
export type FastapiResumeMultipartUploadQueryHookResult = ReturnType<typeof useFastapiResumeMultipartUploadQuery>;
export type FastapiResumeMultipartUploadLazyQueryHookResult = ReturnType<typeof useFastapiResumeMultipartUploadLazyQuery>;
export type FastapiResumeMultipartUploadQueryResult = Apollo.QueryResult<FastapiResumeMultipartUploadQuery, FastapiResumeMultipartUploadQueryVariables>;
export const FastapiTerminateWideOrthoimgLoadDocument = gql`
    mutation FastapiTerminateWideOrthoimgLoad($company_id: UUID! = "00000000-0000-0000-0000-000000000000", $upload_id: String! = "") {
  fastapiTerminateWideOrthoimgLoad(companyId: $company_id, uploadId: $upload_id) {
    __typename
    ... on Success {
      message
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on InvalidParameterError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiTerminateWideOrthoimgLoadMutationFn = Apollo.MutationFunction<FastapiTerminateWideOrthoimgLoadMutation, FastapiTerminateWideOrthoimgLoadMutationVariables>;

/**
 * __useFastapiTerminateWideOrthoimgLoadMutation__
 *
 * To run a mutation, you first call `useFastapiTerminateWideOrthoimgLoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiTerminateWideOrthoimgLoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiTerminateWideOrthoimgLoadMutation, { data, loading, error }] = useFastapiTerminateWideOrthoimgLoadMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      upload_id: // value for 'upload_id'
 *   },
 * });
 */
export function useFastapiTerminateWideOrthoimgLoadMutation(baseOptions?: Apollo.MutationHookOptions<FastapiTerminateWideOrthoimgLoadMutation, FastapiTerminateWideOrthoimgLoadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiTerminateWideOrthoimgLoadMutation, FastapiTerminateWideOrthoimgLoadMutationVariables>(FastapiTerminateWideOrthoimgLoadDocument, options);
      }
export type FastapiTerminateWideOrthoimgLoadMutationHookResult = ReturnType<typeof useFastapiTerminateWideOrthoimgLoadMutation>;
export type FastapiTerminateWideOrthoimgLoadMutationResult = Apollo.MutationResult<FastapiTerminateWideOrthoimgLoadMutation>;
export type FastapiTerminateWideOrthoimgLoadMutationOptions = Apollo.BaseMutationOptions<FastapiTerminateWideOrthoimgLoadMutation, FastapiTerminateWideOrthoimgLoadMutationVariables>;
export const FastapiLaunchIpEngineForWideDocument = gql`
    mutation FastapiLaunchIpEngineForWide($company_id: UUID! = "00000000-0000-0000-0000-000000000000", $engine_information_id: UUID! = "00000000-0000-0000-0000-000000000000", $wide_orthoimg_ids: [UUID!]! = ["00000000-0000-0000-0000-000000000000"]) {
  fastapiLaunchIpEngineForWide(
    companyId: $company_id
    engineInformationId: $engine_information_id
    wideOrthoimgIds: $wide_orthoimg_ids
  ) {
    __typename
    ... on Success {
      message
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on InternalError {
      message
    }
    ... on NoNipeEngineInformationError {
      message
    }
    ... on NoWideOrthoimgsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on S3OrthoImageCopyError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiLaunchIpEngineForWideMutationFn = Apollo.MutationFunction<FastapiLaunchIpEngineForWideMutation, FastapiLaunchIpEngineForWideMutationVariables>;

/**
 * __useFastapiLaunchIpEngineForWideMutation__
 *
 * To run a mutation, you first call `useFastapiLaunchIpEngineForWideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiLaunchIpEngineForWideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiLaunchIpEngineForWideMutation, { data, loading, error }] = useFastapiLaunchIpEngineForWideMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      engine_information_id: // value for 'engine_information_id'
 *      wide_orthoimg_ids: // value for 'wide_orthoimg_ids'
 *   },
 * });
 */
export function useFastapiLaunchIpEngineForWideMutation(baseOptions?: Apollo.MutationHookOptions<FastapiLaunchIpEngineForWideMutation, FastapiLaunchIpEngineForWideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiLaunchIpEngineForWideMutation, FastapiLaunchIpEngineForWideMutationVariables>(FastapiLaunchIpEngineForWideDocument, options);
      }
export type FastapiLaunchIpEngineForWideMutationHookResult = ReturnType<typeof useFastapiLaunchIpEngineForWideMutation>;
export type FastapiLaunchIpEngineForWideMutationResult = Apollo.MutationResult<FastapiLaunchIpEngineForWideMutation>;
export type FastapiLaunchIpEngineForWideMutationOptions = Apollo.BaseMutationOptions<FastapiLaunchIpEngineForWideMutation, FastapiLaunchIpEngineForWideMutationVariables>;
export const FastapiS3SignedUrlFieldFileDocument = gql`
    mutation FastapiS3SignedUrlFieldFile($company_id: UUID! = "00000000-0000-0000-0000-000000000000", $user_field_id: UUID! = "00000000-0000-0000-0000-000000000000", $filename: String! = "") {
  fastapiS3SignedUrlFieldFile(
    companyId: $company_id
    ufieldId: $user_field_id
    filename: $filename
  ) {
    __typename
    ... on S3SignedUrlSuccess {
      signedUrl
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on InvalidParameterError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on UFieldActiveDoesNotExistsError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiS3SignedUrlFieldFileMutationFn = Apollo.MutationFunction<FastapiS3SignedUrlFieldFileMutation, FastapiS3SignedUrlFieldFileMutationVariables>;

/**
 * __useFastapiS3SignedUrlFieldFileMutation__
 *
 * To run a mutation, you first call `useFastapiS3SignedUrlFieldFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiS3SignedUrlFieldFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiS3SignedUrlFieldFileMutation, { data, loading, error }] = useFastapiS3SignedUrlFieldFileMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      user_field_id: // value for 'user_field_id'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useFastapiS3SignedUrlFieldFileMutation(baseOptions?: Apollo.MutationHookOptions<FastapiS3SignedUrlFieldFileMutation, FastapiS3SignedUrlFieldFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiS3SignedUrlFieldFileMutation, FastapiS3SignedUrlFieldFileMutationVariables>(FastapiS3SignedUrlFieldFileDocument, options);
      }
export type FastapiS3SignedUrlFieldFileMutationHookResult = ReturnType<typeof useFastapiS3SignedUrlFieldFileMutation>;
export type FastapiS3SignedUrlFieldFileMutationResult = Apollo.MutationResult<FastapiS3SignedUrlFieldFileMutation>;
export type FastapiS3SignedUrlFieldFileMutationOptions = Apollo.BaseMutationOptions<FastapiS3SignedUrlFieldFileMutation, FastapiS3SignedUrlFieldFileMutationVariables>;
export const FastapiInsertAUfieldInCompanyV2Document = gql`
    mutation FastapiInsertAUfieldInCompanyV2($user_field_name: String! = "", $company_id: UUID! = "00000000-0000-0000-0000-000000000000", $master_field_key: String! = "", $user_field_group_id: UUID! = "00000000-0000-0000-0000-000000000000") {
  fastapiInsertAUfieldInCompanyV2(
    fieldName: $user_field_name
    nbCompanyId: $company_id
    nbMfieldKey: $master_field_key
    nbUfieldGroupId: $user_field_group_id
  ) {
    __typename
    ... on InsertFieldSuccess {
      fieldId
      message
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on MFieldActiveDoesNotExistsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    ... on NumFieldsExcessError {
      message
    }
    ... on UFieldGroupActiveDoesNotExistsError {
      message
    }
    ... on UserIdError {
      message
    }
  }
}
    `;
export type FastapiInsertAUfieldInCompanyV2MutationFn = Apollo.MutationFunction<FastapiInsertAUfieldInCompanyV2Mutation, FastapiInsertAUfieldInCompanyV2MutationVariables>;

/**
 * __useFastapiInsertAUfieldInCompanyV2Mutation__
 *
 * To run a mutation, you first call `useFastapiInsertAUfieldInCompanyV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFastapiInsertAUfieldInCompanyV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fastapiInsertAUfieldInCompanyV2Mutation, { data, loading, error }] = useFastapiInsertAUfieldInCompanyV2Mutation({
 *   variables: {
 *      user_field_name: // value for 'user_field_name'
 *      company_id: // value for 'company_id'
 *      master_field_key: // value for 'master_field_key'
 *      user_field_group_id: // value for 'user_field_group_id'
 *   },
 * });
 */
export function useFastapiInsertAUfieldInCompanyV2Mutation(baseOptions?: Apollo.MutationHookOptions<FastapiInsertAUfieldInCompanyV2Mutation, FastapiInsertAUfieldInCompanyV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastapiInsertAUfieldInCompanyV2Mutation, FastapiInsertAUfieldInCompanyV2MutationVariables>(FastapiInsertAUfieldInCompanyV2Document, options);
      }
export type FastapiInsertAUfieldInCompanyV2MutationHookResult = ReturnType<typeof useFastapiInsertAUfieldInCompanyV2Mutation>;
export type FastapiInsertAUfieldInCompanyV2MutationResult = Apollo.MutationResult<FastapiInsertAUfieldInCompanyV2Mutation>;
export type FastapiInsertAUfieldInCompanyV2MutationOptions = Apollo.BaseMutationOptions<FastapiInsertAUfieldInCompanyV2Mutation, FastapiInsertAUfieldInCompanyV2MutationVariables>;
export const FastapiS3SignedUrlTrackerApksDocument = gql`
    query FastapiS3SignedUrlTrackerApks($company_id: UUID! = "00000000-0000-0000-0000-000000000000") {
  fastapiS3SignedUrlTrackerApks(companyId: $company_id) {
    ... on S3SignedUrlTrackerApksSuccess {
      signedUrls {
        signedUrl
        appName
        version
        codeHash
        releaseDatetime
        buildType
      }
    }
    ... on UserIdError {
      message
    }
    ... on CompanyDoesNotExistsError {
      message
    }
    ... on NotHavingPermissionError {
      message
    }
    __typename
  }
}
    `;

/**
 * __useFastapiS3SignedUrlTrackerApksQuery__
 *
 * To run a query within a React component, call `useFastapiS3SignedUrlTrackerApksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastapiS3SignedUrlTrackerApksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastapiS3SignedUrlTrackerApksQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useFastapiS3SignedUrlTrackerApksQuery(baseOptions?: Apollo.QueryHookOptions<FastapiS3SignedUrlTrackerApksQuery, FastapiS3SignedUrlTrackerApksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FastapiS3SignedUrlTrackerApksQuery, FastapiS3SignedUrlTrackerApksQueryVariables>(FastapiS3SignedUrlTrackerApksDocument, options);
      }
export function useFastapiS3SignedUrlTrackerApksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FastapiS3SignedUrlTrackerApksQuery, FastapiS3SignedUrlTrackerApksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FastapiS3SignedUrlTrackerApksQuery, FastapiS3SignedUrlTrackerApksQueryVariables>(FastapiS3SignedUrlTrackerApksDocument, options);
        }
export type FastapiS3SignedUrlTrackerApksQueryHookResult = ReturnType<typeof useFastapiS3SignedUrlTrackerApksQuery>;
export type FastapiS3SignedUrlTrackerApksLazyQueryHookResult = ReturnType<typeof useFastapiS3SignedUrlTrackerApksLazyQuery>;
export type FastapiS3SignedUrlTrackerApksQueryResult = Apollo.QueryResult<FastapiS3SignedUrlTrackerApksQuery, FastapiS3SignedUrlTrackerApksQueryVariables>;
export const GetMobileCompaniesDocument = gql`
    query GetMobileCompanies($app_user_active_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_company(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now"}}
    order_by: {name: asc}
  ) {
    id
    name
    nbpm_user_attr_links(where: {app_user_active: {id: {_eq: $app_user_active_id}}}) {
      nbpm_user_attribute {
        attribute
      }
    }
    nbpm_contract_plan {
      nbpm_contract_plan_links {
        nbpm_authorization {
          key
          name
          writable
        }
      }
    }
  }
}
    `;

/**
 * __useGetMobileCompaniesQuery__
 *
 * To run a query within a React component, call `useGetMobileCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileCompaniesQuery({
 *   variables: {
 *      app_user_active_id: // value for 'app_user_active_id'
 *   },
 * });
 */
export function useGetMobileCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetMobileCompaniesQuery, GetMobileCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMobileCompaniesQuery, GetMobileCompaniesQueryVariables>(GetMobileCompaniesDocument, options);
      }
export function useGetMobileCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMobileCompaniesQuery, GetMobileCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMobileCompaniesQuery, GetMobileCompaniesQueryVariables>(GetMobileCompaniesDocument, options);
        }
export type GetMobileCompaniesQueryHookResult = ReturnType<typeof useGetMobileCompaniesQuery>;
export type GetMobileCompaniesLazyQueryHookResult = ReturnType<typeof useGetMobileCompaniesLazyQuery>;
export type GetMobileCompaniesQueryResult = Apollo.QueryResult<GetMobileCompaniesQuery, GetMobileCompaniesQueryVariables>;
export const GetMobileUserFieldsDocument = gql`
    query GetMobileUserFields($company_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_ufield(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, nb_ufg_links: {nb_ufield_group: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, company_id: {_eq: $company_id}}}}
    order_by: {field_name: asc}
  ) {
    id
    field_name
    polygon_field
    nb_ufattr_links {
      nb_ufield_attribute {
        id
        name
      }
    }
    nb_ufg_links {
      nb_ufield_group {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetMobileUserFieldsQuery__
 *
 * To run a query within a React component, call `useGetMobileUserFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileUserFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileUserFieldsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetMobileUserFieldsQuery(baseOptions?: Apollo.QueryHookOptions<GetMobileUserFieldsQuery, GetMobileUserFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMobileUserFieldsQuery, GetMobileUserFieldsQueryVariables>(GetMobileUserFieldsDocument, options);
      }
export function useGetMobileUserFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMobileUserFieldsQuery, GetMobileUserFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMobileUserFieldsQuery, GetMobileUserFieldsQueryVariables>(GetMobileUserFieldsDocument, options);
        }
export type GetMobileUserFieldsQueryHookResult = ReturnType<typeof useGetMobileUserFieldsQuery>;
export type GetMobileUserFieldsLazyQueryHookResult = ReturnType<typeof useGetMobileUserFieldsLazyQuery>;
export type GetMobileUserFieldsQueryResult = Apollo.QueryResult<GetMobileUserFieldsQuery, GetMobileUserFieldsQueryVariables>;
export const GetMobileUserFieldAttributesDocument = gql`
    query GetMobileUserFieldAttributes($company_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_ufield_attribute(where: {company_id: {_eq: $company_id}}) {
    id
    name
    created
  }
}
    `;

/**
 * __useGetMobileUserFieldAttributesQuery__
 *
 * To run a query within a React component, call `useGetMobileUserFieldAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileUserFieldAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileUserFieldAttributesQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetMobileUserFieldAttributesQuery(baseOptions?: Apollo.QueryHookOptions<GetMobileUserFieldAttributesQuery, GetMobileUserFieldAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMobileUserFieldAttributesQuery, GetMobileUserFieldAttributesQueryVariables>(GetMobileUserFieldAttributesDocument, options);
      }
export function useGetMobileUserFieldAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMobileUserFieldAttributesQuery, GetMobileUserFieldAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMobileUserFieldAttributesQuery, GetMobileUserFieldAttributesQueryVariables>(GetMobileUserFieldAttributesDocument, options);
        }
export type GetMobileUserFieldAttributesQueryHookResult = ReturnType<typeof useGetMobileUserFieldAttributesQuery>;
export type GetMobileUserFieldAttributesLazyQueryHookResult = ReturnType<typeof useGetMobileUserFieldAttributesLazyQuery>;
export type GetMobileUserFieldAttributesQueryResult = Apollo.QueryResult<GetMobileUserFieldAttributesQuery, GetMobileUserFieldAttributesQueryVariables>;
export const GetMobileUserFieldGroupsDocument = gql`
    query GetMobileUserFieldGroups($company_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_ufield_group(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, company_id: {_eq: $company_id}}
  ) {
    id
    name
    created
  }
}
    `;

/**
 * __useGetMobileUserFieldGroupsQuery__
 *
 * To run a query within a React component, call `useGetMobileUserFieldGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileUserFieldGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileUserFieldGroupsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetMobileUserFieldGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetMobileUserFieldGroupsQuery, GetMobileUserFieldGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMobileUserFieldGroupsQuery, GetMobileUserFieldGroupsQueryVariables>(GetMobileUserFieldGroupsDocument, options);
      }
export function useGetMobileUserFieldGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMobileUserFieldGroupsQuery, GetMobileUserFieldGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMobileUserFieldGroupsQuery, GetMobileUserFieldGroupsQueryVariables>(GetMobileUserFieldGroupsDocument, options);
        }
export type GetMobileUserFieldGroupsQueryHookResult = ReturnType<typeof useGetMobileUserFieldGroupsQuery>;
export type GetMobileUserFieldGroupsLazyQueryHookResult = ReturnType<typeof useGetMobileUserFieldGroupsLazyQuery>;
export type GetMobileUserFieldGroupsQueryResult = Apollo.QueryResult<GetMobileUserFieldGroupsQuery, GetMobileUserFieldGroupsQueryVariables>;
export const GetMobileTaskTypesDocument = gql`
    query GetMobileTaskTypes {
  nb_task_type {
    type
  }
}
    `;

/**
 * __useGetMobileTaskTypesQuery__
 *
 * To run a query within a React component, call `useGetMobileTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileTaskTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMobileTaskTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetMobileTaskTypesQuery, GetMobileTaskTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMobileTaskTypesQuery, GetMobileTaskTypesQueryVariables>(GetMobileTaskTypesDocument, options);
      }
export function useGetMobileTaskTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMobileTaskTypesQuery, GetMobileTaskTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMobileTaskTypesQuery, GetMobileTaskTypesQueryVariables>(GetMobileTaskTypesDocument, options);
        }
export type GetMobileTaskTypesQueryHookResult = ReturnType<typeof useGetMobileTaskTypesQuery>;
export type GetMobileTaskTypesLazyQueryHookResult = ReturnType<typeof useGetMobileTaskTypesLazyQuery>;
export type GetMobileTaskTypesQueryResult = Apollo.QueryResult<GetMobileTaskTypesQuery, GetMobileTaskTypesQueryVariables>;
export const GetTackerLatestUserLocationsDocument = gql`
    subscription GetTackerLatestUserLocations($company_id: uuid! = "00000000-0000-0000-0000-000000000000", $datetime: timestamptz! = "now()") {
  nbtrk_context(
    where: {company_id: {_eq: $company_id}, created: {_gte: $datetime}}
    distinct_on: [user_id]
    order_by: [{user_id: asc}, {created: desc}]
  ) {
    id
    created
    task_type
    user_id
    app_user_active {
      name
    }
    nbtrk_eph_locations(order_by: [{loc_time: desc}], limit: 1) {
      loc_time
      point_lat
      point_lng
    }
  }
}
    `;

/**
 * __useGetTackerLatestUserLocationsSubscription__
 *
 * To run a query within a React component, call `useGetTackerLatestUserLocationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetTackerLatestUserLocationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTackerLatestUserLocationsSubscription({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      datetime: // value for 'datetime'
 *   },
 * });
 */
export function useGetTackerLatestUserLocationsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetTackerLatestUserLocationsSubscription, GetTackerLatestUserLocationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetTackerLatestUserLocationsSubscription, GetTackerLatestUserLocationsSubscriptionVariables>(GetTackerLatestUserLocationsDocument, options);
      }
export type GetTackerLatestUserLocationsSubscriptionHookResult = ReturnType<typeof useGetTackerLatestUserLocationsSubscription>;
export type GetTackerLatestUserLocationsSubscriptionResult = Apollo.SubscriptionResult<GetTackerLatestUserLocationsSubscription>;
export const GetMobileUserLocationContextsDocument = gql`
    query GetMobileUserLocationContexts($user_ids: [uuid!]! = ["00000000-0000-0000-0000-000000000000"], $date_from: timestamptz! = "now()", $date_to: timestamptz! = "now()", $task_types: [String!]! = [""]) {
  nbtrk_context(
    where: {user_id: {_in: $user_ids}, created: {_gte: $date_from, _lt: $date_to}, task_type: {_in: $task_types}}
    order_by: [{user_id: asc}, {created: asc}, {task_type: asc}]
  ) {
    id
  }
}
    `;

/**
 * __useGetMobileUserLocationContextsQuery__
 *
 * To run a query within a React component, call `useGetMobileUserLocationContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileUserLocationContextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileUserLocationContextsQuery({
 *   variables: {
 *      user_ids: // value for 'user_ids'
 *      date_from: // value for 'date_from'
 *      date_to: // value for 'date_to'
 *      task_types: // value for 'task_types'
 *   },
 * });
 */
export function useGetMobileUserLocationContextsQuery(baseOptions?: Apollo.QueryHookOptions<GetMobileUserLocationContextsQuery, GetMobileUserLocationContextsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMobileUserLocationContextsQuery, GetMobileUserLocationContextsQueryVariables>(GetMobileUserLocationContextsDocument, options);
      }
export function useGetMobileUserLocationContextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMobileUserLocationContextsQuery, GetMobileUserLocationContextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMobileUserLocationContextsQuery, GetMobileUserLocationContextsQueryVariables>(GetMobileUserLocationContextsDocument, options);
        }
export type GetMobileUserLocationContextsQueryHookResult = ReturnType<typeof useGetMobileUserLocationContextsQuery>;
export type GetMobileUserLocationContextsLazyQueryHookResult = ReturnType<typeof useGetMobileUserLocationContextsLazyQuery>;
export type GetMobileUserLocationContextsQueryResult = Apollo.QueryResult<GetMobileUserLocationContextsQuery, GetMobileUserLocationContextsQueryVariables>;
export const GetMobileUserLocationHistoryDocument = gql`
    query GetMobileUserLocationHistory($context_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nbtrk_context(where: {id: {_eq: $context_id}}) {
    id
    created
    task_type
    user_id
    app_user_active {
      name
    }
    nbtrk_eph_locations(order_by: [{loc_time: asc}]) {
      loc_time
      point_lat
      point_lng
    }
    nb_performed_works(
      where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}}
      order_by: [{start_datetime: asc}]
    ) {
      id
      start_datetime
      working_time
      nb_ufield {
        id
        field_name
        polygon_field
      }
    }
  }
}
    `;

/**
 * __useGetMobileUserLocationHistoryQuery__
 *
 * To run a query within a React component, call `useGetMobileUserLocationHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileUserLocationHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileUserLocationHistoryQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetMobileUserLocationHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetMobileUserLocationHistoryQuery, GetMobileUserLocationHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMobileUserLocationHistoryQuery, GetMobileUserLocationHistoryQueryVariables>(GetMobileUserLocationHistoryDocument, options);
      }
export function useGetMobileUserLocationHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMobileUserLocationHistoryQuery, GetMobileUserLocationHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMobileUserLocationHistoryQuery, GetMobileUserLocationHistoryQueryVariables>(GetMobileUserLocationHistoryDocument, options);
        }
export type GetMobileUserLocationHistoryQueryHookResult = ReturnType<typeof useGetMobileUserLocationHistoryQuery>;
export type GetMobileUserLocationHistoryLazyQueryHookResult = ReturnType<typeof useGetMobileUserLocationHistoryLazyQuery>;
export type GetMobileUserLocationHistoryQueryResult = Apollo.QueryResult<GetMobileUserLocationHistoryQuery, GetMobileUserLocationHistoryQueryVariables>;
export const GetMobileUserFieldsInGeometryDocument = gql`
    query GetMobileUserFieldsInGeometry($geometry: geometry! = {}) {
  nb_ufield(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, polygon_field: {_st_intersects: $geometry}}
    order_by: {field_name: asc}
  ) {
    id
    field_name
    polygon_field
  }
}
    `;

/**
 * __useGetMobileUserFieldsInGeometryQuery__
 *
 * To run a query within a React component, call `useGetMobileUserFieldsInGeometryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileUserFieldsInGeometryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileUserFieldsInGeometryQuery({
 *   variables: {
 *      geometry: // value for 'geometry'
 *   },
 * });
 */
export function useGetMobileUserFieldsInGeometryQuery(baseOptions?: Apollo.QueryHookOptions<GetMobileUserFieldsInGeometryQuery, GetMobileUserFieldsInGeometryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMobileUserFieldsInGeometryQuery, GetMobileUserFieldsInGeometryQueryVariables>(GetMobileUserFieldsInGeometryDocument, options);
      }
export function useGetMobileUserFieldsInGeometryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMobileUserFieldsInGeometryQuery, GetMobileUserFieldsInGeometryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMobileUserFieldsInGeometryQuery, GetMobileUserFieldsInGeometryQueryVariables>(GetMobileUserFieldsInGeometryDocument, options);
        }
export type GetMobileUserFieldsInGeometryQueryHookResult = ReturnType<typeof useGetMobileUserFieldsInGeometryQuery>;
export type GetMobileUserFieldsInGeometryLazyQueryHookResult = ReturnType<typeof useGetMobileUserFieldsInGeometryLazyQuery>;
export type GetMobileUserFieldsInGeometryQueryResult = Apollo.QueryResult<GetMobileUserFieldsInGeometryQuery, GetMobileUserFieldsInGeometryQueryVariables>;
export const DeleteMobilePerformedWorksDocument = gql`
    mutation DeleteMobilePerformedWorks($ids: [uuid!]! = ["00000000-0000-0000-0000-000000000000"]) {
  update_nb_performed_work(where: {id: {_in: $ids}}, _set: {valid_end: "now()"}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteMobilePerformedWorksMutationFn = Apollo.MutationFunction<DeleteMobilePerformedWorksMutation, DeleteMobilePerformedWorksMutationVariables>;

/**
 * __useDeleteMobilePerformedWorksMutation__
 *
 * To run a mutation, you first call `useDeleteMobilePerformedWorksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMobilePerformedWorksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMobilePerformedWorksMutation, { data, loading, error }] = useDeleteMobilePerformedWorksMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteMobilePerformedWorksMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMobilePerformedWorksMutation, DeleteMobilePerformedWorksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMobilePerformedWorksMutation, DeleteMobilePerformedWorksMutationVariables>(DeleteMobilePerformedWorksDocument, options);
      }
export type DeleteMobilePerformedWorksMutationHookResult = ReturnType<typeof useDeleteMobilePerformedWorksMutation>;
export type DeleteMobilePerformedWorksMutationResult = Apollo.MutationResult<DeleteMobilePerformedWorksMutation>;
export type DeleteMobilePerformedWorksMutationOptions = Apollo.BaseMutationOptions<DeleteMobilePerformedWorksMutation, DeleteMobilePerformedWorksMutationVariables>;
export const InsertMobilePerformedWorksDocument = gql`
    mutation InsertMobilePerformedWorks($input: [nb_performed_work_insert_input!]! = []) {
  insert_nb_performed_work(objects: $input) {
    returning {
      id
    }
  }
}
    `;
export type InsertMobilePerformedWorksMutationFn = Apollo.MutationFunction<InsertMobilePerformedWorksMutation, InsertMobilePerformedWorksMutationVariables>;

/**
 * __useInsertMobilePerformedWorksMutation__
 *
 * To run a mutation, you first call `useInsertMobilePerformedWorksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMobilePerformedWorksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMobilePerformedWorksMutation, { data, loading, error }] = useInsertMobilePerformedWorksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertMobilePerformedWorksMutation(baseOptions?: Apollo.MutationHookOptions<InsertMobilePerformedWorksMutation, InsertMobilePerformedWorksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertMobilePerformedWorksMutation, InsertMobilePerformedWorksMutationVariables>(InsertMobilePerformedWorksDocument, options);
      }
export type InsertMobilePerformedWorksMutationHookResult = ReturnType<typeof useInsertMobilePerformedWorksMutation>;
export type InsertMobilePerformedWorksMutationResult = Apollo.MutationResult<InsertMobilePerformedWorksMutation>;
export type InsertMobilePerformedWorksMutationOptions = Apollo.BaseMutationOptions<InsertMobilePerformedWorksMutation, InsertMobilePerformedWorksMutationVariables>;
export const InsertPerformedWorksDocument = gql`
    mutation InsertPerformedWorks($input: [nb_performed_work_insert_input!]! = []) {
  insert_nb_performed_work(objects: $input) {
    returning {
      id
    }
  }
}
    `;
export type InsertPerformedWorksMutationFn = Apollo.MutationFunction<InsertPerformedWorksMutation, InsertPerformedWorksMutationVariables>;

/**
 * __useInsertPerformedWorksMutation__
 *
 * To run a mutation, you first call `useInsertPerformedWorksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPerformedWorksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPerformedWorksMutation, { data, loading, error }] = useInsertPerformedWorksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertPerformedWorksMutation(baseOptions?: Apollo.MutationHookOptions<InsertPerformedWorksMutation, InsertPerformedWorksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPerformedWorksMutation, InsertPerformedWorksMutationVariables>(InsertPerformedWorksDocument, options);
      }
export type InsertPerformedWorksMutationHookResult = ReturnType<typeof useInsertPerformedWorksMutation>;
export type InsertPerformedWorksMutationResult = Apollo.MutationResult<InsertPerformedWorksMutation>;
export type InsertPerformedWorksMutationOptions = Apollo.BaseMutationOptions<InsertPerformedWorksMutation, InsertPerformedWorksMutationVariables>;
export const UpdatePerformedWorkDocument = gql`
    mutation UpdatePerformedWork($performed_work_id: uuid! = "00000000-0000-0000-0000-000000000000", $input: nb_performed_work_set_input! = {}) {
  update_nb_performed_work(where: {id: {_eq: $performed_work_id}}, _set: $input) {
    returning {
      id
    }
  }
}
    `;
export type UpdatePerformedWorkMutationFn = Apollo.MutationFunction<UpdatePerformedWorkMutation, UpdatePerformedWorkMutationVariables>;

/**
 * __useUpdatePerformedWorkMutation__
 *
 * To run a mutation, you first call `useUpdatePerformedWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerformedWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerformedWorkMutation, { data, loading, error }] = useUpdatePerformedWorkMutation({
 *   variables: {
 *      performed_work_id: // value for 'performed_work_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePerformedWorkMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePerformedWorkMutation, UpdatePerformedWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePerformedWorkMutation, UpdatePerformedWorkMutationVariables>(UpdatePerformedWorkDocument, options);
      }
export type UpdatePerformedWorkMutationHookResult = ReturnType<typeof useUpdatePerformedWorkMutation>;
export type UpdatePerformedWorkMutationResult = Apollo.MutationResult<UpdatePerformedWorkMutation>;
export type UpdatePerformedWorkMutationOptions = Apollo.BaseMutationOptions<UpdatePerformedWorkMutation, UpdatePerformedWorkMutationVariables>;
export const DeletePerformedWorksDocument = gql`
    mutation DeletePerformedWorks($performed_work_ids: [uuid!]! = ["00000000-0000-0000-0000-000000000000"]) {
  update_nb_performed_work(
    where: {id: {_in: $performed_work_ids}}
    _set: {valid_end: "now()"}
  ) {
    returning {
      id
    }
  }
}
    `;
export type DeletePerformedWorksMutationFn = Apollo.MutationFunction<DeletePerformedWorksMutation, DeletePerformedWorksMutationVariables>;

/**
 * __useDeletePerformedWorksMutation__
 *
 * To run a mutation, you first call `useDeletePerformedWorksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePerformedWorksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePerformedWorksMutation, { data, loading, error }] = useDeletePerformedWorksMutation({
 *   variables: {
 *      performed_work_ids: // value for 'performed_work_ids'
 *   },
 * });
 */
export function useDeletePerformedWorksMutation(baseOptions?: Apollo.MutationHookOptions<DeletePerformedWorksMutation, DeletePerformedWorksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePerformedWorksMutation, DeletePerformedWorksMutationVariables>(DeletePerformedWorksDocument, options);
      }
export type DeletePerformedWorksMutationHookResult = ReturnType<typeof useDeletePerformedWorksMutation>;
export type DeletePerformedWorksMutationResult = Apollo.MutationResult<DeletePerformedWorksMutation>;
export type DeletePerformedWorksMutationOptions = Apollo.BaseMutationOptions<DeletePerformedWorksMutation, DeletePerformedWorksMutationVariables>;
export const GetPerformedWorksDocument = gql`
    query GetPerformedWorks($company_id: uuid! = "00000000-0000-0000-0000-000000000000", $user_field_group_id: uuid! = "00000000-0000-0000-0000-000000000000") {
  nb_performed_work(
    where: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, company_id: {_eq: $company_id}, _or: [{ufield_id: {_is_null: true}}, {nb_ufield: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, nb_ufg_links: {nb_ufield_group: {valid_start: {_lte: "now()"}, valid_end: {_gte: "now()"}, id: {_eq: $user_field_group_id}}}}}]}
    order_by: [{updated: desc}]
  ) {
    ...PerformedWork
  }
}
    ${PerformedWorkFragmentDoc}`;

/**
 * __useGetPerformedWorksQuery__
 *
 * To run a query within a React component, call `useGetPerformedWorksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerformedWorksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerformedWorksQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      user_field_group_id: // value for 'user_field_group_id'
 *   },
 * });
 */
export function useGetPerformedWorksQuery(baseOptions?: Apollo.QueryHookOptions<GetPerformedWorksQuery, GetPerformedWorksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPerformedWorksQuery, GetPerformedWorksQueryVariables>(GetPerformedWorksDocument, options);
      }
export function useGetPerformedWorksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPerformedWorksQuery, GetPerformedWorksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPerformedWorksQuery, GetPerformedWorksQueryVariables>(GetPerformedWorksDocument, options);
        }
export type GetPerformedWorksQueryHookResult = ReturnType<typeof useGetPerformedWorksQuery>;
export type GetPerformedWorksLazyQueryHookResult = ReturnType<typeof useGetPerformedWorksLazyQuery>;
export type GetPerformedWorksQueryResult = Apollo.QueryResult<GetPerformedWorksQuery, GetPerformedWorksQueryVariables>;
export const Upsert_App_UserDocument = gql`
    mutation upsert_app_user($email: String) {
  insert_app_user(
    objects: {email: $email}
    on_conflict: {constraint: app_user_hasura_user_id_key, update_columns: email}
  ) {
    returning {
      id
    }
  }
}
    `;
export type Upsert_App_UserMutationFn = Apollo.MutationFunction<Upsert_App_UserMutation, Upsert_App_UserMutationVariables>;

/**
 * __useUpsert_App_UserMutation__
 *
 * To run a mutation, you first call `useUpsert_App_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsert_App_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAppUserMutation, { data, loading, error }] = useUpsert_App_UserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpsert_App_UserMutation(baseOptions?: Apollo.MutationHookOptions<Upsert_App_UserMutation, Upsert_App_UserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Upsert_App_UserMutation, Upsert_App_UserMutationVariables>(Upsert_App_UserDocument, options);
      }
export type Upsert_App_UserMutationHookResult = ReturnType<typeof useUpsert_App_UserMutation>;
export type Upsert_App_UserMutationResult = Apollo.MutationResult<Upsert_App_UserMutation>;
export type Upsert_App_UserMutationOptions = Apollo.BaseMutationOptions<Upsert_App_UserMutation, Upsert_App_UserMutationVariables>;